import { RoleContext } from "#src/routes/settings/roles-and-permissions/roles/[roleId]/detail/RoleContext";
import { useQuery } from "@tanstack/react-query";
import {
  Panel,
  TextAreaInput,
  TextInput,
  useFormContext,
} from "@validereinc/common-components";
import { RoleUpdateSchema, RolesAdapter } from "@validereinc/domain";
import React, { useContext } from "react";
import { useDebounce } from "use-debounce";

export const EditRoleFormPanel = () => {
  const form = useFormContext();
  const { roleQuery } = useContext(RoleContext) ?? {};
  const [enteredRoleName] = useDebounce<string>(
    form.watch(RoleUpdateSchema.keyof().Enum.name) ?? "",
    500
  );

  const isEditedNameSameAsOriginalName =
    enteredRoleName.trim().toLocaleLowerCase() ===
    roleQuery?.data?.name.trim().toLocaleLowerCase();
  const duplicateQueryPayload: Parameters<typeof RolesAdapter.getList>[0] = {
    filters: {
      name: enteredRoleName,
      isAlreadyFormatted: true,
    },
  };

  const { data: duplicateRoles } = useQuery({
    queryKey: ["roles", duplicateQueryPayload],
    queryFn: () => RolesAdapter.getList(duplicateQueryPayload),
    enabled: Boolean(enteredRoleName && !isEditedNameSameAsOriginalName),
  });

  return (
    <Panel title="Details">
      <TextInput
        name="name"
        label="Name"
        isRequired
        validate={{
          uniqueName: () => {
            return !isEditedNameSameAsOriginalName &&
              duplicateRoles?.total_entries
              ? "This role already exists"
              : true;
          },
        }}
      />
      <TextAreaInput
        name="description"
        label="Description"
      />
    </Panel>
  );
};
