export const DEFAULT_INLINE_MONITOR_INPUTS = {
  num_offspec_threshold: undefined,
  interval_minutes: undefined,
  lower_limit: undefined,
  upper_limit: undefined,
};

export const DEFAULT_ALERT_SPEC_INPUTS = {
  lower_limit: undefined,
  upper_limit: undefined,
  std_sigma: undefined,
  std_historical_range: undefined,
  std_historical_range_unit: undefined,
  std_min_num_measurements: undefined,
  roq_previous_total_vol_percentage_deviation: undefined,
};
