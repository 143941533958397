import history from "#routers/history";
import { linkToEmissionReports } from "#routers/links";
import { GetAllObjectValue } from "#utils/objectFormatter";
import { LegacyDataTable, Tooltip } from "@validereinc/common-components";
import classNames from "classnames/bind";
import * as PropTypes from "prop-types";
import React from "react";
import { AutoSizer } from "react-virtualized";
import styles from "./EmissionReports.module.scss";

const cx = classNames.bind(styles);

const EmissionReportsTable = ({
  emissionReports,
  filterRow,
  filterPillbox,
  noFilterListCount,
}) => {
  const objectArrayRenderer = (rowData, columnKey, property = "name") => {
    return (
      <div className="nowrap-2">
        {GetAllObjectValue(rowData[columnKey], property)?.sort()?.join(", ") ??
          "-"}
      </div>
    );
  };

  const listTooltipRenderer = (rowData, columnKey, title) => {
    const list = rowData[columnKey];

    if (list.length) {
      const tooltip = (
        <ul className={cx("tooltipContent")}>
          {list.map((item) => (
            <li key={item.id}>{item?.name ?? "-"}</li>
          ))}
        </ul>
      );

      const label = `${list.length} ${title}${list.length > 1 ? "s" : ""}`;

      return (
        <Tooltip
          content={tooltip}
          trigger="hover"
          title={title}
        >
          <span className={cx("tooltipBase")}>{label}</span>
        </Tooltip>
      );
    }

    return "-";
  };

  const headers = [
    {
      label: "Report Name",
      key: "name",
      width: 250,
      fixed: true,
    },
    {
      label: "Sales Facilities",
      key: "facilities",
      width: 300,
      cellRenderer: objectArrayRenderer,
    },
    {
      label: "Sales Product Streams",
      key: "sales_product_stream_ids",
      width: 200,
      cellRenderer: (rowData, columnKey) =>
        listTooltipRenderer(rowData, columnKey, "Stream"),
    },
    {
      label: "Included Equipment",
      key: "equipment",
      width: 150,
      cellRenderer: (rowData, columnKey) =>
        listTooltipRenderer(rowData, columnKey, "Equipment"),
    },
  ];

  return (
    <AutoSizer>
      {({ height, width }) => (
        <LegacyDataTable
          width={width}
          height={height}
          headers={headers}
          list={emissionReports}
          filterKey="name"
          rowHeight={60}
          defaultSortBy="name"
          defaultSortDirection="asc"
          filterPillbox={filterPillbox}
          filterRow={filterRow}
          noFilterListCount={noFilterListCount}
          onCellClick={(rowData) =>
            history.push(linkToEmissionReports(rowData.id))
          }
          highlightRow
        />
      )}
    </AutoSizer>
  );
};

EmissionReportsTable.propTypes = {
  emissionReports: PropTypes.array.isRequired,
  filterRow: PropTypes.object.isRequired,
  filterPillbox: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  noFilterListCount: PropTypes.number.isRequired,
};

export default EmissionReportsTable;
