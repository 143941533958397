import {
  EntityDropdownInput,
  BaseEntityDropdownProps,
} from "#batteries-included-components/Dropdowns/EntityDropdownInput";
import useLocalization from "#src/hooks/useLocalization";
import { FlowDomain } from "@validereinc/domain";
import React from "react";

export const FlowDropdownInput = ({
  name = "flow.id",
  label,
  ...props
}: BaseEntityDropdownProps) => {
  const { localize } = useLocalization();

  return (
    <EntityDropdownInput
      fetchEntityList={FlowDomain.getFlows}
      label={label ?? localize("Flow")}
      name={name}
      {...props}
    />
  );
};
