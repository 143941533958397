import { useAlert } from "@validereinc/common-components";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { ProgressBar } from "react-bootstrap";
import { connect } from "react-redux";
import { NOTIFICATION_OPTIONS } from "../../../../utils/enums";
import NotificationService from "../../../Services/NotificationService";
import NotificationForm from "./NotificationForm";

const mapStateToProps = (state) => {
  return {
    profile: state.profiles,
  };
};

const CompanyNotificationSettings = (props) => {
  const [notificationSettings, setNotificationSettings] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(true);
  const [disabled, setDisabled] = useState(false);
  const { addAlert } = useAlert();

  const companyId = props.profile.data.company_id;

  const onDropdownSelect = (value, key) => {
    const newNotificationSettings = {
      ...notificationSettings,
      [key]: NOTIFICATION_OPTIONS[value].value,
    };
    setNotificationSettings(newNotificationSettings);
    submitNotificationSettings(newNotificationSettings);
  };

  const submitNotificationSettings = (newNotificationSettings) => {
    setIsSubmitted(false);
    NotificationService.updateCompanyNotificationSettings(
      companyId,
      newNotificationSettings
    )
      .then(() => {
        setIsSubmitted(true);
        addAlert({
          variant: "success",
          message: "Notification settings successfully updated.",
        });
      })
      .catch(() => {
        setIsSubmitted(true);
      });
  };

  useEffect(() => {
    if (companyId && !isLoaded) {
      setIsLoaded(false);
      NotificationService.getCompanyNotificationSettings(companyId)
        .then((response) => {
          setNotificationSettings(response.data);
          setIsLoaded(true);
        })
        .catch(() => {
          setDisabled(true);
          setIsLoaded(true);
        });
    }
  }, [companyId]);

  return (
    <div className="companyNotificationSetting">
      <ProgressBar
        active
        now={100}
        hidden={isSubmitted}
      />
      {isLoaded && notificationSettings ? (
        <NotificationForm
          notificationSettings={notificationSettings}
          onDropdownSelect={onDropdownSelect}
          disabled={disabled}
        />
      ) : (
        <div className="noNotificationSettingsAvailable">
          No notification settings available.
        </div>
      )}
    </div>
  );
};

CompanyNotificationSettings.propTypes = {
  profile: PropTypes.object,
  addAlertMessage: PropTypes.func,
};

export default connect(mapStateToProps, null)(CompanyNotificationSettings);
