import { getFilterObject, ResourceServiceType } from "../util";
import { restAPI } from "./api";
import { ReportType, TemplatedReportType } from "../schemas";

type TemplatedReportServiceType = Pick<
  ResourceServiceType<TemplatedReportType>,
  "getList" | "getOne"
> & {
  generate: (params: {
    name: string;
    templatedReportName: string;
    input: Record<string, string | number>;
  }) => Promise<ReportType>;
};

export const TemplatedReportAdapter: TemplatedReportServiceType = {
  getOne: async ({ id }) => {
    const data = await restAPI.nodeAPI.GET<TemplatedReportType>({
      endpoint: `/templated_reports/${id}`,
    });
    return { data };
  },
  getList: ({ page, pageSize, sortBy, sortDirection, filters }) =>
    restAPI.nodeAPI.POST({
      endpoint: "/templated_reports/search_company",
      body: {
        page,
        page_size: pageSize,
        sort_by: sortBy,
        sort_direction: sortDirection,
        filter: getFilterObject(filters),
      },
    }),
  generate: ({ name, templatedReportName, input }) =>
    restAPI.nodeAPI.POST({
      version: 2,
      endpoint: "/reports",
      body: {
        name,
        templated_report_name: templatedReportName,
        input,
      },
    }),
};
