import SummaryInformation from "#common/SummaryInformation/SummaryInformation";
import { ensureStreamListIsFetched, fetchSiteList } from "#redux/actions/index";
import {
  CUBIC_METERS_VOLUME,
  getFormattedMeasurementValueWithUnit,
} from "#redux/reducers/measurements";
import InventoryService from "#services/InventoryService";
import { Page, Panel, Tab, Tabs } from "@validereinc/common-components";
import moment from "moment";
import * as PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { getBreadcrumbsObject } from "../../Routers/breadcrumbsHelper";
import history from "../../Routers/history";
import { linkToInventories } from "../../Routers/links";
import { havePermission } from "../Redux/reducers/permissions";
import "./InventoryDetail.scss";
import QualitySummary from "./Summary/QualitySummary";
import VolumeSummary from "./Summary/VolumeSummary";
import VolumeOverview from "./Volume/VolumeOverview";

const DEFAULT_DATE_RANGE = 30;

const mapStateToProps = ({ streams, sites, measurements, permissions }) => {
  return {
    streams: streams.data?.toJS() ?? [],
    sites: sites.data?.toJS() ?? [],
    getFormattedMeasurementValueWithUnit: (measurementKey, measurementObject) =>
      getFormattedMeasurementValueWithUnit(measurements)(
        measurementKey,
        measurementObject
      ),
    haveAccountingPermissions: havePermission(permissions)(
      "gas:accounting.records_of_volume",
      "read"
    ),
  };
};

const mapDispatchToProps = {
  ensureStreamListIsFetched,
  fetchSiteList,
};

const DEFAULT_TAB = "summary";

const TABS = {
  [DEFAULT_TAB]: {
    name: "Summary",
  },
  volume: {
    name: "Volume",
  },
};

const getActiveTab = (tab) => {
  if (!TABS[tab]) {
    return DEFAULT_TAB;
  }

  return tab;
};

const useFetchInventoryDetail = (inventoryId) => {
  const [inventoryDetail, setInventoryDetail] = useState({});
  const [state, setState] = useState("loading");

  useEffect(() => {
    setState("loading");

    InventoryService.getInventoryDetail(inventoryId, moment().format())
      .then(({ data }) => {
        setInventoryDetail(data);
        setState("loaded");
      })
      .catch(() => setState("error"));
  }, []);

  return [inventoryDetail, state];
};

const useFetchVolumeSummaryChart = (inventoryId) => {
  const [chartData, setChartData] = useState([]);
  const [state, setState] = useState("loading");

  const [dateRange, setDateRange] = useState({
    from: moment().subtract(DEFAULT_DATE_RANGE, "days").startOf("day").format(),
    to: moment().endOf("day").format(),
  });

  useEffect(() => {
    setState("loading");

    InventoryService.getVolumeChart(inventoryId, dateRange.from, dateRange.to)
      .then(({ data }) => {
        setChartData(data);
        setState("loaded");
      })
      .catch(() => setState("error"));
  }, [dateRange.from, dateRange.to]);

  const selectDateAndTime = (from, to) => {
    setDateRange({
      from: moment(from).format(),
      to: moment(to).format(),
    });
  };

  return [chartData, state, dateRange, selectDateAndTime];
};

const InventoryDetail = (props) => {
  const inventoryId = props.match.params.inventoryId;
  const [inventoryDetail, loadingState] = useFetchInventoryDetail(inventoryId);

  const [chartData, chartDataLoadingState, dateRange, selectDateAndTime] =
    useFetchVolumeSummaryChart(inventoryId);

  useEffect(() => {
    props.ensureStreamListIsFetched();
    props.fetchSiteList();
  }, []);

  const siteName = useMemo(() => {
    return props.sites.find((site) => site.id === inventoryDetail.site_id)
      ?.name;
  }, [props.sites, inventoryDetail]);

  const activeStream = useMemo(() => {
    return props.streams.find(
      (stream) => stream.id === inventoryDetail.active_stream_id
    );
  }, [props.streams, inventoryDetail]);

  const chartDataWithStreams = useMemo(() => {
    const streamNameMap = {};
    props.streams.forEach((stream) => (streamNameMap[stream.id] = stream.name));

    return chartData.map((data) => {
      const streamName = streamNameMap[data.stream_id] ?? "-";

      return {
        ...data,
        x: moment(data.datetime).valueOf(),
        y: data.volume?.value,
        streamName,
      };
    });
  }, [chartData, props.streams]);

  const activeTab = getActiveTab(props.match.params.tab);

  const breadcrumbs = getBreadcrumbsObject(
    props.breadcrumbs,
    props.match.params
  );

  const productType = activeStream?.product_type ?? null;

  return (
    <Page
      title={inventoryDetail?.name}
      breadcrumbs={breadcrumbs}
      isLoading={loadingState === "loading"}
    >
      <div className="inventoryDetail">
        <Panel className="inventoryDetail__headerPanel">
          <div className="inventoryDetail__summarySection">
            <div>
              <SummaryInformation
                title="Site"
                value={siteName}
                isLoading={loadingState === "loading"}
              />

              <SummaryInformation
                title="Product"
                value={productType}
                isLoading={loadingState === "loading"}
              />
            </div>

            <div>
              <SummaryInformation
                title="Stream"
                value={activeStream?.name}
              />

              <SummaryInformation
                title="Filled Level"
                value={props.getFormattedMeasurementValueWithUnit(
                  CUBIC_METERS_VOLUME,
                  inventoryDetail?.volume
                )}
              />
            </div>

            <div>
              <SummaryInformation
                title="Capacity"
                value={props.getFormattedMeasurementValueWithUnit(
                  CUBIC_METERS_VOLUME,
                  inventoryDetail?.capacity
                )}
              />
            </div>
          </div>
        </Panel>

        <Tabs
          activeKey={activeTab}
          onChange={(tab) => {
            history.push(linkToInventories(inventoryId, tab));
          }}
        >
          <Tab
            tabKey="summary"
            title={TABS[DEFAULT_TAB].name}
          >
            <VolumeSummary
              inventoryId={inventoryId}
              inventoryDetail={inventoryDetail}
              chartData={chartDataWithStreams}
              chartDataLoadingState={chartDataLoadingState}
              dateRange={dateRange}
              selectDateAndTime={selectDateAndTime}
              productType={productType}
            />

            <QualitySummary
              activeStream={activeStream}
              inventoryDetail={inventoryDetail}
              loadingState={loadingState}
            />
          </Tab>

          {props.haveAccountingPermissions ? (
            <Tab
              tabKey="volume"
              title={TABS.volume.name}
            >
              <VolumeOverview
                chartData={chartDataWithStreams}
                chartDataLoadingState={chartDataLoadingState}
                dateRange={dateRange}
                selectDateAndTime={selectDateAndTime}
                inventoryDetail={inventoryDetail}
                location={props.location}
                productType={productType}
              />
            </Tab>
          ) : null}
        </Tabs>
      </div>
    </Page>
  );
};

InventoryDetail.propTypes = {
  ensureStreamListIsFetched: PropTypes.func,
  fetchSiteList: PropTypes.func,
  sites: PropTypes.array,
  streams: PropTypes.array,
  match: PropTypes.object,
  location: PropTypes.object,
  getFormattedMeasurementValueWithUnit: PropTypes.func,
  breadcrumbs: PropTypes.array,
  haveAccountingPermissions: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(InventoryDetail);
