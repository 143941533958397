import { CreateFlowFilterPanel } from "#batteries-included-components/Panels/FilterPanels/CreateFlowFilterPanel";
import {
  getDownstreamAssetsFromFlow,
  getUpstreamAssetsFromFlow,
  selectedAssetsToFlowFields,
} from "#batteries-included-components/Panels/FormPanels/FlowFormPanel/FlowConnectionsStep.helpers";
import { useSearchParams } from "#routers/hooks";
import { useFlowDetailFlow } from "#routes/organization/flows/[flowId]/detail/FlowDetailPage.helpers";
import { useMultiStepFormContext } from "#src/hooks/useMultiStepForm";
import { useStorageKey } from "#src/hooks/useStorageKey";
import { Panel } from "@validereinc/common-components";
import { AssetGroupAssetType, AssetType } from "@validereinc/domain";
import classNames from "classnames/bind";
import React, { useEffect, useState } from "react";
import { FlowEquipmentSelectionTablePanel } from "./FlowEquipmentSelectionTablePanel";
import { FlowFacilitySelectionTablePanel } from "./FlowFacilitySelectionTablePanel";
import styles from "./FlowFormPanel.module.scss";
import { FlowSelectionTablePanel } from "./FlowSelectionTablePanel";
import { SelectedAssetsPanel } from "./SelectedAssetsPanel";

const cx = classNames.bind(styles);

export const FlowConnectionStep = ({
  stepNumberOverride,
}: {
  stepNumberOverride?: number;
}) => {
  const STEP_NUMBER = stepNumberOverride ?? 2;
  const { currentStep, updateStep } = useMultiStepFormContext();
  const { filterConfigStorageKey } = useStorageKey("flow-connections-step");
  const { data: flow } = useFlowDetailFlow({
    filterConfigStorageKey,
    enabled: currentStep === STEP_NUMBER,
  });

  const upstreamAssets = getUpstreamAssetsFromFlow(flow);
  const downstreamAssets = getDownstreamAssetsFromFlow(flow);

  const [selectedOrigins, setSelectedOrigins] =
    useState<AssetGroupAssetType[]>(upstreamAssets);
  const [selectedDestinations, setSelectedDestinations] =
    useState<AssetGroupAssetType[]>(downstreamAssets);

  useEffect(() => {
    setSelectedOrigins(upstreamAssets);
    setSelectedDestinations(downstreamAssets);
  }, [flow]);

  const addAssetToFlow = (asset: AssetGroupAssetType, isUpstream: boolean) => {
    if (isUpstream) {
      const existingOrigins =
        asset.asset_type === AssetType.FLOW
          ? selectedOrigins.filter(
              ({ asset_type }) => asset_type === asset.asset_type
            )
          : [];

      setSelectedOrigins([...existingOrigins, asset]);
    } else {
      const existingDestinations = selectedDestinations.filter(
        ({ asset_type }) => asset_type === asset.asset_type
      );
      setSelectedDestinations([...existingDestinations, asset]);
    }
  };

  const removeAssetFromFlow = (
    asset: AssetGroupAssetType,
    isUpstream: boolean
  ) => {
    if (isUpstream) {
      setSelectedOrigins(selectedOrigins.filter(({ id }) => id !== asset.id));
    } else {
      setSelectedDestinations(
        selectedDestinations.filter(({ id }) => id !== asset.id)
      );
    }
  };

  const selectedAssets = Object.fromEntries(
    [...selectedOrigins, ...selectedDestinations].map((asset) => [
      asset.id,
      asset,
    ])
  );

  useEffect(() => {
    const values = {
      ...selectedAssetsToFlowFields(selectedOrigins, true),
      ...selectedAssetsToFlowFields(selectedDestinations, false),
    };
    updateStep(STEP_NUMBER, {
      getValues: () => values,
      getFormState: () => ({
        isValid: !!Object.keys(values).length,
        isSubmitting: false,
      }),
    });
  }, [selectedOrigins, selectedDestinations]);

  const [searchParams] = useSearchParams();

  const selectionTablePanelProps = {
    filterConfigStorageKey,
    selectedAssets,
    addAssetToFlow,
  };

  return (
    <>
      {currentStep === STEP_NUMBER ? (
        <Panel title="Connections">
          <p>Select the origin and destination asset(s) of your flow</p>

          <div>
            <CreateFlowFilterPanel
              filterConfigStorageKey={filterConfigStorageKey}
            />

            <div className={cx("connectionsPanelsContainer")}>
              {searchParams.entity_type === AssetType.FACILITY ? (
                <FlowFacilitySelectionTablePanel
                  {...selectionTablePanelProps}
                />
              ) : null}

              {searchParams.entity_type === AssetType.EQUIPMENT ? (
                <FlowEquipmentSelectionTablePanel
                  {...selectionTablePanelProps}
                />
              ) : null}

              {searchParams.entity_type === AssetType.FLOW ? (
                <FlowSelectionTablePanel {...selectionTablePanelProps} />
              ) : null}

              <div className={cx("selectedAssetsPanelContainer")}>
                <SelectedAssetsPanel
                  selectedOrigins={selectedOrigins}
                  selectedDestinations={selectedDestinations}
                  removeAssetFromFlow={removeAssetFromFlow}
                />
              </div>
            </div>
          </div>
        </Panel>
      ) : null}
    </>
  );
};
