import {
  FilterPillbox,
  MultiDropdownInputWithSearch,
} from "@validereinc/common-components";
import difference from "lodash/difference";
import differenceBy from "lodash/differenceBy";
import indexOf from "lodash/indexOf";
import intersectionWith from "lodash/intersectionWith";
import moment from "moment";
import React, { useEffect, useState } from "react";
import useQueryParams from "../../../../Routers/useQueryParams";
import { formatDailyVolumeDate } from "./DailyVolumeHelper";

const FIRST_DAY_OF_CURRENT_MONTH = moment().startOf("month").startOf("day");

const LAST_DAY_OF_CURRENT_MONTH = moment().endOf("month").startOf("day");

const DEFAULT_DATE_RANGE = {
  from: formatDailyVolumeDate(FIRST_DAY_OF_CURRENT_MONTH),
  to: formatDailyVolumeDate(LAST_DAY_OF_CURRENT_MONTH),
};

const SOURCE_OPTIONS = [
  { id: "manual", name: "Manual" },
  { id: "production_management_system", name: "Production Management System" },
  { id: "field", name: "Field" },
];

const DEFAULT_FILTER_DROPDOWN = {
  adjustmentFactors: {
    label: "Adjustment Factor",
    inputs: [],
    options: [],
    labelKey: "name",
    paramKey: "id",
  },
  state: {
    label: "Status",
    inputs: [],
    options: ["Entry Required", "Locked", "Unlocked"],
  },
  source: {
    label: "Source",
    inputs: [],
    options: SOURCE_OPTIONS,
    labelKey: "name",
  },
};

function noAdjustmentFactorMatch(adjustmentFactors, stream) {
  return (
    intersectionWith(
      adjustmentFactors,
      stream.adjustment_factor_ids,
      (adjustmentFactor, adjustmentFactorId) =>
        adjustmentFactor.id === adjustmentFactorId
    ).length === 0
  );
}

function getStreamState(stream) {
  if (stream.is_entry_required) {
    return "Entry Required";
  } else if (stream.is_locked) {
    return "Locked";
  } else {
    return "Unlocked";
  }
}

const queryParamsConverter = (filterDropdowns) => {
  // This function will convert filterDropdown into useQueryParams format
  const queryParamInput = DEFAULT_DATE_RANGE;
  const queryParamOptions = {};

  Object.keys(filterDropdowns).map((key) => {
    queryParamInput[key] = filterDropdowns[key].inputs;
    queryParamOptions[key] = filterDropdowns[key].options;
  });

  return { queryParamInput, queryParamOptions };
};

const filterVolumeList = (volumeList, queryParams) => {
  const { adjustmentFactors, state, source } = queryParams;

  const filteredVolumeList = volumeList.filter((stream) => {
    if (
      adjustmentFactors.length > 0 &&
      noAdjustmentFactorMatch(adjustmentFactors, stream)
    ) {
      return false;
    }

    const streamState = getStreamState(stream);
    if (state.length > 0 && indexOf(state, streamState) === -1) {
      return false;
    }

    if (
      source?.length &&
      source.every((sourceFilter) => sourceFilter.id !== stream.source)
    ) {
      return false;
    }

    return true;
  });

  return filteredVolumeList;
};

const useFilterInputs = (volumeList, unboundedAdjustmentFactors) => {
  const [filterDropdowns, setFilterDropdown] = useState({
    ...DEFAULT_FILTER_DROPDOWN,
  });

  const { queryParamInput, queryParamOptions } =
    queryParamsConverter(filterDropdowns);

  const [queryParams, setQueryParams] = useQueryParams(
    queryParamInput,
    queryParamOptions
  );

  const onDropdownSelect = (selectedValue, key) => {
    setQueryParams({ [key]: selectedValue });
  };

  const onClearFilterClick = (filterObject) => {
    const newQueryParam = { ...queryParams };

    if (filterObject) {
      const { filterKey } = filterObject;

      if (filterKey === "adjustmentFactors" || filterKey === "source") {
        newQueryParam[filterKey] = differenceBy(
          newQueryParam[filterKey],
          [filterObject],
          "id"
        );
      }

      if (filterKey === "state") {
        newQueryParam[filterKey] = difference(newQueryParam[filterKey], [
          filterObject.name,
        ]);
      }
    } else {
      Object.keys(DEFAULT_FILTER_DROPDOWN).map((key) => {
        newQueryParam[key] = [];
      });
    }

    setQueryParams({ ...newQueryParam });
  };

  useEffect(() => {
    if (volumeList.length > 0 || unboundedAdjustmentFactors.length > 0) {
      const adjustmentFactors = { ...filterDropdowns.adjustmentFactors };
      adjustmentFactors.options = unboundedAdjustmentFactors;

      setFilterDropdown({ ...filterDropdowns, adjustmentFactors });
    }
  }, [unboundedAdjustmentFactors, volumeList]);

  const { from: _from, to: _to, ...params } = queryParams;

  const filterRow = (
    <div className="filterRow">
      {Object.keys(filterDropdowns).map((key) => {
        const { label, labelKey, options } = filterDropdowns[key];

        return (
          <MultiDropdownInputWithSearch
            key={key}
            label={label}
            labelKey={labelKey ? labelKey : null}
            dropdownKey={key}
            value={queryParams[key]}
            options={options}
            onChange={onDropdownSelect}
          />
        );
      })}
    </div>
  );

  const filteredStreams = filterVolumeList(volumeList, queryParams);

  const filterPillbox = (
    <FilterPillbox
      filterBy={params}
      onClearFilterClick={onClearFilterClick}
    />
  );

  return [
    filterRow,
    filterPillbox,
    filteredStreams,
    onDropdownSelect,
    onClearFilterClick,
  ];
};

export default useFilterInputs;
