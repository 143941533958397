import { restAPI } from "./api";
import { getFilterObject, GetListResponseType } from "../util";

export const LedgerEntryStatus = {
  active: "active",
  retired: "retired",
  removed: "removed",
} as const;

export type LedgerEntryStatusType =
  (typeof LedgerEntryStatus)[keyof typeof LedgerEntryStatus];

export type LedgerType = {
  id: string;
  owner_id: string;
  vintage: string;
  status: LedgerEntryStatusType;
  range_start: number;
  range_end: number;
  holding: boolean;
  project_group_id: string;
  serial_number_range: string | null;
};

export type LedgerListFilterType = {
  companyId?: string;
  sort_by?: string;
  sort_direction?: string;
  page?: number;
  page_size?: number;
  filter?: Partial<LedgerType>;
};

export type GetLedgerListResponseType = GetListResponseType<LedgerType>;

export const LedgerAdapter = {
  getList: ({
    companyId,
    page,
    page_size,
    sort_by,
    sort_direction,
    filter,
  }: LedgerListFilterType) =>
    restAPI.publicNodeAPI.POST<GetLedgerListResponseType>({
      endpoint: `/ledger/${companyId}/search`,
      body: {
        sort_by: sort_by,
        sort_direction: sort_direction,
        page: page,
        page_size: page_size,
        filter: getFilterObject(filter),
      },
    }),
};
