import {
  apiRequestServicesPromise,
  apiPutServicesPromise,
  apiDeleteServicesPromise,
  apiPostServicesPromise,
} from "./ServiceHelper";

const AnalyzeService = {
  getAnalyzeBookmarks() {
    return apiRequestServicesPromise("/api/analyze/bookmarks");
  },

  getAnalyzeSpecs(analyzeBookmarkIds) {
    const parameter = {
      id: analyzeBookmarkIds,
    };

    return apiRequestServicesPromise(`/api/analyze/bookmarks`, parameter);
  },

  getAnalyzeSpec(analyzeBookmarkId) {
    return apiRequestServicesPromise(
      `/api/analyze/bookmarks/${analyzeBookmarkId}`
    );
  },

  addAnalyzeBookmarks(bookmark) {
    const parameter = {
      title: bookmark.title,
      stream_ids: bookmark.streams,
      sample_types: bookmark.sampleTypes,
      measurement_types: bookmark.measurementType,
      fixed_date_start: bookmark.timeRangeStart,
      fixed_date_end: bookmark.timeRangeEnd,
      relative_day_range: parseInt(bookmark.relativeDayRange, 10),
      // time_grouping: 60, // TODO: This should come from the bookmark.
    };

    return apiPostServicesPromise(`/api/analyze/bookmarks`, parameter);
  },

  removeAnalyzeBookmarks(analyzeBookmark) {
    return apiDeleteServicesPromise(
      `/api/analyze/bookmarks/${analyzeBookmark.id}`
    );
  },

  updateAnalyzeBookmarks(bookmark) {
    const parameter = {
      id: bookmark.id,
      title: bookmark.title,
      stream_ids: bookmark.streams,
      sample_types: bookmark.sampleTypes,
      measurement_types: bookmark.measurementType,
      fixed_date_start: bookmark.timeRangeStart,
      fixed_date_end: bookmark.timeRangeEnd,
      relative_day_range: parseInt(bookmark.relativeDayRange),
      // time_grouping: 60, // TODO: This should come from the bookmark.
    };

    return apiPutServicesPromise(
      `/api/analyze/bookmarks/${parameter.id}`,
      parameter
    );
  },
};

export default AnalyzeService;
