import RouteList from "#routers/RouteList";
import type { History } from "history";
import React from "react";
import { Router } from "react-router-dom";

const RouterManager = ({ history }: { history: History }) => {
  return (
    <Router history={history}>
      <RouteList />
    </Router>
  );
};

export default RouterManager;
