import { useState, useEffect } from "react";

/**
 * State that syncs with local storage.
 * Credits to Josh Comeau for the snippet.
 * @param defaultValue the first value to store. similar concept to useState.
 * @param key the key to store the value under in local storage.
 * @param parse optionally provide an override to the parsing that needs to happen when the value from local storage is read. default is JSON.parse().
 * @returns a tuple: the value and the value setter. similar in concept to base useState.
 */
export function useStickyState<T>(
  defaultValue: T,
  key: string,
  parser: (valueFromLocalStorage: string) => T = (value) =>
    JSON.parse(value) as T
): [T, React.Dispatch<React.SetStateAction<T>>] {
  const getFromStorage = () => {
    const stickyValue = window.localStorage.getItem(key);

    try {
      return stickyValue !== null ? parser(stickyValue) : defaultValue;
    } catch (err: unknown) {
      console.error(err);

      if (
        (err as DOMException)?.name === "InvalidCharacterError" ||
        (err as SyntaxError)?.name === "SyntaxError"
      ) {
        console.error("Sticky state was not JSON parse-able", {
          stickyValue,
          key,
        });
      }

      // return the default value, even if JSON parsing fails
      return defaultValue;
    }
  };
  const [value, setValue] = useState<T>(getFromStorage);

  useEffect(() => {
    const oldValue = window.localStorage.getItem(key);

    // don't sync with storage if the value is already there
    if (oldValue === JSON.stringify(value)) {
      return;
    }

    window.localStorage.setItem(key, JSON.stringify(value));
    window.dispatchEvent(
      new StorageEvent("storage", {
        key,
        newValue: JSON.stringify(value),
        oldValue,
        storageArea: window.localStorage,
      })
    );
  }, [key, value]);

  useEffect(() => {
    const handleStorageEvent = (ev: StorageEvent) => {
      if (
        ev.key !== key ||
        ev.oldValue === ev.newValue ||
        ev.newValue === JSON.stringify(value) ||
        ev.storageArea !== window.localStorage
      ) {
        return;
      }

      // re-sync value in storage with the value in state
      setValue(getFromStorage());
    };

    window.addEventListener("storage", handleStorageEvent);

    return () => window.removeEventListener("storage", handleStorageEvent);
  }, [value]);

  return [value, setValue];
}

/**
 * State that syncs with session storage.
 * @param defaultValue the first value to store. similar concept to useState.
 * @param key the key to store the value under in local storage.
 * @param parse optionally provide an override to the parsing that needs to happen when the value from local storage is read. default is JSON.parse().
 * @returns a tuple: the value and the value setter. similar in concept to base useState.
 */
export function useSessionStickyState<T>(
  defaultValue: T,
  key: string,
  parser: (valueFromSessionStorage: string) => T = (value) =>
    JSON.parse(value) as T
): [T, React.Dispatch<React.SetStateAction<T>>] {
  const getFromStorage = () => {
    const stickyValue = window.sessionStorage.getItem(key);

    try {
      return stickyValue !== null ? parser(stickyValue) : defaultValue;
    } catch (err: unknown) {
      console.error(err);

      if (
        (err as DOMException)?.name === "InvalidCharacterError" ||
        (err as SyntaxError)?.name === "SyntaxError"
      ) {
        console.error("Sticky state was not JSON parse-able", {
          stickyValue,
          key,
        });
      }

      // return the default value, even if JSON parsing fails
      return defaultValue;
    }
  };
  const [value, setValue] = useState<T>(getFromStorage);

  useEffect(() => {
    const oldValue = window.sessionStorage.getItem(key);

    // don't sync with storage if the value is already there
    if (oldValue === JSON.stringify(value)) {
      return;
    }

    window.sessionStorage.setItem(key, JSON.stringify(value));
    window.dispatchEvent(
      new StorageEvent("storage", {
        key,
        newValue: JSON.stringify(value),
        oldValue,
        storageArea: window.sessionStorage,
      })
    );
  }, [key, value]);

  useEffect(() => {
    const handleStorageEvent = (ev: StorageEvent) => {
      if (
        ev.key !== key ||
        ev.oldValue === ev.newValue ||
        ev.newValue === JSON.stringify(value) ||
        ev.storageArea !== window.sessionStorage
      ) {
        return;
      }

      // re-sync value in storage with the value in state
      setValue(getFromStorage());
    };

    window.addEventListener("storage", handleStorageEvent);

    return () => window.removeEventListener("storage", handleStorageEvent);
  }, [value]);

  return [value, setValue];
}
