import { RoutingLink } from "#batteries-included-components/RoutingLink";
import {
  useListFlowProductCategories,
  useListFlowProductTypes,
  useListFlowTypes,
} from "#hooks/adapters/useFlows";
import { linkToDeviceDetail, linkToFacilities } from "#routers/links";
import { linkToEquipmentDetail } from "#routes/organization/equipment/[equipmentId]";
import { FlowDetailRoute } from "#routes/organization/flows/[flowId]/detail";
import { useFlowDetailFlow } from "#routes/organization/flows/[flowId]/detail/FlowDetailPage.helpers";
import { NetworkDetailRoute } from "#routes/organization/networks/[networkId]/detail";
import { useListCustomAttributes } from "#src/components/hooks/adapters/useCustomAttributes";
import { KeyValuePanel, StorageKeys } from "@validereinc/common-components";
import { AssetType, AttributeDataType } from "@validereinc/domain";
import { dateFormatter, datetimeFormatter } from "@validereinc/utilities";
import isNull from "lodash/isNull";
import React from "react";

export const FlowDetailsPanel = ({
  filterConfigStorageKey,
}: Pick<StorageKeys, "filterConfigStorageKey">) => {
  const { data: flow } = useFlowDetailFlow({ filterConfigStorageKey });
  const flowTypes = useListFlowTypes().data ?? [];
  const productCategories = useListFlowProductCategories().data ?? [];
  const productTypes = useListFlowProductTypes().data ?? [];
  const customAttributes =
    useListCustomAttributes({ filters: { entity_type: AssetType.FLOW } }).data
      ?.data ?? [];

  return (
    <KeyValuePanel
      panelMaxColumnCount={3}
      panelKeyValueListProps={{ maxRowCount: 6, variant: "shaded" }}
      panelProps={{ isFluidY: false }}
      data={[
        {
          title: "Product Type",

          value: productTypes?.find(({ id }) => id === flow?.product_type)
            ?.name,
        },
        {
          title: "Flow Type",
          value: flowTypes?.find(({ id }) => id === flow?.type)?.name,
        },
        {
          title: "Product Category",
          value: productCategories?.find(
            ({ id }) => id === flow?.product_category
          )?.name,
        },
        {
          title: "Associated Facility",
          value: flow?.associated_facility_id ? (
            <RoutingLink to={linkToFacilities(flow?.associated_facility_id)}>
              {flow?.associated_facility?.name ?? "-"}
            </RoutingLink>
          ) : (
            "-"
          ),
        },
        {
          title: "Associated Equipment",
          value: flow?.associated_equipment_id ? (
            <RoutingLink
              to={linkToEquipmentDetail(flow?.associated_equipment_id)}
            >
              {flow?.associated_equipment?.name ?? "-"}
            </RoutingLink>
          ) : (
            "-"
          ),
        },
        ...(customAttributes
          ?.filter(
            ({ entity_subtype }) =>
              !entity_subtype || flow?.type == entity_subtype
          )
          .map(({ display_name, field_name, data_type }) => {
            const value = flow?.custom_attributes?.[field_name];

            if (typeof value === "undefined" || isNull(value)) {
              return {
                title: display_name,
                value: "-",
              };
            }

            // IMPROVE: need to abstract display logic away for attributes in one
            // centralized place. This is currently repeated and fragmented in
            // several places, most notably, forms.
            switch (data_type) {
              case AttributeDataType.DATE_TIME:
                return {
                  title: display_name,
                  value: datetimeFormatter(new Date(value)),
                };
              case AttributeDataType.DATE:
                return {
                  title: display_name,
                  value: dateFormatter(new Date(value)),
                };
              case AttributeDataType.LOOKUP:
                switch (value?.entity_type) {
                  case AssetType.FACILITY:
                    return {
                      title: display_name,
                      value: (
                        <RoutingLink to={linkToFacilities(value?.id)}>
                          {value?.name}
                        </RoutingLink>
                      ),
                    };
                  case AssetType.EQUIPMENT:
                    return {
                      title: display_name,
                      value: (
                        <RoutingLink to={linkToEquipmentDetail(value?.id)}>
                          {value?.name}
                        </RoutingLink>
                      ),
                    };
                  case AssetType.FLOW:
                    return {
                      title: display_name,
                      value: (
                        <RoutingLink
                          to={FlowDetailRoute.toLink({
                            pathParams: { flowId: value?.id },
                          })}
                        >
                          {value?.name}
                        </RoutingLink>
                      ),
                    };
                  case AssetType.DEVICE:
                    return {
                      title: display_name,
                      value: (
                        <RoutingLink to={linkToDeviceDetail(value?.id)}>
                          {value?.name}
                        </RoutingLink>
                      ),
                    };
                  case AssetType.ASSET_GROUP:
                    return {
                      title: display_name,
                      value: (
                        <RoutingLink
                          to={NetworkDetailRoute.toLink({
                            pathParams: { networkId: value?.id },
                          })}
                        >
                          {value?.name}
                        </RoutingLink>
                      ),
                    };
                  default:
                    return {
                      title: display_name,
                      valie: "-",
                    };
                }
              case AttributeDataType.STRING:
              default: {
                return {
                  title: display_name,
                  value,
                };
              }
            }
          }) ?? []),
      ]}
    />
  );
};
