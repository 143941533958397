import { useState } from "react";

const useManualEntryModal = () => {
  const [show, setShow] = useState(false);

  const showManualEntryModal = () => {
    setShow(true);
  };

  const onManualEntryModalHide = () => {
    setShow(false);
  };

  return [show, showManualEntryModal, onManualEntryModalHide];
};

export default useManualEntryModal;
