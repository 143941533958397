import Immutable from "immutable";

import { USER_PERMISSIONS_RETRIEVED } from "../constants/action-types";

export const havePermission =
  (permissionState) => (permission: string | string[], minRequirement) => {
    if (!permissionState?.permissionsMap) {
      return false;
    }

    if (minRequirement === "none") {
      return true;
    }

    const permissions = [permission].flat();

    return permissions.some((name) =>
      permissionState.permissionsMap.get(name)?.includes(minRequirement)
    );
  };

export const PermissionsState = Immutable.Record(
  {
    permissionsMap: undefined,
  },
  "PermissionsState"
);

export default (state = PermissionsState(), action) => {
  switch (action.type) {
    case USER_PERMISSIONS_RETRIEVED: {
      return state.set("permissionsMap", Immutable.Map(action.permissions));
    }
    default:
      return state;
  }
};
