const displayProperties = () => [
  {
    label: "Sulphur Content",
    key: "Sulphur",
  },
  {
    label: "Application Name",
    key: "method",
    notMeasurement: true,
  },
  {
    label: "Run Time",
    key: "total_time",
    unit: "s",
    decimals: 2,
    notMeasurement: true,
  },
  {
    label: "Linear",
    key: "linear",
    decimals: 5,
    notMeasurement: true,
  },
  {
    label: "Alpha",
    key: "alpha",
    decimals: 5,
    notMeasurement: true,
  },
  {
    label: "Hyper",
    key: "hyper",
    decimals: 5,
    notMeasurement: true,
  },
  {
    label: "Region",
    key: "region",
    notMeasurement: true,
  },
  {
    label: "Raw",
    key: "raw",
    decimals: 5,
    notMeasurement: true,
  },
  {
    label: "BG-Cor",
    key: "bg_cor",
    decimals: 5,
    notMeasurement: true,
  },
  {
    label: "X-Cor",
    key: "x_cor",
    decimals: 5,
    notMeasurement: true,
  },
  {
    label: "Norm",
    key: "norm",
    decimals: 5,
    notMeasurement: true,
  },
  {
    label: "Live Time",
    key: "live_time",
    decimals: 5,
    notMeasurement: true,
  },
  {
    label: "Bg Counts",
    key: "bg_counts",
    decimals: 5,
    notMeasurement: true,
  },
  {
    label: "Offset",
    key: "offset",
    decimals: 5,
    notMeasurement: true,
  },
  {
    label: "keV/ch",
    key: "kev_ch",
    decimals: 5,
    notMeasurement: true,
  },
  {
    label: "Noise",
    key: "noise",
    decimals: 5,
    notMeasurement: true,
  },
];

export default displayProperties;
