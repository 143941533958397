import { useState, useEffect, useMemo } from "react";
import moment from "moment";
import sortBy from "lodash/sortBy";
import { getComponentQualityOrderValue } from "#utils/componentQualitySortOrder";
import { getComponentShortForm } from "#utils/stringFormatter";

const DEFAULT_NUM_MONTHS = 3;

/**
 * This hook keeps track of the parameters used for the sample chart and the
 * selection table, passing out the values and the functions needed to change
 * them.
 */
const useManageSelectionFetchParams = (accountingRecordDetail, streams) => {
  const displayProperties = useMemo(() => {
    if (accountingRecordDetail.qualities) {
      const qualities = Object.keys(accountingRecordDetail.qualities);
      return sortBy(qualities, [
        getComponentQualityOrderValue(),
        (a) => a.toLocaleLowerCase(),
      ]).map((quality) => getComponentShortForm(quality));
    }
  }, [accountingRecordDetail]);

  const [measurement, setMeasurement] = useState(displayProperties[0]);
  const [from, setFrom] = useState(moment().valueOf());
  const [until, setUntil] = useState(moment().valueOf());
  const [stream, setStream] = useState({});
  const [siteSystemId, setSiteSystemId] = useState(null);

  /**
   * accountingRecordDetail is set the moment the modal is opened, this ensures
   * that the selection fetches occur at the same time result fetches instead of
   * being dependent on the other.
   *
   * setSiteSystemId is change here as well so it changes in the same render as
   * streamId and won't cause two fetches that may become a race condition
   */
  useEffect(() => {
    if (accountingRecordDetail.selection_stream_id && streams.length) {
      const stream = streams.find(
        (stream) => stream?.id === accountingRecordDetail.selection_stream_id
      );

      setStream(stream);
      resetDateRange();

      setSiteSystemId(
        accountingRecordDetail?.accounting_period?.site_system?.id
      );
    }
  }, [accountingRecordDetail, streams]);

  // On modal open (only time displayProperties change)
  useEffect(() => {
    setMeasurement(displayProperties[0]);
  }, [displayProperties]);

  const resetDateRange = () => {
    setUntil(
      moment(accountingRecordDetail?.accounting_period?.until)
        .endOf("day")
        .add(1, "month")
        .valueOf()
    );

    setFrom(
      moment(accountingRecordDetail?.accounting_period?.until)
        .startOf("day")
        .subtract(DEFAULT_NUM_MONTHS, "months")
        .valueOf()
    );
  };

  const onMeasurementSelect = (value) => {
    setMeasurement(value);
  };

  const onDateSelect = (from, until) => {
    setFrom(moment(from).startOf("day").valueOf());
    setUntil(moment(until).endOf("day").valueOf());
  };

  const onStreamParamSelect = (value) => {
    if (value && value.id !== stream?.id) {
      setStream(value);
      resetDateRange();
    }
  };

  return [
    onMeasurementSelect,
    onDateSelect,
    onStreamParamSelect,
    resetDateRange,
    measurement,
    from,
    until,
    stream,
    siteSystemId,
    displayProperties,
  ];
};

export default useManageSelectionFetchParams;
