import React, { useEffect, useState } from "react";

import * as PropTypes from "prop-types";

import { AutoSizer } from "react-virtualized";

import {
  LegacyDataTable,
  Panel,
  useAlert,
} from "@validereinc/common-components";

import { downloadLink } from "#utils/download";

import RecordOfQualityService from "#services/RecordOfQualityService";

import SampleService from "#services/SampleService";

import classNames from "classnames/bind";

import styles from "./DownloadableReportsPanel.module.scss";

import PreviewModal from "../TestDetailModal/TestAnalysis/PreviewModal";

const ROW_HEIGHT = 55;
const HEADER_HEIGHT = 45;

const cx = classNames.bind(styles);

const DownloadableReportPanel = ({
  sampleId,
  pdfReportLink,
  labReportId,
  testImageUrls = [],
  instrumentType,
}) => {
  const [downloadableReports, setDownloadableReports] = useState([]);
  const [previewLink, setPreviewLink] = useState();

  const { addAlert } = useAlert();

  const createSuccessfulDownloadAlertMessage = () => {
    addAlert({
      variant: "success",
      message: "Your files will start downloading shortly.",
    });
  };

  const onViolationLetterDownloadLinkClick = (sampleId) => {
    RecordOfQualityService.getDownloadSampleCompositionalLink(
      sampleId,
      "violation_letter"
    ).then(({ data }) => {
      downloadLink(data.url);
      createSuccessfulDownloadAlertMessage();
    });
  };

  const onLabResultDownloadLinkClick = (labReportId) => {
    SampleService.getLabReportFile(labReportId).then(({ data }) => {
      downloadLink(data.url);
      createSuccessfulDownloadAlertMessage();
    });
  };

  const getImageName = (index) => {
    if (instrumentType === "water") {
      if (index === 0) {
        return "Regular Image";
      }

      if (index === 1) {
        return "UV Image";
      }
    }

    return `Image ${index + 1}`;
  };

  const cellRenderer = (rowData) => {
    const { onClick, name, url } = rowData;

    return (
      <>
        <a
          className="link"
          onClick={onClick}
        >
          {name}
        </a>

        {url ? (
          <a
            className={`buttonLink ${cx("previewLink")}`}
            onClick={() => setPreviewLink(url)}
          >
            (Preview)
          </a>
        ) : null}
      </>
    );
  };

  useEffect(() => {
    const newDownloadList = [];

    testImageUrls?.forEach((url, index) => {
      newDownloadList.push({
        name: getImageName(index),
        onClick: () => {
          downloadLink(url?.original);
          createSuccessfulDownloadAlertMessage();
        },
        url,
      });
    });

    if (sampleId) {
      newDownloadList.push({
        name: "Generate Violation Letter",
        onClick: () => onViolationLetterDownloadLinkClick(sampleId),
      });
    }

    if (pdfReportLink) {
      newDownloadList.push({
        name: "PDF",
        onClick: () => downloadLink(pdfReportLink),
      });
    }

    if (labReportId) {
      newDownloadList.push({
        name: "Lab Report",
        onClick: () => onLabResultDownloadLinkClick(labReportId),
      });
    }

    setDownloadableReports(newDownloadList);
  }, [sampleId, pdfReportLink, labReportId]);

  const headers = [
    {
      label: "Name",
      key: "name",
      width: 350,
      fixed: true,
      cellRenderer: cellRenderer,
    },
  ];

  return (
    <>
      <Panel title="Downloadable Reports">
        <div
          className={cx("downloadableReportContainer")}
          style={{
            height: ROW_HEIGHT * downloadableReports.length + HEADER_HEIGHT,
          }}
        >
          <AutoSizer>
            {({ width, height }) => (
              <LegacyDataTable
                width={width}
                height={height}
                headers={headers}
                list={downloadableReports}
              />
            )}
          </AutoSizer>
        </div>
      </Panel>

      {previewLink && (
        <PreviewModal
          smallImageUrl={previewLink.small}
          largeImageUrl={previewLink.original}
          onClose={() => setPreviewLink(undefined)}
        />
      )}
    </>
  );
};

DownloadableReportPanel.propTypes = {
  sampleId: PropTypes.string.isRequired,
  pdfReportLink: PropTypes.string,
  labReportId: PropTypes.string,
  testImageUrls: PropTypes.array,
  instrumentType: PropTypes.string,
};

export default DownloadableReportPanel;
