import FacilityService from "#components/Services/FacilityService";
import { AttributeDataType, AssetType } from "@validereinc/domain";
import { KeyValuePanel } from "@validereinc/common-components";
import {
  dateFormatter,
  datetimeFormatter,
  formatCoordinateDegrees,
} from "@validereinc/utilities";
import isNull from "lodash/isNull";
import * as PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import {
  linkToFacilities,
  linkToEquipmentDetail,
  linkToDeviceDetail,
} from "#routers/links";
import { FlowDetailRoute } from "#routes/organization/flows/[flowId]/detail";
import { NetworkDetailRoute } from "#routes/organization/networks/[networkId]/detail";
import { RoutingLink } from "#src/batteries-included-components/RoutingLink";

const OverviewPanel = ({ facilityDetail, isDisplayMap }) => {
  const [customAttributes, setCustomAttributes] = useState([]);

  useEffect(() => {
    (async () => {
      const {
        data: { data },
      } = await FacilityService.getCustomAttributes();

      setCustomAttributes(data);
    })();
  }, []);

  const data = [
    {
      title: "Location",
      value: formatCoordinateDegrees([
        facilityDetail?.latitude,
        facilityDetail?.longitude,
      ]),
    },
    ...customAttributes
      .filter(
        ({ entity_subtype }) =>
          !entity_subtype || facilityDetail?.type == entity_subtype
      )
      .map(({ display_name, field_name, data_type }) => {
        const value = facilityDetail?.custom_attributes?.[field_name];

        if (typeof value === "undefined" || isNull(value)) {
          return {
            title: display_name,
            value: "-",
          };
        }

        // IMPROVE: need to abstract display logic away for attributes in one
        // centralized place. This is currently repeated and fragmented in
        // several places, most notably, forms.
        switch (data_type) {
          case AttributeDataType.DATE_TIME:
            return {
              title: display_name,
              value: datetimeFormatter(new Date(value)),
            };
          case AttributeDataType.DATE:
            return {
              title: display_name,
              value: dateFormatter(new Date(value)),
            };
          case AttributeDataType.LOOKUP:
            switch (value?.entity_type) {
              case AssetType.FACILITY:
                return {
                  title: display_name,
                  value: (
                    <RoutingLink to={linkToFacilities(value?.id)}>
                      {value?.name}
                    </RoutingLink>
                  ),
                };
              case AssetType.EQUIPMENT:
                return {
                  title: display_name,
                  value: (
                    <RoutingLink to={linkToEquipmentDetail(value?.id)}>
                      {value?.name}
                    </RoutingLink>
                  ),
                };
              case AssetType.FLOW:
                return {
                  title: display_name,
                  value: (
                    <RoutingLink
                      to={FlowDetailRoute.toLink({
                        pathParams: { flowId: value?.id },
                      })}
                    >
                      {value?.name}
                    </RoutingLink>
                  ),
                };
              case AssetType.DEVICE:
                return {
                  title: display_name,
                  value: (
                    <RoutingLink to={linkToDeviceDetail(value?.id)}>
                      {value?.name}
                    </RoutingLink>
                  ),
                };
              case AssetType.ASSET_GROUP:
                return {
                  title: display_name,
                  value: (
                    <RoutingLink
                      to={NetworkDetailRoute.toLink({
                        pathParams: { networkId: value?.id },
                      })}
                    >
                      {value?.name}
                    </RoutingLink>
                  ),
                };
              default:
                return {
                  title: display_name,
                  valie: "-",
                };
            }

          case AttributeDataType.STRING:
          default: {
            return {
              title: display_name,
              value,
            };
          }
        }
      }),
  ];

  return (
    <KeyValuePanel
      panelMaxColumnCount={isDisplayMap ? 2 : 3}
      panelKeyValueListProps={{ maxRowCount: 6, variant: "shaded" }}
      panelProps={{ isFluidY: false }}
      data={data}
    />
  );
};

OverviewPanel.propTypes = {
  facilityDetail: PropTypes.object,
  isDisplayMap: PropTypes.bool,
};

export default OverviewPanel;
