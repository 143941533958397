import React from "react";
import * as PropTypes from "prop-types";
import FontAwesome from "react-fontawesome";
import { isValidString } from "../../../Instrument/InstrumentComponentHelper";

const DescriptionRow = (props) => (
  <tr>
    <td>
      <span className="hidden-xs">
        <FontAwesome
          className="fa-fw instrumentDescription__icon"
          name={props.icon}
        />
        {props.title + ":"}
      </span>

      <span className="visible-xs">{props.title + ":"}</span>
    </td>
    <td>
      <span>{isValidString(props.value, "Unavailable")}</span>
    </td>
  </tr>
);

DescriptionRow.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  value: PropTypes.string,
};

export default DescriptionRow;
