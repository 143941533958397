import ProgressBar from "#common/ProgressBar/ProgressBar";
import {
  CUBIC_METERS_VOLUME,
  getFormattedMeasurementValueWithUnit,
} from "#redux/reducers/measurements";
import config from "#src/config";
import { getTimeStringFromDate } from "#utils/timeFormatter";
import { Title } from "@validereinc/common-components";
import moment from "moment";
import * as PropTypes from "prop-types";
import React, { useMemo } from "react";
import { connect } from "react-redux";
import { getCapacityPercentage } from "../InventoryTableHelper";
import "./FilledVolume.scss";

const mapStateToProps = (state) => {
  return {
    getFormattedMeasurementValueWithUnit: (measurementKey, measurementValue) =>
      getFormattedMeasurementValueWithUnit(state.measurements)(
        measurementKey,
        measurementValue
      ),
  };
};

const FilledVolumeRow = ({ title, value }) => (
  <section className="filledVolumeRow">
    <span className="filledVolumeRow__title">{title}</span>

    <span className="filledVolumeRow__value">{value}</span>
  </section>
);

const FilledVolume = ({
  inventoryDetail,
  getFormattedMeasurementValueWithUnit,
}) => {
  const { capacity, volume } = inventoryDetail;

  const lastUpdatedAt = useMemo(() => {
    if (inventoryDetail) {
      const now = moment();
      return getTimeStringFromDate(now, config.DATE_FORMAT);
    }
    return "-";
  }, [inventoryDetail]);

  const capacityPercentage = getCapacityPercentage(
    volume?.value,
    capacity?.value
  );

  return (
    <div className="filledVolume">
      <Title
        type="subheader"
        className="filledVolume__subtitle"
      >
        Current Filled Volume
      </Title>

      <div className="filledVolume__percent">{`${capacityPercentage}%`}</div>

      <div className="filledVolume__time">{`Last Updated: ${lastUpdatedAt}`}</div>

      <ProgressBar
        value={capacityPercentage}
        min={0}
        max={100}
      />

      <div className="filledVolume__divider"></div>

      <FilledVolumeRow
        title="Current Volume"
        value={getFormattedMeasurementValueWithUnit(
          CUBIC_METERS_VOLUME,
          volume
        )}
      />

      <FilledVolumeRow
        title="Tank Capacity"
        value={getFormattedMeasurementValueWithUnit(
          CUBIC_METERS_VOLUME,
          capacity
        )}
      />
    </div>
  );
};

FilledVolume.propTypes = {
  inventoryDetail: PropTypes.object,
  getFormattedMeasurementValueWithUnit: PropTypes.func,
};

FilledVolumeRow.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string,
};

export default connect(mapStateToProps, null)(FilledVolume);
