import React, { useState } from "react";
import * as PropTypes from "prop-types";
import { Button } from "react-bootstrap";
import AnnotationImageView, {
  TUBE_VALUES,
} from "./AnnotationImageView/AnnotationImageView";
import AnnotationSteps from "./AnnotationSteps/AnnotationSteps";
import { STAGE_NAMES } from "./AnnotationSteps/useStages";
import "./Annotate.css";
import useAnnotationTools, {
  INITIAL_TOOL,
  INITIAL_LINE_COLOR,
} from "./Hooks/useAnnotationTools";
import MeasurementControlPanel from "./AnnotationSubmission/MeasurementControlPanel";
import {
  calculateAnnotation,
  getTraceVertices,
  findMeniscusTrace,
  APPROXIMATION_METHODS,
  getCriticalPoint,
  getApproximationMethodForMeasurementType,
} from "./Utils/AnnotationUtils";
import useMeasurementReducer, {
  MEASUREMENT_LABELS,
} from "./Hooks/useMeasurementReducer";
import ImageControls from "./AnnotationImageView/ImageControls";

const CTR_IMAGE_HEIGHT = 5496;
const CTR_IMAGE_WIDTH = 3672;

const SKETCH_HEIGHT = 687;

const Annotate = ({ testDetails }) => {
  const [leftTubeState, leftTubeActions] = useMeasurementReducer();
  const [rightTubeState, rightTubeActions] = useMeasurementReducer();

  const [approximationMethod, setApproximationMethod] = useState(
    APPROXIMATION_METHODS.AVERAGE
  );

  const [currentMeasurement, setCurrentMeasurement] = useState(null);
  const [isMeasuringRightTube, setIsMeasuringRightTube] = useState(null);

  const onMeasurementClick = (isMeasuringRightTube) => (measurementType) => {
    const approximationMethod =
      getApproximationMethodForMeasurementType(measurementType);
    setApproximationMethod(approximationMethod);
    setCurrentMeasurement(measurementType);
    setIsMeasuringRightTube(isMeasuringRightTube);
  };

  const onLeftMeasurementClick = onMeasurementClick(false);
  const onRightMeasurementClick = onMeasurementClick(true);

  const sketchHeight = SKETCH_HEIGHT;
  const imageScale = sketchHeight / CTR_IMAGE_HEIGHT;
  const sketchWidth = imageScale * CTR_IMAGE_WIDTH;

  const {
    imageProperties,
    setValue,
    selectTool,
    addCriticalPointLine,
    setPan,
    setZoom,
    resetView,
    resetHistory,
    undo,
    canUndo,
  } = useAnnotationTools(imageScale, sketchWidth);

  const resetCurrentAnnotation = () => {
    setCurrentMeasurement(null);
    selectTool(INITIAL_TOOL, INITIAL_LINE_COLOR);
    resetView();
    setValue({});
    resetHistory();
  };

  const onStageComplete = (prevStageName) => {
    switch (prevStageName) {
      case STAGE_NAMES.DRAW_LINE: {
        const meniscusObject = findMeniscusTrace(imageProperties.value);
        const points = getTraceVertices(meniscusObject, imageScale);
        const criticalPoint = getCriticalPoint(points, approximationMethod);

        addCriticalPointLine(criticalPoint);
        break;
      }
    }
    resetHistory();
  };

  const onComplete = (lowerTickValue, upperTickValue) => {
    const { meniscus, upperTick, lowerTick } = calculateAnnotation(
      imageProperties.value,
      imageScale,
      approximationMethod,
      lowerTickValue,
      upperTickValue
    );

    const currentTubeActions = isMeasuringRightTube
      ? rightTubeActions
      : leftTubeActions;

    currentTubeActions.setMeniscusValue(currentMeasurement, meniscus);
    currentTubeActions.setUpperTickValue(currentMeasurement, upperTick);
    currentTubeActions.setLowerTickValue(currentMeasurement, lowerTick);

    resetCurrentAnnotation();
  };

  let activeTube = TUBE_VALUES.NONE;
  if (currentMeasurement) {
    activeTube = isMeasuringRightTube ? TUBE_VALUES.RIGHT : TUBE_VALUES.LEFT;
  }

  return (
    <div>
      {currentMeasurement ? (
        <AnnotationSteps
          selectedTool={imageProperties.tool}
          onStageComplete={onStageComplete}
          onComplete={onComplete}
          onToolSelect={selectTool}
          approximationMethod={approximationMethod}
          setApproximationMethod={setApproximationMethod}
          value={imageProperties.value}
          undo={undo}
          canUndo={canUndo}
        />
      ) : (
        <MeasurementControlPanel
          testId={testDetails.test_id}
          onLeftMeasurementClick={onLeftMeasurementClick}
          onRightMeasurementClick={onRightMeasurementClick}
          leftTubeState={leftTubeState}
          rightTubeState={rightTubeState}
          leftTubeActions={leftTubeActions}
          rightTubeActions={rightTubeActions}
        />
      )}
      <AnnotationImageView
        regularImageUrl={testDetails.test_image_urls.regular.original}
        uvImageUrl={testDetails.test_image_urls.uv.original}
        imageProperties={imageProperties}
        setValue={setValue}
        sketchHeight={sketchHeight}
        sketchWidth={sketchWidth}
        imageScale={imageScale}
        setPan={setPan}
        setZoom={setZoom}
        activeTube={activeTube}
      >
        <ImageControls
          imageProperties={imageProperties}
          selectTool={selectTool}
          resetView={resetView}
          undo={undo}
          canUndo={canUndo}
        >
          {currentMeasurement && (
            <header style={{ textAlign: "center", fontSize: "1.5em" }}>
              <div>
                Measuring{" "}
                <span style={{ fontWeight: "bold" }}>
                  {MEASUREMENT_LABELS[currentMeasurement]}
                </span>{" "}
                on the{" "}
                <span style={{ fontWeight: "bold" }}>
                  {isMeasuringRightTube ? "Right" : "Left"}
                </span>{" "}
                tube
              </div>
              <div>
                <Button
                  bsSize="small"
                  onClick={resetCurrentAnnotation}
                >
                  Go Back
                </Button>
              </div>
            </header>
          )}
        </ImageControls>
      </AnnotationImageView>
    </div>
  );
};

Annotate.propTypes = {
  testDetails: PropTypes.object.isRequired,
};

export default Annotate;
