import {
  linkToAlertConfiguration,
  linkToAlerts,
  linkToBalances,
  linkToChainOfCustody,
  linkToCreateFormSubmission,
  linkToDashboards,
  linkToDataMapping,
  linkToDeviceDetail,
  linkToEmissionReports,
  linkToEquipmentDetail,
  linkToEquipmentList,
  linkToFacilities,
  linkToFormSubmissionDetail,
  linkToFormSubmissionList,
  linkToInstruments,
  linkToInventories,
  linkToManageWorkflows,
  linkToManualEntry,
  linkToProductionReports,
  linkToProductionStreams,
  linkToRecordOfVolume,
  linkToSamples,
  linkToTests,
} from "./links";

export const DASHBOARDS_BREADCRUMB = {
  title: "Dashboards",
  path: linkToDashboards,
};

export const CUSTOM_DASHBOARDS_BREADCRUMB = {
  title: "Custom Dashboards",
  path: linkToDashboards,
};

export const DATA_MANAGEMENT_BREADCRUMB = { title: "Data Management" };

export const DATA_MAPPING_BREADCRUMB = {
  title: "Data Mapping",
  path: linkToDataMapping,
};

export const DATA_MAPPING_DETAIL_BREADCRUMB = {
  title: "Detail",
};

export const DATA_MAPPING_UPLOAD_DETAIL_BREADCRUMB = {
  title: "Detail",
};

export const BALANCES_BREADCRUMB = { title: "Balances", path: linkToBalances };
export const BALANCES_ANALYZE_BREADCRUMB = {
  title: "Analyze:id",
  path: linkToBalances,
};
export const EDIT_BALANCES_BREADCRUMB = { title: "Edit" };
export const CREATE_BALANCES_BREADCRUMB = { title: "Create" };

export const ROV_BREADCRUMB = {
  title: "Records of Volume",
  path: linkToRecordOfVolume,
};
export const ROV_ADJUSTMENT_FACTORS_BREADCRUMB = {
  title: "Adjustment Factors",
};

export const WORKFLOWS_BREADCRUMB = {
  title: "Workflows",
  path: linkToManageWorkflows,
};
export const CREATE_WORKFLOWS_BREADCRUMB = { title: "Create" };
export const EDIT_WORKFLOWS_BREADCRUMB = { title: "Edit" };

export const INSTRUMENTS_BREADCRUMB = {
  title: "Instruments",
  path: linkToInstruments,
};
export const INSTRUMENTS_DETAIL_BREADCRUMB = { title: "Detail" };

export const INVENTORIES_BREADCRUMB = {
  title: "Inventories",
  path: linkToInventories,
};
export const INVENTORY_DETAIL_BREADCRUMB = { title: "Detail" };

export const CHAIN_OF_CUSTODY_BREADCRUMB = {
  title: "Chain Of Custody",
  path: linkToChainOfCustody,
};
export const CREATE_CHAIN_OF_CUSTODY_BREADCRUMB = { title: "Create" };

export const MANUAL_ENTRY_BREADCRUMB = {
  title: "Manual Entry",
  path: linkToManualEntry,
};

export const CREATE_MANUAL_ENTRY_BREADCRUMB = {
  title: "Create",
};

export const PRODUCTION_BREADCRUMB = {
  title: "Production",
};

export const STREAMS_BREADCRUMB = {
  title: "Streams",
  path: linkToProductionStreams,
};

export const PRODUCTION_REPORTS_BREADCRUMB = {
  title: "Production Reports",
  path: linkToProductionReports,
};

export const CREATE_PRODUCTION_REPORTS_BREADCRUMB = { title: "Create" };
export const EDIT_PRODUCTION_REPORTS_BREADCRUMB = { title: "Edit" };

export const EDIT_RECORDS_BREADCRUMB = { title: "Edit Record" };

export const EMISSION_REPORTS_BREADCRUMB = {
  title: "Reports",
  path: linkToEmissionReports,
};

export const EMISSION_REPORTS_DETAIL_BREADCRUMB = {
  title: "Detail:id",
  path: linkToEmissionReports,
};

export const CREATE_EMISSION_REPORTS_BREADCRUMB = { title: "Create" };
export const EDIT_EMISSION_REPORTS_BREADCRUMB = { title: "Edit" };

export const MEASUREMENTS_BREADCRUMB = {
  title: "Measurements",
};

export const TESTS_BREADCRUMB = {
  title: "Tests",
  path: linkToTests,
};

export const TEST_DETAIL_BREADCRUMB = {
  title: "Detail",
};

export const SAMPLES_BREADCRUMB = {
  title: "Samples",
  path: linkToSamples,
};

export const SAMPLE_DETAIL_BREADCRUMB = {
  title: "Detail",
};

export const ORGANIZATION_BREADCRUMB = {
  title: "Organization",
};

export const FACILITIES_BREADCRUMB = {
  title: "facility_plural",
  path: linkToFacilities,
};

export const CREATE_FACILITIES_BREADCRUMB = {
  title: "Create Facility",
};

export const FACILITY_DETAIL_BREADCRUMB = {
  title: "Facility Detail:id",
  path: linkToFacilities,
};

export const EQUIPMENT_DETAIL_BREADCRUMB = {
  title: "Equipment Detail:equipmentId",
  path: linkToEquipmentDetail,
};

export const EQUIPMENT_LIST_BREADCRUMB = {
  title: "equipment_plural",
  path: linkToEquipmentList,
};

export const ADD_EQUIPMENT_BREADCRUMB = {
  title: "Add Equipment",
};

export const ADD_DEVICE_BREADCRUMB = {
  title: "Add Device",
};

export const ALERT_MANAGEMENT_BREADCRUMB = {
  title: "Alert Management",
};

export const ALERT_CONFIGURATION_BREADCRUMB = {
  title: "Alert Configuration",
  path: linkToAlertConfiguration,
};

export const CREATE_ALERT_CONFIGURATION_BREADCRUMB = {
  title: "Create Configuration",
};

export const ALERTS_BREADCRUMB = {
  title: "Alerts",
  path: linkToAlerts,
};

export const ALERT_BREADCRUMB = {
  title: "Alert Detail",
};

export const FORM_SCHEMA_LIST_BREADCRUMB = {
  title: "Form Templates",
};

export const FORM_SUBMISSION_CREATE_BREADCRUMB = {
  title: "Create Form Submission",
};

export const FORM_SUBMISSION_LIST_BREADCRUMB = {
  title: "Form Submissions",
  path: linkToFormSubmissionList,
};

export const CREATE_FORM_SUBMISSION_BREADCRUMB = {
  title: "Create Form Submission",
  path: linkToCreateFormSubmission,
};

export const FORM_SUBMISSION_DETAIL_BREADCRUMB = {
  title: "Form Submission Detail:formSubmissionId",
  path: linkToFormSubmissionDetail,
};

export const ANALYZE_BREADCRUMB = {
  title: "Analyze",
};

export const ANALYZE_DETAIL_BREADCRUMB = {
  title: "Detail",
};

export const WORKFLOW_TASKS_BREADCRUMB = {
  title: "Tasks",
};

export const MANAGE_WORKFLOW_BREADCRUMB = {
  title: "Manage Workflows",
  path: linkToManageWorkflows,
};

/** @deprecated */
export const CALCULATIONS_BREADCRUMB = {
  title: "Calculations",
  path: () => "/app/emissions/calculations",
};

export const IMPORTS_BREADCRUMB = {
  title: "Imports",
};

export const LAB_RESULT_BREADCRUMB = {
  title: "Lab Results",
};

export const RECORDS_BREADCRUMB = {
  title: "Records",
};

export const RECORD_OF_QUALITY_BREADCRUMB = {
  title: "Record of Quality",
};

export const ROV_BREADCRUMB_DETAIL = {
  title: "Detail",
};

export const EMISSION_BREADCRUMB = {
  title: "Emissions",
};

export const EMISSION_RECORDS_BREADCRUMB = {
  title: "Records",
};

export const INSIGHTS_BREADCRUMB = {
  title: "Insights",
};

export const INSIGHTS_LIST_BREADCRUMB = {
  title: "List",
};

export const APPLY_DEFAULT_BREADCRUMB = {
  title: "Apply Defaults",
};

export const CREATE_DATA_MAPPING_BREADCRUMB = {
  title: "Create",
};

export const UPLOAD_DATA_MAPPING_BREADCRUMB = {
  title: "Upload",
};

export const DEVICE_DETAIL_BREADCRUMB = {
  title: "Device:deviceId",
  path: linkToDeviceDetail,
};
