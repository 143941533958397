import { restAPI } from "./api";

/**
 * The feature flags config (subset of all possible feature
 * flags and if they're on or not)
 * @deprecated reflects old monolith authorization system (Elixir API, OpsHub)
 * that is currently in use in legacy parts of the app
 */
export type LegacyFeatureFlagsType = Record<string, boolean>;

export const LegacyFeatureFlagsAdapter = {
  getList: () =>
    restAPI.elixirAPI.GET<{ features: LegacyFeatureFlagsType }>({
      endpoint: `/@feature_flags`,
    }),
};
