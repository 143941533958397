import {
  SavedFilterTag,
  useSavedFilters,
} from "#hooks/FilterPanel/useSavedFilters";
import { useFlowFilters } from "#hooks/filters/useFlowFilters";
import { DEFAULT_DATE_RANGES } from "#src/hooks/useDateRange";
import {
  FilterPanel,
  StorageKeys,
  useFilters,
} from "@validereinc/common-components";
import { AssetType, FlowDomain } from "@validereinc/domain";
import React from "react";

export const FlowsFilterPanel = ({
  filterConfigStorageKey,
  isWithCustomAttributes = false,
  isWithPeriod = false,
}: {
  isWithCustomAttributes?: boolean;
  isWithPeriod?: boolean;
} & Pick<StorageKeys, "filterConfigStorageKey">) => {
  const filters = useFlowFilters({ isWithCustomAttributes, isWithPeriod });

  const [_, setFilters] = useFilters(filterConfigStorageKey);

  const defaultValue = isWithPeriod
    ? {
        period: DEFAULT_DATE_RANGES.currentWholeMonth,
      }
    : {};

  const { filterProps } = useSavedFilters({
    queryKey: [AssetType.FLOW],
    savedFilterResourceAdapter: FlowDomain.savedFilters,
    setFilters,
    filterKeyBlacklist: ["period.from", "period.to"],
    tag: SavedFilterTag.LIST,
  });

  return (
    <FilterPanel
      {...filterProps}
      storageKey={filterConfigStorageKey}
      defaultValue={defaultValue}
      defaultActiveKeys={["Filters"]}
      filters={filters}
    />
  );
};
