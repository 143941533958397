import { useDataMapping } from "#components/hooks/useDataMapping";
import DataMappingService from "#components/Services/DataMappingService";
import { getBreadcrumbsObject } from "#routers/breadcrumbsHelper";
import { useNavigate } from "#routers/hooks";
import {
  linkToDataMapping,
  linkToUploadDataMappingValidation,
} from "#routers/links";
import {
  Button,
  FileInput,
  Form,
  Page,
  Panel,
  Stepper,
  useForm,
} from "@validereinc/common-components";
import classNames from "classnames/bind";
import * as PropTypes from "prop-types";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import {
  UPLOAD_DATA_LABEL,
  UPLOAD_STEPS,
  UPLOAD_TITLE,
} from "../DataMappingConstants";
import styles from "./UploadDataMapping.module.scss";

const cx = classNames.bind(styles);

const CURRENT_STEP = 1;

const FileUploadForm = ({ breadcrumbs }) => {
  const { id } = useParams();
  const [dataMappingDetail, fetchState] = useDataMapping(id);
  const [formState, setFormState] = useState("loaded");

  const form = useForm({ defaultValues: { dataMappingId: id } });

  const navigate = useNavigate();

  const handleOnNextClick = async (formValues) => {
    setFormState("loading");

    const {
      data: { task_id: taskId },
    } = await DataMappingService.uploadCreateDataMappingFile(formValues);

    DataMappingService.planTask({ taskId });

    navigate({
      pathname: linkToUploadDataMappingValidation(id),
      query: {
        taskId,
      },
    });
  };

  const handleOnCancelClick = () => {
    navigate({ pathname: linkToDataMapping() });
  };

  const actionRow = (
    <Stepper
      steps={UPLOAD_STEPS}
      activeStep={CURRENT_STEP}
    />
  );

  const footer = (
    <div className={cx("footer")}>
      <Button onClick={handleOnCancelClick}>Cancel</Button>
      <div className={cx("stepActionContainer")}>
        <Button
          variant="primary"
          onClick={form.handleSubmit(handleOnNextClick)}
          isLoading={formState === "loading" || fetchState === "loading"}
          disabled={formState !== "loaded" || fetchState !== "loaded"}
        >
          Next
        </Button>
      </div>
    </div>
  );

  const uploadDataMappingBreadcrumbs = [
    ...breadcrumbs,
    {
      title: dataMappingDetail?.mapping_name ?? "-",
      path: linkToDataMapping(),
    },
    { title: "Upload" },
  ];

  return (
    <Page
      title={UPLOAD_TITLE}
      breadcrumbs={getBreadcrumbsObject(uploadDataMappingBreadcrumbs)}
      actionRow={actionRow}
      footer={footer}
      footerAlign="left"
    >
      <Panel title={UPLOAD_DATA_LABEL}>
        <div style={{ minHeight: "60vh" }}>
          <dl className={cx("dataMappingDetailContainer")}>
            <dd>Mapping to Upload To</dd>
            <dt>{dataMappingDetail?.mapping_name ?? "-"}</dt>
            <dd>Mapping Category</dd>
            <dt>{dataMappingDetail?.mapping_entity ?? "-"}</dt>
            <dd>Update Strategy</dd>
            <dt>{dataMappingDetail?.mapping_strategy ?? "-"}</dt>
          </dl>

          <Form {...form}>
            <FileInput
              name="file"
              label="File Selection"
              placeholder="Drag a .csv or .xls file here to update records for this connection (Max. 20MB)"
              showIcon={true}
              isRequired={true}
              isLoading={formState === "loading" || fetchState === "loading"}
              isDisabled={
                formState !== "loaded" || !id || fetchState !== "loaded"
              }
            />
          </Form>
        </div>
      </Panel>
    </Page>
  );
};

FileUploadForm.propTypes = {
  breadcrumbs: PropTypes.array.isRequired,
};

export default FileUploadForm;
