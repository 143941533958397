import { Page, Panel } from "@validereinc/common-components";
import filter from "lodash/filter";
import * as PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import InventoryService from "../Services/InventoryService";
import "./Inventory.scss";
import InventoryTable from "./InventoryTable";

export const useFetchInventories = () => {
  const [inventories, setInventories] = useState([]);
  const [state, setState] = useState("loading");

  useEffect(() => {
    setState("loading");

    InventoryService.getInventories()
      .then(({ data }) => {
        // All valid inventory require active stream
        const validInventories = filter(
          data,
          (inventory) => !!inventory.active_stream_id
        );
        setInventories(validInventories);
        setState("loaded");
      })
      .catch(() => setState("error"));
  }, []);

  return [inventories, state];
};

const Inventory = ({ height, breadcrumbs }) => {
  const [inventories, loadingState] = useFetchInventories();

  return (
    <Page
      title="Tank List"
      breadcrumbs={breadcrumbs}
    >
      <Panel
        className="inventory"
        style={{ height: height }}
      >
        <InventoryTable
          inventories={inventories}
          loadingState={loadingState}
        />
      </Panel>
    </Page>
  );
};

Inventory.propTypes = {
  height: PropTypes.number.isRequired,
  breadcrumbs: PropTypes.array.isRequired,
};

export default Inventory;
