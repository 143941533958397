import * as React from "react";
import { useRef, useState, useEffect } from "react";
import { Map, Source, Layer, NavigationControl } from "react-map-gl";
// utils
import {
  findNearestPoint,
  managePopup,
  clusterLayer,
  clusterCountLayer,
  unclusteredCircleLayer,
  unclusteredPointLayer,
} from "./map-cluster-util";
import { useSearchParams } from "#routers/hooks";
import {
  INIT_MASS_POLLUTANT,
  MASS_POLLUTANTS,
  NO_FACILITY_NEARBY,
} from "#src/constants";
// types
import type { MapRef, GeoJSONSource } from "react-map-gl";
import type {
  MapClusterFeatureCollectionType,
  MapClusterFeaturesType,
  MapFacilityNodeDataType,
  MapFacilityNodeDataCollectionType,
  MapClusterPropsType,
} from "./types";

export const MapCluster = ({
  data,
  onMapItemClick,
  mouseEnterCallback,
  isUnclusteredNodesVisible,
  initialViewState,
}: MapClusterPropsType) => {
  const mapRef = useRef<MapRef>(null);

  const [searchParams] = useSearchParams();
  const [isListeningToMapEvents, setIsListeningToMapEvents] = useState(false);

  const listenToMapMouseEvents = () => {
    if (mapRef.current && !isListeningToMapEvents) {
      mapRef.current.getMap().on("click", handleMapClick);
      const map = mapRef.current.getMap();
      const layerId = "unclustered-circle";

      managePopup(map, layerId, mouseEnterCallback);

      setIsListeningToMapEvents(true);
    }
  };

  useEffect(() => {
    if (mapRef?.current && isUnclusteredNodesVisible) {
      const map = mapRef.current.getMap();
      map.setPaintProperty("unclustered-circle", "circle-opacity", 1);
      map.setPaintProperty("unclustered-point", "text-opacity", 1);
    }
  }, [isUnclusteredNodesVisible]);

  useEffect(() => {
    setTimeout(() => {
      // needs to be delayed to make sure the map is loaded
      listenToMapMouseEvents();
    }, 1000);
  }, []);

  const handleMapClick = async (event) => {
    mapRef.current
      ?.getMap()
      .flyTo({ center: [event.lngLat.lng, event.lngLat.lat] });

    if (!event?.features) {
      const selectedPoint = {
        latitude: event.lngLat.lat,
        longitude: event.lngLat.lng,
      };

      const nearestFacility = findNearestPoint(selectedPoint, data);
      const id = nearestFacility?.id ?? "";

      const { pollutant } = searchParams;
      const selectedPollutant = pollutant || INIT_MASS_POLLUTANT.dataKey;

      const facilityData: MapFacilityNodeDataType = {
        id: nearestFacility?.id,
        name: nearestFacility?.name ?? NO_FACILITY_NEARBY,
        description: nearestFacility?.industry_segment?.name ?? "",
        longitude: event.lngLat.lng,
        latitude: event.lngLat.lat,
        url: `/app/organization/facilities/${id}/detail`,
        label:
          MASS_POLLUTANTS.find((pol) => pol.dataKey === selectedPollutant)
            ?.label || INIT_MASS_POLLUTANT.label,
      };

      if (facilityData.name === NO_FACILITY_NEARBY) {
        return null;
      }

      return onMapItemClick(facilityData);
    }

    const feature =
      event?.features && event?.features.length > 0 ? event.features[0] : null;
    if (!feature) {
      return;
    }
    const clusterId = feature.properties.cluster_id;

    const mapboxSource = mapRef.current.getSource(
      "facilities"
    ) as GeoJSONSource;

    mapboxSource.getClusterExpansionZoom(clusterId, (err, zoom) => {
      if (err) {
        return;
      }

      mapRef.current.easeTo({
        center: feature.geometry.coordinates,
        zoom,
        duration: 500,
      });
    });
  };

  const validData: MapFacilityNodeDataCollectionType = data.filter(
    (facility) => facility.latitude && facility.longitude
  );

  const features: MapClusterFeaturesType = validData.map((facility) => ({
    type: "Feature",
    properties: {
      cluster: false,
      id: facility.id,
      name: facility.name,
      description: facility?.industry_segment?.name ?? "",
    },
    geometry: {
      type: "Point",
      coordinates: [
        parseFloat(facility.longitude),
        parseFloat(facility.latitude),
        0.0,
      ],
    },
  }));

  const finalData: MapClusterFeatureCollectionType = {
    type: "FeatureCollection",
    crs: {
      type: "name",
      properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" },
    },
    features: features,
  };

  return (
    <Map
      id="clusterMap"
      initialViewState={initialViewState}
      mapStyle="mapbox://styles/mapbox/light-v11"
      mapboxAccessToken="pk.eyJ1IjoidmFsaWRlcmVtYXBib3giLCJhIjoiY2xkYW94bGdjMDRhcjNwcXIzN3RmbDQzYiJ9.uyyp1hejxJZ983LU5zfxmQ"
      interactiveLayerIds={[clusterLayer.id]}
      onClick={handleMapClick}
      ref={mapRef}
    >
      <NavigationControl showCompass={false} />
      <Source
        id="facilities"
        type="geojson"
        data={finalData}
        cluster={true}
        clusterMaxZoom={14}
        clusterRadius={50}
      >
        <Layer {...clusterLayer} />
        <Layer {...clusterCountLayer} />
        <Layer {...unclusteredCircleLayer} />
        <Layer {...unclusteredPointLayer}></Layer>
      </Source>
    </Map>
  );
};
