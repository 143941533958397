import {
  Accordion,
  Button,
  CheckboxInput,
  Drawer,
  DropdownInput,
  Form,
  Info,
  Switch,
  TextAreaInput,
  TextInput,
  useForm,
  useFormContext,
} from "@validereinc/common-components";
import get from "lodash/get";
import React, { useState } from "react";
import { v4 as uuid } from "uuid";
import { LookupQuestionFilters } from "./LookupQuestionFilters";
import { MeasurementFields } from "./MeasurementFields";
import { QuestionConditions } from "./QuestionConditions";
import { QuestionFields } from "./QuestionFields";
import type { DrawerProps } from "@validereinc/common-components";
import classNames from "classnames/bind";
import styles from "./CreateFormTemplateAddQuestionDrawer.module.scss";

const cx = classNames.bind(styles);

const IS_REQUIRED_TOOLTIP_CONTENT =
  "The user will not be able to submit the form without inputting a response to this question.";

const { AccordionPanel } = Accordion;

export const CreateFormTemplateAddQuestionDrawer = ({
  isOpen,
  onClose,
  onSave: onSaveCallback,
}: Pick<DrawerProps, "isOpen" | "onClose"> & {
  onSave: (newQuestionId: string) => void;
}) => {
  const parentForm = useFormContext();

  const questions = parentForm.watch("config.questions");

  const [isConditional, setIsConditional] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);

  const form = useForm({
    defaultValues: { prompt: "", description: "", is_required: true },
  });

  const values = form.watch();

  const questionsArray = Object.entries(questions ?? {}).map(
    ([questionId, question]) => ({ question_id: questionId, ...question })
  );

  const deviceQuestions = questionsArray?.filter(
    ({ type, data_type }) => type === "question" && data_type === "device"
  );

  const onSave = form.handleSubmit((newValues) => {
    const newQuestionId = uuid();

    const currentQuestions = parentForm.getValues("config.questions");

    const { conditions, ...restQuestion } = newValues;

    parentForm.setValue("config.questions", {
      ...currentQuestions,
      [newQuestionId]: {
        ...restQuestion,
        conditions: conditions?.reduce(
          (total, { operation, comparator, question }) => {
            return {
              ...total,
              [question]:
                operation === "$exists"
                  ? true
                  : {
                      ...(total?.[question] ?? {}),
                      [operation]: comparator,
                    },
            };
          },
          {}
        ),
      },
    });

    setIsConditional(false);
    setIsFiltered(false);

    onSaveCallback(newQuestionId);

    onClose();

    form.reset();
  });

  const onDelete = () => {
    onClose();
  };

  const onChangeIsConditional = (newIsConditional: boolean) => {
    if (newIsConditional) {
      form.setValue("conditions", [
        { question: "", operation: "$exists", value: "", type: "$exists" },
      ]);
    } else {
      form.unregister("conditions");
    }

    setIsConditional(newIsConditional);
  };

  const onChangeIsFiltered = (newIsFiltered: boolean) => {
    if (newIsFiltered) {
      form.setValue("filter", [{}]);
    } else {
      form.unregister("filter");
    }

    setIsFiltered(newIsFiltered);
  };

  return (
    <Drawer
      title="Configure Question"
      isOpen={isOpen}
      onClose={onClose}
      actionRow={[
        <Button
          key="delete-question-button"
          onClick={onDelete}
        >
          Delete
        </Button>,
        <Button
          key="save-question-button"
          onClick={onSave}
          variant="primary"
        >
          Save
        </Button>,
      ]}
    >
      <Form {...form}>
        <Accordion defaultActiveKeys={["question-overview"]}>
          <AccordionPanel
            dataKey="question-overview"
            title="Question Overview"
            isError={[
              "prompt",
              "description",
              "type",
              "data_type",
              "measurement_type",
              "measurement_unit",
              "source_lookup_question_id",
              "subject_lookup_question_id",
            ].some((key) => get(form.formState.errors, key))}
          >
            <>
              <TextInput
                label="Prompt"
                name="prompt"
                isRequired
                validate={{
                  maxLength: (prompt) =>
                    prompt.length < 100 ||
                    "Prompt must be less than 100 characters.",
                }}
              />

              <TextAreaInput
                label="Description"
                name="description"
                validate={{
                  maxLength: (description) =>
                    description.length < 1024 ||
                    "Description must be less than 1024 characters.",
                }}
              />

              <div className={cx("checkbox-container")}>
                <CheckboxInput
                  name="is_required"
                  label="Question is required"
                  isLabelShown={false}
                  className={cx("checkbox")}
                />
                <Info content={IS_REQUIRED_TOOLTIP_CONTENT} />
              </div>

              <DropdownInput
                label="Question Type"
                name="type"
                options={[
                  { label: "Question", value: "question" },
                  { label: "Measurement", value: "measurement" },
                ]}
                labelKey="label"
                valueKey="value"
                isRequired
              />

              {values.type === "question" ? <QuestionFields /> : null}

              {values.type === "measurement" ? (
                <MeasurementFields deviceQuestions={deviceQuestions} />
              ) : null}

              {values?.data_type === "lookup" && values?.lookup_entity_type ? (
                <Switch
                  name="isFiltered"
                  label="Filter Lookup Options?"
                  value={isFiltered}
                  onChange={onChangeIsFiltered}
                />
              ) : null}

              {questionsArray?.length ? (
                <Switch
                  name="isConditional"
                  label="Define question conditionals"
                  value={isConditional}
                  onChange={onChangeIsConditional}
                />
              ) : null}
            </>
          </AccordionPanel>

          {isFiltered ? (
            <AccordionPanel
              dataKey="question-filters"
              title="Filter Options"
              isError={get(form.formState.errors, "filter")}
            >
              <LookupQuestionFilters questions={questionsArray} />
            </AccordionPanel>
          ) : null}

          {isConditional ? (
            <AccordionPanel
              dataKey="question-conditions"
              title="Conditionality"
              isError={get(form.formState.errors, "conditions")}
            >
              <QuestionConditions questions={questionsArray} />
            </AccordionPanel>
          ) : null}
        </Accordion>
      </Form>
    </Drawer>
  );
};
