export * from "./FacilityRecordValueSourcePage";

export const FACILITY_RECORD_VALUE_SOURCE_PAGE_TITLE = "Add Source";

export const linkToAddFacilityRecordValueSource = (
  facilityId = ":facilityId",
  recordId = ":recordId",
  measurementType = ":measurementType"
) =>
  `/app/organization/facilities/${facilityId}/record/${recordId}/value/${measurementType}/configure`;

export const FACILITY_RECORD_VALUE_SOURCE_BREADCRUMB = {
  title: `${FACILITY_RECORD_VALUE_SOURCE_PAGE_TITLE}:flowId:recordId:measurementType`,
  path: linkToAddFacilityRecordValueSource(),
};
