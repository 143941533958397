import { styles } from "@validereinc/common-components";
import filter from "lodash/filter";
import React, { Component } from "react";
import { Col, ProgressBar, Row } from "react-bootstrap";
import "./StatusBar.css";

/* eslint-disable react/prop-types */

const lodash = { filter };

const StatusTitle = (props) => (
  <div className="statusTitle">
    <div className="statusTitle__title">{props.title}</div>
    <div
      className="statusTitle__text"
      style={{ color: props.textColor }}
    >
      {`${props.value ? props.value : 0} ${props.unit}`}
    </div>
  </div>
);

class StatusBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      workflowTaskStatus: {},
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.workflowTasks !== this.props.workflowTasks) {
      this.setState({
        workflowTaskStatus: this.getWorkflowInstancesStatus(
          this.props.workflowTasks
        ),
      });
    }
  }

  getWorkflowInstancesStatus(workflowTasks) {
    const workflowTaskStatus = {
      completed: 0,
      pending: 0,
      sampled: 0,
      completed_percent: 0,
      pending_percent: 0,
    };

    if (workflowTasks.length > 0) {
      workflowTaskStatus.completed = this.filterWorkflowtasksByStatus(
        workflowTasks,
        "completed"
      );
      workflowTaskStatus.pending = this.filterWorkflowtasksByStatus(
        workflowTasks,
        "pending"
      );
      workflowTaskStatus.sampled = this.filterWorkflowtasksByStatus(
        workflowTasks,
        "sampled"
      );

      // Excluding scheduled and missed task
      const totalActiveTask =
        workflowTaskStatus.completed +
        workflowTaskStatus.pending +
        workflowTaskStatus.sampled;

      workflowTaskStatus.completed_percent = this.calculatePercent(
        workflowTaskStatus.completed,
        totalActiveTask
      );
      workflowTaskStatus.pending_percent = this.calculatePercent(
        workflowTaskStatus.pending,
        totalActiveTask
      );
      workflowTaskStatus.sampled_percent = this.calculatePercent(
        workflowTaskStatus.sampled,
        totalActiveTask
      );
    }

    return workflowTaskStatus;
  }

  filterWorkflowtasksByStatus(tasks, taskType) {
    return lodash.filter(tasks, { state: taskType }).length;
  }

  calculatePercent(value, total) {
    const percent = (value / total) * 100;
    return percent ? Math.floor(percent) : 0;
  }

  render() {
    const { workflowTaskStatus } = this.state;

    return (
      <div>
        <Row className="hidden-xs">
          <Col sm={2}>
            <StatusTitle
              title="Completed Percent"
              value={workflowTaskStatus.completed_percent}
              unit="%"
              textColor="#43a047"
            />
          </Col>
          <Col sm={2}>
            <StatusTitle
              title="Completed"
              value={workflowTaskStatus.completed}
              unit="Tasks"
              textColor="#43a047"
            />
          </Col>
          <Col sm={2}>
            <StatusTitle
              title="Sampled"
              value={workflowTaskStatus.sampled}
              unit="Tasks"
              textColor={styles.status.default}
            />
          </Col>
          <Col sm={2}>
            <StatusTitle
              title="Pending"
              value={workflowTaskStatus.pending}
              unit="Tasks"
              textColor={styles.status.warning}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <ProgressBar className="workflowFilter__progressBarContainer">
              <ProgressBar
                bsStyle="success"
                now={workflowTaskStatus.completed_percent}
                key={1}
                striped
              />
              <ProgressBar
                bsStyle="warning"
                now={workflowTaskStatus.pending_percent}
                key={2}
                striped
              />
              <ProgressBar
                now={workflowTaskStatus.sampled_percent}
                key={3}
                striped
              />
            </ProgressBar>
          </Col>
        </Row>
      </div>
    );
  }
}

export default StatusBar;
