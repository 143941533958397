import { Icon } from "@validereinc/common-components";
import classNames from "classnames/bind";
import React, { forwardRef } from "react";
import styles from "./AccountChip.module.css";

export type AccountChipProps = {
  isPresentational?: boolean;
  name?: string;
};

const cx = classNames.bind(styles);

export const AccountChip = forwardRef<
  HTMLDivElement & HTMLButtonElement,
  AccountChipProps
>(({ name, isPresentational = false, ...restProps }: AccountChipProps, ref) => {
  const getFirstLetters = (name?: string) => {
    if (!name) {
      return;
    }

    // Split the name into an array of names by space
    const namesArray = name.split(" ");

    // If the name contains only one name
    if (namesArray.length === 1) {
      return namesArray[0].charAt(0).toUpperCase();
    }
    // If the name contains more than one name, just take the first two names
    else if (namesArray.length >= 2) {
      return (
        namesArray[0].charAt(0).toUpperCase() +
        namesArray[1].charAt(0).toUpperCase()
      );
    }

    // If the name is empty or invalid, return nothing
    return;
  };

  const firstLettersOfName = getFirstLetters(name);
  const isNameConfigured = Boolean(name && firstLettersOfName);

  if (isPresentational) {
    return (
      <div
        ref={ref}
        className={cx("chip")}
        aria-label={name}
        {...restProps}
      >
        {isNameConfigured ? firstLettersOfName : <Icon variant="warning" />}
      </div>
    );
  }

  return (
    <button
      ref={ref}
      className={cx("chip-button")}
      aria-label={name}
      {...restProps}
    >
      <span className={cx("chip", "interactive")}>
        {isNameConfigured ? firstLettersOfName : <Icon variant="warning" />}
      </span>
    </button>
  );
});

AccountChip.displayName = "AccountChip";
