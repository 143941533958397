import { EquipmentDropdownInput } from "#batteries-included-components/Dropdowns/EquipmentDropdownInput";
import { FacilityDropdownInput } from "#batteries-included-components/Dropdowns/FacilityDropdownInput";
import { FlowDropdownInput } from "#batteries-included-components/Dropdowns/FlowDropdownInput";
import { RecordValueConfigurationContext } from "#batteries-included-components/Layouts/RecordConfiguration/RecordConfigurationContext";
import { useParams } from "#routers/hooks";
import { FormCategoryDropdownInput } from "#src/batteries-included-components/Dropdowns/FormCategoryDropdownInput";
import { FormSchemaDropdownInput } from "#src/batteries-included-components/Forms/FormSubmissionForm/FormSubmissionSections/FormSubmissionField/QuestionField/LookupField/FormSchemaDropdownInput";
import useLocalization from "#src/hooks/useLocalization";
import { getDefaultDateRange } from "#utils/recordUtils";
import {
  DateSelectorInput,
  DropdownInput,
  FilterPanel,
  useFilters,
} from "@validereinc/common-components";
import { AssetType } from "@validereinc/domain";
import React, { useContext, useEffect, useMemo, useState } from "react";

export const FormConfigurationFilterPanel = ({
  storageKey,
}: {
  storageKey: string;
}) => {
  const { localize } = useLocalization();
  const { record } = useContext(RecordValueConfigurationContext) || {};
  const [storedFilters, setStoredFilters] = useFilters(storageKey);
  const params = useParams<{ measurementType: string }>();

  const { asset_type, facility, flow, equipment, formSchema, formCategory } =
    storedFilters;

  const [assetType, setAssetType] = useState<string | undefined>(asset_type);

  const defaultDateRange = useMemo(
    () => getDefaultDateRange(record, params.measurementType),
    [record, params.measurementType]
  );

  const filterValue = {
    asset_type,
    facility,
    flow,
    equipment,
    dateRange:
      storedFilters.from && storedFilters.to
        ? {
            from: new Date(storedFilters.from),
            to: new Date(storedFilters.to),
          }
        : defaultDateRange,
  };

  const onFiltersChange = ({
    dateRange,
    ...filters
  }: Record<string, string> & { dateRange: { from: Date; to: Date } }) => {
    setStoredFilters({
      ...storedFilters,
      ...filters,
      ...(dateRange && {
        from: dateRange.from,
        to: dateRange.to,
      }),
    });
  };

  const onReset = () => {
    setAssetType(undefined);
  };

  useEffect(() => {
    setAssetType(record?.asset_type);
    const formCategory =
      storedFilters.formCategory ??
      record?.values?.find(
        ({ measurement_type }) => measurement_type === params.measurementType
      )?.configuration?.form_category_id;
    setStoredFilters({
      ...storedFilters,
      ...filterValue.dateRange,
      ...(record?.asset_type === AssetType.FACILITY
        ? {
            facility: record?.asset_id,
            asset_type: record.asset_type,
            formCategory,
          }
        : {}),
      ...(record?.asset_type === AssetType.EQUIPMENT
        ? {
            equipment: record?.asset_id,
            asset_type: record.asset_type,
            formCategory,
          }
        : {}),
      ...(record?.asset_type === AssetType.FLOW
        ? {
            flow: record?.asset_id,
            asset_type: record.asset_type,
            formCategory,
          }
        : {}),
    });
  }, [record, formCategory]);

  return (
    <FilterPanel
      value={filterValue}
      onChange={onFiltersChange}
      onReset={onReset}
      defaultActiveKeys={["Asset"]}
      isVertical
      filters={[
        {
          component: (
            <FormCategoryDropdownInput
              style={{ width: "355px" }}
              filter={{
                measurement_type: params.measurementType,
                archived: false,
              }}
              isRequired
              label="Form Category"
              placeholder="Search Form Categories"
              name="formCategory"
              defaultValue={formCategory}
              isFluid={false}
            />
          ),
        },
        {
          component: (
            <FormSchemaDropdownInput
              style={{ width: "355px" }}
              filter={{ form_category_id: formCategory, status: "active" }}
              isRequired
              label="Form Template"
              isDisabled={!formCategory}
              placeholder="Search Form Template"
              name="formSchema"
              defaultValue={formSchema}
              isFluid={false}
            />
          ),
        },
        {
          component: (
            <DateSelectorInput
              name="dateRange"
              variant={"time"}
              label="Submission Date"
              isRequired
              isRange
              isInline
            />
          ),
        },
        {
          section: "Asset",
          component: (
            <DropdownInput
              label="Asset Type"
              name="asset_type"
              options={[
                {
                  name: localize("Facility"),
                  id: "facility",
                },
                { name: localize("Equipment"), id: "equipment" },
                { name: localize("Flow"), id: "flow" },
              ]}
              onChange={(value?: string) => {
                setAssetType(value);
              }}
              labelKey="name"
              valueKey="id"
              isSearchable
              isFluid
              isOptionalTextShown={false}
            />
          ),
        },
        ...(assetType === AssetType.FACILITY
          ? [
              {
                section: "Asset",
                component: (
                  <FacilityDropdownInput
                    key="facility"
                    name="facility"
                    isMulti={false}
                    isFluid
                    isRequired
                  />
                ),
              },
            ]
          : []),
        ...(assetType === AssetType.EQUIPMENT
          ? [
              {
                section: "Asset",
                component: (
                  <EquipmentDropdownInput
                    key="equipment"
                    name="equipment"
                    isMulti={false}
                    isFluid
                    isRequired
                  />
                ),
              },
            ]
          : []),
        ...(assetType === AssetType.FLOW
          ? [
              {
                section: "Asset",
                component: (
                  <FlowDropdownInput
                    key="flow"
                    name="flow"
                    isMulti={false}
                    isFluid
                    isRequired
                  />
                ),
              },
            ]
          : []),
      ]}
    />
  );
};
