/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  FormMeasurementType,
  FormQuestionType,
  FormSchemaDataType,
} from "@validereinc/domain";
import isEmpty from "lodash/isEmpty";
import { ReactNode } from "react";
import { UseFormReturn } from "react-hook-form";
import { FormSubmissionsSectionRepeater } from "./FormSubmissionsSectionRepeater";

export type SectionRendererPropType = {
  onAddSection: () => void;
  onRemoveSection: () => void;
  index: number;
  title: string;
  addButtonLabel: string;
  hasErrors?: boolean;
  canAddSection?: boolean;
  canRemoveSection?: boolean;
  children: ReactNode;
  dataKey: string;
  description?: string;
};

export type QuestionRendererPropType = {
  dataKey: string;
  name: string;
  type: "question" | "measurement";
} & (FormMeasurementType | FormQuestionType);

export type SectionRenderFunctionType = (
  sectionRenderFunctionProps: SectionRendererPropType
) => ReactNode;

export type QuestionRenderFunctionType = (
  questionRenderFunctionProps: QuestionRendererPropType
) => ReactNode;

export type FormSubmissionFormControllerType = (props: {
  form: UseFormReturn<any>;
  formSchema?: FormSchemaDataType;
  sectionRenderFunction: SectionRenderFunctionType;
  questionRenderFunction: QuestionRenderFunctionType;
}) => JSX.Element | null;

const FormSubmissionFormController: FormSubmissionFormControllerType = ({
  form,
  formSchema,
  sectionRenderFunction,
  questionRenderFunction,
}) => {
  if (
    !formSchema ||
    !Array.isArray(formSchema?.config?.sections) ||
    isEmpty(formSchema?.config?.questions ?? {})
  )
    return null;

  return (
    <>
      {formSchema.config.sections.map((sectionProps) => (
        <FormSubmissionsSectionRepeater
          key={sectionProps.id}
          sectionRenderFunction={sectionRenderFunction}
          questionRenderFunction={questionRenderFunction}
          formQuestions={formSchema?.config?.questions}
          form={form}
          {...sectionProps}
        />
      ))}
    </>
  );
};

export default FormSubmissionFormController;
