export const EDIT_SCENARIO_PAGE_TITLE = "Edit Scenario";

export const linkToEditScenario = (scenarioId = ":scenarioId") =>
  `/app/scenarios/${scenarioId}/edit`;
export const EDIT_SCENARIO_BREADCRUMB = {
  title: EDIT_SCENARIO_PAGE_TITLE,
  path: linkToEditScenario,
};

export * from "./EditScenarioPage";
