import {
  CUBIC_METERS_VOLUME,
  getFormattedMeasurementValueWithUnit,
} from "#redux/reducers/measurements";
import { LegacyDataTable } from "@validereinc/common-components";
import * as PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { AutoSizer } from "react-virtualized";
import { getComponentShortForm } from "../../Quality/RecordOfQualityHelper";
import { sortComponents } from "../BalancesTableHelper";
import { getTotalLabel } from "../BalanceUpserts/BalanceHelper";
import "./BalanceSummaryTable.scss";
import { getAllComponents, getBalanceList } from "./BalanceSummaryTableHelper";

const COMPONENT_COLUMN_WIDTH = 150;

const mapStateToProps = (state) => {
  return {
    selectedRecordOfVolumes: state.recordOfVolume?.toJS() ?? [],
    getFormattedMeasurementValueWithUnit: (measurementKey, measurementObject) =>
      getFormattedMeasurementValueWithUnit(state.measurements)(
        measurementKey,
        measurementObject
      ),
  };
};

const BALANCE_ROWS = {
  percent_differences: { properties: "percent difference", type: "optional" },
  differences: { properties: "difference", type: "required" },
  inlets: { properties: "inlets", isSource: true, type: "required" },
  outlets: { properties: "outlets", isSource: true, type: "required" },
  references: { properties: "references", isSource: true, type: "optional" },
};

const BalanceSummaryTable = (props) => {
  const {
    balanceSummary,
    balanceType,
    haveReferenceSources,
    showTotalVolume,
    showEnergy,
    getFormattedMeasurementValueWithUnit,
  } = props;
  const components = getAllComponents(balanceSummary?.summary?.components);

  const propertiesRenderer = (rowData, columnKey) => {
    const properties = rowData[columnKey];

    return properties ? <span className="capitalized">{properties}</span> : "-";
  };

  const aggregateRenderer = (rowData, columnKey) => {
    const total = rowData[columnKey];

    const measurementKey =
      columnKey === "energy" ? "Energy" : CUBIC_METERS_VOLUME;

    return getFormattedMeasurementValueWithUnit(measurementKey, total);
  };

  const componentRenderer = (rowData, columnKey) => {
    const component = rowData[columnKey];

    return getFormattedMeasurementValueWithUnit(CUBIC_METERS_VOLUME, component);
  };

  const getAggregateColumnProperties = (
    balanceType,
    showTotalVolume,
    showEnergy
  ) => {
    const aggregateProperties = [];

    if (showTotalVolume) {
      aggregateProperties.push({
        label: getTotalLabel(balanceType),
        key: "total",
        width: 150,
        cellRenderer: aggregateRenderer,
        disableSort: true,
        fixed: true,
        rightAlign: true,
      });
    }

    if (showEnergy) {
      aggregateProperties.push({
        label: "Energy",
        key: "energy",
        width: 150,
        cellRenderer: aggregateRenderer,
        disableSort: true,
        fixed: true,
        rightAlign: true,
      });
    }

    return aggregateProperties;
  };

  const ComponentHeaderConfigs =
    sortComponents(components).map((component) => {
      return {
        label: getComponentShortForm(component),
        key: component,
        width: COMPONENT_COLUMN_WIDTH,
        cellRenderer: componentRenderer,
        rightAlign: true,
        disableSort: true,
      };
    }) ?? [];

  const Headers = [
    {
      label: "Properties",
      key: "properties",
      width: 150,
      cellRenderer: propertiesRenderer,
      disableSort: true,
      fixed: true,
    },

    ...getAggregateColumnProperties(balanceType, showTotalVolume, showEnergy),

    ...ComponentHeaderConfigs,
  ];

  const balanceList = getBalanceList(
    balanceSummary,
    BALANCE_ROWS,
    haveReferenceSources
  );

  return (
    <div className="balanceSummaryTable">
      <AutoSizer>
        {({ width, height }) => (
          <LegacyDataTable
            headers={Headers}
            list={balanceList}
            width={width}
            height={height}
            rowHeight={40}
          />
        )}
      </AutoSizer>
    </div>
  );
};

BalanceSummaryTable.propTypes = {
  balanceSummary: PropTypes.object.isRequired,
  haveReferenceSources: PropTypes.bool,
  balanceType: PropTypes.string,
  showTotalVolume: PropTypes.bool,
  showEnergy: PropTypes.bool,
  getFormattedMeasurementValueWithUnit: PropTypes.func,
};

export default connect(mapStateToProps)(BalanceSummaryTable);
