import React from "react";
import * as PropTypes from "prop-types";
import { Col, FormGroup, ControlLabel, Checkbox } from "react-bootstrap";

const CheckBoxInput = (props) => (
  <FormGroup>
    <Col
      componentClass={ControlLabel}
      sm={props.titleCol}
    >
      {props.title}
    </Col>
    <Col sm={12 - props.titleCol}>
      <div className={props.className}>
        <Checkbox
          checked={props.checked}
          onChange={props.onChange}
          disabled={props.disabled}
        >
          {props.name}
        </Checkbox>
      </div>
    </Col>
  </FormGroup>
);

CheckBoxInput.propTypes = {
  title: PropTypes.string,
  titleCol: PropTypes.number,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  name: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

export default CheckBoxInput;
