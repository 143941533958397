import { WorkflowTasksFilterPanel } from "#src/batteries-included-components/Panels/FilterPanels/WorkflowTasksFilterPanel";
import {
  WorkflowTasksTablePanel,
  WorkflowTasksTablePanelProps,
} from "#src/batteries-included-components/Panels/TablePanels/WorkflowTasksTablePanel";
import React from "react";

type WorkflowTasksListLayoutProps = {
  tablePanelProps: WorkflowTasksTablePanelProps;
  isDisplayingMyTasks?: boolean;
};

export const WorkflowTasksListLayout = ({
  tablePanelProps,
  isDisplayingMyTasks,
}: WorkflowTasksListLayoutProps) => {
  return (
    <>
      <WorkflowTasksFilterPanel
        filterConfigStorageKey={tablePanelProps?.filterConfigStorageKey}
        isDisplayingMyTasks={isDisplayingMyTasks}
      />
      <WorkflowTasksTablePanel
        {...tablePanelProps}
        isDisplayingMyTasks={isDisplayingMyTasks}
      />
    </>
  );
};
