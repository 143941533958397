import {
  AuthQueryType,
  LegacyFeatureFlagQueryType,
  LegacyPermissionQueryType,
  useIsFeatureAvailable,
  useLegacyIsFeatureAvailable,
} from "#src/contexts/AuthenticatedContext.helpers";
import React, { PropsWithChildren, ReactNode } from "react";

/**
 * Use to hide UI behind a feature flag and permissions check
 * @returns renders the composed children if the queried feature flags and
 * permissions are enabled, fallback UI otherwise
 */
export const IsFeatureAvailable = ({
  children,
  featureFlagQuery,
  permissionQuery,
  fallbackChildren,
}: PropsWithChildren<{
  featureFlagQuery: AuthQueryType;
  permissionQuery?: AuthQueryType;
  fallbackChildren: ReactNode;
}>) => {
  const [isAvailable] = useIsFeatureAvailable({
    featureFlagQuery,
    permissionQuery,
  });

  // are all the queried feature flags and permissions, enabled? if so, render the children within
  if (isAvailable) {
    return <>{children}</>;
  }

  // when access is denied, fallback to provided UI
  return <>{fallbackChildren}</>;
};

/**
 * @deprecated reflects old monolith authorization system (Elixir API, OpsHub)
 * that is currently in use in legacy parts of the app
 *
 * Use to hide UI behind a feature flag and permissions check
 * @returns renders the composed children if the queried feature flags and
 * permissions are enabled, fallback UI otherwise
 */
export const LegacyIsFeatureAvailable = ({
  children,
  fallbackChildren,
  featureId,
  featureFlagQuery,
  notFeatureFlagQuery,
  permissionQuery,
}: PropsWithChildren<{
  featureId: string;
  featureFlagQuery: LegacyFeatureFlagQueryType;
  notFeatureFlagQuery?: string;
  permissionQuery?: LegacyPermissionQueryType;
  fallbackChildren: ReactNode;
}>) => {
  const [isAvailable] = useLegacyIsFeatureAvailable({
    featureId,
    featureFlagQuery,
    notFeatureFlagQuery,
    permissionQuery,
  });

  // are all the queried feature flags and permissions, enabled? if so, render the children within
  if (isAvailable) {
    return <>{children}</>;
  }

  // when access is denied, fallback to provided UI
  return <>{fallbackChildren}</>;
};
