import DashboardService from "#components/Services/DashboardService";
import { useNavigate, useSearchParams } from "#routers/hooks";
import DashboardDetailsModal from "#src/batteries-included-components/Modals/CreateDashboardModal/CreateDashboardModal";
import { RoutingLink } from "#src/batteries-included-components/RoutingLink";
import useTableState from "#src/hooks/useTableState";
import { linkToCustomDashboardDetails } from "#src/routes/dashboard/custom-dashboard/[customDashboardId]/details";
import {
  CardGrid,
  DataTable,
  FilterPanel,
  Panel,
  SortingType,
  TextInput,
} from "@validereinc/common-components";
import { SortDirection } from "@validereinc/domain";
import { datetimeFormatter, toFlattenedObject } from "@validereinc/utilities";
import React, { useCallback, useState } from "react";

/*
TODO Toggle between CardGrid & DataTable views  
We replaced the DataTable with the CardGrid but we realize that our customers
may wish to toggle between them in the future so we can bring the DataTable back
at some point once we have a way to switch between them
<DataTable {...tableProps} headers={headers} isFluid={false} />
*/

const sorting: SortingType = {
  sortBy: "name",
  sortDirection: SortDirection.ASCENDING,
};

export const CustomDashboardTab = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, toggleIsLoading] = useState(true);

  const [isCreateDashboardModalOpen, setIsCreateDashboardModalOpen] =
    useState(false);

  const onFetchData = useCallback(async (newSearchParams) => {
    const { page, rowPerPage, sort, sortDirection, ...restFilters } =
      newSearchParams;
    const list = await DashboardService.getDashboardsList({
      page,
      rowPerPage,
      sort: sort ?? sorting.sortBy,
      sortDirection: sortDirection ?? sorting.sortDirection,
      filters: restFilters,
    });
    toggleIsLoading(false);
    return list;
  }, []);

  const { tableProps } = useTableState({
    onFetchData,
    initialSort: sorting,
    entityPerPage: "Cards per page:",
  });

  const onOpenCreateDashboardModal = () => setIsCreateDashboardModalOpen(true);

  const onCloseCreateDashboardModal = () =>
    setIsCreateDashboardModalOpen(false);

  const onFiltersChange = (filters) => {
    setSearchParams(toFlattenedObject(filters));
  };

  const onResetFilters = (filters) => {
    setSearchParams(
      toFlattenedObject(filters, { transformObjectValue: (val) => val?.id })
    );
  };

  const onPrimaryCardClick = (id) => {
    navigate({
      pathname: linkToCustomDashboardDetails(id),
    });
  };

  return (
    <>
      <FilterPanel
        value={searchParams}
        onChange={onFiltersChange}
        onReset={onResetFilters}
        defaultActiveKeys={["Dashboards"]}
        filters={[
          {
            component: (
              <TextInput
                name="name"
                placeholder="Search Dashboards..."
                type="search"
                isInline
              />
            ),
          },
          {
            section: "Dashboards",
            component: (
              <TextInput
                name="description"
                label="Description"
                type="search"
                isOptionalTextShown={false}
              />
            ),
          },
        ]}
      />

      <Panel>
        <CardGrid
          {...tableProps}
          headers={[
            {
              label: "Dashboard",
              key: "name",
              minWidth: 200,
              isSortable: true,
              renderComponent: ({ item }) => (
                <RoutingLink to={linkToCustomDashboardDetails(item?.id)}>
                  {item.name}
                </RoutingLink>
              ),
            },
            { label: "Description", key: "description" },
            {
              label: "Last Updated",
              key: "updated_at",
              renderComponent: ({ item }) => (
                <DataTable.DataRow.DateCell value={item.updated_at} />
              ),
            },
          ]}
          isFluid={false}
          onCtaClick={onOpenCreateDashboardModal}
          onPrimaryCardClick={onPrimaryCardClick}
          isLoading={isLoading}
          getMeta={({
            updatedAt,
            createdAt,
          }:
            | {
                updatedAt: Date;
                createdAt?: undefined;
              }
            | {
                createdAt: Date;
                updatedAt?: undefined;
              }) => {
            return datetimeFormatter(updatedAt || createdAt);
          }}
        />
      </Panel>

      <DashboardDetailsModal
        isOpen={isCreateDashboardModalOpen}
        onClose={onCloseCreateDashboardModal}
      />
    </>
  );
};
