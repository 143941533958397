import filter from "lodash/filter";
import { v1 as uuidv1 } from "uuid";
import moment from "moment";

export const validateSampleDate = (samples) => {
  let errorMessage = null;

  samples.map((sample) => {
    if (!sample.started_at || !sample.ended_at) {
      errorMessage = `Selected sample ${sample.stream?.name} needs a date.`;
    }
  });

  return errorMessage;
};

export function getSampleWithoutManualWorkflowTasks(samples) {
  return filter(
    samples,
    (sample) => sample.workflow_instance?.test_type !== "manual"
  );
}

export const validateChainOfCustodyForm = (inputs) => {
  const { samples } = inputs;

  const sampleDateErrorMessage = validateSampleDate(samples);
  if (sampleDateErrorMessage) {
    return sampleDateErrorMessage;
  }

  return null;
};

export const formatSamples = (samples) => {
  if (samples) {
    return samples.map((sample) => {
      return {
        id: uuidv1(),
        sample_name: sample.name,
        sample_type: sample.type,
        sample_point_id: sample.sample_point_id,
        new_sample: true,
        scheduled: "No",
        stream: { id: sample.stream_id, name: sample.name },
        stream_id: sample.stream_id,
        started_at: null,
        ended_at: null,
      };
    });
  }

  return [];
};

export const getFirstSiteNameFromTests = (tests) => {
  return tests?.[0]?.instrument?.site_name;
};

export const getFirstDateFromTests = (tests) => {
  return moment(tests?.[0]?.date).toDate();
};
