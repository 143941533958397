/**
 * The grant types possible on a permission (read, write, read & write)
 * @deprecated reflects old monolith authorization system (Elixir API, OpsHub)
 * that is currently in use in legacy parts of the app
 */
export const LegacyPermissionGrant = {
  READ: "read",
  WRITE: "write",
  ALL: "validere",
} as const;

/**
 * The grant types possible on a permission (read, write, read & write)
 * @deprecated reflects old monolith authorization system (Elixir API, OpsHub)
 * that is currently in use in legacy parts of the app
 */
export type LegacyPermissionGrantType =
  (typeof LegacyPermissionGrant)[keyof typeof LegacyPermissionGrant];

/**
 * The permission config (the grants on a subset of all possible permissions)
 * @deprecated reflects old monolith authorization system (Elixir API, OpsHub)
 * that is currently in use in legacy parts of the app
 */
export type LegacyPermissionsType = Record<string, LegacyPermissionGrantType[]>;
