import { Button, Modal, Title } from "@validereinc/common-components";
import React, { PureComponent } from "react";
import { ProgressBar } from "react-bootstrap";
import ChainOfCustodyService from "../Services/ChainOfCustodyService";
import "./ChainOfCustodyFormModal.css";

/* eslint-disable react/prop-types */

class Iframe extends PureComponent {
  render() {
    const src = "javascript: window.frameElement.getAttribute('srcdoc');";
    return this.props.srcDoc ? (
      <div>
        <iframe
          className="chainOfCustodyFormModal__iframe"
          name="chainOfCustodyFormModal__iframe"
          srcDoc={this.props.srcDoc}
          src={src}
          height="800px"
          width="95%"
        />
      </div>
    ) : (
      <div>Chain Of Custody form is unavailable</div>
    );
  }
}

export default class ChainOfCustodyFormModal extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      srcDoc: null,
      show: false,
      showProgress: false,
      alertType: "info",
    };

    this.fetchSamples = this.fetchSamples.bind(this);
    this.modalClosed = this.modalClosed.bind(this);
    this.clearForm = this.clearForm.bind(this);
    this.printChainOfCustodyForm = this.printChainOfCustodyForm.bind(this);
    this.handleErrorResponse = this.handleErrorResponse.bind(this);
  }

  componentDidMount() {
    if (this.props.show && this.props.chainOfCustodyId) {
      this.fetchSamples(this.props.chainOfCustodyId);
      this.setState({
        show: this.props.show,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.show &&
      prevProps.chainOfCustodyId !== this.props.chainOfCustodyId
    ) {
      this.fetchSamples(this.props.chainOfCustodyId);
      this.setState({
        show: this.props.show,
      });
    }
  }

  fetchSamples(chainOfCustodyId) {
    this.setState({
      showProgress: true,
    });

    if (chainOfCustodyId) {
      ChainOfCustodyService.getChainOfCustodyForm(chainOfCustodyId)
        .then(({ data }) => {
          this.setState({
            showProgress: false,
            srcDoc: data,
          });
        })
        .catch(() => {
          this.handleErrorResponse();
        });
    }
  }

  printChainOfCustodyForm() {
    window.frames.chainOfCustodyFormModal__iframe.focus();
    window.frames.chainOfCustodyFormModal__iframe.print();
  }

  modalClosed() {
    this.clearForm();
    this.props.hide();
  }

  clearForm() {
    this.setState({
      srcDoc: null,
      show: false,
      showProgress: false,
    });
  }

  handleErrorResponse() {
    this.setState({
      showProgress: false,
    });
  }

  render() {
    return (
      <Modal
        className="chainOfCustodyFormModal"
        open={this.props.show}
        onClose={this.modalClosed}
      >
        <Modal.Header>
          <Title>Chain of Custody Form</Title>
        </Modal.Header>

        <Modal.Body>
          <div hidden={!this.state.showProgress}>
            <ProgressBar
              active
              now={100}
            />
          </div>

          <Iframe srcDoc={this.state.srcDoc} />
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => this.printChainOfCustodyForm()}
          >
            Print
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
