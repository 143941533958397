import { Button, Modal, Title } from "@validereinc/common-components";
import isNil from "lodash/isNil";
import * as PropTypes from "prop-types";
import React, { useReducer, useState } from "react";
import { Radio, Table } from "react-bootstrap";
import Loader from "react-loader";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { linkToAnnotations } from "../../../../Routers/links";
import TextBoxInput from "../../../Common/Form/TextBoxInput";
import CheckBox from "../../../Inputs/CheckBox";
import options from "../../../LoadingBar/LoadingBar";
import { havePermission } from "../../../Redux/reducers/permissions";
import { submitAnnotation } from "../../../Services/AnnotationsService";
import {
  MEASUREMENT_LABELS,
  MEASUREMENT_TYPES,
  MeasurementState,
} from "../Hooks/useMeasurementReducer";
import { makeSubmitAnnotatePayload } from "../Utils/AnnotationApiHelper";
import "./SubmitModal.css";

const _ = { isNil };

const TUBE_KEYS_TO_LABELS = {
  tube_present: "Is the tube present?",
  exterior_residue: "Is there exterior residue on the tube?",
  tube_full: "Is the tube full?",
  ticks_visible: "Are the ticks visible?",
};

const useTubeInformation = () => {
  const reducer = (state, { type, value }) => {
    return {
      ...state,
      [type]: value,
    };
  };

  const [state, dispatch] = useReducer(reducer, {
    tube_present: null,
    exterior_residue: null,
    tube_full: null,
    ticks_visible: null,
  });

  const setValue = (type) => (value) => dispatch({ type, value });

  return [state, setValue];
};

const useSubmitAnnotations = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isComplete, setIsComplete] = useState(false);

  const submitAnnotations = (testId, payload) => {
    if (!isLoading) {
      setIsLoading(true);
      setIsComplete(false);

      submitAnnotation(testId, payload)
        .then(() => {
          setIsComplete(true);
        })
        .catch(() => {
          setIsComplete(false);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  return [isLoading, isComplete, submitAnnotations];
};

const isValueSet = (value) => {
  return value !== undefined;
};

const QuestionInput = ({ value, setValue }) => {
  const isYesChecked = value === true;
  const isNoChecked = value === false;

  const onChange = (valueToSet) => (event) => {
    if (event.target.checked) {
      setValue(valueToSet);
    }
  };

  return (
    <>
      <Radio
        inline
        checked={isYesChecked}
        onChange={onChange(true)}
      >
        Yes
      </Radio>{" "}
      <Radio
        inline
        checked={isNoChecked}
        onChange={onChange(false)}
      >
        No
      </Radio>{" "}
    </>
  );
};

QuestionInput.propTypes = {
  value: PropTypes.bool,
  setValue: PropTypes.func.isRequired,
};

const Question = ({ label, children }) => {
  return (
    <tr>
      <td>
        <span>{label}</span>
      </td>
      {children.map((child, index) => {
        return <td key={index}>{child}</td>;
      })}
    </tr>
  );
};

Question.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

const mapStateToProps = (state) => {
  return {
    hasValiderePermissions: havePermission(state.permissions)(
      "360:annotations",
      "validere"
    ),
  };
};

export const SubmitModal = ({
  testId,
  isOpen,
  onClose,
  leftTubeState,
  rightTubeState,
  hasValiderePermissions,
}) => {
  const [notes, setNotes] = useState("");
  const [leftTubeInformation, setLeftTubeInformation] = useTubeInformation();
  const [rightTubeInformation, setRightTubeInformation] = useTubeInformation();

  const [isParallaxEnabled, setIsParallaxEnabled] = useState(true);
  const [isDilutionEnabled, setIsDilutionEnabled] = useState(true);

  const isSubmitDisabled =
    Object.keys(leftTubeInformation).some((key) =>
      _.isNil(leftTubeInformation[key])
    ) ||
    Object.keys(rightTubeInformation).some((key) =>
      _.isNil(rightTubeInformation[key])
    );

  const [isLoading, isComplete, submitAnnotations] = useSubmitAnnotations();

  if (isComplete) {
    return <Redirect to={{ pathname: linkToAnnotations() }} />;
  }

  return (
    <Modal
      open={isOpen}
      className="submitModal"
      onClose={onClose}
    >
      <Loader
        loaded={!isLoading}
        options={options}
      >
        <Modal.Header>
          <Title>Submit Measurements</Title>
        </Modal.Header>

        <Modal.Body>
          <Table>
            <thead>
              <tr>
                <th>Measurement</th>
                <th>Left Tube</th>
                <th>Right Tube</th>
              </tr>
            </thead>
            <tbody>
              {Object.values(MEASUREMENT_TYPES).map((type) => {
                const leftMeasurementValue = leftTubeState[type].meniscus.value;
                const rightMeasurementValue =
                  rightTubeState[type].meniscus.value;

                let leftMeasurementValueText = "-";
                if (leftTubeState[type].hasError) {
                  leftMeasurementValueText = "Error";
                } else if (isValueSet(leftMeasurementValue)) {
                  leftMeasurementValueText = leftMeasurementValue;
                }

                let rightMeasurementValueText = "-";
                if (rightTubeState[type].hasError) {
                  rightMeasurementValueText = "Error";
                } else if (isValueSet(rightMeasurementValue)) {
                  rightMeasurementValueText = rightMeasurementValue;
                }

                return (
                  <tr key={type}>
                    <td>{MEASUREMENT_LABELS[type]}</td>
                    <td>{leftMeasurementValueText}</td>
                    <td>{rightMeasurementValueText}</td>
                  </tr>
                );
              })}
              {Object.keys(TUBE_KEYS_TO_LABELS).map((key) => {
                const label = TUBE_KEYS_TO_LABELS[key];
                return (
                  <Question
                    key={key}
                    label={label}
                  >
                    <QuestionInput
                      value={leftTubeInformation[key]}
                      setValue={setLeftTubeInformation(key)}
                    />
                    <QuestionInput
                      value={rightTubeInformation[key]}
                      setValue={setRightTubeInformation(key)}
                    />
                  </Question>
                );
              })}
            </tbody>
          </Table>
          <TextBoxInput
            type="textarea"
            titleCol={2}
            title="Notes"
            placeholder="Enter notes for your reading"
            value={notes}
            onChange={(event) => setNotes(event.target.value)}
          />
          {hasValiderePermissions ? (
            <div className="submitModal__correctionFactorSettings clearfix">
              <CheckBox
                title="Apply parallax correction?"
                titleCol={6}
                checked={isParallaxEnabled}
                onChange={() => setIsParallaxEnabled(!isParallaxEnabled)}
              />
              <CheckBox
                title="Apply dilution correction?"
                titleCol={6}
                checked={isDilutionEnabled}
                onChange={() => setIsDilutionEnabled(!isDilutionEnabled)}
              />
            </div>
          ) : null}
        </Modal.Body>

        <Modal.Footer>
          <Button
            className="pull-left"
            onClick={onClose}
          >
            Close
          </Button>
          <Button
            disabled={isSubmitDisabled}
            variant="primary"
            onClick={() =>
              submitAnnotations(
                testId,
                makeSubmitAnnotatePayload({
                  leftTubeState,
                  rightTubeState,
                  notes,
                  leftTubeInformation,
                  rightTubeInformation,
                  isParallaxEnabled,
                  isDilutionEnabled,
                })
              )
            }
          >
            Submit Measurements
          </Button>
        </Modal.Footer>
      </Loader>
    </Modal>
  );
};

SubmitModal.propTypes = {
  testId: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  leftTubeState: PropTypes.instanceOf(MeasurementState).isRequired,
  rightTubeState: PropTypes.instanceOf(MeasurementState).isRequired,
  hasValiderePermissions: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps, undefined)(SubmitModal);
