import { useState } from "react";
import { Tools, ValueHelpers } from "react-sketch";
import { Record } from "immutable";
import useStateWithHistory from "./useStateWithHistory";

export const INITIAL_TOOL = Tools.ZoomBox;
export const INITIAL_LINE_COLOR = "black";

export const ImageProperties = Record({
  tool: undefined,
  lineColor: undefined,
  value: undefined,
  pan: undefined,
  setPan: undefined,
  zoom: undefined,
});

const useToolSelection = (value, zoom) => {
  const [selectedTool, setTool] = useState(INITIAL_TOOL);
  const [lineColor, setLineColor] = useState(INITIAL_LINE_COLOR);

  const selectTool = (tool, lineColor) => {
    setTool(tool);
    setLineColor(lineColor);
  };

  const objects = value.objects || [];
  const boxesCount = objects.filter((object) => object.type === "rect").length;
  let tool = selectedTool;
  if (tool === Tools.Rectangle && boxesCount === 1) {
    tool = Tools.Nothing;
  } else if (tool === Tools.ZoomBox && zoom !== 1.0) {
    tool = Tools.Nothing;
  }

  return {
    tool,
    lineColor,
    selectTool,
  };
};

const useAnnotationTools = (imageScale, sketchWidth) => {
  const [value, setValue, { resetHistory, undo, canUndo }] =
    useStateWithHistory({});

  const [pan, setPan] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);

  const { tool, lineColor, selectTool } = useToolSelection(value, zoom);

  const addCriticalPointLine = (criticalPoint) => {
    const sketchSpaceCriticalPoint = imageScale * criticalPoint;

    const line = ValueHelpers.createLine(
      [0, sketchSpaceCriticalPoint, sketchWidth, sketchSpaceCriticalPoint],
      { stroke: "hsla(215, 100%, 60%, 1)", strokeWidth: 0.25 }
    ).toJSON();
    const newValue = ValueHelpers.addToValue(value, [line]);
    setValue(newValue);
  };

  const resetView = () => {
    setZoom(1);
    setPan({
      x: 0,
      y: 0,
    });
  };

  return {
    imageProperties: new ImageProperties({
      tool,
      lineColor,
      value,
      pan,
      setPan,
      zoom,
    }),
    setValue,
    selectTool,
    addCriticalPointLine,
    setPan,
    setZoom,
    resetView,
    resetHistory,
    undo,
    canUndo,
  };
};

export default useAnnotationTools;
