import { DefaultRecordValueConfigTablePanel } from "#batteries-included-components/Panels/TablePanels/DefaultRecordValueConfigTablePanel";
import {
  RecordsFilterPanel,
  RecordsTablePanel,
} from "#src/batteries-included-components";
import { useStorageKey } from "#src/hooks/useStorageKey";
import { useFilters } from "@validereinc/common-components";
import { AssetType } from "@validereinc/domain";
import isEqual from "lodash/isEqual";
import React, { useState } from "react";

export const FlowRecordsTab = () => {
  const storageKeys = useStorageKey("flows-detail-records");
  const [filters] = useFilters(storageKeys.filterConfigStorageKey);

  const activeMode = filters.record_mode ?? "records";

  const [selectableProperties, setSelectableProperties] = useState<string[]>();

  return (
    <div>
      <RecordsFilterPanel
        isDateRange={true}
        filterConfigStorageKey={storageKeys.filterConfigStorageKey}
        selectableProperties={selectableProperties}
      />
      {activeMode === "records" && (
        <RecordsTablePanel
          type={AssetType.FLOW}
          setAvailableProperties={(keys) => {
            if (isEqual(keys, selectableProperties)) return;
            setSelectableProperties(keys);
          }}
          {...storageKeys}
        />
      )}
      {activeMode === "automation" && (
        <DefaultRecordValueConfigTablePanel
          type={AssetType.FLOW}
          {...storageKeys}
        />
      )}
    </div>
  );
};
