import { ValidateCSVSafeInput } from "#utils/formValidator";

export const getErrorMessage = (name, accountingRecordIds) => {
  if (!name) {
    return "The recombined report must have a name";
  }

  const checkNameCSVSafe = ValidateCSVSafeInput("Name", name);
  if (checkNameCSVSafe) {
    return checkNameCSVSafe;
  }

  if (!accountingRecordIds.length) {
    return "The recombined report must have an accounting record";
  }

  return null;
};
