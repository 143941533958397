import { ReportingGroupTablePanel } from "#batteries-included-components/Panels/TablePanels/ReportingGroupTablePanel";
import { useBreadcrumbs } from "#routers/breadcrumbsHelper";
import { useNavigate } from "#routers/hooks";
import {
  SCENARIO_LIST_BREADCRUMB,
  SCENARIOS_BREADCRUMB,
  SCENARIOS_PAGE_TITLE,
} from "#routes/scenarios";
import { linkToCreateScenario } from "#routes/scenarios/create-scenario";
import { Button, Page } from "@validereinc/common-components";
import React from "react";

export const ScenarioListPage = () => {
  const navigate = useNavigate();

  const actionRow = [
    <Button
      key="create-scenario-button"
      variant="primary"
      onClick={() => navigate({ pathname: linkToCreateScenario() })}
    >
      Create Scenario
    </Button>,
  ];

  const breadcrumbs = useBreadcrumbs([
    SCENARIOS_BREADCRUMB,
    SCENARIO_LIST_BREADCRUMB,
  ]);

  return (
    <Page
      title={SCENARIOS_PAGE_TITLE}
      breadcrumbs={breadcrumbs}
      actionRow={actionRow}
    >
      <ReportingGroupTablePanel />
    </Page>
  );
};
