import DateRangeSelector from "#common/DateSelector/DateRangeSelector";
import { getDateObject } from "#common/DateSelector/DateRangeSelectorHelper";
import SidePanelSection from "#common/SidePanel/SidePanelSection";
import { Button, Tooltip } from "@validereinc/common-components";
import moment from "moment";
import * as PropTypes from "prop-types";
import React from "react";
import { Badge } from "react-bootstrap";
import FontAwesome from "react-fontawesome";
import DropdownInputWithSearch from "../../../../../Inputs/DropdownInputWithSearch";
import {
  getMethodString,
  getSampleSelectionCaption,
} from "../../../RecordOfQualityHelper";
import { useSelectionModalContext } from "../../Context/selectionModalContext";
import RecordOfQualitySelectionTable from "./RecordOfQualitySelectionTable";

const dateRangeTooltipMessage =
  "You can choose different methods/samples for calculating the RoQ over the " +
  "course of an accounting period. Create or adjust intervals by changing " +
  "the end date here.";

const UTC = true;

const getSubHeader = (interval) => {
  let numSelected = interval?.sampleIds?.length ?? 0;

  if (interval.previousAccountingRecordId) {
    numSelected = 1;
  }

  if (interval.method === "volume_weighted_inline") {
    numSelected = 1;
  }

  return (
    <div className="recordOfQualitySampleSelections__subHeader">
      <span>Sample Selections</span>{" "}
      <Badge
        className="recordOfQualitySampleSelections__badge"
        style={{
          display: numSelected ? "inline-block" : "none",
        }}
      >
        {numSelected}
      </Badge>
    </div>
  );
};

const RecordOfQualitySampleSelectionForm = (props) => {
  const {
    interval,
    intervalSelectionIndex,
    visibleMethodOptions,
    nextIntervalUntil,
  } = props;

  const {
    accountingPeriod,
    setMethod,
    updateIntervalSelections,
    deleteIntervalSelection,
    selectionData,
    hasWriteAccess,
    setDefaultDateRange,
  } = useSelectionModalContext();

  const onDateSelect = (from, to) => {
    from = moment.utc(from);
    to = moment.utc(to);

    updateIntervalSelections({
      accountingPeriod,
      from,
      to,
      updateIndex: intervalSelectionIndex,
      selectionData,
    });
  };

  const onRemoveInterval = () => {
    deleteIntervalSelection(accountingPeriod, intervalSelectionIndex);
  };

  const onMethodSelect = (value) => {
    setDefaultDateRange();
    setMethod(value, intervalSelectionIndex);
  };

  return (
    <SidePanelSection
      subHeader={getSubHeader(interval)}
      caption={getSampleSelectionCaption(interval)}
      className="recordOfQualitySampleSelections__section"
    >
      <section className="filterRow recordOfQualitySelection__filterRow">
        <span className="recordOfQualitySelection__filterRow__label">
          Sample Selection Interval
        </span>

        <DateRangeSelector
          dateRange={{
            from: interval.from,
            to: interval.until,
          }}
          onDateRangeChange={onDateSelect}
          icon
          disableRelativeDays
          disabledDays={[
            {
              before: getDateObject(interval.from, UTC),
              after: getDateObject(nextIntervalUntil, UTC),
            },
          ]}
          disableFrom
          disabled={!hasWriteAccess}
          allowOneDayRange
          initialMonth="from"
          utc={UTC}
        />

        {hasWriteAccess && (
          <Tooltip
            content={dateRangeTooltipMessage}
            width={300}
          >
            <FontAwesome
              name="info-circle"
              style={{ marginLeft: "3px" }}
            />
          </Tooltip>
        )}
      </section>

      <section className="recordOfQualitySelection__filterRow">
        <section className="recordOfQualitySelection__filterRow__method">
          <span className="recordOfQualitySelection__filterRow__label">
            Method
          </span>

          <DropdownInputWithSearch
            value={getMethodString(interval.method)}
            filterKey="name"
            options={visibleMethodOptions}
            onSelect={onMethodSelect}
            disabled={!hasWriteAccess}
          />
        </section>
      </section>

      <RecordOfQualitySelectionTable
        hasWriteAccess={hasWriteAccess}
        intervalSelectionIndex={intervalSelectionIndex}
        interval={interval}
      />

      {intervalSelectionIndex !== 0 && hasWriteAccess && (
        <Button
          variant="error-outline"
          onClick={onRemoveInterval}
        >
          Remove Selection
        </Button>
      )}
    </SidePanelSection>
  );
};

RecordOfQualitySampleSelectionForm.propTypes = {
  interval: PropTypes.object.isRequired,
  intervalSelectionIndex: PropTypes.number.isRequired,
  visibleMethodOptions: PropTypes.array,
  nextIntervalUntil: PropTypes.object,
};

export default RecordOfQualitySampleSelectionForm;
