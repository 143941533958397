import { DEFAULT_DATE_RANGES } from "#src/hooks/useDateRange";
import {
  DateRangeValue,
  DateSelector,
  useFilters,
} from "@validereinc/common-components";
import React from "react";

/** It would be nice to embed this in a filter panel, but it resets defaults every load when nested inside a tab */
export const PeriodSelector = ({
  filterKey = "asset_period_selector",
}: {
  filterKey: string;
}) => {
  const [filters, setFilters] = useFilters<{ period: DateRangeValue }>(
    filterKey
  );
  return (
    <div
      role="region"
      aria-label="Filter Area"
      style={{ marginBottom: 16 }}
    >
      <DateSelector
        key="period"
        variant="month"
        value={
          filters.period?.from && filters.period?.to
            ? {
                from: new Date(filters.period.from),
                to: new Date(filters.period.to),
              }
            : DEFAULT_DATE_RANGES.currentWholeMonth
        }
        onChange={(value: DateRangeValue) => {
          setFilters({ period: value });
        }}
      />
    </div>
  );
};
