import { useGetOneEstimationMethodRun } from "#hooks/adapters/useEstimationMethods";
import { useGetOneNetwork } from "#hooks/adapters/useNetworks";
import { useGetOneUser } from "#hooks/adapters/useUsers";
import { useParams } from "#routers/hooks";
import { NetworkDetailPageParameters } from "#routes/organization/networks/[networkId]/detail";
import { NetworkCalculationFilterType } from "#routes/organization/networks/[networkId]/detail/NetworkCalculationTab/NetworkCalculationFilterPanel";
import { useMeasurementTypes } from "#src/contexts/MeasurementTypeContext";
import { DEFAULT_DATE_RANGES } from "#src/hooks/useDateRange";
import {
  KeyValuePanel,
  StorageKeys,
  useFilters,
} from "@validereinc/common-components";
import { AssetType } from "@validereinc/domain";
import {
  datetimeFormatter,
  getYearMonthFromDateRange,
  yearMonthName,
} from "@validereinc/utilities";
import React from "react";

export const NetworkCalculationSummaryPanel = ({
  filterConfigStorageKey,
}: Pick<StorageKeys, "filterConfigStorageKey">) => {
  const { getUnitName, getPrecisionByType } = useMeasurementTypes();
  const { networkId } = useParams<NetworkDetailPageParameters>();
  const { data: assetGroup } = useGetOneNetwork(networkId);
  const [filters] = useFilters<NetworkCalculationFilterType>(
    filterConfigStorageKey
  );
  const yearMonth = getYearMonthFromDateRange(
    filters?.yearMonth ?? DEFAULT_DATE_RANGES.currentWholeMonth
  );

  const { data: run } = useGetOneEstimationMethodRun({
    id: assetGroup?.default_estimation_method_id,
    assetType: AssetType.ASSET_GROUP,
    yearMonth,
  });

  const { data: savedByUser } = useGetOneUser({ id: run?.updated_by });

  const getDisplayValueForOutputType = (type: string) => {
    const value = run?.output?.outputs.find(
      ({ measurement_type }) => measurement_type === type
    );

    if (!value) {
      return "-";
    }

    const numericValue =
      typeof value.measurement_value === "number"
        ? value.measurement_value
        : Number(value.measurement_value);
    const precision = getPrecisionByType(value.measurement_type);
    return `${numericValue.toLocaleString(
      "en-US",
      precision
        ? {
            minimumFractionDigits: precision,
            maximumFractionDigits: precision,
          }
        : {}
    )} ${
      value?.measurement_unit ? getUnitName(value?.measurement_unit) : null
    }`;
  };

  return (
    <KeyValuePanel
      panelProps={{ title: "Calculation Summary" }}
      data={[
        {
          title: "Total Upstream Volume",
          value: getDisplayValueForOutputType("upstream_volume"),
        },
        {
          title: "Non-Adjustable Downstream Volume",
          value: getDisplayValueForOutputType(
            "downstream_volume_non_adjustable"
          ),
        },
        {
          title: "Adjustable Downstream Volume",
          value: getDisplayValueForOutputType("downstream_volume_adjustable"),
        },
        {
          title: "Adjusted Downstream Volume",
          value: getDisplayValueForOutputType("downstream_volume_adjusted"),
        },
        {
          title: "Adjustment Factor",
          value: getDisplayValueForOutputType("adjustment_factor"),
        },
        {
          title: "Last Saved At",
          value: run?.updated_at
            ? datetimeFormatter(new Date(run.updated_at))
            : "-",
        },
        {
          title: "Saved By",
          value: savedByUser?.name,
        },
        {
          title: "Period",
          value: yearMonthName(yearMonth),
        },
      ]}
    />
  );
};
