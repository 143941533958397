import { useParams } from "#routers/hooks";
import { BreadcrumbType } from "@validereinc/common-components";
import { useMemo } from "react";
import useLocalization from "../hooks/useLocalization";
import { SetCleanHistoryPath } from "./historyHelper";

/** @deprecated use new BreadcrumbType from common-components */
export type LegacyBreadcrumbType = {
  path?: (...args: string[]) => string;
  title: string;
};

/** @deprecated use RoutePath pattern and associated hooks instead */
function getBreadCrumbPath(
  breadcrumb: LegacyBreadcrumbType,
  urlParams: Record<string, string>
) {
  const { path, title } = breadcrumb;

  if (typeof path === "function") {
    const params = title.split(":").slice(1);
    return path(...params.map((param) => urlParams[param]));
  }

  return undefined;
}

/** @deprecated use RoutePath pattern and associated hooks instead */
function getBreadCrumbName(breadcrumb: string) {
  return breadcrumb.split(":")[0];
}

/** @deprecated use RoutePath pattern and associated hooks instead */
export function getBreadcrumbsObject(
  breadcrumbs: LegacyBreadcrumbType[],
  urlParams: Record<string, string> = {},
  replacementTitles: Record<string, string | undefined> = {}
): BreadcrumbType[] {
  return (
    breadcrumbs?.map((breadcrumb, index) => {
      const linkPath = getBreadCrumbPath(breadcrumb, urlParams);

      return {
        title: replacementTitles[index] ?? getBreadCrumbName(breadcrumb.title),
        ...(linkPath
          ? { href: linkPath, onClick: () => SetCleanHistoryPath(linkPath) }
          : {}),
      };
    }) ?? []
  );
}

/**
 * Hook that applies localization and onClick handlers to breadcrumbs
 * @deprecated use RoutePath pattern and associated hooks instead
 */
export const useBreadcrumbs = (
  /** Array of breadcrumb objects */
  breadcrumbs: BreadcrumbType[],
  /** Set of titles to override that of a breadcrumb, keyed by the index of the breadcrumb */
  replacementTitles: Record<string, string | undefined> = {},
  /** Parameters to use instead of URL parameters */
  explicitParams?: Record<string, string>
): BreadcrumbType[] => {
  const { localize, isLoading } = useLocalization();
  const pathParams = useParams();
  const params = explicitParams ?? pathParams;

  return useMemo(
    () =>
      breadcrumbs?.map((breadcrumb, index) => {
        const linkPath = getBreadCrumbPath(breadcrumb, params);

        return {
          title:
            replacementTitles[index] ??
            localize(getBreadCrumbName(breadcrumb.title)),
          ...(linkPath
            ? { href: linkPath, onClick: () => SetCleanHistoryPath(linkPath) }
            : {}),
        };
      }) ?? [],
    [breadcrumbs, params, replacementTitles, isLoading]
  );
};
