import { useInventories } from "#hooks/useInventories";
import { ensureStreamListIsFetched } from "#redux/actions/index";
import { getBreadcrumbsObject } from "#routers/breadcrumbsHelper";
import { getPropertyAsMap } from "#utils/objectFormatter";
import { Form, Page, Panel, useForm } from "@validereinc/common-components";
import differenceBy from "lodash/differenceBy";
import unionBy from "lodash/unionBy";
import * as PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import ComponentModal from "./ComponentModal";
import ProductionReportsForm from "./ProductionReportsForm";
import SourcesModal from "./SourcesModal";

const COMPONENT_MODAL_KEY = "component";
const SOURCES_MODAL_KEY = ["add", "edit"];

const mapStateToProps = ({ streams }) => {
  return {
    streams: streams?.data?.toJS() ?? [],
  };
};

const mapDispatchToProps = {
  ensureStreamListIsFetched,
};

const CreateProductionReports = ({
  ensureStreamListIsFetched,
  streams,
  breadcrumbs,
  match,
}) => {
  const [formState, setFormState] = useState("enabled");
  const [modalState, setModalState] = useState(null);
  const [sourceKey, setSourceKey] = useState(null);
  const [sourceListType, setSourceListType] = useState(null);
  const [sourceCheckedList, setSourceCheckedList] = useState([]);

  const [inventories] = useInventories();

  const form = useForm();

  useEffect(() => {
    ensureStreamListIsFetched();
  }, []);

  const onComponentsTableEdit = ({ components, componentProperties }) => {
    form.reset({
      ...form.getValues(),
      components,
      componentProperties,
    });
  };

  const onSourceSelectionEdit = ({ selectedValues }, action) => {
    if (action === "add" || action === "edit") {
      const newSourceList = unionBy(
        selectedValues,
        form.getValues(sourceKey),
        "id"
      );

      form.setValue(sourceKey, newSourceList);
    }

    if (action === "delete") {
      const newSourceList = differenceBy(
        form.getValues(sourceKey),
        selectedValues,
        "id"
      );

      form.setValue(sourceKey, newSourceList);
    }
  };

  const onSourceSelect = (sourceKey, action, sourceListType) => {
    setSourceKey(sourceKey ?? null);
    setModalState(action ?? null);
    setSourceListType(sourceListType);
  };

  const onSourceChecked = (checkedList, sourceKey) => {
    setSourceCheckedList({ ...sourceCheckedList, [sourceKey]: checkedList });
  };

  const onSubmit = (_formValues) => {
    setFormState("disabled");

    setFormState("enabled");
  };

  const inventoriesWithStreamDetails = useMemo(() => {
    if (streams.length && inventories.length) {
      const streamsMap = getPropertyAsMap(streams);

      return inventories.map((inventory) => ({
        ...inventory,
        product_type: streamsMap?.[inventory.active_stream_id]?.product_type,
      }));
    }

    return [];
  }, [streams, inventories]);

  return (
    <Page
      title="Create Production Report"
      breadcrumbs={getBreadcrumbsObject(breadcrumbs, match.params)}
    >
      <Panel>
        <Form
          onSubmit={onSubmit}
          {...form}
        >
          <ProductionReportsForm
            form={form}
            formState={formState}
            setModalState={setModalState}
            onComponentsTableEdit={onComponentsTableEdit}
            onSourceSelect={onSourceSelect}
            onSourceChecked={onSourceChecked}
            sourceCheckedList={sourceCheckedList}
            streams={streams}
          />
        </Form>
      </Panel>

      {modalState === COMPONENT_MODAL_KEY && (
        <ComponentModal
          defaultValues={form.getValues()}
          onClose={() => setModalState(null)}
          onSubmit={onComponentsTableEdit}
        />
      )}

      {SOURCES_MODAL_KEY.includes(modalState) && (
        <SourcesModal
          onClose={() => setModalState(null)}
          onSubmit={onSourceSelectionEdit}
          sourceListType={sourceListType}
          sourceKey={sourceKey}
          action={modalState}
          streams={streams}
          inventories={inventoriesWithStreamDetails}
          sourceCheckedList={sourceCheckedList}
        />
      )}
    </Page>
  );
};

CreateProductionReports.propTypes = {
  streams: PropTypes.array.isRequired,
  ensureStreamListIsFetched: PropTypes.func.isRequired,
  breadcrumbs: PropTypes.array,
  match: PropTypes.object,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateProductionReports);
