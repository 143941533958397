import {
  useCreateOne,
  useDeleteOne,
  useGetList,
  useUpdateOne,
} from "#hooks/adapters/adapterUtils";
import { CustomAttributesDomain, Resources } from "@validereinc/domain";

export const CUSTOM_ATTRIBUTE_KEY = Resources.CUSTOM_ATTRIBUTE_DEFINITION;

export const useListCustomAttributes = useGetList(
  CustomAttributesDomain.getList,
  CUSTOM_ATTRIBUTE_KEY
);
export const useCreateCustomAttributes = useCreateOne(
  CustomAttributesDomain.createOne,
  CUSTOM_ATTRIBUTE_KEY
);
export const useUpdateCustomAttributes = useUpdateOne(
  CustomAttributesDomain.updateOne,
  CUSTOM_ATTRIBUTE_KEY
);
export const useDeleteCustomAttributes = useDeleteOne(
  CustomAttributesDomain.deleteOne,
  CUSTOM_ATTRIBUTE_KEY
);
