const data = {
  data: {
    data: [
      // Landfill 1
      {
        id: 1,
        accounting_period: {
          end_date: "2022-04-04T16:32:50Z",
          id: "4c5db632-4cd2-4d27-9ecc-19e0c93712a5",
          start_date: "2022-01-01T16:32:50Z",
        },
        facility: {
          id: 1,
          name: "Landfill 1",
          facility_code: "ABGP102934",
        },
        total_emissions: {
          CO2: {
            value: 7582.67,
            unit: "tonnes",
          },
          CH4: {
            value: 54.56,
            unit: "tonnes",
          },
          N2O: {
            value: 0.12,
            unit: "tonnes",
          },
          CO2E: {
            value: 8982.43,
            unit: "tonnes",
          },
        },
      },
      {
        id: 2,
        accounting_period: {
          end_date: "2022-04-04T16:32:50Z",
          id: "4c5db632-4cd2-4d27-9ecc-19e0c93712a5",
          start_date: "2022-02-04T16:32:50Z",
        },
        facility: {
          id: 1,
          name: "Landfill 1",
          facility_code: "ABGP102934",
        },
        total_emissions: {
          CO2: {
            value: 7334.11,
            unit: "tonnes",
          },
          CH4: {
            value: 63.11,
            unit: "tonnes",
          },
          N2O: {
            value: 0.56,
            unit: "tonnes",
          },
          CO2E: {
            value: 9078.74,
            unit: "tonnes",
          },
        },
      },
      {
        id: 3,
        accounting_period: {
          end_date: "2022-04-04T16:32:50Z",
          id: "4c5db632-4cd2-4d27-9ecc-19e0c93712a5",
          start_date: "2022-03-04T16:32:50Z",
        },
        facility: {
          id: 1,
          name: "Landfill 1",
          facility_code: "ABGP102934",
        },
        total_emissions: {
          CO2: {
            value: 7314.42,
            unit: "tonnes",
          },
          CH4: {
            value: 58.11,
            unit: "tonnes",
          },
          N2O: {
            value: 0.23,
            unit: "tonnes",
          },
          CO2E: {
            value: 9080.53,
            unit: "tonnes",
          },
        },
      },
      {
        id: 4,
        accounting_period: {
          end_date: "2022-04-04T16:32:50Z",
          id: "4c5db632-4cd2-4d27-9ecc-19e0c93712a5",
          start_date: "2022-04-04T16:32:50Z",
        },
        facility: {
          id: 1,
          name: "Landfill 1",
          facility_code: "ABGP102934",
        },
        total_emissions: {
          CO2: {
            value: 7334.11,
            unit: "tonnes",
          },
          CH4: {
            value: 63.11,
            unit: "tonnes",
          },
          N2O: {
            value: 0.56,
            unit: "tonnes",
          },
          CO2E: {
            value: 9078.74,
            unit: "tonnes",
          },
        },
      },
      {
        id: 5,
        accounting_period: {
          end_date: "2022-04-04T16:32:50Z",
          id: "4c5db632-4cd2-4d27-9ecc-19e0c93712a5",
          start_date: "2022-05-04T16:32:50Z",
        },
        facility: {
          id: 1,
          name: "Landfill 1",
          facility_code: "ABGP102934",
        },
        total_emissions: {
          CO2: {
            value: 7332.31,
            unit: "tonnes",
          },
          CH4: {
            value: 73.11,
            unit: "tonnes",
          },
          N2O: {
            value: 0.53,
            unit: "tonnes",
          },
          CO2E: {
            value: 9053.34,
            unit: "tonnes",
          },
        },
      },
      // Landfill 2
      {
        id: 6,
        accounting_period: {
          end_date: "2022-04-04T16:32:50Z",
          id: "4c5db632-4cd2-4d27-9ecc-19e0c93712a5",
          start_date: "2022-01-01T16:32:50Z",
        },
        facility: {
          id: 1,
          name: "Landfill 2",
          facility_code: "ABGP102935",
        },
        total_emissions: {
          CO2: {
            value: 6912.07,
            unit: "tonnes",
          },
          CH4: {
            value: 38.13,
            unit: "tonnes",
          },
          N2O: {
            value: 0.11,
            unit: "tonnes",
          },
          CO2E: {
            value: 7868.6,
            unit: "tonnes",
          },
        },
      },
      {
        id: 7,
        accounting_period: {
          end_date: "2022-04-04T16:32:50Z",
          id: "4c5db632-4cd2-4d27-9ecc-19e0c93712a5",
          start_date: "2022-02-01T16:32:50Z",
        },
        facility: {
          id: 1,
          name: "Landfill 2",
          facility_code: "ABGP102935",
        },
        total_emissions: {
          CO2: {
            value: 6812.11,
            unit: "tonnes",
          },
          CH4: {
            value: 36.95,
            unit: "tonnes",
          },
          N2O: {
            value: 0.11,
            unit: "tonnes",
          },
          CO2E: {
            value: 7768.64,
            unit: "tonnes",
          },
        },
      },
      {
        id: 8,
        accounting_period: {
          end_date: "2022-04-04T16:32:50Z",
          id: "4c5db632-4cd2-4d27-9ecc-19e0c93712a5",
          start_date: "2022-03-01T16:32:50Z",
        },
        facility: {
          id: 1,
          name: "Landfill 2",
          facility_code: "ABGP102935",
        },
        total_emissions: {
          CO2: {
            value: 6442.43,
            unit: "tonnes",
          },
          CH4: {
            value: 37.53,
            unit: "tonnes",
          },
          N2O: {
            value: 0.13,
            unit: "tonnes",
          },
          CO2E: {
            value: 7464.53,
            unit: "tonnes",
          },
        },
      },
      {
        id: 9,
        accounting_period: {
          end_date: "2022-04-04T16:32:50Z",
          id: "4c5db632-4cd2-4d27-9ecc-19e0c93712a5",
          start_date: "2022-04-01T16:32:50Z",
        },
        facility: {
          id: 1,
          name: "Landfill 2",
          facility_code: "ABGP102935",
        },
        total_emissions: {
          CO2: {
            value: 6843.31,
            unit: "tonnes",
          },
          CH4: {
            value: 34.53,
            unit: "tonnes",
          },
          N2O: {
            value: 0.12,
            unit: "tonnes",
          },
          CO2E: {
            value: 7568.65,
            unit: "tonnes",
          },
        },
      },
      {
        id: 10,
        accounting_period: {
          end_date: "2022-04-04T16:32:50Z",
          id: "4c5db632-4cd2-4d27-9ecc-19e0c93712a5",
          start_date: "2022-05-01T16:32:50Z",
        },
        facility: {
          id: 1,
          name: "Landfill 2",
          facility_code: "ABGP102935",
        },
        total_emissions: {
          CO2: {
            value: 6832.12,
            unit: "tonnes",
          },
          CH4: {
            value: 32.94,
            unit: "tonnes",
          },
          N2O: {
            value: 0.12,
            unit: "tonnes",
          },
          CO2E: {
            value: 7342.63,
            unit: "tonnes",
          },
        },
      },
      // Landfill 1 LFG Capture
      {
        id: 11,
        accounting_period: {
          end_date: "2022-04-04T16:32:50Z",
          id: "4c5db632-4cd2-4d27-9ecc-19e0c93712a5",
          start_date: "2022-01-01T16:32:50Z",
        },
        facility: {
          id: 1,
          name: "Landfill 1 LFG Capture",
          facility_code: "ABGP1032934",
        },
        total_emissions: {
          CO2: {
            value: 8562.84,
            unit: "tonnes",
          },
          CH4: {
            value: 40.89,
            unit: "tonnes",
          },
          N2O: {
            value: 0.12,
            unit: "tonnes",
          },
          CO2E: {
            value: 9620.85,
            unit: "tonnes",
          },
        },
      },
      {
        id: 12,
        accounting_period: {
          end_date: "2022-04-04T16:32:50Z",
          id: "4c5db632-4cd2-4d27-9ecc-19e0c93712a5",
          start_date: "2022-02-01T16:32:50Z",
        },
        facility: {
          id: 1,
          name: "Landfill 1 LFG Capture",
          facility_code: "ABGP1032934",
        },
        total_emissions: {
          CO2: {
            value: 8162.52,
            unit: "tonnes",
          },
          CH4: {
            value: 45.49,
            unit: "tonnes",
          },
          N2O: {
            value: 0.34,
            unit: "tonnes",
          },
          CO2E: {
            value: 9401.09,
            unit: "tonnes",
          },
        },
      },
      {
        id: 13,
        accounting_period: {
          end_date: "2022-04-04T16:32:50Z",
          id: "4c5db632-4cd2-4d27-9ecc-19e0c93712a5",
          start_date: "2022-03-01T16:32:50Z",
        },
        facility: {
          id: 1,
          name: "Landfill 1 LFG Capture",
          facility_code: "ABGP1032934",
        },
        total_emissions: {
          CO2: {
            value: 8154.54,
            unit: "tonnes",
          },
          CH4: {
            value: 42.43,
            unit: "tonnes",
          },
          N2O: {
            value: 0.12,
            unit: "tonnes",
          },
          CO2E: {
            value: 9365.35,
            unit: "tonnes",
          },
        },
      },
      {
        id: 14,
        accounting_period: {
          end_date: "2022-04-04T16:32:50Z",
          id: "4c5db632-4cd2-4d27-9ecc-19e0c93712a5",
          start_date: "2022-04-01T16:32:50Z",
        },
        facility: {
          id: 1,
          name: "Landfill 1 LFG Capture",
          facility_code: "ABGP1032934",
        },
        total_emissions: {
          CO2: {
            value: 8342.23,
            unit: "tonnes",
          },
          CH4: {
            value: 43.21,
            unit: "tonnes",
          },
          N2O: {
            value: 0.42,
            unit: "tonnes",
          },
          CO2E: {
            value: 9321.53,
            unit: "tonnes",
          },
        },
      },
      {
        id: 15,
        accounting_period: {
          end_date: "2022-04-04T16:32:50Z",
          id: "4c5db632-4cd2-4d27-9ecc-19e0c93712a5",
          start_date: "2022-05-01T16:32:50Z",
        },
        facility: {
          id: 1,
          name: "Landfill 1 LFG Capture",
          facility_code: "ABGP1032934",
        },
        total_emissions: {
          CO2: {
            value: 8231.51,
            unit: "tonnes",
          },
          CH4: {
            value: 42.21,
            unit: "tonnes",
          },
          N2O: {
            value: 0.12,
            unit: "tonnes",
          },
          CO2E: {
            value: 9421.23,
            unit: "tonnes",
          },
        },
      },
      // Battery W12
      {
        id: 16,
        accounting_period: {
          end_date: "2022-04-04T16:32:50Z",
          id: "4c5db632-4cd2-4d27-9ecc-19e0c93712a5",
          start_date: "2022-01-01T16:32:50Z",
        },
        facility: {
          id: 1,
          name: "Battery W12",
          facility_code: "ABBT12049129",
        },
        total_emissions: {
          CO2: {
            value: 0.56,
            unit: "tonnes",
          },
          CH4: {
            value: 8.34,
            unit: "tonnes",
          },
          CO2E: {
            value: 209.06,
            unit: "tonnes",
          },
        },
      },
      {
        id: 17,
        accounting_period: {
          end_date: "2022-04-04T16:32:50Z",
          id: "4c5db632-4cd2-4d27-9ecc-19e0c93712a5",
          start_date: "2022-02-01T16:32:50Z",
        },
        facility: {
          id: 1,
          name: "Battery W12",
          facility_code: "ABBT12049129",
        },
        total_emissions: {
          CO2: {
            value: 1.01,
            unit: "tonnes",
          },
          CH4: {
            value: 9.34,
            unit: "tonnes",
          },
          CO2E: {
            value: 234.51,
            unit: "tonnes",
          },
        },
      },
      {
        id: 18,
        accounting_period: {
          end_date: "2022-04-04T16:32:50Z",
          id: "4c5db632-4cd2-4d27-9ecc-19e0c93712a5",
          start_date: "2022-03-01T16:32:50Z",
        },
        facility: {
          id: 1,
          name: "Battery W12",
          facility_code: "ABBT12049129",
        },
        total_emissions: {
          CO2: {
            value: 1.34,
            unit: "tonnes",
          },
          CH4: {
            value: 9.53,
            unit: "tonnes",
          },
          CO2E: {
            value: 234.21,
            unit: "tonnes",
          },
        },
      },
      {
        id: 19,
        accounting_period: {
          end_date: "2022-04-04T16:32:50Z",
          id: "4c5db632-4cd2-4d27-9ecc-19e0c93712a5",
          start_date: "2022-04-01T16:32:50Z",
        },
        facility: {
          id: 1,
          name: "Battery W12",
          facility_code: "ABBT12049129",
        },
        total_emissions: {
          CO2: {
            value: 1.53,
            unit: "tonnes",
          },
          CH4: {
            value: 9.23,
            unit: "tonnes",
          },
          CO2E: {
            value: 265.23,
            unit: "tonnes",
          },
        },
      },
      {
        id: 20,
        accounting_period: {
          end_date: "2022-04-04T16:32:50Z",
          id: "4c5db632-4cd2-4d27-9ecc-19e0c93712a5",
          start_date: "2022-05-01T16:32:50Z",
        },
        facility: {
          id: 1,
          name: "Battery W12",
          facility_code: "ABBT12049129",
        },
        total_emissions: {
          CO2: {
            value: 1.34,
            unit: "tonnes",
          },
          CH4: {
            value: 9.65,
            unit: "tonnes",
          },
          CO2E: {
            value: 234.12,
            unit: "tonnes",
          },
        },
      },
      // Well 01/23-1C1
      {
        id: 20,
        accounting_period: {
          end_date: "2022-04-04T16:32:50Z",
          id: "4c5db632-4cd2-4d27-9ecc-19e0c93712a5",
          start_date: "2022-01-01T16:32:50Z",
        },
        facility: {
          id: 1,
          name: "Well 01/23-1C1",
          facility_code: "ABWII12941515",
        },
        total_emissions: {
          CO2: {
            value: 2.21,
            unit: "tonnes",
          },
          CH4: {
            value: 0.13,
            unit: "tonnes",
          },
          CO2E: {
            value: 8.32,
            unit: "tonnes",
          },
        },
      },
      {
        id: 21,
        accounting_period: {
          end_date: "2022-04-04T16:32:50Z",
          id: "4c5db632-4cd2-4d27-9ecc-19e0c93712a5",
          start_date: "2022-02-01T16:32:50Z",
        },
        facility: {
          id: 1,
          name: "Well 01/23-1C1",
          facility_code: "ABWII12941515",
        },
        total_emissions: {
          CO2: {
            value: 2.42,
            unit: "tonnes",
          },
          CH4: {
            value: 0.44,
            unit: "tonnes",
          },
          CO2E: {
            value: 7.54,
            unit: "tonnes",
          },
        },
      },
      {
        id: 23,
        accounting_period: {
          end_date: "2022-04-04T16:32:50Z",
          id: "4c5db632-4cd2-4d27-9ecc-19e0c93712a5",
          start_date: "2022-03-01T16:32:50Z",
        },
        facility: {
          id: 1,
          name: "Well 01/23-1C1",
          facility_code: "ABWII12941515",
        },
        total_emissions: {
          CO2: {
            value: 2.54,
            unit: "tonnes",
          },
          CH4: {
            value: 0.35,
            unit: "tonnes",
          },
          CO2E: {
            value: 7.53,
            unit: "tonnes",
          },
        },
      },
      {
        id: 24,
        accounting_period: {
          end_date: "2022-04-04T16:32:50Z",
          id: "4c5db632-4cd2-4d27-9ecc-19e0c93712a5",
          start_date: "2022-04-01T16:32:50Z",
        },
        facility: {
          id: 1,
          name: "Well 01/23-1C1",
          facility_code: "ABWII12941515",
        },
        total_emissions: {
          CO2: {
            value: 2.34,
            unit: "tonnes",
          },
          CH4: {
            value: 0.56,
            unit: "tonnes",
          },
          CO2E: {
            value: 8.43,
            unit: "tonnes",
          },
        },
      },
      {
        id: 25,
        accounting_period: {
          end_date: "2022-04-04T16:32:50Z",
          id: "4c5db632-4cd2-4d27-9ecc-19e0c93712a5",
          start_date: "2022-05-01T16:32:50Z",
        },
        facility: {
          id: 1,
          name: "Well 01/23-1C1",
          facility_code: "ABWII12941515",
        },
        total_emissions: {
          CO2: {
            value: 2.32,
            unit: "tonnes",
          },
          CH4: {
            value: 0.67,
            unit: "tonnes",
          },
          CO2E: {
            value: 7.34,
            unit: "tonnes",
          },
        },
      },
    ],
    page_number: 1,
    page_size: 1,
    total_entries: 10,
    total_pages: 1,
  },
};

export default data;
