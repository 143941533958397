import { useState, useEffect } from "react";
import EquipmentService from "#services/EquipmentService";

const useEquipments = () => {
  const [equipments, setEquipments] = useState([]);
  const [loadingState, setLoadingState] = useState("loading");

  useEffect(() => {
    EquipmentService.getEquipments()
      .then(() => {
        setEquipments(MOCK_DATA);
        setLoadingState("loaded");
      })
      .catch(() => setLoadingState("error"));
  }, []);

  return [equipments, loadingState];
};

export default useEquipments;

const MOCK_DATA = [
  {
    facility_id: "c82941c7-333a-453e-a208-0f4c07d51a83",
    id: "fb703b56-213d-49aa-8de7-a8bfa39eb0fe",
    metadata: {},
    name: "That one chromatograph",
    service: "De-butanizer",
    sub_type: "Gas Chromatograph",
  },
  {
    facility_id: "12cc988c-90be-45cd-99e3-504b67a256a2",
    id: "f1469f33-0cd0-5f7f-a64d-c607a46313c3",
    metadata: {},
    name: "Injection Pump",
    service: "Pneumatic Device",
    sub_type: "Chemical Injection Pump",
  },
];
