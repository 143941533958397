import history from "#src/Routers/history";
import { linkToCreateManualEntry } from "#src/Routers/links";
import { Page, Panel, Title } from "@validereinc/common-components";
import * as PropTypes from "prop-types";
import React from "react";
import FontAwesome from "react-fontawesome";
import { connect } from "react-redux";
import { havePermission } from "../Redux/reducers/permissions";
import "./ManualEntry.scss";
import { MANUAL_ENTRY_TYPES } from "./ManualEntryConstants";

const mapStateToProps = ({ permissions }) => ({
  havePermission: havePermission(permissions),
});

const ManualEntryTypePanel = (props) => {
  return (
    <Panel
      className="manualEntry__typePanel"
      onClick={() => history.push(linkToCreateManualEntry(props.type))}
    >
      <div className="manualEntry__typePanel__icon">
        <FontAwesome
          name="circle-thin"
          stack="2x"
        />

        <FontAwesome
          name="plus"
          stack="1x"
        />
      </div>

      <Title className="title">{props.title}</Title>
    </Panel>
  );
};

const ManualEntry = (props) => {
  const availableManualEntryForms = MANUAL_ENTRY_TYPES.filter(
    ({ permission }) => props.havePermission(permission, "write")
  );

  return (
    <Page
      title="Manual Entry"
      breadcrumbs={props.breadcrumbs}
    >
      {availableManualEntryForms.length ? (
        <div className="manualEntry__panelContainer">
          {availableManualEntryForms.map(({ title, id }) => (
            <ManualEntryTypePanel
              key={title}
              title={title}
              type={id}
            />
          ))}
        </div>
      ) : (
        <div className="manualEntry__noForms">
          No Manual Entry Forms available
        </div>
      )}
    </Page>
  );
};

ManualEntryTypePanel.propTypes = {
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

ManualEntry.propTypes = {
  havePermission: PropTypes.func.isRequired,
  breadcrumbs: PropTypes.array.isRequired,
};

export default connect(mapStateToProps)(ManualEntry);
