import {
  useGetOneNetwork,
  useUpdateNetwork,
} from "#src/components/hooks/adapters/useNetworks";
import { useListReportingGroups } from "#src/components/hooks/adapters/useReportingGroups";
import useLocalization from "#src/hooks/useLocalization";
import { AssetGroupStatus, AssetType } from "@validereinc/domain";
import { toStartCaseString } from "@validereinc/utilities";
import React from "react";
import AssetEditDialog from "./AssetEditDialog/AssetEditDialog";
import { AssetEditDialogPropTypes } from "./AssetEditDialog/types";

const EditAssetGroupDialog = (props: {
  assetGroupId?: string;
  onClose: () => void;
}) => {
  const { localize } = useLocalization();

  const { data: assetDetails, isLoading: isLoadingDefaultValues } =
    useGetOneNetwork(props.assetGroupId);

  const updateAssetGroupMutation = useUpdateNetwork({
    onSuccess: () => {
      props.onClose();
    },
  });

  const { data, isLoading: isLoadingReportGroups } =
    useListReportingGroups() ?? [];

  const isUpdating = updateAssetGroupMutation.isLoading;
  const isLoading =
    isUpdating || isLoadingDefaultValues || isLoadingReportGroups;

  const primaryFields: AssetEditDialogPropTypes["primaryFields"] = [
    {
      display_name: `${localize("Network")} name`,
      field_name: "name",
      data_type: "string",
      is_required: true,
      is_state_managed: false,
    },
    {
      display_name: "Status",
      field_name: "status",
      data_type: "pick-list",
      pick_list_values: Object.values(AssetGroupStatus).map((id) => ({
        id,
        name: toStartCaseString(id),
      })),
      is_required: true,
      is_state_managed: false,
    },
    {
      display_name: "Reporting Scenario",
      field_name: "reporting_group_id",
      data_type: "pick-list",
      pick_list_values: data?.data ?? [],
      is_required: true,
      is_state_managed: false,
    },
  ];

  return (
    <AssetEditDialog
      primaryFields={primaryFields}
      assetId={props.assetGroupId}
      assetType={AssetType.ASSET_GROUP}
      dialogProps={{ title: `Edit ${localize("Network")} Attributes` }}
      onClose={() => {
        if (isUpdating) return;
        props.onClose();
      }}
      isLoading={isLoading}
      onUpdate={(newValues) =>
        updateAssetGroupMutation.mutate({
          assetGroupId: props.assetGroupId ?? "",
          ...newValues,
        })
      }
      assetDetails={assetDetails}
      hasGeoPointField
    />
  );
};

export default EditAssetGroupDialog;
