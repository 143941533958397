export const CALCULATION_RESULT_DETAIL_FALLBACK_PAGE_TITLE =
  "Calculation Result Detail:methodId:equipmentId:resultId";

export const linkToEstimationMethodCalculationResult = (
  methodId = ":methodId",
  equipmentId = ":equipmentId",
  resultId = ":resultId"
) =>
  `/app/organization/equipment/${equipmentId}/estimation-method/${methodId}/result/${resultId}/detail`;

export const CALCULATION_RESULT_DETAIL_BREADCRUMB = {
  title: CALCULATION_RESULT_DETAIL_FALLBACK_PAGE_TITLE,
  path: linkToEstimationMethodCalculationResult,
};

export { default as CalculationResultDetail } from "./CalculationResultDetail";
