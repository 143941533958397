import {
  Button,
  Form,
  Modal,
  Title,
  useForm,
} from "@validereinc/common-components";
import * as PropTypes from "prop-types";
import React from "react";
import ComponentModalForm from "./ComponentModalForm";

const ComponentModal = ({ onClose, onSubmit, defaultValues }) => {
  const form = useForm({
    defaultValues: {
      componentProperties: defaultValues?.componentProperties,
      components: defaultValues?.components,
    },
  });

  const handleSubmit = (formValues) => {
    onSubmit(formValues);
    onClose();
  };

  return (
    <Modal
      show={true}
      onClose={onClose}
      size="large"
    >
      <Modal.Header>
        <Title>Select Components</Title>
      </Modal.Header>

      <Form
        onSubmit={handleSubmit}
        {...form}
      >
        <Modal.Body>
          <ComponentModalForm form={form} />
        </Modal.Body>

        <Modal.Footer>
          <Button
            className="pull-left"
            onClick={onClose}
          >
            Close
          </Button>

          <Button
            variant="primary"
            type="submit"
          >
            Update Components
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

ComponentModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  defaultValues: PropTypes.object,
};

export default ComponentModal;
