import { EmptyState } from "@validereinc/common-components";
import classNames from "classnames/bind";
import { LockSimple } from "phosphor-react";
import React from "react";
import styles from "./AccessDenied.module.scss";

const cx = classNames.bind(styles);

export const AccessDeniedLayout = ({ className }: { className?: string }) => {
  return (
    <EmptyState
      title="You do not have permission to view this feature"
      suggestion="Contact your system administrator for more information."
      className={cx(className, "container")}
      variant="warning"
      icon={<LockSimple />}
    />
  );
};
