import { config } from "../../config";
import {
  ErrorCodes,
  ErrorCodesToExplanationGetters,
  HTTPForbiddenError,
  HTTPNotFoundError,
  HTTPResponseError,
} from "../errors";
import { HTTPUtils } from "../http";
import { RequestWrapperType, requestWrapper } from "./requestWrapper";

export type GETRequestType = {
  version?: number;
  responseType?: "json" | "blob" | "arraybuffer";
} & Pick<RequestWrapperType, "query" | "endpoint" | "headers">;

type GETProps = {
  api: string;
} & GETRequestType;

export const GET = async <T = unknown>({
  api,
  endpoint,
  query = {},
  headers = {},
  responseType = "json",
}: GETProps): Promise<T> => {
  try {
    const request = requestWrapper({
      method: "GET",
      endpoint: `${api}${endpoint}`,
      query,
      shouldQueryHaveArrayNotation:
        api == config.elixirURL || api === config.alertingURL,
      headers,
    });
    const response = await fetch(request);

    if (HTTPUtils.status.isSuccess(response.status)) {
      switch (responseType) {
        case "arraybuffer":
          return (await response.arrayBuffer()) as T;
        case "blob":
          return (await response.blob()) as T;
        case "json":
        default:
          return (await response.json()) as T;
      }
    } else {
      throw { response, request };
    }
  } catch (cause: unknown) {
    if (!HTTPUtils.objectHasResponseAndRequest(cause)) {
      throw new HTTPResponseError("GET: unknown error", {
        code: ErrorCodes["http, unknown error"],
        getExplanation:
          ErrorCodesToExplanationGetters[ErrorCodes["http, unknown error"]],
        method: "GET",
        endpoint: `${api}${endpoint}`,
        requestQuery: query,
        requestContentType: headers["Content-Type"],
        cause,
      });
    }

    if (
      cause.response.headers.get("Content-Type")?.includes("application/json")
    ) {
      if (HTTPUtils.status.isForbidden(cause.response.status)) {
        throw new HTTPForbiddenError("GET: resource access forbidden", {
          requestQuery: query,
          requestContentType: headers["Content-Type"],
          responseBodyJson: await cause.response.json(),
          status: cause.response.status,
          responseContentType: cause.response.headers.get("Content-Type"),
          endpoint: cause.request.url,
          method: "GET",
        });
      } else if (HTTPUtils.status.isNotFound(cause.response.status)) {
        throw new HTTPNotFoundError("GET: resource not found", {
          requestQuery: query,
          requestContentType: headers["Content-Type"],
          responseBodyJson: await cause.response.json(),
          status: cause.response.status,
          responseContentType: cause.response.headers.get("Content-Type"),
          endpoint: cause.request.url,
          method: "GET",
        });
      } else {
        throw new HTTPResponseError("GET: unknown error with info from API", {
          requestQuery: query,
          requestContentType: headers["Content-Type"],
          responseBodyJson: await cause.response.json(),
          status: cause.response.status,
          responseContentType: cause.response.headers.get("Content-Type"),
          endpoint: cause.request.url,
          method: "GET",
        });
      }
    }

    throw new HTTPResponseError("GET: unknown error", {
      code: ErrorCodes["http, unknown error"],
      getExplanation:
        ErrorCodesToExplanationGetters[ErrorCodes["http, unknown error"]],
      requestQuery: query,
      requestContentType: headers["Content-Type"],
      status: cause.response.status,
      responseContentType: cause.response.headers.get("Content-Type"),
      endpoint: cause.request.url,
      method: "GET",
    });
  }
};
