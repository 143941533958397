import { Panel } from "@validereinc/common-components";
import * as PropTypes from "prop-types";
import React from "react";
import { Table } from "react-bootstrap";

const DescriptionPanel = (props) => (
  <Panel
    title="Instrument Description"
    className="instrumentDescription"
  >
    <Table className="instrumentDescription__table">
      <tbody>{props.children}</tbody>
    </Table>
  </Panel>
);

DescriptionPanel.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default DescriptionPanel;
