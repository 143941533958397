import { isValidFloatwUnit } from "../InstrumentComponentHelper";

const WATER_MEASUREMENT_DECIMAL = 2;

const waterMeasurement = ({ metadata }) => {
  return `Left: ${isValidFloatwUnit(
    metadata.left_value,
    WATER_MEASUREMENT_DECIMAL,
    "",
    "-"
  )}, Right:
  ${isValidFloatwUnit(
    metadata.right_value,
    WATER_MEASUREMENT_DECIMAL,
    "",
    "-"
  )}`;
};

const displayProperties = () => {
  return [
    {
      label: "Water",
      key: "Water",
      getMetadata: (measurement) => waterMeasurement(measurement),
    },
    {
      label: "Water & Sediment",
      key: "Water & Sediment",
      getMetadata: (measurement) => waterMeasurement(measurement),
    },
    {
      label: "Water & Sediment & Interface",
      key: "Water & Sediment & Interface",
      getMetadata: (measurement) => waterMeasurement(measurement),
    },
    {
      label: "Method",
      key: "method",
      notMeasurement: true,
    },
    {
      label: "Diluted",
      key: "diluted",
      getValue: ({ value }) => (value ? "Yes" : "No"),
      notMeasurement: true,
    },
    {
      label: "Demulsifier Added",
      key: "demulsifier",
      getValue: ({ value }) => (value ? "Yes" : "No"),
      notMeasurement: true,
    },
  ];
};

export default displayProperties;
