import {
  CUBIC_METERS_VOLUME,
  getFormattedMeasurementValue,
} from "#redux/reducers/measurements";
import { LegacyDataTable } from "@validereinc/common-components";
import * as PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { AutoSizer } from "react-virtualized";
import { getComponentShortForm } from "../../RecordOfQualityHelper";
import "./SystemBalance.scss";
import { SystemBalanceProperties } from "./SystemBalanceConstant";
import { getPropertiesValue } from "./SystemBalanceHelper";
import "./SystemBalanceTable.scss";

const PROPERTY_COLUMN_WIDTH = 135;

const NAME_COLUMN_WIDTH = 100;

const SYSTEM_BALANCE_TABLE_HEIGHT_OFFSET = 480;

const mapStateToProps = (state) => {
  return {
    getFormattedMeasurementValue: (measurementKey, measurementValue) =>
      getFormattedMeasurementValue(state.measurements)(
        measurementKey,
        measurementValue
      ),
  };
};

const SystemBalanceTable = (props) => {
  const {
    components,
    qualities,
    displayProperties,
    getFormattedMeasurementValue,
  } = props;

  const nameRenderer = (rowData) => {
    return (
      <div className="systemBalanceTable__name">
        <span className="systemBalanceTable__row capitalized">
          {getComponentShortForm(rowData, props.componentVolumeConfig)}
        </span>
      </div>
    );
  };

  const measurementRenderer = (rowData, columnKey) => {
    const { value, unit } = getPropertiesValue(
      components,
      qualities,
      rowData,
      columnKey
    );

    const measurementKey = unit !== "%" ? CUBIC_METERS_VOLUME : null;

    return value ? (
      <div className="systemBalanceTable__row">
        <div className="systemBalanceTable__row__value">
          {getFormattedMeasurementValue(measurementKey, value)}
        </div>
        <div className="systemBalanceTable__row__unit">{unit}</div>
      </div>
    ) : (
      "-"
    );
  };

  const SystemBalancePropertiesColumn = SystemBalanceProperties.map(
    (property) => ({
      label: property.name,
      key: property.id,
      width: PROPERTY_COLUMN_WIDTH,
      cellRenderer: measurementRenderer,
      rightAlign: true,
      disableSort: true,
    })
  );

  const Headers = [
    {
      label: "Properties",
      key: name,
      width: NAME_COLUMN_WIDTH,
      cellRenderer: nameRenderer,
      disableSort: true,
      fixed: true,
    },
    ...SystemBalancePropertiesColumn,
  ];

  const height = props.height - SYSTEM_BALANCE_TABLE_HEIGHT_OFFSET;

  return (
    <div className="systemBalanceTableContainer">
      <div
        className="systemBalanceTable"
        style={{ height: `${height}px` }}
      >
        <AutoSizer>
          {({ width, height }) => (
            <LegacyDataTable
              headers={Headers}
              list={displayProperties}
              width={width}
              height={height}
              rowHeight={40}
              collapseBorder={true}
            />
          )}
        </AutoSizer>
      </div>
    </div>
  );
};

SystemBalanceTable.propTypes = {
  components: PropTypes.object.isRequired,
  qualities: PropTypes.object,
  componentVolumeConfig: PropTypes.object,
  balanceType: PropTypes.string.isRequired,
  total: PropTypes.object.isRequired,
  displayProperties: PropTypes.array.isRequired,
  height: PropTypes.number.isRequired,
  getFormattedMeasurementValue: PropTypes.func,
};

export default connect(mapStateToProps)(SystemBalanceTable);
