import { useState, useEffect } from "react";
import RecordOfQualityService from "#services/RecordOfQualityService";

const useFetchResults = (accountingPeriod, isInitialLoad, setIsInitialLoad) => {
  const [systemBalance, setSystemBalance] = useState({});
  const [initialSystemBalanceResult, setInitialSystemBalanceResult] = useState(
    {}
  );

  const [state, setState] = useState("initial");
  const [lastUpdatedAt, setLastUpdatedAt] = useState(new Date());

  useEffect(() => {
    if (accountingPeriod?.id) {
      setState(isInitialLoad ? "loading" : "refetching");

      RecordOfQualityService.getSystemBalance(accountingPeriod.id)
        .then(({ data }) => {
          if (isInitialLoad) {
            setInitialSystemBalanceResult(data);
            setIsInitialLoad(false);
          }
          setSystemBalance({
            ...data,
            ...getMockSourcesData(accountingPeriod),
          });
          setState("loaded");
        })
        .catch(() => setState("error"));
    }
  }, [accountingPeriod?.id, lastUpdatedAt]);

  const refetchResults = () => {
    setLastUpdatedAt(new Date());
  };

  return [systemBalance, state, initialSystemBalanceResult, refetchResults];
};

const getMockSourcesData = ({ until, site_system }) => {
  const month = new Date(until).getMonth();

  let mockSources = {
    inlets: ["id"],
    outlets: ["id"],
    name: `${site_system.name.replace("System", "Inlets and Outlets")}`,
  };

  if (month % 3 === 1) {
    mockSources = {
      inlets: ["id"],
      name: `${site_system.name.replace("System", "Inlets")}`,
    };
  } else if (month % 3 === 2) {
    mockSources = {
      outlets: ["id"],
      name: `${site_system.name.replace("System", "Outlets")}`,
    };
  }

  return mockSources;
};

const useFetchBalances = () => {
  const [balances, setBalances] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    RecordOfQualityService.getAccountingPeriods()
      .then(({ data }) => {
        // TO DO: replace with updated balances api
        setBalances(
          data.map((d) => ({
            ...d,
            ...getMockSourcesData(d),
          }))
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return [balances, isLoading];
};

/**
 * This hook handles all the fetches related to showing the results of the current
 * roq selection to the record balance and overall system balance. It syncs the
 * roq selection state to the backend data fetched
 */
const useManageResultFetches = (updateResultsData) => {
  const [balances, isBalancesLoading] = useFetchBalances();
  const [selectedBalance, setSelectedBalance] = useState(undefined);
  const [isInitialBalanceFetch, setIsInitialBalanceFetch] = useState(true);

  const [
    systemBalance,
    loadingState,
    initialSystemBalanceResult,
    refetchResults,
  ] = useFetchResults(
    selectedBalance,
    isInitialBalanceFetch,
    setIsInitialBalanceFetch
  );

  const onBalanceSelect = (balance) => {
    setSelectedBalance(balance);
    setIsInitialBalanceFetch(true);
  };

  useEffect(() => {
    refetchResults();
  }, [updateResultsData]);

  return [
    systemBalance,
    loadingState,
    initialSystemBalanceResult,
    balances,
    isBalancesLoading,
    selectedBalance,
    onBalanceSelect,
  ];
};

export default useManageResultFetches;
