import React from "react";

import { UserDetailStatusPanel } from "#src/batteries-included-components/Panels";

import { RolesKeyValuePanel } from "./RolesKeyValuePanel";

import styles from "./UserRolesTab.module.scss";
import classNames from "classnames/bind";

const cx = classNames.bind(styles);

export const UserRolesTab = () => {
  return (
    <div className={cx("container")}>
      <div className={cx("contentStatus")}>
        <UserDetailStatusPanel />
      </div>

      <div className={cx("contentContainer")}>
        <RolesKeyValuePanel />
      </div>
    </div>
  );
};
