import {
  apiRequestServicesPromise,
  nodeApiDeleteServicesPromise,
  nodeApiPostServicesPromise,
  nodeApiPutServicesPromise,
  nodeApiRequestServicesPromise,
} from "./ServiceHelper";
import { getFilterObject } from "#utils/api-abstraction";

import type { PaginationAndSortParams } from "./types";

const EquipmentService = {
  getEquipments() {
    return apiRequestServicesPromise("/api/equipment");
  },

  getSavedEquipmentFilters({
    page,
    rowPerPage,
    sort,
    sortDirection,
  }: Partial<PaginationAndSortParams>) {
    return nodeApiRequestServicesPromise("/equipment/filters", {
      page,
      page_size: rowPerPage,
      sort_by: sort,
      sort_direction: sortDirection,
    });
  },

  saveEquipmentFilter(name, filters) {
    return nodeApiPostServicesPromise("/equipment/filters", {
      name,
      filter: getFilterObject(filters),
    });
  },

  editEquipmentFilter(id, filter) {
    return nodeApiPutServicesPromise(`/equipment/filters/${id}`, filter);
  },

  deleteEquipmentFilter(id) {
    return nodeApiDeleteServicesPromise(`/equipment/filters/${id}`);
  },
};

export default EquipmentService;
