import { z } from "zod";

export const FeatureFlagSchema = z
  .object({
    // TODO: is it possible to represent the exact FF format here?
    // the name of the flag
    name: z.string(),
    // the version number - this could be a union in the future
    version: z.literal("v1"),
    // is the feature flag globally enabled?
    isEnabled: z.boolean(),
    // is the feature flag set to be deprecated?
    isDeprecated: z.boolean(),
  })
  .describe("Represents a Feature Flag model in the Node API");

export type FeatureFlagType = z.infer<typeof FeatureFlagSchema>;
