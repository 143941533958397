import { TimezoneDropdownInput } from "#src/batteries-included-components";
import {
  CheckboxInput,
  Panel,
  TelephoneInput,
  TextInput,
} from "@validereinc/common-components";
import { UserUpdateSchema, zod } from "@validereinc/domain";
import React from "react";

export const UserUpdateFormSchema = UserUpdateSchema.merge(
  zod.object({
    roles: zod.array(zod.string()).optional(),
  })
);
export type UserUpdateFormType = zod.infer<typeof UserUpdateFormSchema>;

export const EditUserFormPanel = () => {
  const formKeys = UserUpdateFormSchema.keyof().Enum;

  return (
    <>
      <Panel title="Details">
        <div style={{ width: 250 }}>
          <TextInput
            name={formKeys.name}
            label="Name"
            placeholder="Name"
            isRequired
          />
          <TextInput
            name="email"
            label="Email"
            placeholder="Email"
            isDisabled
            isRequired
          />
          <TelephoneInput
            name={formKeys.phone}
            inputId={formKeys.phone}
            label="Phone Number"
          />
          <TimezoneDropdownInput
            name={formKeys.timezone}
            inputId={formKeys.timezone}
          />
        </div>
      </Panel>
      <Panel title="Roles & Permissions">
        <CheckboxInput
          name="quicksight"
          label="User has access to Insights Dashboards"
          isLabelShown={false}
          isFluid
        />
      </Panel>
    </>
  );
};
