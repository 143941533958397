import { Button, Title } from "@validereinc/common-components";
import isEqual from "lodash/isEqual";
import * as PropTypes from "prop-types";
import React from "react";
import { Checkbox } from "react-bootstrap";
import { getSourceTitle } from "../BalanceHelper";
import InventorySourceTable from "./Inventory/InventorySourceTable";
import SourceTable from "./SourceTable";

const areEqual = (prevProps, nextProps) => {
  return (
    isEqual(prevProps.sources, nextProps.sources) &&
    prevProps.lastUpdatedTime === nextProps.lastUpdatedTime &&
    prevProps.isDisabled === nextProps.isDisabled
  );
};

const SourceInput = React.memo(
  ({
    title,
    sourceKey,
    sources,
    openSourceSelectionModal,
    onSyncInletClick,
    isInventory,
    showMultiplier,
    onSourceChecked,
    onEditClick,
    isDisabled,
  }) => {
    const shareProps = {
      sourceKey,
      sources,
      showMultiplier,
      onSourceChecked,
      onEditClick,
    };

    return (
      <div className="sourceInput">
        <Title
          type="subheader"
          className="balancesForm__title"
        >
          {title}
        </Title>

        {openSourceSelectionModal && (
          <div className="sourceInput__actionRow">
            <div className="buttonContainer">
              <Button
                onClick={() => openSourceSelectionModal(sourceKey, "add")}
                disabled={isDisabled}
              >
                Add Source
              </Button>

              {sourceKey?.includes("reference") && (
                <Checkbox
                  disabled={isDisabled}
                  onChange={() => onSyncInletClick(sourceKey)}
                >
                  Sync with inlet {getSourceTitle(sourceKey)}
                </Checkbox>
              )}
            </div>
          </div>
        )}

        {isInventory ? (
          <InventorySourceTable
            {...shareProps}
            title="Sample List"
            disabled={isDisabled}
          />
        ) : (
          <SourceTable
            {...shareProps}
            title="Sample List"
            disabled={isDisabled}
          />
        )}
      </div>
    );
  },
  (prevProps, nextProps) => areEqual(prevProps, nextProps)
);

// Used for debugging, React.memo messes with automatic naming
SourceInput.displayName = "SourceInput";

SourceInput.propTypes = {
  title: PropTypes.string.isRequired,
  sourceKey: PropTypes.string,
  sources: PropTypes.array.isRequired,
  openSourceSelectionModal: PropTypes.func,
  syncWithInlet: PropTypes.bool,
  onSyncInletClick: PropTypes.func,
  isInventory: PropTypes.bool.isRequired,
  showMultiplier: PropTypes.bool.isRequired,
  onSourceChecked: PropTypes.func,
  onEditClick: PropTypes.func,
  isDisabled: PropTypes.bool,
};

export default SourceInput;
