import React from "react";

import { EventsDomain } from "@validereinc/domain";

import { toFlattenedObject } from "@validereinc/utilities";

// components
import {
  Button,
  CheckboxInput,
  DateSelectorInput,
  Dialog,
  Form,
  TextInput,
  useAlert,
  useForm,
} from "@validereinc/common-components";

import { validateDates } from "./EventDialog.utils";

import { useMutation, useQueryClient } from "@tanstack/react-query";
import type { EventDetailsType, EventSchemaType } from "@validereinc/domain";

type FormValues = {
  end?: string | null;
  start: string;
  is_ongoing: boolean;
  description: string;
  due_date: string;
};

export const EditEventDialog = ({
  event,
  eventTemplate,
  onClose,
  ...restProps
}: {
  event: EventDetailsType;
  eventTemplate: EventSchemaType;
  isOpen?: boolean;
  onClose: (shouldRefresh?: boolean) => void;
}) => {
  const { addAlert } = useAlert();

  const form = useForm({
    defaultValues: {
      description: event.description,
      start: new Date(event.start),
      ...(event.end
        ? { end: new Date(event.end), is_ongoing: false }
        : { is_ongoing: true }),
    },
  });

  const queryClient = useQueryClient();
  const { isLoading, mutate: editEvent } = useMutation({
    mutationFn: async (formValues: FormValues) => {
      const updatedEvent = {
        eventId: event.id,
        ...formValues,
      };

      if (updatedEvent.is_ongoing) {
        // We need to send null on the end to the api do that it knows the end
        // needs to be removed
        updatedEvent.end = null;
      }

      await EventsDomain.edit(toFlattenedObject(updatedEvent));
      queryClient.invalidateQueries({
        queryKey: ["events", event.id],
      });
    },
  });

  const isOngoing = form.watch("is_ongoing");
  const start = form.watch("start");

  const onFormSubmit = form.handleSubmit(async ({ ...values }) => {
    const formValues = values as FormValues;

    const validationError = validateDates(formValues);

    if (validationError) {
      form.setError(validationError.type, {
        message: validationError.message,
      });
      return;
    }

    editEvent(formValues, {
      onSuccess: () => {
        onClose(true);
        addAlert?.({
          variant: "success",
          message: `Successfully edited "${eventTemplate?.name}" event.`,
        });
      },
      onError: (error) => {
        console.error(error);

        addAlert?.({
          variant: "error",
          message: "Failed to edit event. Please try again.",
        });
      },
    });
  });

  return (
    <Dialog
      title={`Edit ${eventTemplate ? `${eventTemplate?.name} ` : ""}Event`}
      {...restProps}
      onClose={() => onClose(false)}
      actionRow={[
        <Button
          key="edit-event-button"
          variant="primary"
          isLoading={isLoading}
          onClick={onFormSubmit}
          disabled={form.formState.isSubmitting}
        >
          Save
        </Button>,
      ]}
    >
      <Form {...form}>
        <TextInput
          name="description"
          label="Description"
        />

        <CheckboxInput
          name="is_ongoing"
          label="Event is Ongoing"
          isLabelShown={false}
          isFluid
        />

        <DateSelectorInput
          variant="time"
          name="start"
          label="Start Date"
          isFluid
          isRequired
        />

        {!isOngoing ? (
          <DateSelectorInput
            variant="time"
            name="end"
            label="End Date"
            isFluid
            isRequired
            isDisabled={!start}
            min={start}
          />
        ) : null}

        <DateSelectorInput
          variant="time"
          name="due_date"
          label="Due Date"
          isFluid
          isDisabled={!start}
          min={start}
        />
      </Form>
    </Dialog>
  );
};
