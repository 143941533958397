import {
  apiRequestServicesPromise,
  apiPostServicesPromise,
  apiPutServicesPromise,
} from "./ServiceHelper";
import moment from "moment";

// fixes a bug where this was being sent as a string like "2" instead of a number 2
const updateFrequency = (frequency) => {
  if (frequency.type === "weekly") {
    const weeklyFrequencyAsNumber = Number(frequency.repeat_every_x_weeks);
    frequency.repeat_every_x_weeks = weeklyFrequencyAsNumber;
  } else if (frequency.type === "monthly") {
    const monthlyFrequencyAsNumber = Number(frequency.repeat_every_x_months);
    frequency.repeat_every_x_months = monthlyFrequencyAsNumber;
  }
  return frequency;
};

const WorkflowService = {
  // Get workflows
  getWorkflow() {
    return apiRequestServicesPromise("/api/workflows");
  },

  // Get workflow for user not for the company
  // TODO: in the future we need to add get workflow detail for the company
  getWorkflowDetail(workflowId) {
    return apiRequestServicesPromise(`/api/workflows/` + workflowId);
  },

  archiveWorkflow(workflowId) {
    const parameter = {
      archive: true,
    };

    return apiPutServicesPromise(`/api/workflows/${workflowId}`, parameter);
  },

  createWorkflow(workflowForm) {
    if (workflowForm?.frequency) {
      workflowForm.frequency = updateFrequency(workflowForm.frequency);
    }
    const parameter = {
      name: workflowForm.name,
      start_time_utc_epoch: workflowForm.startTime,
      frequency: workflowForm.frequency,
      occurrences_left: workflowForm.occurrences,
      tests_required: workflowForm.testsRequired,
      test_type: workflowForm.testType,
      alert_settings: workflowForm.alertSettings,
      site_ids: workflowForm.siteId,
      stream_ids: workflowForm.streamIds,
      timezone: workflowForm.timezone,
      repeats_per_scheduling_period: workflowForm.repeats_per_scheduling_period,
      offspec_retest: workflowForm.offspec_retest,
      offspec_retest_3pl: workflowForm.offspec_retest_3pl,
    };

    return apiPostServicesPromise(`/api/workflows`, parameter);
  },

  updateWorkflow(workflowForm) {
    if (workflowForm?.frequency) {
      workflowForm.frequency = updateFrequency(workflowForm.frequency);
    }
    const parameter = {
      name: workflowForm.name,
      start_time_utc_epoch: workflowForm.startTime,
      frequency: workflowForm.frequency,
      occurrences_left: workflowForm.occurrences,
      tests_required: workflowForm.testsRequired,
      test_type: workflowForm.testType,
      alert_settings: workflowForm.alertSettings,
      site_ids: workflowForm.siteId,
      stream_ids: workflowForm.streamIds,
      update_active: workflowForm.updateActive,
      timezone: workflowForm.timezone,
      repeats_per_scheduling_period: workflowForm.repeats_per_scheduling_period,
      offspec_retest: workflowForm.offspec_retest,
      offspec_retest_3pl: workflowForm.offspec_retest_3pl,
    };

    return apiPutServicesPromise(
      `/api/workflows/${workflowForm.workflowId}`,
      parameter
    );
  },

  // Get workflow instances
  getWorkflowInstance(workflowId) {
    return apiRequestServicesPromise(`/api/workflows/${workflowId}/instances`);
  },

  // Get workflow instances for specific user
  getWorkflowInstanceForUser(startDate, endDate) {
    let parameter = {};

    if (startDate && endDate) {
      parameter = {
        from: startDate,
        until: endDate,
      };
    }

    return apiRequestServicesPromise(`/api/workflows/instances`, parameter);
  },

  getWorkflowPDFDownloadLink(from, until, filters) {
    const parameter = {
      from: moment(from).toISOString(),
      until: moment(until).toISOString(),
      ...filters,
    };

    return apiRequestServicesPromise(
      "/api/workflows/instances/pdf/url",
      parameter
    );
  },

  getWorkflowCSVDownloadLink(from, until, filters) {
    const parameter = {
      from: moment(from).toISOString(),
      until: moment(until).toISOString(),
      ...filters,
    };

    return apiRequestServicesPromise(
      "/api/workflows/instances/csv/url",
      parameter
    );
  },

  updateWorkflowInstanceStatus(workflowInstanceId, status) {
    return apiPostServicesPromise(
      `/api/workflows/instances/${workflowInstanceId}/${status}`,
      {}
    );
  },
};

export default WorkflowService;
