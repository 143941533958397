import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { Row, Col } from "react-bootstrap";

import CentrifugeReaderDescriptionPanel from "./ValidereWaterInstrument/CentrifugeReaderDescriptionPanel";
import VaporDescriptionPanel from "./ValidereVaporInstrument/ValidereVaporDescriptionPanel";
import EralyticDescriptionPanel from "./EralyticsVaporInstrument/EralyticDescriptionPanel";
import RigakuSulphurDescriptionPanel from "./RigakuSulphurInstrument/RigakuSulphurDescriptionPanel";
import AntonPaarDensityDescriptionPanel from "./AntonPaarDensity4500Instrument/AntonPaarDensityDescriptionPanel";
import AntonPaarSVM3001DescriptionPanel from "./AntonPaarSVM3001Instrument/AntonPaarSVM3001DescriptionPanel";
import RudolphDensityDescriptionPanel from "./RudolphDensityInstrument/RudolphDensityDescriptionPanel";
import HoribaSulphurDescriptionPanel from "./HoribaSulphurInstrument/HoribaSulphurDescriptionPanel";
import LatestSamplesPanel from "./LatestSamplesPanel";
import AgilentGCDescriptionPanel from "./AgilentGCInstrument/AgilentGCDescriptionPanel";
import AquamaxKFDescriptionPanel from "./AquamaxKFInstrument/AquamaxKFDescriptionPanel";
import GenericDescriptionPanel from "./Generic/GenericDescriptionPanel";

import { getSortedList } from "./InstrumentHelper";
import InstrumentService from "../Services/InstrumentService";
import moment from "moment";
import { connect } from "react-redux";
import { addAlertMessage } from "../Redux/actions/alertMessages";
import GrabnerMiniVapDescriptionPanel from "./GrabnerMiniVapInstrument/GrabnerMiniVapDescriptionPanel";

const DEFAULT_DATE_RANGE = 7; // last 7 days of test

const mapDispatchToProps = {
  addAlertMessage,
};

export class InstrumentSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      instrumentId: this.props.match.params.instrumentId,
      instrumentInfo: [],
      sampleList: [],
    };

    this.getSampleList = this.getSampleList.bind(this);
  }

  componentDidMount() {
    this.getSampleList();
  }

  getSampleList() {
    InstrumentService.getInstrumentTests(this.state.instrumentId, {
      from: moment()
        .subtract(DEFAULT_DATE_RANGE, "days")
        .startOf("day")
        .toDate(),
      until: moment().endOf("day").toDate(),
    })
      .then(({ data }) => {
        const sortList = getSortedList(data, "date", true, false);
        this.setState({ sampleList: sortList });
      })
      .finally(() => {
        this.setState({
          loaded: true,
        });
      });
  }

  renderDescriptionPanel() {
    const { instrumentInfo } = this.props;

    switch (instrumentInfo.type) {
      case "water": {
        return (
          <CentrifugeReaderDescriptionPanel instrumentInfo={instrumentInfo} />
        );
      }
      case "validere vapor": {
        return <VaporDescriptionPanel instrumentInfo={instrumentInfo} />;
      }
      case "eralytics vapor": {
        return <EralyticDescriptionPanel instrumentInfo={instrumentInfo} />;
      }
      case "rigaku xray fluorescence": {
        return (
          <RigakuSulphurDescriptionPanel instrumentInfo={instrumentInfo} />
        );
      }
      case "anton paar svm 3001": {
        return (
          <AntonPaarSVM3001DescriptionPanel instrumentInfo={instrumentInfo} />
        );
      }
      case "anton paar density": {
        return (
          <AntonPaarDensityDescriptionPanel instrumentInfo={instrumentInfo} />
        );
      }
      case "rudolph research density": {
        return (
          <RudolphDensityDescriptionPanel instrumentInfo={instrumentInfo} />
        );
      }
      case "horiba xray fluorescence": {
        return (
          <HoribaSulphurDescriptionPanel instrumentInfo={instrumentInfo} />
        );
      }
      case "agilent gas chromatography": {
        return <AgilentGCDescriptionPanel instrumentInfo={instrumentInfo} />;
      }
      case "aquamax kf": {
        return <AquamaxKFDescriptionPanel instrumentInfo={instrumentInfo} />;
      }
      case "grabner minivap vpxpert": {
        return (
          <GrabnerMiniVapDescriptionPanel instrumentInfo={instrumentInfo} />
        );
      }
      default: {
        return <GenericDescriptionPanel instrumentInfo={instrumentInfo} />;
      }
    }
  }

  render() {
    const { sampleList } = this.state;

    return (
      <div className="instrumentSummary">
        <Row>
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={6}
          >
            {this.renderDescriptionPanel()}
          </Col>
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={6}
          >
            <LatestSamplesPanel
              sampleList={sampleList}
              loaded={this.state.loaded}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

InstrumentSummary.propTypes = {
  instrumentInfo: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default connect(null, mapDispatchToProps)(InstrumentSummary);
