import DeleteModal from "#components/Common/DeleteModal/DeleteModal";
import DataMappingService from "#components/Services/DataMappingService";
import { havePermission } from "#redux/reducers/permissions";
import { getBreadcrumbsObject } from "#routers/breadcrumbsHelper";
import { useNavigate } from "#routers/hooks";
import { linkToDataMapping } from "#routers/links";
import useTableState from "#src/hooks/useTableState";
import { getFrontendTableState } from "#utils/frontendTableActions";
import { Button, Page } from "@validereinc/common-components";
import * as PropTypes from "prop-types";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import MappingOverviewPanel from "./MappingOverviewPanel";
import UploadHistoryPanel from "./UploadHistoryPanel";

const INITIAL_SORT = {
  header: "date_created",
  direction: "desc",
};

const mapStateToProps = ({ permissions }) => {
  return {
    haveWritePermission: havePermission(permissions)(
      "core:data_ingest",
      "write"
    ),
  };
};

export const DataMappingDetail = ({ breadcrumbs, haveWritePermission }) => {
  const { id } = useParams();
  const [dataMappingDetail, setDataMappingDetail] = useState({});
  const [fetchState, setFetchState] = useState("loading");
  const [response, setResponse] = useState(undefined);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const navigate = useNavigate();

  const handleDeleteClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const onFetchData = useCallback(
    async (newSearchParams) => {
      let newResponse = response;

      if (!response) {
        newResponse = await DataMappingService.getDataMappingTasks(id);

        setResponse(newResponse);
      }

      const searchParamsWithDefaults = {
        ...newSearchParams,
        sort: newSearchParams.sort ?? INITIAL_SORT.header,
        sortDirection: newSearchParams.sortDirection ?? INITIAL_SORT.direction,
      };

      return getFrontendTableState({
        data: newResponse,
        itemsKey: "data",
        query: searchParamsWithDefaults,
      });
    },
    [response]
  );

  const { tableProps } = useTableState({
    onFetchData,
    initialSort: INITIAL_SORT,
  });

  useEffect(() => {
    DataMappingService.getDataMappingDetail(id)
      .then(({ data }) => {
        setDataMappingDetail(data);
        setFetchState("loaded");
      })
      .catch(() => {
        setFetchState("error");
      });
  }, []);

  const dataMappingDetailBreadcrumb = useMemo(() => {
    const newBreadcrumbs = getBreadcrumbsObject(breadcrumbs);
    if (dataMappingDetail?.mapping_name) {
      newBreadcrumbs[newBreadcrumbs.length - 1].title =
        dataMappingDetail.mapping_name;
    }

    return newBreadcrumbs;
  }, [breadcrumbs, dataMappingDetail]);

  return (
    <Page
      title={dataMappingDetail?.mapping_name ?? "-"}
      breadcrumbs={dataMappingDetailBreadcrumb}
      actionRow={
        haveWritePermission ? (
          <Button
            variant="error-outline"
            onClick={handleDeleteClick}
          >
            Delete
          </Button>
        ) : null
      }
    >
      <MappingOverviewPanel
        dataMappingDetail={dataMappingDetail}
        taskList={tableProps.items}
        isLoading={fetchState === "loading"}
      />

      <UploadHistoryPanel
        tableProps={tableProps}
        navigate={navigate}
        dataMappingId={id}
      />

      <DeleteModal
        open={isModalOpen}
        instanceName={dataMappingDetail?.mapping_name}
        entityName="Data Mapping"
        onClose={handleCloseModal}
        doDelete={() => DataMappingService.deleteDataMapping(id)}
        onDelete={() => {
          navigate({
            pathname: linkToDataMapping(),
          });
        }}
      />
    </Page>
  );
};

DataMappingDetail.propTypes = {
  breadcrumbs: PropTypes.array.isRequired,
  haveWritePermission: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(DataMappingDetail);
