import ModernError, { type InstanceOptions } from "modern-errors";

export type ErrorCtx = Record<string, string | undefined>;

export interface HTTPForbiddenErrorCtx extends ErrorCtx {
  target?: string;
}

export interface HTTPNotFoundErrorCtx extends ErrorCtx {
  resource?: string;
  resourceId?: string;
}

export interface AuthAccountNotFoundErrorCtx extends ErrorCtx {
  email?: string;
}

export interface BaseErrorProps {
  /** the reference code of the error */
  code: (typeof ErrorCodes)[keyof typeof ErrorCodes];
  /** the user-facing error message */
  getExplanation: <T extends ErrorCtx = ErrorCtx>(ctx?: T) => string;
  /** the link the user can take to understand the error better, if available */
  referenceURL: string;
}

export const ErrorCodes = {
  "general, unknown error": "G-0",
  "http, unknown error": "H-0",
  "http, status 404": "H-S404",
  "http, status 403": "H-S403",
  "authentication, account not found": "A-A404",
} as const;

export const ErrorCodesToInternalLabels = Object.entries(ErrorCodes).reduce<
  Record<(typeof ErrorCodes)[keyof typeof ErrorCodes], string>
>(
  (map, [key, value]) => {
    map[value] = key;
    return map;
  },
  {} as Record<(typeof ErrorCodes)[keyof typeof ErrorCodes], string>
);

export const ErrorCodesToExplanationGetters: Record<
  (typeof ErrorCodes)[keyof typeof ErrorCodes],
  BaseErrorProps["getExplanation"]
> = {
  "G-0": () => "An unknown error occurred",
  "H-0": () => "An unknown network error occurred",
  "H-S404": (ctx: HTTPNotFoundErrorCtx = {}) =>
    ctx.resource && ctx.resourceId
      ? `The resource "${ctx.resource}" with id ${ctx.resourceId} was not found.`
      : "The resource was not found.",
  "H-S403": (ctx: HTTPForbiddenErrorCtx = {}) =>
    ctx.target
      ? `You do not have the necessary permissions to complete the "${ctx.target}" action.`
      : "You do not have the necessary permissions to complete the action.",
  "A-A404": (ctx: AuthAccountNotFoundErrorCtx = {}) =>
    ctx.email
      ? `User account for ${ctx.email} not found`
      : "User account not found",
};

/**
 * Custom error class for all domain-specific or intentional errors thrown from
 * the domain package that is consumed by all our front-ends.
 *
 * Every error has an associated error code, a function to get an error
 * explanation, and a reference URL, all intended to be shown to the end-user.
 *
 * @example
 * if (err instanceof BaseError) {
 *   // properties that can be accessed: err.code, err.getExplanation({...}), err.referenceURL
 * }
 */
export const BaseError = ModernError.subclass("BaseError", {
  custom: class extends ModernError {
    _isValidereDefined = true;
    code: BaseErrorProps["code"];
    getExplanation: BaseErrorProps["getExplanation"];
    referenceURL: BaseErrorProps["referenceURL"];

    constructor(
      message: string,
      options: InstanceOptions & Partial<BaseErrorProps> = {}
    ) {
      super(message, options);

      this.code = options.code ?? ErrorCodes["general, unknown error"];
      this.getExplanation =
        options.getExplanation ??
        ErrorCodesToExplanationGetters[ErrorCodes["general, unknown error"]];
      this.referenceURL =
        options.referenceURL ?? "https://support.validere.com/";
    }
  },
});
