import { useListCustomAttributes } from "#src/components/hooks/adapters/useCustomAttributes";
import { AssetType } from "@validereinc/domain";

const STATIC_EQUIPMENT_FILTERS = [
  {
    display_name: "Name",
    field_name: "name",
    data_type: "string",
  },
  {
    display_name: "Type",
    field_name: "type.id",
    data_type: "lookup",
    lookup_type: "equipmentType",
  },
  {
    display_name: "Facility",
    field_name: "facility.id",
    data_type: "lookup",
    lookup_type: "facility",
  },
];

export const useEquipmentFilters = () => {
  const { data, isLoading } = useListCustomAttributes({
    filters: { entity_type: AssetType.EQUIPMENT },
  });
  return isLoading
    ? []
    : [
        ...STATIC_EQUIPMENT_FILTERS,
        ...(data?.data.map(({ field_name, ...restOption }) => ({
          ...restOption,
          field_name: `custom_attributes.${field_name}`,
        })) ?? []),
      ];
};
