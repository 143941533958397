import { useAlertDetail } from "#components/hooks/useAlertDetail";
import { getBreadcrumbsObject } from "#routers/breadcrumbsHelper";
import { useParams } from "#routers/hooks";
import config from "#src/config";
import { getTimeStringFromUTCDate } from "#utils/timeFormatter";
import {
  Button,
  ContentSwitcher,
  Page,
  Panel,
} from "@validereinc/common-components";
import * as PropTypes from "prop-types";
import React, { useState } from "react";
import { getAlertTimestamp } from "../AlertListHelpers";
import AlertConditionContainer from "./AlertConditionContainer";
import CloseAlertModal from "./CloseAlertModal";

export const MAX_ALERT_ID_LENGTH = 11;

const AlertDetail = ({ breadcrumbs }) => {
  const { alertId } = useParams();

  const [isCloseModalOpen, setIsCloseModalOpen] = useState(false);
  const [activeSwitcher, setActiveSwitcher] = useState(0);

  const handleCloseClick = () => {
    setIsCloseModalOpen(true);
  };

  const [alertDetail, isLoadingAlertDetail] = useAlertDetail(alertId);

  const actionRow =
    alertDetail?.resolved === false ? (
      <Button
        variant="error-outline"
        onClick={handleCloseClick}
      >
        Close
      </Button>
    ) : undefined;

  const conditions = alertDetail?.monitor?.conditions
    ? alertDetail?.monitor?.conditions.map((condition, index) => {
        return {
          dataKey: index,
          label: `Condition ${index + 1}`,
        };
      })
    : [
        {
          dataKey: 0,
          label: "Condition 1",
        },
      ];

  const alertDate = getTimeStringFromUTCDate(
    getAlertTimestamp(alertDetail),
    config.DATETIME_FORMAT_READABLE
  );

  return (
    <Page
      title={`${alertDetail?.monitor?.name} Alert - ${alertDate}`}
      breadcrumbs={getBreadcrumbsObject(breadcrumbs)}
      actionRow={actionRow}
      isLoading={isLoadingAlertDetail}
    >
      {conditions?.length > 1 ? (
        <Panel>
          <ContentSwitcher
            activeKey={activeSwitcher}
            items={conditions}
            onChange={(newActiveKey) => setActiveSwitcher(newActiveKey)}
          />
        </Panel>
      ) : null}
      <AlertConditionContainer
        alertDetail={alertDetail}
        conditionIndex={activeSwitcher}
      />

      {isCloseModalOpen && (
        <CloseAlertModal
          open={isCloseModalOpen}
          onClose={() => setIsCloseModalOpen(false)}
          alertId={alertId}
        />
      )}
    </Page>
  );
};

AlertDetail.propTypes = {
  breadcrumbs: PropTypes.array.isRequired,
};

export default AlertDetail;
