import UserService from "#services/UserService";
import { Button, Modal, Title, useAlert } from "@validereinc/common-components";
import * as PropTypes from "prop-types";
import React, { useState } from "react";
import "./CreateApiTokenModal.scss";

const DeleteApiTokenModal = ({
  apiTokenDetail,
  userId,
  show,
  onHide,
  updateApiTokenList,
}) => {
  const [loadingState, setLoadingState] = useState("loaded");
  const { name, id } = apiTokenDetail;
  const { addAlert } = useAlert();

  const onDeleteClick = () => {
    setLoadingState("loading");

    if (apiTokenDetail) {
      UserService.deleteApiTokens(userId, id)
        .then(() => {
          addAlert({
            variant: "success",
            message: "API Token has been successfully removed.",
          });
          updateApiTokenList();
          onHide();
        })
        .finally(() => {
          setLoadingState("loaded");
        });
    }
  };

  return (
    <Modal
      className="deleteApiTokenModal"
      open={show}
      onClose={onHide}
    >
      <Modal.Header>
        <Title>Delete API Token</Title>
      </Modal.Header>

      <Modal.Body>
        <div>
          Are you sure you want to delete{" "}
          <b>{name && name !== "" ? name : id}</b> API Token?
          <br />
          By doing this you will revoke access from any services that are using
          this token.
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button
          className="pull-left"
          onClick={onHide}
        >
          Close
        </Button>

        <Button
          variant="error"
          onClick={onDeleteClick}
          isLoading={loadingState === "loading"}
        >
          Yes, I would like to delete this token.
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

DeleteApiTokenModal.propTypes = {
  show: PropTypes.bool.isRequired,
  userId: PropTypes.number.isRequired,
  onHide: PropTypes.func.isRequired,
  apiTokenDetail: PropTypes.object.isRequired,
  updateApiTokenList: PropTypes.func.isRequired,
};

export default DeleteApiTokenModal;
