import {
  linkToEditMember,
  linkToEditRoleSetting,
  linkToEditWorkflow,
  linkToViewChainOfCustody,
  linkToCompanySettings,
  linkToRoleNotificationSetting,
} from "../../../../Routers/links";

export const getActivityLink = (resourceType, resourceId) => {
  switch (resourceType) {
    case "user": {
      return linkToEditMember(resourceId);
    }
    case "role": {
      return linkToEditRoleSetting(resourceId);
    }
    case "workflow": {
      return linkToEditWorkflow(resourceId);
    }
    case "chain_of_custody": {
      return linkToViewChainOfCustody(resourceId);
    }
    case "company_notification_preferences": {
      return linkToCompanySettings();
    }
    case "role_notification_preferences": {
      return linkToRoleNotificationSetting();
    }
    default:
      return null;
  }
};
