import history from "./history";
import config from "../config";

export function SetHistoryPath(path) {
  const params = new URLSearchParams(history.location.search);
  const currentPath = getCurrentPath();

  if (currentPath !== path) {
    history.push({
      pathname: path,
      search: "?" + params.toString(),
    });
  }
}

export function SetCleanHistoryPath(path: string) {
  const currentPath = getCurrentPath();

  if (currentPath !== path) {
    const [pathname, search] = path.split("?");
    history.push({ pathname, search });
  }
}

export function SetHistoryQueryParam(object, newQueryString) {
  const search = newQueryString ?? GetQueryString(object);

  history.replace({ search });
}

export function GetQueryString(
  object = {},
  queryString = history.location.search
) {
  const params = new URLSearchParams(queryString);
  Object.keys(object).map((key) => {
    const value = object[key];
    if (value || value === 0) {
      // The query parameter can take either an array of objects or
      // variables.
      if (Array.isArray(value)) {
        if (value.length > 0) {
          // If the parameter is array then we will take the id and of the
          // object and append to the parameters.
          value.forEach((element, index) => {
            element = typeof element === "object" ? element.id : element;
            if (index === 0) {
              params.set(key, element);
            } else {
              params.append(key, element);
            }
          });
        } else {
          // If the parameter is an empty array then we remove it
          params.delete(key);
        }
      } else {
        params.set(key, value);
      }
    } else {
      params.delete(key);
    }
  });
  return `?${params.toString()}`;
}

export function GetQueryParam(key, defaultValue) {
  const params = new URLSearchParams(history.location.search);
  const value = params.get(key) ? params.get(key) : defaultValue;

  return value;
}

export function GetAllQueryParam(key, defaultValue) {
  const params = new URLSearchParams(history.location.search);
  const value =
    params.getAll(key).length > 0 ? params.getAll(key) : defaultValue;
  return value;
}

export function GetArrayQueryParams(
  paramKey,
  filterKey,
  array,
  defaultReturn = array
) {
  const params = new URLSearchParams(history.location.search);
  const value = params.getAll(paramKey);
  defaultReturn = defaultReturn ? defaultReturn : array;

  // We will take the query parameter and match against list of available
  // options and return any row that is matched. If none of the row is matched
  // then we know the filter maybe invalid so we will return the entire list
  // back.
  if (array && value && value.length > 0) {
    if (value[0] === "none") {
      return ["none"];
    }

    const filteredArray = array.filter(
      (row) => value.indexOf(String(filterKey ? row[filterKey] : row)) !== -1
    );

    return filteredArray.length > 0 ? filteredArray : defaultReturn;
  }

  return defaultReturn;
}

export function ClearQueryParam() {
  const currentPath = getCurrentPath();
  history.push({ pathname: currentPath });
}

// This function will convert filterDropdown into useQueryParams format
// filterDropdowns are queryParams that have a limited amount of values
// they can be (i.e. filterDropdowns[key].options) and are shown as dropdowns
// filterInputs are single value query params shown as inputs or daterange
// (e.g. from, until)
export const queryParamsConverter = (filterDropdowns, filterInputs = {}) => {
  const queryParamInput = filterInputs;
  const queryParamOptions = {};

  Object.keys(filterDropdowns).map((key) => {
    queryParamInput[key] = filterDropdowns[key].inputs;
    queryParamOptions[key] = filterDropdowns[key].options;
  });

  return { queryParamInput, queryParamOptions };
};

export const getCurrentPath = () => {
  const fullPathname = window.location.pathname;
  // Remove base route
  const pathnameWithoutBaseRoute = fullPathname.replace(
    `${config.BASENAME}`,
    ""
  );

  return pathnameWithoutBaseRoute;
};
