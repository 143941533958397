import React, { useMemo } from "react";

import { linkToEquipmentDetail } from "#routers/links";
import { useFlowDetailFlow } from "#routes/organization/flows/[flowId]/detail/FlowDetailPage.helpers";
import { RoutingLink } from "#src/batteries-included-components/RoutingLink";
import { DataTable, StorageKeys } from "@validereinc/common-components";

export const FlowEquipmentTable = ({
  variant,
  filterConfigStorageKey,
}: {
  variant: "upstream" | "downstream";
} & Pick<StorageKeys, "filterConfigStorageKey">) => {
  const { data: flow } = useFlowDetailFlow({ filterConfigStorageKey });

  const equipmentDetail = useMemo(
    () => flow?.[`${variant}_equipment`] ?? { id: undefined },
    [flow]
  );

  return (
    <DataTable
      items={[{ ...equipmentDetail, type: "Equipment" }]}
      headers={[
        {
          label: "Name",
          key: "name",
          renderComponent: ({ item }) => (
            <RoutingLink to={linkToEquipmentDetail(equipmentDetail?.id)}>
              {item.name}
            </RoutingLink>
          ),
        },
        {
          label: "Type",
          key: "type",
        },
      ]}
    />
  );
};
