import { Button, DataTable, Panel } from "@validereinc/common-components";
import * as PropTypes from "prop-types";
import React, { useState } from "react";

import AddRecipientModal from "./AddRecipientModal";
import RemoveRecipientModal from "./RemoveRecipientModal";

const DeliveryStep = ({ form }) => {
  const subscribers = form.watch("subscribers");

  const [isAddRecipientModalOpen, setIsAddRecipientModalOpen] = useState(false);
  const [defaultEmail, setDefaultEmail] = useState();
  const [recipientEmailToRemove, setRecipientEmailToRemove] = useState(false);

  const onOpenRecipientModal = (email) => {
    if (email) {
      setDefaultEmail(email);
    }

    setIsAddRecipientModalOpen(true);
  };

  const onCloseRecipientModal = () => {
    setIsAddRecipientModalOpen(false);
    setDefaultEmail(undefined);
  };

  const onAddRecipient = (newRecipientEmail) => {
    form.setValue("subscribers", [
      ...(subscribers ?? []),
      { delivery_address: newRecipientEmail },
    ]);
  };

  const onEditRecipient = (newRecipientEmail) => {
    form.setValue(
      "subscribers",
      subscribers.map(({ delivery_address }) => ({
        delivery_address:
          delivery_address === defaultEmail
            ? newRecipientEmail
            : delivery_address,
      }))
    );
  };

  const onOpenRemoveRecipientModal = (delivery_address) =>
    setRecipientEmailToRemove(delivery_address);

  const onCloseRemoveRecipientModal = () => {
    setRecipientEmailToRemove(false);
  };

  const onRemoveRecipient = () => {
    form.setValue(
      "subscribers",
      subscribers.filter(
        ({ delivery_address }) => delivery_address !== recipientEmailToRemove
      )
    );
  };

  return (
    <>
      <p>Define recipients of the alert by entering their emails</p>

      <Panel
        title="Recipients"
        actionRow={
          <Button onClick={() => onOpenRecipientModal()}>Add Recipient</Button>
        }
      >
        <DataTable
          headers={[{ key: "delivery_address", label: "Recipient" }]}
          items={subscribers}
          getItemActions={({ item: { delivery_address } }) => [
            {
              label: "Edit",
              buttonProps: {
                onClick: () => onOpenRecipientModal(delivery_address),
                icon: "pencil-simple",
              },
            },
            {
              label: "Remove",
              buttonProps: {
                onClick: () => onOpenRemoveRecipientModal(delivery_address),
                icon: "trash",
              },
            },
          ]}
        />
      </Panel>

      <AddRecipientModal
        isOpen={isAddRecipientModalOpen}
        onAdd={defaultEmail ? onEditRecipient : onAddRecipient}
        onClose={onCloseRecipientModal}
        defaultEmail={defaultEmail}
      />

      <RemoveRecipientModal
        recipientEmailToRemove={recipientEmailToRemove}
        onRemove={onRemoveRecipient}
        onClose={onCloseRemoveRecipientModal}
      />
    </>
  );
};

DeliveryStep.propTypes = {
  form: PropTypes.object.isRequired,
};

export default DeliveryStep;
