export function getAllFilterInputs(filters) {
  const filterPillbox = {};

  Object.keys(filters).map((key) => {
    const inputs = filters[key]?.inputs ?? [];
    if (inputs.length > 0) {
      filterPillbox[key] = inputs;
    }
  });

  return filterPillbox;
}

export function clearAllInputs(filterDropdowns) {
  const updatedFilterDropdowns = { ...filterDropdowns };

  Object.keys(updatedFilterDropdowns).map((key) => {
    updatedFilterDropdowns[key].inputs = [];
  });

  return updatedFilterDropdowns;
}

export function noMeasurementMatch(measurements, test) {
  const testMeasurements = Object.keys(test.measurements);

  return measurements.every(
    (m) => !testMeasurements.find((teatMeasurement) => teatMeasurement === m)
  );
}

// Returns true and thus filter out if (orphanFilter includes yes and there is
// a sample id) OR (filter includes no and there is no sample id)
export function noOrphanFilterMatch(orphanFilter, test) {
  return orphanFilter.every((filter) => {
    if (filter === "yes") {
      return !!test.sample?.id;
    } else {
      return !test.sample?.id;
    }
  });
}
