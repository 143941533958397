import Immutable from "immutable";

import {
  ALERT_MESSAGE_CREATED,
  ALERT_MESSAGE_REMOVED,
  ALERT_MESSAGES_CLEARED,
} from "../constants/action-types";
import moment from "moment";

const DEFAULT_TIMEOUT_SECONDS = 3;

const MAX_ALERT_MESSAGE = 2;

export class AlertMessage extends Immutable.Record({
  type: undefined,
  title: undefined,
  message: undefined,
  timestamp: undefined,
  dismissesAt: undefined,
}) {
  constructor(props) {
    super({
      timestamp: Date.now(),
      dismissesAt: moment().add(DEFAULT_TIMEOUT_SECONDS, "seconds"),
      ...props,
    });
  }

  millisecondsLeft() {
    const now = moment();
    const duration = this.dismissesAt.diff(now);
    const timeLeft = moment.duration(duration).asMilliseconds();

    return timeLeft;
  }
}

export const AlertMessagesState = Immutable.Record(
  {
    list: Immutable.List(),
  },
  "AlertMessagesState"
);

const removeFromList = (list, item) =>
  list.filter((listItem) => listItem !== item);

export default (state = new AlertMessagesState(), action) => {
  switch (action.type) {
    case ALERT_MESSAGE_CREATED: {
      let list = state.list;

      if (state.list.size >= MAX_ALERT_MESSAGE) {
        list = list.shift();
      }

      const newList = list.push(action.alertMessage);

      return state.set("list", newList);
    }
    case ALERT_MESSAGE_REMOVED: {
      const list = removeFromList(state.list, action.alertMessage);

      return state.set("list", list);
    }
    case ALERT_MESSAGES_CLEARED: {
      return state.set("list", Immutable.List());
    }
    default:
      return state;
  }
};
