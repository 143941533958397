import {
  apiRequestServicesPromise,
  apiPostServicesPromise,
  apiDeleteServicesPromise,
  apiPutServicesPromise,
} from "./ServiceHelper";

// TODO: address deprecation
/**
 * @deprecated communicates with the monolithic Elixir API which is currently
 * being deprecated. Also, the service pattern is being deprecated in favor of
 * the domain package adapters.
 */
const RoleService = {
  getRoles() {
    return apiRequestServicesPromise(`/api/roles`);
  },

  requestUpdateRole(inputs, roleId) {
    const parameter = {
      name: inputs.name,
      permissions: inputs.permissions,
    };

    return apiPutServicesPromise(`/api/roles/${roleId}`, parameter);
  },

  requestCreateNewRole(role) {
    const parameter = {
      name: role.name,
      permissions: role.permissions,
    };

    return apiPostServicesPromise(`/api/roles`, parameter);
  },

  requestRemoveRole(roleId) {
    return apiDeleteServicesPromise(`/api/roles/${roleId}`);
  },
};

export default RoleService;
