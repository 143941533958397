import WorkflowService from "#services/WorkflowService";
import moment from "moment";
import * as PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { SetHistoryPath } from "../../Routers/historyHelper";
import useQueryParams from "../../Routers/useQueryParams";
import { GetJSDataWithKey } from "../../utils/immutableConverter";
import {
  fetchInstrumentList,
  fetchSiteList,
  WorkflowsWasRequested,
  WorkflowTasksWasRequested,
} from "../Redux/actions/index";
import NavigationBar from "./NavigationBar/NavigationBar";
import StatusBar from "./StatusBar/StatusBar";
import "./Workflows.css";

import config from "#src/config";
import { downloadLink } from "#utils/download";
import {
  getTimeStringFromDate,
  offsetTimezoneToGMT,
} from "#utils/timeFormatter";
import { Page, Panel, useAlert } from "@validereinc/common-components";
import debounce from "lodash/debounce";
import { linkToViewWorkflows } from "../../Routers/links";
import { havePermission } from "../Redux/reducers/permissions";
import { useWorkflowsFilter } from "./WorkflowsFilter";
import {
  getDateRange,
  getWorkflowTasksWithCalibrationTasks,
} from "./WorkflowsHelpers";
import WorkflowView from "./WorkflowView";

const DATE_CHANGE_WAIT_TIME = 150;

const mapStateToProps = (state) => {
  return {
    workflows: state.workflows,
    sites: state.sites,
    instruments: state.instruments,
    havePermission: havePermission(state.permissions),
  };
};

const mapDispatchToProps = {
  WorkflowsWasRequested,
  WorkflowTasksWasRequested,
  fetchSiteList,
  fetchInstrumentList,
};

const useManageDateRange = (paramView) => {
  const [view, setView] = useState("schedule");
  const [queryParams, setQueryParams] = useQueryParams({
    from: getTimeStringFromDate(moment().startOf("month"), config.DATE_FORMAT),
    to: getTimeStringFromDate(moment().endOf("month"), config.DATE_FORMAT),
  });

  const onDateRangeChange = useCallback((from, to) => {
    from = getTimeStringFromDate(from, config.DATE_FORMAT);
    to = getTimeStringFromDate(to, config.DATE_FORMAT);

    setQueryParams({ from, to });
  }, []);

  useEffect(() => {
    const nextView = paramView === "monthly" ? "monthly" : "schedule";

    if (view !== nextView) {
      if (nextView === "monthly") {
        const dateRange = getDateRange(queryParams.from, nextView);

        setQueryParams({ from: dateRange.from, to: dateRange.to });
      }

      setView(nextView);
    }
  }, [paramView]);

  return [queryParams.from, queryParams.to, onDateRangeChange, view];
};

const useFetchWorkflowTasks = (props, from, to) => {
  const [workflowTasks, setWorkflowTasks] = useState(null);
  const [haveEditPermission, setHaveEditPermission] = useState(false);
  const [updateWorkflowTasks, setUpdateWorkflowTasks] = useState(false);

  const debouncedWorkflowTaskRequest = useCallback(
    debounce(props.WorkflowTasksWasRequested, DATE_CHANGE_WAIT_TIME),
    []
  );

  useEffect(() => {
    if (props.havePermission("lite:workflows", "write")) {
      props.WorkflowsWasRequested();
      setHaveEditPermission(true);
    }
    props.fetchSiteList();
    props.fetchInstrumentList();
  }, []);

  useEffect(() => {
    if (from && to) {
      debouncedWorkflowTaskRequest(moment(from).toDate(), moment(to).toDate());
    }
  }, [from, to, updateWorkflowTasks]);

  useEffect(() => {
    if (props.workflows.loadingState === "loaded") {
      const instruments = GetJSDataWithKey("data", props.instruments);
      const workflowTasks = GetJSDataWithKey(
        "workflowInstances",
        props.workflows
      );

      const workflowTasksWithCalibrationTasks =
        getWorkflowTasksWithCalibrationTasks(
          workflowTasks,
          instruments,
          from,
          to
        );
      setWorkflowTasks(workflowTasksWithCalibrationTasks);
    }
  }, [props.workflows, props.instruments]);

  const refetchWorkflowTasks = () => {
    setUpdateWorkflowTasks(!updateWorkflowTasks);
  };

  return [workflowTasks, haveEditPermission, refetchWorkflowTasks];
};

// MAIN COMPONENT
const Workflows = (props) => {
  const [groupStreamsByWorkflow, setGroupStreamsByWorkflow] = useState(false);
  const { addAlert } = useAlert();

  const [from, to, onDateRangeChange, view] = useManageDateRange(
    props.match?.params?.view
  );

  const [workflowTasks, haveEditPermission, refetchWorkflowTasks] =
    useFetchWorkflowTasks(props, from, to);

  const [
    filterRow,
    filterPillbox,
    filteredWorkflowTasks,
    filterDropdowns,
    queryParams,
  ] = useWorkflowsFilter(
    props.workflows,
    props.sites,
    haveEditPermission,
    workflowTasks
  );

  const onGroupByWorkflowToggle = useCallback(() => {
    setGroupStreamsByWorkflow(!groupStreamsByWorkflow);
  }, [groupStreamsByWorkflow]);

  const onToggleView = useCallback(() => {
    const nextViewState = view === "monthly" ? "schedule" : "monthly";

    SetHistoryPath(linkToViewWorkflows(nextViewState));
  }, [view]);

  const downloadWithPromise = (workflowFilePromise) => {
    const requestFilters = {
      site_ids: queryParams.sites.map((site) => site.id),
      states: queryParams.status.map((s) => s.id),
      streams: queryParams.streams
        .map((s) => s.id)
        .filter((s) => s !== "calibration"),
      workflow_ids: queryParams.workflows
        .map((w) => w.id)
        .filter((s) => s !== "calibration"),
      test_types: queryParams.testTypes.map((t) => t.id),
    };

    workflowFilePromise(from, to, requestFilters).then((response) => {
      downloadLink(response.data.url);
      addAlert({
        variant: "success",
        message: "Your file will start download shortly.",
      });
    });
  };

  const onCSVDownload = () => {
    downloadWithPromise(WorkflowService.getWorkflowCSVDownloadLink);
  };

  const onPDFDownload = () => {
    downloadWithPromise(WorkflowService.getWorkflowPDFDownloadLink);
  };

  return (
    <Page
      title="Workflow Tasks"
      breadcrumbs={props.breadcrumbs}
    >
      <Panel className="workflow__mainPanel">
        <NavigationBar
          from={from}
          to={to}
          onDateRangeChange={onDateRangeChange}
          view={view}
          onToggleView={onToggleView}
          groupStreamsByWorkflow={groupStreamsByWorkflow}
          onGroupByWorkflowToggle={onGroupByWorkflowToggle}
          haveEditPermission={haveEditPermission}
          onCSVDownload={onCSVDownload}
          onPDFDownload={onPDFDownload}
        />

        <StatusBar workflowTasks={filteredWorkflowTasks} />

        <WorkflowView
          from={offsetTimezoneToGMT(from, "startOf", "day")}
          to={offsetTimezoneToGMT(to, "endOf", "day")}
          haveEditPermission={haveEditPermission}
          groupStreamsByWorkflow={groupStreamsByWorkflow}
          workflows={filterDropdowns.workflows.options}
          streams={filterDropdowns.streams.options}
          workflowTasks={filteredWorkflowTasks}
          view={view}
          height={props.height}
          filterRow={filterRow}
          filterPillbox={filterPillbox}
          refetchWorkflowTasks={refetchWorkflowTasks}
          loadingState={props.workflows.loadingState}
        />
      </Panel>
    </Page>
  );
};

Workflows.propTypes = {
  WorkflowsWasRequested: PropTypes.func.isRequired,
  fetchSiteList: PropTypes.func.isRequired,
  fetchInstrumentList: PropTypes.func.isRequired,
  WorkflowTasksWasRequested: PropTypes.func.isRequired,
  havePermission: PropTypes.func.isRequired,
  workflows: PropTypes.object,
  instruments: PropTypes.object,
  sites: PropTypes.object,
  match: PropTypes.object,
  height: PropTypes.number.isRequired,
  addAlertMessage: PropTypes.func.isRequired,
  breadcrumbs: PropTypes.array.isRequired,
};

const WorkflowsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Workflows);

export default WorkflowsContainer;
