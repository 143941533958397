import { toFlattenedObject } from "@validereinc/utilities";
import { isEmpty } from "lodash";
import startCase from "lodash/startCase";
import {
  DeviceStatus,
  DeviceType,
  DeviceTypeType,
  ReportWithDownloadType,
} from "../schemas";
import {
  GetOneRequestType,
  FilterObjectType,
  GetListRequestType,
  GetListResponseType,
  ResourceServiceType,
  SortDirection,
  fetchAndCollate,
  getFilterObject,
} from "../util";
import { DeviceTypesDomain } from "./DeviceTypesDomain";
import { restAPI } from "./api";
import { SavedFilterServiceType, SavedFiltersDomain } from "./composable";

export type DeviceFilterType = {
  "facility.custom_attributes"?: Record<string, string>;
  "facility.id"?: string;
  "facility.name"?: string;
  "facility.status"?: string;
  attributes?: Record<string, string>;
  custom_attributes?: Record<string, string>;
  id?: string;
  measurement_types?: string;
  name?: string;
  status?: string;
  type_id?: string;
};

type DevicesServiceType = Pick<
  ResourceServiceType<DeviceType>,
  "getList" | "getOne" | "updateOne" | "createOne" | "deleteOne"
> & {
  exportList: (params: GetListRequestType) => Promise<ReportWithDownloadType>;
  savedFilters: SavedFilterServiceType<DeviceFilterType>;
  types: {
    getList: () => Promise<DeviceTypeType[]>;
  };
  statuses: {
    getList: ({
      page,
      pageSize,
      filters,
    }: {
      page: number;
      pageSize: number;
      filters: { name: string };
    }) => {
      data: Array<{ id: string; name: string }>;
      total_entries: number;
      total_pages: number;
    };
  };
};

export const Device: DevicesServiceType = {
  exportList: async ({
    filters,
    sortBy = "name",
    sortDirection = "asc",
  }: GetListRequestType<FilterObjectType<DeviceFilterType>>) => {
    const { status, ...restFilters } = filters ?? {};
    return restAPI.nodeAPI.POST<ReportWithDownloadType>({
      endpoint: "/devices/export",
      body: {
        sort_by: sortBy,
        sort_direction: sortDirection,
        filter: {
          ...getFilterObject(toFlattenedObject(restFilters)),
          ...(typeof status === "string" && !isEmpty(status)
            ? { status }
            : getFilterObject({ status })),
        },
      },
    });
  },

  getList: async ({
    filters,
    pageSize,
    page,
    sortBy = "name",
    sortDirection = SortDirection.ASCENDING,
  }: GetListRequestType<FilterObjectType<DeviceFilterType>>) => {
    const { status, ...restFilters } = filters ?? {};

    return fetchAndCollate(
      ({ page, pageSize }) =>
        restAPI.nodeAPI.POST<GetListResponseType<DeviceType>>({
          endpoint: "/devices/search",
          body: {
            page,
            page_size: pageSize,
            sort_by: sortBy,
            sort_direction: sortDirection,
            filter: {
              ...getFilterObject(toFlattenedObject(restFilters)),
              ...(typeof status === "string" && !isEmpty(status)
                ? { status }
                : getFilterObject({ status })),
            },
          },
        }),
      page,
      pageSize
    );
  },

  getOne: async ({ id, meta }: GetOneRequestType<{ period?: string }>) =>
    restAPI.nodeAPI
      .GET<DeviceType>({
        endpoint: `/devices/${id}`,
        query: meta?.period ? { period: meta.period } : {},
      })
      .then((resp) => ({
        data: resp,
      })),

  updateOne: async ({ id, data }) => {
    const resp = await restAPI.nodeAPI.PUT<DeviceType>({
      endpoint: `/devices/${id}`,
      body: data,
    });
    return { data: resp };
  },

  createOne: async (props) => {
    const resp = await restAPI.nodeAPI.POST<DeviceType>({
      endpoint: "/devices",
      body: props.data,
    });

    return { data: resp };
  },

  deleteOne: async ({ id }: { id: string }) =>
    await restAPI.nodeAPI.DELETE({ endpoint: `/devices/${id}` }),

  savedFilters: SavedFiltersDomain<DeviceFilterType>({
    endpoint: "/devices/filters",
  }),

  types: DeviceTypesDomain,

  statuses: {
    // this function mocks an API call for device status types:
    getList: ({
      page = 1,
      pageSize = 20,
      filters: { name } = {},
    }: {
      page?: number;
      pageSize?: number;
      filters?: { name?: string };
    }) => {
      const totalData = Object.values(DeviceStatus);
      const minIndex = (page - 1) * pageSize;
      const maxIndex = page * pageSize;

      const data = totalData.reduce(
        (total: Array<{ id: string; name: string }>, status, index) => {
          if (index > maxIndex || index < minIndex) {
            return total;
          }

          if (!name || status.toLowerCase().includes(name.toLowerCase())) {
            return [...total, { id: status, name: startCase(status) }];
          }

          return total;
        },
        []
      );

      return {
        data,
        total_entries: totalData.length,
        total_pages: Math.ceil(totalData.length / pageSize),
      };
    },
  },
};
