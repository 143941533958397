import React from "react";
import * as PropTypes from "prop-types";
import config from "../../../config";
import InstrumentDetail from "../InstrumentDetail";
import { getMeasurementType } from "../../Redux/reducers/measurements";
import { connect } from "react-redux";
import { notesColumn } from "../Shared/TestsDatatable/NotesColumn";
import { sortByMeasurement } from "../InstrumentHelper";
import {
  sampleStateRenderer,
  sortBySampleState,
} from "../../Samples/SampleHelper";
import { getTimeStringFromDate } from "#utils/timeFormatter";
import TestDetailModal from "#components/Tests/TestDetailModal/TestDetailModal";

/* eslint-disable react/prop-types */

const measurementWidth = 150;

const dateRenderer = (rowData, columnKey) => {
  return (
    <div>
      {getTimeStringFromDate(rowData[columnKey], config.DATETIME_FORMAT)}
    </div>
  );
};

const headers = (measurementRenderer) => [
  {
    label: "Name",
    key: "name",
    width: 300,
    fixed: true,
  },
  {
    label: "Sample State",
    key: "sample_state",
    width: 150,
    cellRenderer: (row) => sampleStateRenderer(row.sample),
    sort: sortBySampleState,
  },
  {
    label: "Date",
    key: "date",
    width: 170,
    cellRenderer: dateRenderer,
  },
  {
    label: "Tester",
    key: "tested_by",
    width: 150,
    supress: true,
  },
  {
    label: "Methane C1 (%)",
    key: "Mole Percentage Methane (C1)",
    width: measurementWidth,
    cellRenderer: measurementRenderer,
    sort: sortByMeasurement,
  },
  {
    label: "Ethane C2 (%)",
    key: "Mole Percentage Ethane (C2)",
    width: measurementWidth,
    cellRenderer: measurementRenderer,
    sort: sortByMeasurement,
  },
  {
    label: "Propane C3 (%)",
    key: "Mole Percentage Propane (C3)",
    width: measurementWidth,
    cellRenderer: measurementRenderer,
    sort: sortByMeasurement,
  },
  {
    label: "Butane C4 (%)",
    key: "Mole Percentage Butane (C4)",
    width: measurementWidth,
    cellRenderer: measurementRenderer,
    sort: sortByMeasurement,
  },
  {
    label: "Pentane C5 (%)",
    key: "Mole Percentage Pentane (C5)",
    width: measurementWidth,
    cellRenderer: measurementRenderer,
    sort: sortByMeasurement,
  },
  {
    label: "Hexane C6 (%)",
    key: "Mole Percentage Hexanes (C6)",
    width: measurementWidth,
    cellRenderer: measurementRenderer,
    sort: sortByMeasurement,
  },
  {
    label: "Heptane C7 (%)",
    key: "Mole Percentage Heptanes (C7)",
    width: measurementWidth,
    cellRenderer: measurementRenderer,
    sort: sortByMeasurement,
  },
  {
    label: "Heptanes Plus C7+ (%)",
    key: "Mole Percentage Heptanes Plus (C7+)",
    width: measurementWidth,
    cellRenderer: measurementRenderer,
    sort: sortByMeasurement,
  },
  {
    label: "Carbon Dioxide (%)",
    key: "Mole Percentage Carbon Dioxide (CO2)",
    width: measurementWidth,
    cellRenderer: measurementRenderer,
    sort: sortByMeasurement,
  },
  {
    label: "Nitrogen N2 (%)",
    key: "Mole Percentage Nitrogen (N2)",
    width: measurementWidth,
    cellRenderer: measurementRenderer,
    sort: sortByMeasurement,
  },
  {
    label: "Hydrogen Sulphide H2S (%)",
    key: "Mole Percentage Hydrogen Sulphide (H2S)",
    width: measurementWidth,
    cellRenderer: measurementRenderer,
    sort: sortByMeasurement,
  },
  notesColumn,
];

const mapStateToProps = (state) => {
  return {
    getMeasurementDecimals: (measurement) =>
      getMeasurementType(state.measurements)(measurement).decimals,
  };
};

const AgilentGCTests = (props) => {
  const measurementRenderer = (rowData, columnKey) => {
    const decimals = props.getMeasurementDecimals(columnKey);

    try {
      const value = rowData.measurements[columnKey].value;
      return <div>{value.toFixed(decimals)}</div>;
    } catch {
      return <div>-</div>;
    }
  };

  return (
    <InstrumentDetail
      testId={props.match.params.testId}
      instrumentId={props.match.params.instrumentId}
      height={props.height}
      instrumentInfo={props.instrumentInfo}
      ReportModalComponent={TestDetailModal}
      filterKey="name"
      headers={headers(measurementRenderer)}
      defaultSortBy="date"
      defaultSortDirection="desc"
    />
  );
};

AgilentGCTests.propTypes = {
  match: PropTypes.object.isRequired,
  height: PropTypes.number.isRequired,
};

export default connect(mapStateToProps, null)(AgilentGCTests);
