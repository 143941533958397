import { FlowStatusPillVariants } from "#hooks/adapters/useFlows";
import { useGetManyUsers } from "#hooks/adapters/useUsers";
import { useFlowDetailFlow } from "#routes/organization/flows/[flowId]/detail/FlowDetailPage.helpers";
import { StatusPanel, StorageKeys } from "@validereinc/common-components";
import type { UserType } from "@validereinc/domain";
import { datetimeFormatter, toStartCaseString } from "@validereinc/utilities";
import React from "react";

export const FlowStatusPanel = ({
  filterConfigStorageKey,
}: Pick<StorageKeys, "filterConfigStorageKey">) => {
  const { data: flow, isLoading } = useFlowDetailFlow({
    filterConfigStorageKey,
  });

  const usersQuery = useGetManyUsers([flow?.created_by, flow?.updated_by]);
  const userMap = usersQuery.reduce(
    (accumulator: Record<string, UserType>, current) => {
      if (current.data?.id) {
        accumulator[current.data.id] = current.data;
      }
      return accumulator;
    },
    {}
  );

  const statusPanelData = flow
    ? [
        {
          label: "Updated At",
          value: datetimeFormatter(new Date(flow?.updated_at)),
        },
        {
          label: "Updated By",
          value: userMap[flow?.updated_by]?.name ?? "-",
        },
        {
          label: "Created At",
          value: datetimeFormatter(new Date(flow?.created_at)),
        },
        {
          label: "Created By",
          value: userMap[flow?.created_by]?.name ?? "-",
        },
      ]
    : [];

  return (
    <StatusPanel
      data={statusPanelData}
      isLoading={isLoading}
      statusText={toStartCaseString(flow?.status)}
      statusVariant={FlowStatusPillVariants[flow?.status]}
    />
  );
};
