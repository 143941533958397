import { useAuthenticatedContext } from "#src/contexts/AuthenticatedContext.helpers";
import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { RolesAdapter, UsersAdapter } from "@validereinc/domain";
import React, { createContext } from "react";
import { useParams } from "react-router-dom";

export const RoleContext = createContext<{
  roleQuery: UseQueryResult<
    Awaited<ReturnType<typeof RolesAdapter.getOne>>["data"]
  >;
  roleMembersQuery: UseQueryResult<
    Awaited<ReturnType<typeof RolesAdapter.members.getMany>>
  >;
  rolePermissionsQuery: UseQueryResult<
    Awaited<ReturnType<typeof RolesAdapter.permissions.getMany>>
  >;
  isCurrentUserInRole: boolean;
  doesRoleHaveMembers: boolean;
  createdByUserQuery: UseQueryResult<
    Awaited<ReturnType<typeof UsersAdapter.getOne>>["data"] | undefined
  >;
  updatedByUserQuery: UseQueryResult<
    Awaited<ReturnType<typeof UsersAdapter.getOne>>["data"] | undefined
  >;
} | null>(null);

export const RoleProvider = ({
  children,
}: {
  children: React.ReactElement | React.ReactElement[];
}) => {
  const { roleId } = useParams<{ roleId: string }>();
  const {
    claims: {
      user: { id: currentUserId },
    },
  } = useAuthenticatedContext();
  const roleQuery = useQuery(
    ["roles", roleId],
    () => RolesAdapter.getOne({ id: roleId }),
    {
      enabled: Boolean(roleId),
      staleTime: 3 * 60 * 1000,
      select: (resp) => resp.data,
    }
  );
  const roleMembersQuery = useQuery(
    ["roles", roleId, "members"],
    () => RolesAdapter.members.getMany({ roleId: roleId }),
    {
      enabled: Boolean(roleId),
      staleTime: 3 * 60 * 1000,
    }
  );
  const rolePermissionsQuery = useQuery(
    ["roles", roleId, "permissions"],
    () => RolesAdapter.permissions.getMany({ id: roleId }),
    {
      enabled: Boolean(roleId),
      staleTime: 3 * 60 * 1000,
    }
  );
  const createdByUserQuery = useQuery(
    ["users", roleQuery.data?.created_by],
    () => {
      if (!roleQuery.data?.created_by) return;

      return UsersAdapter.getOne({ id: roleQuery.data.created_by });
    },
    {
      enabled: Boolean(roleQuery.data?.created_by),
      staleTime: 5 * 60 * 1000,
      select: (resp) => resp?.data,
    }
  );
  const updatedByUserQuery = useQuery(
    ["users", roleQuery.data?.updated_by],
    () => {
      if (!roleQuery.data?.updated_by) return;

      return UsersAdapter.getOne({ id: roleQuery.data.updated_by });
    },
    {
      enabled: Boolean(roleQuery.data?.updated_by),
      staleTime: 5 * 60 * 1000,
      select: (resp) => resp?.data,
    }
  );

  return (
    <RoleContext.Provider
      value={{
        roleQuery,
        roleMembersQuery,
        rolePermissionsQuery,
        isCurrentUserInRole: !!roleMembersQuery.data?.find(
          (member) => member.user_id === currentUserId
        ),
        doesRoleHaveMembers: !!roleMembersQuery.data?.length,
        createdByUserQuery,
        updatedByUserQuery,
      }}
    >
      {children}
    </RoleContext.Provider>
  );
};
