import React, { useState } from "react";
import { Marker as MapboxMarker } from "react-map-gl";
import * as PropTypes from "prop-types";
import classNames from "classnames/bind";
import styles from "./Marker.module.scss";
import truncate from "lodash/truncate";
import MarkerIcon from "./MarkerIcon";

const cx = classNames.bind(styles);

const getLabelClassName = (iconType, size) => {
  if (!!iconType && size === "small") {
    return "labelForSmallIcon";
  }
  if (!!iconType && size === "large") {
    return "labelForLargeIcon";
  }
  if (!iconType && size === "small") {
    return "labelForSmallNoIcon";
  }
  if (!iconType && size === "large") {
    return "labelForLargeNoIcon";
  }
};

const Marker = ({
  size = "small",
  iconType,
  colour = "darkBlue",
  label,
  isLabelToggleable = false, // false disables click to show/hide
  ...mapboxMarkerProps
}) => {
  const [showLabel, setShowLabel] = useState(!isLabelToggleable);
  return (
    <>
      <MapboxMarker
        onClick={() =>
          isLabelToggleable ? setShowLabel((prevState) => !prevState) : null
        }
        clickTolerance={10}
        anchor="center"
        {...mapboxMarkerProps}
      >
        <MarkerIcon
          iconType={iconType}
          size={size}
          colour={colour}
        />
      </MapboxMarker>
      {showLabel && label ? (
        <MapboxMarker
          anchor="top"
          {...mapboxMarkerProps}
        >
          <div className={cx("label", getLabelClassName(iconType, size))}>
            {truncate(label, { length: 25 })}
          </div>
        </MapboxMarker>
      ) : null}
    </>
  );
};

Marker.propTypes = {
  initialViewState: PropTypes.object.isRequired,
  mapboxMarkerProps: PropTypes.object,
  size: PropTypes.string,
  label: PropTypes.string,
  iconType: PropTypes.string,
  colour: PropTypes.string,
  isLabelToggleable: PropTypes.bool,
};

export default Marker;
