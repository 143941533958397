import { useGetList } from "#src/components/hooks/adapters/adapterUtils";
import {
  Resources,
  WorkflowTaskAdapter,
  WorkflowTaskType,
} from "@validereinc/domain";

export const useListWorkflowTasks = useGetList<WorkflowTaskType>(
  WorkflowTaskAdapter.getList,
  Resources.WORKFLOW,
  "tasks"
);

export const useListWorkflowActions = useGetList<WorkflowTaskType>(
  WorkflowTaskAdapter.actions.getList,
  Resources.WORKFLOW,
  "actions"
);
