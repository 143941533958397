import moment from "moment";
import { v1 as uuidv1 } from "uuid";

export const getMeasurementTypeToPrefill = (instrument) => {
  switch (instrument?.type) {
    case "anton paar density":
    case "anton paar dma 35 density": {
      return "Density";
    }
    case "anton paar svm 3000":
    case "anton paar svm 3001": {
      return "Kinematic Viscosity";
    }
    case "eralytics vapor": {
      return "Vapour Pressure";
    }
    case "horiba xray fluorescence": {
      return "Sulphur";
    }
    case "rigaku xray fluorescence": {
      return "Sulphur";
    }
    case "rudolph research density": {
      return "Density";
    }
    case "water": {
      return "Water";
    }
    case "validere vapor": {
      return "Vapour Pressure";
    }
    case "agilent gas chromatography": {
      return "Mole Percentage Methane (C1)";
    }
    case "aquamax kf": {
      return "Water";
    }
    case "isl vida density meter": {
      return "Density";
    }
    default: {
      return null;
    }
  }
};

export const getInputWithTestInfo = (
  testInfo,
  streams,
  getMeasurementType,
  reset
) => {
  const { date, instrument, sample } = testInfo;

  const measurementType = getMeasurementTypeToPrefill(instrument);
  const unit = measurementType
    ? getMeasurementType(measurementType).unit
    : null;

  const stream = streams.find((stream) => stream.id === sample?.stream_id);

  reset({
    stream: stream,
    measurements: [
      { date: moment(date).toDate(), measurementType, unit, uuid: uuidv1() },
    ],
  });
};
