import { GetAllObjectValue } from "#utils/objectFormatter";
import {
  Button,
  FormInputWrapper,
  InputStack,
  SelectInput,
  TextInput,
  Title,
} from "@validereinc/common-components";
import classNames from "classnames/bind";
import union from "lodash/union";
import * as PropTypes from "prop-types";
import React, { useMemo } from "react";
import AdvancedSection from "./AdvancedSection";
import ComponentsTable from "./ComponentsTable";
import {
  COMPONENT_PRICING_OPTIONS,
  PRODUCT_PRICING_OPTIONS,
} from "./CreateProductionReportsConstants";
import styles from "./ProductionReportsForm.module.scss";
import SourcesTableInput from "./SourcesTableInput";

const cx = classNames.bind(styles);

const PRICING_OPTIONS = ["Components", "Product Type"];

const CURRENCY_OPTIONS = ["CAD", "USD"];

const ProductionReportsForm = ({
  form,
  formState,
  setModalState,
  onSourceSelect,
  onSourceChecked,
  sourceCheckedList,
  streams,
}) => {
  const components = form.watch("components");
  const componentProperties = form.watch("componentProperties");

  const pricingType = form.watch("pricingType");

  const [inlet, outlet, reference] = form.watch([
    "inlet",
    "outlet",
    "reference",
  ]);

  const productTypes = useMemo(() => {
    const uniqInletProductTypes = inlet
      ? GetAllObjectValue(inlet, "product_type")
      : [];

    const uniqOutletProductTypes = outlet
      ? GetAllObjectValue(outlet, "product_type")
      : [];

    const uniqReferenceProductTypes = reference
      ? GetAllObjectValue(reference, "product_type")
      : [];

    return union(
      uniqInletProductTypes,
      uniqOutletProductTypes,
      uniqReferenceProductTypes
    );
  }, [inlet, outlet, reference]);

  const sharedProps = {
    isDisabled: formState !== "enabled",
    showIcon: true,
  };

  return (
    <>
      <Title
        type="subheader"
        className={cx("formTitle")}
      >
        General
      </Title>

      <TextInput
        name="name"
        label="Name"
        {...sharedProps}
        isRequired
      />

      <Title
        type="subheader"
        className={cx("formTitle")}
      >
        Properties
      </Title>

      <FormInputWrapper
        name="components"
        validate={{
          exist: (components) => {
            return !components?.length ? "Components is required." : null;
          },
        }}
        as={({ inputRef }) => (
          // react hook form uses ${element}.focus() to scroll to input with error
          // <div> do not have this function unless tabIndex prop is given
          <div
            tabIndex={-1}
            ref={inputRef}
          >
            <Button
              className={cx("button")}
              onClick={() => setModalState("component")}
              disabled={formState !== "enabled"}
            >
              {components?.length ? "Edit" : "Add"} Components
            </Button>

            <ComponentsTable
              components={components}
              componentProperties={componentProperties}
            />
          </div>
        )}
      />

      <SourcesTableInput
        sourceKey="inlet"
        onSourceSelect={onSourceSelect}
        sourceCheckedList={sourceCheckedList}
        form={form}
        formState={formState}
        onSourceChecked={onSourceChecked}
        streams={streams}
      />

      <SourcesTableInput
        sourceKey="outlet"
        onSourceSelect={onSourceSelect}
        sourceCheckedList={sourceCheckedList}
        form={form}
        formState={formState}
        onSourceChecked={onSourceChecked}
        streams={streams}
      />

      <Title
        type="subheader"
        className={cx("formTitle")}
      >
        Pricing
      </Title>

      <SelectInput
        {...sharedProps}
        name="pricingType"
        label="Pricing Type"
        isRequired
        options={PRICING_OPTIONS}
      />

      <SelectInput
        {...sharedProps}
        name="currency"
        label="Currency"
        isRequired
        options={CURRENCY_OPTIONS}
      />

      {pricingType === PRICING_OPTIONS[0] // Components
        ? components?.map((component) => (
            <InputStack
              key={component}
              name={component}
              label={component}
            >
              <div className={cx("priceInput")}>
                <TextInput
                  name={`pricing.${component}.price`}
                  placeholder="$"
                  showIcon
                  type="number"
                  step={0.01}
                  validate={{
                    isPositive: (value) =>
                      value >= 0 || "Price must be a non-negative number.",
                  }}
                />

                <div>Per</div>

                <SelectInput
                  name={`pricing.${component}.unit`}
                  placeholder="unit"
                  options={COMPONENT_PRICING_OPTIONS?.[component] ?? []}
                  showIcon
                  validate={{
                    isRequired: (value) => !!value || "unit is required",
                  }}
                />
              </div>
            </InputStack>
          ))
        : null}

      {pricingType === PRICING_OPTIONS[1] // Product Type
        ? productTypes?.map((productType) => (
            <InputStack
              key={productType}
              name={productType}
              label={productType}
            >
              <div className={cx("priceInput")}>
                <TextInput
                  name={`pricing.${productType}.price`}
                  placeholder="$"
                  showIcon
                  type="number"
                  step={0.01}
                  validate={{
                    isPositive: (value) =>
                      value >= 0 || "Price must be a non-negative number.",
                  }}
                />

                <div>Per</div>

                <SelectInput
                  name={`pricing.${productType}.unit`}
                  placeholder="unit"
                  options={PRODUCT_PRICING_OPTIONS?.[productType] ?? []}
                  showIcon
                  validate={{
                    isRequired: (value) => !!value || "unit is required",
                  }}
                />
              </div>
            </InputStack>
          ))
        : null}

      <AdvancedSection>
        <SourcesTableInput
          sourceKey="reference"
          onSourceSelect={onSourceSelect}
          sourceCheckedList={sourceCheckedList}
          form={form}
          formState={formState}
          onSourceChecked={onSourceChecked}
          streams={streams}
        />
      </AdvancedSection>

      <div className="clearfix">
        <Button
          variant="primary"
          type="submit"
          className="pull-right"
        >
          Create Balance
        </Button>
      </div>
    </>
  );
};

ProductionReportsForm.propTypes = {
  form: PropTypes.object.isRequired,
  formState: PropTypes.string,
  setModalState: PropTypes.func.isRequired,
  onSourceSelect: PropTypes.func.isRequired,
  onSourceChecked: PropTypes.func.isRequired,
  sourceCheckedList: PropTypes.array,
  streams: PropTypes.array,
};

export default ProductionReportsForm;
