import { useSearchParams } from "#src/Routers/hooks";
import { WorkflowTasksListLayout } from "#src/batteries-included-components/Layouts/Workflows/Tasks/List";
import { useAuthenticatedContext } from "#src/contexts/AuthenticatedContext.helpers";
import { useStorageKey } from "#src/hooks/useStorageKey";
import { useBreadcrumbsFromRoute } from "#src/utils/route";
import { Page, Tab } from "@validereinc/common-components";
import React from "react";
import { WorkflowTasksRoutePath } from "./";

const DEFAULT_TAB_KEY = "my-tasks";

export const WorkflowMyTasksPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [breadcrumbs] = useBreadcrumbsFromRoute(WorkflowTasksRoutePath);
  const {
    v2: { userInfo },
  } = useAuthenticatedContext();

  // My tasks and all tasks have two different set of filters that in conflict.
  // Therefore two different filters:
  const myTasksStorageKeys = useStorageKey("workflows-my-tasks");
  const allTasksStorageKeys = useStorageKey("workflows-all-tasks");

  return (
    <Page
      title={WorkflowTasksRoutePath.title}
      breadcrumbs={breadcrumbs}
      activeTabKey={searchParams?.tab ?? DEFAULT_TAB_KEY}
      onActiveTabKeyChange={(tabKey: string) => {
        setSearchParams({ tab: tabKey });
      }}
    >
      <Tab
        title="My Tasks"
        tabKey="my-tasks"
      >
        <WorkflowTasksListLayout
          isDisplayingMyTasks
          tablePanelProps={{
            ...myTasksStorageKeys,
            taskFilters: {
              $or: [
                {
                  assignee_user: userInfo.user?.id,
                },
                {
                  "assignee_group.user_id": userInfo.user?.id,
                },
              ],
            },
          }}
        />
      </Tab>
      <Tab
        title="All Tasks"
        tabKey="all-tasks"
      >
        <WorkflowTasksListLayout
          tablePanelProps={{
            ...allTasksStorageKeys,
            taskFilters: {},
          }}
        />
      </Tab>
    </Page>
  );
};
