import React from "react";
import * as PropTypes from "prop-types";
import DescriptionPanel from "../Shared/DescriptionPanel/DescriptionPanel";
import DescriptionRow from "../Shared/DescriptionPanel/DescriptionRow";

const GrabnerMiniVapDescriptionPanel = (props) => (
  <DescriptionPanel>
    <DescriptionRow
      title="Instrument ID"
      value={props.instrumentInfo.name}
      icon="hashtag"
    />

    <DescriptionRow
      title="Type"
      value="Grabner Minivap Vpxpert System"
      icon="cube"
    />

    <DescriptionRow
      title="Serial Number"
      value={props.instrumentInfo.current_serial_number}
      icon="laptop"
    />
  </DescriptionPanel>
);

GrabnerMiniVapDescriptionPanel.propTypes = {
  instrumentInfo: PropTypes.object.isRequired,
};

export default GrabnerMiniVapDescriptionPanel;
