import Immutable from "immutable";

import {
  UPDATED_TO_ANALYZE,
  ADDED_TO_ANALYZE,
  REMOVED_FROM_ANALYZE,
} from "../constants/action-types";

export const AnalyzeSpec = Immutable.Record({
  id: undefined,
  name: undefined,
  title: undefined,
  timeRangeStart: undefined,
  timeRangeEnd: undefined,
  relativeDayRange: undefined,
  sampleTypes: undefined,
  measurementType: undefined,
  streams: undefined,
  bookmarkId: undefined,
  isBookmarked: false,
});

export const AnalyzeState = Immutable.Record(
  {
    specs: Immutable.List(),
  },
  "AnalyzeState"
);

const inList = (specs, analyzeSpec) => {
  return specs.some((element) => element.id === analyzeSpec.id);
};

const updateInList = (list, analyzeSpec) => {
  return list.map((element) => {
    if (element.id === analyzeSpec.id) {
      return analyzeSpec;
    } else {
      return element;
    }
  });
};

export default (state = new AnalyzeState(), action) => {
  switch (action.type) {
    case UPDATED_TO_ANALYZE: {
      if (inList(state.specs, action.analyzeSpec)) {
        const specs = updateInList(state.specs, action.analyzeSpec);

        return state.set("specs", specs);
      }
      return state;
    }
    case ADDED_TO_ANALYZE: {
      const specs = inList(state.specs, action.analyzeSpec)
        ? updateInList(state.specs, action.analyzeSpec)
        : state.specs.push(action.analyzeSpec);

      return state.set("specs", specs);
    }
    case REMOVED_FROM_ANALYZE: {
      const specs = state.specs.filter((spec) => spec.id !== action.id);
      return state.set("specs", specs);
    }
    default:
      return state;
  }
};
