import { fetchMeasurementData } from "#components/Organization/Devices/Detail/deviceDetailHelper";
import AnalysisPanel from "#components/Organization/Devices/Detail/MeasurementsTab/AnalysisPanel";
import { FIFTEEN_MINUTES, FIVE_MINUTES, ONE_HOUR } from "#utils/date";
import { Dropdown } from "@validereinc/common-components";
import isEmpty from "lodash/isEmpty";
import moment from "moment";
import * as PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { getAlertTimestamp } from "../AlertListHelpers";
import { generateFilters } from "./alertDetailHelper";

const MeasurementPanel = ({
  deviceId,
  measurementSeriesDetail,
  isLoading,
  measurementTypes,
  alertDetail,
}) => {
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [measurementData, setMeasurementData] = useState([]);
  const [interval, setInterval] = useState(ONE_HOUR?.id);

  const filters = generateFilters(alertDetail, interval);

  // Fetch Measurements
  useEffect(async () => {
    if (!isLoading && isEmpty(measurementSeriesDetail)) {
      setIsDataLoading(false);
    }
    if (
      !isEmpty(measurementSeriesDetail) &&
      measurementTypes.length &&
      deviceId
    ) {
      setIsDataLoading(true);

      const data = await fetchMeasurementData(
        deviceId,
        [measurementSeriesDetail],
        measurementTypes,
        filters
      );

      setMeasurementData(data);
      setIsDataLoading(false);
    }
  }, [measurementSeriesDetail, deviceId, interval]);

  const actionRow = (
    <Dropdown
      value={interval}
      onChange={(value) => setInterval(value)}
      isClearable={false}
      placeholder="Interval"
      labelKey="name"
      valueKey="id"
      options={[FIVE_MINUTES, FIFTEEN_MINUTES, ONE_HOUR]}
      isInline
      isSortedAlphabetically={false}
    />
  );

  return (
    <>
      <AnalysisPanel
        data={measurementData}
        isLoading={isDataLoading}
        from={filters.date_range.from}
        to={filters.date_range.to}
        title="Alert Readings"
        thresholds={[
          {
            axis: "bottom",
            value: moment.utc(getAlertTimestamp(alertDetail)).toDate(),
            label: "Alert Timestamp",
          },
        ]}
        actionRow={actionRow}
      />
    </>
  );
};

MeasurementPanel.propTypes = {
  deviceId: PropTypes.string,
  measurementSeriesDetail: PropTypes.object,
  isLoading: PropTypes.bool,
  measurementTypes: PropTypes.array,
  alertDetail: PropTypes.object,
};

export default MeasurementPanel;
