import PageConfigs from "#routers/PageConfigs";
import history from "#routers/history";
import { Sidebar, SidebarProps } from "@validereinc/common-components";
import classNames from "classnames/bind";
import find from "lodash/find";
import React, { useState } from "react";
import { linkToUserSetting } from "../../../Routers/links";
import styles from "./SideBar.module.css";
import { isLinkIncludeInPath } from "./SideBarHelper";

// IMPROVE: this function and related code is smelly
function getActiveTabKey() {
  return find(Object.keys(PageConfigs), (key) => {
    return isLinkIncludeInPath(PageConfigs[key].link);
  });
}

const cx = classNames.bind(styles);

// IMPROVE: the sidebar needs a holistic refactor
const SidebarContainer = ({
  tabs,
  name,
  version,
  onSignOutClick,
  hideFooter,
}: Omit<SidebarProps, "onProfileClick">) => {
  const [activeTabKey] = useState<string>(getActiveTabKey() as string);

  return (
    <Sidebar
      className={cx("container")}
      activeTab={activeTabKey}
      tabs={tabs}
      name={name}
      onProfileClick={() => history.push(linkToUserSetting())}
      onSignOutClick={onSignOutClick}
      version={version}
      hideFooter={hideFooter}
    />
  );
};

export default SidebarContainer;
