import { useEffect, useState } from "react";
import ProductionService from "#services/ProductionService";
import RecordOfVolumeService from "#services/RecordOfVolumeService";
import RecordOfQualityService from "#services/RecordOfQualityService";
import { AssertIsBetweenDate, AssertIsBeforeDate } from "#utils/assert";

export const useFetchHistoricalData = (recordId) => {
  const [historicalData, setHistoricalData] = useState({});
  const [state, setState] = useState("loading");

  useEffect(() => {
    setState("loading");

    ProductionService.getHistorical(recordId)
      .then(({ data }) => {
        setHistoricalData(data);
        setState("loaded");
      })
      .catch(() => setState("error"));
  }, []);

  return [historicalData, state];
};

// TODO: consolodate this and useFetchRecordDetail once edit page is migrated
export const useRecordDetail = (recordId, from, to) => {
  const [recordDetail, setRecordDetail] = useState({});
  const [state, setState] = useState("loading");
  const [lastUpdatedAt, setLastUpdatedAt] = useState(new Date());

  const updateRecordDetail = (recordDetail) => {
    if (recordDetail) {
      setRecordDetail(recordDetail);
    } else {
      setLastUpdatedAt(new Date());
    }
  };

  const reloadRecordDetail = () => {
    // Get the record we currently have selected
    ProductionService.getProductionRecordDetail(recordId, from, to)
      .then(({ data: { selection_stream_id, ...originalRecordDetail } }) => {
        const properties = [];
        const selection_intervals = [];

        //Get all accounting periods
        RecordOfQualityService.getAccountingPeriods().then(
          ({ data: accountingPeriods }) => {
            // Filter accounting periods down to the months selected
            const newPeriods = accountingPeriods.filter(
              ({ site_system: { id }, from: periodFrom }) => {
                return (
                  // The sit system is the same as the record selected
                  originalRecordDetail.accounting_period.site_system.id ===
                    id && AssertIsBetweenDate(periodFrom, from, to, "month")
                );
              }
            );

            Promise.all(
              newPeriods.map(({ id, from }) =>
                // Get ROQs related to Accounting period
                RecordOfQualityService.getRecordOfQualities(id).then(
                  ({ data: records }) => {
                    // Get the proper record
                    const { id: recordId } = records.find(
                      ({ selection_stream_id: streamId }) =>
                        streamId === selection_stream_id
                    );

                    // Get detailed ROQ for selected stream/accounting period
                    return ProductionService.getProductionRecordDetail(
                      recordId,
                      from,
                      to
                    ).then(({ data }) => {
                      properties.push(data);

                      selection_intervals.push(...data.selection_intervals);
                    });
                  }
                )
              )
            ).then(() => {
              setRecordDetail({
                ...originalRecordDetail,
                properties: properties.sort((a, b) => {
                  return AssertIsBeforeDate(
                    b.accounting_period?.from,
                    a.accounting_period?.from
                  )
                    ? -1
                    : 1;
                }),
                selection_intervals,
              });

              setState("loaded");
            });
          }
        );
      })
      .catch(() => setState("error"));
  };

  useEffect(() => {
    setState("loading");

    reloadRecordDetail();
  }, [lastUpdatedAt, from, to]);

  return [recordDetail, state, updateRecordDetail, reloadRecordDetail];
};

export const useFetchRecordDetail = (recordId) => {
  const [recordDetail, setRecordDetail] = useState({});
  const [state, setState] = useState("loading");
  const [lastUpdatedAt, setLastUpdatedAt] = useState(new Date());

  const updateRecordDetail = (recordDetail) => {
    if (recordDetail) {
      setRecordDetail(recordDetail);
    } else {
      setLastUpdatedAt(new Date());
    }
  };

  useEffect(() => {
    setState("loading");

    ProductionService.getProductionRecordDetail(recordId)
      .then(({ data }) => {
        setRecordDetail(data);
        setState("loaded");
      })
      .catch(() => setState("error"));
  }, [lastUpdatedAt]);

  return [recordDetail, state, updateRecordDetail];
};

export const useFetchDailyVolume = (streamId, from, to) => {
  const [volumes, setVolumes] = useState([]);
  const [loadingState, setLoadingState] = useState("loading");
  const [lastUpdatedAt, setLastUpdatedAt] = useState(new Date());

  useEffect(() => {
    if (streamId && from && to) {
      setLoadingState("loading");

      RecordOfVolumeService.getRecordOfVolumes(streamId, from, to)
        .then(({ data }) => {
          setVolumes(data);
          setLoadingState("loaded");
        })
        .catch(() => {
          setLoadingState("error");
        });
    }
  }, [streamId, from, to, lastUpdatedAt]);

  const refetchDailyVolume = () => {
    setLastUpdatedAt(new Date());
  };

  return [loadingState, volumes, refetchDailyVolume];
};
