import { useAuthenticatedContext } from "#src/contexts/AuthenticatedContext.helpers";
import React, { PropsWithChildren, createContext } from "react";
import { connect } from "react-redux";

export const DefaultLocalizationMapping = {
  facility: "Facility",
  facility_plural: "Facilities",
  equipment: "Equipment",
  equipment_plural: "Equipment",
  flow: "Flow",
  flow_plural: "Flows",
  device: "Device",
  device_plural: "Devices",
  asset_group: "Network",
  asset_group_plural: "Networks",
  asset: "Asset",
  asset_plural: "Assets",
};

export const LocalizationContext = createContext<{
  mappings: Record<string, string>;
  isLoading: boolean;
}>({
  mappings: {},
  isLoading: true,
});

// TODO: port to legacy Redux code tied to the legacy auth system (Elixir). Will be removed in the future.
const mapStateToProps = ({ profiles }) => ({
  profile: profiles.data?.toJS() ?? {},
});

// TODO: the profile prop here will be removed when Redux is ripped out. The any typing here is temporary.
export const LocalizationProvider = ({
  children,
  profile,
}: PropsWithChildren<{ profile?: any }>) => {
  const {
    isLoading,
    v2: {
      companyInfo: { company },
    },
  } = useAuthenticatedContext();

  return (
    <LocalizationContext.Provider
      value={{
        mappings: company?.localization ?? profile?.company_localization ?? {},
        isLoading,
      }}
    >
      {children}
    </LocalizationContext.Provider>
  );
};

export default connect(mapStateToProps)(LocalizationProvider);
