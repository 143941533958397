import { downloadXLSX } from "#utils/download";
import axios from "axios";
import config from "../../config";
import { ErrorResponseMessage } from "./ErrorResponseMessage";

const ValidMimeTypes = [
  "text/csv",
  "application/vnd.ms-excel",
  "application/pdf",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
];

const SERVER_TIMEOUT = 60000; // 60sec timeout for requests

export const isSuccessfulResponse = (responseCode) =>
  [200, 201, 202, 203, 204].includes(responseCode);

export const validereApi = axios.create({
  baseURL: config.API_URL,
  timeout: SERVER_TIMEOUT,
  headers: {
    Accept: `application/vnd.validere.v${config.API_VERSION}+json; text/csv;, charset=utf-8`,
  },
  validateStatus: (status) => status < 400,
});

export const nodeAPI = axios.create({
  baseURL: config.NODE_API_URL,
  timeout: SERVER_TIMEOUT,
  validateStatus: (status) => status < 400,
});

export const alertingAPI = axios.create({
  baseURL: config.ALERTING_API_URL,
  timeout: SERVER_TIMEOUT,
  validateStatus: (status) => status < 400,
});

export const NODE_API_MAX_PAGE_SIZE = 1000;

export const dataEngApi = axios.create({
  baseURL: config.DATA_ENG_API_URL,
  timeout: SERVER_TIMEOUT,
  validateStatus: (status) => status < 400,
});

export const dataPlatformApi = axios.create({
  baseURL: config.DATA_PLATFORM_API_URL,
  timeout: SERVER_TIMEOUT,
  validateStatus: (status) => status < 400,
});

export function nodeApiRequestServicesPromise(URL, params = {}, options = {}) {
  const { version = "v1" } = params;

  return apiRequestServicesPromise(
    `/${version}${URL}`,
    params,
    options,
    nodeAPI
  );
}

export function alertingApiRequestServicesPromise(
  URL,
  params = {},
  options = {}
) {
  const { version = "v1" } = params;

  return apiRequestServicesPromise(
    `/api/${version}${URL}`,
    params,
    options,
    alertingAPI
  );
}

export function dataEngRequestServicesPromise(URL, params = {}, options = {}) {
  return apiRequestServicesPromise(URL, params, options, dataEngApi);
}

export function dataPlatformRequestServicesPromise(
  URL,
  params = {},
  options = {}
) {
  return apiRequestServicesPromise(URL, params, options, dataPlatformApi);
}

export function apiRequestServicesPromise(
  URL,
  params,
  options = {},
  api = validereApi
) {
  const { signal, headers, responseType } = options;

  return api
    .get(URL, {
      params,
      signal,
      headers,
      responseType,
    })
    .then((response) => ({
      data: response.data,
      status: response.status,
      ETag: response.headers.etag,
    }));
}

export function nodeApiPutServicesPromise(URL, params = {}) {
  const { version = "v1" } = params;

  return apiPutServicesPromise(`/${version}${URL}`, params, nodeAPI);
}

export function dataEngApiPutServicesPromise(URL, params = {}) {
  return apiPutServicesPromise(URL, params, dataEngApi);
}

export function apiPutServicesPromise(URL, parameter, api = validereApi) {
  return api.put(URL, parameter).then((response) => ({
    status: response.status,
    data: response.data,
  }));
}

export function alertingApiPutServicesPromise(URL, body = {}) {
  const { version = "v1" } = body;

  return apiPutServicesPromise(`/api/${version}${URL}`, body, alertingAPI);
}

export function nodeApiPostServicesPromise(URL, body = {}, config = {}) {
  const { version = "v1" } = body;

  return apiPostServicesPromise(`/${version}${URL}`, body, config, nodeAPI);
}

export function dataEngApiPostServicesPromise(URL, body = {}, config = {}) {
  return apiPostServicesPromise(URL, body, config, dataEngApi);
}

export function dataPlatformPostServicesPromise(URL, body = {}, config = {}) {
  return apiPostServicesPromise(URL, body, config, dataPlatformApi);
}

export function nodeApiPostServicesPromiseXLSX(URL, params = {}) {
  const { version = "v1", outputFilename } = params;

  // copied from https://stackoverflow.com/questions/68890870/how-to-download-excel-in-response-from-api-react-js
  return nodeAPI
    .post(`/${version}${URL}`, undefined, {
      headers: {
        Accept:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "Content-Type": "blob",
      },
      responseType: "arraybuffer",
    })
    .then((response) => {
      downloadXLSX(outputFilename, response.data);
    });
}

export function apiPostServicesPromise(
  URL,
  data = {},
  config = {},
  api = validereApi
) {
  return api.post(URL, data, config).then((response) => ({
    status: response.status,
    data: response.data,
  }));
}

export function alertingApiPostServicesPromise(URL, body = {}, config = {}) {
  const { version = "v1" } = body;

  return apiPostServicesPromise(
    `/api/${version}${URL}`,
    body,
    config,
    alertingAPI
  );
}

export function nodeApiDeleteServicesPromise(URL, params = {}) {
  const { version = "v1" } = params;

  return apiDeleteServicesPromise(`/${version}${URL}`, params, nodeAPI);
}

export function dataEngApiDeleteServicesPromise(URL, params = {}) {
  return apiDeleteServicesPromise(URL, params, dataEngApi);
}

export function apiDeleteServicesPromise(URL, parameter, api = validereApi) {
  return api.delete(URL, { data: parameter }).then((response) => ({
    status: response.status,
  }));
}

export function alertingApiDeleteServicesPromise(URL, params = {}) {
  const { version = "v1" } = params;

  return apiDeleteServicesPromise(`/api/${version}${URL}`, params, alertingAPI);
}

export function apiPostUploadFileServicesPromise(URL, formData) {
  return validereApi
    .post(URL, formData, {
      headers: {
        Accept: `application/vnd.validere.v${config.API_VERSION}+json`,
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => ({
      status: response.status,
      data: response.data,
    }));
}

export function apiHTMLRequestServicesPromise(URL, parameter) {
  return validereApi
    .get(URL, {
      params: parameter,
      headers: {
        Accept: `application/vnd.validere.v${config.API_VERSION}+json, text/html`,
      },
    })
    .then((response) => ({
      data: response.data,
      status: response.status,
    }));
}

export function apiDownloadServicesPromise(URL, parameter) {
  return validereApi
    .get(URL, {
      params: parameter,
      headers: {
        Accept: `application/vnd.validere.v${
          config.API_VERSION
        }+json; ${ValidMimeTypes.join(";")}`,
      },
      responseType: "blob",
    })
    .then((response) => {
      return {
        status: response.status,
        data: response.data,
      };
    });
}

export function openEmailClient(sendTo, subject) {
  const href = "mailto:" + sendTo + "?subject=" + subject;
  const test = window.open(href);

  setTimeout(() => {
    test.close();
  }, 500);
}

export function getETagHeader(ETag) {
  if (ETag) {
    return { "If-None-Match": ETag };
  }

  return {};
}

export function getErrorMessage(statusCode) {
  switch (statusCode) {
    case 500:
      return ErrorResponseMessage.serverError;
    case 401:
      return ErrorResponseMessage.authError;
    case 403:
      return ErrorResponseMessage.noPermission;
    default:
      return ErrorResponseMessage.error;
  }
}

// Iterate through the specified API until an incomplete page is encountered
export async function flattenPagedApi(api, params) {
  const cumulativeResult = [];
  let currentPage = 1;
  let result;

  do {
    result = await api({
      ...params,
      page: currentPage,
      rowPerPage: NODE_API_MAX_PAGE_SIZE,
    });
    cumulativeResult.push(result);
    currentPage += 1;
  } while (result?.data?.data?.length === NODE_API_MAX_PAGE_SIZE);

  const totalData = cumulativeResult.flatMap(({ data: { data } }) => data);
  return {
    status: 200,
    data: {
      page_size: totalData.length,
      page_number: 1,
      total_entries: totalData.length,
      total_pages: 1,
      data: totalData,
    },
  };
}
