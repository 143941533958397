import { useBreadcrumbs } from "#src/Routers/breadcrumbsHelper";
import { useNavigate, useParams, useSearchParams } from "#src/Routers/hooks";
import { linkToFormSubmissionDetail } from "#src/Routers/links";
import { RoutingLink } from "#src/batteries-included-components/RoutingLink";
import AlertMessageWithLink from "#src/components/Common/Alerts/AlertMessageWithLink";
import { FORMS_BREADCRUMB } from "#src/routes/forms";
import { FORMS_CATEGORIES_BREADCRUMB } from "#src/routes/forms/categories";
import { WorkflowDetailsRoutePath } from "#src/routes/workflows/all/[workflowId]";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  Banner,
  Button,
  Form,
  Page,
  useAlert,
  useForm,
} from "@validereinc/common-components";
import {
  EventsDomain,
  FormCategoryAdapter,
  FormSchema,
  FormSubmission,
  FormSubmissionType,
  WorkflowTaskAdapter,
} from "@validereinc/domain";
import classNames from "classnames/bind";
import React, { useMemo } from "react";
import { CREATE_FORM_SUBMISSION_BREADCRUMB } from ".";
import { FORM_TEMPLATE_DETAILS_BREADCRUMB, linkToFormTemplateDetail } from "..";
import { FORM_CATEGORY_DETAILS_BREADCRUMB } from "../../..";
import { DEFAULT_QUERY_OPTIONS } from "../../../../../../../components/hooks/adapters/adapterUtils";
import styles from "./CreateFormSubmissionPage.module.scss";
import { getSmartDefaultValues } from "./util";
import { useAuthenticatedContext } from "#src/contexts/AuthenticatedContext.helpers";
import { FormSubmissionSection } from "#src/batteries-included-components/Forms/FormSubmissionForm/FormSubmissionSections/FormSubmissionSection";
import { FormSubmissionField } from "#src/batteries-included-components/Forms/FormSubmissionForm/FormSubmissionSections/FormSubmissionField";
import { FormSubmissionFormController } from "@validereinc/domain-controllers";

const cx = classNames.bind(styles);

const now = new Date().toISOString();

export const CreateFormSubmissionPage = () => {
  const navigate = useNavigate();

  const { categoryId, formTemplateId } = useParams<{
    categoryId: string;
    formTemplateId: string;
  }>();

  const { addAlert } = useAlert();

  const {
    v2: {
      userInfo: { user },
    },
  } = useAuthenticatedContext();

  const [
    {
      "event-id": eventId,
      "task-id": workflowTaskId,
      "associated-asset-id": associatedAssetId,
      "associated-asset-type": associatedAssetType,
      "default-values": serializedEncodedDefaultValues,
    },
  ] = useSearchParams();

  const defaultValues = useMemo(() => {
    let value = {} as Record<string, string>;
    if (!serializedEncodedDefaultValues) return value;
    try {
      const decodedDefaultValues = atob(serializedEncodedDefaultValues);
      value = JSON.parse(decodedDefaultValues) as Record<string, string>;
    } catch (err) {
      ExceptionUtils.reportException(err, "error", {
        hint: `Default values (URLParam: default-values) couldn't be decoded and parsed: ${serializedEncodedDefaultValues}`,
      });
    }
    return value;
  }, [serializedEncodedDefaultValues]);

  const query = useQuery({
    queryKey: ["formSchemas", formTemplateId] as const,
    queryFn: ({ queryKey: [_, formSchemaId] }) =>
      FormSchema.getOne({ id: formSchemaId }),
    ...DEFAULT_QUERY_OPTIONS,
  });

  const taskQuery = useQuery(
    ["workflows", "tasks", workflowTaskId],
    () => {
      if (!workflowTaskId) {
        return;
      }

      return WorkflowTaskAdapter.getOne({ id: workflowTaskId });
    },
    {
      enabled: Boolean(workflowTaskId),
      select: (resp) => resp?.data,
    }
  );

  const form = useForm<Pick<FormSubmissionType, "answers">>({
    defaultValues: getSmartDefaultValues(query?.data, {
      now,
      currentUserName: user?.name,
      associatedAssetId,
      associatedAssetType,
      defaultValues,
    }),
  });

  const queryClient = useQueryClient();

  const categoryQuery = useQuery({
    queryKey: ["formCategories", categoryId],
    queryFn: async ({ queryKey }) => {
      const [_, id] = queryKey;

      return await FormCategoryAdapter.getOne({
        id,
      });
    },
  });

  const templateQuery = useQuery({
    queryKey: ["formSchemas", formTemplateId],
    queryFn: async ({ queryKey }) => {
      const [_, formSchemaId] = queryKey;

      return await FormSchema.getOne({
        id: formSchemaId,
      });
    },
    staleTime: 2 * 60 * 1000,
  });

  const breadcrumbs = useBreadcrumbs(
    [
      FORMS_BREADCRUMB,
      FORMS_CATEGORIES_BREADCRUMB,
      FORM_CATEGORY_DETAILS_BREADCRUMB,
      FORM_TEMPLATE_DETAILS_BREADCRUMB,
      CREATE_FORM_SUBMISSION_BREADCRUMB,
    ],
    {
      2: categoryQuery?.data?.name,
      3: templateQuery?.data?.name,
    }
  );

  const mutation = useMutation({
    mutationFn: async (
      formValues: Parameters<typeof FormSubmission.createOne>[0]["data"]
    ) => {
      const body = {
        data: {
          ...formValues,
          workflow_task_id: workflowTaskId,
        },
      };

      const {
        data: { id, ...restResponse },
      } = await FormSubmission.createOne(body);

      if (eventId) {
        await EventsDomain.formSubmissions.addToEvent({
          formSubmissionId: id,
          eventId,
        });

        queryClient.invalidateQueries({
          queryKey: ["event", { id: eventId }],
        });
      }

      return { id, ...restResponse };
    },
    onSuccess: ({ id }, formValues) => {
      queryClient.invalidateQueries({ queryKey: ["workflows", "tasks"] });
      queryClient.invalidateQueries({ queryKey: ["events"] });
      queryClient.invalidateQueries({
        queryKey: ["forms", "submissions"],
      });

      addAlert?.({
        variant: "success",
        message:
          formValues.status !== "draft" ? (
            <AlertMessageWithLink
              mainText="Successfully created form submission."
              linkText="View Submission Details"
              position="bottom"
              onLinkClick={() =>
                navigate({
                  pathname: linkToFormSubmissionDetail(id),
                })
              }
            />
          ) : (
            "Successfully saved form as draft."
          ),
      });

      form.reset({}, { keepDirty: false });

      if (workflowTaskId || eventId) {
        navigate.goBack();
      } else {
        navigate({
          pathname: linkToFormTemplateDetail(categoryId, formTemplateId),
          query: {
            "submission-type": formValues.status === "draft" ? "draft" : "all",
          },
          replace: true,
        });
      }
    },
    onError: (err, formValues) => {
      addAlert?.({
        variant: "error",
        message:
          formValues.status !== "draft"
            ? "Failed to create form submission"
            : "Failed to save form as draft.",
      });
      console.error(err);
    },
  });

  const onError = (errors: Record<string, unknown>) => {
    console.error("Error submitting form", errors);
  };

  const onCancel = () => {
    navigate.goBack();
  };

  const onSubmit = form.handleSubmit((formValues) => {
    mutation.mutate({
      ...formValues,
      form_schema_id: formTemplateId,
    });
  }, onError);

  const onSaveAsDraft = async () => {
    const formValues = form.getValues();

    await mutation.mutate({
      ...formValues,
      form_schema_id: formTemplateId,
      status: "draft",
    });
  };

  const errorCount = Object.values(
    form.formState?.errors?.answers ?? {}
  ).reduce((total, value) => {
    const newErrors = value.map((value) => Object.keys(value ?? {})).flat();

    return (total += newErrors.length);
  }, 0);

  return (
    <Page
      isLoading={query?.isLoading}
      category={CREATE_FORM_SUBMISSION_BREADCRUMB.title}
      title={templateQuery.data?.name}
      breadcrumbs={breadcrumbs}
      footer={
        <div className={cx("footerContainer")}>
          <Button
            key="cancel-action"
            onClick={onCancel}
          >
            Cancel
          </Button>

          <div className={cx("footerActionsContainer")}>
            {errorCount ? (
              <p className={cx("errorCount")}>
                {errorCount
                  ? `${errorCount} field${
                      errorCount > 1 ? "s have" : " has"
                    } an error. Please fix the error${
                      errorCount > 1 ? "s" : ""
                    } to submit the form.`
                  : ""}
              </p>
            ) : null}

            <Button
              key="save-as-draft-action"
              onClick={onSaveAsDraft}
              isLoading={
                mutation.isLoading && mutation.variables?.status === "draft"
              }
              disabled={mutation.isLoading}
            >
              Save as Draft
            </Button>

            <Button
              key="submit-action"
              variant="primary"
              onClick={onSubmit}
              isLoading={
                mutation.isLoading && mutation.variables?.status !== "draft"
              }
              disabled={mutation.isLoading}
            >
              Submit
            </Button>
          </div>
        </div>
      }
    >
      {taskQuery?.data && !taskQuery.isLoading ? (
        <div className={cx("bannerContainer")}>
          <Banner
            titleText="This submission has some associated automation"
            descriptionText={`Submitting this form will attach it to the "${taskQuery?.data.name}" task in workflow "${taskQuery.data?.workflow.workflow_template.name}".`}
            actionContent={
              <RoutingLink
                to={WorkflowDetailsRoutePath.toLinkParts({
                  pathParams: {
                    workflowId: taskQuery.data.workflow_id,
                  },
                })}
              >
                View Workflow
              </RoutingLink>
            }
          />
        </div>
      ) : null}

      <Form {...form}>
        <FormSubmissionFormController
          form={form}
          formSchema={query?.data}
          sectionRenderFunction={(all) => <FormSubmissionSection {...all} />}
          questionRenderFunction={(all) => <FormSubmissionField {...all} />}
        />
      </Form>
    </Page>
  );
};
