import { LegacyDataTable } from "@validereinc/common-components";
import get from "lodash/get";
import sortBy from "lodash/sortBy";
import * as PropTypes from "prop-types";
import React, { useMemo } from "react";
import { connect } from "react-redux";
import { AutoSizer } from "react-virtualized";
import useFilterInputs from "./StreamsTableFilter";

const ROW_HEIGHT = 60;

const mapStateToProps = ({ streams }) => {
  return {
    streams: streams.data.toJS() ?? [],
    isLoading: streams.isRequestInProgress,
  };
};

const sortByColumnKey = (rowData, columnKey) => {
  return sortBy(rowData, (rowData) => get(rowData, columnKey));
};

const simpleTextRenderer = (rowData, columnKey) => {
  const text = get(rowData, columnKey, "-");

  return <div className="capitalized textWrapColumn">{text}</div>;
};

const shortCodeRenderer = (rowData, columnKey) => {
  const shortCodes = rowData[columnKey];

  return shortCodes ? (
    <div className="samplesTable__streamShortCode">
      {rowData[columnKey]?.map((shortCode) => (
        <div key={shortCode}>{shortCode}</div>
      ))}
    </div>
  ) : (
    "-"
  );
};

const StreamsTable = (props) => {
  const streamsWithShortCodes = useMemo(() => {
    const shortCodeMap = {};
    props.shortCodes.forEach((shortCode) => {
      if (shortCodeMap[shortCode.stream_id]) {
        shortCodeMap[shortCode.stream_id].push(shortCode.short_code);
      } else {
        shortCodeMap[shortCode.stream_id] = [shortCode.short_code];
      }
    });

    return props.streams.map((stream) => {
      const shortCode = shortCodeMap[stream.id];

      if (shortCode) {
        return { ...stream, stream_short_code: shortCode };
      }
      return stream;
    });
  }, [props.streams, props.shortCodes]);

  const [filterRow, filterPillbox, filteredStreamsWithShortCodes] =
    useFilterInputs(streamsWithShortCodes);

  const headers = [
    {
      label: "Stream Name",
      key: "name",
      width: 250,
      cellRenderer: simpleTextRenderer,
      fixed: true,
    },
    {
      label: "Stream Short Code",
      key: "stream_short_code",
      width: 175,
      cellRenderer: shortCodeRenderer,
    },
    {
      label: "Product Type",
      key: "product_type",
      width: 150,
    },
    {
      label: "Transport Type",
      key: "transport_type",
      cellRenderer: simpleTextRenderer,
      sort: sortByColumnKey,
      width: 200,
    },
    {
      label: "Source Facility",
      key: "source_facility.name",
      cellRenderer: simpleTextRenderer,
      sort: sortByColumnKey,
      width: 200,
    },
    {
      label: "Terminal Facility",
      key: "terminal_facility.name",
      cellRenderer: simpleTextRenderer,
      sort: sortByColumnKey,
      width: 250,
      disableSort: true,
    },
  ];

  return (
    <AutoSizer>
      {({ height, width }) => (
        <LegacyDataTable
          width={width}
          height={height}
          headers={headers}
          list={filteredStreamsWithShortCodes}
          filterRow={filterRow}
          filterPillbox={filterPillbox}
          filterKey="name"
          defaultSortBy="date"
          defaultSortDirection="desc"
          rowHeight={ROW_HEIGHT}
          noFilterListCount={props.streams?.length}
          isLoading={props.isLoading}
          onCheckboxClick={props.onStreamCheck}
          checkedList={props.selectedStream}
          disableSelectAll
          disableCheckbox={props.disabled}
        />
      )}
    </AutoSizer>
  );
};

StreamsTable.propTypes = {
  streams: PropTypes.array.isRequired,
  isLoading: PropTypes.bool,
  onStreamCheck: PropTypes.func,
  selectedStream: PropTypes.array,
  shortCodes: PropTypes.array,
  disabled: PropTypes.bool,
};

export default connect(mapStateToProps, null)(StreamsTable);
