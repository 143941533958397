import { WorkflowCategoriesRoutePath } from "#src/routes/workflows/categories";
import { RoutePath } from "#src/utils/route";

export const WorkflowCategoryDetailsRoutePath =
  WorkflowCategoriesRoutePath.concat(
    new RoutePath<"workflowCategoryId">({
      path: "/:workflowCategoryId",
      title: "Workflow Category Details",
    })
  );
