import SummaryInformation from "#common/SummaryInformation/SummaryInformation";
import { getFormattedMeasurementValue } from "#redux/reducers/measurements";
import config from "#src/config";
import history from "#src/Routers/history";
import {
  linkToManualEntry,
  linkToSampleDetail,
  linkToTestDetail,
} from "#src/Routers/links";
import { getTimeStringFromDate } from "#utils/timeFormatter";
import { Button, Modal, Title } from "@validereinc/common-components";
import * as PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import "./FieldManualEntryConfirmationModal.scss";
import SelectedSampleSummary from "./SelectedSampleSummary";

const mapStateToProps = ({ measurements }) => {
  return {
    getFormattedMeasurementValue: (measurementKey, measurementValue) =>
      getFormattedMeasurementValue(measurements)(
        measurementKey,
        measurementValue
      ),
  };
};

const FieldManualEntryConfirmationModal = (props) => {
  const { instrument, date, measurements, samples } = props.input;

  const onTestLinkClick = () => {
    history.push(linkToTestDetail(props.createdTestId));
  };

  const onSampleLinkClick = () => {
    if (samples?.id) {
      history.push(linkToSampleDetail(samples.id));
    }
  };

  const onConfirmationClick = () => {
    history.push(linkToManualEntry());
  };

  const onHide = () => {
    props.onHide();
    onConfirmationClick();
  };

  return (
    <Modal
      open={props.show}
      onClose={onHide}
    >
      <Modal.Header>
        <Title>Field Test Created</Title>
      </Modal.Header>

      <Modal.Body>
        <div className="fieldManualEntryConfirmationModal__testDetail">
          <Title
            type="subheader"
            className="title"
          >
            Test Detail
          </Title>

          <SummaryInformation
            title="Instrument"
            value={instrument?.name ?? "-"}
          />

          <SummaryInformation
            title="Test Date"
            value={getTimeStringFromDate(date, config.DATETIME_FORMAT)}
          />

          {measurements.map(({ value, unit, temperature, type }) => {
            const formattedValue = props.getFormattedMeasurementValue(
              type,
              value
            );

            const valueString = `${formattedValue} ${unit} ${
              temperature ? `(${temperature} °C)` : ""
            }`;

            return (
              <SummaryInformation
                key={type}
                title={type}
                value={valueString}
              />
            );
          })}
        </div>

        {samples && (
          <div className="fieldManualEntryConfirmationModal__sampleDetail">
            <Title type="subheader">Associated Sample</Title>

            <SelectedSampleSummary sample={samples} />
          </div>
        )}
      </Modal.Body>

      <Modal.Footer>
        <div className="fieldManualEntryConfirmationModal__actionRow">
          <Button
            className="pull-left"
            onClick={onHide}
          >
            Close
          </Button>

          {samples?.id && (
            <Button onClick={onSampleLinkClick}>Go to Sample</Button>
          )}

          <Button
            variant="primary"
            onClick={onTestLinkClick}
          >
            Go to test
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

FieldManualEntryConfirmationModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  input: PropTypes.object,
  getFormattedMeasurementValue: PropTypes.func.isRequired,
  createdTestId: PropTypes.string,
};

export default connect(mapStateToProps)(FieldManualEntryConfirmationModal);
