import { useNavigate } from "#src/Routers/hooks";
import { Button, EmptyState, Icon } from "@validereinc/common-components";
import classNames from "classnames/bind";
import React from "react";
import styles from "./NotAvailable.module.scss";

const cx = classNames.bind(styles);

export const NotAvailableLayout = () => {
  const navigate = useNavigate();

  return (
    <EmptyState
      icon={<Icon variant="lock" />}
      title="You do not have permission to view this page or it does not exist."
      suggestion="Contact your system administrator for more information."
      className={cx("container")}
      action={
        <Button
          variant="primary"
          onClick={() => {
            navigate.goBack();
          }}
          iconPosition="left"
          icon="arrow-left"
        >
          Return to previous page
        </Button>
      }
    />
  );
};
