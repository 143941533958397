import { useEffect, useState } from "react";

import RecordOfQualityService from "#services/RecordOfQualityService";
import { getCorrectMockUnit } from "../Production/Streams/Detail/StreamDetailHelper";

export function useRecordsOfQuality(streams) {
  const [recordsOfQuality, setRecordsOfQuality] = useState([]);

  useEffect(() => {
    RecordOfQualityService.getAccountingPeriods().then(
      ({ data: accountingPeriods }) => {
        const { id: accountingPeriodId } = accountingPeriods.find(
          ({ balance_type }) => balance_type === "gas"
        );

        const { id: liquidAccountingPeriodId } = accountingPeriods.find(
          ({ balance_type }) => balance_type === "liquid"
        );

        Promise.all([
          RecordOfQualityService.getRecordOfQualities(accountingPeriodId),
          RecordOfQualityService.getRecordOfQualities(liquidAccountingPeriodId),
        ]).then(([{ data: records }, { data: liquidRecords }]) => {
          setRecordsOfQuality(
            streams.reduce((total, value) => {
              const recordsList =
                getCorrectMockUnit(value) === "liquid"
                  ? liquidRecords
                  : records;

              const record = recordsList.find(
                ({ selection_stream_id }) => value.id === selection_stream_id
              );

              if (record) {
                return [
                  ...total,
                  {
                    ...value,
                    product_category: record.stream.product_category,
                    recordOfQualityId: record.id,
                    last_revision_by: record.last_revision_by,
                  },
                ];
              }

              return total;
            }, [])
          );
        });
      }
    );
  }, [streams]);

  return [recordsOfQuality];
}
