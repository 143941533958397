import {
  Button,
  useFieldArray,
  useFormContext,
} from "@validereinc/common-components";
import React from "react";
import { QuestionCondition } from "./QuestionCondition";

export const QuestionConditions = ({ questions }) => {
  const form = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: "conditions",
  });

  const onAddCondition = () => {
    append({ question: "", operation: "$exists", value: "", type: "$exists" });
  };

  const onRemoveCondition = (conditionIndex: number) => {
    remove(conditionIndex);
  };

  return (
    <>
      <Button
        icon="plus-circle"
        onClick={onAddCondition}
      >
        Add Condition
      </Button>

      {fields.map((_field, index) => (
        <QuestionCondition
          key={index}
          index={index}
          questions={questions}
          onRemoveCondition={onRemoveCondition}
          isRemovable={fields.length > 1}
        />
      ))}
    </>
  );
};
