// use for requests that query/get data
export const QUERY_STATUS = {
  LOADING: "loading",
  SUCCESS: "success",
  ERROR: "error",
} as const;

// use for requests that query/get in the background (e.g. stale data re-fetching)
export const FETCH_STATUS = {
  IDLE: "idle",
  FETCHING: "fetching",
  PAUSED: "paused",
} as const;

// use for requests that are mutations - updates, creates, deletes etc.
export const MUTATION_STATUS = {
  IDLE: "idle",
  ...QUERY_STATUS,
} as const;

export type QueryStatusType = (typeof QUERY_STATUS)[keyof typeof QUERY_STATUS];
export type FetchStatusType = (typeof FETCH_STATUS)[keyof typeof FETCH_STATUS];
export type MutationStatusType =
  (typeof MUTATION_STATUS)[keyof typeof MUTATION_STATUS];
