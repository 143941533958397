import {
  apiRequestServicesPromise,
  apiPostServicesPromise,
  apiPutServicesPromise,
} from "./ServiceHelper";

const StreamService = {
  getScheduleTask() {
    // We are excluding manual workflow task since it can only be manually completed
    const params = {
      test_type: ["field", "third_party_lab"],
    };

    return apiRequestServicesPromise("/api/workflows/instances", params);
  },

  getStreams() {
    return apiRequestServicesPromise("/api/streams").then(
      ({ data, ...rest }) => ({
        ...rest,
        data: data.filter(({ type }) => type === "Client"),
      })
    );
  },

  createSample(parameter) {
    return apiPostServicesPromise("/api/samples", parameter);
  },

  assignToStream(stream_id, test_ids) {
    const parameters = {
      test_ids,
    };

    return apiPutServicesPromise(`/api/streams/${stream_id}/tests`, parameters);
  },
};

export default StreamService;
