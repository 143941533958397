import { getValue } from "../InstrumentHelper";
import { getFirstValidValue } from "#utils/objectFormatter";

const displayProperties = (test) => {
  const densityTemperature = getFirstValidValue([
    test.metadata?.density_temperature?.value,
    test.measurements.Density?.temperature_in_c,
  ]);

  const DensityLabel = densityTemperature
    ? `Density @ ${densityTemperature} °C`
    : "Density";

  return [
    {
      label: DensityLabel,
      key: "Density",
    },
    {
      label: "Specific Gravity",
      key: "specific_gravity",
      getValue: (value) => getValue(value)?.value ?? "-",
      decimals: 5,
      notMeasurement: true,
    },
    {
      label: "API Density @ 15 ℃",
      key: "api_density_15_c",
      measurementKey: "Density",
    },
    {
      label: "API Gravity @ 15 ℃",
      key: "api_gravity_15_c",
      getValue: (value) => getValue(value)?.value ?? "-",
      decimals: 2,
      notMeasurement: true,
    },
    {
      label: "API Spec. Gravity @ 15 ℃",
      key: "api_spec_gravity_15_c",
      getValue: (value) => getValue(value)?.value ?? "-",
      decimals: 2,
      notMeasurement: true,
    },
  ];
};

export default displayProperties;
