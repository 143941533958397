import {
  useExportEquipment,
  useListEquipment,
} from "#hooks/adapters/useEquipment";
import { useGetOneFacility } from "#hooks/adapters/useFacilities";
import { useTableSortingAndPagination } from "#redux/reducers/tableStateReducer";
import { useNavigate, useParams, useSearchParams } from "#routers/hooks";
import { linkToAddEquipment, linkToEquipmentDetail } from "#routers/links";
import { FacilityDetailPageParams } from "#routes/organization/facilities/[facilityId]";
import { ImportDataActionStatusWithQueue } from "#src/batteries-included-components/Banners/ImportDataActionStatus";
import { ImportDataAction } from "#src/batteries-included-components/Buttons/ImportDataAction";
import { RoutingLink } from "#src/batteries-included-components/RoutingLink/RoutingLink";
import { useIsFeatureAvailable } from "#src/contexts/AuthenticatedContext.helpers";
import useLocalization from "#src/hooks/useLocalization";
import { useStorageKey } from "#src/hooks/useStorageKey";
import {
  Button,
  DataTable,
  DataTablePanel,
  HeaderType,
  SortingType,
  useFilters,
} from "@validereinc/common-components";
import { AssetType, EquipmentType, SortDirection } from "@validereinc/domain";
import {
  getYearMonthFromDateRange,
  toSnakeCaseString,
  toStartCaseString,
} from "@validereinc/utilities";
import React from "react";

const sorting: SortingType = {
  sortBy: "name",
  sortDirection: SortDirection.ASCENDING,
};

export const FacilityEquipmentPanel = ({
  filterKey,
}: {
  filterKey: string;
}) => {
  const [isDataIngestionEnabled] = useIsFeatureAvailable({
    featureFlagQuery: "core:data_pipeline",
  });
  const { tableConfigStorageKey } = useStorageKey("facilities-equipment");
  const navigate = useNavigate();
  const { localize } = useLocalization();
  const { facilityId } = useParams<FacilityDetailPageParams>();

  const [
    {
      section: _section, // pulls it out of request since its in searchParams
      tab: _tab, // same as above
      ...equipmentFilters
    },
  ] = useSearchParams();

  const [{ period }] = useFilters<{ period: { from?: string } }>(filterKey);

  const [tableState, updateTableState] = useTableSortingAndPagination(
    { ...sorting, itemsPerPage: 25 },
    { equipmentFilters, facilityId }
  );

  const equipmentApiParams = {
    page: tableState.page,
    pageSize: tableState.itemsPerPage,
    sortBy: tableState.sortBy,
    sortDirection: tableState.sortDirection,
    period: getYearMonthFromDateRange(period),
    filters: {
      ...equipmentFilters,
      "facility.id": facilityId,
    },
  };

  const { data, isLoading } = useListEquipment(equipmentApiParams);
  const items = data?.data ?? [];
  const { data: facility } = useGetOneFacility(facilityId, {
    period: getYearMonthFromDateRange(period),
  });

  const { mutate: handleExport, isLoading: isExporting } = useExportEquipment(
    equipmentApiParams,
    facility?.name ? toSnakeCaseString(facility.name) : undefined
  );

  function handleOnAddEquipmentClick() {
    navigate({
      pathname: linkToAddEquipment(facilityId),
    });
  }

  const actionRow = [
    <Button
      key="add-equipment"
      variant="outline"
      onClick={handleOnAddEquipmentClick}
    >
      Add {localize("Equipment")}
    </Button>,
    <Button
      key="export-equipment"
      variant="outline"
      onClick={() => handleExport()}
      isLoading={isExporting}
    >
      Export
    </Button>,
    isDataIngestionEnabled ? (
      <ImportDataAction
        key="import-equipment"
        resource={{
          id: AssetType.EQUIPMENT,
          name: "Equipment",
          singular: localize("Equipment"),
          plural: localize("equipment_plural"),
        }}
      />
    ) : null,
  ];

  const headers: Array<HeaderType<EquipmentType>> = [
    {
      key: "name",
      label: "Name",
      minWidth: 300,
      isSortable: true,
      renderComponent: ({ item }) => (
        <RoutingLink to={linkToEquipmentDetail(item?.id)}>
          {item.name}
        </RoutingLink>
      ),
    },
    {
      key: "type.name",
      label: "Type",
      isSortable: false,
      renderComponent: ({ item }) => item?.type?.name ?? "-",
    },
    {
      label: "Status",
      key: "status",
      isSortable: true,
      renderComponent: ({ item }) => (
        <DataTable.DataRow.PillCell
          variant={item.status === "active" ? "success" : "default"}
          value={toStartCaseString(item.status)}
        />
      ),
    },
    {
      label: "Effective Date",
      key: "effective_date",
      isSortable: true,
      renderComponent: ({ item }) => (
        <DataTable.DataRow.DateCell value={item.effective_date} />
      ),
    },
  ];

  return (
    <>
      {isDataIngestionEnabled ? (
        <ImportDataActionStatusWithQueue
          resource={{
            id: AssetType.EQUIPMENT,
            name: "Equipment",
            singular: localize("Equipment"),
            plural: localize("equipment_plural"),
          }}
        />
      ) : null}
      <DataTablePanel
        storageKey={tableConfigStorageKey}
        panelProps={{
          title: localize("equipment_plural"),
          actionRow,
        }}
        dataTableProps={{
          headers,
          items,
          isLoading,
          sorting,
          pagination: {
            page: tableState.page,
            itemsPerPage: tableState.itemsPerPage,
            total: data?.total_entries ?? 0,
          },
          onSortChange: updateTableState,
          onPaginationChange: updateTableState,
        }}
      />
    </>
  );
};
