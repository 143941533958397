import { Button, Modal, Title } from "@validereinc/common-components";
import React, { Component } from "react";
import StreamChooser from "./StreamChooser";

/* eslint-disable react/prop-types */

export default class StreamListModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedStream: null,
    };
  }

  onStreamSelected = (selectedStream) => {
    this.props.onMatchClicked(selectedStream);
  };

  render() {
    const { selectedStream } = this.state;
    const { title, handleClose } = this.props;

    return (
      <Modal
        className="streamListModal"
        open={true}
        onClose={handleClose}
      >
        <Modal.Header>
          <Title>{title}</Title>
        </Modal.Header>

        <Modal.Body>
          <StreamChooser
            selectedStream={selectedStream}
            onStreamSelected={this.onStreamSelected}
          />
        </Modal.Body>
        <Modal.Footer className="clearfix">
          <Button
            className="pull-left"
            onClick={handleClose}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
