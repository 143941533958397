import React, { createContext, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { ensureStreamListIsFetched } from "#redux/actions/index";
import { addAlertMessage } from "#redux/actions/alertMessages";
import useManageSelectionModal from "./useManageSelectionModal";

export const SelectionModalContext = createContext();

const mapStateToProps = ({ streams }) => ({
  streams,
});

const mapDispatchToProps = {
  ensureStreamListIsFetched,
  addAlertMessage,
};

const SelectionModalProvider = ({ children, ...props }) => {
  const modalDataAndFunctions = useManageSelectionModal(props);

  useEffect(() => {
    props.ensureStreamListIsFetched();
  }, []);

  return (
    <SelectionModalContext.Provider
      value={{
        ...props,
        ...modalDataAndFunctions,
      }}
    >
      {children}
    </SelectionModalContext.Provider>
  );
};

export const useSelectionModalContext = () => useContext(SelectionModalContext);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectionModalProvider);

SelectionModalProvider.propTypes = {
  children: PropTypes.node.isRequired,
  streams: PropTypes.object,
  accountingRecordDetail: PropTypes.object,
  accountingPeriod: PropTypes.object,
  hasWriteAccess: PropTypes.bool,
  displayProperties: PropTypes.array,
  ensureStreamListIsFetched: PropTypes.func,
  addAlertMessage: PropTypes.func,
  refreshAccountingPeriod: PropTypes.func,
  isModalVisisble: PropTypes.bool,
};
