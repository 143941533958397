import {
  useGetOneTransactionDetail,
  useOriginalSubmissionFileDownload,
} from "#hooks/adapters/useTransactions";
import { useBreadcrumbs } from "#routers/breadcrumbsHelper";
import { DATA_TOOLS_BREADCRUMB } from "#routes/data-tools";
import { DATA_SUBMISSIONS_LIST_BREADCRUMB } from "#routes/data-tools/submissions";
import {
  DATA_SUBMISSION_DETAIL_BREADCRUMB,
  DATA_SUBMISSION_DETAIL_TITLE,
} from "#routes/data-tools/submissions/[transactionId]/detail";
import {
  extractItemsFromTransactionDetails,
  getTransactionTitle,
  showErrorDetails,
  useDataSubmissionDetail,
} from "#routes/data-tools/submissions/[transactionId]/detail/DataSubmissionDetail.helpers";
import { DataSubmissionDetailsPanel } from "#routes/data-tools/submissions/[transactionId]/detail/DataSubmissionDetailsPanel";
import { DataSubmissionErrorPanel } from "#routes/data-tools/submissions/[transactionId]/detail/DataSubmissionErrorPanel";
import { DataSubmissionRowTable } from "#routes/data-tools/submissions/[transactionId]/detail/DataSubmissionRowTable";
import { DataSubmissionStatusPanel } from "#routes/data-tools/submissions/[transactionId]/detail/DataSubmissionStatusPanel";
import { Button, Column, Page, Row } from "@validereinc/common-components";
import React from "react";

export const DataSubmissionDetailPage = () => {
  const {
    firstTransactionState: transaction,
    dataset,
    isLoading,
  } = useDataSubmissionDetail();

  const { data: transactionDetailData } = useGetOneTransactionDetail();
  const showErrorInstances = showErrorDetails(
    extractItemsFromTransactionDetails(transactionDetailData)
  );

  const title = getTransactionTitle(transaction, dataset);

  const downloadOriginalSubmission = useOriginalSubmissionFileDownload();

  const actionRow = [
    <Button
      key="download-original-submission"
      variant="outline"
      onClick={() =>
        downloadOriginalSubmission.mutate({
          transactionId: transaction?.transaction_id ?? "",
          fileName: transaction?.original_file_name ?? "",
        })
      }
      isLoading={downloadOriginalSubmission.isLoading}
    >
      Download Original Submission
    </Button>,
  ];

  return (
    <Page
      title={title}
      category={DATA_SUBMISSION_DETAIL_TITLE}
      isLoading={isLoading}
      actionRow={actionRow}
      breadcrumbs={useBreadcrumbs(
        [
          DATA_TOOLS_BREADCRUMB,
          DATA_SUBMISSIONS_LIST_BREADCRUMB,
          DATA_SUBMISSION_DETAIL_BREADCRUMB,
        ],
        { 2: title }
      )}
    >
      <Row>
        <Column variant={6}>
          <DataSubmissionStatusPanel />
        </Column>
        <Column variant={18}>
          <DataSubmissionDetailsPanel />
        </Column>
      </Row>
      <Row>
        <Column variant={showErrorInstances ? 18 : 24}>
          <DataSubmissionRowTable />
        </Column>
        {showErrorInstances ? (
          <Column variant={6}>
            <DataSubmissionErrorPanel />
          </Column>
        ) : null}
      </Row>
    </Page>
  );
};
