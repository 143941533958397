import { WorkflowTaskType } from "../schemas";
import {
  DeleteOneRequestType,
  FilterObjectType,
  GetListRequestType,
  GetListResponseType,
  HTTPResponseError,
  ResourceServiceType,
  ResponseErrorType,
  ResponseStatusType,
  UpdateOneRequestType,
  UpdateOneResponseType,
  fetchAndCollate,
  getFilterObject,
  isResponseErrorType,
} from "../util";
import { restAPI } from "./api";

export const WorkflowTaskAdapter: WorkflowTaskAdapterType = {
  /**
   * Get the complete list of Workflow Task
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/workflow_tasks/search_workflow_tasks
   */
  getList: async ({ page, pageSize, sortBy, sortDirection, filters }) =>
    fetchAndCollate(
      async ({ page, pageSize }) => {
        const formattedFilters = getFilterObject(filters);
        const resp = await restAPI.nodeAPI.POST<
          GetListResponseType<WorkflowTaskType> | ResponseErrorType
        >({
          endpoint: `/workflow_tasks/search`,
          body: {
            page,
            page_size: pageSize,
            sort_by: sortBy,
            sort_direction: sortDirection,
            filter: formattedFilters,
          },
        });

        if (isResponseErrorType(resp)) {
          console.error(
            "API returned an error for /workflow_tasks/search",
            resp
          );
          throw new HTTPResponseError(
            "API returned an error /workflow_tasks/search"
          );
        }

        return resp;
      },
      page,
      pageSize
    ),
  actions: {
    getList: async ({ page, pageSize, sortBy, sortDirection, filters }) =>
      fetchAndCollate(
        async ({ page, pageSize }) => {
          const formattedFilters = getFilterObject(filters);
          const resp = await restAPI.nodeAPI.POST<
            GetListResponseType<WorkflowTaskType> | ResponseErrorType
          >({
            endpoint: `/workflow_tasks/steps/search`,
            body: {
              page,
              page_size: pageSize,
              sort_by: sortBy,
              sort_direction: sortDirection,
              filter: formattedFilters,
            },
          });

          if (isResponseErrorType(resp)) {
            console.error(
              "API returned an error for /workflow_tasks/steps/search",
              resp
            );
            throw new HTTPResponseError(
              "API returned an error /workflow_tasks/steps/search"
            );
          }

          return resp;
        },
        page,
        pageSize
      ),
  },
  getOne: async ({ id }) => {
    const resp = await restAPI.nodeAPI.GET<WorkflowTaskType>({
      endpoint: `/workflow_tasks/${id}`,
    });

    return {
      data: resp,
    };
  },
  getMany: () =>
    Promise.reject({
      success: false,
      error: "Method not implemented",
    }),

  /**
   * Update a workflow task
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/workflow_tasks/put_workflow_task
   */
  updateOne: async ({
    id,
    data: { status, assignee_user, assignee_group },
  }) => {
    const resp = await restAPI.nodeAPI.PUT<WorkflowTaskType>({
      endpoint: `/workflow_tasks/${id}`,
      body: {
        ...(status ? { status } : {}),
        ...(assignee_user !== undefined ? { assignee_user } : {}),
        ...(assignee_group !== undefined ? { assignee_group } : {}),
      },
    });

    return {
      data: resp,
    };
  },
  updateMany: () =>
    Promise.reject({
      success: false,
      error: "Method not implemented",
    }),
  createOne: () =>
    Promise.reject({
      success: false,
      error: "Method not implemented",
    }),
  createMany: () =>
    Promise.reject({
      success: false,
      error: "Method not implemented",
    }),
  deleteOne: () =>
    Promise.reject({
      success: false,
      error: "Method not implemented",
    }),
  deleteMany: () =>
    Promise.reject({
      success: false,
      error: "Method not implemented",
    }),
  formSubmission: {
    updateOne: ({ id, meta }) => {
      const { taskId } = meta ?? {};

      return restAPI.nodeAPI.PUT({
        endpoint: `/workflow_tasks/${taskId}/form_submission/${id}`,
      });
    },
    deleteOne: ({ id, meta }) => {
      const { taskId } = meta ?? {};

      return restAPI.nodeAPI.DELETE({
        endpoint: `/workflow_tasks/${taskId}/form_submission/${id}`,
      });
    },
  },
};

type WorkflowTaskGetListType = (
  params: GetListRequestType<
    FilterObjectType<
      Exclude<
        WorkflowTaskType,
        "step_id" | "emission_event" | "company_id" | "form_submission"
      > & {
        "assignee_group.user_id": string;
      }
    >
  >
) => Promise<GetListResponseType<WorkflowTaskType>>;

export interface WorkflowTaskAdapterType
  extends ResourceServiceType<WorkflowTaskType> {
  getList: WorkflowTaskGetListType;
  actions: {
    getList: WorkflowTaskGetListType;
  };
  formSubmission: Pick<ResourceServiceType<void>, "updateOne" | "deleteOne"> & {
    updateOne: (
      params: UpdateOneRequestType<
        void,
        {
          taskId: string;
        }
      >
    ) => Promise<UpdateOneResponseType<void>>;
    deleteOne: (
      params: DeleteOneRequestType<
        void,
        {
          taskId: string;
        }
      >
    ) => Promise<ResponseStatusType | ResponseErrorType>;
  };
}
