import React from "react";

import { Column, Row, StorageKeys } from "@validereinc/common-components";
import classNames from "classnames/bind";

import { FlowDetailsPanel } from "./FlowDetailsPanel";
import { FlowDevicesPanel } from "./FlowDevicesPanel";
import { FlowDownstreamPanel } from "./FlowDownstreamPanel";
import { FlowStatusPanel } from "./FlowStatusPanel";
import { FlowUpstreamPanel } from "./FlowUpstreamPanel";

import { PeriodSelector } from "#src/batteries-included-components";
import styles from "./FlowDetailPage.module.scss";
const cx = classNames.bind(styles);

export const FlowOverviewTab = ({
  filterConfigStorageKey,
}: Pick<StorageKeys, "filterConfigStorageKey">) => {
  return (
    <>
      <PeriodSelector filterKey={filterConfigStorageKey} />
      <div className={cx("container")}>
        <div className={cx("contentStatus")}>
          <FlowStatusPanel filterConfigStorageKey={filterConfigStorageKey} />
        </div>

        <div className={cx("contentContainer")}>
          <FlowDetailsPanel filterConfigStorageKey={filterConfigStorageKey} />

          <Row>
            <Column variant={9}>
              <FlowUpstreamPanel
                filterConfigStorageKey={filterConfigStorageKey}
              />
            </Column>

            <Column variant={9}>
              <FlowDownstreamPanel
                filterConfigStorageKey={filterConfigStorageKey}
              />
            </Column>
          </Row>

          <FlowDevicesPanel filterConfigStorageKey={filterConfigStorageKey} />
        </div>
      </div>
    </>
  );
};
