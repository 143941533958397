import { useBreadcrumbs } from "#src/Routers/breadcrumbsHelper";
import { useNavigate, useSearchParams } from "#src/Routers/hooks";
import {
  RolePermissionsTab,
  RoleSummaryTab,
  RoleUsersTab,
} from "#src/batteries-included-components";
import { AccessDeniedLayout } from "#src/batteries-included-components/Layouts/Authorization/AccessDenied";
import { useIsOpsHubExperience } from "#src/contexts/AuthenticatedContext.helpers";
import { SETTINGS_BREADCRUMB } from "#src/routes/settings";
import {
  ROLES_AND_PERMISSIONS_BREADCRUMB,
  linkToRolesListPage,
} from "#src/routes/settings/roles-and-permissions";
import { ROLES_BREADCRUMB } from "#src/routes/settings/roles-and-permissions/roles";
import { ROLE_DETAILS_BREADCRUMB } from "#src/routes/settings/roles-and-permissions/roles/[roleId]/detail";
import {
  RoleContext,
  RoleProvider,
} from "#src/routes/settings/roles-and-permissions/roles/[roleId]/detail/RoleContext";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  Button,
  Dialog,
  Page,
  Tab,
  Tooltip,
  useAlert,
} from "@validereinc/common-components";
import { RoleStatus, RoleType, RolesAdapter } from "@validereinc/domain";
import React, { useContext, useState } from "react";
import { linkToRoleEditPage } from "../edit";

const DEFAULT_TAB_KEY = "summary";

const RoleDetailPageContent = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { addAlert } = useAlert();
  const navigate = useNavigate();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const { roleQuery, doesRoleHaveMembers } = useContext(RoleContext) ?? {};
  const queryClient = useQueryClient();
  const breadcrumbs = useBreadcrumbs(
    [
      SETTINGS_BREADCRUMB,
      ROLES_AND_PERMISSIONS_BREADCRUMB,
      ROLES_BREADCRUMB,
      ROLE_DETAILS_BREADCRUMB,
    ],
    {
      3: roleQuery?.data?.name,
    }
  );
  const updateRoleMutation = useMutation({
    mutationFn: ({
      roleId,
      status,
    }: {
      roleId: string;
      status: RoleType["status"];
    }) => {
      return RolesAdapter.updateOne({ id: roleId, data: { status } });
    },
    onSuccess: (updatedRole) => {
      queryClient.invalidateQueries({
        queryKey: ["roles"],
      });
      addAlert?.({
        variant: "success",
        message: `Successfully updated role ${updatedRole.data.name ?? ""}`,
      });
    },
    onError: () => {
      addAlert?.({
        variant: "error",
        message: "Failed to update role",
      });
    },
  });
  const deleteRoleMutation = useMutation({
    mutationFn: ({ roleId }: { roleId: string }) => {
      return RolesAdapter.deleteOne({ id: roleId });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["roles"] });
      queryClient.invalidateQueries({ queryKey: ["users"] });
      addAlert?.({
        variant: "success",
        message: `Successfully deleted role ${roleQuery?.data?.name ?? ""}`,
      });
      navigate({
        pathname: linkToRolesListPage(),
      });
    },
    onError: () => {
      addAlert?.({
        variant: "error",
        message: "Failed to delete role",
      });
    },
  });

  const handleActiveTabKeyChange = (tabKey) => {
    setSearchParams({ tab: tabKey });
  };

  const onEdit = () => {
    if (roleQuery?.data?.id) {
      navigate({ pathname: linkToRoleEditPage(roleQuery.data.id) });
    }
  };

  const getActionRow = () => {
    return [
      ...(!doesRoleHaveMembers
        ? [
            <Button
              key="delete-role"
              variant="error"
              onClick={() => setIsDeleteModalOpen(true)}
              isLoading={deleteRoleMutation.isLoading}
              disabled={roleQuery?.data?.status === "disabled"}
            >
              Delete
            </Button>,
          ]
        : [
            <Tooltip
              key="delete-role"
              content="Delete all associated users before deleting this role"
            >
              <Button
                variant="error-outline"
                onClick={() => setIsDeleteModalOpen(true)}
                isLoading={deleteRoleMutation.isLoading}
                disabled
              >
                Delete
              </Button>
            </Tooltip>,
          ]),
      <Button
        key="update-status"
        variant={
          roleQuery?.data?.status === "active" ? "error-outline" : "primary"
        }
        isLoading={updateRoleMutation.isLoading}
        onClick={() =>
          updateRoleMutation.mutate({
            roleId: roleQuery?.data?.id,
            status:
              roleQuery?.data?.status === "active"
                ? RoleStatus.disabled
                : RoleStatus.active,
          })
        }
      >
        {roleQuery?.data?.status === "active" ? "Disable" : "Enable"}
      </Button>,
      <Button
        key="edit"
        onClick={onEdit}
      >
        Edit
      </Button>,
    ];
  };

  return (
    <>
      <Page
        title={roleQuery?.data?.name ?? "Role Details"}
        category="Role"
        breadcrumbs={breadcrumbs}
        activeTabKey={searchParams?.tab ?? DEFAULT_TAB_KEY}
        onActiveTabKeyChange={handleActiveTabKeyChange}
        isLoading={roleQuery?.isLoading}
        actionRow={getActionRow()}
      >
        <Tab
          tabKey="summary"
          title="Summary"
        >
          <RoleSummaryTab />
        </Tab>
        <Tab
          tabKey="members"
          title="Members"
        >
          <RoleUsersTab />
        </Tab>
        <Tab
          tabKey="permissions"
          title="Permissions"
        >
          <RolePermissionsTab />
        </Tab>
      </Page>

      <Dialog
        key="delete-role"
        title="Delete Role?"
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        actionRow={[
          <Button
            key="disable-dialog-action"
            variant="error"
            onClick={() => {
              if (!roleQuery?.data?.id) return;

              deleteRoleMutation.mutate({ roleId: roleQuery?.data?.id });
            }}
            disabled={!roleQuery?.data?.id}
            isLoading={deleteRoleMutation.isLoading}
          >
            Delete
          </Button>,
        ]}
      >
        Are you sure you want to delete this role? Deleting this role will
        remove all permissions configured on this role as well.
      </Dialog>
    </>
  );
};

export const RoleDetailPage = () => {
  const [isOpsExperienceEnabled] = useIsOpsHubExperience();

  if (isOpsExperienceEnabled) {
    return <AccessDeniedLayout />;
  }

  return (
    <RoleProvider>
      <RoleDetailPageContent />
    </RoleProvider>
  );
};
