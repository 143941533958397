import { computeDifference } from "../ResultDifference";

export function getPropertiesValue(
  currentSystemBalance,
  originalSystemBalance,
  property
) {
  const { total, components, qualities } = currentSystemBalance;

  const componentVolumeProperties = components?.[property] ?? null;
  if (componentVolumeProperties) {
    const originalProperties = originalSystemBalance?.components?.[property];

    const { percentChange, difference } = computeDifference(
      componentVolumeProperties,
      originalProperties
    );

    return { ...componentVolumeProperties, percentChange, difference };
  }

  const qualityMeasurementProperties = qualities?.[property] ?? null;
  if (qualityMeasurementProperties) {
    const originalProperties = originalSystemBalance?.qualities?.[property];

    const { percentChange, difference } = computeDifference(
      qualityMeasurementProperties,
      originalProperties
    );
    return { ...qualityMeasurementProperties, percentChange, difference };
  }

  const totalVolumeProperties = total ?? null;
  if (totalVolumeProperties && property === "total") {
    const originalProperties = originalSystemBalance?.total;

    const { percentChange, difference } = computeDifference(
      totalVolumeProperties,
      originalProperties
    );
    return { ...totalVolumeProperties, percentChange, difference };
  }

  return {};
}
