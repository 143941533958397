import { getAlertParameter } from "#utils/stringFormatter";
import { LegacyDataTable, Panel } from "@validereinc/common-components";
import find from "lodash/find";
import * as PropTypes from "prop-types";
import React, { useMemo } from "react";
import { AutoSizer } from "react-virtualized";
import "./StreamAlerts.scss";
import { convertToStreamDetailsFormat } from "./StreamAlertsDetailHelper";

const StreamAlertDetail = ({
  height,
  selectedStream,
  changePage,
  onMeasurementClick,
  streamAlertsList,
}) => {
  const measurementRenderer = (rowData, columnKey) => {
    return <div className="wrap">{rowData[columnKey]}</div>;
  };

  const alertParameterRenderer = (rowData, columnKey) => {
    const alertParameterExists = rowData[columnKey];

    if (alertParameterExists) {
      const alertParameter = getAlertParameter(rowData[columnKey], columnKey);
      return <div className="wrap">{alertParameter}</div>;
    }
    return "-";
  };

  const formattedAlertsList = useMemo(() => {
    const stream = find(streamAlertsList, { id: selectedStream.id });
    return convertToStreamDetailsFormat(stream);
  }, [selectedStream, streamAlertsList]);

  const onAddClick = () => {
    changePage("create");
  };

  const onCellClick = (rowData) => {
    onMeasurementClick(rowData.measurement);
    changePage("create");
  };

  const headers = [
    {
      label: "Measurement",
      key: "measurement",
      width: 300,
      cellRenderer: measurementRenderer,
    },
    {
      label: "Limit Alerts",
      key: "limit_alerts",
      cellRenderer: alertParameterRenderer,
      width: 150,
    },
    {
      label: "Statistical Alerts",
      key: "statistical_alerts",
      cellRenderer: alertParameterRenderer,
      width: 150,
    },
    {
      label: "Balance Alerts",
      key: "balance_alerts",
      cellRenderer: alertParameterRenderer,
      width: 150,
    },
    {
      label: "Inline Alerts",
      key: "inline_monitors",
      cellRenderer: alertParameterRenderer,
      width: 150,
    },
  ];

  return (
    <Panel>
      <div style={{ height: height }}>
        <AutoSizer>
          {({ height, width }) => (
            <LegacyDataTable
              height={height}
              width={width}
              headers={headers}
              list={formattedAlertsList}
              rowHeight={60}
              addButtonName="Stream Alert"
              onAddClick={onAddClick}
              onCellClick={onCellClick}
              defaultSortBy="measurement"
              defaultSortDirection="asc"
            />
          )}
        </AutoSizer>
      </div>
    </Panel>
  );
};

StreamAlertDetail.propTypes = {
  height: PropTypes.number.isRequired,
  selectedStream: PropTypes.object.isRequired,
  changePage: PropTypes.func.isRequired,
  onMeasurementClick: PropTypes.func.isRequired,
  streamAlertsList: PropTypes.array.isRequired,
};

export default StreamAlertDetail;
