import React, { Component } from "react";
import findIndex from "lodash/findIndex";
import moment from "moment";
import "./MonthlyView.css";
import DayPicker from "react-day-picker/DayPicker";
import "react-day-picker/lib/style.css";
import "./MonthlyView.css";
import FontAwesome from "react-fontawesome";
import Popup from "./Popup";
import PopupWorkflowDetail from "./PopupWorkflowDetail";
import PopupEventsDetail from "./PopupEventsDetail";
import { AssertIsSameDate } from "../../../utils/assert";

/* eslint-disable react/prop-types */
const lodash = { findIndex };

const NUM_OF_COLUMNS = 7;

const EVENT_ROW_HEIGHT = 20;

const POPUP_WIDTH = 400;

const POPUP_HEIGHT = 650;

// The Height of Su/Mo/Tu ... calendar header row
const CALENDAR_HEADER_HEIGHT = 30;

// border (1px * 2), day number (23px), padding (0.5rem * 2 = 16)
const TABLE_CELL_HEIGHT_OFFSET = 41;

// padding (0.5rem * 2 = 16)
const TABLE_CELL_PADDING = 16;

class MonthlyView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      eventDetail: {},
      dayDetail: {},

      showPopUp: false,
      showMorePopup: false,

      dateCellRefs: [],

      popupPanelPosition: {
        x: 0,
        y: 0,
      },

      showMorePopupPositions: {
        x: 0,
        y: 0,
      },

      showMorePopupDimensions: {
        width: 0,
        height: 0,
      },
    };

    this.renderDay = this.renderDay.bind(this);
    this.eventClicked = this.eventClicked.bind(this);
    this.showMoreEventClicked = this.showMoreEventClicked.bind(this);
    this.closeShowMorePopup = this.closeShowMorePopup.bind(this);
    this.closePopup = this.closePopup.bind(this);
    this.getTableCellDimensions = this.getTableCellDimensions.bind(this);
  }

  renderDay(day) {
    const { todayAgenda, futureAgendas, pastAgendas } = this.props;
    const { dateCellRefs } = this.state;
    const date = day.getDate();

    const agendas = [...pastAgendas, todayAgenda, ...futureAgendas];

    const renderDayAgendaIndex = lodash.findIndex(agendas, function (agenda) {
      return AssertIsSameDate(agenda.date, day, "day");
    });

    const events =
      renderDayAgendaIndex !== -1 ? agendas[renderDayAgendaIndex].agendas : [];

    const tableCellDimensions = this.getTableCellDimensions(events);

    return (
      <div
        className="dayCell"
        key={date}
        ref={(ref) => (dateCellRefs[date] = ref)}
        style={{
          width: `${tableCellDimensions.width}px`,
          height: `${tableCellDimensions.height}px`,
        }}
      >
        <div className="dayCell__date">{date}</div>
        <div className="dayCell__events">
          {events
            ? events.map((eventDetail, index) => {
                if (index < tableCellDimensions.maxNumRow) {
                  eventDetail.date = day;

                  return (
                    <div
                      key={index}
                      className="monthlyView__dayCellEvent"
                      onClick={() => this.eventClicked(date, eventDetail)}
                    >
                      <FontAwesome
                        name="circle"
                        className="icon"
                        style={{ fontSize: "8px", color: eventDetail.color }}
                      />
                      {eventDetail.name}
                    </div>
                  );
                } else if (index === tableCellDimensions.maxNumRow) {
                  return (
                    <div
                      key={index}
                      className="dayCell__event"
                      onClick={() =>
                        this.showMoreEventClicked(date, day, events)
                      }
                    >
                      {`${events.length - index} more`}
                    </div>
                  );
                }
              })
            : null}
        </div>
      </div>
    );
  }

  eventClicked(date, eventDetail, showMoreOffset) {
    const popupPanelPosition = this.getPopupPosition(
      date,
      eventDetail,
      showMoreOffset
    );

    this.setState({
      popupPanelPosition: popupPanelPosition,
      eventDetail: eventDetail,
      showPopup: true,
    });
  }

  showMoreEventClicked(index, date, dayEvents) {
    const showMorePopupPositions = this.getShowMorePosition(index);
    const showMorePopupDimensions = this.getShowMoreDimensions();

    const dayDetail = {
      events: dayEvents,
      date: date,
    };

    this.setState({
      showMorePopup: true,
      showMorePopupPositions: showMorePopupPositions,
      showMorePopupDimensions: showMorePopupDimensions,
      dayDetail: dayDetail,
    });
  }

  closePopup() {
    this.setState({
      showPopup: false,
    });
  }

  closeShowMorePopup() {
    this.setState({
      showMorePopup: false,
    });
  }

  getPopupPosition(date, eventDetail, showMoreOffset) {
    const { dateCellRefs } = this.state;
    const dateCellPosition = dateCellRefs[date].getBoundingClientRect();

    let xOffSet = showMoreOffset ? 20 + showMoreOffset : 20;
    let yOffset = 20;

    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;

    const tableCellDimensions = this.getTableCellDimensions();

    // if there is room on the right side of the screen then we can offset to
    // the right otherwise we will offset to the left
    const estimatePopupPositionX =
      dateCellPosition.left + tableCellDimensions.width + POPUP_WIDTH + xOffSet;
    if (estimatePopupPositionX > screenWidth) {
      xOffSet = (POPUP_WIDTH + xOffSet) * -1;
    } else {
      xOffSet = tableCellDimensions.width + xOffSet;
    }

    const estimatePopupPositionY =
      dateCellPosition.top + yOffset + POPUP_HEIGHT;
    if (estimatePopupPositionY > screenHeight) {
      yOffset = estimatePopupPositionY - screenHeight;
    }

    return {
      x: dateCellPosition.left + xOffSet,
      y: dateCellPosition.top - yOffset,
    };
  }

  getShowMorePosition(date) {
    const { dateCellRefs } = this.state;
    const dateCellPosition = dateCellRefs[date].getBoundingClientRect();
    const xOffset = 40;
    const yOffset = 40;

    return {
      y: dateCellPosition.top - yOffset,
      x: dateCellPosition.left - xOffset,
    };
  }

  getShowMoreDimensions() {
    const tableCellDimensions = this.getTableCellDimensions();

    return {
      width: tableCellDimensions.width + 100,
      height: tableCellDimensions.height + 100,
    };
  }

  getTableCellDimensions() {
    let { width, height } = this.props;
    const { numWeeksInMonth } = this.props;
    const minCellWidth = 30;
    const minCellHeight = 0;
    let maxNumRow = 0;

    // The height passed needs to be divided between calendar header, and
    // the number of weeks in month (table cells stacked on top of each other).
    // Subtracted by one to remove top of topmost and bottom of bottommost
    height =
      height -
      CALENDAR_HEADER_HEIGHT -
      TABLE_CELL_PADDING * (numWeeksInMonth - 1);

    if (width && height) {
      width = Math.max(width / NUM_OF_COLUMNS - 35, minCellWidth);
      height = Math.max(height / numWeeksInMonth, minCellHeight);
      maxNumRow = Math.floor(
        (height - TABLE_CELL_HEIGHT_OFFSET) / EVENT_ROW_HEIGHT
      );
    }

    return { width, height, maxNumRow };
  }

  render() {
    const {
      showMorePopupDimensions,
      showMorePopupPositions,
      popupPanelPosition,
      eventDetail,
      dayDetail,
      showPopup,
      showMorePopup,
    } = this.state;

    const { to } = this.props;

    return (
      <div className="monthlyView">
        <DayPicker
          canChangeMonth={false}
          month={moment(to).utc().toDate()}
          renderDay={this.renderDay}
        />
        <Popup
          style={{
            left: `${showMorePopupPositions.x}px`,
            top: `${showMorePopupPositions.y}px`,
            width: `${showMorePopupDimensions.width}px`,
            height: `${showMorePopupDimensions.height}px`,
          }}
          show={showMorePopup}
          closePopup={this.closeShowMorePopup}
        >
          <PopupEventsDetail
            showMorePopupDimensions={showMorePopupDimensions}
            dayDetail={dayDetail}
            eventClicked={this.eventClicked}
          />
        </Popup>
        <Popup
          style={{
            left: `${popupPanelPosition.x}px`,
            top: `${popupPanelPosition.y}px`,
            width: `${POPUP_WIDTH}px`,
            height: `${POPUP_HEIGHT}px`,
          }}
          show={showPopup}
          closePopup={this.closePopup}
        >
          <PopupWorkflowDetail eventDetail={eventDetail} />
        </Popup>
      </div>
    );
  }
}

export default MonthlyView;
