import uniqWith from "lodash/uniqWith";
import moment from "moment";
import * as PropTypes from "prop-types";
import React, { Component } from "react";
import { Button, Col, Row } from "react-bootstrap";
import FontAwesome from "react-fontawesome";
import { connect } from "react-redux";
import {
  INSTRUMENT_TYPES,
  SAMPLE_TYPES,
  userFacingInstrumentType,
} from "../../utils/enums";
import DateRangeSelector from "../Common/DateSelector/DateRangeSelector";
import { addToAnalyze, updateAnalyze } from "../Redux/actions/analyze";
import { ensureStreamListIsFetched } from "../Redux/actions/index";
import { AnalyzeSpec } from "../Redux/reducers/analyze";
import {
  getMeasurementType,
  measurementNames,
} from "../Redux/reducers/measurements";
import SampleService from "../Services/SampleService";
import AnalyzeGraph from "./AnalyzeGraph";
import "./AnalyzePanel.scss";

import config from "#src/config";
import { getTimeStringFromDate } from "#utils/timeFormatter";
import {
  AlertMessage,
  MultiDropdownInputWithSearch,
  Panel,
  Title,
} from "@validereinc/common-components";
import find from "lodash/find";
import findIndex from "lodash/findIndex";
import includes from "lodash/includes";
import isEqual from "lodash/isEqual";
import { GetJSData } from "../../utils/immutableConverter";
import { SetObjectWithParameterValue } from "../../utils/objectFormatter";
import DropdownInputWithSearch from "../Inputs/DropdownInputWithSearch";

/* eslint-disable react/prop-types */

const lodash = { isEqual, includes, findIndex, find };

const Intervals = [
  { id: "1_min", name: "1 min", seconds: 60 },
  { id: "15_min", name: "15 min.", seconds: 900 },
  { id: "hour", name: "1 hour", seconds: 3600 },
  { id: "day", name: "1 day", seconds: 86400 },
  { id: "week", name: "1 week", seconds: 604800 },
  { id: "30_days", name: "30 days", seconds: 2592000 },
  { id: "year", name: "1 year", seconds: 31536000 },
];

const DEFAULT_INLINE_INTERVAL = Intervals[2];

const StreamColorArray = [
  "#2196f3",
  "#e91e63",
  "#9c27b0",
  "#4caf50",
  "#fdd835",
];

const MAXIMUM_NUM_STREAMS = 5;

const MAXIMUM_NUM_UNIQUE_MEASUREMENT_UNIT = 2;

const ALL_SAMPLE_TYPES = Object.values(SAMPLE_TYPES);

const ALL_SOURCE_TYPES = [
  {
    id: INSTRUMENT_TYPES.FIELD,
    name: userFacingInstrumentType(INSTRUMENT_TYPES.FIELD),
  },
  {
    id: INSTRUMENT_TYPES.INLINE,
    name: userFacingInstrumentType(INSTRUMENT_TYPES.INLINE),
  },
  {
    id: INSTRUMENT_TYPES.THIRD_PARTY_LAB,
    name: userFacingInstrumentType(INSTRUMENT_TYPES.THIRD_PARTY_LAB),
  },
  {
    id: INSTRUMENT_TYPES.VIRTUAL_INLINE,
    name: userFacingInstrumentType(INSTRUMENT_TYPES.VIRTUAL_INLINE),
  },
  {
    id: "Crude Monitor",
    name: "Crude Monitor",
  },
];

const MULTIPLE_MEASUREMENT_TYPES_WARNING = `The compare streams feature is not available when
comparing multiple measurement types`;

const MULTIPLE_STREAMS_WARNING = `The multiple measurement type feature is not available when comparing multiple streams.`;

const Header = (props) => (
  <Title
    type="panelheader"
    className="analyzeHeader"
  >
    {props.title}

    {props.title && (
      <div
        className="analyzeHeader__bookmarkButton"
        style={props.bookmarkEnabled ? { cursor: "pointer" } : {}}
        onClick={props.onBookmarkClicked}
      >
        <FontAwesome
          name={props.isBookmarked ? "bookmark" : "bookmark-o"}
          className="icon"
        />
      </div>
    )}

    <Button
      className="analyzeHeader__closeButton pull-right"
      onClick={props.onClosePanelClicked}
    >
      <FontAwesome name="times" />
    </Button>
  </Title>
);

const REQUEST_STATES = {
  NONE: "none",
  IN_PROGRESS: "in-progress",
  SUCCESS: "success",
  FAILURE: "failure",
};

const mapStateToProps = (state) => {
  return {
    streamsStore: state.streams,
    supportedMeasurementTypes: measurementNames(state.measurements),
    getMeasurementUnit: (measurement) =>
      getMeasurementType(state.measurements)(measurement).unit,
  };
};

const mapDispatchToProps = {
  ensureStreamListIsFetched,
  addToAnalyze,
  updateAnalyze,
};

class AnalyzePanel extends Component {
  constructor(props) {
    super(props);

    const {
      timeRangeStart,
      timeRangeEnd,
      relativeDayRange,
      measurementType,
      sampleTypes,
    } = props.analyzeSpec;

    this.state = {
      selectedMeasurementTypeList: measurementType,
      measurementType: measurementType,
      inlineInterval: DEFAULT_INLINE_INTERVAL,
      selectedSourceType: [...ALL_SOURCE_TYPES],
      selectedSampleTypes: sampleTypes,
      availableSampleTypes: [...ALL_SAMPLE_TYPES],

      streams: this.getStreamObject([...this.props.analyzeSpec.streams]),
      streamName: "",
      requestState: REQUEST_STATES.NONE,
      alertSpec: null,

      isMeasurementDropdownOpen: false,

      fromDate: moment(timeRangeStart).toDate(),
      toDate: moment(timeRangeEnd).toDate(),
      relativeDayRange: relativeDayRange,
      openBookmarkComfirmModal: false,
    };

    this.onSourceTypeSelect = this.onSourceTypeSelect.bind(this);
    this.onStreamSelect = this.onStreamSelect.bind(this);
    this.resetSubmeasurement = this.resetSubmeasurement.bind(this);
    this.removeStream = this.removeStream.bind(this);
    this.getStreamName = this.getStreamName.bind(this);
  }

  componentDidMount() {
    this.props.ensureStreamListIsFetched();
    this.fetchMultiStreamData();
  }

  componentDidUpdate(prevProps) {
    const {
      sampleTypes,
      measurementType,
      relativeDayRange,
      timeRangeStart,
      timeRangeEnd,
    } = this.props.analyzeSpec;

    if (
      !lodash.isEqual(
        this.props.analyzeSpec.toJS(),
        prevProps.analyzeSpec.toJS()
      )
    ) {
      this.setState(
        {
          selectedSampleTypes: sampleTypes,
          selectedMeasurementTypeList: measurementType,
          streams: this.getStreamObject([...this.props.analyzeSpec.streams]),
          relativeDayRange: relativeDayRange,
          fromDate: moment(timeRangeStart).toDate(),
          toDate: moment(timeRangeEnd).toDate(),
        },
        () => {
          this.fetchMultiStreamData();
        }
      );
    }
  }

  fetchMultiStreamData() {
    const {
      selectedMeasurementTypeList,
      streams,
      selectedSampleTypes,
      inlineInterval,
      selectedSourceType,
      relativeDayRange,
    } = this.state;

    let fromDate = moment(this.state.fromDate);
    let toDate = moment(this.state.toDate);

    if (relativeDayRange) {
      fromDate = moment().subtract(relativeDayRange, "days");
      toDate = moment();
    }

    this.setState({
      requestState: REQUEST_STATES.IN_PROGRESS,
      alertSpec: null,
      fromDate: fromDate.toDate(),
      toDate: toDate.toDate(),
    });

    const selectedSourceTypeIds = this.getAllSourceTypesId(selectedSourceType);
    const intervalInSeconds = inlineInterval.seconds;

    SampleService.getAnalyzeDataForStreams(
      streams,
      [...selectedSampleTypes],
      intervalInSeconds,
      selectedMeasurementTypeList,
      selectedSourceTypeIds,
      moment(fromDate).startOf("day").unix(),
      moment(toDate).endOf("day").unix()
    ).then((samplesByStream) => {
      let measurementType = undefined;

      if (samplesByStream[0]) {
        // Set up multiple measurement axis if there is more than one or more
        // unique measurementUnit
        if (samplesByStream[0].data[1]) {
          this.setSubmeasurement(samplesByStream[0].data);
        } else {
          this.resetSubmeasurement();
        }

        samplesByStream.forEach((samplesByMeasurementType, index) => {
          const streamName = this.getStreamName(streams[index].id);
          streams[index] = {
            id: streams[index].id,
            name: streamName,
            color: StreamColorArray[index],
            samples: {},
          };

          if (samplesByMeasurementType.data[0]) {
            measurementType = samplesByMeasurementType.data[0].measurement_type;

            const stream = {
              id: streams[index].id,
              name: streamName,
              measurementType: measurementType,
              color: streams[index].color,
            };

            const samples = samplesByMeasurementType.data[0].analyze_rows;
            streams[index].samples = SetObjectWithParameterValue(
              samples,
              "stream",
              stream
            );
          }
        });

        if (samplesByStream[0].data[0]) {
          const mainStreamAlertSpec = samplesByStream[0].data[0].alert_spec;

          this.setState({
            streams: streams,
            alertSpec: mainStreamAlertSpec,
          });
        }

        this.setState({
          requestState: REQUEST_STATES.SUCCESS,
          measurementType: measurementType,
        });
      } else {
        this.setState({
          requestState: REQUEST_STATES.SUCCESS,
        });
      }
    });
  }

  setSubmeasurement(samples) {
    // Remove first default measuremnet type data
    const submeasurementSamples = samples.slice(1);

    const submeasurementStreams = [];

    submeasurementSamples.forEach((submeasurement, index) => {
      const streams = this.state.streams;

      const submeasurementStream = {
        id: streams[0].id,
        color: StreamColorArray[index + 1], // Skip color that is used by the main component
        name: this.getStreamName(streams[0].id),
        measurementType: submeasurement.measurement_type,
      };

      submeasurementStream.samples = SetObjectWithParameterValue(
        submeasurement.analyze_rows,
        "stream",
        submeasurementStream
      );

      submeasurementStreams.push(submeasurementStream);
    });

    this.setState({
      submeasurementType: submeasurementSamples[0].measurement_type,
      submeasurementStreams: submeasurementStreams,
      submeasurementAlertSpec: submeasurementSamples[0].alert_spec,
    });
  }

  resetSubmeasurement() {
    this.setState({
      submeasurementType: null,
      submeasurementStreams: null,
      submeasurementAlertSpec: null,
    });
  }

  updateAnalyzeSpec() {
    const {
      streams,
      selectedMeasurementTypeList,
      selectedSampleTypes,
      fromDate,
      toDate,
      relativeDayRange,
    } = this.state;

    const streamIds = streams.map((stream) => stream.id);

    this.props.updateAnalyze(
      new AnalyzeSpec({
        id: this.props.analyzeSpec.id,
        title: this.props.analyzeSpec.title,
        name: this.props.analyzeSpec.name,
        timeRangeStart: getTimeStringFromDate(fromDate, config.DATE_FORMAT),
        timeRangeEnd: getTimeStringFromDate(toDate, config.DATE_FORMAT),
        relativeDayRange: relativeDayRange,
        measurementType: selectedMeasurementTypeList,
        sampleTypes: selectedSampleTypes,
        streams: [...streamIds],
        isBookmarked: this.props.analyzeSpec.isBookmarked,
      })
    );
  }

  onMeasurementTypeSelect = (measurementTypes) => {
    if (measurementTypes.length > 0) {
      // compare stream is only available for single measurement type only.
      let streams = this.state.streams;

      if (measurementTypes.length > 1 && streams[0]) {
        streams = [streams[0]];
      }

      // only can compare two unique measurement unit
      const numOfUniqMeasurementUnit = uniqWith(
        measurementTypes,
        (valueA, valueB) => {
          return (
            this.props.getMeasurementUnit(valueA) ===
            this.props.getMeasurementUnit(valueB)
          );
        }
      ).length;

      if (numOfUniqMeasurementUnit <= MAXIMUM_NUM_UNIQUE_MEASUREMENT_UNIT) {
        this.setState(
          {
            streams: streams,
            selectedMeasurementTypeList: measurementTypes,
            errorMessage: null,
          },
          () => {
            this.fetchMultiStreamData();
            this.updateAnalyzeSpec();
          }
        );
      } else {
        this.setState({
          errorMessage: `You cannot plot the set ${measurementTypes.join(
            ", "
          )} since it has more than ${MAXIMUM_NUM_UNIQUE_MEASUREMENT_UNIT} unique measurement units.`,
        });
      }
    }
  };

  onSampleTypeSelect = (eventKey) => {
    this.setState(
      {
        selectedSampleTypes: eventKey,
      },
      () => {
        this.fetchMultiStreamData();
        this.updateAnalyzeSpec();
      }
    );
  };

  onSourceTypeSelect(selectedInstrumentType) {
    if (selectedInstrumentType.length > 0) {
      const availableSampleTypes = [...ALL_SAMPLE_TYPES];

      let selectedSampleTypes = this.state.selectedSampleTypes;

      selectedSampleTypes = selectedSampleTypes.reduce(
        (selectedSampleTypes, sampleType) => {
          if (lodash.includes(availableSampleTypes, sampleType)) {
            return [...selectedSampleTypes, sampleType];
          } else {
            return [...selectedSampleTypes];
          }
        },
        []
      );

      // if there is no sample type selected then we default to
      // available select all the sample type
      if (!selectedSampleTypes || selectedSampleTypes.length < 1) {
        selectedSampleTypes = availableSampleTypes;
      }

      this.setState(
        {
          selectedSourceType: selectedInstrumentType,
          selectedSampleTypes: selectedSampleTypes,
        },
        () => {
          this.fetchMultiStreamData();
        }
      );
    }
  }

  onStreamSelect(selectedStreams) {
    if (selectedStreams.length > 0) {
      selectedStreams.map(
        (stream, index) => (stream.color = StreamColorArray[index])
      );

      this.setState(
        {
          streams: selectedStreams,
        },
        () => {
          this.fetchMultiStreamData();
          this.updateAnalyzeSpec();
        }
      );
    }
  }

  onIntervalSelect = (eventKey) => {
    this.setState(
      {
        inlineInterval: eventKey,
      },
      () => {
        this.fetchMultiStreamData();
      }
    );
  };

  onDateSelect = (fromDate, toDate, relativeDayRange) => {
    this.setState(
      {
        fromDate,
        toDate,
        relativeDayRange: relativeDayRange?.value ?? 0,
      },
      () => {
        this.fetchMultiStreamData();
        this.updateAnalyzeSpec();
      }
    );
  };

  removeStream(streamId) {
    const { streams } = this.state;

    // If stream exist then remove the stream.
    const streamIndex = this.getStreamIndex(streams, streamId);
    if (streamIndex !== -1) {
      streams.splice(streamIndex, 1);

      this.setState(
        {
          streams,
        },
        () => {
          this.updateAnalyzeSpec();
          this.fetchMultiStreamData();
        }
      );
    }
  }

  getStreamIndex(streams, streamId) {
    return lodash.findIndex(streams, { id: streamId });
  }

  getAllSourceTypesId(sourceTypes) {
    return sourceTypes.reduce((sourceTypes, sourceType) => {
      return [...sourceTypes, sourceType.id];
    }, []);
  }

  getStreamName(streamId) {
    const stream = lodash.find(GetJSData(this.props.availableStreams), {
      id: streamId,
    });

    return stream ? stream.name : "unknown";
  }

  areStateRecordsLoaded() {
    return [REQUEST_STATES.FAILURE, REQUEST_STATES.SUCCESS].includes(
      this.state.requestState
    );
  }

  streamsHaveSampleData(streams) {
    for (const stream of streams) {
      if (stream?.samples && stream.samples.length > 0) {
        return true;
      }
    }

    return false;
  }

  getStreamObject(streamIds) {
    return streamIds.map((streamId) => ({ id: streamId }));
  }

  getWarningMessage() {
    const { selectedMeasurementTypeList, streams, errorMessage } = this.state;

    if (errorMessage) {
      return errorMessage;
    }

    if (selectedMeasurementTypeList.length > 1) {
      return MULTIPLE_MEASUREMENT_TYPES_WARNING;
    } else if (streams.length > 1) {
      return MULTIPLE_STREAMS_WARNING;
    }
  }

  render() {
    const {
      onClosePanelClicked,
      onBookmarkClicked,
      analyzeSpec,
      streamsStore,
      bookmarkEnabled,
      maxNumOfMeasurementType,
      onSampleModalToggle,
    } = this.props;

    const {
      inlineInterval,
      selectedMeasurementTypeList,
      submeasurementType,
      measurementType,
      selectedSourceType,
      selectedSampleTypes,
      availableSampleTypes,
      fromDate,
      toDate,
      relativeDayRange,
      alertSpec,
      submeasurementAlertSpec,
      streams,
      submeasurementStreams,
    } = this.state;

    const areRecordsLoaded =
      this.areStateRecordsLoaded() && !streamsStore.isRequestInProgress;

    const streamName = streams[0].name;

    const title = analyzeSpec.title ? analyzeSpec.title : streams[0].name;

    const warningMessage = this.getWarningMessage();

    return (
      <Col xs={12}>
        <Panel
          title={
            <Header
              title={title}
              isBookmarked={analyzeSpec.isBookmarked}
              onBookmarkClicked={onBookmarkClicked}
              onClosePanelClicked={onClosePanelClicked}
              bookmarkEnabled={bookmarkEnabled}
            />
          }
          className="analyzePanel"
          loaded={areRecordsLoaded}
        >
          {warningMessage && (
            <AlertMessage
              type="info"
              onClose={this.handleAlertDismiss}
              style={{ marginBottom: "15px" }}
            >
              <b>Note:</b>&nbsp; {warningMessage}
            </AlertMessage>
          )}

          {streamName ? (
            <div className="analyticsSelectorRow">
              <MultiDropdownInputWithSearch
                label="Measurement Types"
                width={150}
                value={selectedMeasurementTypeList}
                options={this.props.supportedMeasurementTypes}
                onChange={this.onMeasurementTypeSelect}
                selectLimit={maxNumOfMeasurementType}
              />

              <MultiDropdownInputWithSearch
                label="Source Types"
                labelKey="name"
                width={100}
                value={[...selectedSourceType]}
                options={ALL_SOURCE_TYPES}
                onChange={this.onSourceTypeSelect}
              />

              <MultiDropdownInputWithSearch
                label="Sample Types"
                width={100}
                value={[...selectedSampleTypes]}
                options={availableSampleTypes}
                onChange={this.onSampleTypeSelect}
              />

              <MultiDropdownInputWithSearch
                label="Streams"
                labelKey="name"
                value={[...streams]}
                options={GetJSData(streamsStore)}
                onChange={this.onStreamSelect}
                disabled={selectedMeasurementTypeList.length > 1}
                selectLimit={MAXIMUM_NUM_STREAMS}
              />

              <div className="analyzePanel__divider" />

              <div className="analyzePanel__selector">
                <FontAwesome
                  name="clock-o"
                  className="icon"
                />
                <DropdownInputWithSearch
                  title="Inline Intervals"
                  className="analyzePanel__noBorder"
                  value={inlineInterval.name}
                  options={Intervals}
                  onSelect={this.onIntervalSelect}
                  filterKey={"name"}
                  noCaret={true}
                  defaultTitle={true}
                />
              </div>

              <div className="analyzePanel__divider" />

              <DateRangeSelector
                dateRange={{
                  from: fromDate,
                  to: toDate,
                }}
                relativeDayRange={relativeDayRange}
                onDateRangeChange={this.onDateSelect}
                icon
              />
            </div>
          ) : null}

          {areRecordsLoaded ? (
            <Row>
              <Col xs={12}>
                {streams.length > 0 && this.streamsHaveSampleData(streams) ? (
                  <AnalyzeGraph
                    measurementTypeName={measurementType}
                    submeasurementTypeName={submeasurementType}
                    streamName={streamName}
                    alertSpec={alertSpec}
                    submeasurementAlertSpec={submeasurementAlertSpec}
                    streams={streams}
                    submeasurementStreams={submeasurementStreams}
                    startDate={fromDate}
                    endDate={toDate}
                    removeStream={this.removeStream}
                    onSampleModalToggle={onSampleModalToggle}
                    measurementTypeListNames={selectedMeasurementTypeList}
                  />
                ) : (
                  <div className="noDataMessage">No samples to display</div>
                )}
              </Col>
            </Row>
          ) : null}
        </Panel>
      </Col>
    );
  }
}
AnalyzePanel.propTypes = {
  analyzeSpec: PropTypes.object.isRequired,
  onClosePanelClicked: PropTypes.func.isRequired,
};

const AnalyzePanelContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AnalyzePanel);

export default AnalyzePanelContainer;
