import { getComponentShortForm } from "#utils/stringFormatter";

export const sortByProperties = (list, columnKey) => {
  return [...list].sort((a, b) => {
    const aValue = getComponentShortForm(a[columnKey]);
    const bValue = getComponentShortForm(b[columnKey]);

    return aValue.localeCompare(bValue);
  });
};
