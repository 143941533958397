import { useListEquipment } from "#hooks/adapters/useEquipment";
import { useListFacilities } from "#hooks/adapters/useFacilities";
import {
  useGetManySingleDownstreamFlow,
  useGetManySingleUpstreamFlow,
  useListFlows,
} from "#hooks/adapters/useFlows";
import { useFlowHeaders } from "#hooks/tables/useFlowHeaders";
import { useTableSortingAndPagination } from "#redux/reducers/tableStateReducer";
import { FlowDetailRoute } from "#routes/organization/flows/[flowId]/detail";
import { useSearchParams } from "#src/Routers/hooks";
import { RoutingLink } from "#src/batteries-included-components/RoutingLink/RoutingLink";
import useLocalization from "#src/hooks/useLocalization";
import { useStorageKey } from "#src/hooks/useStorageKey";
import { linkToEquipmentDetail } from "#src/routes/organization/equipment/[equipmentId]";
import { linkToFacilityDetail } from "#src/routes/organization/facilities/[facilityId]";
import {
  DataTablePanel,
  SortingType,
  useFilters,
} from "@validereinc/common-components";
import { FlowV2Type, SortDirection } from "@validereinc/domain";
import React from "react";

const FLOW_KEYS = {
  NAME: "name",
  TYPE: "type",
  PRODUCT_CATEGORY: "product_category",
  PRODUCT_TYPE: "product_type",
  ASSOCIATED_FACILITY: "associated_facility_id",
  ASSOCIATED_EQUIPMENT: "associated_equipment_id",
  DESTINATION_ASSET: "destination_asset",
  SOURCE_ASSET: "source_asset",
};

export const sorting: SortingType = {
  sortBy: FLOW_KEYS.NAME,
  sortDirection: SortDirection.ASCENDING,
};

export const AssociatedFlowTablePanel = ({
  id,
  type,
}: {
  id: string;
  type: "equipment" | "facility";
}) => {
  const { localize } = useLocalization();
  const storageKeys = useStorageKey(`${type}-flows`);

  // IMPROVE: temporary till EntityFilterPanel component can be re-worked so all
  // filters come from storage consistently
  const [{ name: nameFromSearch }] = useSearchParams();
  const [{ name: nameFromStorage }] = useFilters<{ name: string }>(
    storageKeys.filterConfigStorageKey
  );

  const name = nameFromSearch || nameFromStorage;

  const [tableState, updateTableState] = useTableSortingAndPagination(
    { ...sorting, itemsPerPage: 25 },
    { name, type, id }
  );

  const { data, isLoading } = useListFlows({
    page: tableState.page,
    pageSize: tableState.itemsPerPage,
    sortBy: tableState.sortBy,
    sortDirection: tableState.sortDirection,
    filters: {
      $or:
        type === "equipment"
          ? [
              { associated_equipment_id: id },
              { downstream_equipment_id: id },
              { upstream_equipment_id: id },
            ]
          : [
              { associated_facility_id: id },
              { downstream_facility_id: id },
              { upstream_facility_id: id },
            ],
      name,
    },
  });

  const items = data?.data ?? [];

  const flowIdsWithSingleUpstream = items.reduce(
    (acc: string[], { upstream_flows_count, id }) => {
      if (upstream_flows_count === 1) {
        acc.push(id);
      }
      return acc;
    },
    []
  );
  const upstreamFlowsQuery = useGetManySingleUpstreamFlow({
    ids: flowIdsWithSingleUpstream,
  });
  const isUpstreamLoading = upstreamFlowsQuery.some(
    (result) => result.isLoading
  );
  const upstreamFlowsMap = upstreamFlowsQuery.reduce<
    Record<string, FlowV2Type>
  >((accumulator, current, index) => {
    const flowId = flowIdsWithSingleUpstream[index];
    if (current?.data) {
      accumulator[flowId] = current?.data;
    }
    return accumulator;
  }, {});

  const flowIdsWithSingleDownstream = items.reduce(
    (acc: string[], { downstream_flows_count, id }) => {
      if (downstream_flows_count === 1) {
        acc.push(id);
      }
      return acc;
    },
    []
  );
  const downstreamFlowsQuery = useGetManySingleDownstreamFlow({
    ids: flowIdsWithSingleDownstream,
  });
  const isDownstreamLoading = downstreamFlowsQuery.some(
    (result) => result.isLoading
  );

  const downstreamFlowsMap = downstreamFlowsQuery.reduce<
    Record<string, FlowV2Type>
  >((accumulator, current, index) => {
    const flowId = flowIdsWithSingleDownstream[index];
    if (current?.data) {
      accumulator[flowId] = current?.data;
    }
    return accumulator;
  }, {});

  /** TODO: Remove these once the flows API returns the upstream/downstream facility & equipment detail */
  const facilityIds = items
    .flatMap(({ upstream_facility_id, downstream_facility_id }) => [
      upstream_facility_id,
      downstream_facility_id,
    ])
    .filter((item) => item);
  const listFacilities = useListFacilities(
    { filters: { id: facilityIds } },
    { enabled: !!facilityIds.length }
  );
  const facilities = listFacilities.data?.data ?? [];

  const equipmentIds = items
    .flatMap(({ upstream_equipment_id, downstream_equipment_id }) => [
      upstream_equipment_id,
      downstream_equipment_id,
    ])
    .filter((item) => item);
  const listEquipment = useListEquipment(
    { filters: { id: equipmentIds } },
    { enabled: !!equipmentIds.length }
  );
  const equipment = listEquipment.data?.data ?? [];

  const headers = [
    ...useFlowHeaders(),
    {
      label: "Origin",
      key: FLOW_KEYS.SOURCE_ASSET,
      variant: "link",
      renderComponent: ({ item }: { item: FlowV2Type }) => {
        const upstreamEquipment = equipment.find(
          ({ id }) => id === item?.upstream_equipment_id
        );
        const upstreamFacility = facilities.find(
          ({ id }) => id === item?.upstream_facility_id
        );
        const hasUpstreamFlows =
          item.upstream_flows_count && item.upstream_flows_count > 0;

        if (item.downstream_flows_count && item.downstream_flows_count > 1) {
          return "Many";
        }

        if (!hasUpstreamFlows && !upstreamFacility && !upstreamEquipment) {
          return "-";
        }

        let pathname: string, value: string;

        if (upstreamEquipment) {
          pathname = linkToEquipmentDetail(upstreamEquipment.id);
          value = upstreamEquipment.name;
        } else if (upstreamFacility) {
          pathname = linkToFacilityDetail(upstreamFacility.id);
          value = upstreamFacility.name;
        } else if (hasUpstreamFlows) {
          const upstreamFlow = upstreamFlowsMap[item.id];

          if (!upstreamFlow) {
            return "-";
          }

          pathname = FlowDetailRoute.toLink({
            pathParams: { flowId: upstreamFlow.id },
          });
          value = upstreamFlow.name;
        } else {
          return "-";
        }

        return <RoutingLink to={pathname}>{value}</RoutingLink>;
      },
    },
    {
      label: "Destination",
      key: FLOW_KEYS.DESTINATION_ASSET,
      renderComponent: ({ item }: { item: FlowV2Type }) => {
        const downstreamEquipment = equipment.find(
          ({ id }) => id === item?.downstream_equipment_id
        );
        const downstreamFacility = facilities.find(
          ({ id }) => id === item?.downstream_facility_id
        );
        const hasDownstreamFlow =
          item.downstream_flows_count && item.downstream_flows_count > 0;

        if (item.downstream_flows_count && item.downstream_flows_count > 1) {
          return "Many";
        }

        if (!hasDownstreamFlow && !downstreamFacility && !downstreamEquipment) {
          return "-";
        }

        let pathname: string, value: string;

        if (downstreamEquipment) {
          pathname = linkToEquipmentDetail(downstreamEquipment.id);
          value = downstreamEquipment.name;
        } else if (downstreamFacility) {
          pathname = linkToFacilityDetail(downstreamFacility.id);
          value = downstreamFacility.name;
        } else if (hasDownstreamFlow) {
          const downstreamFlow = downstreamFlowsMap[item.id];

          if (!downstreamFlow) {
            return "-";
          }

          pathname = FlowDetailRoute.toLink({
            pathParams: { flowId: downstreamFlow.id },
          });
          value = downstreamFlow.name;
        } else {
          return "-";
        }

        return <RoutingLink to={pathname}>{value}</RoutingLink>;
      },
    },
  ];

  return (
    <DataTablePanel
      dataTableProps={{
        headers,
        items,
        isLoading: isLoading || isDownstreamLoading || isUpstreamLoading,
        sorting,
        pagination: {
          page: tableState.page,
          itemsPerPage: tableState.itemsPerPage,
          total: data?.total_entries ?? 0,
        },
        onSortChange: updateTableState,
        onPaginationChange: updateTableState,
      }}
      panelProps={{ title: localize("flow_plural") }}
      storageKey={storageKeys.tableConfigStorageKey}
    />
  );
};
