import { EmissionCustomReportListTab } from "#batteries-included-components/Tabs/CustomReports/EmissionCustomReportListTab";
import { EmissionsCustomReportListRoute } from "#src/routes/emissions/custom-reports";
import { useBreadcrumbsFromRoute } from "#src/utils/route";
import { Page } from "@validereinc/common-components";
import React from "react";

export const EmissionsCustomReportListPage = () => {
  const [breadcrumbs] = useBreadcrumbsFromRoute(EmissionsCustomReportListRoute);

  return (
    <Page
      title={EmissionsCustomReportListRoute.title}
      category={EmissionsCustomReportListRoute.subtitle}
      breadcrumbs={breadcrumbs}
    >
      <EmissionCustomReportListTab isOldSidebarLocation />
    </Page>
  );
};
