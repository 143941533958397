import { useNavigate } from "#src/Routers/hooks";
import {
  getAssetTypeForDisplay,
  getIsDeleted,
  getListOfChangedAttributes,
  getPath,
  getTransformedAssetName,
  getTransformedDate,
} from "#src/routes/change-log/ActivityLogsPage.helpers";
import { useQuery } from "@tanstack/react-query";
import { KeyValueList, Link, useAlert } from "@validereinc/common-components";
import {
  ActivityAction,
  UsersAdapter,
  type ActivityType,
} from "@validereinc/domain";
import classNames from "classnames/bind";
import React, { type ReactElement } from "react";
import styles from "./ActivityLogsDetailsKeyValueList.module.scss";

const cx = classNames.bind(styles);

export const ActivityLogsDetailsKeyValueList = ({
  selectedLog,
}: {
  selectedLog: ActivityType | null;
}) => {
  const navigate = useNavigate();
  const { addAlert } = useAlert();
  const associatedUser = useQuery(
    ["users", selectedLog?.author_id],
    () => {
      if (!selectedLog?.author_id) {
        return;
      }

      return UsersAdapter.getOne({ id: selectedLog.author_id });
    },
    {
      enabled: Boolean(selectedLog?.author_id),
      staleTime: 3 * 60 * 1000,
      select: (resp) => resp?.data,
    }
  );

  if (!selectedLog) {
    return null;
  }

  const getAssetName = (selectedLog: ActivityType) => {
    const path = getPath(selectedLog);

    if (!path) {
      return null;
    }

    return (
      <Link
        onClick={async () => {
          try {
            const result = await getIsDeleted(selectedLog);

            if (!result) {
              return null;
            }

            navigate({ pathname: path });
          } catch (err) {
            addAlert?.({
              variant: "error",
              message: "The resource does not exist.",
            });
          }
        }}
        label={getTransformedAssetName(selectedLog)}
      />
    );
  };

  const data: Array<{ title: string; value: string | number | ReactElement }> =
    [
      { title: "Logged At", value: getTransformedDate(selectedLog) },
      {
        title: "By User",
        value: associatedUser.data?.name,
      },
      {
        title: "Resource Type",
        value: getAssetTypeForDisplay(selectedLog),
      },
      { title: "Resource Name", value: getAssetName(selectedLog) },
    ];

  if (selectedLog?.action === ActivityAction.UPDATE) {
    data.push({
      title: "Updated Attributes",
      value: getListOfChangedAttributes(selectedLog)?.length ?? "-",
    });
  }

  return (
    <KeyValueList
      data={data}
      className={cx("keyValueListContainer")}
    />
  );
};
