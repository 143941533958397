export const MANUAL_ENTRY_TYPES = [
  {
    id: "inline",
    title: "Add Inline Measurements",
    permission: "360:field_labs",
  },
  {
    id: "component-analysis",
    title: "Add Component Analysis",
    permission: "lite:lab_results",
  },
  {
    id: "field",
    title: "Add Field Test",
    permission: "360:field_labs",
  },
];
