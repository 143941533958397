import { ReorderDirection, ReorderDirectionType } from "@validereinc/domain";

export const moveItem = (
  items: any[],
  move: (from: number, to: number) => void,
  indexToMoveFrom: number,
  direction: ReorderDirectionType
) => {
  const indexToMoveTo =
    direction === ReorderDirection.UP
      ? indexToMoveFrom - 1
      : indexToMoveFrom + 1;

  if (indexToMoveTo < 0 || indexToMoveTo > items.length - 1) {
    return;
  }

  move(indexToMoveFrom, indexToMoveTo);
};
