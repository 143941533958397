import config from "#src/config";
import {
  getTimeStringFromDate,
  getTimeStringFromUTC,
} from "#utils/timeFormatter";
import { Title } from "@validereinc/common-components";
import React, { Component } from "react";
import FontAwesome from "react-fontawesome";
import { AutoSizer, List } from "react-virtualized";
import "react-virtualized/styles.css";
import {
  linkToCreateWorkflow,
  linkToEditWorkflow,
} from "../../../Routers/links";
import { getWorkflowTaskStateColor } from "../../../utils/styleCalculator";
import PopupTaskBar from "./PopupTaskBar";
import "./PopupWorkflowDetail.css";

/* eslint-disable react/prop-types */

const PopupRow = (props) => (
  <div className="monthlyView__popupRowContainer">
    <div className="monthlyView__popupRowIcon">
      <FontAwesome
        className="fa-fw"
        name={props.icon}
        style={props.style}
      />
    </div>
    <div className="monthlyView__popupRow">
      {props.children ? props.children : null}
    </div>
  </div>
);

const getCompletedRowText = (properties) => {
  const text = properties.dismissed_by ? "Dismissed on" : "Complete on";
  const formattedTime = getTimeStringFromUTC(
    properties.completed_at_utc_epoch,
    config.DATETIME_FORMAT
  );

  return `${text} ${formattedTime}`;
};

class PopupWorkflowDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updateTasks: false,
      workflowTasks: [],
    };

    this.getTaskRowRenderer = this.getTaskRowRenderer.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.eventDetail !== this.props.eventDetail) {
      this.setWorkflowTasks(this.props.eventDetail);
    }
  }

  setWorkflowTasks(eventDetail) {
    let workflowTasks = [];

    if (eventDetail?.tasks) {
      const categorizedTasks = eventDetail.tasks;

      workflowTasks = Object.keys(categorizedTasks).reduce(
        (workflowTasks, key) => {
          if (categorizedTasks[key]) {
            return [...workflowTasks, ...categorizedTasks[key]];
          } else {
            return workflowTasks;
          }
        },
        []
      );
    }

    this.setState({
      workflowTasks: workflowTasks,
      updateTasks: !this.state.updateTasks,
    });
  }

  getOffSpecRetestString(fieldRest, thirdPartyRetest) {
    if (fieldRest && thirdPartyRetest) {
      return "field and 3rd party lab";
    } else if (thirdPartyRetest) {
      return "3rd party lab";
    } else {
      return "field lab";
    }
  }

  getTaskRowRenderer = (props) => {
    const task = this.state.workflowTasks[props.index];

    if (task) {
      const taskStateIcon = getWorkflowTaskStateColor(task.state);

      return (
        <div
          className="taskRowRenderer"
          key={props.index}
          style={props.style}
        >
          <FontAwesome
            name={taskStateIcon.icon}
            style={{ color: taskStateIcon.color }}
            className="icon fa-fw"
          />
          {task.name}
        </div>
      );
    } else {
      return null;
    }
  };

  render() {
    const { eventDetail, hideTitle } = this.props;
    const { workflowTasks } = this.state;

    // Only user with write access to the workflows have the access to
    // workflow detail and view. Other user will just have the access
    // to workflow task detail.
    const properties = eventDetail.workflow
      ? eventDetail.workflow
      : eventDetail;

    return (
      <React.Fragment>
        {eventDetail.workflow && eventDetail.workflow.id !== "calibration" ? (
          <PopupTaskBar
            addLink={eventDetail.workflow ? linkToCreateWorkflow() : null}
            editLink={
              eventDetail.workflow
                ? linkToEditWorkflow(eventDetail.workflow.id)
                : null
            }
          />
        ) : null}

        {!hideTitle && (
          <PopupRow
            icon="circle"
            style={{ color: eventDetail.color }}
          >
            <Title
              type="subheader"
              className="monthlyView__popupTitle"
            >
              {eventDetail.name}
            </Title>

            <div className="monthlyView__popupDate">
              {getTimeStringFromDate(eventDetail.date, config.DATEMONTH_FORMAT)}
            </div>
          </PopupRow>
        )}

        {properties.state ? (
          <PopupRow
            icon={getWorkflowTaskStateColor(properties.state).icon}
            style={{ color: getWorkflowTaskStateColor(properties.state).color }}
          >
            <div className="capitalized">{properties.state}</div>
          </PopupRow>
        ) : null}

        {properties.sites ? (
          <PopupRow icon="map-marker">
            {properties.sites.map((site) => site.name).join(", ")}
          </PopupRow>
        ) : null}

        {properties.test_type ? (
          <PopupRow icon="cubes">
            <span className="capitalized">
              {properties.test_type.replace(/_/g, " ")}
            </span>
          </PopupRow>
        ) : null}

        {properties.product_type ? (
          <PopupRow icon="flask">
            {`${properties.product_type} Product`}
          </PopupRow>
        ) : null}

        {properties.start_time_utc_epoch || properties.inserted_at_utc_epoch ? (
          <PopupRow icon="calendar-plus-o">
            {`Created on ${getTimeStringFromUTC(
              properties.start_time_utc_epoch
                ? properties.start_time_utc_epoch
                : properties.inserted_at_utc_epoch,
              config.DATETIME_FORMAT
            )}`}
          </PopupRow>
        ) : null}

        {properties.deadline_at_utc_epoch &&
        !properties.completed_at_utc_epoch ? (
          <PopupRow icon="calendar-times-o">
            {`Due by ${getTimeStringFromUTC(
              properties.deadline_at_utc_epoch,
              config.DATETIME_FORMAT
            )}`}
          </PopupRow>
        ) : null}

        {properties.completed_at_utc_epoch ? (
          <PopupRow icon="calendar-check-o">
            {getCompletedRowText(properties)}
          </PopupRow>
        ) : null}

        {properties.dismissed_by ? (
          <PopupRow icon="user">
            <span className="capitalized">
              {`Dismissed by: ${properties.dismissed_by?.name}`}
            </span>
          </PopupRow>
        ) : null}

        {properties.completed_by ? (
          <PopupRow icon="user">
            <span className="capitalized">
              {`Completed by: ${properties.completed_by?.name}`}
            </span>
          </PopupRow>
        ) : null}

        {properties.tests_required && properties.tests_required.length > 0 ? (
          <PopupRow icon="list-alt">
            {`${properties.tests_required.join(", ")} Test Required`}
          </PopupRow>
        ) : null}

        {properties.tests_completed && properties.tests_completed.length > 0 ? (
          <PopupRow icon="list-alt">
            {`${properties.tests_completed.join(", ")} Test Completed`}
          </PopupRow>
        ) : null}

        {properties.offspec_retest || properties.offspec_retest_3pl ? (
          <PopupRow icon="repeat">
            If off-spec then{" "}
            {this.getOffSpecRetestString(
              properties.offspec_retest,
              properties.offspec_retest_3pl
            )}{" "}
            retest
          </PopupRow>
        ) : null}

        {properties.timezone ? (
          <PopupRow icon="globe">{properties.timezone}</PopupRow>
        ) : null}

        {workflowTasks && workflowTasks.length > 0 ? (
          <PopupRow icon="tasks">
            {workflowTasks.length} Tasks
            <div className="monthlyView__taskListContainer">
              <AutoSizer>
                {({ height, width }) => (
                  <List
                    width={width}
                    height={height}
                    rowCount={workflowTasks.length}
                    rowHeight={30}
                    rowRenderer={this.getTaskRowRenderer}
                    updateTasks={this.state.updateTasks}
                  />
                )}
              </AutoSizer>
            </div>
          </PopupRow>
        ) : null}
      </React.Fragment>
    );
  }
}

export default PopupWorkflowDetail;
