import {
  Button,
  Form,
  useFieldArray,
  useForm,
  useFormContext,
} from "@validereinc/common-components";
import React, { useEffect } from "react";
import { LookupQuestionFilter } from "./LookupQuestionFilter";
import { getQueryObjectForQuestionType } from "./utils";

export const LookupQuestionFilters = ({ questions }) => {
  const parentForm = useFormContext();
  const lookupEntityType = parentForm.watch("lookup_entity_type");

  const { watch, handleSubmit, control, ...restForm } = useForm({
    defaultValues: { filters: [{ attribute: "", filterType: "static" }] },
  });

  const { fields, append, remove } = useFieldArray({
    name: "filters",
    rules: { minLength: 1 },
    control,
  });

  const onSubmit = ({ filters }) => {
    parentForm.setValue(
      "filter",
      filters?.reduce(
        (
          accumulator,
          { attribute, filterType, referenceQuestion, staticValue, comparator }
        ) => {
          const queryObject = getQueryObjectForQuestionType({
            comparator,
            value:
              filterType === "static" ? staticValue : `%${referenceQuestion}%`,
          });

          return { ...accumulator, [attribute]: queryObject };
        },
        {}
      )
    );
  };

  useEffect(() => {
    const subscription = watch(() => handleSubmit(onSubmit)());

    return () => subscription.unsubscribe();
  }, [handleSubmit, watch]);

  return (
    <>
      <Button
        variant="outline"
        icon="plus-circle"
        onClick={() => {
          append({ attribute: "", filterType: "static" });
        }}
      >
        Add Filter
      </Button>

      <Form {...{ handleSubmit, watch, control, ...restForm }}>
        {fields.map((field, fieldIndex) => (
          <LookupQuestionFilter
            key={field.id}
            name={`filters.${fieldIndex}`}
            questions={questions}
            id={field.id}
            index={fieldIndex}
            onRemove={remove}
            lookupEntityType={lookupEntityType}
          />
        ))}
      </Form>
    </>
  );
};
