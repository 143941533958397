import { Page, Panel } from "@validereinc/common-components";
import React, { Component } from "react";
import { Nav, NavItem } from "react-bootstrap";
import { connect } from "react-redux";
import { getBreadcrumbsObject } from "../../../../Routers/breadcrumbsHelper";
import history from "../../../../Routers/history";
import { linkToInlineInstrumentTab } from "../../../../Routers/links";
import "../../../Instrument/Instrument.css";
import { fetchInstrumentList } from "../../../Redux/actions/index";
import CalibrationTable from "../../CalibrationTable";
import InstrumentAnalytics from "../Analytics/Analytics";
import InstrumentDescriptionPanel from "./InstrumentDescriptionPanel";

/* eslint-disable react/prop-types */

const mapStateToProps = (state) => {
  return {
    instruments: state.instruments,
  };
};

const mapDispatchToProps = {
  fetchInstrumentList,
};

class Summary extends Component {
  state = {
    tab: "summary",
  };

  constructor(props) {
    super(props);
    this.setBrowserHistory = this.setBrowserHistory.bind(this);
  }

  componentDidMount() {
    this.props.fetchInstrumentList();
    const tab = this.props.match.params.tab;

    if (tab === "analytics") {
      this.setState({
        tab: "analytics",
      });
    }
  }

  handleTabSelect = (tab) => {
    this.setBrowserHistory(tab);
    this.setState({ tab });
  };

  setBrowserHistory(tab) {
    const instrumentId = this.props.match.params.instrumentId;
    const instrumentType = this.props.match.params.instrumentType;
    const newPath = linkToInlineInstrumentTab(
      instrumentType,
      instrumentId,
      tab
    );

    if (newPath !== history.location.pathname) {
      history.push(newPath);
    }
  }

  render() {
    const { match, instruments, breadcrumbs } = this.props;
    const instrumentId = match.params.instrumentId;

    const isLoaded = !instruments.isRequestInProgress;
    const instrument =
      isLoaded && instruments.data.find((i) => i.id === instrumentId);

    const breadcrumbsArray = getBreadcrumbsObject(breadcrumbs, match.params);

    return (
      <Page
        isLoading={!isLoaded}
        breadcrumbs={breadcrumbsArray}
        title={instrument?.name}
      >
        {instrument ? (
          <div className="instrument page">
            <Panel className="infoPanel">
              {
                <div className="calibrationPanel hidden-xs">
                  <CalibrationTable
                    className="calibrationTable"
                    instrumentInfo={instrument}
                  />
                </div>
              }
              <Nav
                className="instrument__navButtons"
                bsStyle="pills"
                activeKey={this.state.tab}
                onSelect={this.handleTabSelect}
              >
                <NavItem
                  eventKey={"summary"}
                  title="Summary"
                >
                  Summary
                </NavItem>
                <NavItem eventKey={"analytics"}>Analytics</NavItem>
              </Nav>
            </Panel>

            <div className="instrumentSummary">
              {this.state.tab === "summary" && (
                <InstrumentDescriptionPanel instrument={instrument} />
              )}
              {this.state.tab === "analytics" && (
                <InstrumentAnalytics instrument={instrument} />
              )}
            </div>
          </div>
        ) : null}
      </Page>
    );
  }
}

const SummaryContainer = connect(mapStateToProps, mapDispatchToProps)(Summary);

export default SummaryContainer;
