import { NON_INVALIDATING_QUERY_OPTIONS } from "#hooks/adapters/adapterUtils";
import { useQuery } from "@tanstack/react-query";
import {
  CalculatorDomain,
  GetCalculatorsRequestType,
  GetCalculatorsResponseType,
} from "@validereinc/domain";

export const useListCalculatorLibraries = () =>
  useQuery({
    queryKey: ["calculator", "getLibraries"],
    queryFn: () => CalculatorDomain.getLibraries(),
    ...NON_INVALIDATING_QUERY_OPTIONS,
  });

export const useListCalculators = (params: GetCalculatorsRequestType = {}) =>
  useQuery<GetCalculatorsResponseType>({
    ...NON_INVALIDATING_QUERY_OPTIONS,
    queryKey: ["calculator", "getCalculators", params],
    queryFn: () => CalculatorDomain.getCalculators(params),
  });

export const useListNetworkCalculators = (
  params: GetCalculatorsRequestType = { include: ["documentation"] }
) =>
  useQuery({
    queryKey: ["networkCalculator", "getNetworkCalculators", params],
    queryFn: () => CalculatorDomain.getNetworkCalculators(params),
    ...NON_INVALIDATING_QUERY_OPTIONS,
  });
