import { queryParamsConverter } from "#routers/historyHelper";
import useQueryParams from "#src/Routers/useQueryParams";
import {
  FilterPillbox,
  MultiDropdownInputWithSearch,
} from "@validereinc/common-components";
import difference from "lodash/difference";
import differenceBy from "lodash/differenceBy";
import React, { useEffect, useState } from "react";

const FILTER_DROPDOWNS = {
  facilityName: {
    label: "Facility",
    inputs: [],
    options: [],
    selectionLimit: 10,
  },
  facilityCode: {
    label: "Facility Code",
    inputs: [],
    options: [],
    selectionLimit: 10,
  },
};

const RecordsTableFilter = (facilities, { from, until }) => {
  const [filterDropdowns, setFilterDropdowns] = useState(FILTER_DROPDOWNS);

  const { queryParamInput, queryParamOptions } = queryParamsConverter(
    filterDropdowns,
    { from, until }
  );

  const [queryParams, setQueryParams] = useQueryParams(
    queryParamInput,
    queryParamOptions
  );

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (facilities.length) {
      const { facilityName, facilityCode } = filterDropdowns;

      facilityName.options = facilities.map((facility) => facility.name);
      facilityCode.options = facilities.map(
        (facility) => facility.facility_code
      );
      setFilterDropdowns({
        ...filterDropdowns,
        facilityName,
        facilityCode,
      });

      setIsLoading(false);
    }
  }, [facilities]);

  const onDropdownSelect = (selectedValue, key) => {
    setQueryParams({ [key]: selectedValue });
  };

  const clearAllFilters = () => {
    const newQueryParam = { ...queryParams };

    Object.keys(newQueryParam).forEach((key) => {
      newQueryParam[key] = [];
    });

    setQueryParams(newQueryParam);
  };

  const onClearFilterClick = (filterObject) => {
    if (!filterObject) {
      return clearAllFilters();
    }

    const newQueryParam = { ...queryParams };
    const { filterKey, name, id } = filterObject;

    if (id) {
      newQueryParam[filterKey] = differenceBy(
        newQueryParam[filterKey],
        [filterObject],
        "id"
      );
    } else {
      newQueryParam[filterKey] = difference(newQueryParam[filterKey], [name]);
    }

    setQueryParams({ ...newQueryParam });
  };

  // the added filters should not be present in filterPillbox
  const {
    from: _queryParamFrom,
    until: _queryParamUntil,
    ...filterBy
  } = queryParams;

  const filterRow = (
    <div className="filterRow">
      {Object.keys(filterDropdowns).map((key) => {
        const { label, labelKey, options, selectionLimit } =
          filterDropdowns[key];

        return (
          <MultiDropdownInputWithSearch
            key={key}
            label={label}
            labelKey={labelKey ? labelKey : null}
            dropdownKey={key}
            value={queryParams[key]}
            options={options}
            onChange={onDropdownSelect}
            selectLimit={selectionLimit}
          />
        );
      })}
    </div>
  );

  const filterPillbox = (
    <FilterPillbox
      filterBy={filterBy}
      onClearFilterClick={onClearFilterClick}
    />
  );

  // Only pass the full queryParams object once all options are loaded
  // so it doesn't cause an extra fetch
  return [filterRow, filterPillbox, !isLoading ? queryParams : {}, isLoading];
};

export default RecordsTableFilter;
