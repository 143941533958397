import { RoutePath } from "#src/utils/route";
import { UserGroupCategoriesRoutePath } from "../..";

export const UserGroupCategoryDetailsRoutePath =
  UserGroupCategoriesRoutePath.concat(
    new RoutePath<"categoryId">({
      path: "/:categoryId/details",
      title: "User Group Category Details",
    })
  );
