import useCarbonFacilities from "#components/hooks/useCarbonFacilities";
import useDeviceTypes from "#components/hooks/useDeviceTypes";
import useMeasurementTypes from "#components/hooks/useMeasurementTypes";
import {
  Button,
  Form,
  TextInput,
  WithTooltip,
} from "@validereinc/common-components";
import classNames from "classnames/bind";
import * as PropTypes from "prop-types";
import React, { useMemo } from "react";
import { v4 as uuidv4 } from "uuid";

import ConditionFields from "./ConditionFields";

import { ALERT_CONDITION_TOOLTIP } from "#components/AlertManagement/AlertConstants";
import styles from "../CreateAlertConfiguration.module.scss";

const cx = classNames.bind(styles);

const DetailsStep = ({ form }) => {
  const [facilityOptions] = useCarbonFacilities();
  const [deviceTypeOptions] = useDeviceTypes();
  const [measurementTypeOptions] = useMeasurementTypes();

  const conditions = form.watch("conditions");

  const displayConditions = useMemo(
    // We can't use the index of the element as the key to manage re-renders.
    // This will cause a re-render of each condition on addition/removal
    () => conditions.map((condition) => ({ ...condition, id: uuidv4() })),
    [conditions]
  );

  const onAddCondition = () => {
    form.setValue("conditions", [...conditions, { duration: 0 }]);
  };

  const onRemoveCondition = (index) => {
    form.setValue(
      "conditions",
      conditions.filter(
        (_condition, conditionIndex) => conditionIndex !== index
      )
    );
  };

  return (
    <>
      <p>Define the conditions for sending out an alert</p>

      <Form {...form}>
        <div className={cx("nameInput")}>
          <TextInput
            name="name"
            label="Alert Name"
            isRequired
          />
        </div>

        {displayConditions?.map((condition, index) => (
          <div key={condition.id}>
            <WithTooltip tooltip={ALERT_CONDITION_TOOLTIP}>
              <h2 className={cx("sectionHeader")}>Condition {index + 1}</h2>
            </WithTooltip>

            {conditions?.length > 1 ? (
              <div className={cx("removeConditionAction")}>
                <Button
                  icon="minus-circle"
                  onClick={() => onRemoveCondition(index)}
                >
                  Remove Condition
                </Button>
              </div>
            ) : null}

            <ConditionFields
              form={form}
              name={`conditions[${index}]`}
              facilityOptions={facilityOptions}
              deviceTypeOptions={deviceTypeOptions}
              measurementTypeOptions={measurementTypeOptions}
            />

            {displayConditions.length > 1 &&
            index < displayConditions.length - 1 ? (
              <hr />
            ) : null}
          </div>
        ))}

        <div className={cx("addConditionAction")}>
          <Button
            icon="plus-circle"
            onClick={onAddCondition}
          >
            Add Condition
          </Button>
        </div>
      </Form>
    </>
  );
};

DetailsStep.propTypes = {
  form: PropTypes.object.isRequired,
};

export default DetailsStep;
