import { Modal, Title } from "@validereinc/common-components";
import * as PropTypes from "prop-types";
import React from "react";
import { getManualEntryType } from "../../ManualEntryHelper";
import InlineManualEntry from "./InlineManualEntry";

const InlineManualEntryModal = ({ show, onHide, testInfo, refetchData }) => {
  return (
    <Modal
      open={show}
      onClose={onHide}
    >
      <Modal.Header>
        <Title>{getManualEntryType("inline")?.title ?? "Unavailable"}</Title>
      </Modal.Header>

      <InlineManualEntry
        view="modal"
        bodyComponent={Modal.Body}
        footerComponent={Modal.Footer}
        show={show}
        onHide={onHide}
        testInfo={testInfo}
        refetchData={refetchData}
      />
    </Modal>
  );
};

InlineManualEntryModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  testInfo: PropTypes.object,
  refetchData: PropTypes.func,
};

export default InlineManualEntryModal;
