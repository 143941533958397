import { useBreadcrumbs } from "#src/Routers/breadcrumbsHelper";
import { useNavigate, useParams } from "#src/Routers/hooks";
import AlertMessageWithLink from "#src/components/Common/Alerts/AlertMessageWithLink";
import {
  MultiStepFormProvider,
  useMultiStepFormContext,
} from "#src/hooks/useMultiStepForm";
import { FORMS_BREADCRUMB } from "#src/routes/forms";
import { FORMS_CATEGORIES_BREADCRUMB } from "#src/routes/forms/categories";
import {
  FORM_CATEGORY_DETAILS_BREADCRUMB,
  linkToFormCategoryDetails,
} from "#src/routes/forms/categories/[categoryId]";
import { CREATE_FORM_CATEGORY_FORM_TEMPLATE_BREADCRUMB } from "#src/routes/forms/categories/[categoryId]/create-form-template";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Page, useAlert } from "@validereinc/common-components";
import { FormCategoryAdapter, FormSchema } from "@validereinc/domain";
import React from "react";
import { linkToFormTemplateDetail } from "../templates/[formTemplateId]";
import {
  CreateFormTemplateDetailsStep,
  CreateFormTemplateSectionStep,
} from "./steps";

export const CreateFormTemplatePageContent = () => {
  const { categoryId } = useParams<{ categoryId: string }>();

  const { stepper, footer } = useMultiStepFormContext();

  const categoryQuery = useQuery({
    queryKey: ["formCategories", categoryId],
    queryFn: ({ queryKey: [_, id] }) =>
      FormCategoryAdapter.getOne({
        id,
      }),
  });

  const breadcrumbs = useBreadcrumbs(
    [
      FORMS_BREADCRUMB,
      FORMS_CATEGORIES_BREADCRUMB,
      FORM_CATEGORY_DETAILS_BREADCRUMB,
      CREATE_FORM_CATEGORY_FORM_TEMPLATE_BREADCRUMB,
    ],
    {
      2: categoryQuery?.data?.name,
    }
  );

  return (
    <Page
      breadcrumbs={breadcrumbs}
      category={categoryQuery?.data?.name}
      title="Create Template"
      actionRow={stepper}
      footer={footer}
    >
      <CreateFormTemplateDetailsStep />
      <CreateFormTemplateSectionStep />
    </Page>
  );
};

export const CreateFormTemplatePage = () => {
  const { addAlert } = useAlert();
  const { categoryId } = useParams<{ categoryId: string }>();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: async (formValues) =>
      await FormSchema.createOne({ data: formValues }),
    onSuccess: ({ id }) => {
      addAlert?.({
        variant: "success",
        message: (
          <AlertMessageWithLink
            mainText="Successfully created form template."
            linkText="View Template Details"
            position="bottom"
            onLinkClick={() =>
              navigate({
                pathname: linkToFormTemplateDetail(categoryId, id),
              })
            }
          />
        ),
      });

      queryClient.invalidateQueries({
        queryKey: ["formCategories", categoryId],
      });

      queryClient.invalidateQueries({
        queryKey: ["formSchemas"],
      });

      navigate({
        pathname: linkToFormCategoryDetails(categoryId),
      });
    },
    onError: () => {
      addAlert?.({
        variant: "error",
        message: "Failed to create form template.",
      });
    },
  });

  const onSubmit = (values: any[]) => {
    mutation.mutate({
      ...values.reduce((total, current) => ({ ...total, ...current }), {}),
      form_category_id: categoryId,
    });
  };

  const onCancel = () => {
    navigate.goBack();
  };

  return (
    <MultiStepFormProvider
      steps={[
        {
          label: "Details",
        },
        {
          label: "Sections & Questions",
        },
      ]}
      onSubmit={onSubmit}
      onCancel={onCancel}
    >
      <CreateFormTemplatePageContent />
    </MultiStepFormProvider>
  );
};
