import ColumnSelect from "#components/Common/ColumnSelect/ColumnSelect";
import { ensureStreamListIsFetched } from "#redux/actions/index";
import { getBreadcrumbsObject } from "#routers/breadcrumbsHelper";
import { useNavigate, useSearchParams } from "#routers/hooks";
import { linkToCreateWorkflow, linkToEditWorkflow } from "#routers/links";
import WorkflowService from "#services/WorkflowService";
import useTableState from "#src/hooks/useTableState";
import { getFrontendTableState } from "#utils/frontendTableActions";
import { GetAllObjectValue } from "#utils/objectFormatter";
import {
  Button,
  DataTable,
  Filters,
  Page,
  Panel,
} from "@validereinc/common-components";
import intersectionWith from "lodash/intersectionWith";
import * as PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import useManageWorkflowConfig from "./useManageWorkflowConfig";

const mapStateToProps = ({ streams }) => {
  return {
    streams: streams.data.toJS(),
  };
};

const mapDispatchToProps = {
  ensureStreamListIsFetched,
};

const MANAGE_WORKFLOW_LOCAL_STORAGE_KEY = "manage_workflow_selection_headers";

function ManageWorkflows({ breadcrumbs, streams, ensureStreamListIsFetched }) {
  const [sites, setSites] = useState([]);
  const [measurementTypes, setMeasurementTypes] = useState([]);
  const [streamsWithName, setStreamsWithName] = useState([]);
  const [streamIds, setStreamIds] = useState([]);

  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [resultantWorkflow, setResultantWorkflow] = useState();

  const manageWorkflowBreadcrumbs = getBreadcrumbsObject(breadcrumbs);

  const onFetchData = useCallback(
    async (newSearchParams) => {
      let newResponse = resultantWorkflow;

      if (!resultantWorkflow) {
        newResponse = await WorkflowService.getWorkflow();
        setResultantWorkflow(newResponse);
      }

      return getFrontendTableState({
        data: newResponse,
        itemsKey: "data",
        query: newSearchParams,
        filterMapping: {
          name: {
            filter: ({ value, item }) =>
              value?.toLowerCase()?.includes(item?.toLowerCase()),
          },
          sites: {
            filter: ({ value, item }) =>
              value.find(({ id }) =>
                Array.isArray(item)
                  ? item.includes(id.toString())
                  : id.toString() === item
              ),
          },
        },
      });
    },
    [resultantWorkflow]
  );

  const { tableProps } = useTableState({
    onFetchData,
  });

  const { headers, filters } = useManageWorkflowConfig({
    sites,
    measurementTypes,
    streamsWithName,
  });
  const [displayHeaders, setDisplayHeaders] = useState(headers);

  useEffect(() => {
    ensureStreamListIsFetched();
  }, []);

  useEffect(() => {
    if (resultantWorkflow) {
      const sites = GetAllObjectValue(resultantWorkflow.data, "sites", "id");
      const measurementTypes = GetAllObjectValue(
        resultantWorkflow.data,
        "tests_required"
      );
      const streamIds = GetAllObjectValue(resultantWorkflow.data, "stream_ids");

      setSites(sites);
      setMeasurementTypes(measurementTypes);
      setStreamIds(streamIds);
    }
  }, [resultantWorkflow]);

  useEffect(() => {
    const streamsWithName = intersectionWith(
      streams,
      streamIds,
      (streams, streamId) => {
        return streams.id === streamId;
      }
    );
    setStreamsWithName(streamsWithName);
  }, [streamIds, streams]);

  const onFiltersChange = (values) => {
    const { test_type, sites, tests_required, stream_ids, name } = values;
    setSearchParams({
      ...searchParams,
      sites: sites?.map(({ id }) => id),
      test_type: test_type?.map(({ id }) => id),
      tests_required,
      stream_ids: stream_ids?.map(({ id }) => id),
      name,
    });
  };

  function onRowClick(rowData) {
    navigate({
      pathname: linkToEditWorkflow(rowData.id),
    });
  }

  function createWorkflow() {
    navigate({
      pathname: linkToCreateWorkflow(),
    });
  }

  return (
    <Page
      title="Manage Workflows"
      breadcrumbs={manageWorkflowBreadcrumbs}
    >
      <Filters
        onChange={onFiltersChange}
        filters={filters}
        actions={[
          <ColumnSelect
            key={"column-select"}
            options={headers}
            onChange={setDisplayHeaders}
            value={displayHeaders}
            localStorageKey={MANAGE_WORKFLOW_LOCAL_STORAGE_KEY}
          />,
          <Button
            key="export-button"
            variant="primary"
            size="small"
            onClick={createWorkflow}
          >
            Add Workflow
          </Button>,
        ]}
      />
      <Panel>
        <DataTable
          headers={displayHeaders}
          {...tableProps}
          onRowClick={onRowClick}
          isFluid={false}
        />
      </Panel>
    </Page>
  );
}

ManageWorkflows.propTypes = {
  breadcrumbs: PropTypes.array.isRequired,
  height: PropTypes.number,
  streams: PropTypes.array.isRequired,
  ensureStreamListIsFetched: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageWorkflows);
