import { Device } from "@validereinc/domain";
import { useEffect, useState } from "react";

export const useDeviceDetail = (id, dependencies = []) => {
  const [isLoading, setIsLoading] = useState(true);
  const [deviceDetail, setDeviceDetail] = useState({});

  useEffect(() => {
    (async () => {
      if (id) {
        setIsLoading(true);
        const response = await Device.getOne({ id });
        setDeviceDetail(response.data);
        setIsLoading(false);
      }
    })();
  }, [id, ...dependencies]);

  return [deviceDetail, isLoading];
};
