import { Panel, Title } from "@validereinc/common-components";
import * as PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import history from "../../../../Routers/history";
import { linkToEditBalances } from "../../../../Routers/links";
import BalancesServices from "../../../Services/BalancesService";
import "./SourceSummary.scss";
import SourceSummaryTable from "./SourceSummaryTable";

const useFetchSources = (balanceId, dateRange, fetchSource, sourceKey) => {
  const [sources, setSources] = useState([]);
  const [loadingState, setLoadingState] = useState("loading");

  useEffect(() => {
    setLoadingState("loading");

    if (balanceId && dateRange && fetchSource) {
      BalancesServices.getSourceDetail(balanceId, sourceKey, dateRange)
        .then(({ data }) => {
          setSources(data);
          setLoadingState("loaded");
        })
        .catch(() => {
          setLoadingState("error");
        });
    }
  }, [balanceId, dateRange, fetchSource, sourceKey]);

  return [sources, loadingState];
};

const SourceSummary = (props) => {
  const {
    balanceId,
    dateRange,
    sourceKey,
    hasSourceData,
    haveWritePermissions,
    balanceType,
    showTotalVolume,
    showEnergy,
  } = props;

  const [enableFetchSource, setFetchSource] = useState(false);
  const [sources, loadingState] = useFetchSources(
    balanceId,
    dateRange,
    enableFetchSource,
    sourceKey
  );

  const onFetchSourceClick = () => {
    setFetchSource(true);
  };

  const onModifyButtonClick = () => {
    history.push(linkToEditBalances(balanceId));
  };

  if (dateRange) {
    return (
      <Panel
        title={
          <Title
            type="panelheader"
            className="sourceSummary__header"
          >
            {sourceKey}
          </Title>
        }
        className="sourceSummary"
      >
        {enableFetchSource ? (
          <SourceSummaryTable
            sources={sources}
            loaded={loadingState === "loaded"}
            balanceType={balanceType}
            showTotalVolume={showTotalVolume}
            showEnergy={showEnergy}
          />
        ) : (
          <div className="sourceSummary__hint">
            {hasSourceData ? (
              <a
                className="sourceSummary__fetchDetailLink link"
                onClick={onFetchSourceClick}
              >
                Click me to load {sourceKey} sources.
              </a>
            ) : (
              <div>
                No {sourceKey} sources selected.
                <br />
                {haveWritePermissions && (
                  <>
                    <a
                      className="sourceSummary__fetchDetailLink link"
                      onClick={onModifyButtonClick}
                    >
                      Click me
                    </a>{" "}
                    to add {sourceKey} sources.
                  </>
                )}
              </div>
            )}
          </div>
        )}
      </Panel>
    );
  } else {
    return null;
  }
};

SourceSummary.propTypes = {
  balanceId: PropTypes.string,
  dateRange: PropTypes.object,
  sourceKey: PropTypes.string.isRequired,
  haveWritePermissions: PropTypes.bool.isRequired,
  balanceType: PropTypes.string,
  showTotalVolume: PropTypes.bool,
  hasSourceData: PropTypes.bool,
  showEnergy: PropTypes.bool,
};

export default SourceSummary;
