import Immutable from "immutable";

import {
  FETCH_INSIGHT_LIST,
  FETCH_INSIGHT_LIST_ERROR,
  USER_SIGNED_OUT,
} from "../constants/action-types";

const insight = Immutable.Record({
  id: undefined,
  title: undefined,
  summary: undefined,
  recommendation: undefined,
  risk_level: undefined,
  data_start_utc_epoch: undefined,
  data_end_utc_epoch: undefined,
  company_id: undefined,
  sites: undefined,
  has_download: undefined,
});

export const data = new Immutable.Set([]);

export const InsightState = Immutable.Record(
  {
    fetchError: false,
    fetchErrorMessage: "",
    fetchErrorStatus: "",
    data: data,
    loadingState: "loading",
  },
  "InsightState"
);

export default (state = new InsightState(), action) => {
  let newData = new Immutable.Set([]);
  switch (action.type) {
    case FETCH_INSIGHT_LIST:
      action.payload.forEach(function (insightItem) {
        newData = newData.add(new insight(insightItem));
      });

      return state.withMutations((s) => {
        s.data = newData;
        s.fetchError = false;
        s.fetchErrorMessage = "";
        s.fetchErrorStatus = "";
        s.loadingState = "loaded";
      });

    case FETCH_INSIGHT_LIST_ERROR:
      return state.withMutations((s) => {
        s.fetchError = true;
        s.fetchErrorMessage = action.payload.message;
        s.fetchErrorStatus = action.payload.status;
        s.loadingState = "error";
      });

    case USER_SIGNED_OUT:
      return state.withMutations((s) => {
        s.data = new Immutable.Set([]);
        s.fetchError = false;
        s.fetchErrorMessage = "";
        s.fetchErrorStatus = "";
        s.loadingState = "loaded";
      });

    default:
      return state;
  }
};
