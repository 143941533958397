import { linkToDeviceDetail, linkToFormSubmissionDetail } from "#routers/links";
import { RoutingLink } from "#src/batteries-included-components/RoutingLink";
import FileDownloadLink from "#src/components/Common/FileDownloadLink/FileDownloadLink";
import { recordValueStatusPickerOptions } from "#src/components/Common/InlineRecordValueStatusPicker/InlineRecordValueStatusPicker";
import { useMeasurementTypes } from "#src/contexts/MeasurementTypeContext";
import { linkToCalculationResultDetailPage } from "#utils/links";
import { PillPopover, Tooltip } from "@validereinc/common-components";
import {
  AssetType,
  AssetTypeType,
  EstimationMethodType,
  EstimationMethodWithConfigurationAndRunType,
  FormResponseDataItemType,
  RecordType,
  RecordValueConfigurationSourceType,
  RecordValueConfigurationTypeNames,
  RecordValueConfigurationTypeType,
  RecordValueConfigurationTypeTypeType,
  RecordValueType,
} from "@validereinc/domain";
import {
  fullMonthFormatter,
  getFormattedNumberWithUnit,
  yearMonthParser,
} from "@validereinc/utilities";
import { endOfMonth, startOfMonth } from "date-fns";
import { truncate } from "lodash";
import React, { ReactNode } from "react";

export const getSourcePath = (
  configurationType?: RecordValueConfigurationTypeTypeType,
  source?: RecordValueConfigurationSourceType
) => {
  if (!source || !configurationType) {
    return "";
  }
  switch (configurationType) {
    case RecordValueConfigurationTypeType.CALCULATION:
      return source.estimation_method_entity_type &&
        source.estimation_method_entity_id &&
        source.estimation_method_id &&
        source.year_month
        ? linkToCalculationResultDetailPage(
            source.estimation_method_entity_type,
            source.estimation_method_entity_id,
            source.estimation_method_id,
            source.year_month
          )
        : "";
    case RecordValueConfigurationTypeType.MEASUREMENT:
      return linkToFormSubmissionDetail(source.form_submission_id);
    case RecordValueConfigurationTypeType.MEASUREMENT_SERIES:
      return linkToDeviceDetail(source.device_id);
    default:
      return "";
  }
};

export const getSourceName = (
  configurationType?: RecordValueConfigurationTypeTypeType,
  source?: Omit<RecordValueConfigurationSourceType, "unit" | "value">,
  measurement?: FormResponseDataItemType
) => {
  if (!source || !configurationType) {
    return "-";
  }
  const time = source.measurement_time ?? measurement?.time;
  switch (configurationType) {
    case RecordValueConfigurationTypeType.CALCULATION:
      return source.estimation_method_name;
    case RecordValueConfigurationTypeType.MEASUREMENT:
      return time
        ? `${fullMonthFormatter(new Date(time))} ${source.form_schema_name}`
        : source.form_schema_name;
    case RecordValueConfigurationTypeType.MEASUREMENT_SERIES:
      return source.device_name;
    default:
      return undefined;
  }
};

export const getSubjectIdFromSearchParams = (
  searchParams: Record<string, string>
) => {
  switch (searchParams.asset_type) {
    case AssetType.FACILITY:
      return searchParams.facility;
    case AssetType.EQUIPMENT:
      return searchParams.equipment;
    case AssetType.FLOW:
      return searchParams.flow;
    default:
      return;
  }
};

export const getAssetIdsByType = (
  items: Array<{ id?: string; type?: string }>,
  assetType: AssetTypeType
) =>
  Array.from(
    new Set(
      items
        .filter(({ id, type }) => !!id && type === assetType)
        .map(({ id }) => id)
    )
  );

export const enhanceSource = (
  source: RecordValueConfigurationSourceType,
  estimationMethods: EstimationMethodWithConfigurationAndRunType[]
) => {
  if (source.estimation_method_id) {
    const estimationMethod = estimationMethods.find(
      ({ id }: EstimationMethodType) => id === source.estimation_method_id
    );
    return {
      ...source,
      estimation_method_entity_id: estimationMethod?.entity_id,
    };
  }
  return source;
};

export const getDefaultDateRange = (
  record?: RecordType,
  measurementType?: string
) => {
  const recordValue = record?.values.find(
    ({ measurement_type }) => measurement_type === measurementType
  );

  const from = recordValue?.configuration?.start
    ? new Date(recordValue?.configuration?.start)
    : startOfMonth(record ? yearMonthParser(record.year_month) : new Date());
  const to = recordValue?.configuration?.end
    ? new Date(recordValue?.configuration?.end)
    : endOfMonth(record ? yearMonthParser(record.year_month) : new Date());

  return { from, to };
};

export const useRenderRecordValue = () => {
  const { getPrecisionByType, getUnitName } = useMeasurementTypes();

  return (item?: RecordValueType): ReactNode => {
    const value = item?.value;
    const unit = item?.measurement_unit;
    if (!value) return "-";
    return getFormattedNumberWithUnit(
      {
        value: Number(value),
        ...(unit ? { unit: getUnitName(unit, value) } : {}),
      },
      null,
      {
        maxFractionDigits: getPrecisionByType(item?.measurement_type),
        minFractionDigits: 0,
      }
    );
  };
};

export const renderRecordValueSource = (item?: RecordValueType): ReactNode => {
  const recordValueSources = item?.configuration?.sources;

  if (!Array.isArray(recordValueSources)) return "-";
  if (recordValueSources.length > 1) return "Many";

  const source = recordValueSources[0];

  const name =
    getSourceName(item?.configuration.configuration_type, source) ??
    "Unknown Estimation Method";
  const pathname =
    getSourcePath(item?.configuration.configuration_type, source) ?? "";
  const query =
    source?.estimation_method_entity_type === AssetType.ASSET_GROUP
      ? {
          tab: "calculations",
          period: source.year_month ?? "",
        }
      : undefined;

  return (
    <RoutingLink
      to={{
        pathname,
        search: `?${new URLSearchParams(query).toString()}`,
      }}
    >
      {name}
    </RoutingLink>
  );
};

export const renderRecordValueSourceType = (
  item?: RecordValueType
): ReactNode => {
  return (
    RecordValueConfigurationTypeNames[
      item?.configuration?.configuration_type ?? ""
    ] ?? "-"
  );
};

export const renderRecordValueStatus = (item?: RecordValueType): ReactNode => {
  if (!item?.status) return "-";

  const statusObject = recordValueStatusPickerOptions.find(
    (option) => option.value === item?.status
  );

  return (
    <PillPopover.Display
      label={statusObject?.label ?? ""}
      variant={statusObject?.variant}
      hasDot={statusObject?.icon !== null}
      icon={statusObject?.icon ?? null}
    />
  );
};

export const renderRecordValueConfigurationType = (
  item?: RecordValueType
): ReactNode =>
  item?.default_record_value_config_id ? "Default Automation" : "Custom";

export const renderRecordValueNote = (item?: RecordValueType): ReactNode => {
  let component = (
    <div>{item?.note ? truncate(item?.note, { length: 100 }) : "-"}</div>
  );

  if (!!item?.note && item.note.length > 100) {
    component = (
      <Tooltip
        align="end"
        content={item.note}
        trigger="hover"
      >
        {component}
      </Tooltip>
    );
  }

  return component;
};

export const renderRecordValueAttachment = (
  item?: RecordValueType
): ReactNode =>
  item?.attachment_filename && item?.attachment_ref ? (
    <FileDownloadLink
      fileName={item.attachment_filename}
      fileUrl={item.attachment_ref}
      hideIcon
    />
  ) : (
    "-"
  );
