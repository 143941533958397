import { useQuery } from "@tanstack/react-query";
import { CalculatorResultsDomain } from "@validereinc/domain";

export const useReportingGroupMeasurementTypes = (
  reportingGroupId: string,
  defaultMeasurementTypes: string[] = []
) => {
  const params = {
    page: 1,
    pageSize: 1,
    filter: {
      reporting_group: reportingGroupId,
    },
  };

  const { data, isLoading } = useQuery({
    queryKey: ["calculatorResults", params],
    queryFn: () => CalculatorResultsDomain.getList(params),
    select: ({ data }) => data[0]?.measurement,
  });

  const measurementTypeList = Object.keys(data ?? {});

  return {
    measurementTypeList: measurementTypeList.length
      ? measurementTypeList
      : defaultMeasurementTypes,
    isLoading,
  };
};
