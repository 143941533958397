import {
  apiRequestServicesPromise,
  apiPostUploadFileServicesPromise,
} from "./ServiceHelper";

const ThirdPartyLabImportService = {
  getThirdPartyLabImportFiles(startTime, endTime) {
    const parameter = {
      start_time: startTime,
      end_time: endTime,
    };

    return apiRequestServicesPromise("/api/lab_reports", parameter);
  },

  importThirdPartyLabFile(siteId, file) {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("file_name", file.name);
    formData.append("site_id", siteId);

    return apiPostUploadFileServicesPromise("api/lab_reports", formData);
  },
};

export default ThirdPartyLabImportService;
