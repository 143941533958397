import React from "react";
import getAgilentGCInstrumentDisplayProperties from "#components/Instrument/AgilentGCInstrument/DisplayProperties";
import getAntonPaarDisplayProperties from "#components/Instrument/AntonPaarDensity4500Instrument/DisplayProperties";
import getAntonPaarSVM3001DisplayProperties from "#components/Instrument/AntonPaarSVM3001Instrument/DisplayProperties";
import getAntonPaarSVM3001CustomMeasurementHeaders from "#components/Instrument/AntonPaarSVM3001Instrument/CustomMeasurementHeaders";
import getAquamaxKFDisplayProperties from "#components/Instrument/AquamaxKFInstrument/DisplayProperties";
import getEralyticVaporDisplayProperties from "#components/Instrument/EralyticsVaporInstrument/DisplayProperties";
import getHoribaXrayFluorescence from "#components/Instrument/HoribaSulphurInstrument/DisplayProperties";
import getRigakuXrayFluorescence from "#components/Instrument/RigakuSulphurInstrument/DisplayProperties";
import getRudolphDensityDisplayProperties from "#components/Instrument/RudolphDensityInstrument/DisplayProperties";
import getMettlerToledoT5DisplayProperties from "#components/Instrument/MettlerToledoT5Instrument/DisplayProperties";
import getMettlerToledoV20SDisplayProperties from "#components/Instrument/MettlerToledoV20SInstrument/DisplayProperties";
import getValidereVaporDisplayProperties from "#components/Instrument/ValidereVaporInstrument/DisplayProperties";
import getGrabnerMiniVapDisplayProperties from "#components/Instrument/GrabnerMiniVapInstrument/DisplayProperties";
import getValidereWaterDisplayProperties from "#components/Instrument/ValidereWaterInstrument/DisplayProperties";
import { getMeasurementValue } from "#components/Instrument/InstrumentHelper";
import {
  getAlertSpecString,
  getAlertTypeString,
  getDisplayProperties,
  getMeasurementTextStyle,
} from "../TestDetailModal/TestAnalysis/TestAnalysisHelper";
import find from "lodash/find";
import pickBy from "lodash/pickBy";

export const MEASUREMENT_ROW_HEIGHT = 80;
export const MEASUREMENT_TABLE_OFFSET = 90;
export const MEASUREMENT_TABLE_OVERFLOW = 40;

/**
 * A list of objects determining each row of the MeasurementTable
 *
 * @param {string} label The string displayed in the Measurement name column
 * @param {string} key The property to retrieve in `allMeasurements` object above
 * @param {string} measurementKey The string, if present, to use to find the
 *   measurement's MeasurementType for formatting (defaults to key)
 * @param {boolean} notMeasurement Whether or not the value could be formatted
 *   using an existing MeasurementType
 *
 * The Params below are only used if notMeasurement is true
 *
 * @param {function} getValue Formats the given value
 * @param {string} unit Additional string to be added after the value
 * @param {number} decimals formats the number value to have exactly number decimals
 */
export const getAllMeasurementDisplayProperties = (testDetail) => {
  switch (testDetail?.instrument?.type) {
    case "agilent gas chromatography":
      return getAgilentGCInstrumentDisplayProperties();

    case "anton paar density":
      return getAntonPaarDisplayProperties(testDetail);

    case "anton paar svm 3001":
    case "anton paar svm 3000":
      return getAntonPaarSVM3001DisplayProperties(testDetail);

    case "aquamax kf":
      return getAquamaxKFDisplayProperties(testDetail);

    case "eralytics vapor":
      return getEralyticVaporDisplayProperties(testDetail);

    case "horiba xray fluorescence":
      return getHoribaXrayFluorescence(testDetail);

    case "rigaku xray fluorescence":
      return getRigakuXrayFluorescence(testDetail);

    case "rudolph research density":
      return getRudolphDensityDisplayProperties(testDetail);

    case "mettler toledo t5":
      return getMettlerToledoT5DisplayProperties(testDetail);

    case "mettler toledo v20s":
      return getMettlerToledoV20SDisplayProperties(testDetail);

    case "validere vapor":
      return getValidereVaporDisplayProperties(testDetail);

    case "grabner minivap vpxpert": {
      return getGrabnerMiniVapDisplayProperties(testDetail);
    }

    case "water": {
      return getValidereWaterDisplayProperties(testDetail);
    }

    default:
      return getDisplayProperties(testDetail?.measurements);
  }
};

export const getDisplayPropertiesWithAlerts = (
  testDetail,
  displayProperties
) => {
  const measurementsWithAlerts = pickBy(
    testDetail?.measurements,
    (measurement) => measurement.alerts.length > 0
  );

  return displayProperties
    .filter((property) => {
      return property.key in measurementsWithAlerts;
    })
    .map((property) => ({ ...property, alertTriggered: true }));
};

export const getMeasurementDisplayProperties = (testDetail) => {
  const displayProperties = getAllMeasurementDisplayProperties(testDetail);
  const displayPropertiesWithAlerts = getDisplayPropertiesWithAlerts(
    testDetail,
    displayProperties
  );
  return [displayProperties, displayPropertiesWithAlerts];
};

export const getCustomMeasurementHeaders = (testDetail, getMeasurementType) => {
  switch (testDetail?.instrument?.type) {
    case "anton paar svm 3001":
    case "anton paar svm 3000":
      return getAntonPaarSVM3001CustomMeasurementHeaders(
        testDetail,
        getMeasurementType
      );
    default:
      return [];
  }
};

export const getMeasurementPanelHeight = (measurements) => {
  const height = measurements.length
    ? MEASUREMENT_ROW_HEIGHT * measurements.length +
      MEASUREMENT_TABLE_OFFSET +
      MEASUREMENT_TABLE_OVERFLOW
    : MEASUREMENT_TABLE_OFFSET + MEASUREMENT_ROW_HEIGHT;
  return { height: `${height}px` };
};

export const getMeasurementRenderers = (value, getMeasurementType) => {
  const {
    measurements,
    additional_measurements,
    metadata,
    measurement_alert_specs: alertSpecs,
  } = value || {};

  const allMeasurements = {
    ...measurements,
    ...additional_measurements,
    ...metadata,
  };
  return {
    measurementNameRenderer: (rowData, columnKey) => {
      return (
        <div
          className="wrap"
          style={getMeasurementTextStyle(rowData.alertTriggered)}
        >
          {rowData[columnKey]}
        </div>
      );
    },
    measurementRenderer: ({
      key,
      measurementKey = key,
      notMeasurement,
      unit,
      getValue,
      getMetadata,
      decimals,
      alertTriggered = false,
    }) => {
      try {
        let value = "";

        if (notMeasurement) {
          value = getValue
            ? getValue(allMeasurements[key], key)
            : allMeasurements[key];
          unit = unit ? unit : "";
        } else {
          const measurementType = getMeasurementType(measurementKey);

          value = allMeasurements[key].value ?? allMeasurements[key];
          decimals = measurementType.decimals;
          unit = measurementType?.unit ?? "";
        }

        value = decimals ? getMeasurementValue(value, decimals) : value;

        if (value) {
          return (
            <div>
              <div
                className="wrap"
                style={getMeasurementTextStyle(alertTriggered)}
              >{`${value} ${unit}`}</div>

              {getMetadata ? (
                <div>{getMetadata(allMeasurements[key])}</div>
              ) : null}
            </div>
          );
        }
      } catch {
        return <div>-</div>;
      }
    },
    alertSpecRenderer: ({
      key,
      notMeasurement,
      measurementKey = key,
      alertTriggered = false,
    }) => {
      const alertSpec = find(alertSpecs, { test_property: key });
      const decimals = notMeasurement
        ? null
        : getMeasurementType(measurementKey);

      return (
        <div style={getMeasurementTextStyle(alertTriggered)}>
          {getAlertSpecString(alertSpec, decimals)}
        </div>
      );
    },
    alertTypeRenderer: ({ key, alertTriggered = false }) => {
      const alertSpec = find(alertSpecs, { test_property: key });

      return (
        <div style={getMeasurementTextStyle(alertTriggered)}>
          {getAlertTypeString(alertSpec)}
        </div>
      );
    },
  };
};
