import config from "#src/config";
import { getTimeStringFromDate } from "#utils/timeFormatter";
import {
  LegacyDataTable,
  Title,
  Tooltip,
} from "@validereinc/common-components";
import find from "lodash/find";
import * as PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { AutoSizer } from "react-virtualized";
import history from "../../../Routers/history";
import { linkToBalances, linkToCreateBalances } from "../../../Routers/links";
import { useFetchInventories } from "../../Inventories/Inventory";
import BalancesServices from "../../Services/BalancesService";
import "./BalancesTable.scss";
import useFilterInputs from "./BalancesTableFilter";
import {
  sortByComponents,
  sortByName,
  sortBySource,
  sortComponents,
} from "./BalancesTableHelper";

function getSourceDetail(sourceList, sourceId) {
  return find(sourceList, { id: sourceId });
}

const useFetchBalanceList = () => {
  const [balancesList, setBalancesList] = useState([]);
  const [loadingState, setLoadingState] = useState("loading");

  useEffect(() => {
    BalancesServices.getBalances()
      .then(({ data }) => {
        setBalancesList(data);
        setLoadingState("loaded");
      })
      .catch(() => {
        setLoadingState("error");
      });
  }, []);

  return [balancesList, loadingState];
};

const BalancesTable = (props) => {
  const [balancesList, loadingState] = useFetchBalanceList();
  const [filterPillbox, filterRow, filteredBalance] = useFilterInputs(
    balancesList,
    props.streams
  );

  const [inventoryList] = useFetchInventories();

  const onAddClick = () => {
    history.push(linkToCreateBalances());
  };

  const onViewClick = (balanceId) => {
    history.push(linkToBalances(balanceId));
  };

  const typeRenderer = (rowData, columnKey) => {
    const type = rowData[columnKey];
    return (
      <span className="capitalized">{type?.replace("_", " ") ?? "-"}</span>
    );
  };

  const sourceRenderer = (rowData, columnKey) => {
    let streams = [];
    if (props.streams.length) {
      streams = rowData[`${columnKey}_streams`]
        .map((stream) => {
          return getSourceDetail(props.streams, stream.id);
        })
        .sort((a, b) => a?.name?.localeCompare?.(b?.name));
    }

    let inventories = [];
    if (inventoryList.length) {
      inventories = rowData[`${columnKey}_inventories`]
        .map((inventory) => {
          return getSourceDetail(inventoryList, inventory.id);
        })
        .sort((a, b) => a?.name?.localeCompare?.(b?.name));
    }

    const numSources = streams.length + inventories.length;

    if (numSources) {
      const tooltip = (
        <div className="balancesTable__tooltipContent">
          {streams.length ? (
            <>
              <Title
                className="balancesTable__tooltipTitle"
                type="subheader"
                style={{ color: "white" }}
              >
                Streams
              </Title>
              <ul>
                {streams.map((stream) => (
                  <li key={stream?.id}>{stream?.name ?? "-"}</li>
                ))}
              </ul>
            </>
          ) : null}

          {inventories.length ? (
            <>
              <Title
                className="balancesTable__tooltipTitle"
                type="subheader"
                style={{ color: "white" }}
              >
                Inventories
              </Title>
              <ul>
                {inventories.map((inventory) => (
                  <li key={inventory?.id}>{inventory?.name ?? "-"}</li>
                ))}
              </ul>
            </>
          ) : null}
        </div>
      );

      const label = `${numSources} ${columnKey}${numSources > 1 ? "s" : ""}`;

      return (
        <Tooltip
          content={tooltip}
          trigger="click"
        >
          <span className="balancesTable__tooltipBase">{label}</span>
        </Tooltip>
      );
    }

    return "-";
  };

  const componentRenderer = (rowData, columnKey) => {
    const components = rowData[columnKey];

    if (components?.length ?? 0) {
      const tooltip = (
        <ul>
          {sortComponents(components).map((component) => (
            <li key={component}>{component}</li>
          ))}
        </ul>
      );

      return (
        <Tooltip
          content={tooltip}
          title="Components"
        >
          <span className="balancesTable__tooltipBase">
            {`${components?.length ?? 0} ${columnKey}`}
          </span>
        </Tooltip>
      );
    }

    return "-";
  };

  const dateUpdatedRenderer = (rowData, columnKey) => {
    const lastUpdatedDate = rowData[columnKey];

    if (rowData[columnKey]) {
      return getTimeStringFromDate(lastUpdatedDate, config.DATETIME_FORMAT);
    }
    return rowData?.[columnKey]?.name ?? "-";
  };

  const updateByRenderer = (rowData, columnKey) => {
    return rowData?.[columnKey]?.name ?? "-";
  };

  const Headers = [
    {
      label: "Name",
      key: "name",
      width: 250,
      fixed: true,
    },
    {
      label: "Type",
      key: "type",
      width: 150,
      cellRenderer: typeRenderer,
    },
    {
      label: "Inlets",
      key: "inlet",
      width: 150,
      cellRenderer: sourceRenderer,
      sort: sortBySource,
    },
    {
      label: "Outlets",
      key: "outlet",
      width: 150,
      cellRenderer: sourceRenderer,
      sort: sortBySource,
    },
    {
      label: "References",
      key: "reference",
      width: 150,
      cellRenderer: sourceRenderer,
      sort: sortBySource,
    },
    {
      label: "Components",
      key: "components",
      width: 150,
      cellRenderer: componentRenderer,
      sort: sortByComponents,
    },
    {
      label: "Last Updated At",
      key: "last_revision_at",
      width: 200,
      cellRenderer: dateUpdatedRenderer,
    },
    {
      label: "Last Updated By",
      key: "last_revision_by",
      width: 150,
      cellRenderer: updateByRenderer,
      sort: sortByName,
      suppress: true,
    },
  ];

  return (
    <AutoSizer>
      {({ height, width }) => (
        <LegacyDataTable
          addButtonName="Balances"
          width={width}
          height={height}
          headers={Headers}
          list={filteredBalance}
          filterKey="name"
          filterTitle="Name"
          defaultSortBy="last_revision_at"
          defaultSortDirection="desc"
          filterRow={filterRow}
          filterPillbox={filterPillbox}
          isLoading={loadingState !== "loaded"}
          onAddClick={props.haveWritePermission ? onAddClick : null}
          onCellClick={(rowData) => onViewClick(rowData.id)}
          highlightRow={true}
        />
      )}
    </AutoSizer>
  );
};

BalancesTable.propTypes = {
  haveWritePermission: PropTypes.bool.isRequired,
  streams: PropTypes.array.isRequired,
};

export default BalancesTable;
