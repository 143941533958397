import { z } from "zod";
import { DomainModelMetaSchema, DomainModelSchema } from "./DomainModelSchemas";

export const FormCategorySchema = z
  .object({
    company_id: z.string(),
    name: z.string(),
    archived: z.boolean(),
  })
  .merge(DomainModelSchema)
  .merge(DomainModelMetaSchema)
  .describe("Represents a Form Category in the Node API");

export type FormCategoryType = z.infer<typeof FormCategorySchema>;
