import Immutable from "immutable";

import {
  FETCH_USER_PROFILE,
  FETCH_USER_PROFILE_ERROR,
  USER_SIGNED_OUT,
} from "../constants/action-types";

export const Profile = Immutable.Record({
  id: undefined,
  name: undefined,
  username: undefined,
  email: undefined,
  phone_number: undefined,
  job_title: undefined,
  company_id: undefined,
  company_name: undefined,
  company_localization: undefined,
  sites: undefined,
  state: undefined,
  role: undefined,
  members: undefined,
  enabled_chain_of_custody_types: undefined,
  restrict_calibration_access: undefined,
  restricted_calibration_instrument_ids: undefined,
  digest_settings: undefined,
  timezone_preference: undefined,
});

export const ProfileState = Immutable.Record(
  {
    fetchError: false,
    fetchErrorMessage: "",
    fetchErrorStatus: "",
    data: new Profile(),
  },
  "ProfileState"
);

export default (state = new ProfileState(), action) => {
  let newData = state.data;

  switch (action.type) {
    case FETCH_USER_PROFILE:
      newData = new Profile(action.payload);

      return state.withMutations((s) => {
        s.data = newData;
        s.fetchError = false;
        s.fetchErrorMessage = "";
        s.fetchErrorStatus = "";
      });

    case FETCH_USER_PROFILE_ERROR:
      return state.withMutations((s) => {
        s.fetchError = true;
        s.fetchErrorMessage = action.payload.message;
        s.fetchErrorStatus = action.payload.status;
      });

    case USER_SIGNED_OUT:
      return state.withMutations((s) => {
        s.data = new Profile();
        s.fetchError = false;
        s.fetchErrorMessage = "";
        s.fetchErrorStatus = "";
      });

    default:
      return state;
  }
};
