import ResizePanels from "#common/Panel/ResizePanels";
import { Page, Panel } from "@validereinc/common-components";
import isEmpty from "lodash/isEmpty";
import sortBy from "lodash/sortBy";
import uniqWith from "lodash/uniqWith";
import * as PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { ClearQueryParam } from "../../../Routers/historyHelper";
import useQueryParams from "../../../Routers/useQueryParams";
import { SortListByType } from "../../../utils/sorters";
import { havePermission } from "../../Redux/reducers/permissions";
import RecordOfQualityService from "../../Services/RecordOfQualityService";
import AccountingPeriodSelectionPanel from "./AccountingPeriodSelectionPanel";
import ConfirmationModal from "./ConfirmationModal";
import "./RecordOfQuality.scss";
import { COMPONENT_QUALITIES } from "./RecordOfQualityConstants";
import Records from "./Records/Records";
import Results from "./Results/Results";

const DEFAULT_PANEL_WIDTH = [70, 30];

const HEIGHT_OFFSET = 105;

function getDisplayProperties(accountingPeriod) {
  return (
    accountingPeriod?.site_system?.display_properties ?? COMPONENT_QUALITIES
  );
}

const mapStateToProps = ({ permissions }) => {
  return {
    haveWritePermissions: havePermission(permissions)(
      "gas:records_of_quality",
      "write"
    ),
  };
};

function getLatestAccountingPeriod(accountingPeriods) {
  return SortListByType(accountingPeriods, "from", "asc").pop();
}

const useFetchAccountingPeriods = (updateAccountingPeriod) => {
  const [siteSystems, setSiteSystems] = useState([]);
  const [accountingPeriods, setAccountingPeriods] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    RecordOfQualityService.getAccountingPeriods()
      .then(({ data }) => {
        setAccountingPeriods(data);

        // Get all the available site systems
        const siteSystems = uniqWith(data, (valueA, valueB) => {
          return valueA.site_system.id === valueB.site_system.id;
        }).map((accountingPeriod) => accountingPeriod.site_system);

        const sortedSiteSystemByName = sortBy(siteSystems, "name");

        setSiteSystems(sortedSiteSystemByName);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [updateAccountingPeriod]);

  return [siteSystems, accountingPeriods, isLoading];
};

const RecordOfQuality = (props) => {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [updateAccountingPeriod, setUpdateAccountingPeriod] = useState(false);

  const [showReportModal, setShowReportModal] = useState(false);

  const [numberApprovalRequired, setNumApprovalRequired] = useState(null);

  const [siteSystems, accountingPeriods, isLoading] = useFetchAccountingPeriods(
    updateAccountingPeriod
  );

  const [inputs] = useState({ accounting_period: {} });
  const [queryParams, setQueryParams] = useQueryParams(inputs, {
    accounting_period: accountingPeriods,
  });
  const selectedAccountingPeriod = queryParams.accounting_period;
  // Get the latest accounting period will be the default
  useEffect(() => {
    if (isEmpty(selectedAccountingPeriod) && accountingPeriods.length) {
      const latestAccountingPeriod =
        getLatestAccountingPeriod(accountingPeriods);
      setQueryParams({ accounting_period: latestAccountingPeriod.id });
    }
  }, [selectedAccountingPeriod, accountingPeriods]);

  const onSiteSystemSelect = (site_system) => {
    const accountingPeriodForGivenSite = accountingPeriods.filter(
      (accountingPeriod) => accountingPeriod.site_system.id === site_system.id
    );

    const accountingPeriod = getLatestAccountingPeriod(
      accountingPeriodForGivenSite
    );

    setQueryParams({ accounting_period: accountingPeriod.id });

    setNumApprovalRequired(null);
  };

  const onAccountingPeriodSelect = (period) => {
    if (queryParams.accounting_period.id !== period.id) {
      // clear query filters
      ClearQueryParam();
      setQueryParams({ accounting_period: period.id });

      setNumApprovalRequired(null);
    }
  };

  const refreshAccountingPeriod = () => {
    setUpdateAccountingPeriod(!updateAccountingPeriod);
  };

  const isSelectedAccountingPeriodDisabled =
    selectedAccountingPeriod.is_locked || !props.haveWritePermissions;

  const displayProperties = getDisplayProperties(selectedAccountingPeriod);

  const height = props.height - HEIGHT_OFFSET;

  if (accountingPeriods.length > 0 || isLoading) {
    return (
      <Page
        title="Accounting Period"
        isLoading={isLoading}
        breadcrumbs={props.breadcrumbs}
      >
        <AccountingPeriodSelectionPanel
          siteSystems={siteSystems}
          accountingPeriods={accountingPeriods}
          selectedAccountingPeriod={selectedAccountingPeriod}
          numberApprovalRequired={numberApprovalRequired}
          onSiteSystemSelect={onSiteSystemSelect}
          onAccountingPeriodSelect={onAccountingPeriodSelect}
          setShowConfirmationModal={setShowConfirmationModal}
          haveWritePermissions={props.haveWritePermissions}
          isLoading={isLoading}
          setShowReportModal={setShowReportModal}
        />

        <ResizePanels
          height={height}
          defaultPanelWidth={DEFAULT_PANEL_WIDTH}
          panelWidthKey="resize--accountingPeriod"
        >
          <Panel style={{ overflow: "auto" }}>
            <Records
              accountingPeriod={selectedAccountingPeriod}
              height={height}
              updateAccountingPeriod={updateAccountingPeriod}
              setNumApprovalRequired={(numOfApprovalRequired) =>
                setNumApprovalRequired(numOfApprovalRequired)
              }
              refreshAccountingPeriod={refreshAccountingPeriod}
              hasWriteAccess={!isSelectedAccountingPeriodDisabled}
              displayProperties={displayProperties}
              location={props.location}
              showReportModal={showReportModal}
              setShowReportModal={setShowReportModal}
            />
          </Panel>

          <Panel style={{ overflow: "auto" }}>
            <Results
              height={height}
              accountingPeriod={selectedAccountingPeriod}
              updateAccountingPeriod={updateAccountingPeriod}
              displayProperties={displayProperties}
            />
          </Panel>
        </ResizePanels>

        {showConfirmationModal && (
          <ConfirmationModal
            accountingPeriod={selectedAccountingPeriod}
            show={showConfirmationModal}
            onConfirmationModalClose={() => {
              setShowConfirmationModal(false);
            }}
            refreshAccountingPeriod={refreshAccountingPeriod}
          />
        )}
      </Page>
    );
  } else {
    return (
      <div className="recordOfQuality__errorMessage">
        No accounting period is available
      </div>
    );
  }
};

RecordOfQuality.propTypes = {
  height: PropTypes.number.isRequired,
  haveWritePermissions: PropTypes.bool,
  location: PropTypes.object,
  breadcrumbs: PropTypes.array.isRequired,
};

export default connect(mapStateToProps, null)(RecordOfQuality);
