import React, { useState } from "react";
import * as PropTypes from "prop-types";
import "./SystemBalanceChart.scss";
import {
  XYPlot,
  VerticalBarSeries,
  XAxis,
  YAxis,
  HorizontalGridLines,
  Hint,
  ChartLabel,
} from "react-vis";
import { AutoSizer } from "react-virtualized";
import { getNumberColorBySign } from "#utils/styleCalculator";
import { getComponentShortForm } from "../../RecordOfQualityHelper";
import DropdownButton from "react-bootstrap/lib/DropdownButton";
import MenuItem from "react-bootstrap/lib/MenuItem";
import { PERCENTAGE_DIFFERENCE_KEY } from "./SystemBalanceConstant";

const CONTAINER_PADDING = 30;

const MAXIMUM_X_AXIS_STRING_CHAR = 8;

const CHART_OPTIONS = {
  percent_differences: "% Diff",
  recoveries: "Recovery",
};

function getTotalDifferencePlotData(data, view) {
  let value = 0;
  let title = "-";

  if (view === PERCENTAGE_DIFFERENCE_KEY) {
    title = "Total Percent";
    value = data?.percent_differences?.value ?? 0;
  } else {
    title = "Total Recovery";
    value = data?.recoveries?.value ?? 0;
  }

  return {
    x: title,
    y: value,
    label: value,
    color: getNumberColorBySign(value),
  };
}

function getComponentPlotData(data, displayProperties) {
  return [...displayProperties].map((component) => {
    const value = data[component]?.value ?? 0;

    return {
      x: component,
      y: value,
      label: value,
      color: getNumberColorBySign(value),
    };
  });
}

function getXAxisTick(tick, componentVolumeConfig) {
  if (tick === "Total Percent" || tick === "Total Recovery") {
    return "Total";
  } else {
    return getComponentShortForm(tick, componentVolumeConfig).substring(
      0,
      MAXIMUM_X_AXIS_STRING_CHAR
    );
  }
}

const SystemBalance = (props) => {
  const [view, setView] = useState(PERCENTAGE_DIFFERENCE_KEY);
  const [hintData, setHintData] = useState(null);
  const percentDifferences = {
    ...(props.components?.percent_differences ?? {}),
    ...(props.qualities?.percent_differences ?? {}),
  };

  const recoveries = {
    ...(props.components?.recoveries ?? {}),
    ...(props.qualities?.recoveries ?? {}),
  };

  const totalDifferencePlotData = getTotalDifferencePlotData(
    props.totalDifference,
    view
  );

  const componentPlotData = getComponentPlotData(
    view === PERCENTAGE_DIFFERENCE_KEY ? percentDifferences : recoveries,
    props.displayProperties
  );

  const hintFormat = (data) => {
    return [
      {
        title: "Property",
        value: data.x,
      },
      {
        title: "Value",
        value: `${data.y} %`,
      },
    ];
  };

  const onDropdownSelect = (eventKey) => {
    setView(eventKey);
  };

  return (
    <div className="systemBalanceChartContainer">
      <div className="systemBalanceChart__selectionRow">
        <DropdownButton
          className=""
          title={CHART_OPTIONS[view]}
          onSelect={onDropdownSelect}
          id={`dropdown-basic`}
        >
          {Object.keys(CHART_OPTIONS).map((option) => (
            <MenuItem
              key={option}
              eventKey={option}
            >
              {CHART_OPTIONS[option]}
            </MenuItem>
          ))}
        </DropdownButton>
      </div>

      <AutoSizer>
        {({ width, height }) => (
          <XYPlot
            width={width}
            height={Math.max(height - CONTAINER_PADDING, 0)}
            xType="ordinal"
            colorType="literal"
            margin={{ left: 60 }}
          >
            <HorizontalGridLines />

            <VerticalBarSeries
              data={[totalDifferencePlotData, ...componentPlotData]}
              barWidth={0.2}
              onValueMouseOver={(value) => setHintData(value)}
              onValueMouseOut={() => setHintData(null)}
            />

            <XAxis
              tickFormat={(tick) =>
                getXAxisTick(tick, props.componentVolumeConfig)
              }
            />

            <YAxis />

            <ChartLabel
              text="Percent (%)"
              xPercent={0.03}
              yPercent={0.4}
              style={{ transform: "rotate(-90)" }}
            />

            {hintData && (
              <Hint
                value={hintData}
                format={hintFormat}
                style={{
                  width: "200px",
                }}
              />
            )}
          </XYPlot>
        )}
      </AutoSizer>
    </div>
  );
};

SystemBalance.propTypes = {
  components: PropTypes.object,
  qualities: PropTypes.object,
  totalDifference: PropTypes.object,
  componentVolumeConfig: PropTypes.object,
  displayProperties: PropTypes.array.isRequired,
};

export default SystemBalance;
