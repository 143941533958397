import { Page } from "@validereinc/common-components";
import * as PropTypes from "prop-types";
import React from "react";

import { useFacilities } from "#hooks/useFacilities";
import config from "#src/config";
import { getUTCTimeString } from "#utils/timeFormatter";
import moment from "moment";
import EmissionRecordsDateSelector from "./EmissionRecordsDateSelector";
import EmissionRecordsPanel from "./EmissionRecordsPanel";

const FIRST_DAY_OF_CURRENT_MONTH = moment().startOf("month").startOf("day");
const LAST_DAY_OF_CURRENT_MONTH = moment().endOf("month").startOf("day");
const DEFAULT_DATE_RANGE = {
  from: getUTCTimeString(FIRST_DAY_OF_CURRENT_MONTH, config.DATE_FORMAT),
  until: getUTCTimeString(LAST_DAY_OF_CURRENT_MONTH, config.DATE_FORMAT),
};

const EmissionRecords = ({ height, match, breadcrumbs }) => {
  const [facilities, facilitiesLoadingState] = useFacilities();

  return (
    <Page
      title="Emission Records"
      breadcrumbs={breadcrumbs}
    >
      <EmissionRecordsDateSelector defaultDateRange={DEFAULT_DATE_RANGE} />

      <EmissionRecordsPanel
        isFacilitiesLoaded={facilitiesLoadingState !== "loading"}
        defaultDateRange={DEFAULT_DATE_RANGE}
        facilities={facilities}
        height={height}
        match={match}
      />
    </Page>
  );
};

EmissionRecords.propTypes = {
  height: PropTypes.number.isRequired,
  match: PropTypes.object.isRequired,
  breadcrumbs: PropTypes.array.isRequired,
};

export default EmissionRecords;
