import { z } from "zod";

export const DomainModelSchema = z.object({
  /** unique ID of the domain model */
  id: z.string().uuid(),
});

export const DomainModelMetaSchema = z.object({
  /** when was this model created? (will be an ISO UTC date string) */
  created_at: z.string().datetime(),
  /** when was this model updated? (will be an ISO UTC date string) */
  updated_at: z.string().datetime(),
});

export const DomainModelMetaExtendedSchema = z.object({
  /** who created this model? (will be a UUID of a user model) */
  created_by: z.string().uuid().nullable(),
  /** who updated this model? (will be a UUID of a user model) */
  updated_by: z.string().uuid().nullable(),
});

export const SearchModelMetaSchema = z.object({
  /** The current page number */
  page: z.number(),
  /** How many entries per page */
  pageSize: z.number(),
  /** Determines how the table is sorted based on given properties and asc/desc */
  sortDirection: z.enum(["desc", "asc"]),
  /** Determines how the table is sorted */
  sortBy: z.string(),
});

export const AssetCustomAttributeSchema = z.record(
  z.string(),
  z.string().or(z.number())
);

export const IdAndNameSchema = z.object({
  id: z.string().uuid(),
  name: z.string(),
});

export type DomainModel = z.infer<typeof DomainModelSchema>;
export type DomainModelMeta = z.infer<typeof DomainModelMetaSchema>;
export type DomainModelMetaExtended = z.infer<
  typeof DomainModelMetaExtendedSchema
>;
export type SearchModelMeta = z.infer<typeof SearchModelMetaSchema>;
