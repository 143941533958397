import {
  CUBIC_METERS_VOLUME,
  getFormattedMeasurementValueWithUnit,
} from "#redux/reducers/measurements";
import {
  LegacyDataTable,
  Title,
  Tooltip,
} from "@validereinc/common-components";
import * as PropTypes from "prop-types";
import React from "react";
import FontAwesome from "react-fontawesome";
import { connect } from "react-redux";
import { AutoSizer } from "react-virtualized";
import { isValidFloatwDecimal } from "../../../../../../Instrument/InstrumentComponentHelper";
import { getComponentShortForm } from "../../../../RecordOfQualityHelper";
import { useSelectionModalContext } from "../../../Context/selectionModalContext";
import "./RecordOfQualityResultTable.scss";

const ROW_HEIGHT = 45;
const COMPONENT_VALUE_WIDTH = 130;

const mapStateToProps = (state) => {
  return {
    getFormattedMeasurementValueWithUnit: (measurementKey, measurementObject) =>
      getFormattedMeasurementValueWithUnit(state.measurements)(
        measurementKey,
        measurementObject
      ),
  };
};

const AverageRoqToolTip =
  "The average is calculated from the previous 4 RoQ (not including current one)";

function getQualityColumns(
  balanceType,
  currentRecordOfQuality,
  historicalRecordOfQuality,
  volumeType
) {
  const qualityColumns = {};

  qualityColumns["Current RoQ"] = {
    [volumeType]:
      balanceType === "gas"
        ? currentRecordOfQuality?.gas_equivalent_volume
        : currentRecordOfQuality?.raw_volume ?? "-",
    ...currentRecordOfQuality.qualities,
  };

  qualityColumns["Previous RoQ"] = {
    [volumeType]: historicalRecordOfQuality.previous?.volume,
    ...historicalRecordOfQuality.previous?.qualities,
  };

  qualityColumns["Average RoQ"] = {
    [volumeType]: historicalRecordOfQuality.average?.volume,
    ...historicalRecordOfQuality.average?.qualities,
  };

  return qualityColumns;
}

function getQualityColumnHeader(header) {
  if (header === "Average RoQ") {
    return (
      <Tooltip
        content={AverageRoqToolTip}
        width={200}
      >
        {header}
        <FontAwesome
          name="info-circle"
          style={{ marginLeft: "3px" }}
        />
      </Tooltip>
    );
  }
  return header;
}

const RecordOfQualityResultTable = (props) => {
  const { historicalData, recordOfQualityDetail, displayProperties } =
    useSelectionModalContext();

  const volumeType = props.balanceType === "gas" ? "GEV" : "Total Volume";

  const qualityColumns = getQualityColumns(
    props.balanceType,
    recordOfQualityDetail,
    historicalData,
    volumeType
  );

  const priceRenderer = (component) => {
    const priceValue =
      recordOfQualityDetail.accounting_period.component_prices?.[component];

    if (priceValue?.value) {
      const { symbol, value, currency, unit } = priceValue;

      return (
        <div className="recordOfQualityResultTable__row">
          {`${symbol}${isValidFloatwDecimal(
            value,
            2,
            "-"
          )} ${currency} / ${unit}`}
        </div>
      );
    } else {
      return "-";
    }
  };

  const componentValueRenderer = (component, column) => {
    const componentValue = qualityColumns?.[column]?.[component];

    let measurementKey = component;
    if (component == "GEV" || component === "Total Volume") {
      measurementKey = CUBIC_METERS_VOLUME;
    }

    if (componentValue?.value) {
      return (
        <div className="recordOfQualityResultTable__row">
          {props.getFormattedMeasurementValueWithUnit(
            measurementKey,
            componentValue
          )}
        </div>
      );
    } else {
      return <div className="recordOfQualityResultTable__row--empty">-</div>;
    }
  };

  const headers = [
    {
      label: "Component",
      key: "name",
      width: 110,
      cellRenderer: getComponentShortForm,
      disableSort: true,
      fixed: true,
    },

    {
      label: "Price",
      key: "price",
      width: COMPONENT_VALUE_WIDTH,
      cellRenderer: priceRenderer,
      disableSort: true,
      rightAlign: true,
    },

    ...Object.keys(qualityColumns).map((column) => ({
      label: getQualityColumnHeader(column),
      key: column,
      width: COMPONENT_VALUE_WIDTH,
      cellRenderer: componentValueRenderer,
      disableSort: true,
      rightAlign: true,
    })),
  ];

  return (
    <div className="recordOfQualityResultTable">
      <Title
        type="subheader"
        className="recordOfQualityResultTable__title"
      >
        Component Composition
      </Title>

      <AutoSizer>
        {({ width, height }) => (
          <LegacyDataTable
            list={[volumeType, ...displayProperties]}
            headers={headers}
            height={height}
            width={width}
            rowHeight={ROW_HEIGHT}
          />
        )}
      </AutoSizer>
    </div>
  );
};

RecordOfQualityResultTable.propTypes = {
  balanceType: PropTypes.string,
  getFormattedMeasurementValueWithUnit: PropTypes.func,
};

export default connect(mapStateToProps)(RecordOfQualityResultTable);
