import { RecordValueSourceConfigurationPage } from "#batteries-included-components/Layouts/RecordConfiguration";
import { useNavigate, useParams } from "#routers/hooks";
import { useFlowDetailFlow } from "#routes/organization/flows/[flowId]/detail/FlowDetailPage.helpers";
import {
  FlowRecordValueDetailRoute,
  FlowRecordValuePageParamsType,
} from "#routes/organization/flows/[flowId]/detail/record/[recordId]/value/[measurementType]";
import { FlowRecordValueSourceRoute } from "#routes/organization/flows/[flowId]/detail/record/[recordId]/value/[measurementType]/source/index";
import { RecordContext, RecordProvider } from "#src/contexts/RecordContext";
import { useStorageKey } from "#src/hooks/useStorageKey";
import { useBreadcrumbsFromRoute } from "#utils/route";
import { useQuery } from "@tanstack/react-query";
import { MeasurementsDomain } from "@validereinc/domain";
import { yearMonthName } from "@validereinc/utilities";
import React, { useContext } from "react";

export const FlowRecordValueSourcePageContent = () => {
  const navigate = useNavigate();
  const { filterConfigStorageKey } = useStorageKey("flow-details-filters");
  const params = useParams<FlowRecordValuePageParamsType>();

  const { data: flow } = useFlowDetailFlow({ filterConfigStorageKey });
  const { record } = useContext(RecordContext) || {};

  const { data: measurementTypes } = useQuery({
    queryKey: ["measurementTypes"],
    queryFn: MeasurementsDomain.listMeasurementTypes,
  });

  const measurementType = measurementTypes?.find(
    ({ id }) => id === params.measurementType
  );

  const recordPageTitle = record
    ? `${yearMonthName(record.year_month)} Record`
    : FlowRecordValueSourceRoute.previous?.previous?.title;

  const recordValuePageTitle = record
    ? `${yearMonthName(record.year_month)} ${measurementType?.name}`
    : FlowRecordValueSourceRoute.previous?.title;

  const pageTitle = measurementType
    ? `Configure Source`
    : FlowRecordValueSourceRoute.title;

  const [breadcrumbs] = useBreadcrumbsFromRoute(FlowRecordValueSourceRoute, {
    detail: { title: flow?.name },
    recordId: { title: recordPageTitle },
    measurementType: { title: recordValuePageTitle },
    source: { title: pageTitle },
  });

  return (
    <RecordValueSourceConfigurationPage
      pageProps={{
        title: recordValuePageTitle,
        category: "Configure Source",
        breadcrumbs,
      }}
      onClose={() => {
        navigate(
          FlowRecordValueDetailRoute.toLinkParts({ pathParams: params })
        );
      }}
    />
  );
};

export const FlowRecordValueSourcePage = () => (
  <RecordProvider>
    <FlowRecordValueSourcePageContent />
  </RecordProvider>
);
