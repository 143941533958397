import {
  apiRequestServicesPromise,
  apiDownloadServicesPromise,
  openEmailClient,
} from "./ServiceHelper";
import config from "../../config";

const CONTACT = "analytics@validere.com";

const InsightService = {
  getInsightList() {
    return apiRequestServicesPromise("/api/v1/insights");
  },

  getInsightPDFReport(id) {
    const INSIGHT_LIST_URL = `${config.API_URL}/api/v1/insights`;

    const parameter = {
      insight_record_id: id,
    };

    return apiDownloadServicesPromise(INSIGHT_LIST_URL, parameter);
  },

  openEmailClient(subject) {
    openEmailClient(CONTACT, subject);
  },
};

export default InsightService;
