import { rest } from "msw";
import {
  ReportingGroupDomainServiceType,
  ReportingGroupType,
} from "../adapters";
import { DomainConfig } from "../config";
import {
  BuildMockHandlersType,
  getApiUrlWithEndpoint,
} from "../mocks/handlers";
import { DomainFaker, DomainMock } from "../mocks/utils";
import { ReportingGroupSchema } from "../schemas";
import { GetListResponseType } from "../util";

export const reportingGroupMock = new DomainMock(
  ReportingGroupSchema,
  () => {
    const createdAt = DomainFaker.en.date.past();

    return {
      id: DomainFaker.en.string.uuid(),
      name: DomainFaker.en.word.words(),
      description: DomainFaker.en.lorem.sentences(2),
      priority: DomainFaker.en.number.int(1000),
      company_id: DomainFaker.en.string.uuid(),
      created_at: DomainFaker.en.date.past().toISOString(),
      updated_at: DomainFaker.en.date
        .between({ from: createdAt, to: new Date() })
        .toISOString(),
    };
  },
  true
).fix();

export const reportingGroupMockBadPriority = new DomainMock(
  ReportingGroupSchema,
  () => {
    const createdAt = DomainFaker.en.date.past();

    return {
      id: DomainFaker.en.string.uuid(),
      name: DomainFaker.en.word.words(),
      description: DomainFaker.en.lorem.sentences(2),
      priority: DomainFaker.en.number.int({ min: 1, max: 10 }) * -1,
      company_id: DomainFaker.en.string.uuid(),
      created_at: DomainFaker.en.date.past().toISOString(),
      updated_at: DomainFaker.en.date
        .between({ from: createdAt, to: new Date() })
        .toISOString(),
    };
  },
  false
).fix();

export const buildReportingGroupHandlers: BuildMockHandlersType<
  ReportingGroupDomainServiceType
> = (configData: DomainConfig) => {
  const { nodeURL: url } = configData;

  return {
    getList: rest.get<
      never,
      {
        page: string;
        pageSize: string;
      },
      GetListResponseType<ReportingGroupType>
    >(
      getApiUrlWithEndpoint({ url, version: 1, endpoint: "/reporting_groups" }),
      (req, res, ctx) => {
        const page = Number(req.url.searchParams.get("page"));
        const pageSize = Number(req.url.searchParams.get("pageSize"));
        const data = new Array(30)
          .fill(0)
          .map(() => reportingGroupMock.generate());

        return res(
          ctx.status(200),
          ctx.json({
            data,
            page_number: page,
            page_size: pageSize,
            total_entries: data.length,
            total_pages: 1,
          })
        );
      }
    ),
    getOne: rest.get(
      getApiUrlWithEndpoint({
        url,
        version: 1,
        endpoint: "/reporting_groups/:id",
      }),
      (req, res, ctx) => {
        const { id } = req.params;
        const mockData = reportingGroupMock.mock;

        if (typeof id !== "string") {
          return res(ctx.status(400));
        }

        mockData.id = id;

        return res(ctx.status(200), ctx.json(mockData));
      }
    ),
  };
};
