import React, { useMemo } from "react";

import { DataTable, StorageKeys } from "@validereinc/common-components";

import { FlowDetailRoute } from "../.";

import { useListFlowTypes } from "#hooks/adapters/useFlows";
import { useFlowDetailFlow } from "#routes/organization/flows/[flowId]/detail/FlowDetailPage.helpers";
import { RoutingLink } from "#src/batteries-included-components/RoutingLink";
import type { FlowType } from "@validereinc/domain";

export const FlowFlowsTable = ({
  variant,
  filterConfigStorageKey,
}: {
  variant: "upstream" | "downstream";
} & Pick<StorageKeys, "filterConfigStorageKey">) => {
  const { data: flow } = useFlowDetailFlow({ filterConfigStorageKey });

  const flowData = useMemo(() => flow?.[`${variant}_flows`], [flow]);
  const { data: flowTypes } = useListFlowTypes();

  return (
    <DataTable
      items={flowData}
      headers={[
        {
          label: "Name",
          key: "name",
          renderComponent: ({ item: { id, name } }: { item: FlowType }) => (
            <RoutingLink
              to={FlowDetailRoute.toLink({ pathParams: { flowId: id } })}
            >
              {name}
            </RoutingLink>
          ),
        },
        {
          label: "Type",
          key: "type",
          renderComponent: ({ item: { type } }: { item: FlowType }) =>
            flowTypes?.find(({ id }) => id === type)?.name ?? "-",
        },
      ]}
    />
  );
};
