const VAPOUR_PRESSURE = "Vapour Pressure";

const displayProperties = () => [
  {
    label: VAPOUR_PRESSURE,
    key: VAPOUR_PRESSURE,
  },
  {
    label: "Method",
    key: "method",
    notMeasurement: true,
  },
  {
    label: "D5191 DVPE",
    key: "d5191_dvpe_in_kpa",
    measurementKey: VAPOUR_PRESSURE,
  },
  {
    label: "D4953 DVPE",
    key: "d4953_dvpe_in_kpa",
    measurementKey: VAPOUR_PRESSURE,
  },
  {
    label: "D323 RVPE",
    key: "d323_rvpe_in_kpa",
    measurementKey: VAPOUR_PRESSURE,
  },
  {
    label: "Filling Temp.",
    key: "filling_temperature_in_c",
    unit: "℃",
    notMeasurement: true,
  },
  {
    label: "Gas Correction Pressure",
    key: "gas_correction_pressure_in_kpa",
    decimal: "3",
    unit: "kPa",
    notMeasurement: true,
  },
  {
    label: "Pabs",
    key: "pabs_in_kpa",
    decimal: "3",
    unit: "kPa",
    notMeasurement: true,
  },
  {
    label: "Pressure",
    key: "pressure_in_kpa",
    decimal: "3",
    unit: "kPa",
    notMeasurement: true,
  },
  {
    label: "Repetitions",
    key: "repetitions",
    notMeasurement: true,
  },
  {
    label: "Rinsing Cycles",
    key: "rinsing_cycles",
    notMeasurement: true,
  },
];

export default displayProperties;
