import { useSearchParams } from "#routers/hooks";
import {
  AssociatedFlowTablePanel,
  PeriodSelector,
} from "#src/batteries-included-components";
import { IsFeatureAvailable } from "#src/batteries-included-components/Layouts/Authorization/IsFeatureAvailable";
import { AssetType, FacilityType } from "@validereinc/domain";
import React, { useEffect } from "react";
import { DevicesPanel } from "./DevicesPanel";
import EntityFilterPanel from "./EntityFilterPanel";
import FacilityDetailOverviewTab from "./FacilityDetailOverviewTab";
import { FacilityEquipmentPanel } from "./FacilityEquipmentPanel";

// IMPROVE: this component and sub-components need some cleanup
export const FacilityOverviewTab = ({
  facilityDetail,
  filterKey = "facility_detail_filter",
}: {
  facilityDetail?: FacilityType;
  filterKey?: string;
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    setSearchParams({ section: AssetType.EQUIPMENT, ...searchParams });
  }, []);

  return (
    <>
      <PeriodSelector filterKey={filterKey} />
      <FacilityDetailOverviewTab facilityDetail={facilityDetail} />

      <EntityFilterPanel />

      {facilityDetail &&
      (searchParams.section === AssetType.EQUIPMENT ||
        !searchParams.section) ? (
        <FacilityEquipmentPanel filterKey={filterKey} />
      ) : null}

      {facilityDetail && searchParams.section === AssetType.DEVICE ? (
        <IsFeatureAvailable
          featureFlagQuery="core:devices"
          permissionQuery="devices:read"
          fallbackChildren={null}
        >
          <DevicesPanel />
        </IsFeatureAvailable>
      ) : null}

      {facilityDetail && searchParams.section === AssetType.FLOW ? (
        <IsFeatureAvailable
          featureFlagQuery="core:flows"
          permissionQuery="flows:read"
          fallbackChildren={null}
        >
          <AssociatedFlowTablePanel
            id={facilityDetail.id}
            type="facility"
          />
        </IsFeatureAvailable>
      ) : null}
    </>
  );
};
