import NotesList from "#common/Notes/NotesList";
import { ACCOUNTING_RECORD_NOTE, useSubmitNote } from "#services/NotesService";
import { SortListByType } from "#utils/sorters";
import {
  Form,
  FormButton,
  Modal,
  ReactLoader,
  TextWithFileInput,
  useForm,
} from "@validereinc/common-components";
import * as PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import { AutoSizer } from "react-virtualized";
import "./RecordOfQualityNotes.scss";

const RecordOfQualityNotes = ({
  accountingRecordId,
  modalBodyHeight,
  isLoading,
  notes,
  fetchNotes,
}) => {
  const [formState, setFormState] = useState("enabled");

  const form = useForm({
    defaultValues: {
      note: {},
    },
  });

  const [_isSubmittingNote, submitNote] = useSubmitNote(
    accountingRecordId,
    ACCOUNTING_RECORD_NOTE
  );
  const [clearInputs, setClearInputs] = useState(false);

  useEffect(() => {
    setClearInputs(!clearInputs);
  }, [accountingRecordId]);

  const onSubmitNote = (inputs) => {
    setFormState("loading");

    return submitNote(inputs.note)
      .then((result) => {
        fetchNotes();

        return result;
      })
      .finally(() => {
        form.reset();
        setFormState("enabled");
      });
  };

  const sortedNotes = useMemo(() => {
    return notes ? SortListByType(notes, "date", "desc") : [];
  }, [notes]);

  /**
   * NotesList only being rendered when active because dynamic row heights need
   * it to be visible (inactive are display:none) to be measured, else 0 height
   */
  return (
    <Form
      onSubmit={onSubmitNote}
      {...form}
    >
      <Modal.Body
        className="recordOfQualityNotes"
        style={{ height: modalBodyHeight, padding: 0 }}
      >
        <div className="recordOfQualityNotes__listContainer">
          <AutoSizer disableWidth>
            {({ height }) => (
              <ReactLoader
                loaded={!isLoading}
                position={50}
              >
                <NotesList
                  list={sortedNotes}
                  height={height}
                />
              </ReactLoader>
            )}
          </AutoSizer>
        </div>

        <TextWithFileInput
          name="note"
          label=""
          isRequired
          showIcon
          multiple
          isDisabled={formState !== "enabled"}
        />
      </Modal.Body>

      <Modal.Footer>
        <FormButton
          variant="primary"
          type="submit"
          isLoading={formState === "loading"}
          disabled={formState !== "enabled" || !accountingRecordId}
        >
          Add Note
        </FormButton>
      </Modal.Footer>
    </Form>
  );
};

RecordOfQualityNotes.propTypes = {
  accountingRecordId: PropTypes.string.isRequired,
  modalBodyHeight: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired,
  notes: PropTypes.arrayOf(PropTypes.object).isRequired,
  fetchNotes: PropTypes.func.isRequired,
};

export default RecordOfQualityNotes;
