import cloneDeep from "lodash/cloneDeep";
import get from "lodash/get";
import orderBy from "lodash/orderBy";
import set from "lodash/set";

/**
 * @deprecated
 * @param {{ item, value }} param
 * @param {(string|string[])} param.item The filters to be applied
 * @param {string} param.value The string to be checked
 * @returns {boolean} does the value match (or is included) in filter item
 */
export const defaultFilterFunction = ({ value, item }) => {
  if (Array.isArray(item)) {
    if (Array.isArray(value)) {
      return item.some((element) => value.includes(element));
    } else {
      return item.includes(value);
    }
  }
  return item === value || value?.includes(item);
};

/**
 * An adaptor that takes a fetch request that returns all data and restructures it so it resembles
 * a paginated request.
 *
 * @deprecated this should be done in the @validereinc/domain package's adapters
 * @param {{filterMapping: Object, query: Object, itemsKey: string, data: Object}} Object
 * @param {{status: number, ETag, data}} Object.data The fetch response
 * @param {string} Object.itemsKey The dot notation of where the array of items are found in the fetch response
 * @param {Object} Object.query The object representation of the query params
 * @param {Object} Object.filterMapping An object with properties matching query params, with each property
 *    setting an optional `itemKey` (dot notation where to find value), `filter` (given entire object
 *    in `item` and filter `value`, should the item stay in results), and `sort` (function to determine order)
 * @returns {{status: number, ETag, data}} returns the fetch response structured in a way that matches
 *    backend pagination responses { data: { data: [paginatedItems], page_number, page_size, total_entries,
 *    allItems: item[], filteredItems: item[] } }
 */
export const getFrontendTableState = ({
  filterMapping,
  query,
  itemsKey,
  data,
}) => {
  const {
    sort,
    sortDirection,
    page = 1,
    rowPerPage = 10,
    ...restQuery
  } = query;

  const newData = cloneDeep(data);

  const items = get(newData, itemsKey);

  if (items?.length) {
    const filters = Object.entries(restQuery);

    const filtered = items.reduce((total, current) => {
      const shouldInclude = filters.every(([dataKey, value]) => {
        const filterItemKey = filterMapping?.[dataKey]?.itemKey;

        if (
          Object.prototype.hasOwnProperty.call(current, dataKey) ||
          filterItemKey
        ) {
          const filterFunction =
            filterMapping?.[dataKey]?.filter ?? defaultFilterFunction;

          return filterFunction({
            value: filterItemKey
              ? get(current, filterItemKey)
              : current[dataKey],
            item: value,
            dataKey,
          });
        } else {
          // A dataKey (query param) that is not included in subscription or filterMapping
          // does not affect what's shown in table
          return true;
        }
      });

      return shouldInclude ? [...total, current] : total;
    }, []);

    const sortFunction = filterMapping?.[sort]?.sort
      ? (a, b) => {
          const ascending = filterMapping?.[sort]?.sort(a, b);

          return sortDirection === "asc" ? ascending : ascending * -1;
        }
      : undefined;

    const sortedColumns =
      sort && sortDirection
        ? sortFunction
          ? filtered.sort(sortFunction)
          : orderBy(filtered, [(row) => get(row, sort)], [sortDirection])
        : filtered;

    const maxPage = Math.ceil(filtered?.length / rowPerPage);

    const pageValue = page > maxPage ? maxPage : page;

    const paginatedItems = sortedColumns.slice(
      (pageValue - 1) * rowPerPage,
      pageValue * rowPerPage
    );

    set(newData, itemsKey, {});

    newData.data.data = paginatedItems;
    newData.data.page_number = Number(page);
    newData.data.page_size = Number(rowPerPage);
    newData.data.total_entries = sortedColumns.length;
    newData.data.filteredItems = sortedColumns;
    newData.data.allItems = items;

    return newData;
  } else {
    const newData = {
      data: { page_number: page, page_size: rowPerPage, total_entries: 0 },
    };

    set(newData, itemsKey, []);

    return newData;
  }
};
