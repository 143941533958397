import { RoutePath } from "#utils/route";
import {
  FlowDetailParamsType,
  FlowDetailRoute,
} from "#routes/organization/flows/[flowId]/detail";

export type FlowEstimationMethodDetailParamsType = FlowDetailParamsType & {
  estimationMethodId: string;
};
export const FlowEstimationMethodDetailRoute = FlowDetailRoute.concat(
  new RoutePath<"estimationMethodId">({
    path: "/estimation-details/:estimationMethodId",
    title: "Estimation Method Details",
  })
);
