import { validereApi } from "./ServiceHelper";

const annotationConfig = {
  timeout: 45000,
};

export function getAnnotationsQueue() {
  return validereApi
    .get(`/api/tests/annotations/queue`, annotationConfig)
    .then((response) => ({
      data: response.data,
      status: response.status,
      ETag: response.headers.etag,
    }));
}

export function getAnnotationDetails(testId) {
  return validereApi
    .get(`/api/tests/annotations/${testId}`, annotationConfig)
    .then((response) => ({
      data: response.data,
      status: response.status,
      ETag: response.headers.etag,
    }));
}

export function submitAnnotation(testId, payload) {
  return validereApi
    .post(`/api/tests/${testId}/annotate`, payload, annotationConfig)
    .then((response) => ({
      status: response.status,
      data: response.data,
    }));
}
