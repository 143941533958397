import {
  SummaryInformation,
  SummaryInformationContainer,
} from "#components/Common/SummaryInformation";
import Map from "#components/Map/Map";
import Marker from "#components/Map/Marker/Marker";
import { PeriodSelector } from "#src/batteries-included-components";
import config from "#src/config";
import { getTimeStringFromDate } from "#utils/timeFormatter";
import { Panel, StorageKeys } from "@validereinc/common-components";
import { DeviceType } from "@validereinc/domain";
import classNames from "classnames/bind";
import React from "react";
import OverviewPanel from "./OverviewPanel";
import styles from "./OverviewPanel.module.scss";

const cx = classNames.bind(styles);

const DeviceDetailOverviewTab = ({
  deviceDetail,
  isLoading,
  filterConfigStorageKey,
}: {
  deviceDetail?: DeviceType;
  isLoading: boolean;
} & Pick<StorageKeys, "filterConfigStorageKey">) => {
  const deviceLocation = deviceDetail?.attributes?.location;
  const initialViewState = {
    latitude: deviceLocation?.[0],
    longitude: deviceLocation?.[1],
  };

  const sharedProps = {
    type: "vertical",
    isLoading,
    allowWrap: true,
    isStatusPanel: true,
  };

  const isDisplayMap = deviceLocation && !isLoading;

  return (
    <>
      <PeriodSelector filterKey={filterConfigStorageKey} />
      <div className={cx("overviewAndMapSection")}>
        <Panel isFluidY={false}>
          <SummaryInformationContainer
            variant="vertical"
            status={deviceDetail?.status}
            statusVariant={deviceDetail?.status}
          >
            <SummaryInformation
              {...sharedProps}
              title="Created At"
              value={getTimeStringFromDate(
                deviceDetail?.created_at,
                config.DATETIME_FORMAT_READABLE
              )}
            />
            <SummaryInformation
              {...sharedProps}
              title="Updated At"
              value={getTimeStringFromDate(
                deviceDetail?.updated_at,
                config.DATETIME_FORMAT_READABLE
              )}
            />
          </SummaryInformationContainer>
        </Panel>
        <OverviewPanel
          deviceDetail={deviceDetail}
          isLoading={isLoading}
          isDisplayMap={isDisplayMap}
        />
        {isDisplayMap ? (
          <Map
            initialViewState={initialViewState}
            subtractFromHeightOnResize={16}
          >
            <Marker
              latitude={initialViewState.latitude}
              longitude={initialViewState.longitude}
              iconType="broadcast"
              size="large"
              label={deviceDetail.name}
            />
          </Map>
        ) : null}
      </div>
    </>
  );
};

export default DeviceDetailOverviewTab;
