import React, { createContext, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { ensureStreamListIsFetched } from "#redux/actions/index";
import { addAlertMessage } from "#redux/actions/alertMessages";
import useManageRecordSelection from "./useManageRecordSelection";

export const RecordSelectionContext = createContext();

const mapStateToProps = ({ streams }) => ({
  streams: streams.data?.toJS() ?? [],
});

const mapDispatchToProps = {
  ensureStreamListIsFetched,
  addAlertMessage,
};

const RecordSelectionProvider = ({
  children,
  streams,
  accountingRecordDetail,
  updateRecordDetail,
  ensureStreamListIsFetched,
  addAlertMessage,
}) => {
  const recordDataAndFunctions = useManageRecordSelection({
    accountingRecordDetail,
    updateRecordDetail,
    streams,
    ensureStreamListIsFetched,
    addAlertMessage,
  });

  useEffect(() => {
    ensureStreamListIsFetched();
  }, []);

  return (
    <RecordSelectionContext.Provider
      value={{
        ...recordDataAndFunctions,
      }}
    >
      {children}
    </RecordSelectionContext.Provider>
  );
};

export const useRecordSelectionContext = () =>
  useContext(RecordSelectionContext);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RecordSelectionProvider);

RecordSelectionProvider.propTypes = {
  children: PropTypes.node.isRequired,
  streams: PropTypes.array.isRequired,
  accountingRecordDetail: PropTypes.object.isRequired,
  updateRecordDetail: PropTypes.func.isRequired,
  ensureStreamListIsFetched: PropTypes.func.isRequired,
  addAlertMessage: PropTypes.func,
};
