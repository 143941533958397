import config from "#src/config";
import { getTimeStringFromDate } from "#utils/timeFormatter";
import { Panel, Tooltip } from "@validereinc/common-components";
import React from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { Link, Router } from "react-router-dom";
import { AutoSizer, Column } from "react-virtualized";
import history from "../../../../Routers/history";
import {
  getActivityColour,
  getActivityIcon,
} from "../../../../utils/styleCalculator";
import CustomTable from "../../../Common/Table/CustomTable";
import ActivityCell from "./ActivityCell";
import "./ActivityTable.scss";
import { getActivityLink } from "./activityTableLinks";

/* eslint-disable react/prop-types */

const TABLE_HEIGHT_OFFSET = 200;

const MIN_TABLE_HEIGHT = 150;

const formatCityAndCountry = (city, country) => {
  if (city) {
    return `${city}, ${country}`;
  } else if (country) {
    return country;
  } else {
    return "Unknown";
  }
};

const formatLatLong = (latitude, longitude) => {
  if (latitude && longitude) {
    return `Lat: ${latitude}, Long: ${longitude}`;
  } else {
    return "Unknown Latitude/Longitude";
  }
};

const activityRenderer = ({ rowData }) => {
  const { resource_type, resource_ids, type, action_type, message } = rowData;

  const links = resource_ids
    .map((id) => getActivityLink(resource_type, id))
    .filter(Boolean);

  const style = {
    icon: getActivityIcon(resource_type, type),
    color: getActivityColour(action_type),
  };

  if (links.length === 0) {
    return (
      <div>
        <ActivityCell
          style={style}
          description={message}
        />
      </div>
    );
  } else if (links.length === 1) {
    return (
      <div>
        <Link to={links[0]}>
          <ActivityCell
            style={style}
            description={message}
          />
        </Link>
      </div>
    );
  } else {
    const popover = (
      <Popover id="activityMessagePopover">
        <Router history={history}>
          {links.map((link, index) => {
            return (
              <Link
                key={link + index}
                className="activityTable__link"
                to={link}
              >
                Go to {resource_type.replace(/_/g, " ")}
              </Link>
            );
          })}
        </Router>
      </Popover>
    );
    return (
      <div>
        <OverlayTrigger
          trigger="click"
          placement="bottom"
          rootClose
          overlay={popover}
        >
          <a
            href=""
            onClick={(event) => event.preventDefault()}
          >
            <ActivityCell
              style={style}
              description={message}
            />
          </a>
        </OverlayTrigger>
      </div>
    );
  }
};

const userRenderer = ({ rowData }) => (
  <div data-hj-suppress>{rowData.user.name}</div>
);

const dateRenderer = ({ rowData }) => (
  <div>{getTimeStringFromDate(rowData.date, config.DATETIME_FORMAT)}</div>
);

const locationRenderer = ({ rowData }) => {
  const location = rowData.metadata.location;

  if (!location) {
    return "Unknown";
  }

  return (
    <div>
      <Tooltip content={formatLatLong(location.latitude, location.longitude)}>
        <span data-hj-suppress>
          {formatCityAndCountry(location.city, location.country)}
        </span>
      </Tooltip>
    </div>
  );
};

const ActivityTable = (props) => {
  const { filterRow, height } = props;
  const tableHeight = Math.max(height - TABLE_HEIGHT_OFFSET, MIN_TABLE_HEIGHT);

  return (
    <Panel
      className="activityTable"
      style={{ height: tableHeight }}
    >
      <AutoSizer>
        {({ width, height }) => (
          <CustomTable
            width={width}
            height={height}
            list={props.activities}
            filterRow={filterRow}
            filterKey="message"
            filterTitle="Activity"
            defaultSortBy="date"
            defaultSortDirection="desc"
            csvDownload={props.csvDownload}
          >
            <Column
              label="User"
              dataKey="user.email"
              cellRenderer={userRenderer}
              width={width / 4}
            />
            <Column
              label="Activity"
              dataKey="resource_ids"
              cellRenderer={activityRenderer}
              width={width / 2}
            />
            <Column
              label="Date"
              dataKey="date"
              cellRenderer={dateRenderer}
              width={width / 4}
            />
            <Column
              label="Location"
              dataKey="metadata"
              cellRenderer={locationRenderer}
              width={width / 4}
              disableSort
            />
          </CustomTable>
        )}
      </AutoSizer>
    </Panel>
  );
};

export default ActivityTable;
