import { AnalyzeSpec } from "../Redux/reducers/analyze";
import { v1 as uuidv1 } from "uuid";
import find from "lodash/find";
import { getTimeStringFromDate } from "#utils/timeFormatter";
import config from "#src/config";

const lodash = { find };

export function getAnalyzeSpec(
  stream,
  sampleType,
  measurementType,
  timeRangeStart,
  timeRangeEnd
) {
  return new AnalyzeSpec({
    id: uuidv1(),
    timeRangeStart: getTimeStringFromDate(timeRangeStart, config.DATE_FORMAT),
    timeRangeEnd: getTimeStringFromDate(timeRangeEnd, config.DATE_FORMAT),
    sampleTypes: [sampleType],
    measurementType: [measurementType],
    streams: [stream.id],
  });
}

export function getStreamsWithName(bookmark, availableStreams) {
  const streamIds = bookmark.streams;
  const streamsWithName = streamIds.map((streamId) => {
    if (streamId) {
      return lodash.find(availableStreams, { id: streamId });
    }
  });

  return {
    ...bookmark,
    streams: streamsWithName,
  };
}
