import {
  linkToFacilities,
  linkToEquipmentDetail,
  linkToDeviceDetail,
} from "#routers/links";
import FacilityService from "#services/FacilityService";
import { RoutingLink } from "#src/batteries-included-components/RoutingLink";
import config from "#src/config";
import useLocalization from "#src/hooks/useLocalization";
import { getTimeStringFromDate } from "#utils/timeFormatter";
import {
  dateFormatter,
  datetimeFormatter,
  formatCoordinateDegrees,
} from "@validereinc/utilities";
import { KeyValuePanel } from "@validereinc/common-components";
import React, { useEffect, useState } from "react";
import isNull from "lodash/isNull";
import { AttributeDataType, AssetType } from "@validereinc/domain";
import { FlowDetailRoute } from "#routes/organization/flows/[flowId]/detail";
import { NetworkDetailRoute } from "#routes/organization/networks/[networkId]/detail";

const EquipmentDetailsPanel = ({
  equipmentDetail,
  isMapDisplayed,
}: EquipmentDetailsPanelProps) => {
  const [customAttributes, setCustomAttributes] = useState([]);
  const { localize, isLoading: isMappingLoading } = useLocalization();

  useEffect(() => {
    (async () => {
      const {
        data: { data },
      } = await FacilityService.getEquipmentCustomAttributes();

      setCustomAttributes(data);
    })();
  }, []);

  const data = [
    {
      title: localize("Facility"),
      value: (
        <RoutingLink to={linkToFacilities(equipmentDetail?.facility?.id)}>
          {equipmentDetail?.facility?.name ?? "-"}
        </RoutingLink>
      ),
    },
    {
      title: "Effective Date",
      value: getTimeStringFromDate(
        equipmentDetail?.effective_date,
        config.DATEMONTH_FORMAT
      ),
    },
    { title: "Type", value: equipmentDetail?.type?.name },
    {
      title: "Location",
      value: formatCoordinateDegrees([
        equipmentDetail?.latitude,
        equipmentDetail?.longitude,
      ]),
    },

    ...(customAttributes
      ?.filter(
        ({ entity_subtype }) =>
          !entity_subtype || equipmentDetail?.type_id == entity_subtype
      )
      .map(({ display_name, field_name, data_type }) => {
        const value = equipmentDetail?.custom_attributes?.[field_name];

        if (typeof value === "undefined" || isNull(value)) {
          return {
            title: display_name,
            value: "-",
          };
        }

        // IMPROVE: need to abstract display logic away for attributes in one
        // centralized place. This is currently repeated and fragmented in
        // several places, most notably, forms.
        switch (data_type) {
          case AttributeDataType.DATE_TIME:
            return {
              title: display_name,
              value: datetimeFormatter(new Date(value)),
            };
          case AttributeDataType.DATE:
            return {
              title: display_name,
              value: dateFormatter(new Date(value)),
            };
          case AttributeDataType.LOOKUP:
            switch (value?.entity_type) {
              case AssetType.FACILITY:
                return {
                  title: display_name,
                  value: (
                    <RoutingLink to={linkToFacilities(value?.id)}>
                      {value?.name}
                    </RoutingLink>
                  ),
                };
              case AssetType.EQUIPMENT:
                return {
                  title: display_name,
                  value: (
                    <RoutingLink to={linkToEquipmentDetail(value?.id)}>
                      {value?.name}
                    </RoutingLink>
                  ),
                };
              case AssetType.FLOW:
                return {
                  title: display_name,
                  value: (
                    <RoutingLink
                      to={FlowDetailRoute.toLink({
                        pathParams: { flowId: value?.id },
                      })}
                    >
                      {value?.name}
                    </RoutingLink>
                  ),
                };
              case AssetType.DEVICE:
                return {
                  title: display_name,
                  value: (
                    <RoutingLink to={linkToDeviceDetail(value?.id)}>
                      {value?.name}
                    </RoutingLink>
                  ),
                };
              case AssetType.ASSET_GROUP:
                return {
                  title: display_name,
                  value: (
                    <RoutingLink
                      to={NetworkDetailRoute.toLink({
                        pathParams: { networkId: value?.id },
                      })}
                    >
                      {value?.name}
                    </RoutingLink>
                  ),
                };
              default:
                return {
                  title: display_name,
                  valie: "-",
                };
            }
          case AttributeDataType.STRING:
          default: {
            return {
              title: display_name,
              value,
            };
          }
        }
      }) ?? []),
  ];

  return (
    <KeyValuePanel
      panelMaxColumnCount={isMapDisplayed ? 2 : 3}
      panelKeyValueListProps={{ maxRowCount: 6, variant: "shaded" }}
      panelProps={{ isFluidY: false, loaded: !isMappingLoading }}
      data={data}
    />
  );
};

type EquipmentDetailsPanelProps = {
  equipmentDetail: any;
  isMapDisplayed?: boolean;
};

export default EquipmentDetailsPanel;
