import { Page, Panel, useAlert } from "@validereinc/common-components";
import find from "lodash/find";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Col, ProgressBar } from "react-bootstrap";
import { NOTIFICATION_OPTIONS } from "../../../../utils/enums";
import NotificationService from "../../../Services/NotificationService";
import RoleService from "../../../Services/RoleService";
import NotificationForm from "./NotificationForm";
import "./RoleNotificationSettings.css";

const lodash = { find };

const RoleNotificationSettings = (props) => {
  const roleId = props.match.params.roleId;
  const [notificationSettings, setNotificationSettings] = useState(null);
  const [roleDetail, setRoleDetail] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(true);
  const { addAlert } = useAlert();

  const getRoleDetail = (roles) => {
    return lodash.find(roles, { id: roleId });
  };

  const onDropdownSelect = (value, key) => {
    const newNotificationSettings = {
      ...notificationSettings,
      [key]: NOTIFICATION_OPTIONS[value].value,
    };

    setNotificationSettings(newNotificationSettings);
    submitNotificationSettings(newNotificationSettings);
  };

  const submitNotificationSettings = (newNotificationSettings) => {
    setIsSubmitted(false);
    NotificationService.updateRoleNotificationSettings(
      roleId,
      newNotificationSettings
    )
      .then((_response) => {
        setIsSubmitted(true);
        addAlert({
          variant: "success",
          message: "Notification settings successfully updated.",
        });
      })
      .catch(() => {
        setIsSubmitted(true);
      });
  };

  useEffect(() => {
    if (roleId && !isLoaded) {
      RoleService.getRoles()
        .then(({ data }) => {
          const roleDetail = getRoleDetail(data);
          if (roleDetail) {
            setRoleDetail(roleDetail);
          }

          setIsLoaded(true);
        })
        .catch(() => {
          setIsLoaded(false);
        });

      NotificationService.getCompanyRoleNotificationSettings(roleId)
        .then(({ data }) => {
          setNotificationSettings(data);
          setIsLoaded(true);
        })
        .catch(() => {
          setIsLoaded(false);
        });
    }
  }, [roleId]);

  return (
    <Page
      isLoading={!roleDetail?.name}
      title={`Edit ${roleDetail.name || ""} Role Notification Settings`}
    >
      <Col
        xs={12}
        sm={12}
        md={12}
        lg={9}
      >
        <Panel className="roleNotificationSettings">
          <div hidden={isSubmitted}>
            <ProgressBar
              active
              now={100}
            />
          </div>

          {isLoaded && notificationSettings ? (
            <NotificationForm
              notificationSettings={notificationSettings}
              onDropdownSelect={onDropdownSelect}
              disabled={false}
            />
          ) : (
            <div className="noNotificationSettingsAvailable">
              No notification setting available.
            </div>
          )}
        </Panel>
      </Col>
    </Page>
  );
};

RoleNotificationSettings.propTypes = {
  profile: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  hasWritePermissions: PropTypes.bool,
};

export default RoleNotificationSettings;
