import {
  apiPostServicesPromise,
  apiPostUploadFileServicesPromise,
  apiPutServicesPromise,
} from "./ServiceHelper";
import { COMPONENTS } from "../ManualEntry/ManualEntryForms/ComponentAnalysis/ComponentAnalysisConstant";
import moment from "moment";
import indexOf from "lodash/indexOf";

const ManualEntryService = {
  addComponentAnalysisSample(input) {
    const formData = new FormData();

    formData.append("site_id", input.site.id);
    formData.append("stream_id", input.stream.id);
    formData.append("sample_type", input.sampleType);
    formData.append("source_lab", input.sourceLab);
    formData.append(
      "sample_point_id",
      input.samplePointId ? input.samplePointId : null
    );
    formData.append("started_at", moment(input.sampleStartTime).toISOString());
    formData.append("ended_at", moment(input.sampleEndTime).toISOString());
    formData.append(
      "test_date",
      moment(input.testDate).startOf("day").toISOString()
    );
    formData.append("file", input.attachment[0]);

    Object.keys(input.measurements).map((measurement) => {
      if (indexOf(COMPONENTS, measurement) === -1) {
        formData.append(
          `measurements[${measurement}]`,
          input.measurements[measurement]?.value ?? 0
        );
      }
    });

    // All component are all required field.
    COMPONENTS.map((component) => {
      formData.append(
        `measurements[${input.unit} ${component}]`,
        input.measurements[component]?.value ?? 0
      );
    });

    return apiPostUploadFileServicesPromise(
      "api/samples/manual/component_analysis",
      formData
    );
  },

  addInlineMeasurement(input, upsert = false) {
    const parameters = {
      measurements: input.measurements.map((measurement) => ({
        stream_id: input.stream.id,
        instrument_id: measurement.instrument.id,
        measurement_date: moment(measurement.date).format(),
        measurement_type: measurement.measurementType,
        value: parseFloat(measurement.value),
        measurement_unit: measurement.unit,
      })),
    };

    const request = upsert ? apiPutServicesPromise : apiPostServicesPromise;

    return request(`api/instruments/inline_measurements`, parameters);
  },

  addFieldMeasurement(input, profile) {
    const parameters = {
      user_id: profile.id,
      type: "field",
      test_date: moment(input.date).format(),
      short_code: "manual-entry",
      metadata: {},
      sample_id: input.samples?.id ?? null,
      measurements: input.measurements.map((measurement) => ({
        type: measurement.type,
        unit: measurement.unit,
        value: parseFloat(measurement.value),
        date: moment(input.date).format(),
        metadata: {},
        temperature_in_c: measurement.temperature
          ? parseFloat(measurement.temperature)
          : null,
      })),
    };

    return apiPostServicesPromise(
      `api/instruments/${input.instrument.id}/tests`,
      parameters
    );
  },
};

export default ManualEntryService;
