import {
  RECORDS_AUTOMATION_BREADCRUMB,
  RECORDS_AUTOMATION_TITLE,
} from "#routes/records/automation/index";
import { RECORDS_PAGE_BREADCRUMB } from "#src/routes/records/index";
import { Page } from "@validereinc/common-components";
import React from "react";

export const RecordAutomationListPage = () => {
  return (
    <Page
      title={RECORDS_AUTOMATION_TITLE}
      breadcrumbs={[RECORDS_PAGE_BREADCRUMB, RECORDS_AUTOMATION_BREADCRUMB]}
    >
      coming soon
    </Page>
  );
};
