import { z } from "zod";
import {
  DomainModelMetaExtendedSchema,
  DomainModelMetaSchema,
  DomainModelSchema,
} from "./DomainModelSchemas";
import { UserSchema } from "./UserSchemas";

export const UserGroupSchema = z
  .object({
    name: z.string(),
    description: z.string().optional(),
    group_category_id: z.string().uuid(),
  })
  .merge(DomainModelSchema)
  .merge(DomainModelMetaSchema)
  .merge(DomainModelMetaExtendedSchema)
  .describe("Represents a user group in the Node API");

export const CreateUserGroupSchema = UserGroupSchema.pick({
  name: true,
  description: true,
  group_category_id: true,
})
  .partial({
    description: true,
  })
  .extend({
    user_ids: z.array(z.string().uuid()),
  });

export const UpdateUserGroupSchema = UserGroupSchema.pick({
  name: true,
  description: true,
}).partial();

export const UserGroupMembershipSchema = z
  .object({
    user_group_id: z.string().uuid(),
    user_id: z.string().uuid(),
    company_id: z.string().uuid(),
    user: UserSchema.pick({
      id: true,
      name: true,
      email: true,
    }),
  })
  .merge(DomainModelMetaSchema)
  .merge(DomainModelMetaExtendedSchema)
  .describe("Represents a user membership within a user group in the Node API");

export type UserGroupType = z.infer<typeof UserGroupSchema>;
export type CreateUserGroupType = z.infer<typeof CreateUserGroupSchema>;
export type UpdateUserGroupType = z.infer<typeof UpdateUserGroupSchema>;
export type UserGroupMembershipType = z.infer<typeof UserGroupMembershipSchema>;
