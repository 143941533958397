import { clearAllInputs, getAllFilterInputs } from "#utils/arrayFormatter";
import { GetAllObjectValue } from "#utils/objectFormatter";
import {
  FilterPillbox,
  MultiDropdownInputWithSearch,
} from "@validereinc/common-components";
import differenceBy from "lodash/differenceBy";
import intersectionBy from "lodash/intersectionBy";
import React, { useEffect, useState } from "react";

const DEFAULT_FILTER_DROPDOWN = {
  salesFacility: {
    label: "Sales Facility",
    inputs: [],
    options: [],
    labelKey: "name",
  },
  salesProductStream: {
    label: "Sales Product Stream",
    inputs: [],
    options: [],
    labelKey: "name",
  },
  equipment: {
    label: "Equipment",
    inputs: [],
    options: [],
    labelKey: "name",
  },
};

const noIntersectionBetweenLists = (filterListOptions, list, key = "id") => {
  return intersectionBy(filterListOptions, list, key).length === 0;
};

export function filterProductionReports(emissionReports, filters) {
  const { salesFacility, salesProductStream, equipment } = filters;

  const filteredInventories = emissionReports.filter((report) => {
    // if no intersection, it is not included in the filtered list
    if (
      salesFacility?.length > 0 &&
      noIntersectionBetweenLists(salesFacility, report.facilities)
    ) {
      return false;
    }

    if (
      salesProductStream?.length > 0 &&
      noIntersectionBetweenLists(
        salesProductStream,
        report.sales_product_stream_ids
      )
    ) {
      return false;
    }

    if (
      equipment?.length > 0 &&
      noIntersectionBetweenLists(equipment, report.equipment)
    ) {
      return false;
    }

    return true;
  });

  return filteredInventories;
}

const EmissionReportsTableFilter = (emissionReports) => {
  const [filterDropdowns, setFilterDropdowns] = useState({
    ...DEFAULT_FILTER_DROPDOWN,
  });

  useEffect(() => {
    if (emissionReports.length > 0) {
      const { salesFacility, salesProductStream, equipment } = filterDropdowns;

      salesFacility.options = GetAllObjectValue(
        emissionReports,
        "facilities",
        "id"
      );
      salesProductStream.options = GetAllObjectValue(
        emissionReports,
        "sales_product_stream_ids",
        "id"
      );
      equipment.options = GetAllObjectValue(emissionReports, "equipment", "id");

      setFilterDropdowns((filterDropdowns) => ({
        ...filterDropdowns,
        salesFacility,
        salesProductStream,
        equipment,
      }));
    }
  }, [emissionReports]);

  const onDropdownSelect = (selectedValue, key) => {
    const filterDropdown = { ...filterDropdowns[key] };
    filterDropdown.inputs = selectedValue;

    setFilterDropdowns({ ...filterDropdowns, [key]: filterDropdown });
  };

  const clearAllFilters = () => {
    setFilterDropdowns(clearAllInputs(filterDropdowns));
  };

  const onClearFilterClick = (filterObject) => {
    if (filterObject) {
      const { filterKey } = filterObject;
      const updatedFilterDropdown = { ...filterDropdowns[filterKey] };

      updatedFilterDropdown.inputs = differenceBy(
        updatedFilterDropdown.inputs,
        [filterObject],
        "id"
      );

      setFilterDropdowns({
        ...filterDropdowns,
        [filterKey]: updatedFilterDropdown,
      });
    } else {
      clearAllFilters();
    }
  };

  const filterRow = (
    <div className="filterRow">
      {Object.keys(filterDropdowns).map((key) => {
        const { label, labelKey, options, inputs } = filterDropdowns[key];

        return (
          <MultiDropdownInputWithSearch
            key={key}
            label={label}
            labelKey={labelKey ? labelKey : null}
            dropdownKey={key}
            value={inputs}
            options={options}
            onChange={onDropdownSelect}
          />
        );
      })}
    </div>
  );

  const filterInputs = getAllFilterInputs(filterDropdowns);

  const filteredProductionReports = filterProductionReports(
    emissionReports,
    filterInputs
  );

  const filterPillbox = (
    <FilterPillbox
      filterBy={filterInputs}
      onClearFilterClick={onClearFilterClick}
    />
  );

  return [filterRow, filterPillbox, filteredProductionReports];
};

export default EmissionReportsTableFilter;
