import {
  Button,
  EmptyState,
  Icon,
  KeyValueTileGrid,
  Panel,
} from "@validereinc/common-components";
import { UserGroupMembershipType } from "@validereinc/domain";
import React from "react";

export const SelectedUsersPanel = ({
  selectedUsers,
  removeUser,
}: {
  selectedUsers: Array<UserGroupMembershipType["user"]>;
  removeUser: (userId: string) => void;
}) => {
  return (
    <Panel
      title="Selected Users"
      isFluidY={false}
    >
      {selectedUsers?.length ? (
        <KeyValueTileGrid
          size="small"
          orientation="vertical"
          data={selectedUsers.map(({ id, name, email }) => ({
            title: email,
            value: name,
            actions: [
              <Button
                key="remove-user-button"
                onClick={() => removeUser(id)}
                variant="text"
                icon="minus-circle"
              />,
            ],
          }))}
        />
      ) : (
        <EmptyState
          title="No user(s) selected"
          icon={<Icon variant="stack" />}
          suggestion="Add users from the table."
        />
      )}
    </Panel>
  );
};
