import React from "react";
import PropTypes from "prop-types";
import DescriptionRow from "../Shared/DescriptionPanel/DescriptionRow";
import DescriptionPanel from "../Shared/DescriptionPanel/DescriptionPanel";

const AquamaxKFDescriptionPanel = (props) => (
  <DescriptionPanel>
    <DescriptionRow
      title="Instrument ID"
      value={props.instrumentInfo.name}
      icon="hashtag"
    />

    <DescriptionRow
      title="Type"
      value="Aquamax KF"
      icon="cube"
    />

    <DescriptionRow
      title="Location"
      value={props.instrumentInfo.location}
      icon="map-marker"
    />

    <DescriptionRow
      title="Serial Number"
      value={props.instrumentInfo.current_serial_number}
      icon="id-card"
    />
  </DescriptionPanel>
);

AquamaxKFDescriptionPanel.propTypes = {
  instrumentInfo: PropTypes.object.isRequired,
};

export default AquamaxKFDescriptionPanel;
