import SummaryInformation from "#common/SummaryInformation/SummaryInformation";
import config from "#config";
import { getTimeStringFromDate } from "#utils/timeFormatter";
import { Panel } from "@validereinc/common-components";
import classNames from "classnames/bind";
import find from "lodash/find";
import * as PropTypes from "prop-types";
import React, { useMemo } from "react";
import { sampleStateRenderer } from "../SampleHelper";
import styles from "./SampleDetail.module.scss";

const cx = classNames.bind(styles);

const SampleDetailSummary = ({
  fetchedSample,
  streams,
  selectedSampleLoadingState,
}) => {
  const productType = useMemo(() => {
    if (fetchedSample) {
      return (
        find(streams, { id: fetchedSample.stream_id })?.product_type ?? "-"
      );
    }

    return "-";
  }, [fetchedSample, streams]);

  const sharedProps = {
    className: cx("summaryContainerItem"),
    type: "vertical",
  };

  return (
    <Panel
      title="Summary"
      loaded={selectedSampleLoadingState === "loaded"}
    >
      <div className={cx("summaryContainer")}>
        <SummaryInformation
          {...sharedProps}
          title="Source"
          value={fetchedSample?.sample_type ?? "-"}
        />

        <SummaryInformation
          {...sharedProps}
          title="Status"
          value={sampleStateRenderer(fetchedSample)}
        />

        <SummaryInformation
          {...sharedProps}
          title="Sample Date"
          value={getTimeStringFromDate(
            fetchedSample?.ended_at,
            config.DATETIME_FORMAT
          )}
        />

        <SummaryInformation
          {...sharedProps}
          title="Product Type"
          value={productType}
        />

        <SummaryInformation
          {...sharedProps}
          title="Site"
          value={fetchedSample?.site?.name ?? "-"}
        />

        <SummaryInformation
          {...sharedProps}
          title="Validated By"
          value={fetchedSample?.validated_by?.email ?? "-"}
        />

        <SummaryInformation
          {...sharedProps}
          title="Validated Date"
          value={
            fetchedSample?.validated_at
              ? getTimeStringFromDate(
                  fetchedSample?.validated_at,
                  config.DATETIME_FORMAT
                )
              : "-"
          }
        />
      </div>
    </Panel>
  );
};

SampleDetailSummary.propTypes = {
  fetchedSample: PropTypes.object,
  streams: PropTypes.array.isRequired,
  selectedSampleLoadingState: PropTypes.string.isRequired,
};

export default SampleDetailSummary;
