import {
  alertingApiRequestServicesPromise,
  alertingApiPostServicesPromise,
  alertingApiPutServicesPromise,
  alertingApiDeleteServicesPromise,
} from "./ServiceHelper";

import type { PaginationParams } from "./types";

const MAX_ALERTING_PAGE_SIZE = 100000;
const AlertingService = {
  getAlertConfigurations({ page, rowPerPage }: Partial<PaginationParams> = {}) {
    return alertingApiRequestServicesPromise(`/configuration`, {
      page,
      page_size: rowPerPage || MAX_ALERTING_PAGE_SIZE,
    });
  },

  async createAlertConfiguration({ name, conditions, subscribers }) {
    return alertingApiPostServicesPromise("/configuration", {
      name,
      conditions: conditions.map(
        ({
          aggregation_function,
          duration,
          measurement_series_id,
          operator,
          threshold,
          window,
        }) => ({
          aggregation_function,
          duration,
          measurement_series_id,
          operator,
          threshold,
          window,
        })
      ),
      subscribers,
    });
  },

  getAlertConfiguration(alertConfigurationId) {
    return alertingApiRequestServicesPromise(
      `/configuration/${alertConfigurationId}`
    );
  },

  archiveConfiguration(alertConfigurationId) {
    return alertingApiPutServicesPromise(
      `/archive-configuration/${alertConfigurationId}`
    );
  },

  getAlerts({ page, rowPerPage }: Partial<PaginationParams> = {}) {
    return alertingApiRequestServicesPromise(`/alerts`, {
      page,
      page_size: rowPerPage || MAX_ALERTING_PAGE_SIZE,
    });
  },

  getAlertDetail(alertId) {
    return alertingApiRequestServicesPromise(`/alerts/${alertId}`);
  },

  resolveAlert(alertId, params) {
    return alertingApiPutServicesPromise(`/resolve-alert/${alertId}`, params);
  },

  addSubscriberToAlert(alertId, subscriber) {
    const params = {
      delivery_address: subscriber,
      monitor_id: alertId,
    };

    return alertingApiPostServicesPromise(`/subscriber`, params);
  },

  removeSubscriberFromAlert(subscriberId) {
    return alertingApiDeleteServicesPromise(`/subscriber/${subscriberId}`);
  },
};

export default AlertingService;
