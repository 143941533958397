import { EquipmentDropdownInput } from "#batteries-included-components/Dropdowns/EquipmentDropdownInput";
import { FacilityDropdownInput } from "#batteries-included-components/Dropdowns/FacilityDropdownInput";
import {
  useListFlowProductCategories,
  useListFlowProductTypes,
  useListFlowTypes,
} from "#hooks/adapters/useFlows";
import { useFlowDetailFlow } from "#routes/organization/flows/[flowId]/detail/FlowDetailPage.helpers";
import CustomAttributeField from "#src/components/Common/CustomAttributeField";
import { useListCustomAttributes } from "#src/components/hooks/adapters/useCustomAttributes";
import { DEFAULT_DATE_RANGES } from "#src/hooks/useDateRange";
import useLocalization from "#src/hooks/useLocalization";
import {
  useMemoizedFormState,
  useMultiStepFormContext,
} from "#src/hooks/useMultiStepForm";
import { FlowDetailParamsType } from "#src/routes/organization/flows/[flowId]/detail";
import {
  DropdownInput,
  Form,
  Panel,
  StorageKeys,
  TextInput,
  useForm,
} from "@validereinc/common-components";
import { AssetType, FlowStatus } from "@validereinc/domain";
import { toStartCaseString } from "@validereinc/utilities";
import classNames from "classnames/bind";
import React, { useEffect } from "react";
import { useParams } from "react-router";
import styles from "./FlowFormPanel.module.scss";

const cx = classNames.bind(styles);

export const BasicInfoFormStep = ({
  filterConfigStorageKey,
}: Pick<StorageKeys, "filterConfigStorageKey">) => {
  const STEP_NUMBER = 1;
  const { currentStep, updateStep } = useMultiStepFormContext();

  const { flowId } = useParams<FlowDetailParamsType>();

  const { localize } = useLocalization();

  const isLoadingFlowEnabled = currentStep === STEP_NUMBER && !!flowId;

  const { data: flow, isLoading } = useFlowDetailFlow({
    filterConfigStorageKey,
    enabled: isLoadingFlowEnabled,
  });
  const flowTypes = useListFlowTypes().data ?? [];
  const productCategories = useListFlowProductCategories().data ?? [];
  const productTypes = useListFlowProductTypes().data ?? [];
  const customAttributes =
    useListCustomAttributes({ filters: { entity_type: AssetType.FLOW } }).data
      ?.data ?? [];

  const form = useForm({
    defaultValues: {
      period: DEFAULT_DATE_RANGES.currentWholeMonth,
    },
  });

  const getFormState = useMemoizedFormState(form);

  useEffect(() => {
    updateStep(STEP_NUMBER, {
      getValues: () => form.getValues(),
      getFormState,
    });
  }, [flow, getFormState]);

  const type = form?.watch("type");
  const parentId = form?.watch("associated_facility_id");

  useEffect(() => {
    form.setValue("associated_equipment_id", undefined);
  }, [parentId]);

  useEffect(() => {
    if (form) {
      const currentValues = form.getValues("custom_attributes") ?? {};
      const newAttributeValues = Object.keys(currentValues).reduce<
        Record<string, any>
      >((acc, key) => {
        if (currentValues[key]) acc[key] = "";
        return acc;
      }, {});
      form.setValue(
        "custom_attributes",
        {
          ...newAttributeValues,
          ...flow?.custom_attributes,
        },
        { shouldTouch: true }
      );

      const {
        downstream_flows: _downstream_flows,
        upstream_flows: _upstream_flows,
        downstream_facility: _downstream_facility,
        upstream_facility: _upstream_facility,
        downstream_equipment: _downstream_equipment,
        upstream_equipment: _upstream_equipment,
        downstream_flow_ids: _downstream_flow_ids,
        downstream_equipment_id: _downstream_equipment_id,
        downstream_facility_id: _downstream_facility_id,
        upstream_equipment_id: _upstream_equipment_id,
        upstream_facility_id: _upstream_facility_id,
        upstream_flow_ids: _upstream_flow_ids,
        ...initialValues
      } = flow ?? {};

      Object.keys(initialValues).forEach((k) => {
        form.setValue(k, flow[k]);
      });
      form.trigger();
    }
  }, [flow]);

  const sharedInputProps = {
    isDisabled: isLoadingFlowEnabled && isLoading,
  };

  return (
    <>
      {currentStep === STEP_NUMBER ? (
        <Panel>
          <Form {...form}>
            <h4 className="sectionTitle">Details</h4>

            <div className={cx("basicInfoContainer")}>
              <TextInput
                name="name"
                label="Name"
                isRequired
                {...sharedInputProps}
              />

              <DropdownInput
                name="type"
                label="Type"
                options={flowTypes}
                labelKey="name"
                valueKey="id"
                isRequired
                {...sharedInputProps}
              />

              <DropdownInput
                name="status"
                label="Status"
                options={Object.values(FlowStatus).map((id) => ({
                  id,
                  name: toStartCaseString(id),
                }))}
                labelKey="name"
                valueKey="id"
                isRequired
                {...sharedInputProps}
              />

              <DropdownInput
                name="product_category"
                label="Product Category"
                options={productCategories}
                labelKey="name"
                valueKey="id"
                isRequired
                {...sharedInputProps}
              />

              <DropdownInput
                name="product_type"
                label="Product Type"
                options={productTypes}
                labelKey="name"
                valueKey="id"
                isRequired
                {...sharedInputProps}
              />

              <FacilityDropdownInput
                name="associated_facility_id"
                label={`Associated ${localize("Facility")}`}
                isMulti={false}
                isOptionalTextShown={true}
                {...sharedInputProps}
              />

              <EquipmentDropdownInput
                name="associated_equipment_id"
                label={`Associated ${localize("Equipment")}`}
                isMulti={false}
                isOptionalTextShown={true}
                refetch={parentId}
                fetchFilters={parentId ? { "facility.id": parentId } : {}}
                {...sharedInputProps}
              />

              {customAttributes?.map(({ field_name, ...restAttribute }) => (
                <CustomAttributeField
                  key={`custom_attributes.${field_name}`}
                  attribute={{ field_name, ...restAttribute }}
                  subtype={type ?? null}
                  {...sharedInputProps}
                />
              ))}
            </div>
          </Form>
        </Panel>
      ) : null}
    </>
  );
};
