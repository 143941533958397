import useLocalization from "#src/hooks/useLocalization";
import {
  Accordion,
  Button,
  EmptyState,
  Icon,
  MetricTileGrid,
  Panel,
} from "@validereinc/common-components";
import { AssetGroupAssetType } from "@validereinc/domain";
import React from "react";

const { AccordionPanel } = Accordion;

export const SelectedAssetsPanel = ({
  selectedOrigins,
  selectedDestinations,
  removeAssetFromFlow,
}: {
  selectedOrigins: AssetGroupAssetType[];
  selectedDestinations: AssetGroupAssetType[];
  removeAssetFromFlow: (
    asset: AssetGroupAssetType,
    isUpstream: boolean
  ) => void;
}) => {
  const { localize } = useLocalization();

  const removeOrigin = (item: AssetGroupAssetType) => {
    removeAssetFromFlow(item, true);
  };

  const removeDestination = (item: AssetGroupAssetType) => {
    removeAssetFromFlow(item, false);
  };

  return (
    <Panel
      title="Selected Assets"
      isFluidY={false}
    >
      <Accordion defaultActiveKeys={["origin", "destination"]}>
        <AccordionPanel
          dataKey="origin"
          title="Origin"
        >
          {selectedOrigins?.length ? (
            <MetricTileGrid
              size="small"
              orientation="vertical"
              data={selectedOrigins.map(({ id, name, asset_type }) => ({
                title: `${localize(asset_type)} Name`,
                value: name,
                actions: [
                  <Button
                    key="remove-origin-button"
                    onClick={() => removeOrigin({ id, asset_type })}
                    variant="text"
                    icon="minus-circle"
                  />,
                ],
              }))}
            />
          ) : (
            <EmptyState
              title="No origin(s) selected"
              icon={<Icon variant="stack" />}
              suggestion="Add asset(s) as origin from the table."
              isFluid
            />
          )}
        </AccordionPanel>

        <AccordionPanel
          dataKey="destination"
          title="Destination"
        >
          {selectedDestinations?.length ? (
            <MetricTileGrid
              size="small"
              orientation="vertical"
              data={selectedDestinations.map(({ id, name, asset_type }) => ({
                title: `${localize(asset_type)} Name`,
                value: name,
                actions: [
                  <Button
                    key="remove-origin-button"
                    onClick={() => removeDestination({ id, asset_type })}
                    variant="text"
                    icon="minus-circle"
                  />,
                ],
              }))}
            />
          ) : (
            <EmptyState
              title="No destination(s) selected"
              icon={<Icon variant="stack" />}
              suggestion="Add asset(s) as destination from the table."
              isFluid
            />
          )}
        </AccordionPanel>
      </Accordion>
    </Panel>
  );
};
