import React from "react";
import * as PropTypes from "prop-types";
import { connect } from "react-redux";
import { getMeasurementType } from "../../Redux/reducers/measurements";
import { getAlertSpec } from "../InstrumentComponentHelper";
import InstrumentDetail from "../InstrumentDetail";
import {
  dateRenderer,
  sampleDateRenderer,
  sortBySampleDate,
  getMeasurementValue,
} from "../InstrumentHelper";
import MeasurementStatus from "../Shared/TestsDatatable/MeasurementStatus";
import { notesColumn } from "../Shared/TestsDatatable/NotesColumn";
import {
  sampleStateRenderer,
  sortBySampleState,
} from "../../Samples/SampleHelper";
import TestDetailModal from "../../Tests/TestDetailModal/TestDetailModal";

const measurementRenderer = (measurementType) =>
  function measurementRendererInner(rowData, columnKey) {
    const defaultAlertSpec = {
      measurement_unit: measurementType.unit,
      upper_limit: 103,
      lower_limit: 0,
    };

    try {
      const value = getMeasurementValue(
        rowData.measurements[columnKey].value,
        measurementType.decimals
      );
      const unit = rowData.measurements[columnKey].unit;
      return (
        <MeasurementStatus
          value={value}
          unit={unit}
          status={rowData.status}
          labels={rowData.labels}
          defaultAlertSpec={defaultAlertSpec}
          alertSpec={getAlertSpec(
            rowData.measurement_alert_specs,
            measurementType.name,
            defaultAlertSpec
          )}
          measurementType={measurementType}
        />
      );
    } catch {
      return <div>-</div>;
    }
  };
const metadataRenderer = (rowData, columnKey) => {
  return <div>{rowData.metadata[columnKey] ?? "-"}</div>;
};

const sortByMetadata = (list, sortBy) => {
  return list.sort((a, b) => {
    const aValue = a.metadata[sortBy] ?? "-";
    const bValue = b.metadata[sortBy] ?? "-";

    return aValue.localeCompare(bValue);
  });
};

const sortByMeasurements = (list, measurementName) => {
  return [...list].sort((a, b) => {
    return (
      a.measurements[measurementName].value -
      b.measurements[measurementName].value
    );
  });
};

const headers = (measurementType) => [
  {
    label: "Name",
    key: "name",
    width: 400,
    fixed: true,
  },
  {
    label: "Sample State",
    key: "sample_state",
    width: 150,
    cellRenderer: (row) => sampleStateRenderer(row.sample),
    sort: sortBySampleState,
  },
  {
    label: "Test Date",
    key: "date",
    width: 170,
    cellRenderer: dateRenderer,
  },
  {
    label: "Tester",
    key: "operator",
    width: 150,
    cellRenderer: metadataRenderer,
    sort: sortByMetadata,
  },
  {
    label: "Type",
    key: "crude_type",
    width: 150,
    cellRenderer: metadataRenderer,
    sort: sortByMetadata,
  },
  {
    label: "VPCR (37.8℃)",
    key: measurementType.name,
    width: 250,
    cellRenderer: measurementRenderer(measurementType),
    sort: sortByMeasurements,
  },
  {
    label: "Sample Start Date",
    key: "started_at",
    width: 170,
    cellRenderer: sampleDateRenderer,
    sort: sortBySampleDate,
  },
  {
    label: "Sample End Date",
    key: "ended_at",
    width: 170,
    cellRenderer: sampleDateRenderer,
    sort: sortBySampleDate,
  },
  notesColumn,
];

const mapStateToProps = (state) => {
  return {
    measurementType: getMeasurementType(state.measurements)("Vapour Pressure"),
  };
};

const InstrumentVaporTests = (props) => {
  return (
    <InstrumentDetail
      testId={props.match.params.testId}
      instrumentInfo={props.instrumentInfo}
      height={props.height}
      ReportModalComponent={TestDetailModal}
      filterKey="name"
      headers={headers(props.measurementType)}
      defaultSortBy="date"
      defaultSortDirection="desc"
    />
  );
};

InstrumentVaporTests.propTypes = {
  match: PropTypes.object.isRequired,
  height: PropTypes.number.isRequired,
  instrumentInfo: PropTypes.object.isRequired,
  measurementType: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, undefined)(InstrumentVaporTests);
