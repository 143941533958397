import React, { useEffect, useState } from "react";
import * as PropTypes from "prop-types";
import "./StreamAlerts.scss";
import { connect } from "react-redux";
import { getMeasurementType } from "#redux/reducers/measurements";

import AlertSpecPanel from "./StreamAlertsForm/AlertSpecPanel";
import StreamsAndMeasurementPicker from "./StreamAlertsForm/StreamsAndMeasurementsPicker";
import InlineMonitorPanel from "./StreamAlertsForm/InlineMonitorPanel";
import AlertService from "#services/AlertService";
import isEmpty from "lodash/isEmpty";

const mapStateToProps = (state) => {
  return {
    measurementTypes: state.measurements.measurementTypesList,
    getMeasurementType: getMeasurementType(state.measurements),
  };
};

const StreamAlertCreate = ({
  selectedStreams,
  measurementTypes,
  getMeasurementType,
  preSelectedMeasurement,
  refetchStreams,
}) => {
  const [checkedStreams, setCheckedStreams] = useState(selectedStreams);
  const [measurementInfo, setMeasurementInfo] = useState({
    name: preSelectedMeasurement,
    unit: preSelectedMeasurement
      ? getMeasurementType(preSelectedMeasurement).unit
      : null,
  });
  const [existingAlertSpecData, setExistingAlertSpecData] = useState(null);
  const [existingInlineMonitorData, setExistingInlineMonitorData] =
    useState(null);
  const [inlineMonitorIds, setInlineMonitorIds] = useState(null);
  const [isFetchingExistingData, setIsFetchingExistingData] = useState(false);
  const [isFetchingInlineIds, setIsFetchingInlineIds] = useState(false);

  const fetchAndSetFormData = async () => {
    if (selectedStreams.length === 1 && measurementInfo.name) {
      setIsFetchingExistingData(true);

      let existingAlertSpecData = null;
      let existingInlineMonitorData = null;

      const existingAlertSpecResponse =
        await AlertService.getSpecificAlertSpecSetting(
          selectedStreams[0].id,
          measurementInfo.name
        );
      if (!isEmpty(existingAlertSpecResponse.data)) {
        existingAlertSpecData = existingAlertSpecResponse.data;
      }

      const existingInlineMonitorResponse =
        await AlertService.getSpecificInlineAlertSetting(
          selectedStreams[0].id,
          measurementInfo.name
        );
      if (!isEmpty(existingInlineMonitorResponse.data)) {
        existingInlineMonitorData = existingInlineMonitorResponse.data;
      }
      setExistingAlertSpecData(existingAlertSpecData);
      setExistingInlineMonitorData(existingInlineMonitorData);
      setIsFetchingExistingData(false);
    }
  };

  const fetchExistingInlineIds = async () => {
    if (selectedStreams.length > 0 && measurementInfo.name) {
      // so we can batch delete inline monitors if multiple streams selected
      setIsFetchingInlineIds(true);
      const existingInlineMonitors =
        await AlertService.getInlineMeasurementAlertSetting(
          checkedStreams,
          measurementInfo.name
        );
      const inlineIds = existingInlineMonitors?.data.map(
        (monitors) => monitors.id
      );
      setInlineMonitorIds(inlineIds);
      setIsFetchingInlineIds(false);
    }
  };

  useEffect(() => {
    fetchAndSetFormData();
    fetchExistingInlineIds();
  }, [measurementInfo.name]);

  const disabledForm = !(checkedStreams?.length > 0 && !!measurementInfo.name);

  return (
    <>
      <StreamsAndMeasurementPicker
        originalSelectedStreams={selectedStreams}
        checkedStreams={checkedStreams}
        measurementTypes={measurementTypes}
        getMeasurementType={getMeasurementType}
        measurementInfo={measurementInfo}
        setMeasurementInfo={setMeasurementInfo}
        setCheckedStreams={setCheckedStreams}
      />
      <AlertSpecPanel
        measurementInfo={measurementInfo}
        disabled={disabledForm}
        existingAlertSpecData={existingAlertSpecData}
        checkedStreams={checkedStreams}
        refetchStreams={refetchStreams}
        isFetchingAPI={isFetchingExistingData}
      />
      <InlineMonitorPanel
        measurementInfo={measurementInfo}
        disabled={disabledForm}
        existingInlineMonitorData={existingInlineMonitorData}
        checkedStreams={checkedStreams}
        refetchStreams={refetchStreams}
        inlineMonitorIds={inlineMonitorIds}
        isFetchingAPI={isFetchingExistingData}
        isFetchingInlineIds={isFetchingInlineIds}
        fetchExistingInlineIds={fetchExistingInlineIds}
      />
    </>
  );
};

StreamAlertCreate.propTypes = {
  selectedStreams: PropTypes.array.isRequired,
  preSelectedMeasurement: PropTypes.object.isRequired,
  measurementTypes: PropTypes.object.isRequired,
  getMeasurementType: PropTypes.func.isRequired,
  refetchStreams: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, null)(StreamAlertCreate);
