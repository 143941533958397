import { useMultiStepFormContext } from "#src/hooks/useMultiStepForm";
import { Button, Footer } from "@validereinc/common-components";
import React from "react";

export const MutateRoleFooter = ({
  submissionActionText,
  onCancel,
}: {
  submissionActionText: string;
  onCancel: () => void;
}) => {
  const { isFirstStep, isLastStep, goNext, goPrevious, isSubmitting, isValid } =
    useMultiStepFormContext();

  return (
    <Footer>
      <Button onClick={onCancel}>Cancel</Button>
      {!isFirstStep ? <Button onClick={goPrevious}>Previous</Button> : null}

      <Button
        variant="primary"
        onClick={goNext}
        isLoading={isSubmitting}
        disabled={!isValid}
      >
        {!isLastStep ? "Next" : submissionActionText}
      </Button>
    </Footer>
  );
};
