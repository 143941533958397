import { signIn } from "#redux/actions/authentication";
import { AuthenticationLayouts } from "#src/batteries-included-components/Layouts/Authentication";
import * as PropTypes from "prop-types";
import React, { useEffect } from "react";
import { connect } from "react-redux";

const mapDispatchToProps = {
  signIn,
};

const Login = (props) => {
  useEffect(() => {
    props.signIn();
  }, []);

  return <AuthenticationLayouts.LoadingOnLoginRedirectLayout />;
};

Login.propTypes = {
  signIn: PropTypes.func.isRequired,
};

export default connect(null, mapDispatchToProps)(Login);
