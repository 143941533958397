import { FormConfigurationFilterPanel } from "#batteries-included-components/Layouts/RecordConfiguration/FormConfigurationFilterPanel";
import { FunctionSelector } from "#batteries-included-components/Layouts/RecordConfiguration/FunctionSelector";
import { RecordValueConfigurationContext } from "#batteries-included-components/Layouts/RecordConfiguration/RecordConfigurationContext";
import { DEFAULT_QUERY_OPTIONS } from "#hooks/adapters/adapterUtils";
import { useParams } from "#routers/hooks";
import { linkToFormSubmissionDetail } from "#routers/links";
import { RoutingLink } from "#src/batteries-included-components/RoutingLink";
import {
  useClientSideSortingAndPagination,
  useTableSortingAndPagination,
} from "#src/components/Redux/reducers/tableStateReducer";
import { useListFormSubmissions } from "#src/components/hooks/adapters/useFormSubmissions";
import { useStorageKey } from "#src/hooks/useStorageKey";
import { linkToAssetDetailPage } from "#utils/links";
import {
  getAssetIdsByType,
  getSourceName,
  getSubjectIdFromSearchParams,
} from "#utils/recordUtils";
import { UseQueryOptions, useQueries, useQuery } from "@tanstack/react-query";
import {
  Column,
  DataTable,
  DataTablePanel,
  HeaderType,
  Row,
  useFilters,
} from "@validereinc/common-components";
import {
  AssetType,
  AssetTypeType,
  EquipmentDomain,
  EquipmentType,
  FacilityDomain,
  FacilityType,
  FlowDomain,
  FlowType,
  FormCategoryAdapter,
  FormResponseDataItemType,
  FormSchemaQuestionType,
  FormSubmission,
  FormSubmissionAnswerType,
  MeasurementAnswerType,
  MeasurementsDomain,
  RecordValueConfigurationTypeType,
  SeriesResponseDataItemType,
  SortDirection,
  UserType,
  UsersAdapter,
  isMeasurementField,
  type FormSubmissionType,
} from "@validereinc/domain";
import React, { useContext, useEffect, useMemo, useState } from "react";

const getQuestionByMeasurementType = (
  measurementType?: string,
  formSubmission?: FormSubmissionType
) => {
  if (!measurementType || !formSubmission) {
    return undefined;
  }

  const relevantQuestion = Object.entries(
    formSubmission?.form_schema?.config.questions ?? {}
  ).find(
    ([_, question]: [string, FormSubmissionAnswerType]) =>
      isMeasurementField(question) &&
      question?.measurement_type === measurementType
  );

  if (!relevantQuestion) {
    return;
  }

  const [questionId, question] = relevantQuestion;

  /* Because there's no way to link the measurement to a specific question, we just grab the
     first one of the appropriate measurement type on the submission */
  const answer = Object.values(formSubmission.answers)
    .flat()
    .find(({ [questionId]: value }) => value)?.[questionId];

  return { question, questionId, answer: answer as MeasurementAnswerType };
};

type FormConfigurationItemType = FormResponseDataItemType & {
  questionId?: string;
  question?: FormSchemaQuestionType;
  answer?: MeasurementAnswerType;
  form_submission?: FormSubmissionType;
  user?: UserType;
  asset?: FacilityType | EquipmentType | FlowType;
};

export const FormConfiguration = () => {
  const params = useParams<{ measurementType: string }>();

  const { filterConfigStorageKey, tableConfigStorageKey } = useStorageKey(
    "record-source-configuration-panel-forms-tab"
  );
  const [storedFilters] = useFilters<{
    asset_type: string;
    formCategory: string;
    formSchema: string;
    from: string;
    to: string;
  }>(filterConfigStorageKey);

  const { measurementType, form, record } =
    useContext(RecordValueConfigurationContext) || {};

  const [selected, setSelectedState] = useState<
    Record<string, SeriesResponseDataItemType>
  >({});

  const setSelected = (selectedItems: Record<string, any>) => {
    setSelectedState(selectedItems);
    form?.setValue("measurement_ids", Object.keys(selectedItems));
  };

  const subjectType = storedFilters.asset_type;
  const subjectId = getSubjectIdFromSearchParams(storedFilters);

  const formCategoryId = storedFilters.formCategory;

  const formCategoryQuery = useQuery({
    queryKey: ["formCategories", formCategoryId],
    queryFn: () => FormCategoryAdapter.getOne({ id: formCategoryId }),
    ...DEFAULT_QUERY_OPTIONS,
    enabled: !!formCategoryId,
  });

  const formCategory = formCategoryQuery?.data;

  const formSchemaId = storedFilters.formSchema;

  useEffect(() => {
    form?.setValue("form_category_id", formCategoryId);
  }, [formCategoryId]);

  /** Get all relevant measurements */
  const measurementPlotParams = {
    measurementType: params.measurementType,
    ...(subjectType && subjectId ? { subjectType, subjectId } : {}),
    start: storedFilters.from,
    end: storedFilters.to,
    include_forms: "true",
  };

  const isMeasurementPlotQueryEnabled =
    !!storedFilters.from && !!storedFilters.to;

  const measurementPlotQuery = useQuery({
    queryKey: ["measurementsPlot", measurementPlotParams],
    queryFn: () =>
      MeasurementsDomain.getMeasurementPlotByMeasurementType(
        measurementPlotParams
      ),
    enabled: isMeasurementPlotQueryEnabled,
  });

  const formSubmissionIds = Array.from(
    new Set(
      measurementPlotQuery.data?.form?.data
        .filter(({ form_category: { id } }) => id === formCategoryId)
        .map((item) => item.form_submission_id)
    )
  );

  /** Get Form Submissions */
  const formSubmissionParams: Parameters<typeof FormSubmission.getList>[0] = {
    filters: {
      id: formSubmissionIds,
    },
    meta: {
      answers: true,
    },
  };

  const isFormSubmissionQueryEnabled = !!formSubmissionIds.length;

  const formSubmissionQuery = useListFormSubmissions(formSubmissionParams, {
    enabled: isFormSubmissionQueryEnabled,
  });

  const formSubmissions = formSubmissionQuery.data?.data ?? [];

  const findSubmission = (submissionId?: string) =>
    formSubmissions.find(({ id }) => id === submissionId);

  /** Get Form Users */
  const userQueries = useQueries<
    Array<
      UseQueryOptions<
        Awaited<ReturnType<typeof UsersAdapter.getOne>> | undefined,
        unknown,
        UserType | undefined
      >
    >
  >({
    queries: Array.from(
      new Set(formSubmissions.map(({ created_by }) => created_by) ?? [])
    ).map((id) => ({
      queryKey: ["users", id],
      queryFn: () => {
        return UsersAdapter.getOne({ id });
      },
      staleTime: 5 * 60 * 1000,
      select: (resp) => resp?.data,
    })),
  });

  const usersMap = useMemo(() => {
    return userQueries.reduce<Record<string, UserType>>((map, q) => {
      if (!q.data?.id || map[q.data.id]) {
        return map;
      }

      map[q.data.id] = q.data;
      return map;
    }, {});
  }, [userQueries]);

  const measurements =
    measurementPlotQuery.data?.form?.data
      .filter(
        (item) =>
          (!formCategoryId || item.form_category.id === formCategoryId) &&
          (!formSchemaId || item.form_schema.id === formSchemaId)
      )
      .map((item) => {
        const form_submission = findSubmission(item.form_submission_id);
        const user = form_submission?.created_by
          ? usersMap[form_submission.created_by]
          : null;

        const details = getQuestionByMeasurementType(
          params.measurementType,
          form_submission
        );

        return {
          ...item,
          ...(details ?? {}),
          form_submission,
          ...(user ? { user } : {}),
        };
      }) ?? [];

  const assets = measurements.map(({ answer }) => ({
    id: answer?.subject_id,
    type: answer?.subject_type,
  }));

  const equipmentParams = {
    filters: { id: getAssetIdsByType(assets, AssetType.EQUIPMENT) },
  };
  const equipmentQuery = useQuery({
    queryKey: ["equipment", equipmentParams],
    queryFn: () => EquipmentDomain.getEquipment(equipmentParams),
    enabled: !!equipmentParams.filters.id.length,
    select: ({ data }) => data,
  });

  const facilityParams = {
    filters: { id: getAssetIdsByType(assets, AssetType.FACILITY) },
  };
  const facilityQuery = useQuery({
    queryKey: ["facilities", facilityParams],
    queryFn: () => FacilityDomain.getList(facilityParams),
    enabled: !!facilityParams.filters.id.length,
    select: ({ data }) => data,
  });

  const flowParams = {
    filters: { id: getAssetIdsByType(assets, AssetType.FLOW) },
  };
  const flowQuery = useQuery({
    queryKey: ["flows", flowParams],
    queryFn: () => FlowDomain.getFlows(flowParams),
    enabled: !!flowParams.filters.id.length,
    select: ({ data }) => data,
  });

  const getAsset = (assetType: AssetTypeType, assetId: string) => {
    switch (assetType) {
      case AssetType.EQUIPMENT:
        return equipmentQuery.data?.find(({ id }) => assetId === id);
      case AssetType.FACILITY:
        return facilityQuery.data?.find(({ id }) => assetId === id);
      case AssetType.FLOW:
      default:
        return flowQuery.data?.find(({ id }) => assetId === id);
    }
  };

  const isLoading =
    (isMeasurementPlotQueryEnabled && measurementPlotQuery.isLoading) ||
    (isFormSubmissionQueryEnabled && formSubmissionQuery.isLoading) ||
    userQueries.some((q) => q.isLoading) ||
    (!!equipmentParams.filters.id.length && equipmentQuery.isLoading) ||
    (!!facilityParams.filters.id.length && facilityQuery.isLoading) ||
    (!!formCategoryId && formCategoryQuery.isLoading) ||
    (!!flowParams.filters.id.length && flowQuery.isLoading);

  // REVIEW: FormConfigurationItemType doesn't seem to be what the items are
  const headers: Array<HeaderType<FormConfigurationItemType>> = [
    {
      key: "form_submission.form_schema.name",
      label: "Name",
      renderComponent: ({ item }) => (
        <RoutingLink to={linkToFormSubmissionDetail(item?.form_submission_id)}>
          {getSourceName(
            RecordValueConfigurationTypeType.MEASUREMENT,
            {
              form_schema_name: item?.form_schema?.name,
              id: item?.id,
              form_submission_id: item?.form_submission_id,
            },
            item
          )}
        </RoutingLink>
      ),
    },
    {
      key: "asset.name",
      label: "Asset",
      renderComponent: ({ item }) =>
        item.answer?.subject_type &&
        item.answer?.subject_id &&
        item.asset?.name ? (
          <RoutingLink
            to={linkToAssetDetailPage(
              item.answer?.subject_type,
              item.answer?.subject_id
            )}
          >
            {item.asset?.name}
          </RoutingLink>
        ) : (
          "-"
        ),
    },
    {
      key: `measure_value`,
      label: measurementType?.name,
      renderComponent: ({ item }) => (
        <DataTable.DataRow.NumberCell value={item.measure_value} />
      ),
    },
    {
      key: "time",
      label: "Date",
      renderComponent: ({ item }) => (
        <DataTable.DataRow.DateCell value={item.time} />
      ),
    },
    {
      key: `form_submission.created_at`,
      label: "Submitted On",
      renderComponent: ({ item }) => (
        <DataTable.DataRow.DateCell value={item.form_submission?.created_at} />
      ),
    },
    {
      key: `user.name`,
      label: "Submitted By",
    },
  ];

  const rawItems = measurements.map((item) => ({
    ...item,
    asset: getAsset(item.answer?.subject_type, item.answer?.subject_id),
  }));

  const sorting = {
    sortBy: "form_submission.form_schema.name",
    sortDirection: SortDirection.ASCENDING,
  };

  const [tableState, updateTableState] = useTableSortingAndPagination(sorting);

  const { items, pagination } = useClientSideSortingAndPagination(
    rawItems,
    tableState
  );

  useEffect(() => {
    if (measurementPlotQuery.isLoading) return;
    const initialSelection = Object.fromEntries(
      (
        record?.values.find((v) => v.measurement_type === measurementType?.id)
          ?.configuration.measurement_ids ?? []
      )
        .map((selectedId: string) => {
          const findFormSubmission = items.find(({ id }) => id === selectedId);
          return findFormSubmission ? [selectedId, findFormSubmission] : null;
        })
        .filter((e) => !!e)
    );
    setSelected(initialSelection);
  }, [
    record,
    measurementType,
    measurementPlotQuery.isLoading,
    formCategoryId,
    formSchemaId,
  ]);

  return (
    <>
      <FormConfigurationFilterPanel storageKey={filterConfigStorageKey} />
      <Row>
        <Column variant={18}>
          <DataTablePanel
            panelProps={{ title: formCategory?.name }}
            dataTableProps={{
              headers: items.length ? headers : [],
              items,
              emptyStateProps: {
                title: formCategory
                  ? `There are no submissions of category ${formCategory?.name} with ${measurementType?.name} measurements`
                  : "Select a Form Category",
                suggestion: formCategory ? "Try changing your source type" : "",
              },
              isLoading,
              getItemId: (item: FormConfigurationItemType) => item.id,
              selectable: true,
              selected: items.length ? selected : undefined,
              onSelectionChange: (newSelection: Record<string, any>) => {
                setSelected(newSelection);
              },
              sorting,
              pagination,
              onSortChange: updateTableState,
              onPaginationChange: updateTableState,
            }}
            storageKey={tableConfigStorageKey}
          />
        </Column>
        <Column variant={6}>
          <FunctionSelector
            values={Object.values(selected).map(
              (item) => item.measure_value ?? 0
            )}
            isLoading={isLoading}
          />
        </Column>
      </Row>
    </>
  );
};
