import { WorkflowTemplatesRoutePath } from "#src/routes/workflows/templates";
import { RoutePath } from "#src/utils/route";

export const WorkflowTemplateDetailsRoutePath =
  WorkflowTemplatesRoutePath.concat(
    new RoutePath<"workflowTemplateId">({
      path: "/:workflowTemplateId",
      title: "Workflow Template",
    })
  );
