// With a font-size of 13px, the average character width is 8px for Roboto font
export const CHARACTER_WIDTH_IN_PIXELS = 8;

/**
 * Useful for describing the width of a group of columns with variable header
 * label width but roughly the same width in cell values (e.g. Mole Percentage
 * measurement columns)
 */
export function getColumnWidth(label, minColumnWidth) {
  const labelLength = label?.length ?? 0;

  return Math.max(minColumnWidth, labelLength * CHARACTER_WIDTH_IN_PIXELS);
}
