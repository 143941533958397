export const ESTIMATION_METHOD_LIST_PAGE_TITLE =
  "Estimation Methods:equipmentId";

export const linkToEstimationMethodList = (equipmentId = ":equipmentId") =>
  `/app/organization/equipment/${equipmentId}/detail?tab=estimationMethods`;

export const ESTIMATION_METHOD_LIST_BREADCRUMB = {
  title: ESTIMATION_METHOD_LIST_PAGE_TITLE,
  path: linkToEstimationMethodList,
};
