export const CUSTOM_REPORT_DETAIL_PAGE_TITLE = "Custom Report";
export const linkToCustomReportDetail = (
  reportVariant = ":reportVariant",
  reportId = ":reportId"
) => `/app/custom-reports/${reportVariant}/${reportId}/detail`;
export const CUSTOM_REPORT_DETAIL_BREADCRUMB = {
  title: CUSTOM_REPORT_DETAIL_PAGE_TITLE,
  path: linkToCustomReportDetail,
};
export type CustomReportDetailParamType = {
  reportVariant: string;
  reportId: string;
};

export * from "./CustomReportDetailPage";
