import {
  ActivityLogsDetailsKeyValueList,
  ActivityLogsUpdateDetailsKeyValueList,
} from "#src/batteries-included-components/KeyValueList";
import { ActivityLogsActionKeyValueList } from "#src/batteries-included-components/KeyValueList/ActivityLogsActionDetails";
import { ActivityLogsCreateDetailsKeyValueList } from "#src/batteries-included-components/KeyValueList/ActivityLogsCreateDetails/ActivityLogsCreateDetailsKeyValueList";
import { Drawer, DrawerProps } from "@validereinc/common-components";
import { ActivityAction, ActivityType } from "@validereinc/domain";
import classNames from "classnames/bind";
import React from "react";
import styles from "./ActivityLogsDrawer.module.scss";

const cx = classNames.bind(styles);

export const ActivityLogsDrawer = ({
  onClose,
  logDetails,
}: {
  logDetails: ActivityType | null;
} & Pick<DrawerProps, "onClose">) => {
  return (
    <Drawer
      size="md"
      isOpen={!!logDetails}
      onClose={onClose}
      title="Change Details"
    >
      <div className={cx("key-value-container")}>
        <ActivityLogsActionKeyValueList selectedLog={logDetails} />
      </div>

      <div className={cx("key-value-container")}>
        <ActivityLogsDetailsKeyValueList selectedLog={logDetails} />
      </div>

      {logDetails?.action === ActivityAction.UPDATE ? (
        <div className={cx("key-value-container")}>
          <ActivityLogsUpdateDetailsKeyValueList selectedLog={logDetails} />
        </div>
      ) : null}

      {logDetails?.action === ActivityAction.CREATE ? (
        <div className={cx("key-value-container")}>
          <ActivityLogsCreateDetailsKeyValueList selectedLog={logDetails} />
        </div>
      ) : null}
    </Drawer>
  );
};
