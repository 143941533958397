import {
  SavedFilterTag,
  useSavedFilters,
} from "#hooks/FilterPanel/useSavedFilters";
import { useFacilityFilters } from "#hooks/filters/useFacilityFilters";
import { DEFAULT_DATE_RANGES } from "#src/hooks/useDateRange";
import {
  FilterPanel,
  StorageKeys,
  useFilters,
} from "@validereinc/common-components";
import { AssetType, FacilityDomain } from "@validereinc/domain";
import React from "react";

export const FacilityListFilterPanel = ({
  filterConfigStorageKey,
}: Pick<StorageKeys, "filterConfigStorageKey">) => {
  const filters = useFacilityFilters({
    isWithCustomAttributes: true,
    isWithPeriod: true,
  });

  const [_, setFilters] = useFilters<any>(filterConfigStorageKey);

  const { filterProps } = useSavedFilters({
    queryKey: [AssetType.FACILITY],
    savedFilterResourceAdapter: FacilityDomain.savedFilters,
    setFilters,
    filterKeyBlacklist: ["period.from", "period.to"],
    tag: SavedFilterTag.LIST,
  });

  return (
    <FilterPanel
      {...filterProps}
      storageKey={filterConfigStorageKey}
      defaultActiveKeys={["Facilities"]}
      defaultValue={{
        period: DEFAULT_DATE_RANGES.currentWholeMonth,
      }}
      filters={filters}
    />
  );
};
