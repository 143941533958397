import React from "react";
import * as PropTypes from "prop-types";
import DescriptionPanel from "../Shared/DescriptionPanel/DescriptionPanel";
import DescriptionRow from "../Shared/DescriptionPanel/DescriptionRow";

const AntonPaarDensityDescriptionPanel = (props) => (
  <DescriptionPanel>
    <DescriptionRow
      title="Instrument ID"
      value={props.instrumentInfo.name}
      icon="hashtag"
    />

    <DescriptionRow
      title="Type"
      value="Anton Paar Density Meter"
      icon="cube"
    />

    <DescriptionRow
      title="Serial Number"
      value={props.instrumentInfo.current_serial_number}
      icon="laptop"
    />

    <DescriptionRow
      title="Location"
      value={props.instrumentInfo.location}
      icon="map-marker"
    />
  </DescriptionPanel>
);

AntonPaarDensityDescriptionPanel.propTypes = {
  instrumentInfo: PropTypes.object.isRequired,
};

export default AntonPaarDensityDescriptionPanel;
