import React from "react";
import { SinglePropertyPillPanel } from "../SinglePropertyPillPanel";
import { useGetOneReportingGroup } from "#src/components/hooks/adapters/useReportingGroups";

const ReportingGroupPillPanel = ({
  id,
  isLoading,
}: {
  id?: string;
  isLoading: boolean;
}) => {
  const reportingGroupQuery = useGetOneReportingGroup(id);

  return (
    <SinglePropertyPillPanel
      values={[reportingGroupQuery.data?.data?.name ?? ""]}
      panelProps={{
        title: "Reporting Scenario",
        loaded: !isLoading && !reportingGroupQuery.isLoading && !!id,
        isFluidY: false,
      }}
      emptyStateProps={{ title: "" }}
      pillProps={{ hasDot: false, variant: "primary" }}
    />
  );
};

export default ReportingGroupPillPanel;
