import { getFilterObject } from "#utils/api-abstraction";
import {
  apiRequestServicesPromise,
  nodeApiDeleteServicesPromise,
  nodeApiPostServicesPromise,
  nodeApiPutServicesPromise,
  nodeApiRequestServicesPromise,
} from "./ServiceHelper";

import type { PaginationAndSortParams } from "./types";

const FacilityService = {
  getFacilities() {
    return apiRequestServicesPromise("/api/facilities");
  },

  getFacilityDetail(id) {
    return nodeApiRequestServicesPromise(`/facilities/${id}`);
  },

  getFacilitiesList({
    page,
    rowPerPage,
    sort,
    sortDirection,
    filters,
  }: Partial<PaginationAndSortParams & { filters: any }>) {
    const { name, status, ...restFilters } = filters || {};

    const formattedFilters = getFilterObject(restFilters);

    const filter = { ...formattedFilters };

    if (name) {
      filter.name = { $like: name };
    }

    if (status) {
      filter.status = status;
    }

    return nodeApiPostServicesPromise("/facilities/search", {
      page,
      page_size: rowPerPage,
      sort_by: sort,
      sort_direction: sortDirection,
      filter,
    });
  },

  getEquipmentList(params) {
    return nodeApiRequestServicesPromise("/equipment", params);
  },

  getEquipmentListV2({
    page,
    rowPerPage,
    sort,
    sortDirection,
    filters,
  }: Partial<PaginationAndSortParams & { filters: any }>) {
    const { name, ...restFilters } = filters || {};

    const formattedFilters = getFilterObject(restFilters);

    const filter = { ...formattedFilters };

    if (name) {
      filter.name = { $like: name };
    }

    return nodeApiPostServicesPromise("/equipment/search", {
      page,
      page_size: rowPerPage,
      sort_by: sort,
      sort_direction: sortDirection,
      filter,
    });
  },

  addFacility(parameters) {
    return nodeApiPostServicesPromise("/facilities", parameters);
  },

  editFacility(parameters, facilityId) {
    return nodeApiPutServicesPromise(`/facilities/${facilityId}`, parameters);
  },

  deleteFacility(facilityId) {
    return nodeApiDeleteServicesPromise(`/facilities/${facilityId}`);
  },

  getEquipmentTypes() {
    return nodeApiRequestServicesPromise(`/equipment_types`);
  },

  getEquipmentDetail(equipmentId) {
    return nodeApiRequestServicesPromise(`/equipment/${equipmentId}`);
  },

  deleteEquipment(equipmentId) {
    return nodeApiDeleteServicesPromise(`/equipment/${equipmentId}`);
  },

  getCustomAttributes() {
    return nodeApiRequestServicesPromise(
      "/custom_attribute_definition/facility?page_size=1000"
    );
  },

  getEquipmentCustomAttributes() {
    return nodeApiRequestServicesPromise(
      "/custom_attribute_definition/equipment?page_size=1000"
    );
  },

  getSavedFacilityFilters({
    page,
    rowPerPage,
    sort,
    sortDirection,
  }: Partial<PaginationAndSortParams>) {
    return nodeApiRequestServicesPromise("/facilities/filters", {
      page,
      page_size: rowPerPage,
      sort_by: sort,
      sort_direction: sortDirection,
    });
  },

  saveFacilityFilter(name, filters) {
    return nodeApiPostServicesPromise("/facilities/filters", {
      name,
      filter: getFilterObject(filters),
    });
  },

  editFacilityFilter(id, filter) {
    return nodeApiPutServicesPromise(`/facilities/filters/${id}`, filter);
  },

  deleteFacilityFilter(id) {
    return nodeApiDeleteServicesPromise(`/facilities/filters/${id}`);
  },
};

export default FacilityService;
