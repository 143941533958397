import { parseCustomReportConfiguration } from "#batteries-included-components/Layouts/CustomReport/CustomReportConfigurationPanel/CustomReportConfigurationPanel.helpers";
import { CustomReportDetailLayout } from "#batteries-included-components/Layouts/CustomReport/CustomReportDetailLayout";
import {
  CustomReportVariants,
  getCustomReportFilters,
  getCustomReportGroupBy,
} from "#batteries-included-components/Layouts/CustomReport/CustomReportDetailLayout.helpers";
import { CreateCustomReportModal } from "#batteries-included-components/Modals/CustomReports/CreateCustomReportModal";
import { useBreadcrumbs } from "#routers/breadcrumbsHelper";
import { useNavigate } from "#routers/hooks";
import { VOLUME_BREADCRUMB } from "#routes/volume";
import { VOLUME_CUSTOM_REPORT_LIST_BREADCRUMB } from "#routes/volume/custom-reports";
import { linkToVolumeCustomReportDetail } from "#routes/volume/custom-reports/[reportId]/detail";
import { CREATE_VOLUME_CUSTOM_REPORT_BREADCRUMB } from "#routes/volume/custom-reports/create/index";
import {
  CustomStorageKeyTypes,
  useCustomStorageKey,
  useStorageKey,
} from "#src/hooks/useStorageKey";
import { Button, Page, useFilters } from "@validereinc/common-components";
import { getFilterObject } from "@validereinc/domain";
import React, { useEffect, useState } from "react";

const VARIANT = CustomReportVariants.VOLUMETRIC;
export const CreateVolumeCustomReportPage = () => {
  const navigate = useNavigate();
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
  const storageKeys = useStorageKey("volume-custom-report-detail");
  const configStorageKey = useCustomStorageKey(
    "volume-custom-report-detail",
    CustomStorageKeyTypes.CONFIG
  );

  const [filters] = useFilters(storageKeys.filterConfigStorageKey);
  const [configuration, setConfiguration] = useFilters(configStorageKey);

  const { rows } = parseCustomReportConfiguration(configuration);

  useEffect(() => {
    setConfiguration({});
  }, []);

  const filter = getFilterObject(
    getCustomReportFilters(VARIANT, configuration, filters)
  );

  return (
    <>
      <Page
        category="Volume Custom Report"
        title="Unsaved Volume Report"
        actionRow={[
          <Button
            key="save-custom-report"
            variant="primary"
            onClick={() => setIsSaveModalOpen(true)}
          >
            Save Report
          </Button>,
        ]}
        breadcrumbs={useBreadcrumbs([
          VOLUME_BREADCRUMB,
          VOLUME_CUSTOM_REPORT_LIST_BREADCRUMB,
          CREATE_VOLUME_CUSTOM_REPORT_BREADCRUMB,
        ])}
      >
        <CustomReportDetailLayout
          configStorageKey={configStorageKey}
          reportVariant={VARIANT}
          {...storageKeys}
        />
      </Page>
      <CreateCustomReportModal
        variant={VARIANT}
        isOpen={isSaveModalOpen}
        onClose={() => {
          setIsSaveModalOpen(false);
        }}
        onSave={(id) => {
          navigate({ pathname: linkToVolumeCustomReportDetail(id) });
        }}
        filter={filter}
        groupBy={getCustomReportGroupBy(VARIANT, rows)}
      />
    </>
  );
};
