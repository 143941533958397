import { VolumeCustomReportListTab } from "#batteries-included-components/Tabs/CustomReports/VolumeCustomReportListTab";
import { VOLUME_BREADCRUMB } from "#routes/volume";
import {
  VOLUME_CUSTOM_REPORT_LIST_BREADCRUMB,
  VOLUME_CUSTOM_REPORT_LIST_PAGE_TITLE,
} from "#routes/volume/custom-reports/index";
import { Page } from "@validereinc/common-components";
import React from "react";

export const VolumeCustomReportListPage = () => {
  return (
    <Page
      title={VOLUME_CUSTOM_REPORT_LIST_PAGE_TITLE}
      breadcrumbs={[VOLUME_BREADCRUMB, VOLUME_CUSTOM_REPORT_LIST_BREADCRUMB]}
    >
      <VolumeCustomReportListTab isOldSidebarLocation />
    </Page>
  );
};
