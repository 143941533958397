import uniq from "lodash/uniq";
import intersection from "lodash/intersection";
import intersectionBy from "lodash/intersectionBy";
import uniqBy from "lodash/uniqBy";
import find from "lodash/find";
import sortBy from "lodash/sortBy";
import { COMPONENTS } from "./BalanceUpserts/BalancesUpsertsConstant";
import indexOf from "lodash/indexOf";

function noComponentMatch(components, balanceComponents) {
  return intersection(components, balanceComponents).length === 0;
}

function noSourceMatch(sources, balanceSources) {
  return intersectionBy(sources, balanceSources, "id").length === 0;
}

function noTypeMatch(type, balanceType) {
  return indexOf(type, balanceType) === -1;
}

function getStreamDetail(streams, streamId) {
  const streamDetail = find(streams, { id: streamId });

  if (streamDetail) {
    return streamDetail;
  } else {
    return {};
  }
}

export function filterBalancesList(balancesList, queryParams) {
  const { type, inlets, outlets, references, components } = queryParams;

  const filteredBalancesList = balancesList.filter((balance) => {
    if (type.length > 0 && noTypeMatch(type, balance.type)) {
      return false;
    }

    if (inlets.length > 0 && noSourceMatch(inlets, balance.inlet_streams)) {
      return false;
    }

    if (outlets.length > 0 && noSourceMatch(outlets, balance.outlet_streams)) {
      return false;
    }

    if (
      references.length > 0 &&
      noSourceMatch(references, balance.reference_streams)
    ) {
      return false;
    }

    if (
      components.length > 0 &&
      noComponentMatch(components, balance.components)
    ) {
      return false;
    }

    return true;
  });

  return filteredBalancesList;
}

export function getUniqTypes(balancesList) {
  const allTypes = balancesList.reduce(
    (type, balance) => [...type, balance.type],
    []
  );

  return uniq(allTypes);
}

export function getUniqComponents(balancesList) {
  const allComponents = balancesList.reduce(
    (components, balance) => [...components, ...balance.components],
    []
  );

  return uniq(allComponents);
}

export function getUniqStream(balancesList, source, streams) {
  const allStreams = balancesList.reduce(
    (streams, balance) => [...streams, ...balance[source]],
    []
  );

  const uniqStreams = uniqBy(allStreams, "id");
  const uniqStreamsWithDetail = uniqStreams.map((stream) =>
    getStreamDetail(streams, stream.id)
  );

  return uniqStreamsWithDetail;
}

export function sortBySource(list, columnKey) {
  return [...list].sort((a, b) => {
    const aNumStreams = a[`${columnKey}_streams`]?.length ?? 0;
    const aNumInventories = a[`${columnKey}_inventories`]?.length ?? 0;

    const bNumStreams = b[`${columnKey}_streams`]?.length ?? 0;
    const bNumInventories = b[`${columnKey}_inventories`]?.length ?? 0;

    const aValue = aNumStreams + aNumInventories ?? -1;
    const bValue = bNumStreams + bNumInventories ?? -1;

    return aValue - bValue;
  });
}

export function sortByName(list, columnKey) {
  return [...list].sort((a, b) => {
    const aValue = a[columnKey]?.name ?? "-";
    const bValue = b[columnKey]?.name ?? "-";

    return aValue.localeCompare(bValue);
  });
}

export function sortByComponents(list, columnKey) {
  return [...list].sort((a, b) => {
    const aValue = a[columnKey]?.length ?? "-";
    const bValue = b[columnKey]?.length ?? "-";

    return aValue - bValue;
  });
}

export function sortComponents(componentList) {
  return sortBy(componentList, (component) => {
    return indexOf(COMPONENTS, component);
  });
}
