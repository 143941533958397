import React, { useMemo } from "react";
import SidePanelSection from "#common/SidePanel/SidePanelSection";
import RecordOfQualitySampleChart from "./RecordOfQualitySampleChart";
import DropdownInputWithSearch from "../../../../../Inputs/DropdownInputWithSearch";
import DateRangeSelector from "#common/DateSelector/DateRangeSelector";
import RecordOfQualityAlarms from "./RecordOfQualityAlarms";
import "./RecordOfQualitySelection.scss";
import RecordOfQualitySampleSelections from "./RecordOfQualitySampleSelections";
import { isAdjustableMethod } from "../../../RecordOfQualityHelper";
import { useSelectionModalContext } from "../../Context/selectionModalContext";

const RecordOfQualitySelection = () => {
  const {
    onSampleClick,
    accountingRecordDetail,
    roqSelectionState,
    hasWriteAccess,
    streams,
    stream,
    displayProperties,
    onMeasurementSelect,
    onDateSelect,
    onStreamSelect,
    measurement,
    from,
    until,
    accountingPeriod,
  } = useSelectionModalContext();

  // Clicking and adding samples to selection intervals is only enabled when
  // there is only one interval (does not add sample to multiple intervals).
  // The method for that one interval should be adjustable (able to change
  // sample chosen)
  const qualityChartSelectionEnabled = useMemo(() => {
    const { intervalSelections } = roqSelectionState;

    return (
      hasWriteAccess &&
      intervalSelections.length === 1 &&
      isAdjustableMethod(intervalSelections[0].method)
    );
  }, [roqSelectionState.intervalSelections, hasWriteAccess]);

  return (
    <div className="recordOfQualitySelection">
      {accountingRecordDetail.alarms?.sample_alarms.length ||
      accountingRecordDetail.alarms?.accounting_record_alarms.length ? (
        <RecordOfQualityAlarms alarms={accountingRecordDetail.alarms} />
      ) : null}

      <SidePanelSection subHeader="Qualities">
        <section className="filterRow recordOfQualitySelection__filterRow">
          <section className="recordOfQualitySelection__filterRow__measurement">
            <DropdownInputWithSearch
              value={stream?.name}
              options={streams}
              onSelect={onStreamSelect}
              filterKey={"name"}
            />

            <DropdownInputWithSearch
              value={measurement}
              options={displayProperties}
              onSelect={onMeasurementSelect}
            />

            <DateRangeSelector
              dateRange={{
                from,
                to: until,
              }}
              onDateRangeChange={onDateSelect}
              relativeToDate={() => accountingPeriod?.until}
              icon
            />
          </section>
        </section>

        <RecordOfQualitySampleChart
          onSampleClick={qualityChartSelectionEnabled ? onSampleClick : null}
        />
      </SidePanelSection>

      <RecordOfQualitySampleSelections />
    </div>
  );
};

export default RecordOfQualitySelection;
