import { useCustomAttributeFilters } from "#hooks/FilterPanel/useCustomAttributeFilters";
import {
  DropdownInput,
  FilterPanel,
  StorageKeys,
  TextInput,
} from "@validereinc/common-components";
import { AssetGroupStatus, AssetType } from "@validereinc/domain";
import { toStartCaseString } from "@validereinc/utilities";
import React from "react";

export const NetworkFilterPanel = ({
  filterConfigStorageKey,
}: Pick<StorageKeys, "filterConfigStorageKey">) => {
  const { customAttributeFilters } = useCustomAttributeFilters({
    assetType: AssetType.ASSET_GROUP,
  });

  return (
    <FilterPanel
      storageKey={filterConfigStorageKey}
      defaultActiveKeys={["Filters"]}
      filters={[
        {
          component: (
            <TextInput
              name="name"
              placeholder="Search Networks..."
              type="search"
              isInline
            />
          ),
        },
        {
          section: "Filters",
          component: (
            <DropdownInput
              key="status"
              name="status"
              label="Status"
              options={Object.values(AssetGroupStatus).map((id) => ({
                id,
                name: toStartCaseString(id),
              }))}
              labelKey="name"
              valueKey="id"
              isMulti
              isFluid
              isSearchable
              isOptionalTextShown={false}
            />
          ),
        },
        ...customAttributeFilters,
      ]}
    />
  );
};
