export const TITLE = "Create Data Mapping";
export const UPLOAD_TITLE = "Upload Data";

export const BASIC_INFO_LABEL = "Basic Info";
export const UPLOAD_DATA_LABEL = "Upload Data";
export const DATA_MAPPING_LABEL = "Data Mapping";
export const VALIDATION_LABEL = "Validation";

export const STEPS = [
  { key: "basic_info", label: BASIC_INFO_LABEL },
  { key: "upload", label: UPLOAD_DATA_LABEL },
  { key: "data_mapping", label: DATA_MAPPING_LABEL },
  { key: "validation", label: VALIDATION_LABEL },
];

export const DATA_MAPPING_METHOD = [
  { id: "update", name: "Update existing records" },
  { id: "create", name: "Create new records" },
];

export const UPLOAD_STEPS = [
  { key: "upload", label: UPLOAD_DATA_LABEL },
  { key: "validation", label: VALIDATION_LABEL },
];
