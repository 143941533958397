import { UserListFilterPanel } from "#src/batteries-included-components/Panels/FilterPanels/UserListFilterPanel";
import { UserListTablePanel } from "#src/batteries-included-components/Panels/TablePanels/UserListTablePanel";
import { useStorageKey } from "#src/hooks/useStorageKey";
import React from "react";

export const UserListTab = () => {
  const { filterConfigStorageKey, tableConfigStorageKey } =
    useStorageKey("users");

  return (
    <>
      <UserListFilterPanel filterConfigStorageKey={filterConfigStorageKey} />
      <UserListTablePanel
        filterConfigStorageKey={filterConfigStorageKey}
        tableConfigStorageKey={tableConfigStorageKey}
      />
    </>
  );
};
