import React, { useState, useEffect } from "react";
import * as PropTypes from "prop-types";
import "./DailyVolumeChart.scss";
import {
  XYPlot,
  VerticalBarSeries,
  XAxis,
  YAxis,
  HorizontalGridLines,
  ChartLabel,
} from "react-vis";
import RecordOfVolumeService from "#services/RecordOfVolumeService";
import { AutoSizer } from "react-virtualized";
import {
  getTickFormat,
  getDailyVolumePlotData,
  useHint,
  getXAxisSettings,
  getYAxisLabel,
  getBarWidth,
  getDefaultInterval,
} from "./DailyVolumeChartHelper";
import ReactLoader from "#components/LoadingBar/ReactLoader";
import moment from "moment";
import config from "../../../../config";
import DateRangeSelector from "#common/DateSelector/DateRangeSelector";
import DropdownInputWithSearch from "../../../Inputs/DropdownInputWithSearch";
import { formatDailyVolumeDate } from "./DailyVolumeHelper";
import { getFormattedMeasurementValueWithUnit } from "#redux/reducers/measurements";
import { connect } from "react-redux";

const BAR_WIDTH = 10;

const INTERVAL_OPTIONS = ["daily", "weekly", "monthly"];

const mapStateToProps = (state) => {
  return {
    getFormattedMeasurementValueWithUnit: (measurementKey, measurementObject) =>
      getFormattedMeasurementValueWithUnit(state.measurements)(
        measurementKey,
        measurementObject
      ),
  };
};

const useFetchDailyVolumeData = (streamId, from, to, interval) => {
  const [volumeChartData, setVolumeChartData] = useState([]);
  const [loadingState, setLoadingState] = useState("loading");

  useEffect(() => {
    if (streamId) {
      setLoadingState("loading");

      const formattedFrom = formatDailyVolumeDate(from);
      const formattedTo = formatDailyVolumeDate(to);

      RecordOfVolumeService.getRecordOfVolumeChart(
        streamId,
        formattedFrom,
        formattedTo,
        interval
      )
        .then(({ data }) => {
          setVolumeChartData(data);
          setLoadingState("loaded");
        })
        .catch(() => {
          setLoadingState("error");
        });
    }
  }, [streamId, from, to, interval]);

  return [loadingState, volumeChartData];
};

const DailyVolumeChart = (props) => {
  const { streamId, from, to, setQueryParams } = props;

  const [interval, setInterval] = useState(getDefaultInterval(to, from));
  const [loadingState, volumeChartData] = useFetchDailyVolumeData(
    streamId,
    from,
    to,
    interval
  );
  const [hint, setHintData] = useHint(
    props.getFormattedMeasurementValueWithUnit
  );
  const [minX, maxX, xTickValues] = getXAxisSettings(volumeChartData, from, to);
  const yAxisLabel = getYAxisLabel(volumeChartData);

  const onIntervalSelect = (selectedInterval) => {
    setInterval(selectedInterval);
  };

  useEffect(() => {
    setInterval(getDefaultInterval(to, from));
  }, [to, from]);

  const onDateSelect = (from, to) => {
    setQueryParams({
      from: moment.utc(from).format(config.DATE_FORMAT),
      to: moment.utc(to).format(config.DATE_FORMAT),
    });
  };

  return (
    <div className="dailyVolumeChartContainer">
      <div className="filterRow">
        {setQueryParams && (
          <DateRangeSelector
            dateRange={{
              from: from,
              to: to,
            }}
            onDateRangeChange={onDateSelect}
            icon
            utc={true}
          />
        )}

        <DropdownInputWithSearch
          title="Frequency"
          value={interval}
          options={INTERVAL_OPTIONS}
          onSelect={onIntervalSelect}
          pullRight
        />
      </div>

      <ReactLoader
        loaded={loadingState === "loaded"}
        position={50}
      >
        <div className="dailyVolumeChart">
          <AutoSizer>
            {({ width, height }) => (
              <XYPlot
                width={width}
                height={height}
                xDomain={[minX, maxX]}
                colorType="literal"
                margin={{ left: 85, right: 35 }}
              >
                <HorizontalGridLines />

                <XAxis
                  position="middle"
                  tickValues={xTickValues}
                  tickPadding={10}
                  tickFormat={(value, index) =>
                    getTickFormat(value, index, xTickValues.length)
                  }
                />

                <YAxis
                  tickFormat={(data) => data}
                  style={{ line: { stroke: "none" } }}
                />

                <ChartLabel
                  text={yAxisLabel}
                  xPercent={0.02}
                  yPercent={0.48}
                  style={{ transform: "rotate(-90)" }}
                />

                <VerticalBarSeries
                  data={getDailyVolumePlotData(volumeChartData)}
                  barWidth={getBarWidth(
                    width,
                    volumeChartData.length,
                    BAR_WIDTH
                  )}
                  onValueMouseOver={(value) => setHintData(value)}
                  onValueMouseOut={() => setHintData(null)}
                />

                {hint}
              </XYPlot>
            )}
          </AutoSizer>
        </div>
      </ReactLoader>
    </div>
  );
};

DailyVolumeChart.propTypes = {
  streamId: PropTypes.string,
  from: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  setQueryParams: PropTypes.func,
  getFormattedMeasurementValueWithUnit: PropTypes.func,
};

export default connect(mapStateToProps)(DailyVolumeChart);
