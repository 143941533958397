import { useBreadcrumbs } from "#src/Routers/breadcrumbsHelper";
import { useNavigate } from "#src/Routers/hooks";
import { AccessDeniedLayout } from "#src/batteries-included-components/Layouts/Authorization/AccessDenied";
import {
  getPermissionDisplayLabel,
  useIsOpsHubExperience,
} from "#src/contexts/AuthenticatedContext.helpers";
import { SETTINGS_BREADCRUMB } from "#src/routes/settings";
import { ROLES_AND_PERMISSIONS_BREADCRUMB } from "#src/routes/settings/roles-and-permissions";
import {
  RoleContext,
  RoleProvider,
} from "#src/routes/settings/roles-and-permissions/roles/[roleId]/detail/RoleContext";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  Button,
  DropdownInput,
  Form,
  Page,
  useAlert,
  useForm,
} from "@validereinc/common-components";
import {
  PermissionsDomain,
  RolePermissionType,
  RolesAdapter,
} from "@validereinc/domain";
import classNames from "classnames/bind";
import React, { useContext } from "react";
import { EDIT_ROLE_PROFILE_BREADCRUMB } from ".";
import { ROLE_DETAILS_PERMISSIONS_BREADCRUMB } from "..";
import { ROLES_BREADCRUMB } from "../../..";
import { ROLE_DETAILS_BREADCRUMB, linkToRoleDetailPage } from "../../detail";
import styles from "./EditRolePermissionsPage.module.scss";

const cx = classNames.bind(styles);

export const EditRolePermissionsPageContent = () => {
  const navigate = useNavigate();
  const { addAlert } = useAlert();
  const { roleQuery, rolePermissionsQuery } = useContext(RoleContext) ?? {};
  const queryClient = useQueryClient();
  const form = useForm({
    defaultValues: {
      permissions:
        rolePermissionsQuery?.data?.map((rolePerm) => rolePerm.name) ?? [],
    },
  });
  const breadcrumbs = useBreadcrumbs(
    [
      SETTINGS_BREADCRUMB,
      ROLES_AND_PERMISSIONS_BREADCRUMB,
      ROLES_BREADCRUMB,
      ROLE_DETAILS_BREADCRUMB,
      ROLE_DETAILS_PERMISSIONS_BREADCRUMB,
      EDIT_ROLE_PROFILE_BREADCRUMB,
    ],
    {
      3: roleQuery?.data?.name,
    },
    { roleId: roleQuery?.data?.id }
  );
  const associatePermissionsMutation = useMutation({
    mutationFn: ({
      roleId,
      selectedPermissions,
    }: {
      roleId: string;
      selectedPermissions: string[];
    }) => {
      // TODO: configuring each permission deeply will come in a future update
      const selectedPermissionsConfigured =
        selectedPermissions.map<RolePermissionType>((perm) => ({
          name: perm,
          active: true,
        }));

      return RolesAdapter.permissions.update({
        id: roleId,
        data: selectedPermissionsConfigured,
      });
    },
    onError: () => {
      addAlert?.({
        variant: "error",
        message: "Failed to configure permissions on role.",
      });
    },
    onSuccess: (_, { roleId }) => {
      queryClient.invalidateQueries({
        queryKey: ["roles", roleId, "permissions"],
      });
      queryClient.invalidateQueries({
        queryKey: ["users"],
      });
      addAlert?.({
        variant: "success",
        message: "Successfully configured permissions on role.",
      });
    },
  });

  const goBack = () =>
    navigate({
      pathname: linkToRoleDetailPage(roleQuery?.data?.id),
      query: {
        tab: "permissions",
      },
    });
  const onSubmit = form.handleSubmit(async (values: any) => {
    const { permissions: selectedPermissions } = values;

    await associatePermissionsMutation.mutateAsync({
      roleId: roleQuery?.data?.id ?? "",
      selectedPermissions,
    });

    goBack();
  });

  const footer = (
    <div className={cx("footerContainer")}>
      <Button
        onClick={goBack}
        disabled={form?.formState.isSubmitting}
      >
        Cancel
      </Button>
      <Button
        variant="primary"
        onClick={onSubmit}
        disabled={roleQuery?.isLoading}
        isLoading={roleQuery?.isLoading ?? form?.formState.isSubmitting}
        type="button"
      >
        Assign Permissions
      </Button>
    </div>
  );

  return (
    <Form {...form}>
      <Page
        title="Assign Permissions"
        category={`Role | ${roleQuery?.data?.name}`}
        breadcrumbs={breadcrumbs}
        isLoading={roleQuery?.isLoading}
        footer={footer}
      >
        <div className={cx("detailsStepContainer")}>
          <DropdownInput
            isDisabled={roleQuery?.isLoading}
            label="Permissions"
            name="permissions"
            labelKey="label"
            valueKey="name"
            onFetchData={async (payload) => {
              let { data } = await PermissionsDomain.getList({
                ...payload,
                filters: {
                  name: payload.searchTerm,
                },
              });

              // REVIEW: not a fan of the fact that we need to do this. A refactor of DropdownInput is needed.
              if (Array.isArray(payload.value)) {
                data = data.filter((d) => payload.value.includes(d.name));
              }

              return data.map((d) => ({
                ...d,
                label: getPermissionDisplayLabel(d.name),
              }));
            }}
            isRequired
            isMulti
            isFluid
          />
        </div>
      </Page>
    </Form>
  );
};

export const EditRolePermissionsPage = () => {
  const [isOpsExperienceEnabled] = useIsOpsHubExperience();

  if (isOpsExperienceEnabled) {
    return <AccessDeniedLayout />;
  }

  return (
    <RoleProvider>
      <EditRolePermissionsPageContent />
    </RoleProvider>
  );
};
