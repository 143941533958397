import React, { useEffect, useState } from "react";
import * as PropTypes from "prop-types";
import { ProgressBar } from "react-bootstrap";
import { List, AutoSizer } from "react-virtualized";
import AnnotationsPanel from "./AnnotationsPanel";
import { getAnnotationsQueue } from "../Services/AnnotationsService";
import "./Annotations.css";

const HEIGHT_OFFSET = 200;

const MIN_HEIGHT = 650;

const POLL_INTERVAL = 5000;

const useFetchTestsForAnnotations = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [testsList, setTestsList] = useState(null);

  const fetch = () => {
    setIsLoading(true);
    setError(null);

    getAnnotationsQueue()
      .then(({ data }) => {
        const sortedTestsList = [...data];
        sortedTestsList.sort((testA, testB) => {
          return new Date(testA.date) - new Date(testB.date);
        });

        setTestsList(sortedTestsList);
        setIsLoading(false);
      })
      .catch(({ message }) => {
        setError(message);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (testsList === null) {
      fetch();
    }

    const cancel = setInterval(() => {
      fetch();
    }, POLL_INTERVAL);

    return () => {
      clearInterval(cancel);
    };
  }, []);

  return [isLoading, error, testsList];
};

const getNoRowsRenderer = () => {
  return <p className="noDataAvailable">No Tests Available</p>;
};

const Annotations = (props) => {
  const { height } = props;

  const annotationsListHeight = Math.max(height - HEIGHT_OFFSET, MIN_HEIGHT);

  const [isLoading, error, testsList] = useFetchTestsForAnnotations();

  if (error) {
    return <div>There was an error</div>;
  }

  /* eslint-disable-next-line react/prop-types */
  const getTaskRowRenderer = ({ index, key, style }) => {
    const test = testsList[index];
    return (
      <AnnotationsPanel
        key={key}
        style={style}
        test={test}
      />
    );
  };

  return (
    <div
      className="annotationsListContainer"
      style={{ height: annotationsListHeight }}
    >
      {isLoading ? (
        <header>
          <ProgressBar
            active
            now={76}
          />
        </header>
      ) : (
        <header>
          <ProgressBar
            active
            now={76}
            bsClass="annotationsListContainer__hide"
          />
        </header>
      )}
      {testsList === null && isLoading ? (
        <div>Loading</div>
      ) : (
        <AutoSizer>
          {({ height, width }) => (
            <List
              width={width}
              height={height}
              rowCount={testsList.length}
              rowHeight={100}
              rowRenderer={getTaskRowRenderer}
              updateVirtualList={testsList}
              noRowsRenderer={getNoRowsRenderer}
            />
          )}
        </AutoSizer>
      )}
    </div>
  );
};

Annotations.propTypes = {
  height: PropTypes.number,
};

export default Annotations;
