export const linkToFormTemplateDetail = (
  formCategoryId = ":categoryId",
  formTemplateId = ":formTemplateId"
) => `/app/forms/categories/${formCategoryId}/templates/${formTemplateId}`;

export const FORM_TEMPLATE_DETAILS_BREADCRUMB = {
  title: "Form Template:categoryId:formTemplateId",
  path: linkToFormTemplateDetail,
};

export * from "./FormTemplateDetailPage";
