import WorkflowService from "#services/WorkflowService";
import {
  Button,
  Form,
  FormButton,
  Modal,
  SelectInput,
  useAlert,
  useForm,
} from "@validereinc/common-components";
import * as PropTypes from "prop-types";
import React, { useState } from "react";
import PopupWorkflowDetail from "../MonthlyView/PopupWorkflowDetail";
import {
  COMPLETED_OPTION,
  DISMISSED_OPTION,
  SAMPLED_OPTION,
} from "./WorkflowTaskConstants";
import {
  canComplete,
  canDismiss,
  cannotChangeStatus,
  canSample,
  getDefaultStatusValue,
} from "./WorkflowTaskHelpers";

const WorkflowTaskDetail = ({
  closeModal,
  refetchWorkflowTasks,
  workflowTask,
}) => {
  const [formState, setFormState] = useState("enabled");
  const { addAlert } = useAlert();

  const form = useForm({
    defaultValues: {
      status: getDefaultStatusValue(workflowTask),
    },
  });

  const onSubmitStatus = (inputs) => {
    setFormState("loading");
    const status = inputs.status;

    return WorkflowService.updateWorkflowInstanceStatus(
      workflowTask.id,
      status.id
    )
      .then((result) => {
        refetchWorkflowTasks();

        const message = `The workflow task status is now ${status.name}`;
        addAlert({
          variant: "success",
          message: message,
        });
        return result;
      })
      .catch(() => {
        setFormState("enabled");
      });
  };

  const statusOptions = [];

  if (canComplete(workflowTask)) {
    statusOptions.push(COMPLETED_OPTION);
  }

  if (canDismiss(workflowTask)) {
    statusOptions.push(DISMISSED_OPTION);
  }

  if (canSample(workflowTask)) {
    statusOptions.push(SAMPLED_OPTION);
  }

  const cannotUpdateStatus =
    cannotChangeStatus(workflowTask) ||
    formState !== "enabled" ||
    statusOptions.length === 0;

  return (
    <Form
      onSubmit={onSubmitStatus}
      {...form}
    >
      <Modal.Body style={{ padding: 0 }}>
        <PopupWorkflowDetail
          eventDetail={workflowTask}
          hideTitle
        />
        <SelectInput
          name="status"
          label="Status"
          labelKey="name"
          options={statusOptions}
          isDisabled={cannotUpdateStatus}
          ignoreCase
          isRequired
        />
      </Modal.Body>

      <Modal.Footer className="clearfix">
        <Button
          className="pull-left"
          onClick={closeModal}
        >
          Close
        </Button>
        <FormButton
          className="pull-right"
          variant="primary"
          type="submit"
          disabled={cannotUpdateStatus}
        >
          Update Status
        </FormButton>
      </Modal.Footer>
    </Form>
  );
};

WorkflowTaskDetail.propTypes = {
  closeModal: PropTypes.func.isRequired,
  refetchWorkflowTasks: PropTypes.func,
  workflowTask: PropTypes.object,
};

export default WorkflowTaskDetail;
