import { getDateObject } from "#common/DateSelector/DateRangeSelectorHelper";
import { formatDailyVolumeDate } from "#components/Records/Volume/DailyVolume/DailyVolumeHelper";
import { getBreadcrumbsObject } from "#routers/breadcrumbsHelper";
import useQueryParams from "#routers/useQueryParams";
import ProductionService from "#services/ProductionService";
import {
  ControlLabel,
  DateRangeSelector,
  Page,
} from "@validereinc/common-components";
import classNames from "classnames/bind";
import moment from "moment";
import * as PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import SingleRecordView from "./SingleRecordView";
import styles from "./StreamDetail.module.scss";

const cx = classNames.bind(styles);

const LAST_DAY_OF_CURRENT_MONTH = moment().endOf("month").endOf("day").toDate();

const FIRST_DAY_OF_LAST_MONTH = moment()
  .subtract(1, "month")
  .startOf("month")
  .startOf("day");

const LAST_DAY_OF_LAST_MONTH = moment()
  .subtract(1, "month")
  .endOf("month")
  .startOf("day");

const DEFAULT_DATE_RANGE = {
  from: FIRST_DAY_OF_LAST_MONTH,
  to: LAST_DAY_OF_LAST_MONTH,
};

const RecordDetail = (props) => {
  const streamId = props.match.params.streamId;
  const [recordDetail, setRecordDetail] = useState({});
  const [loadingState, setLoadingState] = useState("loading");
  const [queryParams, setQueryParams] = useQueryParams(DEFAULT_DATE_RANGE);

  const onChangeDateRange = ({ from }) => {
    setQueryParams({
      from: formatDailyVolumeDate(from),
      to: formatDailyVolumeDate(moment(from).endOf("month").startOf("day")),
    });
  };

  useEffect(() => {
    setLoadingState("loading");
    setRecordDetail({});

    ProductionService.getSingleMonthProductionRecordDetail(
      streamId,
      queryParams.from
    ).then(({ data }) => {
      setLoadingState("loaded");
      setRecordDetail(data.accounting_record);
    });
  }, [queryParams]);

  const breadcrumbs = useMemo(() => {
    const breadcrumbs = getBreadcrumbsObject(
      props.breadcrumbs,
      props.match.params
    );

    if (breadcrumbs[1] && recordDetail) {
      breadcrumbs.push({
        title: recordDetail?.stream?.name ?? "-",
      });
    }

    return breadcrumbs;
  }, [props.breadcrumbs, props.match.params, recordDetail]);

  return (
    <Page
      isLoading={!recordDetail?.stream?.name}
      title={recordDetail?.stream?.name}
      breadcrumbs={breadcrumbs}
    >
      <div className={cx("controls-container")}>
        <ControlLabel label="Date Range">
          <DateRangeSelector
            id="roq-date-range-selector"
            value={{
              from: moment(queryParams.from).toDate(),
              to: moment(queryParams.to).toDate(),
            }}
            onChange={onChangeDateRange}
            align="start"
            hoverRange="monthly"
            disableRelativeDays
            disabledDays={[
              {
                after: getDateObject(LAST_DAY_OF_CURRENT_MONTH),
              },
            ]}
          />
        </ControlLabel>
      </div>

      <SingleRecordView
        recordDetail={recordDetail}
        recordDetailLoadingState={loadingState}
        dateRange={queryParams}
      />
    </Page>
  );
};

RecordDetail.propTypes = {
  match: PropTypes.object.isRequired,
  breadcrumbs: PropTypes.array.isRequired,
};

export default RecordDetail;
