/* Application */
export const APPLICATION_INITIALIZED = "APPLICATION_INITIALIZED";

/* Alerts */
export const FETCH_ALERT = "FETCH_ALERT";
export const FETCH_ALERT_ERROR = "FETCH_ALERT_ERROR";

/* Insights */
export const FETCH_INSIGHT_LIST = "FETCH_INSIGHT_LIST";
export const FETCH_INSIGHT_LIST_ERROR = "FETCH_INSIGHT_LIST_ERROR";

/* Sites */
export const FETCH_SITE_LIST = "FETCH_SITE_LIST";
export const FETCH_SITE_LIST_SUCCESS = "FETCH_SITE_LIST_SUCCESS";
export const FETCH_SITE_LIST_ERROR = "FETCH_SITE_LIST_ERROR";

/* Instruments */
export const FETCH_INSTRUMENT_LIST = "FETCH_INSTRUMENT_LIST";
export const FETCH_INSTRUMENT_LIST_SUCCESS = "FETCH_INSTRUMENT_LIST_SUCCESS";
export const FETCH_INSTRUMENT_LIST_ERROR = "FETCH_INSTRUMENT_LIST_ERROR";

/* Streams */
export const FETCH_STREAM_LIST = "FETCH_STREAM_LIST";
export const FETCH_STREAM_LIST_SUCCESS = "FETCH_STREAM_LIST_SUCCESS";
export const FETCH_STREAM_LIST_ERROR = "FETCH_STREAM_LIST_ERROR";

/* Users */
export const FETCH_USER_PROFILE = "FETCH_USER_PROFILE";
export const FETCH_USER_PROFILE_ERROR = "FETCH_USER_PROFILE_ERROR";

/* Chain of Custody */
export const FETCH_COCS = "FETCH_COCS";
export const FETCH_COCS_ERROR = "FETCH_COCS_ERROR";

/* Authentication and IAM */
export const USER_SIGNED_OUT = "USER_SIGNED_OUT";
export const USER_SIGNED_IN = "USER_SIGNED_IN";

export const FETCH_USER_PERMISSIONS_REQUESTED =
  "FETCH_USER_PERMISSIONS_REQUESTED";
export const USER_PERMISSIONS_RETRIEVED = "USER_PERMISSIONS_RETRIEVED";
export const FETCH_USER_PERMISSIONS_ERROR = "FETCH_USER_PERMISSIONS_ERROR";

/* Workflows  */
export const FETCH_WORKFLOWS = "FETCH_WORKFLOWS";
export const FETCH_WORKFLOWS_ERROR = "FETCH_WORKFLOWS_ERROR";
export const FETCH_WORKFLOW_INSTANCES = "FETCH_WORKFLOW_INSTANCES";
export const FETCH_WORKFLOW_INSTANCES_ERROR = "FETCH_WORKFLOW_INSTANCES_ERROR";
export const START_FETCH_WORKFLOWS = "START_FETCH_WORKFLOWS";

/* Measurement Types */
export const MEASUREMENT_TYPES_RECEIVED = "MEASUREMENT_TYPES_RECEIVED";
export const MEASUREMENT_TYPES_ERROR = "MEASUREMENT_TYPES_ERROR";

/* Alert Messages */
export const ALERT_MESSAGE_CREATED = "ALERT_MESSAGE_CREATED";
export const ALERT_MESSAGE_REMOVED = "ALERT_MESSAGE_REMOVED";
export const ALERT_MESSAGES_CLEARED = "ALERT_MESSAGES_CLEARED";

/* Analyze */
export const UPDATED_TO_ANALYZE = "UPDATED_TO_ANALYZE";
export const ADDED_TO_ANALYZE = "ADDED_TO_ANALYZE";
export const REMOVED_FROM_ANALYZE = "REMOVED_FROM_ANALYZE";

/* Record of Volume */
export const UPDATE_SELECTED_VOLUME = "UPDATE_SELECTED_VOLUME";

/* Feature Flags */
export const FEATURE_FLAGS_REQUESTED = "FEATURE_FLAGS_REQUESTED";
export const FEATURE_FLAGS_RETRIEVED = "FEATURE_FLAGS_RETRIEVED";
export const FEATURE_FLAGS_RETRIEVAL_FAILED = "FEATURE_FLAGS_RETRIEVAL_FAILED";

/* Adjustment Factors */
export const FETCH_ADJUSTMENT_FACTORS = "FETCH_ADJUSTMENT_FACTORS";
export const FETCH_ADJUSTMENT_FACTORS_SUCCESS =
  "FETCH_ADJUSTMENT_FACTORS_SUCCESS";
export const FETCH_ADJUSTMENT_FACTORS_ERROR = "FETCH_ADJUSTMENT_FACTORS_ERROR";
