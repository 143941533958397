import {
  nodeApiPostServicesPromise,
  nodeApiPutServicesPromise,
  nodeApiRequestServicesPromise,
} from "#services/ServiceHelper";
import { getFilterObject } from "#src/utils/api-abstraction";
import mime from "mime";

import type { PaginationAndSortParams } from "./types";

// TODO: migrate to @validereinc/domain
const FormService = {
  listFormCategories({
    page,
    rowPerPage,
    sort,
    sortDirection,
    relatedEntityId,
    relatedEntityType,
  }) {
    if (!page && !rowPerPage) {
      rowPerPage = 1000;
    }
    return nodeApiRequestServicesPromise("/form_categories", {
      page,
      page_size: rowPerPage,
      sort_by: sort,
      sort_direction: sortDirection,
      related_entity_id: relatedEntityId,
      related_entity_type: relatedEntityType,
    });
  },

  listFormSchemas({
    page,
    rowPerPage,
    sort,
    sortDirection,
  }: Partial<PaginationAndSortParams>) {
    if (!page && !rowPerPage) {
      rowPerPage = 1000;
    }
    return nodeApiRequestServicesPromise("/form_schemas", {
      page,
      page_size: rowPerPage,
      sort_by: sort,
      sort_direction: sortDirection,
      status: "active",
    });
  },

  getFormSchema({ formSchemaId }) {
    if (!formSchemaId) {
      return null;
    }

    return nodeApiRequestServicesPromise(`/form_schemas/${formSchemaId}`);
  },

  getFormSubmissionDetail(formSubmissionId) {
    if (formSubmissionId) {
      return nodeApiRequestServicesPromise(
        `/form_submissions/${formSubmissionId}`
      );
    }
  },

  listFormSubmissions({
    page,
    rowPerPage,
    sort,
    sortDirection,
    related_entity_type, // needs to be sent without wrapping in $like query
    ...filters
  }) {
    const filter = getFilterObject(filters);
    return nodeApiPostServicesPromise("/form_submissions/search", {
      page,
      page_size: rowPerPage,
      sort_by: sort,
      sort_direction: sortDirection,
      filter: {
        related_entity_type,
        ...filter,
        "form_schema.status": "active",
      },
    });
  },

  /**
   * Update a specified form submission
   * @param {string} formSubmissionId the ID of the form submission entity
   * @param {object} data properties to update {@link https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/}
   * @returns a promise with response status and data
   */
  updateFormSubmission(formSubmissionId, data = {}) {
    if (!formSubmissionId) {
      return;
    }

    return nodeApiPutServicesPromise(
      `/form_submissions/${formSubmissionId}`,
      data
    );
  },

  /**
   * Get the XLSX export of a form submission
   * @param {string} formSubmissionId the form submission entity ID
   * @retuns a promise with response status and data. The response data is an ArrayBuffer that can be fed into a Blob/File object which can be made downloadable.
   */
  getFormSubmissionXLSXExport(formSubmissionId: string) {
    if (!formSubmissionId) {
      return;
    }

    return nodeApiRequestServicesPromise(
      `/form_submissions/${formSubmissionId}`,
      {},
      {
        headers: {
          Accept: mime.getType(".xlsx"),
        },
        responseType: "arraybuffer",
      }
    );
  },
};

export default FormService;
