import { CustomReportBuilderConfigurationType } from "#batteries-included-components/Layouts/CustomReport/List/helpers";
import {
  Button,
  DateSelectorInput,
  DropdownInput,
  Form,
  Panel,
  useFilters,
  useForm,
} from "@validereinc/common-components";
import React, { useEffect } from "react";

import {
  CUSTOM_REPORT_CONFIGURATION_SETTINGS,
  parseCustomReportConfiguration,
  useEquipmentRowOptions,
  useFlowRowOptions,
} from "#batteries-included-components/Layouts/CustomReport/CustomReportConfigurationPanel/CustomReportConfigurationPanel.helpers";
import {
  CustomReportVariantType,
  CustomReportVariants,
  getConfigFromSavedFilter,
  useSavedCustomReport,
} from "#batteries-included-components/Layouts/CustomReport/CustomReportDetailLayout.helpers";
import { useListCalculatorResults } from "#hooks/adapters/useCalculatorResults";
import { useListRecords } from "#hooks/adapters/useRecords";
import { useListReportingGroups } from "#hooks/adapters/useReportingGroups";
import { useParams } from "#routers/hooks";
import { CustomReportDetailParamType } from "#routes/reports/custom-reports/[reportVariant]/[reportId]/detail";
import { ReportingGroupFilterSwitcher } from "#src/batteries-included-components";
import { useMeasurementTypes } from "#src/contexts/MeasurementTypeContext";
import { DEFAULT_DATE_RANGES } from "#src/hooks/useDateRange";
import { hydrateDateRange } from "#utils/date";
import { AssetType } from "@validereinc/domain";
import classNames from "classnames/bind";
import uniq from "lodash/uniq";
import { useDeepCompareEffectNoCheck } from "use-deep-compare-effect";
import styles from "./CustomReportConfigurationPanel.module.scss";
const cx = classNames.bind(styles);

export const CustomReportConfigurationPanel = ({
  configStorageKey,
  onClear,
  variant,
}: {
  configStorageKey: string;
  onClear?: () => void;
  variant: CustomReportVariantType;
}) => {
  const { reportId } = useParams<CustomReportDetailParamType>();
  const { measurementTypes, isLoading: isMeasurementTypesLoading } =
    useMeasurementTypes();
  const [configuration, setConfiguration] = useFilters(configStorageKey);

  const { dateRange } = parseCustomReportConfiguration(configuration);

  const equipmentRowOptions = useEquipmentRowOptions({
    enabled: variant === CustomReportVariants.EMISSIONS,
  });
  const flowRowOptions = useFlowRowOptions({
    enabled: variant === CustomReportVariants.VOLUMETRIC,
  });

  const reportingGroupQuery = useListReportingGroups();

  const resultsQuery = useListCalculatorResults(
    {},
    {
      enabled: variant === CustomReportVariants.EMISSIONS,
    }
  );

  const recordsQuery = useListRecords(
    {
      year_month: DEFAULT_DATE_RANGES.lastTwelveMonths,
      asset_type: AssetType.FLOW,
    },
    { enabled: variant === CustomReportVariants.VOLUMETRIC }
  );

  const resultMeasurementTypes =
    uniq(
      resultsQuery.data?.data?.flatMap(({ measurement }) =>
        Object.keys(measurement)
      )
    ) ?? [];

  const recordMeasurementTypes =
    uniq(
      recordsQuery.data?.data?.flatMap(({ values }) =>
        values.map(({ measurement_type }) => measurement_type)
      )
    ) ?? [];

  let rowOptions: Array<{ label: string; value: string }> = [];
  let outputOptions: Array<{ label: string; value: string }> = [];
  let defaultOutputs: string[] = [];
  let isLoading = isMeasurementTypesLoading || reportingGroupQuery.isLoading;

  switch (variant) {
    case CustomReportVariants.EMISSIONS:
      isLoading = isLoading || resultsQuery.isLoading;
      rowOptions = equipmentRowOptions;
      defaultOutputs = ["mass_ch4", "mass_co2", "mass_co2eq", "mass_n2o"];
      outputOptions = measurementTypes
        .filter(
          ({ quantity, id }) =>
            quantity !== "volume" &&
            (resultMeasurementTypes.includes(id) || defaultOutputs.includes(id))
        )
        .map(({ id, name }) => ({ label: name, value: id }));
      break;
    case CustomReportVariants.VOLUMETRIC:
      isLoading = isLoading || recordsQuery.isLoading;
      rowOptions = flowRowOptions;
      defaultOutputs = ["volume"];
      outputOptions = measurementTypes
        .filter(
          ({ quantity, id }) =>
            quantity === "volume" &&
            (recordMeasurementTypes.includes(id) || defaultOutputs.includes(id))
        )
        .map(({ id, name }) => ({ label: name, value: id }));
      break;
  }

  const defaultValues = {
    [CUSTOM_REPORT_CONFIGURATION_SETTINGS.rows.name]: [],
    [CUSTOM_REPORT_CONFIGURATION_SETTINGS.outputs.name]: defaultOutputs,
    [CUSTOM_REPORT_CONFIGURATION_SETTINGS.reportingScenario.name]:
      reportingGroupQuery.data?.data[0]?.id,
    [CUSTOM_REPORT_CONFIGURATION_SETTINGS.dateRange.name]:
      DEFAULT_DATE_RANGES.lastTwelveMonths,
  };

  const configurationValues = {
    ...defaultValues,
    ...configuration,
  };

  useDeepCompareEffectNoCheck(() => {
    /** Set filters to default on initial render */
    setConfiguration(configurationValues);
  }, [configurationValues, setConfiguration]);

  const savedFilter = useSavedCustomReport(variant, reportId);

  useEffect(() => {
    if (!savedFilter) {
      return;
    }
    const { config } = getConfigFromSavedFilter(variant, savedFilter);
    setConfiguration(config);
  }, [savedFilter]);

  const form = useForm({
    defaultValues: {
      ...configurationValues,
      ...(dateRange
        ? {
            [CUSTOM_REPORT_CONFIGURATION_SETTINGS.dateRange.name]:
              hydrateDateRange(dateRange),
          }
        : {}),
    },
  });

  const onSubmit = (values: CustomReportBuilderConfigurationType) => {
    const newValues = values ?? defaultValues;
    setConfiguration(newValues);
    form.reset(newValues);
  };

  return (
    <Panel
      title="Configuration"
      loaded={!isLoading}
    >
      <Form
        {...form}
        defaultValues={defaultValues}
        onSubmit={onSubmit}
        onBlur={null}
      >
        <div className={cx("toolboxContainer")}>
          <ReportingGroupFilterSwitcher
            key={CUSTOM_REPORT_CONFIGURATION_SETTINGS.reportingScenario.name}
            name={CUSTOM_REPORT_CONFIGURATION_SETTINGS.reportingScenario.name}
            style={{ marginBottom: 8 }}
            shouldSelectFirstOptionAsDefault
          />

          <DateSelectorInput
            key={CUSTOM_REPORT_CONFIGURATION_SETTINGS.dateRange.name}
            name={CUSTOM_REPORT_CONFIGURATION_SETTINGS.dateRange.name}
            label={CUSTOM_REPORT_CONFIGURATION_SETTINGS.dateRange.label}
            variant="month"
            isFluid
            isRange
            isRequired
          />
          <DropdownInput
            key={CUSTOM_REPORT_CONFIGURATION_SETTINGS.rows.name}
            name={CUSTOM_REPORT_CONFIGURATION_SETTINGS.rows.name}
            label={CUSTOM_REPORT_CONFIGURATION_SETTINGS.rows.label}
            labelKey="label"
            valueKey="value"
            options={rowOptions}
            placeholder={CUSTOM_REPORT_CONFIGURATION_SETTINGS.rows.placeholder}
            isFluid
            isMulti
            isSearchable
            isRequired
          />
          <DropdownInput
            key={CUSTOM_REPORT_CONFIGURATION_SETTINGS.outputs.name}
            name={CUSTOM_REPORT_CONFIGURATION_SETTINGS.outputs.name}
            label={CUSTOM_REPORT_CONFIGURATION_SETTINGS.outputs.label}
            labelKey="label"
            valueKey="value"
            options={outputOptions}
            placeholder={
              CUSTOM_REPORT_CONFIGURATION_SETTINGS.outputs.placeholder
            }
            isFluid
            isMulti
            isSearchable
            isRequired
          />
        </div>
        <div className={cx("toolboxActions")}>
          <Button
            type="button"
            variant="outline"
            className={cx("toolboxAction")}
            onClick={() => {
              form.reset(defaultValues);
              setConfiguration(defaultValues);
              onClear?.();
            }}
          >
            Clear
          </Button>
          <Button
            type="submit"
            variant="primary"
            className={cx("toolboxAction")}
            disabled={!form.formState.isDirty || form.formState.isSubmitting}
          >
            Submit
          </Button>
        </div>
      </Form>
    </Panel>
  );
};
