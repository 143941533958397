import { RoutingLink } from "#batteries-included-components/RoutingLink";
import {
  useApplyConfiguration,
  useGetOneEstimationMethodConfiguration,
  useGetOneEstimationMethodRun,
  useRunEstimationMethod,
  useUpdateEstimationMethodConfiguration,
} from "#hooks/adapters/useEstimationMethods";
import { useListFlows } from "#hooks/adapters/useFlows";
import {
  useClearNetworksCache,
  useGetOneNetwork,
} from "#hooks/adapters/useNetworks";
import { useTableSortingAndPagination } from "#redux/reducers/tableStateReducer";
import { useParams } from "#routers/hooks";
import { FlowRecordDetailRoute } from "#routes/organization/flows/[flowId]/detail/record/[recordId]";
import { NetworkDetailPageParameters } from "#routes/organization/networks/[networkId]/detail";
import { NetworkCalculationFilterType } from "#routes/organization/networks/[networkId]/detail/NetworkCalculationTab/NetworkCalculationFilterPanel";
import { useNetworkCalculationFlowHeaders } from "#routes/organization/networks/[networkId]/detail/NetworkCalculationTab/NetworkCalculationTab.helpers";
import { useMeasurementTypes } from "#src/contexts/MeasurementTypeContext";
import { DEFAULT_DATE_RANGES } from "#src/hooks/useDateRange";
import {
  Button,
  DataTable,
  DataTablePanel,
  HeaderType,
  SortingType,
  StorageKeys,
  Switch,
  useFilters,
} from "@validereinc/common-components";
import { AssetType, FlowType, SortDirection } from "@validereinc/domain";
import {
  getYearMonthFromDateRange,
  yearMonthName,
} from "@validereinc/utilities";
import React from "react";

const sorting: SortingType = {
  sortBy: "name",
  sortDirection: SortDirection.ASCENDING,
};

export const NetworkCalculationConsumersPanel = ({
  filterConfigStorageKey,
  tableConfigStorageKey,
}: StorageKeys) => {
  const { networkId } = useParams<NetworkDetailPageParameters>();
  const { data: assetGroup } = useGetOneNetwork(networkId);
  const { getUnitName } = useMeasurementTypes();

  const [networkFilters] = useFilters<NetworkCalculationFilterType>(
    filterConfigStorageKey
  );

  const yearMonth =
    networkFilters?.yearMonth ?? DEFAULT_DATE_RANGES.currentWholeMonth;

  const filters = {
    id:
      assetGroup?.assets
        ?.filter(
          ({ asset_type, id }) =>
            asset_type === AssetType.FLOW &&
            !assetGroup?.network_inlet_ids?.includes(id)
        )
        .map(({ id }) => id) ?? [],
  };

  const [tableState, updateTableState] = useTableSortingAndPagination(
    sorting,
    filters
  );

  const { data: flows, isLoading: isFlowsLoading } = useListFlows(
    {
      page: tableState.page,
      pageSize: tableState.itemsPerPage,
      sortBy: tableState.sortBy,
      sortDirection: tableState.sortDirection,
      filters,
    },
    { enabled: !!filters.id.length }
  );

  const isLoading = isFlowsLoading && !!filters.id.length;

  const configId = {
    id: assetGroup?.default_estimation_method_id,
    assetType: AssetType.ASSET_GROUP,
    yearMonth: getYearMonthFromDateRange(yearMonth),
  };

  const applyConfiguration = useApplyConfiguration();
  const onClickApplyConfiguration = () => {
    applyConfiguration.mutate(configId);
  };

  const runEstimationMethod = useRunEstimationMethod();
  const onClickSave = () => {
    runEstimationMethod.mutate({ ...configId, promoteToRecord: true });
  };

  const { data: configuration } =
    useGetOneEstimationMethodConfiguration(configId);
  const { data: run } = useGetOneEstimationMethodRun(configId);

  const { invalidate } = useClearNetworksCache();

  const updateEstimationMethodConfiguration =
    useUpdateEstimationMethodConfiguration({
      onSuccess: () => {
        invalidate();
      },
    });

  const onToggleAdjustable = (flowId: string, isChecked: boolean) => {
    const previousProrationValue =
      configuration?.asset_inputs?.[flowId]?.is_prorated;

    updateEstimationMethodConfiguration.mutate({
      ...configId,
      data: {
        calculator_version: configuration?.calculator_version,
        asset_inputs: {
          [flowId]: {
            is_prorated: {
              // If we don't spread the old value, we lose the unit/type/quantity config
              ...previousProrationValue,
              value: isChecked,
            },
          },
        },
      },
    });
  };

  const items = flows?.data ?? [];

  const headers: Array<HeaderType<FlowType>> = [
    ...useNetworkCalculationFlowHeaders(),
    {
      key: "adjustable",
      label: "Adjust Value",
      renderComponent: ({ item }) => {
        return (
          <Switch
            id={item.id}
            isDisabled={
              updateEstimationMethodConfiguration.isLoading || isLoading
            }
            value={!!configuration?.asset_inputs?.[item.id]?.is_prorated?.value}
            onChange={(isChecked) => onToggleAdjustable(item.id, isChecked)}
          />
        );
      },
    },
    {
      key: "volume_record_id",
      label: "Source",
      renderComponent: ({ item }: { item: FlowType }) => {
        const input = configuration?.asset_input_sources?.[item.id]?.volume;

        if (!input) {
          return "-";
        }

        return (
          <RoutingLink
            to={FlowRecordDetailRoute.toLink({
              pathParams: { flowId: item.id, recordId: input.record_id },
            })}
          >
            {`${item.name} - ${yearMonthName(
              getYearMonthFromDateRange(yearMonth)
            )}`}
          </RoutingLink>
        );
      },
    },
    {
      key: "volume_source_type",
      label: "Source Type",
      renderComponent: ({ item }: { item: FlowType }) => {
        const input = configuration?.asset_input_sources?.[item.id]?.volume;
        return input ? "Record" : "-";
      },
    },
    {
      key: "volume",
      label: "Volume Input",
      renderComponent: ({ item }) => {
        const input = configuration?.asset_inputs?.[item.id]?.volume;
        return input ? (
          <DataTable.DataRow.NumberCell
            value={input.value}
            unit={input.unit}
          />
        ) : (
          "-"
        );
      },
    },
    {
      key: "adjusted_volume",
      label: "Adjusted Volume",
      renderComponent: ({ item }: { item: FlowType }) => {
        const output = run?.output.outputs.find(
          ({ asset_id, measurement_type }) =>
            asset_id === item.id && measurement_type === "volume"
        );
        return output ? (
          <DataTable.DataRow.NumberCell
            value={output.measurement_value}
            unit={getUnitName(output.measurement_unit)}
          />
        ) : (
          "-"
        );
      },
    },
  ];

  const actionRow = [
    <Button
      key="apply_configurations"
      onClick={onClickApplyConfiguration}
      isLoading={applyConfiguration.isLoading}
    >
      Apply Configuration
    </Button>,
    <Button
      key="save"
      variant="primary"
      onClick={onClickSave}
      isLoading={runEstimationMethod.isLoading}
    >
      Calculate
    </Button>,
  ];

  return (
    <DataTablePanel
      storageKey={tableConfigStorageKey}
      panelProps={{ title: "Consumers", actionRow }}
      dataTableProps={{
        headers,
        items,
        isLoading,
        sorting,
        onSortChange: updateTableState,
        onPaginationChange: updateTableState,
        pagination: {
          page: tableState.page,
          itemsPerPage: tableState.itemsPerPage,
          total: flows?.total_entries ?? 0,
        },
      }}
    />
  );
};
