import {
  COMPLETED_OPTION,
  DISMISSED_OPTION,
  SAMPLED_OPTION,
  DISMISSABLE_WORKFLOW_STATUS,
  COMPLETEABLE_WORKFLOW_STATUS,
  COMPLETED_WORKFLOW_STATUS,
  SAMPLEABLE_WORKFLOW_STATUS,
} from "./WorkflowTaskConstants";
import orderBy from "lodash/orderBy";
import config from "#config";
import { getTimeStringFromDate } from "#utils/timeFormatter";

const TASKS_STATUS_ORDER = [
  "missed",
  "pending",
  "sampled",
  "completed",
  "scheduled",
  "dismissed",
];

// Given status, returns appropriate time property to use
const TASKS_STATUS_TIME = {
  dismissed: "completed_at_utc_epoch",
  missed: "deadline_at_utc_epoch",
  pending: "deadline_at_utc_epoch",
  sampled: "sampled_at_utc_epoch",
  completed: "completed_at_utc_epoch",
  scheduled: "inserted_at_utc_epoch",
};

export const getDateForState = (rowData) => {
  return rowData?.[TASKS_STATUS_TIME[rowData?.state]];
};

export const canAddNotes = (workflowTask) =>
  workflowTask &&
  workflowTask.workflow_id !== "calibration" &&
  workflowTask.state !== "scheduled";

export const canComplete = (workflowTask) =>
  workflowTask &&
  workflowTask.workflow_id !== "calibration" &&
  workflowTask.test_type === "manual" &&
  COMPLETEABLE_WORKFLOW_STATUS.includes(workflowTask.state);

export const canDismiss = (workflowTask) =>
  workflowTask &&
  workflowTask.workflow_id !== "calibration" &&
  DISMISSABLE_WORKFLOW_STATUS.includes(workflowTask.state);

export const canSample = (workflowTask) =>
  workflowTask &&
  workflowTask.workflow_id !== "calibration" &&
  SAMPLEABLE_WORKFLOW_STATUS.includes(workflowTask.state);

export const cannotChangeStatus = (workflowTask) =>
  workflowTask &&
  workflowTask.workflow_id !== "calibration" &&
  COMPLETED_WORKFLOW_STATUS.includes(workflowTask.state);

export const getDefaultStatusValue = (workflowTask) => {
  if (workflowTask.state === "dismissed") {
    return DISMISSED_OPTION;
  } else if (workflowTask.state === "completed") {
    return COMPLETED_OPTION;
  } else if (workflowTask.state === "sampled") {
    return SAMPLED_OPTION;
  }

  return null;
};

/**
 * Sorts by status, date, then by name. If two tasks have the same status, name,
 * and occur on the same day, they should be grouped together
 */
export const sortByStatusAndName = (list) => {
  // sort by task/date/name/most samples
  const workflowTasks = orderBy(list, [
    (row) => TASKS_STATUS_ORDER.indexOf(row.state),
    (row) => getDateForState(row),
    "name",
    "id",
  ]);

  // create a hash table with key: task|day|name and value: array of workflowtasks
  const groupedByStatusTimeName = new Map();
  workflowTasks.forEach((task) => {
    const taskStatus = TASKS_STATUS_ORDER.indexOf(task.state);
    const taskDay = getTimeStringFromDate(
      getDateForState(task) * 1000,
      config.DATE_FORMAT
    );
    const taskName = task.name;

    const taskKey = `${taskStatus}|${taskDay}|${taskName}`;
    groupedByStatusTimeName.get(taskKey)?.push(task) ??
      groupedByStatusTimeName.set(taskKey, [task]);
  });

  // Merge all the pre-sorted arrays together
  const finalList = [];

  for (const taskArray of groupedByStatusTimeName.values()) {
    finalList.push(...taskArray);
  }
  return finalList;
};

export const nameSorter = (workflowTasks) =>
  orderBy(workflowTasks, [
    "name",
    (row) => getDateForState(row),
    (row) => TASKS_STATUS_ORDER.indexOf(row.state),
  ]);

export const statusSorter = (workflowTasks) =>
  orderBy(workflowTasks, [
    (row) => TASKS_STATUS_ORDER.indexOf(row.state),
    (row) => getDateForState(row),
    "name",
    "id",
  ]);

export const workflowSorter = (workflowTasks, workflows) => {
  return orderBy(workflowTasks, [
    (row) => find(workflows, { id: row.workflow_id })?.name ?? -1,
    (row) => getDateForState(row),
  ]);
};

export const dateSorter = (workflowTasks, dateProperty) => {
  return orderBy(workflowTasks, [
    (row) => row[dateProperty],
    (row) => TASKS_STATUS_ORDER.indexOf(row.state),
    "name",
  ]);
};

export const typeSorter = (workflowTasks) =>
  orderBy(workflowTasks, [
    "test_type",
    (row) => getDateForState(row),
    (row) => TASKS_STATUS_ORDER.indexOf(row.state),
    "name",
  ]);
