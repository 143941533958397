import { linkToDataSubmissionsList } from "#routes/data-tools/submissions";
import { linkToDataSubmissionDetailPage } from "#routes/data-tools/submissions/[transactionId]/detail";
import { ResourceLocalizedType } from "#src/batteries-included-components/Buttons/ImportDataAction";
import { useQueueUnique } from "#src/hooks/useQueue";
import { useQuery } from "@tanstack/react-query";
import { Banner, IconLoading, Link } from "@validereinc/common-components";
import { TransactionAdapter, TransactionType } from "@validereinc/domain";
import classNames from "classnames/bind";
import kebabCase from "lodash/kebabCase";
import React from "react";
import styles from "./ImportDataActionStatus.module.scss";

const cx = classNames.bind(styles);

/**
 * Represents the status of a queued import data action
 */
export const ImportDataActionStatus = ({
  transactionId,
  onDismiss,
}: ImportDataActionStatusProps) => {
  const maxRetries = 3;
  const {
    data: transactionData,
    status: transactionDataStatus,
    failureCount: transactionDataFailureCount,
  } = useQuery(
    ["transactions", transactionId],
    () =>
      TransactionAdapter.getOne({
        id: transactionId,
        meta: { history: false },
      }),
    {
      enabled: Boolean(transactionId),
      refetchInterval: (data) => {
        if (!data) {
          return false;
        }

        if (
          data.data?.[0].status === "completed" ||
          data.data?.[0].status === "failed"
        ) {
          return false;
        }

        return 3000;
      },
      refetchIntervalInBackground: true,
      retry: maxRetries,
      retryDelay: (attempt) => attempt * 1000,
    }
  );

  const mapTransactionStatusToBannerVariant = (
    status?: TransactionType["status"]
  ) => {
    switch (status) {
      case "completed":
      case "completed_with_partial_success":
        return "success";
      case "failed":
        return "error";
      case "pending":
        return "info";
      default:
        return "generic";
    }
  };

  const mapTransactionToStatusDisplayText = (transaction?: TransactionType) => {
    if (!transaction) {
      return "Importing Data: in-progress...";
    }

    if (transaction.status !== "pending") {
      return transaction.status === "completed" ||
        transaction.status === "completed_with_partial_success"
        ? "Successfully imported data."
        : "Failed to import data.";
    }

    const getDisplayTextForTransactionStep = (
      step: TransactionType["step"]
    ) => {
      switch (step) {
        case "transaction_recorder":
          return "Importing Data, Logging";
        case "vulnerability_scanner":
          return "Importing Data, Safety Checks";
        case "archiver":
          return "Importing Data, Backup";
        case "validator":
          return "Importing Data, Validation";
        case "operator":
          return "Importing Data, Processing";
        case "sink":
        default:
          return "Importing Data";
      }
    };

    const getDisplayTextForTransactionState = (
      state: TransactionType["state"]
    ) => {
      switch (state) {
        case "pending":
          return "In-Progress...";
        case "failed":
          return "Failed.";
        case "partial_failure":
          return "Partly failed.";
        case "partial_success":
          return "Partly succeeded.";
        case "retried":
          return "Retried.";
        case "success":
          return "Success.";
      }
    };

    return `${getDisplayTextForTransactionStep(
      transaction.step
    )}: ${getDisplayTextForTransactionState(transaction.state).toLowerCase()}`;
  };

  const handleDismiss = () => {
    if (onDismiss) {
      onDismiss(transactionId);
    }
  };

  const withinRetries = transactionDataFailureCount < maxRetries;
  // is this component still loading?
  const isLoading = withinRetries && transactionDataStatus === "loading";
  // this component doesn't have everything it needs
  const isError =
    (!withinRetries && transactionDataStatus === "error") ||
    transactionDataStatus === "error";
  // the transaction representing the import data action
  const thisTransaction = transactionData?.data?.[0];
  // is the data importing in-progress?
  const isImporting = thisTransaction && thisTransaction.status === "pending";

  if (isLoading || isError || !thisTransaction) {
    return (
      <Banner
        className={cx("banner")}
        variant={isLoading ? "generic" : "warning"}
        titleText={
          isLoading
            ? "Fetching data import details..."
            : "Couldn't fetch data import details."
        }
        icon={isLoading ? <IconLoading speed="slow" /> : undefined}
        isDismissable={!isLoading}
        onDismiss={handleDismiss}
      />
    );
  }

  return (
    <Banner
      className={cx("banner")}
      variant={mapTransactionStatusToBannerVariant(thisTransaction?.status)}
      titleText={mapTransactionToStatusDisplayText(thisTransaction)}
      icon={isImporting ? <IconLoading speed="fast" /> : undefined}
      actionContent={
        <>
          {isImporting ? (
            <>You can also see the upload status in </>
          ) : (
            <>You can also see detailed submission information in </>
          )}
          <Link
            label={"Data Submission Details"}
            variant="secondary"
            underline="always"
            anchorTagProps={{
              href: thisTransaction?.transaction_id
                ? linkToDataSubmissionDetailPage(thisTransaction.transaction_id)
                : linkToDataSubmissionsList(),
            }}
          />
        </>
      }
      isDismissable
      onDismiss={handleDismiss}
    />
  );
};

/**
 * Renders the ImportDataActionStatus BIC in-sync with a queue in local storage representing queued data import actions
 */
export const ImportDataActionStatusWithQueue = ({
  resource,
  resourceId,
}: ImportDataActionStatusWithQueueProps) => {
  const [queue, setQueue] = useQueueUnique<TransactionType>(
    [],
    `queue-import-data-action-${resource.id}-${kebabCase(resource.name)}${
      resourceId ? `-${resourceId}` : ""
    }`,
    (tx) => tx.transaction_id
  );

  const handleDismiss = (transactionId: string) => {
    setTimeout(() => {
      setQueue(queue.cutById(transactionId));
    }, 500);
  };

  return (
    <>
      {queue.size()
        ? queue.getAsArray().map((item) => (
            <ImportDataActionStatus
              key={item.transaction_id}
              transactionId={item.transaction_id}
              onDismiss={handleDismiss}
            />
          ))
        : null}
    </>
  );
};

export type ImportDataActionStatusProps = {
  transactionId: string;
  onDismiss?: (transactionId: string) => void;
};

export type ImportDataActionStatusWithQueueProps = {
  resource: ResourceLocalizedType;
  resourceId?: string;
};
