import { getConvertedUnitString } from "#utils/stringFormatter";
import { EmptyState, Panel, XYChart } from "@validereinc/common-components";
import {
  FormResponseDataItemType,
  SeriesResponseDataItemType,
} from "@validereinc/domain";
import moment from "moment";
import { Stack } from "phosphor-react";
import React from "react";
import type { DataForGraphType } from "./EntityMeasurementsTab";

const CHART_HEIGHT = 400;

type AnalysisDataPoint = SeriesResponseDataItemType | FormResponseDataItemType;
type EntityAnalysisPanelPropsType = {
  data: DataForGraphType;
  isLoading: boolean;
  tickFormat?: string;
  from: string;
  to: string;
  title?: string;
  thresholds?: any[];
  actionRow?: React.ReactNode;
  leftAxisLabel?: string;
};

const EntityAnalysisPanel = ({
  data,
  isLoading,
  tickFormat = "MMM DD, YYYY h:mm A",
  from,
  to,
  title = "Analysis",
  thresholds,
  actionRow,
  leftAxisLabel = "",
}: EntityAnalysisPanelPropsType) => {
  const leftSource = data?.[0];
  const rightSource = undefined; // update when right source functionality added to page

  const leftAxis = leftSource && {
    label: leftAxisLabel,
    valueAccessor: (dataPoint: AnalysisDataPoint): number =>
      dataPoint?.measure_value,
    valueRenderer: (dataPoint: AnalysisDataPoint) =>
      getConvertedUnitString(
        dataPoint?.measure_value,
        (value) => value,
        leftSource.unit
      ),
  };

  const rightAxis = rightSource && {
    label: `${rightSource?.label} (${rightSource?.unit})`,
    valueAccessor: (dataPoint: AnalysisDataPoint) => dataPoint?.measure_value,
    valueRenderer: (dataPoint: AnalysisDataPoint) =>
      getConvertedUnitString(
        dataPoint?.measure_value,
        (value) => value,
        rightSource.unit
      ),
  };

  const bottomAxis = {
    compare: (
      { time: aTime }: { time: string },
      { time: bTime }: { time: string }
    ) => (moment(aTime).isAfter(bTime) ? 1 : -1),
    valueAccessor: (dataPoint: AnalysisDataPoint) => dataPoint?.time,
    tickRenderer: (date: Date): string => moment(date).format(tickFormat),
  };

  return (
    <Panel
      title={title}
      loaded={!isLoading}
      actionRow={actionRow}
    >
      {leftAxis ? (
        <XYChart
          height={CHART_HEIGHT}
          sets={data}
          leftAxis={leftAxis}
          rightAxis={rightAxis}
          bottomAxis={bottomAxis}
          from={from}
          to={to}
          thresholds={thresholds}
        />
      ) : (
        <EmptyState
          title="There is no data to display"
          suggestion="Please try adjusting your filters"
          icon={<Stack />}
        />
      )}
    </Panel>
  );
};

export default EntityAnalysisPanel;
