import { DataList } from "@validereinc/common-components";
import classNames from "classnames/bind";
import * as PropTypes from "prop-types";
import React from "react";

import styles from "./DataMappingForm.module.scss";

const cx = classNames.bind(styles);

const DataPreviewPanel = ({ previewData, previewDataLoading }) => {
  return (
    <div className={cx("subsection")}>
      <p className={cx("subsectionHeader")}>Data Preview</p>

      <DataList
        data={previewData}
        isLoading={previewDataLoading}
      />
    </div>
  );
};

DataPreviewPanel.propTypes = {
  previewData: PropTypes.array.isRequired,
  previewDataLoading: PropTypes.bool.isRequired,
};

export default DataPreviewPanel;
