import { AuthenticationLoadingLayout } from "#src/batteries-included-components/Layouts/Authentication/AuthenticationLoading";
import {
  EmptyState,
  EmptyStateProps,
  Icon,
} from "@validereinc/common-components";
import React from "react";

export const ErrorOnLoginCallbackLayout = ({
  action,
}: Pick<EmptyStateProps, "action">) => {
  return (
    <AuthenticationLoadingLayout>
      <EmptyState
        title="Failed to authenticate"
        suggestion="You will have to login again."
        variant="error"
        size="large"
        icon={<Icon variant="lock" />}
        // WIP: to house the sign out action here directly we'll have to expose signout through the authenticated context
        action={action}
      />
    </AuthenticationLoadingLayout>
  );
};
