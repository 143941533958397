import { WorkflowTaskDetailsRoutePath } from "#src/routes/workflows/all/[workflowId]/detail/task/[taskId]";
import { RoutePath } from "#src/utils/route";

export const WorkflowTaskAddFormSubmissionRoutePath =
  WorkflowTaskDetailsRoutePath.concat(
    new RoutePath<string, "formSchemaId">({
      path: "/form-submission/add",
      title: "Add Form Submission",
    })
  );
