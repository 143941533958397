import { Tooltip } from "@validereinc/common-components";
import * as PropTypes from "prop-types";
import React from "react";
import { getStringWithUnitFormat } from "../../../../utils/stringFormatter";
import "./MeasurementStatus.css";
import ProgressBar from "./ProgressBar";

function getStatus(status, labels) {
  const label = getLabel(labels);

  switch (status) {
    case "preliminary-error":
      return "preliminary";
    case "pending":
      return "pending";
    case "error":
      return "error";
    default:
      return label ? label : status;
  }
}

function getLabel(labels) {
  if (labels.length === 1) {
    return labels[0].split("_").join(" ");
  }

  if (labels.length > 1) {
    return "multiple warnings";
  }

  return null;
}

const MeasuremntStatus = (props) => {
  const status = getStatus(props.status, props.labels);

  const tooltip = (
    <>
      {props.alertSpec.lower_limit ? (
        <div>
          <strong>
            {"Minimum limit is " +
              getStringWithUnitFormat(
                props.alertSpec.lower_limit.toFixed(
                  props.measurementType.decimals
                ),
                props.alertSpec.measurement_unit
              )}
          </strong>
        </div>
      ) : null}
      {props.alertSpec.upper_limit ? (
        <strong>
          {"Maximum limit is " +
            getStringWithUnitFormat(
              props.alertSpec.upper_limit.toFixed(
                props.measurementType.decimals
              ),
              props.alertSpec.measurement_unit
            )}
        </strong>
      ) : null}
    </>
  );

  return (
    <Tooltip
      content={tooltip}
      style={{ display: "block" }}
      width={250}
    >
      <div className="measurementStatus">
        <div className="measurementStatus__textRow">
          <div className="measurementStatus__status">{status}</div>
          <div className="measurementStatus__value">
            <b>{`${props.value} ${props.unit}`}</b>
          </div>
        </div>
        <ProgressBar
          value={props.value}
          alertSpec={props.alertSpec}
          defaultAlertSpec={props.defaultAlertSpec}
        />
      </div>
    </Tooltip>
  );
};

MeasuremntStatus.propTypes = {
  alertSpec: PropTypes.object.isRequired,
  defaultAlertSpec: PropTypes.object.isRequired,
  measurementType: PropTypes.object,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  unit: PropTypes.string.isRequired,
  status: PropTypes.string,
  labels: PropTypes.array.isRequired,
};

export default MeasuremntStatus;
