import React, { useContext, useState } from "react";

import { useHistory, useNavigate, useParams } from "#src/Routers/hooks";

import classNames from "classnames/bind";

// breadcrumbs
import { EVENTS_BREADCRUMB } from "#src/routes/events";
import { EVENT_CATEGORIES_BREADCRUMB } from "#src/routes/events/event-categories";
import { EVENT_CATEGORY_DETAIL_BREADCRUMB } from "#src/routes/events/event-categories/[eventCategoryId]";
import { EVENT_TEMPLATE_DETAIL_BREADCRUMB } from "#src/routes/events/event-categories/[eventCategoryId]/templates/[templateId]/details";
import {
  EVENT_DETAIL_BREADCRUMB,
  linkToEventDetails,
} from "#src/routes/events/event-categories/[eventCategoryId]/templates/[templateId]/events/[eventId]";
import { ADD_EQUIPMENT_BREADCRUMB, ADD_EQUIPMENT_PAGE_TITLE } from "../";

import { EquipmentType, EventsDomain } from "@validereinc/domain";

// components
import { Button, Page } from "@validereinc/common-components";

import { EquipmentFilterPanel } from "#src/batteries-included-components";
import { SelectedEquipmentPanel } from "#src/batteries-included-components/Panels/SelectedEntitiesPanels/SelectedEquipmentPanel";
import { EquipmentTablePanel } from "#src/batteries-included-components/Panels/TablePanels/EquipmentTablePanel";

import { getBreadcrumbsObject } from "#src/Routers/breadcrumbsHelper";
import {
  EventCategoryContext,
  EventCategoryProvider,
} from "#src/contexts/EventCategoryContext";
import { EventContext, EventProvider } from "#src/contexts/EventContext";
import {
  EventTemplateContext,
  EventTemplateProvider,
} from "#src/contexts/EventTemplateContext";

import styles from "./AddEquipmentPage.module.scss";

const cx = classNames.bind(styles);

export const AddEquipmentToEventContent = () => {
  const urlParams = useParams<{ eventId: string }>();
  const navigate = useNavigate();
  const history = useHistory();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const { eventCategory } = useContext(EventCategoryContext) || {};
  const { eventTemplate } = useContext(EventTemplateContext) || {};
  const { event } = useContext(EventContext) || {};

  const [selectedEquipment, setSelectedEquipment] = useState<
    Record<string, EquipmentType>
  >({});

  const breadcrumbs = getBreadcrumbsObject(
    [
      EVENTS_BREADCRUMB,
      EVENT_CATEGORIES_BREADCRUMB,
      EVENT_CATEGORY_DETAIL_BREADCRUMB,
      EVENT_TEMPLATE_DETAIL_BREADCRUMB,
      EVENT_DETAIL_BREADCRUMB,
      ADD_EQUIPMENT_BREADCRUMB,
    ],
    urlParams,
    { 2: eventCategory?.name, 3: eventTemplate?.name, 4: event?.name }
  );

  const onCancel = async () => {
    history.goBack();
  };

  const onSubmit = async () => {
    setIsSubmitting(true);

    await Promise.all(
      Object.values(selectedEquipment).map(({ id }) => {
        return EventsDomain.equipment.addToEvent({
          eventId: event.id,
          equipmentId: id,
        });
      })
    );

    navigate({
      pathname: linkToEventDetails(
        eventCategory.id,
        eventTemplate.id,
        event.id
      ),
    });

    setIsSubmitting(false);
  };

  return (
    <Page
      title={ADD_EQUIPMENT_PAGE_TITLE}
      breadcrumbs={breadcrumbs}
      footer={
        <div className={cx("footer")}>
          <Button
            onClick={onCancel}
            disabled={isSubmitting}
          >
            Cancel
          </Button>

          <div style={{ display: "flex", flexDirection: "row", gap: 16 }}>
            <Button
              variant="primary"
              onClick={onSubmit}
              disabled={
                isSubmitting || !Object.values(selectedEquipment).length
              }
            >
              Add
            </Button>
          </div>
        </div>
      }
    >
      <EquipmentFilterPanel isWithCustomAttributes={false} />

      <div className={cx("container")}>
        <EquipmentTablePanel
          isWithCustomAttributes={false}
          selected={selectedEquipment}
          onSelectionChange={setSelectedEquipment}
        />

        <SelectedEquipmentPanel
          selectedEquipment={selectedEquipment}
          setSelectedEquipment={setSelectedEquipment}
        />
      </div>
    </Page>
  );
};

export const AddEquipmentToEventPage = () => {
  const { eventCategoryId, eventTemplateId, eventId } = useParams<{
    eventCategoryId: string;
    eventTemplateId: string;
    eventId: string;
  }>();

  return (
    <EventCategoryProvider eventCategoryId={eventCategoryId}>
      <EventTemplateProvider eventTemplateId={eventTemplateId}>
        <EventProvider eventId={eventId}>
          <AddEquipmentToEventContent />
        </EventProvider>
      </EventTemplateProvider>
    </EventCategoryProvider>
  );
};
