import { fetchInstrumentList, fetchSiteList } from "#redux/actions/index";
import {
  filterFieldInstruments,
  sortInstrumentByName,
} from "#redux/reducers/instruments";
import { havePermission } from "#redux/reducers/permissions";
import UserService from "#services/UserService";
import { CombineTwoArrayWithCheck } from "#utils/arrayFormatter";
import {
  Button,
  Form,
  FormButton,
  useAlert,
  useForm,
} from "@validereinc/common-components";
import * as PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import AccessDefinitionsModal from "../../AccessDefinitionsModal/AccessDefinitionsModal";
import ProfileForm from "../../User/ProfileForm";

import intersectionWith from "lodash/intersectionWith";
import {
  formatTimezoneName,
  getAvailableTimezone,
} from "../../User/ProfileHelpers";
import DeactivateConfirmationModal from "./ConfirmationModals/DeactivateConfirmationModal";
import ReactivateConfirmationModal from "./ConfirmationModals/ReactivateConfirmationModal";

const ACCESS_DEFINITION_MODAL_KEY = "ACCESS_DEFINITION_MODAL_KEY";
const DEACTIVATE_USER_MODAL_KEY = "DEACTIVATE_USER_MODAL_KEY";
const ACTIVATE_USER_MODAL_KEY = "ACTIVATE_USER_MODAL_KEY";

const TIMEZONE_OPTIONS = Object.keys(getAvailableTimezone());

const DEFAULT_INPUT = {
  name: "",
  company_name: "",
  email: "",
  phone_number: "",
  job_title: "",
  timezone_preference: "",
  sites: [],
  role: [],
  restrict_calibration_access: [],
  restricted_calibration_instruments: [],
};

const mapStateToProps = (state) => {
  return {
    profile: state.profiles?.data?.toJS() ?? {},
    hasWritePermissions: havePermission(state.permissions)(
      "core:user.management",
      "write"
    ),
    sites: state.sites.data?.toJS() ?? [],
    instrumentsList:
      state?.instruments?.data
        .toJS()
        .filter(filterFieldInstruments)
        .sort(sortInstrumentByName) ?? [],
  };
};

const mapDispatchToProps = {
  fetchSiteList,
  fetchInstrumentList,
};

const EditMemberProfile = ({
  memberProfile,
  sites,
  instrumentsList,
  hasWritePermissions,
  fetchInstrumentList,
  fetchSiteList,
  updateMemberProfile,
}) => {
  const [formState, setFormState] = useState("loading");
  const [availableRoles, setAvailableRoles] = useState([]);
  const [hasInitializedForm, setHasInitializedForm] = useState(false);
  const [modalKey, setModalKey] = useState(null);
  const { addAlert } = useAlert();

  const form = useForm({
    defaultValues: DEFAULT_INPUT,
  });

  const onUpdateClick = (inputs) => {
    setHasInitializedForm(true);
    setFormState("loading");

    UserService.requestUpdateProfile(inputs)
      .then(() => {
        addAlert({
          variant: "success",
          message: "Profile successfully updated.",
        });
      })
      .finally(() => {
        setFormState("enabled");
      });
  };

  const onModalClose = () => {
    setModalKey(null);
  };

  useEffect(() => {
    fetchSiteList();
    fetchInstrumentList();

    if (hasWritePermissions) {
      UserService.getRoles().then(({ data }) => {
        setAvailableRoles(data);
      });
    }
  }, []);

  useEffect(() => {
    if (memberProfile?.id && !hasInitializedForm) {
      let formattedSites = [];
      if (sites.length > 0) {
        formattedSites = CombineTwoArrayWithCheck(
          sites,
          memberProfile.sites,
          "id"
        );
      }

      let formattedRestrictedInstruments = [];
      if (memberProfile.restricted_calibration_instrument_ids?.length > 0) {
        formattedRestrictedInstruments = intersectionWith(
          instrumentsList,
          memberProfile.restricted_calibration_instrument_ids,
          (instrument, restrictedCalibrationInstrumentId) =>
            instrument.id === restrictedCalibrationInstrumentId
        );
      }

      form.reset({
        ...memberProfile,
        sites: formattedSites,
        timezone_preference: formatTimezoneName(
          memberProfile.timezone_preference
        ),
        phone_number: memberProfile.phone_number ?? "",
        job_title: memberProfile.job_title ?? "",
        restricted_calibration_instruments: formattedRestrictedInstruments,
      });

      if (memberProfile.state === "active") {
        setFormState("enabled");
      }
    }
  }, [sites, memberProfile, instrumentsList]);

  const inputs = form.watch();

  return (
    <div>
      <Form
        onSubmit={onUpdateClick}
        {...form}
      >
        <ProfileForm
          inputs={inputs}
          formState={formState}
          hasWritePermissions={hasWritePermissions}
          availableSites={sites}
          availableRoles={availableRoles}
          availableInstruments={instrumentsList}
          availableTimezones={TIMEZONE_OPTIONS}
          handleAccessDefinitionClick={() => {
            setModalKey(ACCESS_DEFINITION_MODAL_KEY);
          }}
        />

        <div className="clearfix">
          {memberProfile?.state === "active" ? (
            <>
              <Button
                variant="error-outline"
                onClick={() => {
                  setModalKey(DEACTIVATE_USER_MODAL_KEY);
                }}
                disabled={formState !== "enabled"}
              >
                Deactivate
              </Button>

              <FormButton
                variant="primary"
                className="pull-right"
                type="submit"
                isLoading={formState === "loading"}
                disabled={formState !== "enabled" || !hasWritePermissions}
              >
                Save changes
              </FormButton>
            </>
          ) : (
            <Button
              variant="primary"
              className="pull-right"
              onClick={() => {
                setModalKey(ACTIVATE_USER_MODAL_KEY);
              }}
            >
              Activate
            </Button>
          )}
        </div>
      </Form>

      {modalKey === DEACTIVATE_USER_MODAL_KEY && (
        <DeactivateConfirmationModal
          memberProfile={memberProfile}
          updateMemberProfile={updateMemberProfile}
          onHide={onModalClose}
        />
      )}

      {modalKey === ACTIVATE_USER_MODAL_KEY && (
        <ReactivateConfirmationModal
          memberProfile={memberProfile}
          updateMemberProfile={updateMemberProfile}
          onHide={onModalClose}
        />
      )}

      {modalKey === ACCESS_DEFINITION_MODAL_KEY && (
        <AccessDefinitionsModal
          show={true}
          onHide={onModalClose}
        />
      )}
    </div>
  );
};

EditMemberProfile.propTypes = {
  memberProfile: PropTypes.object.isRequired,
  hasWritePermissions: PropTypes.bool.isRequired,
  sites: PropTypes.array,
  instrumentsList: PropTypes.array,
  addAlertMessage: PropTypes.func.isRequired,
  fetchInstrumentList: PropTypes.func.isRequired,
  fetchSiteList: PropTypes.func.isRequired,
  updateMemberProfile: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditMemberProfile);
