import { useTableSortingAndPagination } from "#redux/reducers/tableStateReducer";
import {
  WORKFLOW_ALL_FILTERS,
  WorkflowsFilterPanelFiltersType,
} from "#src/batteries-included-components/Panels/FilterPanels/WorkflowsFilterPanel/WorkflowsFilterPanel";
import { AssetTypeSelection } from "#src/batteries-included-components/Panels/FilterPanels/assetFilterComponents";
import { WorkflowStatusToPillVariantMap } from "#src/batteries-included-components/Panels/TablePanels/WorkflowsTablePanel/WorkflowsTablePanel.helpers";
import { RoutingLink } from "#src/batteries-included-components/RoutingLink";
import { linkToFacilityDetail } from "#src/routes/organization/facilities/[facilityId]";
import { linkToAssetDetailPage } from "#src/utils/links";
import { WorkflowDetailsRoutePath } from "#src/routes/workflows/all/[workflowId]";
import { WorkflowCategoryDetailsRoutePath } from "#src/routes/workflows/categories/[workflowCategoryId]";
import { WorkflowTemplateDetailsRoutePath } from "#src/routes/workflows/templates/[workflowTemplateId]";
import { useQuery } from "@tanstack/react-query";
import {
  DataTable,
  DataTablePanel,
  FilterPillVariants,
  FilterPills,
  HeaderType,
  StorageKeys,
  useFilters,
} from "@validereinc/common-components";
import {
  AssetType,
  GetListRequestType,
  SortDirection,
  WorkflowAdapter,
  WorkflowStatus,
  WorkflowType,
  WorkflowsGetListFilterType,
} from "@validereinc/domain";
import { toStartCaseString } from "@validereinc/utilities";
import useLocalization from "#src/hooks/useLocalization";
import { DEFAULT_QUERY_OPTIONS } from "#hooks/adapters/adapterUtils";
import startCase from "lodash/startCase";
import React, { useState } from "react";

export type WorkflowsTablePanelProps = {
  /** certain columns are turned off when this table panel is used within the workflow template details page */
  isWithinWorkflowTemplateDetails?: boolean;
  /** optionally bypass filters set in session storage which are fetched through useFilters() */
  overrideFilters?: {
    templateId?: string;
  };
} & StorageKeys;

const isValidId = (assetIds: string[] | undefined) => {
  return !!assetIds?.length;
};

const getAssetFilters = (
  inputFilters: Partial<WorkflowsFilterPanelFiltersType>
) => {
  const { assetType, facilityId, equipmentId, deviceId, flowId, assetGroupId } =
    inputFilters;
  switch (assetType) {
    case AssetTypeSelection.FACILITY:
      return isValidId(facilityId)
        ? { "facility.id": facilityId }
        : { "workflow_template.asset_type": assetType };
    case AssetTypeSelection.EQUIPMENT:
      return isValidId(equipmentId)
        ? { "equipment.id": equipmentId }
        : { "workflow_template.asset_type": assetType };
    case AssetTypeSelection.DEVICE:
      return isValidId(deviceId)
        ? { "device.id": deviceId }
        : { "workflow_template.asset_type": assetType };
    case AssetTypeSelection.FLOW:
      return isValidId(flowId)
        ? { "flow.id": flowId }
        : { "workflow_template.asset_type": assetType };
    case AssetTypeSelection.ASSET_GROUP:
      return isValidId(assetGroupId)
        ? { "asset_group.id": assetGroupId }
        : { "workflow_template.asset_type": assetType };
    default:
      return {};
  }
};

export const WorkflowsTablePanel = ({
  isWithinWorkflowTemplateDetails = false,
  overrideFilters,
  tableConfigStorageKey,
  filterConfigStorageKey,
}: WorkflowsTablePanelProps) => {
  const [statusFilter, setStatusFilter] = useState();
  const [storedFilters] = useFilters<WorkflowsFilterPanelFiltersType>(
    filterConfigStorageKey
  );
  const { localize } = useLocalization();
  const {
    [WORKFLOW_ALL_FILTERS.dateRange.name]: dateRange,
    categoryId,
    templateId,
  } = storedFilters;

  const sorting = {
    sortBy: "created_at",
    sortDirection: SortDirection.DESCENDING,
  };

  const filters = {
    created_at: dateRange,
    status: statusFilter ? { $exact: statusFilter } : undefined,
    "workflow_category.id": categoryId,
    "workflow_template.id": overrideFilters?.templateId ?? templateId,
    ...getAssetFilters(storedFilters),
  };

  const [tableState, updateTableState] = useTableSortingAndPagination(
    sorting,
    filters
  );

  const reqPayload: GetListRequestType<WorkflowsGetListFilterType> = {
    page: tableState.page,
    pageSize: tableState.itemsPerPage,
    sortBy: tableState.sortBy,
    sortDirection: tableState.sortDirection,
    filters,
  };

  const filterPills = [
    {
      name: "All",
      label: "All",
      value: null,
      isSelected: true,
    },
    {
      name: WorkflowStatus.COMPLETE,
      label: startCase(WorkflowStatus.COMPLETE),
      value: WorkflowStatus.COMPLETE,
      variant: FilterPillVariants.GOOD,
      isSelected: statusFilter,
    },
    {
      name: WorkflowStatus.IN_PROGRESS,
      label: startCase(WorkflowStatus.IN_PROGRESS),
      value: WorkflowStatus.IN_PROGRESS,
      variant: FilterPillVariants.PENDING,
      isSelected: statusFilter,
    },
    {
      name: WorkflowStatus.DISMISSED,
      label: startCase(WorkflowStatus.DISMISSED),
      value: WorkflowStatus.DISMISSED,
      variant: FilterPillVariants.NEUTRAL,
      isSelected: statusFilter,
    },
    {
      name: WorkflowStatus.OVERDUE,
      label: startCase(WorkflowStatus.OVERDUE),
      value: WorkflowStatus.OVERDUE,
      variant: FilterPillVariants.ATTENTION,
      isSelected: statusFilter,
    },
    {
      name: WorkflowStatus.MISSED,
      label: startCase(WorkflowStatus.MISSED),
      value: WorkflowStatus.MISSED,
      variant: FilterPillVariants.FAILURE,
      isSelected: statusFilter,
    },
  ];

  const { data, isLoading } = useQuery({
    queryKey: ["workflows", "getList", reqPayload],
    queryFn: () => WorkflowAdapter.getList(reqPayload),
    ...DEFAULT_QUERY_OPTIONS,
  });

  const headers: Array<HeaderType<WorkflowType>> = [
    {
      label: "Name",
      key: "id",
      isSortable: false,
      renderComponent: ({ item }: { item: WorkflowType }) => {
        return (
          <RoutingLink
            to={WorkflowDetailsRoutePath.toLinkParts({
              pathParams: {
                workflowId: item.id,
              },
            })}
          >
            {item.workflow_template.name}
          </RoutingLink>
        );
      },
    },
    {
      label: "Status",
      key: "status",
      isSortable: true,
      renderComponent: ({ item }) => (
        <DataTable.DataRow.PillCell
          variant={WorkflowStatusToPillVariantMap[item.status] || "default"}
          value={toStartCaseString(item.status)}
        />
      ),
    },
    {
      label: "Due Date",
      key: "due_date",
      isSortable: true,
      renderComponent: ({ item }) => (
        <DataTable.DataRow.DateCell value={item.due_date} />
      ),
    },
    {
      label: "Asset",
      isSortable: false,
      key: "asset.name",
      renderComponent: ({ item }: { item: WorkflowType }) =>
        item.asset ? (
          <RoutingLink
            to={linkToAssetDetailPage(item.asset.asset_type, item.asset.id)}
          >
            {item.asset.name}
          </RoutingLink>
        ) : (
          "-"
        ),
    },
    {
      label: "Asset Type",
      isSortable: false,
      key: "asset.type",
      renderComponent: ({ item }: { item: WorkflowType }) =>
        item.asset ? localize(item.asset.asset_type) : "-",
    },
    {
      label: "Associated Facility",
      isSortable: false,
      key: "facility.name",
      renderComponent: ({ item }: { item: WorkflowType }) =>
        item.asset &&
        item.asset.asset_type !== AssetType.FACILITY &&
        item.facility ? (
          <RoutingLink to={linkToFacilityDetail(item.facility_id)}>
            {item.facility.name}
          </RoutingLink>
        ) : (
          "-"
        ),
    },
    ...(!isWithinWorkflowTemplateDetails
      ? [
          {
            label: "Category",
            key: "workflow_template.workflow_category.name",
            isSortable: false,
            renderComponent: ({ item }: { item: WorkflowType }) => (
              <RoutingLink
                to={WorkflowCategoryDetailsRoutePath.toLinkParts({
                  pathParams: {
                    workflowCategoryId:
                      item.workflow_template.workflow_category.id,
                  },
                })}
              >
                {item.workflow_template.workflow_category.name}
              </RoutingLink>
            ),
          },
          {
            label: "Template",
            key: "workflow_template.name",
            isSortable: false,
            renderComponent: ({ item }: { item: WorkflowType }) => (
              <RoutingLink
                to={WorkflowTemplateDetailsRoutePath.toLinkParts({
                  pathParams: {
                    workflowTemplateId: item.workflow_template_id,
                  },
                })}
              >
                {item.workflow_template.name}
              </RoutingLink>
            ),
          },
        ]
      : []),
    {
      key: "created_at",
      label: "Created At",
      isSortable: true,
      renderComponent: ({ item }) => (
        <DataTable.DataRow.DateCell value={item.created_at} />
      ),
    },
    {
      key: "updated_at",
      label: "Updated At",
      isSortable: true,
      renderComponent: ({ item }) => (
        <DataTable.DataRow.DateCell value={item.updated_at} />
      ),
    },
  ];

  return (
    <DataTablePanel
      storageKey={tableConfigStorageKey}
      panelProps={{
        title: "Workflows",
        isFluidY: false,
        titleDecorator: (
          <FilterPills
            name="status"
            pills={filterPills}
            onChange={setStatusFilter}
          />
        ),
      }}
      dataTableProps={{
        headers,
        items: data?.data ?? [],
        isLoading,
        sorting,
        pagination: {
          page: tableState.page,
          itemsPerPage: tableState.itemsPerPage,
          total: data?.total_entries ?? 0,
        },
        onSortChange: updateTableState,
        onPaginationChange: updateTableState,
      }}
    />
  );
};
