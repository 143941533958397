import { convertMapToOpts } from "#src/constants";
import {
  DropdownInput,
  FilterPanel,
  StorageKeys,
  TextInput,
} from "@validereinc/common-components";
import {
  WorkflowCategoriesAdapter,
  WorkflowTemplateStatus,
} from "@validereinc/domain";
import startCase from "lodash/startCase";
import React from "react";

export const WorkflowTemplateStatusOptions = convertMapToOpts(
  WorkflowTemplateStatus,
  ([_, value]) => startCase(value)
);

export const WorkflowTemplatesFilterPanel = ({
  filterConfigStorageKey,
  showCategoryFilter = true,
}: {
  showCategoryFilter?: boolean;
} & Pick<StorageKeys, "filterConfigStorageKey">) => {
  return (
    <FilterPanel
      storageKey={filterConfigStorageKey}
      defaultActiveKeys={"Filters"}
      filters={[
        {
          component: (
            <TextInput
              name="name"
              key="workflow-templates-name-filter"
              placeholder="Search Templates..."
              type="search"
              isInline
            />
          ),
        },
        {
          component: (
            <DropdownInput
              key="status"
              name="status"
              placeholder="Select a Status"
              options={WorkflowTemplateStatusOptions}
              labelKey="label"
              valueKey="value"
              isInline
              isSearchable
              isMulti
            />
          ),
        },
        ...(showCategoryFilter
          ? [
              {
                component: (
                  <DropdownInput
                    key="category_id"
                    name="category_id"
                    onFetchData={async (payload) => {
                      let { data } = await WorkflowCategoriesAdapter.getList({
                        ...payload,
                        filters: {
                          ...(payload.searchTerm
                            ? {
                                name: payload.searchTerm,
                              }
                            : {}),
                        },
                      });

                      // REVIEW: not a fan of the fact that we need to do this. A refactor of DropdownInput is needed.
                      if (Array.isArray(payload.value)) {
                        data = data.filter((d) => payload.value.includes(d.id));
                      }

                      return data;
                    }}
                    labelKey="name"
                    valueKey="id"
                    placeholder="Select Category"
                    isInline
                    isMulti
                  />
                ),
              },
            ]
          : []),
      ]}
    />
  );
};
