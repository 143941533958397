import {
  EntityDropdownInput,
  EntityDropdownProps,
} from "#batteries-included-components/Dropdowns/EntityDropdownInput";
import { Device, DeviceStatusType } from "@validereinc/domain";
import React from "react";

export const DeviceStatusDropdownInput = ({
  name = "status",
  label,
  ...props
}: EntityDropdownProps<DeviceStatusType>) => {
  return (
    <EntityDropdownInput
      fetchEntityList={Device.statuses.getList}
      label={label ?? "Status"}
      name={name}
      sort={{
        sortBy: "name",
        sortDirection: "asc",
      }}
      {...props}
    />
  );
};
