import {
  FilterPanel,
  StorageKeys,
  TextInput,
} from "@validereinc/common-components";
import React from "react";

export const TemplatedReportFilterPanel = ({
  filterConfigStorageKey,
}: Pick<StorageKeys, "filterConfigStorageKey">) => {
  return (
    <FilterPanel
      storageKey={filterConfigStorageKey}
      filters={[
        {
          component: (
            <TextInput
              name="display_name"
              key="name"
              placeholder="Search by name..."
              type="search"
              isInline
            />
          ),
        },
      ]}
    />
  );
};
