import { dateTimeRenderer, statusRenderer } from "#common/Table/renderers";
import { getFormattedMeasurementValueWithUnit } from "#redux/reducers/measurements";
import {
  getLocalStorageItem,
  setLocalStorageItem,
} from "#utils/localStorageHelper";
import { LegacyDataTable, Pill, Tooltip } from "@validereinc/common-components";
import differenceBy from "lodash/differenceBy";
import get from "lodash/get";
import * as PropTypes from "prop-types";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { AutoSizer } from "react-virtualized";
import { pillRenderer } from "../Common/Table/renderers";
import MeasurementsCell from "./MeasurementsCell";

const mapStateToProps = ({ measurements }) => {
  return {
    getFormattedMeasurementValueWithUnit: (measurementKey, measurementValue) =>
      getFormattedMeasurementValueWithUnit(measurements)(
        measurementKey,
        measurementValue
      ),
  };
};

const TEST_LOCAL_STORAGE_KEY = "test_management_headers";

const simpleTextRenderer = (rowData, columnKey) => {
  const text = get(rowData, columnKey, "-");

  return <div className="capitalized textWrapColumn">{text}</div>;
};

const TestManagementTable = (props) => {
  const measurementRenderer = (rowData) => {
    const measurements = Object.entries(rowData.measurements).map(
      ([name, measurement]) => {
        const formatttedValue = props.getFormattedMeasurementValueWithUnit(
          name,
          measurement
        );

        return { name, value: formatttedValue };
      }
    );

    return (
      <Tooltip
        title="Measurements"
        content={<MeasurementsCell measurements={measurements} />}
      >
        <Pill variant="info">{measurements?.length}</Pill>
      </Tooltip>
    );
  };

  const streamRenderer = (rowData, columnKey) => {
    const stream = get(rowData, columnKey);

    if (stream) {
      return <div className="capitalized textWrapColumn">{stream}</div>;
    } else {
      return <Pill variant="error">Orphan</Pill>;
    }
  };

  const samplePointRenderer = (rowData, columnKey) => {
    const samplePointIds = get(rowData, columnKey);

    return samplePointIds?.length ? samplePointIds.join(", ") : "-";
  };

  useEffect(() => {
    const defaultColumns = getLocalStorageItem(TEST_LOCAL_STORAGE_KEY);

    if (!defaultColumns) {
      const defaultHeaders = differenceBy(
        headers,
        nonDefaultHeaders,
        "key"
      ).map(({ key }) => ({ key }));

      setLocalStorageItem(TEST_LOCAL_STORAGE_KEY, defaultHeaders);
    }
  }, []);

  const headers = [
    {
      label: "Test Name",
      key: "name",
      cellRenderer: simpleTextRenderer,
      width: 250,
      fixed: true,
      disableSort: true,
    },
    {
      label: "Test Type",
      key: "type",
      width: 100,
      fixed: true,
    },
    {
      label: "Test Date",
      key: "date",
      cellRenderer: dateTimeRenderer,
      width: 135,
    },
    {
      label: "Tester",
      key: "tested_by",
      width: 150,
      disableSort: true,
      suppress: true,
    },
    {
      label: "Site",
      key: "instrument.site_name",
      cellRenderer: simpleTextRenderer,
      width: 200,
      disableSort: true,
    },

    {
      label: "Status",
      key: "status",
      cellRenderer: statusRenderer("test"),
      width: 130,
      disableSort: true,
    },
    {
      label: "Instrument Name",
      key: "instrument.name",
      cellRenderer: simpleTextRenderer,
      width: 200,
      disableSort: true,
    },
    {
      label: "Stream",
      key: "sample.streamName",
      cellRenderer: streamRenderer,
      width: 250,
      disableSort: true,
    },

    {
      label: "Sample Point ID",
      key: "sample.samplePointIds",
      cellRenderer: samplePointRenderer,
      width: 150,
      disableSort: true,
    },
    {
      label: "Sample Date",
      key: "sample.started_at",
      cellRenderer: dateTimeRenderer,
      width: 135,
      disableSort: true,
    },
    {
      label: "Sample Type",
      key: "sample.type",
      cellRenderer: simpleTextRenderer,
      width: 115,
      disableSort: true,
    },
    {
      label: "Short Code",
      key: "short_code",
      width: 120,
      cellRenderer: pillRenderer(),
      disableSort: true,
    },
    {
      label: "Measurements",
      key: "measurements",
      width: 120,
      cellRenderer: measurementRenderer,
    },
  ];

  const nonDefaultHeaders = [{ key: "type" }, { key: "sample.samplePointIds" }];

  return (
    <AutoSizer>
      {({ height, width }) => (
        <LegacyDataTable
          width={width}
          height={height}
          headers={headers}
          list={props.tests}
          filterRow={props.filterRow}
          filterPillbox={props.filterPillbox}
          defaultSortBy="date"
          defaultSortDirection="desc"
          onCheckboxClick={props.onTestCheck}
          onCellClick={props.onTestClick}
          rowHeight={80}
          noFilterListCount={props.noFilterListCount}
          showOverlayLoader={props.isLoading}
          actionRow={props.actionRow}
          checkedList={props.checkedList}
          disableCheckbox={props.disabled}
          disableSelectAll={!!props.getCheckboxDisabledState}
          getCheckboxDisabledState={props.getCheckboxDisabledState}
          highlightRow={true}
          paginationDetail={props.paginationDetail}
          onPaginationChange={props.onPaginationChange}
          customizableColumns={props.view !== "modal"}
          customizableColumnsLocalStorageKey={TEST_LOCAL_STORAGE_KEY}
          customizableColumnDropdownOptions={nonDefaultHeaders}
        />
      )}
    </AutoSizer>
  );
};

TestManagementTable.propTypes = {
  onTestCheck: PropTypes.func,
  onTestClick: PropTypes.func,
  getFormattedMeasurementValueWithUnit: PropTypes.func,
  tests: PropTypes.array.isRequired,
  isLoading: PropTypes.bool,
  noFilterListCount: PropTypes.number,
  view: PropTypes.string,
  dateRange: PropTypes.object,
  selectDate: PropTypes.func,
  filterRow: PropTypes.object,
  filterPillbox: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  actionRow: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  checkedList: PropTypes.array,
  disabled: PropTypes.bool,
  getCheckboxDisabledState: PropTypes.func,
  paginationDetail: PropTypes.object,
  onPaginationChange: PropTypes.func,
};

export default connect(mapStateToProps, null)(TestManagementTable);
