import { useState, useEffect } from "react";
import MeasurementsService from "#components/Services/MeasurementsService";

export const useMeasurementSeriesDetail = (id, dependencies = []) => {
  const [isLoading, setIsLoading] = useState(true);
  const [measurementSeriesDetail, setMeasurementSeriesDetail] = useState({});

  useEffect(() => {
    (async () => {
      if (id) {
        setIsLoading(true);
        const response =
          await MeasurementsService.getMeasurementSourcesDetail(id);
        setMeasurementSeriesDetail(response.data);
        setIsLoading(false);
      }
    })();
  }, [id, ...dependencies]);

  return [measurementSeriesDetail, isLoading];
};
