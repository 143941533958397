import {
  EntityDropdownInput,
  EntityDropdownProps,
} from "#src/batteries-included-components/Dropdowns/EntityDropdownInput";
import { WorkflowTaskFilterPanelFiltersType } from "#src/batteries-included-components/Panels/FilterPanels/WorkflowTasksFilterPanel";
import { StorageKeys, useFilters } from "@validereinc/common-components";
import {
  UserGroupType,
  UserGroupsAdapter,
  UserType,
  UsersAdapter,
} from "@validereinc/domain";
import cloneDeep from "lodash/cloneDeep";
import React, { useState } from "react";

const defaultSelectedGroup = "users";

const WorkflowTaskUserGroupDropdown = (
  props: Omit<
    EntityDropdownProps<UserType | UserGroupType>,
    "fetchEntityList"
  > &
    Pick<StorageKeys, "filterConfigStorageKey">
) => {
  const { onChange: _, filterConfigStorageKey, name, ...restProps } = props;

  const [activeGroup, setActiveGroup] = useState(defaultSelectedGroup);

  const [filters, setFilters] = useFilters<WorkflowTaskFilterPanelFiltersType>(
    filterConfigStorageKey
  );

  const adapter =
    activeGroup === "users" ? UsersAdapter.getList : UserGroupsAdapter.getList;

  return (
    <EntityDropdownInput<UserType | UserGroupType>
      name={name}
      isMulti={false}
      onGroupChange={(newGroup) => {
        setActiveGroup(newGroup);
      }}
      groups={[
        { id: "users", name: "Users" },
        { id: "groups", name: "User Groups" },
      ]}
      defaultGroup={defaultSelectedGroup}
      fetchEntityList={adapter}
      sort={{
        sortBy: "name",
        sortDirection: "asc",
      }}
      onChange={(newValue: string) => {
        const newFilters = cloneDeep(filters);
        if (activeGroup === "groups") newFilters.assignee_group = newValue;
        if (activeGroup === "users") newFilters.assignee_user = newValue;
        (newFilters as any)[name] = newValue;
        setFilters(newFilters);
      }}
      {...restProps}
    />
  );
};

export default WorkflowTaskUserGroupDropdown;
