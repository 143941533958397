import {
  useCreateOne,
  useDeleteOne,
  useGetList,
  useGetOne,
  useUpdateOne,
} from "#src/components/hooks/adapters/adapterUtils";
import { downloadLink } from "#utils/download";
import { useMutation } from "@tanstack/react-query";
import { useAlert } from "@validereinc/common-components";
import {
  FormSubmission,
  FormSubmissionFilterType,
  FormSubmissionType,
  GetListRequestType,
  Resources,
} from "@validereinc/domain";

export const useGetOneFormSubmission = useGetOne<FormSubmissionType>(
  FormSubmission.getOne,
  Resources.FORM_SUBMISSION
);

export const useListFormSubmissions = useGetList<FormSubmissionType>(
  FormSubmission.getList,
  Resources.FORM_SUBMISSION
);

export const useDeleteOneFormSubmission = useDeleteOne<FormSubmissionType>(
  FormSubmission.deleteOne,
  Resources.FORM_SUBMISSION
);

export const useCreateOneFormSubmission = useCreateOne<FormSubmissionType>(
  FormSubmission.createOne,
  Resources.FORM_SUBMISSION
);

export const useUpdateOneFormSubmission = useUpdateOne<FormSubmissionType>(
  FormSubmission.updateOne,
  Resources.FORM_SUBMISSION
);

// Exports all form submissions for a template. This is different from useForms useExportFormSubmission
export const useExportFormSubmissions = (
  apiParams: GetListRequestType<FormSubmissionFilterType> = {}
) => {
  const { addAlert } = useAlert();

  return useMutation({
    mutationFn: async () => {
      const report = await FormSubmission.exportList?.(apiParams);
      if (report) downloadLink(report.s3_download_link);
      else throw new Error();
    },
    onError: (error) => {
      console.error(error);
      addAlert?.({
        variant: "error",
        message: "Unable to export form submissions",
      });
    },
  });
};
