export function GetPath(path) {
  try {
    return {
      mainPath: path.split("/").slice(0, 3).join("/"),
      nestedPath: path.split("/").slice(0, 4).join("/"),
    };
  } catch (error) {
    return path;
  }
}

export function OpenNewLink(url) {
  window.open(url);
}
