import React from "react";
import * as PropTypes from "prop-types";
import SystemBalance from "./SystemBalance/SystemBalance";

const Results = (props) => {
  return (
    <div className="results">
      <SystemBalance {...props} />
    </div>
  );
};

Results.propTypes = {
  analyzeBookmarks: PropTypes.array,
  analyzeBookmarkClicked: PropTypes.func,
};

export default Results;
