import { RecordEquipmentSummaryPanel } from "#batteries-included-components/Panels/SummaryPanels/RecordEquipmentSummaryPanel";
import { useBreadcrumbs } from "#routers/breadcrumbsHelper";
import { ORGANIZATION_BREADCRUMB } from "#routes/organization";
import { EQUIPMENT_LIST_BREADCRUMB } from "#routes/organization/equipment";
import ReportingGroupPillPanel from "#src/batteries-included-components/Panels/PillPanels/ReportingGroupPillPanel/ReportingGroupPillPanel";
import { RecordSummaryPanel } from "#src/batteries-included-components/Panels/SummaryPanels/RecordSummaryPanel";
import { RecordValueTablePanel } from "#src/batteries-included-components/Panels/TablePanels/RecordValueTablePanel";
import {
  EquipmentContext,
  EquipmentProvider,
} from "#src/contexts/EquipmentContext";
import { RecordContext, RecordProvider } from "#src/contexts/RecordContext";
import { Column, Page, Row } from "@validereinc/common-components";
import { yearMonthName } from "@validereinc/utilities";
import React, { useContext } from "react";
import {
  EQUIPMENT_RECORD_DETAIL_BREADCRUMB,
  EQUIPMENT_RECORD_DETAIL_PAGE_TITLE,
} from ".";
import { EQUIPMENT_DETAIL_BREADCRUMB } from "../../";

const EquipmentRecordDetailPageContent = () => {
  const { equipment } = useContext(EquipmentContext) ?? {};
  const { record } = useContext(RecordContext) ?? {};

  const pageTitle = record
    ? `${yearMonthName(record.year_month)} Record`
    : EQUIPMENT_RECORD_DETAIL_PAGE_TITLE;

  const breadcrumbs = useBreadcrumbs(
    [
      ORGANIZATION_BREADCRUMB,
      EQUIPMENT_LIST_BREADCRUMB,
      EQUIPMENT_DETAIL_BREADCRUMB,
      EQUIPMENT_RECORD_DETAIL_BREADCRUMB,
    ],
    {
      2: equipment?.name,
      3: pageTitle,
    }
  );

  return (
    <Page
      category="Equipment Record"
      breadcrumbs={breadcrumbs}
      title={pageTitle}
    >
      <Row>
        <Column variant={6}>
          <RecordSummaryPanel />
          <ReportingGroupPillPanel
            id={record?.reporting_group_id}
            isLoading={!record}
          />
        </Column>
        <Column variant={18}>
          <RecordEquipmentSummaryPanel />
          <RecordValueTablePanel />
        </Column>
      </Row>
    </Page>
  );
};

export const EquipmentRecordDetailPage = () => (
  <EquipmentProvider>
    <RecordProvider>
      <EquipmentRecordDetailPageContent />
    </RecordProvider>
  </EquipmentProvider>
);
