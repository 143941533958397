import findIndex from "lodash/findIndex";

export const validateRoleExist = (availableRoles, newName, existingName) => {
  if (
    newName !== existingName &&
    findIndex(availableRoles, { name: newName }) !== -1
  ) {
    return "Role name already exists";
  }

  return null;
};
