export const VOLUME_CUSTOM_REPORT_DETAIL_PAGE_TITLE = "Volume Custom Report";

export const linkToVolumeCustomReportDetail = (reportId = ":reportId") =>
  `/app/volume/custom-reports/${reportId}/detail`;

export const VOLUME_CUSTOM_REPORT_DETAIL_BREADCRUMB = {
  title: VOLUME_CUSTOM_REPORT_DETAIL_PAGE_TITLE,
  path: linkToVolumeCustomReportDetail,
};

export type VolumeCustomReportDetailParamType = {
  reportId: string;
};

export * from "./VolumeCustomReportDetailPage";
