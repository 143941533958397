import {
  apiRequestServicesPromise,
  apiPutServicesPromise,
} from "./ServiceHelper";

const CalibrationService = {
  getCalibrationsList() {
    return apiRequestServicesPromise("/api/calibrations");
  },

  getCalibrationCSVDownloadLink() {
    return apiRequestServicesPromise("/api/calibrations/csv/url");
  },

  updateCalibrationData(instrumentId, last_checked, next_check) {
    const parameter = {
      last_checked,
      next_check,
    };

    return apiPutServicesPromise(
      `/api/calibrations/${instrumentId}`,
      parameter
    );
  },

  updateStandardCheckDuration(instrumentId, standardCheckDaysDuration) {
    const parameter = {
      standard_check_days_duration: parseFloat(standardCheckDaysDuration),
    };

    return apiPutServicesPromise(
      `/api/calibrations/${instrumentId}`,
      parameter
    );
  },

  getShortCodes() {
    return apiRequestServicesPromise("/api/calibration_short_codes");
  },
};

export default CalibrationService;
