import { RoutePath } from "#utils/route";
import { FlowListRoute } from "#routes/organization/flows";

export type FlowDetailParamsType = {
  flowId: string;
};

export const FlowDetailRoute = FlowListRoute.concat(
  new RoutePath<"flowId", "tab">({
    path: "/:flowId/detail",
    title: "Flow Detail",
  })
);
