import { useEffect } from "react";

const useKeyDown = (keyCodeHandlers) => {
  useEffect(() => {
    const handler = (event) => {
      const handler = keyCodeHandlers[event.keyCode];
      if (handler) {
        handler();
        event.preventDefault();
        return false;
      }
    };

    window.addEventListener("keydown", handler);
    return () => {
      window.removeEventListener("keydown", handler);
    };
  });
};

export default useKeyDown;
