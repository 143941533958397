import { EntityDropdownInput } from "#src/batteries-included-components/Dropdowns/EntityDropdownInput";
import UserGroupsTablePanel from "#src/batteries-included-components/Panels/TablePanels/UserGroupsTablePanel";
import { useStorageKey } from "#src/hooks/useStorageKey";
import { UserGroupsRoutePath } from "#src/routes/settings/users/groups";
import {
  ContentSwitcher,
  FilterPanel,
  TextInput,
} from "@validereinc/common-components";
import {
  SortDirection,
  UserGroupCategoriesAdapter,
  UserGroupCategorySchema,
  UserGroupCategoryType,
  UserGroupSchema,
} from "@validereinc/domain";
import classNames from "classnames/bind";
import React, { useState } from "react";
import { useLocation } from "react-router";
import UserGroupCategoriesPanel from "../../Panels/CardPanels/UserGroupCategoriesPanel";
import styles from "./UserGroupsTab.module.scss";

const cx = classNames.bind(styles);

const CONTENT_SWITCHER_ITEMS = [
  { label: "By Category", dataKey: "categories" },
  { label: "All Groups", dataKey: "groups" },
];

const UserGroupsTab = () => {
  const categoriesStorageKeys = useStorageKey("user-group-categories");
  const groupsStorageKeys = useStorageKey("users-groups");
  const location = useLocation();

  const [activeTab, setActiveTab] = useState<string>(
    location.pathname === UserGroupsRoutePath.path ? "groups" : "categories"
  );

  return (
    <>
      <div className={cx("filter-row")}>
        <ContentSwitcher
          items={CONTENT_SWITCHER_ITEMS}
          onChange={(value) => setActiveTab(value)}
          activeKey={activeTab}
        />
        {activeTab === "categories" && (
          <FilterPanel
            storageKey={categoriesStorageKeys.filterConfigStorageKey}
            className={cx("filter-panel")}
            filters={[
              {
                component: (
                  <TextInput
                    key="search"
                    name="search"
                    placeholder="Search Categories..."
                    type="search"
                    isInline
                  />
                ),
              },
            ]}
          />
        )}
        {activeTab === "groups" && (
          <FilterPanel
            storageKey={groupsStorageKeys.filterConfigStorageKey}
            className={cx("filter-panel")}
            filters={[
              {
                component: (
                  <TextInput
                    key="search"
                    name="search"
                    placeholder="Search Groups..."
                    type="search"
                    isInline
                  />
                ),
              },
              {
                component: (
                  <EntityDropdownInput<UserGroupCategoryType>
                    isInline
                    isMulti={false}
                    fetchEntityList={UserGroupCategoriesAdapter.getList}
                    placeholder="User Group Category"
                    name={UserGroupSchema.keyof().Enum.group_category_id}
                    sort={{
                      sortBy: UserGroupCategorySchema.keyof().Enum.name,
                      sortDirection: SortDirection.ASCENDING,
                    }}
                  />
                ),
              },
            ]}
          />
        )}
      </div>
      {activeTab === "categories" && (
        <UserGroupCategoriesPanel
          filterConfigStorageKey={categoriesStorageKeys.filterConfigStorageKey}
        />
      )}
      {activeTab === "groups" && (
        <UserGroupsTablePanel
          filterConfigStorageKey={groupsStorageKeys.filterConfigStorageKey}
          tableConfigStorageKey={groupsStorageKeys.tableConfigStorageKey}
        />
      )}
    </>
  );
};

export default UserGroupsTab;
