import {
  Checkbox,
  DataTable,
  DropdownInput,
  Form,
} from "@validereinc/common-components";
import classNames from "classnames/bind";
import cloneDeep from "lodash/cloneDeep";
import get from "lodash/get";
import startCase from "lodash/startCase";
import * as PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";

import styles from "./DataMappingForm.module.scss";

const cx = classNames.bind(styles);

const EntityMappingPanel = ({
  entityCategory,
  entityCategories,
  isEntityCategoriesLoading,
  previewData,
  form,
  mappingStrategy,
}) => {
  const [displayEntityCategories, setDisplayEntityCategories] = useState([]);

  const [showOptionalFields, setShowOptionalFields] = useState(true);

  const values = form.watch();

  useEffect(() => {
    if (showOptionalFields) {
      setDisplayEntityCategories(entityCategories);
    } else {
      setDisplayEntityCategories(
        entityCategories.filter(({ required }) => required)
      );
    }
  }, [showOptionalFields, entityCategories]);

  // Needed because MultiDropdownInputWithSearch manipulates the original objects
  const previewDataOptions = useMemo(
    () => cloneDeep(previewData).map(({ label }) => label),
    [previewData]
  );

  const filledFields = useMemo(
    () =>
      entityCategories.filter(
        (entity) => get(values, entity.field_name) !== undefined
      ),
    [entityCategories, values]
  );

  return (
    <div className={cx("subsection")}>
      <div className={cx("streamsHeader")}>
        <div>
          <p className={cx("subsectionHeader")}>{startCase(entityCategory)}</p>
          <p>
            {filledFields.length}/{entityCategories.length} fields filled
          </p>
        </div>

        {mappingStrategy === "create" ? (
          <Checkbox
            value={showOptionalFields}
            onChange={setShowOptionalFields}
            label="Show Optional Fields"
          />
        ) : null}
      </div>

      <Form {...form}>
        <DataTable
          headers={[
            {
              label: "Entity Fields",
              key: "label",
              renderComponent: ({ item: { field_name, required } }) =>
                `${startCase(field_name)}${
                  mappingStrategy === "create" && !required ? " (optional)" : ""
                }`,
            },
            {
              label: "Header to Associate",
              key: "associated_header",
              renderComponent: ({ item: { field_name } }) => {
                return (
                  <DropdownInput
                    key={field_name}
                    name={field_name}
                    options={previewDataOptions}
                    isMulti={false}
                    onChange={(newValues) => {
                      form.setValue(field_name, newValues);
                    }}
                    value={get(values, field_name)}
                    isClearable
                  />
                );
              },
            },
            {
              label: "ID Lookup",
              key: "lookup",
              renderComponent: ({ item: { field_name, lookupable } }) => {
                const isChecked = !!get(values, `lookup.${field_name}`);
                return lookupable ? (
                  <Checkbox
                    value={isChecked}
                    onChange={() =>
                      form.setValue(`lookup.${field_name}`, !isChecked)
                    }
                  />
                ) : null;
              },
            },
          ]}
          items={displayEntityCategories}
          isLoading={isEntityCategoriesLoading}
        />
      </Form>
    </div>
  );
};

EntityMappingPanel.propTypes = {
  entityCategory: PropTypes.string.isRequired,
  entityCategories: PropTypes.array.isRequired,
  isEntityCategoriesLoading: PropTypes.bool.isRequired,
  previewData: PropTypes.array.isRequired,
  form: PropTypes.object.isRequired,
  mappingStrategy: PropTypes.string,
};

export default EntityMappingPanel;
