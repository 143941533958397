import React, { useEffect, useState } from "react";
import * as PropTypes from "prop-types";
import { getAnnotationDetails } from "../../Services/AnnotationsService";
import Annotate from "./Annotate";
import { Redirect } from "react-router-dom";
import { linkToAnnotations } from "../../../Routers/links";

const HEIGHT_OFFSET = 185;

const MIN_HEIGHT = 1200;

const useFetchTest = (testId) => {
  const [isLoading, setIsLoading] = useState(true);
  const [errorStatus, setErrorStatus] = useState(null);
  const [testDetails, setTestDetails] = useState(null);

  useEffect(() => {
    getAnnotationDetails(testId)
      .then(({ data }) => {
        setTestDetails(data);
        setIsLoading(false);
      })
      .catch(({ response }) => {
        setErrorStatus(response.status);
        setIsLoading(false);
      });
  }, []);

  return [isLoading, errorStatus, testDetails];
};

const AnnotatePage = (props) => {
  const { height, match } = props;

  const annotationsListHeight = Math.max(height + HEIGHT_OFFSET, MIN_HEIGHT);
  const testId = match.params.testId;
  const [isLoading, errorStatus, testDetails] = useFetchTest(testId);

  if (isLoading) {
    return <div>Loading</div>;
  }

  if (errorStatus === 403) {
    return <Redirect to={{ pathname: linkToAnnotations() }} />;
  } else if (errorStatus) {
    return <div>There was an error</div>;
  }

  return (
    <div style={{ height: annotationsListHeight }}>
      <Annotate testDetails={testDetails} />
    </div>
  );
};
AnnotatePage.propTypes = {
  height: PropTypes.number,
  match: PropTypes.object.isRequired,
};

export default AnnotatePage;
