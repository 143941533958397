import { useState, useLayoutEffect } from "react";

export const useMediaBreakpoint = () => {
  const breakpoints = {
    initial: 0,
    xs: 520,
    sm: 768,
    md: 1024,
    lg: 1280,
    xl: 1640,
  } as const;
  const determineBreakpoint = (screenWidth: number): keyof typeof breakpoints =>
    (Object.keys(breakpoints) as Array<keyof typeof breakpoints>)
      .reverse()
      .find((breakpoint) => {
        return screenWidth >= breakpoints[breakpoint];
      }) ?? "initial";

  const [currentBreakpoint, setCurrentBreakpoint] = useState<
    keyof typeof breakpoints
  >(() => determineBreakpoint(window.innerWidth));

  const handleWindowResize = () => {
    setCurrentBreakpoint(determineBreakpoint(window.innerWidth));
  };

  useLayoutEffect(() => {
    window.addEventListener("resize", handleWindowResize);

    return () => window.removeEventListener("resize", handleWindowResize);
  });

  return {
    currentBreakpoint,
    currentBreakpointWidth: breakpoints[currentBreakpoint],
    allBreakpoints: breakpoints,
  };
};
