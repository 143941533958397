export * from "./AllEventsPage";

export const EVENTS_ALL_PAGE_TITLE = "All Events";

export const linkToAllEvents = () => {
  return `/app/events/all-events`;
};

export const EVENTS_ALL_BREADCRUMB = {
  title: EVENTS_ALL_PAGE_TITLE,
  path: linkToAllEvents,
};

export const EVENTS_ALL_TAB = {
  id: "all_events",
  title: EVENTS_ALL_PAGE_TITLE,
};
