import styles from "@validereinc/common-components/constants";
import * as PropTypes from "prop-types";
import React from "react";
import FontAwesome from "react-fontawesome";
import "./TestsList.scss";

const TEST_TYPE_LABEL = {
  field: "Field",
  lab: "Third Party Lab",
};

const getTestIcon = (testType) => {
  switch (testType) {
    case "accepted":
      return { color: styles.statusCompletedColour, icon: "check-circle" };
    case "pending":
    case "preliminary":
      return { color: styles.statusPendingColour, icon: "pause-circle" };
    case "error":
    case "preliminary-error":
      return { color: styles.statusErrorColour, icon: "times-circle" };
    default:
      return null;
  }
};

const TestListItem = ({ test, onClick }) => {
  const { type, instrumentName, note_count, status } = test;

  const testIcon = getTestIcon(status);

  const itemTitle =
    type === "lab"
      ? TEST_TYPE_LABEL[type]
      : `${TEST_TYPE_LABEL[type]}: ${instrumentName ?? ""}`;

  const noteCountString = note_count
    ? ` (${note_count} ${note_count > 1 ? "notes" : "note"})`
    : "";

  return (
    <div>
      <a
        className="linkableText"
        onClick={onClick}
      >
        {testIcon && (
          <FontAwesome
            name={testIcon.icon}
            style={{
              color: testIcon.color,
            }}
            className="icon"
          />
        )}

        {`${itemTitle} ${noteCountString}`}
      </a>
    </div>
  );
};

const TestsList = ({ tests, onClick }) => {
  return (
    <div className="testsList">
      {tests?.length ? (
        tests.map((test) => (
          <TestListItem
            key={test.id}
            test={test}
            onClick={() => onClick(test)}
          />
        ))
      ) : (
        <div>Sample has no Associated tests</div>
      )}
    </div>
  );
};

TestListItem.propTypes = {
  test: PropTypes.object,
  onClick: PropTypes.func,
};

TestsList.propTypes = {
  tests: PropTypes.array,
  onClick: PropTypes.func,
};

export default TestsList;
