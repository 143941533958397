import SummaryInformation from "#common/SummaryInformation/SummaryInformation";
import { linkToInstrumentTests } from "#routers/links";
import config from "#src/config";
import { getTimeStringFromDate } from "#utils/timeFormatter";
import { Panel, Tooltip } from "@validereinc/common-components";
import * as PropTypes from "prop-types";
import React from "react";

const NameWithTooltip = ({ name }) => {
  const tooltip = (
    <Tooltip content={name}>
      <div className="instrumentName">{name}</div>
    </Tooltip>
  );

  return tooltip;
};

const TestOverview = ({ testDetail }) => {
  const { name, status, type, date, instrument, sample, tested_by } =
    testDetail;

  return (
    <Panel
      className="testAnalysis__overview"
      style={{ paddingTop: "5px" }}
    >
      <div>
        <SummaryInformation
          title="Stream"
          value={<NameWithTooltip name={name ?? "-"} />}
        />

        <SummaryInformation
          title="Type"
          value={type ?? "-"}
          capitalized
        />

        <SummaryInformation
          title="Instrument"
          value={<NameWithTooltip name={instrument?.name ?? "-"} />}
          capitalized
          link={instrument ? linkToInstrumentTests(instrument?.id) : null}
        />
      </div>

      <div>
        <SummaryInformation
          title="Test Date"
          value={getTimeStringFromDate(date, config.DATETIME_FORMAT)}
          capitalized
        />

        <SummaryInformation
          title="Test State"
          value={status ?? "-"}
          capitalized
        />

        <SummaryInformation
          title="Tester"
          value={tested_by ?? "-"}
          capitalized
          suppress
        />
      </div>

      <div>
        <SummaryInformation
          title="Sample Date"
          value={getTimeStringFromDate(
            sample?.ended_at,
            config.DATETIME_FORMAT
          )}
          capitalized
        />

        <SummaryInformation
          title="Sample State"
          value={sample?.state ?? "-"}
          capitalized
        />

        <SummaryInformation
          title="Sample by"
          value={sample?.sampled_by ?? "-"}
          capitalized
          suppress
        />
      </div>
    </Panel>
  );
};

TestOverview.propTypes = {
  testDetail: PropTypes.object,
};

export default TestOverview;
