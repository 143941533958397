import {
  ErrorCodes,
  ErrorCodesToExplanationGetters,
  HTTPForbiddenError,
  HTTPNotFoundError,
  HTTPResponseError,
} from "../errors";
import { HTTPUtils } from "../http";
import { RequestWrapperType, requestWrapper } from "./requestWrapper";

export type DELETERequestType = { version?: number } & Pick<
  RequestWrapperType,
  "endpoint" | "headers"
>;

type DELETEProps = {
  api: string;
} & DELETERequestType;

// IMPROVE: this needs refactoring - this method is unnecessary and can be folded into the callee
/**
 * Some successful 20X responses from the backend will contain
 * No content. Calling response.json() will throw an exception
 * even though the response suceeded.
 */
async function parseResponse(response: Response) {
  try {
    return await response.json();
  } catch (e) {
    if (HTTPUtils.status.isSuccess(response.status)) {
      return {};
    }

    throw e;
  }
}

export const DELETE = async <T = unknown>({
  api,
  endpoint,
  headers = {},
}: DELETEProps): Promise<T> => {
  try {
    const request = requestWrapper({
      method: "DELETE",
      endpoint: `${api}${endpoint}`,
      headers,
    });
    const response = await fetch(request);

    if (HTTPUtils.status.isSuccess(response.status)) {
      return parseResponse(response) as T;
    } else {
      throw { response, request };
    }
  } catch (cause: unknown) {
    if (!HTTPUtils.objectHasResponseAndRequest(cause)) {
      throw new HTTPResponseError("DELETE: unknown error", {
        code: ErrorCodes["http, unknown error"],
        getExplanation:
          ErrorCodesToExplanationGetters[ErrorCodes["http, unknown error"]],
        method: "DELETE",
        endpoint: `${api}${endpoint}`,
        cause,
      });
    }

    if (
      cause.response.headers.get("Content-Type")?.includes("application/json")
    ) {
      if (HTTPUtils.status.isForbidden(cause.response.status)) {
        throw new HTTPForbiddenError("DELETE: forbidden", {
          responseBodyJson: await cause.response.json(),
          status: cause.response.status,
          responseContentType: cause.response.headers.get("Content-Type"),
          endpoint: cause.request.url,
          method: "DELETE",
        });
      } else if (HTTPUtils.status.isNotFound(cause.response.status)) {
        throw new HTTPNotFoundError("DELETE: resource not found", {
          responseBodyJson: await cause.response.json(),
          status: cause.response.status,
          responseContentType: cause.response.headers.get("Content-Type"),
          endpoint: cause.request.url,
          method: "DELETE",
        });
      } else {
        throw new HTTPResponseError(
          "DELETE: unknown error with info from API",
          {
            responseBodyJson: await cause.response.json(),
            status: cause.response.status,
            responseContentType: cause.response.headers.get("Content-Type"),
            endpoint: cause.request.url,
            method: "DELETE",
          }
        );
      }
    }

    throw new HTTPResponseError("DELETE: unknown error", {
      code: ErrorCodes["http, unknown error"],
      getExplanation:
        ErrorCodesToExplanationGetters[ErrorCodes["http, unknown error"]],
      status: cause.response.status,
      responseContentType: cause.response.headers.get("Content-Type"),
      endpoint: cause.request.url,
      method: "DELETE",
    });
  }
};
