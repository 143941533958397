import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Button, Dialog, useAlert } from "@validereinc/common-components";
import { FormSchema, FormSchemaUpdateOneType } from "@validereinc/domain";
import startCase from "lodash/startCase";
import React from "react";

import type { DialogProps } from "@validereinc/common-components";

const STATUS_MAPPING = {
  archived: {
    present: "archive",
    past: "archived",
    warning:
      "Users will no longer be able to create submissions for this form.",
  },
  active: {
    present: "activate",
    past: "activated",
    warning: "Users will now be able to create submissions for this form.",
  },
  draft: {
    present: "draft",
    past: "drafted",
    warning:
      "Users will no longer be able to create submissions for this form.",
  },
};

export const UpdateFormTemplateStatusDialog = ({
  isOpen,
  onClose,
  formSchemaId,
  status,
}: Pick<DialogProps, "isOpen" | "onClose"> & {
  formSchemaId: string;
  status?: "archived" | "active" | "draft";
}) => {
  const queryClient = useQueryClient();
  const { addAlert } = useAlert();

  const {
    present,
    warning,
  }: { past?: string; present?: string; warning?: string } = status
    ? STATUS_MAPPING[status]
    : {};

  const { mutate, isLoading: isMutating } = useMutation({
    mutationFn: (data: FormSchemaUpdateOneType["data"]) =>
      FormSchema.updateOne({ id: formSchemaId, data }),

    onSuccess: ({ status: newStatus }) => {
      queryClient.invalidateQueries({
        queryKey: ["formSchemas"],
      });

      const { past }: { past?: string; present?: string; warning?: string } =
        newStatus ? STATUS_MAPPING[newStatus] : {};

      addAlert?.({
        variant: "success",
        message: `Successfully ${past} template.`,
      });
    },
    onError: () => {
      const { past }: { past?: string; present?: string; warning?: string } =
        status ? STATUS_MAPPING[status] : {};

      addAlert?.({
        variant: "error",
        message: `Couldn't ${past} template.`,
      });
    },
  });

  const onSubmit = () => {
    mutate({
      status,
    });

    onClose();
  };

  return (
    <Dialog
      title={`${startCase(present)} Template?`}
      isOpen={isOpen && !!status}
      onClose={onClose}
      actionRow={[
        <Button
          key="archive"
          variant="error-outline"
          isLoading={isMutating}
          onClick={onSubmit}
        >
          {startCase(present)} Template
        </Button>,
      ]}
    >
      Are you sure you want to {present} this template? {warning}
    </Dialog>
  );
};
