import { useMultiStepFormContext } from "#src/hooks/useMultiStepForm";
import { useStorageKey } from "#src/hooks/useStorageKey";
import { useQuery } from "@tanstack/react-query";
import { Panel } from "@validereinc/common-components";
import {
  UserGroupMembershipType,
  UserGroupsAdapter,
  UserType,
} from "@validereinc/domain";
import classNames from "classnames/bind";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import UserGroupsFilterPanel from "../../FilterPanels/UserGroupsFilterPanel";
import { SelectedUsersPanel } from "./SelectedUsersPanel";
import styles from "./UserGroupFormPanel.module.scss";
import { UserSelectionTablePanel } from "./UserSelectionTablePanel";

const cx = classNames.bind(styles);

export const UserSelectionStep = () => {
  const STEP_NUMBER = 2;
  const { currentStep, updateStep } = useMultiStepFormContext();
  const { filterConfigStorageKey } = useStorageKey("user-selection-step");

  const [selectedUsers, setSelectedUsers] = useState<
    Array<UserGroupMembershipType["user"]>
  >([]);

  const { groupId } = useParams<{ groupId: string }>();

  const groupMembersQuery = useQuery({
    queryFn: () =>
      UserGroupsAdapter.members.getList({ meta: { userGroupId: groupId } }),
    enabled: !!groupId,
  });

  useEffect(() => {
    if (!Array.isArray(groupMembersQuery.data?.data)) return;
    setSelectedUsers(groupMembersQuery.data.data.map(({ user }) => user));
  }, [groupMembersQuery.data]);

  const addUser = (newUser: UserType) => {
    const existingUsers = [...selectedUsers].filter(
      (user) => newUser.id !== user.id
    );
    setSelectedUsers([...existingUsers, newUser]);
  };

  const removeUser = (userId: string) => {
    setSelectedUsers(selectedUsers.filter(({ id }) => id !== userId));
  };

  useEffect(() => {
    const values = { user_ids: selectedUsers.map(({ id }) => id) };
    updateStep(STEP_NUMBER, {
      getValues: () => values,
      getFormState: () => ({
        isValid: !!Object.keys(values).length,
        isSubmitting: false,
      }),
    });
  }, [selectedUsers]);

  const isLoading = !!groupId && groupMembersQuery.isLoading;

  return (
    <>
      {currentStep === STEP_NUMBER ? (
        <Panel
          title="Users"
          loaded={!isLoading}
        >
          <div>
            <UserGroupsFilterPanel
              filterConfigStorageKey={filterConfigStorageKey}
            />

            <div className={cx("connectionsPanelsContainer")}>
              <UserSelectionTablePanel
                addUser={addUser}
                filterConfigStorageKey={filterConfigStorageKey}
                selectedUsers={selectedUsers}
              />

              <div className={cx("selectedAssetsPanelContainer")}>
                <SelectedUsersPanel
                  removeUser={removeUser}
                  selectedUsers={selectedUsers}
                />
              </div>
            </div>
          </div>
        </Panel>
      ) : null}
    </>
  );
};
