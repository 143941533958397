import { Page } from "@validereinc/common-components";
import * as PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import AlertService from "../../../Services/AlertService";
import { getStreamSettingsBreadcrumbs } from "./SettingsHelper";
import StreamAlertDetail from "./StreamAlertDetail";
import StreamAlertCreate from "./StreamAlertsCreate";
import StreamAlertsView from "./StreamAlertsView";

const StreamSettings = ({ height }) => {
  const [page, setPage] = useState("view");
  const [selectedStreams, setSelectedStreams] = useState([]);
  const [streamAlertsList, setStreamAlertsList] = useState([]);
  const [lastFetchedAt, setLastFetchedAt] = useState(new Date());
  const [loadingState, setLoadingState] = useState("isLoading");
  const [preSelectedMeasurement, setPreSelectedMeasurement] = useState(null);

  useEffect(() => {
    AlertService.getStreamAlertsSettings()
      .then(({ data }) => {
        setStreamAlertsList(data);
      })
      .finally(() => {
        setLoadingState("loaded");
      });
  }, [lastFetchedAt]);

  useEffect(() => {
    if (page === "view") {
      setSelectedStreams([]);
      setPreSelectedMeasurement(null);
    }
    if (page === "details") {
      setPreSelectedMeasurement(null);
    }
  }, [page]);

  const getTitle = (page) => {
    if (page === "view") {
      return "Stream Alerts";
    }
    if (page === "details") {
      return selectedStreams[0].name;
    }
    if (page === "create") {
      return "Create Stream Alert";
    }
  };

  const refetchStreams = () => {
    setLastFetchedAt(new Date());
  };

  const onMeasurementClick = (measurement) => {
    setPreSelectedMeasurement(measurement);
  };

  const changePage = (page) => {
    setPage(page);
  };

  const updateSelectedStreams = (streams) => {
    setSelectedStreams(streams);
  };

  return (
    <Page
      title={getTitle(page)}
      isLoading={loadingState !== "loaded"}
      breadcrumbs={getStreamSettingsBreadcrumbs(selectedStreams, setPage, page)}
    >
      {page === "view" && (
        <StreamAlertsView
          height={height}
          streamAlertsList={streamAlertsList}
          changePage={changePage}
          updateSelectedStreams={updateSelectedStreams}
          selectedStreams={selectedStreams}
        />
      )}
      {page === "details" && (
        <StreamAlertDetail
          height={height}
          changePage={changePage}
          selectedStream={selectedStreams[0]}
          onMeasurementClick={onMeasurementClick}
          streamAlertsList={streamAlertsList}
        />
      )}
      {page === "create" && (
        <StreamAlertCreate
          height={height}
          selectedStreams={selectedStreams}
          preSelectedMeasurement={preSelectedMeasurement}
          refetchStreams={refetchStreams}
        />
      )}
    </Page>
  );
};

StreamSettings.propTypes = {
  height: PropTypes.number.isRequired,
};

export default StreamSettings;
