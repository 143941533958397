import {
  getAlertParameter,
  getBeautifiedAlertType,
} from "#utils/stringFormatter";
import {
  Button,
  LegacyDataTable,
  Panel,
  Tooltip,
} from "@validereinc/common-components";
import * as PropTypes from "prop-types";
import React from "react";
import { AutoSizer } from "react-virtualized";
import "./StreamAlerts.scss";
import useFilterInputs from "./StreamAlertsTableFilter";

const StreamAlertTooltipContent = ({ streamAlertDetail, alertType }) => {
  return streamAlertDetail[alertType].map((alert) => {
    const alertParameter = getAlertParameter(alert, alertType);
    const displayProperty =
      alertType === "inline_monitors"
        ? alert.property
        : `${alert.test_property}:`;

    return (
      <div
        key={alert.id}
        className="streamAlertsView__tooltip"
      >
        {displayProperty} {alertType !== "inline_monitors" && alertParameter}
      </div>
    );
  });
};

const StreamAlertsView = ({
  height,
  streamAlertsList,
  selectedStreams,
  changePage,
  updateSelectedStreams,
}) => {
  const [filterRow, filterPillbox, filteredStreams] =
    useFilterInputs(streamAlertsList);

  const onStreamClick = (stream) => {
    changePage("details");
    updateSelectedStreams([stream]);
  };

  const onStreamCheckboxClick = (streams) => {
    updateSelectedStreams(streams);
  };

  const nameRenderer = (rowData, columnKey) => {
    return <div className="wrap">{rowData[columnKey]}</div>;
  };

  const alertNumberRenderer = (rowData, columnKey) => {
    const title = getBeautifiedAlertType(columnKey);
    if (!rowData || rowData[columnKey].length === 0) {
      return "-";
    }
    return (
      <Tooltip
        title={title}
        content={
          <StreamAlertTooltipContent
            streamAlertDetail={rowData}
            alertType={columnKey}
          />
        }
        width={400}
      >
        <div className="wrap streamAlertsView__alertUnderline">
          {rowData[columnKey].length} Alerts
        </div>
      </Tooltip>
    );
  };

  const actionRow = (
    <div>
      <Button
        variant="primary"
        onClick={() => changePage("create")}
        disabled={selectedStreams.length === 0}
      >
        Add Stream Alerts
      </Button>
    </div>
  );

  const headers = [
    {
      label: "Stream",
      key: "name",
      width: 300,
      cellRenderer: nameRenderer,
    },
    {
      label: "Product Type",
      key: "product_type",
      cellRenderer: nameRenderer,
      width: 125,
    },
    {
      label: "Limit Alerts",
      key: "limit_alerts",
      cellRenderer: alertNumberRenderer,
      width: 150,
    },
    {
      label: "Statistical Alerts",
      key: "statistical_alerts",
      cellRenderer: alertNumberRenderer,
      width: 150,
    },
    {
      label: "Balance Alerts",
      key: "balance_alerts",
      cellRenderer: alertNumberRenderer,
      width: 150,
    },
    {
      label: "Inline Alerts",
      key: "inline_monitors",
      cellRenderer: alertNumberRenderer,
      width: 150,
    },
  ];

  return (
    <Panel>
      <div style={{ height: height }}>
        <AutoSizer>
          {({ height, width }) => (
            <LegacyDataTable
              height={height}
              width={width}
              headers={headers}
              list={filteredStreams}
              checkedList={selectedStreams}
              onCheckboxClick={onStreamCheckboxClick}
              rowHeight={60}
              filterRow={filterRow}
              filterPillbox={filterPillbox}
              onCellClick={onStreamClick}
              actionRow={actionRow}
            />
          )}
        </AutoSizer>
      </div>
    </Panel>
  );
};

StreamAlertsView.propTypes = {
  height: PropTypes.number.isRequired,
  streamAlertsList: PropTypes.object.isRequired,
  selectedStreams: PropTypes.array.isRequired,
  changePage: PropTypes.object.isRequired,
  updateSelectedStreams: PropTypes.object.isRequired,
};

StreamAlertTooltipContent.propTypes = {
  streamAlertDetail: PropTypes.object.isRequired,
  alertType: PropTypes.string.isRequired,
};

export default StreamAlertsView;
