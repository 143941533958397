import EmptyChartIcon from "#components/Common/SVG/EmptyChartIcon";
import DashboardService from "#components/Services/DashboardService";
import { useBreadcrumbs } from "#routers/breadcrumbsHelper";
import { useParams } from "#routers/hooks";
import ChartDrawer from "#src/batteries-included-components/Drawers/DashboardChartDrawer";
import { AccessDeniedLayout } from "#src/batteries-included-components/Layouts/Authorization/AccessDenied";
import DashboardDetailsModal from "#src/batteries-included-components/Modals/CreateDashboardModal/CreateDashboardModal";
import DeleteDashboardModal from "#src/batteries-included-components/Modals/DeleteDashboardModal/DeleteDashboardModal";
import DashboardChart from "#src/batteries-included-components/Panels/ChartPanels/XYChartPanels/DashboardMeasurementsPanel/DashboardChart";
import {
  useIsFeatureAvailable,
  useLegacyIsFeatureAvailable,
} from "#src/contexts/AuthenticatedContext.helpers";
import { DASHBOARDS_BREADCRUMB } from "#src/routes/dashboard";
import { Button, Page } from "@validereinc/common-components";
import classNames from "classnames/bind";
import React, { useEffect, useState } from "react";
import { CUSTOM_DASHBOARD_DETAILS_DASHBOARD_BREADCRUMB } from ".";
import { CUSTOM_DASHBOARD_BREADCRUMB } from "../..";
import styles from "./CustomDashboardDetails.module.scss";

const cx = classNames.bind(styles);

export const CustomDashboardDetailsPage = () => {
  const [isCustomDashboardsAvailableLegacy] = useLegacyIsFeatureAvailable({
    featureFlagQuery: ["core/dashboards"],
    permissionQuery: ["core:dashboard"],
    featureId: "dashboards",
  });
  const [isCustomDashboardsAvailable] = useIsFeatureAvailable({
    featureFlagQuery: "core:dashboards",
    permissionQuery: "dashboards:read",
  });
  const { dashboardId } = useParams<{ dashboardId: string }>();
  const [isEditModalOpen, setIsEditmodalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeletemodalOpen] = useState(false);
  const [dashboardDetail, setDashboardDetail] = useState();
  const [isCreateChartDrawerOpen, setIsCreateChartDrawerOpen] = useState(false);

  const fetchDetails = async () => {
    const { data } = await DashboardService.getDashboardDetail(dashboardId);

    setDashboardDetail(data);
  };

  const onOpenEditModal = () => setIsEditmodalOpen(true);
  const onCloseEditModal = (shouldRefetch = false) => {
    if (shouldRefetch) {
      fetchDetails();
    }

    setIsEditmodalOpen(false);
  };

  const onOpenDeleteModal = () => setIsDeletemodalOpen(true);
  const onCloseDeleteModal = () => setIsDeletemodalOpen(false);

  const onOpenCreateChartDrawer = () => setIsCreateChartDrawerOpen(true);
  const onCloseCreateChartDrawer = async (shouldRefetchData = false) => {
    if (shouldRefetchData) {
      await fetchDetails();
    }

    setIsCreateChartDrawerOpen(false);
  };

  const AddChartAction = () => (
    <Button
      variant="primary"
      onClick={onOpenCreateChartDrawer}
      disabled={!dashboardDetail}
    >
      Add Chart
    </Button>
  );

  const actionRow = [
    <Button
      key="delete-dashboard-action"
      variant="error-outline"
      onClick={onOpenDeleteModal}
      disabled={!dashboardDetail}
    >
      Delete
    </Button>,
    <Button
      key="edit-dashboard-action"
      variant="outline"
      onClick={onOpenEditModal}
      disabled={!dashboardDetail}
    >
      Edit Details
    </Button>,
    <AddChartAction key="add-chart-action" />,
  ];

  useEffect(() => {
    (async () => {
      await fetchDetails();
    })();
  }, [dashboardId]);

  if (!isCustomDashboardsAvailable && !isCustomDashboardsAvailableLegacy) {
    return <AccessDeniedLayout />;
  }

  return (
    <Page
      title={dashboardDetail?.name}
      breadcrumbs={useBreadcrumbs(
        [
          DASHBOARDS_BREADCRUMB,
          CUSTOM_DASHBOARD_BREADCRUMB,
          CUSTOM_DASHBOARD_DETAILS_DASHBOARD_BREADCRUMB,
        ],
        { 2: dashboardDetail?.name }
      )}
      actionRow={actionRow}
    >
      {!dashboardDetail?.chart?.length ? (
        <div className={cx("emptyState")}>
          <EmptyChartIcon />

          <p className={cx("error")}>There are no charts to display</p>
          <p className={cx("helper")}>
            Create a new chart to begin plotting data.
          </p>

          <AddChartAction />
        </div>
      ) : null}

      {dashboardDetail?.chart
        ?.sort(
          ({ json: { order: aOrder } }, { json: { order: bOrder } }) =>
            aOrder - bOrder
        )
        ?.map((chart) => (
          <DashboardChart
            key={chart.order}
            {...chart}
            onRefetchData={fetchDetails}
          />
        ))}

      <ChartDrawer
        dashboardId={dashboardId}
        isOpen={isCreateChartDrawerOpen}
        onClose={onCloseCreateChartDrawer}
        order={dashboardDetail?.chart?.length}
      />

      <DashboardDetailsModal
        isOpen={isEditModalOpen}
        onClose={onCloseEditModal}
        dashboard={dashboardDetail}
      />

      <DeleteDashboardModal
        isOpen={isDeleteModalOpen}
        onClose={onCloseDeleteModal}
        dashboardName={dashboardDetail?.name}
      />
    </Page>
  );
};
