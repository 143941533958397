import {
  Button,
  Form,
  FormButton,
  Modal,
  Title,
  useForm,
} from "@validereinc/common-components";
import * as PropTypes from "prop-types";
import React, { useEffect } from "react";
import { getSourceTitle } from "../BalanceHelper";
import SourceSelectionForm from "./SourceSelectionForm";
import "./SourceSelectionModal.scss";
import { getSourcesWithMultiplier } from "./SourceSelectionModalHelper";

const DEFAULT_INPUTS = {
  sources: [],
  multiplier: "1",
};

const SourceSelectionModal = ({
  show,
  sources,
  sourceAction,
  selectedSourceType,
  onSourceInputChange,
  onHide,
}) => {
  const form = useForm({
    defaultValues: DEFAULT_INPUTS,
  });

  const onSourceChecked = (checkedSources) => {
    form.setValue("sources", checkedSources);
  };

  const onDeleteClick = () => {
    const selectedSources = form.getValues("sources");

    onSourceInputChange("delete", selectedSources);
    onHide();
  };

  const onStreamSubmit = (inputs) => {
    const selectedSources = getSourcesWithMultiplier(
      inputs.sources,
      inputs.multiplier
    );

    onSourceInputChange("add", selectedSources);
    onHide();
  };

  useEffect(() => {
    if (show) {
      if (sourceAction === "edit" || sourceAction === "delete") {
        const multiplier =
          sources?.length === 1 ? sources[0]?.multiplier : null;

        form.reset({
          sources,
          multiplier,
        });
      }
    }

    if (!show) {
      form.reset();
    }
  }, [show]);

  return (
    <Modal
      className="sourceSelectionModal"
      size="large"
      open={show}
      onClose={onHide}
    >
      <Modal.Header>
        <Title className="capitalized">
          {`${sourceAction} ${getSourceTitle(selectedSourceType)}`}
        </Title>
      </Modal.Header>

      <Form
        onSubmit={onStreamSubmit}
        {...form}
      >
        <Modal.Body>
          <SourceSelectionForm
            selectedSourceType={selectedSourceType}
            sources={sources}
            sourceAction={sourceAction}
            onSourceChecked={sourceAction === "add" ? onSourceChecked : null}
          />
        </Modal.Body>

        <Modal.Footer className="sourceSelectionModal__footer clearfix">
          <Button
            className="pull-left"
            onClick={onHide}
          >
            Close
          </Button>

          {sourceAction === "edit" && (
            <>
              <Button
                variant="error-outline"
                onClick={onDeleteClick}
              >
                delete selected streams
              </Button>

              <FormButton
                variant="primary"
                type="submit"
              >
                edit streams
              </FormButton>
            </>
          )}

          {sourceAction === "add" && (
            <FormButton
              variant="primary"
              type="submit"
            >
              add streams
            </FormButton>
          )}
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

SourceSelectionModal.propTypes = {
  show: PropTypes.bool.isRequired,
  sources: PropTypes.array.isRequired,
  sourceAction: PropTypes.string.isRequired,
  selectedSourceType: PropTypes.string.isRequired,
  onSourceInputChange: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
};

export default SourceSelectionModal;
