import { UseQueryResult, useQuery } from "@tanstack/react-query";
import type { UserType } from "@validereinc/domain";
import { UsersAdapter } from "@validereinc/domain";
import React, { createContext } from "react";

export const UserDetailContext = createContext<{
  userDetails: UseQueryResult<UserType, unknown>;
} | null>(null);

export const UserDetailProvider = ({
  userId,
  children,
}: {
  userId: string;
  children: React.ReactElement | React.ReactElement[];
}) => {
  const userDetails = useQuery(
    ["users", userId],
    () => UsersAdapter.getOne({ id: userId }),
    {
      enabled: Boolean(userId),
      staleTime: 5 * 60 * 1000,
      select: (resp) => resp?.data,
    }
  );

  return (
    <UserDetailContext.Provider value={{ userDetails }}>
      {children}
    </UserDetailContext.Provider>
  );
};
