import { Page, Panel } from "@validereinc/common-components";
import moment from "moment";
import * as PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { fetchSiteList } from "../Redux/actions/index";
import ThirdPartyLabImportService from "../Services/ThirdPartyLabImportService";
import ThirdPartyLabImportModal from "./ThirdPartyLabImportModal";
import "./ThirdPartyLabImports.scss";
import ThirdPartyLabImportsTable from "./ThirdPartyLabImportsTable";
import useFilterInputs from "./ThirdPartyLabImportsTableFilter";

const mapStateToProps = ({ sites }) => ({
  sites: sites?.data?.toJS() ?? [],
});

const mapDispatchToProps = {
  fetchSiteList,
};

const START_DATE = moment();
const END_DATE = moment().endOf("day");

const useFetchImports = () => {
  const [imports, setImports] = useState([]);
  const [state, setState] = useState("loading");
  const [lastFetchDate, setLastFetchDate] = useState(new Date());

  useEffect(() => {
    setState("loading");

    ThirdPartyLabImportService.getThirdPartyLabImportFiles(START_DATE, END_DATE)
      .then(({ data }) => {
        setImports(data);
        setState("loaded");
      })
      .catch(() => setState("error"));
  }, [lastFetchDate]);

  return [imports, state, setLastFetchDate];
};

const ThirdPartyLabImports = ({
  height,
  fetchSiteList,
  sites,
  breadcrumbs,
}) => {
  const [showImportModal, setShowImportModal] = useState(false);

  const [thirdPartyLabImportFiles, loadingState, fetchThirdPartyLabFiles] =
    useFetchImports();

  const [filterRow, filterPillbox, filteredThirdPartyLabImportFiles] =
    useFilterInputs(thirdPartyLabImportFiles);

  useEffect(() => {
    fetchSiteList();
  }, []);

  return (
    <Page
      title="Imports"
      isLoading={loadingState === "isLoading"}
      breadcrumbs={breadcrumbs}
    >
      <Panel
        style={{ height }}
        loaded={loadingState !== "loading"}
        className="thirdPartyLabImports__panel"
      >
        <ThirdPartyLabImportsTable
          height={height}
          thirdPartyLabImportFiles={filteredThirdPartyLabImportFiles}
          filterRow={filterRow}
          filterPillbox={filterPillbox}
          noFilterListCount={thirdPartyLabImportFiles?.length}
          openImportModal={() => setShowImportModal(true)}
        />

        {showImportModal && (
          <ThirdPartyLabImportModal
            sites={sites}
            show={showImportModal}
            onClose={() => setShowImportModal(false)}
            fetchThirdPartyLabFiles={() => fetchThirdPartyLabFiles(new Date())}
          />
        )}
      </Panel>
    </Page>
  );
};

ThirdPartyLabImports.propTypes = {
  height: PropTypes.number.isRequired,
  fetchSiteList: PropTypes.func.isRequired,
  sites: PropTypes.array.isRequired,
  breadcrumbs: PropTypes.array.isRequired,
};

const ThirdPartyLabImportsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ThirdPartyLabImports);

export default ThirdPartyLabImportsContainer;
