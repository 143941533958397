import { useFetchEmissionReports } from "#hooks/useEmissionReports";
import { havePermission } from "#redux/reducers/permissions";
import history from "#routers/history";
import { linkToCreateEmissionReports } from "#routers/links";
import { Button, Page, Panel } from "@validereinc/common-components";
import * as PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import EmissionReportsTable from "./EmissionReportsTable";
import useFilterInputs from "./EmissionReportsTableFilter";

const mapStateToProps = ({ permissions }) => ({
  isValidereUser: havePermission(permissions)(
    "esg:emissions_reports",
    "validere"
  ),
});

const EmissionReports = ({ height, isValidereUser, breadcrumbs }) => {
  const [emissionReports, emissionReportsLoadingState] =
    useFetchEmissionReports();

  const [filterRow, filterPillbox, filteredEmissionReports] =
    useFilterInputs(emissionReports);

  const actionRow = isValidereUser ? (
    <Button
      variant="primary"
      onClick={() => history.push(linkToCreateEmissionReports())}
    >
      Create Report
    </Button>
  ) : null;

  return (
    <Page
      title="Emission Reports"
      actionRow={actionRow}
      breadcrumbs={breadcrumbs}
    >
      <Panel
        style={{ height }}
        loaded={emissionReportsLoadingState === "loaded"}
      >
        <EmissionReportsTable
          emissionReports={filteredEmissionReports}
          filterRow={filterRow}
          filterPillbox={filterPillbox}
          noFilterListCount={emissionReports.length}
        />
      </Panel>
    </Page>
  );
};

EmissionReports.propTypes = {
  height: PropTypes.number.isRequired,
  isValidereUser: PropTypes.bool.isRequired,
  breadcrumbs: PropTypes.array.isRequired,
};

export default connect(mapStateToProps)(EmissionReports);
