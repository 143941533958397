import ProgressBar from "#common/ProgressBar/ProgressBar";
import { ensureStreamListIsFetched, fetchSiteList } from "#redux/actions/index";
import {
  CUBIC_METERS_VOLUME,
  getFormattedMeasurementValueWithUnit,
} from "#redux/reducers/measurements";
import { LegacyDataTable, Tooltip } from "@validereinc/common-components";
import get from "lodash/get";
import * as PropTypes from "prop-types";
import React, { useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { AutoSizer } from "react-virtualized";
import history from "../../Routers/history";
import { linkToInventories } from "../../Routers/links";
import "./InventoryTable.scss";
import useFilterInputs from "./InventoryTableFilter";
import {
  getCapacityPercentage,
  getInventoryList,
  sortByPercentage,
} from "./InventoryTableHelper";

const mapStateToProps = ({ streams, sites, measurements }) => {
  return {
    streams: streams.data?.toJS() ?? [],
    sites: sites.data?.toJS() ?? [],
    getFormattedMeasurementValueWithUnit: (measurementKey, measurementObject) =>
      getFormattedMeasurementValueWithUnit(measurements)(
        measurementKey,
        measurementObject
      ),
  };
};

const mapDispatchToProps = {
  ensureStreamListIsFetched,
  fetchSiteList,
};

const wrappedTextRenderer = (rowData, columnKey) => {
  const text = get(rowData, columnKey, "-");

  return (
    <Tooltip content={text}>
      <div className="inventoryTable__wrapTextCell">{text}</div>
    </Tooltip>
  );
};

const InventoryTable = (props) => {
  const inventoriesWithSitesWithProductType = useMemo(() => {
    return getInventoryList(props.sites, props.streams, props.inventories);
  }, [props.sites, props.streams, props.inventories]);

  const [
    filterRow,
    filterPillbox,
    filteredInventoriesWithSitesWithProductType,
  ] = useFilterInputs(inventoriesWithSitesWithProductType);

  useEffect(() => {
    props.ensureStreamListIsFetched();
    props.fetchSiteList();
  }, []);

  const onCellClick = (inventory) => {
    history.push(linkToInventories(inventory.id));
  };

  const volumeRenderer = (rowData) => {
    const { volume, capacity } = rowData;

    const tankVolume = `${props.getFormattedMeasurementValueWithUnit(
      CUBIC_METERS_VOLUME,
      volume
    )} / ${props.getFormattedMeasurementValueWithUnit(
      CUBIC_METERS_VOLUME,
      capacity
    )}`;

    const capacityPercentage = getCapacityPercentage(
      volume.value,
      capacity.value
    );

    return (
      <div className="inventoryTable__volumeCell">
        <div className="inventoryTable__volumeCell__volumeTitle">
          <div className="volume">{tankVolume}</div>
          <div className="percentage">{`${capacityPercentage}%`}</div>
        </div>
        <ProgressBar
          value={capacityPercentage}
          min={0}
          max={100}
        />
      </div>
    );
  };

  const headers = [
    {
      label: "Name",
      key: "name",
      width: 300,
      fixed: true,
      cellRenderer: wrappedTextRenderer,
    },
    {
      label: "Active Stream",
      key: "stream",
      width: 275,
      cellRenderer: wrappedTextRenderer,
    },
    {
      label: "Site",
      key: "siteName",
      width: 200,
    },
    {
      label: "Product",
      key: "productType",
      width: 100,
    },
    {
      label: "Volume",
      key: "volume",
      width: 240,
      cellRenderer: volumeRenderer,
      sort: sortByPercentage,
    },
  ];

  return (
    <AutoSizer>
      {({ height, width }) => (
        <LegacyDataTable
          width={width}
          height={height}
          headers={headers}
          list={filteredInventoriesWithSitesWithProductType}
          filterRow={filterRow}
          filterPillbox={filterPillbox}
          filterKey="name"
          defaultSortBy="name"
          defaultSortDirection="asc"
          rowHeight={60}
          noFilterListCount={props.inventories.length}
          isLoading={props.loadingState !== "loaded"}
          onCellClick={onCellClick}
          highlightRow
        />
      )}
    </AutoSizer>
  );
};

InventoryTable.propTypes = {
  inventories: PropTypes.array.isRequired,
  loadingState: PropTypes.string,
  ensureStreamListIsFetched: PropTypes.func,
  fetchSiteList: PropTypes.func,
  sites: PropTypes.array,
  streams: PropTypes.array,
  getFormattedMeasurementValueWithUnit: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(InventoryTable);
