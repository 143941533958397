export const FACILITY_EMISSION_RECORD_DETAIL_FALLBACK_PAGE_TITLE =
  "Emissions Record";

export const linkToFacilityEmissionRecordDetail = (
  facilityId = ":facilityId",
  emissionPeriod = ":emissionPeriod"
) =>
  `/app/organization/facilities/${facilityId}/emissions/${emissionPeriod}/detail`;

export const FACILITY_EMISSION_RECORD_DETAIL_BREADCRUMB = {
  title: FACILITY_EMISSION_RECORD_DETAIL_FALLBACK_PAGE_TITLE,
  path: linkToFacilityEmissionRecordDetail(),
};

export { default as FacilityEmissionsRecordDetail } from "./FacilityEmissionsRecordDetailPage";
