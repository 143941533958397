import { restAPI } from "./api";

export type QuickSightDashboardType = {
  name: string;
  id: string;
  updated_at: string;
};

type GetDashboardType = {
  name?: string;
  sortBy?: string;
  sortDirection?: "asc" | "desc";
};

export type QuickSightDashboardDetailType = {
  embed_url: string;
  dashboard: QuickSightDashboardType;
};

export type QuickSightSessionsType = {
  embed_url: string;
};

export const Insights = {
  /**
   * Get insights list dashboards
   * @see https://validere-integration-quicksight-api.s3.us-west-2.amazonaws.com/index.html#/Dashboards/get_dashboards_dashboards_get
   * @returns the insights list dashboards
   */
  getDashboards: ({
    name,
    sortBy = "name",
    sortDirection = "asc",
  }: GetDashboardType) =>
    restAPI.insightsAPI.GET<QuickSightDashboardType[]>({
      endpoint: "/dashboards",
      query: {
        sort_by: sortBy,
        sort_direction: sortDirection,
        name,
      },
    }),

  /**
   * Get insights dashboard details
   * @see https://validere-integration-quicksight-api.s3.us-west-2.amazonaws.com/index.html#/Dashboards/get_embed_url_dashboards__dashboard_id__embed_url_get
   * @returns the details for an insights dashboard with an embed url
   */
  getDashboardDetails: ({ insightsId }: { insightsId: string }) =>
    restAPI.insightsAPI.GET<QuickSightDashboardDetailType>({
      endpoint: `/dashboards/${insightsId}/embed_url`,
    }),

  /**
   * Get a temporary session URL and authorization code to embed a QuickSight session in a website/application
   * @see https://validere-integration-quicksight-api.s3.us-west-2.amazonaws.com/index.html#/Sessions/get_embed_url_sessions_embed_url_get
   * @returns insights embed url
   */
  getInsightsSessions: () =>
    restAPI.insightsAPI.GET<QuickSightSessionsType>({
      endpoint: `/sessions/embed_url`,
    }),
};
