import {
  Resources,
  WorkflowTemplateType,
  WorkflowTemplatesAdapter,
} from "@validereinc/domain";
import { useGetList, useGetOne } from "./adapterUtils";

export const useGetOneWorkflowTemplate = useGetOne<WorkflowTemplateType>(
  WorkflowTemplatesAdapter.getOne,
  Resources.WORKFLOW_TEMPLATE
);

export const useListWorkflowTemplates = useGetList<WorkflowTemplateType>(
  WorkflowTemplatesAdapter.getList,
  Resources.WORKFLOW_TEMPLATE
);
