import { styles } from "@validereinc/common-components";
import isNil from "lodash/isNil";
import { AssertIsFloatGreaterThan, AssertIsFloatLessThan } from "./assert";
import { colorPalette } from "./colorPalette";

const CREATE_COLOUR = "#00897B";
const DELETE_COLOUR = "#D32F2F";
const ALTER_COLOUR = "#f5d800";
const OTHER_COLOUR = "#2196f3";

const color = {
  success: "#03a9f4",
  warning: styles.status.warning,
  error: styles.status.error,
  info: "#9c27b0",
  missed: "#9b9b9b",
  completed: styles.status.success,
  sampled: styles.status.default,
};

const unknownStyle = {
  icon: "fa-minus-circle",
  color: "#ddd",
};

const negativeColor = "#e11f57";
const positiveColor = "#3e8ae1";

export function getProgressBarWidth(value, lowerLimit, upperLimit) {
  lowerLimit = lowerLimit || 0;
  upperLimit = upperLimit || 0;

  if (!isNil(value)) {
    const percent = getPercent(value, lowerLimit, upperLimit);
    return percent + "%";
  }

  return "0%";
}

export function getProgressColor(value, lowerlimit, upperlimit) {
  if (!lowerlimit) {
    lowerlimit = 0;
  }

  if (upperlimit === null && AssertIsFloatGreaterThan(value, lowerlimit)) {
    return color.success;
  }

  if (
    AssertIsFloatGreaterThan(value, lowerlimit) &&
    AssertIsFloatLessThan(value, upperlimit)
  ) {
    return color.success;
  } else {
    return color.error;
  }
}

export function getProgressTableCellAlignment(value) {
  if (value || value === 0) {
    return "left";
  } else {
    return "center";
  }
}

export function getStatusBarColor(value, lowerlimit, upperlimit, hasError) {
  if (hasError) {
    return "5px solid" + color.error;
  }

  return "5px solid" + getProgressColor(value, lowerlimit, upperlimit);
}

export function getStatusBarColorByStatus(status) {
  switch (status) {
    case "pending":
    case "preliminary":
    case "preliminary-error":
      return "5px solid" + color.warning;
    case "accepted":
      return "5px solid" + color.success;
    case "error":
      return "5px solid" + color.error;
    default:
      return "5px solid" + color.success;
  }
}

export function getStatusBarColorByMessage(errorMessage, warningMessage) {
  if (errorMessage && errorMessage !== "") {
    return "5px solid" + color.error;
  } else if (warningMessage && warningMessage !== "") {
    return "5px solid" + color.warning;
  } else {
    return "5px solid" + color.success;
  }
}

export function getFirstChar(string) {
  if (string && typeof string === "string") {
    return string.charAt(0).toLowerCase();
  } else {
    return null;
  }
}

export function getProfileColor(char) {
  const defaultColor = "#9e9e9e"; //grey
  const colorMap = {
    a: "#F44336",
    b: "#E91E63",
    c: "#BA68C8",
    d: "#673AB7",
    e: "#3F51B5",
    f: "#2196F3",
    g: "#03A9F4",
    h: "#00BCD4",
    i: "#009688",
    j: "#4CAF50",
    k: "#8BC34A",
    l: "#CDDC39",
    m: "#FDD835",
    n: "#FFC107",
    o: "#FF9800",
    p: "#FF5722",
    q: "#795548",
    r: "#9E9E9E",
    s: "#607D8B",
    t: "#A1887F",
    u: "#FF5722",
    v: "#FFD600",
    w: "#827717",
    x: "#33691E",
    y: "#43A047",
    z: "#009688",
  };

  return colorMap[char?.toLowerCase()] ?? defaultColor;
}

const ACTIVITY_ICON_MAP = {
  user: (activityType) => {
    if (activityType === "front_end_login") {
      return "sign-in";
    } else if (activityType === "front_end_logout") {
      return "sign-out";
    } else {
      return "user";
    }
  },
  role: (_activityType) => "address-card",
  company: (_activityType) => "company",
  facility: (_activityType) => "map-marker",
  site: (_activityType) => "map-marker",
  stream: (_activityType) => "link",
  sample: (_activityType) => "flask",
  test: (_activityType) => "flask",
  lab_report: (_activityType) => "cloud-upload",
  instrument: (_activityType) => "cube",
  chain_of_custody: (_activityType) => "handshake-o",
  workflow: (_activityType) => "calendar",
  insight: (_activityType) => "bolt",
  company_notification_preferences: (_activityType) => "bell",
  role_notification_preferences: (_activityType) => "bell",
  user_notification_preferences: (_activityType) => "bell",
  user_site_notification_preferences: (_activityType) => "bell",
};

export function getActivityIcon(resourceType, activityType) {
  const getter = ACTIVITY_ICON_MAP[resourceType];
  if (getter) {
    return getter(activityType);
  } else {
    return "question-circle-o";
  }
}

export function getActivityColour(actionType) {
  switch (actionType) {
    case "create":
      return CREATE_COLOUR;
    case "edit":
      return ALTER_COLOUR;
    case "delete":
      return DELETE_COLOUR;
    case "other":
      return OTHER_COLOUR;
    default:
      return OTHER_COLOUR;
  }
}

export function getIconStyle(styles, value) {
  if (styles[value]) {
    return styles[value];
  } else {
    return unknownStyle;
  }
}

export function getWorkflowTaskStateColor(state) {
  switch (state) {
    case "dismissed":
    case "missed":
      return {
        icon: "times",
        color: color.missed,
      };
    case "completed":
      return {
        icon: "check",
        color: color.completed,
      };
    case "sampled":
      return {
        icon: "check",
        color: color.sampled,
      };
    case "pending":
      return {
        icon: "clock-o",
        color: color.warning,
      };
    case "late":
      return {
        icon: "clock-o",
        color: color.error,
      };
    case "scheduled":
      return {
        icon: "clock-o",
        color: "#9575cd",
      };
    default:
      return {
        icon: "times",
        color: unknownStyle.color,
      };
  }
}

export function getColorPalette(numColorsRequired) {
  const numAdditionalColor = numColorsRequired - colorPalette.length;

  if (numAdditionalColor > 0) {
    for (let i = 0; i < numAdditionalColor; i++) {
      const randomColor = getRandomGeneratedColor();
      colorPalette.push(randomColor);
    }

    return colorPalette;
  } else {
    return colorPalette;
  }
}

export function getNumberColorBySign(value) {
  return value > 0 ? positiveColor : negativeColor;
}

function getRandomGeneratedColor() {
  const red = getRandomNumber();
  const green = getRandomNumber();
  const blue = getRandomNumber();

  return `rgb(${red}, ${green}, ${blue})`;
}

function getRandomNumber() {
  return Math.floor(Math.random() * 255 + 1);
}

function getPercent(value, lowerLimit, upperLimit) {
  const ratio = (value - lowerLimit) / (upperLimit - lowerLimit);

  return 100 * Math.max(Math.min(ratio, 1.0), 0.0);
}
