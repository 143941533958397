export * from "./DataSubmissionDetailPage";

export const DATA_SUBMISSION_DETAIL_TITLE = "Data Submission";

export const linkToDataSubmissionDetailPage = (
  transactionId = ":transactionId"
) => `/app/data-tools/submissions/${transactionId}/detail`;

export type DataSubmissionDetailPageParamType = {
  transactionId: string;
};

export const DATA_SUBMISSION_DETAIL_BREADCRUMB = {
  title: DATA_SUBMISSION_DETAIL_TITLE,
  path: linkToDataSubmissionDetailPage(),
};
