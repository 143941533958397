import find from "lodash/find";
import { INVENTORY_SOURCE_OPTIONS } from "./VolumeEntryConstant";
import orderBy from "lodash/orderBy";

const getTotalVolume = (value) => {
  if (value === 0) {
    return "0";
  }

  return value ?? "";
};

const getFormattedData = (volumeEntry, adjustmentFactors, defaultInputs) => {
  const { source, total_volume, adjustment_factor_ids, opening_volume } =
    volumeEntry;

  const selectedAdjustmentFactors = getSelectedAdjustmentFactors(
    adjustment_factor_ids,
    adjustmentFactors
  );

  const sourceOption = find(INVENTORY_SOURCE_OPTIONS, {
    id: source,
  });

  return {
    ...defaultInputs,
    source: sourceOption ?? defaultInputs.source,
    dates: [volumeEntry],
    unit: total_volume?.unit ?? defaultInputs.unit,
    totalVolume: getTotalVolume(total_volume?.value),
    openingVolume: getTotalVolume(opening_volume?.value),
    openingVolumeUnit: opening_volume?.unit ?? null,
    adjustmentFactors: selectedAdjustmentFactors,
  };
};

export const getUsableVolumeEntries = (action, volumeEntries) => {
  if (action === "add" || action === "edit") {
    return volumeEntries.filter((entry) => !entry.is_locked);
  }

  return volumeEntries;
};

export const getSelectedAdjustmentFactors = (
  adjustmentFactorIds,
  adjustmentFactors
) => {
  const selectedAdjustmentFactors = [];

  adjustmentFactorIds?.forEach((id) => {
    const adjustmentFactor = find(adjustmentFactors, { id });

    if (adjustmentFactor) {
      selectedAdjustmentFactors.push(adjustmentFactor);
    }
  });

  return selectedAdjustmentFactors;
};

export const getInitialInputs = (
  defaultInputs,
  volumeEntries,
  adjustmentFactors,
  action,
  streamId,
  streamAdjustmentConfigs
) => {
  if (action === "add" || volumeEntries.length !== 1) {
    const selectedStreamConfig = streamAdjustmentConfigs.find(
      (streamConfig) => streamConfig.stream_id === streamId
    );

    const adjustmentFactorIds =
      selectedStreamConfig?.adjustment_factor_ids ?? [];

    const selectedAdjustmentFactors = getSelectedAdjustmentFactors(
      adjustmentFactorIds,
      adjustmentFactors
    );

    let openingVolume;
    if (volumeEntries.length === 1) {
      openingVolume = getTotalVolume(volumeEntries[0]?.opening_volume?.value);
    }

    return {
      ...defaultInputs,
      dates: orderBy(volumeEntries, "date"),
      adjustmentFactors: selectedAdjustmentFactors,
      openingVolume,
    };
  }

  return getFormattedData(volumeEntries[0], adjustmentFactors, defaultInputs);
};
