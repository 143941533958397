import { useListCustomAttributes } from "#src/components/hooks/adapters/useCustomAttributes";
import { useParams } from "#src/Routers/hooks";
import type { CustomAttributeType, EquipmentType } from "@validereinc/domain";
import { AssetType, EquipmentDomain } from "@validereinc/domain";
import React, { createContext, useEffect, useState } from "react";

export const EquipmentContext = createContext<{
  equipment?: EquipmentType;
  isLoading: boolean;
  customAttributes?: CustomAttributeType[];
} | null>(null);

export const EquipmentProvider = ({
  children,
}: {
  children: React.ReactElement | React.ReactElement[];
}) => {
  const { equipmentId } = useParams<{ equipmentId: string }>();

  const [isEquipmentLoading, setIsEquipmentLoading] = useState(false);
  const [equipment, setEquipment] = useState<EquipmentType>();

  const fetchEquipment = async (equipmentId: string) => {
    try {
      setIsEquipmentLoading(true);

      const newEquipment = await EquipmentDomain.getEquipmentById({
        equipmentId,
      });

      setEquipment(newEquipment);
    } catch (caught) {
      console.error(caught);
    } finally {
      setIsEquipmentLoading(false);
    }
  };

  useEffect(() => {
    if (equipmentId) {
      fetchEquipment(equipmentId);
    }
  }, [equipmentId]);

  const customAttributeQuery = useListCustomAttributes({
    filters: { entity_type: AssetType.EQUIPMENT },
  });
  const customAttributes = customAttributeQuery?.data?.data ?? [];

  const isLoading = isEquipmentLoading || customAttributeQuery.isLoading;

  return (
    <EquipmentContext.Provider
      value={{
        equipment,
        isLoading,
        customAttributes,
      }}
    >
      {children}
    </EquipmentContext.Provider>
  );
};
