import React from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import * as PropTypes from "prop-types";
import "./MeasurementControlPanel.css";
import {
  MEASUREMENT_TYPES,
  MeasurementState,
} from "../Hooks/useMeasurementReducer";

const isValueSet = (value) => {
  return value !== undefined;
};

const areValuesSet = (tubeState) =>
  Object.values(tubeState.toJS()).every((tubeValues) =>
    isValueSet(tubeValues.meniscus.value)
  );

const areValuesAboveZero = (tubeState) =>
  Object.values(tubeState.toJS()).every(
    (tubeValues) =>
      isValueSet(tubeValues.meniscus.value) && tubeValues.meniscus.value >= 0
  );

const areValuesInCorrectOrder = (tubeState) => {
  const { WATER_SEDIMENT_INTERFACE, WATER_SEDIMENT, SEDIMENT } =
    MEASUREMENT_TYPES;

  const getValue = (type) => tubeState[type].meniscus.value;

  const shouldCheck = (typeA, typeB) => {
    const bothNotZero = getValue(typeA) !== 0 && getValue(typeB) !== 0;
    const bothNotError = getValue(typeA) !== null && getValue(typeB) !== null;
    const bothEntered =
      getValue(typeA) !== undefined && getValue(typeB) !== undefined;

    return bothNotZero && bothNotError && bothEntered;
  };

  const wsiTows = shouldCheck(WATER_SEDIMENT_INTERFACE, WATER_SEDIMENT)
    ? getValue(WATER_SEDIMENT_INTERFACE) >= getValue(WATER_SEDIMENT)
    : true;
  const wsTos = shouldCheck(WATER_SEDIMENT, SEDIMENT)
    ? getValue(WATER_SEDIMENT) >= getValue(SEDIMENT)
    : true;
  const wsiTos = shouldCheck(WATER_SEDIMENT_INTERFACE, SEDIMENT)
    ? getValue(WATER_SEDIMENT_INTERFACE) >= getValue(SEDIMENT)
    : true;

  return wsiTows && wsTos && wsiTos;
};

export const SubmitButton = ({ onClick, leftTubeState, rightTubeState }) => {
  const areAllValuesSet =
    areValuesSet(leftTubeState) && areValuesSet(rightTubeState);

  const areAllValuesInCorrectOrder =
    areValuesInCorrectOrder(leftTubeState) &&
    areValuesInCorrectOrder(rightTubeState);

  const areAllValuesAboveZero =
    areValuesAboveZero(leftTubeState) && areValuesAboveZero(rightTubeState);

  const isDisabled =
    !areAllValuesSet || !areAllValuesInCorrectOrder || !areAllValuesAboveZero;

  if (isDisabled) {
    const reasons = [];
    if (!areAllValuesSet) {
      reasons.push("Some measurements have not been annotated");
    }
    if (!areAllValuesInCorrectOrder) {
      reasons.push(
        "Some measurements are in the incorrect order (e.g. the water value is smaller than the sediment value)"
      );
    }
    if (!areAllValuesAboveZero) {
      reasons.push("Some measurement values are below zero");
    }

    const tooltip = (
      <Tooltip id="measurementControlPanel__submitButton__tooltip">
        <ul>
          {reasons.map((reason) => {
            return <li key={reason}>{reason}</li>;
          })}
        </ul>
      </Tooltip>
    );

    return (
      <OverlayTrigger
        placement="bottom"
        overlay={tooltip}
      >
        <div className="measurementControlPanel__submitButton__overlayFix">
          <Button
            className="measurementControlPanel__submitButton"
            disabled={isDisabled}
            bsStyle="success"
          >
            Submit
          </Button>
        </div>
      </OverlayTrigger>
    );
  } else {
    return (
      <Button
        className="measurementControlPanel__submitButton"
        bsStyle="success"
        onClick={onClick}
      >
        Submit
      </Button>
    );
  }
};

SubmitButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  leftTubeState: PropTypes.instanceOf(MeasurementState).isRequired,
  rightTubeState: PropTypes.instanceOf(MeasurementState).isRequired,
};

export default SubmitButton;
