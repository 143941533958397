import {
  ALERT_MESSAGE_CREATED,
  ALERT_MESSAGE_REMOVED,
  ALERT_MESSAGES_CLEARED,
} from "../constants/action-types";

export const addAlertMessage = (alertMessage) => (dispatch) => {
  dispatch({
    type: ALERT_MESSAGE_CREATED,
    alertMessage,
  });

  if (alertMessage.dismissesAt) {
    const millisecondsLeft = alertMessage.millisecondsLeft();

    setTimeout(() => {
      dispatch(removeAlertMessage(alertMessage));
    }, millisecondsLeft);
  }
};

export function removeAlertMessage(alertMessage) {
  return {
    type: ALERT_MESSAGE_REMOVED,
    alertMessage,
  };
}

export function clearAlertMessages() {
  return {
    type: ALERT_MESSAGES_CLEARED,
  };
}
