import { useDeviceDetail } from "#components/hooks/useDeviceDetail";
import { useMeasurementSeriesDetail } from "#components/hooks/useMeasurementSeriesDetail";
import { Column, Grid, Row } from "@validereinc/common-components";
import { Device, DeviceType } from "@validereinc/domain";
import isEmpty from "lodash/isEmpty";
import * as PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import AlertMapPanel from "./AlertMapPanel";
import AlertOverviewPanel from "./AlertOverviewPanel";
import DeviceOverviewPanel from "./DeviceOverviewPanel";
import MeasurementPanel from "./MeasurementPanel";

const AlertConditionContainer = ({ conditionIndex, alertDetail }) => {
  const [isLoadingDevicesAtFacility, setIsLoadingDevicesAtFacility] =
    useState(true);
  const [devicesAtFacility, setDevicesAtFacility] = useState<DeviceType[]>([]);

  const measurementSeriesId =
    alertDetail?.monitor?.conditions?.[conditionIndex]?.measurement_series_id;
  const [measurementSeriesDetail, isLoadingMeasurementSeries] =
    useMeasurementSeriesDetail(measurementSeriesId, [alertDetail]);

  const [deviceDetail, isLoadingDeviceDetail] = useDeviceDetail(
    measurementSeriesDetail?.device_id,
    [measurementSeriesDetail]
  );

  useEffect(() => {
    //MAP: DEVICES AT FACILITY
    (async () => {
      if (measurementSeriesDetail?.device?.facility?.id) {
        const response = await Device.getList({
          page: 1,
          pageSize: 100,
          filters: {
            "facility.id": measurementSeriesDetail?.device?.facility.id,
          },
        });
        setDevicesAtFacility(response.data);
        setIsLoadingDevicesAtFacility(false);
      }
    })();
  }, [measurementSeriesDetail?.device?.facility]);

  const measurementTypes =
    measurementSeriesDetail && !isEmpty(measurementSeriesDetail)
      ? [
          {
            id: measurementSeriesDetail.measurement_type,
            name: measurementSeriesDetail.measurement_type_name,
          },
        ]
      : [];

  return (
    <Row>
      <Column variant={6}>
        <AlertOverviewPanel
          alertDetail={alertDetail}
          measurementSeriesDetail={measurementSeriesDetail}
          isLoading={isLoadingMeasurementSeries}
          conditionIndex={conditionIndex}
        />
      </Column>
      <Column variant={18}>
        <Grid>
          <Row>
            <Column>
              <DeviceOverviewPanel
                deviceDetail={deviceDetail}
                isLoading={isLoadingDeviceDetail}
              />
            </Column>
          </Row>
          <Row>
            <Column>
              <MeasurementPanel
                alertDetail={alertDetail}
                deviceId={deviceDetail?.id}
                measurementSeriesDetail={measurementSeriesDetail}
                isLoading={isLoadingMeasurementSeries || isLoadingDeviceDetail}
                measurementTypes={measurementTypes}
              />
            </Column>
          </Row>
          <Row>
            <Column>
              <AlertMapPanel
                deviceDetail={deviceDetail}
                devicesAtFacility={devicesAtFacility}
                isLoading={isLoadingDeviceDetail || isLoadingDevicesAtFacility}
              />
            </Column>
          </Row>
        </Grid>
      </Column>
    </Row>
  );
};

AlertConditionContainer.propTypes = {
  conditionIndex: PropTypes.number.isRequired,
  alertDetail: PropTypes.object.isRequired,
};

export default AlertConditionContainer;
