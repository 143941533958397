import { getFirstValidValue } from "#utils/objectFormatter";

/** Given an SVM 3000 or SVM3001 test detail, returns the tester's name or undefined */
export const getTesterName = (testDetail) => {
  return getFirstValidValue([
    testDetail?.metadata?.user?.value,
    testDetail?.metadata?.instrument_user?.value,
    testDetail?.metadata.user_name?.value,
    testDetail?.tested_by,
  ]);
};
