import { useState, useEffect } from "react";
import EmissionsService from "#services/EmissionService";

export const useFetchEmissionReports = () => {
  const [emissionReports, setEmissionReports] = useState([]);
  const [loadingState, setLoadingState] = useState("loading");

  useEffect(() => {
    EmissionsService.getEmissionReports()
      .then(({ data }) => {
        setEmissionReports(data);
        setLoadingState("loaded");
      })
      .catch(() => setLoadingState("error"));
  }, []);

  return [emissionReports, loadingState];
};

export function useEmissionReportSettings(id) {
  const [emissionReportSettings, setEmissionReportDetail] = useState({});
  const [fetchState, setFetchState] = useState("loading");

  useEffect(() => {
    if (id) {
      EmissionsService.getEmissionReportSettings(id)
        .then(({ data }) => {
          setEmissionReportDetail(data);
          setFetchState("loaded");
        })
        .catch(() => {
          setFetchState("error");
        });
    }
  }, []);

  return [emissionReportSettings, fetchState];
}
