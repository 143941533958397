import { KeyValuePanel } from "@validereinc/common-components";
import React from "react";

const EstimationMethodDetailsPanel = ({
  data,
  isLoading,
}: EstimationMethodDetailsPanelProps) => {
  return (
    <KeyValuePanel
      panelMaxColumnCount={2}
      panelKeyValueListProps={{ maxRowCount: 6, variant: "shaded" }}
      panelProps={{
        isFluidY: false,
        loaded: !isLoading,
        style: { marginBottom: 0, alignSelf: "flex-start" },
      }}
      data={data}
    />
  );
};

// TODO: reference type from KeyValuePanel component
export type EstimationMethodDetailsPanelProps = {
  data?: Array<{
    title: string | React.ReactNode;
    value: string | React.ReactNode;
    description?: string;
  }>;
  isLoading?: boolean;
};

export default EstimationMethodDetailsPanel;
