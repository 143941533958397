/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  FormSchemaQuestionType,
  FormSchemaSectionType,
} from "@validereinc/domain";
import get from "lodash/get";
import { useEffect, useMemo } from "react";
import { useFieldArray, UseFormReturn } from "react-hook-form";
import {
  QuestionRenderFunctionType,
  SectionRenderFunctionType,
} from "./FormSubmissionFormController";
import { areConditionsSatisfied } from "./util";

const questionFieldNameGenerator = (
  sectionId: string,
  questionId: string,
  fieldIndex: number
) => `answers.${sectionId}.${fieldIndex}.${questionId}.value`;

export const FormSubmissionsSectionRepeater = ({
  id: sectionId,
  name,
  is_repeatable,
  questions,
  formQuestions,
  sectionRenderFunction,
  description,
  questionRenderFunction,
  form,
}: {
  formQuestions: FormSchemaQuestionType;
  sectionRenderFunction: SectionRenderFunctionType;
  questionRenderFunction: QuestionRenderFunctionType;
  form: UseFormReturn<any>;
} & FormSchemaSectionType) => {
  const values = form.watch();

  const questionsArrayWithId = useMemo(
    () =>
      questions?.map((questionId) => ({
        id: questionId,
        ...formQuestions[questionId],
      })),
    [questions, formQuestions]
  );

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: `answers.${sectionId}`,
  });

  /**
   * This variables is an array in the form of:
   * [
   *   {questionId1: true, questionId2: false, ...},
   *   {questionId1: true, questionId2: false, ...},
   *   {questionId1: true, questionId2: false, ...},
   * ]
   *
   * where each entry of this array represents one repeated section.
   */
  const questionVisibilityMapById = useMemo(() => {
    return fields.map((_, i) =>
      questionsArrayWithId.reduce(
        (prevValue: Record<string, boolean>, question) => {
          prevValue[question.id] = areConditionsSatisfied({
            conditions: question?.conditions,
            values,
            fieldName: questionFieldNameGenerator(sectionId, question.id, i),
          });
          return prevValue;
        },
        {}
      )
    );
  }, [values, questionsArrayWithId, fields, sectionId]);

  const sectionVisibilityArray = useMemo(
    () =>
      questionVisibilityMapById.map((visibilityObject) =>
        Object.values(visibilityObject).some((e) => e)
      ),
    [questionVisibilityMapById]
  );

  const defaultValues = Object.fromEntries(
    questions.map((questionId) => [questionId, { value: "" }])
  );

  // Because useFieldArray is uncontrolled, first repeatable section must be created manually:

  const shouldRenderFirstRepeatableSection = useMemo(
    () =>
      questionsArrayWithId.some(({ id, conditions }) =>
        areConditionsSatisfied({
          conditions,
          fieldName: questionFieldNameGenerator(sectionId, id, 0),
          values,
        })
      ),
    [questionsArrayWithId, sectionId, values]
  );

  useEffect(() => {
    if (values && questions?.length) {
      if (!shouldRenderFirstRepeatableSection) {
        if (fields.length) {
          fields.forEach((_field, index) => {
            remove(index);
          });
        }
      } else {
        if (!fields.length) {
          append(defaultValues);
        }
      }
    }
  }, [
    shouldRenderFirstRepeatableSection,
    fields,
    defaultValues,
    questions?.length,
    values,
    append,
    remove,
  ]);

  return (
    <>
      {fields
        .filter((_, index) => sectionVisibilityArray[index])
        .map(({ id: repeatedSectionUUID }, index) =>
          sectionRenderFunction({
            dataKey: repeatedSectionUUID,
            index,
            title: `${name}${fields?.length > 1 ? ` ${index + 1}` : ""}`,
            addButtonLabel: `Add ${name}`,
            canAddSection: is_repeatable && fields.length - 1 === index,
            onAddSection: () => {
              append(defaultValues);
            },
            canRemoveSection: fields?.length > 1,
            description,
            onRemoveSection: () => {
              remove(index);
            },
            hasErrors: !!get(
              form.formState.errors,
              `answers.${sectionId}.${index}`,
              false
            ),
            children: questionsArrayWithId
              .filter(
                (question) => questionVisibilityMapById[index][question.id]
              )
              ?.map(({ id: questionId, ...question }) => {
                return questionRenderFunction({
                  dataKey: questionId,
                  name: questionFieldNameGenerator(
                    sectionId,
                    questionId,
                    index
                  ),
                  ...question,
                });
              }),
          })
        )}
    </>
  );
};
