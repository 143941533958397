import { Button } from "@validereinc/common-components";
import * as PropTypes from "prop-types";
import React from "react";
import "./ResetButton.css";

const ResetButton = (props) => (
  <div
    className="analyzeGraph__resetButtonContainer"
    hidden={!props.show}
  >
    <Button
      className="analyzeGraph__resetButton"
      onClick={props.onClick}
    >
      Reset
    </Button>
  </div>
);

ResetButton.propTypes = {
  show: PropTypes.bool,
  onClick: PropTypes.func,
};

export default ResetButton;
