import {
  apiRequestServicesPromise,
  apiPostServicesPromise,
  apiPutServicesPromise,
  apiHTMLRequestServicesPromise,
} from "./ServiceHelper";
import moment from "moment";

const ChainOfCustodyService = {
  getChainOfCustodyList(startDate, endDate) {
    const parameter = {
      issued_before: startDate.toISOString(),
      issed_after: endDate.toISOString(),
    };

    return apiRequestServicesPromise("/api/chains_of_custody", parameter);
  },

  getChainOfCustodyDetail(COCId) {
    return apiRequestServicesPromise(`/api/chains_of_custody/${COCId}`);
  },

  getSamples(startDate, endDate) {
    const parameter = {
      start_date: startDate.toISOString(),
      end_date: endDate.toISOString(),
      chain_of_custody_id: "null",
      sample_type: ["unknown", "spot", "composite"],
    };

    return apiRequestServicesPromise("/api/samples", parameter);
  },

  createChainOfCustodyForm(type, site_id, sample_ids, name, newSamples) {
    newSamples = newSamples.map((sample) => {
      return {
        stream_id: sample.stream_id,
        sample_type: sample?.sample_type?.toLowerCase() ?? null,
        started_at: moment(sample.started_at).toISOString(),
        ended_at: moment(sample.ended_at).toISOString(),
        site_id: site_id,
        workflow_instance_id: sample?.workflow_instance_id ?? null,
        sample_point_id: sample.sample_point_id,
        riser_id: null,
        tank_id: null,
      };
    });

    const parameter = {
      name: name,
      type: type,
      site_id: site_id,
      sample_ids: sample_ids,
      samples: newSamples,
    };

    return apiPostServicesPromise("/api/chains_of_custody", parameter);
  },

  updateChainOfCustodyForm(COCId, name) {
    const parameter = {
      name: name,
    };

    return apiPutServicesPromise(`/api/chains_of_custody/${COCId}`, parameter);
  },

  getChainOfCustodyForm(COCId) {
    return apiHTMLRequestServicesPromise(`/chains_of_custody/${COCId}/form`);
  },
};

export default ChainOfCustodyService;
