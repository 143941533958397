import {
  apiRequestServicesPromise,
  apiPostServicesPromise,
  apiPutServicesPromise,
} from "./ServiceHelper";

const getFormattedInput = (inputs, sourceOptions) => {
  const { showTotalVolume, showEnergy, ...formattedInputs } = inputs;

  if (sourceOptions) {
    Object.keys(sourceOptions).map((source) => {
      if (inputs[source]?.length) {
        formattedInputs[source] = inputs[source].map((sourceInput) => ({
          id: sourceInput.id,
          multiplier: parseFloat(sourceInput.multiplier),
        }));
      }
    });
  }

  formattedInputs.show_total_volume = showTotalVolume === "yes";

  formattedInputs.show_energy =
    formattedInputs.type === "gas" && showEnergy === "yes";

  if (inputs.volumeUnit) {
    formattedInputs.volume_unit = inputs.volumeUnit;
  }

  return formattedInputs;
};

const BalancesServices = {
  getBalances() {
    return apiRequestServicesPromise(`/api/balances`);
  },

  getBalancesSummary(balanceId, dateRange) {
    return apiRequestServicesPromise(
      `/api/balances/${balanceId}/analyze`,
      dateRange
    );
  },

  getSourceDetail(balanceId, sourceType, dateRange) {
    return apiRequestServicesPromise(
      `/api/balances/${balanceId}/streams/${sourceType}`,
      dateRange
    );
  },

  getBalancesDetail(balancesId) {
    return apiRequestServicesPromise(`api/balances/${balancesId}`);
  },

  createBalances(inputs, sourceOptions) {
    const params = getFormattedInput(inputs, sourceOptions);

    return apiPostServicesPromise(`api/balances`, params);
  },

  updateBalances(inputs) {
    const params = getFormattedInput(inputs);

    return apiPutServicesPromise(`api/balances/${params.id}`, params);
  },
};

export default BalancesServices;
