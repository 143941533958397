import { componentQualityComparator } from "#utils/componentQualitySortOrder";
import { GetAllObjectValue } from "#utils/objectFormatter";
import {
  Button,
  DateTimeInput,
  SelectInput,
  TextInput,
  Title,
} from "@validereinc/common-components";
import isEqual from "lodash/isEqual";
import uniqWith from "lodash/uniqWith";
import * as PropTypes from "prop-types";
import React, { useMemo } from "react";
import "./InlineManualEntry.scss";

const InlineManualEntryForm = ({
  form,
  formState,
  streams,
  instruments,
  onChangeNumMeasurements,
  getMeasurementType,
}) => {
  const validateUniqDateTypeInstrument = (index) => {
    const uniqDateTypeInstrument = form
      .getValues("measurements")
      .map((measurement) => {
        return {
          date: measurement.date.toString(),
          measurementType: measurement.measurementType,
          instrument: measurement.instrument.id,
        };
      });

    if (
      uniqWith(uniqDateTypeInstrument, isEqual).length !==
      uniqDateTypeInstrument.length
    ) {
      return `Measurement #${
        index + 1
      } must not have the same Date, Measurement Type, and Instrument as another Measurement.`;
    }
  };

  const inlineInstruments = useMemo(() => {
    return instruments.filter(
      (instrument) => instrument.instrument_type === "inline"
    );
  }, [instruments]);

  const sharedProps = {
    isDisabled: formState !== "enabled",
    isRequired: true,
    showIcon: true,
  };

  const measurements = form.watch("measurements");

  return (
    <div className="inlineManualEntry">
      <Title
        type="subheader"
        className="form__header"
      >
        Information
      </Title>

      <SelectInput
        name="stream"
        label="Stream"
        labelKey="name"
        options={streams}
        ignoreCase={true}
        {...sharedProps}
      />

      {measurements?.map((measurement, index) => {
        const { measurementType, uuid } = measurement;

        const measurementTypeOptions = GetAllObjectValue(
          inlineInstruments,
          "valid_measurement_types"
        ).sort(componentQualityComparator);

        const inlineInstrumentOptions = inlineInstruments.filter(
          (instrument) =>
            !measurementType ||
            instrument.valid_measurement_types.includes(measurementType)
        );

        return (
          <div
            className="inlineManualEntry__measurementSection"
            key={uuid}
          >
            <Title
              type="subheader"
              className="form__header"
            >
              {`Measurement #${index + 1}`}
            </Title>

            <DateTimeInput
              name={`measurements[${index}].date`}
              label="Date"
              {...sharedProps}
            />

            <SelectInput
              name={`measurements[${index}].measurementType`}
              label="Measurement Type"
              options={measurementTypeOptions}
              onChange={(value) => {
                form.setValue(
                  `measurements[${index}].unit`,
                  getMeasurementType(value).unit
                );
                form.setValue(`measurements[${index}].instrument`, null);
              }}
              isClearable={true}
              ignoreCase={true}
              {...sharedProps}
            />

            <SelectInput
              name={`measurements[${index}].instrument`}
              label="Inline Instrument"
              labelKey="name"
              options={inlineInstrumentOptions}
              validate={{
                isUniqDateTypeInstrument: () =>
                  validateUniqDateTypeInstrument(index),
              }}
              isClearable={true}
              ignoreCase={true}
              {...sharedProps}
            />

            <TextInput
              name={`measurements[${index}].value`}
              label={measurements[index].measurementType ?? "Value"}
              type="number"
              unit={form.watch(`measurements[${index}].unit`)}
              placeholder="Value"
              {...sharedProps}
            />

            {measurements.length > 1 && (
              <Button
                className="removeMeasurementsButton"
                variant="error-outline"
                onClick={() => onChangeNumMeasurements(uuid)}
                disabled={formState !== "enabled"}
              >
                Remove Measurement
              </Button>
            )}

            <div className="inlineManualEntry__divider" />
          </div>
        );
      })}

      <div className="inlineManualEntry__addButtonRow">
        <Button
          className="addMeasurementButton"
          onClick={() => onChangeNumMeasurements()}
          disabled={formState !== "enabled"}
          icon="plus"
        >
          Add Another Measurement
        </Button>
      </div>
    </div>
  );
};

InlineManualEntryForm.propTypes = {
  streams: PropTypes.array.isRequired,
  instruments: PropTypes.array.isRequired,
  formState: PropTypes.string.isRequired,
  form: PropTypes.object.isRequired,
  getMeasurementType: PropTypes.func.isRequired,
  onChangeNumMeasurements: PropTypes.func.isRequired,
};

export default InlineManualEntryForm;
