import { useNavigate, useParams } from "#src/Routers/hooks";
import { UserGroupFormPanel } from "#src/batteries-included-components/Panels/FormPanels/UserGroupFormPanel/UserGroupFormPanel";
import { USER_GROUP_QUERY_KEY } from "#src/components/hooks/adapters/useUserGroups";
import {
  MultiStepFormProvider,
  useMultiStepFormContext,
} from "#src/hooks/useMultiStepForm";
import { useBreadcrumbsFromRoute } from "#src/utils/route";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Page, useAlert } from "@validereinc/common-components";
import { UpdateUserGroupType, UserGroupsAdapter } from "@validereinc/domain";
import pick from "lodash/pick";
import React from "react";
import { UserGroupEditRoutePath } from ".";
import { DEFAULT_QUERY_OPTIONS } from "../../../../../../components/hooks/adapters/adapterUtils";
import { UserGroupDetailsRoutePath } from "../details";

const EditUserGroupPageContent = () => {
  const { stepper, footer } = useMultiStepFormContext();
  const { groupId } = useParams<{ groupId: string }>();
  const fetchedUserGroup = useQuery({
    queryKey: [...USER_GROUP_QUERY_KEY, groupId],
    queryFn: () => UserGroupsAdapter.getOne({ id: groupId }),
    ...DEFAULT_QUERY_OPTIONS,
    enabled: !!groupId,
  });
  const [breadcrumbs] = useBreadcrumbsFromRoute(UserGroupEditRoutePath);

  return (
    <Page
      breadcrumbs={breadcrumbs}
      title={fetchedUserGroup.data?.data.name}
      category="Edit User Group"
      actionRow={stepper}
      footer={footer}
      isLoading={!!groupId && fetchedUserGroup.isLoading}
    >
      <UserGroupFormPanel />
    </Page>
  );
};

const EditUserGroupPage = () => {
  const { groupId } = useParams<{ groupId: string }>();
  const navigate = useNavigate();
  const { addAlert } = useAlert();

  const groupMembersQuery = useQuery({
    queryFn: () =>
      UserGroupsAdapter.members.getList({ meta: { userGroupId: groupId } }),
    enabled: !!groupId,
  });

  const navigateToGroupDetailsPage = () =>
    navigate(
      UserGroupDetailsRoutePath.toLinkParts({
        pathParams: {
          groupId,
        },
      })
    );
  const updateMutation = useMutation({
    mutationFn: async ({
      userIdDifferences,
      data,
    }: {
      userIdDifferences: { added: string[]; removed: string[] };
      data: UpdateUserGroupType;
    }) => {
      const requests: Array<Promise<void>> = [];

      requests.push(
        ...userIdDifferences.added.map((newUserIdToAdd) =>
          UserGroupsAdapter.members.add({
            id: newUserIdToAdd,
            meta: { userGroupId: groupId },
            data: null,
          })
        ),
        ...userIdDifferences.removed.map((newUserIdToAdd) =>
          UserGroupsAdapter.members.remove({
            id: newUserIdToAdd,
            meta: { userGroupId: groupId },
          })
        )
      );
      return UserGroupsAdapter.updateOne({
        id: groupId,
        data,
      }).then(() => {
        Promise.all(requests);
      });
    },
    onSuccess: () => {
      addAlert?.({
        variant: "success",
        message: `Successfully updated user group.`,
      });
      navigateToGroupDetailsPage();
    },
    onError: () => {
      addAlert?.({
        variant: "error",
        message: `Failed to update user group.`,
      });
    },
  });
  const onSubmit = async (
    values: Array<Partial<UpdateUserGroupType & { user_ids: string[] }>>
  ) => {
    const allValues = values.reduce(
      (total, current) => ({ ...total, ...current }),
      {}
    );

    if (groupMembersQuery.isLoading) return;

    const oldUserIds =
      groupMembersQuery?.data?.data.map(({ user_id }) => user_id) ?? [];

    const {
      // name,
      // description,
      user_ids: newUserIds = [],
      ...data
    } = pick(allValues, ["name", "description", "user_ids"]);

    const userIdDifferences = {
      added: newUserIds.filter((x) => !oldUserIds.includes(x)),
      removed: oldUserIds.filter((x) => !newUserIds.includes(x)),
    };

    updateMutation.mutate({
      userIdDifferences,
      data,
    });
  };

  return (
    <MultiStepFormProvider
      steps={[
        {
          label: "Details",
        },
        {
          label: "User Selection (optional)",
        },
      ]}
      onCancel={navigateToGroupDetailsPage}
      onSubmit={onSubmit}
      onSubmitMutationState={{
        isSubmitting: updateMutation.isLoading,
      }}
      submissionActionText="Save"
    >
      <EditUserGroupPageContent />
    </MultiStepFormProvider>
  );
};

export default EditUserGroupPage;
