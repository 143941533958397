import { localStorageToJSON } from "@validereinc/utilities";

export function getLocalStorageItem(key: string) {
  return localStorageToJSON(key) ?? null;
}

export function setLocalStorageItem(key, value) {
  if (typeof window !== "undefined") {
    window.localStorage.setItem(key, JSON.stringify(value));
  }
}
