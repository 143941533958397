import { RecordValueSourceConfigurationPage } from "#batteries-included-components/Layouts/RecordConfiguration";
import {
  FACILITIES_BREADCRUMB,
  FACILITY_DETAIL_BREADCRUMB,
} from "#routers/breadcrumbs";
import { useBreadcrumbs } from "#routers/breadcrumbsHelper";
import { useNavigate, useParams } from "#routers/hooks";
import { ORGANIZATION_BREADCRUMB } from "#routes/organization";
import {
  FACILITY_RECORD_DETAIL_BREADCRUMB,
  FACILITY_RECORD_DETAIL_PAGE_TITLE,
} from "#routes/organization/facilities/[facilityId]/record/[recordId]/details";
import {
  FACILITY_RECORD_VALUE_DETAIL_BREADCRUMB,
  FACILITY_RECORD_VALUE_DETAIL_PAGE_TITLE,
  FacilityRecordValuePageParamsType,
  linkToFacilityRecordValueDetail,
} from "#routes/organization/facilities/[facilityId]/record/[recordId]/value/[measurementType]";
import {
  FACILITY_RECORD_VALUE_SOURCE_BREADCRUMB,
  FACILITY_RECORD_VALUE_SOURCE_PAGE_TITLE,
} from "#routes/organization/facilities/[facilityId]/record/[recordId]/value/[measurementType]/add-source/index";
import {
  FacilityContext,
  FacilityProvider,
} from "#src/contexts/FacilityContext";
import { RecordContext, RecordProvider } from "#src/contexts/RecordContext";
import { useQuery } from "@tanstack/react-query";
import { MeasurementsDomain } from "@validereinc/domain";
import { yearMonthName } from "@validereinc/utilities";
import React, { useContext } from "react";

export const FacilityRecordValueSourcePageContent = () => {
  const navigate = useNavigate();
  const params = useParams<FacilityRecordValuePageParamsType>();

  const { facility } = useContext(FacilityContext) || {};
  const { record } = useContext(RecordContext) || {};

  const { data: measurementTypes } = useQuery({
    queryKey: ["measurementTypes"],
    queryFn: MeasurementsDomain.listMeasurementTypes,
  });

  const measurementType = measurementTypes?.find(
    ({ id }) => id === params.measurementType
  );

  const recordPageTitle = record
    ? `${yearMonthName(record.year_month)} Record`
    : FACILITY_RECORD_DETAIL_PAGE_TITLE;

  const recordValuePageTitle = record
    ? `${yearMonthName(record.year_month)} ${measurementType?.name}`
    : FACILITY_RECORD_VALUE_DETAIL_PAGE_TITLE;

  const pageTitle = measurementType
    ? `Configure Source`
    : FACILITY_RECORD_VALUE_SOURCE_PAGE_TITLE;

  const breadcrumbs = useBreadcrumbs(
    [
      ORGANIZATION_BREADCRUMB,
      FACILITIES_BREADCRUMB,
      FACILITY_DETAIL_BREADCRUMB,
      FACILITY_RECORD_DETAIL_BREADCRUMB,
      FACILITY_RECORD_VALUE_DETAIL_BREADCRUMB,
      FACILITY_RECORD_VALUE_SOURCE_BREADCRUMB,
    ],
    {
      2: facility?.name,
      3: recordPageTitle,
      4: recordValuePageTitle,
      5: pageTitle,
    },
    {
      ...params,
      id: params.facilityId,
    }
  );

  return (
    <RecordValueSourceConfigurationPage
      pageProps={{
        title: recordValuePageTitle,
        category: "Configure Source",
        breadcrumbs,
      }}
      onClose={() => {
        navigate({
          pathname: linkToFacilityRecordValueDetail(
            params.facilityId,
            params.recordId,
            params.measurementType
          ),
        });
      }}
    />
  );
};

export const FacilityRecordValueSourcePage = () => (
  <FacilityProvider>
    <RecordProvider>
      <FacilityRecordValueSourcePageContent />
    </RecordProvider>
  </FacilityProvider>
);
