import { userSignedOut } from "#redux/actions";
import {
  handleRedirectCallback,
  popEntryLocation,
  signOut,
} from "#redux/actions/authentication";
// import UserService from "#services/UserService";
import { AuthenticationLayouts } from "#src/batteries-included-components/Layouts/Authentication";
import { isFeatureEnabled } from "#src/components/Redux/reducers/featureFlags";
import { ExceptionUtils } from "#src/utils/exception";
import { Button } from "@validereinc/common-components";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Redirect, RouteComponentProps } from "react-router-dom";

const mapDispatchToProps = {
  handleRedirectCallback,
  userSignedOut,
  popEntryLocation,
  signOut,
};

const LoginCallback = ({
  handleRedirectCallback,
  popEntryLocation,
  userSignedOut,
  signOut,
  location,
  history,
}: LoginCallbackProps) => {
  const [error, setError] = useState<unknown>();
  const [redirectTo, setRedirectTo] = useState();

  const stripHash = () => {
    // Replacing history with the current route (/app/callback) strips the hash from the
    // URL, without redirecting or reloading the page. If the user refreshes now,
    // they'll be redirected to the login page automatically.
    history.replace(location.pathname);
  };

  useEffect(() => {
    handleRedirectCallback()
      // .then((accessToken: string) => {
      .then(() => {
        const entryLocation = popEntryLocation();

        ExceptionUtils.registerLifecycleEvent({
          category: "app",
          type: "info",
          data: {
            to: entryLocation ?? {
              pathname: "/",
            },
          },
          message: "Intended entry location to redirect to registered",
        });
        setRedirectTo(entryLocation ? entryLocation : "/");
      })
      .catch((err: unknown) => {
        ExceptionUtils.reportException(err, "fatal", {
          hint: "Failed to handle login redirect callback and/or registering entry location",
        });
        setError(err);
        userSignedOut();
        stripHash();
      });
  }, []);

  if (!error) {
    if (redirectTo) {
      return <Redirect to={redirectTo} />;
    } else {
      return <AuthenticationLayouts.VerifyingOnLoginLayout />;
    }
  }

  return (
    <AuthenticationLayouts.ErrorOnLoginCallbackLayout
      action={
        <Button
          variant="primary"
          onClick={() => signOut()}
        >
          Back to Login
        </Button>
      }
    />
  );
};

type LoginCallbackProps = {
  handleRedirectCallback: typeof handleRedirectCallback;
  popEntryLocation: typeof popEntryLocation;
  userSignedOut: typeof userSignedOut;
  signOut: typeof signOut;
} & RouteComponentProps;

export default connect(
  (state) => ({
    auth0Instance: state.authentication.auth0Instance,
    checkIsFeatureEnabled: isFeatureEnabled(state.featureFlags),
  }),
  mapDispatchToProps
)(LoginCallback);
