import React, { useState, useEffect } from "react";
import * as PropTypes from "prop-types";
import "./SystemBalance.scss";
import SystemBalanceTable from "./SystemBalanceTable";
import SystemBalanceChart from "./SystemBalanceChart";
import RecordOfQualityService from "#services/RecordOfQualityService";
import SystemBalanceSummary from "./SystemBalanceSummary";
import Section from "#common/Section/Section";
import { getEstimatedValue } from "../../RecordOfQualityHelper";

const MAXIMUM_DISPLAY_PROPERTIES_FOR_CHART = 10;

const SYSTEM_BALANCE_HEIGHT_OFFSET = 95;

const useFetchSystemBalance = (accountingPeriodId, updateAccountingPeriod) => {
  const [systemBalance, setSystemBalance] = useState({});
  const [state, setState] = useState("loading");

  useEffect(() => {
    setState("loading");
    if (accountingPeriodId) {
      RecordOfQualityService.getSystemBalance(accountingPeriodId)
        .then(({ data }) => {
          setSystemBalance(data);
          setState("loaded");
        })
        .catch(() => {
          setState("error");
        });
    } else {
      setState("error");
    }
  }, [accountingPeriodId, updateAccountingPeriod]);

  return [systemBalance, state];
};

const SystemBalanceSummaryHeader = (props) => (
  <div className="systemBalanceHeader">
    <div className="systemBalanceHeader__title">Estimated Balance Value</div>

    <div className="systemBalanceHeader__estimatedValue">
      {getEstimatedValue(props.total.estimated_value)}
    </div>
  </div>
);

const SystemBalance = (props) => {
  const [{ components, qualities, total }, state] = useFetchSystemBalance(
    props.accountingPeriod?.id ?? null,
    props.updateAccountingPeriod
  );

  // For the system balance chart we will need to limit to maximum properties displayed.
  const displayPropertiesForChart = [...props.displayProperties].slice(
    0,
    MAXIMUM_DISPLAY_PROPERTIES_FOR_CHART
  );

  const height = props.height - SYSTEM_BALANCE_HEIGHT_OFFSET;

  return (
    <div
      className="systemBalance"
      style={{ height: `${height}px` }}
    >
      {state === "loaded" ? (
        <div>
          <Section header={<SystemBalanceSummaryHeader total={total} />}>
            <SystemBalanceSummary
              total={total}
              balanceType={props.accountingPeriod.balance_type}
            />
          </Section>

          <Section header="Net Facility Balance">
            <SystemBalanceChart
              totalDifference={total}
              components={components}
              qualities={qualities}
              componentVolumeConfig={
                props.accountingPeriod.component_volume_config
              }
              displayProperties={displayPropertiesForChart}
            />

            <SystemBalanceTable
              height={height}
              components={components}
              qualities={qualities}
              total={total}
              componentVolumeConfig={
                props.accountingPeriod.component_volume_config
              }
              displayProperties={props.displayProperties}
              balanceType={props.accountingPeriod.balance_type}
            />
          </Section>
        </div>
      ) : (
        <div>Loading...</div>
      )}

      {state === "error" && <div>System Balance is unavailable.</div>}
    </div>
  );
};

SystemBalanceSummaryHeader.propTypes = {
  total: PropTypes.object.isRequired,
};

SystemBalance.propTypes = {
  accountingPeriod: PropTypes.object.isRequired,
  displayProperties: PropTypes.array.isRequired,
  updateAccountingPeriod: PropTypes.bool.isRequired,
  total: PropTypes.object,
  height: PropTypes.number.isRequired,
};

export default SystemBalance;
