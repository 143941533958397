import {
  AccountNavBarSettings,
  LegacyAccountNavBarSettings,
} from "#src/batteries-included-components/Layouts/Authentication/AccountNavBar";
import {
  AuthenticatedContextActiveAuthVersionType,
  AuthenticatedContextAuthVersionType,
} from "#src/contexts/AuthenticatedContext";
import {
  AuthQueryType,
  useAuthenticatedContext,
  useGetHolisticAuthorizationEvaluators,
} from "#src/contexts/AuthenticatedContext.helpers";
import { SidebarTabType } from "@validereinc/common-components";
import { useMemo } from "react";
import PageConfigs from "../../Routers/PageConfigs";
import {
  LegacyOpsHubFeatures,
  useSidebarTabsWithMapping,
} from "./SideBar/SidebarTabs";

// TODO: note this was made during a temporary refactor as this is eventually intended to be fully thrown away in the routing engine refactor.
/**
 * Get all the localized sidebar tabs that are available because they passed
 * feature flags and permissions checks. Use this list to generate the side nav
 * and top nav (unfortunate tech debt at time of writing), navigational
 * elements, for an authenticated session.
 * @returns a memoized list of all the available sidebar tabs
 */
export const useGetSidebarTabsAvailable = ({
  onClickBindings,
}: {
  onClickBindings: Record<
    SidebarTabType["id"],
    Pick<SidebarTabType, "id" | "link">
  >;
}): [SidebarTabType[], boolean] => {
  const {
    activeAuthVersion,
    v1: {
      user: legacyUser,
      featureFlags: legacyFeatureFlags,
      permissions: legacyPermissions,
    },
    v2: {
      featureFlags: featureFlagsGlobal,
      userInfo: { permissions },
      companyInfo: { featureFlags, checkIsFeatureEnabled },
    },
  } = useAuthenticatedContext();
  const { isFeatureEnabledWaterfall, hasPermissionsWaterfall } =
    useGetHolisticAuthorizationEvaluators();
  const [sidebarTabs, isSidebarTabsLoading] =
    useSidebarTabsWithMapping(onClickBindings);

  const getAssociatedPageConfig = (tab: SidebarTabType) => PageConfigs[tab.id];

  const availableSidebarTabs = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return sidebarTabs.reduce<SidebarTabType[]>((availableTabs, currentTab) => {
      const newCurrentTab: SidebarTabType = { ...currentTab };
      const hasNestedTabs = Boolean(newCurrentTab.nested?.length);

      // if the tab config has nested tabs...
      if (hasNestedTabs) {
        // run the evaluators for each nested tab and filter out tabs that are denied...
        newCurrentTab.nested = newCurrentTab.nested?.filter((nestedTab) => {
          const shouldBeInNewNav = isNewAccountNavigationEnabled(nestedTab.id, {
            activeAuthVersion,
            checkIsFeatureEnabled,
          });

          if (shouldBeInNewNav) {
            return false;
          }

          const associatedPageConfig = getAssociatedPageConfig(nestedTab);

          if (!associatedPageConfig) {
            return false;
          }

          return (
            isFeatureEnabledWaterfall({
              featureFlagQuery: associatedPageConfig.get("featureFlagsQuery"),
              legacyFeatureFlagQuery: associatedPageConfig.get(
                "legacyFeatureFlagsQuery"
              ),
              legacyNotFeatureFlagQuery: associatedPageConfig.get(
                "legacyInverseFeatureFlagQuery"
              ),
              featureId: nestedTab.id,
            }) &&
            hasPermissionsWaterfall({
              permissionsQuery: associatedPageConfig.get("permissionsQuery"),
              legacyPermissionsQuery: associatedPageConfig.get(
                "legacyPermissionsQuery"
              ),
              shouldCheckPermissions: associatedPageConfig.get(
                "shouldCheckPermissions"
              ),
            })
          );
        });

        // if all the nested tabs get denied, then we don't show this root tab either
        if (!newCurrentTab.nested?.length) {
          return availableTabs;
        } else {
          // otherwise we allow access since some combination of nested tabs are allowed
          availableTabs.push(newCurrentTab);
          return availableTabs;
        }
      }

      const associatedPageConfigForRootTab =
        getAssociatedPageConfig(newCurrentTab);

      // if the associated page config doesn't exist, we skip the root tab
      if (!associatedPageConfigForRootTab) {
        return availableTabs;
      }

      const shouldBeInNewNav = isNewAccountNavigationEnabled(newCurrentTab.id, {
        activeAuthVersion,
        checkIsFeatureEnabled,
      });

      if (shouldBeInNewNav) {
        return availableTabs;
      }

      // run evaluators against the root tab config to determine access
      const isRootTabAvailable =
        isFeatureEnabledWaterfall({
          featureFlagQuery:
            associatedPageConfigForRootTab.get("featureFlagsQuery"),
          legacyFeatureFlagQuery: associatedPageConfigForRootTab.get(
            "legacyFeatureFlagsQuery"
          ),
          legacyNotFeatureFlagQuery: associatedPageConfigForRootTab.get(
            "legacyInverseFeatureFlagQuery"
          ),
          featureId: newCurrentTab.id,
        }) &&
        hasPermissionsWaterfall({
          permissionsQuery:
            associatedPageConfigForRootTab.get("permissionsQuery"),
          legacyPermissionsQuery: associatedPageConfigForRootTab.get(
            "legacyPermissionsQuery"
          ),
          shouldCheckPermissions: associatedPageConfigForRootTab.get(
            "shouldCheckPermissions"
          ),
        });

      // if the root tab is denied, hide the root tab
      if (!isRootTabAvailable) {
        return availableTabs;
      }

      // otherwise, show the root tab
      availableTabs.push(newCurrentTab);

      return availableTabs;
    }, []);
  }, [
    sidebarTabs,
    isFeatureEnabledWaterfall,
    hasPermissionsWaterfall,
    legacyUser,
    legacyFeatureFlags,
    legacyPermissions,
    featureFlagsGlobal,
    featureFlags,
    permissions,
  ]);

  return [availableSidebarTabs, isSidebarTabsLoading];
};

/**
 * Use to determine if certain sidebar entires that also appear in the new
 * account nav bar should be hidden from the sidebar. This is temporary while
 * the old auth system is still being used.
 * @param sidebarId the ID of the sidebar config
 * @returns true if it should be hidden, false otherwise
 */
const isNewAccountNavigationEnabled = (
  sidebarId: string,
  {
    activeAuthVersion,
    checkIsFeatureEnabled,
  }: {
    activeAuthVersion: AuthenticatedContextActiveAuthVersionType;
    checkIsFeatureEnabled: (query: AuthQueryType) => boolean;
  }
) => {
  const isV1AuthOnly =
    activeAuthVersion !== "unknown" &&
    Array.isArray(activeAuthVersion) &&
    activeAuthVersion.length === 1 &&
    activeAuthVersion.includes("v1");
  const isDualAuth =
    activeAuthVersion !== "unknown" &&
    Array.isArray(activeAuthVersion) &&
    activeAuthVersion.length === 2 &&
    (["v1", "v2"] satisfies AuthenticatedContextAuthVersionType[]).every((v) =>
      activeAuthVersion.includes(v)
    );
  const isV2AuthOnly =
    activeAuthVersion !== "unknown" &&
    Array.isArray(activeAuthVersion) &&
    activeAuthVersion.length === 1 &&
    activeAuthVersion.includes("v2");

  // hide the home page when the user is v1 auth only or when they're dual-auth and the `ops:auth` flag is enabled
  if (
    (isV1AuthOnly || (isDualAuth && checkIsFeatureEnabled("ops:auth"))) &&
    sidebarId === "home"
  ) {
    return true;
  }

  // hide legacy ops-hub features when user is dual-auth and the `ops:auth` flag is not enabled OR when the user is v2 auth only
  if (
    ((isDualAuth && !checkIsFeatureEnabled("ops:auth")) || isV2AuthOnly) &&
    Object.values(LegacyOpsHubFeatures).includes(sidebarId as never)
  ) {
    return true;
  }

  // hide the v2 auth settings and v1 auth settings when the user is a dual-auth
  // user AND they don't have the ops:auth flag enabled or they're a v2 auth only user
  if (
    ((isDualAuth && !checkIsFeatureEnabled("ops:auth")) || isV2AuthOnly) &&
    (Object.values(AccountNavBarSettings).includes(sidebarId as never) ||
      Object.values(LegacyAccountNavBarSettings).includes(sidebarId as never))
  ) {
    return true;
  }

  return false;
};
