import { fetchInstrumentList } from "#redux/actions/index";
import RecordOfQualityService from "#services/RecordOfQualityService";
import SampleService from "#services/SampleService";
import { downloadLink } from "#utils/download";
import { getPropertyAsMap } from "#utils/objectFormatter";
import {
  LegacyDataTable,
  Panel,
  useAlert,
} from "@validereinc/common-components";
import classNames from "classnames/bind";
import * as PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { AutoSizer } from "react-virtualized";
import { getAlerts } from "../SampleComparisonTableHelper";
import styles from "./SampleDetail.module.scss";

const cx = classNames.bind(styles);

const SAMPLE_DOWNLOAD_FILES = [
  { title: "Crude Analysis", id: "crude_analysis" },
  { title: "Full Compositional Analysis", id: "compositional_analysis" },
  { title: "Violation Letter", id: "violation_letter" },
];

const mapStateToProps = ({ instruments }) => {
  return {
    instruments: instruments.data?.toJS() ?? [],
  };
};

const mapDispatchToProps = {
  fetchInstrumentList,
};

const DownloadableReportPanel = ({
  fetchedSample,
  instruments,
  fetchInstrumentList,
  selectedSampleLoadingState,
}) => {
  const [downloadableReports, setDownloadableReport] = useState([]);
  const { addAlert } = useAlert();

  const createSuccessfulDownloadAlertMessage = () => {
    addAlert({
      variant: "success",
      message: "Your files will start downloading shortly.",
    });
  };

  const onDownloadLinkClick = (sampleId, type) => {
    RecordOfQualityService.getDownloadSampleCompositionalLink(
      sampleId,
      type
    ).then(({ data }) => {
      downloadLink(data.url);
      createSuccessfulDownloadAlertMessage();
    });
  };

  const onLabResultDownloadLinkClick = (labReportId) => {
    SampleService.getLabReportFile(labReportId).then(({ data }) => {
      downloadLink(data.url);
      createSuccessfulDownloadAlertMessage();
    });
  };

  const cellRenderer = (rowData) => {
    const { onClick, name } = rowData;

    return (
      <a
        className="link"
        onClick={onClick}
      >
        {name}
      </a>
    );
  };

  useEffect(() => {
    fetchInstrumentList();
  }, []);

  useEffect(() => {
    const newDownloadList = [];

    if (fetchedSample?.id) {
      const alerts = getAlerts(fetchedSample);

      SAMPLE_DOWNLOAD_FILES.map((file) => {
        if (file.id !== "violation_letter" || alerts.length) {
          newDownloadList.push({
            id: file.id,
            name: file.title,
            onClick: () => onDownloadLinkClick(fetchedSample.id, file.id),
          });
        }
      });

      if (fetchedSample?.tests && instruments.length) {
        const instrumentNameMap = getPropertyAsMap(instruments, "name");
        const sortedTests = fetchedSample?.tests.sort((a, b) =>
          instrumentNameMap[a.instrument_id]?.localeCompare(
            instrumentNameMap[b.instrument_id]
          )
        );

        sortedTests.map((test) => {
          if (test.lab_report_id) {
            newDownloadList.push({
              id: test.id,
              name:
                test.type === "lab"
                  ? "Lab Report"
                  : `${test?.instrumentName ?? ""} Report`,
              onClick: () => onLabResultDownloadLinkClick(test?.lab_report_id),
            });
          }
        });
      }

      setDownloadableReport(newDownloadList);
    }
  }, [fetchedSample, instruments]);

  const headers = [
    {
      label: "Name",
      key: "name",
      width: 350,
      fixed: true,
      cellRenderer: cellRenderer,
    },
  ];

  return (
    <Panel
      title="Downloadable Reports"
      loaded={selectedSampleLoadingState === "loaded"}
    >
      <div className={cx("downloadableReportContainer")}>
        <AutoSizer>
          {({ width, height }) => (
            <LegacyDataTable
              width={width}
              height={height}
              headers={headers}
              list={downloadableReports}
            />
          )}
        </AutoSizer>
      </div>
    </Panel>
  );
};

DownloadableReportPanel.propTypes = {
  fetchedSample: PropTypes.object,
  instruments: PropTypes.array.isRequired,
  fetchInstrumentList: PropTypes.func.isRequired,
  selectedSampleLoadingState: PropTypes.string.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DownloadableReportPanel);
