import { Modal, Title } from "@validereinc/common-components";
import * as PropTypes from "prop-types";
import React from "react";
import "./AccessDefinitionsModal.css";

const PermissionsDefinitions = (props) => (
  <div className="accessDefinitions">
    <div className="accessDefinitions__title">{props.title}</div>

    <p className="accessDefinitions__definitions">
      <b>Read Access: </b>
      {props.readDefinition}
    </p>

    <p className="accessDefinitions__definitions">
      <b>Read and Write Access: </b>
      {props.writeDefinition}
    </p>
  </div>
);

const AccessDefinitionsModal = (props) => (
  <Modal
    open={props.show}
    onClose={props.onHide}
  >
    <Modal.Header>
      <Title>Access Definitions</Title>
    </Modal.Header>

    <Modal.Body>
      <div className="accessDefinitionsContainer">
        <PermissionsDefinitions
          title="Workflows"
          readDefinition="View workflow(s) and tasks on user's assigned site(s)."
          writeDefinition="Schedule workflow(s) on user's assigned site(s). Enable and disable non-routine notifications for all sites."
        />
        <PermissionsDefinitions
          title="Notifications"
          readDefinition="View notifications from user's assigned site(s)."
          writeDefinition="View notifications from user's assigned site(s)."
        />
        <PermissionsDefinitions
          title="Calibrations"
          readDefinition="View instruments from user's assigned site(s) or instrument(s)."
          writeDefinition="View and edit instrument's calibrations and serial numbers from user's assigned site(s) or instrument(s)."
        />
        <PermissionsDefinitions
          title="Field Labs"
          readDefinition="View field instruments including test results and calibration records from the instruments assigned to user's site(s)."
          writeDefinition="Access to LIMS (Sample App) and Validere CTR
          (Centrifuge Tube Reader) assigned to user's site(s)."
        />
        <PermissionsDefinitions
          title="Inline Analyzers"
          readDefinition="View field instruments including test results and measurement readings from the instruments assigned to user's site(s)."
        />
        <PermissionsDefinitions
          title="Lab Results / Imports"
          readDefinition="View lab results from user's assigned site(s)."
          writeDefinition="Import CSV files from third party labs. Export a CSV file of the data from the Lab Results page."
        />
        <PermissionsDefinitions
          title="Chains of Custody"
          readDefinition="View Chains of Custody forms from user's assigned site(s)."
          writeDefinition="Generate and View Chains of Custody forms from user's assigned site(s)."
        />
        <PermissionsDefinitions
          title="Analyze"
          readDefinition="Access to use the Analyze tool for test results from user's assigned site(s)."
          writeDefinition="Access to use the Analyze tool for test results from user's assigned site(s)."
        />
        <PermissionsDefinitions
          title="Insights"
          readDefinition="View Insights assigned to user's site(s)."
          writeDefinition="View Insights assigned to user's site(s)."
        />
        <PermissionsDefinitions
          title="Stream Alert"
          readDefinition="View stream alert limits across all streams."
          writeDefinition="Set stream limits across all streams. Enable and disable orphan test notification."
        />
        <PermissionsDefinitions
          title="User Management"
          readDefinition="View other members assigned to the same site(s)."
          writeDefinition="Add, delete and edit user account information on the same site(s). Create, delete and edit roles."
        />
        <PermissionsDefinitions
          title="Records Of Quality"
          readDefinition="View Natural Gas and Liquid records of quality from user's assigned site(s)."
          writeDefinition="Modify Natural Gas and Liquid records of quality from user's assigned site(s)."
        />
      </div>
    </Modal.Body>
  </Modal>
);

PermissionsDefinitions.propTypes = {
  title: PropTypes.string,
  readDefinition: PropTypes.string,
  writeDefinition: PropTypes.string,
};

AccessDefinitionsModal.propTypes = {
  onHide: PropTypes.func,
  show: PropTypes.bool,
};

export default AccessDefinitionsModal;
