import styles from "@validereinc/common-components/constants";
import * as PropTypes from "prop-types";
import React from "react";
import FontAwesome from "react-fontawesome";
import { MAX_DECIMAL_PLACES } from "./ComponentAnalysisConstant";
import "./TotalAccumulation.scss";

const TotalAccumulation = ({ totalAccumulation, accumulationDifference }) => {
  return (
    <div className="totalAccumulation">
      <div className="totalAccumulation__icon">
        {accumulationDifference === 0 ? (
          <FontAwesome
            className="success"
            name="check"
          />
        ) : (
          <FontAwesome
            className="error"
            name="exclamation-circle"
          />
        )}
      </div>

      <div>
        <div className="totalAccumulation__title">Total Accumulation</div>
        <div className="totalAccumulation__subtitle">
          Total accumulation of all the component values should sum up to 100%.
        </div>
        <div className="totalAccumulation__total">
          Current Total:{" "}
          <span className="totalAccumulation__value">{totalAccumulation}%</span>
        </div>
        <div className="totalAccumulation__total">
          Difference:{" "}
          <span
            className="totalAccumulation__value --statusErrorColour"
            style={{
              color:
                accumulationDifference !== 0 ? styles.statusErrorColour : null,
            }}
          >
            {accumulationDifference?.toFixed(MAX_DECIMAL_PLACES)}%
          </span>
        </div>
      </div>
    </div>
  );
};

TotalAccumulation.propTypes = {
  totalAccumulation: PropTypes.string.isRequired,
  accumulationDifference: PropTypes.number.isRequired,
};

export default TotalAccumulation;
