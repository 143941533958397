import React, { Component } from "react";
import "./Popup.css";
import { List, AutoSizer } from "react-virtualized";
import "react-virtualized/styles.css";
import "./PopupEventsDetail.css";
import FontAwesome from "react-fontawesome";
import moment from "moment";

/* eslint-disable react/prop-types */

class PopupEventsDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updateEvents: false,
    };

    this.getEventRowRenderer = this.getEventRowRenderer.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.dayDetail !== this.props.dayDetail) {
      this.setState({
        updateEvents: !this.state.updateEvents,
      });
    }
  }

  getEventRowRenderer = (props) => {
    const { dayDetail } = this.props;

    const xOffset = 50;

    const date = moment(dayDetail.date).date();
    const eventDetail = dayDetail.events[props.index];
    eventDetail.date = dayDetail.date;

    return (
      <div
        className="monthlyView__dayCellEvent"
        key={props.index}
        style={props.style}
        onClick={() => this.props.eventClicked(date, eventDetail, xOffset)}
      >
        <FontAwesome
          name="circle"
          className="icon"
          style={{ fontSize: "10px", color: eventDetail.color }}
        />
        {eventDetail.name}
      </div>
    );
  };

  render() {
    const { dayDetail, showMorePopupDimensions } = this.props;

    const heightOffset = 70;

    const height = showMorePopupDimensions
      ? showMorePopupDimensions.height - heightOffset
      : 0;

    return (
      <React.Fragment>
        <div className="monthlyView__popupDay">
          {moment(dayDetail.date).date()}
        </div>
        <div
          className="monthlyView__popupEventsContainer"
          style={{
            height: `${height}px`,
          }}
        >
          <AutoSizer>
            {({ height, width }) => (
              <List
                width={width}
                height={height}
                rowCount={dayDetail.events ? dayDetail.events.length : 0}
                rowHeight={25}
                rowRenderer={this.getEventRowRenderer}
                updateEvents={!this.state.updateEvents}
              />
            )}
          </AutoSizer>
        </div>
      </React.Fragment>
    );
  }
}

export default PopupEventsDetail;
