import React from "react";
import * as PropTypes from "prop-types";
import { getLabelsString } from "#utils/stringFormatter";
import "./AlertsList.scss";

const hasAlertsAndWarning = (labels, metadata) => {
  return (
    labels?.length > 0 ||
    metadata?.msg_warnings?.length > 0 ||
    metadata?.msg_errors?.length > 0
  );
};

const AlertsList = ({ labels, metadata }) => {
  return (
    <div className="testAnalysis__alertList">
      {metadata?.msg_errors?.map((error) => (
        <div
          key={error}
          className="alert error"
        >
          {error}
        </div>
      ))}

      {metadata?.msg_warnings?.map((warning) => (
        <div
          key={warning}
          className="alert warning"
        >
          {warning}
        </div>
      ))}

      {labels?.map((label) => (
        <div
          key={label}
          className="alert warning"
        >
          {getLabelsString(label)}
        </div>
      ))}

      {!hasAlertsAndWarning(labels, metadata) ? (
        <div>No Instrument Warnings</div>
      ) : null}
    </div>
  );
};

AlertsList.propTypes = {
  labels: PropTypes.array,
  metadata: PropTypes.object,
};

export default AlertsList;
