import DateRangeSelector from "#common/DateSelector/DateRangeSelector";
import { clearAllInputs, getAllFilterInputs } from "#utils/arrayFormatter";
import {
  FilterPillbox,
  MultiDropdownInputWithSearch,
} from "@validereinc/common-components";
import differenceBy from "lodash/differenceBy";
import find from "lodash/find";
import React, { useState } from "react";

const DEFAULT_FILTER_DROPDOWN = {
  sampleType: {
    label: "Type",
    inputs: [],
    options: [
      { id: "composite", name: "Composite" },
      { id: "spot", name: "Spot" },
      { id: "unknown", name: "Unknown" },
    ],
    labelKey: "name",
    paramKey: "id",
  },
  workflowType: {
    label: "Workflow",
    inputs: [],
    options: [
      { id: "field", name: "Field" },
      { id: "third_party_lab", name: "Third Party Lab" },
      { id: "non-routine", name: "Non-Routine" },
    ],
    labelKey: "name",
    paramKey: "id",
  },
};

const getFilterSamples = (samples, filters, selectedExistingSamples) => {
  const { sampleType, workflowType } = filters;

  const filteredSamples = samples.filter((sample) => {
    if (
      sampleType?.length > 0 &&
      sampleType.every((type) => type.id !== sample.sample_type)
    ) {
      return false;
    }

    if (
      workflowType?.length > 0 &&
      workflowType.every((type) => {
        if (type.id === "non-routine") {
          return !!sample.workflow_instance?.test_type;
        } else {
          return type.id !== sample.workflow_instance?.test_type;
        }
      })
    ) {
      return false;
    }

    if (
      selectedExistingSamples?.length > 0 &&
      !!find(selectedExistingSamples, { id: sample.id })
    ) {
      return false;
    }

    return true;
  });

  return filteredSamples;
};

const useFilterInputs = (
  samples,
  from,
  to,
  onDateRangeChange,
  selectedExistingSamples
) => {
  const [filterDropdowns, setFilterDropdowns] = useState({
    ...DEFAULT_FILTER_DROPDOWN,
  });

  const clearAllFilters = () => {
    setFilterDropdowns(clearAllInputs(filterDropdowns));
  };

  const onDropdownSelect = (selectedValue, key) => {
    const filterDropdown = { ...filterDropdowns[key] };
    filterDropdown.inputs = selectedValue;

    setFilterDropdowns({ ...filterDropdowns, [key]: filterDropdown });
  };

  const onClearFilterClick = (filterObject) => {
    if (filterObject) {
      const { filterKey } = filterObject;
      const updatedFilterDropdown = { ...filterDropdowns[filterKey] };

      updatedFilterDropdown.inputs = differenceBy(
        updatedFilterDropdown.inputs,
        [filterObject],
        "id"
      );

      setFilterDropdowns({
        ...filterDropdowns,
        [filterKey]: updatedFilterDropdown,
      });
    } else {
      clearAllFilters();
    }
  };

  const filterRow = (
    <div className="filterRow">
      {Object.keys(filterDropdowns).map((key) => {
        const { label, inputs, labelKey, options } = filterDropdowns[key];

        return (
          <MultiDropdownInputWithSearch
            key={key}
            label={label}
            labelKey={labelKey ? labelKey : null}
            dropdownKey={key}
            value={inputs}
            options={options}
            onChange={onDropdownSelect}
          />
        );
      })}

      <DateRangeSelector
        dateRange={{
          from: from,
          to: to,
        }}
        onDateRangeChange={onDateRangeChange}
        icon
      />
    </div>
  );

  const filterInputs = getAllFilterInputs(filterDropdowns);

  const filteredSamples = getFilterSamples(
    samples,
    filterInputs,
    selectedExistingSamples
  );

  const filterPillbox = (
    <FilterPillbox
      filterBy={filterInputs}
      onClearFilterClick={onClearFilterClick}
    />
  );

  return [filterPillbox, filterRow, filteredSamples];
};

export default useFilterInputs;
