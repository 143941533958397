import SummaryInformation from "#common/SummaryInformation/SummaryInformation";
import { BALANCE_SOURCE_TYPES } from "#utils/enums";
import { getDollarValue } from "#utils/stringFormatter";
import { getNumberColorBySign } from "#utils/styleCalculator";
import {
  MultiDropdownInputWithSearch,
  Panel,
  Title,
} from "@validereinc/common-components";
import classNames from "classnames/bind";
import isEmpty from "lodash/isEmpty";
import * as PropTypes from "prop-types";
import React, { useState } from "react";
import { getPropertyValue } from "../ProductionReportHelper";
import NetFacilityChart from "./NetFacilityChart";
import styles from "./ProductionReportSummary.module.scss";
import ReportSummaryTable from "./ReportSummaryTable";

const cx = classNames.bind(styles);

const CHART_OPTIONS = [
  { id: "differences", title: "Differences" },
  { id: "percent_differences", title: "Percent Difference" },
];

const EstimatedBalanceCurrency = ({ estimatedBalance }) => {
  if (estimatedBalance) {
    return (
      <span
        style={{
          color: getNumberColorBySign(estimatedBalance.value),
        }}
        className="estimatedValue"
      >
        {getDollarValue(estimatedBalance.value, estimatedBalance.currency)}
      </span>
    );
  }

  return "-";
};

const checkWhichBalanceSourceType = (inlets, outlets) => {
  if (isEmpty(inlets) && !isEmpty(outlets)) {
    return BALANCE_SOURCE_TYPES.OUTLETS_ONLY;
  } else if (!isEmpty(inlets) && isEmpty(outlets)) {
    return BALANCE_SOURCE_TYPES.INLETS_ONLY;
  }
  return BALANCE_SOURCE_TYPES.INLETS_AND_OUTLETS;
};

const ProductionReportSummary = ({
  productionReportDetail,
  productionReportInfo,
}) => {
  const { summary, inlets, outlets } = productionReportDetail ?? {};
  const { inlet_streams, outlet_streams } = productionReportInfo ?? {};
  const [chartType, setChartType] = useState(CHART_OPTIONS[0]); //difference

  const balanceSourceType = checkWhichBalanceSourceType(
    inlet_streams,
    outlet_streams
  );

  const sharedProps = {
    spaceBetween: true,
    titleStyle: {
      fontWeight: "normal",
      width: "200px",
      whiteSpace: "break-spaces",
    },
  };

  if (!summary) {
    return <Panel title="Report Summary">Unavailable</Panel>;
  }

  return (
    <Panel title="Report Summary">
      <div className={cx("container")}>
        <div className={cx("estimatedValueContainer")}>
          <Title type="subheader">Estimated Report Value</Title>

          <Title type="subheader">
            <EstimatedBalanceCurrency
              estimatedBalance={summary?.total?.estimated_value}
            />
          </Title>

          <div className="horizontalDivider" />

          {!isEmpty(inlet_streams) && (
            <SummaryInformation
              {...sharedProps}
              title="Receipts Plus Opening Inventory"
              value={getPropertyValue(inlets?.total)}
            />
          )}

          {!isEmpty(outlet_streams) && (
            <SummaryInformation
              {...sharedProps}
              title="Dispositions Plus Closing Inventory"
              value={getPropertyValue(outlets?.total)}
            />
          )}

          {balanceSourceType === BALANCE_SOURCE_TYPES.INLETS_AND_OUTLETS && (
            <>
              <SummaryInformation
                {...sharedProps}
                title="Absolute Differences"
                value={getPropertyValue(summary?.total?.differences)}
              />
              <SummaryInformation
                {...sharedProps}
                title="Percent Differences"
                value={getPropertyValue(summary?.total?.percent_differences)}
              />
            </>
          )}
        </div>

        <div className="verticalDivider" />

        <div className={cx("netFacilityChartContainer")}>
          {balanceSourceType === BALANCE_SOURCE_TYPES.INLETS_AND_OUTLETS && (
            <div className={cx("optionsContainer")}>
              <MultiDropdownInputWithSearch
                options={CHART_OPTIONS}
                label="Chart"
                labelKey="title"
                value={[chartType]}
                onChange={(value) => setChartType(value)}
                isMulti={false}
              />
            </div>
          )}

          <NetFacilityChart
            productionReportDetail={productionReportDetail}
            volumeUnit={productionReportInfo?.volume_unit}
            balanceSourceType={balanceSourceType}
            chartType={chartType}
          />
        </div>
      </div>

      <ReportSummaryTable
        productionReportDetail={productionReportDetail}
        balanceSourceType={balanceSourceType}
      />
    </Panel>
  );
};

EstimatedBalanceCurrency.propTypes = {
  estimatedBalance: PropTypes.object.isRequired,
};

ProductionReportSummary.propTypes = {
  productionReportDetail: PropTypes.object.isRequired,
  productionReportInfo: PropTypes.object.isRequired,
};

export default ProductionReportSummary;
