import React from "react";
import * as PropTypes from "prop-types";
import { connect } from "react-redux";
import { getMeasurementType } from "../../Redux/reducers/measurements";
import { getAlertSpec } from "../InstrumentComponentHelper";
import InstrumentDetail from "../InstrumentDetail";
import {
  sampleDateRenderer,
  sortBySampleDate,
  dateRenderer,
  getMeasurementValue,
} from "../InstrumentHelper";
import MeasurementStatus from "../Shared/TestsDatatable/MeasurementStatus";
import { notesColumn } from "../Shared/TestsDatatable/NotesColumn";
import {
  sampleStateRenderer,
  sortBySampleState,
} from "../../Samples/SampleHelper";
import TestDetailModal from "../../Tests/TestDetailModal/TestDetailModal";

const measurementWidth = 250;

const measurementRenderer = (measurementType, defaultAlertSpec) =>
  function measurementRendererInner(rowData, columnKey) {
    try {
      const value = getMeasurementValue(
        rowData.measurements[columnKey].value,
        measurementType.decimals
      );
      const unit = rowData.measurements[columnKey].unit;

      return (
        <MeasurementStatus
          value={value}
          unit={unit}
          status={rowData.status}
          labels={rowData.labels}
          defaultAlertSpec={defaultAlertSpec}
          alertSpec={getAlertSpec(
            rowData.measurement_alert_specs,
            measurementType.name,
            defaultAlertSpec
          )}
          measurementType={measurementType}
        />
      );
    } catch {
      return <div>-</div>;
    }
  };

const testedByRenderer = (rowData, columnKey) => {
  return <div>{rowData[columnKey] ?? "-"}</div>;
};

const sampleTypeRenderer = (rowData, columnKey) => {
  try {
    const value = rowData.sample[columnKey];
    return <div style={{ textTransform: "capitalize" }}>{value}</div>;
  } catch {
    return <div>-</div>;
  }
};

export const getSampleType = (sample) => {
  if (sample) {
    return sample.type;
  } else if (sample === null) {
    return "Orphan";
  } else {
    return "Unavailable";
  }
};

const sortByMetadata = (list, sortBy) => {
  return list.sort((a, b) => {
    const aValue = a.metadata[sortBy] ?? "-";
    const bValue = b.metadata[sortBy] ?? "-";

    return aValue.localeCompare(bValue);
  });
};

const sortByMeasurements = (list, measurementName) => {
  return [...list].sort((a, b) => {
    return (
      a.measurements[measurementName].value -
      b.measurements[measurementName].value
    );
  });
};

const headers = (wsMeasurementType, wsiMeasurementType) => [
  {
    label: "Name",
    key: "name",
    width: 400,
    fixed: true,
  },
  {
    label: "Sample State",
    key: "sample_state",
    width: 150,
    cellRenderer: (row) => sampleStateRenderer(row.sample),
    sort: sortBySampleState,
  },
  {
    label: "Test Date",
    key: "date",
    width: 170,
    cellRenderer: dateRenderer,
  },
  {
    label: "Tested By",
    key: "tested_by",
    width: 150,
    cellRenderer: testedByRenderer,
    sort: sortByMetadata,
  },
  {
    label: "Type",
    key: "type",
    width: 150,
    cellRenderer: sampleTypeRenderer,
    sort: (list, sortBy) => {
      return list.sort((a, b) => {
        const aValue = a.sample?.[sortBy] ?? "-";
        const bValue = b.sample?.[sortBy] ?? "-";

        return aValue.localeCompare(bValue);
      });
    },
  },
  {
    label: wsMeasurementType.display_name,
    key: wsMeasurementType.name,
    width: measurementWidth,
    cellRenderer: measurementRenderer(wsMeasurementType, {
      measurement_unit: wsMeasurementType.unit,
      upper_limit: 0.5,
      lower_limit: 0,
    }),
    sort: sortByMeasurements,
  },
  {
    label: wsiMeasurementType.display_name,
    key: wsiMeasurementType.name,
    width: measurementWidth,
    cellRenderer: measurementRenderer(wsiMeasurementType, {
      measurement_unit: wsiMeasurementType.unit,
      upper_limit: 0.5,
      lower_limit: 0,
    }),
    sort: sortByMeasurements,
  },
  {
    label: "Sample Start Date",
    key: "started_at",
    width: 170,
    cellRenderer: sampleDateRenderer,
    sort: sortBySampleDate,
  },
  {
    label: "Sample End Date",
    key: "ended_at",
    width: 170,
    cellRenderer: sampleDateRenderer,
    sort: sortBySampleDate,
  },
  notesColumn,
];

const mapStateToProps = (state) => {
  return {
    wsMeasurementType: getMeasurementType(state.measurements)(
      "Water & Sediment"
    ),
    wsiMeasurementType: getMeasurementType(state.measurements)(
      "Water & Sediment & Interface"
    ),
  };
};

const WaterInstrumentTests = (props) => {
  return (
    <InstrumentDetail
      testId={props.match.params.testId}
      instrumentInfo={props.instrumentInfo}
      height={props.height}
      ReportModalComponent={TestDetailModal}
      filterKey="name"
      headers={headers(props.wsMeasurementType, props.wsiMeasurementType)}
      defaultSortBy="date"
      defaultSortDirection="desc"
    />
  );
};

WaterInstrumentTests.propTypes = {
  match: PropTypes.object.isRequired,
  height: PropTypes.number.isRequired,
  instrumentInfo: PropTypes.object.isRequired,
  wsMeasurementType: PropTypes.object.isRequired,
  wsiMeasurementType: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, undefined)(WaterInstrumentTests);
