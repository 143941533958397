import { z } from "zod";
import { AssetType } from "./AssetSchemas";

export const ResourceLookup = {
  ...AssetType,
  FORM_SUBMISSION: "form_submission",
} as const;

export const AttributeDataType = {
  NUMBER: "number",
  STRING: "string",
  GEO_POINT: "geo_point",
  BOOLEAN: "boolean",
  INTEGER: "integer",
  PICK_LIST: "pick-list",
  MULTI_PICK_LIST: "multi-pick-list",
  NUMBER_ARRAY: "number-array",
  LOOKUP: "lookup",
  DATE: "date",
  DATE_TIME: "date-time",
  DATE_TIME_RANGE: "date-time-range",
  FILE: "file",
} as const;

// REVIEW: verify if this is correct as this list is not verifiable through Swagger
export const AttributeId = {
  emissions_recovered: "emissions_recovered",
  compressibility: "compressibility",
  device_type: "device_type",
  vessel_height: "vessel_height",
  vessel_inside_diameter: "vessel_inside_diameter",
  gas_composition: "gas_composition",
  recovery_fraction: "recovery_fraction",
  unlit_feed_gas: "unlit_feed_gas",
  pressure: "pressure",
  temperature: "temperature",
  fuel_hhv: "fuel_hhv",
  fuel_unit: "fuel_unit",
  n2o_mass_emission_factor: "n2o_mass_emission_factor",
  flare_combustion_efficiency: "flare_combustion_efficiency",
  device_bleed_type: "device_bleed_type",
  device_count: "device_count",
  pneumatic_device_efs: "pneumatic_device_efs",
} as const;

export const AttributeSchema = z.object({
  id: z.string(),
  name: z.string(),
  description: z.string(),
  data_type: z.enum([
    AttributeDataType.NUMBER,
    AttributeDataType.STRING,
    AttributeDataType.GEO_POINT,
    AttributeDataType.BOOLEAN,
    AttributeDataType.INTEGER,
    AttributeDataType.PICK_LIST,
    AttributeDataType.MULTI_PICK_LIST,
    AttributeDataType.NUMBER_ARRAY,
    AttributeDataType.LOOKUP,
    AttributeDataType.DATE,
    AttributeDataType.DATE_TIME,
    AttributeDataType.FILE,
    AttributeDataType.DATE_TIME_RANGE,
  ]),
  pick_list_values: z.array(z.string()).optional(),
  is_required: z.boolean(),
  units: z.string().optional(), // REVIEW: units here but "unit" in custom attributes. talk to BE about this.
  minimum: z.number().optional(), // REVIEW: only appears in some cases. Verify with BE team. is there a maximum counterpart too?
});

export type ResourceLookupType =
  (typeof ResourceLookup)[keyof typeof ResourceLookup];
export type AttributeDataTypeType =
  (typeof AttributeDataType)[keyof typeof AttributeDataType];
export type AttributeType = z.infer<typeof AttributeSchema>;
export type AttributeIdType = (typeof AttributeId)[keyof typeof AttributeId];

export const AttributeSchemaHelpers = {
  getDataTypeForDisplay: (value: AttributeType["data_type"]) => {
    switch (value) {
      case "string":
        return "Plain Text";
      case "boolean":
        return "Yes / No or True / False";
      case "date":
        return "Date";
      case "date-time":
        return "Date and Time";
      case "date-time-range":
        return "Date and Time Range";
      case "file":
        return "File";
      case "number":
        return "Number (can have decimals)";
      case "number-array":
        return "Number Array";
      case "integer":
        return "Integer";
      case "lookup":
        return "Lookup";
      case "geo_point":
        return "Geo Coordinates";
      case "pick-list":
        return "Single Pick List";
      case "multi-pick-list":
        return "Multi Pick List";
    }
  },
};
