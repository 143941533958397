import DateRangeSelector from "#common/DateSelector/DateRangeSelector";
import useQueryParams from "#routers/useQueryParams";
import config from "#src/config";
import { getTimeStringFromDate } from "#utils/timeFormatter";
import { ControlLabel } from "@validereinc/common-components";
import * as PropTypes from "prop-types";
import React from "react";
import "./EmissionRecords.scss";

const EmissionRecordsDateSelector = ({ defaultDateRange }) => {
  const [queryParams, setQueryParams] = useQueryParams(defaultDateRange);

  const selectDate = (from, until) => {
    setQueryParams({
      from: getTimeStringFromDate(from, config.DATE_FORMAT),
      until: getTimeStringFromDate(until, config.DATE_FORMAT),
    });
  };

  return (
    <ControlLabel
      label="Period"
      className="EmissionRecords__DatePicker"
    >
      <div className="filterRow">
        <DateRangeSelector
          dateRange={{ from: queryParams.from, to: queryParams.until }}
          onDateRangeChange={selectDate}
          icon
          disableRelativeDays
        />
      </div>
    </ControlLabel>
  );
};

EmissionRecordsDateSelector.propTypes = {
  defaultDateRange: PropTypes.object.isRequired,
};

export default EmissionRecordsDateSelector;
