import {
  CUBIC_METERS_VOLUME,
  getFormattedMeasurementValueWithUnit,
} from "#redux/reducers/measurements";
import { LegacyDataTable } from "@validereinc/common-components";
import * as PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { AutoSizer } from "react-virtualized";
import "./DailyVolumeTable.scss";
import {
  getVolumeByDays,
  sortByNotes,
  sortByVolume,
} from "./DailyVolumeTableHelper";

const SOURCE_SHORT_LABELS = {
  production_management_system: "PMS",
};

const mapStateToProps = (state) => {
  return {
    getFormattedMeasurementValueWithUnit: (measurementKey, measurementObject) =>
      getFormattedMeasurementValueWithUnit(state.measurements)(
        measurementKey,
        measurementObject
      ),
  };
};

const DailyVolumeTable = ({
  volumes,
  dateRange,
  getFormattedMeasurementValueWithUnit,
  height = 510,
}) => {
  const [dailyVolumes, setDailyVolumes] = useState([]);

  useEffect(() => {
    if (dateRange && volumes) {
      const { from, to } = dateRange;

      const dailyVolumes = getVolumeByDays(from, to, volumes);

      setDailyVolumes(dailyVolumes);
    }
  }, [dateRange, volumes]);

  const stateRenderer = (rowData) => {
    if (rowData.is_entry_required) {
      return "Entry Required";
    } else {
      return rowData.is_locked ? "Locked" : "Unlocked";
    }
  };

  const sourceRenderer = (rowData) => {
    if (rowData.source) {
      const sourceShortForm = SOURCE_SHORT_LABELS[rowData.source];
      return sourceShortForm ?? rowData.source;
    } else {
      return "-";
    }
  };

  const volumeRenderer = (rowData, columnKey) => {
    const totalVolume = rowData[columnKey];

    if (totalVolume) {
      return (
        <span>
          {getFormattedMeasurementValueWithUnit(
            CUBIC_METERS_VOLUME,
            totalVolume
          )}
        </span>
      );
    } else {
      return "-";
    }
  };

  const notesRenderer = (rowData, columnKey) => rowData[columnKey] || "-";

  const Headers = [
    {
      label: "Date",
      key: "date",
      width: 100,
      fixed: true,
    },
    {
      label: "Status",
      key: "state",
      width: 150,
      cellRenderer: stateRenderer,
      disableSort: true,
    },
    {
      label: "Source",
      key: "source",
      width: 100,
      cellRenderer: sourceRenderer,
    },
    {
      label: "Total Volume",
      key: "total_volume",
      width: 200,
      cellRenderer: volumeRenderer,
      sort: sortByVolume,
      rightAlign: true,
    },
    {
      label: "Notes",
      key: "note_count",
      width: 100,
      sort: sortByNotes,
      cellRenderer: notesRenderer,
    },
  ];

  return (
    <div
      className="dailyVolumeTable"
      style={{ height }}
    >
      <AutoSizer>
        {({ height, width }) => (
          <LegacyDataTable
            width={width}
            height={height}
            headers={Headers}
            list={dailyVolumes}
            rowHeight={40}
            defaultSortBy="date"
            defaultSortDirection="asc"
          />
        )}
      </AutoSizer>
    </div>
  );
};

DailyVolumeTable.propTypes = {
  volumes: PropTypes.array.isRequired,
  dateRange: PropTypes.object.isRequired,
  getFormattedMeasurementValueWithUnit: PropTypes.func,
  height: PropTypes.number,
};

const DailyVolumeTableContainer = connect(mapStateToProps)(DailyVolumeTable);

export default DailyVolumeTableContainer;
