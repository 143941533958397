export const DISMISSABLE_WORKFLOW_STATUS = ["missed", "pending"];

export const COMPLETEABLE_WORKFLOW_STATUS = ["pending"];

export const COMPLETED_WORKFLOW_STATUS = ["completed", "sampled", "dismissed"];

export const SAMPLEABLE_WORKFLOW_STATUS = ["pending"];

export const COMPLETED_OPTION = { id: "complete", name: "Completed" };

export const DISMISSED_OPTION = { id: "dismiss", name: "Dismissed" };

export const SAMPLED_OPTION = { id: "sample", name: "Sampled" };
