import React from "react";
import * as PropTypes from "prop-types";
import "./SummaryInformation.scss";

const SummaryInformation = ({ title, value, isLoading, style, className }) => {
  if (typeof value === "string") {
    const [number, unit] = value.split(" ");
    value = (
      <>
        <span className="value">{number}</span>
        <span className="unit">{unit}</span>
      </>
    );
  }

  return (
    <section
      className={`summaryInformation ${className || null}`}
      style={style}
    >
      <div className="summaryInformation__title">{title}</div>
      <div className="summaryInformation__value">
        {isLoading ? "loading" : value}
      </div>
    </section>
  );
};

SummaryInformation.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  isLoading: PropTypes.bool,
  style: PropTypes.object,
  className: PropTypes.string,
};

export default SummaryInformation;
