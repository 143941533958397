import { useState, useEffect } from "react";
import DataMappingService from "#components/Services/DataMappingService";

export const useDataMapping = (dataMappingId) => {
  const [dataMappingDetail, setDataMappingDetail] = useState({});
  const [fetchState, setFetchState] = useState("loading");

  useEffect(() => {
    if (dataMappingId) {
      DataMappingService.getDataMappingDetail(dataMappingId)
        .then(({ data }) => {
          setDataMappingDetail(data);
          setFetchState("loaded");
        })
        .catch(() => {
          setFetchState("error");
        });
    }
  }, [dataMappingId]);

  return [dataMappingDetail, fetchState];
};
