import React from "react";
import ReactLoader from "#components/LoadingBar/ReactLoader";
import ResultsBalanceTable from "./SystemBalanceResults/ResultsBalanceTable";
import SidePanelSection from "#common/SidePanel/SidePanelSection";
import RecordOfQualityRecordResults from "./RecordResults/RecordOfQualityRecordResults";
import "./RecordOfQualityResults.scss";
import { useSelectionModalContext } from "../../Context/selectionModalContext";
import OverlayLoader from "#common/OverlayLoader/OverlayLoader";
import ResultsBalanceSummary from "./SystemBalanceResults/ResultsBalanceSummary";

const RecordOfQualityResults = () => {
  const { resultLoadingState, accountingPeriod, actionState } =
    useSelectionModalContext();

  return (
    <div className="recordOfQualityResults__container">
      {(resultLoadingState === "refetching" || actionState === "disabled") && (
        <OverlayLoader text="Re-calculating..." />
      )}

      <div className="recordOfQualityResults">
        <SidePanelSection
          className="recordOfQualityResults__section"
          subHeader="Record"
        >
          <RecordOfQualityRecordResults />
        </SidePanelSection>

        <div className="recordOfQualityResults__balanceContainer">
          <ReactLoader
            loaded={resultLoadingState !== "loading"}
            position={50}
          >
            {resultLoadingState !== "error" ? (
              <div className="recordOfQualityResults__balance">
                <SidePanelSection
                  className="recordOfQualityResults__section"
                  subHeader="System Balance"
                >
                  <ResultsBalanceSummary
                    balanceType={accountingPeriod.balance_type}
                  />

                  <ResultsBalanceTable
                    componentVolumeConfig={
                      accountingPeriod.component_volume_config
                    }
                  />
                </SidePanelSection>
              </div>
            ) : (
              <div>System Balance is unavailable.</div>
            )}
          </ReactLoader>
        </div>
      </div>
    </div>
  );
};

export default RecordOfQualityResults;
