import { Button, Dialog, useAlert } from "@validereinc/common-components";
import React from "react";

export const DeleteQuestionDialog = ({
  isOpen,
  onClose,
  handleDeleteQuestion,
  questionIdToDelete,
}: {
  isOpen: boolean;
  onClose: () => void;
  handleDeleteQuestion: (questionId: string) => void;
  questionIdToDelete: string;
}) => {
  const DELETE_QUESTION_DIALOG_TITLE = "Delete Question?";
  const DELETE_QUESTION_DIALOG_DESCRIPTION =
    "This will delete any conditional or filter logic associated to other questions.";

  const { addAlert } = useAlert();

  return (
    <Dialog
      title={DELETE_QUESTION_DIALOG_TITLE}
      isOpen={isOpen}
      onClose={() => {
        onClose();
      }}
      actionRow={[
        <Button
          key="delete-question-action"
          variant="error"
          onClick={() => {
            handleDeleteQuestion(questionIdToDelete);
            onClose();
            addAlert?.({
              variant: "success",
              message: "Successfully deleted question.",
            });
          }}
        >
          Delete
        </Button>,
      ]}
    >
      {DELETE_QUESTION_DIALOG_DESCRIPTION}
    </Dialog>
  );
};
