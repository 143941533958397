import { updateSelectedVolumeForEntry } from "#redux/actions/recordOfVolume";
import {
  CUBIC_METERS_VOLUME,
  getFormattedMeasurementValueWithUnit,
} from "#redux/reducers/measurements";
import { Button, LegacyDataTable } from "@validereinc/common-components";
import find from "lodash/find";
import union from "lodash/union";
import * as PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { AutoSizer } from "react-virtualized";
import AdjustmentFactorsCell from "../AdjustmentFactorCell";
import { getRecordCheckboxState } from "../RecordOfVolumeHelper";
import "./DailyVolumeTable.scss";
import useFilterInputs from "./DailyVolumeTableFilter";
import {
  getVolumeByDays,
  sortByNotes,
  sortByVolume,
} from "./DailyVolumeTableHelper";

const HEIGHT_OFFSET = 30;

const SOURCE_SHORT_LABELS = {
  production_management_system: "PMS",
};

const mapStateToProps = (state) => {
  return {
    selectedRecordOfVolumes: state.recordOfVolume?.toJS() ?? [],
    getFormattedMeasurementValueWithUnit: (measurementKey, measurementObject) =>
      getFormattedMeasurementValueWithUnit(state.measurements)(
        measurementKey,
        measurementObject
      ),
  };
};

const mapDispatchToProps = {
  updateSelectedVolumeForEntry,
};

const DailyVolumeTable = (props) => {
  const { volumes, dateRange, adjustmentFactors } = props;

  const [dailyVolumes, setDailyVolumes] = useState([]);
  const [checkedDailyVolume, setCheckedDailyVolume] = useState([]);
  const [filterRow, filterPillbox, filteredDailyVolumes] = useFilterInputs(
    dailyVolumes,
    adjustmentFactors
  );

  useEffect(() => {
    if (dateRange && volumes) {
      const { from, to } = dateRange;

      const dailyVolumes = getVolumeByDays(from, to, volumes);

      setDailyVolumes(dailyVolumes);
    }
  }, [dateRange, volumes]);

  const onActionClick = (selectedStream) => {
    const { is_entry_required, is_locked } = selectedStream;

    props.updateSelectedVolumeForEntry(
      union([selectedStream], checkedDailyVolume)
    );

    if (!props.haveWritePermission || is_locked) {
      props.setModalState("view");
    }

    if (is_entry_required && !is_locked) {
      props.setModalState("add");
    }

    if (!is_entry_required && !is_locked) {
      props.setModalState("edit");
    }
  };

  const onBatchEditButtonClick = () => {
    props.updateSelectedVolumeForEntry(checkedDailyVolume);
    props.setModalState("add");
  };

  const onStreamCheckboxClick = (checkedStreams) => {
    setCheckedDailyVolume(checkedStreams);
  };

  const stateRenderer = (rowData) => {
    if (rowData.is_entry_required) {
      return "Entry Required";
    } else {
      return rowData.is_locked ? "Locked" : "Unlocked";
    }
  };

  const sourceRenderer = (rowData) => {
    if (rowData.source) {
      const sourceShortForm = SOURCE_SHORT_LABELS[rowData.source];
      return sourceShortForm ?? rowData.source;
    } else {
      return "-";
    }
  };

  const volumeRenderer = (rowData, columnKey) => {
    const totalVolume = rowData[columnKey];

    if (totalVolume) {
      return (
        <span>
          {props.getFormattedMeasurementValueWithUnit(
            CUBIC_METERS_VOLUME,
            totalVolume
          )}
        </span>
      );
    } else {
      return "-";
    }
  };

  const adjustmentFactorRenderer = (volumeRowData) => {
    const adjustmentFactorsForVolume =
      volumeRowData.adjustment_factor_ids?.map((factorId) => {
        return find(props.adjustmentFactors, { id: factorId });
      }) ?? [];

    return (
      <AdjustmentFactorsCell adjustmentFactors={adjustmentFactorsForVolume} />
    );
  };

  const Headers = [
    {
      label: "Date",
      key: "date",
      width: 100,
      fixed: true,
    },
    {
      label: "Status",
      key: "state",
      width: 150,
      cellRenderer: stateRenderer,
      disableSort: true,
    },
    {
      label: "Source",
      key: "source",
      width: 100,
      cellRenderer: sourceRenderer,
    },
    {
      label: "Total Unadjusted Volume",
      key: "total_volume",
      width: 200,
      cellRenderer: volumeRenderer,
      sort: sortByVolume,
      rightAlign: true,
    },
    {
      label: "Adjustment Factors",
      key: "adjustments",
      width: 250,
      cellRenderer: adjustmentFactorRenderer,
      disableSort: true,
    },
    {
      label: "Notes",
      key: "note_count",
      width: 100,
      sort: sortByNotes,
    },
  ];

  const haveCheckedDailyVolume = checkedDailyVolume?.length > 0;

  const BatchEditButton = () =>
    props.haveWritePermission ? (
      <Button
        type="primary"
        onClick={onBatchEditButtonClick}
        disabled={!haveCheckedDailyVolume}
      >
        {`Group Edit ${
          haveCheckedDailyVolume ? checkedDailyVolume.length : ""
        }`}{" "}
        Volume Entries
      </Button>
    ) : null;

  return (
    <div
      className="dailyVolumeTable"
      style={{ height: props.height - HEIGHT_OFFSET }}
    >
      <AutoSizer>
        {({ height, width }) => (
          <LegacyDataTable
            width={width}
            height={height}
            headers={Headers}
            list={filteredDailyVolumes}
            onCheckboxClick={
              props.haveWritePermission ? onStreamCheckboxClick : null
            }
            filterRow={props.noFilter ? null : filterRow}
            filterPillbox={props.noFilter ? null : filterPillbox}
            rowHeight={40}
            defaultSortBy="date"
            onCellClick={props.noAction ? null : onActionClick}
            highlightRow={!props.noAction}
            defaultSortDirection="asc"
            actionRow={<BatchEditButton />}
            getCheckboxDisabledState={getRecordCheckboxState}
          />
        )}
      </AutoSizer>
    </div>
  );
};

DailyVolumeTable.propTypes = {
  haveWritePermission: PropTypes.bool,
  height: PropTypes.number,
  volumes: PropTypes.array.isRequired,
  dateRange: PropTypes.object.isRequired,
  adjustmentFactors: PropTypes.array.isRequired,
  updateSelectedVolumeForEntry: PropTypes.func.isRequired,
  setModalState: PropTypes.func,
  getFormattedMeasurementValueWithUnit: PropTypes.func,
  noAction: PropTypes.bool,
  noFilter: PropTypes.bool,
};

const DailyVolumeTableContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DailyVolumeTable);

export default DailyVolumeTableContainer;
