import { useState, useEffect } from "react";
import {
  apiRequestServicesPromise,
  apiPostServicesPromise,
} from "./ServiceHelper";
import { Record } from "immutable";

/**
 * Generalized service to handle note get/post. To use in components, import
 * a hook (useFetchNotes/useSubmitNote) as well as a noteType and pass in the
 * resource id (e.g. recordOfQuality.id) and noteType to the hook to get data
 */

export const TestNoteAttachment = Record({
  id: null,
  file_name: null,
  file_size: null,
  checksum: null,
  download_link: null,
});

export const TestNote = Record({
  id: null,
  text: null,
  date: null,
  user_id: null,
  user: null,
  attachments: null,
});

// NOTE TYPES
export const TEST_NOTE = "testNote";
export const ACCOUNTING_RECORD_NOTE = "accountingRecordNote";
export const VOLUME_NOTE = "volumeNote";
export const WORKFLOWTASKS_NOTE = "workflowTasksNote";
export const SAMPLE_NOTE = "sampleNote";

const getNotesRoute = (id, noteType) => {
  switch (noteType) {
    case TEST_NOTE:
      return `/api/tests/${id}/notes`;
    case ACCOUNTING_RECORD_NOTE:
      return `/api/accounting_periods/records/${id}/notes`;
    case VOLUME_NOTE:
      return `/api/streams/records_of_volume/${id}/notes`;
    case WORKFLOWTASKS_NOTE:
      return `/api/workflows/instances/${id}/notes`;
    case SAMPLE_NOTE:
      return `/api/samples/${id}/notes`;
  }
};

export const getNotes = (id, noteType) => {
  return apiRequestServicesPromise(getNotesRoute(id, noteType)).then(
    (response) => {
      const parsedData = response.data.map((apiTestNote) => {
        const attachments = apiTestNote.attachments.map(
          (apiTestNoteAttachment) => {
            return new TestNoteAttachment(apiTestNoteAttachment);
          }
        );

        return new TestNote({
          ...apiTestNote,
          attachments,
        });
      });

      return {
        ...response,
        data: parsedData,
      };
    }
  );
};

export const useFetchNotes = (id, noteType = TEST_NOTE) => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorStatus, setErrorStatus] = useState(null);
  const [notes, setNotes] = useState([]);
  const [requestPromise, setRequestPromise] = useState(null);
  const [requestId, setRequestId] = useState(null);

  const fetchNotes = () => {
    if (!isLoading || id !== requestId) {
      setIsLoading(true);
      setRequestId(id);
      const promise = getNotes(id, noteType)
        .then(({ data }) => {
          setNotes(data);
        })
        .catch(({ status }) => {
          setErrorStatus(status);
        })
        .finally(() => {
          setIsLoading(false);
          setRequestPromise(null);
        });

      setRequestPromise(promise);
      return promise;
    } else {
      return requestPromise;
    }
  };

  useEffect(() => {
    if (id) {
      fetchNotes();
    } else {
      setNotes([]);
    }
  }, [id]);

  return [isLoading, errorStatus, notes, fetchNotes];
};

const postNotesRoute = (id, noteType) => {
  switch (noteType) {
    case TEST_NOTE:
      return `api/tests/${id}/notes`;
    case ACCOUNTING_RECORD_NOTE:
      return `/api/accounting_periods/records/${id}/notes`;
    case VOLUME_NOTE:
      return `/api/streams/records_of_volume/notes`;
    case WORKFLOWTASKS_NOTE:
      return `/api/workflows/instances/${id}/notes`;
    case SAMPLE_NOTE:
      return `/api/samples/${id}/notes`;
  }
};

export const submitTestNote = (
  id,
  text,
  attachedFiles = [],
  noteType = TEST_NOTE,
  multipleNoteParam
) => {
  const formData = new FormData();
  formData.append("text", text);

  Object.keys(attachedFiles).map((key) => {
    formData.append("file_uploads[]", attachedFiles[key]);
  });

  if (multipleNoteParam) {
    id.forEach((noteId) => {
      formData.append(`${multipleNoteParam}[]`, noteId);
    });
  }

  return apiPostServicesPromise(postNotesRoute(id, noteType), formData);
};

export const useSubmitNote = (id, noteType = TEST_NOTE, multipleNoteParam) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [requestPromise, setRequestPromise] = useState(null);

  const submitNote = (note) => {
    if (!isSubmitting) {
      setIsSubmitting(true);

      const promise = submitTestNote(
        id,
        note.text,
        note.files,
        noteType,
        multipleNoteParam
      ).finally(() => {
        setIsSubmitting(false);
        setRequestPromise(null);
      });

      setRequestPromise(promise);
      return promise;
    } else {
      return requestPromise;
    }
  };

  return [isSubmitting, submitNote];
};
