import { useAddFormSubmissionContext } from "#src/batteries-included-components/Layouts/Form/Submission/Add/AddFormSubmission/AddFormSubmissionContext";
import {
  Button,
  EmptyState,
  EmptyStateProps,
  Icon,
  MetricTileGrid,
  Panel,
} from "@validereinc/common-components";
import React from "react";

export type SelectedFormSubmissionsPanelProps = {
  emptyStateProps: Pick<EmptyStateProps, "suggestion">;
};

export const SelectedFormSubmissionsPanel = ({
  emptyStateProps,
}: SelectedFormSubmissionsPanelProps) => {
  const { selectedFormSubmissions, deselectFormSubmission } =
    useAddFormSubmissionContext();

  return (
    <Panel
      title="Selected Form Submissions"
      isFluidY={false}
    >
      {selectedFormSubmissions?.length ? (
        <MetricTileGrid
          size="small"
          orientation="vertical"
          data={selectedFormSubmissions.map(({ id, form_schema }) => ({
            title: "Submission Name",
            value: `${form_schema?.name} - ${id?.slice(0, 7)}`,
            actions: [
              <Button
                key="remove-origin-button"
                onClick={() => deselectFormSubmission(id)}
                variant="text"
                icon="minus-circle"
              />,
            ],
          }))}
        />
      ) : (
        <EmptyState
          title="No Form Submissions Selected"
          icon={<Icon variant="stack" />}
          isFluid
          {...emptyStateProps}
        />
      )}
    </Panel>
  );
};
