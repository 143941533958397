import React from "react";
import * as PropTypes from "prop-types";
import { connect } from "react-redux";
import { getMeasurementType } from "../../Redux/reducers/measurements";
import { getAlertSpec } from "../InstrumentComponentHelper";
import InstrumentDetail from "../InstrumentDetail";
import isNil from "lodash/isNil";
import {
  sampleDateRenderer,
  sortBySampleDate,
  dateRenderer,
  getMeasurementValue,
} from "../InstrumentHelper";
import MeasurementStatus from "../Shared/TestsDatatable/MeasurementStatus";
import { notesColumn } from "../Shared/TestsDatatable/NotesColumn";
import {
  sampleStateRenderer,
  sortBySampleState,
} from "../../Samples/SampleHelper";
import { getFirstValidValue } from "#utils/objectFormatter";
import TestDetailModal from "#components/Tests/TestDetailModal/TestDetailModal";

const measurementRenderer = (measurementType) =>
  function measurementRendererInner(rowData, columnKey) {
    const defaultAlertSpec = {
      measurement_unit: measurementType.unit,
      upper_limit: 103,
      lower_limit: 0,
    };

    try {
      const value = getMeasurementValue(
        rowData.measurements[columnKey].value,
        measurementType.decimals
      );
      const unit = rowData.measurements[columnKey].unit;

      return (
        <MeasurementStatus
          value={value}
          unit={unit}
          status={rowData.status}
          labels={rowData.labels}
          defaultAlertSpec={defaultAlertSpec}
          alertSpec={getAlertSpec(
            rowData.measurement_alert_specs,
            measurementType.name,
            defaultAlertSpec
          )}
          measurementType={measurementType}
        />
      );
    } catch {
      return <div>-</div>;
    }
  };

const testerRenderer = (rowData, columnKey) => {
  const tester = getFirstValidValue([
    rowData.metadata[columnKey],
    rowData.tested_by,
  ]);

  return <div>{tester ?? "-"}</div>;
};

const measuringTempRenderer = (rowData, columnKey) => {
  const temperature_in_c = getFirstValidValue([
    rowData.metadata?.[columnKey],
    rowData.measurements?.["Vapour Pressure"]?.temperature_in_c,
  ]);

  if (temperature_in_c) {
    return <div>{temperature_in_c} °C</div>;
  } else {
    <div>-</div>;
  }
};

const headers = (measurementType) => [
  {
    label: "Name",
    key: "name",
    width: 400,
    fixed: true,
  },
  {
    label: "Sample State",
    key: "sample_state",
    width: 150,
    cellRenderer: (row) => sampleStateRenderer(row.sample),
    sort: sortBySampleState,
  },
  {
    label: "Test Date",
    key: "date",
    width: 170,
    cellRenderer: dateRenderer,
  },
  {
    label: "Tester",
    key: "operator",
    width: 150,
    cellRenderer: testerRenderer,
    sort: (list, sortBy) => {
      return list.sort((a, b) => {
        const aValue = a.metadata[sortBy] ?? a.tested_by ?? "-";
        const bValue = b.metadata[sortBy] ?? b.tested_by ?? "-";

        return aValue.localeCompare(bValue);
      });
    },
    suppress: true,
  },
  {
    label: "Measuring Temp. (°C)",
    key: "measuring_temperature_in_c",
    width: 150,
    cellRenderer: measuringTempRenderer,
    sort: (list, sortBy) => {
      return list.sort((a, b) => {
        const aValue =
          a.metadata[sortBy] ??
          a.measurements?.["Vapour Pressure"]?.temperature_in_c ??
          null;
        const bValue =
          b.metadata[sortBy] ??
          b.measurements?.["Vapour Pressure"]?.temperature_in_c ??
          null;

        if (!isNil(aValue) && !isNil(bValue)) {
          return aValue - bValue;
        } else if (isNil(aValue) && !isNil(bValue)) {
          return -1;
        } else if (!isNil(aValue) && isNil(bValue)) {
          return 1;
        } else {
          return 0;
        }
      });
    },
  },
  {
    label: measurementType.display_name,
    key: measurementType.name,
    width: 250,
    cellRenderer: measurementRenderer(measurementType),
    sort: (list, measurementName) => {
      return [...list].sort((a, b) => {
        return (
          a.measurements[measurementName].value -
          b.measurements[measurementName].value
        );
      });
    },
  },
  {
    label: "Sample Start Date",
    key: "started_at",
    width: 170,
    cellRenderer: sampleDateRenderer,
    sort: sortBySampleDate,
  },
  {
    label: "Sample End Date",
    key: "ended_at",
    width: 170,
    cellRenderer: sampleDateRenderer,
    sort: sortBySampleDate,
  },
  notesColumn,
];

const mapStateToProps = (state) => {
  return {
    measurementType: getMeasurementType(state.measurements)("Vapour Pressure"),
  };
};

const Tests = (props) => {
  return (
    <InstrumentDetail
      testId={props.match.params.testId}
      instrumentInfo={props.instrumentInfo}
      height={props.height}
      ReportModalComponent={TestDetailModal}
      filterKey="name"
      headers={headers(props.measurementType)}
      defaultSortBy="date"
      defaultSortDirection="desc"
    />
  );
};

Tests.propTypes = {
  match: PropTypes.object.isRequired,
  height: PropTypes.number.isRequired,
  instrumentInfo: PropTypes.object.isRequired,
  measurementType: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, undefined)(Tests);
