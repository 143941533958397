import { recordValueStatusPickerOptions } from "#src/components/Common/InlineRecordValueStatusPicker/InlineRecordValueStatusPicker";
import {
  useHasRecordLockingPermission,
  useUpdateStatusOfValuesOfRecord,
} from "#src/components/hooks/adapters/useRecords";
import {
  Button,
  ButtonProps,
  DropdownMenu,
  DropdownMenuOption,
  Icon,
} from "@validereinc/common-components";
import React from "react";

import classNames from "classnames/bind";
import styles from "./BulkRecordValueStatusChangeButton.module.scss";

const cx = classNames.bind(styles);

const BulkRecordValueStatusChangeButton = ({
  recordId,
  measurementTypes,
  label,
  onSuccess,
  variant,
  isDisabled,
}: {
  recordId: string;
  measurementTypes?: string[];
  label?: string;
  onSuccess?: () => void;
  isDisabled?: boolean;
} & Partial<Pick<ButtonProps, "variant">>) => {
  const setRecordValueStatus = useUpdateStatusOfValuesOfRecord({
    onSuccess,
  });
  const hasLockPermission = useHasRecordLockingPermission();

  const displayButton = (
    <Button
      isLoading={setRecordValueStatus.isLoading}
      variant={variant}
      iconPosition="right"
      icon="caret-down"
      iconProps={{ weight: "fill", size: 16 }}
    >
      {label ?? <>Change Record Status</>}
    </Button>
  );

  const dropdownOptions = recordValueStatusPickerOptions.map(
    ({ label, value, icon, variant }) =>
      ({
        label,
        variant,
        slotLeft: ({ iconProps }) =>
          icon ? (
            <Icon
              {...iconProps}
              variant="lock-simple"
            />
          ) : (
            <div className={cx("empty-icon-spacer")} />
          ),
        onClick: () => {
          if (recordId)
            setRecordValueStatus.mutate({
              recordId,
              measurementTypes: measurementTypes ?? [],
              status: value,
            });
        },
      }) as DropdownMenuOption
  );

  if (!hasLockPermission) return null;

  if (isDisabled) return displayButton;

  return <DropdownMenu options={dropdownOptions}>{displayButton}</DropdownMenu>;
};

export default BulkRecordValueStatusChangeButton;
