import { Panel } from "@validereinc/common-components";
import * as PropTypes from "prop-types";
import React from "react";
import { useSelectionModalContext } from "../../../Context/selectionModalContext";
import "./RecordOfQualityRecordResults.scss";
import RecordOfQualityResultPricing from "./RecordOfQualityResultPricing";
import RecordOfQualityResultTable from "./RecordOfQualityResultTable";

const RecordOfQualityRecordResults = () => {
  const { resultLoadingState, accountingPeriod, recordOfQualityDetail } =
    useSelectionModalContext();

  return (
    <Panel loaded={resultLoadingState !== "loading"}>
      <div className="recordOfQualityRecordResults__content">
        <RecordOfQualityResultPricing
          recordOfQualityDetail={recordOfQualityDetail}
        />

        <RecordOfQualityResultTable
          balanceType={accountingPeriod.balance_type}
        />
      </div>
    </Panel>
  );
};

RecordOfQualityRecordResults.propTypes = {
  balanceType: PropTypes.string,
};

export default RecordOfQualityRecordResults;
