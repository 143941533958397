export const CALCULATION_RESULT_LIST_FALLBACK_PAGE_TITLE =
  "Calculation Results:methodId:equipmentId";

export const linkToCalculationResultList = (
  methodId = ":methodId",
  equipmentId = ":equipmentId"
) =>
  `/app/organization/equipment/${equipmentId}/estimation-method/${methodId}/detail?tab=results`;

export const CALCULATION_RESULT_LIST_BREADCRUMB = {
  title: CALCULATION_RESULT_LIST_FALLBACK_PAGE_TITLE,
  path: linkToCalculationResultList,
};
