import CustomAttributeField from "#common/CustomAttributeField";
import {
  linkToDeviceDetail,
  linkToEquipmentDetail,
  linkToFacilities,
} from "#routers/links";
import { FlowDetailRoute } from "#routes/organization/flows/[flowId]/detail";
import { NetworkDetailRoute } from "#routes/organization/networks/[networkId]/detail";
import { RoutingLink } from "#src/batteries-included-components/RoutingLink";
import { displayValue } from "#utils/stringFormatter";
import { HeaderType } from "@validereinc/common-components";
import { AssetType, AssetTypeType } from "@validereinc/domain";
import React, { useMemo } from "react";
import { useListCustomAttributes } from "../adapters/useCustomAttributes";

// REVIEW: Datatable cannot currently render arrays or bools, requires
// additional common component work. This should be closer to the custom
// attribute domain model.
export const DATATABLE_UNSUPPORTED_ATTR_TYPES = [
  "multi-pick-list",
  "number-array",
  "geo_point",
  "boolean",
  "lookup",
];

export const useCustomAttributeFilters = <ItemType,>({
  assetType,
  section = "Filters",
  prefix = "custom_attributes",
  enabled = true,
  sortable = false,
}: {
  assetType: AssetTypeType;
  section?: string;
  prefix?: string;
  enabled?: boolean;
  sortable?: boolean;
}) => {
  const { data, isLoading } = useListCustomAttributes(
    { filters: { entity_type: assetType } },
    { enabled }
  );
  const customAttributes = data?.data ?? [];

  const getAssetLink = (value: any) => {
    switch (value?.entity_type) {
      case AssetType.FACILITY:
        return (
          <RoutingLink to={linkToFacilities(value?.id)}>
            {value?.name}
          </RoutingLink>
        );
      case AssetType.EQUIPMENT:
        return (
          <RoutingLink to={linkToEquipmentDetail(value?.id)}>
            {value?.name}
          </RoutingLink>
        );
      case AssetType.FLOW:
        return (
          <RoutingLink
            to={FlowDetailRoute.toLink({
              pathParams: { flowId: value?.id },
            })}
          >
            {value?.name}
          </RoutingLink>
        );
      case AssetType.DEVICE:
        return (
          <RoutingLink to={linkToDeviceDetail(value?.id)}>
            {value?.name}
          </RoutingLink>
        );
      case AssetType.ASSET_GROUP:
        return (
          <RoutingLink
            to={NetworkDetailRoute.toLink({
              pathParams: { networkId: value?.id },
            })}
          >
            {value?.name}
          </RoutingLink>
        );

      default:
        return "-";
    }
  };

  const customAttributeHeaders: Array<HeaderType<ItemType>> = useMemo(() => {
    return customAttributes.map(
      ({ display_name, field_name, data_type, entity_subtype }) => ({
        label: display_name,
        key: `${prefix}.${field_name}`,
        isSelected: !entity_subtype ? true : false,
        isSortable: sortable,
        field_name,
        minWidth: 180,
        renderComponent: ({ value }) =>
          data_type == "lookup"
            ? getAssetLink(value)
            : DATATABLE_UNSUPPORTED_ATTR_TYPES.includes(data_type)
              ? displayValue(value)
              : value ?? "-",
      })
    );
  }, [customAttributes]);

  const customAttributeFilters = useMemo(() => {
    return customAttributes.map((attribute) => {
      const name = prefix
        ? `${prefix}.${attribute.field_name}`
        : attribute.field_name;

      return {
        section,
        component: (
          <CustomAttributeField
            key={name}
            name={name}
            attribute={{ ...attribute, is_required: false }}
            isDisabled={false}
            isOptionalTextShown={false}
            isFilterForm
          />
        ),
      };
    });
  }, [customAttributes, prefix]);

  return {
    customAttributes,
    customAttributeHeaders,
    customAttributeFilters,
    isLoading: enabled && isLoading,
  };
};
