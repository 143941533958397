import { useState, useMemo } from "react";
import { GetJSData } from "#utils/immutableConverter";
import useRoQSelection from "./useRoQSelection";
import useManageResultFetches from "./useManageResultFetches";
import useManageSelectionFetches from "./useManageSelectionFetches";
import useManageSubmission from "./useManageSubmission";

function getAvailableStreams(streams, accountingRecordSelectedStream) {
  if (streams.length > 0) {
    streams = streams.sort((a, b) => {
      if (accountingRecordSelectedStream === a.id) {
        return -1;
      }
      if (accountingRecordSelectedStream === b.id) {
        return 1;
      }

      return a.name.localeCompare(b.name);
    });

    streams[0].name = `(Current) ${streams[0].name}`;

    return streams;
  }

  return [];
}

/**
 * The logic behind the modal has been split into four parts.
 *
 * useRoQSelection maintains the state of the current roqSelection and gives
 * out functions to change that state.
 *
 * useManageResultFetches fetches the non-interactable data (results) and
 * initializes the above roqSelectionState with contents of data fetched
 *
 * useManageSelectionFetches fetches the interactable data (selection data)
 * and changes roqSelectionState via two ways: the contents of data fetched and
 * user interaction (e.g. sample selection, method selection). There is local
 * state handling the fetch parameters in useManageSelectionFetchParams.
 *
 * useManageSubmission handles interaction with backend whenever the above
 * roqSelection requires a reset/save/approve action
 */
const useManageSelectionModal = ({
  accountingRecordDetail,
  accountingPeriod,
  displayProperties,
  hasWriteAccess,
  streams,
  addAlertMessage,
  refreshAccountingPeriod,
  isModalVisible,
}) => {
  const [shouldRefreshAccountingPeriod, setShouldRefreshAccountingPeriod] =
    useState(false);
  const [updateModalData, setUpdateModalData] = useState(new Date());

  const refetchModalData = () => {
    setUpdateModalData(new Date());
  };

  const onModalClose = () => {
    if (shouldRefreshAccountingPeriod) {
      refreshAccountingPeriod();

      setShouldRefreshAccountingPeriod(false);
    }
  };

  const [
    roqSelectionState,
    {
      setSelectionState,
      setStream,
      setMethod,
      toggleSample,
      updateIntervalSelections,
      deleteIntervalSelection,
      setPreselectedValues,
      setDefaultIntervalSelections,
      setIsInitialized,
    },
  ] = useRoQSelection(hasWriteAccess, isModalVisible);

  const accountingPeriodStreams = useMemo(() => {
    return getAvailableStreams(
      GetJSData(streams),
      accountingRecordDetail?.stream?.id
    );
  }, [streams, accountingRecordDetail?.id]);

  const [
    recordOfQualityDetail,
    systemBalance,
    historicalData,
    resultLoadingState,
    originalRecordOfQualityDetail,
    originalSystemBalance,
  ] = useManageResultFetches(
    accountingRecordDetail,
    accountingPeriod,
    setSelectionState,
    updateModalData,
    isModalVisible,
    roqSelectionState
  );

  const [
    sampleData,
    sampleDataLoadingState,
    selectionData,
    selectionDataLoadingState,
    onMeasurementSelect,
    onDateSelect,
    onStreamSelect,
    onSampleClick,
    measurement,
    from,
    until,
    stream,
    setDefaultDateRange,
    accountingPeriodEndDate,
  ] = useManageSelectionFetches(
    displayProperties,
    accountingPeriod,
    accountingRecordDetail,
    recordOfQualityDetail,
    roqSelectionState,
    accountingPeriodStreams,
    updateModalData,
    setStream,
    toggleSample,
    setPreselectedValues,
    setDefaultIntervalSelections
  );

  const [approveSelection, resetSelection, actionState, setActionState] =
    useManageSubmission(
      roqSelectionState,
      recordOfQualityDetail,
      addAlertMessage,
      refetchModalData,
      setShouldRefreshAccountingPeriod,
      isModalVisible,
      setIsInitialized
    );

  return {
    roqSelectionState,
    setSelectionState,
    setStream,
    setMethod,
    toggleSample,
    updateIntervalSelections,
    deleteIntervalSelection,
    recordOfQualityDetail,
    systemBalance,
    historicalData,
    resultLoadingState,
    originalRecordOfQualityDetail,
    originalSystemBalance,
    sampleData,
    sampleDataLoadingState,
    selectionData,
    selectionDataLoadingState,
    onMeasurementSelect,
    onDateSelect,
    onStreamSelect,
    onSampleClick,
    measurement,
    from,
    until,
    stream,
    setDefaultDateRange,
    accountingPeriodEndDate,
    streams: accountingPeriodStreams,
    displayProperties,
    accountingPeriod,
    accountingRecordDetail,
    hasWriteAccess,
    approveSelection,
    resetSelection,
    actionState,
    setActionState,
    onModalClose,
  };
};

export default useManageSelectionModal;
