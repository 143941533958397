import React, { createContext, useEffect, useState } from "react";

import { EventCategoryDomain } from "@validereinc/domain";

import type { EventCategoryType } from "@validereinc/domain";

export const EventCategoryContext = createContext<{
  eventCategory: EventCategoryType | undefined;
  isLoading: boolean;
} | null>(null);

export const EventCategoryProvider = ({
  eventCategoryId,
  children,
}: {
  eventCategoryId: string;
  children: React.ReactElement | React.ReactElement[];
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [eventCategory, setEventCategory] = useState<EventCategoryType>();

  const fetchEventCategory = async () => {
    try {
      setIsLoading(true);

      const newEventCategory = await EventCategoryDomain.getOne({
        eventCategoryId,
      });

      setEventCategory(newEventCategory);
    } catch (caught) {
      console.error(caught);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (eventCategoryId) {
      fetchEventCategory();
    }
  }, [eventCategoryId]);

  return (
    <EventCategoryContext.Provider
      value={{
        eventCategory,
        isLoading,
      }}
    >
      {children}
    </EventCategoryContext.Provider>
  );
};
