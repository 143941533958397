import { AssertIsAfterOrEqualDate } from "#utils/assert";
import {
  Button,
  DateInput,
  Form,
  FormButton,
} from "@validereinc/common-components";
import * as PropTypes from "prop-types";
import React from "react";

export const EditCalibrationForm = ({
  onSubmit,
  formMethods,
  isLoading,
  onHide,
  getNextCheckDateFromLast,
}) => (
  <Form
    className="editCalibrationForm"
    onSubmit={onSubmit}
    {...formMethods}
  >
    <DateInput
      disabled={isLoading}
      name="lastChecked"
      label="Last Checked Date"
      onChange={(e) =>
        formMethods.setValue("nextCheck", getNextCheckDateFromLast(e))
      }
      showIcon
      isRequired
    />
    <DateInput
      name="nextCheck"
      label="Next Check Date"
      dayPickerProps={{ disabledDays: { before: new Date() } }}
      validate={{
        laterThanToday: (date) => {
          return (
            (!!date && AssertIsAfterOrEqualDate(date, new Date(), "date")) ||
            "Date must be later than today"
          );
        },
      }}
      disabled={isLoading}
      isRequired
      showIcon
    />
    <div className="clearfix">
      <Button onClick={onHide}>Cancel</Button>

      <FormButton
        variant="primary"
        type="submit"
        className="pull-right"
        isLoading={isLoading}
      >
        Update Calibration
      </FormButton>
    </div>
  </Form>
);

EditCalibrationForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  formMethods: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  getNextCheckDateFromLast: PropTypes.func.isRequired,
};

export default EditCalibrationForm;
