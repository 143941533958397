import { ResourceServiceType } from "../util";
import { restAPI } from "./api";
import {
  TemplatedConfigurationRunRequestType,
  TemplatedConfigurationRunResponseType,
} from "../schemas/TemplatedConfigurationRunSchemas";

type TemplatedConfigurationRunServiceType = Pick<
  ResourceServiceType<TemplatedConfigurationRunResponseType>,
  "createOne"
>;

export const TemplatedConfigurationRunAdapter: TemplatedConfigurationRunServiceType =
  {
    createOne: ({
      data: { templated_configuration_name, ...body },
    }: {
      data: TemplatedConfigurationRunRequestType & {
        templated_configuration_name: string;
      };
    }) =>
      restAPI.nodeAPI
        .POST<TemplatedConfigurationRunResponseType>({
          endpoint: `/templated_configurations/${templated_configuration_name}/runs`,
          body,
        })
        .then((resp) => ({ data: resp })),
  };
