export function getSourcesWithMultiplier(sources, multiplier) {
  if (multiplier && multiplier !== "") {
    const newSourceList = sources.map((source) => {
      const newSource = { ...source };
      newSource.multiplier = multiplier;

      return newSource;
    });

    return newSourceList;
  }

  return sources;
}
