import { linkToDeviceDetail } from "#routers/links";
import { linkToEquipmentDetail } from "#routes/organization/equipment/[equipmentId]";
import { linkToEstimationMethodDetail } from "#routes/organization/equipment/[equipmentId]/estimation-method/[methodId]/detail";
import { linkToEstimationMethodCalculationResult } from "#routes/organization/equipment/[equipmentId]/estimation-method/[methodId]/result/[resultId]/detail";
import { linkToEquipmentRecordDetail } from "#routes/organization/equipment/[equipmentId]/record-details/[recordId]";
import { linkToEquipmentRecordValueDetail } from "#routes/organization/equipment/[equipmentId]/record-details/[recordId]/value/[measurementType]";
import { linkToAddEquipmentRecordValueSource } from "#routes/organization/equipment/[equipmentId]/record-details/[recordId]/value/[measurementType]/add-source";
import { linkToFacilityDetail } from "#routes/organization/facilities/[facilityId]";
import { linkToFacilityRecordDetail } from "#routes/organization/facilities/[facilityId]/record/[recordId]/details";
import { linkToFacilityRecordValueDetail } from "#routes/organization/facilities/[facilityId]/record/[recordId]/value/[measurementType]";
import { linkToAddFacilityRecordValueSource } from "#routes/organization/facilities/[facilityId]/record/[recordId]/value/[measurementType]/add-source";
import { FlowDetailRoute } from "#routes/organization/flows/[flowId]/detail";
import { FlowEstimationMethodDetailRoute } from "#routes/organization/flows/[flowId]/detail/estimation-details/[estimationMethodId]";
import { FlowEstimationMethodResultDetailRoute } from "#routes/organization/flows/[flowId]/detail/estimation-details/[estimationMethodId]/result/[yearMonth]";
import { FlowRecordDetailRoute } from "#routes/organization/flows/[flowId]/detail/record/[recordId]";
import { FlowRecordValueDetailRoute } from "#routes/organization/flows/[flowId]/detail/record/[recordId]/value/[measurementType]";
import { FlowRecordValueSourceRoute } from "#routes/organization/flows/[flowId]/detail/record/[recordId]/value/[measurementType]/source";
import { NetworkDetailRoute } from "#routes/organization/networks/[networkId]/detail";
import { NetworkCalculationResultRoute } from "#src/routes/organization/networks/[networkId]/detail/calculation-result/[period]/details";
import { AssetType, AssetTypeType } from "@validereinc/domain";

export const linkToAssetDetailPage = (
  assetType: AssetTypeType,
  assetId: string
) => {
  switch (assetType) {
    case AssetType.FACILITY:
      return linkToFacilityDetail(assetId);
    case AssetType.EQUIPMENT:
      return linkToEquipmentDetail(assetId);
    case AssetType.DEVICE:
      return linkToDeviceDetail(assetId);
    case AssetType.FLOW:
      return FlowDetailRoute.toLink({ pathParams: { flowId: assetId } });
    case AssetType.ASSET_GROUP:
      return NetworkDetailRoute.toLink({ pathParams: { networkId: assetId } });
    default:
      console.error(
        `No asset detail page link configured for assetType=${assetType}`
      );
      return "";
  }
};
export const linkToCalculationDetailPage = (
  assetType: AssetTypeType,
  assetId: string,
  estimationMethodId: string
) => {
  switch (assetType) {
    case AssetType.EQUIPMENT:
      return linkToEstimationMethodDetail(estimationMethodId, assetId);
    case AssetType.FLOW:
      return FlowEstimationMethodDetailRoute.toLink({
        pathParams: { flowId: assetId, estimationMethodId },
      });
    case AssetType.ASSET_GROUP:
      // TODO: Need to include query parameter for "calculations" tab
      return NetworkDetailRoute.toLink({ pathParams: { networkId: assetId } });
    default:
      return "";
  }
};

export const linkToCalculationResultDetailPage = (
  assetType: AssetTypeType,
  assetId: string,
  estimationMethodId: string,
  yearMonth: string
) => {
  switch (assetType) {
    case AssetType.ASSET_GROUP:
      return NetworkCalculationResultRoute.toLink({
        pathParams: {
          networkId: assetId,
          period: yearMonth,
        },
      });
    case AssetType.EQUIPMENT:
      return linkToEstimationMethodCalculationResult(
        estimationMethodId,
        assetId,
        yearMonth
      );
    case AssetType.FLOW:
      return FlowEstimationMethodResultDetailRoute.toLink({
        pathParams: {
          flowId: assetId,
          estimationMethodId,
          yearMonth,
        },
      });
    default:
      return "";
  }
};

export const linkToEstimationMethodDetailPage = (
  assetType: AssetTypeType,
  assetId: string,
  estimationMethodId: string
) => {
  switch (assetType) {
    case AssetType.EQUIPMENT:
      return linkToEstimationMethodDetail(estimationMethodId, assetId);
    case AssetType.FLOW:
      return FlowEstimationMethodDetailRoute.toLink({
        pathParams: { flowId: assetId, estimationMethodId },
      });
    default:
      console.error(
        `No estimation method detail page link configured for assetType=${assetType}`
      );
      return "";
  }
};

export const linkToRecordDetail = (
  assetType: AssetTypeType,
  assetId: string,
  recordId: string
) => {
  let link;
  switch (assetType) {
    case AssetType.FACILITY:
      link = linkToFacilityRecordDetail;
      break;
    case AssetType.EQUIPMENT:
      link = linkToEquipmentRecordDetail;
      break;
    case AssetType.FLOW:
    default:
      link = (flowId: string, recordId: string) =>
        FlowRecordDetailRoute.toLink({
          pathParams: {
            flowId,
            recordId,
          },
        });
  }
  return link(assetId, recordId);
};
export const linkToRecordValueDetail = (
  assetType: AssetTypeType,
  assetId: string,
  recordId: string,
  measurementType: string
) => {
  let link;
  switch (assetType) {
    case AssetType.FACILITY:
      link = linkToFacilityRecordValueDetail;
      break;
    case AssetType.EQUIPMENT:
      link = linkToEquipmentRecordValueDetail;
      break;
    case AssetType.FLOW:
    default:
      link = (flowId: string, recordId: string, measurementType: string) =>
        FlowRecordValueDetailRoute.toLink({
          pathParams: {
            flowId,
            recordId,
            measurementType,
          },
        });
  }
  return link(assetId, recordId, measurementType);
};

export const linkToRecordValueSourceDetail = (
  assetType: AssetTypeType,
  assetId?: string,
  recordId?: string,
  measurementType?: string
): string => {
  let urlFunction: (
    assetId: string,
    recordId?: string,
    measurementType?: string
  ) => string;
  switch (assetType) {
    case AssetType.FACILITY:
      urlFunction = linkToAddFacilityRecordValueSource;
      break;
    case AssetType.EQUIPMENT:
      urlFunction = linkToAddEquipmentRecordValueSource;
      break;
    case AssetType.FLOW:
    default:
      urlFunction = (flowId, recordId, measurementType) =>
        FlowRecordValueSourceRoute.toLink({
          pathParams: {
            flowId,
            recordId,
            measurementType,
          },
        });
  }
  return urlFunction(assetId ?? "", recordId, measurementType);
};
