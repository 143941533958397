import { ensureStreamListIsFetched, fetchSiteList } from "#redux/actions/index";
import { getBreadcrumbsObject } from "#src/Routers/breadcrumbsHelper";
import { SAMPLE_TYPES } from "#utils/enums";
import { Page, Panel } from "@validereinc/common-components";
import * as PropTypes from "prop-types";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getManualEntryType } from "../../ManualEntryHelper";
import ComponentAnalysis from "./ComponentAnalysis";

const mapStateToProps = (state) => {
  return {
    sites: state.sites.data.toJS(),
    streams: state.streams.data?.toJS() ?? [],
  };
};

const mapDispatchToProps = {
  fetchSiteList,
  ensureStreamListIsFetched,
};

const ComponentAnalysisPage = (props) => {
  useEffect(() => {
    props.fetchSiteList();
    props.ensureStreamListIsFetched();
  }, []);

  const sampleTypes = Object.values(SAMPLE_TYPES);

  const sourceLabs = props.profile.enabled_chain_of_custody_types;

  const isLoaded = props.sites && props.streams && sampleTypes && sourceLabs;

  const breadcrumbs = getBreadcrumbsObject(
    props.breadcrumbs,
    props.match.params
  );

  return (
    <Page
      title={getManualEntryType("component-analysis")?.title}
      isLoading={!isLoaded}
      breadcrumbs={breadcrumbs}
    >
      <Panel className="manualEntry">
        <ComponentAnalysis
          sites={props.sites}
          streams={props.streams}
          sampleTypes={sampleTypes}
          sourceLabs={sourceLabs}
        />
      </Panel>
    </Page>
  );
};

ComponentAnalysisPage.propTypes = {
  sites: PropTypes.array.isRequired,
  streams: PropTypes.array.isRequired,
  match: PropTypes.object.isRequired,
  breadcrumbs: PropTypes.array,
  profile: PropTypes.object.isRequired,
  fetchSiteList: PropTypes.func.isRequired,
  ensureStreamListIsFetched: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ComponentAnalysisPage);
