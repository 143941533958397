import React from "react";
import * as PropTypes from "prop-types";
import Select from "react-select";

const selectStyles = {
  control: (base, { isFocused }) => ({
    ...base,
    borderColor: isFocused ? "#46c0c1" : "hsl(0,0%,80%)",
    boxShadow: isFocused ? "0 0 0 1px #46c0c1" : null,
    "&:hover": {
      borderColor: isFocused ? "#46c0c1" : "hsl(0,0%,80%)",
    },
  }),
  option: (base, { isFocused, isSelected }) => ({
    ...base,
    textTransform: "capitalize",
    backgroundColor: isFocused || isSelected ? "#46c0c1" : "#ffffff",
  }),
};

const FilterColumn = ({
  filter,
  onChange,
  options,
  placeholder,
  value,
  isClearable = true,
  ...props
}) => {
  const onFilterColumnChange = (value) => {
    onChange(value);
  };
  return (
    <Select
      isMulti
      hideSelectedOptions={false}
      value={value ? value : filter ? filter.value : []}
      onChange={onFilterColumnChange}
      options={options}
      closeMenuOnSelect={false}
      placeholder={placeholder ? placeholder : "Select..."}
      styles={selectStyles}
      isClearable={isClearable}
      {...props}
    />
  );
};

FilterColumn.propTypes = {
  filter: PropTypes.object,
  onChange: PropTypes.func,
  options: PropTypes.array,
  placeholder: PropTypes.string,
  value: PropTypes.array,
  isClearable: PropTypes.bool,
};

export default FilterColumn;
