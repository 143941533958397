import {
  FilterPillbox,
  MultiDropdownInputWithSearch,
} from "@validereinc/common-components";
import difference from "lodash/difference";
import differenceBy from "lodash/differenceBy";
import sortBy from "lodash/sortBy";
import React, { useEffect, useState } from "react";
import {
  clearAllInputs,
  filterSourceList,
  getAllFilterInputs,
  getUniqCategory,
  getUniqProductTypes,
  getUniqSites,
} from "./SourceTableHelper";

const DEFAULT_FILTER_DROPDOWN = {
  sites: {
    label: "site",
    inputs: [],
    options: [],
    labelKey: "name",
    paramKey: "id",
  },
  productTypes: {
    label: "product types",
    inputs: [],
    options: [],
  },
  category: {
    label: "category",
    inputs: [],
    options: [],
  },
};

const useFilterInputs = (sourceList) => {
  const [filterDropdowns, setFilterDropdown] = useState({
    ...DEFAULT_FILTER_DROPDOWN,
  });

  const onDropdownSelect = (selectedValue, key) => {
    const filterDropdown = { ...filterDropdowns[key] };
    filterDropdown.inputs = selectedValue;

    setFilterDropdown({ ...filterDropdowns, [key]: filterDropdown });
  };

  const onClearFilterClick = (filterObject) => {
    if (filterObject) {
      const { filterKey, name } = filterObject;
      const updatedFilterDropdown = { ...filterDropdowns[filterKey] };

      if (filterKey === "sites") {
        updatedFilterDropdown.inputs = differenceBy(
          updatedFilterDropdown.inputs,
          [filterObject],
          "id"
        );
      }

      if (filterKey === "productTypes" || filterKey === "category") {
        updatedFilterDropdown.inputs = difference(
          updatedFilterDropdown.inputs,
          [name]
        );
      }

      setFilterDropdown({
        ...filterDropdowns,
        [filterKey]: updatedFilterDropdown,
      });
    } else {
      setFilterDropdown(clearAllInputs(filterDropdowns));
    }
  };

  useEffect(() => {
    if (sourceList.length > 0) {
      const sites = { ...filterDropdowns.sites };
      sites.options = sortBy(getUniqSites(sourceList), "name");

      const productTypes = { ...filterDropdowns.productTypes };
      productTypes.options = sortBy(getUniqProductTypes(sourceList));

      const category = { ...filterDropdowns.category };
      category.options = sortBy(getUniqCategory(sourceList));

      setFilterDropdown({
        ...filterDropdowns,
        sites,
        productTypes,
        category,
      });
    }
  }, [sourceList]);

  const filterRow = (
    <div className="filterRow">
      {Object.keys(filterDropdowns).map((key) => {
        const { label, labelKey, options, inputs } = filterDropdowns[key];

        return (
          <MultiDropdownInputWithSearch
            key={key}
            label={label}
            labelKey={labelKey ? labelKey : null}
            dropdownKey={key}
            value={inputs}
            options={options}
            onChange={onDropdownSelect}
          />
        );
      })}
    </div>
  );

  const filterInputs = getAllFilterInputs(filterDropdowns);

  const filteredSources = filterSourceList(sourceList, filterInputs);

  const filterPillbox = (
    <FilterPillbox
      filterBy={filterInputs}
      onClearFilterClick={onClearFilterClick}
    />
  );

  return [
    filterRow,
    filterPillbox,
    filteredSources,
    onDropdownSelect,
    onClearFilterClick,
  ];
};

export default useFilterInputs;
