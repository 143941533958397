import React from "react";

import {
  Button,
  EmptyState,
  Icon,
  MetricTileGrid,
  Panel,
} from "@validereinc/common-components";

import { DeviceType } from "@validereinc/domain";

export const SelectedDevicesPanel = ({
  selectedDevices,
  onDeselectDevice,
}: {
  selectedDevices: DeviceType[];
  onDeselectDevice: (id: string) => void;
}) => {
  return (
    <Panel
      title="Selected Devices"
      isFluidY={false}
    >
      {selectedDevices?.length ? (
        <MetricTileGrid
          size="small"
          orientation="vertical"
          data={selectedDevices.map(({ id, name }) => ({
            title: "Name",
            value: name,
            actions: [
              <Button
                key="remove-origin-button"
                onClick={() => onDeselectDevice(id)}
                variant="text"
                icon="minus-circle"
              />,
            ],
          }))}
        />
      ) : (
        <EmptyState
          title="No Devices Selected"
          icon={<Icon variant="stack" />}
          suggestion="Select devices to add to event."
          isFluid
        />
      )}
    </Panel>
  );
};
