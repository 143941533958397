export function getPropertiesValue(components, qualities, property, columnKey) {
  const componentVolumeProperties = components?.[columnKey]?.[property] ?? null;
  if (componentVolumeProperties) {
    return componentVolumeProperties;
  }

  const qualityMeasurementProperties =
    qualities?.[columnKey]?.[property] ?? null;

  if (qualityMeasurementProperties) {
    return qualityMeasurementProperties;
  }

  return {};
}
