import { useState, useEffect } from "react";
import { QUERY_STATUS } from "#constants";
import FacilityService from "#services/FacilityService";

import type { QueryStatusType } from "#constants";

export type EquipmentType = {
  id: string;
  name: string;
};

const useEquipmentTypes = (): [EquipmentType[], QueryStatusType] => {
  const [allEquipmentTypes, setAllEquipmentTypes] = useState<EquipmentType[]>(
    []
  );
  const [loadingState, setLoadingState] = useState<QueryStatusType>(
    QUERY_STATUS.LOADING
  );

  useEffect(() => {
    FacilityService.getEquipmentTypes()
      .then(({ data }) => {
        setAllEquipmentTypes(data);

        setLoadingState(QUERY_STATUS.SUCCESS);
      })
      .catch(() => setLoadingState(QUERY_STATUS.ERROR));
  }, []);

  return [allEquipmentTypes, loadingState];
};

export default useEquipmentTypes;
