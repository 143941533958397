import { ActivityLogsList } from "#src/batteries-included-components/Layouts/ActivityLogs/ActivityLogsList";
import { AssetType } from "@validereinc/domain";
import React from "react";

export const ChangeLogTab = ({ resourceId }: { resourceId: string }) => {
  return (
    <ActivityLogsList
      resourceId={resourceId}
      resourceType={{ name: AssetType.DEVICE }}
    />
  );
};
