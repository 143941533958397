import { ReportingGroupFilterSwitcher } from "#src/batteries-included-components/Filters/ReportingGroupFilterSwitcher";
import { useMeasurementTypes } from "#src/contexts/MeasurementTypeContext";
import { DEFAULT_DATE_RANGES } from "#src/hooks/useDateRange";
import { useQuery } from "@tanstack/react-query";
import {
  ContentSwitcher,
  DateSelectorInput,
  DropdownInput,
  FilterPanel,
  StorageKeys,
  useFilters,
} from "@validereinc/common-components";
import { RecordFilterType, ReportingGroupDomain } from "@validereinc/domain";
import React from "react";

export const FILTER_CONFIG = {
  dateRange: {
    name: "date_range",
  },
  reportingScenario: {
    name: "reporting_group_id",
  },
  recordMode: {
    name: "record_mode",
  },
} as const;

export const RecordsFilterPanel = ({
  isDateRange,
  filterConfigStorageKey,
  selectableProperties,
}: {
  isDateRange: boolean;
  selectableProperties?: string[];
} & Pick<StorageKeys, "filterConfigStorageKey">) => {
  const defaultDate = isDateRange
    ? DEFAULT_DATE_RANGES.currentWholeYear
    : DEFAULT_DATE_RANGES.lastWholeMonth;

  const { data: reportingGroups, isLoading } = useQuery(
    ["reportingGroups"],
    () => ReportingGroupDomain.getList(),
    {
      select: (response) => response?.data ?? [],
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );

  const [filters] = useFilters<
    {
      record_mode: "records" | "automation";
    } & RecordFilterType
  >(filterConfigStorageKey);

  const defaultValue = {
    date_range: defaultDate,
    reporting_group_id: reportingGroups?.[0]?.id,
  };

  const recordMode = filters.record_mode ?? "records";

  const { getTypeName } = useMeasurementTypes();

  const propertySelectorValues = selectableProperties?.map((key) => ({
    key,
    name: getTypeName(key),
  }));

  return (
    <FilterPanel
      isLoading={isLoading}
      storageKey={filterConfigStorageKey}
      defaultValue={defaultValue}
      filters={[
        {
          component: (
            <ReportingGroupFilterSwitcher
              key={FILTER_CONFIG.reportingScenario.name}
              name={FILTER_CONFIG.reportingScenario.name}
              options={reportingGroups}
              shouldSelectFirstOptionAsDefault
            />
          ),
        },
        {
          component: (
            <ContentSwitcher
              name={FILTER_CONFIG.recordMode.name}
              key={FILTER_CONFIG.recordMode.name}
              activeKey={recordMode}
              items={[
                { label: "Records", dataKey: "records" },
                { label: "Default Automations", dataKey: "automation" },
              ]}
            />
          ),
        },
        ...(recordMode === "records"
          ? [
              {
                component: (
                  <DateSelectorInput
                    key={FILTER_CONFIG.dateRange.name}
                    name={FILTER_CONFIG.dateRange.name}
                    variant="month"
                    isRange={isDateRange}
                    isInline
                  />
                ),
              },
              {
                component: (
                  <DropdownInput
                    key="measurement_type"
                    name="measurement_type"
                    options={propertySelectorValues}
                    placeholder="Select Property..."
                    labelKey="name"
                    valueKey="key"
                    isSearchable
                    isInline
                    isOptionalTextShown={false}
                  />
                ),
              },
            ]
          : []),
      ]}
    />
  );
};
