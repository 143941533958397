import { toSnakeCaseString } from "@validereinc/utilities";

/**
 * Convert an object with camel-cased keys to snake-cased keys
 * @param oldObject a single-level deep object with camel-cased keys
 * @returns exact same object with snake-cased keys
 */
export const camelToSnakeKeys = <T>(oldObject: T) => {
  const newObject: Record<string, any> = {};

  for (const camelCasedKey in oldObject) {
    newObject[toSnakeCaseString(camelCasedKey)] = oldObject[camelCasedKey];
  }

  return newObject;
};
