import {
  AlertMessage,
  LegacyDataTable,
  Panel,
} from "@validereinc/common-components";
import * as PropTypes from "prop-types";
import React from "react";
import { AutoSizer } from "react-virtualized";
import FieldManualEntryModal from "../ManualEntry/ManualEntryForms/Field/FieldManualEntryModal";
import useManualEntryModal from "../ManualEntry/ManualEntryForms/useManualEntryModal";
import "./InstrumentTests.scss";

const HEIGHT_OFFSET = 121; // leaving 15px space clearance

const InstrumentTests = (props) => {
  const [show, showModal, onHide] = useManualEntryModal();

  // CTR not allowed to go make manual field test
  const isWaterInstrument = props.instrumentInfo?.type === "water";

  return (
    <Panel
      className="instrumentTests__container"
      style={{ height: props.height - HEIGHT_OFFSET }}
      title="Samples"
    >
      <AlertMessage
        type="info"
        style={{ marginBottom: "15px" }}
      >
        Note: if you are&nbsp;
        <b>not</b>&nbsp;able find your sample try&nbsp;{" "}
        <b>selecting an earlier date range</b>
      </AlertMessage>

      <div className="instrumentTests">
        <AutoSizer>
          {({ height, width }) => (
            <LegacyDataTable
              headers={props.headers}
              list={props.tests}
              width={width}
              height={height}
              filterKey={props.filterKey}
              filterRow={props.filterRow}
              highlightRow={true}
              onCellClick={props.openTestDetail}
              addButtonName="Field Test"
              onAddClick={!isWaterInstrument ? () => showModal() : null}
              defaultSortBy={props.defaultSortBy}
              defaultSortDirection={props.defaultSortDirection}
              isLoading={props.isLoading}
            />
          )}
        </AutoSizer>
      </div>

      {show && (
        <FieldManualEntryModal
          show={true}
          onHide={onHide}
          instrumentInfo={props.instrumentInfo}
          refetchData={props.refetchData}
        />
      )}
    </Panel>
  );
};

InstrumentTests.propTypes = {
  tests: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  height: PropTypes.number.isRequired,
  headers: PropTypes.array.isRequired,
  filterKey: PropTypes.string.isRequired,
  openTestDetail: PropTypes.func.isRequired,
  defaultSortBy: PropTypes.string,
  defaultSortDirection: PropTypes.string,
  refetchData: PropTypes.func,
  haveWritePermission: PropTypes.func,
  instrumentInfo: PropTypes.object.isRequired,
  filterRow: PropTypes.object.isRequired,
};

export default InstrumentTests;
