import Immutable from "immutable";

import {
  FETCH_COCS,
  FETCH_COCS_ERROR,
  USER_SIGNED_OUT,
} from "../constants/action-types";

const chainOfCustody = Immutable.Record({
  id: undefined,
  name: undefined,
  state: undefined,
  site: undefined,
  received_at: undefined,
  issued_at: undefined,
  user: undefined,
  type: undefined,
  code: undefined,
  samples: undefined,
});

export const chainOfCustodyState = Immutable.Record(
  {
    fetchError: false,
    fetchErrorMessage: "",
    fetchErrorStatus: "",
    chainOfCustody: new Immutable.Set([]),
  },
  "chainOfCustodyState"
);

export default (state = new chainOfCustodyState(), action) => {
  let newData = new Immutable.Set([]);
  switch (action.type) {
    case FETCH_COCS:
      action.payload.forEach(function (row) {
        newData = newData.add(new chainOfCustody(row));
      });

      return state.withMutations((s) => {
        s.chainOfCustody = newData;
        s.fetchError = false;
        s.fetchErrorMessage = "";
        s.fetchErrorStatus = "";
      });

    case FETCH_COCS_ERROR:
      return state.withMutations((s) => {
        s.fetchError = true;
        s.fetchErrorMessage = action.payload.message;
        s.fetchErrorStatus = action.payload.status;
      });

    case USER_SIGNED_OUT:
      return state.withMutations((s) => {
        s.chainOfCustody = new Immutable.Set([]);
        s.samples = new Immutable.Set([]);
        s.fetchError = false;
        s.fetchErrorMessage = "";
        s.fetchErrorStatus = "";
      });

    default:
      return state;
  }
};
