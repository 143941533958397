import {
  Accordion,
  Button,
  DropdownInput,
  Panel,
  useFormContext,
} from "@validereinc/common-components";
import get from "lodash/get";
import React from "react";
import { ComparativeCondition } from "./ComparativeCondition";
import { ConditionValueField } from "./ConditionValueField";

const { AccordionPanel } = Accordion;

export const QuestionCondition = ({
  index,
  questions,
  onRemoveCondition,
  isRemovable,
}) => {
  const form = useFormContext();

  const condition = form.watch(`conditions.${index}`);

  const question = questions?.find(
    ({ question_id }) => condition.question === question_id
  );

  return (
    <Panel>
      <Accordion defaultActiveKeys={["condition"]}>
        <AccordionPanel
          title={`Condition ${index + 1}`}
          dataKey="condition"
          actionRow={
            isRemovable
              ? [
                  <Button
                    key="remove-condition"
                    icon="trash"
                    onClick={() => onRemoveCondition(index)}
                  />,
                ]
              : undefined
          }
          isError={get(form.formState.errors, `conditions.${index}`)}
        >
          <>
            <DropdownInput
              label="Question"
              name={`conditions.${index}.question`}
              options={questions}
              labelKey="prompt"
              valueKey="question_id"
              isFluid
              isRequired
            />

            {question ? (
              <DropdownInput
                label="Value Type"
                name={`conditions.${index}.type`}
                options={[
                  { label: "Any Value", value: "$exists" },
                  ...([
                    "string",
                    "number",
                    "integer",
                    "boolean",
                    "date",
                    "date-time",
                    "pick-list",
                    "multi-pick-list",
                    "lookup",
                  ].includes(question.data_type)
                    ? [{ label: "Exact Value", value: "$eq" }]
                    : []),
                  ...(question?.type === "measurement" ||
                  ["number", "integer", "date", "date-time"].includes(
                    question?.data_type
                  )
                    ? [
                        {
                          label: "Comparative Value",
                          value: "comparative",
                        },
                      ]
                    : []),
                  ...([
                    "string",
                    "pick-list",
                    "multi-pick-list",
                    "lookup",
                  ].includes(question?.data_type)
                    ? [{ label: "Partial Value", value: "partial" }]
                    : []),
                ]}
                labelKey="label"
                valueKey="value"
                isFluid
                isRequired
                onChange={(newValue) => {
                  if (["$exists", "$eq"].includes(newValue)) {
                    form.setValue(`conditions.${index}.operation`, newValue);
                  }

                  if (newValue === "partial") {
                    if (question?.data_type === "string") {
                      form.setValue(`conditions.${index}.operation`, "$like");
                    } else {
                      form.setValue(`conditions.${index}.operation`, "$in");
                    }
                  }
                }}
              />
            ) : null}

            {["$eq", "partial"].includes(condition?.type) ? (
              <ConditionValueField
                name={`conditions.${index}.comparator`}
                question={question}
                condition={condition}
              />
            ) : null}

            {condition?.type === "comparative" ? (
              <ComparativeCondition
                name={`conditions.${index}`}
                question={question}
              />
            ) : null}
          </>
        </AccordionPanel>
      </Accordion>
    </Panel>
  );
};
