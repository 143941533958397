import React from "react";
import * as PropTypes from "prop-types";
import {
  getProgressBarWidth,
  getProgressColor,
} from "../../../../utils/styleCalculator";

const ProgressBar = (props) => {
  return (
    <div
      style={{
        width: "100%",
        height: "5px",
        backgroundColor: "#dadada",
        borderRadius: "2px",
        display: "inline-block",
      }}
    >
      <div
        style={{
          width: getProgressBarWidth(
            props.value || 0,
            props.defaultAlertSpec.lower_limit,
            props.defaultAlertSpec.upper_limit
          ),
          height: "100%",
          backgroundColor: getProgressColor(
            props.value || 0,
            props.alertSpec.lower_limit,
            props.alertSpec.upper_limit
          ),
          borderRadius: "2px",
          transition: "all .2s ease-out",
        }}
      />
    </div>
  );
};

ProgressBar.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  alertSpec: PropTypes.object.isRequired,
  defaultAlertSpec: PropTypes.object.isRequired,
};

export default ProgressBar;
