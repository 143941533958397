import {
  Button,
  Form,
  Modal,
  Title,
  useForm,
} from "@validereinc/common-components";
import classNames from "classnames/bind";
import * as PropTypes from "prop-types";
import React from "react";
import styles from "./SourcesModal.module.scss";
import SourcesModalForm from "./SourcesModalForm";

const cx = classNames.bind(styles);

const SourcesModal = ({
  onClose,
  onSubmit,
  sourceListType,
  action,
  sourceKey,
  inventories,
  streams,
  sourceCheckedList,
}) => {
  const form = useForm({
    defaultValues: {
      selectedValues: action === "edit" ? sourceCheckedList[sourceKey] : [],
      multiplier: 1,
    },
  });

  const handleSubmit = (formValues) => {
    onSubmit(formValues);
    onClose();
  };

  const handleClick = async (action) => {
    const isValid = await form.trigger();

    if (isValid) {
      onSubmit(form.getValues(), action);
      onClose();
    }
  };

  const onCheckboxClick = (list) => {
    form.setValue("selectedValues", list);
  };

  return (
    <Modal
      show={true}
      onClose={onClose}
      className={cx("modal")}
    >
      <Modal.Header>
        <Title className="capitalized">{`${sourceKey}s`}</Title>
      </Modal.Header>

      <Form
        onSubmit={handleSubmit}
        {...form}
      >
        <Modal.Body>
          <SourcesModalForm
            form={form}
            sourceListType={sourceListType}
            sourceKey={sourceKey}
            action={action}
            inventories={inventories}
            streams={streams}
            onCheckboxClick={onCheckboxClick}
            selectedValues={sourceCheckedList[sourceKey]}
          />
        </Modal.Body>

        <Modal.Footer>
          <Button
            className="pull-left"
            onClick={onClose}
          >
            Close
          </Button>

          {action === "add" && (
            <Button
              variant="primary"
              onClick={() => handleClick("add")}
            >
              Update {sourceKey}s
            </Button>
          )}

          {action === "edit" && (
            <>
              <Button
                variant="warning"
                className={cx("removeButton")}
                onClick={() => handleClick("delete")}
              >
                Remove Selected {sourceKey}s
              </Button>

              <Button
                variant="primary"
                onClick={() => handleClick("edit")}
              >
                Update {sourceKey}s
              </Button>
            </>
          )}
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

SourcesModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  defaultValues: PropTypes.object,
  sourceListType: PropTypes.string,
  action: PropTypes.string,
  sourceKey: PropTypes.string,
  inventories: PropTypes.array,
  streams: PropTypes.array,
  sourceCheckedList: PropTypes.array,
};

export default SourcesModal;
