import { useState, useEffect } from "react";
import InventoryService from "#services/InventoryService";
import filter from "lodash/filter";

export const useInventories = () => {
  const [inventories, setInventories] = useState([]);
  const [state, setState] = useState("loading");

  useEffect(() => {
    setState("loading");

    InventoryService.getInventories()
      .then(({ data }) => {
        // All valid inventory require active stream
        const validInventories = filter(
          data,
          (inventory) => !!inventory.active_stream_id
        );
        setInventories(validInventories);
        setState("loaded");
      })
      .catch(() => setState("error"));
  }, []);

  return [inventories, state];
};
