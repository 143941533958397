import { QueueUnique } from "#src/utils/queue";
import { useStickyState } from "#src/hooks/useStickyState";

/**
 * Hook to use a global unique value queue. The Queue is sticky - meaning it's persisted in local storage through useStickyState.
 * @param defaultValue the default values of the queue. Values are stored in a Set, so values must be uniquely identifiable.
 * @param key the unique key representing the queue
 * @param idGetter a function that gets a unique identifier for each value in the queue. Default function attempts to get the "id" property off each value assuming the values have such a property.
 * @returns a tuple: the queue and the queue setter. similar in concept to React's useState.
 */
export const useQueueUnique = <T>(
  defaultValue: T[],
  key: string,
  idGetter: (data: T) => string = (data: any) => data?.id
) =>
  useStickyState(
    new QueueUnique<T>(defaultValue ?? [], idGetter),
    key,
    (value) => {
      return new QueueUnique<T>(JSON.parse(value), idGetter);
    }
  );
