import {
  Accordion,
  Button,
  Form,
  Panel,
  useFieldArray,
  useForm,
} from "@validereinc/common-components";
import get from "lodash/get";
import React, { useEffect, useMemo } from "react";
import { v4 as uuid } from "uuid";
import { CreateFormTemplateMetricTiles } from "./CreateFormTemplateMetricTiles";
import { CreateFormTemplateSection } from "./CreateFormTemplateSection";

import {
  useMemoizedFormState,
  useMultiStepFormContext,
} from "#src/hooks/useMultiStepForm";
import { ReorderDirection, ReorderDirectionType } from "@validereinc/domain";
import { moveItem } from "#src/utils/moveItem";

const { AccordionPanel } = Accordion;

const SECTIONS_STEP_TITLE = "Sections & Questions";
const STEP_NUMBER = 2;

export const CreateFormTemplateSectionStep = () => {
  const { currentStep, updateStep } = useMultiStepFormContext();

  const getNewSection = () => ({
    description: "",
    id: uuid(),
    name: "",
    is_repeatable: false,
    questions: [],
  });

  const defaultSectionValue = useMemo(() => getNewSection(), []);

  const form = useForm({
    defaultValues: {
      config: {
        questions: {},
        sections: [defaultSectionValue],
      },
    },
  });

  const { fields, append, move, remove } = useFieldArray({
    control: form.control,
    name: "config.sections",
  });

  const sectionArray = form.watch("config.sections");

  const onAddSection = () => {
    append(getNewSection());
  };

  const onMoveSection = (
    indexToSwapFrom: number,
    direction: ReorderDirectionType
  ) => {
    moveItem(fields, move, indexToSwapFrom, direction);
  };

  const onRemoveSection = (index: number) => {
    remove(index);
  };

  const getFormState = useMemoizedFormState(form);

  useEffect(() => {
    updateStep(STEP_NUMBER, {
      getValues: () => form.getValues(),
      getFormState,
    });
  }, [getFormState]);

  return (
    <>
      {currentStep === STEP_NUMBER ? (
        <Form {...form}>
          <Panel title={SECTIONS_STEP_TITLE}>
            <CreateFormTemplateMetricTiles />

            <Panel>
              <Accordion defaultActiveKeys={[defaultSectionValue.id]}>
                {fields.map((field, index) => (
                  <AccordionPanel
                    key={field.id}
                    dataKey={field.id}
                    title={`Section ${index + 1}${
                      sectionArray[index]?.name
                        ? `: ${sectionArray[index]?.name}`
                        : ""
                    }`}
                    actionRow={[
                      ...(index !== 0
                        ? [
                            <Button
                              key={`move-section-up-${index}-button`}
                              icon="arrow-circle-up"
                              onClick={() =>
                                onMoveSection(index, ReorderDirection.UP)
                              }
                            />,
                          ]
                        : []),
                      ...(index !== fields.length - 1
                        ? [
                            <Button
                              key={`move-section-down-${index}-button`}
                              icon="arrow-circle-down"
                              onClick={() =>
                                onMoveSection(index, ReorderDirection.DOWN)
                              }
                            />,
                          ]
                        : []),
                      ...(fields?.length > 1
                        ? [
                            <Button
                              key={`remove-section-${index}-button`}
                              icon="trash"
                              variant="error-outline"
                              onClick={() => onRemoveSection(index)}
                            />,
                          ]
                        : []),
                    ]}
                    isError={get(
                      form.formState.errors,
                      `config.sections.${index}`
                    )}
                  >
                    <CreateFormTemplateSection index={index} />
                  </AccordionPanel>
                ))}
              </Accordion>
            </Panel>

            <Button onClick={onAddSection}>Add Section</Button>
          </Panel>
        </Form>
      ) : null}
    </>
  );
};
