import { useEffect, useState } from "react";
import ProductionService from "../Services/ProductionService";
import useQueryParams from "#routers/useQueryParams";

export function useProductionReports() {
  const [productionReports, setProductionReports] = useState([]);
  const [loadingState, setLoadingState] = useState("loading");

  useEffect(() => {
    ProductionService.getProductionReports()
      .then(({ data }) => {
        setProductionReports(data);
        setLoadingState("loaded");
      })
      .catch(() => setLoadingState("error"));
  }, []);

  return [productionReports, loadingState];
}

export function useProductionReportDetail(id, defaultDateRange) {
  const [productionReportDetail, setProductionReportDetail] = useState({});
  const [fetchState, setFetchState] = useState("loading");
  const [queryParams] = useQueryParams(defaultDateRange);

  useEffect(() => {
    ProductionService.getProductionReportDetail(
      id,
      queryParams.periodFrom,
      queryParams.periodTo
    )
      .then(({ data }) => {
        setProductionReportDetail(data);
        setFetchState("loaded");
      })
      .catch(() => {
        setFetchState("error");
      });
  }, [id, queryParams.periodFrom, queryParams.periodTo]);

  return [productionReportDetail, fetchState];
}

export function useProductionReportInfo(id) {
  const [productionReportInfo, setProductionReportInfo] = useState({});
  const [fetchState, setFetchState] = useState("loading");

  useEffect(() => {
    ProductionService.getProductionReportInfo(id)
      .then(({ data }) => {
        setProductionReportInfo(data);
        setFetchState("loaded");
      })
      .catch(() => {
        setFetchState("error");
      });
  }, [id]);

  return [productionReportInfo, fetchState];
}
