import {
  UserPermissionsTab,
  UserRolesTab,
  UserSummaryTab,
} from "#batteries-included-components/Tabs";
import { useNavigate, useParams } from "#routers/hooks";
import { useBreadcrumbs } from "#src/Routers/breadcrumbsHelper";
import { useSearchParams } from "#src/Routers/hooks";
import { AccessDeniedLayout } from "#src/batteries-included-components/Layouts/Authorization/AccessDenied";
import {
  useAuthenticatedContext,
  useIsFeatureAvailable,
  useIsOpsHubExperience,
} from "#src/contexts/AuthenticatedContext.helpers";
import { USERS_LIST_BREADCRUMB } from "#src/routes/settings/users";
import { linkToUserEditPage } from "#src/routes/settings/users/edit";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  Button,
  Dialog,
  Page,
  Tab,
  useAlert,
} from "@validereinc/common-components";
import { UserType, UsersAdapter } from "@validereinc/domain";
import React, { useContext, useState } from "react";
import { USER_DETAILS_BREADCRUMB } from ".";
import { SETTINGS_BREADCRUMB } from "../..";
import { UserDetailContext, UserDetailProvider } from "./UserDetailContext";

const DEFAULT_TAB_KEY = "profile";

const UserDetailPageContent = () => {
  const {
    claims: {
      user: { id: currentUserId },
    },
  } = useAuthenticatedContext();
  const { userDetails } = useContext(UserDetailContext) ?? {};
  const { addAlert } = useAlert();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const queryClient = useQueryClient();
  const updateUserStatusMutation = useMutation({
    mutationFn: ({
      id,
      status,
    }: Pick<UserType, "status" | "id"> & { previousData: UserType }) => {
      return UsersAdapter.updateOne({
        id: id,
        data: {
          status: status,
        },
      });
    },
    onSuccess: (updatedUser) => {
      queryClient.invalidateQueries({ queryKey: ["users"] });
      addAlert?.({
        variant: "success",
        message:
          updatedUser.data.status === "disabled"
            ? `Successfully disabled account for ${
                updatedUser.data.name ?? "User"
              }.`
            : `Successfully enabled account for ${
                updatedUser.data.name ?? "User"
              }`,
      });
    },
    onError: (_, mutationData) => {
      addAlert?.({
        variant: "error",
        message:
          mutationData.status === "disabled"
            ? `Failed to disable account for ${
                mutationData.previousData.name ?? "User"
              }.`
            : `Failed to enable account for ${
                mutationData.previousData.name ?? "User"
              }`,
      });
    },
    onMutate: () => {
      setIsModalOpen(false);
    },
  });

  const isThisUserCurrentUser = currentUserId === userDetails?.data?.id;

  const handleActiveTabKeyChange = (tabKey) => {
    setSearchParams({ tab: tabKey });
  };

  const onReset = () => {
    if (!userDetails?.data?.id) {
      return;
    }

    navigate({ pathname: linkToUserEditPage(userDetails.data?.id) });
  };

  const breadcrumbs = useBreadcrumbs(
    [SETTINGS_BREADCRUMB, USERS_LIST_BREADCRUMB, USER_DETAILS_BREADCRUMB],
    {
      2: userDetails?.data?.name,
    }
  );

  const actionRow = () => {
    if (userDetails?.data?.status !== "disabled") {
      return [
        <Button
          key="deactivate-user"
          variant="error-outline"
          onClick={() => setIsModalOpen(true)}
        >
          Deactivate
        </Button>,
        <Button
          key="edit-user"
          onClick={onReset}
        >
          Edit
        </Button>,
      ];
    }

    return [
      !isThisUserCurrentUser ? (
        <Button
          key="active-user"
          variant="primary"
          onClick={() => setIsModalOpen(true)}
        >
          Reactivate
        </Button>
      ) : null,
    ];
  };

  return (
    <>
      <Page
        title={`${userDetails?.data?.name}${
          isThisUserCurrentUser ? " (You)" : ""
        }`}
        category="User"
        breadcrumbs={breadcrumbs}
        activeTabKey={searchParams?.tab ?? DEFAULT_TAB_KEY}
        onActiveTabKeyChange={handleActiveTabKeyChange}
        isLoading={userDetails?.isLoading}
        actionRow={actionRow()}
      >
        <Tab
          tabKey="profile"
          title="Profile"
        >
          <UserSummaryTab />
        </Tab>
        <Tab
          tabKey="roles"
          title="Roles"
        >
          <UserRolesTab />
        </Tab>
        <Tab
          tabKey="permissions"
          title="Permissions"
        >
          <UserPermissionsTab />
        </Tab>
      </Page>

      {/* IMPROVE: can be a BIC */}
      <Dialog
        key="deactivate-user"
        title={
          currentUserId === userDetails?.data?.id
            ? `Deactivate your account?`
            : `Deactivate account for ${userDetails?.data?.name}?`
        }
        isOpen={isModalOpen && userDetails?.data?.status === "active"}
        onClose={() => setIsModalOpen(false)}
        actionRow={[
          <Button
            key="deactivate-dialog-action"
            variant="error"
            onClick={() => {
              if (!userDetails?.data) {
                return;
              }

              updateUserStatusMutation.mutate({
                id: userDetails.data.id,
                status: "disabled",
                previousData: userDetails.data,
              });
            }}
            disabled={Boolean(
              !userDetails?.data || updateUserStatusMutation.isLoading
            )}
          >
            Deactivate
          </Button>,
        ]}
      >
        {currentUserId === userDetails?.data?.id
          ? "Are you sure you want to deactivate your account? You will lose access to the app entirely. Only a system administrator can enable your account if you'd like access again."
          : "Are you sure you want to disable this user? Disabling this user will remove any level of access that has been assigned to them."}
      </Dialog>

      {/* IMPROVE: can be a BIC */}
      <Dialog
        key="enable-user"
        title={`Reactivate account for ${userDetails?.data?.name}?`}
        isOpen={isModalOpen && userDetails?.data?.status === "disabled"}
        onClose={() => setIsModalOpen(false)}
        actionRow={[
          <Button
            key="enable-dialog-action"
            variant="primary"
            onClick={() => {
              if (!userDetails?.data) {
                return;
              }

              updateUserStatusMutation.mutate({
                id: userDetails.data.id,
                status: "active",
                previousData: userDetails.data,
              });
            }}
            disabled={Boolean(
              !userDetails?.data || updateUserStatusMutation.isLoading
            )}
          >
            Reactivate
          </Button>,
        ]}
      >
        Are you sure you want to reactivate this user? Activating this user will
        enable any level of access that has been assigned.
      </Dialog>
    </>
  );
};

export const UserDetailPage = () => {
  const { userId } = useParams<{ userId: string }>();
  const {
    claims: {
      user: { id: currentUserId },
    },
  } = useAuthenticatedContext();
  const [isPageAvailable] = useIsFeatureAvailable({
    featureFlagQuery: "core:roles_permissions",
  });
  const [isOpsExperienceEnabled] = useIsOpsHubExperience();

  const isThisUserCurrentUser = currentUserId === userId;

  if (!isThisUserCurrentUser && (!isPageAvailable || isOpsExperienceEnabled)) {
    return <AccessDeniedLayout />;
  }

  return (
    <UserDetailProvider userId={userId}>
      <UserDetailPageContent />
    </UserDetailProvider>
  );
};
