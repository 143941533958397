import { Button, Modal, Title } from "@validereinc/common-components";
import * as PropTypes from "prop-types";
import React, { useState } from "react";

import RecordOfQualityService from "../../Services/RecordOfQualityService";
import "./ConfirmationModal.scss";

const ConfirmationModal = (props) => {
  const [buttonState, setButtonState] = useState("enabled");

  const onApproveButtonClick = () => {
    setButtonState("disabled");

    RecordOfQualityService.lockAccountingPeriod(props.accountingPeriod.id)
      .then(() => {
        setButtonState("enabled");

        props.refreshAccountingPeriod();
        props.onConfirmationModalClose();
      })
      .finally(() => {
        setButtonState("enabled");
      });
  };

  return (
    <Modal
      className="recordOfQualityConfirmationModal"
      open={props.show}
      onClose={props.onConfirmationModalClose}
    >
      <Modal.Header>
        <Title>Approve System Balance</Title>
      </Modal.Header>

      <Modal.Body>
        Do you wish to approve this accounting period?
        <div className="recordOfQualityConfirmationModal__notes">
          **Note: Once the accounting period is <b>approved</b> you will
          <b> not be able to reverse</b> this action.**
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button
          className="pull-left"
          onClick={props.onConfirmationModalClose}
        >
          Close
        </Button>

        <Button
          variant="primary"
          onClick={onApproveButtonClick}
          disabled={buttonState !== "enabled"}
        >
          Approve
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

ConfirmationModal.propTypes = {
  show: PropTypes.bool.isRequired,
  accountingPeriod: PropTypes.object.isRequired,
  refreshAccountingPeriod: PropTypes.func.isRequired,
  onConfirmationModalClose: PropTypes.func.isRequired,
};

export default ConfirmationModal;
