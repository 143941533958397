import { useNavigate, useSearchParams } from "#routers/hooks";
import { linkToEditScenario } from "#routes/scenarios/[scenarioId]/edit";
import useTableState from "#src/hooks/useTableState";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  Button,
  DataTablePanel,
  Dialog,
  useAlert,
} from "@validereinc/common-components";
import type { ReportingGroupType } from "@validereinc/domain";
import { ReportingGroupDomain } from "@validereinc/domain";
import React, { useEffect, useState } from "react";

export const ReportingGroupTablePanel = () => {
  const navigate = useNavigate();
  const { addAlert } = useAlert();
  const [searchParams] = useSearchParams();

  const queryClient = useQueryClient();
  const [itemToDelete, setItemToDelete] = useState<ReportingGroupType>();
  const nameOfDeletedItem = itemToDelete
    ? `"${itemToDelete.name}"`
    : "scenario";

  const reportingGroupsParams = {
    page: searchParams.page ?? 1,
    pageSize: searchParams.rowPerPage ?? 25,
  };

  const reportingGroups = useQuery({
    queryKey: ["reportingGroups", reportingGroupsParams],
    queryFn: () => ReportingGroupDomain.getList(reportingGroupsParams),
  });

  const deleteReportingGroup = useMutation({
    mutationFn: (id: string) => ReportingGroupDomain.deleteOne({ id }),
    onSuccess: async () => {
      addAlert({
        variant: "success",
        message: `Successfully deleted ${nameOfDeletedItem}.`,
      });
      await queryClient.invalidateQueries({ queryKey: ["reportingGroups"] });
    },
    onSettled: () => {
      setItemToDelete(undefined);
    },
  });

  const headers = [
    {
      key: "name",
      label: "Name",
    },
    {
      key: "description",
      label: "Description",
    },
    {
      key: "priority",
      label: "Is Primary",
      renderComponent: ({ item }: { item: ReportingGroupType }) =>
        item.id === reportingGroups?.data?.data[0]?.id &&
        (!searchParams.page || searchParams.page == 1)
          ? "Yes"
          : "No",
    },
  ];

  const getItemActions = ({ item }: { item: ReportingGroupType }) => [
    {
      label: "Edit",
      buttonProps: {
        onClick: () => navigate({ pathname: linkToEditScenario(item.id) }),
        icon: "pencil-simple",
      },
    },
    {
      label: "Delete",
      buttonProps: {
        onClick: () => setItemToDelete(item),
        icon: "trash",
      },
    },
  ];

  const { tableProps, fetchData } = useTableState({
    onFetchData: () => Promise.resolve(reportingGroups.data),
    isEnabled: !reportingGroups.isLoading,
    itemsKey: "data",
  });

  /** This allows us to re-render the table once the refetch is done */
  useEffect(() => {
    if (reportingGroups.data) {
      fetchData({});
    }
  }, [reportingGroups.data]);

  return (
    <>
      <DataTablePanel
        panelProps={{ title: "Scenarios" }}
        dataTableProps={{
          ...tableProps,
          headers,
          getItemActions,
        }}
      />
      <Dialog
        isOpen={!!itemToDelete}
        onClose={() => setItemToDelete(undefined)}
        title={`Delete ${nameOfDeletedItem}`}
        actionRow={[
          <Button
            key="delete-dialog-action"
            onClick={() => deleteReportingGroup.mutate(itemToDelete.id)}
            variant="error"
          >
            Delete
          </Button>,
        ]}
      >
        Are you sure you want to delete this reporting group? This action cannot
        be undone.
      </Dialog>
    </>
  );
};
