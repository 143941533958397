export const USER_DETAILS_FALLBACK_PAGE_TITLE = "User:userId";

export const linkToUserDetailPage = (userId = ":userId") => {
  return `/app/settings/users/${userId}/detail`;
};

export const USER_DETAILS_BREADCRUMB = {
  title: USER_DETAILS_FALLBACK_PAGE_TITLE,
  path: linkToUserDetailPage,
};

export * from "./UserDetailPage";
