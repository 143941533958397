const getInput = (input, parseFunction, predicates = []) => {
  const basePredicates = [
    (input) => input !== "",
    (input) => input !== undefined,
  ];

  predicates = [...basePredicates, ...predicates];

  const isParsable = predicates.reduce((acc, p) => acc && p(input), true);

  return isParsable ? parseFunction(input) : "";
};

export const getIntegerInput = (input, predicates = []) => {
  return getInput(input, parseInt, predicates);
};

export const getFloatInput = (input, predicates = []) => {
  return getInput(input, parseFloat, predicates);
};

export const getPositiveIntegerInput = (input) => {
  const predicates = [(input) => parseInt(input, 10) > 0];

  return getIntegerInput(input, predicates);
};

export const getNonNegativeIntegerInput = (input) => {
  const predicates = [(input) => parseInt(input, 10) >= 0];
  return getIntegerInput(input, predicates);
};

export const getDaysFromSeconds = (seconds) => seconds / 86400;
export const getSecondsFromDays = (days) => days * 86400;
