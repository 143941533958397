import DataMappingService from "#components/Services/DataMappingService";
import { getBreadcrumbsObject } from "#routers/breadcrumbsHelper";
import { useNavigate, useSearchParams } from "#routers/hooks";
import {
  linkToCreateDataMappingValidation,
  linkToDataMapping,
} from "#routers/links";
import {
  Button,
  Page,
  Panel,
  Stepper,
  useForm,
} from "@validereinc/common-components";
import classNames from "classnames/bind";
import get from "lodash/get";
import * as PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { DATA_MAPPING_LABEL, STEPS, TITLE } from "../../DataMappingConstants";
import commonStyles from "../CreateDataMapping.module.scss";
import styles from "./DataMappingForm.module.scss";
import DataPreviewPanel from "./DataPreviewPanel";
import EntityMappingPanel from "./EntityMappingPanel";

const cx = classNames.bind(styles);
const commonCx = classNames.bind(commonStyles);

const CURRENT_STEP = 3;

const DataMappingForm = ({ breadcrumbs }) => {
  const [{ dataMappingId, taskId }] = useSearchParams();

  const form = useForm();

  const [entityCategory, setEntityCategory] = useState();

  const [previewDataLoading, setPreviewDataLoading] = useState(true);
  const [previewData, setPreviewData] = useState([]);

  const navigate = useNavigate();

  const [entityCategories, setEntityCategories] = useState([]);
  const [isEntityCategoriesLoading, setIsEntityCategoriesLoading] =
    useState(true);
  const [dataMappingDetail, setDataMappingDetail] = useState({});

  useEffect(() => {
    (async () => {
      setIsEntityCategoriesLoading(true);

      const { data: dataMappingData } =
        await DataMappingService.getDataMappingDetail(dataMappingId);

      const { data } = await DataMappingService.getEntityCategories({
        entityCategory: dataMappingData.mapping_entity,
        dataMappingId: dataMappingId,
      });

      setDataMappingDetail(dataMappingData);
      setEntityCategories(data.attrs);
      setIsEntityCategoriesLoading(false);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      setPreviewDataLoading(true);

      const {
        data: { task_previews, mapping_entity },
      } = await DataMappingService.getTaskPreview({ taskId });

      setEntityCategory(mapping_entity);

      setPreviewData(
        task_previews.map(({ header, preview }) => ({
          label: header,
          value: preview,
        }))
      );

      setPreviewDataLoading(false);
    })();
  }, [taskId]);

  const handleOnNextClick = async (values) => {
    const data_mapping = entityCategories.map((entityCategory) => {
      const categoryHeader = get(values, entityCategory.field_name);
      const lookup = get(values?.lookup, entityCategory.field_name);

      const updatedCategory = { ...entityCategory };

      if (categoryHeader) {
        updatedCategory.associated_header = categoryHeader;
      }

      if (lookup) {
        updatedCategory.lookup = true;
      }

      return updatedCategory;
    });

    await DataMappingService.updateDataMapping({
      dataMappingId,
      data_mapping,
      dataMappingDetail,
    });

    DataMappingService.planTask({ taskId });

    navigate({
      pathname: linkToCreateDataMappingValidation(),
      query: { dataMappingId, taskId },
    });
  };

  const handleOnCancelClick = () => {
    navigate({ pathname: linkToDataMapping() });
  };

  const actionRow = (
    <Stepper
      steps={STEPS}
      activeStep={CURRENT_STEP}
    />
  );

  const footer = (
    <div className={commonCx("footer")}>
      <Button onClick={handleOnCancelClick}>Cancel</Button>
      <div className={commonCx("stepActionContainer")}>
        <Button
          variant="primary"
          onClick={form.handleSubmit(handleOnNextClick)}
        >
          Process Data & Create Mapping
        </Button>
      </div>
    </div>
  );

  return (
    <Page
      title={TITLE}
      breadcrumbs={getBreadcrumbsObject(breadcrumbs)}
      actionRow={actionRow}
      footer={footer}
      footerAlign="left"
    >
      <Panel title={DATA_MAPPING_LABEL}>
        <p>
          Map column headers from the source file to associated entity fields.
        </p>

        <div className={cx("container")}>
          <DataPreviewPanel
            previewData={previewData}
            previewDataLoading={previewDataLoading}
          />

          <EntityMappingPanel
            entityCategory={entityCategory}
            entityCategories={entityCategories}
            isEntityCategoriesLoading={isEntityCategoriesLoading}
            dataMappingId={dataMappingId}
            mappingStrategy={dataMappingDetail?.mapping_strategy}
            previewData={previewData}
            form={form}
          />
        </div>
      </Panel>
    </Page>
  );
};

DataMappingForm.propTypes = {
  breadcrumbs: PropTypes.array.isRequired,
};

export default DataMappingForm;
