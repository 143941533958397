import { Button, Dialog } from "@validereinc/common-components";
import React from "react";

import { useDeleteOneFormSubmission } from "#src/components/hooks/adapters/useFormSubmissions";
import type { DialogProps } from "@validereinc/common-components";

export const DeleteDraftFormSubmissionDialog = ({
  isOpen,
  onClose,
  formSubmissionId,
  onDelete,
}: Pick<DialogProps, "isOpen" | "onClose"> & {
  formSubmissionId: string;
  onDelete?: () => void;
}) => {
  const mutation = useDeleteOneFormSubmission({
    successMessage: "Successfully deleted form draft",
    errorMessage: "Failed to delete form draft",
    onSuccess: () => {
      onDelete?.();
      onClose();
    },
  });

  return (
    <Dialog
      title="Delete Draft"
      isOpen={isOpen}
      onClose={onClose}
      actionRow={[
        <Button
          key="delete"
          variant="error"
          onClick={() =>
            mutation.mutate({
              id: formSubmissionId,
            })
          }
        >
          Delete Draft
        </Button>,
      ]}
    >
      Deleting the existing draft will remove it permanently. You will no longer
      be able to access the draft or its values.
    </Dialog>
  );
};
