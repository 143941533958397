import { useNavigate } from "#src/Routers/hooks";
import { linkToRecordValueSourceDetail } from "#src/utils/links";
import { Button, Tooltip } from "@validereinc/common-components";
import { AssetTypeType } from "@validereinc/domain";
import React from "react";

const RecordValueConfigureSourcesButton = ({
  isLocked,
  assetType,
  assetId,
  recordId,
  measurementType,
}: {
  assetType: AssetTypeType;
  assetId: string;
  isLocked?: boolean;
  recordId: string;
  measurementType: string;
}) => {
  const navigate = useNavigate();
  const navigateToAddSource = () => {
    navigate({
      pathname: linkToRecordValueSourceDetail(
        assetType,
        assetId,
        recordId,
        measurementType
      ),
    });
  };

  const button = (
    <Button
      onClick={navigateToAddSource}
      disabled={isLocked}
      icon={isLocked ? "lock" : ""}
    >
      Manage Sources
    </Button>
  );
  return isLocked ? (
    <Tooltip
      content="You cannot manage sources on this record because it is locked."
      title="Locked Record Value"
    >
      {button}
    </Tooltip>
  ) : (
    button
  );
};

export default RecordValueConfigureSourcesButton;
