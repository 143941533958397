import React, { useEffect, useMemo, useState } from "react";
import InstrumentSummary from "./InstrumentSummary";

import AgilentGCTests from "./AgilentGCInstrument/AgilentGCTests";
import InstrumentAntonPaar4500Tests from "./AntonPaarDensity4500Instrument/InstrumentAntonPaar4500Tests";
import InstrumentAntonPaarSVM3001Tests from "./AntonPaarSVM3001Instrument/InstrumentAntonPaarSVM3001Tests";
import InstrumentAquamaxTests from "./AquamaxKFInstrument/InstrumentAquamaxTests";
import DensityOnlyInstrumentTests from "./DensityOnlyInstruments/DensityOnlyInstrumentTests";
import InstrumentEralyticVaporTests from "./EralyticsVaporInstrument/InstrumentEralyticVaporTests";
import InstrumentHoribaTests from "./HoribaSulphurInstrument/InstrumentHoribaTests";
import InstrumentMettlerToledoT5Tests from "./MettlerToledoT5Instrument/InstrumentMettlerToledoT5Tests";
import InstrumentMettlerToledoV20STests from "./MettlerToledoV20SInstrument/InstrumentMettlerToledoV20STests";
import InstrumentRigakuTests from "./RigakuSulphurInstrument/InstrumentRigakuTests";
import InstrumentRudolphTests from "./RudolphDensityInstrument/InstrumentRudolphTests";
import InstrumentVaporTests from "./ValidereVaporInstrument/ValidereVaporTests";
import InstrumentCSVExport from "./ValidereWaterInstrument/InstrumentCSVExport";
import WaterInstrumentTests from "./ValidereWaterInstrument/WaterInstrumentTests";

import GenericTests from "./Generic/GenericTests";

import { Button, Page } from "@validereinc/common-components";
import { Route } from "react-router-dom";
import { getBreadcrumbsObject } from "../../Routers/breadcrumbsHelper";
import InstrumentService from "../Services/InstrumentService";
import InstrumentGrabnerMiniVapTests from "./GrabnerMiniVapInstrument/InstrumentGrabnerMiniVapTests";
import "./Instrument.css";
import "./Instrument.scss";
import { InstrumentInfoPanel } from "./InstrumentInfoPanel";
import ArchiveInstrumentModal from "./InstrumentsList/Modals/ArchiveInstrumentModal";
import EditInstrumentDetailsModal from "./InstrumentsList/Modals/EditInstrumentDetailsModal";

/* eslint-disable react/prop-types, react/display-name */

const getComponentForInstrumentType = (instrumentType) => {
  switch (instrumentType) {
    case "anton paar density": {
      return InstrumentAntonPaar4500Tests;
    }
    case "anton paar dma 35 density":
    case "isl vida density meter": {
      return DensityOnlyInstrumentTests;
    }
    case "anton paar svm 3000":
    case "anton paar svm 3001": {
      return InstrumentAntonPaarSVM3001Tests;
    }
    case "eralytics vapor": {
      return InstrumentEralyticVaporTests;
    }
    case "horiba xray fluorescence": {
      return InstrumentHoribaTests;
    }
    case "rigaku xray fluorescence": {
      return InstrumentRigakuTests;
    }
    case "rudolph research density": {
      return InstrumentRudolphTests;
    }
    case "water": {
      return WaterInstrumentTests;
    }
    case "validere vapor": {
      return InstrumentVaporTests;
    }
    case "agilent gas chromatography": {
      return AgilentGCTests;
    }
    case "aquamax kf": {
      return InstrumentAquamaxTests;
    }
    case "mettler toledo t5": {
      return InstrumentMettlerToledoT5Tests;
    }
    case "mettler toledo v20s": {
      return InstrumentMettlerToledoV20STests;
    }
    case "grabner minivap vpxpert": {
      return InstrumentGrabnerMiniVapTests;
    }
    default: {
      return GenericTests;
    }
  }
};

const useFetchInstrumentDetail = (instrumentId) => {
  const [instrumentInfo, setInstrumentInfo] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);
  const [lastUpdatedAt, setLastUpdatedAt] = useState(new Date());

  useEffect(() => {
    setIsLoaded(false);
    setInstrumentInfo({});

    InstrumentService.getInstrumentDetails(instrumentId).then(({ data }) => {
      setInstrumentInfo(data);
      setIsLoaded(true);
    });
  }, [instrumentId, lastUpdatedAt]);

  const refetchInstruments = () => {
    setLastUpdatedAt(new Date());
  };

  return { isLoaded, instrumentInfo, refetchInstruments };
};

export const Instrument = (props) => {
  const instrumentId = props.match.params.instrumentId;
  const { height } = props;

  const [modal, setModal] = useState(null);
  const { isLoaded, instrumentInfo, refetchInstruments } =
    useFetchInstrumentDetail(instrumentId);

  const breadcrumbs = useMemo(() => {
    const newBreadcrumbs = getBreadcrumbsObject(
      props.breadcrumbs,
      props.match.params
    );

    if (newBreadcrumbs) {
      newBreadcrumbs[newBreadcrumbs.length - 1].title = instrumentInfo?.name;
    }

    return newBreadcrumbs;
  }, [props.breadcrumbs, props.match.params, instrumentInfo]);

  return (
    <Page
      title={instrumentInfo?.name}
      isLoading={!isLoaded}
      breadcrumbs={breadcrumbs}
      actionRow={[
        instrumentInfo?.status === "active" ? (
          <Button
            key="infoPanel__archiveButton"
            className="infoPanel__archiveButton"
            onClick={() => setModal("archive")}
            variant="error-outline"
            disabled={!isLoaded}
          >
            Archive
          </Button>
        ) : (
          <Button
            key="infoPanel__archiveButton"
            className="infoPanel__archiveButton"
            onClick={() => setModal("archive")}
            disabled={!isLoaded}
          >
            Unarchive
          </Button>
        ),
        <Button
          key="infoPanel__editButton"
          className="infoPanel__editButton"
          onClick={() => setModal("editDetails")}
          variant="primary"
          disabled={!isLoaded}
        >
          Edit
        </Button>,
      ]}
    >
      <div className="instrument">
        <InstrumentInfoPanel
          instrumentInfo={instrumentInfo}
          location={props.location}
        />

        <Route
          path="/app/instrument/view/:instrumentId/summary"
          exact={false}
          render={(props) => {
            return (
              <InstrumentSummary
                {...props}
                instrumentInfo={instrumentInfo}
                height={height}
              />
            );
          }}
        />
        <Route
          path="/app/instrument/view/:instrumentId/csvExport"
          exact={true}
          render={(props) => {
            return (
              <InstrumentCSVExport
                {...props}
                instrumentInfo={instrumentInfo}
              />
            );
          }}
        />
        <Route
          path="/app/instrument/view/:instrumentId/tests/:testId"
          exact={true}
          render={(props) => {
            const TestsComponent = getComponentForInstrumentType(
              instrumentInfo.type
            );

            return (
              <TestsComponent
                {...props}
                instrumentInfo={instrumentInfo}
                height={height}
              />
            );
          }}
        />
        {modal === "editDetails" && (
          <EditInstrumentDetailsModal
            show={true}
            onHide={() => setModal(null)}
            instrumentId={instrumentInfo.id}
            instrumentName={instrumentInfo.name}
            serialNumber={instrumentInfo.current_serial_number}
            availableSerialNumbers={instrumentInfo.serial_numbers}
            refetchCalibrationData={refetchInstruments}
          />
        )}
        {modal === "archive" && (
          <ArchiveInstrumentModal
            show={true}
            onHide={() => setModal(null)}
            instrumentDetail={instrumentInfo}
            refetchInstruments={refetchInstruments}
          />
        )}
      </div>
    </Page>
  );
};

export default Instrument;
