export * from "./MeasurementTypePage";

export const MEASUREMENT_TYPE_TITLE = "Measurement Type";

export const linkToMeasurementTypesSettingsPage = () =>
  `/app/settings/measurement-types`;

export const MEASUREMENT_TYPES_SETTINGS_BREADCRUMB = {
  title: MEASUREMENT_TYPE_TITLE,
  path: linkToMeasurementTypesSettingsPage,
};
