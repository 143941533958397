import sortBy from "lodash/sortBy";
import { COMPONENTS } from "../../Records/Balances/BalanceUpserts/BalancesUpsertsConstant";
import indexOf from "lodash/indexOf";
import find from "lodash/find";
import { sumArrayLengths } from "#utils/arrayFormatter";
import { InputIsMatched } from "#utils/filters";

export const STREAM_TYPES = ["inlet", "outlet", "reference"];

export function sortByStreams(list) {
  return [...list].sort((a, b) => {
    const aValue = sumArrayLengths(
      ...STREAM_TYPES.map((columnKey) => a[`${columnKey}_streams`]),
      ...STREAM_TYPES.map((columnKey) => a[`${columnKey}_inventories`])
    );

    const bValue = sumArrayLengths(
      ...STREAM_TYPES.map((columnKey) => b[`${columnKey}_streams`]),
      ...STREAM_TYPES.map((columnKey) => b[`${columnKey}_inventories`])
    );

    return aValue - bValue;
  });
}

export function sortByProperties(list, columnKey) {
  return [...list].sort((a, b) => {
    const aValue = a[columnKey]?.length ?? "-";
    const bValue = b[columnKey]?.length ?? "-";

    return aValue - bValue;
  });
}

export function sortProperties(propertyList) {
  return sortBy(propertyList, (property) => {
    return indexOf(COMPONENTS, property);
  });
}

export function searchByName(list, inputValue) {
  return list.filter((row) => InputIsMatched(inputValue, row.name ?? "-"));
}
export function getSourceDetail(sourceList, sourceId) {
  return find(sourceList, { id: sourceId });
}

export function getSourceDetailOfIdList(sourceList, idList) {
  if (sourceList.length) {
    return idList
      .map(({ id }) => {
        return getSourceDetail(sourceList, id);
      })
      .sort((a, b) => a?.name?.localeCompare?.(b?.name));
  }

  return [];
}
