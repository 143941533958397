import { useBreadcrumbs } from "#routers/breadcrumbsHelper";
import { useNavigate, useParams } from "#routers/hooks";
import { linkToDashboards } from "#routes/dashboard";
import { AccessDeniedLayout } from "#src/batteries-included-components/Layouts/Authorization/AccessDenied";
import {
  useIsFeatureAvailable,
  useLegacyIsFeatureAvailable,
} from "#src/contexts/AuthenticatedContext.helpers";
import { Page, useAlert } from "@validereinc/common-components";
import { Insights, QuickSightDashboardDetailType } from "@validereinc/domain";
import * as QuickSightEmbedding from "amazon-quicksight-embedding-sdk";
import classNames from "classnames/bind";
import React, { useEffect, useRef, useState } from "react";
import { INSIGHTS_DETAILS_DASHBOARD_BREADCRUMB } from ".";
import { INSIGHTS_BREADCRUMB } from "../..";
import { DASHBOARDS_BREADCRUMB } from "../../..";
import styles from "./InsightsDetailsPage.module.scss";

const cx = classNames.bind(styles);

export const InsightsDetailsPage = () => {
  const [isInsightsAvailableLegacy] = useLegacyIsFeatureAvailable({
    featureFlagQuery: ["core/insights_dashboard"],
    permissionQuery: ["core:insights"],
    featureId: "dashboards",
  });
  const [isInsightsAvailable] = useIsFeatureAvailable({
    featureFlagQuery: "dashboards:insights",
    permissionQuery: "insights_dashboards:read",
  });
  const navigate = useNavigate();
  const iframeRef = useRef(null);
  const { insightsId } = useParams<{ insightsId: string }>();
  const { addAlert } = useAlert();
  const [loading, setLoading] = useState(false);
  const [detail, setDetail] = useState<QuickSightDashboardDetailType | null>(
    null
  );

  const fetchInsightsDetails = async (insightsId: string) => {
    try {
      setLoading(true);
      const response = await Insights.getDashboardDetails({ insightsId });

      setDetail(response);
    } catch (error) {
      addAlert?.({
        variant: "error",
        message:
          "There was an error fetching your insights dashboard details. Please try again",
      });

      navigate({ pathname: linkToDashboards() });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (insightsId) {
      fetchInsightsDetails(insightsId);
    }
  }, [insightsId]);

  useEffect(() => {
    async function embedDashboard() {
      if (!detail?.embed_url) {
        return;
      }

      const embeddingContext =
        await QuickSightEmbedding.createEmbeddingContext();

      const frameOptions = {
        url: detail.embed_url,
        container: iframeRef.current!,
        withIframePlaceholder: true,
        resizeHeightOnSizeChangedEvent: true,
      };

      await embeddingContext.embedDashboard(frameOptions);
    }

    embedDashboard();
  }, [detail?.embed_url]);

  if (!isInsightsAvailable && !isInsightsAvailableLegacy) {
    return <AccessDeniedLayout />;
  }

  return (
    <Page
      isLoading={loading}
      title={detail?.dashboard?.name}
      breadcrumbs={useBreadcrumbs(
        [
          DASHBOARDS_BREADCRUMB,
          INSIGHTS_BREADCRUMB,
          INSIGHTS_DETAILS_DASHBOARD_BREADCRUMB,
        ],
        { 2: detail?.dashboard.name }
      )}
      category="Insights"
    >
      <div className={cx("iframeContainer")}>
        <div ref={iframeRef} />
      </div>
    </Page>
  );
};
