import useEquipmentTypes from "#components/hooks/useEquipmentTypes";
import { useCustomAttributeFilters } from "#hooks/FilterPanel/useCustomAttributeFilters";
import {
  useClearEquipmentCache,
  useExportEquipment,
  useListEquipment,
} from "#hooks/adapters/useEquipment";
import { useTableSortingAndPagination } from "#redux/reducers/tableStateReducer";
import { linkToEquipmentDetail, linkToFacilities } from "#routers/links";
import { ImportDataActionStatusWithQueue } from "#src/batteries-included-components/Banners/ImportDataActionStatus";
import { ImportDataAction } from "#src/batteries-included-components/Buttons/ImportDataAction";
import EditEquipmentDialog from "#src/batteries-included-components/Dialogs/EditEquipmentDialog";
import { FacilityDropdownInput } from "#src/batteries-included-components/Dropdowns/FacilityDropdownInput";
import { RoutingLink } from "#src/batteries-included-components/RoutingLink/RoutingLink";
import {
  SavedFilterTag,
  useSavedFilters,
} from "#src/components/hooks/FilterPanel/useSavedFilters";
import {
  EQUIPMENT_STATUS_OPTIONS,
  FACILITY_STATUS_OPTIONS,
} from "#src/constants";
import { useIsFeatureAvailable } from "#src/contexts/AuthenticatedContext.helpers";
import { DEFAULT_DATE_RANGES } from "#src/hooks/useDateRange";
import useLocalization from "#src/hooks/useLocalization";
import { useStorageKey } from "#src/hooks/useStorageKey";
import {
  Button,
  DataTable,
  DataTablePanel,
  DateSelectorInput,
  Dialog,
  DropdownInput,
  FilterPanel,
  HeaderType,
  SortingType,
  TextInput,
  useAlert,
  useFilters,
} from "@validereinc/common-components";
import {
  AssetType,
  EquipmentDomain,
  EquipmentFilterType,
  EquipmentStatus,
  EquipmentType,
  SortDirection,
} from "@validereinc/domain";
import {
  getYearMonthFromDateRange,
  toStartCaseString,
} from "@validereinc/utilities";
import React, { useMemo, useState } from "react";

const sorting: SortingType = {
  sortBy: "facility_name",
  sortDirection: SortDirection.ASCENDING,
};

export const EquipmentList = () => {
  const [isDataIngestionEnabled] = useIsFeatureAvailable({
    featureFlagQuery: "core:data_pipeline",
  });
  const storageKeys = useStorageKey("equipment");
  const { addAlert } = useAlert();
  const [assetIdToEdit, setAssetIdToEdit] = useState<string | undefined>();
  const [equipmentToDelete, setEquipmentToDelete] = useState<EquipmentType>();
  const { localize, isLoading: isMappingLoading } = useLocalization();
  const [equipmentTypes] = useEquipmentTypes();
  const [rawFilters, setFilters] = useFilters<EquipmentFilterType>(
    storageKeys.filterConfigStorageKey
  );

  const { savedFilter: _, period, ...filters } = rawFilters;

  const [tableState, updateTableState] = useTableSortingAndPagination(sorting, {
    ...filters,
    period,
  });

  const {
    customAttributeFilters: equipmentCustomAttributeFilters,
    customAttributeHeaders: equipmentCustomAttributeHeaders,
  } = useCustomAttributeFilters({
    assetType: AssetType.EQUIPMENT,
    section: "Equipment",
  });
  const { customAttributeFilters: facilityCustomAttributeFilters } =
    useCustomAttributeFilters({
      assetType: AssetType.FACILITY,
      section: "Facility",
      prefix: "facility.custom_attributes",
    });

  const params = {
    page: tableState.page,
    pageSize: tableState.itemsPerPage,
    sortBy: tableState.sortBy,
    sortDirection: tableState.sortDirection,
    filters,
    period: getYearMonthFromDateRange(period),
  };
  const { data, isLoading, refetch } = useListEquipment(params);
  const { invalidate } = useClearEquipmentCache();

  const { mutate: handleExport, isLoading: isExporting } = useExportEquipment({
    filters,
    period: getYearMonthFromDateRange(period),
    sortBy: params.sortBy,
    sortDirection: params.sortDirection,
  });

  const { filterProps } = useSavedFilters({
    queryKey: [AssetType.EQUIPMENT],
    savedFilterResourceAdapter: EquipmentDomain.savedFilters,
    setFilters,
    filterKeyBlacklist: ["period.from", "period.to"],
    tag: SavedFilterTag.LIST,
  });

  const deleteEquipmentOnClick = async () => {
    try {
      if (equipmentToDelete) {
        await EquipmentDomain.delete({
          id: equipmentToDelete.id,
        });
        addAlert({
          variant: "success",
          message: `Successfully deleted '${equipmentToDelete.name}'`,
        });
      }
    } finally {
      invalidate();
      setEquipmentToDelete(undefined);
    }
  };

  const rowActionItems = ({ item }: { item: EquipmentType }) => [
    {
      label: "Edit",
      buttonProps: {
        onClick: () => setAssetIdToEdit(item.id),
        icon: "pencil-simple",
      },
    },
    {
      label: "Delete",
      buttonProps: {
        onClick: () => setEquipmentToDelete(item),
        icon: "trash",
      },
    },
  ];

  const fixedHeaders: Array<HeaderType<EquipmentType>> = useMemo(
    () => [
      {
        label: `${localize("Equipment")} Name`,
        key: "name",
        isSortable: true,
        minWidth: 240,
        renderComponent: ({ item }: { item: EquipmentType }) => (
          <RoutingLink to={linkToEquipmentDetail(item?.id)}>
            {item.name}
          </RoutingLink>
        ),
      },
      {
        label: "Status",
        key: "status",
        isSortable: true,
        variant: "pill",
        renderComponent: ({ item }: { item: EquipmentType }) => (
          <DataTable.DataRow.PillCell
            variant={
              item.status === EquipmentStatus.ACTIVE ? "success" : "default"
            }
            value={toStartCaseString(item.status)}
          />
        ),
      },
      {
        label: `${localize("Equipment")} Type`,
        key: "type_id",
        isSortable: true,
        minWidth: 240,
        renderComponent: ({ item }: { item: EquipmentType }) =>
          item?.type?.name ?? "-",
      },
      {
        label: `${localize("Facility")} Name`,
        key: "facility_name",
        isSortable: true,
        minWidth: 240,
        renderComponent: ({ item }: { item: EquipmentType }) => (
          <RoutingLink to={linkToFacilities(item?.facility_id)}>
            {item?.facility?.name ?? ""}
          </RoutingLink>
        ),
      },
      {
        label: "Effective Date",
        key: "effective_date",
        minWidth: 180,
        renderComponent: ({ item }) => (
          <DataTable.DataRow.DateCell value={item.effective_date} />
        ),
        isSortable: true,
      },
    ],
    [isMappingLoading]
  );
  const headers = useMemo(
    () => [...fixedHeaders, ...equipmentCustomAttributeHeaders],
    [fixedHeaders, equipmentCustomAttributeHeaders]
  );
  const items = data?.data ?? [];

  const actionRow = (
    <>
      <Button
        key="export-equipment"
        variant="outline"
        onClick={() => handleExport()}
        isLoading={isExporting}
      >
        Export
      </Button>
      {isDataIngestionEnabled ? (
        <ImportDataAction
          key="import-equipment"
          resource={{
            id: AssetType.EQUIPMENT,
            name: "Equipment",
            singular: localize("Equipment"),
            plural: localize("equipment_plural"),
          }}
        />
      ) : null}
    </>
  );

  return (
    <>
      {isDataIngestionEnabled ? (
        <ImportDataActionStatusWithQueue
          resource={{
            id: AssetType.EQUIPMENT,
            name: "Equipment",
            singular: localize("Equipment"),
            plural: localize("equipment_plural"),
          }}
        />
      ) : null}
      <FilterPanel
        {...filterProps}
        storageKey={storageKeys.filterConfigStorageKey}
        defaultActiveKeys={["Facility"]}
        defaultValue={{
          period: DEFAULT_DATE_RANGES.currentWholeMonth,
        }}
        filters={[
          {
            component: (
              <TextInput
                name="name"
                placeholder={`Search ${localize("equipment_plural")}...`}
                type="search"
                isInline
              />
            ),
          },
          {
            component: (
              <DateSelectorInput
                key={"period"}
                name={"period"}
                variant="month"
                isInline
              />
            ),
          },
          {
            section: "Facility",
            component: (
              <FacilityDropdownInput
                isMulti
                isFluid
                isOptionalTextShown={false}
              />
            ),
          },
          {
            section: "Facility",
            component: (
              <DropdownInput
                label="Facility Status"
                name="facility.status"
                options={FACILITY_STATUS_OPTIONS}
                labelKey="label"
                valueKey="value"
                isMulti
                isFluid
                isSearchable
                isOptionalTextShown={false}
              />
            ),
          },
          ...facilityCustomAttributeFilters,
          {
            section: "Equipment",
            component: (
              <DropdownInput
                label={`${localize("Equipment")} Type`}
                name="type.id"
                options={equipmentTypes}
                labelKey="name"
                valueKey="id"
                isMulti
                isFluid
                isSearchable
                isOptionalTextShown={false}
              />
            ),
          },
          {
            section: "Equipment",
            component: (
              <DropdownInput
                label="Status"
                name="status"
                options={EQUIPMENT_STATUS_OPTIONS}
                labelKey="label"
                valueKey="value"
                isMulti
                isFluid
                isSearchable
                isOptionalTextShown={false}
              />
            ),
          },
          ...equipmentCustomAttributeFilters,
        ]}
      />
      <DataTablePanel
        storageKey={storageKeys.tableConfigStorageKey}
        panelProps={{
          actionRow,
          title: localize("equipment_plural"),
        }}
        dataTableProps={{
          isLoading,
          items,
          sorting,
          headers,
          onSortChange: updateTableState,
          onPaginationChange: updateTableState,
          pagination: {
            page: tableState.page,
            itemsPerPage: tableState.itemsPerPage,
            total: data?.total_entries ?? 0,
          },
          isFluid: false,
          getItemActions: rowActionItems,
        }}
      />
      <Dialog
        isOpen={!!equipmentToDelete}
        onClose={() => setEquipmentToDelete(undefined)}
        title={`Delete "${equipmentToDelete?.name}"`}
        actionRow={[
          <Button
            key="delete-dialog-action"
            onClick={deleteEquipmentOnClick}
            variant="error"
          >
            Delete
          </Button>,
        ]}
      >
        <div>
          Are you sure you want to delete this {localize("equipment")}? This
          action cannot be undone.
        </div>
      </Dialog>
      <EditEquipmentDialog
        defaultPeriod={getYearMonthFromDateRange(period) ?? ""}
        onClose={() => setAssetIdToEdit(undefined)}
        equipmentId={assetIdToEdit}
        onEdit={() => {
          refetch();
        }}
      />
    </>
  );
};
