import { useListDevices } from "#hooks/adapters/utils/useDevices";
import { useTableSortingAndPagination } from "#redux/reducers/tableStateReducer";
import { useNavigate, useParams, useSearchParams } from "#routers/hooks";
import { linkToAddDevice, linkToDeviceDetail } from "#routers/links";
import { FacilityDetailPageParams } from "#routes/organization/facilities/[facilityId]";
import { RoutingLink } from "#src/batteries-included-components/RoutingLink/RoutingLink";
import { useStorageKey } from "#src/hooks/useStorageKey";
import {
  Button,
  DataTable,
  DataTablePanel,
  HeaderType,
  SortingType,
} from "@validereinc/common-components";
import { DeviceType, SortDirection } from "@validereinc/domain";
import React from "react";

const sorting: SortingType = {
  sortBy: "name",
  sortDirection: SortDirection.ASCENDING,
};

export const DevicesPanel = () => {
  const { facilityId } = useParams<FacilityDetailPageParams>();
  const { tableConfigStorageKey } = useStorageKey("facilities-devices");
  const navigate = useNavigate();
  const [
    {
      section: _section, // pulls it out of request since its in searchParams
      tab: _tab, // same as above
      ...deviceFilters
    },
  ] = useSearchParams();

  const filters = { ...deviceFilters, "facility.id": facilityId };

  const [tableState, updateTableState] = useTableSortingAndPagination(
    { ...sorting, itemsPerPage: 25 },
    filters
  );

  const { data, isLoading } = useListDevices({
    page: tableState.page,
    pageSize: tableState.itemsPerPage,
    sortBy: tableState.sortBy,
    sortDirection: tableState.sortDirection,
    filters,
  });

  const items = data?.data ?? [];

  const headers: Array<HeaderType<DeviceType>> = [
    {
      key: "name",
      label: "Name",
      minWidth: 300,
      isSortable: true,
      renderComponent: ({ item }) => (
        <RoutingLink to={linkToDeviceDetail(item.id)}>{item.name}</RoutingLink>
      ),
    },
    {
      key: "type.name",
      label: "Type",
      minWidth: 300,
    },
    {
      label: "Status",
      key: "status",
      isSortable: true,
      renderComponent: ({ item }) => (
        <DataTable.DataRow.PillCell
          variant={item.status === "active" ? "success" : "default"}
          value={item.status}
        />
      ),
    },
    {
      label: "Created Date",
      key: "created_at",
      isSortable: true,
      renderComponent: ({ item }) => (
        <DataTable.DataRow.DateCell
          value={item.created_at}
          withTime
        />
      ),
    },
  ];

  const actionRow = (
    <Button
      variant="outline"
      onClick={handleOnAddDeviceClick}
    >
      Add Device
    </Button>
  );

  function handleOnAddDeviceClick() {
    navigate({
      pathname: linkToAddDevice(facilityId),
    });
  }

  return (
    <DataTablePanel
      storageKey={tableConfigStorageKey}
      panelProps={{
        title: "Devices",
        actionRow,
      }}
      dataTableProps={{
        items,
        headers,
        isLoading,
        pagination: {
          page: tableState.page,
          itemsPerPage: tableState.itemsPerPage,
          total: data?.total_entries ?? 0,
        },
        onSortChange: updateTableState,
        onPaginationChange: updateTableState,
      }}
    />
  );
};
