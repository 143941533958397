import { validate as uuidValidate } from "uuid";

/**
 * Formats a value to support the mongo-like syntax the backend expects.
 *
 * @param value The atomic value we want to send to the backend
 * @returns A filter value the backend can consume for filter endpoints
 */
const getFilterValue = (value) => {
  if (Array.isArray(value)) {
    return { $in: value };
  }

  if (typeof value === "string") {
    // An exception is made for a string that is a valid uuid since the $like syntax won't work
    if (uuidValidate(value)) {
      return value;
    }

    return { $like: value };
  }

  return value;
};

/**
 * Formats an object's values to support the mongo-like syntax the backend expects.
 *
 * @param filters An object containing key/value pairs we want to filter on
 * @returns A filter object the backend can consume for filter endpoints
 */
export const getFilterObject = (filters) => {
  return Object.fromEntries(
    Object.entries(filters).map(([key, value]) => [key, getFilterValue(value)])
  );
};
