import * as PropTypes from "prop-types";
import React, { useState } from "react";
import InstrumentService from "../../../Services/InstrumentService";
import EditInstrumentDetailsForm from "./EditInstrumentDetailsForm";

import {
  Button,
  Form,
  FormButton,
  Modal,
  Title,
  useAlert,
  useForm,
} from "@validereinc/common-components";

export const EditInstrumentDetailsModal = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const { addAlert } = useAlert();

  const createOption = (label) => ({
    label,
    value: label,
  });

  const [serialNumberOptions, setSerialNumberOptions] = useState(
    props.availableSerialNumbers.map((option) => createOption(option))
  );

  const formMethods = useForm({
    defaultValues: {
      name: props.instrumentName,
      serialNumber: props.serialNumber
        ? createOption(props.serialNumber)
        : props.serialNumber,
    },
  });

  const handleCreateOption = (inputValue) => {
    const newOption = createOption(inputValue);
    formMethods.setValue("serialNumber", newOption);
    setSerialNumberOptions([...serialNumberOptions, newOption]);
  };

  const handleSubmit = (formState) => {
    setIsLoading(true);

    InstrumentService.updateDetails(
      props.instrumentId,
      formState.name,
      formState.serialNumber?.value
    )
      .then(() => {
        props.refetchCalibrationData();
        addAlert({
          variant: "success",
          message: "Instrument details updated successfully.",
        });
        props.onHide();
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Modal
      className="serialNumberModal"
      open={props.show}
      onClose={props.onHide}
    >
      <Modal.Header>
        <Title>{props.instrumentName}</Title>
      </Modal.Header>
      <Form
        onSubmit={handleSubmit}
        {...formMethods}
        className="editDetailsForm"
      >
        <Modal.Body>
          <EditInstrumentDetailsForm
            availableSerialNumbers={serialNumberOptions}
            handleCreateOption={handleCreateOption}
            isLoading={isLoading}
            formMethods={formMethods}
          />
        </Modal.Body>
        <Modal.Footer>
          <div className="clearfix">
            <Button
              onClick={props.onHide}
              className="pull-left"
            >
              Close
            </Button>

            <FormButton
              variant="primary"
              type="submit"
              isLoading={isLoading}
            >
              Update Instrument Details
            </FormButton>
          </div>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

EditInstrumentDetailsModal.propTypes = {
  show: PropTypes.bool.isRequired,
  serialNumber: PropTypes.string,
  availableSerialNumbers: PropTypes.array,
  instrumentId: PropTypes.string.isRequired,
  instrumentName: PropTypes.string.isRequired,
  onHide: PropTypes.func.isRequired,
  addAlertMessage: PropTypes.func.isRequired,
  refetchCalibrationData: PropTypes.func.isRequired,
};

export default EditInstrumentDetailsModal;
