import React from "react";
import UserActivity from "../../User/UserActivity/UserActivity";
import SettingSection from "../../SettingSection";

/* eslint-disable react/prop-types */

const MIN_HEIGHT = 600;

export const UsersActivity = ({ height, isActive }) => {
  const activityTableHeight = Math.max(height, MIN_HEIGHT);

  return (
    <SettingSection title="All User's Activities">
      <div className="usersActivity">
        {isActive && <UserActivity height={activityTableHeight} />}
      </div>
    </SettingSection>
  );
};

export default UsersActivity;
