import React, { useMemo } from "react";

import history from "#routers/history";
import { linkToProductionStreams } from "#routers/links";
import { LegacyDataTable } from "@validereinc/common-components";
import startCase from "lodash/startCase";
import * as PropTypes from "prop-types";
import { AutoSizer } from "react-virtualized";

import { useRecordsOfQuality } from "#hooks/useRecordsOfQuality";
import { CaseInsensitiveRowFilter } from "#utils/filters";
import useFilterInputs from "./StreamsTableFilter";

const HEIGHT_OFFSET = 35;

const StreamsTable = ({
  streams,
  report,
  paginationDetail,
  onPaginationChange,
}) => {
  const [filterRow, filterPillbox, filterValues] = useFilterInputs(
    streams,
    report
  );

  const [recordsOfQualityStreams] = useRecordsOfQuality(streams);

  const filteredStreams = useMemo(() => {
    const { inlet_streams, outlet_streams, reference_streams } = report || {};

    const getStreamCategory = (stream) => {
      if (inlet_streams.map(({ id }) => id).includes(stream.id)) {
        return "Receipt";
      }
      if (outlet_streams.map(({ id }) => id).includes(stream.id)) {
        return "Disposition";
      }
      return "-";
    };

    // Assign the stream category based on the selected report
    const {
      from = [],
      to = [],
      streamCategory = [],
      productCategory = [],
      stream = [],
    } = filterValues || {};

    const filterOnReport = ({ id }) =>
      report
        ? [
            ...inlet_streams.map(({ id }) => id),
            ...outlet_streams.map(({ id }) => id),
            ...reference_streams.map(({ id }) => id),
          ].includes(id)
        : true;

    const filterOnStream = ({ id }) =>
      stream.length ? stream.find(({ id: streamId }) => streamId === id) : true;
    const filterOnStreamCategory = ({ stream_category }) =>
      streamCategory.length ? streamCategory.includes(stream_category) : true;
    const filterOnProductCategory = ({ product_category }) =>
      productCategory.length
        ? CaseInsensitiveRowFilter(product_category, productCategory)
        : true;
    const filterOnFrom = ({ source_facility: { id: sourceId } }) =>
      from.length
        ? from.find(({ id: streamId }) => streamId === sourceId)
        : true;
    const filterOnTo = ({ terminal_facility: { id: terminalId } }) =>
      to.length ? to.find(({ id: streamId }) => streamId === terminalId) : true;

    const streamFilters = [
      filterOnReport,
      filterOnStream,
      filterOnStreamCategory,
      filterOnProductCategory,
      filterOnFrom,
      filterOnTo,
    ];

    return recordsOfQualityStreams.reduce((filteredStreams, stream) => {
      const streamWithReportMetadata = report
        ? { ...stream, stream_category: getStreamCategory(stream) }
        : stream;

      const shouldShowStream = streamFilters.reduce(
        (output, filter) => output && filter(streamWithReportMetadata),
        true
      );
      if (shouldShowStream) {
        filteredStreams.push(streamWithReportMetadata);
      }
      return filteredStreams;
    }, []);
  }, [filterValues, recordsOfQualityStreams]);

  const nestedObjectRenderer = (rowData, columnKey) =>
    columnKey
      .split(".")
      .reduce((object, key) => (object[key] ? object[key] : object), rowData);

  const startCaseRenderer = (rowData, columnKey) =>
    startCase(rowData[columnKey]);

  const onCellClick = (rowData) => {
    history.push(linkToProductionStreams(rowData?.recordOfQualityId));
  };

  const headers = [
    {
      label: "Stream Name",
      key: "name",
      width: 350,
      fixed: true,
    },
    ...(report
      ? [
          {
            label: "Stream Category",
            key: "stream_category",
            width: 150,
          },
        ]
      : []),

    {
      label: "Product Category",
      key: "product_category",
      width: 150,
      cellRenderer: startCaseRenderer,
    },
    {
      label: "From",
      key: "source_facility.name",
      width: 175,
      cellRenderer: nestedObjectRenderer,
    },
    {
      label: "To",
      key: "terminal_facility.name",
      width: 175,
      cellRenderer: nestedObjectRenderer,
    },
  ];

  return (
    <AutoSizer>
      {({ height, width }) => (
        <LegacyDataTable
          width={width}
          height={height - HEIGHT_OFFSET}
          headers={headers}
          list={filteredStreams}
          defaultSortBy="name"
          defaultSortDirection="desc"
          rowHeight={50}
          highlightRow={true}
          onCellClick={onCellClick}
          showOverlayLoader={!recordsOfQualityStreams}
          paginationDetail={paginationDetail}
          onPaginationChange={onPaginationChange}
          filterRow={filterRow}
          filterPillbox={filterPillbox}
        />
      )}
    </AutoSizer>
  );
};

StreamsTable.propTypes = {
  streams: PropTypes.array.isRequired,
  paginationDetail: PropTypes.object.isRequired,
  onPaginationChange: PropTypes.func.isRequired,
  report: PropTypes.object,
};

export default StreamsTable;
