import {
  Button,
  Form,
  Modal,
  RadioInput,
  Title,
  useAlert,
  useForm,
} from "@validereinc/common-components";
import startCase from "lodash/startCase";
import PropTypes from "prop-types";
import React, { useState } from "react";

import { FORM_SUBMISSION_STATUS_OPTIONS } from "#constants";

const UpdateStatusModal = ({
  open,
  onClose,
  doUpdate,
  onUpdate,
  entityTitle,
  currentStatus,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { addAlert } = useAlert();
  const form = useForm({
    defaultValues: {
      status: currentStatus,
    },
  });

  const handleOnClose = () => {
    form.reset({
      status: currentStatus,
    });
    onClose();
  };

  const handleOnSubmit = async ({ status }) => {
    try {
      setIsSubmitting(true);
      await doUpdate({ status });

      addAlert({
        variant: "success",
        message: `${entityTitle} status changed to ${startCase(
          status.toLowerCase()
        )}`,
      });

      if (onUpdate) {
        onUpdate();
      }

      setIsSubmitting(false);
      onClose();
    } catch (err) {
      setIsSubmitting(false);
      addAlert({
        variant: "error",
        message: `Unable to update status of ${entityTitle}.`,
      });
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleOnClose}
    >
      <Modal.Header>
        <Title>Update Status</Title>
      </Modal.Header>
      <Form
        {...form}
        onSubmit={handleOnSubmit}
      >
        <Modal.Body>
          <RadioInput
            label="Select a status"
            name="status"
            isRequired
            labelKey="label"
            valueKey="value"
            options={FORM_SUBMISSION_STATUS_OPTIONS}
          />
        </Modal.Body>
        <Modal.Footer className="clearfix modal-footer">
          <Button onClick={handleOnClose}>Cancel</Button>
          <Button
            type="submit"
            variant="primary"
            disabled={isSubmitting}
          >
            Update Status
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

UpdateStatusModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  doUpdate: PropTypes.func.isRequired,
  onUpdate: PropTypes.func,
  entityTitle: PropTypes.string.isRequired,
  currentStatus: PropTypes.string,
};

export default UpdateStatusModal;
