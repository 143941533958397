import { EQUIPMENT_FILTERS } from "#hooks/filters/useEquipmentFilters";
import { FLOW_FILTERS } from "#hooks/filters/useFlowFilters";
import { useListCustomAttributes } from "#src/components/hooks/adapters/useCustomAttributes";
import { DEFAULT_DATE_RANGES } from "#src/hooks/useDateRange";
import useLocalization from "#src/hooks/useLocalization";
import { hydrateDateRange } from "#utils/date";
import { DateRangeValue } from "@validereinc/common-components";
import { AssetType } from "@validereinc/domain";

export const CUSTOM_REPORT_CONFIGURATION_SETTINGS = {
  dateRange: {
    label: "Date Range",
    name: "date_range",
  },
  reportingScenario: {
    name: "reporting_scenario",
  },
  rows: {
    name: "rows",
    label: "Rows",
    placeholder: "Select rows to group by",
  },
  outputs: {
    name: "outputs",
    label: "Outputs",
    placeholder: "Select outputs to show",
  },
} as const;

export const FLOW_CUSTOM_REPORT_STATIC_ROWS = {
  FLOW: "flow.name",
  FLOW_TYPE: "flow.type",
  FLOW_PRODUCT_TYPE: "flow.product_type",
  FLOW_PRODUCT_CATEGORY: "flow.product_category",
  FLOW_ASSOCIATED_FACILITY: "flow.associated_facility.name",
  FLOW_ASSOCIATED_EQUIPMENT: "flow.associated_equipment.name",
  YEAR_MONTH: "year_month",
} as const;

export const FLOW_CUSTOM_REPORT_GROUP_BY: Record<string, string[]> = {
  [FLOW_CUSTOM_REPORT_STATIC_ROWS.FLOW]: ["flow.id", "flow.name"],
  [FLOW_CUSTOM_REPORT_STATIC_ROWS.FLOW_TYPE]: ["flow.type"],
  [FLOW_CUSTOM_REPORT_STATIC_ROWS.FLOW_PRODUCT_TYPE]: ["flow.product_type"],
  [FLOW_CUSTOM_REPORT_STATIC_ROWS.FLOW_PRODUCT_CATEGORY]: [
    "flow.product_category",
  ],
  [FLOW_CUSTOM_REPORT_STATIC_ROWS.FLOW_ASSOCIATED_FACILITY]: [
    "flow.associated_facility_id",
    "flow.associated_facility.name",
  ],
  [FLOW_CUSTOM_REPORT_STATIC_ROWS.FLOW_ASSOCIATED_EQUIPMENT]: [
    "flow.associated_equipment_id",
    "flow.associated_equipment.name",
  ],
  [FLOW_CUSTOM_REPORT_STATIC_ROWS.YEAR_MONTH]: ["year_month"],
};

export const EQUIPMENT_CUSTOM_REPORT_STATIC_ROWS = {
  EQUIPMENT: "equipment.name",
  EQUIPMENT_STATUS: "equipment.status",
  EQUIPMENT_TYPE: "equipment.type.name",
  FACILITY: "equipment.facility.name",
  FACILITY_STATUS: "equipment.facility.status",
  ESTIMATION_METHOD: "estimation_method_name",
  MEASUREMENT_TYPE: "measurement_type",
  YEAR_MONTH: "year_month",
  CALCULATOR: "estimation_method.analytics_calculator_id",
} as const;

export const EQUIPMENT_CUSTOM_REPORT_GROUP_BY: Record<string, string[]> = {
  [EQUIPMENT_CUSTOM_REPORT_STATIC_ROWS.EQUIPMENT]: [
    "equipment.id",
    "equipment.name",
  ],
  [EQUIPMENT_CUSTOM_REPORT_STATIC_ROWS.EQUIPMENT_STATUS]: ["equipment.status"],
  [EQUIPMENT_CUSTOM_REPORT_STATIC_ROWS.EQUIPMENT_TYPE]: ["equipment.type.name"],
  [EQUIPMENT_CUSTOM_REPORT_STATIC_ROWS.FACILITY]: [
    "equipment.facility.id",
    "equipment.facility.name",
  ],
  [EQUIPMENT_CUSTOM_REPORT_STATIC_ROWS.FACILITY_STATUS]: [
    "equipment.facility.status",
  ],
  [EQUIPMENT_CUSTOM_REPORT_STATIC_ROWS.ESTIMATION_METHOD]: [
    "estimation_method_id",
    "entity_id",
    "estimation_method.name",
  ],
  [EQUIPMENT_CUSTOM_REPORT_STATIC_ROWS.MEASUREMENT_TYPE]: ["measurement_type"],
  [EQUIPMENT_CUSTOM_REPORT_STATIC_ROWS.YEAR_MONTH]: ["year_month"],
  [EQUIPMENT_CUSTOM_REPORT_STATIC_ROWS.CALCULATOR]: [
    "estimation_method.analytics_calculator_id",
  ],
};

const filterKeyToApiKey = {
  [EQUIPMENT_FILTERS.equipmentName.name]: "equipment.id",
  [EQUIPMENT_FILTERS.equipmentType.name]: "equipment.type.id",
  [EQUIPMENT_FILTERS.equipmentStatus.name]: "equipment.status",
  [EQUIPMENT_FILTERS.facilityName.name]: "equipment.facility.id",
  [EQUIPMENT_FILTERS.facilityStatus.name]: "equipment.facility.status",
  [FLOW_FILTERS.flowType.name]: "flow.type",
  [FLOW_FILTERS.flowStatus.name]: "flow.status",
  [FLOW_FILTERS.flowProductType.name]: "flow.product_type",
  [FLOW_FILTERS.flowProductCategory.name]: "flow.product_category",
  [FLOW_FILTERS.flowAssociatedFacility.name]: "flow.associated_facility_id",
  [FLOW_FILTERS.flowAssociatedEquipment.name]: "flow.associated_equipment_id",
};

export const apiKeyToFilterKey = Object.fromEntries(
  Object.entries(filterKeyToApiKey).map(([key, value]) => [value, key])
);

/** Deserialize the configuration values from session storage */
export const parseCustomReportConfiguration = (
  configuration?: unknown
): {
  dateRange: DateRangeValue;
  reportingGroup?: string;
  rows: string[];
  outputs: string[];
} => {
  const {
    [CUSTOM_REPORT_CONFIGURATION_SETTINGS.dateRange.name]: dateRange,
    [CUSTOM_REPORT_CONFIGURATION_SETTINGS.reportingScenario.name]:
      reportingGroup,
    [CUSTOM_REPORT_CONFIGURATION_SETTINGS.rows.name]: rows,
    [CUSTOM_REPORT_CONFIGURATION_SETTINGS.outputs.name]: outputs,
  } = configuration ?? {};

  return {
    dateRange: dateRange
      ? hydrateDateRange(dateRange)
      : DEFAULT_DATE_RANGES.lastTwelveMonths,
    reportingGroup,
    rows: rows ?? [],
    outputs: outputs ?? [],
  };
};

export const useEquipmentRowOptions = ({ enabled }: { enabled: boolean }) => {
  const { localize } = useLocalization();

  const equipmentCustomAttributesQuery = useListCustomAttributes(
    { filters: { entity_type: AssetType.EQUIPMENT } },
    { enabled }
  );
  const facilityCustomAttributesQuery = useListCustomAttributes(
    { filters: { entity_type: AssetType.FACILITY } },
    { enabled }
  );

  const facilityLabel = localize("Facility");
  const equipmentLabel = localize("Equipment");

  const customAttributeOptions = [
    ...(equipmentCustomAttributesQuery.data?.data ?? []).map(
      ({ display_name, field_name }) => ({
        label: `${equipmentLabel} ${display_name}`,
        value: `equipment.custom_attributes.${field_name}`,
      })
    ),
    ...(facilityCustomAttributesQuery.data?.data ?? []).map(
      ({ display_name, field_name }) => ({
        label: `${facilityLabel} ${display_name}`,
        value: `equipment.facility.custom_attributes.${field_name}`,
      })
    ),
  ];

  return [
    {
      label: equipmentLabel,
      value: EQUIPMENT_CUSTOM_REPORT_STATIC_ROWS.EQUIPMENT,
    },
    {
      label: `${equipmentLabel}: Status`,
      value: EQUIPMENT_CUSTOM_REPORT_STATIC_ROWS.EQUIPMENT_STATUS,
    },
    {
      label: `${equipmentLabel}: Type`,
      value: EQUIPMENT_CUSTOM_REPORT_STATIC_ROWS.EQUIPMENT_TYPE,
    },
    {
      label: `${facilityLabel}`,
      value: EQUIPMENT_CUSTOM_REPORT_STATIC_ROWS.FACILITY,
    },
    {
      label: `${facilityLabel}: Status`,
      value: EQUIPMENT_CUSTOM_REPORT_STATIC_ROWS.FACILITY_STATUS,
    },
    {
      label: "Estimation Method",
      value: EQUIPMENT_CUSTOM_REPORT_STATIC_ROWS.ESTIMATION_METHOD,
    },
    {
      label: "Measurement Type",
      value: EQUIPMENT_CUSTOM_REPORT_STATIC_ROWS.MEASUREMENT_TYPE,
    },
    {
      label: "Period",
      value: EQUIPMENT_CUSTOM_REPORT_STATIC_ROWS.YEAR_MONTH,
    },
    {
      label: "Calculator Name",
      value: EQUIPMENT_CUSTOM_REPORT_STATIC_ROWS.CALCULATOR,
    },
    ...customAttributeOptions,
  ];
};

export const useFlowRowOptions = ({ enabled }: { enabled: boolean }) => {
  const { localize } = useLocalization();

  const flowCustomAttributesQuery = useListCustomAttributes(
    { filters: { entity_type: AssetType.FLOW } },
    { enabled }
  );
  const facilityCustomAttributesQuery = useListCustomAttributes(
    { filters: { entity_type: AssetType.FACILITY } },
    { enabled }
  );
  const equipmentCustomAttributesQuery = useListCustomAttributes(
    { filters: { entity_type: AssetType.EQUIPMENT } },
    { enabled }
  );

  const flowLabel = localize("Flow");
  const facilityLabel = localize("Facility");
  const equipmentLabel = localize("Equipment");

  const customAttributeOptions = [
    ...(flowCustomAttributesQuery.data?.data ?? []).map(
      ({ display_name, field_name }) => ({
        label: `${flowLabel}: ${display_name}`,
        value: `flow.custom_attributes.${field_name}`,
      })
    ),
    ...(facilityCustomAttributesQuery.data?.data ?? []).map(
      ({ display_name, field_name }) => ({
        label: `${facilityLabel}: ${display_name}`,
        value: `flow.associated_facility.custom_attributes.${field_name}`,
      })
    ),
    ...(equipmentCustomAttributesQuery.data?.data ?? []).map(
      ({ display_name, field_name }) => ({
        label: `${equipmentLabel}: ${display_name}`,
        value: `flow.associated_equipment.custom_attributes.${field_name}`,
      })
    ),
  ];

  return [
    {
      label: flowLabel,
      value: FLOW_CUSTOM_REPORT_STATIC_ROWS.FLOW,
    },
    {
      label: `${flowLabel}: Type`,
      value: FLOW_CUSTOM_REPORT_STATIC_ROWS.FLOW_TYPE,
    },
    {
      label: `${flowLabel}: Product Type`,
      value: FLOW_CUSTOM_REPORT_STATIC_ROWS.FLOW_PRODUCT_TYPE,
    },
    {
      label: `${flowLabel}: Product Category`,
      value: FLOW_CUSTOM_REPORT_STATIC_ROWS.FLOW_PRODUCT_CATEGORY,
    },
    {
      label: `${flowLabel}: ${facilityLabel}`,
      value: FLOW_CUSTOM_REPORT_STATIC_ROWS.FLOW_ASSOCIATED_FACILITY,
    },
    {
      label: `${flowLabel}: ${equipmentLabel}`,
      value: FLOW_CUSTOM_REPORT_STATIC_ROWS.FLOW_ASSOCIATED_EQUIPMENT,
    },
    {
      label: "Period",
      value: FLOW_CUSTOM_REPORT_STATIC_ROWS.YEAR_MONTH,
    },
    ...customAttributeOptions,
  ];
};
