import React from "react";
import { useStorageKey } from "#src/hooks/useStorageKey";
import { TemplatedReportTablePanel } from "#routes/reports/templated-reports/TemplatedReports/TemplatedReportTablePanel";
import { TemplatedReportFilterPanel } from "#routes/reports/templated-reports/TemplatedReports/TemplatedReportFilterPanel";

export const TemplatedReportsTab = () => {
  const storageKeys = useStorageKey("export-templated-reports");

  return (
    <>
      <TemplatedReportFilterPanel
        filterConfigStorageKey={storageKeys.filterConfigStorageKey}
      />
      <TemplatedReportTablePanel {...storageKeys} />
    </>
  );
};
