import {
  NetworkStatusPillVariants,
  useGetOneNetwork,
} from "#hooks/adapters/useNetworks";
import { useGetManyUsers } from "#hooks/adapters/useUsers";
import { useParams } from "#routers/hooks";
import { NetworkDetailPageParameters } from "#routes/organization/networks/[networkId]/detail";
import {
  StatusPanel,
  StorageKeys,
  useFilters,
} from "@validereinc/common-components";
import { UserType } from "@validereinc/domain";
import {
  datetimeFormatter,
  getYearMonthFromDateRange,
  toStartCaseString,
} from "@validereinc/utilities";
import React from "react";

export const NetworkStatusPanel = ({
  filterConfigStorageKey,
}: Pick<StorageKeys, "filterConfigStorageKey">) => {
  const { networkId } = useParams<NetworkDetailPageParameters>();
  const [filters] = useFilters<{ period: { from?: string } }>(
    filterConfigStorageKey
  );
  const { data: network, isLoading } = useGetOneNetwork(networkId, {
    period: getYearMonthFromDateRange(filters.period),
  });

  const usersQuery = useGetManyUsers([
    network?.created_by,
    network?.updated_by,
  ]);
  const userMap = usersQuery.reduce(
    (accumulator: Record<string, UserType>, current) => {
      if (current.data?.id) {
        accumulator[current.data.id] = current.data;
      }
      return accumulator;
    },
    {}
  );

  const statusPanelData = network
    ? [
        {
          label: "Updated At",
          value: datetimeFormatter(new Date(network?.updated_at)),
        },
        {
          label: "Updated By",
          value: userMap[network?.updated_by]?.name ?? "-",
        },
        {
          label: "Created At",
          value: datetimeFormatter(new Date(network?.created_at)),
        },
        {
          label: "Created By",
          value: userMap[network?.created_by]?.name ?? "-",
        },
      ]
    : [];

  return (
    <StatusPanel
      data={statusPanelData}
      isLoading={isLoading}
      statusText={toStartCaseString(network?.status)}
      statusVariant={NetworkStatusPillVariants[network?.status]}
    />
  );
};
