import React from "react";
import { useStorageKey } from "#src/hooks/useStorageKey";
import { ExportHistoryTablePanel } from "#routes/reports/templated-reports/ExportHistory/ExportHistoryTablePanel";
import { ExportHistoryFilterPanel } from "#routes/reports/templated-reports/ExportHistory/ExportHistoryFilterPanel";

export const ExportHistoryTab = () => {
  const storageKeys = useStorageKey("export-templated-reports");

  return (
    <>
      <ExportHistoryFilterPanel
        filterConfigStorageKey={storageKeys.filterConfigStorageKey}
      />
      <ExportHistoryTablePanel {...storageKeys} />
    </>
  );
};
