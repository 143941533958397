import NotificationService from "#services/NotificationService";
import { ExceptionUtils } from "#src/utils/exception";
import {
  LegacyDataTable,
  Panel,
  useAlert,
} from "@validereinc/common-components";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Checkbox } from "react-bootstrap";
import { AutoSizer } from "react-virtualized";
import "./UserNotificationSettings.scss";

const LIST_HEIGHT_OFFSET = 200;

const MIN_LIST_HEIGHT = 150;

const useFetchNotificationSettings = () => {
  const [notificationSettings, setNotificationSettings] = useState({});

  useEffect(() => {
    NotificationService.getUserAndSiteNotificationSettings()
      .then(({ data }) => {
        setNotificationSettings(data);
      })
      .catch((err) => {
        ExceptionUtils.reportException(err);
      });
  }, []);

  return [notificationSettings];
};

const useManageSiteNotificationSettings = (sites) => {
  const [siteNotificationSettings, setSiteNotificationSettings] = useState([]);
  const [isInitialized, setIsInitialized] = useState(false);

  const [areCheckboxesDisabled, setAreCheckboxesDisabled] = useState(false);

  const [notificationSettings] = useFetchNotificationSettings();

  const { addAlert } = useAlert();

  useEffect(() => {
    if (sites?.length && notificationSettings?.sites && !isInitialized) {
      const siteNotificationSettings = Object.entries(
        notificationSettings.sites
      ).map(([siteId, settings]) => {
        return {
          siteId,
          // JS object properties can only be strings or Symbols, the siteId
          // is casted to string since it is a property of notificationSettings.sites
          // Using non-strict comparison for integer site.id and string siteId
          siteName: sites.find((site) => site.id == siteId)?.name ?? "",
          ...settings,
        };
      });

      setSiteNotificationSettings(siteNotificationSettings);
      setIsInitialized(true);
    }
  }, [sites, notificationSettings]);

  const onCheckBoxClick = (rowData, key) => {
    setAreCheckboxesDisabled(true);

    const updatedNotificationSettings = {
      ...rowData,
      [key]: rowData[key]?.length ? [] : ["email"],
    };

    NotificationService.updateUserNotificationSettings(
      updatedNotificationSettings.siteId,
      updatedNotificationSettings
    )
      .then(() => {
        setSiteNotificationSettings((siteNotificationSettings) =>
          siteNotificationSettings.map((settings) => {
            return settings.siteId === updatedNotificationSettings.siteId
              ? updatedNotificationSettings
              : settings;
          })
        );
        addAlert({
          variant: "success",
          message: `${rowData.siteName} Notification settings successfully updated.`,
        });
      })
      .finally(() => {
        setAreCheckboxesDisabled(false);
      });
  };

  return [
    siteNotificationSettings,
    onCheckBoxClick,
    isInitialized,
    areCheckboxesDisabled,
  ];
};

const UserNotificationSettingsList = (props) => {
  const height = Math.max(props.height - LIST_HEIGHT_OFFSET, MIN_LIST_HEIGHT);

  const [
    siteNotificationSettings,
    onCheckboxClick,
    isInitialized,
    areCheckboxesDisabled,
  ] = useManageSiteNotificationSettings(props.sites);

  const checkboxRenderer = (rowData, columnKey) => {
    return (
      <Checkbox
        checked={rowData[columnKey]?.length}
        onChange={() => onCheckboxClick(rowData, columnKey)}
        disabled={areCheckboxesDisabled}
      />
    );
  };

  const headers = [
    {
      label: "Site",
      key: "siteName",
      width: 350,
      fixed: true,
    },
    {
      label: "Non Routine",
      key: "tests.non_routine",
      width: 100,
      disableSort: true,
      cellRenderer: checkboxRenderer,
    },
    {
      label: "Off Spec",
      key: "tests.offspec",
      width: 100,
      disableSort: true,
      cellRenderer: checkboxRenderer,
    },
    {
      label: "Orphan",
      key: "tests.orphan",
      width: 100,
      disableSort: true,
      cellRenderer: checkboxRenderer,
    },
    {
      label: "Alert Digest",
      key: "tests.alert_digest",
      width: 100,
      disableSort: true,
      cellRenderer: checkboxRenderer,
    },
  ];

  return (
    <Panel
      className="userNotificationSettings__panel"
      style={{ height }}
      loaded={isInitialized}
    >
      <AutoSizer>
        {({ height, width }) => (
          <LegacyDataTable
            width={width}
            height={height}
            headers={headers}
            list={siteNotificationSettings}
            filterKey="siteName"
            defaultSortBy="siteName"
            defaultSortDirection="asc"
          />
        )}
      </AutoSizer>
    </Panel>
  );
};

UserNotificationSettingsList.propTypes = {
  height: PropTypes.number,
  sites: PropTypes.array.isRequired,
};

export default UserNotificationSettingsList;
