import { AssetGroupDomain, AssetGroupStatusType } from ".";
import { CustomAttributeRecordType } from "../schemas";
import { GetOneRequestType } from "../util";

export type CreateNetworkType = {
  name: string;
  status: AssetGroupStatusType;
  flow_ids?: string[];
  custom_attributes?: CustomAttributeRecordType;
  reporting_group_id?: string;
};

export type UpdateNetworkType = {
  assetGroupId: string;
  name?: string;
  status?: AssetGroupStatusType;
  flow_ids?: string[];
  reporting_group_id?: string;
  custom_attributes?: CustomAttributeRecordType;
  start_date?: string;
  end_date?: string;
};

export const NetworkDomain = {
  /**
   * a convenience method to get a single Network (otherwise known as a Asset Group) through the AssetGroupDomain
   */
  getNetwork: async ({ id, meta }: GetOneRequestType<{ period?: string }>) =>
    AssetGroupDomain.getOne({ id, meta: { period: meta?.period ?? "" } }),
  /**
   * a convenience method to create a Network (otherwise known as a Asset Group) through the AssetGroupDomain
   */
  createNetwork: async ({
    flow_ids = [],
    name,
    status,
    custom_attributes,
    reporting_group_id,
  }: CreateNetworkType) =>
    AssetGroupDomain.create({
      flow_ids,
      name,
      status,
      custom_attributes,
      reporting_group_id,
    }),
  /**
   * a convenience method to create a Network (otherwise known as a Asset Group) through the AssetGroupDomain
   */
  updateNetwork: async ({
    assetGroupId,
    status,
    name,
    custom_attributes,
    reporting_group_id,
    ...rest
  }: UpdateNetworkType) =>
    AssetGroupDomain.update({
      assetGroupId,
      name,
      status,
      reporting_group_id,
      custom_attributes,
      ...rest,
    }),

  /**
   * a convenience method to delete a Network (otherwise known as a Asset Group) through the AssetGroupDomain
   */
  deleteNetwork: async ({ assetGroupId }: { assetGroupId: string }) =>
    AssetGroupDomain.delete({ assetGroupId }),
};
