import { useState, useEffect } from "react";
import AlertingService from "#components/Services/AlertingService";

export const useAlertDetail = (alertId) => {
  const [isAlertDetailLoading, setIsAlertDetailLoading] = useState(true);
  const [alertDetail, setAlertDetail] = useState({});

  useEffect(() => {
    (async () => {
      const response = await AlertingService.getAlertDetail(alertId);
      setAlertDetail(response.data);
      setIsAlertDetailLoading(false);
    })();
  }, [alertId]);

  return [alertDetail, isAlertDetailLoading];
};
