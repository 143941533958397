export const SAMPLE_TYPES = {
  SPOT: "spot",
  COMPOSITE: "composite",
  UNKNOWN: "unknown",
  VIRTUAL: "virtual",
};

export const INSTRUMENT_TYPES = {
  FIELD: "field",
  INLINE: "inline",
  VIRTUAL_INLINE: "inline_virtual",
  THIRD_PARTY_LAB: null,
};

const INSTRUMENT_MODELS = [
  "anton paar density",
  "anton paar svm 3000",
  "anton paar svm 3001",
  "eralytics vapor",
  "horiba xray fluorescence",
  "rigaku xray fluorescence",
  "rudolph research density",
  "water",
  "validere vapor",
  "agilent gas chromatography",
  "aquamax kf",
];

export function userFacingInstrumentType(instrumentType) {
  switch (instrumentType) {
    case INSTRUMENT_TYPES.FIELD:
      return "Field Lab";
    case INSTRUMENT_TYPES.INLINE:
      return "Inline Analyzer";
    case INSTRUMENT_TYPES.VIRTUAL_INLINE:
      return "Virtual Inline Analyzer";
    case INSTRUMENT_TYPES.THIRD_PARTY_LAB:
      return "Third Party Lab";
    default:
      console.error(`Unhandled instrument type: '${instrumentType}'`);
      return "Unknown";
  }
}

export function isValidInstrumentModel(instrumentModel) {
  if (!instrumentModel) {
    return false;
  }

  const isValid = INSTRUMENT_MODELS.includes(instrumentModel.toLowerCase());

  if (!isValid) {
    console.error(`Unhandled instrument type: '${instrumentModel}'`);
  }

  return isValid;
}

export const TIME_GROUPINGS = {
  None: null,
  Minute: 60,
  "15 Minute": 900,
  Hour: 3600,
  Day: 86400,
  Week: 604800,
  "30 Day": 2592000,
  Year: 31536000,
};

export const NOTIFICATION_CONFIG = {
  "tests.offspec": {
    title: "Off-spec Notification",
    message:
      "Receive a notification when there are tests outside the measurement limits.",
  },
  "tests.non_routine": {
    title: "Non-routine Notification",
    message:
      "Receive a notification when a test is not associated to a scheduled workflow.",
  },
  "tests.orphan": {
    title: "Orphan Notification",
    message:
      "Receive a notification when a test is not associated to an available stream.",
  },
};

export const NOTIFICATION_OPTIONS = [
  { id: "email", name: "Email Notification", value: ["email"] },
  { id: "none", name: "None", value: [] },
];

export const BALANCE_SOURCE_TYPES = {
  INLETS_ONLY: "inletsOnly",
  OUTLETS_ONLY: "outletsOnly",
  INLETS_AND_OUTLETS: "inletsAndOutlets",
};
