import { DeviceConfiguration } from "#batteries-included-components/Layouts/RecordConfiguration/DeviceConfiguration";
import { EstimationMethodConfiguration } from "#batteries-included-components/Layouts/RecordConfiguration/EstimationMethodConfiguration";
import { FormConfiguration } from "#batteries-included-components/Layouts/RecordConfiguration/FormConfiguration";
import { ManualEntryConfiguration } from "#batteries-included-components/Layouts/RecordConfiguration/ManualEntryConfiguration";
import { RecordValueConfigurationContext } from "#batteries-included-components/Layouts/RecordConfiguration/RecordConfigurationContext";
import { useSearchParams } from "#src/Routers/hooks";
import { DropdownInput, Panel } from "@validereinc/common-components";
import { RecordValueConfigurationTypeType } from "@validereinc/domain";
import { yearMonthName } from "@validereinc/utilities";
import React, { useContext, useEffect, useMemo } from "react";

export const RecordConfigurationPanel = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const { record, measurementType, form } =
    useContext(RecordValueConfigurationContext) || {};

  const recordInitialConfigurationType = record?.values.find(
    (v) => v.measurement_type === measurementType?.id
  )?.configuration.configuration_type;

  const activeKey = searchParams.tab ?? RecordValueConfigurationTypeType.MANUAL;

  useEffect(() => {
    if (recordInitialConfigurationType)
      setSearchParams({ tab: recordInitialConfigurationType });
  }, [recordInitialConfigurationType]);

  useEffect(() => {
    form?.setValue("configuration_type", activeKey);
  }, [activeKey]);

  const dropdownOptions = useMemo(
    () => [
      {
        dataKey: RecordValueConfigurationTypeType.CALCULATION,
        label: "Estimation Methods",
      },
      {
        dataKey: RecordValueConfigurationTypeType.MEASUREMENT,
        label: "Form Submission",
      },
      {
        dataKey: RecordValueConfigurationTypeType.MEASUREMENT_SERIES,
        label: "Device",
      },
      {
        dataKey: RecordValueConfigurationTypeType.MANUAL,
        label: "Manual Entry",
      },
    ],
    []
  );

  return (
    <Panel title="Sources">
      {record && measurementType ? (
        <p>
          Select a source and aggregation function to assign as the{" "}
          {measurementType?.name} record configuration for the period of{" "}
          {record && yearMonthName(record?.year_month)}
        </p>
      ) : null}

      <DropdownInput
        style={{ width: "355px" }}
        inputId="SourceTypePicker"
        label="Source Type"
        name={"sourceType"}
        placeholder="Select Source Type"
        options={dropdownOptions}
        defaultValue={recordInitialConfigurationType ?? searchParams.tab}
        labelKey="label"
        valueKey="dataKey"
        isLoading={false}
        isRequired
        isClearable={false}
        onChange={(tab) => setSearchParams({ tab })}
      />
      {activeKey === RecordValueConfigurationTypeType.CALCULATION ? (
        <EstimationMethodConfiguration />
      ) : null}
      {activeKey === RecordValueConfigurationTypeType.MEASUREMENT ? (
        <FormConfiguration />
      ) : null}
      {activeKey === RecordValueConfigurationTypeType.MEASUREMENT_SERIES ? (
        <DeviceConfiguration />
      ) : null}
      {activeKey === RecordValueConfigurationTypeType.MANUAL ? (
        <ManualEntryConfiguration />
      ) : null}
    </Panel>
  );
};
