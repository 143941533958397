import { linkToFormSubmissionDetail } from "#src/Routers/links";
import { RoutingLink } from "#src/batteries-included-components/RoutingLink";
import { getStatusType } from "#src/components/Common/Table/rendererHelper";
import { useTableSortingAndPagination } from "#src/components/Redux/reducers/tableStateReducer";
import { linkToFormCategoryDetails } from "#src/routes/forms/categories/[categoryId]";
import { linkToFormTemplateDetail } from "#src/routes/forms/categories/[categoryId]/templates/[formTemplateId]";
import { UseQueryOptions, useQueries, useQuery } from "@tanstack/react-query";
import {
  DataTable,
  DataTablePanel,
  HeaderType,
  SortingType,
  StorageKeys,
  useFilters,
} from "@validereinc/common-components";
import {
  FormSubmissionFilterType,
  SortDirection,
  UserType,
  UsersAdapter,
  WorkflowAdapter,
  WorkflowFormSubmissionType,
} from "@validereinc/domain";
import { toStartCaseString } from "@validereinc/utilities";
import React, { useMemo } from "react";

const sorting: SortingType = {
  sortBy: "created_at",
  sortDirection: SortDirection.DESCENDING,
};

export const WorkflowFormSubmissionsTablePanel = ({
  workflowId,
  filterConfigStorageKey,
  tableConfigStorageKey,
}: {
  workflowId: string;
} & StorageKeys) => {
  const [filters] = useFilters<FormSubmissionFilterType>(
    filterConfigStorageKey
  );

  const [tableState, updateTableState] = useTableSortingAndPagination(sorting);

  const requestPayload: Parameters<typeof WorkflowAdapter.forms.getList>[0] = {
    workflowId,
    page: tableState.page,
    pageSize: tableState.itemsPerPage,
    sortBy: tableState.sortBy,
    sortDirection: tableState.sortDirection,
    filters: filters,
  };

  const query = useQuery({
    queryKey: ["workflows", "forms", requestPayload],
    queryFn: async () => {
      const { data, total_entries } =
        await WorkflowAdapter.forms.getList(requestPayload);
      updateTableState({ total: total_entries, isTotalKnown: true });
      return data;
    },
    staleTime: 5 * 60 * 1000,
  });

  const formSubmissionsCreatedByUsers = useQueries<
    Array<
      UseQueryOptions<
        Awaited<ReturnType<typeof UsersAdapter.getOne>> | undefined,
        unknown,
        UserType | undefined
      >
    >
  >({
    queries:
      query?.data?.map((submission: WorkflowFormSubmissionType) => ({
        queryKey: ["users", submission.created_by],
        queryFn: () =>
          UsersAdapter.getOne({
            id: submission.created_by,
          }),
        enabled: Boolean(submission.created_by),
        staleTime: 3 * 60 * 1000,
        select: (resp) => resp?.data,
      })) ?? [],
  });

  const formSubmissionsCreatedByUsersMap = useMemo(() => {
    return formSubmissionsCreatedByUsers.reduce<Record<string, UserType>>(
      (map, q) => {
        if (!q.data?.id || map[q.data.id]) {
          return map;
        }

        map[q.data.id] = q.data;
        return map;
      },
      {}
    );
  }, [formSubmissionsCreatedByUsers]);

  const submissionsHeaders: Array<HeaderType<WorkflowFormSubmissionType>> = [
    {
      label: "Name",
      key: "id",
      isSortable: false,
      renderComponent: ({ item }) => (
        <RoutingLink to={linkToFormSubmissionDetail(item.id)}>
          {`${item?.form_schema?.name} - ${item?.id?.slice(0, 7)}`}
        </RoutingLink>
      ),
    },
    {
      label: "Template",
      key: "form_template.name",
      isSortable: false,
      renderComponent: ({ item }) => (
        <RoutingLink
          to={linkToFormTemplateDetail(
            item.form_schema?.form_category?.id,
            item.form_schema?.id
          )}
        >
          {item?.form_schema?.name}
        </RoutingLink>
      ),
    },
    {
      label: "Category",
      key: "form_template.form_category.name",
      isSortable: false,
      renderComponent: ({ item }: { item: WorkflowFormSubmissionType }) => (
        <RoutingLink
          to={linkToFormCategoryDetails(item.form_schema?.form_category?.id)}
        >
          {item?.form_schema?.form_category?.name}
        </RoutingLink>
      ),
    },
    {
      label: "Status",
      key: "status",
      isSortable: true,
      renderComponent: ({ item }) => (
        <DataTable.DataRow.PillCell
          variant={getStatusType(item.status, "form").type}
          value={toStartCaseString(item.status)}
        />
      ),
    },
    {
      label: "Associated Task",
      key: "task",
      isSortable: false,
      renderComponent: ({ item }) => {
        if (item?.is_workflow_trigger) {
          return "Workflow Trigger";
        } else if (item?.associated_tasks?.length) {
          return item?.associated_tasks?.[0]?.workflow_task_name;
        } else {
          return "-";
        }
      },
    },
    {
      label: "Submission Date",
      key: "updated_at",
      isSortable: true,
      renderComponent: ({ item }) => (
        <DataTable.DataRow.DateCell
          value={item.updated_at}
          withTime={false}
        />
      ),
    },
    {
      label: "Submitted By",
      key: "created_by",
      isSortable: true,
      renderComponent: ({ item }) =>
        formSubmissionsCreatedByUsersMap[item.created_by]?.name ?? "-",
    },
  ];

  return (
    <DataTablePanel
      storageKey={tableConfigStorageKey}
      panelProps={{
        title: "Form Submissions",
      }}
      dataTableProps={{
        headers: submissionsHeaders,
        isLoading: query?.isLoading,
        items: query?.data ?? [],
        pagination: {
          page: tableState.page,
          itemsPerPage: tableState.itemsPerPage,
          total: tableState.total,
        },
        sorting,
        onSortChange: updateTableState,
        onPaginationChange: updateTableState,
      }}
    />
  );
};
