import { useGetCalculator } from "#src/components/hooks/useCalculator";
import { useGetEstimationMethod } from "#src/components/hooks/useEstimationMethod";
import { QueryStatusType } from "#src/constants";
import { useQuery } from "@tanstack/react-query";
import type {
  AssetTypeType,
  CalculatorType,
  CalculatorVersionType,
  EstimationMethodDefaultRecordType,
  EstimationMethodType,
  GetListResponseType,
  UserType,
} from "@validereinc/domain";
import { EstimationMethodDomain } from "@validereinc/domain";
import React, { PropsWithChildren, createContext, useContext } from "react";

// NOTE: intentionally typed `any` as entity type is determined when consuming this context
export const EstimationMethodDetailContext = createContext<
  EstimationMethodDetailContextType<any>
>({});

export const useEstimationMethodDetailContext = function <TEntity>() {
  const context = useContext<EstimationMethodDetailContextType<TEntity>>(
    EstimationMethodDetailContext
  );

  if (context === null) {
    throw new Error(
      "Must be called within EstimationMethodDetailContextProvider."
    );
  }

  return context;
};

export function EstimationMethodDetailContextProvider<TEntity>({
  estimationMethodId,
  entityType,
  entityId,
  entityDetail,
  entityFetchStatus,
  children,
}: PropsWithChildren<EstimationMethodDetailContextProviderProps<TEntity>>) {
  const [methodDetail, methodDetailFetchStatus, methodRefetch] =
    useGetEstimationMethod(estimationMethodId, entityType);
  const [calculatorDetail, calculatorDetailFetchStatus] = useGetCalculator(
    methodDetail?.analytics_calculator_id
  );

  const {
    data: defaultRecordsData,
    status: defaultRecordStatus,
    refetch: refetchDefaultRecords,
  } = useQuery({
    queryKey: ["estimationMethod", estimationMethodId, "defaultRecords"],
    queryFn: () =>
      EstimationMethodDomain.defaultRecords.getList({
        id: estimationMethodId,
        meta: {
          entityType,
        },
      }),
  });

  const calculatorVersion = calculatorDetail?.versions.find(
    ({ version }) => version === calculatorDetail?.default_version
  );

  // NOTE: intentionally written for type assertion
  const EstimationMethodDetailContextTyped =
    EstimationMethodDetailContext as React.Context<
      EstimationMethodDetailContextType<TEntity>
    >;

  return (
    <EstimationMethodDetailContextTyped.Provider
      value={{
        entityType,
        entityId,
        method: {
          data: methodDetail,
          status: methodDetailFetchStatus,
          refetch: methodRefetch,
        },
        entity: {
          data: entityDetail,
          status: entityFetchStatus,
        },
        calculator: {
          data: calculatorDetail,
          status: calculatorDetailFetchStatus,
        },
        calculatorVersion,
        defaultRecords: {
          data: defaultRecordsData,
          status: defaultRecordStatus,
          refetch: refetchDefaultRecords,
        },
      }}
    >
      {children}
    </EstimationMethodDetailContextTyped.Provider>
  );
}

export type DataDetailResultType<T> = {
  data?: T;
  status: QueryStatusType;
  refetch?: () => void;
};

export type EstimationMethodWithUserDataType = EstimationMethodType & {
  createdBy?: UserType;
  updatedBy?: UserType;
};

export type EstimationMethodDetailContextType<TEntity> = {
  method?: DataDetailResultType<EstimationMethodWithUserDataType>;
  entity?: DataDetailResultType<TEntity>;
  calculator?: DataDetailResultType<CalculatorType>;
  defaultRecords?: DataDetailResultType<
    GetListResponseType<EstimationMethodDefaultRecordType>
  >;
  calculatorVersion?: CalculatorVersionType;
} & Pick<
  EstimationMethodDetailContextProviderProps<TEntity>,
  "entityType" | "entityId"
>;

export type EstimationMethodDetailContextProviderProps<TEntity> = {
  estimationMethodId: string;
  entityType: AssetTypeType;
  entityId: string;
  entityDetail: TEntity;
  entityFetchStatus: QueryStatusType;
};
