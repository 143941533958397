export const EDIT_ROLE_PROFILE_TITLE = "Edit Role";

export const linkToRoleEditPage = (roleId = ":roleId") =>
  `/app/settings/roles-and-permissions/roles/${roleId}/edit`;

export const EDIT_ROLE_BREADCRUMB = {
  title: EDIT_ROLE_PROFILE_TITLE,
  path: linkToRoleEditPage,
};

export * from "./EditRolePage";
