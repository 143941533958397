import { getMeasurementType } from "#redux/reducers/measurements";
import { LegacyDataTable, Panel } from "@validereinc/common-components";
import * as PropTypes from "prop-types";
import React, { useMemo } from "react";
import { connect } from "react-redux";
import { AutoSizer } from "react-virtualized";
import {
  getCustomMeasurementHeaders,
  getMeasurementPanelHeight,
  getMeasurementRenderers,
  MEASUREMENT_ROW_HEIGHT,
  MEASUREMENT_TABLE_OVERFLOW,
} from "./TestMeasurementTableHelper";

const mapStateToProps = (state) => {
  return {
    getMeasurementType: (measurement) =>
      getMeasurementType(state.measurements)(measurement),
  };
};

const TestMeasurementTable = ({
  title,
  testDetail,
  getMeasurementType,
  displayProperties,
}) => {
  const customMeasurementHeaders = useMemo(() => {
    return getCustomMeasurementHeaders(testDetail, getMeasurementType);
  }, [testDetail, getMeasurementType]);

  const {
    measurementNameRenderer,
    measurementRenderer,
    alertSpecRenderer,
    alertTypeRenderer,
  } = useMemo(() => {
    return getMeasurementRenderers(testDetail, getMeasurementType);
  }, [testDetail, getMeasurementType]);

  const measurementHeaders = customMeasurementHeaders.length
    ? customMeasurementHeaders
    : [
        {
          label: "Value",
          key: "value",
          cellRenderer: measurementRenderer,
          width: 165,
          disableSort: true,
          rightAlign: true,
        },
      ];

  const headers = [
    {
      label: "Measurement",
      key: "label",
      width: 200,
      disableSort: true,
      fixed: true,
      cellRenderer: measurementNameRenderer,
    },
    ...measurementHeaders,
    {
      label: "Alert Type",
      key: "alertType",
      cellRenderer: alertTypeRenderer,
      width: 150,
      disableSort: true,
    },
    {
      label: "Alert Threshold",
      key: "alertSpec",
      cellRenderer: alertSpecRenderer,
      width: 150,
      disableSort: true,
    },
  ];
  return (
    <Panel
      title={title}
      style={getMeasurementPanelHeight(displayProperties)}
    >
      <AutoSizer>
        {({ height, width }) => (
          <LegacyDataTable
            height={height - MEASUREMENT_TABLE_OVERFLOW}
            width={width}
            headers={headers}
            list={displayProperties}
            defaultSortBy="started_at"
            defaultSortDirection="desc"
            rowHeight={MEASUREMENT_ROW_HEIGHT}
          />
        )}
      </AutoSizer>
    </Panel>
  );
};

TestMeasurementTable.propTypes = {
  testDetail: PropTypes.object.isRequired,
  displayProperties: PropTypes.array.isRequired,
  getMeasurementType: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

export default connect(mapStateToProps, undefined)(TestMeasurementTable);
