import {
  Button,
  DataTable,
  Panel,
  useFieldArray,
  useFormContext,
} from "@validereinc/common-components";
import React, { useState } from "react";
import { CreateFormTemplateAddQuestionDrawer } from "./CreateFormTemplateAddQuestionDrawer";

import {
  ReorderDirection,
  ReorderDirectionType,
  FormSchemaQuestionType,
  FormSchemaDisplayQuestionType,
} from "@validereinc/domain";
import { DeleteQuestionDialog } from "./DeleteQuestionDialog";
import { moveItem } from "#src/utils/moveItem";

export const CreateFormTemplateQuestionTable = ({ name }: { name: string }) => {
  const parentForm = useFormContext();

  const [isAddQuestionDrawerOpen, setIsAddQuestionDrawerOpen] = useState(false);
  const [questionIdToDelete, setQuestionIdToDelete] = useState<string>("");

  const { fields, move } = useFieldArray({
    control: parentForm.control,
    name: name,
  });

  const sectionQuestions = parentForm.watch(name);

  const questions = parentForm.watch("config.questions");

  const displayQuestions = sectionQuestions.reduce(
    (
      total: FormSchemaQuestionType[],
      question: FormSchemaQuestionType,
      index: number
    ) => {
      const questionDetails: FormSchemaDisplayQuestionType = {
        id: question,
        question_order: `#${index + 1}`,
        ...questions[question],
      };

      if (!questionDetails) {
        return total;
      } else {
        return [...total, questionDetails];
      }
    },
    [] as FormSchemaQuestionType[]
  );

  const moveQuestion = (
    indexToSwapFrom: number,
    direction: ReorderDirectionType
  ) => {
    moveItem(fields, move, indexToSwapFrom, direction);
  };

  const deleteQuestionfromSectionQuestions = (questionIdToDelete: string) => {
    let newSectionQuestions = [...sectionQuestions];
    newSectionQuestions = newSectionQuestions.filter(
      (questionId) => questionId !== questionIdToDelete
    );
    parentForm.setValue(name, [...newSectionQuestions]);
  };
  const deleteQuestionFromConfigQuestions = (questionIdToDelete: string) => {
    const newQuestions = { ...questions };
    delete newQuestions[questionIdToDelete];
    parentForm.setValue("config.questions", { ...newQuestions });
  };

  const handleDeleteQuestion = (questionIdToDelete: string) => {
    deleteQuestionfromSectionQuestions(questionIdToDelete);
    deleteQuestionFromConfigQuestions(questionIdToDelete);
  };

  const getItemActions = ({
    item,
  }: {
    item: FormSchemaDisplayQuestionType;
  }) => {
    const questionIndex = parseInt(item.question_order.slice(1)) - 1;
    return [
      ...(questionIndex !== 0
        ? [
            {
              label: "Move Up",
              buttonProps: {
                onClick: () => moveQuestion(questionIndex, ReorderDirection.UP),
                icon: "arrow-circle-up",
              },
            },
          ]
        : []),
      ...(questionIndex !== displayQuestions.length - 1
        ? [
            {
              label: "Move Down",
              buttonProps: {
                onClick: () =>
                  moveQuestion(questionIndex, ReorderDirection.DOWN),
                icon: "arrow-circle-down",
              },
            },
          ]
        : []),
      ...[
        {
          label: "Delete",
          buttonProps: {
            onClick: () => {
              setQuestionIdToDelete(item.id);
            },
            icon: "trash",
            className: "button--error-outline",
          },
        },
      ],
    ];
  };

  const onCloseAddQuestionDrawer = () => {
    setIsAddQuestionDrawerOpen(false);
  };

  const onOpenAddQuestionDrawer = () => {
    setIsAddQuestionDrawerOpen(true);
  };

  const onSaveQuestionDrawer = (newQuestionId: string) => {
    parentForm.setValue(name, [...sectionQuestions, newQuestionId]);
  };

  const CreateQuestionButton = () => (
    <Button
      icon="plus-circle"
      onClick={onOpenAddQuestionDrawer}
    >
      Create Question
    </Button>
  );

  return (
    <>
      <Panel
        title="Questions"
        actionRow={
          displayQuestions?.length
            ? [<CreateQuestionButton key="create-question-button" />]
            : undefined
        }
      >
        <DataTable
          headers={[
            {
              key: "question_order",
              label: "Question Order",
            },
            { key: "prompt", label: "Prompt" },
            { key: "description", label: "Description" },
            { key: "type", label: "Question Type" },
          ]}
          items={displayQuestions}
          emptyStateProps={{
            title: "There are no questions in this section",
            suggestion: 'Press the "Create Question" button to add a question',
            action: <CreateQuestionButton />,
          }}
          getItemActions={getItemActions}
        />
      </Panel>

      <CreateFormTemplateAddQuestionDrawer
        isOpen={isAddQuestionDrawerOpen}
        onClose={onCloseAddQuestionDrawer}
        onSave={onSaveQuestionDrawer}
      />
      <DeleteQuestionDialog
        isOpen={!!questionIdToDelete}
        onClose={() => {
          setQuestionIdToDelete("");
        }}
        handleDeleteQuestion={handleDeleteQuestion}
        questionIdToDelete={questionIdToDelete}
      />
    </>
  );
};
