import { colours } from "@validereinc/common-components";
import type { LayerProps } from "react-map-gl";

export function findNearestPoint(selectedPoint, pointsArray) {
  let nearestPoint = null;
  let minDistance = 10;

  for (const point of pointsArray) {
    const distance = Math.sqrt(
      (selectedPoint.latitude - point.latitude) ** 2 +
        (selectedPoint.longitude - point.longitude) ** 2
    );

    if (distance < minDistance) {
      minDistance = distance;
      nearestPoint = point;
    }
  }

  return nearestPoint;
}

export const managePopup = (
  map: any,
  layerId = "unclustered-circle",
  mouseEnterCallback: (properties: any, point: any) => void
) => {
  const handleMouseEnter = async (e) => {
    if (!map) {
      return;
    }
    map.getCanvas().style.cursor = "pointer";

    const coordinates = e.features[0].geometry.coordinates.slice();

    while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
      coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
    }
    const properties = e.features[0].properties;

    const point = e.point;

    mouseEnterCallback(properties, point);
    map.setPaintProperty("unclustered-circle", "circle-opacity", 0);
    map.setPaintProperty("unclustered-point", "text-opacity", 0);
  };

  map.on("mouseenter", layerId, handleMouseEnter);
};

const colourText400 = colours.customProperties["--colour-text-400"];
export const clusterLayer: LayerProps = {
  id: "clusters",
  type: "circle",
  source: "facilities",
  filter: ["has", "point_count"],
  paint: {
    "circle-color": [
      "step",
      ["get", "point_count"],
      colourText400,
      100,
      colourText400,
      750,
      colourText400,
    ],
    "circle-radius": ["step", ["get", "point_count"], 20, 100, 30, 750, 40],
  },
};

export const clusterCountLayer: LayerProps = {
  id: "cluster-count",
  type: "symbol",
  source: "facilities",
  filter: ["has", "point_count"],
  paint: { "text-color": "white" },
  layout: {
    "text-field": "{point_count_abbreviated}",
    "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
    "text-size": 12,
  },
};

export const unclusteredCircleLayer: LayerProps = {
  id: "unclustered-circle",
  type: "circle",
  source: "facilities",
  filter: ["!", ["has", "point_count"]],
  paint: {
    "circle-color": "#666",
    "circle-radius": 8,
    "circle-stroke-width": 0,
    "circle-stroke-color": "#fff",
  },
};

export const unclusteredPointLayer: LayerProps = {
  id: "unclustered-point",
  type: "symbol",
  source: "facilities",
  filter: ["!", ["has", "point_count"]],
  layout: {
    // "icon-image": "building", // marker
    "icon-size": 1.5,
    "text-field": "{name}",
    "text-font": ["Open Sans Regular"],
    "text-offset": [0, 0.8],
    "text-anchor": "top",
    "text-size": 12,
  },
};
