import { EVENTS_BREADCRUMB } from "#routes/events";
import { EventsFilterPanel } from "#src/batteries-included-components/Panels/FilterPanels/EventsFilterPanel";
import { EventsTablePanel } from "#src/batteries-included-components/Panels/TablePanels/EventsTablePanel";
import { useStorageKey } from "#src/hooks/useStorageKey";
import { Page } from "@validereinc/common-components";
import React from "react";
import { EVENTS_ALL_BREADCRUMB, EVENTS_ALL_PAGE_TITLE } from "./";

export const AllEventsPage = () => {
  const storageKeys = useStorageKey("events");

  const breadcrumbs = [EVENTS_BREADCRUMB, EVENTS_ALL_BREADCRUMB];

  return (
    <Page
      title={EVENTS_ALL_PAGE_TITLE}
      breadcrumbs={breadcrumbs}
    >
      <EventsFilterPanel
        filterConfigStorageKey={storageKeys.filterConfigStorageKey}
      />
      <EventsTablePanel {...storageKeys} />
    </Page>
  );
};
