import React from "react";
import { getFirstValidValue } from "#utils/objectFormatter";

const displayProperties = (test) => {
  const methodOneTemperature = getFirstValidValue([
    test.measurements["Vapour Pressure"]?.temperature_in_c,
    test.metadata?.temperature_one_in_celsius,
  ]);
  const methodOneLabel = methodOneTemperature ? (
    <div>
      VPCR @ ${methodOneTemperature} ℃ <br />
      (ASTMD 6377 Method)
    </div>
  ) : (
    <div>
      VPCR <br /> (ASTMD 6377 Method)
    </div>
  );

  const methodTwoTemperature = test?.metadata?.temperature_two_in_celsius;
  const methodTwoLabel = methodTwoTemperature ? (
    <div>
      VPCR @ ${methodTwoTemperature} ℃<br />
      (ASTMD 7975 Method)
    </div>
  ) : (
    <div>
      VPCR <br /> (ASTMD 7975 Method)
    </div>
  );

  return [
    {
      label: methodOneLabel,
      key: "Vapour Pressure",
    },
    {
      label: (
        <div>
          Volume/Liquid <br /> (ASTMD 6377 Method)
        </div>
      ),
      key: "volume_to_liquid_ratio_one",
      notMeasurement: true,
    },
    {
      label: methodTwoLabel,
      key: "p6377",
      measurementKey: "Vapour Pressure",
    },
    {
      label: (
        <div>
          Volume/Liquid <br />
          (ASTMD 7975 Method)
        </div>
      ),
      key: "volume_to_liquid_ratio_two",
      notMeasurement: true,
    },
    {
      label: "Crude Type",
      key: "crude_type",
      notMeasurement: true,
    },
  ];
};

export default displayProperties;
