const config = {
  locale: "en-US",
  APP_URL: process.env.APP_URL,
  NODE_API_URL: process.env.NODE_API_URL,
  INSIGHTS_API_URL: process.env.INSIGHTS_API_URL,
  ALERTING_API_URL: process.env.ALERTING_API_URL,
  API_URL: process.env.API_URL,
  DATA_ENG_API_URL: process.env.DATA_ENG_API_URL,
  // TODO: remove once the FileService under Services/ has been migrated to the domain package
  DATA_PLATFORM_API_URL: process.env.DATA_PLATFORM_API_URL,
  DATA_PLATFORM_SOURCE_API_URL: process.env.DATA_PLATFORM_SOURCE_API_URL,
  DATA_PLATFORM_ADMIN_API_URL: process.env.DATA_PLATFORM_ADMIN_API_URL,
  ENV: process.env.ENV,
  DEPLOYMENT_ENV: process.env.DEPLOYMENT_ENV,
  VERSION: process.env.VERSION,
  BUILD: process.env.BUILD,
  DOMAIN: process.env.DOMAIN ?? "validere.auth0.com",
  CLIENT_ID: process.env.CLIENT_ID ?? "#",
  PRIVACY_POLICY_URL: `https://www.validere.com/privacy-policy`,
  TERMS_OF_SERVICE_URL: `https://www.validere.com/terms-of-use`,
  VALIDERE_STATIC_ASSET_URL: `https://assets.validere.com`,
  RELEASE_NOTE_URL: "https://support.validere.com/category/175-9-release-notes",
  API_VERSION: "2021-04-06",
  TIME_FORMAT: "HH:mm:ss",
  TIME_MINUTE_FORMAT: "hh:mm A",
  DATE_FORMAT: "YYYY-MM-DD",
  DATE_WITH_UNDERSCORE_FORMAT: "YYYY_MM_DD",
  DATETIME_FORMAT: "YYYY-MM-DD HH:mm:ss z",
  DATETIME_FORMAT_READABLE: "MMM DD YYYY HH:mm:ss z",
  DATETIME_NO_TIMEZONE_FORMAT: "YYYY-MM-DD HH:mm:ss",
  DATEMONTH_FORMAT: "MMM DD, YYYY",
  LONG_DATEMONTH_FORMAT: "YYYY - MMMM",
  SHORT_DATEMONTH_FORMAT: "MMM YY",
  DATE_MONTHYEAR_FORMAT: "MMM YYYY",
  DATEMONTHTIME_FORMAT: "MM/DD HH:mm:ss",
  API_ID: "https://validere360.com/api",
  SENTRY_DSN:
    "https://34486fc773914e90a589e911dfb11079@o212082.ingest.us.sentry.io/1372056",
  KNOWLEDGE_BASE_URL: "https://support.validere.com",
  SUPPORT_EMAIL: "support@validere.com",
  API_DOCS_URL: "https://docs.validere.com/getting-started/readme",
} as const;

export default config;
