import {
  useGetOneFlow,
  useListFlowProductCategories,
  useListFlowProductTypes,
  useListFlowTypes,
  useUpdateFlow,
} from "#src/components/hooks/adapters/useFlows";
import useLocalization from "#src/hooks/useLocalization";
import { AssetType, FlowStatus } from "@validereinc/domain";
import { toStartCaseString } from "@validereinc/utilities";
import React from "react";
import AssetEditDialog from "./AssetEditDialog/AssetEditDialog";
import { AssetEditDialogPropTypes } from "./AssetEditDialog/types";

const EditFlowDialog = (props: {
  flowId?: string;
  defaultPeriod: string;
  onClose: () => void;
  onEdit?: () => void;
}) => {
  const { localize } = useLocalization();

  const { data: assetDetails, isLoading: isLoadingDefaultValues } =
    useGetOneFlow(
      {
        flowId: props.flowId ?? "",
        period: props.defaultPeriod,
      },
      { enabled: !!props.flowId }
    );
  const updateFlowMutation = useUpdateFlow({
    onSuccess: () => {
      props.onEdit?.();
      props.onClose();
    },
  });

  const { data: flowTypes, isLoading: isLoadingFlowTypes } = useListFlowTypes();
  const { data: productCategories, isLoading: isLoadingProductCategories } =
    useListFlowProductCategories();
  const { data: productTypes, isLoading: isLoadingProductTypes } =
    useListFlowProductTypes();

  const isUpdating = updateFlowMutation.isLoading;
  const isLoading =
    isUpdating ||
    isLoadingDefaultValues ||
    isLoadingFlowTypes ||
    isLoadingProductCategories ||
    isLoadingProductTypes;

  const primaryFields: AssetEditDialogPropTypes["primaryFields"] = [
    {
      display_name: `${localize("flow")} name`,
      field_name: "name",
      data_type: "string",
      is_required: true,
      is_state_managed: false,
    },
    {
      display_name: "Type",
      field_name: "type",
      data_type: "pick-list",
      pick_list_values: flowTypes ?? [],
      is_required: true,
      is_state_managed: false,
    },
    {
      display_name: "Status",
      field_name: "status",
      data_type: "pick-list",
      pick_list_values: Object.values(FlowStatus).map((id) => ({
        id,
        name: toStartCaseString(id),
      })),
      is_required: true,
      is_state_managed: false,
    },
    {
      display_name: "Product Category",
      field_name: "product_category",
      data_type: "pick-list",
      pick_list_values: productCategories ?? [],
      is_required: true,
      is_state_managed: false,
    },
    {
      display_name: "Product Type",
      field_name: "product_type",
      data_type: "pick-list",
      pick_list_values: productTypes ?? [],
      is_required: true,
      is_state_managed: false,
    },
    {
      display_name: `Associated ${localize("Facility")}`,
      field_name: "associated_facility_id",
      data_type: "lookup",
      lookup_entity_type: AssetType.FACILITY,
      is_state_managed: false,
    },
    {
      display_name: `Associated ${localize("Equipment")}`,
      field_name: "associated_equipment_id",
      data_type: "lookup",
      lookup_entity_type: AssetType.EQUIPMENT,
      is_state_managed: false,
    },
  ];

  return (
    <AssetEditDialog
      primaryFields={primaryFields}
      assetId={props.flowId}
      assetType={AssetType.FLOW}
      dialogProps={{ title: `Edit ${localize("flow")} Attributes` }}
      onClose={() => {
        if (isUpdating) return;
        props.onClose();
      }}
      isLoading={isLoading}
      onUpdate={(newValues) =>
        updateFlowMutation.mutate({
          flowId: props.flowId ?? "",
          ...newValues,
        })
      }
      assetDetails={assetDetails as Record<string, any>}
    />
  );
};

export default EditFlowDialog;
