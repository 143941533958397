import {
  MeasurementQuantityType,
  MeasurementTypeType,
  MeasurementUnitType,
  UnitConfigurationType,
} from "../schemas";
import { restAPI } from "./api";
import {
  CreateOneRequestType,
  DeleteOneRequestType,
  fetchAndCollate,
  GetListResponseType,
  PaginatedListRequestType,
  ResourceServiceType,
  UpdateOneRequestType,
} from "../util";
export const MeasurementTypeAdapter: MeasurementTypeAdapterType = {
  /**
   * Get the complete list of Measurement Quantities
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/measurement_types/list_measurement_quantities
   */
  listMeasurementQuantities: async () =>
    restAPI.nodeAPI.GET<MeasurementQuantityType[]>({
      endpoint: "/measurement_quantities",
    }),
  /**
   * Get the complete list of Measurement Types, optionally filtered based on an asset "subject_id"
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/measurement_types/list_measurement_types_v2
   */
  listMeasurementTypes: async (subject_id?: string) =>
    restAPI.nodeAPI.GET<MeasurementTypeType[]>({
      endpoint: "/measurement_types",
      query: subject_id ? { subject_id } : {},
      version: 2,
    }),
  /**
   * Get the complete list of Measurement Units, optionally filtered based on a measurement quantity
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/measurement_types/list_all_measurement_units
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/measurement_types/list_measurement_units
   */
  listMeasurementUnits: async (quantity?: string) =>
    restAPI.nodeAPI.GET<MeasurementUnitType[]>({
      endpoint: quantity
        ? `/measurement_units/${quantity}`
        : "/measurement_units",
    }),
  /** Search for a list of unit configs for your company
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/company_unit_config/search_company_unit_config
   */
  listCompanyUnitConfigurations: async () =>
    fetchAndCollate(({ page, pageSize }) =>
      restAPI.nodeAPI.POST<GetListResponseType<UnitConfigurationType>>({
        endpoint: `/company_unit_config/search`,
        body: {
          page,
          page_size: pageSize,
        },
      })
    ),
  /** Search for a list of unit configs for your user
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/user_unit_config/search_user_unit_config
   */
  listUserUnitConfigurations: async () =>
    fetchAndCollate(({ page, pageSize }) =>
      restAPI.nodeAPI.POST<GetListResponseType<UnitConfigurationType>>({
        endpoint: `/user_unit_config/search`,
        body: {
          page,
          page_size: pageSize,
        },
      })
    ),
};

export interface MeasurementTypeAdapterType {
  listMeasurementQuantities: () => Promise<MeasurementQuantityType[]>;
  listMeasurementTypes: (subjectId?: string) => Promise<MeasurementTypeType[]>;
  listMeasurementUnits: (quantity?: string) => Promise<MeasurementUnitType[]>;
  listUserUnitConfigurations: () => Promise<
    GetListResponseType<UnitConfigurationType>
  >;
  listCompanyUnitConfigurations: () => Promise<
    GetListResponseType<UnitConfigurationType>
  >;
}

type CompanyUnitConfiguration = Pick<
  ResourceServiceType<UnitConfigurationType>,
  "getList" | "updateOne" | "createOne" | "deleteOne"
>;

export const CompanyUnitConfigurationAdapter: CompanyUnitConfiguration = {
  /** Search for a list of unit configs for your company
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/company_unit_config/search_company_unit_config
   */
  getList: async ({
    page,
    pageSize,
    sortBy,
    sortDirection,
    filters,
  }: Partial<PaginatedListRequestType<any>> = {}) =>
    fetchAndCollate(
      ({ page, pageSize }) =>
        restAPI.nodeAPI.POST<GetListResponseType<UnitConfigurationType>>({
          endpoint: `/company_unit_config/search`,
          body: {
            page,
            page_size: pageSize,
            sort_by: sortBy,
            sort_direction: sortDirection,
            ...filters,
          },
        }),
      page,
      pageSize
    ),
  /** Update a company unit prefernce
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/company_unit_config/upsert_company_unit_config
   */
  updateOne: async ({
    id,
    data,
  }: UpdateOneRequestType<UnitConfigurationType>) =>
    restAPI.nodeAPI
      .PUT<UnitConfigurationType>({
        endpoint: `/company_unit_config/${id}`,
        body: data,
      })
      .then((resp) => ({ data: resp })),
  /** Create a company unit prefernce
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/company_unit_config/upsert_company_unit_config
   */
  createOne: async ({ data }: CreateOneRequestType<UnitConfigurationType>) =>
    restAPI.nodeAPI
      .PUT<UnitConfigurationType>({
        endpoint: `/company_unit_config/${data.measurement_type}`,
        body: {
          measurement_unit: data.measurement_unit,
          precision: data.precision,
        },
      })
      .then((resp) => ({ data: resp })),
  /** Delete a company unit prefernce
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/company_unit_config/delete_company_unit_config
   */
  deleteOne: async ({ id }: DeleteOneRequestType<UnitConfigurationType>) =>
    restAPI.nodeAPI
      .DELETE<UnitConfigurationType>({
        endpoint: `/company_unit_config/${id}`,
      })
      .then(() => ({ status: 200 })),
};

type UserUnitConfiguration = Pick<
  ResourceServiceType<UnitConfigurationType>,
  "getList" | "updateOne" | "createOne" | "deleteOne"
>;

export const UserUnitConfigurationAdapter: UserUnitConfiguration = {
  /** Search for a list of unit configs for your company
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/user_unit_config/search_user_unit_config
   */
  getList: async () =>
    fetchAndCollate(({ page, pageSize }) =>
      restAPI.nodeAPI.POST<GetListResponseType<UnitConfigurationType>>({
        endpoint: `/user_unit_config/search`,
        body: {
          page,
          page_size: pageSize,
        },
      })
    ),
  /** Update a user unit prefernce
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/user_unit_config/upsert_user_unit_config
   */
  updateOne: async ({
    id,
    data,
  }: UpdateOneRequestType<UnitConfigurationType>) =>
    restAPI.nodeAPI
      .PUT<UnitConfigurationType>({
        endpoint: `/user_unit_config/${id}`,
        body: data,
      })
      .then((resp) => ({ data: resp })),
  /** Create a user unit prefernce
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/user_unit_config/upsert_user_unit_config
   */
  createOne: async ({ data }: CreateOneRequestType<UnitConfigurationType>) =>
    restAPI.nodeAPI
      .PUT<UnitConfigurationType>({
        endpoint: `/user_unit_config/${data.measurement_type}`,
        body: {
          measurement_unit: data.measurement_unit,
          precision: data.precision,
        },
      })
      .then((resp) => ({ data: resp })),
  /** Delete a user unit prefernce
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/company_unit_config/delete_company_unit_config
   */
  deleteOne: async ({ id }: DeleteOneRequestType<UnitConfigurationType>) =>
    restAPI.nodeAPI
      .DELETE<UnitConfigurationType>({
        endpoint: `/user_unit_config/${id}`,
      })
      .then(() => ({ status: 200 })),
};

export const UnitConfigurationPreference = {
  COMPANY: "company",
  USER: "user",
} as const;

export type UnitConfigurationPreferenceType =
  (typeof UnitConfigurationPreference)[keyof typeof UnitConfigurationPreference];
