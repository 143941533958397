export const COMPONENT_QUALITIES = [
  "Methane (C1)",
  "Ethane (C2)",
  "Propane (C3)",
  "Propane Minus (C3-)",
  "Isobutane (iC4)",
  "n-Butane (NC4)",
  "Butane Minus (C4-)",
  "Butane (C4)",
  "Deemed Butane",
  "Isopentane (iC5)",
  "n-Pentane (NC5)",
  "Pentane (C5)",
  "Pentanes Plus (C5+)",
  "Hexanes (C6)",
  "Hexanes Plus (C6+)",
  "Heptanes Plus (C7+)",
];

export const INERTS = [
  "Nitrogen (N2)",
  "Carbon Dioxide (CO2)",
  "Hydrogen Sulphide (H2S)",
];

export const HELIUM_AND_HYDROGEN_INERTS = ["Hydrogen (H2)", "Helium (He)"];

/**
 * Here are all the options for method that the user can use when they.
 * go select their sample in RoQ.
 *
 * Gas/Liquid Product RoQ the user can select
 * Average, Earliest Sample, Latest Sample, Previous RoQ, Volume Weighted Average, Volume Weighted Inline
 *
 * Oil Product RoQ the user can select
 * Average, Volume Weighted Average, Volume Weighted Inline, Previous RoQ, None
 *
 * Inventory Product RoQ the user can select
 * Average, Earliest Sample, Latest Sample, Previous RoQ, Effective Date
 * */
export const METHOD_OPTIONS = [
  {
    id: "average_of_selected_samples",
    name: "Average",
    default: true,
    oil: true,
    inventory: true,
  },
  {
    id: "earliest_sample",
    name: "Earliest Sample",
    default: true,
    inventory: true,
  },
  {
    id: "latest_sample",
    name: "Latest Sample",
    default: true,
    inventory: true,
  },
  {
    id: "previous_accounting_record",
    name: "Previous RoQ",
    default: true,
    oil: true,
    inventory: true,
    defaultOnly: true,
  },
  {
    id: "volume_weighted_average",
    name: "Volume Weighted Average",
    default: true,
    oil: true,
  },
  {
    id: "volume_weighted_inline",
    name: "Volume Weighted Inline",
    default: true,
    oil: true,
    defaultOnly: true,
  },
  {
    id: "multiple",
    name: "Multiple",
  },
  {
    id: "none",
    name: "None",
    oil: true,
    inventory: true,
    defaultOnly: true,
  },
  {
    name: "Effective Date",
    id: "effective_date",
    inventory: true,
  },
];
