import { GetAllObjectValue } from "#utils/objectFormatter";
import {
  FilterPillbox,
  MultiDropdownInputWithSearch,
} from "@validereinc/common-components";
import difference from "lodash/difference";
import React, { useEffect, useState } from "react";

const DEFAULT_FILTER_DROPDOWN = {
  site: {
    label: "Site",
    inputs: [],
    options: [],
  },
  productType: { label: "Product Type", inputs: [], options: [] },
};

export function filterInventories(inventories, filters) {
  const { site, productType } = filters;

  const filteredInventories = inventories.filter((inventory) => {
    if (site?.length > 0 && site.every((s) => s !== inventory.siteName)) {
      return false;
    }

    if (
      productType?.length > 0 &&
      productType.every((productType) => productType !== inventory.productType)
    ) {
      return false;
    }

    return true;
  });

  return filteredInventories;
}

export function getAllFilterInputs(filters) {
  const filterPillbox = {};

  Object.keys(filters).map((key) => {
    const inputs = filters[key]?.inputs ?? [];
    if (inputs.length > 0) {
      filterPillbox[key] = inputs;
    }
  });

  return filterPillbox;
}

export function clearAllInputs(filterDropdowns) {
  const updatedFilterDropdowns = { ...filterDropdowns };

  Object.keys(updatedFilterDropdowns).map((key) => {
    updatedFilterDropdowns[key].inputs = [];
  });

  return updatedFilterDropdowns;
}

const InventoryTableFilter = (inventories) => {
  const [filterDropdowns, setFilterDropdowns] = useState({
    ...DEFAULT_FILTER_DROPDOWN,
  });

  useEffect(() => {
    if (inventories.length > 0) {
      const { site, productType } = { ...filterDropdowns };

      const siteOptions = GetAllObjectValue(inventories, "siteName").filter(
        (option) => option
      );
      site.options = siteOptions;

      const productTypeOptions = GetAllObjectValue(
        inventories,
        "productType"
      ).filter((option) => option);
      productType.options = productTypeOptions;

      setFilterDropdowns((filterDropdowns) => ({
        ...filterDropdowns,
        site,
        productType,
      }));
    }
  }, [inventories]);

  const onDropdownSelect = (selectedValue, key) => {
    const filterDropdown = { ...filterDropdowns[key] };
    filterDropdown.inputs = selectedValue;

    setFilterDropdowns({ ...filterDropdowns, [key]: filterDropdown });
  };

  const clearAllFilters = () => {
    setFilterDropdowns(clearAllInputs(filterDropdowns));
  };

  const onClearFilterClick = (filterObject) => {
    if (filterObject) {
      const { filterKey, name } = filterObject;
      const updatedFilterDropdown = { ...filterDropdowns[filterKey] };

      updatedFilterDropdown.inputs = difference(updatedFilterDropdown.inputs, [
        name,
      ]);

      setFilterDropdowns({
        ...filterDropdowns,
        [filterKey]: updatedFilterDropdown,
      });
    } else {
      clearAllFilters();
    }
  };

  const filterRow = (
    <div className="filterRow">
      {Object.keys(filterDropdowns).map((key) => {
        const { label, labelKey, options, inputs } = filterDropdowns[key];

        return (
          <MultiDropdownInputWithSearch
            key={key}
            label={label}
            labelKey={labelKey ? labelKey : null}
            dropdownKey={key}
            value={inputs}
            options={options}
            onChange={onDropdownSelect}
          />
        );
      })}
    </div>
  );

  const filterInputs = getAllFilterInputs(filterDropdowns);

  const filteredInventories = filterInventories(inventories, filterInputs);

  const filterPillbox = (
    <FilterPillbox
      filterBy={filterInputs}
      onClearFilterClick={onClearFilterClick}
    />
  );

  return [filterRow, filterPillbox, filteredInventories];
};

export default InventoryTableFilter;
