import { TextInput, Title } from "@validereinc/common-components";
import * as PropTypes from "prop-types";
import React, { useMemo } from "react";
import AdvancedSection from "./AdvancedSection";
import SourcesTable from "./SourcesTable";

const SourcesModalForm = ({
  form,
  sourceKey,
  sourceListType,
  streams,
  inventories,
  selectedValues,
  onCheckboxClick,
}) => {
  const sourcesList = useMemo(() => {
    if (sourceListType === "stream") {
      return streams;
    } else if (sourceListType === "inventory") {
      return inventories;
    }

    return selectedValues;
  }, [sourceListType, streams, inventories, selectedValues]);

  return (
    <>
      <Title
        className="capitalized"
        type="subheader"
      >{`${sourceKey} ${sourceListType ?? ""}`}</Title>

      <SourcesTable
        sourcesList={sourcesList}
        selectedValues={form.watch("selectedValues")}
        onCheckboxClick={onCheckboxClick}
        streams={streams}
      />

      <AdvancedSection>
        <TextInput
          label="Multiplier"
          name="multiplier"
          isRequired
        />
      </AdvancedSection>
    </>
  );
};

SourcesModalForm.propTypes = {
  form: PropTypes.object.isRequired,
  sourceKey: PropTypes.string,
  sourceListType: PropTypes.string,
  streams: PropTypes.array,
  inventories: PropTypes.array,
  selectedValues: PropTypes.array,
  onCheckboxClick: PropTypes.func,
};

export default SourcesModalForm;
