import { useFetchInventories } from "#components/Inventories/Inventory";
import { addAlertMessage } from "#redux/actions/alertMessages";
import { ensureStreamListIsFetched, fetchSiteList } from "#redux/actions/index";
import { havePermission } from "#redux/reducers/permissions";
import { getBreadcrumbsObject } from "#routers/breadcrumbsHelper";
import BalancesServices from "#services/BalancesService";
import history from "#src/Routers/history";
import { linkToBalances } from "#src/Routers/links";
import {
  Button,
  Form,
  FormButton,
  Page,
  Panel,
  useAlert,
  useForm,
} from "@validereinc/common-components";
import * as PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  getSourceWithSiteAndStreamDetail,
  useFetchBalanceList,
} from "./BalanceHelper";
import BalancesForm from "./BalancesForm";
import {
  DEFAULT_SOURCE_INPUTS,
  DEFAULT_SOURCE_SELECTION,
} from "./BalancesUpsertsConstant";
import "./EditBalances.scss";
import useManageSourceInput from "./SourceSelection/useManageSourceInput";

const mapStateToProps = ({ permissions, streams, sites }) => {
  return {
    haveWritePermission: havePermission(permissions)(
      "gas:accounting.balances",
      "write"
    ),
    streams: streams?.data.toJS() ?? [],
    sites: sites?.data.toJS() ?? [],
  };
};

const mapDispatchToProps = {
  ensureStreamListIsFetched,
  fetchSiteList,
  addAlertMessage,
};

const useFetchBalanceDetail = (balanceId) => {
  const [balances, setBalances] = useState({});
  const [fetchDetailState, setFetchDetailState] = useState("loading");

  useEffect(() => {
    if (balanceId) {
      BalancesServices.getBalancesDetail(balanceId)
        .then(({ data }) => {
          setBalances(data);
          setFetchDetailState("loaded");
        })
        .catch(() => {
          setFetchDetailState("error");
        });
    }
  }, [balanceId]);

  return [balances, fetchDetailState];
};

const EditBalances = ({
  sites,
  streams,
  match,
  ensureStreamListIsFetched,
  fetchSiteList,
  breadcrumbs,
  haveWritePermission,
}) => {
  const balanceId = match.params.id;
  const [formState, setFormState] = useState("disabled");
  const [balancesDetail, fetchDetailState] = useFetchBalanceDetail(balanceId);
  const [inventories, inventoryLoadingState] = useFetchInventories();
  const { addAlert } = useAlert();

  const form = useForm({
    defaultValues: DEFAULT_SOURCE_INPUTS,
  });

  const [
    lastUpdatedTime,
    onSourceChecked,
    editSourceClick,
    openSourceSelectionModal,
    sourceSelectionModal,
  ] = useManageSourceInput(streams, sites, inventories, form);

  const [lastUpdatedBalanceList] = useState(new Date());
  const [balancesList] = useFetchBalanceList(lastUpdatedBalanceList);

  const onBalanceUpdate = (inputs) => {
    setFormState("loading");

    BalancesServices.updateBalances(inputs)
      .then(() => {
        addAlert({
          variant: "success",
          message: "Balance has been successfully updated.",
        });
      })
      .finally(() => {
        setFormState("enabled");
      });
  };

  const onViewBalanceClick = () => {
    history.push(linkToBalances(balanceId));
  };

  useEffect(() => {
    ensureStreamListIsFetched();
    fetchSiteList();
  }, []);

  useEffect(() => {
    if (
      balancesDetail &&
      fetchDetailState === "loaded" &&
      inventoryLoadingState === "loaded" &&
      sites.length > 0 &&
      streams.length > 0 &&
      formState === "disabled"
    ) {
      const sourcesWithSiteAndStreamDetails = getSourceWithSiteAndStreamDetail(
        streams,
        sites,
        inventories,
        balancesDetail,
        DEFAULT_SOURCE_SELECTION
      );

      form.reset({
        ...sourcesWithSiteAndStreamDetails,
      });

      setFormState("enabled");
    }
  }, [sites, streams, inventoryLoadingState]);

  return (
    <Page
      title="Edit Balance"
      breadcrumbs={getBreadcrumbsObject(breadcrumbs, match.params)}
    >
      <Panel className="createBalanceContainer">
        <Form
          onSubmit={onBalanceUpdate}
          {...form}
        >
          <BalancesForm
            form={form}
            formState={formState}
            balancesList={balancesList}
            balancesDetail={balancesDetail}
            sourceSelectionOptions={DEFAULT_SOURCE_SELECTION}
            haveWritePermission={haveWritePermission}
            openSourceSelectionModal={openSourceSelectionModal}
            onSourceChecked={onSourceChecked}
            editSourceClick={editSourceClick}
            lastUpdatedTime={lastUpdatedTime}
            disabled={formState !== "enabled"}
          />

          <div className="createBalanceButtonContainer clearfix">
            <FormButton
              className="pull-right"
              variant="primary"
              type="submit"
              isLoading={formState === "loading"}
              disabled={formState !== "enabled"}
            >
              Update Balance
            </FormButton>

            <Button
              className="pull-right"
              onClick={onViewBalanceClick}
              disabled={formState !== "enabled"}
            >
              View Balance
            </Button>
          </div>
        </Form>
      </Panel>

      {sourceSelectionModal}
    </Page>
  );
};

EditBalances.propTypes = {
  match: PropTypes.object.isRequired,
  haveWritePermission: PropTypes.bool.isRequired,
  sites: PropTypes.array,
  streams: PropTypes.array,
  ensureStreamListIsFetched: PropTypes.func.isRequired,
  addAlertMessage: PropTypes.func.isRequired,
  fetchSiteList: PropTypes.func.isRequired,
  breadcrumbs: PropTypes.array,
};

const BalancesContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditBalances);

export default BalancesContainer;
