import { EventCategoriesPanel } from "#src/batteries-included-components/Panels/TablePanels/EventCategoriesPanel";
import { Page } from "@validereinc/common-components";
import React from "react";
import { EVENT_CATEGORIES_BREADCRUMB, EVENT_CATEGORIES_PAGE_TITLE } from ".";
import { EVENTS_BREADCRUMB } from "../";

export const EventCategoriesPage = () => {
  const breadcrumbs = [EVENTS_BREADCRUMB, EVENT_CATEGORIES_BREADCRUMB];

  return (
    <Page
      title={EVENT_CATEGORIES_PAGE_TITLE}
      breadcrumbs={breadcrumbs}
    >
      <EventCategoriesPanel title={EVENT_CATEGORIES_PAGE_TITLE} />
    </Page>
  );
};
