import find from "lodash/find";

export const getCombinedInventoryRecord = (records) => {
  const recordOfQualitiesWithInventoryRecordCombined = [];

  records.map((record) => {
    if (record.category !== "inventory_opening") {
      if (record.category === "inventory_closing") {
        const openInventoryRecord = find(
          records,
          (openingInventoryRecord) =>
            openingInventoryRecord.category === "inventory_opening" &&
            openingInventoryRecord.stream.id === record.stream.id
        );

        record.inventory_opening = openInventoryRecord;
      }

      recordOfQualitiesWithInventoryRecordCombined.push(record);
    }
  });

  return recordOfQualitiesWithInventoryRecordCombined;
};
