import React from "react";
import * as PropTypes from "prop-types";
import "./SystemBalanceSummary.scss";
import { SystemBalanceProperties } from "./SystemBalanceConstant";
import {
  getFormattedMeasurementValueWithUnit,
  CUBIC_METERS_VOLUME,
  PERCENTAGE_KEY,
} from "#redux/reducers/measurements";
import { connect } from "react-redux";

const mapStateToProps = (state) => {
  return {
    getFormattedMeasurementValueWithUnit: (measurementKey, measurementObject) =>
      getFormattedMeasurementValueWithUnit(state.measurements)(
        measurementKey,
        measurementObject
      ),
  };
};

const SystemBalanceProperty = (props) => (
  <div className="systemBalanceProperty">
    <div className="systemBalanceProperty__name">{props.name}</div>
    <div
      className="systemBalanceProperty__value"
      style={props.style}
    >
      {props.value}
    </div>
  </div>
);

const SystemBalanceSummary = (props) => {
  return (
    <div className="systemBalanceSummary">
      <SystemBalanceProperty
        name="Balance Type"
        value={`${props.balanceType} Balance`}
        style={{ textTransform: "capitalize" }}
      />

      {SystemBalanceProperties.map(({ name, id }) => {
        const measurementKey =
          props.total[id]?.unit !== "%" ? CUBIC_METERS_VOLUME : PERCENTAGE_KEY;

        return (
          <SystemBalanceProperty
            key={id}
            name={name}
            value={props.getFormattedMeasurementValueWithUnit(
              measurementKey,
              props.total[id]
            )}
          />
        );
      })}
    </div>
  );
};

SystemBalanceProperty.propTypes = {
  name: PropTypes.string.isRequired,
  style: PropTypes.object,
  value: PropTypes.string.isRequired,
};

SystemBalanceSummary.propTypes = {
  total: PropTypes.object.isRequired,
  balanceType: PropTypes.string.isRequired,
  getFormattedMeasurementValueWithUnit: PropTypes.func,
};

export default connect(mapStateToProps)(SystemBalanceSummary);
