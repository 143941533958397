import SelectInput from "#common/Form/SelectInput";
import NotesList from "#common/Notes/NotesList";
import ReactLoader from "#components/LoadingBar/ReactLoader";
import { havePermission } from "#redux/reducers/permissions";
import { useSubmitNote, VOLUME_NOTE } from "#services/NotesService";
import { SortListByType } from "#utils/sorters";
import { AlertMessage } from "@validereinc/common-components";
import orderBy from "lodash/orderBy";
import * as PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { AutoSizer } from "react-virtualized";
import DropdownInputWithSearch from "../../../Inputs/DropdownInputWithSearch";

import "./VolumeNotes.scss";

import {
  Button,
  Form,
  FormButton,
  Modal,
  TextWithFileInput,
  useForm,
} from "@validereinc/common-components";

const mapStateToProps = ({ permissions }) => ({
  havePermission: havePermission(permissions),
});

const VolumeNotes = ({
  isActiveTab,
  entriesWithVolume,
  entriesWithoutVolume,
  volumeEntriesLength,
  havePermission,
  notes,
  areNotesLoading,
  fetchNotes,
  noteDisplayed,
  onDisplayNoteSelect,
  setShouldRefetchDailyVolume,
  onClose,
}) => {
  const [alertMessage, setAlertMessage] = useState(null);
  const [addNotesTo, setAddNotesTo] = useState(entriesWithVolume);
  const [formState, setFormState] = useState("enabled");

  const form = useForm({
    defaultValues: {
      note: {},
    },
  });

  const [isSubmittingNote, submitNote] = useSubmitNote(
    addNotesTo?.map((note) => note.id),
    VOLUME_NOTE,
    "record_of_volume_ids"
  );

  useEffect(() => {
    setAddNotesTo(entriesWithVolume);

    if (!entriesWithVolume.length) {
      setAlertMessage("Create volume entries before adding notes");
    } else if (entriesWithVolume.length !== volumeEntriesLength) {
      setAlertMessage(
        `Cannot add notes to dates without entries (${entriesWithoutVolume
          .map((entry) => entry.date)
          .join(", ")})`
      );
    } else {
      setAlertMessage(null);
    }
  }, [entriesWithVolume, entriesWithoutVolume, volumeEntriesLength]);

  const onAddNotesToSelect = (value) => {
    setAddNotesTo(orderBy(value, "date"));
  };

  const onSubmitNote = (inputs) => {
    setFormState("loading");

    return submitNote(inputs.note)
      .then((result) => {
        fetchNotes();

        setShouldRefetchDailyVolume(true);
        return result;
      })
      .finally(() => {
        form.reset();
        setFormState("enabled");
      });
  };

  const haveWritePermissions = havePermission(
    "gas:accounting.records_of_volume",
    "write"
  );

  const sortedNotes = useMemo(() => {
    return notes ? SortListByType(notes, "date", "desc") : [];
  }, [notes]);

  const formDisabled = !haveWritePermissions || isSubmittingNote;

  return (
    <Form
      onSubmit={onSubmitNote}
      {...form}
    >
      <Modal.Body className="volumeNotes">
        {alertMessage && (
          <AlertMessage type="warning">
            <b>{alertMessage}</b>
          </AlertMessage>
        )}

        <section className="filterRow volumeNotes__filterRow">
          <span>Displaying Notes For: </span>

          <DropdownInputWithSearch
            value={noteDisplayed?.date}
            title="Date"
            filterKey="date"
            options={entriesWithVolume}
            onSelect={onDisplayNoteSelect}
          />
        </section>

        <div className="volumeNotes__notesContainer">
          <AutoSizer disableWidth>
            {({ height }) => (
              <ReactLoader
                loaded={!areNotesLoading}
                position={50}
              >
                {isActiveTab && (
                  <NotesList
                    list={sortedNotes}
                    height={height}
                  />
                )}
              </ReactLoader>
            )}
          </AutoSizer>
        </div>

        <SelectInput
          title="Add Notes to"
          className="volumeNotes__addNotesToInput"
          value={addNotesTo ?? []}
          labelKey="date"
          options={entriesWithVolume}
          onChange={onAddNotesToSelect}
          titleCol={2}
          pullLeft
          isMulti
          closeMenuOnSelect={false}
          isDisabled={formDisabled || !entriesWithVolume.length}
        />

        <TextWithFileInput
          name="note"
          label=""
          isRequired
          showIcon
          multiple
          isDisabled={formState !== "enabled"}
        />
      </Modal.Body>

      <Modal.Footer>
        <Button
          className="pull-left"
          onClick={onClose}
        >
          Close
        </Button>

        <FormButton
          variant="primary"
          type="submit"
          isLoading={formState === "loading"}
          disabled={formState !== "enabled" || !addNotesTo.length}
        >
          Add Note
        </FormButton>
      </Modal.Footer>
    </Form>
  );
};

VolumeNotes.propTypes = {
  isActiveTab: PropTypes.bool,
  entriesWithVolume: PropTypes.array,
  entriesWithoutVolume: PropTypes.array,
  volumeEntriesLength: PropTypes.number,
  profile: PropTypes.object,
  havePermission: PropTypes.func,
  notes: PropTypes.array,
  areNotesLoading: PropTypes.bool,
  fetchNotes: PropTypes.func,
  noteDisplayed: PropTypes.object,
  onDisplayNoteSelect: PropTypes.func,
  setShouldRefetchDailyVolume: PropTypes.func,
  onClose: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, undefined)(VolumeNotes);
