import config from "#src/config";
import { getComponentQualityOrderValue } from "#utils/componentQualitySortOrder";
import { getComponentShortForm } from "#utils/stringFormatter";
import { getTimeStringFromDate } from "#utils/timeFormatter";
import styles from "@validereinc/common-components/constants";
import find from "lodash/find";
import sortBy from "lodash/sortBy";
import uniqBy from "lodash/uniqBy";

export const getSampleMeasurements = (tests) => {
  return tests.reduce(
    (measurements, test) => [...measurements, ...test.measurements],
    []
  );
};

export const getAlerts = (sample) => {
  const measurements = getSampleMeasurements(sample.tests);

  return measurements.reduce((alerts, measurement) => {
    return [...alerts, ...measurement.alerts];
  }, []);
};

export const getNumMeasurementsWithAlerts = (sample) => {
  const measurements = getSampleMeasurements(sample.tests);

  return measurements.reduce(
    (numMeasurementWithAlerts, measurement) =>
      measurement.alerts.length
        ? numMeasurementWithAlerts + 1
        : numMeasurementWithAlerts,
    0
  );
};

export const getUniqMeasurements = (samples) => {
  const allMeasurements = samples.reduce((measurements, sample) => {
    const sampleMeasurements = getSampleMeasurements(sample.tests);

    return [...measurements, ...sampleMeasurements];
  }, []);

  return uniqBy(allMeasurements, "type");
};

export const getSampleComparisonTableRow = (defaultTableRow, measurements) => {
  const measurementRows = measurements.map((measurement) => ({
    label: `${getComponentShortForm(measurement.type)} (${measurement.unit})`,
    key: measurement.type,
    unit: measurement.unit,
  }));

  const sortedMeasurementRows = sortBy(measurementRows, [
    getComponentQualityOrderValue("key"),
    (measurement) => getComponentShortForm(measurement.key),
  ]);

  return [...defaultTableRow, ...sortedMeasurementRows];
};

export const getSampleComparisonTableHeader = (
  defaultTableHeader,
  selectedSample,
  historicalSampleData,
  defaultHeaderWidth,
  cellRenderer
) => {
  const selectedSampleHeader = {
    label: "Selected Sample",
    key: "selectedSample",
    width: defaultHeaderWidth,
    fixed: true,
    disableSort: true,
    wrap: true,
    cellRenderer: cellRenderer,
  };

  const historicalSampleHeaders = [];
  historicalSampleData.map((sample) => {
    if (sample.id !== selectedSample.id) {
      historicalSampleHeaders.push({
        label: getTimeStringFromDate(sample.ended_at, config.DATE_FORMAT),
        key: sample.id,
        width: defaultHeaderWidth,
        disableSort: true,
        wrap: true,
        cellRenderer: cellRenderer,
      });
    }
  });

  return [
    ...defaultTableHeader,
    selectedSampleHeader,
    ...historicalSampleHeaders,
  ];
};

export const getSortedTestContainMeasurements = (sample, measurementType) => {
  const testsValueMap = {};

  return sample.tests
    .filter((test) => {
      const measurement = find(test.measurements, { type: measurementType });

      if (measurement?.value) {
        testsValueMap[test.id] = measurement.value;
        return true;
      }
      return false;
    })
    .sort((a, b) => {
      return testsValueMap[a.id] - testsValueMap[b.id];
    });
};

export const getMeasurementCellStyle = (alerts, testStatus) => {
  if (
    alerts.length > 0 ||
    testStatus === "error" ||
    testStatus === "preliminary-error"
  ) {
    return {
      color: styles.statusErrorColour,
    };
  } else {
    return null;
  }
};

const getComparisonMeasurementSting = (measurement, threshold) => {
  if (measurement.value > threshold) {
    return `${measurement.value} ${measurement.unit} was above ${threshold} ${measurement.unit}`;
  } else {
    return `${measurement.value} ${measurement.unit} was below ${threshold} ${measurement.unit}`;
  }
};

export const getMeasurementAlertMessage = (alert, measurement) => {
  const comparisonMeasurementString = getComparisonMeasurementSting(
    measurement,
    alert.threshold_triggered
  );

  switch (alert.type) {
    case "lower_limit":
      return `Lower Limit: ${comparisonMeasurementString}`;
    case "upper_limit":
      return `Upper Limit: ${comparisonMeasurementString}`;
    case "std_num_measurements":
      return `Standard Deviation (Num. Measurements): ${comparisonMeasurementString}`;
    case "std_seconds_in_past":
      return `Standard Deviation (Historical): ${comparisonMeasurementString}`;
    case "previous_percentage_deviation":
      return `Previous Percentage Deviation: ${comparisonMeasurementString}`;
    case "roq_previous_percentage_deviation":
      return `RoQ Previous Percentage Deviation: ${comparisonMeasurementString}`;
    case "roq_previous_total_vol_percentage_deviation":
      return `RoQ Previous Total Vol. Percentage Deviation: ${comparisonMeasurementString}`;
    case "previous_error_deviation":
      return `Previous Error Deviation: ${comparisonMeasurementString}`;
    default:
      return `${alert.type}: ${comparisonMeasurementString}`;
  }
};

export const getTestType = (type) => {
  if (type && typeof type === "string") {
    return type.replaceAll("_", " ");
  } else {
    return type;
  }
};

export const getMeasurementValue = (measurement, measurementValue) => {
  return `${measurementValue} ${measurement.unit}`;
};
