import React from "react";
import * as PropTypes from "prop-types";
import { getTimeStringFromDate } from "#utils/timeFormatter";
import "./CreateSampleTestList.scss";
import SidePanelSection from "#common/SidePanel/SidePanelSection";
import config from "#src/config";

const CreateSampleTestList = ({ tests }) => {
  return (
    <SidePanelSection subHeader="Included Tests">
      <div className="createSampleTestList">
        {tests.map((test) => {
          return (
            <div
              className="createSampleTestList__listItem"
              key={test.id}
            >
              <div className="createSampleTestList__listContent">
                <b>{test.name}</b>

                <div>
                  <b>Site: </b>
                  {test?.instrument?.site_name ?? "-"}
                </div>

                <div>
                  <b>Date: </b>
                  {getTimeStringFromDate(test.date, config.DATETIME_FORMAT)}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </SidePanelSection>
  );
};

CreateSampleTestList.propTypes = {
  tests: PropTypes.array,
};

export default CreateSampleTestList;
