import {
  useMemoizedFormState,
  useMultiStepFormContext,
} from "#src/hooks/useMultiStepForm";
import {
  DropdownInput,
  Form,
  Panel,
  RadioInput,
  TextAreaInput,
  TextInput,
  useForm,
} from "@validereinc/common-components";
import React, { useEffect } from "react";

const STEP_NUMBER = 1;

export const CreateFormTemplateDetailsStep = () => {
  const { currentStep, updateStep } = useMultiStepFormContext();

  const form = useForm({
    defaultValues: {
      name: "",
      description: "",
      status: "active",
      form_submission_default_status: "pending",
    },
  });

  const getFormState = useMemoizedFormState(form);

  useEffect(() => {
    updateStep(STEP_NUMBER, {
      getValues: () => form.getValues(),
      getFormState,
    });
  }, [getFormState]);

  return (
    <>
      {currentStep === STEP_NUMBER ? (
        <Panel title="Template Details">
          <Form {...form}>
            <TextInput
              label="Name"
              name="name"
              isRequired
              validate={{
                maxLength: (name) =>
                  name.length < 100 || "Name must be less than 100 characters.",
              }}
            />

            <TextAreaInput
              label="Description"
              name="description"
              validate={{
                maxLength: (description) =>
                  description.length < 1024 ||
                  "Description must be less than 1024 characters.",
              }}
            />

            <RadioInput
              label="Status"
              name="status"
              options={[
                { label: "Active", value: "active" },
                { label: "Draft", value: "draft" },
              ]}
              labelKey="label"
              valueKey="value"
              isRequired
            />

            <DropdownInput
              label="Default Form Submission Status"
              name="form_submission_default_status"
              options={[
                { label: "Validated", value: "validated" },
                { label: "Invalidated", value: "invalidated" },
                { label: "Pending", value: "pending" },
                { label: "Submitted", value: "submitted" },
              ]}
              labelKey="label"
              valueKey="value"
              isRequired
            />
          </Form>
        </Panel>
      ) : null}
    </>
  );
};
