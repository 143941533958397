import { getTableCellDisplayValue } from "#batteries-included-components/Tabs/EstimationMethod/helpers";
import { useEstimationMethodDetailContext } from "#src/batteries-included-components/Layouts/EstimationMethod/Detail";
import { useMeasurementTypes } from "#src/contexts/MeasurementTypeContext";
import { useStorageKey } from "#src/hooks/useStorageKey";
import useTableState from "#src/hooks/useTableState";
import {
  DataTablePanel,
  HeaderType,
  Link,
  SortingType,
} from "@validereinc/common-components";
import {
  EstimationMethodDomain,
  EstimationMethodRunType,
  SortDirection,
} from "@validereinc/domain";
import {
  DateFormats,
  getFormattedNumberWithUnit,
  monthFormatter,
} from "@validereinc/utilities";
import parse from "date-fns/parse";
import parseISO from "date-fns/parseISO";
import React, { useCallback, useState } from "react";

const sorting: SortingType = {
  sortBy: "year_month",
  sortDirection: SortDirection.ASCENDING,
};

const EstimationMethodRecordsTab = ({
  onClickRow,
}: {
  onClickRow: (methodId: string, entityId: string, yearMonth: string) => void;
}) => {
  const { method, entityId, entityType } = useEstimationMethodDetailContext();
  const { tableConfigStorageKey } = useStorageKey(
    `${entityType}-estimation-methods-detail-${
      method?.data?.analytics_calculator_id ?? entityId
    }`
  );
  const { getPrecisionByType, getTypeName, getUnitName } =
    useMeasurementTypes();
  const [inputOutputHeaders, setInputOutputHeaders] = useState<
    Array<HeaderType<EstimationMethodRunType>>
  >([]);
  const methodDetail = method?.data;

  const extractInputHeadersFromRun = (
    runData: EstimationMethodRunType
  ): Array<HeaderType<EstimationMethodRunType>> => {
    if (!runData.input?.calculation_parameters?.length) {
      return [];
    }

    return [
      {
        key: "inputs",
        label: "Inputs",
        headers: runData.input.calculation_parameters.map((input) => {
          return {
            label: input.display_name,
            key: input.id,
            tooltip: input.description,
            isSortable: false,
            renderComponent: ({ item }: { item: EstimationMethodRunType }) => {
              // find the right input result
              const inputResult = item?.input?.calculation_parameters?.find(
                (inputCandidate) => inputCandidate.id === input.id
              );

              if (!inputResult) {
                return "-";
              }

              return getTableCellDisplayValue({
                ...inputResult,
                measurement_unit: getUnitName(inputResult.measurement_unit),
              });
            },
          };
        }),
      },
    ];
  };
  const extractOutputHeadersFromRun = (
    runData: EstimationMethodRunType
  ): Array<HeaderType<EstimationMethodRunType>> => {
    if (!runData.output?.outputs?.length) {
      return [];
    }

    return [
      {
        key: "results",
        label: "Results",
        headers: runData.output.outputs.map((output) => {
          return {
            label: getTypeName(output.measurement_type),
            key: output.measurement_type,
            tooltip: output.description,
            isSortable: false,
            renderComponent: ({ item }) => {
              // find the right output result
              const outputResult = item?.output?.outputs?.find(
                (outputCandidate) =>
                  outputCandidate.measurement_type === output.measurement_type
              );

              if (!outputResult) {
                return "-";
              }

              return getFormattedNumberWithUnit(
                {
                  value: Number(outputResult.measurement_value),
                  unit: getUnitName(outputResult.measurement_unit),
                },
                getPrecisionByType(output.measurement_type),
                {
                  showSmallNumberAsExponential: true,
                  maxFractionDigits: 3,
                }
              );
            },
          };
        }),
      },
    ];
  };

  const fetchTableData = useCallback(
    async (newSearchParams) => {
      const { page, rowPerPage, sort, sortDirection } = newSearchParams;

      if (!methodDetail?.id || !entityId) {
        return [];
      }

      const allRuns = await EstimationMethodDomain.run.getList({
        filters: {
          methodId: methodDetail.id,
          entityType,
        },
        page,
        pageSize: rowPerPage,
        sortBy: sort ?? sorting.sortBy,
        sortDirection: sortDirection ?? sorting.sortDirection,
      });

      // use the first calculation run to generate the headers for all the outputs. Assumption is that all calculation runs for this
      // estimation method have the exact same output shape
      if (allRuns.data?.length) {
        setInputOutputHeaders([
          ...extractInputHeadersFromRun(allRuns.data[0]),
          ...extractOutputHeadersFromRun(allRuns.data[0]),
        ]);
      }

      return allRuns;
    },
    [methodDetail, entityId]
  );
  const tableHeaders: Array<HeaderType<EstimationMethodRunType>> = [
    {
      label: "Time Period",
      key: "year_month",
      isSortable: true,
      renderComponent: ({
        item: { year_month: yearMonth, calculation_start: calculationStart },
      }) => (
        <Link
          label={
            yearMonth && calculationStart
              ? monthFormatter(
                  parse(
                    yearMonth,
                    DateFormats.YEAR_MONTH,
                    parseISO(calculationStart)
                  )
                )
              : "-"
          }
          onClick={() => onClickRow(methodDetail?.id, entityId, yearMonth)}
        />
      ),
    },
    ...inputOutputHeaders,
  ];

  const { tableProps } = useTableState({
    onFetchData: fetchTableData,
    itemsKey: "data",
    initialSort: sorting,
  });

  return (
    <DataTablePanel
      storageKey={tableConfigStorageKey}
      panelProps={{
        title: "Results",
      }}
      dataTableProps={{
        ...tableProps,
        headers: tableHeaders,
      }}
    />
  );
};

export default EstimationMethodRecordsTab;
