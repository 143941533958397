import React from "react";
import { Badge } from "react-bootstrap";
import "./NotesColumn.scss";

const cellRenderer = (rowData, columnKey) => {
  const testNoteCount = rowData[columnKey];

  if (testNoteCount === 0) {
    return "-";
  } else {
    return (
      <div className="notesColumn notesColumn__hasNotes">
        <Badge>{testNoteCount}</Badge>
      </div>
    );
  }
};

export const notesColumn = {
  label: "Notes",
  key: "note_count",
  width: 150,
  cellRenderer,
};
