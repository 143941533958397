export const SOURCES_TYPE = {
  inlets: {
    streamSourceKey: "inlet_streams",
    inventorySourceKey: "inlet_inventories",
  },
  outlets: {
    streamSourceKey: "outlet_streams",
    inventorySourceKey: "outlet_inventories",
  },
  references: {
    streamSourceKey: "reference_streams",
    inventorySourceKey: "reference_inventories",
  },
};
