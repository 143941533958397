import { GetPath } from "../LayoutHelper";
import { getCurrentPath } from "#routers/historyHelper";

export function isLinkIncludeInPath(link) {
  const currentBrowserPath = GetPath(getCurrentPath());

  return (
    link === currentBrowserPath.mainPath ||
    link?.includes(currentBrowserPath.nestedPath)
  );
}
