import React, { useEffect, useState } from "react";
import * as PropTypes from "prop-types";
import { fetchAdjustmentFactors } from "#redux/actions/adjustmentFactors";
import { havePermission } from "#redux/reducers/permissions";
import { connect } from "react-redux";
import RecordOfVolumeService from "#services/RecordOfVolumeService";
import { useFetchDailyVolume } from "#src/hooks/records/fetches";
import { useRecordSelectionContext } from "./Context/recordSelectionContext";
import ReactLoader from "#components/LoadingBar/ReactLoader";
import DailyVolumeChart from "#components/Records/Volume/DailyVolume/DailyVolumeChart";
import DailyVolumeTable from "#components/Records/Volume/DailyVolume/DailyVolumeTable";
import VolumeEntryModal from "#components/Records/Volume/VolumeEntry/VolumeEntryModal";

const DAILY_VOLUME_TABLE_HEIGHT = 540;

const VOLUME_ENTRY_ACTIONS = ["add", "edit", "view"];

const mapStateToProps = ({ adjustmentFactors, permissions }) => {
  return {
    haveWritePermission: havePermission(permissions)(
      "gas:accounting.records_of_volume",
      "write"
    ),
    adjustmentFactors: adjustmentFactors?.data.toJS() ?? [],
  };
};

const mapDispatchToProps = {
  fetchAdjustmentFactors,
};

const DailyVolumeSection = ({
  adjustmentFactors,
  fetchAdjustmentFactors,
  haveWritePermission,
}) => {
  const [modalState, setModalState] = useState("closed");
  const [shouldRefetch, setShouldRefetch] = useState(false);
  const [streamAdjustmentConfigs, setStreamAdjustmentConfigs] = useState([]);

  const { stream, accountingRecordDetail, updateRecordDetail } =
    useRecordSelectionContext();

  const { from, until } = accountingRecordDetail?.accounting_period ?? {};

  const [volumeLoadingState, volumes, refetchDailyVolume] = useFetchDailyVolume(
    stream.id,
    from,
    until
  );

  useEffect(() => {
    fetchAdjustmentFactors();

    RecordOfVolumeService.getStreamVolumeAdjustmentConfigs().then(
      ({ data }) => {
        setStreamAdjustmentConfigs(data);
      }
    );
  }, []);

  const onVolumeEntryModalClose = () => {
    setModalState("closed");

    if (shouldRefetch) {
      refetchDailyVolume();

      updateRecordDetail();

      setShouldRefetch(false);
    }
  };

  return (
    <div>
      <ReactLoader
        loaded={volumeLoadingState !== "loading"}
        position={50}
      >
        <DailyVolumeChart
          streamId={stream.id}
          from={from}
          to={until}
        />

        <DailyVolumeTable
          haveWritePermission={haveWritePermission}
          streamId={stream.id}
          adjustmentFactors={adjustmentFactors}
          volumes={volumes}
          dateRange={{ from, to: until }}
          height={DAILY_VOLUME_TABLE_HEIGHT}
          noFilter={true}
          setModalState={setModalState}
        />
      </ReactLoader>

      {VOLUME_ENTRY_ACTIONS.includes(modalState) && (
        <VolumeEntryModal
          show
          onHide={onVolumeEntryModalClose}
          streamId={stream.id}
          action={modalState}
          setShouldRefetchDailyVolume={setShouldRefetch}
          streamAdjustmentConfigs={streamAdjustmentConfigs}
          isInventoryStream={stream?.associated_with_tank}
        />
      )}
    </div>
  );
};

DailyVolumeSection.propTypes = {
  adjustmentFactors: PropTypes.array.isRequired,
  fetchAdjustmentFactors: PropTypes.func.isRequired,
  haveWritePermission: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(DailyVolumeSection);
