import {
  fetchAndCollate,
  getFilterObject,
  GetListRequestType,
  GetListResponseType,
  ResourceServiceType,
} from "../util";
import { ReportWithDownloadType, TemplatedReportStatusType } from "../schemas";
import { restAPI } from "./api";

interface ReportServiceType
  extends Pick<
    ResourceServiceType<ReportWithDownloadType>,
    "getOne" | "getList"
  > {
  getList: (
    params: GetListRequestType<
      Pick<
        ReportWithDownloadType,
        | "id"
        | "name"
        | "templated_report_name"
        | "status"
        | "created_at"
        | "updated_at"
        | "updated_by"
        | "created_by"
      > & {
        "templated_report.display_name": string;
        "templated_report.status": TemplatedReportStatusType;
      }
    >
  ) => Promise<GetListResponseType<ReportWithDownloadType>>;
}

export const ReportAdapter: ReportServiceType = {
  /**
   * Get a report object with download link
   * @see {@link https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/reports/get_report_v2}
   * @returns a report object
   */
  getOne: ({ id }) =>
    restAPI.nodeAPI
      .GET<ReportWithDownloadType>({
        endpoint: `/reports/${id}`,
        version: 2,
      })
      .then((resp) => ({
        data: resp,
      })),
  getList: ({ page, pageSize, sortBy, sortDirection, filters }) =>
    fetchAndCollate(
      ({ page, pageSize }) =>
        restAPI.nodeAPI.POST({
          version: 2,
          endpoint: "/reports/search",
          body: {
            page,
            page_size: pageSize,
            sort_by: sortBy,
            sort_direction: sortDirection,
            filter: getFilterObject(filters),
          },
        }),
      page,
      pageSize
    ),
};
