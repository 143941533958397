import {
  SummaryInformation,
  SummaryInformationContainer,
} from "#components/Common/SummaryInformation";
import { useEstimationMethodDetailContext } from "#src/batteries-included-components/Layouts/EstimationMethod/Detail";
import { SinglePropertyPillPanel } from "#src/batteries-included-components/Panels/PillPanels/SinglePropertyPillPanel";
import config from "#src/config";
import { QUERY_STATUS } from "#src/constants";
import { getTimeStringFromDate } from "#utils/timeFormatter";
import { FormulaRenderer, Panel } from "@validereinc/common-components";
import classNames from "classnames/bind";
import { Tag } from "phosphor-react";
import React from "react";
import EstimationMethodDetailsPanel, {
  EstimationMethodDetailsPanelProps,
} from "./EstimationMethodDetailsPanel";
import styles from "./EstimationMethodOverviewTab.module.scss";

const cx = classNames.bind(styles);

const EstimationMethodOverviewTab = ({
  detailsPanelProps = {},
}: EstimationMethodOverviewTabProps) => {
  const { method, calculator } = useEstimationMethodDetailContext();
  const methodData = method?.data;
  const calculatorDetail = calculator?.data;
  const lastCalculatorVersion = calculatorDetail?.versions.find(
    ({ version }: { version: string }) =>
      version === calculatorDetail?.default_version
  );
  const sharedSummaryInfoProps = {
    type: "vertical",
    allowWrap: true,
    isStatusPanel: true,
  };

  return (
    <div className={cx("container")}>
      <div className={cx("subContainer", "alignStart")}>
        <Panel
          isFluidY={false}
          style={{ marginBottom: 0, alignSelf: "flex-start" }}
        >
          <SummaryInformationContainer variant="vertical">
            <SummaryInformation
              {...sharedSummaryInfoProps}
              title="Updated At"
              value={getTimeStringFromDate(
                methodData?.updated_at,
                config.DATETIME_FORMAT_READABLE
              )}
            />
            <SummaryInformation
              {...sharedSummaryInfoProps}
              title="Updated By"
              value={methodData?.updatedBy?.name}
            />
            <SummaryInformation
              {...sharedSummaryInfoProps}
              title="Created At"
              value={getTimeStringFromDate(
                methodData?.created_at,
                config.DATETIME_FORMAT_READABLE
              )}
            />
            <SummaryInformation
              {...sharedSummaryInfoProps}
              title="Created By"
              value={methodData?.createdBy?.name}
            />
          </SummaryInformationContainer>
        </Panel>
        <SinglePropertyPillPanel
          panelProps={{
            title: "Reporting Scenarios",
            loaded: method?.status !== QUERY_STATUS.LOADING,
          }}
          values={methodData?.reporting_groups?.map(({ name }) => name) ?? []}
          emptyStateProps={{
            title: "No Reporting Scenarios",
            suggestion:
              "Edit this estimation method to associate reporting scenarios",
            icon: <Tag />,
          }}
        />
      </div>
      <div className={cx("subContainer")}>
        <EstimationMethodDetailsPanel {...detailsPanelProps} />
        {typeof lastCalculatorVersion?.documentation === "object" &&
        Object.keys(lastCalculatorVersion?.documentation ?? {}).length ? (
          <Panel
            isFluidY={false}
            style={{ marginBottom: 0 }}
          >
            <FormulaRenderer
              data={lastCalculatorVersion?.documentation}
              isLoading={calculator?.status === "loading"}
            />
          </Panel>
        ) : null}
      </div>
    </div>
  );
};

export type EstimationMethodOverviewTabProps = {
  detailsPanelProps?: EstimationMethodDetailsPanelProps;
};

export default EstimationMethodOverviewTab;
