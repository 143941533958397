import { WorkflowTaskStatusToPillVariantMap } from "#src/batteries-included-components/Panels/TablePanels/WorkflowsTablePanel/WorkflowsTablePanel.helpers";
import { RoutingLink } from "#src/batteries-included-components/RoutingLink";
import { linkToFormCategoryDetails } from "#src/routes/forms/categories/[categoryId]";
import { linkToFormTemplateDetail } from "#src/routes/forms/categories/[categoryId]/templates/[formTemplateId]";
import { linkToUserDetailPage } from "#src/routes/settings/users/detail";
import { UserGroupDetailsRoutePath } from "#src/routes/settings/users/groups/[groupId]/details";
import { useQuery } from "@tanstack/react-query";
import { KeyValueList, Pill } from "@validereinc/common-components";
import {
  FormSchemaDataType,
  UserGroupsAdapter,
  UsersAdapter,
  WorkflowTaskType,
} from "@validereinc/domain";
import { datetimeFormatter } from "@validereinc/utilities";
import classNames from "classnames/bind";
import startCase from "lodash/startCase";
import React, { ReactElement } from "react";
import styles from "./WorkflowTaskDetailDrawer.module.scss";

const cx = classNames.bind(styles);

export const WorkflowTaskDetailKeyValueList = ({
  selectedTask,
  formSchema,
}: {
  selectedTask: WorkflowTaskType | null;
  formSchema?: FormSchemaDataType;
}) => {
  const { data: assignedToUser } = useQuery(
    ["users", selectedTask?.assignee_user],
    () => {
      if (!selectedTask?.assignee_user) {
        return;
      }

      return UsersAdapter.getOne({ id: selectedTask.assignee_user });
    },
    {
      enabled: Boolean(selectedTask?.assignee_user),
      select: (resp) => resp?.data,
      staleTime: 5 * 60 * 1000,
    }
  );
  const { data: assignedToUserGroup } = useQuery(
    ["users", "groups", selectedTask?.assignee_group],
    () => {
      if (!selectedTask?.assignee_group) {
        return;
      }

      return UserGroupsAdapter.getOne({ id: selectedTask.assignee_group });
    },
    {
      enabled: Boolean(selectedTask?.assignee_group),
      select: (resp) => resp?.data,
      staleTime: 3 * 60 * 1000,
    }
  );
  const { data: updatedByUser } = useQuery(
    ["users", selectedTask?.updated_by],
    () => {
      if (!selectedTask?.updated_by) {
        return;
      }

      return UsersAdapter.getOne({ id: selectedTask.updated_by });
    },
    {
      enabled: Boolean(selectedTask?.updated_by),
      select: (resp) => resp?.data,
    }
  );

  if (!selectedTask) {
    return null;
  }

  const data: Array<{ title: string; value: string | number | ReactElement }> =
    [
      {
        title: "Status",
        value: (
          <Pill
            variant={
              WorkflowTaskStatusToPillVariantMap[selectedTask?.status] ||
              "default"
            }
          >
            {startCase(selectedTask.status)}
          </Pill>
        ),
      },
      {
        title: "Description",
        value: selectedTask.description,
      },
      {
        title: "Assigned To",
        value: assignedToUserGroup ? (
          <RoutingLink
            to={UserGroupDetailsRoutePath.toLink({
              pathParams: { groupId: assignedToUserGroup.id },
            })}
          >
            {assignedToUserGroup.name}
          </RoutingLink>
        ) : assignedToUser ? (
          <RoutingLink to={linkToUserDetailPage(assignedToUser.id)}>
            {assignedToUser.name}
          </RoutingLink>
        ) : (
          "-"
        ),
      },
      {
        title: "Updated At",
        value: datetimeFormatter(new Date(selectedTask.updated_at)),
      },
      {
        title: "Updated By",
        value: updatedByUser?.name ?? "-",
      },
      ...(formSchema
        ? [
            {
              title: "Form Submission Template",
              value: (
                <RoutingLink
                  to={linkToFormTemplateDetail(
                    formSchema.form_category.id,
                    formSchema.id
                  )}
                >
                  {formSchema.name}
                </RoutingLink>
              ),
            },
            {
              title: "Form Submission Template Category",
              value: (
                <RoutingLink
                  to={linkToFormCategoryDetails(formSchema.form_category_id)}
                >
                  {formSchema.form_category.name}
                </RoutingLink>
              ),
            },
          ]
        : []),
    ];

  return (
    <KeyValueList
      data={data}
      className={cx("keyValueListContainer")}
    />
  );
};
