import { StorageKeys } from "@validereinc/common-components";
import React from "react";
import { BasicInfoFormStep } from "./BasicInfoFormStep";
import { FlowConnectionStep } from "./FlowConnectionsStep";

export const FlowFormPanel = ({
  filterConfigStorageKey,
  isConnectionEditOnly,
}: Pick<StorageKeys, "filterConfigStorageKey"> & {
  isConnectionEditOnly?: boolean;
}) => {
  if (isConnectionEditOnly) {
    return <FlowConnectionStep stepNumberOverride={1} />;
  }

  return (
    <>
      <BasicInfoFormStep filterConfigStorageKey={filterConfigStorageKey} />
      <FlowConnectionStep />
    </>
  );
};
