import { useEffect, useState } from "react";
import SampleService from "#services/SampleService";

export const useTestDetail = (testId) => {
  const [state, setState] = useState("loading");
  const [test, setTest] = useState(null);
  const [lastFetchDate, setLastFetchDate] = useState(new Date());

  const refreshTest = () => {
    setLastFetchDate(new Date());
  };

  useEffect(() => {
    setState("loading");

    SampleService.getTestDetails(testId)
      .then(({ data }) => {
        setTest(data);
      })
      .finally(() => {
        setState("loaded");
      });
  }, [testId, lastFetchDate]);

  return [test, state, refreshTest];
};
