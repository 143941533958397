import { getAbsoluteValue } from "#utils/stringFormatter";
import { getTimeStringFromDate } from "#utils/timeFormatter";
import { Title, Tooltip } from "@validereinc/common-components";
import { getFormattedNumberWithUnit } from "@validereinc/utilities";
import * as PropTypes from "prop-types";
import React from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import config from "../../../config";
import "./AssociatedTestsCell.scss";

const getFormattedMeasurements = (mesurementsObject) => {
  return Object.entries(mesurementsObject).map(([name, measurement]) => {
    const formatttedValue = getFormattedNumberWithUnit({
      value: getAbsoluteValue(measurement?.value),
      unit: measurement?.unit,
    });

    return { name, value: formatttedValue };
  });
};

const AssociatedTestsCellRow = ({ label, value }) => {
  return (
    <div className="associatedTestsCell__row">
      <div className="associatedTestsCell__label">
        <Tooltip content={label}>{label}</Tooltip>
      </div>
      <div className="associatedTestsCell__value">{value}</div>
    </div>
  );
};

const AssociatedTestsCellPopover = ({ measurements, name, ...props }) => {
  return (
    <Popover
      {...props}
      id="AssociatedTestsCell__popover"
      className="associatedTestsCell__popover"
    >
      <Title
        type="subheader"
        className="associatedTestsCell__popover__title"
      >
        Test Detail
      </Title>

      <div className="associatedTestsCell__popover__content">
        <div className="associatedTestsCell__name">
          <Tooltip content={name}>{name}</Tooltip>
        </div>

        {measurements.map(({ name, value }) => (
          <AssociatedTestsCellRow
            key={name}
            label={name}
            value={value}
          />
        ))}
      </div>
    </Popover>
  );
};

const AssociatedTestsCell = ({ associatedTests, rowHeight }) => {
  if (associatedTests.length == 0) {
    return "-";
  }

  return (
    <div
      className="associatedTestsCell"
      style={{ height: rowHeight }}
    >
      {associatedTests.map(({ date, instrument, id, measurements }) => {
        const formattedMeasurements = getFormattedMeasurements(measurements);

        const popover = (
          <AssociatedTestsCellPopover
            key={id}
            name={instrument?.name}
            measurements={formattedMeasurements}
          />
        );

        return (
          <div key={id}>
            <OverlayTrigger
              trigger="click"
              placement="left"
              rootClose
              overlay={popover}
            >
              <div className="buttonLink">
                {getTimeStringFromDate(date, config.DATETIME_FORMAT)}
              </div>
            </OverlayTrigger>
          </div>
        );
      })}
    </div>
  );
};

AssociatedTestsCellRow.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
};

AssociatedTestsCellPopover.propTypes = {
  measurements: PropTypes.array.isRequired,
  name: PropTypes.string,
};

AssociatedTestsCell.propTypes = {
  associatedTests: PropTypes.array.isRequired,
  rowHeight: PropTypes.number,
};

export default AssociatedTestsCell;
