const noStatusMatch = (status, roqStatus) => {
  return status.every((s) => {
    // the api will return "validere_approved" when the roq is being approved
    // by a validere user and "approved" for normal user.
    if (s.id === "approved") {
      return s.id !== roqStatus && "validere_approved" !== roqStatus;
    }

    return s.id !== roqStatus;
  });
};

export function filterRecordOfQualities(recordOfQualities, filters) {
  const { status, product, category } = filters;

  const filteredRecordOfQualities = recordOfQualities.filter((roq) => {
    if (status?.length > 0 && noStatusMatch(status, roq.state)) {
      return false;
    }

    // Users aren't used to thinking about 'phase' because everyone measures
    // products in the same phase in the industry
    // (i.e. gas is always gas, NGL/C2+/C3+/C5+/oil are always liquid).
    if (product?.length > 0 && product.every((p) => p !== roq.stream.phase)) {
      return false;
    }

    if (category?.length > 0 && category.every((c) => c !== roq.category)) {
      return false;
    }

    return true;
  });

  return filteredRecordOfQualities;
}

export function getAllFilterInputs(filters) {
  const filterPillbox = {};

  Object.keys(filters).map((key) => {
    const inputs = filters[key]?.inputs ?? [];
    if (inputs.length > 0) {
      filterPillbox[key] = inputs;
    }
  });

  return filterPillbox;
}

export function clearAllInputs(filterDropdowns) {
  const updatedFilterDropdowns = { ...filterDropdowns };

  Object.keys(updatedFilterDropdowns).map((key) => {
    updatedFilterDropdowns[key].inputs = [];
  });

  return updatedFilterDropdowns;
}
