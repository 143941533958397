import {
  WorkflowSystemActionType,
  WorkflowSystemActionSchema,
  WorkflowSchema,
  WorkflowType,
} from "../schemas";

import { DomainFaker, DomainMock } from "./utils";

export const WorkflowSystemActionMock = new DomainMock<
  WorkflowSystemActionType,
  typeof WorkflowSystemActionSchema
>(
  WorkflowSystemActionSchema,
  () => {
    return {
      type: DomainFaker.en.helpers.arrayElement(["system_action", "user_task"]),
      task: {
        type: "create_event",
        event_schema_id: DomainFaker.en.string.uuid(),
        event_category_id: DomainFaker.en.string.uuid(),
      },
      next: DomainFaker.en.string.uuid(),
      end: DomainFaker.en.helpers.arrayElement([true, false]),
    };
  },
  true
).fix();

export const WorkflowMock = new DomainMock<WorkflowType, typeof WorkflowSchema>(
  WorkflowSchema,
  () => {
    return {
      created_at: DomainFaker.en.date.past().toISOString(),
      updated_at: DomainFaker.en.date.past().toISOString(),
      created_by: DomainFaker.en.string.uuid(),
      updated_by: DomainFaker.en.string.uuid(),
      id: DomainFaker.en.string.uuid(),
      company_id: DomainFaker.en.string.uuid(),
      workflow_template_id: DomainFaker.en.string.uuid(),
      workflow_template: {
        id: DomainFaker.en.string.uuid(),
        name: DomainFaker.en.word.words(),
        description: DomainFaker.en.lorem.sentences(2),
        workflow_category: {
          id: DomainFaker.en.string.uuid(),
          name: DomainFaker.en.word.words(),
        },
      },
      facility_id: DomainFaker.en.string.uuid(),
      asset_id: DomainFaker.en.string.uuid(),
      time_period: DomainFaker.en.word.words(),
      duration: DomainFaker.en.number.int(),
      status: DomainFaker.en.helpers.arrayElement([
        "complete",
        "in_progress",
        "overdue",
        "dismissed",
        "missed",
      ]),
      due_date: DomainFaker.en.date.past().toISOString(),
      facility: {
        id: DomainFaker.en.string.uuid(),
        name: DomainFaker.en.word.words(),
      },
      step_states: {},
      config: {
        start: "",
        steps: {
          test: WorkflowSystemActionMock.mock,
        },
      },
      variables: {},
    };
  },
  true
).fix();
