import { WorkflowsFilterPanel } from "#src/batteries-included-components";
import {
  WorkflowsTablePanel,
  WorkflowsTablePanelProps,
} from "#src/batteries-included-components/Panels/TablePanels/WorkflowsTablePanel";
import { AssetTypeType } from "@validere/domain";
import React from "react";

type WorkflowsAllLayoutProps = {
  tablePanelProps: WorkflowsTablePanelProps;
};

export const WorkflowsListLayout = ({
  tablePanelProps,
  assetType,
}: WorkflowsAllLayoutProps & { assetType?: AssetTypeType }) => {
  return (
    <>
      <WorkflowsFilterPanel
        hasSelectCategory={!tablePanelProps?.isWithinWorkflowTemplateDetails}
        hasSelectTemplate={!tablePanelProps?.isWithinWorkflowTemplateDetails}
        filterConfigStorageKey={tablePanelProps.filterConfigStorageKey}
        assetType={assetType}
      />
      <WorkflowsTablePanel {...tablePanelProps} />
    </>
  );
};
