import React from "react";
import * as PropTypes from "prop-types";
import InstrumentDetail from "../InstrumentDetail";
import { connect } from "react-redux";
import { getMeasurementType } from "../../Redux/reducers/measurements";
import { getAlertSpec } from "../InstrumentComponentHelper";
import get from "lodash/get";
import {
  dateRenderer,
  sampleDateRenderer,
  sortBySampleDate,
  getMeasurementValue,
} from "../InstrumentHelper";
import MeasurementStatus from "../Shared/TestsDatatable/MeasurementStatus";
import { notesColumn } from "../Shared/TestsDatatable/NotesColumn";
import {
  sampleStateRenderer,
  sortBySampleState,
} from "../../Samples/SampleHelper";
import { getFirstValidValue } from "#utils/objectFormatter";
import { getTesterName } from "./AntonPaarSVM3001Helper";
import TestDetailModal from "#components/Tests/TestDetailModal/TestDetailModal";

const testerRenderer = (rowData) => {
  const tester = getTesterName(rowData) ?? "-";
  return <div>{tester}</div>;
};

const measurementRenderer = (measurementType) =>
  function measurementRendererInner(rowData, columnKey) {
    const defaultAlertSpec = {
      measurement_unit: measurementType.unit,
      upper_limit: 8,
      lower_limit: 0,
    };

    try {
      const value = getMeasurementValue(
        rowData.measurements[columnKey].value,
        measurementType.decimals
      );
      const unit = rowData.measurements[columnKey].unit;

      return (
        <MeasurementStatus
          value={value}
          unit={unit}
          labels={rowData.labels}
          status={rowData.status}
          defaultAlertSpec={defaultAlertSpec}
          alertSpec={getAlertSpec(
            rowData.measurement_alert_specs,
            measurementType.name,
            defaultAlertSpec
          )}
          measurementType={measurementType}
        />
      );
    } catch {
      return null;
    }
  };

const measuringTempRenderer = (rowData) => {
  const temperature_in_c = getFirstValidValue([
    rowData.measurements["Kinematic Viscosity"]?.metadata?.temp?.value,
    rowData.measurements["Kinematic Viscosity"]?.temperature_in_c,
  ]);

  if (temperature_in_c) {
    return <div>{temperature_in_c} °C</div>;
  } else {
    return <div>-</div>;
  }
};

const sortByMeasurement = (list, measurementName) => {
  return [...list].sort((a, b) => {
    return (
      a.measurements[measurementName].value -
      b.measurements[measurementName].value
    );
  });
};

const headers = (measurementType) => {
  return [
    {
      label: "Name",
      key: "name",
      width: 400,
      fixed: true,
    },
    {
      label: "Sample State",
      key: "sample_state",
      width: 150,
      cellRenderer: (row) => sampleStateRenderer(row.sample),
      sort: sortBySampleState,
    },
    {
      label: "Date",
      key: "date",
      width: 170,
      cellRenderer: dateRenderer,
    },
    {
      label: "Tester",
      key: "sample",
      width: 150,
      cellRenderer: testerRenderer,
      sort: (list) => {
        return list.sort((a, b) => {
          const aValue = getTesterName(a) ?? "-";
          const bValue = getTesterName(b) ?? "-";

          return aValue.localeCompare(bValue);
        });
      },
      suppress: true,
    },
    {
      label: "Measuring Temp.",
      key: "measuring_temp",
      width: 150,
      cellRenderer: measuringTempRenderer,
      sort: (list) => {
        return list.sort((a, b) => {
          const aValue = get(
            a.measurements["Kinematic Viscosity"],
            "metadata.temp.value",
            "-"
          );
          const bValue = get(
            b.measurements["Kinematic Viscosity"],
            "metadata.temp.value",
            "-"
          );

          return aValue.localeCompare(bValue);
        });
      },
    },
    {
      label: measurementType.display_name,
      key: measurementType.name,
      width: 250,
      cellRenderer: measurementRenderer(measurementType),
      sort: sortByMeasurement,
    },
    {
      label: "Sample Start Date",
      key: "started_at",
      width: 170,
      cellRenderer: sampleDateRenderer,
      sort: sortBySampleDate,
    },
    {
      label: "Sample End Date",
      key: "ended_at",
      width: 170,
      cellRenderer: sampleDateRenderer,
      sort: sortBySampleDate,
    },
    notesColumn,
  ];
};

const mapStateToProps = (state) => {
  return {
    measurementType: getMeasurementType(state.measurements)(
      "Kinematic Viscosity"
    ),
  };
};

const InstrumentAntonPaarSVM3001Tests = (props) => {
  return (
    <InstrumentDetail
      testId={props.match.params.testId}
      height={props.height}
      instrumentInfo={props.instrumentInfo}
      ReportModalComponent={TestDetailModal}
      filterKey="name"
      headers={headers(props.measurementType, props.instrumentInfo?.type)}
      defaultSortBy="date"
      defaultSortDirection="desc"
    />
  );
};

InstrumentAntonPaarSVM3001Tests.propTypes = {
  match: PropTypes.object.isRequired,
  height: PropTypes.number.isRequired,
  instrumentInfo: PropTypes.object.isRequired,
  measurementType: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  undefined
)(InstrumentAntonPaarSVM3001Tests);
