import DeleteModal from "#common/DeleteModal/DeleteModal";
import DataMappingService from "#components/Services/DataMappingService";
import { havePermission } from "#redux/reducers/permissions";
import { getBreadcrumbsObject } from "#routers/breadcrumbsHelper";
import { useNavigate, useSearchParams } from "#routers/hooks";
import {
  linkToCreateDataMapping,
  linkToUploadDataMapping,
} from "#routers/links";
import useTableState from "#src/hooks/useTableState";
import { getFrontendTableState } from "#utils/frontendTableActions";
import {
  Button,
  DataTable,
  Filters,
  Page,
  Panel,
  SortingType,
} from "@validereinc/common-components";
import { SortDirection } from "@validereinc/domain";
import * as PropTypes from "prop-types";
import React, { useCallback, useState } from "react";
import { connect } from "react-redux";
import useDataMappingConfig from "./useDataMappingConfig";

const sorting: SortingType = {
  sortBy: "mapping_name",
  sortDirection: SortDirection.ASCENDING,
};

const mapStateToProps = ({ permissions }) => {
  return {
    haveWritePermission: havePermission(permissions)(
      "core:data_ingest",
      "write"
    ),
  };
};

export const DataMapping = ({ breadcrumbs, haveWritePermission }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const dataMappingBreadcrumbs = getBreadcrumbsObject(breadcrumbs);

  const [response, setResponse] = useState(undefined);

  const [dataMappingToDelete, setDataMappingToDelete] = useState(undefined);

  const onFetchData = useCallback(
    async ({ refreshData = false, ...newSearchParams }) => {
      let newResponse = response;

      if (refreshData || !response) {
        newResponse = await DataMappingService.getDataMapping();

        setResponse(newResponse);
      }

      const searchParamsWithDefaults = {
        ...newSearchParams,
        sort: newSearchParams.sort ?? sorting.sortBy,
        sortDirection: newSearchParams.sortDirection ?? sorting.sortDirection,
      };

      return getFrontendTableState({
        data: newResponse,
        itemsKey: "data",
        query: searchParamsWithDefaults,
      });
    },
    [response]
  );

  const { tableProps, data, fetchData } = useTableState({
    onFetchData,
    initialSort: sorting,
  });

  const onClickCreateDataMapping = () => {
    navigate({ pathname: linkToCreateDataMapping() });
  };

  // Given the change in internal filter state, update query params
  const onFiltersChange = (values) => {
    setSearchParams({ ...searchParams, ...values });
  };

  const onUploadClick = (dataMappingDetail) => {
    navigate({
      pathname: linkToUploadDataMapping(dataMappingDetail.data_mapping_id),
    });
  };

  const handleOnDeleteClick = () => {
    fetchData({ ...searchParams, refreshData: true });
  };

  const { filters, headers } = useDataMappingConfig({
    dataMappingList: data?.allItems ?? [],
  });

  const getItemActions = ({ item }) => [
    {
      label: "Upload",
      buttonProps: {
        onClick: () => onUploadClick(item),
        icon: "upload-simple",
      },
    },
    {
      label: "Delete",
      buttonProps: {
        onClick: () => setDataMappingToDelete(item),
        icon: "trash",
      },
    },
  ];

  return (
    <Page
      title="Data Mapping"
      breadcrumbs={dataMappingBreadcrumbs}
      actionRow={
        haveWritePermission ? (
          <Button
            variant="primary"
            onClick={onClickCreateDataMapping}
          >
            Create Data Mapping
          </Button>
        ) : null
      }
    >
      <Filters
        onChange={onFiltersChange}
        filters={filters}
      />

      <Panel>
        <DataTable
          {...tableProps}
          headers={headers}
          getItemActions={haveWritePermission ? getItemActions : undefined}
        />
      </Panel>

      <DeleteModal
        open={!!dataMappingToDelete}
        onClose={() => setDataMappingToDelete(undefined)}
        doDelete={() =>
          DataMappingService.deleteDataMapping(
            dataMappingToDelete?.data_mapping_id
          )
        }
        onDelete={handleOnDeleteClick}
        entityName="Data Mapping"
        instanceName={dataMappingToDelete?.mapping_name ?? "-"}
      />
    </Page>
  );
};

DataMapping.propTypes = {
  breadcrumbs: PropTypes.array.isRequired,
  haveWritePermission: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(DataMapping);
