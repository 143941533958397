import {
  SummaryInformation,
  SummaryInformationContainer,
} from "#components/Common/SummaryInformation";
import { statusPillMapping } from "#src/batteries-included-components/Panels/TablePanels/EventsTablePanel";
import { EventContext } from "#src/contexts/EventContext";
import { Panel } from "@validereinc/common-components";
import { datetimeFormatter } from "@validereinc/utilities";
import classNames from "classnames/bind";
import React, { useContext } from "react";
import styles from "./styles.module.scss";

const cx = classNames.bind(styles);

export const EventStatusPanel = () => {
  const { event, isLoading } = useContext(EventContext) || {};

  const sharedProps = {
    type: "vertical",
    isLoading,
    allowWrap: true,
    isStatusPanel: true,
  };

  return (
    <div className={cx("statusContainer")}>
      {event ? (
        <Panel>
          <SummaryInformationContainer
            variant="vertical"
            status={event?.status}
            statusVariant={statusPillMapping[event.status]}
          >
            <SummaryInformation
              {...sharedProps}
              title="Event Start"
              value={
                event?.start
                  ? datetimeFormatter(new Date(event.start))
                  : undefined
              }
            />

            <SummaryInformation
              {...sharedProps}
              title="Event End"
              value={
                event?.end ? datetimeFormatter(new Date(event.end)) : undefined
              }
            />

            <SummaryInformation
              {...sharedProps}
              title="Due Date"
              value={
                event?.due_date
                  ? datetimeFormatter(new Date(event.due_date))
                  : undefined
              }
            />

            <SummaryInformation
              {...sharedProps}
              title="Created"
              value={
                event?.created_at
                  ? datetimeFormatter(new Date(event.created_at))
                  : undefined
              }
            />
          </SummaryInformationContainer>
        </Panel>
      ) : null}
    </div>
  );
};
