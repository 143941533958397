import { QuestionField } from "#batteries-included-components/Forms/FormSubmissionForm/FormSubmissionSections/FormSubmissionField/QuestionField";
import {
  useGenerateTemplatedReport,
  useGetOneTemplatedReport,
} from "#hooks/adapters/useTemplatedReports";
import { useBreadcrumbs } from "#routers/breadcrumbsHelper";
import { useNavigate, useParams } from "#routers/hooks";
import { REPORTS_BREADCRUMB } from "#routes/reports";
import {
  TEMPLATED_REPORT_EXPORT_BREADCRUMB,
  TEMPLATED_REPORT_EXPORT_PAGE_TITLE,
  TemplatedReportExportParamType,
} from "#routes/reports/templated-reports/[reportName]/export/index";
import {
  linkToTemplatedReportList,
  TEMPLATED_REPORT_LIST_BREADCRUMB,
} from "#routes/reports/templated-reports/index";
import {
  Button,
  Form,
  Page,
  Panel,
  useForm,
} from "@validereinc/common-components";
import React from "react";

/** Don't submit empty values */
const sanitizeValues = (inputs: Record<string, any>) =>
  Object.fromEntries(
    Object.entries(inputs).filter(([_, value]) => !!value && value !== "")
  );

export const TemplatedReportExportPage = () => {
  const navigate = useNavigate();
  const { reportName } = useParams<TemplatedReportExportParamType>();

  const { data, isLoading } = useGetOneTemplatedReport({ id: reportName });

  const displayName = data?.data?.display_name
    ? `Export ${data?.data?.display_name}`
    : TEMPLATED_REPORT_EXPORT_PAGE_TITLE;

  const inputs = Object.entries(data?.data?.input_schema ?? {}).map(
    ([key, values]) => ({ key, ...values })
  );

  const navigateToTemplatedReportList = () => {
    navigate({ pathname: linkToTemplatedReportList() });
  };

  const generateReport = useGenerateTemplatedReport({
    onSuccess: navigateToTemplatedReportList,
  });

  const form = useForm();

  const handleExport = form.handleSubmit((values) => {
    generateReport.mutate({
      templatedReportName: reportName,
      name: data?.data?.display_name,
      input: sanitizeValues(values),
    });
  });

  return (
    <Page
      title={displayName}
      category="Templated Reports"
      breadcrumbs={useBreadcrumbs(
        [
          REPORTS_BREADCRUMB,
          TEMPLATED_REPORT_LIST_BREADCRUMB,
          TEMPLATED_REPORT_EXPORT_BREADCRUMB,
        ],
        { 2: data?.data?.display_name }
      )}
      footer={
        <>
          <Button
            onClick={() => navigateToTemplatedReportList()}
            disabled={generateReport.isLoading}
          >
            Cancel
          </Button>

          <Button
            variant="primary"
            type="submit"
            onClick={handleExport}
            disabled={isLoading || generateReport.isLoading}
          >
            Export
          </Button>
        </>
      }
    >
      <Panel title="Report Details">
        <Form {...form}>
          To create a templated report, configure the fields below and click
          &quot;Export.&quot;
          <div style={{ margin: 8, width: 254 }}>
            {inputs.map((input) => (
              <QuestionField
                {...input}
                key={input.key}
                name={input.key}
                prompt={input.display_name}
                isFluid={false}
              />
            ))}
          </div>
        </Form>
      </Panel>
    </Page>
  );
};
