import MeasurementsService from "#services/MeasurementsService";
import { MeasurementType } from "@validereinc/domain";
import { useEffect, useState } from "react";

// IMPROVE: deprecate this and use react query and @validereinc/domain adapters
const useMeasurementTypes = (): [MeasurementType[], string] => {
  const [measurementTypes, setMeasurementTypes] = useState<MeasurementType[]>(
    []
  );
  const [loadingState, setLoadingState] = useState("loading");

  useEffect(() => {
    MeasurementsService.getMeasurementTypes()
      .then(({ data }) => {
        setMeasurementTypes(data);
        setLoadingState("loaded");
      })
      .catch((error) => {
        console.error(error);
        setLoadingState("error");
      });
  }, []);

  return [measurementTypes, loadingState];
};

export default useMeasurementTypes;
