import {
  CalculatorDomain,
  CalculatorType,
  GetCalculatorsRequestType,
} from "@validereinc/domain";
import { useEffect, useState } from "react";

import { QUERY_STATUS, QueryStatusType } from "../../constants/fetch";

/**
 * Request convenience hook to get a single calculator
 */
export function useGetCalculator(
  calculatorId?: string
): [CalculatorType | undefined, QueryStatusType] {
  const [calculatorDetail, setCalculatorDetail] = useState<CalculatorType>();
  const [fetchState, setFetchState] = useState<QueryStatusType>(
    QUERY_STATUS.LOADING
  );

  useEffect(() => {
    if (!calculatorId) {
      return;
    }

    setFetchState(QUERY_STATUS.LOADING);
    CalculatorDomain.getCalculator({
      calculatorId,
    })
      .then((data) => {
        setCalculatorDetail(data);
        setFetchState(QUERY_STATUS.SUCCESS);
      })
      .catch((err) => {
        console.error(err);
        setFetchState(QUERY_STATUS.ERROR);
      });
  }, [calculatorId]);

  return [calculatorDetail, fetchState];
}

/**
 * Request convenience hook to get a list of calculators
 */
export function useListCalculator(
  params: GetCalculatorsRequestType
): [CalculatorType[] | undefined, QueryStatusType] {
  const [calculators, setCalculators] = useState<CalculatorType[]>();
  const [fetchState, setFetchState] = useState<QueryStatusType>(
    QUERY_STATUS.LOADING
  );

  const getData = async () => {
    const { calculators: allCalculators } =
      await CalculatorDomain.getCalculators(params);

    return allCalculators;
  };

  useEffect(() => {
    getData()
      .then((data) => {
        setCalculators(data);
        setFetchState(QUERY_STATUS.SUCCESS);
      })
      .catch((err) => {
        console.error(err);
        setFetchState(QUERY_STATUS.ERROR);
      });
  }, []);

  return [calculators, fetchState];
}
