export const COMPONENT_QUALITIES = [
  "Methane (C1)",
  "Ethane (C2)",
  "Propane (C3)",
  "Propane Minus (C3-)",
  "Isobutane (iC4)",
  "n-Butane (NC4)",
  "Butane Minus (C4-)",
  "Butane (C4)",
  "Deemed Butane",
  "Isopentane (iC5)",
  "n-Pentane (NC5)",
  "Pentane (C5)",
  "Pentanes Plus (C5+)",
  "Hexanes (C6)",
  "Hexanes Plus (C6+)",
  "Heptanes Plus (C7+)",
];

export const COMPONENT_TOTAL_OPTIONS = [
  "Total Volume (GEV)",
  "Total Volume (LEV)",
  "Energy",
];

export const COMPONENT_OPTIONS = [
  ...COMPONENT_QUALITIES,
  ...COMPONENT_TOTAL_OPTIONS,
];

export const GAS_DEFAULTS = [
  "Methane (C1)",
  "Ethane (C2)",
  "Propane (C3)",
  "Butane (C4)",
  "Pentane (C5)",
  "Hexanes (C6)",
  "Heptanes Plus (C7+)",
];

export const LIQUID_DEFAULTS = [
  "Methane (C1)",
  "Ethane (C2)",
  "Propane (C3)",
  "Butane (C4)",
  "Pentanes Plus (C5+)",
];

export const OIL_DEFAULTS = COMPONENT_TOTAL_OPTIONS;

export const VOLUME_UNIT_OPTIONS = ["m³", "e3m3", "Mcf", "gal"];

export const COMPONENT_PRICING_OPTIONS = {
  "Methane (C1)": ["e3m3", "Mcf", "GJ", "mmBTU"],
  "Ethane (C2)": ["m3", "BBL", "gal"],
  "Propane (C3)": ["m3", "BBL", "gal"],
  "Propane Minus (C3-)": ["m3", "BBL", "gal"],
  "Isobutane (iC4)": ["m3", "BBL", "gal"],
  "n-Butane (NC4)": ["m3", "BBL", "gal"],
  "Butane Minus (C4-)": ["m3", "BBL", "gal"],
  "Butane (C4)": ["m3", "BBL", "gal"],
  "Deemed Butane": ["m3", "BBL", "gal"],
  "Isopentane (iC5)": ["m3", "BBL"],
  "n-Pentane (NC5)": ["m3", "BBL"],
  "Pentane (C5)": ["m3", "BBL"],
  "Pentanes Plus (C5+)": ["m3", "BBL"],
  "Hexanes (C6)": ["m3", "BBL"],
  "Hexanes Plus (C6+)": ["m3", "BBL"],
  "Heptanes Plus (C7+)": ["m3", "BBL"],
};

export const PRODUCT_PRICING_OPTIONS = {
  Gas: ["e3m3", "Mcf", "GJ", "mmBTU"],
  CRD: ["e3m3", "Mcf", "GJ", "mmBTU"],
  MSO: ["e3m3", "Mcf", "GJ", "mmBTU"],
  LSO: ["e3m3", "Mcf", "GJ", "mmBTU"],
  "C5+": ["m3", "BBL"],
};
