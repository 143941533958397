import { getAlertTimestamp } from "../AlertListHelpers";

export const generateFilters = (alertDetail, interval) => {
  const alertTime = getAlertTimestamp(alertDetail);
  const fromDate = new Date(alertTime);
  fromDate.setDate(fromDate.getDate() - 1);
  const toDate = new Date(alertTime);
  toDate.setDate(toDate.getDate() + 1);

  return {
    date_range: { from: fromDate, to: toDate },
    interval: interval,
  };
};
