import intersectionBy from "lodash/intersectionBy";
import moment from "moment";
import * as PropTypes from "prop-types";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";

import ColumnSelect from "#common/ColumnSelect/ColumnSelect";
import ChainOfCustodyService from "#components/Services/ChainOfCustodyService";
import { getBreadcrumbsObject } from "#routers/breadcrumbsHelper";
import { useNavigate, useSearchParams } from "#routers/hooks";
import { linkToCreateChainOfCustody } from "#routers/links";
import { AssertIsAfterDate, AssertIsBeforeDate } from "#utils/assert";
import { getFrontendTableState } from "#utils/frontendTableActions";
import {
  Button,
  DataTable,
  Filters,
  Page,
  Panel,
} from "@validereinc/common-components";
import useTableState from "../../hooks/useTableState";
import { fetchSiteList } from "../Redux/actions/index";
import ChainOfCustodyDetailModal from "./ChainOfCustodyDetailModal";
import useChainOfCustodyConfig from "./useChainOfCustodyConfig";

import { getLocalStorageItem } from "#utils/localStorageHelper";
import {
  CHAIN_OF_CUSTODY_LOCAL_STORAGE_KEY,
  EARLIEST_COC_ISSUED_AT,
} from "./ChainOfCustodyConstants";

const INITIAL_SORT = {
  header: "issued_at",
  direction: "desc",
};

const mapStateToProps = ({ sites }) => {
  return {
    sites: sites?.data?.toJS() ?? [],
  };
};

const mapDispatchToProps = {
  fetchSiteList,
};

export const ChainOfCustody = ({ fetchSiteList, sites, breadcrumbs }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const [response, setResponse] = useState(undefined);

  const onFetchData = useCallback(
    async ({ hardRefresh = false, cocId: _cocId, ...newSearchParams }) => {
      let newResponse = response;

      if (hardRefresh || !response) {
        newResponse = await ChainOfCustodyService.getChainOfCustodyList(
          EARLIEST_COC_ISSUED_AT,
          moment().endOf("day")
        );

        setResponse(newResponse);
      }

      const searchParamsWithDefaults = {
        ...newSearchParams,
        sort: newSearchParams.sort ?? INITIAL_SORT.header,
        sortDirection: newSearchParams.sortDirection ?? INITIAL_SORT.direction,
      };

      return getFrontendTableState({
        data: newResponse,
        itemsKey: "data",
        query: searchParamsWithDefaults,
        filterMapping: {
          site: {
            itemKey: "site.name",
          },
          from: {
            filter: ({ value, item }) => {
              return AssertIsBeforeDate(item, value);
            },
            itemKey: "issued_at",
          },
          to: {
            filter: ({ value, item }) => {
              return AssertIsAfterDate(item, value);
            },
            itemKey: "issued_at",
          },
          name: {
            filter: ({ value, item }) =>
              value?.toLowerCase()?.includes(item?.toLowerCase()),
          },
        },
      });
    },
    [response]
  );

  const { tableProps, fetchData, data } = useTableState({
    onFetchData,
    initialSort: INITIAL_SORT,
  });

  const { filters, quickFilters, headers } = useChainOfCustodyConfig({
    chainOfCustodyList: data?.allItems ?? [],
    sites,
  });

  const [displayHeaders, setDisplayHeaders] = useState(() => {
    const savedHeaders = getLocalStorageItem(
      CHAIN_OF_CUSTODY_LOCAL_STORAGE_KEY
    );

    if (savedHeaders) {
      return intersectionBy(headers, savedHeaders, "key");
    }

    return headers;
  });

  // Given the change in internal filter state, update query params
  const onFiltersChange = (values) => {
    const { quickFilters, date, ...rest } = values;

    setSearchParams({ ...searchParams, ...date, ...rest, state: quickFilters });
  };

  const handleAddbuttonClick = (chainOfCustody) => {
    navigate({
      pathname: linkToCreateChainOfCustody(chainOfCustody?.id),
    });
  };

  const handleRowClick = (chainOfCustody) => {
    setSearchParams({
      ...searchParams,
      cocId: chainOfCustody?.id,
    });
  };

  const onModalClose = () => {
    setSearchParams({ ...searchParams, cocId: null });
  };

  const hardRefresh = () => {
    fetchData({ ...searchParams, hardRefresh: true });
  };

  useEffect(() => {
    fetchSiteList();
  }, []);

  const chainOfCustodyDetail = useMemo(() => {
    if (data?.allItems) {
      return data?.allItems?.find((coc) => coc.id === searchParams.cocId);
    }
  }, [data, searchParams.cocId]);

  const pageBreadcrumbs = getBreadcrumbsObject(breadcrumbs);

  return (
    <Page
      title="Chain Of Custody"
      breadcrumbs={pageBreadcrumbs}
    >
      <Filters
        onChange={onFiltersChange}
        filters={filters}
        quickFilters={quickFilters}
        actions={[
          <ColumnSelect
            key="column-select"
            options={headers}
            onChange={setDisplayHeaders}
            value={displayHeaders}
            localStorageKey={CHAIN_OF_CUSTODY_LOCAL_STORAGE_KEY}
          />,
          <Button
            key="export-button"
            variant="primary"
            onClick={handleAddbuttonClick}
          >
            Add Chain of Custody
          </Button>,
        ]}
      />

      <Panel>
        <DataTable
          headers={displayHeaders}
          {...tableProps}
          onRowClick={handleRowClick}
          isFluid={false}
          getItemActions={({ item }) => [
            {
              label: "Edit Chain of Custody",
              buttonProps: {
                onClick: () => handleRowClick(item),
                icon: "pencil-simple",
              },
            },
            {
              label: "Duplicate Chain of Custody",
              buttonProps: {
                onClick: () => handleAddbuttonClick(item),
                icon: "copy-simple",
              },
            },
          ]}
        />
      </Panel>

      {chainOfCustodyDetail && (
        <ChainOfCustodyDetailModal
          show={!!chainOfCustodyDetail}
          chainOfCustody={chainOfCustodyDetail}
          close={onModalClose}
          updateChainOfCustodyList={hardRefresh}
        />
      )}
    </Page>
  );
};

ChainOfCustody.propTypes = {
  fetchSiteList: PropTypes.func.isRequired,
  sites: PropTypes.array.isRequired,
  breadcrumbs: PropTypes.array.isRequired,
};

const ChainOfCustodyContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ChainOfCustody);

export default ChainOfCustodyContainer;
