import { AGGREGATOR_OPTIONS, OPERATOR_OPTIONS } from "#constants";
import useLocalization from "#src/hooks/useLocalization";
import classNames from "classnames/bind";
import * as PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import { DropdownInput, TextInput } from "@validereinc/common-components";

import {
  ALERT_DURATION_TOOLTIP,
  ALERT_THRESHOLD_TOOLTIP,
  ALERT_WINDOW_TOOLTIP,
} from "#components/AlertManagement/AlertConstants";
import { Device, DeviceType } from "@validereinc/domain";
import styles from "../CreateAlertConfiguration.module.scss";

const cx = classNames.bind(styles);

const ConditionFields = ({
  form,
  name,
  facilityOptions,
  deviceTypeOptions,
  measurementTypeOptions,
}) => {
  const { localize } = useLocalization();

  const field = form.watch(name);

  const [deviceOptions, setDeviceOptions] = useState<DeviceType[]>();
  const [isDeviceOptionsLoading, setIsDeviceOptionsLoading] = useState(false);
  const [displayMeasurementTypeOptions, setDisplayMeasurementTypeOptions] =
    useState(measurementTypeOptions);
  const [isDisplayMeasurementTypeOptions, setIsDisplayMeasurementTypeOptions] =
    useState(false);

  const onClearDevice = () => {
    form.resetField(`${name}.device`);
    onClearMeasurement();
  };

  const onClearMeasurement = () => {
    form.resetField(`${name}.measurement_series_id`);
  };

  useEffect(() => {
    if (field?.facility && field?.device_type) {
      (async () => {
        setIsDeviceOptionsLoading(true);

        const { data: newDevices } = await Device.getList({
          pageSize: 1000,
          filters: {
            "facility.id": field?.facility,
            type_id: field?.device_type,
          },
        });

        setDeviceOptions(newDevices);

        setIsDeviceOptionsLoading(false);
      })();
    }
  }, [field?.facility, !field?.device_type]);

  useEffect(() => {
    if (field?.device && measurementTypeOptions?.length) {
      (async () => {
        setIsDisplayMeasurementTypeOptions(true);

        const { data: deviceDetail } = await Device.getOne({
          id: field?.device,
        });

        setDisplayMeasurementTypeOptions(
          measurementTypeOptions.reduce((total, { id, name }) => {
            const measurementSeries = deviceDetail.measurement_series.find(
              ({ measurement_type }) => id === measurement_type
            );

            if (measurementSeries) {
              return [
                ...total,
                {
                  name,
                  measurementSeriesId: measurementSeries.id,
                  unit: measurementSeries.unit,
                },
              ];
            } else {
              return total;
            }
          }, [])
        );

        setIsDisplayMeasurementTypeOptions(false);
      })();
    }
  }, [field?.device, measurementTypeOptions]);

  const thresholdUnit = () => {
    if (
      field?.measurement_series_id &&
      field?.aggregation_function !== "count"
    ) {
      return displayMeasurementTypeOptions.find(
        ({ measurementSeriesId }) =>
          measurementSeriesId === field.measurement_series_id
      )?.unit;
    }
    return undefined;
  };

  return (
    <>
      <DropdownInput
        label={localize("facility")}
        name={`${name}.facility`}
        options={facilityOptions}
        labelKey="name"
        valueKey="id"
        isRequired
        onChange={onClearDevice}
      />

      <DropdownInput
        label="Device Type"
        name={`${name}.device_type`}
        options={deviceTypeOptions}
        labelKey="name"
        valueKey="id"
        isRequired
        onChange={onClearDevice}
      />

      <DropdownInput
        label="Device"
        name={`${name}.device`}
        options={deviceOptions}
        labelKey="name"
        valueKey="id"
        isRequired
        isDisabled={!field?.facility || !field?.device_type}
        isLoading={isDeviceOptionsLoading}
        onChange={onClearMeasurement}
      />

      <DropdownInput
        label="Measurement"
        name={`${name}.measurement_series_id`}
        options={displayMeasurementTypeOptions}
        labelKey="name"
        valueKey="measurementSeriesId"
        isRequired
        isDisabled={!field?.device}
        isLoading={isDisplayMeasurementTypeOptions}
      />

      <div className={cx("conditionFieldsContainer")}>
        <DropdownInput
          label="Aggregation Function"
          name={`${name}.aggregation_function`}
          options={AGGREGATOR_OPTIONS}
          labelKey="label"
          valueKey="value"
          isRequired
          isFluid
        />

        <DropdownInput
          label="Operator"
          name={`${name}.operator`}
          options={OPERATOR_OPTIONS}
          labelKey="label"
          valueKey="value"
          isRequired
          isFluid
        />

        <TextInput
          label="Threshold"
          name={`${name}.threshold`}
          type="number"
          unit={thresholdUnit()}
          isRequired
          description={ALERT_THRESHOLD_TOOLTIP}
        />

        <TextInput
          label="Window"
          name={`${name}.window`}
          type="number"
          unit="min"
          isRequired
          description={ALERT_WINDOW_TOOLTIP}
        />

        <TextInput
          label="Duration"
          name={`${name}.duration`}
          type="number"
          unit="min"
          description={ALERT_DURATION_TOOLTIP}
        />
      </div>
    </>
  );
};

ConditionFields.propTypes = {
  form: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  facilityOptions: PropTypes.array.isRequired,
  deviceTypeOptions: PropTypes.array.isRequired,
  measurementTypeOptions: PropTypes.array.isRequired,
};

export default ConditionFields;
