import { MultiDropdownInputWithSearch } from "@validereinc/common-components";
import indexOf from "lodash/indexOf";
import intersectionWith from "lodash/intersectionWith";
import isEqual from "lodash/isEqual";
import map from "lodash/map";
import unionBy from "lodash/unionBy";
import React, { PureComponent } from "react";
import FontAwesome from "react-fontawesome";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  GetArrayQueryParams,
  SetHistoryQueryParam,
} from "../../../../Routers/historyHelper";
import { linkToCreateMember } from "../../../../Routers/links";
import { GetJSDataWithKey } from "../../../../utils/immutableConverter";
import { addAlertMessage } from "../../../Redux/actions/alertMessages";
import { fetchSiteList, fetchUserProfile } from "../../../Redux/actions/index";
import { havePermission } from "../../../Redux/reducers/permissions";
import SettingSection from "../../SettingSection";
import "./Members.css";
import MembersTable from "./MembersTable";

/* eslint-disable react/prop-types */

const lodash = { indexOf, intersectionWith, unionBy, map, isEqual };

const mapStateToProps = (state) => {
  return {
    sites: state.sites,
    profile: state.profiles,
    hasWritePermissions: havePermission(state.permissions)(
      "core:user.management",
      "write"
    ),
  };
};

const mapDispatchToProps = {
  fetchUserProfile,
  fetchSiteList,
  addAlertMessage,
};

export function FilterMemberList(members, inputs) {
  return members.filter((member) => {
    if (lodash.indexOf(inputs.roles, member.role) === -1) {
      return false;
    }

    if (lodash.indexOf(inputs.status, member.state) === -1) {
      return false;
    }

    if (member.sites && member.sites.length === 0) {
      return true;
    } else if (
      member.sites &&
      !lodash.intersectionWith(
        member.sites,
        inputs.sites,
        (object, otherValue) => {
          return object.id === otherValue.id;
        }
      ).length > 0
    ) {
      return false;
    }

    return true;
  });
}

export class Members extends PureComponent {
  static getDerivedStateFromProps(props, state) {
    const inputs = {
      roles: GetArrayQueryParams("roles", null, state.roles),
      status: GetArrayQueryParams("status", null, state.status),
      sites: GetArrayQueryParams("sites", "id", state.sites),
    };

    return {
      inputs: { ...inputs },
      filterMembers: FilterMemberList(props.profile.data.members, inputs),
    };
  }

  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      members: [],
      filterMembers: [],

      roles: [],
      status: [],
      sites: [],

      inputs: {
        roles: [],
        status: [],
        sites: [],
      },
    };

    this.setFilterList = this.setFilterList.bind(this);
    this.selectStatus = this.selectStatus.bind(this);
    this.selectSites = this.selectSites.bind(this);
    this.selectRoles = this.selectRoles.bind(this);
  }

  componentDidMount() {
    this.props.fetchUserProfile();
    this.props.fetchSiteList();

    this.setFilterList(this.props.profile.data.members, this.props.sites);
  }

  componentDidUpdate(prevProps) {
    if (
      !lodash.isEqual(prevProps.profile, this.props.profile) ||
      prevProps.sites !== this.props.sites
    ) {
      this.setFilterList(this.props.profile.data.members, this.props.sites);
    }
  }

  setFilterList(members, sites) {
    if (members) {
      const roles = lodash
        .unionBy(members, (member) => member.role)
        .map((member) => member.role);

      const status = lodash
        .unionBy(members, (member) => member.state)
        .map((member) => member.state);

      this.setState({
        roles: roles,
        status: status,
      });
    }

    if (sites) {
      this.setState({
        sites: GetJSDataWithKey("data", sites),
      });
    }
  }

  selectRoles(roles) {
    const inputs = this.state.inputs;
    inputs.roles = roles;

    SetHistoryQueryParam({ roles: roles });

    this.setState({
      filterMembers: FilterMemberList(this.props.profile.data.members, inputs),
    });
  }

  selectSites(sites) {
    const inputs = this.state.inputs;
    inputs.sites = sites;

    SetHistoryQueryParam({ sites: sites });

    this.setState({
      filterMembers: FilterMemberList(this.props.profile.data.members, inputs),
    });
  }

  selectStatus(status) {
    const inputs = this.state.inputs;
    inputs.status = status;

    SetHistoryQueryParam({ status: status });

    this.setState({
      filterMembers: FilterMemberList(this.props.profile.data.members, inputs),
    });
  }

  render() {
    const { height, hasWritePermissions } = this.props;
    const { filterMembers, inputs, roles, sites, status } = this.state;

    const filterRow = (
      <div className="members__filterRow">
        <MultiDropdownInputWithSearch
          label="Roles"
          value={inputs.roles}
          options={roles}
          onChange={this.selectRoles}
        />

        <MultiDropdownInputWithSearch
          label="Sites"
          labelKey="name"
          value={inputs.sites}
          options={sites}
          onChange={this.selectSites}
        />

        <MultiDropdownInputWithSearch
          label="Status"
          value={inputs.status}
          options={status}
          onChange={this.selectStatus}
        />
      </div>
    );

    return (
      <div
        className="members"
        style={{ height: height }}
      >
        {hasWritePermissions ? (
          <SettingSection title="Invite New User">
            <div className="members__createUserRow">
              <Link
                className="members__button"
                to={linkToCreateMember()}
              >
                <FontAwesome
                  name="user-plus"
                  className="icon"
                />{" "}
                Invite New User
              </Link>
            </div>
          </SettingSection>
        ) : null}

        <SettingSection title="Users">
          <MembersTable
            filterMembers={[...filterMembers]}
            filterRow={filterRow}
            height={height}
            writePermission={hasWritePermissions}
          />
        </SettingSection>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Members);
