import { RoutePath } from "#utils/route";
import { FlowEstimationMethodDetailRoute } from "#routes/organization/flows/[flowId]/detail/estimation-details/[estimationMethodId]";

export const FlowEstimationMethodResultDetailRoute =
  FlowEstimationMethodDetailRoute.concat(
    new RoutePath<"yearMonth">({
      path: "/result/:yearMonth",
      title: "Calculation Result",
    })
  );
