import React from "react";
import { Broadcast, Cylinder } from "phosphor-react";

import * as PropTypes from "prop-types";
import classNames from "classnames/bind";
import styles from "./Marker.module.scss";

const cx = classNames.bind(styles);

const iconSizeMap = {
  large: 30,
  small: 15,
};

const MarkerIcon = ({ iconType, size, colour }) => {
  const iconSize = iconSizeMap[size];
  const iconWeight = size === "small" ? "regular" : "light";
  const iconComponents = {
    broadcast: (
      <Broadcast
        size={iconSize}
        weight={iconWeight}
      />
    ),
    cylinder: (
      <Cylinder
        size={iconSize}
        weight={iconWeight}
      />
    ),
  };

  return (
    <div
      className={cx(colour, "marker", iconType ? "icon" : "noIcon", {
        [size]: !iconType,
      })}
    >
      {iconComponents[iconType]}
    </div>
  );
};

MarkerIcon.propTypes = {
  size: PropTypes.string,
  iconType: PropTypes.string,
  colour: PropTypes.string,
};

export default MarkerIcon;
