import { ExceptionUtils } from "#src/utils/exception";
import {
  apiDeleteServicesPromise,
  apiPostServicesPromise,
  apiPutServicesPromise,
  apiRequestServicesPromise,
} from "./ServiceHelper";

// TODO: address deprecation
/**
 * @deprecated communicates with the monolithic Elixir API which is currently
 * being deprecated. Also, the service pattern is being deprecated in favor of
 * the domain package adapters.
 */
const UserService = {
  getCurrentUser() {
    return apiRequestServicesPromise(`/api/users/@me`);
  },

  getCurrentUserPermissions() {
    return apiRequestServicesPromise(`/api/users/@permissions`);
  },

  getUsersCSVLink() {
    return apiRequestServicesPromise(`/api/users/csv_url`);
  },

  reportLogin() {
    return apiPostServicesPromise(`/api/users/login`, {
      device: "front_end",
    }).catch((err) => {
      ExceptionUtils.reportException(err, "error", {
        hint: "Failed to report login",
      });

      return Promise.reject(err);
    });
  },

  reportLogout() {
    return apiPostServicesPromise(`/api/users/logout`, {
      device: "front_end",
    }).catch((err) => {
      ExceptionUtils.reportException(err, "error", {
        hint: "Failed to report logout",
      });

      return Promise.reject(err);
    });
  },

  getActivity(startDate, endDate) {
    return apiRequestServicesPromise(`/api/users/activity`, {
      start_date: startDate,
      end_date: endDate,
    });
  },

  getActivityForUser(userId, startDate, endDate) {
    return apiRequestServicesPromise(`/api/users/${userId}/activity`, {
      start_date: startDate,
      end_date: endDate,
    });
  },

  requestUpdateProfile(inputs) {
    const restrictedCalibrationInstrumentsIds =
      inputs?.restricted_calibration_instruments.map(
        (instruments) => instruments.id
      );

    const siteIds = inputs.sites.map((site) => site.id);

    const formattedTimezone = inputs.timezone_preference?.split(" ")[1];

    const parameter = {
      alternative_email: inputs.email,
      name: inputs.name,
      phone_number: inputs.phone_number,
      job_title: inputs.job_title,
      username: inputs.email,
      role_id: inputs.role?.id,
      sites: siteIds,
      restrict_calibration_access:
        restrictedCalibrationInstrumentsIds.length > 0,
      restricted_calibration_instrument_ids:
        restrictedCalibrationInstrumentsIds,
      timezone_preference: formattedTimezone,
    };

    return apiPutServicesPromise(`/api/users/${inputs.id}`, parameter);
  },

  requestInviteNewMember(inputs) {
    const restrictedCalibrationInstrumentsIds =
      inputs?.restricted_calibration_instruments.map(
        (instruments) => instruments.id
      );

    const siteIds = inputs.sites.map((site) => site.id);

    const formattedTimezone = inputs.timezone_preference?.split(" ")[1];

    const parameter = {
      email: inputs.email,
      alternative_email: inputs.email,
      name: inputs.name,
      phone_number: inputs.phone_number,
      job_title: inputs.job_title,
      username: inputs.email,
      role_id: inputs.role?.id,
      sites: siteIds,
      restrict_calibration_access:
        restrictedCalibrationInstrumentsIds.length > 0,
      restricted_calibration_instrument_ids:
        restrictedCalibrationInstrumentsIds,
      timezone_preference: formattedTimezone,
    };

    return apiPostServicesPromise(`/api/users/invite`, parameter);
  },

  requestDisableUser(userId) {
    return apiPutServicesPromise(`/api/users/${userId}/disable`);
  },

  requestReactivateUser(userId) {
    return apiPutServicesPromise(`/api/users/${userId}/reactivate`);
  },

  getRoles() {
    return apiRequestServicesPromise(`/api/roles`);
  },

  requestUpdateRole(role) {
    const parameter = {
      name: role.name,
      permissions: role.permissions,
    };

    return apiPutServicesPromise(`/api/roles/${role.id}`, parameter);
  },

  requestCreateNewRole(role) {
    const parameter = {
      name: role.name,
      permissions: role.permissions,
    };

    return apiPostServicesPromise(`/api/roles`, parameter);
  },

  requestRemoveRole(roleId) {
    return apiDeleteServicesPromise(`/api/roles/${roleId}`);
  },

  requestChangePassword() {
    return apiPostServicesPromise(`/api/users/@me/password/reset`);
  },

  getApiTokens(userId) {
    return apiRequestServicesPromise(`/api/users/${userId}/api_tokens`);
  },

  createApiTokens(userId, form) {
    const param = {
      name: form?.name ?? null,
    };

    return apiPostServicesPromise(`/api/users/${userId}/api_tokens`, param);
  },

  deleteApiTokens(userId, apiTokenId) {
    return apiDeleteServicesPromise(
      `/api/users/${userId}/api_tokens/${apiTokenId}`
    );
  },

  getAppVersion() {
    return apiRequestServicesPromise("/api/version");
  },
};

export default UserService;
