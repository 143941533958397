import { SettingsRoutePath } from "#src/routes/settings";
import { RoutePath } from "#src/utils/route";

export const UsersRoutePath = SettingsRoutePath.concat(
  new RoutePath<string, "tab">({
    path: "/users",
    title: "Users",
  })
);

/** @deprecated */
export const USERS_LIST_BREADCRUMB = {
  title: "Users",
  path: () => "/app/settings/users",
};

/** @deprecated */
export const linkToUsersListPage = () => "/app/settings/users";
