import {
  Button,
  Page,
  Panel,
  Stepper,
  useForm,
} from "@validereinc/common-components";
import classNames from "classnames/bind";
import * as PropTypes from "prop-types";
import React, { useMemo, useState } from "react";

import AlertingService from "#components/Services/AlertingService";
import { getBreadcrumbsObject } from "#routers/breadcrumbsHelper";
import { useNavigate } from "#routers/hooks";
import {
  linkToAlertConfiguration,
  linkToAlertConfigurationDetail,
} from "#routers/links";

import DeliveryStep from "./DeliveryStep";
import DetailsStep from "./DetailsStep";

import styles from "./CreateAlertConfiguration.module.scss";

const STEPS = [{ label: "Details & Conditions" }, { label: "Delivery" }];

const cx = classNames.bind(styles);

const CreateAlertConfiguration = ({ breadcrumbs }) => {
  const [activeStep, setActiveStep] = useState(1);

  const breadcrumbsObject = getBreadcrumbsObject(breadcrumbs);

  const form = useForm({ defaultValues: { conditions: [{ duration: 0 }] } });

  const navigate = useNavigate();

  const isOnFinalStep = useMemo(
    () => activeStep === STEPS?.length,
    [activeStep]
  );

  const onCancel = () => navigate({ pathname: linkToAlertConfiguration() });

  const onNext = () => {
    form.handleSubmit(() => setActiveStep(activeStep + 1))();
  };

  const onPrevious = () => setActiveStep(activeStep - 1);

  const onSubmit = () => {
    form.handleSubmit(async (values) => {
      const {
        data: { id: configurationId },
      } = await AlertingService.createAlertConfiguration(values);

      navigate({ pathname: linkToAlertConfigurationDetail(configurationId) });
    })();
  };

  return (
    <Page
      title="Create Alert Configuration"
      breadcrumbs={breadcrumbsObject}
      actionRow={
        <Stepper
          steps={STEPS}
          activeStep={activeStep}
        />
      }
      footer={
        <div className={cx("footerActions")}>
          <div>
            <Button onClick={onCancel}>Cancel</Button>
          </div>

          {isOnFinalStep ? (
            <Button onClick={onPrevious}>Previous</Button>
          ) : null}

          <Button
            variant="primary"
            onClick={isOnFinalStep ? onSubmit : onNext}
          >
            {isOnFinalStep ? "Done" : "Next"}
          </Button>
        </div>
      }
    >
      <Panel title={STEPS[activeStep - 1].label}>
        {activeStep === 1 ? <DetailsStep form={form} /> : null}
        {activeStep === 2 ? <DeliveryStep form={form} /> : null}
      </Panel>
    </Page>
  );
};

CreateAlertConfiguration.propTypes = {
  breadcrumbs: PropTypes.array.isRequired,
};

export default CreateAlertConfiguration;
