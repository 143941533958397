import {
  FilterPillbox,
  MultiDropdownInputWithSearch,
} from "@validereinc/common-components";
import filter from "lodash/filter";
import uniqBy from "lodash/uniqBy";
import React, { useCallback, useEffect, useState } from "react";
import { GetQueryString } from "../../Routers/historyHelper";
import useQueryParams from "../../Routers/useQueryParams";
import { GetJSDataWithKey } from "../../utils/immutableConverter";
import { filterWorkflowTasks, getOptions } from "./WorkflowsHelpers";

const TEST_TYPES_OPTIONS = [
  { id: "field", name: "Field Lab" },
  { id: "third_party_lab", name: "3rd Party Lab" },
  { id: "manual", name: "Manual" },
  { id: "calibration", name: "Calibrations" },
];

const DEFAULT_STATUS_OPTIONS = [
  { id: "scheduled", name: "Scheduled" },
  { id: "pending", name: "Pending" },
  { id: "sampled", name: "Sampled" },
  { id: "completed", name: "Completed" },
  { id: "dismissed", name: "Dismissed" },
];

const ALL_STATUS_OPTIONS = [
  { id: "missed", name: "Missed" },
  ...DEFAULT_STATUS_OPTIONS,
];

/**
 * A common pattern throughout the app is to use url query params to store
 * filtering options, which is retrieved by `useQueryParams`. The results
 * are displayed in the `filterPillbox` component and changed via `filterRow`
 * inputs/dropdowns. The custom hook below is what brings all these together
 * and, in general, requires 3 things: the unfiltered list, the filter function,
 * and the DEFAULT_FILTER_DROPDOWN object whose properties are:
 *
 * - object properties: Each one corresponds to a query param and an input/dropdown
 * - label: The string used as a label for the given input/dropdown/pillbox
 * - input: An array means it can take on multiple values, otherwise only one
 * - options: An array of acceptable values the query strings can be matched to. If
 *     a query string value does not match any in this array, it will not be used
 * - filterKey: If the options value are objects, which property must the query
 *     string values match
 */
const DEFAULT_WORKFLOW_FILTER_DROPDOWN = {
  sites: {
    label: "Sites",
    inputs: [],
    options: [],
    labelKey: "name",
  },
  workflows: {
    label: "Workflows",
    inputs: [],
    options: [],
    labelKey: "name",
  },
  streams: {
    label: "Streams",
    inputs: [],
    options: [],
    labelKey: "name",
  },
  testTypes: {
    label: "Test Types",
    inputs: [],
    options: TEST_TYPES_OPTIONS,
    labelKey: "name",
  },
  status: {
    label: "Status",
    inputs: [],
    options: ALL_STATUS_OPTIONS,
    labelKey: "name",
  },
};

const queryParamsConverter = (filterDropdowns) => {
  // This function will convert filterDropdown into useQueryParams format
  const queryParamInput = {};
  const queryParamOptions = {};

  Object.keys(filterDropdowns).map((key) => {
    queryParamInput[key] = filterDropdowns[key].inputs;
    queryParamOptions[key] = filterDropdowns[key].options;
  });

  return [queryParamInput, queryParamOptions];
};

export const useWorkflowsFilter = (
  propsWorkflow,
  propsSites,
  haveEditPermission,
  workflowTasks
) => {
  const [name, setName] = useState("");

  const [filterDropdowns, setFilterDropdowns] = useState(
    DEFAULT_WORKFLOW_FILTER_DROPDOWN
  );

  const [queryParamInitialInputs, queryParamOptions] = useCallback(
    queryParamsConverter(filterDropdowns),
    [filterDropdowns]
  );

  const [queryParams, setQueryParams] = useQueryParams(
    queryParamInitialInputs,
    queryParamOptions
  );

  // Sets the default if they first visit page with no query params
  useEffect(() => {
    if (GetQueryString() === "?") {
      setQueryParams({ status: DEFAULT_STATUS_OPTIONS });
    }
  }, []);

  useEffect(() => {
    if (propsWorkflow.loadingState === "loaded") {
      const workflowTasks = GetJSDataWithKey(
        "workflowInstances",
        propsWorkflow
      );

      const workflows = GetJSDataWithKey("workflows", propsWorkflow);
      const sites = GetJSDataWithKey("data", propsSites);
      const streams = uniqBy(workflowTasks, "stream_id");

      const options = getOptions(workflows, sites, streams);

      setFilterDropdowns((filterDropdowns) => {
        return Object.entries(filterDropdowns).reduce(
          (filterObj, [key, dropdown]) => {
            filterObj[key] = options[key]
              ? { ...dropdown, options: options[key] }
              : (filterObj[key] = dropdown);

            return filterObj;
          },
          {}
        );
      });
    }
  }, [propsWorkflow, propsSites]);

  const handleSearch = useCallback((event) => {
    setName(event.target.value);
  }, []);

  const onDropdownSelect = useCallback((selectedValue, key) => {
    setQueryParams({ [key]: selectedValue });
  }, []);

  const onClearFilterClick = useCallback(
    (filterBy) => {
      if (filterBy) {
        const { filterKey, name } = filterBy;
        const inputs = Array.isArray(queryParams[filterKey])
          ? [...queryParams[filterKey]]
          : [queryParams[filterKey]];

        setQueryParams({
          [filterKey]: filter(inputs, (input) => {
            const value = input?.name ?? input;
            return value !== name;
          }),
        });
      } else {
        // queryParams has all the filters participating in filtering of the table
        // Create an object with these filters as properties to use setQueryParams
        const removedFilters = Object.keys(queryParams).reduce(
          (filterObj, filter) => ({ ...filterObj, [filter]: null }),
          {}
        );
        setQueryParams(removedFilters);

        setName("");
      }
    },
    [queryParams]
  );

  const filterRow = (
    <div className="datatable__filterRow">
      <div className="filterRow">
        <input
          className="inputbox-compress"
          placeholder={" Tasks"}
          value={name}
          onChange={handleSearch}
        />

        {Object.entries(filterDropdowns).map(([key, dropdown]) => {
          const { label, labelKey, options } = dropdown;

          return (
            <MultiDropdownInputWithSearch
              key={key}
              label={label}
              labelKey={labelKey ? labelKey : null}
              dropdownKey={key}
              value={queryParams[key]}
              options={options}
              onChange={onDropdownSelect}
            />
          );
        })}
      </div>
    </div>
  );

  const inputs = { name, ...queryParams };

  const filteredWorkflowTasks = useCallback(
    filterWorkflowTasks(inputs, workflowTasks, haveEditPermission),
    [inputs, workflowTasks, haveEditPermission]
  );

  const filterPillbox = (
    <FilterPillbox
      filterBy={queryParams}
      onClearFilterClick={onClearFilterClick}
      filteredListCount={filteredWorkflowTasks?.length}
      noFilterListCount={workflowTasks?.length}
    />
  );

  return [
    filterRow,
    filterPillbox,
    filteredWorkflowTasks,
    filterDropdowns,
    queryParams,
  ];
};
