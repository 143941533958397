import { Button, Modal, Title } from "@validereinc/common-components";
import classNames from "classnames/bind";
import * as PropTypes from "prop-types";
import React from "react";

import styles from "../CreateAlertConfiguration.module.scss";

const { Header, Body, Footer } = Modal;

const cx = classNames.bind(styles);

const RemoveRecipientModal = ({
  recipientEmailToRemove,
  onClose,
  onRemove,
}) => {
  const onRemoveRecipient = () => {
    onRemove();

    onClose();
  };

  return (
    <Modal
      open={!!recipientEmailToRemove}
      onClose={onClose}
      size="small"
    >
      <Header>
        <Title>Remove Recipient</Title>
      </Header>

      <Body>
        <p>
          Are you sure you want to remove{" "}
          <span className={cx("bold")}>{recipientEmailToRemove}</span> as a
          recipient?
        </p>
      </Body>

      <Footer>
        <div className={cx("modalActionsContainer")}>
          <Button onClick={onClose}>Cancel</Button>

          <Button
            onClick={onRemoveRecipient}
            variant="error"
          >
            Remove
          </Button>
        </div>
      </Footer>
    </Modal>
  );
};

RemoveRecipientModal.propTypes = {
  recipientEmailToRemove: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};

export default RemoveRecipientModal;
