import { useListUserGroups } from "#src/components/hooks/adapters/useUserGroups";
import { UserDetailContext } from "#src/routes/settings/users/detail/UserDetailContext";
import { SortDirection, UserGroupType } from "@validereinc/domain";
import React, { useContext } from "react";
import { SinglePropertyPillPanel } from "../SinglePropertyPillPanel";

const UserGroupsPillPanel = () => {
  const { userDetails } = useContext(UserDetailContext) ?? {};

  const userId = userDetails?.data?.id;
  const isUserIdDefined = !!userId;

  const groupsQuery = useListUserGroups(
    {
      sortBy: "name",
      sortDirection: SortDirection.ASCENDING,
      filters: { "user.id": [userId] },
    },
    { enabled: isUserIdDefined }
  );

  return (
    <SinglePropertyPillPanel
      values={
        groupsQuery.data?.data.map((group: UserGroupType) => group.name) ?? []
      }
      panelProps={{
        title: "Associated User Groups",
        loaded:
          isUserIdDefined && !userDetails?.isLoading && !groupsQuery.isLoading,
        isFluidY: false,
      }}
      emptyStateProps={{ title: "" }}
      pillProps={{ hasDot: false, variant: "default" }}
    />
  );
};

export default UserGroupsPillPanel;
