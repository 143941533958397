import find from "lodash/find";
import { ValidateCSVSafeInput } from "#utils/formValidator";
import {
  COMPONENT_QUALITIES,
  INERTS,
} from "../../Quality/RecordOfQualityConstants";

export const MEASUREMENT_OPTIONS = [...COMPONENT_QUALITIES, ...INERTS];

export function getErrorMessage(input, adjustmentFactors) {
  const { name, id, description, type, total_volume_factor, components } =
    input;

  if (!name) {
    return "Adjustment factor must have a name.";
  }

  const existingAdjustmentFactorName = find(
    adjustmentFactors,
    (existingFactor) => {
      return existingFactor.id !== id && existingFactor.name === name;
    }
  );
  if (existingAdjustmentFactorName) {
    return "Adjustment factor name must be unique.";
  }

  const checkNameCSVSafe = ValidateCSVSafeInput("Name", name);
  if (checkNameCSVSafe) {
    return checkNameCSVSafe;
  }

  if (!description) {
    return "Adjustment factor must have a descriptions.";
  }

  const checkDescriptionCSVSafe = ValidateCSVSafeInput(
    "Description",
    description
  );
  if (checkDescriptionCSVSafe) {
    return checkDescriptionCSVSafe;
  }

  if (type === "Total Volume") {
    if (!total_volume_factor || total_volume_factor < 0) {
      return `Total volume factor must be a positive value.`;
    }
  } else {
    if (!components?.length) {
      return "A component adjustment factor must have at least 1 component";
    }

    for (const component of components) {
      if (!input[component] || input[component] < 0) {
        return `${component} factor must be a positive value.`;
      }
    }
  }

  return null;
}

export function getAdjustmentFactorValue(value) {
  if (value === 0) {
    return "0";
  }

  return value ?? "";
}

export function getFormattedData(adjustmentFactor) {
  const { type, is_compound, component_volume_factors } = adjustmentFactor;

  const components = Object.keys(component_volume_factors).sort((a, b) => {
    return MEASUREMENT_OPTIONS.indexOf(a) - MEASUREMENT_OPTIONS.indexOf(b);
  });
  const componentValues = {};

  Object.entries(component_volume_factors).forEach(([key, value]) => {
    componentValues[key] = value;
  });

  return {
    ...adjustmentFactor,
    type: type === "component" ? "Component Volume" : "Total Volume",
    compound: is_compound ? "Yes" : "No",
    components,
    ...componentValues,
  };
}

export function getAdjustmentFactorParams(input) {
  const {
    name,
    id,
    compound,
    description,
    type,
    components,
    total_volume_factor,
  } = input;

  const commonParams = {
    name,
    id,
    is_compound: compound === "Yes",
    description,
  };

  let typeSpecificParams;
  if (type === "Total Volume") {
    typeSpecificParams = {
      type: "total",
      total_volume_factor: Number.parseFloat(total_volume_factor),
    };
  } else {
    const component_volume_factors =
      components?.reduce((volumeFactors, component) => {
        volumeFactors[component] = Number.parseFloat(input[component]);
        return volumeFactors;
      }, {}) ?? null;

    typeSpecificParams = {
      type: "component",
      component_volume_factors,
      total_volume_factor: null,
    };
  }

  return {
    ...commonParams,
    ...typeSpecificParams,
  };
}
