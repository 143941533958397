import React from "react";
import { getTimeStringFromDate } from "#utils/timeFormatter";
import config from "#src/config";
import * as PropTypes from "prop-types";
import { SAMPLE_TYPE } from "./RecordOfQualitySampleChart";

const CustomHintRow = ({ title, value }) => (
  <section className="rv-hint__row">
    <span className="rv-hint__title">{title}</span>
    <span className="rv-hint__value">{value}</span>
  </section>
);

const CustomHint = ({
  point,
  measurementKey,
  getFormattedMeasurementValueWithUnit,
}) => {
  const from = getTimeStringFromDate(point.from, config.DATE_FORMAT);
  const until = getTimeStringFromDate(point.until, config.DATE_FORMAT);
  const date = getTimeStringFromDate(point.date, config.DATE_FORMAT);
  const value = getFormattedMeasurementValueWithUnit(measurementKey, {
    value: point.value,
    unit: point.unit,
  });
  const type = SAMPLE_TYPE[point?.type];

  return (
    <div className="rv-hint__content">
      <section className="rv-hint__details">
        <h6 className="rv-hint__sectionTitle">Details</h6>

        {point.type === "inline" ? (
          <CustomHintRow
            title={"Date"}
            value={date}
          />
        ) : (
          <>
            <CustomHintRow
              title={"Start Date"}
              value={from}
            />
            <CustomHintRow
              title={"End Date"}
              value={until}
            />
          </>
        )}
        <CustomHintRow
          title={"Value"}
          value={value}
        />
        <CustomHintRow
          title={"Type"}
          value={type}
        />

        {point.metadata &&
          Object.entries(point.metadata).map(([key, valueString]) => {
            if (!valueString) {
              return;
            }

            if (key.includes("Temperature")) {
              valueString = valueString.replace(/deg. /i, "°");
            }

            const [value, unit] = valueString.split(" ");

            return (
              <CustomHintRow
                key={key}
                title={key}
                value={getFormattedMeasurementValueWithUnit(key, {
                  value,
                  unit,
                })}
              />
            );
          })}
      </section>

      {point.alarms?.length > 0 && (
        <section className="rv-hint__alarms">
          <h6 className="rv-hint__sectionTitle">Alarms</h6>

          <ul>
            {point.alarms.map((alarm, index) => (
              <li key={index}>{alarm}</li>
            ))}
          </ul>
        </section>
      )}
    </div>
  );
};
CustomHint.propTypes = {
  point: PropTypes.object,
  measurementKey: PropTypes.string,
  getFormattedMeasurementValueWithUnit: PropTypes.func,
};

CustomHintRow.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string,
};

export default CustomHint;
