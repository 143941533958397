import ManualEntryService from "#services/ManualEntryService";
import {
  Button,
  Form,
  FormButton,
  useAlert,
  useForm,
} from "@validereinc/common-components";
import moment from "moment";
import * as PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import {
  ACCUMULATIVE_COMPONENT_OPTIONS,
  COMPONENTS,
  COMPONENT_UNITS_OPTIONS,
  DENSITY_HEPTANES_PLUS,
  HEPTANES_PLUS,
  HEPTANES_PLUS_MOLECULAR_WEIGHT,
  HEXANES,
  HEXANES_PLUS,
  HEXANES_PLUS_MOLECULAR_WEIGHT,
  MAX_ACCUMULATION_PERCENT,
} from "./ComponentAnalysisConstant";
import { getComponentTotalAccumulation } from "./ComponentAnalysisHelper";
import InformationForm from "./InformationForm";
import PropertiesForm from "./PropertiesForm";

const TODAY = moment().startOf("day").toDate();

const DEFAULT_COMPONENT_VALUE = { value: "", unit: "%" };

const DEFAULT_VALUES = {
  accumulativeComponent: ACCUMULATIVE_COMPONENT_OPTIONS[0],
  unit: COMPONENT_UNITS_OPTIONS[0],
  sampleStartTime: TODAY,
  sampleEndTime: TODAY,
  samplePointId: null,
  testDate: TODAY,
  measurements: {},
};

const ComponentAnalysis = ({ sites, streams, sampleTypes, sourceLabs }) => {
  const [formState, setFormState] = useState("enabled");
  const { addAlert } = useAlert();

  const form = useForm({
    defaultValues: DEFAULT_VALUES,
  });

  const onMeasurementChange = (key, value, unit) => {
    const measurements = form.getValues("measurements");
    value = parseFloat(value) || 0;

    const measurementValue = { value, unit };

    measurements[key] = measurementValue;

    // This is accumulation calculation rule
    // if C6+ Selected: C7+ = 0, C6 = C6+
    // if C7+ Selected: C6+ = C6 + C7+
    if (key === HEXANES || key === HEXANES_PLUS || key === HEPTANES_PLUS) {
      if (form.getValues("accumulativeComponent") === HEXANES_PLUS) {
        measurements[HEPTANES_PLUS] = { ...DEFAULT_COMPONENT_VALUE };
        measurements[HEXANES_PLUS] = measurements[HEXANES];
      } else {
        measurements[HEXANES_PLUS] = { ...DEFAULT_COMPONENT_VALUE };

        if (measurements[HEXANES].value || measurements[HEPTANES_PLUS].value) {
          measurements[HEXANES_PLUS].value =
            measurements[HEXANES].value + measurements[HEPTANES_PLUS].value;
        }
      }
    }

    form.setValue("measurements", measurements);
  };

  const onStreamChange = (value) => {
    form.setValue("stream", [value]);
    form.setValue("samplePointId", "");
  };

  const submitSample = (inputs) => {
    setFormState("loading");

    // Remove any measurement without values
    Object.keys(inputs.measurements).forEach((key) => {
      if (
        inputs.measurements[key]?.value === undefined ||
        inputs.measurements[key]?.value === ""
      ) {
        delete inputs.measurements[key];
      }
    });

    ManualEntryService.addComponentAnalysisSample(inputs)
      .then(() => {
        setFormState("preview");

        addAlert({
          variant: "success",
          message: "A sample has been successfully created.",
        });
      })
      .catch(() => {
        setFormState("enabled");
      });
  };

  const onAddAnotherSampleClick = () => {
    form.reset({
      ...DEFAULT_VALUES,
    });

    setFormState("enabled");
  };

  useEffect(() => {
    if (form) {
      // Clear out C6, C6+, C7+ value when the user toggle accumulative components
      // to prevent invalid inputs
      const measurements = form.getValues("measurements");

      const newMeasurements = {
        ...measurements,
        [HEPTANES_PLUS]: { ...DEFAULT_COMPONENT_VALUE },
        [HEXANES_PLUS]: { ...DEFAULT_COMPONENT_VALUE },
        [HEXANES]: { ...DEFAULT_COMPONENT_VALUE },
        [HEPTANES_PLUS_MOLECULAR_WEIGHT]: { ...DEFAULT_COMPONENT_VALUE },
        [DENSITY_HEPTANES_PLUS]: { ...DEFAULT_COMPONENT_VALUE },
        [HEXANES_PLUS_MOLECULAR_WEIGHT]: { ...DEFAULT_COMPONENT_VALUE },
      };

      form.setValue("measurements", newMeasurements);
    }
  }, [form.getValues("accumulativeComponent")]);

  const totalAccumulation = getComponentTotalAccumulation(
    form.getValues("measurements")
  );

  const accumulationDifference = MAX_ACCUMULATION_PERCENT - totalAccumulation;

  form.watch(["accumulativeComponent", "measurements", "stream"]);

  return (
    <Form
      className="componentAnalysis"
      onSubmit={submitSample}
      {...form}
    >
      <InformationForm
        form={form}
        formState={formState}
        sites={sites}
        streams={streams}
        sampleTypes={sampleTypes}
        sourceLabs={sourceLabs}
        onMeasurementChange={onMeasurementChange}
        onStreamChange={onStreamChange}
      />

      <PropertiesForm
        form={form}
        formState={formState}
        components={COMPONENTS}
        onMeasurementChange={onMeasurementChange}
        totalAccumulation={totalAccumulation}
        accumulationDifference={accumulationDifference}
      />

      <div className="clearfix">
        {formState !== "preview" ? (
          <div className="actionRow">
            <FormButton
              variant="primary"
              className="pull-right"
              type="submit"
              isLoading={formState === "loading"}
              disabled={formState !== "enabled" || accumulationDifference !== 0}
            >
              Add Sample
            </FormButton>
          </div>
        ) : (
          <div className="actionRow">
            <Button
              variant="primary"
              className="pull-right"
              onClick={onAddAnotherSampleClick}
            >
              Add Another Sample
            </Button>
          </div>
        )}
      </div>
    </Form>
  );
};

ComponentAnalysis.propTypes = {
  sites: PropTypes.array.isRequired,
  streams: PropTypes.array.isRequired,
  sampleTypes: PropTypes.array.isRequired,
  sourceLabs: PropTypes.array.isRequired,
};

export default ComponentAnalysis;
