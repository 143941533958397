import DateRangeSelector from "#common/DateSelector/DateRangeSelector";
import { havePermission } from "#redux/reducers/permissions";
import BalancesServices from "#services/BalancesService";
import config from "#src/config";
import history from "#src/Routers/history";
import { linkToEditBalances } from "#src/Routers/links";
import useQueryParams from "#src/Routers/useQueryParams";
import { getTimeStringFromDate } from "#utils/timeFormatter";
import { Button, Page } from "@validereinc/common-components";
import moment from "moment";
import * as PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getBreadcrumbsObject } from "../../../../Routers/breadcrumbsHelper";
import "./BalancesAnalyze.scss";
import { SOURCES_TYPE } from "./BalancesAnalyzeConstant";
import BalanceSummary from "./BalanceSummary";
import SourceSummary from "./SourceSummary";

const TODAY = {
  from: moment().toDate(),
  until: moment().toDate(),
};

const DEFAULT_DATE_RANGE = {
  from: null,
  until: null,
};

const mapStateToProps = ({ permissions }) => {
  return {
    haveWritePermissions: havePermission(permissions)(
      "gas:accounting.balances",
      "write"
    ),
  };
};

const useManageDateRange = () => {
  const [queryParams, setQueryParams] = useQueryParams(DEFAULT_DATE_RANGE);

  const onDateRangeChange = (from, until) => {
    from = getTimeStringFromDate(from, config.DATE_FORMAT);
    until = getTimeStringFromDate(until, config.DATE_FORMAT);

    setQueryParams({ from, until });
  };

  const dateRange = queryParams.from ? queryParams : null;

  return [dateRange, onDateRangeChange];
};

const useFetchBalanceSummary = (balanceId, dateRange) => {
  const [balanceSummary, setBalanceSummary] = useState(null);
  const [loadingState, setLoadingState] = useState("loaded");

  useEffect(() => {
    if (balanceId && dateRange) {
      setLoadingState("loading");
      BalancesServices.getBalancesSummary(balanceId, dateRange).then(
        ({ data }) => {
          setBalanceSummary(data);
          setLoadingState("loaded");
        }
      );
    }
  }, [balanceId, dateRange]);

  return [balanceSummary, loadingState];
};

const useFetchBalanceDetail = (balanceId) => {
  const [balanceDetail, setBalanceDetail] = useState({});

  useEffect(() => {
    if (balanceId) {
      BalancesServices.getBalancesDetail(balanceId).then(({ data }) => {
        setBalanceDetail(data);
      });
    }
  }, [balanceId]);

  return [balanceDetail];
};

export const BalancesAnalyze = (props) => {
  const balanceId = props.match.params.id;

  const [dateRange, onDateRangeChange] = useManageDateRange();
  const [balanceSummary, loadingState] = useFetchBalanceSummary(
    balanceId,
    dateRange
  );
  const [balanceDetail] = useFetchBalanceDetail(balanceId);

  const onModifyClick = () => {
    history.push(linkToEditBalances(balanceId));
  };

  const haveReferenceSources =
    (!!balanceDetail[SOURCES_TYPE.references.streamSourceKey]?.length ||
      !!balanceDetail[SOURCES_TYPE.references.inventorySourceKey]?.length) ??
    false;

  const breadcrumbs = getBreadcrumbsObject(
    props.breadcrumbs,
    props.match.params
  );

  return (
    <Page
      isLoading={!balanceDetail}
      title={balanceDetail?.name}
      breadcrumbs={breadcrumbs}
    >
      <div className="balancesAnalyze__actions">
        <DateRangeSelector
          dateRange={
            dateRange ? { from: dateRange.from, to: dateRange.until } : TODAY
          }
          onDateRangeChange={onDateRangeChange}
          icon
          allowOneDayRange
        />
        {props.haveWritePermissions && (
          <Button
            variant="primary"
            className="pull-right"
            onClick={onModifyClick}
          >
            edit balance settings
          </Button>
        )}
      </div>

      <BalanceSummary
        balanceSummary={balanceSummary}
        balanceType={balanceDetail?.type}
        showTotalVolume={balanceDetail?.show_total_volume}
        showEnergy={balanceDetail?.show_energy}
        loadingState={loadingState}
        haveReferenceSources={haveReferenceSources}
      />

      {Object.keys(SOURCES_TYPE).map((source) => {
        const hasSourceData =
          (!!balanceDetail[SOURCES_TYPE[source].streamSourceKey]?.length ||
            !!balanceDetail[SOURCES_TYPE[source].inventorySourceKey]?.length) ??
          false;

        return (
          <SourceSummary
            key={source}
            sourceKey={source}
            balanceId={balanceId}
            balanceType={balanceDetail?.type}
            showTotalVolume={balanceDetail?.show_total_volume}
            showEnergy={balanceDetail?.show_energy}
            dateRange={dateRange}
            hasSourceData={hasSourceData}
            haveWritePermissions={props.haveWritePermissions}
          />
        );
      })}
    </Page>
  );
};

BalancesAnalyze.propTypes = {
  match: PropTypes.object.isRequired,
  haveWritePermissions: PropTypes.bool.isRequired,
  breadcrumbs: PropTypes.array.isRequired,
};

export default connect(mapStateToProps, undefined)(BalancesAnalyze);
