import { FormInputWrapper } from "@validereinc/common-components";
import * as PropTypes from "prop-types";
import React from "react";
import EquipmentTable from "./EquipmentTable";

const EquipmentModalForm = ({
  equipments,
  form,
  formState,
  filterRow,
  filterPillbox,
  noFilterListCount,
}) => {
  const onCheckboxClick = (equipments) => {
    form.setValue("equipments", equipments, {
      shouldValidate: true,
    });
  };

  return (
    <FormInputWrapper
      name="equipments"
      label="Equipments"
      validate={{
        exist: (equipments) => {
          return !equipments?.length ? "Equipments is required." : null;
        },
      }}
      as={({ inputRef }) => (
        // react hook form uses ${element}.focus() to scroll to input with error
        // <div> do not have this function unless tabIndex prop is given
        <div
          tabIndex={-1}
          ref={inputRef}
        >
          <EquipmentTable
            equipmentList={equipments}
            selectedValues={form.watch("equipments")}
            onCheckboxClick={onCheckboxClick}
            isDisabled={formState !== "enabled"}
            filterRow={filterRow}
            filterPillbox={filterPillbox}
            noFilterListCount={noFilterListCount}
          />
        </div>
      )}
    />
  );
};

EquipmentModalForm.propTypes = {
  equipments: PropTypes.array,
  form: PropTypes.object.isRequired,
  formState: PropTypes.string,
  filterRow: PropTypes.object.isRequired,
  filterPillbox: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  noFilterListCount: PropTypes.number,
};

export default EquipmentModalForm;
