import {
  apiRequestServicesPromise,
  apiPutServicesPromise,
} from "./ServiceHelper";
import config from "#src/config";
import { getTimeStringFromDate } from "#utils/timeFormatter";
import moment from "moment";

const formatProductionRecordDetailParams = (
  recordId,
  roqDetail,
  approveRecord
) => {
  const { selection_stream_id, selection_intervals } = roqDetail;

  return {
    record_id: recordId,
    selection_stream_id,
    selection_intervals,
    approve_record: approveRecord,
  };
};

const ProductionService = {
  getProductionReports(from, until) {
    return apiRequestServicesPromise("/api/balances", {
      from,
      until,
    });
  },

  getProductionReportDetail(id, from, until) {
    return apiRequestServicesPromise(`api/balances/${id}/analyze`, {
      from,
      until,
    });
  },
  getProductionReportInfo(id) {
    return apiRequestServicesPromise(`api/balances/${id}`);
  },

  updateProductionRecordDetail(recordId, roqDetail, approveRecord) {
    const updateParams = formatProductionRecordDetailParams(
      recordId,
      roqDetail,
      approveRecord
    );

    return apiPutServicesPromise(
      `/api/accounting_periods/records/${recordId}`,
      updateParams
    );
  },

  getProductionRecordDetail(recordId, from, to) {
    const param = {
      from: getTimeStringFromDate(from, config.DATE_FORMAT),
      until: getTimeStringFromDate(to, config.DATE_FORMAT),
    };

    return apiRequestServicesPromise(
      `/api/accounting_periods/records/${recordId}`,
      param
    );
  },

  getSingleMonthProductionRecordDetail(streamId, month) {
    const yearMonthFormat = moment(month).format("YYYY-MM");

    return apiRequestServicesPromise(
      `/api/accounting_records/stream/${streamId}/period/${yearMonthFormat}`
    );
  },

  getHistorical(recordId) {
    return apiRequestServicesPromise(
      `/api/accounting_periods/records/${recordId}/historicals`
    );
  },
};

export default ProductionService;
