import {
  MeniscusValues,
  TickValues,
  MEASUREMENT_TYPES,
} from "../Hooks/useMeasurementReducer";

export const isObjectType = (type) => (object) => {
  return object.type === type;
};

const isStartPoint = ([type, _points]) => type === "M";
const isControlPoint = ([type, _points]) => type === "Q";
const isEndPoint = ([type, _points]) => type === "L";

const parseStartPoint = ([_type, x, y]) => ({ x, y });
const parseControlPoint = ([_type, _from_x1, _from_y1, x, y]) => ({ x, y });
const parseEndPoint = ([_type, x, y]) => ({ x, y });

export const pathElementToPoint = (pathTuple) => {
  if (isStartPoint(pathTuple)) {
    const { x, y } = parseStartPoint(pathTuple);
    return { x, y };
  } else if (isControlPoint(pathTuple)) {
    const { x, y } = parseControlPoint(pathTuple);
    return { x, y };
  } else if (isEndPoint(pathTuple)) {
    const { x, y } = parseEndPoint(pathTuple);
    return { x, y };
  } else {
    console.warn("Unknown path element tuple, skipping parsing", pathTuple);
    return null;
  }
};

export const unscalePoint =
  (imageScale) =>
  ({ x, y }) => {
    return {
      x: x / imageScale,
      y: y / imageScale,
    };
  };

export const yMinimum = (points) => {
  return points.reduce((minima, { y }) => {
    return y < minima || minima === null ? y : minima;
  }, null);
};

export const yMaximum = (points) => {
  return points.reduce((maxima, { y }) => {
    return y > maxima || maxima === null ? y : maxima;
  }, null);
};

export const yAverage = (points) => {
  const sum = points.reduce((sum, { y }) => {
    return y + sum;
  }, 0);

  return points.length > 0 ? sum / points.length : null;
};

export const APPROXIMATION_METHODS = {
  AVERAGE: "average",
  MAXIMA: "maxima",
  MINIMA: "minima",
};

export const getApproximationMethodForMeasurementType = (measurementType) => {
  return measurementType === MEASUREMENT_TYPES.WATER_SEDIMENT
    ? APPROXIMATION_METHODS.MINIMA
    : APPROXIMATION_METHODS.AVERAGE;
};

export const getCriticalPoint = (points, approximationMethod) => {
  // The image coordinates start in the upper left at (0, 0) and end at (width, height) in the lower right
  // so finding the "maxima" of points in the image will be finding the lowest y-value points (closest to the
  // top of the image)
  switch (approximationMethod) {
    case APPROXIMATION_METHODS.MINIMA:
      return yMaximum(points);
    case APPROXIMATION_METHODS.AVERAGE:
      return yAverage(points);
    case APPROXIMATION_METHODS.MAXIMA:
      return yMinimum(points);
    default:
      throw new Error(`Unknown approximation method: '${approximationMethod}'`);
  }
};

export const findMeniscusTrace = (value) => {
  const path = value.objects.find(isObjectType("path"));
  if (!path) {
    throw new Error("Could not find a suitable path in 'value'");
  }

  return path;
};

export const getTraceVertices = (path, imageScale) => {
  return path.path
    .map(pathElementToPoint)
    .filter((e) => e !== null)
    .map(unscalePoint(imageScale));
};

export const findTicksBox = (value) => {
  const box = value.objects.find(isObjectType("rect"));
  if (!box) {
    throw new Error("Could not find a suitable box in 'value'");
  }

  return box;
};

export const getLineCoordinates = (line, imageScale) => {
  return {
    x0: line.left / imageScale,
    y0: line.top / imageScale,
    x1: (line.left + line.width) / imageScale,
    y1: (line.top + line.height) / imageScale,
  };
};

export const getBoxCoordinates = (box, imageScale) => {
  return {
    upperLeftX: box.left / imageScale,
    upperLeftY: box.top / imageScale,
    bottomRightX: (box.left + box.width) / imageScale,
    bottomRightY: (box.top + box.height) / imageScale,
    width: box.width / imageScale,
    height: box.height / imageScale,
  };
};

export const calculateMeasurementValue = (
  criticalPoint,
  boxCoordinates,
  lowerTickValue,
  upperTickValue
) => {
  // Note: this is using a naive algorithm that presumes the tube is a cylinder

  // Note: the image's top left coordinate is (0,0), bottom right is (width, height)
  // These coordinates are in image-space, not sketch-space

  // y-coordinate of the lower edge of the box, and its height
  const { bottomRightY: boxYLower, height: boxHeight } = boxCoordinates;

  // distance from the line to the bottom edge of the box
  const lineToBoxLowerDistance = boxYLower - criticalPoint;

  // ratio of the line -> lower box edge distance to the box's height
  const lineToBoxRatio = lineToBoxLowerDistance / boxHeight;

  const tickDifference = upperTickValue - lowerTickValue;
  // distance between the line and the box's lower edge, expressed in tick-space instead of sketch-space
  const lineToTickDistance = lineToBoxRatio * tickDifference;

  // the final measurement value of the tube
  const measurementValue = lowerTickValue + lineToTickDistance;
  return measurementValue;
};

export const getMeniscusValue = (vertices, criticalPoint, measurementValue) => {
  return new MeniscusValues({
    value: measurementValue,
    criticalPoint,
    vertices,
  });
};

export const getUpperTickValue = (boxCoordinates, upperTickValue) => {
  const { upperLeftX, upperLeftY, width } = boxCoordinates;

  return new TickValues({
    value: upperTickValue,
    vertices: [
      { x: upperLeftX, y: upperLeftY },
      { x: upperLeftX + width, y: upperLeftY },
    ],
  });
};

export const getLowerTickValue = (boxCoordinates, lowerTickValue) => {
  const { bottomRightX, bottomRightY, width } = boxCoordinates;

  return new TickValues({
    value: lowerTickValue,
    vertices: [
      { x: bottomRightX - width, y: bottomRightY },
      { x: bottomRightX, y: bottomRightY },
    ],
  });
};

export const calculateAnnotation = (
  value,
  imageScale,
  approximationMethod,
  lowerTickValue,
  upperTickValue
) => {
  const meniscusObject = findMeniscusTrace(value);
  const points = getTraceVertices(meniscusObject, imageScale);

  const criticalPoint = getCriticalPoint(points, approximationMethod);

  const ticksBox = findTicksBox(value);
  const boxCoordinates = getBoxCoordinates(ticksBox, imageScale);

  const measurementValue = calculateMeasurementValue(
    criticalPoint,
    boxCoordinates,
    lowerTickValue,
    upperTickValue
  );

  return {
    meniscus: getMeniscusValue(points, criticalPoint, measurementValue),
    lowerTick: getLowerTickValue(boxCoordinates, lowerTickValue),
    upperTick: getUpperTickValue(boxCoordinates, upperTickValue),
  };
};
