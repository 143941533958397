import moment from "moment";
import "moment-timezone";

// TODO: clean out this file eventually once usages in legacy parts of the codebase are dropped
/** @deprecated - this is an out of date format. use native Intl API instead through TimezoneDropdownOptions in constants/dropdown.ts */
export const getAvailableTimezone = () => {
  const timezones = moment.tz.names();
  const availableTimezones = {};

  timezones.map((timezone) => {
    const name = formatTimezoneName(timezone);
    availableTimezones[name] = timezone;
  });
  return availableTimezones;
};

/** @deprecated */
export const formatTimezoneName = (timezone) => {
  const name = `(GMT${moment.tz(timezone).format("Z")}) ${timezone}`;
  return name;
};
