import { CustomReportConfigurationPanel } from "#batteries-included-components/Layouts/CustomReport/CustomReportConfigurationPanel/CustomReportConfigurationPanel";
import { CustomReportVariantType } from "#batteries-included-components/Layouts/CustomReport/CustomReportDetailLayout.helpers";
import { CustomReportDetailFilterPanel } from "#batteries-included-components/Panels/FilterPanels/CustomReportDetailFilterPanel";
import { CustomReportSummaryPanel } from "#batteries-included-components/Panels/SummaryPanels/CustomReportSummaryPanel";
import { CustomReportTablePanel } from "#batteries-included-components/Panels/TablePanels/CustomReportTablePanel/CustomReportTablePanel";
import { StorageKeys, useFilters } from "@validereinc/common-components";
import classNames from "classnames/bind";
import React from "react";
import styles from "./CustomReportDetailLayout.module.scss";

const cx = classNames.bind(styles);

export const CustomReportDetailLayout = ({
  configStorageKey,
  reportVariant,
  filterConfigStorageKey,
  tableConfigStorageKey,
}: {
  configStorageKey: string;
  reportVariant: CustomReportVariantType;
} & StorageKeys) => {
  const [_, setFilters] = useFilters(filterConfigStorageKey);

  return (
    <>
      <CustomReportDetailFilterPanel
        variant={reportVariant}
        filterConfigStorageKey={filterConfigStorageKey}
      />
      <div className={cx("layoutContainer")}>
        <div className={cx("configurationContainer")}>
          <CustomReportConfigurationPanel
            variant={reportVariant}
            configStorageKey={configStorageKey}
            onClear={() => {
              setFilters({});
            }}
          />
        </div>
        <div className={cx("dataContainer")}>
          <CustomReportSummaryPanel
            variant={reportVariant}
            configStorageKey={configStorageKey}
            filterConfigStorageKey={filterConfigStorageKey}
          />
          <CustomReportTablePanel
            variant={reportVariant}
            configStorageKey={configStorageKey}
            filterConfigStorageKey={filterConfigStorageKey}
            tableConfigStorageKey={tableConfigStorageKey}
          />
        </div>
      </div>
    </>
  );
};
