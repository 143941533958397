/**
 * A unique-item Queue. Can store arbitrary data as long as each item can be uniquely identifiable in some way.
 * All methods are non-mutating, unless specified otherwise.
 */
export class QueueUnique<T> {
  store = new Set<QueueUniqueEntry<T>>();
  idGetter: (data: T) => string;

  constructor(values: T[], idGetter: (data: T) => string) {
    this.store = new Set(
      values.map((d) => ({
        id: idGetter(d),
        payload: d,
      }))
    );
    this.idGetter = idGetter;
  }

  /**
   * Transform a value to a QueueEntry - a special representation for items in a queue
   * @param value the value to transform
   * @returns a QueueEntry
   */
  transformValueToQueueEntry(value: T): QueueUniqueEntry<T> {
    return {
      id: this.idGetter(value),
      payload: value,
    };
  }

  /**
   * Transform a QueueEntry back to the original value it represents
   * @param value the QueueEntry to transform
   * @returns the value within its payload
   */
  transformQueueEntryToValue(value?: QueueUniqueEntry<T>): T | undefined {
    return value?.payload;
  }

  /**
   * Add a value to the queue. If the item already exists in the queue, nothing will be added. Mutating.
   * @param value the value to add
   * @returns the updated queue with the value transformed to a QueueEntry and added
   */
  add(value: T) {
    this.store.add(this.transformValueToQueueEntry(value));

    return this;
  }

  /**
   * Add a value to the queue. If the item already exists in the queue, nothing will be added. Non-mutating.
   * @param value the value to add
   * @returns the updated queue with the value transformed to a QueueEntry and added
   */
  insert(value: T) {
    const newQueue = this.clone();

    newQueue.store.add(this.transformValueToQueueEntry(value));

    return newQueue;
  }

  /**
   * Remove an item from the queue. Mutating.
   * @param value the value to remove - lookup is performed against id using the idGetter from the constructor
   * @returns the updated queue with the value removed, if it did exist in the queue
   */
  remove(value: T) {
    const lookupId = this.idGetter(value);

    return this.removeById(lookupId);
  }

  /**
   * Remove an item from the queue by its ID. Mutating.
   * @param id the ID of the value to remove
   * @returns the updated queue with the value removed, if it did exist in the queue
   */
  removeById(id: string) {
    for (const entry of this.get()) {
      if (entry.id === id) {
        this.store.delete(entry);
      }
    }

    return this;
  }

  /**
   * Remove the first item from the queue. Mutating.
   * @returns the updated queue after removing
   */
  removeFirst() {
    const newStore = Array.from(this.store.values());

    newStore.shift();
    this.store = new Set(newStore);

    return this;
  }

  /**
   * Remove the last item from the queue. Mutating.
   * @returns the updated queue after removing
   */
  removeLast() {
    const newStore = Array.from(this.store.values());

    newStore.pop();
    this.store = new Set(newStore);

    return this;
  }

  /**
   * Cut an item from the queue. Non-mutating.
   * @param value the value to cut - lookup is performed against id using the idGetter from the constructor
   * @returns the updated queue with the value cut, if it did exist in the queue
   */
  cut(value: T) {
    return this.clone().remove(value);
  }

  /**
   * Cut an item from the queue by its ID. Non-mutating.
   * @param id the ID of the value to cut
   * @returns the updated queue with the value cut, if it did exist in the queue
   */
  cutById(id: string) {
    return this.clone().removeById(id);
  }

  /**
   * Cut the first item from the queue. Non-mutating.
   * @returns the updated queue after cutting
   */
  cutFirst() {
    return this.clone().removeFirst();
  }

  /**
   * Cut the last item from the queue. Non-mutating.
   * @returns the updated queue after cutting
   */
  cutLast() {
    return this.clone().removeLast();
  }

  /**
   * Get the first item in the queue. Non-mutating.
   * @returns the first queue entry or undefined if queue is empty
   */
  getAtPosition(position: number) {
    if (position < 1 || position > this.size()) {
      return;
    }

    return this.getAsArray()[position - 1];
  }

  /**
   * Get the first item in the queue. Non-mutating.
   * @returns the first queue entry or undefined if queue is empty
   */
  getFirst() {
    return this.getAtPosition(1);
  }

  /**
   * Get the last item in the queue. Non-mutating.
   * @returns the first queue entry or undefined if queue is empty
   */
  getLast() {
    return this.getAtPosition(this.size());
  }

  /**
   * Get the entire queue
   * @returns the iterable version of the queue
   */
  get() {
    return this.store.values();
  }

  /**
   * Get the entire queue
   * @returns the array version of the queue
   */
  getAsArray() {
    return Array.from(this.store.values()).map((d) => d.payload);
  }

  /**
   * Set the entire queue. Mutating.
   * @returns the iterable version of the queue
   */
  set(queueValues: T[]) {
    this.store = new Set(
      queueValues.map((d) => ({
        id: this.idGetter(d),
        payload: d,
      }))
    );

    return this;
  }

  /**
   * Clear the queue. Mutating.
   */
  clear() {
    this.store = new Set();
  }

  /**
   * Find an item in the queue
   * @param value the value of the item to find
   * @returns the item if found or undefined otherwise
   */
  find(value: T) {
    const lookup = this.transformValueToQueueEntry(value);

    if (this.store.has(lookup)) {
      return this.findById(lookup.id);
    }

    return;
  }

  /**
   * Find an item in the queue
   * @param id the id to find an item by
   * @returns the item if found or undefined otherwise
   */
  findById(id: QueueUniqueEntry<T>["id"]) {
    for (const entry of this.get()) {
      if (entry.id === id) {
        return this.transformQueueEntryToValue(entry);
      }
    }

    return;
  }

  /**
   * What's the size of the queue? How many items are in it?
   * @returns a number representing the # of items in the queue
   */
  size() {
    return this.store.size;
  }

  /**
   * Creates a new instance of the queue, with the same queue items and other properties
   * @returns a new instance of the same queue
   */
  clone() {
    return new QueueUnique(this.getAsArray(), this.idGetter);
  }

  /**
   * String representation of this queue
   * @returns the queue as a array turned into a string
   */
  toString() {
    return JSON.stringify(this.getAsArray());
  }

  /**
   * JSON representation of this queue
   * @returns the queue as an array
   */
  toJSON() {
    return this.getAsArray();
  }
}

export type QueueUniqueEntry<T> = {
  id: string;
  payload: T;
};
