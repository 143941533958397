import React, { useContext } from "react";

import { DataTable, HeaderType } from "@validereinc/common-components";

import { EquipmentType, EventsDomain } from "@validereinc/domain";

import useLocalization from "#src/hooks/useLocalization";

import { linkToEquipmentDetail, linkToFacilities } from "#src/Routers/links";

import { EventContext } from "#src/contexts/EventContext";

import { toStartCaseString } from "@validereinc/utilities";
import { RoutingLink } from "../RoutingLink";

export const EventEquipmentTable = () => {
  const { event, isLoading, onRefetchEvent } = useContext(EventContext) ?? {};
  const { localize } = useLocalization();

  const headers: Array<HeaderType<EquipmentType>> = [
    {
      label: `${localize("Equipment")} Name`,
      key: "name",
      renderComponent: ({ item }) => (
        <RoutingLink to={linkToEquipmentDetail(item.id)}>
          {item.name}
        </RoutingLink>
      ),
    },
    {
      label: `${localize("Equipment")} Type`,
      key: "type_id",
      renderComponent: ({ item }) => item?.type?.name ?? "-",
    },
    {
      label: `${localize("Facility")} Name`,
      key: "facility_name",
      renderComponent: ({ item }) => (
        <RoutingLink to={linkToFacilities(item.facility_id)}>
          {item?.facility?.name ?? "-"}
        </RoutingLink>
      ),
    },
    {
      key: "status",
      label: "Status",
      renderComponent: ({ item }) => (
        <DataTable.DataRow.PillCell
          variant={item.status === "active" ? "success" : "error"}
          value={toStartCaseString(item.status)}
        />
      ),
    },
    {
      label: "Effective Date",
      key: "effective_date",
      renderComponent: ({ item }) => (
        <DataTable.DataRow.DateCell
          value={item.effective_date}
          withTime
        />
      ),
    },
  ];

  const onRemove = async ({ equipmentId }: { equipmentId: string }) => {
    if (!event?.id) return;

    await EventsDomain.equipment.removeOne({ equipmentId, eventId: event?.id });

    onRefetchEvent?.();
  };

  return (
    <DataTable
      headers={headers}
      items={event?.equipment?.data ?? []}
      isLoading={isLoading}
      getItemActions={({ item: { id } }) => [
        {
          label: "Remove",
          buttonProps: {
            onClick: () => onRemove({ equipmentId: id }),
            icon: "trash",
          },
        },
      ]}
    />
  );
};
