export const getRequiredMeasurementsForInstrument = (instrument) => {
  switch (instrument?.type) {
    case "anton paar density":
    case "anton paar dma 35 density": {
      return ["Density"];
    }
    case "anton paar svm 3000":
    case "anton paar svm 3001": {
      return ["Kinematic Viscosity"];
    }
    case "eralytics vapor": {
      return ["Vapour Pressure"];
    }
    case "horiba xray fluorescence": {
      return ["Sulphur"];
    }
    case "rigaku xray fluorescence": {
      return ["Sulphur"];
    }
    case "rudolph research density": {
      return ["Density"];
    }
    case "water": {
      return ["Water"];
    }
    case "validere vapor": {
      return ["Vapour Pressure"];
    }
    case "aquamax kf": {
      return ["Water"];
    }
    case "mettler toledo t5": {
      return ["TAN"];
    }
    case "mettler toledo v20s": {
      return ["Water"];
    }
    case "isl vida density meter": {
      return ["Density"];
    }
    default: {
      return [];
    }
  }
};
