import { Button as CommonButton } from "@validereinc/common-components";
import moment from "moment";
import * as PropTypes from "prop-types";
import React from "react";
import { Button, ButtonGroup, Checkbox } from "react-bootstrap";
import FontAwesome from "react-fontawesome";
import DateRangeSelector from "../../Common/DateSelector/DateRangeSelector";
import "./NavigationBar.scss";

const NavigationBar = React.memo((props) => {
  const navigationButtonClick = (direction, view) => {
    const increment = view === "monthly" ? "month" : "day";

    let from = moment(props.from);
    let to = moment(props.to);

    if (direction === "back") {
      from = from.subtract(1, increment);
      to = to.subtract(1, increment);
    } else {
      from = from.add(1, increment);
      to = to.add(1, increment);
    }

    props.onDateRangeChange(from, to);
  };

  return (
    <div className="workflowNavigationBar">
      <div className="workflowNavigationBar__dateRange">
        <DateRangeSelector
          dateRange={{ from: props.from, to: props.to }}
          onDateRangeChange={props.onDateRangeChange}
          icon
          hoverRange={props.view === "monthly" ? "monthly" : null}
          disableRelativeDays={props.view === "monthly"}
        />

        <div className="workflowNavigationBar__controls">
          <CommonButton
            onClick={() => navigationButtonClick("back", props.view)}
          >
            <FontAwesome name="caret-left" />
          </CommonButton>

          <CommonButton
            onClick={() => navigationButtonClick("forward", props.view)}
          >
            <FontAwesome name="caret-right" />
          </CommonButton>
        </div>
      </div>

      <div className="workflowNavigationBar__toggle">
        {props.haveEditPermission && props.view === "monthly" && (
          <Checkbox
            className="workflowNavigationBar__checkbox"
            checked={props.groupStreamsByWorkflow}
            onChange={props.onGroupByWorkflowToggle}
            inline
          >
            Group By Workflow
          </Checkbox>
        )}

        <ButtonGroup>
          <Button
            onClick={props.onToggleView}
            className={props.view === "monthly" ? "active" : null}
          >
            Monthly
          </Button>
          <Button
            onClick={props.onToggleView}
            className={props.view === "schedule" ? "active" : null}
          >
            Schedule
          </Button>
        </ButtonGroup>

        <CommonButton
          className="workflowNavigationBar__csvExport"
          onClick={props.onCSVDownload}
          style={{ textTransform: "uppercase" }}
        >
          csv
        </CommonButton>
        <CommonButton
          className="workflowNavigationBar__pdfExport"
          onClick={props.onPDFDownload}
          style={{ textTransform: "uppercase" }}
        >
          pdf
        </CommonButton>
      </div>
    </div>
  );
});

NavigationBar.displayName = "NavigationBar";

NavigationBar.propTypes = {
  from: PropTypes.string,
  to: PropTypes.string,
  onDateRangeChange: PropTypes.func,
  view: PropTypes.string,
  onGroupByWorkflowToggle: PropTypes.func,
  onToggleView: PropTypes.func,
  groupStreamsByWorkflow: PropTypes.bool,
  haveEditPermission: PropTypes.bool,
  onCSVDownload: PropTypes.func,
  onPDFDownload: PropTypes.func,
};

export default NavigationBar;
