import { useListCustomAttributes } from "#src/components/hooks/adapters/useCustomAttributes";
import { useParams } from "#src/Routers/hooks";
import { useQuery } from "@tanstack/react-query";
import {
  AssetType,
  FacilityDomain,
  type CustomAttributeType,
  type FacilityType,
} from "@validereinc/domain";
import React, { ReactNode, createContext } from "react";

export const FacilityContext = createContext<{
  facility?: FacilityType;
  isLoading: boolean;
  customAttributes?: CustomAttributeType[];
} | null>(null);

export const FacilityProvider = ({ children }: { children: ReactNode }) => {
  const { facilityId } = useParams<{ facilityId: string }>();

  const facilityQuery = useQuery({
    queryKey: ["facilities", facilityId],
    queryFn: () => FacilityDomain.getOne({ id: facilityId }),
    enabled: Boolean(facilityId),
    select: (resp) => resp?.data,
  });

  const customAttributeQuery = useListCustomAttributes({
    filters: { entity_type: AssetType.FACILITY },
  });
  const customAttributes = customAttributeQuery?.data?.data ?? [];

  const isLoading =
    (!!facilityId && facilityQuery.isLoading) || customAttributeQuery.isLoading;

  return (
    <FacilityContext.Provider
      value={{
        facility: facilityQuery.data,
        isLoading,
        customAttributes,
      }}
    >
      {children}
    </FacilityContext.Provider>
  );
};
