import { useParams } from "#routers/hooks";
import { useMeasurementTypes } from "#src/contexts/MeasurementTypeContext";
import {
  Column,
  DropdownInput,
  Panel,
  Row,
  TextInput,
} from "@validereinc/common-components";
import React from "react";

export const ManualEntryConfiguration = () => {
  const { measurementTypes, getUnitsByQuantity } = useMeasurementTypes();
  const params = useParams<{ measurementType: string }>();

  const measurementType = measurementTypes?.find(
    ({ id }) => id === params.measurementType
  );
  const units = getUnitsByQuantity(measurementType?.quantity);

  return (
    <Row>
      <Column>
        <Panel title="Manual Entry">
          <Row>
            <Column>
              <TextInput
                label={measurementType?.name ?? "Value"}
                name="value"
                type="number"
                dataType="number"
                placeholder="Set value..."
                key="value"
                isRequired
                isInline
                style={{ width: "244px" }}
              />
            </Column>
          </Row>
          <Row>
            <Column>
              <DropdownInput
                label="Unit"
                name="measurement_unit"
                options={units}
                valueKey="id"
                labelKey="name.plural"
                isRequired
                isInline
                isClearable={false}
              />
            </Column>
          </Row>
        </Panel>
      </Column>
    </Row>
  );
};
