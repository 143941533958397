import { useListCustomAttributes } from "#src/components/hooks/adapters/useCustomAttributes";
import { AssetType } from "@validereinc/domain";

const STATIC_FLOW_FILTERS = [
  {
    display_name: "Name",
    field_name: "name",
    data_type: "string",
  },
  {
    display_name: "Type",
    field_name: "type",
    data_type: "lookup",
    lookup_type: "flowType",
  },
  {
    display_name: "Product Category",
    field_name: "product_category",
    data_type: "lookup",
    lookup_type: "productCategory",
  },
  {
    display_name: "Product Type",
    field_name: "product_type",
    data_type: "lookup",
    lookup_type: "productType",
  },

  {
    display_name: "Facility",
    field_name: "associated_facility_id",
    data_type: "lookup",
    lookup_type: "facility",
  },
  {
    display_name: "Equipment",
    field_name: "associated_equipment_id",
    data_type: "lookup",
    lookup_type: "equipment",
  },
];

export const useFlowFilters = () => {
  const { data, isLoading } = useListCustomAttributes({
    filters: { entity_type: AssetType.FLOW },
  });
  return isLoading
    ? []
    : [
        ...STATIC_FLOW_FILTERS,
        ...(data?.data.map(({ field_name, ...restOption }) => ({
          ...restOption,
          field_name: `custom_attributes.${field_name}`,
        })) ?? []),
      ];
};
