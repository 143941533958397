import { getAssetDropdownInput } from "#src/batteries-included-components/Dropdowns";
import { AssetType, AssetTypeType } from "@validereinc/domain";
import { DropdownInput } from "@validereinc/common-components";
import { useCustomAttributeFilters } from "#src/components/hooks/FilterPanel/useCustomAttributeFilters";
import { convertMapToOpts } from "#src/constants";
import useLocalization from "#src/hooks/useLocalization";
import React from "react";
import startCase from "lodash/startCase";
import { BaseEntityDropdownProps } from "#src/batteries-included-components/Dropdowns/EntityDropdownInput";

export const AssetTypeSelection = {
  ...AssetType,
  ...({ ALL_ASSETS: "all_assets" } as const),
} as const;
export type AssetTypeSelectionType =
  (typeof AssetTypeSelection)[keyof typeof AssetTypeSelection];

type NameValue = { name: string };
type AssetNameMap = { [k in AssetTypeType]: NameValue };

export const useAssetDropdown = (
  assetType: AssetTypeSelectionType,
  nameMap: AssetNameMap
) => {
  const { localize } = useLocalization();
  const commonProps = {
    name:
      assetType !== AssetTypeSelection.ALL_ASSETS
        ? nameMap[assetType].name
        : "",
    isInline: true,
    isLabelShown: false,
    isFluid: false,
    placeholder: `Select ${localize(assetType)}...`,
  };
  if (assetType === AssetTypeSelection.ALL_ASSETS) return <></>;
  return getAssetDropdownInput(
    assetType,
    commonProps as unknown as BaseEntityDropdownProps
  );
};

export const AssetTypeOptions = [
  ...convertMapToOpts(AssetTypeSelection, ([_, value]) => {
    if (value === AssetTypeSelection.ASSET_GROUP) {
      return "Network";
    } else {
      return startCase(value);
    }
  }),
];

export const useCompositeAssetFilters = (
  assetType: AssetTypeSelectionType,
  nameMap: AssetNameMap & { assetType: NameValue }
) => {
  const assetDropdown = useAssetDropdown(assetType, nameMap);
  return [
    {
      component: (
        <DropdownInput
          name={nameMap.assetType.name}
          isClearable={false}
          labelKey="label"
          valueKey="value"
          placeholder={"Select Asset Type..."}
          options={AssetTypeOptions}
          isSortedAlphabetically={false}
          isInline
          description="Filter by Asset Type"
        />
      ),
    },
    { component: assetDropdown },
  ];
};

export const useAssetCustomAttributeFilters = (
  assetType: AssetTypeSelectionType
) => {
  const { localize } = useLocalization();
  const filters = useCustomAttributeFilters({
    assetType:
      assetType !== AssetTypeSelection.ALL_ASSETS
        ? assetType
        : AssetTypeSelection.FACILITY,
    prefix: `workflow.${assetType}.custom_attributes`,
    section: localize(assetType),
  });
  if (assetType === AssetTypeSelection.ALL_ASSETS) {
    return {
      customAttributes: [],
      customAttributeHeaders: [],
      customAttributeFilters: [],
      isLoading: false,
    };
  }
  return filters;
};
