import { restAPI } from ".";
import { AssetTypeType } from "../schemas";
import { PaginationAndSortParamsType, camelToSnakeKeys } from "../util";

export type FormResponseDataItemType = {
  id: string;
  form_category: { id: string; name: string };
  form_schema: { id: string; name: string };
  form_submission_id: string;
  measure_value: number;
  time: string;
};
type FormResponseType = {
  data: FormResponseDataItemType[];
};

export type SeriesResponseDataItemType = {
  measure_value: number;
  time: string;
};
export type SeriesDataType = {
  data: SeriesResponseDataItemType[];
  description: string;
  measurement_series_id: string;
};
type SeriesResponseType = SeriesDataType[] | null;
type GetMeasurementPlotResponseType = {
  form: FormResponseType;
  series: SeriesResponseType;
};

type GetMeasurementPlotByMeasurementParamsType = {
  measurementType: string;
  deviceId?: string;
  subjectId?: string;
  subjectType?: string;
  includeForms?: boolean;
  measurementSeriesIds?: string[];
  start?: string;
  end?: string;
  interval?: "1minute" | "15minute" | "1hour" | "1day";
  func?: "avg" | "sum";
};

type GetMeasurementTypesBySubjectType = {
  subjectId: string;
  subjectType: AssetTypeType;
};

/** @deprecated */
export const MeasurementDataType = {
  INT: "int",
  FLOAT: "float",
} as const;
/** @deprecated */
export type MeasurementDataTypeType =
  (typeof MeasurementDataType)[keyof typeof MeasurementDataType];

/** @deprecated see MeasurementTypeAdapter and associated types for new usage */
export type MeasurementType = {
  data_type: MeasurementDataTypeType;
  id: string;
  name: string;
  units: string[];
};

export const MeasurementsDomain = {
  /**
   * Get measurement data for entity for specific measurement type
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/measurements/plot_measurements
   * @returns object with list of measurement series and form data for a specific entity and measurement type
   */
  getMeasurementPlotByMeasurementType: async ({
    measurementType,
    ...restParams
  }: Partial<
    PaginationAndSortParamsType & GetMeasurementPlotByMeasurementParamsType
  >) => {
    return await restAPI.nodeAPI.GET<GetMeasurementPlotResponseType>({
      endpoint: `/measurements/plot/${measurementType}`,
      query: camelToSnakeKeys(restParams),
    });
  },

  // TODO: this endpoint should be merged with listMeasurementTypes
  /**
   * Get Measurement Types for entity item
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/measurement_types/list_measurement_types
   * @returns list of measurement types for entity item
   * @deprecated use v2 at MeasurementTypeAdapter.listMeasurementTypes
   */
  getMeasurementTypesBySubject: async ({
    ...restParams
  }: GetMeasurementTypesBySubjectType) => {
    return await restAPI.nodeAPI.GET<MeasurementType[]>({
      endpoint: `/measurement_types`,
      query: camelToSnakeKeys(restParams),
    });
  },

  /**
   * List all Measurement Types
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/measurement_types/list_measurement_types
   * @returns List of Measurement Types
   * @deprecated use v2 at MeasurementTypeAdapter.listMeasurementTypes
   */
  listMeasurementTypes: async () =>
    restAPI.nodeAPI.GET<MeasurementType[]>({
      endpoint: "/measurement_types",
    }),
};
