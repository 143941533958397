import Immutable from "immutable";

import {
  FETCH_WORKFLOWS,
  FETCH_WORKFLOWS_ERROR,
  FETCH_WORKFLOW_INSTANCES,
  FETCH_WORKFLOW_INSTANCES_ERROR,
  USER_SIGNED_OUT,
  START_FETCH_WORKFLOWS,
} from "../constants/action-types";

const workflowInstance = Immutable.Record({
  company_id: undefined,
  completed_at_utc_epoch: undefined,
  deadline_at_utc_epoch: undefined,
  sampled_at_utc_epoch: undefined,
  id: undefined,
  inserted_at_utc_epoch: undefined,
  name: undefined,
  samples: undefined,
  product_type: undefined,
  sites: undefined,
  state: undefined,
  test_type: undefined,
  tests_completed: undefined,
  tests_required: undefined,
  stream_id: undefined,
  workflow_id: undefined,
  note_count: undefined,
  dismissed_by: undefined,
  completed_by: undefined,
});

const workflow = Immutable.Record({
  archived: undefined,
  company_id: undefined,
  id: undefined,
  inserted_at_utc_epoch: undefined,
  last_triggered_utc_epoch: undefined,
  name: undefined,
  occurrences_left: undefined,
  offspec_retest: undefined,
  repeats_per_scheduling_period: undefined,
  sites: undefined,
  start_time_utc_epoch: undefined,
  test_type: undefined,
  tests_required: undefined,
  timezone: undefined,
});

export const data = new Immutable.Set([]);

export const WorkflowState = Immutable.Record(
  {
    fetchError: false,
    fetchErrorMessage: "",
    fetchErrorStatus: "",
    loadingState: "",
    workflows: data,
    workflowInstances: data,
  },
  "WorkflowState"
);

export default (state = new WorkflowState(), action) => {
  let newData = data;

  switch (action.type) {
    case START_FETCH_WORKFLOWS:
      return state.withMutations((s) => {
        s.loadingState = "loading";
      });

    case FETCH_WORKFLOWS:
      action.payload.forEach(function (workflowItem) {
        newData = newData.add(new workflow(workflowItem));
      });

      return state.withMutations((s) => {
        s.workflows = newData;
        s.fetchError = false;
        s.fetchErrorMessage = "";
        s.fetchErrorStatus = "";
        s.loadingState = "loading";
      });

    case FETCH_WORKFLOWS_ERROR:
      return state.withMutations((s) => {
        s.fetchError = true;
        s.fetchErrorMessage = "Workflow: " + action.payload.message;
        s.fetchErrorStatus = action.payload.status;
        s.loadingState = "error";
      });

    case FETCH_WORKFLOW_INSTANCES:
      action.payload.forEach(function (workflowInstanceItem) {
        newData = newData.add(new workflowInstance(workflowInstanceItem));
      });

      return state.withMutations((s) => {
        s.workflowInstances = newData;
        s.fetchError = false;
        s.fetchErrorMessage = "";
        s.fetchErrorStatus = "";
        s.loadingState = "loaded";
      });

    case FETCH_WORKFLOW_INSTANCES_ERROR:
      return state.withMutations((s) => {
        s.fetchError = true;
        s.fetchErrorMessage = "Workflow Instance: " + action.payload.message;
        s.fetchErrorStatus = action.payload.status;
        s.loadingState = "error";
      });

    case USER_SIGNED_OUT:
      return state.withMutations((s) => {
        s.workflows = Immutable.Set.of([]);
        s.workflowInstances = Immutable.Set.of([]);
        s.isRequestInProgress = false;
        s.fetchError = false;
        s.fetchErrorMessage = "";
        s.fetchErrorStatus = "";
      });

    default:
      return state;
  }
};
