import { useNavigate } from "#src/Routers/hooks";
import { AuthenticationLayouts } from "#src/batteries-included-components/Layouts/Authentication";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { userSignedOut } from "../Redux/actions";

/* eslint-disable react/prop-types */

const mapDispatchToProps = {
  userSignedOut,
};

// Callback from Auth0 for when the user has signed out by being redirected to
// Auth0's logout page
const Logout = ({ userSignedOut }) => {
  const navigate = useNavigate();

  useEffect(() => {
    userSignedOut();

    // purposely delay the logout by 2.5s to clearly indicate to the user that their session is ending
    setTimeout(() => navigate({ pathname: "/app/login" }), 2500);
  }, []);

  return <AuthenticationLayouts.LoggingOutLayout />;
};

const LogoutContainer = connect(undefined, mapDispatchToProps)(Logout);

export default LogoutContainer;
