export const validateDates = ({
  start,
  end,
  due_date,
}: {
  start: string;
  end?: string;
  due_date: string;
}) => {
  const tzUTC = "UTC";

  const startUTC = new Date(
    start?.toLocaleString("en-US", { timeZone: tzUTC })
  );

  const endUTC =
    end && new Date(end.toLocaleString("en-US", { timeZone: tzUTC }));

  const dueDateUTC = new Date(
    due_date?.toLocaleString("en-US", {
      timeZone: tzUTC,
    })
  );

  if (endUTC && startUTC > endUTC) {
    return {
      type: "end",
      message: "Error: The start date is after the end date.",
    };
  }

  if (startUTC > dueDateUTC) {
    return {
      type: "due_date",
      message: "Error: The start date is after the due date.",
    };
  }

  return null;
};
