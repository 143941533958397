export * from "./AddDevicesPage";

export const ADD_DEVICES_PAGE_TITLE = "Add Devices";

export const linkToAddDevicesToEvent = (
  eventCategoryId = ":eventCategoryId",
  eventTemplateId = ":eventTemplateId",
  eventId = ":eventId"
) => {
  return `/app/events/event-categories/${eventCategoryId}/templates/${eventTemplateId}/events/${eventId}/add-devices`;
};

export const ADD_DEVICES_BREADCRUMB = {
  title: ADD_DEVICES_PAGE_TITLE,
  path: linkToAddDevicesToEvent,
};
