export const SCENARIO_DETAIL_PAGE_TITLE = "Scenario:scenarioId";

export const linkToScenarioDetail = (scenarioId = ":scenarioId") =>
  `/app/scenarios/${scenarioId}`;

export const SCENARIO_DETAIL_BREADCRUMB = {
  title: SCENARIO_DETAIL_PAGE_TITLE,
  // TODO: Enable this when there's a scenario detail page
  // path: linkToScenarioDetail,
};
