import {
  DEFAULT_QUERY_OPTIONS,
  getPollingOptions,
} from "#hooks/adapters/adapterUtils";
import { useQuery } from "@tanstack/react-query";
import { JobAdapter, JobStatus } from "@validereinc/domain";

export const useGetOneJob = (id: string) =>
  useQuery({
    queryKey: ["job", "getOne", id],
    queryFn: () => JobAdapter.getOne({ id }),
    enabled: Boolean(id),
    ...getPollingOptions([
      JobStatus.FAILED,
      JobStatus.FINISHED,
      JobStatus.PARTIAL,
    ]),
    ...DEFAULT_QUERY_OPTIONS,
  });
