import { DEFAULT_QUERY_OPTIONS } from "#src/components/hooks/adapters/adapterUtils";
import { useListCustomAttributes } from "#src/components/hooks/adapters/useCustomAttributes";
import { ExceptionUtils } from "#src/utils/exception";
import { useQuery } from "@tanstack/react-query";
import { KeyValueList } from "@validereinc/common-components";
import {
  AssetGroupDomain,
  AssetType,
  AssetTypeType,
  BaseError,
  CustomAttributeRecordValueType,
  Device,
  EquipmentDomainV2,
  FacilityDomain,
  FlowDomain,
} from "@validereinc/domain";
import classNames from "classnames/bind";
import React from "react";
import styles from "./CustomAttributeKeyValueList.module.scss";

const cx = classNames.bind(styles);

const getAsset = async (assetType: AssetTypeType, assetId: string) => {
  switch (assetType) {
    case AssetType.FACILITY:
      return FacilityDomain.getOne({ id: assetId });
    case AssetType.EQUIPMENT:
      return EquipmentDomainV2.getOne({ id: assetId });
    case AssetType.DEVICE:
      return Device.getOne({ id: assetId });
    case AssetType.FLOW:
      return { data: await FlowDomain.getFlow({ flowId: assetId }) };
    case AssetType.ASSET_GROUP:
      return { data: await AssetGroupDomain.getOne({ id: assetId }) };
  }
};

export const CustomAttributeKeyValueList = ({
  assetType,
  assetId,
  displayAttributes,
}: {
  assetType: AssetTypeType;
  assetId: string;
  displayAttributes: string[];
}) => {
  const { data: assetData, isLoading: assetLoading } = useQuery(
    [assetType, "getOne", { id: assetId }],
    () => {
      return getAsset(assetType, assetId);
    },
    {
      ...DEFAULT_QUERY_OPTIONS,
      enabled: Boolean(assetId),
    }
  );
  const assetAttributes = assetData?.data.custom_attributes;
  const { data: definitionData, isLoading: definitionLoading } =
    useListCustomAttributes({ filters: { entity_type: assetType } });

  const data: Array<{
    title: string;
    value: CustomAttributeRecordValueType;
  }> = [];

  for (const attribute of displayAttributes) {
    const definition = definitionData?.data.find(
      (def) => def.field_name === attribute
    );
    const value = assetAttributes?.[attribute] ?? "-";
    if (definition) {
      data.push({ title: definition.display_name, value });
    } else {
      ExceptionUtils.reportException(
        new BaseError(`Could not find attribute ${attribute} for ${assetType}`),
        "error"
      );
    }
  }

  return (
    <KeyValueList
      data={data}
      className={cx("keyValueListContainer")}
      isLoading={(assetId && assetLoading) || definitionLoading}
    />
  );
};
