import { getBreadcrumbsObject } from "#src/Routers/breadcrumbsHelper";
import { useNavigate } from "#src/Routers/hooks";
import DeviceList from "#src/batteries-included-components/Layouts/Devices/List/DeviceList";
import useLocalization from "#src/hooks/useLocalization";
import { ORGANIZATION_BREADCRUMB } from "#src/routes/organization";
import { DEVICE_LIST_BREADCRUMB } from "#src/routes/organization/devices";
import { linkToDeviceCreate } from "#src/routes/organization/devices/create";
import { Button, Page } from "@validereinc/common-components";
import React from "react";

export const DeviceListPage = () => {
  const navigate = useNavigate();
  const { isLoading: isMappingLoading } = useLocalization();
  const breadcrumbsObject = getBreadcrumbsObject([
    ORGANIZATION_BREADCRUMB,
    DEVICE_LIST_BREADCRUMB,
  ]);

  const handleCreateDevice = () => {
    navigate({
      pathname: linkToDeviceCreate(),
    });
  };

  const actionRow = (
    <Button
      variant="primary"
      onClick={handleCreateDevice}
    >
      Create Device
    </Button>
  );

  return (
    <Page
      title="Devices"
      breadcrumbs={breadcrumbsObject}
      actionRow={actionRow}
      isLoading={isMappingLoading}
    >
      <DeviceList />
    </Page>
  );
};

DeviceListPage.displayName = "DeviceListPage";
