import { useMultiStepFormContext } from "#src/hooks/useMultiStepForm";
import { RoleCreateFormSchema } from "#src/routes/settings/roles-and-permissions/roles/create";
import { useQuery } from "@tanstack/react-query";
import {
  Form,
  Panel,
  TextAreaInput,
  TextInput,
  useForm,
} from "@validereinc/common-components";
import { RolesAdapter } from "@validereinc/domain";
import classNames from "classnames/bind";
import React, { useCallback, useEffect } from "react";
import { useDebounce } from "use-debounce";
import styles from "./CreateRoleDetailsStep.module.scss";

const cx = classNames.bind(styles);

export const CreateRoleDetailsStep = () => {
  const { currentStep, getStep, updateStep } = useMultiStepFormContext();
  const stepDetails = getStep(1);
  const form = useForm();

  const [enteredRoleName] = useDebounce<string>(
    form.watch(RoleCreateFormSchema.keyof().Enum.name),
    500
  );

  const duplicateQueryPayload: Parameters<typeof RolesAdapter.getList>[0] = {
    filters: {
      name: enteredRoleName,
      isAlreadyFormatted: true,
    },
  };

  const { data: duplicateRoles } = useQuery({
    queryKey: ["roles", duplicateQueryPayload],
    queryFn: () => RolesAdapter.getList(duplicateQueryPayload),
    enabled: Boolean(enteredRoleName),
  });

  const memoizedGetFormState = useCallback(
    () => ({
      isSubmitting: form.formState.isSubmitting,
      isValid: form.formState.isValid,
    }),
    [form.formState.isSubmitting, form.formState.isValid]
  );

  useEffect(() => {
    updateStep(1, {
      getValues: () => form.getValues(),
      getFormState: memoizedGetFormState,
    });
  }, [form, memoizedGetFormState]);

  const formKeys = RoleCreateFormSchema.keyof().Enum;

  if (currentStep !== 1) {
    return null;
  }

  return (
    <Panel title={stepDetails?.label}>
      <Form {...form}>
        <div className={cx("detailsStepContainer")}>
          <TextInput
            name={formKeys.name}
            label="Name"
            isRequired
            validate={{
              uniqueName: () => {
                return duplicateRoles?.total_entries
                  ? "This role already exists"
                  : true;
              },
            }}
          />
          <TextAreaInput
            name="description"
            label="Description"
          />
        </div>
      </Form>
    </Panel>
  );
};
