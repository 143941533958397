export const EDIT_USER_ROLE_TITLE = "Edit:userId";

export const linkToEditUserRolePage = (userId = ":userId") =>
  `/app/settings/users/${userId}/roles/edit`;

export const EDIT_USER_ROLE_BREADCRUMB = {
  title: EDIT_USER_ROLE_TITLE,
  path: linkToEditUserRolePage,
};

export * from "./EditUserRolePage";
