import { useMeasurementTypes } from "#src/contexts/MeasurementTypeContext";
import { TextInput } from "@validereinc/common-components";
import React from "react";

export const MeasurementField = ({
  name,
  prompt,
  description,
  is_required,
  measurement_unit,
}: {
  description?: string;
}) => {
  const { measurementUnits } = useMeasurementTypes();

  const displayMeasurementUnit = measurementUnits.find(
    ({ id }) => measurement_unit === id
  );

  return (
    <TextInput
      name={name}
      label={prompt}
      type="number"
      isRequired={is_required}
      description={description}
      unit={displayMeasurementUnit?.name?.symbol ?? measurement_unit}
      isFluid
    />
  );
};
