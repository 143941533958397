import profiles from "./profiles";
import alerts from "./alerts";
import insights from "./insights";
import sites from "./sites";
import instruments from "./instruments";
import chainOfCustody from "./chainOfCustody";
import streams from "./streams";
import workflows from "./workflows";
import authentication from "./authentication";
import permissions from "./permissions";
import measurements from "./measurements";
import alertMessages from "./alertMessages";
import analyze from "./analyze";
import recordOfVolume from "./recordOfVolume";
import adjustmentFactors from "./adjustmentFactors";
import featureFlags from "./featureFlags";

import { combineReducers } from "redux-immutable";
import Immutable from "immutable";

export const StateRecord = Immutable.Record(
  {
    alerts: undefined,
    insights: undefined,
    sites: undefined,
    instruments: undefined,
    streams: undefined,
    chainOfCustody: undefined,
    profiles: undefined,
    workflows: undefined,
    authentication: undefined,
    permissions: undefined,
    measurements: undefined,
    alertMessages: undefined,
    analyze: undefined,
    recordOfVolume: undefined,
    adjustmentFactors: undefined,
    featureFlags: undefined,
  },
  "State"
);

const rootReducer = combineReducers(
  {
    profiles,
    alerts,
    insights,
    sites,
    instruments,
    chainOfCustody,
    streams,
    workflows,
    authentication,
    permissions,
    measurements,
    alertMessages,
    analyze,
    recordOfVolume,
    adjustmentFactors,
    featureFlags,
  },
  StateRecord
);

export default rootReducer;
