import { useCustomAttributeFilters } from "#hooks/FilterPanel/useCustomAttributeFilters";
import { FacilityDropdownInput } from "#src/batteries-included-components";
import useLocalization from "#src/hooks/useLocalization";
import {
  DateSelectorInput,
  DropdownInput,
  TextInput,
} from "@validereinc/common-components";
import {
  AssetType,
  FacilitySchema,
  FacilityStatusOptions,
} from "@validereinc/domain";
import React from "react";

export const useFacilityFilters = ({
  isWithCustomAttributes = false,
  isWithPeriod = false,
}: {
  isWithCustomAttributes?: boolean;
  isWithPeriod?: boolean;
} = {}) => {
  const { localize } = useLocalization();
  const { customAttributeFilters } = useCustomAttributeFilters({
    assetType: AssetType.FACILITY,
    enabled: isWithCustomAttributes,
    section: localize("facility_plural"),
  });

  const periodFilter = (
    <DateSelectorInput
      key={"period"}
      name={"period"}
      variant="month"
      label="Period"
      isInline
      isLabelShown={false}
    />
  );
  const facilitySchemaKeys = FacilitySchema.keyof().Enum;

  return [
    {
      component: (
        <TextInput
          key={facilitySchemaKeys.name}
          name={facilitySchemaKeys.name}
          placeholder={`Search ${localize("facility_plural")}...`}
          label="Search"
          type="search"
          isInline
          isLabelShown={false}
        />
      ),
    },
    ...(isWithPeriod
      ? [
          {
            component: periodFilter,
          },
        ]
      : []),
    {
      section: localize("facility_plural"),
      component: (
        <FacilityDropdownInput
          key={facilitySchemaKeys.id}
          name={facilitySchemaKeys.id}
          isMulti
          isFluid
          isOptionalTextShown={false}
        />
      ),
    },
    {
      section: localize("facility_plural"),
      component: (
        <DropdownInput
          key={facilitySchemaKeys.status}
          name={facilitySchemaKeys.status}
          label="Status"
          options={FacilityStatusOptions}
          labelKey="name"
          valueKey="id"
          isMulti
          isFluid
          isSearchable
          isOptionalTextShown={false}
        />
      ),
    },
    ...(isWithCustomAttributes ? customAttributeFilters : []),
  ];
};
