import { useSearchParams } from "#routers/hooks";
import { ApplyAutomationProgressBanner } from "#src/batteries-included-components/Banners/ApplyAutomationProgressBanner";
import { ImportDataActionStatusWithQueue } from "#src/batteries-included-components/Banners/ImportDataActionStatus/ImportDataActionStatus";
import { useDataIngestionMapping } from "#src/batteries-included-components/Panels/TablePanels/RecordsListTablePanel.helpers";
import {
  EquipmentRecordFilterPanel,
  FacilityRecordFilterPanel,
  FlowRecordFilterPanel,
  RecordsListTablePanel,
} from "#src/batteries-included-components/index";
import { useIsFeatureAvailable } from "#src/contexts/AuthenticatedContext.helpers";
import useLocalization from "#src/hooks/useLocalization";
import {
  CustomStorageKeyTypes,
  useCustomStorageKey,
  useStorageKey,
} from "#src/hooks/useStorageKey";
import { RECORDS_PAGE_BREADCRUMB } from "#src/routes/records/index";
import {
  RECORDS_BREADCRUMB,
  RECORDS_TITLE,
} from "#src/routes/records/records/index";
import { Page, Tab } from "@validereinc/common-components";
import { AssetType } from "@validereinc/domain";
import isEqual from "lodash/isEqual";
import React, { useState } from "react";

export const RecordsListPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { localize } = useLocalization();
  const equipmentRecordsStorageKeys = useStorageKey("equipment-records");
  const equipmentRecordsApplyAutomationStorageKey = useCustomStorageKey(
    "equipment-records-apply-automation",
    CustomStorageKeyTypes.JOB
  );
  const flowRecordStorageKeys = useStorageKey("flows-records");
  const flowRecordsApplyAutomationStorageKey = useCustomStorageKey(
    "flows-records-apply-automation",
    CustomStorageKeyTypes.JOB
  );
  const facilityRecordStorageKeys = useStorageKey("facilities-records");
  const facilityRecordsApplyAutomationStorageKey = useCustomStorageKey(
    "facilities-records-apply-automation",
    CustomStorageKeyTypes.JOB
  );

  const [isFlowEnabled] = useIsFeatureAvailable({
    featureFlagQuery: "core:flows",
    permissionQuery: "flows:read",
  });
  const [isEquipmentEnabled] = useIsFeatureAvailable({
    featureFlagQuery: "core:equipment",
    permissionQuery: "equipment:read",
  });
  const [isFacilityEnabled] = useIsFeatureAvailable({
    featureFlagQuery: "core:facilities",
    permissionQuery: "facilities:read",
  });

  const [selectableProperties, setSelectableProperties] = useState<string[]>();

  const DEFAULT_TAB_KEY = isEquipmentEnabled
    ? "equipment"
    : isFlowEnabled
      ? "flows"
      : "facilities";

  return (
    <Page
      title={RECORDS_TITLE}
      breadcrumbs={[RECORDS_PAGE_BREADCRUMB, RECORDS_BREADCRUMB]}
      activeTabKey={searchParams?.tab ?? DEFAULT_TAB_KEY}
      onActiveTabKeyChange={(tabKey: string) => {
        setSearchParams({ tab: tabKey });
      }}
    >
      {isEquipmentEnabled ? (
        <Tab
          title={`${localize("Equipment")}`}
          tabKey="equipment"
        >
          <ImportDataActionStatusWithQueue
            resource={useDataIngestionMapping(AssetType.EQUIPMENT)}
          />
          <ApplyAutomationProgressBanner
            jobStorageKey={equipmentRecordsApplyAutomationStorageKey}
          />
          <EquipmentRecordFilterPanel
            filterConfigStorageKey={
              equipmentRecordsStorageKeys.filterConfigStorageKey
            }
            selectableProperties={selectableProperties}
          />
          <RecordsListTablePanel
            type={AssetType.EQUIPMENT}
            {...equipmentRecordsStorageKeys}
            applyAutomationStorageKey={
              equipmentRecordsApplyAutomationStorageKey
            }
            setAvailableProperties={(keys) => {
              if (isEqual(keys, selectableProperties)) return;
              setSelectableProperties(keys);
            }}
          />
        </Tab>
      ) : null}
      {isFlowEnabled ? (
        <Tab
          title={`${localize("Flows")}`}
          tabKey="flows"
        >
          <ImportDataActionStatusWithQueue
            resource={useDataIngestionMapping(AssetType.FLOW)}
          />
          <ApplyAutomationProgressBanner
            jobStorageKey={flowRecordsApplyAutomationStorageKey}
          />
          <FlowRecordFilterPanel
            filterConfigStorageKey={
              flowRecordStorageKeys.filterConfigStorageKey
            }
            selectableProperties={selectableProperties}
          />
          <RecordsListTablePanel
            type={AssetType.FLOW}
            {...flowRecordStorageKeys}
            applyAutomationStorageKey={flowRecordsApplyAutomationStorageKey}
            setAvailableProperties={(keys) => {
              if (isEqual(keys, selectableProperties)) return;
              setSelectableProperties(keys);
            }}
          />
        </Tab>
      ) : null}
      {isFacilityEnabled ? (
        <Tab
          title={`${localize("Facilities")}`}
          tabKey="facilities"
        >
          <ImportDataActionStatusWithQueue
            resource={useDataIngestionMapping(AssetType.FACILITY)}
          />
          <ApplyAutomationProgressBanner
            jobStorageKey={facilityRecordsApplyAutomationStorageKey}
          />
          <FacilityRecordFilterPanel
            filterConfigStorageKey={
              facilityRecordStorageKeys.filterConfigStorageKey
            }
            selectableProperties={selectableProperties}
          />
          <RecordsListTablePanel
            type={AssetType.FACILITY}
            {...facilityRecordStorageKeys}
            applyAutomationStorageKey={facilityRecordsApplyAutomationStorageKey}
            setAvailableProperties={(keys) => {
              if (isEqual(keys, selectableProperties)) return;
              setSelectableProperties(keys);
            }}
          />
        </Tab>
      ) : null}
    </Page>
  );
};
