export const EQUIPMENT_EMISSION_RECORD_DETAIL_FALLBACK_PAGE_TITLE =
  "Emissions Record";

export const linkToEquipmentEmissionRecordDetail = (
  equipmentId = ":equipmentId",
  emissionPeriod = ":emissionPeriod"
) =>
  `/app/organization/equipment/${equipmentId}/emissions/${emissionPeriod}/detail`;

export const EQUIPMENT_EMISSION_RECORD_DETAIL_BREADCRUMB = {
  title: EQUIPMENT_EMISSION_RECORD_DETAIL_FALLBACK_PAGE_TITLE,
  path: linkToEquipmentEmissionRecordDetail(),
};

export { default as EmissionsRecordDetail } from "./EmissionsRecordDetailPage";
