import React from "react";

import { EventTemplatesFilterPanel } from "#src/batteries-included-components/Panels/FilterPanels/EventTemplatesFilterPanel";
import { EventCategoryTemplatesPanel } from "#src/batteries-included-components/Panels/TablePanels/EventCategoryTemplatesPanel";

export const EventCategoryTemplatesTab = () => {
  return (
    <>
      <EventTemplatesFilterPanel />

      <EventCategoryTemplatesPanel />
    </>
  );
};
