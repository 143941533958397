import { LegacyDataTable, Panel } from "@validereinc/common-components";
import classNames from "classnames/bind";
import * as PropTypes from "prop-types";
import React from "react";
import { AutoSizer } from "react-virtualized";
import styles from "./ComponentsTable.module.scss";
import useFilterInputs from "./SourcesTableFilter";

const cx = classNames.bind(styles);

const PANEL_PADDING = 20;

const SourcesTable = ({
  sourcesList = [],
  onCheckboxClick,
  selectedValues,
  isDisabled,
  streams,
}) => {
  const [filterRow, filterPillbox, filteredSources] = useFilterInputs(
    sourcesList,
    streams
  );

  const Headers = [
    {
      label: "Name",
      key: "name",
      width: 250,
      fixed: true,
    },
    {
      label: "Facility",
      key: "facility",
      width: 250,
    },
    {
      label: "Product Type",
      key: "product_type",
      width: 150,
    },
    {
      label: "Associated Balance",
      key: "associated_balance",
      width: 250,
    },
  ];

  return (
    <Panel className={cx("container")}>
      <AutoSizer>
        {({ height, width }) => (
          <LegacyDataTable
            width={width}
            height={height - PANEL_PADDING}
            headers={Headers}
            list={filteredSources}
            filterKey="name"
            filterTitle="Name"
            defaultSortBy="name"
            defaultSortDirection="asc"
            onCheckboxClick={onCheckboxClick}
            checkedList={selectedValues}
            getCheckboxDisabledState={() => isDisabled}
            filterRow={filterRow}
            filterPillbox={filterPillbox}
          />
        )}
      </AutoSizer>
    </Panel>
  );
};

SourcesTable.propTypes = {
  sourcesList: PropTypes.array,
  onCheckboxClick: PropTypes.func,
  selectedValues: PropTypes.array,
  isDisabled: PropTypes.bool,
  streams: PropTypes.array,
};

export default SourcesTable;
