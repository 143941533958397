import moment from "moment";
import config from "#src/config";
import {
  apiRequestServicesPromise,
  apiPostServicesPromise,
  apiPutServicesPromise,
  apiDeleteServicesPromise,
} from "./ServiceHelper";
import round from "lodash/round";
import { getTimeStringFromDate } from "#utils/timeFormatter";

const NUM_DECIMALS = 5;

export const getVolumeEntryParams = (inputs, isInventoryStream) => {
  const { dates, source, totalVolume, unit, adjustmentFactors, entryType } =
    inputs;

  let value =
    entryType.id === "Accumulation" ? totalVolume : totalVolume / dates.length;

  value = round(value, NUM_DECIMALS);

  const volumeEntryParams = dates.map((date) => {
    const type = isInventoryStream ? "inventory" : "standard";

    return {
      date: date.date,
      source: source.id,
      total_volume: {
        value,
        unit,
      },
      adjustment_factor_ids:
        adjustmentFactors?.map((factor) => factor.id) ?? [],
      type,
    };
  });

  return volumeEntryParams;
};

const RecordOfVolumeService = {
  getRecordOfVolumes(streamId, from, to) {
    const param = {
      from: getTimeStringFromDate(from, config.DATE_FORMAT),
      until: getTimeStringFromDate(to, config.DATE_FORMAT),
    };
    return apiRequestServicesPromise(
      `api/streams/${streamId}/records_of_volume`,
      param
    );
  },

  getRecordOfVolumesOfActiveStreams(streamIds, from, to) {
    // 1 day subtracted to get opening volume of earliest day
    const param = {
      from: getTimeStringFromDate(
        moment(from).subtract(1, "day"),
        config.DATE_FORMAT
      ),
      until: getTimeStringFromDate(to, config.DATE_FORMAT),
      stream_id: streamIds,
    };

    return apiRequestServicesPromise(`api/streams/records_of_volume`, param);
  },

  getRecordOfVolumeChart(streamId, from, to, interval) {
    const param = {
      from: from,
      until: to,
      interval: interval,
    };

    return apiRequestServicesPromise(
      `/api/streams/${streamId}/records_of_volume/chart`,
      param
    );
  },

  updateStreamAdjustmentFactors(streamIds, adjustmentFactorIds, upperLimit) {
    const params = {
      stream_ids: streamIds,
      adjustment_factor_ids: adjustmentFactorIds,
      upper_limit: upperLimit,
    };

    return apiPutServicesPromise(
      `/api/stream_volume_adjustment_configs`,
      params
    );
  },

  getAdjustmentFactors() {
    return apiRequestServicesPromise(`/api/adjustment_factors`);
  },

  getStreamVolumeAdjustmentConfigs() {
    return apiRequestServicesPromise(`/api/stream_volume_adjustment_configs`);
  },

  addAdjustmentFactor(adjustmentFactor) {
    return apiPostServicesPromise(`/api/adjustment_factors`, adjustmentFactor);
  },

  updateAdjustmentFactor(adjustmentFactor) {
    return apiPutServicesPromise(
      `/api/adjustment_factors/${adjustmentFactor.id}`,
      adjustmentFactor
    );
  },

  deleteAdjustmentFactor(adjustmentFactorId) {
    return apiDeleteServicesPromise(
      `/api/adjustment_factors/${adjustmentFactorId}`
    );
  },

  upsertVolumeEntries(streamId, inputs, isInventoryStream) {
    const records = getVolumeEntryParams(inputs, isInventoryStream);

    return apiPutServicesPromise(`/api/streams/${streamId}/records_of_volume`, {
      records,
    });
  },
};

export default RecordOfVolumeService;
