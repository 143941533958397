import { getPropertyAsMap } from "#utils/objectFormatter";
import findIndex from "lodash/findIndex";

const siteContainInWorkflow = (workflow, site) => {
  return findIndex(workflow?.sites, { id: site.id }) !== -1;
};

export const isInactiveWorkflow = (workflow) => {
  return workflow?.occurrences_left === 0;
};

export const getTestTypeLabel = (testType) => {
  switch (testType) {
    case "field":
      return "Field";
    case "third_party_lab":
      return "3PL";
    case "manual":
      return "Manual";
    default:
      return undefined;
  }
};

export const getScheduleTaskOptions = (workflows, workflowTasks, site) => {
  if (workflowTasks.length) {
    const workflowsMap = getPropertyAsMap(workflows);

    return workflowTasks
      .filter((workflowTask) => {
        const workflow = workflowsMap[workflowTask?.workflow_id];
        if (isInactiveWorkflow(workflow)) {
          return false;
        }

        if (site && !siteContainInWorkflow(workflow, site)) {
          return false;
        }

        return true;
      })
      .map((workflowTask) => {
        const streamName = workflowTask?.name ?? "";
        const workflowName = workflowTask?.workflow?.name ?? "";
        const testType = getTestTypeLabel(workflowTask?.test_type) ?? "";

        return {
          ...workflowTask,
          name: `${streamName} (${workflowName} / ${testType})`,
        };
      });
  } else {
    return [];
  }
};
