import { InputIsMatched } from "#utils/filters";
import filter from "lodash/filter";

export function searchByStreamName(list, streamName) {
  return list.filter((row) =>
    InputIsMatched(streamName, row.stream?.name ?? "-")
  );
}

export const sortByMeasurement = (list, measurementName) => {
  return [...list].sort((a, b) => {
    const aValue = a.qualities[measurementName]?.value ?? -1;
    const bValue = b.qualities[measurementName]?.value ?? -1;

    return aValue - bValue;
  });
};

export const sortByGasEquivalentVolume = (list) => {
  return [...list].sort((a, b) => {
    const aValue = a.gas_equivalent_volume.value ?? -1;
    const bValue = b.gas_equivalent_volume.value ?? -1;

    return aValue - bValue;
  });
};

export const sortByLiquidEquivalentVolume = (list) => {
  return [...list].sort((a, b) => {
    const aValue = a.liquid_equivalent_volume.value ?? -1;
    const bValue = b.liquid_equivalent_volume.value ?? -1;

    return aValue - bValue;
  });
};

export const sortByTotalVolume = (list) => {
  return [...list].sort((a, b) => {
    const aValue = a.raw_volume.value ?? -1;
    const bValue = b.raw_volume.value ?? -1;

    return aValue - bValue;
  });
};

export const sortByEnergy = (list) => {
  return [...list].sort((a, b) => {
    const aValue = a?.energy?.value ?? -1;
    const bValue = b?.energy?.value ?? -1;

    return aValue - bValue;
  });
};

export const sortByStream = (list, columnKey) => {
  return [...list].sort((a, b) => {
    const aValue = a.stream[columnKey] ?? "-";
    const bValue = b.stream[columnKey] ?? "-";

    return aValue.localeCompare(bValue);
  });
};

export const sortByApprovedBy = (list) => {
  return [...list].sort((a, b) => {
    const aValue = a.approved_by?.name ?? -1;
    const bValue = b.approved_by?.name ?? -1;

    return aValue - bValue;
  });
};

export const findAccountingRecordIndex = (
  accountingRecords,
  accountingRecord
) => {
  return accountingRecords.findIndex(
    (record) => record.id === accountingRecord?.id
  );
};

export const getOpeningInventoryDetail = (openingInventory, key) => {
  return openingInventory?.inventory_opening?.[key] ?? null;
};

export const showOpeningInventory = (record) => {
  return record.category === "inventory_closing";
};

export const formatCategory = (category) => {
  return category?.replace("_", " ") ?? "-";
};

export const getInventoryRecord = (records) => {
  return filter(records, { category: "inventory_closing" });
};
