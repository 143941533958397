import React from "react";
import * as PropTypes from "prop-types";
import "./SystemBalanceSummary.scss";
import {
  getFormattedMeasurementValueWithUnit,
  CUBIC_METERS_VOLUME,
  PERCENTAGE_KEY,
} from "#redux/reducers/measurements";
import { connect } from "react-redux";
import { useRecordSelectionContext } from "../Context/recordSelectionContext";
import ResultDifference, { computeDifference } from "./ResultDifference";
import {
  getSystemBalanceProperties,
  shouldShowPercentChange,
} from "./SystemBalanceHelper";

const mapStateToProps = (state) => {
  return {
    getFormattedMeasurementValueWithUnit: (measurementKey, measurementObject) =>
      getFormattedMeasurementValueWithUnit(state.measurements)(
        measurementKey,
        measurementObject
      ),
  };
};

const SystemBalanceProperty = (props) => (
  <div className="systemBalanceProperty">
    <div className="systemBalanceProperty__name">{props.name}</div>
    <div
      className="systemBalanceProperty__value"
      style={props.style}
    >
      {props.value}
    </div>
  </div>
);

export const SystemBalanceSummary = (props) => {
  const { systemBalance, initialSystemBalanceResult } =
    useRecordSelectionContext();

  const { total } = systemBalance;
  const { total: initialTotal } = initialSystemBalanceResult;

  const getPropertyValue = (total, id, initialTotal) => {
    const value = total?.[id];

    const { percentChange, difference } = computeDifference(
      value,
      initialTotal?.[id]
    );

    const measurementKey =
      value?.unit !== "%" ? CUBIC_METERS_VOLUME : PERCENTAGE_KEY;

    return (
      <div style={{ textAlign: "right" }}>
        {props.getFormattedMeasurementValueWithUnit(measurementKey, value)}

        {difference && (
          <ResultDifference
            difference={difference}
            percentChange={percentChange}
            unit={value.unit}
            showPercentChange={shouldShowPercentChange(id)}
          />
        )}
      </div>
    );
  };

  return (
    <div className="systemBalanceSummary">
      {getSystemBalanceProperties(systemBalance).map(({ name, id }) => (
        <SystemBalanceProperty
          key={id}
          name={name}
          value={getPropertyValue(total, id, initialTotal)}
        />
      ))}
    </div>
  );
};

SystemBalanceProperty.propTypes = {
  name: PropTypes.string.isRequired,
  style: PropTypes.object,
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

SystemBalanceSummary.propTypes = {
  getFormattedMeasurementValueWithUnit: PropTypes.func,
};

export default connect(mapStateToProps)(SystemBalanceSummary);
