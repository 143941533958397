import React, { useContext, useEffect, useMemo } from "react";

import {
  Dialog,
  DropdownInput,
  Form,
  FormButton,
  TextInput,
  useAlert,
  useForm,
} from "@validereinc/common-components";
import { RecordDomain } from "@validereinc/domain";

import { useMeasurementTypes } from "#src/contexts/MeasurementTypeContext";
import { RecordContext } from "#src/contexts/RecordContext";

export const AddRecordValueDialog = ({
  isOpen,
  onClose,
  onSubmit,
}: {
  isOpen: boolean;
  onClose?: () => void;
  onSubmit?: () => void;
}) => {
  const { addAlert } = useAlert();
  const { record } = useContext(RecordContext) || {};
  const { measurementTypes, getUnitsByQuantity } = useMeasurementTypes();

  const form = useForm();
  const measurementTypeId = form.watch("measurementType");
  const unit = form.watch("unit");

  const measurementType = useMemo(
    () => measurementTypes.find(({ id }) => id === measurementTypeId),
    [measurementTypes, measurementTypeId]
  );
  const units = getUnitsByQuantity(measurementType?.quantity);

  useEffect(() => {
    if (measurementType) {
      if (!unit || !units.includes(unit)) {
        form.setValue("unit", measurementType.default_unit);
      }
      if (!form.watch("value")) {
        form.setValue("value", 0);
      }
    }
  }, [measurementType]);

  useEffect(() => {
    form.reset();
  }, [isOpen]);

  const submitHandler = async ({
    unit,
    value,
    measurementType,
  }: {
    unit: string;
    value: string;
    measurementType: string;
  }) => {
    if (record?.id) {
      try {
        await RecordDomain.recordValue.update(
          {
            recordId: record?.id,
            measurementType,
          },
          {
            measurement_unit: unit,
            value: parseFloat(value),
          }
        );
        onClose?.();
        onSubmit?.();
      } catch (err) {
        addAlert({
          variant: "error",
          message: "Unable to add new property",
        });
      }
    }
  };

  return (
    <Form
      {...form}
      onSubmit={form.handleSubmit(submitHandler)}
    >
      <Dialog
        key="edit-record-value"
        title="Add Record Value Type"
        isOpen={isOpen}
        onClose={onClose}
        actionRow={[
          <FormButton
            key="save-record-value"
            variant="primary"
            onClick={form.handleSubmit(submitHandler)}
          >
            Add
          </FormButton>,
        ]}
      >
        <DropdownInput
          label="Measurement Type"
          name="measurementType"
          options={measurementTypes ?? []}
          valueKey="id"
          labelKey="name"
          isRequired
          isClearable={false}
        />
        <TextInput
          label="Value"
          name="value"
          type="number"
          dataType="number"
          placeholder="Set value..."
          key="value"
        />
        <DropdownInput
          label="Unit"
          name="unit"
          options={units}
          valueKey="id"
          labelKey="name.plural"
          isRequired
          isClearable={false}
        />
      </Dialog>
    </Form>
  );
};
