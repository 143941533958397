import { FORM_SUBMISSION_STATUS_OPTIONS } from "#src/constants";
import {
  DateSelectorInput,
  DropdownInput,
  FilterPanel,
  StorageKeys,
} from "@validereinc/common-components";
import {
  FormCategoryAdapter,
  FormSchema,
  FormSubmissionStatus,
} from "@validereinc/domain";
import React from "react";

export const FORM_SUBMISSIONS_COLUMN_CONFIGURATION_LOCAL_STORAGE_KEY =
  "form_submissions_list_columns";

export const FormSubmissionsFilterConfig = {
  created_at: {
    name: "created_at",
  },
  category: {
    name: "form_category.id",
  },
  schema: {
    name: "form_schema.id",
  },
  status: {
    name: "status",
  },
} as const;

export type FormSubmissionsFilterType = Record<
  (typeof FormSubmissionsFilterConfig)[keyof typeof FormSubmissionsFilterConfig]["name"],
  string
>;

export const FormSubmissionsFilterPanel = ({
  defaultValue,
  forDraftSubmissions = false,
  filterConfigStorageKey,
}: { defaultValue?: Record<string, any>; forDraftSubmissions?: boolean } & Pick<
  StorageKeys,
  "filterConfigStorageKey"
>) => {
  return (
    <FilterPanel
      storageKey={filterConfigStorageKey}
      defaultActiveKeys={["Filters"]}
      defaultValue={defaultValue || {}}
      filters={[
        {
          component: (
            <DateSelectorInput
              key={FormSubmissionsFilterConfig.created_at.name}
              name={FormSubmissionsFilterConfig.created_at.name}
              inputId="form_submissions.filters.created_at"
              variant="day"
              isRange
              isInline
              description={
                forDraftSubmissions
                  ? "Filter by Saved At"
                  : "Filter by Submission Date"
              }
            />
          ),
        },
        {
          component: (
            <DropdownInput
              key={FormSubmissionsFilterConfig.category.name}
              name={FormSubmissionsFilterConfig.category.name}
              onFetchData={async (payload) => {
                let { data } = await FormCategoryAdapter.getList({
                  ...payload,
                  filters: {
                    name: payload.searchTerm,
                  },
                });

                // REVIEW: not a fan of the fact that we need to do this. A refactor of DropdownInput is needed.
                if (Array.isArray(payload.value)) {
                  data = data.filter((d) => payload.value.includes(d.id));
                }

                return data;
              }}
              labelKey="name"
              valueKey="id"
              label="Form Category"
              placeholder="Select Category"
              isMulti
              isInline
              isOptionalTextShown={false}
              isLabelShown={false}
            />
          ),
        },
        {
          component: (
            <DropdownInput
              key={FormSubmissionsFilterConfig.schema.name}
              name={FormSubmissionsFilterConfig.schema.name}
              onFetchData={async (payload) => {
                let { data } = await FormSchema.getFormSchemas({
                  ...payload,
                  filters: {
                    name: payload.searchTerm,
                    status: "active",
                  },
                });

                // REVIEW: not a fan of the fact that we need to do this. A refactor of DropdownInput is needed.
                if (Array.isArray(payload.value)) {
                  data = data.filter((d) => payload.value.includes(d.id));
                }

                return data;
              }}
              labelKey="name"
              valueKey="id"
              label="Form Template"
              placeholder="Select Template"
              isMulti
              isInline
              isOptionalTextShown={false}
              isLabelShown={false}
            />
          ),
        },
        ...(!forDraftSubmissions
          ? [
              {
                component: (
                  <DropdownInput
                    name={FormSubmissionsFilterConfig.status.name}
                    key={FormSubmissionsFilterConfig.status.name}
                    options={FORM_SUBMISSION_STATUS_OPTIONS.filter(
                      (opt) => opt.value !== FormSubmissionStatus.DRAFT
                    )}
                    labelKey="label"
                    valueKey="value"
                    placeholder="Select Status"
                    isInline
                    isMulti
                    isOptionalTextShown={false}
                  />
                ),
              },
            ]
          : []),
      ]}
    />
  );
};
