import { CompanyFeatureFlagType } from "../schemas";
import {
  GetListRequestType,
  ResourceServiceType,
  ResponseErrorType,
  fetchAndCollate,
  getFilterObject,
} from "../util";
import { restAPI } from "./api";

export const CompanyFeatureFlagsAdapter: CompanyFeatureFlagsResourceServiceType =
  {
    /**
     * Get a list of feature flags enabled across all companies
     * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/company_feature_flags/search_all_company_feature_flags
     */
    getList: () =>
      Promise.reject({
        success: false,
        error: "Method not supported",
      } satisfies ResponseErrorType),
    company: {
      /**
       * Get a list of feature flags enabled for a company
       * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/company_feature_flags/search_company_feature_flags
       */
      getList: async ({
        page,
        pageSize,
        filters,
        meta,
      }: GetListRequestType<
        Pick<CompanyFeatureFlagType, "feature_flag_name">,
        Pick<CompanyFeatureFlagType, "company_id">
      >) =>
        fetchAndCollate(
          ({ page, pageSize }) =>
            restAPI.nodeAPI.POST({
              endpoint: `/company_feature_flags/${meta?.company_id}/search`,
              body: {
                page,
                page_size: pageSize,
                filters: getFilterObject(filters),
              },
            }),
          page,
          pageSize
        ),
      /**
       * Enable a feature flag for a company
       * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/company_feature_flags/create_company_feature_flag
       */
      updateOne: () =>
        Promise.reject({
          success: false,
          error: "Method not supported",
        } satisfies ResponseErrorType),
      /**
       * Remove a feature flag for a company
       * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/company_feature_flags/delete_company_feature_flag
       */
      deleteOne: () =>
        Promise.reject({
          success: false,
          error: "Method not supported",
        } satisfies ResponseErrorType),
    },
  };

interface CompanyFeatureFlagsResourceServiceType
  extends Pick<ResourceServiceType<CompanyFeatureFlagType>, "getList"> {
  company: Pick<
    ResourceServiceType<CompanyFeatureFlagType>,
    "getList" | "updateOne" | "deleteOne"
  >;
}
