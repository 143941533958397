import config from "#src/config";
import { getTimeStringFromDate } from "#utils/timeFormatter";
import {
  MultiDropdownInputWithSearch,
  Panel,
} from "@validereinc/common-components";
import filter from "lodash/filter";
import find from "lodash/find";
import findIndex from "lodash/findIndex";
import pick from "lodash/pick";
import moment from "moment";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import {
  GetArrayQueryParams,
  GetQueryParam,
  SetHistoryQueryParam,
} from "../../../../Routers/historyHelper";
import { SortListByType } from "../../../../utils/sorters";
import { getColorPalette } from "../../../../utils/styleCalculator";
import DateRangeSelector from "../../../Common/DateSelector/DateRangeSelector";
import DropdownInputWithSearch from "../../../Inputs/DropdownInputWithSearch";
import { addAlertMessage } from "../../../Redux/actions/alertMessages";
import { getMeasurementType } from "../../../Redux/reducers/measurements";
import InstrumentService from "../../../Services/InstrumentService";
import "./Analytics.css";
import AnalyticsChart from "./AnalyticsChart";
import AnalyticsTable from "./AnalyticsTable";

/* eslint-disable react/prop-types */

const lodash = { find, findIndex, filter, pick };

const DEFAULT_FROM_DATE = moment().subtract(30, "days");
const DEFAULT_TO_DATE = moment();

const HEIGHT_OFFSET = 800;
const MIN_HEIGHT = 300;
const Y_AXIS_OFFSET_PERCENTAGE = 0.2;

const DOWNSAMPLE_WINDOWS = [
  { id: "none", name: "None", value: null },
  { id: "15_min", name: "15 min.", value: 900 },
  { id: "hour", name: "1 hour", value: 3600 },
  { id: "day", name: "1 day", value: 86400 },
  { id: "week", name: "1 week", value: 604800 },
  { id: "30_days", name: "30 days", value: 2592000 },
  { id: "year", name: "1 year", value: 31536000 },
];

const DEFAULT_DOWNSAMPLE_WINDOW = DOWNSAMPLE_WINDOWS[2];

const mapStateToProps = (state) => {
  return {
    measurementTypes: getMeasurementType(state.measurements),
  };
};

const mapDispatchToProps = {
  addAlertMessage,
};

class Analytics extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      from: moment(GetQueryParam("from", DEFAULT_FROM_DATE)),
      to: moment(GetQueryParam("to", DEFAULT_TO_DATE)),
      lastNumDays: null,

      tableData: [],
      chartData: [],
      streams: [],
      yDomain: [0, 1],

      hightlightSampleId: null,
      selectedMeasurementType: GetQueryParam(
        "measurementType",
        props.instrument.valid_measurement_types[0]
      ),
      selectedStreams: [],
      selectedDownsampleWindow: DEFAULT_DOWNSAMPLE_WINDOW,

      height: 0,
    };

    this.fetchAnalyticData = this.fetchAnalyticData.bind(this);
    this.setMeasurementType = this.setMeasurementType.bind(this);
    this.setDownsampleWindow = this.setDownsampleWindow.bind(this);
    this.selectDate = this.selectDate.bind(this);
    this.selectStreams = this.selectStreams.bind(this);
    this.handleResize = this.handleResize.bind(this);

    this.hoverDataPoint = this.hoverDataPoint.bind(this);
    this.getStreamColor = this.getStreamColor.bind(this);

    this.filterChartDataByStream = this.filterChartDataByStream.bind(this);
    this.filterTableDataByStream = this.filterTableDataByStream.bind(this);
  }

  componentDidMount() {
    this.fetchAnalyticData();
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize() {
    const newHeight = Math.max(window.innerHeight - HEIGHT_OFFSET, MIN_HEIGHT);

    this.setState({
      height: newHeight,
    });
  }

  fetchAnalyticData() {
    const { instrument } = this.props;
    const { selectedMeasurementType, selectedDownsampleWindow } = this.state;

    const period = this.getPeriod();

    if (selectedMeasurementType) {
      InstrumentService.getInlineInstrumentAnalyzeTable(
        instrument.id,
        period,
        selectedMeasurementType,
        selectedDownsampleWindow.value
      )
        .then(({ data }) => {
          const tableData = SortListByType(
            data.table_rows,
            "sample_start_date",
            "asc"
          );
          const chartData = data.plot_points;
          const streamsWithColor = this.colorCodeStreams(chartData);
          const yDomain = this.getYDomainRange(data.measurement_range);

          this.setState({
            tableData: tableData,
            chartData: chartData,
            streams: streamsWithColor,
            selectedStreams: this.getSelectedStreams(streamsWithColor),
            yDomain: yDomain,
            loaded: true,
          });
        })
        .catch(() => {
          this.setState({
            tableData: [],
            chartData: [],
            yDomain: [0, 1],
            loaded: true,
          });
        });
    } else {
      this.setState({
        loaded: true,
        tableData: [],
      });
    }
  }

  colorCodeStreams(streams) {
    const sortedStreams = Object.keys(streams);
    const streamsColorPalette = getColorPalette(sortedStreams.length);

    const streamsWithColorCode = [];

    sortedStreams.forEach((stream, index) => {
      const streamWithColorCode = {};
      streamWithColorCode.color = streamsColorPalette[index];
      streamWithColorCode.id = stream;
      streamWithColorCode.name = streams[stream].name;

      streamsWithColorCode.push(streamWithColorCode);
    });

    return streamsWithColorCode;
  }

  setMeasurementType(measurementType) {
    SetHistoryQueryParam({ measurementType: measurementType });

    this.setState(
      {
        selectedMeasurementType: measurementType,
        loaded: false,
      },
      () => {
        this.fetchAnalyticData();
      }
    );
  }

  setDownsampleWindow(downsampleWindow) {
    this.setState(
      {
        selectedDownsampleWindow: downsampleWindow,
        loaded: false,
      },
      () => {
        this.fetchAnalyticData();
      }
    );
  }

  selectDate(from, to, lastNumDays) {
    SetHistoryQueryParam({
      from: getTimeStringFromDate(from, config.DATE_FORMAT),
      to: getTimeStringFromDate(to, config.DATE_FORMAT),
    });

    this.setState(
      {
        from: from,
        to: to,
        lastNumDays: lastNumDays,
        loaded: false,
      },
      () => {
        this.fetchAnalyticData();
      }
    );
  }

  selectStreams(filteredStream) {
    SetHistoryQueryParam({
      streams: filteredStream,
    });

    this.setState({
      selectedStreams: filteredStream,
    });
  }

  filterChartDataByStream() {
    const { chartData, selectedStreams } = this.state;
    if (selectedStreams) {
      const selectedStreamIds = Object.values(selectedStreams).map(
        (value) => value.id
      );

      return lodash.pick(chartData, selectedStreamIds);
    }
  }

  filterTableDataByStream() {
    const { tableData, selectedStreams } = this.state;

    return tableData.filter((tableRow) => {
      return (
        lodash.findIndex(selectedStreams, { id: tableRow.stream_id }) !== -1
      );
    });
  }

  hoverDataPoint(datapoint) {
    this.setState({
      hightlightSampleId: datapoint.sample_id,
    });
  }

  getPeriod() {
    const { to, from } = this.state;

    return {
      start: moment(from).unix(),
      end: moment(to).unix(),
    };
  }

  getSelectedStreams(availableStreams) {
    return GetArrayQueryParams("streams", "id", availableStreams);
  }

  getYDomainRange(range) {
    let min = range[0];
    let max = range[1];

    min = Math.min(min, min - min * Y_AXIS_OFFSET_PERCENTAGE);
    max = Math.max(max, max + max * Y_AXIS_OFFSET_PERCENTAGE);

    return [min, max];
  }

  getStreamColor(stream_id) {
    const { streams } = this.state;
    const selectedStream = lodash.find(streams, { id: stream_id });

    return selectedStream ? selectedStream.color : "#ddd";
  }

  render() {
    const { instrument, measurementTypes } = this.props;
    const {
      loaded,
      streams,
      yDomain,
      selectedMeasurementType,
      selectedDownsampleWindow,
      selectedStreams,
      hightlightSampleId,
      from,
      to,
      relativeDayRange,
      height,
    } = this.state;

    const filteredChartData = this.filterChartDataByStream();
    const filteredTableData = this.filterTableDataByStream();

    const period = this.getPeriod();

    return (
      <Panel
        title="Inline Analyzer Samples"
        className="inlineAnalyse"
        loaded={loaded}
      >
        <div className="inlineAnalyseSelectorRow">
          <DropdownInputWithSearch
            title="Measurement Type"
            value={selectedMeasurementType}
            options={instrument.valid_measurement_types}
            onSelect={this.setMeasurementType}
            multiSelect={false}
          />

          <MultiDropdownInputWithSearch
            label="Streams"
            labelKey="name"
            value={selectedStreams}
            options={streams}
            onChange={this.selectStreams}
            ignoreCase
            inlineStyle={true}
          />

          <DropdownInputWithSearch
            title="Downsampling"
            value={selectedDownsampleWindow.name}
            options={DOWNSAMPLE_WINDOWS}
            onSelect={this.setDownsampleWindow}
            filterKey={"name"}
            multiSelect={false}
          />

          <div className="divider" />

          <DateRangeSelector
            dateRange={{
              from: from,
              to: to,
            }}
            relativeDayRange={relativeDayRange}
            onDateRangeChange={this.selectDate}
            icon
          />
        </div>

        {Object.keys(filteredChartData).length > 0 ? (
          <AnalyticsChart
            yDomain={yDomain}
            chartData={filteredChartData}
            from={from}
            to={to}
            height={height}
            getStreamColor={this.getStreamColor}
            measurementTypes={measurementTypes}
            selectedMeasurementType={selectedMeasurementType}
            hoverDataPoint={this.hoverDataPoint}
            streams={selectedStreams}
          />
        ) : (
          <div className="inlineAnalyseSelector__noData">No Samples found</div>
        )}

        <AnalyticsTable
          height={height}
          period={period}
          instrument={instrument}
          tableData={filteredTableData}
          hightlightSampleId={hightlightSampleId}
          getStreamColor={this.getStreamColor}
          measurementTypes={measurementTypes}
          selectedMeasurementType={selectedMeasurementType}
          selectedDownsampleWindow={selectedDownsampleWindow}
        />
      </Panel>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Analytics);
