export * from "./DashboardPage";

export const DASHBOARD_TITLE = "Dashboard";

export const linkToDashboards = () => "/app/dashboards";

export const DASHBOARDS_BREADCRUMB = {
  title: "Dashboards",
  path: linkToDashboards,
};
