import React, { useState } from "react";

import * as PropTypes from "prop-types";

import { AutoSizer } from "react-virtualized";

import classNames from "classnames/bind";

import {
  Form,
  FormButton,
  Panel,
  ReactLoader,
  TextWithFileInput,
  useForm,
} from "@validereinc/common-components";

import NotesList from "#common/Notes/NotesList";

import {
  SAMPLE_NOTE,
  useFetchNotes,
  useSubmitNote,
} from "#services/NotesService";

import styles from "./NotesPanel.module.scss";

const cx = classNames.bind(styles);

const NotesPanel = ({ selectedSample, refetchSample }) => {
  const [formState, setFormState] = useState("enabled");

  const form = useForm({
    defaultValues: {
      note: {},
    },
  });

  const [areNotesLoading, _errorStatus, notes, fetchNotes] = useFetchNotes(
    selectedSample?.id,
    SAMPLE_NOTE
  );

  const [_isSubmittingNote, submitNote] = useSubmitNote(
    selectedSample?.id,
    SAMPLE_NOTE
  );

  const onSubmitNote = (inputs) => {
    setFormState("loading");

    return submitNote(inputs.note)
      .then((result) => {
        fetchNotes();
        refetchSample();

        return result;
      })
      .finally(() => {
        form.reset();
        setFormState("enabled");
      });
  };

  return (
    <Panel title="Notes">
      <Form
        onSubmit={onSubmitNote}
        {...form}
      >
        <div>
          {notes?.length ? (
            <div className={cx("notesContainer")}>
              <AutoSizer disableWidth>
                {({ height }) => (
                  <ReactLoader
                    loaded={!areNotesLoading}
                    position={50}
                  >
                    <NotesList
                      list={notes}
                      height={height}
                    />
                  </ReactLoader>
                )}
              </AutoSizer>
            </div>
          ) : null}

          <TextWithFileInput
            name="note"
            label=""
            isRequired
            showIcon
            multiple
            isDisabled={formState !== "enabled"}
          />
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
          }}
        >
          <FormButton
            variant="primary"
            type="submit"
            isLoading={formState === "loading"}
            disabled={formState !== "enabled"}
          >
            Add Note
          </FormButton>
        </div>
      </Form>
    </Panel>
  );
};

NotesPanel.propTypes = {
  selectedSample: PropTypes.object,
  refetchSample: PropTypes.func,
};

export default NotesPanel;
