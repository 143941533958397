import { useDeleteCustomAttributes } from "#src/components/hooks/adapters/useCustomAttributes";
import { Button, Dialog } from "@validereinc/common-components";
import { AssetTypeType, CustomAttributeType } from "@validereinc/domain";
import React from "react";

export const DeleteCustomAttributeDialog = ({
  isOpen,
  onClose,
  assetType,
  itemToDelete,
}: {
  isOpen: boolean;
  onClose: () => void;
  assetType: AssetTypeType;
  itemToDelete: CustomAttributeType;
}) => {
  const onSuccess = () => {
    onClose?.();
  };

  const deleteCustomAttribute = useDeleteCustomAttributes({ onSuccess });
  const handleSubmit = () => {
    const id = itemToDelete?.field_name;
    deleteCustomAttribute.mutate({
      id,
      meta: { entity_type: assetType },
    });
  };

  return (
    <Dialog
      title="Delete Unit Configuration"
      isOpen={isOpen}
      onClose={onClose}
      actionRow={[
        <Button
          key="delete-unit-config"
          variant="primary"
          onClick={handleSubmit}
        >
          Delete
        </Button>,
      ]}
    >
      This will delete all existing attribute values. Are you sure?
    </Dialog>
  );
};
