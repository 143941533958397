import React, { useEffect, useMemo, useState } from "react";

import * as PropTypes from "prop-types";

import { connect } from "react-redux";

import { Button, Page } from "@validereinc/common-components";

import { getBreadcrumbsObject } from "#routers/breadcrumbsHelper";

import { useFetchSample } from "#hooks/useSample";

import { ensureStreamListIsFetched } from "#redux/actions/index";
import { havePermission } from "#redux/reducers/permissions";

import find from "lodash/find";
import DownloadableReportPanel from "./DownloadableReportPanel";
import NotesPanel from "./NotesPanel/NotesPanel";
import SampleComparisonPanel from "./SampleComparisonPanel";
import SampleDetailSummary from "./SampleDetailSummary";
import SubmitAnalysisModal from "./SubmitAnalysisModal";
import TestsPanel from "./TestsPanel";

const mapStateToProps = ({ streams, permissions }) => ({
  streams: streams.data?.toJS() ?? [],
  haveWritePermission: havePermission(permissions)("360:field_labs", "write"),
});

const mapDispatchToProps = {
  ensureStreamListIsFetched,
};

const SampleDetail = ({
  haveWritePermission,
  streams,
  breadcrumbs,
  match: {
    params: { sampleId },
  },
  ensureStreamListIsFetched,
}) => {
  const [fetchedSample, selectedSampleLoadingState, refetchSample] =
    useFetchSample(sampleId);

  const selectedSample = useMemo(() => {
    if (fetchedSample?.id && streams?.length) {
      const selectedSampleWithStreamDetail = {
        ...fetchedSample,
        stream: find(streams, { id: fetchedSample.stream_id }),
      };

      return selectedSampleWithStreamDetail;
    } else {
      return null;
    }
  }, [fetchedSample, streams]);

  const sampleDetailBreadcrumbs = useMemo(() => {
    const newBreadcrumbs = getBreadcrumbsObject(breadcrumbs);

    if (selectedSample?.stream?.name) {
      newBreadcrumbs[newBreadcrumbs.length - 1].title =
        selectedSample?.stream?.name;
    }

    return newBreadcrumbs;
  }, [breadcrumbs, selectedSample]);

  const [showSubmitAnalysisModal, setShowSubmitAnalysisModal] = useState(false);

  const toggleAnalysisModal = () => {
    setShowSubmitAnalysisModal(!showSubmitAnalysisModal);
  };

  const actionRow = haveWritePermission ? (
    <Button
      key="validate-sample-button"
      variant="primary"
      onClick={() => toggleAnalysisModal()}
    >
      Update Status
    </Button>
  ) : undefined;

  useEffect(() => {
    ensureStreamListIsFetched();
  }, []);

  return (
    <Page
      isLoading={!selectedSample}
      title={
        selectedSample?.stream?.name
          ? selectedSample?.stream?.name
          : "Sample Detail"
      }
      breadcrumbs={sampleDetailBreadcrumbs}
      actionRow={actionRow}
    >
      <SampleDetailSummary
        fetchedSample={fetchedSample}
        streams={streams}
        selectedSampleLoadingState={selectedSampleLoadingState}
      />

      <SampleComparisonPanel selectedSample={selectedSample} />

      <TestsPanel
        tests={fetchedSample?.tests}
        selectedSample={selectedSample}
        refetchSample={refetchSample}
      />

      <DownloadableReportPanel
        fetchedSample={fetchedSample}
        selectedSampleLoadingState={selectedSampleLoadingState}
      />

      <NotesPanel
        selectedSample={fetchedSample}
        refetchSample={refetchSample}
      />

      {showSubmitAnalysisModal && (
        <SubmitAnalysisModal
          show={showSubmitAnalysisModal}
          selectedSample={fetchedSample}
          updatedSelectedSample={refetchSample}
          onHide={toggleAnalysisModal}
        />
      )}
    </Page>
  );
};

SampleDetail.propTypes = {
  haveWritePermission: PropTypes.bool.isRequired,
  streams: PropTypes.array.isRequired,
  ensureStreamListIsFetched: PropTypes.func.isRequired,
  breadcrumbs: PropTypes.array.isRequired,
  match: PropTypes.object.isRequired,
};

const SampleDetailContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SampleDetail);

export default SampleDetailContainer;
