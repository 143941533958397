import "core-js/stable";
import "moment-timezone";
import "react-hot-loader";
import "regenerator-runtime/runtime";
import "url-search-params-polyfill";

import React from "react";
import ReactDOM from "react-dom";

import "@validereinc/common-components/dist/main.css";
import "react-vis/dist/style.css";
import "./index.css";

import App from "#src/app";
import config from "#src/config";
import { ExceptionUtils } from "#src/utils/exception";
import { initialize, setupRestAPIWorker } from "@validereinc/domain";
import { configureFormatters } from "@validereinc/utilities";
import packageInfo from "../package.json";

ExceptionUtils.setup({
  dsn: config.SENTRY_DSN,
  environment:
    config.DEPLOYMENT_ENV === "preview"
      ? `preview-${config.ENV}`
      : config.ENV ?? "development",
  version: config.VERSION
    ? config.VERSION
    : `${packageInfo.name}@${packageInfo.version}`,
  isDebuggingEnabled: false,
});

configureFormatters({
  locale: config.locale,
});
ExceptionUtils.registerLifecycleEvent({
  category: "app",
  level: "info",
  type: "info",
  message: "Initialized @validereinc/utilities module",
});

if (window.location.hostname === "validerelabservices.com") {
  window.location.hostname = "validere360.com";
}

// initialize the domain package
initialize({
  publicNodeURL: `${config.NODE_API_URL!}/public`,
  nodeURL: config.NODE_API_URL!,
  elixirURL: config.API_URL!,
  dataEngURL: config.DATA_ENG_API_URL!,
  alertingURL: config.ALERTING_API_URL!,
  insightsURL: config.INSIGHTS_API_URL!,
  dataPlatformSourceURL: config.DATA_PLATFORM_SOURCE_API_URL!,
  dataPlatformAdminURL: config.DATA_PLATFORM_ADMIN_API_URL!,
});
ExceptionUtils.registerLifecycleEvent({
  category: "app",
  level: "info",
  type: "info",
  message: "Initialized @validereinc/domain module",
});

if (process.env.NODE_ENV === "development" && process.env.IS_MSW_ENABLED) {
  // development
  const worker = setupRestAPIWorker();

  worker.start();
}

ReactDOM.render(<App />, document.getElementById("root"));
ExceptionUtils.registerLifecycleEvent({
  category: "app",
  level: "info",
  type: "info",
  message: "Mounted single page application",
});
