export function Minimum(array) {
  if (array && array.length > 0) {
    return Math.min(...array);
  } else {
    return null;
  }
}

export function Maximum(array) {
  if (array && array.length > 0) {
    return Math.max(...array);
  } else {
    return null;
  }
}

export function Average(array) {
  if (array && array.length > 0) {
    const initalValue = 0;
    return (
      array.reduce(
        (sumTotal, currentValue) => sumTotal + currentValue,
        initalValue
      ) / array.length
    );
  } else {
    return null;
  }
}

export function StandardDeviation(array) {
  if (array && array.length > 0) {
    const average = Average(array);

    const totalSquareDifferences = array.map((value) => {
      const difference = value - average;
      const squareDifference = difference * difference;

      return squareDifference;
    });

    const averageSquareDifference = Average(totalSquareDifferences);
    const standardDeviation = Math.sqrt(averageSquareDifference);

    return standardDeviation;
  } else {
    return null;
  }
}

function parseNumber(number) {
  if (typeof number !== "number" && typeof number !== "string") {
    return undefined;
  }

  const numberValue = parseFloat(number);

  if (isNaN(numberValue)) {
    return undefined;
  }

  return numberValue;
}

/**
 * Given 2 number or number-like strings `current` and `original`, returns
 * the difference between them (current - original) or the errorValue
 *
 * It is best to use this with toFixed, as floating point subtraction problems
 * are not covered by this function (e.g. difference(0.3, 0.1) = 0.1999999...)
 */
export function getDifference(current, original, errorValue = "-") {
  const currentValue = parseNumber(current);
  const originalValue = parseNumber(original);

  if (currentValue === undefined || originalValue === undefined) {
    return errorValue;
  }

  return currentValue - originalValue;
}

export function getPercentChange(difference, original, errorValue = "-") {
  const differenceValue = parseNumber(difference);
  const originalValue = parseNumber(original);

  // difference can be 0, original cannot (division by 0 error)
  if (differenceValue === undefined || !originalValue) {
    return errorValue;
  }

  return (differenceValue / Math.abs(originalValue)) * 100;
}
