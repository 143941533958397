export const linkToFormSchemaList = () => {
  return `/app/forms/templates`;
};

export const FORM_SCHEMA_LIST_BREADCRUMB = {
  title: "Templates",
  path: linkToFormSchemaList,
};

export * from "./FormSchemaListPage";
