import ReactLoader from "#components/LoadingBar/ReactLoader";
import { Modal, Title } from "@validereinc/common-components";
import moment from "moment";
import * as PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import useQueryParams from "../../../../Routers/useQueryParams";
import { fetchAdjustmentFactors } from "../../../Redux/actions/adjustmentFactors";
import { havePermission } from "../../../Redux/reducers/permissions";
import RecordOfVolumeService from "../../../Services/RecordOfVolumeService";
import VolumeEntryModal from "../VolumeEntry/VolumeEntryModal";
import DailyVolumeChart from "./DailyVolumeChart";
import { formatDailyVolumeDate } from "./DailyVolumeHelper";
import "./DailyVolumeModal.scss";
import DailyVolumeTable from "./DailyVolumeTable";
import InventoryDailyVolumeTable from "./InventoryDailyVolumeTable";

const DAILY_VOLUME_TABLE_HEIGHT = 540;

const FIRST_DAY_OF_CURRENT_MONTH = moment().startOf("month").startOf("day");

const LAST_DAY_OF_CURRENT_MONTH = moment().endOf("month").startOf("day");

const DEFAULT_DATE_RANGE = {
  from: formatDailyVolumeDate(FIRST_DAY_OF_CURRENT_MONTH),
  to: formatDailyVolumeDate(LAST_DAY_OF_CURRENT_MONTH),
};

const mapStateToProps = ({ permissions, adjustmentFactors }) => {
  return {
    haveWritePermission: havePermission(permissions)(
      "gas:accounting.records_of_volume",
      "write"
    ),
    adjustmentFactors: adjustmentFactors?.data.toJS() ?? [],
  };
};

const mapDispatchToProps = {
  fetchAdjustmentFactors,
};

const useFetchDailyVolume = (streamId, from, to) => {
  const [volumes, setVolumes] = useState([]);
  const [loadingState, setLoadingState] = useState("loading");
  const [lastUpdatedAt, setLastUpdatedAt] = useState(new Date());

  useEffect(() => {
    if (streamId && from && to) {
      setLoadingState("loading");

      RecordOfVolumeService.getRecordOfVolumes(streamId, from, to)
        .then(({ data }) => {
          setVolumes(data);
          setLoadingState("loaded");
        })
        .catch(() => {
          setLoadingState("error");
        });
    }
  }, [streamId, from, to, lastUpdatedAt]);

  const refetchDailyVolume = () => {
    setLastUpdatedAt(new Date());
  };

  return [loadingState, volumes, refetchDailyVolume];
};

const DailyVolumeModal = (props) => {
  const [modalState, setModalState] = useState("closed");
  const [shouldRefetch, setShouldRefetch] = useState(false);

  const [queryParams, setQueryParams] = useQueryParams(DEFAULT_DATE_RANGE);

  const [volumeLoadingState, volumes, refetchDailyVolume] = useFetchDailyVolume(
    props.stream?.id,
    queryParams.from,
    queryParams.to
  );

  useEffect(() => {
    props.fetchAdjustmentFactors();
  }, []);

  useEffect(() => {
    if (props.from && props.until) {
      setQueryParams({
        from: formatDailyVolumeDate(props.from),
        to: formatDailyVolumeDate(props.until),
      });
    }
  }, [props.from, props.until]);

  const onVolumeEntryModalClose = () => {
    setModalState("closed");

    if (shouldRefetch) {
      refetchDailyVolume();

      // If this component is opened in the RoQ page (only way it has this prop),
      // refresh the accounting period as well (for RoQ to reflect volume changes)
      if (props.refreshAccountingPeriod) {
        props.refreshAccountingPeriod();
      }

      setShouldRefetch(false);
    }
  };

  const productType = props.stream?.product_type ?? null;

  const title = productType
    ? `${productType} Volume Overview`
    : `Volume Overview`;

  return (
    <>
      <Modal
        open={props.show}
        onClose={props.onModalClose}
        className="dailyVolumeModal"
      >
        <Modal.Header>
          <Title>{props.stream?.name ?? "Stream not found"}</Title>
        </Modal.Header>

        <Modal.Body className="dailyVolumeModal__body">
          <div className="dailyVolume">
            <ReactLoader
              loaded={volumeLoadingState !== "loading"}
              position={50}
            >
              <div className="dailyVolumeModal__content">
                <Title type="subheader">{title}</Title>

                <DailyVolumeChart
                  streamId={props.stream?.id}
                  from={queryParams.from}
                  to={queryParams.to}
                  setQueryParams={setQueryParams}
                  productType={props.stream?.product_type}
                />

                <Title type="subheader">Daily Detail</Title>

                {props.stream?.associated_with_tank ? (
                  <InventoryDailyVolumeTable
                    title="Daily Detail"
                    haveWritePermission={props.haveWritePermission}
                    adjustmentFactors={props.adjustmentFactors}
                    dateRange={queryParams}
                    volumes={volumes}
                    height={DAILY_VOLUME_TABLE_HEIGHT}
                    setModalState={setModalState}
                  />
                ) : (
                  <DailyVolumeTable
                    haveWritePermission={props.haveWritePermission}
                    streamId={props.stream?.id}
                    adjustmentFactors={props.adjustmentFactors}
                    dateRange={queryParams}
                    volumes={volumes}
                    height={DAILY_VOLUME_TABLE_HEIGHT}
                    setModalState={setModalState}
                  />
                )}
              </div>
            </ReactLoader>
          </div>
        </Modal.Body>
      </Modal>

      {modalState !== "closed" && (
        <VolumeEntryModal
          show
          onHide={onVolumeEntryModalClose}
          streamId={props.stream?.id}
          action={modalState}
          setShouldRefetchDailyVolume={setShouldRefetch}
          streamAdjustmentConfigs={props.streamAdjustmentConfigs}
          isInventoryStream={props.stream?.associated_with_tank}
        />
      )}
    </>
  );
};

DailyVolumeModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onModalClose: PropTypes.func.isRequired,
  ensureStreamListIsFetched: PropTypes.func,
  fetchAdjustmentFactors: PropTypes.func,
  haveWritePermission: PropTypes.bool,
  adjustmentFactors: PropTypes.array,
  streams: PropTypes.array,
  stream: PropTypes.object,
  from: PropTypes.string,
  until: PropTypes.string,
  streamAdjustmentConfigs: PropTypes.array,
  refreshAccountingPeriod: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(DailyVolumeModal);
