import { useGetOneNetwork } from "#hooks/adapters/useNetworks";
import { useParams } from "#routers/hooks";
import { NetworkDetailPageParameters } from "#routes/organization/networks/[networkId]/detail";
import { ReportingGroupFilterSwitcher } from "#src/batteries-included-components";
import { DEFAULT_DATE_RANGES } from "#src/hooks/useDateRange";
import {
  DateSelectorInput,
  FilterPanel,
  StorageKeys,
} from "@validereinc/common-components";
import React from "react";

export type NetworkCalculationFilterType = {
  yearMonth: { from: string; to: string };
  reportingGroup: string;
};

export const NetworkCalculationFilterPanel = ({
  filterConfigStorageKey,
}: Pick<StorageKeys, "filterConfigStorageKey">) => {
  const { networkId } = useParams<NetworkDetailPageParameters>();
  const { data: assetGroup } = useGetOneNetwork(networkId);

  const defaultValue = {
    yearMonth: DEFAULT_DATE_RANGES.currentWholeMonth,
    reportingGroup: assetGroup?.default_network_reporting_group?.id,
  };

  return (
    <FilterPanel
      filters={[
        {
          component: (
            <ReportingGroupFilterSwitcher
              key="reportingGroup"
              name="reportingGroup"
              options={
                assetGroup?.default_network_reporting_group
                  ? [assetGroup.default_network_reporting_group]
                  : []
              }
              shouldSelectFirstOptionAsDefault
            />
          ),
        },
        {
          component: (
            <DateSelectorInput
              key="yearMonth"
              name="yearMonth"
              variant="month"
              isInline
            />
          ),
        },
      ]}
      storageKey={filterConfigStorageKey}
      defaultValue={defaultValue}
    />
  );
};
