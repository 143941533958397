import { Panel, StorageKeys } from "@validereinc/common-components";
import React from "react";
import { EmissionsHomeMetricTileXYChart } from "./EmissionsHomeMetricTileXYChart";

export const TotalEmissionsPanel = ({
  filterConfigStorageKey,
}: Pick<StorageKeys, "filterConfigStorageKey">) => {
  return (
    <Panel
      title="Total Emissions"
      style={{ marginBottom: 0 }}
    >
      <EmissionsHomeMetricTileXYChart
        filterConfigStorageKey={filterConfigStorageKey}
      />
    </Panel>
  );
};
