import React from "react";
import * as PropTypes from "prop-types";
import FontAwesome from "react-fontawesome";

const ActivityCell = (props) => (
  <div className="activityCell">
    <div className="activityCell__icon">
      <FontAwesome
        className="fa-fw"
        name={props.style.icon}
        style={{ color: props.style.color }}
      />
    </div>
    <div className="activityCell__description">{props.description}</div>
  </div>
);

ActivityCell.propTypes = {
  style: PropTypes.object,
  description: PropTypes.string,
};

export default ActivityCell;
