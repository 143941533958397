export * from "./EquipmentRecordValueDetailPage";

export const EQUIPMENT_RECORD_VALUE_DETAIL_PAGE_TITLE = "Record Value Details";

export const linkToEquipmentRecordValueDetail = (
  equipmentId = ":equipmentId",
  recordId = ":recordId",
  measurementType = ":measurementType"
) =>
  `/app/organization/equipment/${equipmentId}/record-details/${recordId}/value/${measurementType}`;

export const EQUIPMENT_RECORD_VALUE_DETAIL_BREADCRUMB = {
  title: `${EQUIPMENT_RECORD_VALUE_DETAIL_PAGE_TITLE}:equipmentId:recordId:measurementType`,
  path: linkToEquipmentRecordValueDetail,
};

export type EquipmentRecordValuePageParamsType = {
  equipmentId: string;
  recordId: string;
  measurementType: string;
};
