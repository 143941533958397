import {
  CreateOneRequestType,
  fetchAndCollate,
  ResourceServiceType,
  ResponseErrorType,
  UpdateOneRequestType,
} from "../../util";
import { restAPI } from "../";

export const SavedFilterStatus = {
  ACTIVE: "active",
  DRAFT: "draft",
} as const;

export type SavedFilterStatusType =
  (typeof SavedFilterStatus)[keyof typeof SavedFilterStatus];

export type SavedFilterType<TFilterType> = {
  id: string;
  name: string;
  status: SavedFilterStatusType;
  filter: TFilterType;
  group_by?: string[];
  metadata?: unknown[];
  created_by: string;
  created_at: string;
  updated_by: string;
  updated_at: string;
};

export type SavedFilterServiceType<TFilterType> = Pick<
  ResourceServiceType<SavedFilterType<TFilterType>>,
  "getList" | "updateOne" | "createOne" | "deleteOne"
>;

export const SavedFiltersDomain = <TFilterType>({
  endpoint,
}: {
  endpoint: string;
}): SavedFilterServiceType<TFilterType> => ({
  getList: ({
    sortBy,
    sortDirection,
    page,
    pageSize,
    filters: { name, status, createdBy, tag } = {},
  }) =>
    fetchAndCollate<SavedFilterType<TFilterType>>(
      ({ page, pageSize }) =>
        restAPI.nodeAPI.GET({
          endpoint,
          query: {
            page,
            page_size: pageSize,
            ...(sortBy ? { sort_by: sortBy } : {}),
            ...(sortDirection ? { sort_direction: sortDirection } : {}),
            ...(status ? { status } : {}),
            ...(tag ? { tag } : {}),
            ...(name ? { name } : {}),
            ...(createdBy ? { created_by: createdBy } : {}),
          },
        }),
      page,
      pageSize
    ),
  createOne: ({ data }: CreateOneRequestType<SavedFilterType<TFilterType>>) =>
    restAPI.nodeAPI
      .POST<SavedFilterType<TFilterType>>({
        endpoint,
        body: data,
      })
      .then((resp) => ({ data: resp })),
  updateOne: ({
    id,
    data,
  }: UpdateOneRequestType<SavedFilterType<TFilterType>>) =>
    restAPI.nodeAPI
      .PUT<SavedFilterType<TFilterType>>({
        endpoint: [endpoint, id].join("/"),
        body: data,
      })
      .then((resp) => ({ data: resp })),
  deleteOne: ({ id }) =>
    restAPI.nodeAPI
      .DELETE<SavedFilterType<TFilterType>>({
        endpoint: [endpoint, id].join("/"),
      })
      .then(() => ({ status: 200 }))
      .catch((resp: ResponseErrorType) => resp),
});
