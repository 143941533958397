import {
  WorkflowTemplateStatus,
  type WorkflowTemplateType,
  type WorkflowTriggerFormCreateType,
  type WorkflowTriggerScheduledSchemaType,
} from "@validereinc/domain";
import formatDuration from "date-fns/formatDuration";

export const WorkflowTemplateStatusToPillVariant: Record<
  WorkflowTemplateType["status"],
  string
> = {
  [WorkflowTemplateStatus.ACTIVE]: "success",
  [WorkflowTemplateStatus.DRAFT]: "info",
  [WorkflowTemplateStatus.ARCHIVE]: "default",
};

export function isScheduledTrigger(
  trigger: WorkflowTriggerFormCreateType | WorkflowTriggerScheduledSchemaType
): trigger is WorkflowTriggerScheduledSchemaType {
  return Boolean(
    trigger &&
      Object?.hasOwn(trigger, "cron") &&
      Object?.hasOwn(trigger, "type") &&
      trigger.type === "scheduled"
  );
}

export function isFormTrigger(
  trigger: WorkflowTriggerFormCreateType | WorkflowTriggerScheduledSchemaType
): trigger is WorkflowTriggerFormCreateType {
  return Boolean(trigger && Object.hasOwn(trigger, "form_schema_id"));
}

export const getTrigger = (
  trigger: WorkflowTriggerFormCreateType | WorkflowTriggerScheduledSchemaType
) => {
  if (isScheduledTrigger(trigger)) {
    return "Defined Schedule";
  }

  if (isFormTrigger(trigger)) {
    return "Form Submission";
  }

  return "-";
};

export const getFormTriggerDetails = (
  trigger?: WorkflowTriggerFormCreateType | WorkflowTriggerScheduledSchemaType
) => {
  if (!trigger || !isFormTrigger(trigger)) {
    return;
  }

  return {
    formSchemaId: trigger.form_schema_id,
    formCategoryId: trigger.form_category_id,
  };
};

export const getDurationForDisplay = (
  duration: number,
  period: WorkflowTriggerScheduledSchemaType["due_date"]["time_period"]
) => {
  const durationFormatted = formatDuration({
    [period]: duration,
  });

  return `${durationFormatted} from creation`;
};
