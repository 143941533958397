import React, { useState } from "react";
import * as PropTypes from "prop-types";
import SettingSection from "../../SettingSection";
import "./Sites.scss";
import SiteTable from "./SiteTable";
import { connect } from "react-redux";
import { fetchSiteList } from "../../../Redux/actions/index";
import SiteSettingModal from "./SiteSettingModal";

const mapStateToProps = (state) => {
  return {
    sites: state.sites?.data?.toJS() ?? [],
  };
};

const mapDispatchToProps = {
  fetchSiteList,
};

const Sites = (props) => {
  const [selectedSite, setSelectedSite] = useState(null);

  const onOpenSiteSetting = (selectedSite) => {
    setSelectedSite(selectedSite);
  };

  const onCloseSiteSetting = () => {
    setSelectedSite(null);
  };

  const updateSiteList = () => {
    props.fetchSiteList();
  };

  return (
    <SettingSection title="Site Settings">
      <SiteTable
        height={props.height}
        sites={props.sites}
        onOpenSiteSetting={onOpenSiteSetting}
      />

      {selectedSite && (
        <SiteSettingModal
          selectedSite={selectedSite}
          show={true}
          updateSiteList={updateSiteList}
          onCloseSiteSetting={onCloseSiteSetting}
        />
      )}
    </SettingSection>
  );
};

Sites.propTypes = {
  height: PropTypes.number.isRequired,
  sites: PropTypes.array.isRequired,
  fetchSiteList: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(Sites);
