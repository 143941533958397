import {
  apiRequestServicesPromise,
  apiPutServicesPromise,
} from "./ServiceHelper";
import { REFERENCE_TEMPERATURE_IN_CELSIUS_PROPERTIES } from "../Settings/Company/Sites/SiteConstant";

const SiteService = {
  getSiteList() {
    return apiRequestServicesPromise("/api/sites");
  },

  updateSiteSetting(inputs) {
    const params = {
      reference_temperatures: {},
      reference_temperature_unit: "deg. C",
    };

    REFERENCE_TEMPERATURE_IN_CELSIUS_PROPERTIES.map((property) => {
      const inputProperty =
        inputs.reference_temperatures[property]?.value ?? null;

      if (inputProperty) {
        params.reference_temperatures[property] =
          inputs.reference_temperatures[property].value;
      }
    });

    return apiPutServicesPromise(`/api/sites/${inputs.id}`, params);
  },
};

export default SiteService;
