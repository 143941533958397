import React from "react";
import moment from "moment";
import { getNumberColorBySign } from "../../../utils/styleCalculator";
import { getDollarValue } from "../../../utils/stringFormatter";
import { METHOD_OPTIONS } from "./RecordOfQualityConstants";
import isEqual from "lodash/isEqual";
import config from "#src/config";
import { AssertIsBeforeOrEqualDate } from "../../../utils/assert";
import { getUTCTimeString } from "#utils/timeFormatter";

export function getComponentShortForm(component, componentVolumeConfig) {
  try {
    // this regex will return any string between "(" and ")"
    // ex) Methane (C1) -> C1
    const componentShortForm = component.match(/\((.*?)\)/)?.[1] ?? component;
    const volumeType = componentVolumeConfig?.[component]?.volume_type ?? "";

    if (
      volumeType === "liquid_equivalent_gpa" ||
      volumeType === "liquid_equivalent_gef"
    ) {
      return `${componentShortForm}(L)`;
    }

    return componentShortForm;
  } catch {
    return "-";
  }
}

export function getGasEquivalentFactorTooltip(gasEquivalentFactor) {
  gasEquivalentFactor = gasEquivalentFactor?.value ?? "Unavailable";

  return (
    <div>
      <b>Gas Equivalent Factor (GEF):</b> {`${gasEquivalentFactor}`}
    </div>
  );
}

export function getMethodString(method) {
  return METHOD_OPTIONS.find((m) => m.id === method)?.name ?? "-";
}

export function getStatus(state) {
  switch (state) {
    case "approved":
    case "validere_approved":
      return <div className="recordOfQuality__status approved">Approved</div>;
    case "approval_required":
      return <div className="recordOfQuality__status">Approval Required</div>;
    case "error":
      return <div className="recordOfQuality__status error">Error</div>;
    default:
      return "-";
  }
}

export function formatDate(date) {
  return getUTCTimeString(date, config.LONG_DATEMONTH_FORMAT);
}

export const getEstimatedValue = (estimatedValue) => {
  if (estimatedValue) {
    return (
      <span
        style={{
          color: getNumberColorBySign(estimatedValue.value),
        }}
        className="estimatedValue"
      >
        {getDollarValue(estimatedValue.value, estimatedValue.currency)}
      </span>
    );
  } else {
    return "-";
  }
};

const formatFetchedIntervals = (fetchedIntervals) => {
  return fetchedIntervals
    .map((interval) => ({
      from: interval.from,
      until: interval.until,
      method: interval.method,
      sampleIds: interval.sample_ids,
      previousAccountingRecordId: interval.previous_accounting_record_id,
    }))
    .sort((a, b) => moment(a.from) - moment(b.from));
};

export const isCurrentSelectionEqualToInitial = (
  currentSelection,
  initialSelection
) => {
  const { intervalSelections } = currentSelection;
  const { selection_intervals } = initialSelection;

  if (intervalSelections.length !== selection_intervals.length) {
    return false;
  }

  const fetchedIntervals = formatFetchedIntervals(selection_intervals);

  return isEqual(intervalSelections, fetchedIntervals);
};

export const getSampleSelectionCaption = (interval) => {
  const from = getUTCTimeString(interval.from, config.DATE_FORMAT);
  const until = getUTCTimeString(interval.until, config.DATE_FORMAT);

  return `${from} - ${until}`;
};

// Given a sample that is retrieved from useFetchSelectionData, is the given
// sample actually a sample or just an aggregate
export const isSampleData = (sample) => {
  return (
    sample.type !== "record_of_quality" &&
    sample.type !== "volume_weighted_inline"
  );
};

// Given a sample and a selection interval, is the sample selected for that interval
export const isSelected = (sample, interval) => {
  const { method, previousAccountingRecordId, sampleIds } = interval;

  switch (method) {
    case "volume_weighted_average":
      return (
        previousAccountingRecordId === sample.id ||
        sampleIds?.includes(sample.id)
      );
    case "volume_weighted_inline":
      return sample.type === "volume_weighted_inline";
    case "previous_accounting_record":
      return previousAccountingRecordId === sample.id;
    default:
      return sampleIds?.includes(sample.id);
  }
};

// Methods whose sample/roq selections can be changed via the UI
export const isAdjustableMethod = (method) =>
  METHOD_OPTIONS.filter((m) => !m.defaultOnly).some((m) => m.id == method);

const toggleIdInSampleArray = (sampleList, id) => {
  return sampleList.includes(id)
    ? sampleList.filter((sampleId) => sampleId !== id)
    : [...sampleList, id].sort();
};

export const updateItemInArray = (array, itemIndex, updateFunction) => {
  return array.map((item, index) => {
    if (index !== itemIndex) {
      return item;
    }

    return updateFunction(item);
  });
};

// Given useFetchRoQDetail selection_intervals, format the data to be used
// in useRoQSelection
export const formatIntervalSelections = (selection_intervals) => {
  return selection_intervals
    .map((interval) => ({
      from: interval.from,
      until: interval.until,
      sampleIds: interval.sample_ids.sort(),
      previousAccountingRecordId:
        interval.previous_accounting_record_id ?? null,
      method: interval.method,
    }))
    .sort((a, b) => moment(a.from) - moment(b.from));
};

export const clearIntervalSelections = (intervalSelections) => {
  return intervalSelections.map((interval) => ({
    ...interval,
    sampleIds: [],
    previousAccountingRecordId: null,
  }));
};

export const updateIntervalSelectionOnToggle = (
  intervalSelection,
  { id, type }
) => {
  switch (intervalSelection.method) {
    case "volume_weighted_average": {
      if (type === "record_of_quality") {
        return {
          ...intervalSelection,
          previousAccountingRecordId:
            intervalSelection.previousAccountingRecordId !== id ? id : null,
        };
      } else {
        return {
          ...intervalSelection,
          sampleIds: toggleIdInSampleArray(intervalSelection.sampleIds, id),
        };
      }
    }
    case "average_of_selected_samples":
      return {
        ...intervalSelection,
        sampleIds: toggleIdInSampleArray(intervalSelection.sampleIds, id),
      };
    default:
      return { ...intervalSelection, sampleIds: [id] };
  }
};

const updateSelectionProperties = (method, preSelectedValues) => {
  switch (method) {
    case "previous_accounting_record": {
      const id = preSelectedValues?.previousAccountingRecord?.id ?? null;

      return {
        previousAccountingRecordId: id,
        sampleIds: [],
      };
    }
    case "earliest_sample": {
      const id = preSelectedValues?.earliestSample?.id ?? null;

      return {
        previousAccountingRecordId: null,
        sampleIds: id ? [id] : [],
      };
    }
    case "latest_sample": {
      const id = preSelectedValues?.latestSample?.id ?? null;

      return {
        previousAccountingRecordId: null,
        sampleIds: id ? [id] : [],
      };
    }
    default:
      return {
        previousAccountingRecordId: null,
        sampleIds: [],
      };
  }
};

export const updateIntervalSelectionsOnMethodChange = (state, value) => {
  const { intervalSelections, preSelectedValues } = state;
  const { id: method, updateIndex } = value;

  return updateItemInArray(intervalSelections, updateIndex, (interval) => ({
    ...interval,
    ...updateSelectionProperties(method, preSelectedValues),
    method,
  }));
};

export const updateIntervalSelectionsFromPreSelectedValues = (
  intervalSelections,
  preselectedValues
) => {
  return intervalSelections.map((interval) => {
    const { sampleIds, previousAccountingRecordId, method } = interval;

    // first load, already has information, don't override
    if (previousAccountingRecordId || sampleIds.length) {
      return interval;
    }

    // selection data cleared e.g. on streamChange
    return {
      ...interval,
      ...updateSelectionProperties(method, preselectedValues),
    };
  });
};

const isValidSampleForInterval = (sample, interval) => {
  if (interval.method !== "volume_weighted_average") {
    return true;
  }

  if (AssertIsBeforeOrEqualDate(sample.from, interval.until, "day")) {
    return true;
  }
  return false;
};

/**
 * When changing interval end date, the samples selected for the method
 * `volume_weighted_average` need to be checked if they're valid for the new interval.
 * If they're not, they can be moved to the next interval if it is a newly
 * created interval (null method) or it also has the method volume_weighted_average.
 */
export const updateSelectionPropertiesOnIntervalChange = (
  intervalSelections,
  updateIndex,
  selectionData
) => {
  const currentInterval = intervalSelections[updateIndex];

  if (currentInterval.method !== "volume_weighted_average") {
    return intervalSelections;
  }

  const invalidSampleIds = [];

  const validSampleIds = currentInterval.sampleIds.filter((sampleId) => {
    const sample = selectionData.find((data) => data.id === sampleId);

    if (!isValidSampleForInterval(sample, currentInterval)) {
      invalidSampleIds.push(sampleId);
      return false;
    }
    return true;
  });
  currentInterval.sampleIds = validSampleIds;

  const nextInterval = intervalSelections[updateIndex + 1];

  if (
    nextInterval.method === null ||
    nextInterval.method === "volume_weighted_average"
  ) {
    nextInterval.sampleIds = [...nextInterval.sampleIds, ...invalidSampleIds];
    nextInterval.method = "volume_weighted_average";
  }

  return intervalSelections;
};
