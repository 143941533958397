import groupBy from "lodash/groupBy";
import config from "#src/config";
import moment from "moment";
import { AssertIsBeforeOrEqualDate } from "#utils/assert";
import { getPropertyAsMap } from "#utils/objectFormatter";
import find from "lodash/find";
import { getTimeStringFromDate } from "#utils/timeFormatter";

export function sortByNotes(list) {
  return [...list].sort((a, b) => {
    const aValue = a.note_count ?? -1;
    const bValue = b.note_count ?? -1;

    return aValue - bValue;
  });
}

export function sortByVolume(list, columnKey) {
  return [...list].sort((a, b) => {
    const aValue = a?.[columnKey]?.value ?? -1;
    const bValue = b?.[columnKey]?.value ?? -1;

    return aValue - bValue;
  });
}

/**
 * Given a dateRange from and to, creates an RoV that is filled in with volumeData
 * information from backend or a placeholder default RoV.
 */
export function getVolumeByDays(from, to, volumeData) {
  const dailyVolume = [];

  while (AssertIsBeforeOrEqualDate(from, to)) {
    const formattedFromDate = getTimeStringFromDate(from, config.DATE_FORMAT);
    const volumeDetail = find(volumeData, { date: formattedFromDate });

    if (volumeDetail) {
      dailyVolume.push({ ...volumeDetail });
    } else {
      dailyVolume.push({
        id: formattedFromDate,
        date: formattedFromDate,
        is_entry_required: true,
      });
    }

    from = moment(from).add(1, "day");
  }

  return dailyVolume;
}

/**
 * Given a dateRange from and to, creates an RoV that is filled in with volumeData
 * information from backend or a placeholder default RoV.
 *
 * Inventory RoV requires opening and closing voliume, with the current date's
 * opening volume is the yesterday's closing volume. It also requires stream
 * information such as the name and product type
 *
 * The volumeData may contain multiple streams in the same day, so grouping by day
 * is done to ensure the right closing volume is matched up to the right stream and day
 */
export function getInventoryVolumeByDays(
  from,
  to,
  volumeData,
  streams,
  streamId
) {
  const dailyVolume = [];

  const streamsMap = getPropertyAsMap(streams);

  const volumeByDaysMap = groupBy(volumeData, (data) => data.date);

  while (AssertIsBeforeOrEqualDate(from, to)) {
    const formattedFromDate = getTimeStringFromDate(from, config.DATE_FORMAT);
    const formattedYesterdayDate = getTimeStringFromDate(
      moment(from).subtract(1, "day"),
      config.DATE_FORMAT
    );

    const volumeDetails = volumeByDaysMap[formattedFromDate];
    const previousVolumeDetails = volumeByDaysMap[formattedYesterdayDate];

    if (volumeDetails) {
      volumeDetails.forEach((detail) => {
        const stream = streamsMap[detail.stream_id];

        const previousVolume = find(previousVolumeDetails, {
          stream_id: detail.stream_id,
        });

        dailyVolume.push({
          ...detail,
          product: stream?.product_type,
          streamName: stream?.name,
          opening_volume: previousVolume?.total_volume ?? null,
          closing_volume: detail.total_volume,
        });
      });
    } else {
      const previousVolume = find(previousVolumeDetails, {
        stream_id: streamId,
      });

      dailyVolume.push({
        id: formattedFromDate,
        date: formattedFromDate,
        is_entry_required: true,
        type: "inventory",
        opening_volume: previousVolume?.total_volume ?? null,
      });
    }

    from = moment(from).add(1, "day");
  }

  return dailyVolume;
}
