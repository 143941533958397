import {
  FilterPillbox,
  MultiDropdownInputWithSearch,
} from "@validereinc/common-components";
import difference from "lodash/difference";
import differenceBy from "lodash/differenceBy";
import intersectionWith from "lodash/intersectionWith";
import uniqBy from "lodash/uniqBy";
import React, { useEffect, useState } from "react";
import history from "../../../Routers/history";
import { linkToAdjustmentFactors } from "../../../Routers/links";
import useQueryParams from "../../../Routers/useQueryParams";
import "./RecordOfVolume.scss";

const DEFAULT_FILTER_DROPDOWN = {
  productTypes: { label: "Product Type", inputs: [], options: [] },
  adjustmentFactors: {
    label: "Adjustment Factor",
    inputs: [],
    options: [],
    labelKey: "name",
    paramKey: "id",
  },
};

const queryParamsConverter = (filterDropdowns) => {
  // This function will convert filterDropdown into useQueryParams format
  const queryParamInput = {};
  const queryParamOptions = {};

  Object.keys(filterDropdowns).map((key) => {
    queryParamInput[key] = filterDropdowns[key].inputs;
    queryParamOptions[key] = filterDropdowns[key].options;
  });

  return { queryParamInput, queryParamOptions };
};

const noAdjustmentFactorMatch = (adjustmentFactors, stream) => {
  return (
    intersectionWith(
      adjustmentFactors,
      stream.adjustmentFactorIds,
      (adjustmentFactor, adjustmentFactorId) =>
        adjustmentFactor.id === adjustmentFactorId
    ).length === 0
  );
};

const filterStreamList = (streamList, queryParams) => {
  const { productTypes, adjustmentFactors } = queryParams;

  const filteredStreamList = streamList.filter((stream) => {
    if (
      productTypes.length > 0 &&
      productTypes.every((type) => type !== stream.product_type)
    ) {
      return false;
    }

    if (
      adjustmentFactors.length > 0 &&
      noAdjustmentFactorMatch(adjustmentFactors, stream)
    ) {
      return false;
    }

    return true;
  });

  return filteredStreamList;
};

const useFilterInputs = (streamList, unboundedAdjustmentFactors, view) => {
  const [filterDropdowns, setFilterDropdowns] = useState({
    ...DEFAULT_FILTER_DROPDOWN,
  });

  const { queryParamInput, queryParamOptions } =
    queryParamsConverter(filterDropdowns);
  const [queryParams, setQueryParams] = useQueryParams(
    queryParamInput,
    queryParamOptions
  );

  const onDropdownSelect = (selectedValue, key) => {
    setQueryParams({ [key]: selectedValue });
  };

  const onClearFilterClick = (filterObject) => {
    const newQueryParam = { ...queryParams };

    if (filterObject) {
      const { filterKey, name } = filterObject;
      if (filterKey === "productTypes") {
        newQueryParam[filterKey] = difference(newQueryParam[filterKey], [name]);
      }

      if (filterKey === "adjustmentFactors") {
        newQueryParam[filterKey] = differenceBy(
          newQueryParam[filterKey],
          [filterObject],
          "id"
        );
      }
    } else {
      Object.keys(newQueryParam).map((key) => {
        newQueryParam[key] = [];
      });
    }

    setQueryParams({ ...newQueryParam });
  };

  const onManageWorkflowClick = () => {
    history.push(linkToAdjustmentFactors());
  };

  useEffect(() => {
    if (streamList.length > 0 || unboundedAdjustmentFactors.length > 0) {
      const productTypes = { ...filterDropdowns.productTypes };
      productTypes.options = uniqBy(streamList, "product_type").map(
        (stream) => stream.product_type
      );

      const adjustmentFactors = { ...filterDropdowns.adjustmentFactors };
      adjustmentFactors.options = unboundedAdjustmentFactors;

      setFilterDropdowns({ productTypes, adjustmentFactors });
    }
  }, [unboundedAdjustmentFactors, streamList]);

  const filterRow = (
    <div className="filterRow">
      {Object.keys(filterDropdowns).map((key) => {
        const { label, labelKey, options } = filterDropdowns[key];

        return (
          <MultiDropdownInputWithSearch
            key={key}
            label={label}
            labelKey={labelKey ? labelKey : null}
            dropdownKey={key}
            value={queryParams[key]}
            options={options}
            onChange={onDropdownSelect}
          />
        );
      })}

      {view !== "tab" && (
        <a
          className="link"
          onClick={onManageWorkflowClick}
        >
          Manage Adjustment Factors
        </a>
      )}
    </div>
  );

  const filterPillbox = (
    <FilterPillbox
      filterBy={queryParams}
      onClearFilterClick={onClearFilterClick}
    />
  );

  const filteredStreams = filterStreamList(streamList, queryParams);

  return [
    filterPillbox,
    filterRow,
    filteredStreams,
    onDropdownSelect,
    onClearFilterClick,
  ];
};

export default useFilterInputs;
