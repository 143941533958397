import React, { useState, useCallback } from "react";
import * as PropTypes from "prop-types";
import moment from "moment";
import minBy from "lodash/minBy";
import maxBy from "lodash/maxBy";
import round from "lodash/round";
import ceil from "lodash/ceil";
import sortBy from "lodash/sortBy";
import {
  XYPlot,
  HorizontalGridLines,
  VerticalGridLines,
  XAxis,
  YAxis,
  CustomSVGSeries,
  ChartLabel,
  Hint,
  WhiskerSeries,
  LineMarkSeries,
} from "react-vis";
import { AutoSizer } from "react-virtualized";
import {
  CircleSVG,
  AlarmSVG,
  SelectedSVG,
  SPOT_PROPS,
  COMPOSITE_PROPS,
  ROQ_PROPS,
  SELECTED_ROQ_PROPS,
  RecordOfQualitySampleChartLegend,
  UNKNOWN_PROPS,
  INLINE_PROPS,
  SELECTED_INLINE_PROPS,
} from "./RecordOfQualitySampleChartSVG";
import Loader from "react-loader";
import loaderOptions from "../../../../../LoadingBar/LoadingBar";
import "./RecordOfQualitySampleChart.scss";
import { getComponentShortForm } from "../../../RecordOfQualityHelper";
import { VirtualSampleSVG } from "#common/SVG/SamplesSVG";
import { useSelectionModalContext } from "../../Context/selectionModalContext";
import { getPrecision } from "../../../../../Common/Chart/LineChartHelper";
import {
  getFormattedMeasurementValue,
  getFormattedMeasurementValueWithUnit,
} from "#redux/reducers/measurements";
import { connect } from "react-redux";
import { getTimeStringFromDate } from "#utils/timeFormatter";
import config from "#src/config";

/** A mapping from API type to hint sample type */
export const SAMPLE_TYPE = {
  composite_sample: "Composite",
  spot_sample: "Spot",
  record_of_quality: "RoQ",
  inline: "Inline",
  unknown_sample: "Unknown",
  virtual_sample: "Virtual",
};
/** The latter types will appear over the former types */
const DISPLAY_PRECEDENCE = ["unknown", "default", "alarm", "selected"];

const MIN_WEEK_BUFFER = 2;

/** Not every y gridline has a label (tick) */
const Y_GRIDLINES = 20;
const Y_TICKS = 4;
const MINIMUM_RANGE = 0.1;

const mapStateToProps = (state) => {
  return {
    selectedRecordOfVolumes: state.recordOfVolume?.toJS() ?? [],
    getFormattedMeasurementValue: (measurementKey, measurementValue) =>
      getFormattedMeasurementValue(state.measurements)(
        measurementKey,
        measurementValue
      ),
    getFormattedMeasurementValueWithUnit: (measurementKey, measurementObject) =>
      getFormattedMeasurementValueWithUnit(state.measurements)(
        measurementKey,
        measurementObject
      ),
  };
};

const formatXAxis = (value) => {
  return getTimeStringFromDate(value, config.SHORT_DATEMONTH_FORMAT);
};

const getAllSelectedData = (intervalSelections) => {
  let allSampleIds = [];
  const allPreviousAccountingRecordIds = [];
  const allMethods = [];

  intervalSelections.forEach((interval) => {
    const { sampleIds, previousAccountingRecordId, method } = interval;

    if (sampleIds) {
      allSampleIds = allSampleIds.concat(sampleIds);
    }

    if (previousAccountingRecordId) {
      allPreviousAccountingRecordIds.push(previousAccountingRecordId);
    }

    if (method) {
      allMethods.push(method);
    }
  });

  return [allSampleIds, allPreviousAccountingRecordIds, allMethods];
};

const getSvgType = (point) => {
  if (point.alarms?.length) {
    return "alarm";
  } else if (point.type === "virtual_sample") {
    return "virtual";
  } else {
    return "default";
  }
};

const getSvgProps = (type) => {
  switch (type) {
    case "spot_sample":
      return SPOT_PROPS;
    case "composite_sample":
      return COMPOSITE_PROPS;
    default:
      return UNKNOWN_PROPS;
  }
};

const getSvgPrecedence = (point, sampleIds) => {
  if (sampleIds.includes(point.id)) {
    return "selected";
  }
  if (point.alarms?.length) {
    return "alarm";
  }
  if (point.type === "spot_sample" || point.type === "composite_sample") {
    return "default";
  }
  if (point.type === "virtual_sample") {
    return "virtual";
  }
  return "unknown";
};

const getSvg = (type, props, precedence) => {
  let svg = <CircleSVG {...props} />;

  if (type === "alarm") {
    svg = <AlarmSVG {...props} />;
  }

  if (type === "virtual") {
    svg = <VirtualSampleSVG />;
  }

  if (precedence === "selected") {
    svg = <SelectedSVG>{svg}</SelectedSVG>;
  }

  return svg;
};

const categorizeData = (data, intervalSelections) => {
  const roqData = [];
  let chartData = [];
  const selectedRoqData = [];
  let inlineData = [];
  let selectedInlineData = [];
  const { chart_points, inline } = data;

  const [sampleIds, previousAccountingRecordIds, methods] =
    getAllSelectedData(intervalSelections);

  if (chart_points) {
    chart_points.forEach((point) => {
      const modifiedPoint = {
        ...point,
        x: moment(point.until).valueOf(),
        y: point.value,
        value: point.value,
      };

      if (point.type === "record_of_quality") {
        const distance = moment(point.until).diff(moment(point.from));
        const roqPoint = {
          ...modifiedPoint,
          x: moment(point.from)
            .add(distance / 2)
            .valueOf(),
          xVariance: distance,
        };

        if (previousAccountingRecordIds.includes(point.id)) {
          return selectedRoqData.push(roqPoint);
        }

        return roqData.push(roqPoint);
      }

      const svgType = getSvgType(point);
      const svgProps = getSvgProps(point.type);
      const svgPrecedence = getSvgPrecedence(point, sampleIds);
      const svg = getSvg(svgType, svgProps, svgPrecedence);

      modifiedPoint.svg = svg;
      modifiedPoint.svgPrecedence = svgPrecedence;

      return chartData.push(modifiedPoint);
    });
    chartData = sortBy(chartData, (d) =>
      DISPLAY_PRECEDENCE.indexOf(d.svgPrecedence)
    );
  }

  if (inline) {
    const inlinePoints = inline
      .map((data) => ({
        ...data,
        x: moment(data.date).valueOf(),
        y: data.value,
        value: data.value,
      }))
      .sort((a, b) => moment(a.date) - moment(b.date));

    if (methods.includes("volume_weighted_inline")) {
      selectedInlineData = inlinePoints;
    } else {
      inlineData = inlinePoints;
    }
  }

  return [chartData, roqData, selectedRoqData, inlineData, selectedInlineData];
};

/** Calculates min and max values, as well as putting gridlines and ticks on
 *  the start of every month. A buffer of at least MIN_WEEK_BUFFER is placed
 *  on the smallest and biggest values to ensure it's not flush with edge
 */
const calculateXDomain = ({ chart_points = [], inline = [] }) => {
  const xTickValues = [];
  const xGridlineValues = [];
  let minX;
  let maxX;

  const data = [...chart_points, ...inline];
  if (data?.length) {
    const minData = minBy(data, (d) => d.from ?? d.date);
    const maxData = maxBy(data, (d) => d.until ?? d.date);
    minX = moment(minData.from ?? minData.date)
      .subtract(MIN_WEEK_BUFFER, "weeks")
      .valueOf();
    maxX = moment(maxData.until ?? maxData.date)
      .add(MIN_WEEK_BUFFER, "weeks")
      .valueOf();

    // Don't want ticks to go over
    const firstTick = moment(minX).startOf("month").add(1, "month").valueOf();

    const tick = moment(firstTick);
    while (tick.isBefore(maxX)) {
      xTickValues.push(tick.valueOf());
      xGridlineValues.push(tick.valueOf());
      tick.add(1, "month");
    }
  }

  return [minX ?? 0, maxX ?? 0, xTickValues, xGridlineValues];
};

/** Calculates min and max values, as well as a set amount of gridlines and ticks
 *  specified by Y_GRIDLINES and Y_TICKS.
 */
const calculateYDomain = ({ chart_points = [], inline = [] }) => {
  const yTickValues = [];
  const yGridlineValues = [];
  let minY;
  let maxY;

  const data = [...chart_points, ...inline];
  if (data?.length) {
    const maxData = maxBy(data, (d) => d.value);
    if (maxData.value === 0) {
      maxData.value = MINIMUM_RANGE;
    }
    const precision = getPrecision(maxData.value / Y_GRIDLINES);
    maxY = ceil(maxData.value / Y_GRIDLINES, precision) * Y_GRIDLINES;

    const step = maxY / Y_GRIDLINES;
    for (let i = 0; i <= Y_GRIDLINES; i++) {
      if (i % (Y_GRIDLINES / Y_TICKS) === 0) {
        yTickValues.push(round(step * i, precision + 1));
      }
      yGridlineValues.push(round(step * i, precision + 1));
    }
  }
  return [minY ?? 0, maxY ?? 0, yTickValues, yGridlineValues];
};

export const CustomHint = ({
  point,
  measurementKey,
  getFormattedMeasurementValueWithUnit,
}) => {
  const from = getTimeStringFromDate(point.from, config.DATE_FORMAT);
  const until = getTimeStringFromDate(point.until, config.DATE_FORMAT);
  const date = getTimeStringFromDate(point.date, config.DATE_FORMAT);
  const value = getFormattedMeasurementValueWithUnit(measurementKey, {
    value: point.value,
    unit: point.unit,
  });
  const type = SAMPLE_TYPE[point?.type];

  return (
    <div className="rv-hint__content">
      <section className="rv-hint__details">
        <h6 className="rv-hint__sectionTitle">Details</h6>

        {point.type === "inline" ? (
          <CustomHintRow
            title={"Date"}
            value={date}
          />
        ) : (
          <>
            <CustomHintRow
              title={"Start Date"}
              value={from}
            />
            <CustomHintRow
              title={"End Date"}
              value={until}
            />
          </>
        )}
        <CustomHintRow
          title={"Value"}
          value={value}
        />
        <CustomHintRow
          title={"Type"}
          value={type}
        />

        {point.metadata &&
          Object.entries(point.metadata).map(([key, valueString]) => {
            if (!valueString) {
              return;
            }

            if (key.includes("Temperature")) {
              valueString = valueString.replace(/deg. /i, "°");
            }

            const [value, unit] = valueString.split(" ");

            return (
              <CustomHintRow
                key={key}
                title={key}
                value={getFormattedMeasurementValueWithUnit(key, {
                  value,
                  unit,
                })}
              />
            );
          })}
      </section>

      {point.alarms?.length > 0 && (
        <section className="rv-hint__alarms">
          <h6 className="rv-hint__sectionTitle">Alarms</h6>

          <ul>
            {point.alarms.map((alarm, index) => (
              <li key={index}>{alarm}</li>
            ))}
          </ul>
        </section>
      )}
    </div>
  );
};
CustomHint.propTypes = {
  point: PropTypes.object,
  measurementKey: PropTypes.string,
  getFormattedMeasurementValueWithUnit: PropTypes.func,
};

const CustomHintRow = ({ title, value }) => (
  <section className="rv-hint__row">
    <span className="rv-hint__title">{title}</span>
    <span className="rv-hint__value">{value}</span>
  </section>
);
CustomHintRow.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string,
};

// MAIN COMPONENT
export const RecordOfQualitySampleChart = (props) => {
  const [hintData, setHintData] = useState(null);

  const { sampleData, sampleDataLoadingState, measurement, roqSelectionState } =
    useSelectionModalContext();

  const { intervalSelections } = roqSelectionState;

  const [chartData, roqData, selectedRoqData, inlineData, selectedInlineData] =
    useCallback(categorizeData(sampleData, intervalSelections), [
      sampleData,
      intervalSelections,
    ]);

  const [minX, maxX, xTickValues, xGridlineValues] = useCallback(
    calculateXDomain(sampleData),
    [sampleData]
  );

  const [minY, maxY, yTickValues, yGridlineValues] = useCallback(
    calculateYDomain(sampleData),
    [sampleData]
  );

  const unit =
    sampleData.chart_points?.[0]?.unit ?? sampleData.inline?.[0]?.unit;

  const yLabel = unit ? `${getComponentShortForm(measurement)} (${unit})` : "";
  // The position of the legend as a single CustomSVGSeries data point
  const yStep = yGridlineValues.length > 0 ? yGridlineValues[1] : 0;
  const legendPosition = { x: maxX, y: maxY + yStep };

  const renderSVG = (data) => {
    const { svg, state } = data;

    const isClickable = props.onSampleClick && state === "validated";
    return (
      <g
        className={isClickable ? "recordOfQualitySampleChart__clickable" : ""}
        onClick={isClickable ? () => props.onSampleClick(data) : null}
      >
        {svg}
      </g>
    );
  };

  const sharedProps = {
    onValueMouseOver: (value) => setHintData(value),
    onValueMouseOut: () => setHintData(null),
  };

  const hasData = sampleData.chart_points?.length || sampleData.inline?.length;

  return (
    <div className="recordOfQualitySampleChart">
      <Loader
        loaded={sampleDataLoadingState !== "loading"}
        options={{ ...loaderOptions, top: "50%" }}
      >
        {sampleDataLoadingState === "loaded" && hasData ? (
          <div className="recordOfQualitySampleChart__container">
            <AutoSizer>
              {({ width, height }) => (
                <XYPlot
                  height={height}
                  width={width}
                  margin={{ left: 70, right: 15, top: 20, bottom: 15 }}
                  xDomain={[minX, maxX]}
                  yDomain={[minY, maxY]}
                  onMouseLeave={() => setHintData(null)}
                >
                  <VerticalGridLines
                    className="recordOfQualitySampleChart__verticalGridLine"
                    tickValues={xGridlineValues}
                  />
                  <HorizontalGridLines
                    tickValues={yGridlineValues}
                    className="recordOfQualitySampleChart__horizontalGridLine"
                  />

                  <XAxis
                    tickFormat={formatXAxis}
                    tickPadding={0}
                    tickValues={xTickValues}
                    // matches other outlined gridlines
                    style={{ line: { stroke: "#999" } }}
                  />
                  <YAxis
                    tickFormat={(data) => data}
                    tickPadding={0}
                    tickValues={yTickValues}
                    style={{ line: { stroke: "none" } }}
                  />

                  <ChartLabel
                    text={yLabel}
                    className="recordOfQualitySampleChart__labelTitle"
                    xPercent={0.03}
                    yPercent={0.25}
                    style={{
                      transform: "rotate(-90)",
                      style: {
                        textAnchor: "middle",
                      },
                    }}
                  />

                  {/* The chart legend */}
                  <CustomSVGSeries
                    data={[legendPosition]}
                    customComponent={() => (
                      <RecordOfQualitySampleChartLegend
                        justifyRight
                        position={{ x: -10 }} // account for the right margin
                      />
                    )}
                  />

                  {/* The Volume Weighted Inline Data  */}
                  <LineMarkSeries
                    data={inlineData}
                    {...INLINE_PROPS}
                    {...sharedProps}
                  />

                  {/* The RoQ data  */}
                  <WhiskerSeries
                    data={roqData}
                    {...ROQ_PROPS}
                    {...sharedProps}
                  />

                  {/* The selected RoQ for the method "Previous RoQ" */}
                  <WhiskerSeries
                    data={selectedRoqData}
                    {...SELECTED_ROQ_PROPS}
                    {...sharedProps}
                  />

                  {/*  The sample data (spot, composite, etc.) */}
                  <CustomSVGSeries
                    data={chartData}
                    customComponent={renderSVG}
                    {...sharedProps}
                  />

                  {/* The Volume Weighted Inline Data when the method
                   "Volume Weighted Inline" is selected */}
                  <LineMarkSeries
                    data={selectedInlineData}
                    {...SELECTED_INLINE_PROPS}
                    {...sharedProps}
                  />

                  {hintData ? (
                    <Hint value={hintData}>
                      <CustomHint
                        point={hintData}
                        measurementKey={measurement}
                        getFormattedMeasurementValueWithUnit={
                          props.getFormattedMeasurementValueWithUnit
                        }
                      />
                    </Hint>
                  ) : null}
                </XYPlot>
              )}
            </AutoSizer>
          </div>
        ) : (
          <div className="recordOfQualitySampleChart__noData">
            No data available
          </div>
        )}
      </Loader>
    </div>
  );
};
RecordOfQualitySampleChart.propTypes = {
  onSampleClick: PropTypes.func,
  getFormattedMeasurementValue: PropTypes.func,
  getFormattedMeasurementValueWithUnit: PropTypes.func,
};

export default connect(mapStateToProps)(RecordOfQualitySampleChart);
