import {
  WorkflowTemplatesFilterPanel,
  WorkflowTemplatesTablePanel,
} from "#src/batteries-included-components";
import { useStorageKey } from "#src/hooks/useStorageKey";
import { useBreadcrumbsFromRoute } from "#src/utils/route";
import { Page } from "@validereinc/common-components";
import React from "react";
import { WorkflowTemplatesRoutePath } from ".";

export const WorkflowTemplatesPage = () => {
  const storageKeys = useStorageKey("workflows-templates");
  const [breadcrumbs] = useBreadcrumbsFromRoute(WorkflowTemplatesRoutePath);

  return (
    <Page
      title={WorkflowTemplatesRoutePath.title}
      category={WorkflowTemplatesRoutePath.subtitle}
      breadcrumbs={breadcrumbs}
    >
      <WorkflowTemplatesFilterPanel
        showCategoryFilter={true}
        filterConfigStorageKey={storageKeys.filterConfigStorageKey}
      />
      <WorkflowTemplatesTablePanel {...storageKeys} />
    </Page>
  );
};
