import { SetCleanHistoryPath } from "#src/Routers/historyHelper";
import { useParams } from "#src/Routers/hooks";
import useLocalization from "#src/hooks/useLocalization";
import { BreadcrumbType } from "@validereinc/common-components";
import { useMemo } from "react";
import { generatePath, matchPath } from "react-router";

export type RoutePathParamKeys<T extends string = string> = Record<
  T,
  string | number
>;
export type RouteQueryParamKeys<T extends string = string> = Partial<
  Record<T, string>
>;

interface RoutePathInterface<
  TPathParamKeys extends string = string,
  TQueryParamKeys extends string = string,
  TPrevRoutePath extends RoutePath = any, // no way around any type annotation here afaik due to recursive typing limitations
> {
  /** the path including the current instance's set path and the set path of any
   * and all RoutePath's concatenated to the current one */
  path: string;
  /** the path set when this RoutePath was defined */
  pathSelf: string;
  /** the previous connected RoutePath, if any. if none and next is set, this RoutePath is the first one. */
  previous?: TPrevRoutePath;
  /** does this RoutePath have no previous connection? likely means this is the
   * first of a series of connected RoutePath's or just by itself with no connections? */
  isFirstOrDangling: boolean;
  /** the title to describe what's rendered at this route */
  title: string;
  /** the title to describe the feature this RoutePath falls under. equal to the
   * title if this RoutePath is dangling or first in a series of connected
   * RoutePath's and not hidden. If it's not first in a series, then it'll be
   * the title of the earliest RoutePath in the connection that is not hidden.
   * */
  subtitle: string;
  /** is this route not actually a navigable / rendered route? */
  isPresentational?: boolean;
  /** is this route hidden from view? */
  isHidden?: boolean;
  /** the path parameters to fill in the route's path. @see React Router v5 docs for how this works. */
  pathParams?: RoutePathParamKeys<TPathParamKeys>;
  /** the query parameters to add to the route's link @see web-native URLSearchParams for how this works. */
  queryParams?: RouteQueryParamKeys<TQueryParamKeys>;
  /** get all the segments of the route's path. A segment is a string split by a / slash. */
  getSegments: () => string[];
  /** get this RoutePath and all previous RoutePath's connected to this one, in
   * a list. Note, typing is lost unlike traversing by accessing "previous"
   * manually. */
  getConcatenatedRoutePaths: () => RoutePath[];
  /** check if a given path matches against the path of this instance */
  getMatch: (
    pathToCheck: string,
    { exact, strict }: { exact: boolean; strict: boolean }
  ) => ReturnType<typeof matchPath<Record<TPathParamKeys, string>>>;
  /** get the route as a breadcrumb object */
  toBreadcrumb: ({
    title,
    pathParams,
    queryParams,
  }: {
    title?: string;
    pathParams?: RoutePathParamKeys<TPathParamKeys>;
    queryParams?: RouteQueryParamKeys<TQueryParamKeys>;
  }) => BreadcrumbType;
  /** get the route as a link */
  toLink: ({
    pathParams,
    queryParams,
  }: {
    pathParams?: RoutePathParamKeys<TPathParamKeys>;
    queryParams?: RouteQueryParamKeys<TQueryParamKeys>;
  }) => string;
  /** get the route as a link object - all the parts of the link split up */
  toLinkParts: ({
    pathParams,
    queryParams,
  }: {
    pathParams?: RoutePathParamKeys<TPathParamKeys>;
    queryParams?: RouteQueryParamKeys<TQueryParamKeys>;
  }) => {
    pathname: string;
    query?: Partial<Record<TQueryParamKeys, string>>;
    search?: string;
  };
  /** connect this RoutePath with another RoutePath, after this */
  concat: <T extends string, K extends string>(
    route: RoutePath<T, K>
  ) => RoutePath<
    T extends string
      ? string extends T
        ? TPathParamKeys
        : TPathParamKeys extends string
          ? string extends TPathParamKeys
            ? T
            : T | TPathParamKeys
          : T
      : never,
    K extends string
      ? string extends K
        ? TQueryParamKeys
        : TQueryParamKeys extends string
          ? string extends TQueryParamKeys
            ? K
            : K | TQueryParamKeys
          : K
      : never,
    RoutePath<TPathParamKeys, TQueryParamKeys, TPrevRoutePath>
  >;
}

export class RoutePath<
  TPathParamKeys extends string = string,
  TQueryParamKeys extends string = string,
  TPrevRoutePath extends RoutePath = any, // no way around any type annotation here afaik due to recursive typing limitations
> implements
    RoutePathInterface<TPathParamKeys, TQueryParamKeys, TPrevRoutePath>
{
  #pathCached: string;
  readonly pathSelf: string;
  readonly title: string;
  readonly isPresentational: boolean = false;
  readonly isHidden: boolean = false;
  pathParams?: RoutePathParamKeys<TPathParamKeys>;
  queryParams?: RouteQueryParamKeys<TQueryParamKeys>;
  #previous?: TPrevRoutePath;

  constructor({
    path,
    previous,
    title,
    isPresentational = false,
    isHidden = false,
    pathParams,
    queryParams,
  }: {
    /** a string path that represents a route */
    path: RoutePathInterface<TPathParamKeys, TQueryParamKeys>["path"];
    /** the previous path segment if any */
    previous?: TPrevRoutePath;
    /** what does this path represent? */
    title: RoutePathInterface<TPathParamKeys, TQueryParamKeys>["title"];
    /** does this route not link to an actual page? */
    isPresentational?: RoutePathInterface<
      TPathParamKeys,
      TQueryParamKeys
    >["isPresentational"];
    /** should this route be hidden? */
    isHidden?: RoutePathInterface<TPathParamKeys, TQueryParamKeys>["isHidden"];
    /** the dynamic path parameters this route takes */
    pathParams?: RoutePathInterface<
      TPathParamKeys,
      TQueryParamKeys
    >["pathParams"];
    /** the query parameters this route can have */
    queryParams?: RoutePathInterface<
      TPathParamKeys,
      TQueryParamKeys
    >["queryParams"];
  }) {
    this.pathSelf = path.split("?")[0];
    this.title = title ?? "";
    this.isPresentational = isPresentational;
    this.isHidden = isHidden;
    this.pathParams = pathParams;
    this.queryParams = queryParams;

    if (previous) {
      this.previous = previous;
    }

    this.#pathCached = String(this.path);
  }

  private getPathWithParams(params?: RoutePathParamKeys<TPathParamKeys>) {
    return generatePath(this.path, params ?? this.pathParams);
  }

  private calculatePath() {
    if (this.#previous?.path) {
      // disregard the previous path if the current path is matched against it -
      // it's already covered!
      const prevPath = this.#previous.getMatch(this.pathSelf)
        ? ""
        : this.#previous.path;

      // join the previous path and the current set path together, remove duplicate slashes, and remove any trailing slashes
      return [prevPath, this.pathSelf]
        .join("/")
        .replace(new RegExp("/{1,}", "g"), "/")
        .replace(new RegExp("/{1,}$"), "");
    }

    return this.pathSelf.replace(new RegExp("/{1,}$"), "");
  }

  get path(): string {
    // don't calculate the path everytime - rely on the cache
    if (this.#pathCached) {
      return this.#pathCached;
    }

    const calculatedPath = this.calculatePath();

    if (!this.#pathCached) {
      this.#pathCached = calculatedPath;
    }

    return calculatedPath;
  }

  set previous(value) {
    this.#previous = value;
  }
  get previous() {
    return this.#previous;
  }

  get isFirstOrDangling() {
    return Boolean(!this.previous);
  }

  get subtitle(): string {
    if (this.previous?.isFirstOrDangling && this.previous.isHidden) {
      return this.title;
    }

    return this.previous ? this.previous.subtitle : this.title;
  }

  getMatch(
    pathToCheck: string,
    { exact, strict }: { exact: boolean; strict: boolean } = {
      exact: false,
      strict: false,
    }
  ) {
    return matchPath<Record<TPathParamKeys, string>>(pathToCheck, {
      path: this.path ?? "",
      exact,
      strict,
    });
  }

  getSegments() {
    return this.path.split("/").filter((s) => s !== "");
  }

  /**
   * Get this route and all previous routes that have
   * been concatenated to this one, in a list
   * @returns an array of RoutePath instances. Specific typing is lost. Traverse
   * ".previous" manually to get around that.
   */
  getConcatenatedRoutePaths() {
    const routes: RoutePath[] = [];

    if (this.previous) {
      routes.push(...this.previous.getConcatenatedRoutePaths());
    }

    routes.push(this);

    return routes;
  }

  toLink({
    pathParams,
    queryParams,
  }: {
    pathParams?: RoutePathParamKeys<TPathParamKeys>;
    queryParams?: RouteQueryParamKeys<TQueryParamKeys>;
  } = {}) {
    const query = new URLSearchParams(
      (queryParams ?? this.queryParams) as Record<string, string>
    );
    const pathWithParams = this.getPathWithParams(
      pathParams ?? this.pathParams
    );
    const isFirstCharASlash = pathWithParams.startsWith("/");

    return `${isFirstCharASlash ? "" : "/"}${pathWithParams}${
      Array.from(query.keys()).length ? `?${query.toString()}` : ""
    }`;
  }

  toLinkParts({
    pathParams,
    queryParams,
    replace = false,
  }: {
    pathParams?: RoutePathParamKeys<TPathParamKeys>;
    queryParams?: RouteQueryParamKeys<TQueryParamKeys>;
    replace?: boolean;
  } = {}) {
    const link = this.toLink({ pathParams, queryParams });
    const query = new URLSearchParams(
      (queryParams ?? this.queryParams) as Record<string, string>
    );

    return {
      replace,
      pathname: link.split("?")[0],
      query: queryParams ?? this.queryParams,
      ...(Array.from(query.keys()).length
        ? { search: `?${query.toString()}` }
        : {}),
    };
  }

  toBreadcrumb({
    title,
    ...restProps
  }: {
    title?: string;
    pathParams?: RoutePathParamKeys<TPathParamKeys>;
    queryParams?: RouteQueryParamKeys<TQueryParamKeys>;
  } = {}): BreadcrumbType {
    return {
      title: title ?? this.title,
      ...(this.isPresentational
        ? {}
        : {
            href: this.toLink(restProps),
            onClick: () => SetCleanHistoryPath(this.toLink(restProps)),
          }),
    };
  }

  clone() {
    return new RoutePath<TPathParamKeys, TQueryParamKeys, TPrevRoutePath>({
      path: this.pathSelf,
      previous: this.previous,
      title: this.title,
      isPresentational: this.isPresentational,
      isHidden: this.isHidden,
      pathParams: this.pathParams,
      queryParams: this.queryParams,
    });
  }

  /**
   * Connects the current RoutePath with another RoutePath, intentionally after
   * the current one.
   * @returns the provided RoutePath with the current RoutePath set in the
   * previous property. Thus, this can be chained infinitely.
   */
  concat<T extends string, K extends string>(route: RoutePath<T, K>) {
    const thisRoute = this.clone();

    route.previous = thisRoute;
    thisRoute.#pathCached = "";
    route.#pathCached = "";

    /**
     * - if inferred T or K doesn't extend string, disregard as never
     * - if inferred T does extend string, then check if T is in-fact primitive string (not a string literal union)
     *   - if so, use current instance's TPathParamKeys as inferred T is redundant
     *   - if not, check if TPathParamKeys extends string correctly
     *     - if so, then check if TPathParamKeys is in-fact primitive string (not a string literal union)
     *        - if so, use inferred T as TPathParamKeys is redundant
     *        - if not, union T and TPathParamKeys because both are string literal unions
     *     - if not, use inferred T as TPathParamKeys is invalid
     */
    return route as RoutePath<
      T extends string
        ? string extends T
          ? TPathParamKeys
          : TPathParamKeys extends string
            ? string extends TPathParamKeys
              ? T
              : T | TPathParamKeys
            : T
        : never,
      K extends string
        ? string extends K
          ? TQueryParamKeys
          : TQueryParamKeys extends string
            ? string extends TQueryParamKeys
              ? K
              : K | TQueryParamKeys
            : K
        : never,
      RoutePath<TPathParamKeys, TQueryParamKeys, TPrevRoutePath>
    >;
  }
}

/**
 * Get the path params filled-in, dynamic data filled-in, localized, and
 * memoized breadcrumbs, given a set of routes. Titles for breadcrumbs can be
 * customized. Path params are drawn from useParams() by default but can be
 * overriden. Query params can also be overriden.
 *
 * @example useBreadcrumbsFromRoutes([[new RoutePath({ title: "Feature A", path: "/app/a" }), {}]]);
 * @example
 * useBreadcrumbsFromRoutes([
 *   [new RoutePath({ title: "Feature A", path: "/app/a" }), {}],
 *   [new RoutePath<"id", "tab">({ title: "Feature A: Detail", path: "/app/a/:id/detail" }), {
 *     title: "Item 1234",
 *     pathParams: { id: "1234" },
 *     queryParams: { tab: "summary" }
 *   }],
 * ]);
 *
 * @param routes an array of arrays. each entry is a tuple: a RoutePath instance
 * and corresponding arguments for the asBreadcrumb method on the instance
 * @param isReady are the breadcrumbs ready to be generated? Useful when the path params or query params are filled in from asynchronous tasks
 * @returns a 2 item tuple. first is the array of breadcrumbs. second is the
 * loading state boolean.
 */
export const useBreadcrumbsFromRoutes = <T extends RoutePath = RoutePath>(
  routes: Array<[T, props: Parameters<T["toBreadcrumb"]>[0]]>,
  isReady = true
): [BreadcrumbType[], boolean] => {
  const { localize, isLoading } = useLocalization();
  const pathParams = useParams();
  const breadcrumbsMemoized = useMemo<BreadcrumbType[]>(
    () =>
      !isReady
        ? []
        : routes
            ?.filter(([route]) => !route.isHidden)
            .map(([route, props]) =>
              route.toBreadcrumb({
                title: props?.title ?? localize(route.title),
                pathParams: props?.pathParams ?? pathParams,
                queryParams: props?.queryParams,
              })
            ) ?? [],
    [routes, pathParams, localize, isReady]
  );

  return [breadcrumbsMemoized, isLoading || !isReady];
};

/**
 * Get the path params filled-in, dynamic data filled-in, localized, and
 * memoized breadcrumbs, given a set of routes. Titles for breadcrumbs can be
 * customized. Path params are drawn from useParams() by default but can be
 * overriden. Query params can also be overriden.
 *
 * @example useBreadcrumbsFromRoute(new RoutePath({ title: "Feature A", path: "/app/a" }));
 * @example
 * useBreadcrumbsFromRoute(
 *   new RoutePath({ title: "Feature A", path: "/app/a" })
 *    .concat(
 *      new RoutePath<"id", "tab">({ title: "Feature A: Detail", path: "/app/a/:id/detail" })
 *    ),
 *   {
 *     "a": {
 *       title: "Feature A Override",
 *     },
 *     "detail": {
 *       title: "Item 1234",
 *       pathParams: { id: "1234" },
 *       queryParams: { tab: "summary" }
 *     }
 *   }
 *  );
 *
 * @param route the route to get the breadcrumbs for
 * @param props a record of strings to function parameters for toBreadcrumb where each key is the last segment
 * @param isReady the breadcrumbs ready to be generated? Useful when the path params or query params are filled in from asynchronous tasks
 * @returns a 2 item tuple. first is the array of breadcrumbs. second is the
 * loading state boolean.
 */
export const useBreadcrumbsFromRoute = <T extends RoutePath = RoutePath>(
  route: T,
  props?: Record<string, Parameters<T["toBreadcrumb"]>[0]>,
  isReady = true
): [BreadcrumbType[], boolean] => {
  const { localize, isLoading } = useLocalization();
  const pathParams = useParams();

  const breadcrumbsMemoized = useMemo<BreadcrumbType[]>(
    () =>
      !isReady
        ? []
        : route
            .getConcatenatedRoutePaths()
            .filter((route) => !route.isHidden)
            .map((route) => {
              const matchingProps = Object.entries(props ?? {}).find(
                ([endOfPath]) => route.path.endsWith(endOfPath)
              );

              return route.toBreadcrumb({
                title: matchingProps?.[1]?.title ?? localize(route.title),
                pathParams: matchingProps?.[1]?.pathParams ?? pathParams,
                queryParams: matchingProps?.[1]?.queryParams,
              });
            }) ?? [],
    [route, props, localize, pathParams, isReady]
  );

  return [breadcrumbsMemoized, isLoading || !isReady];
};
