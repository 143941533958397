import React, { useMemo } from "react";
import * as PropTypes from "prop-types";
import { getAbsoluteValue } from "#utils/stringFormatter";
import {
  getGasProductTypeProperties,
  getCondyProductTypeProperties,
  getMeasurementString,
} from "./StreamDetailHelper";
import {
  NGL_PRODUCT_TYPE_PROPERTIES,
  CRUDE_PRODUCT_TYPE_PROPERTIES,
} from "../StreamsConstants";
import classNames from "classnames/bind";
import styles from "./Qualities.module.scss";

const cx = classNames.bind(styles);

const DECIMAL = 2;

const MeasurementRow = ({ title, quality, volume }) => {
  return (
    <dl className={cx("datalist-item")}>
      <dt className={cx("term")}>{title}</dt>
      {quality || volume ? (
        <dt className={cx("data")}>
          {quality?.value ? (
            <div>
              {getMeasurementString(quality, DECIMAL)}
              <br />
            </div>
          ) : null}

          {volume?.value ? getMeasurementString(volume, DECIMAL) : null}
        </dt>
      ) : (
        "-"
      )}
    </dl>
  );
};

const Qualities = ({ recordDetail }) => {
  const displayProperties = useMemo(() => {
    switch (recordDetail?.product_type) {
      case "Gas":
      case "Natural Gas":
        return getGasProductTypeProperties();
      case "NGL":
        return NGL_PRODUCT_TYPE_PROPERTIES;
      case "C5+":
      case "Condensate":
        return getCondyProductTypeProperties();
      case "CRD":
      case "Liquid":
        return CRUDE_PRODUCT_TYPE_PROPERTIES;
      default: {
        return CRUDE_PRODUCT_TYPE_PROPERTIES;
      }
    }
  }, [recordDetail]);

  const qualityMeasurements = useMemo(() => {
    if (recordDetail?.qualities) {
      return recordDetail.qualities.reduce(
        (obj, item) => ((obj[item.name] = item), obj),
        {}
      );
    }

    return {};
  }, [recordDetail]);

  const volumeMeasurements = useMemo(() => {
    if (recordDetail?.volumes) {
      return recordDetail.volumes.reduce(
        (obj, item) => ((obj[item.name] = item), obj),
        {}
      );
    }

    return {};
  }, [recordDetail]);

  return (
    <section className={cx("container")}>
      <div className={cx("volume-container")}>
        <div>
          <p className={cx("volume")}>
            {getAbsoluteValue(recordDetail?.measured_volume?.value)}{" "}
            <sup className={cx("supertext")}>
              {recordDetail?.measured_volume?.unit}
            </sup>
          </p>
          <p className={cx("subtext")}>Measured Volume</p>
        </div>
      </div>

      <dl className={cx("datalist")}>
        {displayProperties.map((property) => {
          if (property === "Energy") {
            return (
              <MeasurementRow
                key="Energy"
                title="Energy"
                quality={recordDetail?.energy}
              />
            );
          }

          if (property === "Measured Volume") {
            return (
              <MeasurementRow
                key={property}
                title={property}
                volume={recordDetail.measured_volume}
              />
            );
          }

          return (
            <MeasurementRow
              key={property}
              title={property}
              quality={qualityMeasurements[property]}
              volume={volumeMeasurements[property]}
            />
          );
        })}
      </dl>
    </section>
  );
};

Qualities.propTypes = {
  recordDetail: PropTypes.object.isRequired,
};

MeasurementRow.propTypes = {
  title: PropTypes.string.isRequired,
  quality: PropTypes.object,
  volume: PropTypes.object,
};

export default Qualities;
