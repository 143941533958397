import {
  CUSTOM_REPORT_CONFIGURATION_SETTINGS,
  EQUIPMENT_CUSTOM_REPORT_GROUP_BY,
  FLOW_CUSTOM_REPORT_GROUP_BY,
  apiKeyToFilterKey,
  parseCustomReportConfiguration,
} from "#batteries-included-components/Layouts/CustomReport/CustomReportConfigurationPanel/CustomReportConfigurationPanel.helpers";
import { useListCalculatorResultSavedFilters } from "#hooks/adapters/useCalculatorResults";
import { useListRecordSavedFilters } from "#hooks/adapters/useRecords";
import { CALCULATOR_FILTERS } from "#hooks/filters/useCalculatorFilters";
import { EQUIPMENT_FILTERS } from "#hooks/filters/useEquipmentFilters";
import { FLOW_FILTERS } from "#hooks/filters/useFlowFilters";
import { useMeasurementTypes } from "#src/contexts/MeasurementTypeContext";
import { getIntervalInMonthsFormatted, hydrateDateRange } from "#utils/date";
import {
  AssetType,
  FilterObjectType,
  GetListCalculatorResultsFiltersType,
  RecordFilterType,
  SavedFilterType,
} from "@validereinc/domain";
import { toFlattenedObject, yearMonthParser } from "@validereinc/utilities";
import uniq from "lodash/uniq";

const formatStatusFilter = (status: string | string[]) =>
  Array.isArray(status) ? status : { $exact: status };

export const CustomReportVariants = {
  EMISSIONS: "emissions",
  VOLUMETRIC: "volumetric",
};

export type CustomReportVariantType =
  (typeof CustomReportVariants)[keyof typeof CustomReportVariants];

export const useCustomReportUtils = (variant: CustomReportVariantType) => {
  const { measurementTypes } = useMeasurementTypes();

  const reportUtils = {
    [CustomReportVariants.EMISSIONS]: {
      measurementTypeFilter: (measurementTypeId: string) =>
        measurementTypes.find(({ id }) => id === measurementTypeId)
          ?.quantity !== "volume",
    },
    [CustomReportVariants.VOLUMETRIC]: {
      measurementTypeFilter: (measurementTypeId: string) =>
        measurementTypes.find(({ id }) => id === measurementTypeId)
          ?.quantity === "volume",
    },
  };
  return reportUtils[variant];
};

/** FilterPanel + ConfigurationPanel => API Filters */
export const getCustomReportFilters = (
  variant: CustomReportVariantType,
  config: Record<string, unknown>,
  filters: Record<string, unknown>
) => {
  const { reportingGroup, dateRange, outputs } =
    parseCustomReportConfiguration(config);

  const {
    [EQUIPMENT_FILTERS.equipmentName.name]: equipmentId,
    [EQUIPMENT_FILTERS.equipmentType.name]: equipmentTypeId,
    [EQUIPMENT_FILTERS.equipmentStatus.name]: equipmentStatus,
    [EQUIPMENT_FILTERS.facilityName.name]: facilityId,
    [EQUIPMENT_FILTERS.facilityStatus.name]: facilityStatus,
    [CALCULATOR_FILTERS.estimationMethod.name]: estimationMethod,
    [CALCULATOR_FILTERS.calculator.name]: calculator,
    [FLOW_FILTERS.flowType.name]: flowTypeId,
    [FLOW_FILTERS.flowStatus.name]: flowStatus,
    [FLOW_FILTERS.flowProductType.name]: flowProductType,
    [FLOW_FILTERS.flowProductCategory.name]: flowProductCategory,
    [FLOW_FILTERS.flowAssociatedFacility.name]: flowAssociatedFacility,
    [FLOW_FILTERS.flowAssociatedEquipment.name]: flowAssociatedEquipment,
    ...customAttributeFilters
  } = filters;

  const emissionsFilters = {
    ...(reportingGroup ? { reporting_group: { $exact: reportingGroup } } : {}),
    ...(equipmentTypeId ? { ["equipment.type.id"]: equipmentTypeId } : {}),
    ...(equipmentStatus
      ? {
          ["equipment.status"]: formatStatusFilter(equipmentStatus),
        }
      : {}),
    ...(facilityStatus
      ? { ["equipment.facility.status"]: formatStatusFilter(facilityStatus) }
      : {}),
    ...(facilityId ? { ["equipment.facility.id"]: facilityId } : {}),
    ...(equipmentId ? { ["equipment.id"]: equipmentId } : {}),
    ...(estimationMethod
      ? { ["estimation_method.id"]: formatStatusFilter(estimationMethod) }
      : {}),
    ...(calculator
      ? {
          ["estimation_method.analytics_calculator_id"]:
            formatStatusFilter(calculator),
        }
      : {}),
  };

  const volumetricFilters = {
    asset_type: { $exact: AssetType.FLOW },
    ...(reportingGroup
      ? { reporting_group_id: { $exact: reportingGroup } }
      : {}),
    ...(flowTypeId ? { ["flow.type"]: flowTypeId } : {}),
    ...(flowStatus ? { ["flow.status"]: formatStatusFilter(flowStatus) } : {}),
    ...(flowProductType ? { ["flow.product_type"]: flowProductType } : {}),
    ...(flowProductCategory
      ? { ["flow.product_category"]: flowProductCategory }
      : {}),
    ...(flowAssociatedFacility
      ? { ["flow.associated_facility_id"]: flowAssociatedFacility }
      : {}),
    ...(flowAssociatedEquipment
      ? { ["flow.associated_equipment_id"]: flowAssociatedEquipment }
      : {}),
  };

  return {
    ...(outputs ? { measurement_type: outputs } : {}),
    ...(dateRange?.from && dateRange?.to
      ? {
          year_month: getIntervalInMonthsFormatted(hydrateDateRange(dateRange)),
        }
      : {}),
    ...(variant === CustomReportVariants.EMISSIONS ? emissionsFilters : {}),
    ...(variant === CustomReportVariants.VOLUMETRIC ? volumetricFilters : {}),
    ...toFlattenedObject(customAttributeFilters),
  };
};

export const getRowsFromGroupBy = (
  variant: CustomReportVariantType,
  groupBy: string[] = []
) => {
  let groupByMap: Record<string, string[]> = {};
  switch (variant) {
    case CustomReportVariants.EMISSIONS:
      groupByMap = EQUIPMENT_CUSTOM_REPORT_GROUP_BY;
      break;
    case CustomReportVariants.VOLUMETRIC:
      groupByMap = FLOW_CUSTOM_REPORT_GROUP_BY;
      break;
    default:
  }
  const rows: string[] = [];
  let remainingGroupBy = [...groupBy];
  while (remainingGroupBy.length) {
    if (remainingGroupBy[0]?.includes("custom_attributes")) {
      rows.push(remainingGroupBy.shift()!);
    } else if (
      !Object.entries(groupByMap).some(([row, rowGroupBy]) => {
        const targetEntries = remainingGroupBy.slice(0, rowGroupBy.length);
        if (rowGroupBy.join(",") === targetEntries.join(",")) {
          rows.push(row);
          remainingGroupBy = remainingGroupBy.slice(rowGroupBy.length);
          return true;
        }
        return false;
      })
    ) {
      remainingGroupBy = remainingGroupBy.slice(1);
    }
  }
  return rows;
};

/** Get the FilterPanel value from a set of API filters */
const reverseFilter = (filter: FilterObjectType<unknown>) => {
  return Object.fromEntries(
    Object.entries(filter).map(([key, value]) => {
      const mappedKey = apiKeyToFilterKey[key] ?? key;
      if (value?.$in) {
        return [mappedKey, value.$in];
      }
      if (value?.$like) {
        return [mappedKey, value.$like];
      }
      return [mappedKey, value];
    })
  );
};

export const getCustomReportGroupBy = (
  variant: CustomReportVariantType,
  rows: string[] = [],
  /** if this is true, we only return the "display" groupBy item */
  isExport = false
) => {
  let groupByMap: Record<string, string[]> = {};
  switch (variant) {
    case CustomReportVariants.EMISSIONS:
      groupByMap = EQUIPMENT_CUSTOM_REPORT_GROUP_BY;
      break;
    case CustomReportVariants.VOLUMETRIC:
      groupByMap = FLOW_CUSTOM_REPORT_GROUP_BY;
      break;
    default:
  }
  return uniq(
    rows.flatMap((row) => {
      if (!isExport) {
        return groupByMap[row] ?? [row];
      }
      const groupColumn = groupByMap[row]?.[groupByMap[row].length - 1];
      return [groupColumn ?? row];
    })
  );
};

/** Get the ConfigurationPanel value from a saved filter */
export const getConfigFromSavedFilter = (
  variant: CustomReportVariantType,
  savedFilter:
    | SavedFilterType<FilterObjectType<GetListCalculatorResultsFiltersType>>
    | SavedFilterType<FilterObjectType<RecordFilterType>>
) => {
  if (!savedFilter?.filter) {
    return {};
  }
  const {
    filter: {
      reporting_group_id,
      reporting_group,
      year_month,
      measurement_type,
      ...filterInputs
    },
    group_by,
  } = savedFilter;

  const sanitizedReportingGroup = reverseFilter({
    reporting_group,
    reporting_group_id,
  });

  const reportingGroupId =
    sanitizedReportingGroup.reporting_group_id ??
    sanitizedReportingGroup.reporting_group;

  return {
    config: {
      [CUSTOM_REPORT_CONFIGURATION_SETTINGS.reportingScenario.name]:
        reportingGroupId,
      [CUSTOM_REPORT_CONFIGURATION_SETTINGS.dateRange.name]: year_month?.$in
        ? {
            from: yearMonthParser(year_month.$in[0]),
            to: yearMonthParser(year_month.$in[year_month.$in.length - 1]),
          }
        : undefined,
      [CUSTOM_REPORT_CONFIGURATION_SETTINGS.rows.name]: getRowsFromGroupBy(
        variant,
        group_by ?? []
      ),
      [CUSTOM_REPORT_CONFIGURATION_SETTINGS.outputs.name]:
        measurement_type?.$in ?? [],
    },
    filter: reverseFilter(filterInputs),
  };
};

export const useSavedCustomReport = (
  reportVariant: CustomReportVariantType,
  reportId?: string
) => {
  const resultFiltersQuery = useListCalculatorResultSavedFilters(
    {},
    {
      enabled: reportVariant === CustomReportVariants.EMISSIONS,
    }
  );

  const recordFiltersQuery = useListRecordSavedFilters(
    {},
    {
      enabled: reportVariant === CustomReportVariants.VOLUMETRIC,
    }
  );

  let savedFilter;

  switch (reportVariant) {
    case CustomReportVariants.EMISSIONS:
      savedFilter = resultFiltersQuery.data?.data?.find(
        ({ id }) => id === reportId
      );
      break;
    case CustomReportVariants.VOLUMETRIC:
      savedFilter = recordFiltersQuery.data?.data?.find(
        ({ id }) => id === reportId
      );
      break;
  }

  return savedFilter;
};
