import OverlayLoader from "#common/OverlayLoader/OverlayLoader";
import SaveIcon from "#common/SaveIcon/SaveIcon";
import ReactLoader from "#components/LoadingBar/ReactLoader";
import { getBreadcrumbsObject } from "#routers/breadcrumbsHelper";
import { useFetchRecordDetail } from "#src/hooks/records/fetches";
import {
  Button,
  MultiDropdownInputWithSearch,
  Page,
  Panel,
  Tab,
  Tabs,
} from "@validereinc/common-components";
import classNames from "classnames/bind";
import * as PropTypes from "prop-types";
import React, { useMemo, useState } from "react";
import { AutoSizer } from "react-virtualized";
import RecordSelectionProvider, {
  useRecordSelectionContext,
} from "./Context/recordSelectionContext";
import DailyVolumeSection from "./DailyVolumeSection";
import styles from "./EditRecords.module.scss";
import RecordResultsTable from "./RecordResultsTable";
import ResetSelectionModal from "./ResetSelectionModal";
import SampleSelections from "./Selection/SampleSelections";
import SystemBalance from "./SystemBalance/SystemBalance";

const cx = classNames.bind(styles);

const RESULTS_TABLE_HEIGHT = 280;
const RESULTS_PANEL_OFFSET = 125;

const TAB_KEYS = {
  selection: "selection",
  volume: "volume",
};

const EditRecordsContainer = ({ match, breadcrumbs, height }) => {
  const recordId = match.params.recordId;
  const [recordDetail, recordDetailLoadingState, updateRecordDetail] =
    useFetchRecordDetail(recordId);

  const pageBreadcrumbs = useMemo(() => {
    const pageBreadcrumbs = getBreadcrumbsObject(breadcrumbs, match.params);

    if (pageBreadcrumbs[1] && recordDetail.accounting_period) {
      pageBreadcrumbs[1] = {
        ...pageBreadcrumbs[1],
        title: recordDetail?.stream?.name,
      };
    }

    return pageBreadcrumbs;
  }, [breadcrumbs, match.params, recordDetail]);

  return (
    <ReactLoader
      loaded={recordDetailLoadingState !== "loading"}
      position={50}
    >
      <RecordSelectionProvider
        accountingRecordDetail={recordDetail}
        updateRecordDetail={updateRecordDetail}
      >
        <EditRecords
          height={height}
          match={match}
          breadcrumbs={pageBreadcrumbs}
          recordDetailLoadingState={recordDetailLoadingState}
        />
      </RecordSelectionProvider>
    </ReactLoader>
  );
};

const EditRecords = ({ height, breadcrumbs, recordDetailLoadingState }) => {
  const {
    accountingRecordDetail,
    displayProperties,
    actionState,
    setActionState,
    approveSelection,
    resultLoadingState,
    historicalData,
    balances,
    selectedBalance,
    onBalanceSelect,
    isBalancesLoading,
  } = useRecordSelectionContext();

  const [activeTabKey, setActiveTabKey] = useState(TAB_KEYS.selection);
  const [showResetSelectionModal, setShowResetSelectionModal] = useState(false);

  const onTabSelect = (eventKey) => {
    setActiveTabKey(eventKey);
  };

  const onApproveClick = () => {
    setActionState("disabled");

    approveSelection().finally(() => {
      setActionState("enabled");
    });
  };

  const pageTitle = (
    <span className={cx("pageTitle")}>
      Edit Records &nbsp;
      <SaveIcon
        loadingState={recordDetailLoadingState}
        actionState={actionState}
      />
    </span>
  );

  const panelTitle = (
    <div className={cx("editRecordTitleContainer")}>
      Edit Record
      <div className={cx("buttons")}>
        <Button
          onClick={() => setShowResetSelectionModal(true)}
          disabled={actionState !== "enabled"}
          variant="error-outline"
        >
          Reset Selection
        </Button>

        <Button
          onClick={onApproveClick}
          disabled={
            accountingRecordDetail.state === "error" ||
            [null, "None"].includes(accountingRecordDetail.selection_method) ||
            actionState !== "enabled"
          }
          isLoading={actionState !== "enabled"}
          variant="primary"
        >
          {["approved", "validere_approved"].includes(
            accountingRecordDetail.state
          )
            ? "Unapprove"
            : "Approve"}
        </Button>
      </div>
    </div>
  );

  const shouldShowBalanceLoader = (resultLoadingState, actionState) => {
    // initial load does not show loader
    if (resultLoadingState === "initial") {
      return false;
    }

    if (resultLoadingState === "refetching" || actionState === "disabled") {
      return true;
    }

    return false;
  };

  return (
    <Page
      title={pageTitle}
      breadcrumbs={breadcrumbs}
    >
      <div className={cx("container")}>
        <Panel title={panelTitle}>
          <div style={{ position: "relative" }}>
            {actionState === "disabled" && (
              <OverlayLoader text="Re-calculating..." />
            )}

            <RecordResultsTable
              recordOfQualityDetail={accountingRecordDetail}
              historicalData={historicalData}
              height={RESULTS_TABLE_HEIGHT}
            />
          </div>

          <div style={{ margin: "32px 0 0 0" }}>
            <Tabs
              activeKey={activeTabKey}
              onChange={onTabSelect}
            >
              <Tab
                tabKey={TAB_KEYS.selection}
                title="Sample Selection"
              >
                <SampleSelections />
              </Tab>

              <Tab
                tabKey={TAB_KEYS.volume}
                title="Volume"
              >
                <DailyVolumeSection />
              </Tab>
            </Tabs>
          </div>
        </Panel>

        <Panel
          title="Production Reports"
          style={{ height: height + RESULTS_PANEL_OFFSET }}
          className={cx("results")}
        >
          {shouldShowBalanceLoader(resultLoadingState, actionState) && (
            <OverlayLoader text="Re-calculating..." />
          )}

          <div className={cx("dropdownContainer")}>
            <AutoSizer disableHeight>
              {({ width }) => (
                <MultiDropdownInputWithSearch
                  className={cx("dropdown")}
                  options={balances}
                  labelKey="name"
                  value={selectedBalance ? [selectedBalance] : []}
                  onChange={onBalanceSelect}
                  ignoreCase={true}
                  isLoading={isBalancesLoading}
                  placeholder="Select a Balance..."
                  isMulti={false}
                  width={width}
                />
              )}
            </AutoSizer>
          </div>

          <SystemBalance
            accountingPeriod={accountingRecordDetail?.accounting_period}
            displayProperties={displayProperties}
            height={height}
          />
        </Panel>

        {showResetSelectionModal && (
          <ResetSelectionModal
            show={true}
            onModalClose={() => setShowResetSelectionModal(false)}
          />
        )}
      </div>
    </Page>
  );
};

EditRecordsContainer.propTypes = {
  height: PropTypes.number.isRequired,
  match: PropTypes.object.isRequired,
  breadcrumbs: PropTypes.array.isRequired,
};

EditRecords.propTypes = {
  height: PropTypes.number.isRequired,
  match: PropTypes.object.isRequired,
  breadcrumbs: PropTypes.array.isRequired,
  recordDetailLoadingState: PropTypes.string,
};

export default EditRecordsContainer;
