export * from "./EquipmentRecordValueSourcePage";

export const EQUIPMENT_RECORD_VALUE_SOURCE_PAGE_TITLE = "Add Source";

export const linkToAddEquipmentRecordValueSource = (
  equipmentId = ":equipmentId",
  recordId = ":recordId",
  measurementType = ":measurementType"
) =>
  `/app/organization/equipment/${equipmentId}/record/${recordId}/value/${measurementType}/configure`;

export const EQUIPMENT_RECORD_VALUE_SOURCE_BREADCRUMB = {
  title: `${EQUIPMENT_RECORD_VALUE_SOURCE_PAGE_TITLE}:flowId:recordId:measurementType`,
  path: linkToAddEquipmentRecordValueSource(),
};
