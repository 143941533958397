import React from "react";

import { useNavigate } from "#src/Routers/hooks";

import { Button, Panel } from "@validereinc/common-components";

import { EventEquipmentTable } from "#src/batteries-included-components/Tables/EventEquipmentTable";

import { linkToAddEquipmentToEvent } from "#src/routes/events/event-categories/[eventCategoryId]/templates/[templateId]/events/[eventId]/add-equipment";

export const EventEquipmentPanel = ({
  eventCategoryId,
  eventTemplateId,
  eventId,
}: {
  eventCategoryId: string;
  eventTemplateId: string;
  eventId: string;
}) => {
  const navigate = useNavigate();

  const onAddEquipment = () => {
    navigate({
      pathname: linkToAddEquipmentToEvent(
        eventCategoryId,
        eventTemplateId,
        eventId
      ),
    });
  };

  return (
    <Panel
      title="Equipment"
      actionRow={
        <Button
          icon="plus-circle"
          variant="secondary"
          onClick={onAddEquipment}
        >
          Add Equipment
        </Button>
      }
      isFluidY={false}
    >
      <EventEquipmentTable />
    </Panel>
  );
};
