import { useMutation } from "@tanstack/react-query";
import {
  Dialog,
  DropdownInput,
  Form,
  FormButton,
  TextInput,
  useAlert,
  useForm,
} from "@validereinc/common-components";
import {
  EstimationMethodDomain,
  ReportingGroupDomain,
  ReportingGroupType,
} from "@validereinc/domain";
import React, { useEffect } from "react";

import { EstimationMethodWithUserDataType } from "#src/batteries-included-components/Layouts/EstimationMethod/Detail";
import type { AssetTypeType } from "@validereinc/domain";

export const EditEstimationMethodDialog = ({
  entityType,
  onSubmit,
  onClose,
  isEditDialogOpen,
  method,
  reportingGroups,
}: {
  method: EstimationMethodWithUserDataType | undefined;
  isEditDialogOpen?: boolean;
  reportingGroups: ReportingGroupType[];
  entityType?: AssetTypeType;
  onSubmit?: () => void;
  onClose?: () => void;
}) => {
  const { addAlert } = useAlert();

  const form = useForm({
    defaultValues: {
      name: method?.name,
      reportingGroupId:
        method?.reporting_groups?.map((group) => group?.id) ?? [],
    },
  });

  useEffect(() => {
    form.reset();
  }, [isEditDialogOpen]);

  const { mutate, isLoading } = useMutation({
    mutationFn: async (inputs: {
      name: string;
      reportingGroupId: string[];
    }) => {
      try {
        if (!method || !entityType) {
          throw new Error("Estimation method identifiers unavailable");
        }
        await EstimationMethodDomain.update({
          id: method.id,
          data: {
            name: inputs.name,
          },
          meta: { entityType },
        });

        await ReportingGroupDomain.smartUpdate({
          methodId: method?.id,
          currentReportingGroup: method.reporting_groups,
          updatedReportingGroups: inputs?.reportingGroupId,
        });

        onClose?.();
        onSubmit?.();
        addAlert({
          variant: "success",
          message: `Updated ${inputs.name}`,
        });
      } catch (err) {
        addAlert({
          variant: "error",
          message: `Unable to update ${inputs.name}`,
        });
      }
    },
  });

  return (
    <Form
      {...form}
      onSubmit={form.handleSubmit(mutate)}
    >
      <Dialog
        isOpen={isEditDialogOpen}
        onClose={onClose}
        title={`Edit ${method?.name}`}
        actionRow={[
          <FormButton
            key="save-default-values"
            variant="primary"
            onClick={form.handleSubmit(mutate)}
            isLoading={isLoading}
          >
            Save
          </FormButton>,
        ]}
      >
        <TextInput
          name="name"
          label="Estimation Method Name"
          isRequired
          isDisabled={isLoading}
        />
        <DropdownInput
          name="reportingGroupId"
          label="Reporting Scenario"
          options={reportingGroups}
          labelKey="name"
          valueKey="id"
          isMulti={true}
          isFluid
          isSearchable
          isDisabled={isLoading}
        />
      </Dialog>
    </Form>
  );
};
