import { CompanyType } from "../schemas";
import { ResourceServiceType, ResponseErrorType } from "../util";
import { restAPI } from "./api";

export const CompanyAdapter: ResourceServiceType<CompanyType> = {
  getList: () =>
    Promise.reject({
      success: false,
      error: "Method not implemented",
    } satisfies ResponseErrorType),
  /**
   * Get a single company
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/company/get_company
   */
  getOne: async ({ id }) => {
    const company = await restAPI.nodeAPI.GET<CompanyType>({
      endpoint: `/companies/${id}`,
    });

    return {
      data: company,
    };
  },
  getMany: () =>
    Promise.reject({
      success: false,
      error: "Method not implemented",
    } satisfies ResponseErrorType),
  createOne: () =>
    Promise.reject({
      success: false,
      error: "Method not implemented",
    } satisfies ResponseErrorType),
  createMany: () =>
    Promise.reject({
      success: false,
      error: "Method not implemented",
    } satisfies ResponseErrorType),
  updateOne: () =>
    Promise.reject({
      success: false,
      error: "Method not implemented",
    } satisfies ResponseErrorType),
  updateMany: () =>
    Promise.reject({
      success: false,
      error: "Method not implemented",
    } satisfies ResponseErrorType),
  deleteOne: () =>
    Promise.reject({
      success: false,
      error: "Method not implemented",
    } satisfies ResponseErrorType),
  deleteMany: () =>
    Promise.reject({
      success: false,
      error: "Method not implemented",
    } satisfies ResponseErrorType),
};
