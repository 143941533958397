import { fetchAdjustmentFactors } from "#redux/actions/adjustmentFactors";
import { havePermission } from "#redux/reducers/permissions";
import { useFetchNotes, VOLUME_NOTE } from "#services/NotesService";
import { Modal, Tab, Tabs, Title } from "@validereinc/common-components";
import orderBy from "lodash/orderBy";
import * as PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { TAB_KEYS } from "./VolumeEntryConstant";
import VolumeEntryForm from "./VolumeEntryForm";
import "./VolumeEntryModal.scss";
import VolumeNotes from "./VolumeNotes";

const DEFAULT_TAB_KEY = TAB_KEYS.volumes;

const mapStateToProps = ({
  permissions,
  recordOfVolume,
  adjustmentFactors,
}) => ({
  selectedRecordOfVolumes: recordOfVolume?.selectedRecordOfVolume.toJS() ?? [],
  adjustmentFactors: adjustmentFactors?.data.toJS() ?? [],
  haveAdjustmentFactorWritePermission: havePermission(permissions)(
    "gas:accounting.adjustment_factors",
    "write"
  ),
});

const mapDispatchToProps = {
  fetchAdjustmentFactors,
};

const categorizeVolumeEntries = (volumeEntries) => {
  const entriesWithVolume = [];
  const entriesWithoutVolume = [];

  orderBy(volumeEntries, "date").forEach((entry) => {
    if (entry.is_entry_required) {
      entriesWithoutVolume.push(entry);
    } else {
      entriesWithVolume.push(entry);
    }
  });

  return [entriesWithVolume, entriesWithoutVolume];
};

const VolumeEntryModal = ({
  show,
  onHide,
  selectedRecordOfVolumes,
  adjustmentFactors,
  fetchAdjustmentFactors,
  streamId,
  action,
  setShouldRefetchDailyVolume,
  isInventoryStream,
  streamAdjustmentConfigs,
  haveAdjustmentFactorWritePermission,
}) => {
  const [volumeEntries, setVolumeEntries] = useState(selectedRecordOfVolumes);

  const [activeTabKey, setActiveTabKey] = useState(
    action === "note" ? TAB_KEYS.notes : DEFAULT_TAB_KEY
  );

  const [noteDisplayed, setNoteDisplayed] = useState(null);
  const [areNotesLoading, _errorStatus, notes, fetchNotes] = useFetchNotes(
    noteDisplayed?.id,
    VOLUME_NOTE
  );

  const [entriesWithVolume, entriesWithoutVolume] = useCallback(
    categorizeVolumeEntries(volumeEntries),
    [volumeEntries]
  );

  useEffect(() => {
    // return the latest volume entry notes
    if (!areNotesLoading) {
      setNoteDisplayed(entriesWithVolume[entriesWithVolume.length - 1]);
    }
  }, [entriesWithVolume, areNotesLoading]);

  const updateAdjustmentFactorList = () => {
    fetchAdjustmentFactors();
  };

  return (
    <Modal
      open={show}
      onClose={onHide}
      className="volumeEntryModal"
    >
      <Modal.Header>
        <Title>Daily Volume Entries</Title>
      </Modal.Header>

      <div className="volumeEntryModal__content">
        <Tabs
          activeKey={activeTabKey}
          onChange={setActiveTabKey}
        >
          <Tab
            tabKey={TAB_KEYS.volumes}
            title="Volumes"
          >
            <VolumeEntryForm
              volumeEntries={volumeEntries}
              setVolumeEntries={setVolumeEntries}
              adjustmentFactors={adjustmentFactors}
              updateAdjustmentFactorList={updateAdjustmentFactorList}
              streamId={streamId}
              action={action}
              setShouldRefetchDailyVolume={setShouldRefetchDailyVolume}
              streamAdjustmentConfigs={streamAdjustmentConfigs}
              isInventoryStream={isInventoryStream}
              haveAdjustmentFactorWritePermission={
                haveAdjustmentFactorWritePermission
              }
              onHide={onHide}
            />
          </Tab>

          <Tab
            tabKey={TAB_KEYS.notes}
            title="Notes"
          >
            <VolumeNotes
              isActiveTab={activeTabKey === TAB_KEYS.notes}
              entriesWithVolume={entriesWithVolume}
              entriesWithoutVolume={entriesWithoutVolume}
              volumeEntriesLength={volumeEntries.length}
              notes={notes}
              areNotesLoading={areNotesLoading}
              fetchNotes={fetchNotes}
              noteDisplayed={noteDisplayed}
              onDisplayNoteSelect={(note) => setNoteDisplayed(note)}
              setShouldRefetchDailyVolume={setShouldRefetchDailyVolume}
              onClose={onHide}
            />
          </Tab>
        </Tabs>
      </div>
    </Modal>
  );
};
VolumeEntryModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  selectedRecordOfVolumes: PropTypes.array,
  adjustmentFactors: PropTypes.array,
  fetchAdjustmentFactors: PropTypes.func,
  streamId: PropTypes.string,
  action: PropTypes.string,
  setShouldRefetchDailyVolume: PropTypes.func,
  streamAdjustmentConfigs: PropTypes.array,
  isInventoryStream: PropTypes.bool,
  haveAdjustmentFactorWritePermission: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(VolumeEntryModal);
