import React from "react";
import * as PropTypes from "prop-types";
import Form from "../../Common/Form/Form";
import TextBoxInput from "../../Common/Form/TextBoxInput";
import SelectInput from "../../Common/Form/SelectInput";
import DateInput from "../../Common/Form/DateInput";
import config from "../../../config";
import { connect } from "react-redux";
import { measurementNames } from "../../Redux/reducers/measurements";
import { SAMPLE_TYPES } from "../../../utils/enums";

const TITLE_COLUMN = 3;

const ALL_SAMPLE_TYPES = Object.values(SAMPLE_TYPES);

const mapStateToProps = (state) => {
  return {
    supportedMeasurementTypes: measurementNames(state.measurements),
  };
};

const BookmarkForm = (props) => {
  const {
    bookmark,
    availableStreams,
    dateRangeType,
    onDateRangeTypeChange,
    onInputChange,
  } = props;

  return (
    <Form>
      <TextBoxInput
        title="Title"
        type="text"
        titleCol={TITLE_COLUMN}
        value={bookmark.title || ""}
        onChange={(event) => onInputChange("title", event.target.value)}
      />
      <SelectInput
        title="Streams"
        titleCol={TITLE_COLUMN}
        labelKey="name"
        value={bookmark.streams || []}
        options={availableStreams}
        onChange={(value) => onInputChange("streams", value)}
        isMulti
      />
      <SelectInput
        title="Measurement Type"
        titleCol={TITLE_COLUMN}
        value={bookmark.measurementType || []}
        options={props.supportedMeasurementTypes}
        onChange={(value) => onInputChange("measurementType", value)}
        isMulti
      />
      <SelectInput
        title="Sample Type"
        titleCol={TITLE_COLUMN}
        value={bookmark.sampleTypes || []}
        options={ALL_SAMPLE_TYPES}
        onChange={(value) => onInputChange("sampleTypes", value)}
        isMulti
      />
      <SelectInput
        title="Date Range Type"
        titleCol={3}
        labelKey="name"
        value={[dateRangeType]}
        options={props.dateRangeOptions}
        onChange={(value) => onDateRangeTypeChange(value)}
      />
      {dateRangeType.id === "fixed_date_range" ? (
        <>
          <DateInput
            title="Start Date"
            titleCol={TITLE_COLUMN}
            value={bookmark.timeRangeStart}
            format={config.DATE_FORMAT}
            onDayChange={(value) => onInputChange("timeRangeStart", value)}
          />
          <DateInput
            title="End Date"
            titleCol={TITLE_COLUMN}
            value={bookmark.timeRangeEnd}
            format={config.DATE_FORMAT}
            onDayChange={(value) => onInputChange("timeRangeEnd", value)}
          />
        </>
      ) : (
        <TextBoxInput
          title="Last Num. Of Days"
          titleCol={TITLE_COLUMN}
          type="number"
          value={bookmark.relativeDayRange || ""}
          onChange={(event) =>
            onInputChange("relativeDayRange", event.target.value)
          }
        />
      )}
    </Form>
  );
};

BookmarkForm.propTypes = {
  bookmark: PropTypes.object.isRequired,
  dateRangeType: PropTypes.object.isRequired,
  dateRangeOptions: PropTypes.array.isRequired,
  availableStreams: PropTypes.array.isRequired,
  supportedMeasurementTypes: PropTypes.array.isRequired,
  onDateRangeTypeChange: PropTypes.func.isRequired,
  onInputChange: PropTypes.func.isRequired,
};

const BookmarkFormContainer = connect(mapStateToProps, undefined)(BookmarkForm);

export default BookmarkFormContainer;
