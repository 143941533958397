import { getComponentShortForm } from "#utils/stringFormatter";
import { Tooltip } from "@validereinc/common-components";
import * as PropTypes from "prop-types";
import React from "react";
import "./MeasurementsCell.scss";

const MAX_MEASUREMENTS_IN_TOOLTIP = 10;

const MeasurementsCellRow = ({ label, value }) => {
  return (
    <div className="measurementsCell__row">
      <div className="measurementsCell__label">
        <Tooltip content={label}>{label}</Tooltip>
      </div>
      <div className="measurementsCell__value">{value}</div>
    </div>
  );
};

const MeasurementsCell = ({ measurements }) => {
  if (measurements.length == 0) {
    return "-";
  }

  return (
    <div className="measurementsCell">
      {measurements
        .slice(0, MAX_MEASUREMENTS_IN_TOOLTIP)
        .map(({ name, value }) => (
          <MeasurementsCellRow
            key={name}
            label={getComponentShortForm(name)}
            value={value}
          />
        ))}

      {measurements.length > MAX_MEASUREMENTS_IN_TOOLTIP && <div>...</div>}
    </div>
  );
};

MeasurementsCellRow.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
};

MeasurementsCell.propTypes = {
  measurements: PropTypes.array.isRequired,
};

export default MeasurementsCell;
