import {
  apiRequestServicesPromise,
  apiPutServicesPromise,
  validereApi,
} from "./ServiceHelper";
import { TIMEZONE } from "../../utils/timeFormatter";
import moment from "moment";

const getApiFormattedSortByParam = (sortKey) => {
  if (sortKey === "started_at") {
    return "sample.started_at";
  }

  return "sample.ended_at";
};

const getFormattedParamsForSampleValidation = (paginationDetail, filters) => {
  const { page, rowPerPage, sort } = paginationDetail;
  const { site, stream, status, samplePoint, from, to } = filters;

  const [sortBy, sortDirection] = Object.entries(sort)?.[0] || [
    "ended_at",
    "desc",
  ];

  return {
    page,
    page_size: rowPerPage,
    stream_id: stream ? (typeof stream === "string" ? [stream] : stream) : [],
    state: status ? (typeof status === "string" ? [status] : status) : [],
    site_id: site ? (typeof site === "string" ? [site] : site) : [],
    sample_point_id: samplePoint,
    sort_by: getApiFormattedSortByParam(sortBy),
    sort_direction: sortDirection,
    from: from
      ? moment(from).startOf("day").toDate()
      : moment().startOf("day").toDate(),
    until: to
      ? moment(to).endOf("day").toDate()
      : moment().endOf("day").toDate(),
  };
};

const getFormattedParamsForTests = (paginationDetail, filters) => {
  const { page, rowPerPage, sort } = paginationDetail;
  const {
    from,
    to,
    site,
    instrument,
    stream,
    sampleType,
    orphan,
    measurements,
  } = filters;

  const [sortBy, sortDirection] = Object.entries(sort)?.[0] || [];

  let orphansParam = {};
  if (orphan && orphan.length > 0) {
    orphansParam = { orphans: orphan.includes("yes") };
  }

  // No sample point id filter provided by backend, using streams as equivalent
  // since a sample point id is associated with only 1 stream (M:1 relationship)

  return {
    page,
    page_size: rowPerPage,
    sort_by: sortBy,
    sort_direction: sortDirection,
    from: from
      ? moment(from).startOf("day").toDate()
      : moment().startOf("day").toDate(),
    until: to
      ? moment(to).endOf("day").toDate()
      : moment().endOf("day").toDate(),
    site_id: site ? (typeof site === "string" ? [site] : site) : [],

    instrument_id: instrument
      ? typeof instrument === "string"
        ? [instrument]
        : instrument
      : [],

    stream_id: stream ? (typeof stream === "string" ? [stream] : stream) : [],
    measurement_types: measurements
      ? typeof measurements === "string"
        ? [measurements]
        : measurements
      : [],
    sample_type: sampleType
      ? typeof sampleType === "string"
        ? [sampleType]
        : sampleType
      : [],
    ...orphansParam,
  };
};

const getFormattedParamsForSampleCSVExport = (filters) => {
  const { from, until, sites, streams, state } = filters;

  const site_id = sites.map((site) => site.id);
  const stream_id = streams.map((stream) => stream.id);

  return {
    state,
    site_id,
    stream_id,
    from,
    until,
  };
};

const getFormattedParamsForUpdatingSampleState = (inputs) => {
  return inputs.enable_applicable_period === "yes"
    ? {
        state: inputs.state,
        applicable_from: inputs.from,
        applicable_until: inputs.until,
      }
    : {
        state: inputs.state,
      };
};

const SampleService = {
  updateSampleState(sampleId, inputs) {
    const parameters = getFormattedParamsForUpdatingSampleState(inputs);

    return apiPutServicesPromise(`/api/samples/${sampleId}`, parameters);
  },

  getSample(sampleId) {
    return apiRequestServicesPromise(`/api/samples/${sampleId}`);
  },

  getCTRSampleList(instrumentId, beginEpoch, endEpoch) {
    const parameter = {
      water_instrument_id: instrumentId,
      begin_epoch: beginEpoch,
      end_epoch: endEpoch,
    };

    return apiRequestServicesPromise(
      "/api/v1/water_test_sample_list_by_instrument",
      parameter
    );
  },

  getLabResults(startDate, endDate) {
    const parameter = {
      start_date: moment(startDate).toISOString(),
      end_date: moment(endDate).toISOString(),
    };

    return validereApi.get("/api/lab_results", {
      params: parameter,
      timeout: 60000,
    });
  },

  getSamplesForValidation(paginationDetail, filters) {
    const params = getFormattedParamsForSampleValidation(
      paginationDetail,
      filters
    );

    return apiRequestServicesPromise("/api/lab_results", params);
  },

  getSampleCSVExport(filters) {
    const parameters = getFormattedParamsForSampleCSVExport(filters);

    return apiRequestServicesPromise(`/api/lab_results/csv/url`, parameters);
  },

  getLabReportFile(labReportId) {
    return apiRequestServicesPromise(
      `/api/lab_reports/${labReportId}/file/url`
    );
  },

  getValidereVaporSampleList(instrumentId, beginEpoch, endEpoch) {
    const parameter = {
      validere_vapour_instrument_id: instrumentId,
      begin_epoch: beginEpoch,
      end_epoch: endEpoch,
    };

    return apiRequestServicesPromise(
      "/api/v1/validere_vapour_sample_list_by_instrument",
      parameter
    );
  },

  getEralyticSampleList(instrumentId, beginEpoch, endEpoch) {
    const parameter = {
      eralytics_vapour_instrument_id: instrumentId,
      begin_epoch: beginEpoch,
      end_epoch: endEpoch,
    };

    return apiRequestServicesPromise(
      "/api/v1/eralytics_vapour_sample_list_by_instrument",
      parameter
    );
  },

  getRigakuXraySampleList(instrumentId, beginEpoch, endEpoch) {
    const parameter = {
      rigaku_xray_fluorescence_instrument_id: instrumentId,
      begin_epoch: beginEpoch,
      end_epoch: endEpoch,
    };

    return apiRequestServicesPromise(
      "/api/v1/rigaku_xray_fluorescence_sample_list_by_instrument",
      parameter
    );
  },

  getAntonPaarDensitySampleList(instrumentId, beginEpoch, endEpoch) {
    const parameter = {
      anton_paar_density_instrument_id: instrumentId,
      begin_epoch: beginEpoch,
      end_epoch: endEpoch,
    };

    return apiRequestServicesPromise(
      "/api/v1/anton_paar_density_sample_list_by_instrument",
      parameter
    );
  },

  getRudolphDensitySampleList(instrumentId, beginEpoch, endEpoch) {
    const parameter = {
      rudolph_research_density_instrument_id: instrumentId,
      begin_epoch: beginEpoch,
      end_epoch: endEpoch,
    };

    return apiRequestServicesPromise(
      "/api/v1/rudolph_research_density_sample_list_by_instrument",
      parameter
    );
  },

  getAntonPaarSVM3001SampleList(instrumentId, beginEpoch, endEpoch) {
    const parameter = {
      anton_paar_svm_3001_instrument_id: instrumentId,
      begin_epoch: beginEpoch,
      end_epoch: endEpoch,
    };

    return apiRequestServicesPromise(
      "/api/v1/anton_paar_svm_3001_sample_list_by_instrument",
      parameter
    );
  },

  getHoribaSulphurSampleList(instrumentId, beginEpoch, endEpoch) {
    const parameter = {
      horiba_xray_fluorescence_instrument_id: instrumentId,
      begin_epoch: beginEpoch,
      end_epoch: endEpoch,
    };

    return apiRequestServicesPromise(
      "/api/v1/horiba_xray_fluorescence_sample_list_by_instrument",
      parameter
    );
  },

  getTestDetails(testId) {
    const parameter = {
      timezone: TIMEZONE,
    };

    return apiRequestServicesPromise(`/api/tests/${testId}`, parameter);
  },

  getTests(from, until) {
    const parameters = {
      from,
      until,
    };

    return apiRequestServicesPromise(`/api/tests`, parameters);
  },

  getPaginatedTests(paginationDetail, filters) {
    const params = getFormattedParamsForTests(paginationDetail, filters);

    return apiRequestServicesPromise(`/api/paged_tests`, params);
  },

  markTestAsError(testId, note) {
    const formData = new FormData();
    if (note.text) {
      formData.append("text", note.text);
    }

    if (note.files) {
      Object.keys(note.files).map((key) => {
        formData.append("file_uploads[]", note.files[key]);
      });
    }

    return apiPutServicesPromise(`/api/tests/${testId}/errors`, formData);
  },

  assignToSample(sample_id, test_ids) {
    const parameters = {
      test_ids,
    };

    return apiPutServicesPromise(`/api/samples/${sample_id}/tests`, parameters);
  },

  // Get water sample CSV list
  getInstrumentSampleCSV(instrumentId, beginEpoch, endEpoch, timeZone) {
    const parameter = {
      water_instrument_id: instrumentId,
      begin_epoch: beginEpoch,
      end_epoch: endEpoch,
      time_zone: timeZone,
    };

    return apiRequestServicesPromise(
      "/api/v1/water_test_sample_report_list_by_instrument",
      parameter
    );
  },

  getAnalyzeDataForStream(
    stream_id,
    sample_types,
    time_grouping,
    test_types,
    instrument_types,
    start_epoch,
    end_epoch
  ) {
    const parameters = {
      sample_types,
      time_grouping,
      test_types,
      instrument_types,
      start_epoch,
      end_epoch,
    };

    return apiRequestServicesPromise(
      `/api/streams/${stream_id}/analyze`,
      parameters
    );
  },

  getAnalyzeDataForStreams(
    streams,
    sample_types,
    time_grouping,
    test_types,
    instrument_types,
    start_epoch,
    end_epoch
  ) {
    const parameters = {
      sample_types,
      time_grouping,
      test_types,
      instrument_types,
      start_epoch,
      end_epoch,
    };

    const requests = streams.map((stream) => {
      if (stream) {
        return apiRequestServicesPromise(
          `/api/streams/${stream.id}/analyze`,
          parameters
        );
      }
    });

    return Promise.all(requests);
  },

  getAnalyzeCSVDownloadLink(
    stream_ids,
    sample_types,
    instrument_types,
    measurement_types,
    start_date,
    end_date
  ) {
    const parameters = {
      stream_ids,
      sample_types,
      instrument_types,
      measurement_types,
      start_date,
      end_date,
    };

    return apiRequestServicesPromise("/api/analyze/csv/url", parameters);
  },
};

export default SampleService;
