export const SystemBalanceProperties = [
  {
    name: "Receipts Plus Opening Inventory",
    id: "receipts_plus_opening_inventory",
    showPercentChange: true,
  },
  {
    name: "Dispositions Plus Closing Inventory",
    id: "dispositions_plus_closing_inventory",
    showPercentChange: true,
  },
  {
    name: "Absolute Differences",
    id: "absolute_differences",
    showPercentChange: true,
  },
  { name: "Percent Differences", id: "percent_differences" },
  { name: "Recoveries", id: "recoveries" },
];
