import {
  CursorListResponseType,
  DataPlatformResponse,
  fetchAndCollateByCursor,
  GetListRequestType,
  getPaginatedData,
  getSortedData,
  PaginationCursorParamsType,
  ResourceServiceType,
} from "../util";
import { restAPI } from "./api";
import { DatasetGetListFilterType, DatasetType } from "../schemas";

type DatasetServiceType = Pick<
  ResourceServiceType<DatasetType>,
  "getOne" | "getList"
> & { default: Pick<ResourceServiceType<DatasetType>, "getList"> } & {
  downloadTemplate: ({
    id,
    meta,
  }: {
    id: string;
    meta: { company_id?: string };
  }) => Promise<any>;
};

export const DatasetAdapter: DatasetServiceType = {
  /**
   * Get a list of datasets, this is inclusive of both defaults and custom configured datasets
   * @see {@link https://admin.dataplatform.validere.com/docs#/Datasets/list_datasets_datasets_get}
   */
  getList: async ({
    filters,
    page,
    pageSize,
    sortBy,
    sortDirection,
  }: GetListRequestType<DatasetGetListFilterType>) => {
    const fetchData = async ({
      cursorToken,
      pageSize,
    }: PaginationCursorParamsType): Promise<
      CursorListResponseType<DatasetType>
    > => {
      const response = await restAPI.dataPlatformAdminAPI.GET<
        DataPlatformResponse<DatasetType, "Dataset">
      >({
        endpoint: "/datasets",
        query: {
          ...filters,
          ...(pageSize ? { page_size: pageSize } : {}),
          ...(cursorToken ? { start_token: cursorToken } : {}),
        },
      });

      return {
        total_entries: response.meta.total ?? 0,
        next_cursor_token: response.meta.continuation_token,
        data: response.data,
      };
    };

    const allData = await fetchAndCollateByCursor<DatasetType>(
      fetchData,
      pageSize
    );

    // if a specific page and page size isn't requested, return the collated data
    if (!page && !pageSize) {
      return allData;
    }

    // IMPROVE: requesting a single page has performance implications in
    // cursor-based pagination - every page request will fetch all data again,
    // then paginate. To solve this, first complete
    // https://validere.atlassian.net/browse/FE-1799
    return getPaginatedData<DatasetType>(
      getSortedData(allData.data, sortBy as keyof DatasetType, sortDirection),
      page,
      pageSize
    );
  },
  /**
   * Get a single dataset by its UUID
   * @see {@link https://admin-staging.dataplatform.validere.xyz/docs#/Datasets/get_dataset_by_id_datasets__dataset_id__get}
   */
  getOne: ({ id, meta: { client_id, company_id } = {} }) =>
    restAPI.dataPlatformAdminAPI
      .GET<DataPlatformResponse<DatasetType, "Dataset">>({
        endpoint: `/datasets/${id}`,
        query: {
          ...(client_id ? { client_id } : {}),
          ...(company_id ? { company_id } : {}),
        },
      })
      .then((resp) => ({ data: resp.data?.[0] })),
  downloadTemplate: ({ id, meta: { company_id } = {} }) =>
    restAPI.dataPlatformAdminAPI.GET({
      endpoint: `/defaults/datasets/${id}/schema/template`,
      query: {
        ...(company_id ? { company_id } : {}),
      },
      headers: {
        accept:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      },
      responseType: "blob",
    }),
  default: {
    /**
     * Get a list of default datasets
     * @see {@link https://admin-staging.dataplatform.validere.xyz/docs#/Defaults/list_default_datasets_defaults_datasets_get}
     * @returns a list of default datasets after provided filters are applied
     */
    getList: async ({ filters, page, pageSize, sortBy, sortDirection }) => {
      const response = await restAPI.dataPlatformAdminAPI.GET<
        DataPlatformResponse<DatasetType, "Default">
      >({
        endpoint: "/defaults/datasets",
        query: {
          ...filters,
        },
      });

      return getPaginatedData<DatasetType>(
        getSortedData(
          response.data,
          sortBy as keyof DatasetType,
          sortDirection
        ),
        page,
        pageSize
      );
    },
  },
};
