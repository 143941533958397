import { MAX_PAGE_SIZE_DATA_PLATFORM, config } from "../config";
import {
  CursorListResponseType,
  GetListResponseType,
  PaginationCursorParamsType,
  PaginationParamsType,
  SortDirection,
  SortDirectionType,
} from "../util/requests";

/**
 * Collates all the data that can be fetched at an endpoint that supports offset/limit based pagination.
 * @returns a single page of all the data that can possibly be fetched or the data for the provided page/pageSize
 */
export const fetchAndCollate = async <T>(
  /** the query to run to get the data */
  query: (params: PaginationParamsType) => Promise<GetListResponseType<T>>,
  /** if page is provided with pageSize, just use it with the query, once */
  page?: number,
  /** if pageSize is provided with page, just use it with the query, once */
  pageSize?: number
) => {
  const cumulativeResult = [];
  let currentPage = 1;
  let result;

  // If pagination parameters are provided, short-circuit and just use them
  if (page && pageSize) {
    return query({ page, pageSize });
  }

  // Fetch pages while the current page has less than the max page size
  do {
    result = await query({
      page: currentPage,
      pageSize: config.maxPageSize,
    });
    cumulativeResult.push(result);
    currentPage += 1;
  } while (result.data.length === config.maxPageSize);

  const totalData = cumulativeResult.flatMap(({ data }) => data);

  return {
    page_size: totalData.length,
    page_number: 1,
    total_entries: totalData.length,
    total_pages: 1,
    data: totalData,
  };
};

/**
 * Collates all the data that can be fetched at a list endpoint that supports cursor-based pagination, in a single page
 * @returns a single page of all the data that can possibly be fetched or the pageSize worth of data starting from the provided cursor
 */
export const fetchAndCollateByCursor = async <T>(
  /** the async function to run that fetches the data from the endpoint */
  query: (
    params: PaginationCursorParamsType
  ) => Promise<CursorListResponseType<T>>,
  /** the # of data entries per page */
  pageSize?: number,
  /** the cursor to use, if available */
  cursorToken?: string
) => {
  const cumulativeResult = [];
  let currentCursor = cursorToken;
  let result;

  // If pagination parameters are provided, short-circuit and just use them
  if (cursorToken && pageSize) {
    const response = await query({ cursorToken, pageSize });

    return {
      page_size: response.data.length,
      page_number: 1,
      total_entries: response.total_entries,
      total_pages: 1,
      data: response.data,
    };
  }

  // Fetch pages while the current page has less than the max page size
  do {
    result = await query({
      cursorToken: currentCursor,
      pageSize: MAX_PAGE_SIZE_DATA_PLATFORM,
    });
    cumulativeResult.push(result);

    if (!result.next_cursor_token) {
      break;
    }

    currentCursor = result.next_cursor_token;
  } while (result.data.length === MAX_PAGE_SIZE_DATA_PLATFORM);

  const totalData = cumulativeResult.flatMap(({ data }) => data);

  return {
    page_size: totalData.length,
    page_number: 1,
    total_entries: totalData.length,
    total_pages: 1,
    data: totalData,
  };
};

/**
 * Given an arbitary complete list of data, allow pagination on it, given a page
 * and page size argument
 * @returns the requested page of the data, given a page size
 */
export const getPaginatedData = <TData>(
  /** arbitrary list of data */
  data: TData[],
  /** the page # to get */
  page = 1,
  /** the size of each page */
  pageSize = 25
): GetListResponseType<TData> => {
  if (!data) {
    return {
      total_entries: 0,
      total_pages: 1,
      page_number: 1,
      page_size: pageSize,
      data: [],
    };
  }

  const totalEntries = data.length;
  const totalPages = Math.ceil(totalEntries / pageSize);
  const correctedPage = Math.min(Math.max(1, page), totalPages);
  const pageStartSliceIdx = (correctedPage - 1) * pageSize;
  const pageEndSliceIdx = pageStartSliceIdx + pageSize;

  return {
    total_entries: totalEntries,
    total_pages: totalPages,
    page_number: correctedPage,
    page_size: pageSize,
    data: data.slice(
      pageStartSliceIdx,
      correctedPage === totalPages ? undefined : pageEndSliceIdx
    ),
  };
};

/**
 * Given an arbitary complete list of data, allow sorting on it, given a sorting
 * key and direction.
 * @returns the data, sorted by the specified key and ordered in the specified direction
 */
export const getSortedData = <TData>(
  /** arbitrary list of data */
  data: TData[],
  /** the field to sort by */
  sortBy: keyof TData,
  /** the direction to sort */
  sortDirection: SortDirectionType = SortDirection.DESCENDING
): TData[] => {
  if (!data) {
    return [];
  }

  return sortBy && sortDirection
    ? data.slice().sort((dataA, dataB) => {
        const fieldValueA = dataA[sortBy];
        const fieldValueB = dataB[sortBy];
        const bothValues = [fieldValueA, fieldValueB];
        const comparatorDirectionResult =
          sortDirection === SortDirection.ASCENDING ? 1 : -1;

        if (
          bothValues.every(
            (v) =>
              typeof v === "string" ||
              typeof v === "number" ||
              typeof v === "bigint"
          )
        ) {
          return fieldValueA > fieldValueB
            ? comparatorDirectionResult
            : fieldValueA < fieldValueB
              ? -comparatorDirectionResult
              : 0;
        } else if (bothValues.every((v) => typeof v === "symbol")) {
          const fieldValueAString = String(fieldValueA);
          const fieldValueBString = String(fieldValueB);

          return fieldValueAString > fieldValueBString
            ? comparatorDirectionResult
            : fieldValueAString < fieldValueBString
              ? -comparatorDirectionResult
              : 0;
        } else {
          return 0;
        }
      })
    : data.slice();
};
