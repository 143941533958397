import type { FlowMetaDataType } from "@validereinc/domain";
import { FlowDomain } from "@validereinc/domain";
import { useEffect, useState } from "react";

export const useFlowTypes = () => {
  const [flowTypes, setFlowTypes] = useState<FlowMetaDataType>([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchFlowTypes = async () => {
    setIsLoading(true);
    const newFlowTypes = await FlowDomain.getFlowTypes();
    setFlowTypes(newFlowTypes);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchFlowTypes().catch((error) => console.error(error));
  }, []);

  return { flowTypes, isLoading };
};

export const useFlowProductTypes = () => {
  const [flowProductTypes, setFlowTypes] = useState<FlowMetaDataType>([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchFlowProductTypes = async () => {
    setIsLoading(true);
    const newFlowTypes = await FlowDomain.getFlowProductTypes();
    setFlowTypes(newFlowTypes);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchFlowProductTypes().catch((error) => console.error(error));
  }, []);

  return { flowProductTypes, isLoading };
};
