const displayProperties = () => [
  {
    label: "Nitrogen (N2)",
    key: "Mole Percentage Nitrogen (N2)",
  },
  {
    label: "Carbon Dioxide (CO2)",
    key: "Mole Percentage Carbon Dioxide (CO2)",
  },
  {
    label: "Hydrogen Sulphide H2S",
    key: "Mole Percentage Hydrogen Sulphide (H2S)",
  },
  {
    label: "Methane (C1)",
    key: "Mole Percentage Methane (C1)",
  },
  {
    label: "Ethane (C2)",
    key: "Mole Percentage Ethane (C2)",
  },
  {
    label: "Propane (C3)",
    key: "Mole Percentage Propane (C3)",
  },
  {
    label: "Iso-Butane (IC4)",
    key: "Mole Percentage Isobutane (iC4)",
  },
  {
    label: "N-Butane (NC4)",
    key: "Mole Percentage n-Butane (NC4)",
  },
  {
    label: "Butane (C4)",
    key: "Mole Percentage Butane (C4)",
  },
  {
    label: "Iso-Pentane (iC5)",
    key: "Mole Percentage Isopentane (iC5)",
  },
  {
    label: "N-Pentane (NC5)",
    key: "Mole Percentage n-Pentane (NC5)",
  },
  {
    label: "Pentane (C5)",
    key: "Mole Percentage Pentane (C5)",
  },
  {
    label: "Hexane (C6)",
    key: "Mole Percentage Hexanes (C6)",
  },
  {
    label: "Cyclohexane (*C6)",
    key: "Mole Percentage Cyclohexane (*C6)",
  },
  {
    label: "Neohexane (*C6)",
    key: "Mole Percentage Neohexane (*C6)",
  },
  {
    label: "2,3-Dimethylbutane (*C6)",
    key: "Mole Percentage 2,3-dimethylbutane (*C6)",
  },
  {
    label: "N-Hexane (NC6)",
    key: "Mole Percentage n-Hexane (NC6)",
  },
  {
    label: "Heptanes Plus (C7+)",
    key: "Mole Percentage Heptanes Plus (C7+)",
  },
  {
    label: "Heptane (C7)",
    key: "Mole Percentage Heptanes (C7)",
  },
  {
    label: "2,2-Dimethylpentane (*C7)",
    key: "Mole Percentage 2,2-dimethylpentane (*C7)",
  },
  {
    label: "2,4-Dimethylpentane (*C7)",
    key: "Mole Percentage 2,4-dimethylpentane (*C7)",
  },
  {
    label: "2,2,3-Trimethylbutane (*C7)",
    key: "Mole Percentage 2,2,3 - trimethylbutane (*C7)",
  },
  {
    label: "3,3-Dimethylpentane (*C7)",
    key: "Mole Percentage 3,3-dimethylpentane (*C7)",
  },
  {
    label: "2,3-Dimethylpentane",
    key: "Mole Percentage 2,3-dimethylpentane (*C7)",
  },
  {
    label: "2-Methylpentane",
    key: "Mole Percentage 2-methylpentane (*C7)",
  },
  {
    label: "3-Methylpentane (*C7)",
    key: "Mole Percentage 3-methylhexane (*C7)",
  },
  {
    label: "N-Heptane (NC7)",
    key: "Mole Percentage n-Heptane (NC7)",
  },
];

export default displayProperties;
