export const DEFAULT_SOURCE_SELECTION = {
  inlet_streams: [],
  inlet_inventories: [],
  outlet_streams: [],
  outlet_inventories: [],
  reference_streams: [],
  reference_inventories: [],
};

export const DEFAULT_SOURCE_INPUTS = {
  name: "",
  components: [],
  type: "",
  showTotalVolume: "",
  showEnergy: "",
  ...DEFAULT_SOURCE_SELECTION,
};

export const COMPONENTS = [
  "Methane (C1)",
  "Ethane (C2)",
  "Propane (C3)",
  "Propane Minus (C3-)",
  "Isobutane (iC4)",
  "n-Butane (NC4)",
  "Butane Minus (C4-)",
  "Butane (C4)",
  "Deemed Butane",
  "Isopentane (iC5)",
  "n-Pentane (NC5)",
  "Pentane (C5)",
  "Pentanes Plus (C5+)",
  "Hexanes (C6)",
  "Hexanes Plus (C6+)",
  "Heptanes Plus (C7+)",
  "Nitrogen (N2)",
  "Carbon Dioxide (CO2)",
  "Hydrogen Sulphide (H2S)",
];
