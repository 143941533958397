import React from "react";
import * as PropTypes from "prop-types";
import Loader from "react-loader";
import loaderOptions from "./LoadingBar";
import "./ReactLoader.scss";

const ReactLoader = (props) => {
  if (props.position) {
    loaderOptions.top = `${props.position}%`;
  }

  return (
    <Loader
      className="reactLoader"
      loaded={props.loaded}
      options={loaderOptions}
    >
      {props.children}
    </Loader>
  );
};

ReactLoader.propTypes = {
  loaded: PropTypes.bool.isRequired,
  position: PropTypes.number,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default ReactLoader;
