import { useNavigate } from "#routers/hooks";
import { linkToFacilityDetail } from "#routes/organization/facilities/[facilityId]";
import { FacilityContext } from "#src/contexts/FacilityContext";
import useLocalization from "#src/hooks/useLocalization";
import { KeyValuePanel, Link } from "@validereinc/common-components";
import React, { useContext } from "react";

export const RecordFacilitySummaryPanel = () => {
  const navigate = useNavigate();
  const { localize } = useLocalization();
  const { facility } = useContext(FacilityContext) ?? {};

  return (
    <KeyValuePanel
      panelMaxColumnCount={2}
      panelKeyValueListProps={{ variant: "shaded" }}
      panelProps={{ title: "Details", isFluidY: false }}
      data={[
        {
          title: localize("Facility"),
          value: (
            <Link
              onClick={() =>
                navigate({
                  pathname: linkToFacilityDetail(facility?.id),
                })
              }
              label={facility?.name ?? "-"}
            />
          ),
        },
      ]}
    />
  );
};
