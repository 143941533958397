import { linkToAssetDetailPage } from "#utils/links";
import {
  ActivityAction,
  ActivityType,
  AssetType,
  Device,
  EquipmentDomain,
  FacilityDomain,
  FlowDomain,
  UserType,
} from "@validereinc/domain";
import { datetimeFormatter } from "@validereinc/utilities";
import get from "lodash/get";
import startCase from "lodash/startCase";

// IMPROVE: this entire file and all of its functions need a refactor + cleanup
// - lots of inefficencies, bad names that don't communicate intent clearly, and
//   typing mishaps
export function getAssetTypeForDisplay(item: ActivityType) {
  const { resource_type } = item;

  if (!resource_type) {
    return null;
  }

  return startCase(resource_type);
}

export function getTransformedAssetName(item) {
  const { action } = item;

  if (!action) {
    return null;
  }

  if (action === ActivityAction.DELETE) {
    return item.before?.name;
  }

  return item.after?.name;
}

export function getActionForDisplay(item: ActivityType) {
  const { action } = item;

  if (!action) {
    return null;
  }
  return action.charAt(0)?.toUpperCase() + action.slice(1) + "d";
}

const compareItems = (item1: unknown, item2: unknown) => {
  try {
    if (JSON.stringify(item1) !== JSON.stringify(item2)) {
      return false;
    }
  } catch (error) {
    /** Before and/or after cannot be stringified; do a naive comparison */
    if (item1 !== item2) {
      return false;
    }
  }
  return true;
};

/** Determine if an attribute in the change log is "empty" */
/** This includes null, undefined, "" and [] */
const propertyIsNullish = (object: unknown, key: string) =>
  get(object, key) == null || get(object, key)?.length === 0;

export function getAttributes(beforeObj = {}, afterObj = {}) {
  const attributesChanges = [];

  const allKeys = new Set([
    ...Object.keys(beforeObj),
    ...Object.keys(afterObj),
  ]);

  for (const key of allKeys) {
    const isArray =
      Array.isArray(beforeObj[key]) || Array.isArray(afterObj[key]);

    if (
      (propertyIsNullish(beforeObj, key) || propertyIsNullish(afterObj, key)) &&
      !(propertyIsNullish(beforeObj, key) && propertyIsNullish(afterObj, key))
    ) {
      /** One of before or after is nullish, but not both */
      attributesChanges.push(key);
    }
    if (
      !propertyIsNullish(beforeObj, key) &&
      !propertyIsNullish(afterObj, key)
    ) {
      if (!isArray) {
        if (!compareItems(beforeObj?.[key], afterObj?.[key])) {
          /** Before and after both exist, are not an array, and have different JSON representations */
          attributesChanges.push(key);
        }
      } else {
        if (
          !Array.isArray(beforeObj?.[key]) ||
          !Array.isArray(afterObj?.[key])
        ) {
          /** Before and after both exist, one is an array, and the other isn't */
          attributesChanges.push(key);
        }

        if (Array.isArray(beforeObj?.[key]) && Array.isArray(afterObj?.[key])) {
          if (!arraysAreEqual(beforeObj?.[key], afterObj?.[key])) {
            /** Before and after both exist and are arrays, with different contents */
            attributesChanges.push(key);
          }
        }
      }
    }
  }
  return attributesChanges;
}

function arraysAreEqual(array1 = [], array2 = []) {
  if (array1?.length !== array2?.length) {
    return false;
  }

  const sortedArray1 = [...array1].sort();
  const sortedArray2 = [...array2].sort();

  for (let i = 0; i < sortedArray1?.length; i++) {
    if (!compareItems(sortedArray1?.[i], sortedArray2?.[i])) {
      return false;
    }
  }

  return true;
}

export function getListOfChangedAttributes(item: ActivityType) {
  if (item?.action !== ActivityAction.UPDATE) {
    return null;
  }

  const { before: beforeObj, after: afterObj } = item;

  //Top Level Attributes

  const topLevelAttributes = getAttributes(beforeObj, afterObj);

  //Custom Level Attributes

  const customLevelAttributes = getAttributes(
    beforeObj.custom_attributes,
    afterObj.custom_attributes
  );

  //Level Attributes (For equipment and devices)

  const levelAttributes = getAttributes(
    beforeObj.attributes,
    afterObj.attributes
  );

  // Configuration Attributes (For Record Values)
  const configurationAttributes = getAttributes(
    beforeObj.configuration,
    afterObj.configuration
  );

  const result = [
    ...topLevelAttributes,
    ...customLevelAttributes,
    ...levelAttributes,
    ...configurationAttributes.filter((attribute) => attribute !== "value"),
  ].filter(
    (key) =>
      key !== "custom_attributes" &&
      key !== "attributes" &&
      (item.resource_type !== "record_value" || key !== "configuration")
  );

  return result?.length > 0 ? result : null;
}

export function formatAnArrayOfStrings(inputArray) {
  if (!inputArray) {
    return null;
  }

  return inputArray.join(", ");
}

export function getTransformedDate(item) {
  const { timestamp } = item;

  if (!timestamp) {
    return null;
  }

  return datetimeFormatter(new Date(timestamp));
}

export function getTransformedUser(users: UserType[] = [], item: ActivityType) {
  const { author_id } = item;

  if (!author_id) {
    return null;
  }

  const matchingUser = users?.find(
    (user: UserType) => user.id === author_id
  )?.name;

  return matchingUser;
}

export function getPath(item: ActivityType) {
  const { resource_type, resource_id } = item;

  if (!resource_type || !resource_id) {
    return null;
  }
  switch (resource_type) {
    case AssetType.ASSET_GROUP:
    case AssetType.EQUIPMENT:
    case AssetType.FACILITY:
    case AssetType.FLOW:
    case AssetType.DEVICE:
      return linkToAssetDetailPage(resource_type, resource_id);
    default:
      return null;
  }
}

export function getFacilityName(item: ActivityType) {
  const { resource_type, action, after, before } = item;

  if (!resource_type || !action) {
    return null;
  }

  if (
    resource_type === AssetType.FLOW ||
    resource_type === AssetType.FACILITY
  ) {
    return null;
  }

  // TODO: Confirm what to do with backend
  if (action === ActivityAction.UPDATE) {
    return null;
  }

  return after?.facility?.name || before?.facility?.name || "";
}

export function getFacilityId(item: ActivityType) {
  const { resource_type, action, after, before } = item;

  if (!resource_type || !action) {
    return null;
  }

  if (
    resource_type === AssetType.FLOW ||
    resource_type === AssetType.FACILITY
  ) {
    return null;
  }

  // TODO: Confirm what to do with backend
  if (action === ActivityAction.UPDATE) {
    return null;
  }

  return after?.facility?.id || before?.facility?.id || "";
}

export function getUpstreamName(item) {
  // Case 1: Equipment id
  if (item.upstream_equipment) {
    return item.upstream_equipment?.name || "";
  }

  // Case 2: Facility id
  if (item.upstream_facility) {
    return item.upstream_facility?.name || "";
  }

  // Case 3: Flow ids
  if (item.upstream_flows?.length > 0) {
    // Only show name and link if it's only one
    if (item.upstream_flows?.length === 1) {
      return item.upstream_flows[0]?.name || "";
    }

    // Show multiple if it has more than one
    return "multiple";
  }

  return "";
}

export function getDownstreamName(item) {
  // Case 1: Equipment id
  if (item.downstream_equipment) {
    return item.downstream_equipment?.name || "";
  }

  // Case 2: Facility id
  if (item.downstream_facility) {
    return item.downstream_facility?.name || "";
  }

  // Case 3: Flow ids
  if (item?.downstream_flows?.length > 0) {
    // Only show name and link if it's only one
    if (item?.downstream_flows?.length === 1) {
      return item.downstream_flows[0]?.name || "";
    }

    // Show multiple if it has more than one
    return "multiple";
  }

  return "";
}

export async function getIsDeleted(item: ActivityType) {
  const { resource_type, resource_id } = item;

  if (!resource_type || !resource_id) {
    return null;
  }

  switch (resource_type) {
    case AssetType.EQUIPMENT:
      return EquipmentDomain.getEquipmentById({
        equipmentId: resource_id,
      });

    case AssetType.FACILITY:
      return FacilityDomain.getOne({
        id: resource_id,
      });

    case AssetType.FLOW:
      return FlowDomain.getFlow({
        flowId: resource_id,
      });

    case AssetType.DEVICE:
      return Device.getOne({
        id: resource_id,
      }).then((r) => r.data);

    default:
      return null;
  }
}
