import { getListOfChangedAttributes } from "#src/routes/change-log/ActivityLogsPage.helpers";
import {
  renderRecordValueAttachment,
  renderRecordValueConfigurationType,
  renderRecordValueNote,
  renderRecordValueSource,
  renderRecordValueSourceType,
  renderRecordValueStatus,
} from "#src/utils/recordUtils";
import { KeyValueList } from "@validereinc/common-components";
import {
  RecordValueType,
  Resources,
  type ActivityType,
} from "@validereinc/domain";
import classNames from "classnames/bind";
import get from "lodash/get";
import React, { ReactNode } from "react";
import styles from "./ActivityLogsUpdateDetailsKeyValueList.module.scss";

const cx = classNames.bind(styles);

export const ActivityLogsUpdateDetailsKeyValueList = ({
  selectedLog,
}: {
  selectedLog: ActivityType | null;
}) => {
  if (!selectedLog) {
    return null;
  }

  const { before, after } = selectedLog;

  const uniqueChangedAttributes = getListOfChangedAttributes(selectedLog);

  const getKeyValuePerAttribute = (attribute: string) => {
    let getValue: (item: RecordValueType) => ReactNode = (item) =>
      get(item, attribute);

    if (selectedLog.resource_type === Resources.RECORD_VALUE) {
      switch (attribute) {
        case "sources":
          getValue = renderRecordValueSource;
          break;
        case "source_type":
          getValue = renderRecordValueSourceType;
          break;
        case "configuration_type":
          getValue = renderRecordValueConfigurationType;
          break;
        case "attachment":
          getValue = renderRecordValueAttachment;
          break;
        case "status":
          getValue = renderRecordValueStatus;
          break;
        case "note":
          getValue = renderRecordValueNote;
          break;
      }
    }

    const previousValue =
      getValue(before) ||
      before?.custom_attributes?.[attribute] ||
      before?.attributes?.[attribute] ||
      before?.configuration?.[attribute] ||
      null;

    const updatedValue =
      getValue(after) ||
      after?.custom_attributes?.[attribute] ||
      after?.attributes?.[attribute] ||
      after?.configuration?.[attribute] ||
      null;

    return [
      {
        title: "Previous Value",
        value: previousValue,
      },
      {
        title: "Updated Value",
        value: updatedValue,
      },
    ];
  };

  return (
    <>
      {uniqueChangedAttributes?.map((attribute) => {
        return (
          <>
            <h1 className={cx("title")}>{attribute}</h1>
            <KeyValueList
              data={getKeyValuePerAttribute(attribute)}
              className={cx("keyValueListContainer")}
            />
          </>
        );
      })}
    </>
  );
};
