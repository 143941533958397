import history from "#routers/history";
import {
  linkToCreateProductionReports,
  linkToProductionReports,
} from "#routers/links";
import { getPluralizedString } from "#utils/stringFormatter";
import {
  LegacyDataTable,
  Title,
  Tooltip,
} from "@validereinc/common-components";
import classNames from "classnames/bind";
import startCase from "lodash/startCase";
import * as PropTypes from "prop-types";
import React from "react";
import { AutoSizer } from "react-virtualized";
import styles from "./ProductionReportsTable.module.scss";
import {
  getSourceDetailOfIdList,
  searchByName,
  sortByProperties,
  sortByStreams,
  sortProperties,
  STREAM_TYPES,
} from "./ProductionReportsTableHelper";

const cx = classNames.bind(styles);

const ReportsTable = ({
  streams,
  inventories,
  productionReports,
  filterRow,
  filterPillbox,
}) => {
  const streamRenderer = (rowData) => {
    const streamsByType = STREAM_TYPES.map((columnKey) => {
      const streamsList = getSourceDetailOfIdList(
        streams,
        rowData[`${columnKey}_streams`]
      );
      const inventoryList = getSourceDetailOfIdList(
        inventories,
        rowData[`${columnKey}_inventories`]
      );
      const numSources = streamsList.length + inventoryList.length;
      return { streamsList, inventoryList, numSources, columnKey };
    });

    const numSources = streamsByType
      .map(({ numSources }) => numSources)
      .reduce((accumulator, addend) => accumulator + addend, 0);

    if (numSources) {
      const tooltip = (
        <div className={cx("tooltipContent")}>
          {streamsByType.map(({ streamsList, inventoryList, columnKey }) => (
            <>
              {streamsList.length ? (
                <>
                  <Title
                    className={cx("tooltipTitle")}
                    type="subheader"
                    style={{ color: "white" }}
                  >
                    {startCase(columnKey)} Streams
                  </Title>
                  <ul>
                    {streamsList.map((stream) => (
                      <li key={stream?.id}>{stream?.name ?? "-"}</li>
                    ))}
                  </ul>
                </>
              ) : null}

              {inventoryList.length ? (
                <>
                  <Title
                    className={cx("tooltipTitle")}
                    type="subheader"
                    style={{ color: "white" }}
                  >
                    {startCase(columnKey)} Inventories
                  </Title>
                  <ul>
                    {inventoryList.map((inventory) => (
                      <li key={inventory?.id}>{inventory?.name ?? "-"}</li>
                    ))}
                  </ul>
                </>
              ) : null}
            </>
          ))}
        </div>
      );

      return (
        <Tooltip
          content={tooltip}
          trigger="click"
        >
          <span className={cx("tooltipBase")}>
            {`${numSources} ${getPluralizedString("stream")(numSources)}`}
          </span>
        </Tooltip>
      );
    }

    return "-";
  };

  const propertyRenderer = (rowData, columnKey) => {
    const properties = rowData[columnKey];
    const numProperties = properties?.length ?? 0;

    if (numProperties) {
      const tooltip = (
        <ul>
          {sortProperties(properties).map((property) => (
            <li key={property}>{property}</li>
          ))}
        </ul>
      );

      return (
        <Tooltip
          content={tooltip}
          title="Properties"
        >
          <span className={cx("tooltipBase")}>
            {`${numProperties} ${getPluralizedString(
              "property",
              "properties"
            )(numProperties)}`}
          </span>
        </Tooltip>
      );
    }

    return "-";
  };

  const onCellClick = (id) => {
    history.push(linkToProductionReports(id));
  };

  const Headers = [
    {
      label: "Name",
      key: "name",
      width: 250,
      fixed: true,
    },
    {
      label: "Streams",
      key: "inlet",
      width: 150,
      cellRenderer: streamRenderer,
      sort: sortByStreams,
    },
    {
      label: "Properties",
      key: "components",
      width: 150,
      cellRenderer: propertyRenderer,
      sort: sortByProperties,
    },
  ];

  const actionDropdown = [
    {
      title: "View Report",
      onClick: (rowData) => onCellClick(rowData.id),
    },
  ];

  return (
    <AutoSizer>
      {({ height, width }) => (
        <LegacyDataTable
          width={width}
          height={height}
          headers={Headers}
          list={productionReports}
          filterKey="name"
          filterTitle="Name"
          defaultSortBy="from"
          defaultSortDirection="desc"
          rowHeight={60}
          filterRow={filterRow}
          filterPillbox={filterPillbox}
          customSearch={searchByName}
          onAddClick={() => history.push(linkToCreateProductionReports())}
          addButtonName="Production Report"
          onCellClick={(rowData) => onCellClick(rowData.id)}
          actionDropdown={actionDropdown}
        />
      )}
    </AutoSizer>
  );
};

ReportsTable.propTypes = {
  streams: PropTypes.array.isRequired,
  inventories: PropTypes.array.isRequired,
  productionReports: PropTypes.array.isRequired,
  filterRow: PropTypes.object.isRequired,
  filterPillbox: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default ReportsTable;
