import { useNavigate, useSearchParams } from "#routers/hooks";
import { linkToCreateEquipment } from "#routers/links";
import { EquipmentList } from "#routes/organization/equipment/EquipmentList";
import {
  EquipmentRecordFilterPanel,
  RecordsListTablePanel,
} from "#src/batteries-included-components";
import { ApplyAutomationProgressBanner } from "#src/batteries-included-components/Banners/ApplyAutomationProgressBanner";
import { ImportDataActionStatusWithQueue } from "#src/batteries-included-components/Banners/ImportDataActionStatus";
import { IsFeatureAvailable } from "#src/batteries-included-components/Layouts/Authorization/IsFeatureAvailable";
import { useIsFeatureAvailable } from "#src/contexts/AuthenticatedContext.helpers";
import useLocalization from "#src/hooks/useLocalization";
import { useStorageKey } from "#src/hooks/useStorageKey";
import { EquipmentListRoute } from "#src/routes/organization/equipment";
import { useBreadcrumbsFromRoute } from "#src/utils/route";
import { Button, Page, Tab } from "@validereinc/common-components";
import { AssetType } from "@validereinc/domain";
import isEqual from "lodash/isEqual";
import React, { useState } from "react";

const DEFAULT_TAB_KEY = "list";

export const EquipmentListPage = () => {
  const [isDataIngestionEnabled] = useIsFeatureAvailable({
    featureFlagQuery: "core:data_pipeline",
  });
  const recordsStorageKeys = useStorageKey("equipment-records");
  const recordsApplyAutomationStorageKey = useStorageKey(
    "equipment-records-apply-automation"
  ).tableConfigStorageKey;
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { localize, isLoading } = useLocalization();
  const [selectableProperties, setSelectableProperties] = useState<string[]>();
  const [breadcrumbs] = useBreadcrumbsFromRoute(EquipmentListRoute);

  function handleOnCreateEquipmentClick() {
    navigate({
      pathname: linkToCreateEquipment(),
    });
  }

  const actionRow = [
    <IsFeatureAvailable
      key="create-equipment"
      featureFlagQuery="core:equipment"
      permissionQuery="equipment:write"
      fallbackChildren={null}
    >
      <Button
        variant="primary"
        onClick={handleOnCreateEquipmentClick}
      >
        Create {localize("Equipment")}
      </Button>
    </IsFeatureAvailable>,
  ];

  return (
    <Page
      title={localize("equipment_plural")}
      breadcrumbs={breadcrumbs}
      isLoading={isLoading}
      actionRow={actionRow}
      activeTabKey={searchParams?.tab ?? DEFAULT_TAB_KEY}
      onActiveTabKeyChange={(tabKey: string) => {
        setSearchParams({ tab: tabKey });
      }}
    >
      <Tab
        title="List"
        tabKey="list"
      >
        <EquipmentList />
      </Tab>
      <Tab
        title="Records"
        tabKey="records"
      >
        {isDataIngestionEnabled ? (
          <ImportDataActionStatusWithQueue
            resource={{
              id: "record",
              name: "EquipmentRecord",
              singular: `${localize("equipment")} Record`,
              plural: `${localize("equipment")} Records`,
            }}
          />
        ) : null}
        <ApplyAutomationProgressBanner
          jobStorageKey={recordsApplyAutomationStorageKey}
        />
        <EquipmentRecordFilterPanel
          filterConfigStorageKey={recordsStorageKeys.filterConfigStorageKey}
          viewConfigStorageKey={recordsStorageKeys.viewConfigStorageKey}
          selectableProperties={selectableProperties}
        />
        <RecordsListTablePanel
          type={AssetType.EQUIPMENT}
          {...recordsStorageKeys}
          applyAutomationStorageKey={recordsApplyAutomationStorageKey}
          setAvailableProperties={(keys) => {
            if (isEqual(keys, selectableProperties)) return;
            setSelectableProperties(keys);
          }}
        />
      </Tab>
    </Page>
  );
};
