import moment from "moment";
import {
  NODE_API_MAX_PAGE_SIZE,
  nodeApiRequestServicesPromise,
} from "./ServiceHelper";
import config from "#src/config";
import { getUTCTimeString } from "#utils/timeFormatter";

import type { PaginationAndSortParams } from "./types";

const MeasurementsService = {
  getMeasurementTypes() {
    return nodeApiRequestServicesPromise("/measurement_types");
  },

  getMeasurementSources({
    deviceId,
    page,
    rowPerPage,
    sort,
    sortDirection,
  }: Partial<PaginationAndSortParams & { deviceId: string }>) {
    return nodeApiRequestServicesPromise("/measurement_series", {
      device_id: deviceId,
      page,
      page_size: rowPerPage,
      sort_by: sort,
      sort_direction: sortDirection,
    });
  },

  getMeasurementSourcesDetail(measurement_series_id) {
    return nodeApiRequestServicesPromise(
      `/measurement_series/${measurement_series_id}`
    );
  },

  getAggregatedMeasurements(
    deviceId,
    {
      groupBy,
      interval,
      func,
      start,
      end,
      page,
      rowPerPage,
      sort,
      sortDirection,
    }
  ) {
    return nodeApiRequestServicesPromise(`/measurements`, {
      device_id: deviceId,
      group_by: groupBy,
      interval,
      func,
      // interpret the start/end datetimes as assumed to be UTC
      start: moment.utc(start).toISOString(),
      end: moment.utc(end).toISOString(),
      page,
      page_size: rowPerPage,
      sort_by: sort,
      sort_direction: sortDirection,
    });
  },

  getMeasurementList(
    measurementSourceId,
    {
      start,
      end,
      page,
      rowPerPage,
      sort,
      sortDirection,
      groupBy,
      interval,
      func,
    } = {}
  ) {
    return nodeApiRequestServicesPromise(
      `/measurements/${measurementSourceId}`,
      {
        start: getUTCTimeString(start, config.DATETIME_NO_TIMEZONE_FORMAT),
        end: getUTCTimeString(end, config.DATETIME_NO_TIMEZONE_FORMAT),
        page,
        page_size: rowPerPage,
        sort_by: sort,
        sort_direction: sortDirection,
        group_by: groupBy,
        interval,
        func,
      }
    );
  },

  getMeasurementSource(measurementSourceId) {
    return nodeApiRequestServicesPromise(
      `/measurement_series/${measurementSourceId}`
    );
  },

  // Iterate through the measurements list API until an incomplete page is encountered
  async getAllMeasurements(deviceId, { start, end, ...params }) {
    const cumulativeResult = [];
    let currentPage = 1;
    let result;

    do {
      result = await this.getAggregatedMeasurements(deviceId, {
        ...params,
        page: currentPage,
        rowPerPage: NODE_API_MAX_PAGE_SIZE,
        // convert the datetimes to UTC and format in ISO
        start: getUTCTimeString(start),
        end: getUTCTimeString(end),
      });
      cumulativeResult.push(result);
      currentPage += 1;
    } while (result?.data?.data?.length === NODE_API_MAX_PAGE_SIZE);

    const totalData = cumulativeResult.flatMap(({ data: { data } }) => data);

    return {
      status: 200,
      data: {
        page_size: totalData.length,
        page_number: 1,
        total_entries: totalData.length,
        total_pages: 1,
        data: totalData,
      },
    };
  },
};

export default MeasurementsService;
