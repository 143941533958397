import { useParams } from "#routers/hooks";
import { RecordContext } from "#src/contexts/RecordContext";
import { useQuery } from "@tanstack/react-query";
import {
  FormResponseDataItemType,
  MeasurementTypeType,
  MeasurementsDomain,
  RecordType,
  RecordValueType,
  UserType,
} from "@validereinc/domain";
import React, { createContext, useContext, useMemo } from "react";

export const RecordValueContext = createContext<{
  record?: RecordType;
  users?: UserType[];
  sources: FormResponseDataItemType[];
  recordValue?: RecordValueType;
  measurementType?: MeasurementTypeType;
  isLoading: boolean;
} | null>(null);

export const RecordValueProvider = ({
  children,
}: {
  children: React.ReactElement | React.ReactElement[];
}) => {
  const parentContext = useContext(RecordValueContext);
  const { record, users, isLoading } = useContext(RecordContext) ?? {};

  const { measurementType: measurementTypeId } = useParams<{
    measurementType: string;
  }>();

  const recordValue = useMemo(
    () =>
      record?.values.find(
        ({ measurement_type }) => measurement_type === measurementTypeId
      ),
    [record, measurementTypeId]
  );

  const measurementTypesQuery = useQuery({
    queryKey: ["measurementTypes"],
    queryFn: () => MeasurementsDomain.listMeasurementTypes(),
    enabled: !parentContext,
  });

  const measurementType = useMemo(
    () =>
      measurementTypesQuery.data?.find(({ id }) => id === measurementTypeId),
    [measurementTypesQuery.data, measurementTypeId]
  );

  return (
    <RecordValueContext.Provider
      value={
        parentContext ?? {
          record,
          users,
          recordValue,
          measurementType,
          isLoading: isLoading ?? measurementTypesQuery.isLoading,
        }
      }
    >
      {children}
    </RecordValueContext.Provider>
  );
};
