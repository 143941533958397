import { apiRequestServicesPromise } from "./ServiceHelper";

const InventoryService = {
  getInventories() {
    return apiRequestServicesPromise("/api/tanks");
  },

  getInventoryDetail(inventoryId, datetime) {
    return apiRequestServicesPromise(`/api/tanks/${inventoryId}`, {
      datetime,
    });
  },

  getVolumeChart(inventoryId, from, until) {
    const parameters = {
      from,
      until,
    };

    return apiRequestServicesPromise(
      `api/tanks/${inventoryId}/volumes/chart`,
      parameters
    );
  },
};

export default InventoryService;
