import config from "#config";
import {
  useProductionReportDetail,
  useProductionReportInfo,
} from "#hooks/useProductionReports";
import { getBreadcrumbsObject } from "#routers/breadcrumbsHelper";
import { getUTCTimeString } from "#utils/timeFormatter";
import { Button, Page } from "@validereinc/common-components";
import classNames from "classnames/bind";
import moment from "moment";
import * as PropTypes from "prop-types";
import React from "react";
import PeriodSelect from "../../PeriodSelect";
import styles from "./ProductionReportDetail.module.scss";
import { getProductionReportTitle } from "./ProductionReportHelper";
import StreamsPanel from "./StreamsPanel";
import ProductionReportSummary from "./Summary/ProductionReportSummary";

const cx = classNames.bind(styles);

const FIRST_DAY_OF_CURRENT_MONTH = moment().startOf("month").startOf("day");

const LAST_DAY_OF_CURRENT_MONTH = moment().endOf("month").startOf("day");

const DEFAULT_DATE_RANGE = {
  periodFrom: getUTCTimeString(FIRST_DAY_OF_CURRENT_MONTH, config.DATE_FORMAT),
  periodTo: getUTCTimeString(LAST_DAY_OF_CURRENT_MONTH, config.DATE_FORMAT),
};

const ProductionReportDetail = ({ height, breadcrumbs, match }) => {
  const productionReportId = match.params.id;
  const [productionReportDetail, detailsFetchState] = useProductionReportDetail(
    productionReportId,
    DEFAULT_DATE_RANGE
  );

  const [productionReport, reportFetchState] =
    useProductionReportInfo(productionReportId);

  const breadcrumbObject = getBreadcrumbsObject(
    [...breadcrumbs, { title: getProductionReportTitle(productionReport) }],
    match.params
  );

  const onExportClick = () => {
    // Export Logic
  };

  const onApproveClick = () => {
    // Approve Logic
  };

  const actionRow = (
    <div className={cx("actionRow")}>
      <Button onClick={onExportClick}>Export</Button>
      <Button
        variant="primary"
        onClick={onApproveClick}
      >
        Approve
      </Button>
    </div>
  );

  return (
    <Page
      title={getProductionReportTitle(productionReport)}
      breadcrumbs={breadcrumbObject}
      isLoading={
        detailsFetchState !== "loaded" || reportFetchState !== "loaded"
      }
      actionRow={actionRow}
    >
      <div className={cx("dropdownContainer")}>
        <PeriodSelect
          defaultDateRange={DEFAULT_DATE_RANGE}
          hoverRange="monthly"
        />
      </div>
      <ProductionReportSummary
        productionReportDetail={productionReportDetail}
        productionReportInfo={productionReport}
      />
      <StreamsPanel
        height={height - 300}
        report={productionReport}
        defaultDateRange={DEFAULT_DATE_RANGE}
        title="Records"
        match={match}
      />
    </Page>
  );
};

ProductionReportDetail.propTypes = {
  height: PropTypes.number.isRequired,
  match: PropTypes.object.isRequired,
  breadcrumbs: PropTypes.array.isRequired,
};

export default ProductionReportDetail;
