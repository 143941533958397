import { FacilityDropdownInput } from "#batteries-included-components/Dropdowns/FacilityDropdownInput";
import AttributeFieldList, {
  ATTRIBUTE_FIELD_TYPES,
} from "#common/AttributeFieldList/AttributeFieldList";
import CustomAttributeField from "#common/CustomAttributeField";
import FacilityService from "#components/Services/FacilityService";
import { useGetOneEquipment } from "#hooks/adapters/useEquipment";
import useLocalization from "#src/hooks/useLocalization";
import {
  DateInput,
  DropdownInput,
  Form,
  Panel,
  SelectInput,
  TextInput,
} from "@validereinc/common-components";
import classNames from "classnames/bind";
import * as PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import styles from "./EquipmentForm.module.scss";

// constants
import { EQUIPMENT_STATUS_OPTIONS, QUERY_STATUS } from "#constants";
import { CustomAttributeType } from "@validereinc/domain";

const cx = classNames.bind(styles);

const EquipmentForm = ({
  form,
  formState,
  facilitiesLoadingState,
  equipmentTypes,
  equipmentTypesLoadingState,
  handleSubmitClick,
}) => {
  const [customAttributes, setCustomAttributes] = useState([]);
  const { localize } = useLocalization();

  const { type } = form.watch();
  const sharedProps = {
    isDisabled:
      formState !== "loaded" ||
      facilitiesLoadingState !== "loaded" ||
      equipmentTypesLoadingState !== QUERY_STATUS.SUCCESS ||
      form.formState.isSubmitting,
  };

  const { equipmentId } = useParams<{ equipmentId: string }>();
  const { data: equipment } = useGetOneEquipment(equipmentId);

  useEffect(() => {
    (async () => {
      const {
        data: { data },
      } = await FacilityService.getEquipmentCustomAttributes();

      setCustomAttributes(data);
    })();
  }, []);

  useEffect(() => {
    if (form) {
      const currentValues = form.getValues("custom_attributes") ?? {};
      const newAttributeValues = Object.keys(currentValues).reduce<
        Record<string, any>
      >((acc, key) => {
        if (currentValues[key]) acc[key] = "";
        return acc;
      }, {});

      const customAttributeValues = {
        ...newAttributeValues,
        ...equipment?.custom_attributes,
      };

      // When changing equipment type, strip away all the custom attributes
      // that don't exist on the new type:
      const nonExistingCustomAttribsOnNewType = type
        ? (customAttributes ?? [])
            .filter(
              ({ entity_subtype }) =>
                entity_subtype && entity_subtype !== type.id
            )
            .map((ca: CustomAttributeType) => ca.field_name)
        : [];

      Object.keys(customAttributeValues).forEach((key: string) => {
        if (nonExistingCustomAttribsOnNewType.includes(key))
          delete customAttributeValues[key];
      });

      form.setValue("custom_attributes", customAttributeValues, {
        shouldTouch: true,
      });
    }
  }, [equipment, type, customAttributes]);

  return (
    <Panel>
      <Form
        {...form}
        onSubmit={handleSubmitClick}
      >
        <h4 className="sectionTitle">{localize("Equipment")} Details</h4>
        <div className={cx("formContainer")}>
          <FacilityDropdownInput
            isMulti={false}
            isFluid
            isOptionalTextShown={false}
            name="facility_id"
          />
          <TextInput
            {...sharedProps}
            name="name"
            label={`${localize("Equipment")} Name`}
            isRequired
          />
          <DropdownInput
            label="Status"
            name="status"
            options={EQUIPMENT_STATUS_OPTIONS}
            labelKey="label"
            valueKey="value"
            isRequired
            isFluid
          />
          <DateInput
            {...sharedProps}
            name="effective_date"
            label="Effective Date"
            isRequired
            isDisabled={sharedProps.isDisabled}
          />
          {equipmentTypes && (
            <SelectInput
              {...sharedProps}
              isRequired
              name="type"
              label={`${localize("Equipment")} Type`}
              labelKey="name"
              options={equipmentTypes}
              ignoreCase
              isLoading={!equipmentTypes?.length}
              isDisabled={sharedProps.isDisabled}
            />
          )}
          <AttributeFieldList
            attributeFieldTypes={[ATTRIBUTE_FIELD_TYPES.GEO_POINT]}
          />
          {customAttributes?.map(({ field_name, ...restAttribute }) => (
            <CustomAttributeField
              {...sharedProps}
              key={`custom_attributes.${field_name}`}
              attribute={{ field_name, ...restAttribute }}
              subtype={type ? type.id : null}
            />
          ))}
        </div>
      </Form>
    </Panel>
  );
};

EquipmentForm.propTypes = {
  form: PropTypes.object.isRequired,
  formType: PropTypes.string.isRequired,
  formState: PropTypes.string.isRequired,
  facilities: PropTypes.array.isRequired,
  facilitiesLoadingState: PropTypes.string.isRequired,
  equipmentTypes: PropTypes.array.isRequired,
  equipmentTypesLoadingState: PropTypes.string.isRequired,
  handleSubmitClick: PropTypes.func.isRequired,
};

export default EquipmentForm;
