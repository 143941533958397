import React, { createContext, useEffect, useState } from "react";

import { EventSchemaDomain } from "@validereinc/domain";

import type { EventSchemaType } from "@validereinc/domain";

export const EventTemplateContext = createContext<{
  eventTemplate: EventSchemaType | undefined;
  isLoading: boolean;
} | null>(null);

export const EventTemplateProvider = ({
  eventTemplateId,
  children,
}: {
  eventTemplateId: string;
  children: React.ReactElement | React.ReactElement[];
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [eventTemplate, setEventTemplate] = useState<EventSchemaType>();

  const fetchEventTemplate = async () => {
    try {
      setIsLoading(true);

      const newEventTemplate = await EventSchemaDomain.getOne({
        eventSchemaId: eventTemplateId,
      });

      setEventTemplate(newEventTemplate);
    } catch (caught) {
      console.error(caught);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (eventTemplateId) {
      fetchEventTemplate();
    }
  }, [eventTemplateId]);

  return (
    <EventTemplateContext.Provider
      value={{
        eventTemplate,
        isLoading,
      }}
    >
      {children}
    </EventTemplateContext.Provider>
  );
};
