import { useGetOneWorkflowTemplate } from "#src/components/hooks/adapters/useWorkflowTemplates";
import useLocalization from "#src/hooks/useLocalization";
import { ExceptionUtils } from "#src/utils/exception";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  Button,
  CheckboxInput,
  Dialog,
  DialogProps,
  DropdownInput,
  Form,
  TextInput,
  useAlert,
  useForm,
} from "@validereinc/common-components";
import {
  AssetAdapter,
  CreateWorkflowType,
  WorkflowAdapter,
  CreateWorkflowSchema,
} from "@validereinc/domain";
import { DateFormats, isFiniteNumber } from "@validereinc/utilities";
import classNames from "classnames/bind";
import { add, format } from "date-fns";
import { startCase } from "lodash";
import React, { useEffect } from "react";
import styles from "./CreateWorkflowDialog.module.scss";

const cx = classNames.bind(styles);

const CREATE_WORKFLOW_DIALOG_TITLE = "Create Workflows";
const WORKFLOW_DIALOG_DESCRIPTION =
  "Workflows created manually will inherit all configurations set in the workflow's template including facility assignment and due date definition.";
const WORKFLOW_DIALOG_FORECAST_LABEL = "Forecast Workflow(s) Due Date";
const WORKFLOW_DIALOG_DUE_DATE_LABEL = "Workflow(s) Due Date";
const WORKFLOW_DUE_DATE_OPTIONS = [
  { label: "Hour(s) from creation", value: "hours" },
  { label: "Day(s) from creation", value: "days" },
  { label: "Week(s) from creation", value: "weeks" },
  { label: "Month(s) from creation", value: "months" },
];

export const CreateWorkflowDialog = ({
  onClose,
  templateId,
}: Pick<DialogProps, "onClose"> & {
  templateId: string;
}) => {
  const { addAlert } = useAlert();
  const { localize } = useLocalization();
  const queryClient = useQueryClient();
  const form = useForm<CreateWorkflowType>();

  const { data } = useGetOneWorkflowTemplate(
    {
      id: templateId,
    },
    { enabled: Boolean(templateId) }
  );

  const templateDetails = data?.data;

  const templateDueDate = templateDetails?.triggers[0]?.due_date;
  useEffect(() => {
    form.setValue("has_due_date", !!templateDueDate);
  }, [templateDueDate]);

  const mutation = useMutation({
    mutationFn: ({
      asset_ids,
      has_due_date,
      time_period,
      duration,
    }: CreateWorkflowType) => {
      const workflowsToCreate = asset_ids.map((asset_id) => {
        return {
          asset_id,
          time_period,
          duration,
        };
      });

      const promises = workflowsToCreate.map((workflow) => {
        WorkflowAdapter.trigger({
          asset_id: workflow.asset_id,
          time_period: has_due_date ? workflow.time_period : null,
          duration:
            workflow.duration &&
            isFiniteNumber(workflow.duration) &&
            has_due_date
              ? Number(workflow.duration)
              : null,
          workflow_template_id: templateId,
        });
      });

      return Promise.all(promises);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["workflows", "getList"],
      });

      addAlert?.({
        variant: "success",
        message: "Successfully created workflow(s).",
      });

      onCloseCallback();
    },
    onError: (error: unknown) => {
      ExceptionUtils.reportException(error, "error", {
        hint: "Error creating workflow(s)",
      });
      addAlert?.({
        variant: "error",
        message: "Failed to create workflow(s).",
      });
    },
  });

  const assetTypeForDisplay = localize(startCase(templateDetails?.asset_type));

  const onCloseCallback = () => {
    form.reset();

    onClose();
  };

  const onSubmit = form.handleSubmit((formValues) => {
    mutation.mutate(formValues);
  });

  const onFetchData = async ({
    value,
    pageSize,
    page,
    searchTerm,
  }: {
    value: string[];
    pageSize: number;
    page: number;
    searchTerm?: string;
  }) => {
    const filters = {
      ...templateDetails?.asset_filter,
      ...(searchTerm
        ? { [templateDetails?.asset_type + ".name"]: { $like: searchTerm } }
        : {}),
      ...(value?.length > 0 ? { id: value } : {}),
      isAlreadyFormatted: false,
    };

    const { data } = await AssetAdapter.getList({
      pageSize,
      page,
      filters,
      sortBy: "name",
      sortDirection: "asc",
    });
    return data;
  };
  const { has_due_date, duration, time_period: timePeriod } = form.watch();

  return (
    <Dialog
      isOpen={!!templateId}
      onClose={onCloseCallback}
      title={CREATE_WORKFLOW_DIALOG_TITLE}
      actionRow={[
        <Button
          key="create-form-category-action"
          onClick={onSubmit}
          variant="primary"
        >
          {CREATE_WORKFLOW_DIALOG_TITLE}
        </Button>,
      ]}
    >
      <Form {...form}>
        <p>{WORKFLOW_DIALOG_DESCRIPTION}</p>
        <DropdownInput
          placeholder={"Select " + assetTypeForDisplay + "..."}
          label={assetTypeForDisplay}
          onFetchData={onFetchData}
          name={CreateWorkflowSchema.keyof().Enum.asset_ids}
          inputId={CreateWorkflowSchema.keyof().Enum.asset_ids}
          labelKey="name"
          valueKey="id"
          isRequired
          isMulti
        />
        <div className={cx("checkbox-container")}>
          <CheckboxInput
            name={CreateWorkflowSchema.keyof().Enum.has_due_date}
            inputId={CreateWorkflowSchema.keyof().Enum.has_due_date}
            label="Workflow has a due date"
            isLabelShown={false}
          />
        </div>
        {has_due_date && (
          <>
            <label htmlFor="workflow-due-date">
              <strong id="workflow-due-date">
                {WORKFLOW_DIALOG_DUE_DATE_LABEL}
              </strong>
            </label>
            <div className={cx("dropdown-input-container")}>
              <TextInput
                className={cx("text-input")}
                defaultValue={templateDueDate ? templateDueDate.duration : 1}
                name={CreateWorkflowSchema.keyof().Enum.duration}
                inputId={CreateWorkflowSchema.keyof().Enum.duration}
                type="number"
                formatType="integer"
                isRequired
              />
              <DropdownInput
                className={cx("dropdown-input")}
                placeholder={WORKFLOW_DUE_DATE_OPTIONS[0].label}
                defaultValue={
                  templateDueDate
                    ? templateDueDate.time_period
                    : WORKFLOW_DUE_DATE_OPTIONS[0].value
                }
                options={WORKFLOW_DUE_DATE_OPTIONS}
                isSearchable={false}
                name={CreateWorkflowSchema.keyof().Enum.time_period}
                inputId={CreateWorkflowSchema.keyof().Enum.time_period}
                valueKey="value"
                labelKey="label"
                isRequired
              />
            </div>
            <label htmlFor="workflow-forecast-due-date">
              {WORKFLOW_DIALOG_FORECAST_LABEL}
              <p id="workflow-forecast-due-date">
                <strong>
                  {format(
                    add(new Date(), {
                      [timePeriod ?? WORKFLOW_DUE_DATE_OPTIONS[0].value]:
                        duration,
                    }),
                    DateFormats.DATE_TIME
                  )}
                </strong>
              </p>
            </label>
          </>
        )}
      </Form>
    </Dialog>
  );
};
