import { RoutePath } from "#src/utils/route";
import { UserGroupsRoutePath } from "..";

export const GroupCreateRoutePath = UserGroupsRoutePath.concat(
  new RoutePath({
    path: "/create",
    isPresentational: true,
    title: "Create User Group",
  })
);
