export * from "./CreateRolePage";

export const CREATE_ROLE_PAGE_TITLE = "Create Role";

export const linkToCreateRolePage = () =>
  "/app/settings/roles-and-permissions/roles/create";

export const CREATE_ROLE_BREADCRUMB = {
  title: CREATE_ROLE_PAGE_TITLE,
  path: linkToCreateRolePage,
};
