import { ITEMS_PER_PAGE } from "#constants";
import { PaginationTypes, SortingType } from "@validereinc/common-components";
import { SortDirection } from "@validereinc/domain";
import isEqual from "lodash/isEqual";
import orderBy from "lodash/orderBy";
import { useEffect, useMemo, useReducer, useRef } from "react";

export type TableStateType = PaginationTypes & SortingType;

/**
 * Determines if the page needs to be reset back to 1 and data be fetched for
 * that page instead. Keeps track of any applied filters and the page # + total
 * results during the time of filter change - then sets a flag to indicate that
 * resetting should occur. when this hook runs again, the filters will not have
 * changed so what we're waiting for instead is for the page # or total # of
 * results to be different - if either of those changed, that means no data has
 * come in and we're safe to turn off the resetting flag.
 *
 * The reason we'd want to do this all is because if you're on any page but 1,
 * and apply filters that would return less # of results than the page you're
 * on, with the way the BE APIs work (CarbonHub in particular) you make a
 * request for that page and get no results back - which is incorrect because
 * the total entries usually indicates that there is data... in the smaller
 * pages. The UI also doesn't help in this case because the pagination controls
 * dissapear... so it looks like there indeed is no data for the filters you
 * applied. For this reason, whenever filters change, this hook helps you just
 * reset the page back to 1.
 * @param pageAndTotalRecords the current page and total # of results for that page for any data fetched
 * @param newFilters any filters applied to get the data
 * @returns should the page be reset back to 1 and fetch data for that page instead?
 */
export const useShouldPageReset = (
  pageAndTotalRecords: Pick<PaginationTypes, "page" | "total">,
  newFilters: object = {}
) => {
  const { page: newPage, total: newTotal } = pageAndTotalRecords;
  const filters = useRef(newFilters);
  const pageDuringFilterChange = useRef<number | null>(newPage ?? null);
  const totalDuringFilterChange = useRef<number | null>(newTotal ?? null);
  const shouldReset = useRef(false);

  // if filters changed, sync the ref, flag for resetting page, and remember the page and # of results at this time
  if (!isEqual(filters.current, newFilters)) {
    filters.current = newFilters;
    pageDuringFilterChange.current = newPage;
    totalDuringFilterChange.current = newTotal;
    shouldReset.current = true;
  } else if (
    // if a re-render happens for any reason and the page # or the total # of results has changed
    // that means new data has or is coming in, so we can turn off the resetting flag
    pageDuringFilterChange.current !== newPage ||
    totalDuringFilterChange.current !== newTotal
  ) {
    shouldReset.current = false;
    pageDuringFilterChange.current = null;
    totalDuringFilterChange.current = null;
  }

  return shouldReset.current;
};

export const tableStateReducer = (
  prevState: TableStateType,
  stateUpdate: Partial<TableStateType>
) => ({
  ...prevState,
  ...stateUpdate,
});

/**
 * Keep track of sorting and pagination props, resetting the page back to 1 if filters change
 * @param initialState any combination of sorting / pagination props to use by default during first load
 * @param filters filters applied to fetching data
 * @returns [tableState, updateTableState] - pass tableState to the pagination props in the DataTable. pass updateTableState to the onPaginationChange and onSortChange props of the DataTable
 */
export const useTableSortingAndPagination = (
  initialState: Partial<TableStateType> = {},
  filters?: object
) => {
  const [tableState, updateTableState] = useReducer(tableStateReducer, {
    page: 1,
    itemsPerPage: ITEMS_PER_PAGE,
    total: 0,
    entityPerPage: "Rows per page:",
    sortBy: "created_at",
    sortDirection: SortDirection.DESCENDING,
    ...initialState,
  });

  const shouldResetPage = useShouldPageReset(tableState, filters);

  useEffect(() => {
    if (shouldResetPage) {
      updateTableState({ page: 1 });
    }
  }, [shouldResetPage]);

  return [tableState, updateTableState] as const;
};

/** If the backend returns the entire collection, we can slice and sort ourselves */
export const useClientSideSortingAndPagination = <TData,>(
  data: TData[] = [],
  tableState: TableStateType
) => ({
  items: useMemo(
    () =>
      orderBy(data, [tableState.sortBy], [tableState.sortDirection]).slice(
        tableState.itemsPerPage * (tableState.page - 1),
        tableState.itemsPerPage * tableState.page
      ),
    [data, tableState]
  ),
  pagination: {
    page: tableState.page,
    itemsPerPage: tableState.itemsPerPage,
    total: data.length,
  },
});
