export const convertToStreamDetailsFormat = (stream) => {
  // convert from stream obj to list of measurements with alerts
  const streamDetails = {};
  const ALERT_TYPES = [
    "limit_alerts",
    "statistical_alerts",
    "balance_alerts",
    "inline_monitors",
  ];
  ALERT_TYPES.forEach((alertType) => {
    stream[alertType].forEach((alert) => {
      const alertMeasurement =
        alertType === "inline_monitors" ? alert.property : alert.test_property;

      if (streamDetails[alertMeasurement]) {
        streamDetails[alertMeasurement][alertType] = alert;
      } else {
        streamDetails[alertMeasurement] = {
          [alertType]: alert,
          measurement: alertMeasurement,
        };
      }
    });
  });
  return Object.values(streamDetails);
};

export const getInlineInterval = (inlineMonitors) =>
  inlineMonitors?.interval_seconds && inlineMonitors?.interval_seconds / 60;
