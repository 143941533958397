import React, { useState } from "react";

import {
  DropdownInput,
  FilterPanel,
  TextInput,
} from "@validereinc/common-components";

export const EVENT_TEMPLATES_FILTER_PANEL_STORAGE_KEY =
  "event_templates_filters";

export const EventTemplatesFilterPanel = () => {
  const [activeFilters, setActiveFilters] = useState(
    JSON.parse(
      sessionStorage.getItem(EVENT_TEMPLATES_FILTER_PANEL_STORAGE_KEY) ?? "{}"
    )
  );

  const onChange = (newFilters: {
    name?: string;
    status: "active" | "inactive";
  }) => {
    setActiveFilters(newFilters);

    sessionStorage.setItem(
      EVENT_TEMPLATES_FILTER_PANEL_STORAGE_KEY,
      JSON.stringify(newFilters)
    );

    // this allows us to trigger updates in components that are not parents of this component
    dispatchEvent(
      new StorageEvent("storage", {
        key: EVENT_TEMPLATES_FILTER_PANEL_STORAGE_KEY,
      })
    );
  };

  return (
    <FilterPanel
      value={activeFilters}
      onChange={onChange}
      defaultActiveKeys={["Filters"]}
      filters={[
        {
          component: (
            <TextInput
              name="name"
              key="event-template-name-filter"
              placeholder="Search by Name..."
              type="search"
              isInline
            />
          ),
        },
        {
          section: "Filters",
          component: (
            <DropdownInput
              name="status"
              label="Status"
              key="event-template-status-filter"
              options={[
                { key: "active", label: "Active" },
                { key: "inactive", label: "Inctive" },
              ]}
              labelKey="label"
              valueKey="key"
              isFluid
              isOptionalTextShown={false}
            />
          ),
        },
      ]}
    />
  );
};
