import { useBreadcrumbs } from "#routers/breadcrumbsHelper";
import { useNavigate, useSearchParams } from "#routers/hooks";
import { linkToCreateFacility } from "#routers/links";
import {
  FacilityRecordFilterPanel,
  FacilityTablePanel,
  RecordsListTablePanel,
} from "#src/batteries-included-components";
import { ApplyAutomationProgressBanner } from "#src/batteries-included-components/Banners/ApplyAutomationProgressBanner";
import useLocalization from "#src/hooks/useLocalization";
import { useStorageKey } from "#src/hooks/useStorageKey";
import { Button, Page, Tab } from "@validereinc/common-components";
import { AssetType } from "@validereinc/domain";
import isEqual from "lodash/isEqual";
import React, { useState } from "react";
import { ORGANIZATION_BREADCRUMB } from "../";
import { FACILITIES_LIST_BREADCRUMB, FACILITIES_LIST_PAGE_TITLE } from "./";

const DEFAULT_TAB_KEY = "list";

export const FacilityListPage = () => {
  const { localize, isLoading } = useLocalization();
  const [selectableProperties, setSelectableProperties] = useState<string[]>();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const storageKeys = useStorageKey("facilities");
  const recordStorageKeys = useStorageKey("facilities-records");
  const recordsApplyAutomationStorageKey = useStorageKey(
    "facilities-records-apply-automation"
  ).tableConfigStorageKey;

  const handleOnCreateFacilityClick = () => {
    navigate({
      pathname: linkToCreateFacility(),
    });
  };

  const actionRow = (
    <Button
      variant="primary"
      onClick={handleOnCreateFacilityClick}
    >
      Create {localize("facility")}
    </Button>
  );

  return (
    <Page
      breadcrumbs={useBreadcrumbs([
        ORGANIZATION_BREADCRUMB,
        FACILITIES_LIST_BREADCRUMB,
      ])}
      title={`${localize(FACILITIES_LIST_PAGE_TITLE)}`}
      actionRow={actionRow}
      isLoading={isLoading}
      activeTabKey={searchParams?.tab ?? DEFAULT_TAB_KEY}
      onActiveTabKeyChange={(tabKey: string) => {
        setSearchParams({ tab: tabKey });
      }}
    >
      <Tab
        title="List"
        tabKey="list"
      >
        <FacilityTablePanel {...storageKeys} />
      </Tab>
      <Tab
        title="Records"
        tabKey="records"
      >
        <ApplyAutomationProgressBanner
          jobStorageKey={recordsApplyAutomationStorageKey}
        />
        <FacilityRecordFilterPanel
          filterConfigStorageKey={recordStorageKeys.filterConfigStorageKey}
          viewConfigStorageKey={recordStorageKeys.viewConfigStorageKey}
          selectableProperties={selectableProperties}
        />
        <RecordsListTablePanel
          type={AssetType.FACILITY}
          {...recordStorageKeys}
          applyAutomationStorageKey={recordsApplyAutomationStorageKey}
          setAvailableProperties={(keys) => {
            if (isEqual(keys, selectableProperties)) return;
            setSelectableProperties(keys);
          }}
        />
      </Tab>
    </Page>
  );
};
