import { LegacyUserType } from "@validereinc/domain";
import moment from "moment";
import ChainOfCustodyService from "../../Services/ChainOfCustodyService";
import InsightService from "../../Services/InsightService";
import InstrumentService from "../../Services/InstrumentService";
import { validereApi } from "../../Services/ServiceHelper";
import SiteService from "../../Services/SiteService";
import StreamService from "../../Services/StreamService";
import UserService from "../../Services/UserService";
import WorkflowService from "../../Services/WorkflowService";
import {
  FETCH_USER_PROFILE,
  FETCH_USER_PROFILE_ERROR,
  MEASUREMENT_TYPES_ERROR,
  MEASUREMENT_TYPES_RECEIVED,
  START_FETCH_WORKFLOWS,
  USER_SIGNED_IN,
  USER_SIGNED_OUT,
} from "../constants/action-types";

export function userSignedIn() {
  return {
    type: USER_SIGNED_IN,
  };
}

export function userSignedOut() {
  return (dispatch) => {
    dispatch({
      type: USER_SIGNED_OUT,
    });
  };
}

export function WorkflowsWasRequested() {
  return (dispatch) => {
    WorkflowService.getWorkflow()
      .then(({ data }) => {
        dispatch({
          type: "FETCH_WORKFLOWS",
          payload: data,
        });
      })
      .catch((error) => {
        if (error.status && error.status !== 403) {
          dispatch({
            type: "FETCH_WORKFLOWS_ERROR",
            payload: {
              message: error.message,
              status: error.status,
            },
          });
        }
      });
  };
}

export function WorkflowTasksWasRequested(startDate, endDate) {
  return (dispatch) => {
    dispatch({ type: START_FETCH_WORKFLOWS });

    WorkflowService.getWorkflowInstanceForUser(startDate, endDate)
      .then(({ data }) => {
        dispatch({
          type: "FETCH_WORKFLOW_INSTANCES",
          payload: data,
        });
      })
      .catch((error) => {
        if (error.status && error.status !== 403) {
          dispatch({
            type: "FETCH_WORKFLOW_INSTANCES_ERROR",
            payload: {
              message: error.message,
              status: error.status,
            },
          });
        }
      });
  };
}

export function fetchChainOfCustodyList() {
  const startDate = moment(0);
  const endDate = moment().endOf("day");

  return (dispatch) => {
    ChainOfCustodyService.getChainOfCustodyList(startDate, endDate)
      .then(({ data }) => {
        dispatch({
          type: "FETCH_COCS",
          payload: data,
        });
      })
      .catch((error) => {
        if (error.status !== 403) {
          dispatch({
            type: "FETCH_COCS_ERROR",
            payload: {
              message: error.message,
              status: error.status,
            },
          });
        }
      });
  };
}

export function fetchInsightList() {
  return (dispatch) => {
    InsightService.getInsightList()
      .then(({ data }) => {
        dispatch({
          type: "FETCH_INSIGHT_LIST",
          payload: data,
        });
      })
      .catch((error) => {
        if (error.status !== 403) {
          dispatch({
            type: "FETCH_INSIGHT_LIST_ERROR",
            payload: {
              message: error.message,
              status: error.status,
            },
          });
        }
      });
  };
}

export function fetchSiteList() {
  return (dispatch) => {
    dispatch({
      type: "FETCH_SITE_LIST",
    });

    SiteService.getSiteList()
      .then(({ data }) => {
        dispatch({
          type: "FETCH_SITE_LIST_SUCCESS",
          payload: data,
        });
      })
      .catch((error) => {
        if (error.status && error.status !== 403) {
          dispatch({
            type: "FETCH_SITE_LIST_ERROR",
            payload: {
              message: error.message,
              status: error.status,
            },
          });
        }
      });
  };
}

export function fetchInstrumentList() {
  return (dispatch) => {
    dispatch({
      type: "FETCH_INSTRUMENT_LIST",
    });

    InstrumentService.getInstrumentList()
      .then(({ data }) => {
        dispatch({
          type: "FETCH_INSTRUMENT_LIST_SUCCESS",
          payload: data.results,
        });
      })
      .catch((error) => {
        if (error.status && error.status !== 403) {
          dispatch({
            type: "FETCH_INSTRUMENT_LIST_ERROR",
            payload: {
              message: error.message,
              status: error.status,
            },
          });
        }
      });
  };
}

export function ensureStreamListIsFetched() {
  return (dispatch, getState) => {
    const state = getState();

    const AGE_THRESHOLD_SECONDS = 300;

    if (
      moment().unix() - AGE_THRESHOLD_SECONDS >
      state.streams.lastRetrievedTime
    ) {
      fetchStreamList()(dispatch);
    }
  };
}

export function fetchStreamList() {
  return (dispatch) => {
    dispatch({
      type: "FETCH_STREAM_LIST",
    });

    StreamService.getStreams()
      .then(({ data }) => {
        dispatch({
          type: "FETCH_STREAM_LIST_SUCCESS",
          payload: data,
        });
      })
      .catch((error) => {
        if (error.status && error.status !== 403) {
          dispatch({
            type: "FETCH_STREAM_LIST_ERROR",
            payload: {
              message: error.message,
              status: error.status,
            },
          });
        }
      });
  };
}

// IMPROVE: the backup param is a temporary by-pass - a step towards removing Redux completely
export function fetchUserProfile({
  legacyUserBackup,
}: {
  legacyUserBackup?: LegacyUserType;
} = {}) {
  return async (dispatch) => {
    if (legacyUserBackup) {
      dispatch({
        type: FETCH_USER_PROFILE,
        payload: legacyUserBackup,
      });
      return Promise.resolve();
    }

    return UserService.getCurrentUser()
      .then(({ data }) => {
        dispatch({
          type: FETCH_USER_PROFILE,
          payload: data,
        });
      })
      .catch((error) => {
        if (error.status && error.status !== 403) {
          dispatch({
            type: FETCH_USER_PROFILE_ERROR,
            payload: {
              message: error.message,
              status: error.status,
            },
          });
        } else {
          throw error;
        }
      });
  };
}

export function updateUserProfile(data) {
  return (dispatch) => {
    dispatch({
      type: "UPDATE_USER_PROFILE",
      payload: data,
    });
  };
}

const getMeasurementTypes = () => {
  return validereApi.get("/api/measurement_types");
};

export function fetchMeasurementTypes() {
  return (dispatch) => {
    return getMeasurementTypes()
      .then(({ data }) => {
        dispatch({
          type: MEASUREMENT_TYPES_RECEIVED,
          payload: data,
        });
      })
      .catch((error) => {
        if (error.status) {
          dispatch({
            type: MEASUREMENT_TYPES_ERROR,
            payload: {
              message: error.message,
              status: error.status,
            },
          });
        }
      });
  };
}
