export const sortByAggregate = (list, key) => {
  return [...list].sort((a, b) => {
    const aValue = a?.[key]?.value ?? -1;
    const bValue = b?.[key]?.value ?? -1;

    return aValue - bValue;
  });
};

export const sortByMeasurements = (list, key) => {
  return [...list].sort((a, b) => {
    const aValue = a?.components?.[key].value ?? -1;
    const bValue = b?.components?.[key].value ?? -1;

    return aValue - bValue;
  });
};
