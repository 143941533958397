/*
overview:
this helper file includes a few getters & setters to help with the mapbox cluser experience.

explaination:
Since I had to use a closure in my custom hover handler, the arguments won't get updated like a regular useEffect cycle 
and, as those values are already closed over, I'm relying on the address bar to store and retrieve them when needed.
*/

const updateURLParameter = (url: string, param: string, value: string) => {
  const urlObj = new URL(url);
  const searchParams = new URLSearchParams(urlObj.search);

  if (searchParams.has(param)) {
    searchParams.set(param, value);
  } else {
    searchParams.append(param, value);
  }

  urlObj.search = searchParams.toString();
  return urlObj.toString();
};

export const setUrlCacheAfterLoad = (id: string, measurement: number) => {
  const url = window.location.toString();

  // updat the id
  const updatedUrlWithId = updateURLParameter(url, "cached_facility_id", id);

  history.replaceState(null, null, updatedUrlWithId);
  // update the measurement
  const udatedUrlWithId = window.location.toString();
  const updatedUrlWithMeasurement = updateURLParameter(
    udatedUrlWithId,
    "cached_facility_measurement",
    String(measurement)
  );

  history.replaceState(null, null, updatedUrlWithMeasurement);
};

export const getCachedDataUrlParams = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const cachedFacilityId = urlParams.get("cached_facility_id");
  const cachedFacilityMeasurement = urlParams.get(
    "cached_facility_measurement"
  );

  return { cachedFacilityId, cachedFacilityMeasurement };
};
