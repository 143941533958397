import { getComponentShortForm } from "#components/Records/Quality/RecordOfQualityHelper";
import {
  CUBIC_METERS_VOLUME,
  getFormattedMeasurementValue,
} from "#redux/reducers/measurements";
import { LegacyDataTable } from "@validereinc/common-components";
import * as PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { AutoSizer } from "react-virtualized";
import { useRecordSelectionContext } from "../Context/recordSelectionContext";
import ResultDifference from "./ResultDifference";
import "./SystemBalance.scss";
import {
  getPropertiesValue,
  getSystemBalanceProperties,
  shouldShowPercentChange,
} from "./SystemBalanceHelper";
import "./SystemBalanceTable.scss";

const PROPERTY_COLUMN_WIDTH = 140;

const NAME_COLUMN_WIDTH = 100;

const mapStateToProps = (state) => {
  return {
    getFormattedMeasurementValue: (measurementKey, measurementValue) =>
      getFormattedMeasurementValue(state.measurements)(
        measurementKey,
        measurementValue
      ),
  };
};

const SystemBalanceTable = (props) => {
  const { systemBalance, initialSystemBalanceResult } =
    useRecordSelectionContext();

  const nameRenderer = (rowData) => {
    return (
      <div className="systemBalanceTable__name">
        <span className="systemBalanceTable__row capitalized">
          {getComponentShortForm(rowData, props.componentVolumeConfig)}
        </span>
      </div>
    );
  };

  const measurementRenderer = (rowData, columnKey) => {
    const { value, unit, percentChange, difference } = getPropertiesValue(
      systemBalance,
      initialSystemBalanceResult,
      rowData,
      columnKey
    );

    const measurementKey = unit !== "%" ? CUBIC_METERS_VOLUME : null;

    return value ? (
      <div className="resultsBalanceTable__row">
        <div className="resultsBalanceTable__measurement">
          <div className="resultsBalanceTable__measurement__value">
            {props.getFormattedMeasurementValue(measurementKey, value)}
          </div>

          <div className="resultsBalanceTable__measurement__unit">{unit}</div>
        </div>

        {difference && (
          <ResultDifference
            difference={difference}
            percentChange={percentChange}
            unit={unit}
            showPercentChange={shouldShowPercentChange(columnKey)}
          />
        )}
      </div>
    ) : (
      "-"
    );
  };

  const SystemBalancePropertiesColumn = getSystemBalanceProperties(
    systemBalance
  ).map((property) => ({
    label: property.name,
    key: property.id,
    width: PROPERTY_COLUMN_WIDTH,
    cellRenderer: measurementRenderer,
    rightAlign: true,
    disableSort: true,
    wrap: property.wrap,
  }));

  const Headers = [
    {
      label: "Properties",
      key: "name",
      width: NAME_COLUMN_WIDTH,
      cellRenderer: nameRenderer,
      disableSort: true,
      fixed: true,
    },
    ...SystemBalancePropertiesColumn,
  ];

  return (
    <div className="systemBalanceTableContainer">
      <div className="systemBalanceTable">
        <AutoSizer>
          {({ width, height }) => (
            <LegacyDataTable
              headers={Headers}
              list={props.displayProperties}
              width={width}
              height={height}
              rowHeight={55}
              headerHeight={50}
              collapseBorder={true}
            />
          )}
        </AutoSizer>
      </div>
    </div>
  );
};

SystemBalanceTable.propTypes = {
  componentVolumeConfig: PropTypes.object,
  getFormattedMeasurementValue: PropTypes.func,
  displayProperties: PropTypes.object,
};

export default connect(mapStateToProps)(SystemBalanceTable);
