export const TAB_KEYS = {
  volumes: "volumes",
  notes: "notes",
};

export const VOLUME_TYPE_OPTIONS = ["Total Volume"];

export const UNIT_OPTIONS = ["m³", "e3m3", "Mcf", "gal"];

export const ENTRY_TYPE_OPTIONS = [
  { id: "Accumulation", name: "All dates have total volume" },
  { id: "Spread", name: "Spread total volume between dates" },
];

export const SOURCE_OPTIONS = [
  { id: "manual", name: "Manual" },
  { id: "production_management_system", name: "Production Management System" },
];

export const INVENTORY_SOURCE_OPTIONS = [
  ...SOURCE_OPTIONS,
  { id: "field", name: "Field" },
];
