import { fetchSiteList } from "#redux/actions/index";
import ThirdPartyLabImportService from "#services/ThirdPartyLabImportService";
import {
  Button,
  Form,
  FormButton,
  Modal,
  Title,
  useAlert,
  useForm,
} from "@validereinc/common-components";
import * as PropTypes from "prop-types";
import React, { useState } from "react";
import { connect } from "react-redux";
import ThirdPartyLabImportForm from "./ThirdPartyLabImportForm";

const DEFAULT_INPUTS = {
  site: [],
  files: [],
};

const mapDispatchToProps = {
  fetchSiteList,
};

const ThirdPartyLabImportModal = ({
  sites,
  onClose,
  fetchThirdPartyLabFiles,
}) => {
  const [formState, setFormState] = useState("enabled");
  const form = useForm({
    defaultValues: DEFAULT_INPUTS,
  });
  const { addAlert } = useAlert();

  const onImportSubmit = (inputs) => {
    setFormState("loading");
    ThirdPartyLabImportService.importThirdPartyLabFile(
      inputs.site.id,
      inputs.attachment[0] //only accept 1 file
    )
      .then(() => {
        addAlert({
          variant: "success",
          message: "Your file has been submitted.",
        });

        onClose();
        fetchThirdPartyLabFiles();
      })
      .finally(() => {
        setFormState("enabled");
      });
  };

  return (
    <Modal onClose={onClose}>
      <Form
        onSubmit={onImportSubmit}
        {...form}
      >
        <Modal.Header>
          <Title>Import Files</Title>
        </Modal.Header>
        <Modal.Body>
          <ThirdPartyLabImportForm
            formState={formState}
            sites={sites}
          />
        </Modal.Body>

        <Modal.Footer className="clearfix">
          <Button
            className="pull-left"
            onClick={onClose}
          >
            Close
          </Button>

          <FormButton
            className="pull-right"
            variant="primary"
            type="submit"
            isLoading={formState === "loading"}
            disabled={formState !== "enabled"}
          >
            Import
          </FormButton>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

ThirdPartyLabImportModal.propTypes = {
  sites: PropTypes.array.isRequired,
  fetchSiteList: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  fetchThirdPartyLabFiles: PropTypes.func.isRequired,
};

export default connect(null, mapDispatchToProps)(ThirdPartyLabImportModal);
