import Immutable from "immutable";

import { UPDATE_SELECTED_VOLUME } from "../constants/action-types";

const recordOfVolume = Immutable.Record({
  id: undefined,
  date: undefined,
  source: undefined,
  is_locked: undefined,
  is_entry_required: undefined,
  total_volume: undefined,
  adjusted_total_volume: undefined,
  adjustment_factor_ids: undefined,
  note_count: undefined,
  opening_volume: undefined,
  type: undefined,
});

export const recordOfVolumeState = Immutable.Record(
  {
    selectedRecordOfVolume: new Immutable.Set([]),
  },
  "recordOfVolumeState"
);

export default (state = new recordOfVolumeState(), action) => {
  let newData = new Immutable.Set([]);

  switch (action.type) {
    case UPDATE_SELECTED_VOLUME: {
      action.selectedRecordOfVolumes.forEach((volume) => {
        newData = newData.add(new recordOfVolume(volume));
      });

      return state.withMutations((s) => {
        s.selectedRecordOfVolume = newData;
      });
    }

    default:
      return state;
  }
};
