import { EntityDropdownInput } from "#batteries-included-components/Dropdowns/EntityDropdownInput";
import { useListCalculatorResults } from "#hooks/adapters/useCalculatorResults";
import { useListCalculators } from "#hooks/adapters/useCalculators";
import { DropdownInput } from "@validereinc/common-components";
import { AssetType, EstimationMethodDomain } from "@validereinc/domain";
import React, { useMemo } from "react";

export const CALCULATOR_FILTERS = {
  estimationMethod: {
    name: "estimation_method.id",
    label: "Estimation Method",
  },
  calculator: {
    name: "estimation_method.analytics_calculator_id",
    label: "Calculator",
  },
};

export const useCalculatorFilters = () => {
  const calculatorQuery = useListCalculators();
  const validCalculatorsQuery = useListCalculatorResults({
    page: 1,
    pageSize: 1000,
    filters: {
      entity_type: { $exact: AssetType.EQUIPMENT },
    },
    groupBy: ["estimation_method.analytics_calculator_id"],
  });

  const validCalculatorIds =
    validCalculatorsQuery.data?.data.map(
      (item) => item["estimation_method.analytics_calculator_id"]
    ) ?? [];

  const calculatorOptions = useMemo(
    () =>
      calculatorQuery.data?.calculators && validCalculatorIds.length
        ? calculatorQuery.data.calculators
            .map((calculator) => ({
              value: calculator.id,
              label:
                calculator.versions.find(
                  (version) => version.version === calculator.default_version
                )?.title ?? calculator.id,
            }))
            .filter(({ value }) => validCalculatorIds.includes(value))
        : [],
    [calculatorQuery.data, validCalculatorIds]
  );

  return {
    filters: [
      {
        section: "Estimation Method",
        component: (
          <DropdownInput
            key={CALCULATOR_FILTERS.calculator.name}
            name={CALCULATOR_FILTERS.calculator.name}
            isLoading={calculatorQuery.isLoading}
            label="Calculator"
            options={calculatorOptions}
            labelKey="label"
            valueKey="value"
            isMulti
            isFluid
            isSearchable
            isOptionalTextShown={false}
          />
        ),
      },
      {
        section: "Estimation Method",
        component: (
          <EntityDropdownInput
            key={CALCULATOR_FILTERS.estimationMethod.name}
            name={CALCULATOR_FILTERS.estimationMethod.name}
            label="Estimation Method"
            fetchEntityList={EstimationMethodDomain.getList}
            fetchFilters={{ entity_type: { $exact: AssetType.EQUIPMENT } }}
          />
        ),
      },
    ],
    filterKeys: ["Estimation Method"],
  };
};
