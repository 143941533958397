import {
  dataEngApiDeleteServicesPromise,
  dataEngApiPostServicesPromise,
  dataEngApiPutServicesPromise,
  dataEngRequestServicesPromise,
} from "./ServiceHelper";

const DataMappingService = {
  getDataMapping() {
    return dataEngRequestServicesPromise(`/datamappings`);
  },

  getDataMappingDetail(datamappingId) {
    return dataEngRequestServicesPromise(`/datamappings/${datamappingId}`);
  },

  getEntities() {
    return dataEngRequestServicesPromise(`/entities`);
  },

  createDataMapping(values, profile) {
    const params = {
      mapping_name: values.mapping_name,
      mapping_strategy: values.mapping_strategy.id,
      mapping_entity: values.mapping_entity.id,
      company_id: profile.company_id,
      created_by: profile.username,
    };

    return dataEngApiPostServicesPromise(`/datamappings`, params);
  },

  uploadCreateDataMappingFile({ dataMappingId, file }) {
    const formData = new FormData();

    Object.keys(file).map((key) => {
      formData.append("file", file[key]);
    });

    return dataEngApiPostServicesPromise(`/task/${dataMappingId}`, formData);
  },

  deleteDataMapping(dataMappingId) {
    return dataEngApiDeleteServicesPromise(`/datamappings/${dataMappingId}`);
  },

  getTaskPreview({ taskId }) {
    return dataEngRequestServicesPromise(`/task/${taskId}/preview`);
  },

  getEntityCategories({ entityCategory, dataMappingId }) {
    if (dataMappingId) {
      return dataEngRequestServicesPromise(
        `/entities/${dataMappingId}/${entityCategory}`
      );
    }

    return dataEngRequestServicesPromise(`/entities/${entityCategory}`);
  },

  updateDataMapping({ dataMappingId, data_mapping, dataMappingDetail }) {
    const params = {
      ...dataMappingDetail,
      mapping_config: data_mapping,
    };

    return dataEngApiPutServicesPromise(
      `/datamappings/${dataMappingId}`,
      params
    );
  },

  planTask({ taskId }) {
    return dataEngApiPostServicesPromise(`/task/${taskId}/plan`);
  },

  getTaskPlanPreview({ taskId }) {
    return dataEngRequestServicesPromise(`/task/${taskId}/plan`);
  },

  getDataMappingTaskStatus(taskId) {
    return dataEngRequestServicesPromise(`/task/${taskId}`);
  },

  getDataMappingTaskPlan(taskId) {
    return dataEngRequestServicesPromise(`/task/${taskId}/plan`);
  },

  applyTaskPlan(taskId) {
    return dataEngApiPostServicesPromise(`/task/${taskId}/apply`);
  },

  getDataMappingTasks(id) {
    return dataEngRequestServicesPromise(`/datamappings/${id}/tasks`);
  },
};

export default DataMappingService;
