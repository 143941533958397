import { useMeasurementTypes } from "#src/contexts/MeasurementTypeContext";
import {
  EmptyState,
  MetricTileGrid,
  Panel,
} from "@validereinc/common-components";
import type { CalculatorResultType } from "@validereinc/domain";
import { getFormattedNumberWithUnit } from "@validereinc/utilities";
import { Stack } from "phosphor-react";
import React, { useMemo } from "react";

const CalculatorResultsSummaryPanel = ({
  data,
  isLoading: isLoadingProp,
  loadingGridSize = 6,
  isError,
  title = "Emissions Summary",
}: CalculatorResultsSummaryPanelProps) => {
  const {
    getTypeName,
    getUnitName,
    getUnitByType,
    getPrecisionByType,
    isLoading: isMeasurementsLoading,
  } = useMeasurementTypes();

  const isLoading = isMeasurementsLoading || isLoadingProp;

  const aggregatedMeasurementResultMetricTileGridData = useMemo(() => {
    if (isLoading) {
      return Array(loadingGridSize)
        .fill(null)
        .map((_, idx) => ({
          title: `Total X${idx} Emissions`,
        }));
    }

    if (isError || !data?.measurement) {
      return [];
    }

    return (
      Object.entries(data.measurement)?.map(
        ([measurementKey, measurementValue]) => ({
          title: `Total ${getTypeName(measurementKey)} Emissions`,
          value: getFormattedNumberWithUnit(
            {
              value: Number(measurementValue),
              unit: getUnitName(getUnitByType(measurementKey)),
            },
            getPrecisionByType(measurementKey),
            {
              showSmallNumberAsExponential: true,
              maxFractionDigits: 3,
              minFractionDigits: 1,
            }
          ),
        })
      ) ?? []
    );
  }, [data, isError, isLoading]);

  return (
    <Panel
      title={title}
      ariaLabel={title}
    >
      {aggregatedMeasurementResultMetricTileGridData.length ? (
        <MetricTileGrid
          data={aggregatedMeasurementResultMetricTileGridData}
          isLoading={isLoading}
        />
      ) : (
        <EmptyState
          title="There are no results to summarize"
          suggestion="Please try adjusting your filters"
          icon={<Stack />}
        />
      )}
    </Panel>
  );
};

type CalculatorResultsSummaryPanelProps = {
  data: CalculatorResultType | null;
  isLoading?: boolean;
  loadingGridSize?: number;
  isError?: boolean;
  title?: string;
};

export default CalculatorResultsSummaryPanel;
