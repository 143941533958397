import CustomAttributeField from "#common/CustomAttributeField";
import { useGetOneNetwork } from "#hooks/adapters/useNetworks";
import { useListReportingGroups } from "#hooks/adapters/useReportingGroups";
import { useListCustomAttributes } from "#src/components/hooks/adapters/useCustomAttributes";
import {
  useMemoizedFormState,
  useMultiStepFormContext,
} from "#src/hooks/useMultiStepForm";
import {
  DropdownInput,
  Form,
  Panel,
  TextInput,
  useForm,
} from "@validereinc/common-components";
import { AssetGroupStatus, AssetType } from "@validereinc/domain";
import { toStartCaseString } from "@validereinc/utilities";
import classNames from "classnames/bind";
import React, { useEffect } from "react";
import styles from "./NetworkForm.module.scss";

const cx = classNames.bind(styles);

export const NetworkDetailsStep = ({ networkId }: { networkId?: string }) => {
  const STEP_NUMBER = 1;

  const { currentStep, updateStep } = useMultiStepFormContext();

  const { data: network } = useGetOneNetwork(networkId);
  const { data: customAttributes } = useListCustomAttributes({
    filters: { entity_type: AssetType.ASSET_GROUP },
  });
  const reportingGroups = useListReportingGroups().data?.data ?? [];

  const form = useForm({
    defaultValues: {
      name: network?.name,
      status: network?.status ?? AssetGroupStatus.ACTIVE,
      reporting_group_id: network?.default_network_reporting_group_id,
      custom_attributes: network?.custom_attributes,
    },
  });

  const getFormState = useMemoizedFormState(form);

  useEffect(() => {
    updateStep(STEP_NUMBER, {
      getValues: () => form.getValues(),
      getFormState,
    });
  }, [network, getFormState]);

  return (
    <>
      {currentStep === STEP_NUMBER ? (
        <Panel title="Details">
          <div className={cx("detailsContainer")}>
            <Form {...form}>
              <TextInput
                name="name"
                label="Name"
                isRequired
                value={name}
              />
              <DropdownInput
                name="status"
                label="Status"
                options={Object.values(AssetGroupStatus).map((id) => ({
                  id,
                  name: toStartCaseString(id),
                }))}
                labelKey="name"
                valueKey="id"
                isRequired
              />
              <DropdownInput
                name="reporting_group_id"
                label="Reporting Scenario"
                options={reportingGroups}
                labelKey="name"
                valueKey="id"
                isFluid
                isRequired
                isSortedAlphabetically={false}
                isSearchable
              />
              {customAttributes?.data?.map(
                ({ field_name, ...restAttribute }) => (
                  <CustomAttributeField
                    key={`custom_attributes.${field_name}`}
                    attribute={{ field_name, ...restAttribute }}
                  />
                )
              )}
            </Form>
          </div>
        </Panel>
      ) : null}
    </>
  );
};
