import { Button, Title } from "@validereinc/common-components";
import classNames from "classnames/bind";
import * as PropTypes from "prop-types";
import React from "react";
import styles from "./ProductionReportsForm.module.scss";
import SourcesTable from "./SourcesTable";

const cx = classNames.bind(styles);

const SourcesTableInput = ({
  sourceKey,
  onSourceSelect,
  sourceCheckedList,
  onSourceChecked,
  form,
  formState,
  streams,
}) => {
  return (
    <>
      <Title
        type="subheader"
        className={`${cx("formTitle")} capitalized`}
      >
        {sourceKey}s
      </Title>

      <div className={cx("button")}>
        <Button
          style={{ marginRight: "10px" }}
          onClick={() => onSourceSelect(sourceKey, "add", "stream")}
        >
          Add Streams
        </Button>

        <Button
          onClick={() => onSourceSelect(sourceKey, "add", "inventory")}
          disabled={formState !== "enabled"}
        >
          Add Inventories
        </Button>

        <Button
          variant="primary"
          className="pull-right"
          onClick={() => onSourceSelect(sourceKey, "edit")}
          disabled={
            formState !== "enabled" || !sourceCheckedList?.[sourceKey]?.length
          }
        >
          Edit
        </Button>
      </div>

      <SourcesTable
        sourcesList={form.watch(sourceKey)}
        selectedValues={sourceCheckedList?.[sourceKey]}
        onCheckboxClick={(list) => onSourceChecked(list, sourceKey)}
        isDisabled={formState !== "enabled"}
        streams={streams}
      />
    </>
  );
};

SourcesTableInput.propTypes = {
  form: PropTypes.object.isRequired,
  onSourceSelect: PropTypes.func.isRequired,
  sourceKey: PropTypes.string,
  sourceCheckedList: PropTypes.array,
  onSourceChecked: PropTypes.func,
  formState: PropTypes.string,
  streams: PropTypes.array,
};

export default SourcesTableInput;
