import { z } from "zod";
import {
  DomainModelMetaExtendedSchema,
  DomainModelMetaSchema,
  DomainModelSchema,
} from "./DomainModelSchemas";

export const UserGroupCategorySchema = z
  .object({
    name: z.string().max(100),
    description: z.string().max(256),
    company_id: z.string().uuid(),
  })
  .merge(DomainModelSchema)
  .merge(DomainModelMetaSchema)
  .merge(DomainModelMetaExtendedSchema)
  .describe("Represents a user group category model from the Node API");

export const CreateUserGroupCategorySchema = UserGroupCategorySchema.pick({
  name: true,
  description: true,
});

export type UserGroupCategoryType = z.infer<typeof UserGroupCategorySchema>;
export type CreateUserGroupCategoryType = z.infer<
  typeof CreateUserGroupCategorySchema
>;
