import { RoutePath } from "#src/utils/route";

/**
 * route config for /app
 */
export const RootRoute = new RoutePath({
  title: "App",
  isPresentational: true,
  isHidden: true,
  path: "/app",
});
