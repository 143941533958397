import { getComponentShortForm } from "#components/Records/Quality/RecordOfQualityHelper";
import { getMeasurementType } from "#redux/reducers/measurements";
import {
  InputStack,
  SelectInput,
  TextInput,
  Title,
} from "@validereinc/common-components";
import * as PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import {
  ACCUMULATIVE_COMPONENT_OPTIONS,
  COMPONENTS,
  COMPONENT_UNITS_OPTIONS,
  DENSITY_HEPTANES_PLUS,
  DENSITY_HEXANES_PLUS,
  HEPTANES_PLUS,
  HEPTANES_PLUS_MOLECULAR_WEIGHT,
  HEXANES_PLUS,
  HEXANES_PLUS_MOLECULAR_WEIGHT,
} from "./ComponentAnalysisConstant";
import { getMeasurementErrorMessage } from "./ComponentAnalysisHelper";
import TotalAccumulation from "./TotalAccumulation";

const ComponentInput = ({ component, disabled, onMeasurementChange }) => {
  const componentUnit = "%";

  return (
    <TextInput
      name={`measurements.${component}.value`}
      label={component}
      type="number"
      placeholder="0"
      isDisabled={disabled}
      onChange={(value) => onMeasurementChange(component, value, componentUnit)}
      unit={componentUnit}
      validate={{
        validateComponentValue: (value) =>
          getMeasurementErrorMessage(component, value),
      }}
      showIcon
    />
  );
};

const mapStateToProps = ({ measurements }) => {
  return {
    getMeasurementType: getMeasurementType(measurements),
  };
};

const PropertiesForm = ({
  form,
  formState,
  getMeasurementType,
  onMeasurementChange,
  totalAccumulation,
  accumulationDifference,
}) => {
  const sharedProps = {
    showIcon: true,
    isDisabled: formState !== "enabled",
  };

  const isComponentDisabled = (component) => {
    if (sharedProps.isDisabled) {
      return true;
    }

    if (component === HEXANES_PLUS) {
      return true;
    }

    if (component === HEPTANES_PLUS) {
      return form.getValues("accumulativeComponent") === HEXANES_PLUS;
    }

    return false;
  };

  const optionalDensityKey =
    form.getValues("accumulativeComponent") === "Hexanes Plus (C6+)"
      ? DENSITY_HEXANES_PLUS
      : DENSITY_HEPTANES_PLUS;

  const optionalMolarMassKey =
    form.getValues("accumulativeComponent") === "Hexanes Plus (C6+)"
      ? HEXANES_PLUS_MOLECULAR_WEIGHT
      : HEPTANES_PLUS_MOLECULAR_WEIGHT;

  const densityUnit = getMeasurementType(optionalDensityKey).unit;
  const molarMassUnit = getMeasurementType(optionalMolarMassKey).unit;

  return (
    <>
      <Title
        type="subheader"
        className="componentAnalysis__header"
      >
        Properties
      </Title>

      <div>
        <SelectInput
          {...sharedProps}
          name="accumulativeComponent"
          label="Accumulative Component"
          options={ACCUMULATIVE_COMPONENT_OPTIONS}
        />

        <SelectInput
          {...sharedProps}
          name="unit"
          label="Units"
          options={COMPONENT_UNITS_OPTIONS}
        />
      </div>

      <TotalAccumulation
        totalAccumulation={totalAccumulation}
        accumulationDifference={accumulationDifference}
      />

      {COMPONENTS.map((value, index) => {
        if (index % 2 == 0) {
          const component = COMPONENTS[index];
          const nextComponent = COMPONENTS[index + 1];

          return (
            <InputStack key={value}>
              <ComponentInput
                component={component}
                disabled={isComponentDisabled(component)}
                onMeasurementChange={onMeasurementChange}
              />

              {nextComponent ? (
                <ComponentInput
                  component={nextComponent}
                  disabled={isComponentDisabled(nextComponent)}
                  onMeasurementChange={onMeasurementChange}
                />
              ) : (
                <div />
              )}
            </InputStack>
          );
        }

        return null;
      })}

      <InputStack label="Additional Properties">
        <TextInput
          {...sharedProps}
          name={`measurements.${optionalDensityKey}.value`}
          label={`${getComponentShortForm(
            form.getValues("accumulativeComponent")
          )} Density`}
          type="number"
          unit={densityUnit}
          validate={{
            validateDensityValue: (value) =>
              getMeasurementErrorMessage(optionalDensityKey, value),
          }}
        />

        <TextInput
          {...sharedProps}
          name={`measurements.${optionalMolarMassKey}.value`}
          label={`${getComponentShortForm(
            form.getValues("accumulativeComponent")
          )} Molar Mass`}
          type="number"
          unit={molarMassUnit}
          validate={{
            validateMolarMassValue: (value) =>
              getMeasurementErrorMessage(optionalMolarMassKey, value),
          }}
        />
      </InputStack>
    </>
  );
};

ComponentInput.propTypes = {
  component: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  onMeasurementChange: PropTypes.func.isRequired,
};

PropertiesForm.propTypes = {
  form: PropTypes.object.isRequired,
  formState: PropTypes.string.isRequired,
  getMeasurementType: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  onMeasurementChange: PropTypes.func.isRequired,
  totalAccumulation: PropTypes.string.isRequired,
  accumulationDifference: PropTypes.number.isRequired,
};

export default connect(mapStateToProps, undefined)(PropertiesForm);
