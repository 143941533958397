import DateRangeSelector from "#common/DateSelector/DateRangeSelector";
import { Panel, Title } from "@validereinc/common-components";
import * as PropTypes from "prop-types";
import React from "react";
import { Col, Row } from "react-bootstrap";
import InventoryChart from "../InventoryChart/InventoryChart";
import FilledVolume from "./FilledVolume";
import "./VolumeSummary.scss";

const VolumeSummary = ({
  inventoryDetail,
  chartData,
  chartDataLoadingState,
  dateRange,
  selectDateAndTime,
  productType,
}) => {
  const title = productType
    ? `${productType} Volume Overview`
    : `Volume Overview`;

  return (
    <Row>
      <Col sm={12}>
        <Panel
          title={title}
          className="volumeSummary"
        >
          <div className="volumeSummary__content">
            <div className="volumeSummary__filledVolume">
              <FilledVolume inventoryDetail={inventoryDetail} />
            </div>

            <div className="volumeSummary__chart">
              <div className="volumeSummary__chartHeader">
                <Title
                  className="volumeSummary__subtitle"
                  type="subheader"
                >
                  Trend line
                </Title>

                <DateRangeSelector
                  dateRange={dateRange}
                  onDateRangeChange={selectDateAndTime}
                  allowTimeSelection
                  icon
                />
              </div>

              <InventoryChart
                chartData={chartData}
                chartDataLoadingState={chartDataLoadingState}
              />
            </div>
          </div>
        </Panel>
      </Col>
    </Row>
  );
};

VolumeSummary.propTypes = {
  inventoryDetail: PropTypes.object,
  chartData: PropTypes.array.isRequired,
  chartDataLoadingState: PropTypes.string,
  productType: PropTypes.string,
  dateRange: PropTypes.object,
  selectDateAndTime: PropTypes.func,
};

export default VolumeSummary;
