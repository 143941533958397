import React from "react";
import * as PropTypes from "prop-types";
import { VirtualSampleSVG } from "#common/SVG/SamplesSVG";

/** When used in charts, X:0, y:0 is where the data is supposed to be */
const SVG_SIZE = 10;
const DEFAULT_POSITION = { x: 0, y: 0 };
const YELLOW = "#FABD08";
const BLUE = "#4185F4";
const RED = "#EB4639";
const GREEN = "#4caf50";
const GREY = "#AAA";
const PURPLE = "#9c27b0";
const PINK = "#FECFE5";
const TEXT_COLOUR = "#666";
const LEGEND_HEIGHT = 30;

export const ROQ_PROPS = {
  style: { fill: YELLOW, stroke: YELLOW, strokeWidth: "3px" },
};
export const SELECTED_ROQ_PROPS = {
  style: { fill: GREEN, stroke: GREEN, strokeWidth: "3px" },
};

export const SPOT_PROPS = { style: { fill: RED } };
export const COMPOSITE_PROPS = { style: { fill: BLUE } };
export const UNKNOWN_PROPS = { style: { fill: PURPLE } };
export const INLINE_PROPS = {
  style: { stroke: PINK },
  fill: PINK,
  size: SVG_SIZE / 4,
  strokeWidth: 3,
};

export const SELECTED_INLINE_PROPS = {
  ...INLINE_PROPS,
  style: { stroke: GREEN },
  fill: GREEN,
};

const defaultShapeStyle = { fill: GREY };
const defaultSelectedStyle = {
  stroke: GREEN,
  strokeWidth: "3px",
  fill: "none",
};
const defaultTextStyle = { fill: TEXT_COLOUR };

/** Default: A centered, grey circle svg that is 10 pixels in diameter */
export const CircleSVG = ({
  position = {},
  style = {},
  size = SVG_SIZE,
  opacity = 1,
}) => {
  const { x, y } = { ...DEFAULT_POSITION, ...position };
  const finalStyle = { ...defaultShapeStyle, ...style };

  return (
    <circle
      cx={x}
      cy={y}
      r={size / 2}
      style={finalStyle}
      opacity={opacity}
    />
  );
};
CircleSVG.propTypes = {
  position: PropTypes.object,
  style: PropTypes.object,
  size: PropTypes.number,
  opacity: PropTypes.number,
};

/** The original size of the svg is 1100 by 1000, defined in viewBox property.
 *  Given size 10, the svg generated is 5.24x4.65 pixels and is not centered y axis
 *  by 10%. The size multiplier enlarges the size so that the height is SVG_SIZE,
 *  and the position is shifted accordingly to the new size.
 */
export const AlarmSVG = ({
  position = {},
  style = {},
  size = SVG_SIZE,
  opacity = 1,
}) => {
  const sizeMultiplier = 10 / 4.65;
  const finalSize = size * sizeMultiplier;
  const finalPosition = { ...DEFAULT_POSITION, ...position };
  const x = finalPosition.x - (finalSize * 0.524) / 2;
  const y = finalPosition.y - (finalSize * 0.465 + finalSize * 0.1) / 2;
  const finalStyle = { ...defaultShapeStyle, ...style };
  return (
    <svg
      viewBox="0 0 1100 1000"
      x={x}
      y={y}
      height={finalSize}
      width={finalSize}
      opacity={opacity}
    >
      <path
        style={finalStyle}
        d="M569.517 440.013 C587.975 472.007 564.806 512 527.94 512 H48.054
        c-36.937 0-59.999-40.055-41.577-71.987 L246.423 23.985
        c18.467-32.009 64.72-31.951 83.154 0 l239.94 416.028z"
      />
      <path
        fill="#fff"
        d="M288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46
        zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346
        h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654
        h-63.383c-6.884 0-12.356 5.78-11.981 12.654z"
      />
    </svg>
  );
};

AlarmSVG.propTypes = {
  position: PropTypes.object,
  style: PropTypes.object,
  size: PropTypes.number,
  opacity: PropTypes.number,
};

/** Default: A grey text svg whose starting position is the middle left of the text */
const TextSVG = ({ text, position = {}, style = {}, size = SVG_SIZE }) => {
  const finalPosition = { ...DEFAULT_POSITION, ...position };
  const y = finalPosition.y + size / 2;
  const finalStyle = { ...defaultTextStyle, ...style };
  return (
    <text
      x={finalPosition.x}
      y={y}
      style={finalStyle}
    >
      {text}
    </text>
  );
};

TextSVG.propTypes = {
  text: PropTypes.string,
  position: PropTypes.object,
  style: PropTypes.object,
  size: PropTypes.number,
};

const defaultCoordinate = { x1: 0, y1: 0 };
const defaultLineStyle = { stroke: TEXT_COLOUR, strokeWidth: "3px" };
/** Default: A horizontal grey line that is SVG_SIZE in length */
const LineSVG = ({
  position = { ...DEFAULT_POSITION },
  style = {},
  size = SVG_SIZE,
  coordinate = {},
  opacity = 1,
}) => {
  const { x, y } = position;
  const { x1, x2, y1, y2 } = { ...defaultCoordinate, ...coordinate };
  const finalStyle = { ...defaultLineStyle, ...style };
  return (
    <line
      x1={x1 + x}
      x2={x2 ? x2 + x : size + x}
      y1={y1 + y}
      y2={y2 ? y2 + y : y1 + y}
      style={finalStyle}
      opacity={opacity}
    />
  );
};

LineSVG.propTypes = {
  position: PropTypes.object,
  style: PropTypes.object,
  size: PropTypes.number,
  coordinate: PropTypes.object,
  opacity: PropTypes.number,
};

const SelectedMarker = ({
  position = {},
  style = {},
  size = SVG_SIZE * 1.5,
  opacity = 1,
}) => {
  const finalPosition = { ...DEFAULT_POSITION, ...position };
  const x = finalPosition.x - size / 2;
  const y = finalPosition.y - size / 2;
  const finalStyle = { ...defaultSelectedStyle, ...style };

  return (
    <rect
      width={size}
      height={size}
      x={x}
      y={y}
      style={finalStyle}
      opacity={opacity}
    />
  );
};

SelectedMarker.propTypes = {
  position: PropTypes.object,
  style: PropTypes.object,
  size: PropTypes.number,
  opacity: PropTypes.number,
};

/** Default: A legend that is 30 pixels high and its contents centered,
 *   420 pixels in length. The distance between shape and text is 10px,
 *   and around 25px between legend items. If placed in an svg, the
 *   position will be the middle left of the SVG.
 */
export const RecordOfQualitySampleChartLegend = ({
  height = LEGEND_HEIGHT,
  position = { ...DEFAULT_POSITION },
  justifyRight = false,
}) => {
  const centerY = height / 2;
  const finalPosition = { ...DEFAULT_POSITION, ...position };
  const x = justifyRight ? finalPosition.x - 555 : finalPosition.x;
  const y = finalPosition.y - centerY;

  return (
    <svg
      style={{ width: 575, height }}
      x={x}
      y={y}
    >
      <LineSVG
        position={{ x: 0, y: centerY }}
        coordinate={{ x2: 15 }}
        {...INLINE_PROPS}
        size={SVG_SIZE}
      />
      <CircleSVG
        position={{ x: 7, y: centerY }}
        {...INLINE_PROPS}
        size={SVG_SIZE / 1.5}
        style={{ fill: PINK }}
      />
      <TextSVG
        text="Inline"
        position={{ x: 20, y: centerY }}
      />

      <LineSVG
        position={{ x: 65, y: centerY }}
        {...ROQ_PROPS}
      />
      <TextSVG
        text="RoQ"
        position={{ x: 80, y: centerY }}
      />

      <CircleSVG
        position={{ x: 130, y: centerY }}
        {...SPOT_PROPS}
      />
      <TextSVG
        text="Spot"
        position={{ x: 140, y: centerY }}
      />

      <CircleSVG
        position={{ x: 190, y: centerY }}
        {...COMPOSITE_PROPS}
      />
      <TextSVG
        text="Composite"
        position={{ x: 200, y: centerY }}
      />

      <CircleSVG
        position={{ x: 285, y: centerY }}
        {...UNKNOWN_PROPS}
      />
      <TextSVG
        text="Unknown"
        position={{ x: 295, y: centerY }}
      />

      <VirtualSampleSVG position={{ x: 360, y: centerY - 9 }} />
      <TextSVG
        text="Virtual"
        position={{ x: 383, y: centerY }}
      />

      <AlarmSVG position={{ x: 440, y: centerY }} />
      <TextSVG
        text="Alarm"
        position={{ x: 450, y: centerY }}
      />

      <SelectedMarker
        position={{ x: 500, y: centerY }}
        size={SVG_SIZE}
        style={{ strokeWidth: "2px" }}
      />
      <TextSVG
        text="Selected"
        position={{ x: 512, y: centerY }}
      />
    </svg>
  );
};

RecordOfQualitySampleChartLegend.propTypes = {
  height: PropTypes.number,
  position: PropTypes.object,
  justifyRight: PropTypes.bool,
};

/** DECORATOR SVGs */
export const SelectedSVG = ({ children, style = {}, opacity = 1 }) => {
  const finalStyle = { ...defaultSelectedStyle, ...style };
  return (
    <g>
      <SelectedMarker
        style={finalStyle}
        opacity={opacity}
      />
      {children}
    </g>
  );
};

SelectedSVG.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  style: PropTypes.object,
  opacity: PropTypes.number,
};
