import React from "react";
import PropTypes from "prop-types";
import { Tab, Nav, NavItem } from "react-bootstrap";
import "./UserSettings.css";
import { SetCleanHistoryPath } from "../../../Routers/historyHelper";
import Profile from "./Profile";
import UserActivity from "./UserActivity/UserActivity";
import { linkToUserSetting } from "../../../Routers/links";
import { havePermission } from "../../Redux/reducers/permissions";
import { connect } from "react-redux";
import SettingSection from "../SettingSection";
import Notifications from "./Notifications/Notifications";
import ApiTokens from "./ApiTokens/ApiTokens";
import config from "../../../config";

const DEFAULT_TAB = "profile";

const NavLink = (props) => (
  <li>
    <a
      href={props.link}
      target="_blank"
      rel="noreferrer"
    >
      {props.title}
    </a>
  </li>
);

const mapStateToProps = (state) => {
  return {
    hasReadPermission: havePermission(state.permissions)(
      "core:user.management",
      "read"
    ),
    hasNotificationPermission: havePermission(state.permissions)(
      "core:notifications",
      "read"
    ),
  };
};

const UserSettings = (props) => {
  const paramKey = props.match.params.tab;
  const profile = props.profile.toJS();
  const tabKey = paramKey || DEFAULT_TAB;

  const onSelect = (eventKey) => {
    const link = linkToUserSetting(eventKey);
    SetCleanHistoryPath(link);
  };

  return (
    <Tab.Container
      className="userSettings"
      id="userSettings__tabs"
      activeKey={tabKey}
      onSelect={onSelect}
    >
      <div className="userSettings__tabs">
        <div className="userSettings__navs">
          <div className="userSettings__navsHeader">Account</div>
          <Nav stacked>
            <NavItem eventKey="profile">Profile</NavItem>

            {props.hasNotificationPermission ? (
              <NavItem eventKey="notifications">Notifications</NavItem>
            ) : null}

            {props.hasReadPermission ? (
              <NavItem eventKey="activities">Activities</NavItem>
            ) : null}

            {props.hasReadPermission ? (
              <NavItem eventKey="apiTokens">API Tokens</NavItem>
            ) : null}

            <NavLink
              link={config.PRIVACY_POLICY_URL}
              title="Privacy Policy"
            />

            <NavLink
              link={config.TERMS_OF_SERVICE_URL}
              title="Terms of Service"
            />

            <NavLink
              link={config.RELEASE_NOTE_URL}
              title="Release Notes"
            />
          </Nav>
        </div>
        <div className="userSettings__content">
          <Tab.Content animation>
            <Tab.Pane eventKey="profile">
              <Profile
                profile={profile}
                height={props.height}
              />
            </Tab.Pane>

            {props.hasNotificationPermission ? (
              <Tab.Pane eventKey="notifications">
                <Notifications
                  profile={profile}
                  height={props.height}
                />
              </Tab.Pane>
            ) : null}

            {props.hasReadPermission ? (
              <Tab.Pane eventKey="activities">
                <SettingSection title="My Activities">
                  <UserActivity
                    userId={profile.id}
                    height={props.height}
                  />
                </SettingSection>
              </Tab.Pane>
            ) : null}

            {props.hasReadPermission ? (
              <Tab.Pane eventKey="apiTokens">
                <ApiTokens
                  userId={profile.id}
                  height={props.height}
                />
              </Tab.Pane>
            ) : null}
          </Tab.Content>
        </div>
      </div>
    </Tab.Container>
  );
};

NavLink.propTypes = {
  title: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
};

UserSettings.propTypes = {
  height: PropTypes.number,
  match: PropTypes.object,
  profile: PropTypes.object,
  hasReadPermission: PropTypes.bool,
  hasNotificationPermission: PropTypes.bool,
};

export default connect(mapStateToProps, null)(UserSettings);
