import {
  nodeApiPostServicesPromise,
  nodeApiRequestServicesPromise,
  nodeApiDeleteServicesPromise,
  nodeApiPutServicesPromise,
} from "./ServiceHelper";

import type { PaginationAndSortParams } from "./types";

const DashboardService = {
  getDashboardsList({
    page,
    rowPerPage,
    sort,
    sortDirection,
    filters,
  }: Partial<PaginationAndSortParams & { filters: any }>) {
    return nodeApiRequestServicesPromise("/dashboards", {
      page,
      page_size: rowPerPage,
      sort_by: sort,
      sort_direction: sortDirection,
      ...filters,
    });
  },

  createDashboard({ name, description }) {
    return nodeApiPostServicesPromise("/dashboards", {
      name,
      description,
    });
  },

  getDashboardDetail(dashboardId) {
    return nodeApiRequestServicesPromise(`/dashboards/${dashboardId}`);
  },

  editDashboard({ dashboardId, name, description }) {
    return nodeApiPutServicesPromise(`/dashboards/${dashboardId}`, {
      name,
      description,
    });
  },

  deleteDashboard(dashboardId) {
    return nodeApiDeleteServicesPromise(`/dashboards/${dashboardId}`);
  },

  createChart({ dashboardId, name, ...restChart }) {
    return nodeApiPostServicesPromise("/charts", {
      dashboard_id: dashboardId,
      name,
      json: { ...restChart },
    });
  },

  editChart({ dashboardId, chartId, name, ...restChart }) {
    return nodeApiPutServicesPromise(`/charts/${chartId}`, {
      dashboard_id: dashboardId,
      name,
      json: { ...restChart },
    });
  },

  deleteChart({ chartId }) {
    return nodeApiDeleteServicesPromise(`/charts/${chartId}`);
  },
};

export default DashboardService;
