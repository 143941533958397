import { getStatusType } from "#common/Table/rendererHelper";
import {
  LegacyBreadcrumbType,
  getBreadcrumbsObject,
} from "#routers/breadcrumbsHelper";
import { useSearchParams } from "#routers/hooks";
import { linkToFormSubmissionDetail } from "#routers/links";
import { DraftFormSubmissionsList } from "#src/batteries-included-components/Layouts/Form/Submission/List/DraftFormSubmissionsList";
import {
  FormSubmissionsFilterPanel,
  FormSubmissionsFilterType,
} from "#src/batteries-included-components/Panels/FilterPanels/FormSubmissionsFilterPanel";
import { RoutingLink } from "#src/batteries-included-components/RoutingLink";
import { useExportFormSubmissionAsPDF } from "#src/components/Forms/exportFormSubmission";
import { useTableSortingAndPagination } from "#src/components/Redux/reducers/tableStateReducer";
import { useGetManyUsers } from "#src/components/hooks/adapters/useUsers";
import { useStorageKey } from "#src/hooks/useStorageKey";
import { linkToFormCategoryDetails } from "#src/routes/forms/categories/[categoryId]";
import { linkToFormTemplateDetail } from "#src/routes/forms/categories/[categoryId]/templates/[formTemplateId]";
import {
  DataTable,
  DataTablePanel,
  HeaderType,
  Page,
  Tab,
  useFilters,
} from "@validereinc/common-components";
import {
  FormSubmission,
  FormSubmissionStatus,
  FormSubmissionType,
  SortDirection,
  UserType,
} from "@validereinc/domain";
import { toFlattenedObject, toStartCaseString } from "@validereinc/utilities";
import * as PropTypes from "prop-types";
import React, { useMemo } from "react";
import { useListFormSubmissions } from "../hooks/adapters/useFormSubmissions";

// TODO: refactor this and the shared component under components/Common/Forms/FormTable into one component
const FormSubmissionList = ({
  breadcrumbs,
}: {
  breadcrumbs: LegacyBreadcrumbType[];
}) => {
  const { tableConfigStorageKey, filterConfigStorageKey } =
    useStorageKey("forms-submissions");
  const [searchParams, setSearchParams] = useSearchParams<{ tab: string }>();
  const [filters] = useFilters<FormSubmissionsFilterType>(
    filterConfigStorageKey
  );
  const sorting = {
    sortBy: "created_at",
    sortDirection: SortDirection.DESCENDING,
  };
  const [tableState, updateTableState] = useTableSortingAndPagination(
    sorting,
    filters
  );

  const { created_at, ...restFilters } = filters;
  const queryPayload: Parameters<typeof FormSubmission.getList>[0] = {
    page: tableState.page,
    pageSize: tableState.itemsPerPage,
    sortBy: tableState.sortBy,
    sortDirection: tableState.sortDirection,
    filters: {
      created_at,
      ...toFlattenedObject({
        status: Object.values(FormSubmissionStatus).filter(
          (s) => s !== FormSubmissionStatus.DRAFT
        ),
        "form_schema.status": "active",
        ...restFilters,
      }),
    },
    meta: { answers: true },
  };
  const { data: submissionsResponse, isLoading: isSubmissionsLoading } =
    useListFormSubmissions(queryPayload);
  const createdByUsersQueries = useGetManyUsers(
    Array.from(
      new Set(submissionsResponse?.data.map(({ created_by }) => created_by))
    )
  );
  const usersMap = useMemo(
    () =>
      createdByUsersQueries?.reduce<Record<string, UserType>>((map, query) => {
        if (!query.data) {
          return map;
        }

        map[query.data.id] = query.data;
        return map;
      }, {}) ?? {},
    [createdByUsersQueries]
  );
  const exportPDFMutation = useExportFormSubmissionAsPDF({
    includeEmptyAnswers: false,
    metaUserDataMap: usersMap,
  });

  const headers: Array<HeaderType<FormSubmissionType>> = [
    {
      label: "Submission Name",
      key: "id",
      renderComponent: ({ item }) => (
        <RoutingLink to={linkToFormSubmissionDetail(item?.id)}>
          {`${item?.form_schema?.name} - ${item?.id?.slice(0, 7)}`}
        </RoutingLink>
      ),
    },
    {
      label: "Status",
      key: "status",
      isSortable: true,
      renderComponent: ({ item }) => (
        <DataTable.DataRow.PillCell
          variant={getStatusType(item.status, "form").type}
          value={toStartCaseString(item.status)}
        />
      ),
    },
    {
      label: "Form Template",
      key: "form_schema_id",
      renderComponent: ({ item }: { item: FormSubmissionType }) => {
        return (
          <RoutingLink
            to={linkToFormTemplateDetail(
              item.form_schema?.form_category_id,
              item.form_schema_id
            )}
          >
            {item?.form_schema?.name}
          </RoutingLink>
        );
      },
    },
    {
      label: "Form Category",
      key: "form_schema_version",
      renderComponent: ({ item }) => {
        return (
          <RoutingLink
            to={linkToFormCategoryDetails(item?.form_schema?.form_category_id)}
          >
            {item?.form_schema?.form_category?.name}
          </RoutingLink>
        );
      },
    },
    {
      label: "Created At",
      key: "created_at",
      isSortable: true,
      renderComponent: ({ item }) => (
        <DataTable.DataRow.DateCell
          value={item.created_at}
          withTime
        />
      ),
    },
    {
      label: "Last Saved At",
      key: "updated_at",
      isSortable: true,
      renderComponent: ({ item }) => (
        <DataTable.DataRow.DateCell
          value={item.updated_at}
          withTime
        />
      ),
    },
    {
      label: "Submitted By",
      key: "created_by",
      isSortable: true,
      renderComponent: ({ item }) => usersMap[item.created_by]?.name ?? "-",
    },
  ];

  return (
    <Page
      title="Form Submissions"
      onActiveTabKeyChange={(newKey: string) =>
        setSearchParams({ tab: newKey })
      }
      activeTabKey={searchParams?.tab ?? "submissions"}
      breadcrumbs={getBreadcrumbsObject(breadcrumbs)}
    >
      <Tab
        tabKey="submissions"
        title="Submissions"
      >
        <FormSubmissionsFilterPanel
          filterConfigStorageKey={filterConfigStorageKey}
          forDraftSubmissions={false}
        />
        <DataTablePanel
          storageKey={tableConfigStorageKey}
          panelProps={{
            title: "Submissions",
          }}
          dataTableProps={{
            items: submissionsResponse?.data ?? [],
            pagination: {
              page: tableState.page,
              itemsPerPage: tableState.itemsPerPage,
              total: submissionsResponse?.total_entries ?? 0,
              entityPerPage: "Rows per page",
            },
            sorting,
            headers,
            isLoading:
              isSubmissionsLoading ||
              createdByUsersQueries.some((q) => q.isLoading),
            onPaginationChange: updateTableState,
            onSortChange: updateTableState,
            emptyStateProps: {
              title: "There are no submissions to display",
            },
            getItemActions: ({ item }: { item: FormSubmissionType }) => [
              {
                label: "Export as PDF",
                buttonProps: {
                  icon: "share",
                  variant: "text",
                  isLoading: exportPDFMutation.isLoading,
                  onClick: () => {
                    exportPDFMutation.mutate(item);
                  },
                },
              },
            ],
          }}
        />
      </Tab>

      <Tab
        tabKey="drafts"
        title="Drafts"
      >
        <DraftFormSubmissionsList />
      </Tab>
    </Page>
  );
};

FormSubmissionList.propTypes = {
  breadcrumbs: PropTypes.array.isRequired,
};

export default FormSubmissionList;
