import React from "react";

import { Panel } from "@validereinc/common-components";

import { EventCategoriesTable } from "#src/batteries-included-components/Tables/EventCategoriesTable";

export const EventCategoriesPanel = ({ title }: { title: string }) => {
  return (
    <Panel title={title}>
      <EventCategoriesTable />
    </Panel>
  );
};
