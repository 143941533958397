import { FilterPanel, TextInput } from "@validereinc/common-components";
import React from "react";

import type { StorageKeys } from "@validereinc/common-components";

export const FormCategoriesFilterPanel = ({
  filterConfigStorageKey,
}: Pick<StorageKeys, "filterConfigStorageKey">) => (
  <FilterPanel
    storageKey={filterConfigStorageKey}
    filters={[
      {
        component: (
          <TextInput
            key="form-categories-name-filter"
            name="name"
            placeholder="Search Categories..."
            type="search"
            isInline
          />
        ),
      },
    ]}
  />
);
