import {
  Button,
  Panel,
  SelectInput,
  Title,
} from "@validereinc/common-components";
import PropTypes from "prop-types";
import React from "react";
import SamplesTable from "../SamplesTable/SamplesTable";
import StreamsTable from "../StreamsTable/StreamsTable";
import TestManagementTable from "../TestManagementTable";
import { TEST_ASSIGN_OPTIONS } from "./AssignTestConstant";

const AssignTestModalForm = ({
  form,
  inputs,
  formState,
  testList,
  shortCodes,
  onTestCheck,
  onTestClick,
  setShowNewSampleModal,
  allTests,
  samplesTableLastUpdatedAt,
  assignToErrorMessage,
}) => {
  const sharedProps = {
    showIcon: true,
    isDisabled: formState !== "enabled",
    isRequired: true,
  };

  return (
    <>
      <Title type="subheader">Selected Tests</Title>

      {inputs?.tests?.length === 0 ? (
        <div className="errorMessage">Select a test to assign to a sample</div>
      ) : null}

      <Panel className="assignTestModal__table assignTestModal__table--test">
        <TestManagementTable
          view="modal"
          tests={testList}
          checkedList={inputs?.tests ?? []}
          disabled={sharedProps.isDisabled}
          onTestCheck={onTestCheck}
          onTestClick={onTestClick}
          loadingState="loaded"
        />
      </Panel>

      <Title type="subheader">Assign Test To</Title>

      {assignToErrorMessage && (
        <div className="errorMessage">{assignToErrorMessage}</div>
      )}

      <SelectInput
        {...sharedProps}
        name="assignTo"
        options={TEST_ASSIGN_OPTIONS}
        ignoreCase
      />

      {inputs?.assignTo === TEST_ASSIGN_OPTIONS[0] ? (
        <>
          <Button
            onClick={() => setShowNewSampleModal(true)}
            disabled={sharedProps.isDisabled}
          >
            Add New Sample
          </Button>

          <Panel className="assignTestModal__table">
            <SamplesTable
              selectedSample={inputs?.sample ? [inputs?.sample] : []}
              onSampleCheck={(list) => form.setValue("sample", list.pop())}
              setSelectedSample={(samples) => form.setValue("sample", samples)}
              shortCodes={shortCodes}
              allTests={allTests}
              samplesTableLastUpdatedAt={samplesTableLastUpdatedAt}
              disabled={sharedProps.isDisabled}
            />
          </Panel>
        </>
      ) : (
        <>
          <Panel className="assignTestModal__table">
            <StreamsTable
              selectedStream={inputs?.stream ? [inputs.stream] : []}
              onStreamCheck={(list) => form.setValue("stream", list.pop())}
              shortCodes={shortCodes}
              disabled={sharedProps.isDisabled}
            />
          </Panel>
        </>
      )}
    </>
  );
};

AssignTestModalForm.propTypes = {
  form: PropTypes.object.isRequired,
  inputs: PropTypes.object.isRequired,
  formState: PropTypes.string.isRequired,
  testList: PropTypes.array.isRequired,
  onTestClick: PropTypes.func.isRequired,
  onTestCheck: PropTypes.func.isRequired,
  shortCodes: PropTypes.array,
  allTests: PropTypes.array,
  setShowNewSampleModal: PropTypes.func.isRequired,
  samplesTableLastUpdatedAt: PropTypes.instanceOf(Date),
  assignToErrorMessage: PropTypes.string,
};

export default AssignTestModalForm;
