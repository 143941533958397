import React from "react";

import { EventsDomain } from "@validereinc/domain";

import { toFlattenedObject } from "@validereinc/utilities";

import { useNavigate } from "#src/Routers/hooks";

import { linkToEventDetails } from "#src/routes/events/event-categories/[eventCategoryId]/templates/[templateId]/events/[eventId]";

// components
import {
  Button,
  CheckboxInput,
  DateSelectorInput,
  Dialog,
  Form,
  Link,
  TextInput,
  useAlert,
  useForm,
} from "@validereinc/common-components";

import { validateDates } from "./EventDialog.utils";

import { FacilityDropdownInput } from "../Dropdowns/FacilityDropdownInput";

import { useMutation } from "@tanstack/react-query";
import type { EventSchemaType } from "@validereinc/domain";
import omit from "lodash/omit";

type FormValues = {
  facilityId: string;
  start: string;
  end?: string;
  due_date: string;
  is_ongoing: boolean;
};

const CreateEventSuccess = ({ onClick }: { onClick: () => void }) => (
  <div>
    <p style={{ margin: 0 }}>Successfully created event.</p>
    <Link
      underline="always"
      onClick={onClick}
      label="View Event Details"
    />
  </div>
);

export const AddEventDialog = ({
  eventTemplate,
  onClose,
  ...restProps
}: {
  eventTemplate?: EventSchemaType;
  isOpen: boolean;
  onClose: () => void;
}) => {
  const navigate = useNavigate();
  const form = useForm();
  const { addAlert } = useAlert();

  const { isLoading, mutate: createEvent } = useMutation({
    mutationFn: async ({ facilityId, ...eventFields }: FormValues) => {
      let event = {
        ...eventFields,
        associated_facility_id: facilityId,
        event_schema_id: eventTemplate?.id,
        name: eventTemplate?.name,
        status: "open",
        classification: "planned",
      };

      if (event.is_ongoing) {
        // When is_ongoing we do not want to send the end date
        event = omit(event, "end");
      }

      const { id: eventId } = await EventsDomain.create(
        toFlattenedObject(event)
      );

      return { eventId, facilityId };
    },
  });

  const isOngoing = form.watch("is_ongoing");
  const start = form.watch("start");

  const closeDialog = () => {
    form.reset(); // Reset form on dialog close
    onClose();
  };

  const onFormSubmit = form.handleSubmit((values) => {
    const formValues = values as FormValues;
    const validationError = validateDates(formValues);

    if (validationError) {
      form.setError(validationError.type, {
        message: validationError.message,
      });
      return;
    }

    createEvent(formValues, {
      onSuccess: ({ eventId }: { eventId: string }) => {
        closeDialog();

        const eventLink = linkToEventDetails(
          eventTemplate?.event_category_id,
          eventTemplate?.id,
          eventId
        );

        addAlert?.({
          variant: "success",
          message: (
            <CreateEventSuccess
              onClick={() => navigate({ pathname: eventLink })}
            />
          ),
        });
      },
      onError: (err) => {
        console.error(err);
        addAlert?.({
          variant: "error",
          message: "Failed to create event. Please try again.",
        });
      },
    });
  });

  return (
    <Dialog
      title={`Create ${eventTemplate ? `${eventTemplate?.name} ` : ""}Event`}
      onClose={closeDialog}
      {...restProps}
      actionRow={[
        <Button
          key="create-event-button"
          isLoading={isLoading}
          variant="primary"
          onClick={onFormSubmit}
        >
          Create
        </Button>,
      ]}
    >
      <Form {...form}>
        <TextInput
          name="description"
          label="Description"
        />

        <FacilityDropdownInput
          isMulti={false}
          isFluid
          isRequired
          name="facilityId"
        />

        <CheckboxInput
          name="is_ongoing"
          label="Event is Ongoing"
          isLabelShown={false}
          isFluid
        />

        <DateSelectorInput
          variant="time"
          name="start"
          label="Start Date"
          isFluid
          isRequired
        />

        {!isOngoing ? (
          <DateSelectorInput
            variant="time"
            name="end"
            label="End Date"
            isFluid
            isRequired
            isDisabled={!start}
            min={start}
          />
        ) : null}

        <DateSelectorInput
          variant="time"
          name="due_date"
          label="Due Date"
          isFluid
          isDisabled={!start}
          min={start}
        />
      </Form>
    </Dialog>
  );
};
