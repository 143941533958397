import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import UserNotificationSettings from "./UserNotificationSettings";
import SettingSection from "../../SettingSection";
import { AlertDigestEmailSettings } from "./AlertDigestEmailSettings";
import { WorkflowDigestEmailSettings } from "./WorkflowDigestEmailSettings";
import { fetchUserProfile } from "#redux/actions/index";
import { addAlertMessage } from "#redux/actions/alertMessages";
import config from "../../../../config";

const ACCOUNT_NOTIFICATION_PREFERENCE_HELPSCOUT_LINK = `${config.KNOWLEDGE_BASE_URL}article/50-account-notification-preferences`;

const mapDispatchToProps = {
  fetchUserProfile,
  addAlertMessage,
};

const mapStateToProps = (state) => {
  return {
    profile: state.profiles.data,
    sites: state.sites.data?.toJS() ?? [],
  };
};

const ALERT_DIGEST_SETTING_MESSAGE =
  "Set the date and time you will receive your weekly alert digest email. " +
  "The email will contain alerts from the last seven days from your chosen " +
  "date time preference.";

const Notifications = (props) => {
  return (
    <div className="notifications">
      <SettingSection
        title="Workflow Digest Email"
        message="Set the time you will receive your daily Validere workflow digest email."
        link={ACCOUNT_NOTIFICATION_PREFERENCE_HELPSCOUT_LINK}
      >
        <WorkflowDigestEmailSettings
          profile={props.profile}
          addAlertMessage={props.addAlertMessage}
          fetchUserProfile={props.fetchUserProfile}
        />
      </SettingSection>

      <SettingSection
        title="Alert Digest Email"
        message={ALERT_DIGEST_SETTING_MESSAGE}
        link={ACCOUNT_NOTIFICATION_PREFERENCE_HELPSCOUT_LINK}
      >
        <AlertDigestEmailSettings
          profile={props.profile}
          addAlertMessage={props.addAlertMessage}
          fetchUserProfile={props.fetchUserProfile}
        />
      </SettingSection>

      <SettingSection
        title="Notification Settings"
        message="Set the type of notification you wish to receive by site."
        link={ACCOUNT_NOTIFICATION_PREFERENCE_HELPSCOUT_LINK}
      >
        <UserNotificationSettings
          height={props.height}
          sites={props.sites}
        />
      </SettingSection>
    </div>
  );
};

Notifications.propTypes = {
  height: PropTypes.number,
  profile: PropTypes.object.isRequired,
  sites: PropTypes.array.isRequired,
  addAlertMessage: PropTypes.func.isRequired,
  fetchUserProfile: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
