import { TemplatedReportProgressBanner } from "#batteries-included-components/Banners/TemplatedReportProgressBanner";
import { useSearchParams } from "#routers/hooks";
import { REPORTS_BREADCRUMB } from "#routes/reports";
import {
  TEMPLATED_REPORT_LIST_BREADCRUMB,
  TEMPLATED_REPORT_LIST_PAGE_TITLE,
} from "#routes/reports/templated-reports";
import { ExportHistoryTab } from "#routes/reports/templated-reports/ExportHistory/ExportHistoryTab";
import { TemplatedReportsTab } from "#routes/reports/templated-reports/TemplatedReports/TemplatedReportsTab";
import { Page, Tab } from "@validereinc/common-components";
import React from "react";

export const TemplatedReportListPage = () => {
  const [{ tab }, setSearchParams] = useSearchParams();

  return (
    <Page
      title={TEMPLATED_REPORT_LIST_PAGE_TITLE}
      breadcrumbs={[REPORTS_BREADCRUMB, TEMPLATED_REPORT_LIST_BREADCRUMB]}
      activeTabKey={tab ?? "reports"}
      onActiveTabKeyChange={(tab) => setSearchParams({ tab })}
    >
      <Tab
        title="Exportable Reports"
        tabKey="reports"
      >
        <TemplatedReportProgressBanner />
        <TemplatedReportsTab />
      </Tab>
      <Tab
        title="Export History"
        tabKey="history"
      >
        <TemplatedReportProgressBanner />
        <ExportHistoryTab />
      </Tab>
    </Page>
  );
};
