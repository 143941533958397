import {
  LegacyDataTable,
  Panel,
  Tooltip,
} from "@validereinc/common-components";
import * as PropTypes from "prop-types";
import React from "react";
import { AutoSizer } from "react-virtualized";
import useFilterInputs from "./SourceTableFilter";
import {
  sortByCategory,
  sortByMultiplier,
  sortByProductType,
  sortBySite,
} from "./SourceTableHelper";

const SourceTable = ({
  sourceKey,
  sources,
  showMultiplier,
  disabled,
  onSourceChecked,
  onEditClick,
}) => {
  const [filterRow, filterPillbox, filteredSourceList] =
    useFilterInputs(sources);

  const onSourceCheckboxClick = (checkedSource) => {
    onSourceChecked(checkedSource, sourceKey);
  };

  const nameRenderer = (rowData, columnKey) => {
    const name = rowData[columnKey];

    if (name) {
      return (
        <Tooltip content={name}>
          <div className="sourceTable__name">{name}</div>
        </Tooltip>
      );
    }

    return "-";
  };

  const siteRenderer = (rowData, columnKey) => {
    const sites = rowData[columnKey] ?? [];

    if (sites.length === 0) {
      return "All";
    }

    if (sites.length > 0) {
      if (sites.length > 0) {
        const tooltip = (
          <ul>
            {sites.map((site) => (
              <li key={site.id}>{site.name}</li>
            ))}
          </ul>
        );

        return (
          <Tooltip content={tooltip}>
            <span>{sites.length} sites</span>
          </Tooltip>
        );
      }
    }

    return "-";
  };

  const multiplierRenderer = (rowData, columnKey) => {
    const multiplier = rowData[columnKey];

    if (multiplier || multiplier === 0) {
      return `${multiplier}x`;
    }

    return "-";
  };

  const actionRenderer = (rowData) => {
    return (
      <a
        className="link"
        onClick={() => onEditClick(sourceKey, rowData)}
      >
        Edit
      </a>
    );
  };

  const categoryRenderer = (rowData, columnKey) => {
    return rowData?.metadata?.[columnKey] ?? "-";
  };

  const multiplierFactor = () => {
    return showMultiplier
      ? [
          {
            label: "Multiplier Factor",
            key: "multiplier",
            width: 150,
            cellRenderer: multiplierRenderer,
            sort: sortByMultiplier,
          },
        ]
      : [];
  };

  const actionColumn = () => {
    return onEditClick && !disabled
      ? [
          {
            label: "Actions",
            key: "actions",
            cellRenderer: actionRenderer,
            width: 75,
            disableSort: true,
          },
        ]
      : [];
  };

  const Headers = [
    {
      label: "Name",
      key: "name",
      width: 250,
      cellRenderer: nameRenderer,
      fixed: true,
    },
    {
      label: "Site",
      key: "restrict_to_sites",
      width: 150,
      cellRenderer: siteRenderer,
      sort: sortBySite,
    },
    {
      label: "Product Type",
      key: "product_type",
      width: 150,
      sort: sortByProductType,
    },
    {
      label: "Category",
      key: "Category",
      width: 150,
      cellRenderer: categoryRenderer,
      sort: sortByCategory,
    },
    ...multiplierFactor(),
    ...actionColumn(),
  ];

  return (
    <Panel>
      <div className="balanceSourceTable">
        <AutoSizer>
          {({ height, width }) => (
            <LegacyDataTable
              width={width}
              height={height}
              headers={Headers}
              list={filteredSourceList}
              filterKey="name"
              filterTitle="Name"
              defaultSortBy="name"
              defaultSortDirection="asc"
              onCheckboxClick={
                onSourceChecked && !disabled ? onSourceCheckboxClick : null
              }
              filterRow={filterRow}
              filterPillbox={filterPillbox}
            />
          )}
        </AutoSizer>
      </div>
    </Panel>
  );
};

SourceTable.propTypes = {
  title: PropTypes.string,
  sourceKey: PropTypes.string,
  sources: PropTypes.array.isRequired,
  showMultiplier: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  openSourceSelectionModal: PropTypes.func,
  onEditClick: PropTypes.func,
  onSourceChecked: PropTypes.func,
};

export default SourceTable;
