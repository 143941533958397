import CalibrationService from "#services/CalibrationService";
import {
  Modal,
  Title,
  useAlert,
  useForm,
} from "@validereinc/common-components";
import * as PropTypes from "prop-types";
import React, { useState } from "react";
import EffectiveDurationForm from "./EffectiveDurationForm";
import "./EffectiveDurationModal.scss";

export const EffectiveDurationModal = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const { addAlert } = useAlert();

  const formMethods = useForm({
    defaultValues: {
      effectiveDuration: props.instrumentDetail?.standard_check_days_duration,
    },
  });

  const handleSubmit = (formState) => {
    setIsLoading(true);

    CalibrationService.updateStandardCheckDuration(
      props.instrumentDetail?.id,
      formState.effectiveDuration
    )
      .then(() => {
        props.refetchCalibrationData();
        addAlert({
          variant: "success",
          message: "Effective Duration updated successfully.",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Modal
      className="effectiveDurationModal"
      open={props.show}
      onClose={props.onHide}
    >
      <Modal.Header>
        <Title>
          Edit Effective Duration: {props.instrumentDetail?.name ?? ""}
        </Title>
      </Modal.Header>

      <Modal.Body>
        <div className="effectiveDurationModal__hint">
          The updated Standard Check Effective Duration will apply to{" "}
          <b>all future checks</b>.
        </div>

        <EffectiveDurationForm
          disabled={isLoading}
          onSubmit={handleSubmit}
          formMethods={formMethods}
          isLoading={isLoading}
          onHide={props.onHide}
        />
      </Modal.Body>
    </Modal>
  );
};

EffectiveDurationModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  instrumentDetail: PropTypes.object,
  refetchCalibrationData: PropTypes.func.isRequired,
  addAlertMessage: PropTypes.func,
};

export default EffectiveDurationModal;
