import { getMeasurementValue } from "#components/Instrument/InstrumentHelper";
import { componentQualityComparator } from "#utils/componentQualitySortOrder";
import {
  getBalanceString,
  getComponentShortForm,
  getRangeString,
  getSTDString,
} from "#utils/stringFormatter";
import styles from "@validereinc/common-components/constants";
import React from "react";

export const getAlertSpecString = (alert, decimals) => {
  if (!alert) {
    return "-";
  }

  const lowerLimit = getMeasurementValue(alert.lower_limit, decimals);
  const upperLimit = getMeasurementValue(alert.upper_limit, decimals);

  const range = getRangeString(lowerLimit, upperLimit, alert.measurement_unit);

  const stdSigma = getSTDString(
    alert.std_sigma,
    alert.std_min_num_measurements,
    alert.std_num_measurements,
    alert.std_seconds_in_past
  );

  const balance = getBalanceString(
    alert.roq_previous_total_vol_percentage_deviation
  );

  if (!range && !stdSigma && !balance) {
    return "-";
  } else {
    return (
      <div>
        {range && <div>{range}</div>}

        {stdSigma && <div>{stdSigma}</div>}

        {balance && <div>{balance}</div>}
      </div>
    );
  }
};

const hasAlerts = (arr) => {
  return arr.every((element) => element === null);
};

export const getAlertTypeString = (alert) => {
  if (!alert) {
    return "-";
  }

  const hasLimitAlert = !hasAlerts([alert.lower_limit, alert.upper_limit]);
  const hasStatisticalAlert = !hasAlerts([alert.std_sigma]);
  const hasBalanceAlert = !hasAlerts([
    alert.roq_previous_total_vol_percentage_deviation,
  ]);

  if (hasLimitAlert || hasStatisticalAlert || hasBalanceAlert) {
    return (
      <div>
        {hasLimitAlert && <div>Limit</div>}

        {hasStatisticalAlert && <div>Statistical</div>}

        {hasBalanceAlert && <div>Balance</div>}
      </div>
    );
  } else {
    return "-";
  }
};

export const getDisplayProperties = (measurements) => {
  if (measurements) {
    return Object.keys(measurements)
      .sort(componentQualityComparator)
      .map((key) => {
        return {
          label: getComponentShortForm(key),
          key: key,
        };
      });
  }

  return [];
};

export const getMeasurementTextStyle = (alertTriggered) =>
  alertTriggered ? { color: styles.statusErrorColour } : null;

export const getAlertMeasurementPanelHeight = (
  alertMeasurements,
  maxPanelHeight,
  measurementRowHeight,
  measurementTableOffset
) => {
  const height = Math.min(
    measurementRowHeight * alertMeasurements.length + measurementTableOffset,
    maxPanelHeight
  );
  return { height: `${height}px` };
};
