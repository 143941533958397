import moment from "moment";
import { randomNumberGenerator } from "./demoHelper";

const START_OF_DEFAULT_RANGE = 6;

function generateRecords() {
  const records = [];

  for (let i = 0; i < START_OF_DEFAULT_RANGE; i++) {
    const date = moment()
      .subtract(START_OF_DEFAULT_RANGE - i, "months")
      .startOf("month")
      .startOf("day");

    records.push({
      id: i,
      sales_stream: {
        id: "1",
        name: "NGAS Pipeline",
      },
      start_date: date,
      end_date: date,
      sales_volume: {
        value: randomNumberGenerator(53966, 63300),
        unit: "e³m³",
      },
      emissions: {
        CO2: {
          value: randomNumberGenerator(7100, 7500),
          unit: "tonnes",
        },
        CH4: {
          value: randomNumberGenerator(52, 65),
          unit: "tonnes",
        },
        N2O: {
          value: randomNumberGenerator(0.12, 0.55),
          unit: "tonnes",
        },
        CO2E: {
          value: randomNumberGenerator(8500, 9100),
          unit: "tonnes",
        },
      },
      intensities: {
        Carbon: {
          value: randomNumberGenerator(0.15, 0.17),
          unit: "tonnes/m³OE",
        },
        Methane: {
          value: randomNumberGenerator(0.15, 0.14),
          unit: "%",
        },
      },
    });
  }

  return records;
}

export const EmissionReportDetailDemoData = {
  data: {
    id: 1,
    name: "Landfill 1 Network",
    facility_code: "facility_code",
    total_emissions: 123,
    sales_stream_ids: ["1"],
  },
};

export const EmissionReportSummaryDemoData = {
  data: generateRecords(),
};

const periodFrom = moment()
  .subtract(START_OF_DEFAULT_RANGE, "months")
  .startOf("month")
  .startOf("day");

const periodEnd = moment().endOf("month").endOf("day");

export const EmissionReportFacilityDemoData = {
  data: {
    data: [
      {
        id: 1,
        name: "Landfill 1",
        facility_code: "ABGP102934",
        start_date: periodFrom,
        end_date: periodEnd,
        total_emissions: {
          CO2: {
            value: 41367.18,
            unit: "tonnes",
          },
          CH4: {
            value: 313.96,
            unit: "tonnes",
          },
          N2O: {
            value: 2.344,
            unit: "tonnes",
          },
          CO2E: {
            value: 49914.89,
            unit: "tonnes",
          },
        },
      },
      {
        id: 2,
        name: "Landfill 1",
        facility_code: "ABGS102911",
        start_date: periodFrom,
        end_date: periodEnd,
        total_emissions: {
          CO2: {
            value: 3619.62,
            unit: "tonnes",
          },
          CH4: {
            value: 27.47,
            unit: "tonnes",
          },
          N2O: {
            value: 0.21,
            unit: "tonnes",
          },
          CO2E: {
            value: 4367.55,
            unit: "tonnes",
          },
        },
      },
      {
        id: 3,
        name: "Battery W-23",
        facility_code: "ABBT0124512",
        start_date: periodFrom,
        end_date: periodEnd,
        total_emissions: {
          CO2: {
            value: 2585.49,
            unit: "tonnes",
          },
          CH4: {
            value: 19.62,
            unit: "tonnes",
          },
          N2O: {
            value: 0.15,
            unit: "tonnes",
          },
          CO2E: {
            value: 3119.68,
            unit: "tonnes",
          },
        },
      },
      {
        id: 4,
        name: "Battery W-24",
        facility_code: "ABBT0124542",
        start_date: periodFrom,
        end_date: periodEnd,
        total_emissions: {
          CO2: {
            value: 2585.49,
            unit: "tonnes",
          },
          CH4: {
            value: 19.62,
            unit: "tonnes",
          },
          N2O: {
            value: 0.15,
            unit: "tonnes",
          },
          CO2E: {
            value: 3119.68,
            unit: "tonnes",
          },
        },
      },
      {
        id: 5,
        name: "Well 02/23-1C1",
        facility_code: "ABWI6736231",
        start_date: periodFrom,
        end_date: periodEnd,
        total_emissions: {
          CO2: {
            value: 1551.27,
            unit: "tonnes",
          },
          CH4: {
            value: 11.77,
            unit: "tonnes",
          },
          N2O: {
            value: 0.08,
            unit: "tonnes",
          },
          CO2E: {
            value: 1871.8,
            unit: "tonnes",
          },
        },
      },
    ],
  },
};
