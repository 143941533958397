import { queryParamsConverter } from "#routers/historyHelper";
import useQueryParams from "#src/Routers/useQueryParams";
import { GetAllObjectValue } from "#utils/objectFormatter";
import {
  FilterPillbox,
  MultiDropdownInputWithSearch,
} from "@validereinc/common-components";
import difference from "lodash/difference";
import differenceBy from "lodash/differenceBy";
import React, { useEffect, useState } from "react";

const DEFAULT_FILTER_DROPDOWN = {
  stream: {
    label: "Stream",
    inputs: [],
    options: [],
  },
  productType: {
    label: "Product Type",
    inputs: [],
    options: [],
  },
};

const filterStreams = (streams, filters) => {
  const { stream, productType } = filters;
  const filteredStreams = streams.filter((currentStream) => {
    if (stream?.length > 0 && !filters.stream.includes(currentStream.name)) {
      return false;
    }
    if (
      productType?.length > 0 &&
      !filters.productType.includes(currentStream.product_type)
    ) {
      return false;
    }

    return true;
  });

  return filteredStreams;
};

const useFilterInputs = (streamsList) => {
  const [filterDropdowns, setFilterDropdown] = useState({
    ...DEFAULT_FILTER_DROPDOWN,
  });

  const { queryParamInput, queryParamOptions } =
    queryParamsConverter(filterDropdowns);

  const [queryParams, setQueryParams] = useQueryParams(
    queryParamInput,
    queryParamOptions
  );

  useEffect(() => {
    if (streamsList.length > 0) {
      const { stream, productType } = { ...filterDropdowns };

      const streamOptions = GetAllObjectValue(streamsList, "name").filter(
        (option) => option
      );
      stream.options = streamOptions;
      const productTypeOptions = GetAllObjectValue(
        streamsList,
        "product_type"
      ).filter((option) => option);
      productType.options = productTypeOptions;

      setFilterDropdown((filterDropdowns) => ({
        ...filterDropdowns,
        stream,
        productType,
      }));
    }
  }, [streamsList]);

  const onDropdownSelect = (selectedValue, key) => {
    setQueryParams({ [key]: selectedValue });
  };

  const clearAllFilters = () => {
    const newQueryParam = { ...queryParams };

    Object.keys(newQueryParam).forEach((key) => {
      newQueryParam[key] = [];
    });

    setQueryParams(newQueryParam);
  };

  const onClearFilterClick = (filterObject) => {
    if (!filterObject) {
      return clearAllFilters();
    }

    const newQueryParam = { ...queryParams };
    const { filterKey, name, id } = filterObject;

    if (id) {
      newQueryParam[filterKey] = differenceBy(
        newQueryParam[filterKey],
        [filterObject],
        "id"
      );
    } else {
      newQueryParam[filterKey] = difference(newQueryParam[filterKey], [name]);
    }

    setQueryParams({ ...newQueryParam });
  };

  const filterRow = (
    <div className="filterRow">
      {Object.keys(filterDropdowns).map((key) => {
        const { label, options, labelKey } = filterDropdowns[key];
        return (
          <MultiDropdownInputWithSearch
            key={key}
            label={label}
            labelKey={labelKey ? labelKey : null}
            dropdownKey={key}
            value={queryParams[key]}
            options={options}
            onChange={onDropdownSelect}
          />
        );
      })}
    </div>
  );

  const filteredStreams = filterStreams(streamsList, queryParams);

  const filterPillbox = (
    <FilterPillbox
      filterBy={queryParams}
      onClearFilterClick={onClearFilterClick}
    />
  );

  return [filterRow, filterPillbox, filteredStreams];
};

export default useFilterInputs;
