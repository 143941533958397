export function getAssignToStreamErrorMessage(inputs) {
  const { stream, tests } = inputs;

  if (!stream) {
    return "Select a stream to assign tests to";
  }

  const nonOrphanTests = tests
    .filter((test) => test.sample_id)
    .map((test) => test.name);

  if (nonOrphanTests.length) {
    return `The test(s) (${nonOrphanTests.join(
      ", "
    )}) are already assigned to a sample.`;
  }

  return null;
}

export function getAssignToSampleErrorMessage(inputs) {
  const { sample } = inputs;

  if (!sample) {
    return "Select a sample to assign tests to";
  }
}
