import { DomainModelMetaSchema, DomainModelSchema } from "./DomainModelSchemas";
import { z } from "zod";

export const ReportingGroupSchema = z
  .object({
    /** the name of this reporting group */
    name: z.string(),
    /** description of the purpose of this reporting group */
    description: z.string(),
    /** order to show this reporting group in */
    priority: z.number().nonnegative(),
    /** under what company account is this reporting group under? */
    company_id: z.string(),
  })
  .merge(DomainModelSchema)
  .merge(DomainModelMetaSchema)
  .describe(
    "Represents a Reporting Group or Reporting Scenario in the Node API"
  );

export const CreateReportingGroupSchema = ReportingGroupSchema.pick({
  id: true,
  name: true,
  description: true,
  priority: true,
});

export const ReportingGroupEstimationMethodSchema = z
  .object({
    groupId: z.string().uuid(),
    estimationMethodId: z.string().uuid(),
  })
  .describe(
    "Represents a Reporting Group or Reporting Scenario tied to an Estimation Method in the Node API"
  );

export type ReportingGroup = z.infer<typeof ReportingGroupSchema>;
export type CreateReportingGroup = z.infer<typeof CreateReportingGroupSchema>;
export type ReportingGroupEstimationMethod = z.infer<
  typeof ReportingGroupEstimationMethodSchema
>;
