import { useListTemplatedReports } from "#hooks/adapters/useTemplatedReports";
import { convertMapToOpts } from "#src/constants";
import {
  DropdownInput,
  FilterPanel,
  StorageKeys,
} from "@validereinc/common-components";
import { ReportStatus } from "@validereinc/domain";
import startCase from "lodash/startCase";
import React from "react";

export const ExportHistoryFilterPanel = ({
  filterConfigStorageKey,
}: Pick<StorageKeys, "filterConfigStorageKey">) => {
  const { data, isLoading } = useListTemplatedReports({});
  const reports = data?.data ?? [];

  return (
    <FilterPanel
      storageKey={filterConfigStorageKey}
      filters={[
        {
          component: (
            <DropdownInput
              key="templated_report_name"
              name="templated_report_name"
              placeholder="Report Name"
              options={reports}
              isLoading={isLoading}
              labelKey="display_name"
              valueKey="name"
              isMulti
              isInline
              isSearchable
              isOptionalTextShown={false}
            />
          ),
        },
        {
          component: (
            <DropdownInput
              key="status"
              name="status"
              placeholder="Status"
              options={convertMapToOpts(ReportStatus, ([_, label]) =>
                startCase(label)
              )}
              isLoading={isLoading}
              labelKey="label"
              valueKey="value"
              isMulti
              isInline
              isSearchable
              isOptionalTextShown={false}
            />
          ),
        },
      ]}
    />
  );
};
