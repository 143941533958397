const data = {
  data: [
    {
      facilities: [
        {
          id: "eb5bd284-0001-4d8a-b490-5919ff03d985",
          name: "Landfill 1",
        },
      ],
      id: "d45d0e6b-7705-4641-b555-be5299a39753",
      name: "Landfill 1 Network",
      equipment: [{ id: "eq-123", name: "equipments-1" }],
      sales_product_stream_ids: [{ id: "stream-123", name: "stream" }],
      sources: {
        Flare: ["CH4", "NOx"],
        "Stationary Combustion": ["CO2", "PM10", "PM2.5"],
      },
    },
    {
      facilities: [
        {
          id: "eb5bd284-0001-4d8a-b490-5919ff03d985",
          name: "Landfill 2",
        },
      ],
      id: "d45d0e6b-7705-4641-b555-be5299a39753",
      name: "Landfill 2 Network",
      equipment: [{ id: "eq-123", name: "equipments-2" }],
      sales_product_stream_ids: [
        { id: "stream-123", name: "stream" },
        { id: "stream-123", name: "stream" },
      ],
      sources: {
        Flare: ["CH4", "NOx"],
        "Stationary Combustion": ["CO2", "PM10", "PM2.5"],
      },
    },
    {
      facilities: [
        {
          id: "eb5bd284-0001-4d8a-b490-5919ff03d985",
          name: "Landfill 1 LFG Capture",
        },
      ],
      id: "d45d0e6b-7705-4641-b555-be5299a39753",
      name: "Landfill 1 LFG Capture Network",
      equipment: [{ id: "eq-123", name: "equipments-3" }],
      sales_product_stream_ids: [{ id: "stream-123", name: "stream" }],
      sources: {
        Flare: ["CH4", "NOx"],
        "Stationary Combustion": ["CO2", "PM10", "PM2.5"],
      },
    },
  ],
};

export default data;
