import { GetAllObjectValue } from "#utils/objectFormatter";
import {
  FilterPillbox,
  MultiDropdownInputWithSearch,
} from "@validereinc/common-components";
import difference from "lodash/difference";
import differenceBy from "lodash/differenceBy";
import React, { useEffect, useState } from "react";
import { noStreamShortCodeOptionsMatch } from "../SamplesTable/SamplesTableFilterHelper";
import {
  clearAllInputs,
  getAllFilterInputs,
} from "../TestManagementTableFilterHelper";

const DEFAULT_FILTER_DROPDOWN = {
  streamShortCode: {
    label: "Stream Short Code",
    inputs: [],
    options: [],
  },
  productType: {
    label: "Product Type",
    inputs: [],
    options: [],
  },
  transportType: {
    label: "Transport Type",
    inputs: [],
    options: [],
  },
  sourceFacility: {
    label: "Source Facility",
    inputs: [],
    options: [],
    labelKey: "name",
  },
  terminalFacility: {
    label: "Terminal Facility",
    inputs: [],
    options: [],
    labelKey: "name",
  },
};

function filterStreams(streams, filters) {
  const {
    streamShortCode,
    productType,
    transportType,
    sourceFacility: sourceFacilities,
    terminalFacility: terminalFacilities,
  } = filters;

  const filteredStreams = streams.filter((stream) => {
    if (
      streamShortCode?.length > 0 &&
      noStreamShortCodeOptionsMatch(streamShortCode, stream.stream_short_code)
    ) {
      return false;
    }

    if (
      productType?.length > 0 &&
      productType.every((type) => type !== stream.product_type)
    ) {
      return false;
    }

    if (
      transportType?.length > 0 &&
      transportType.every((type) => type !== stream.transport_type)
    ) {
      return false;
    }

    if (
      sourceFacilities?.length > 0 &&
      sourceFacilities.every(
        (facility) => facility?.name !== stream.source_facility?.name
      )
    ) {
      return false;
    }

    if (
      terminalFacilities?.length > 0 &&
      terminalFacilities.every(
        (facility) => facility?.name !== stream.terminal_facility?.name
      )
    ) {
      return false;
    }
    return true;
  });

  return filteredStreams;
}

const useFilterInputs = (streams) => {
  const [filterDropdowns, setFilterDropdown] = useState({
    ...DEFAULT_FILTER_DROPDOWN,
  });

  useEffect(() => {
    if (streams.length > 0) {
      const {
        streamShortCode,
        productType,
        transportType,
        sourceFacility,
        terminalFacility,
      } = {
        ...filterDropdowns,
      };

      const streamShortCodeOptions = GetAllObjectValue(
        streams,
        "stream_short_code"
      ).filter((option) => option);
      streamShortCode.options = streamShortCodeOptions;

      const productTypeOptions = GetAllObjectValue(
        streams,
        "product_type"
      ).filter((option) => option);
      productType.options = productTypeOptions;

      const transportTypeOptions = GetAllObjectValue(
        streams,
        "transport_type"
      ).filter((option) => option);
      transportType.options = transportTypeOptions;

      const soureFacilityOptions = GetAllObjectValue(
        streams,
        "source_facility",
        "name"
      ).filter((option) => option);
      sourceFacility.options = soureFacilityOptions;

      const terminalFacilityOptions = GetAllObjectValue(
        streams,
        "terminal_facility",
        "name"
      ).filter((option) => option);
      terminalFacility.options = terminalFacilityOptions;

      setFilterDropdown({
        ...filterDropdowns,
        streamShortCode,
        productType,
        transportType,
        sourceFacility,
        terminalFacility,
      });
    }
  }, [streams]);

  const onDropdownSelect = (selectedValue, key) => {
    const filterDropdown = { ...filterDropdowns[key] };
    filterDropdown.inputs = selectedValue;

    setFilterDropdown({ ...filterDropdowns, [key]: filterDropdown });
  };

  const clearAllFilters = () => {
    setFilterDropdown(clearAllInputs(filterDropdowns));
  };

  const onFilterClick = (filterObject) => {
    if (filterObject) {
      const { filterKey, name } = filterObject;
      const updatedFilterDropdown = { ...filterDropdowns[filterKey] };

      // If options are an object with the identifying property "filterKey"
      if (DEFAULT_FILTER_DROPDOWN[filterKey].filterKey) {
        updatedFilterDropdown.inputs = differenceBy(
          updatedFilterDropdown.inputs,
          [filterObject],
          "id"
        );
      } else {
        updatedFilterDropdown.inputs = difference(
          updatedFilterDropdown.inputs,
          [name]
        );
      }

      setFilterDropdown({
        ...filterDropdowns,
        [filterKey]: updatedFilterDropdown,
      });
    } else {
      clearAllFilters();
    }
  };

  const filterRow = (
    <div className="filterRow">
      {Object.keys(filterDropdowns).map((key) => {
        const { label, labelKey, options, inputs } = filterDropdowns[key];

        return (
          <MultiDropdownInputWithSearch
            key={key}
            label={label}
            labelKey={labelKey ? labelKey : null}
            dropdownKey={key}
            value={inputs}
            options={options}
            onChange={onDropdownSelect}
          />
        );
      })}
    </div>
  );

  const filterInputs = getAllFilterInputs(filterDropdowns);

  const filteredTests = filterStreams(streams, filterInputs);

  const filterPillbox = (
    <FilterPillbox
      filterBy={filterInputs}
      onClearFilterClick={onFilterClick}
    />
  );

  return [filterRow, filterPillbox, filteredTests];
};

export default useFilterInputs;
