import { AssertIsAfterDate, AssertIsBeforeDate } from "#utils/assert";
import { DateTimeInput, SelectInput } from "@validereinc/common-components";
import * as PropTypes from "prop-types";
import React from "react";

const STATE_OPTIONS = ["validated", "invalidated", "pending"];

const ENABLE_APPLICABLE_PERIOD_OPTIONS = ["yes", "no"];

const SubmitAnalysisForm = ({ form, formState }) => (
  <>
    <SelectInput
      name="state"
      label="State"
      options={STATE_OPTIONS}
      isDisabled={formState !== "enabled"}
      showIcon
    />

    {form.watch("state") === "validated" && (
      <>
        <SelectInput
          name="enable_applicable_period"
          label="Applicable Period"
          className="submitAnalysisModal__applicablePeriodDropdown"
          options={ENABLE_APPLICABLE_PERIOD_OPTIONS}
          isDisabled={formState !== "enabled"}
          showIcon
        />

        {form.watch("enable_applicable_period") === "yes" && (
          <>
            <DateTimeInput
              name="from"
              label="From"
              validate={{
                earlierThanUntil: (date) => {
                  return (
                    (!!date &&
                      AssertIsBeforeDate(date, form.getValues("until"))) ||
                    "From date must be earlier than until date"
                  );
                },
              }}
              isDisabled={formState !== "enabled"}
              showIcon
            />

            <DateTimeInput
              name="until"
              label="Until"
              validate={{
                laterThanFrom: (date) => {
                  return (
                    (!!date &&
                      AssertIsAfterDate(date, form.getValues("from"))) ||
                    "Until date must be later than from date"
                  );
                },
              }}
              isDisabled={formState !== "enabled"}
              showIcon
            />
          </>
        )}
      </>
    )}
  </>
);

SubmitAnalysisForm.propTypes = {
  form: PropTypes.object.isRequired,
  formState: PropTypes.string,
};

export default SubmitAnalysisForm;
