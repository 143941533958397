import { fetchSiteList } from "#redux/actions/index";
import { SetHistoryQueryParam } from "#routers/historyHelper";
import StreamService from "#services/StreamService";
import { GetJSDataWithKey } from "#utils/immutableConverter";
import {
  Button,
  Form,
  FormButton,
  Modal,
  SelectInput,
  Title,
  useAlert,
  useForm,
} from "@validereinc/common-components";
import find from "lodash/find";
import moment from "moment";
import * as PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  getFirstDateFromTests,
  getFirstSiteNameFromTests,
} from "./CreateChainOfCustodyHelper";
import CreateSampleTestList from "./CreateSampleTestList";
import SampleForm from "./SampleModals/NewSampleModal/SampleForm";

const DEFAULT_INPUTS = {
  sample_name: null,
  sample_type: null,
  started_at: moment().startOf("day").toDate(),
  ended_at: moment().endOf("day").toDate(),
  scheduled: "No",
  workflow_instance: null,
  workflow_instance_id: "",
  stream: null,
  stream_id: null,
  sample_point_id: null,
  new_sample: true,
};

const mapStateToProps = (state) => {
  return {
    sites: GetJSDataWithKey("data", state.sites),
  };
};

const mapDispatchToProps = {
  fetchSiteList,
};

const CreateSampleModal = ({
  show,
  onAddSample,
  tests,
  sites,
  onCreateSample,
  selectedSamples,
  hide,
}) => {
  const [formState, setFormState] = useState("enabled");
  const { addAlert } = useAlert();

  const form = useForm({
    defaultValues: DEFAULT_INPUTS,
  });

  const onAddSampleClick = (inputs) => {
    setFormState("loading");

    const sample = {
      site_id: inputs.site?.id,
      sample_type: inputs.sample_type,
      start_utc_epoch: moment(inputs.started_at).unix(),
      end_utc_epoch: moment(inputs.ended_at).unix(),
      sample_point_id: inputs.sample_point_id,
      stream_id:
        inputs.stream?.id ?? inputs.workflow_instance?.stream_id ?? null,
      workflow_instance_id: inputs.workflow_instance?.id ?? null,
    };

    StreamService.createSample(sample)
      .then(({ data }) => {
        addAlert({
          variant: "success",
          message: "Sample created successfully.",
        });

        // The modal uses selectedSamples and selectedSite to prefill the form
        // with data. Once the new sample is created, the parent component may
        // need the new sampleId to continue working (via onCreateSample). If
        // it is through a link instead, the samples (and selectedSample if it
        // exist) is stored in the query params
        if (selectedSamples) {
          SetHistoryQueryParam({
            samples: [...selectedSamples, data.id],
          });
        } else if (onCreateSample) {
          onCreateSample([data]);
        } else {
          SetHistoryQueryParam({
            samples: [data.id],
          });
        }

        const refreshSampleList = true;
        modalClosed(refreshSampleList);
      })
      .finally(() => {
        setFormState("enabled");
      });

    onAddSample?.([sample]);
  };

  const onSiteChange = (site) => {
    form.setValue("site", site);
    form.setValue("workflow_instance", null);
  };

  const modalClosed = (refreshSampleList) => {
    hide(refreshSampleList);
    form.reset();
  };

  useEffect(() => {
    fetchSiteList();

    if (show && sites?.length > 0) {
      const testDate = getFirstDateFromTests(tests);
      const siteName = getFirstSiteNameFromTests(tests);
      const site = find(sites, { name: siteName });

      form.reset({
        ...DEFAULT_INPUTS,
        started_at: testDate,
        ended_at: testDate,
        site: site,
      });
    }
  }, [show, sites]);

  const inputs = form.watch();

  const refreshSampleList = false;

  return (
    <Modal
      open={show}
      onClose={() => modalClosed(refreshSampleList)}
    >
      <Form
        onSubmit={onAddSampleClick}
        {...form}
      >
        <Modal.Header>
          <Title>Add New Sample</Title>
        </Modal.Header>

        <Modal.Body>
          <SelectInput
            name="site"
            label="Site"
            labelKey="name"
            onChange={onSiteChange}
            options={sites}
            ignoreCase
            isRequired
            showIcon
            isDisabled={formState !== "enabled"}
          />

          <SampleForm
            form={form}
            inputs={inputs}
          />

          {tests && <CreateSampleTestList tests={tests} />}
        </Modal.Body>

        <Modal.Footer>
          <Button
            className="pull-left"
            onClick={() => modalClosed(refreshSampleList)}
          >
            Close
          </Button>

          <FormButton
            type="submit"
            variant="primary"
            isLoading={formState === "loading"}
            disabled={formState !== "enabled"}
          >
            Add Sample
          </FormButton>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

CreateSampleModal.propTypes = {
  show: PropTypes.bool.isRequired,
  modalClosed: PropTypes.func,
  onAddSample: PropTypes.func,
  tests: PropTypes.array,
  sites: PropTypes.array.isRequired,
  onCreateSample: PropTypes.func,
  selectedSamples: PropTypes.array,
  hide: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateSampleModal);
