import {
  apiPostServicesPromise,
  apiRequestServicesPromise,
  apiDeleteServicesPromise,
  //nodeApiDeleteServicesPromise, TODO uncomment once backend is ready with new emissions/filters endpoint
  //nodeApiPostServicesPromise,
  //nodeApiPutServicesPromise,
  //nodeApiRequestServicesPromise,
} from "./ServiceHelper";
//import { getFilterObject } from "#utils/api-abstraction";
import EmissionReportsDemoData from "./demo/emission_reports";
import EmissionRecordsDemoData from "./demo/emission_records";
import {
  EmissionReportDetailDemoData,
  EmissionReportSummaryDemoData,
  EmissionReportFacilityDemoData,
} from "./demo/emission_report_detail";

const getFormattedPostEmissionParams = ({
  name,
  equipment,
  facilities,
  streams,
}) => {
  return {
    name,
    equipment_ids: equipment?.map(({ id }) => id) ?? [],
    facility_ids: facilities?.map(({ id }) => id) ?? [],
    sales_product_stream_ids: streams?.map(({ id }) => id) ?? [],
  };
};

const EmissionService = {
  getEmissionReports() {
    //return apiRequestServicesPromise("/api/emissions/reports");
    return new Promise((resolve, _reject) => {
      resolve(EmissionReportsDemoData);
    });
  },

  getEmissionRecords() {
    // return apiRequestServicesPromise("/api/emissions/records");
    return new Promise((resolve, _reject) => {
      resolve(EmissionRecordsDemoData);
    });
  },

  getEmissionReportDetail() {
    //return apiRequestServicesPromise(`/api/emissions_reports/${reportId}`);
    return new Promise((resolve, _reject) => {
      resolve(EmissionReportDetailDemoData);
    });
  },

  getEmissionReportSummary() {
    /*
      return apiRequestServicesPromise(
      `/api/emissions_reports/${reportId}/summary`
      );
    */
    return new Promise((resolve, _reject) => {
      resolve(EmissionReportSummaryDemoData);
    });
  },

  getEmissionFacilityDetail() {
    /*
      return apiRequestServicesPromise(
      `/api/emissions_reports/${reportId}/facility_emissions`,
      params
      );
    */
    return new Promise((resolve, _reject) => {
      resolve(EmissionReportFacilityDemoData);
    });
  },

  getEmissionReportSettings(id) {
    return apiRequestServicesPromise(`/api/emissions/reports/${id}`);
  },

  postEmissionReport(formValues) {
    const params = getFormattedPostEmissionParams(formValues);

    return apiPostServicesPromise("/api/emissions/reports", params);
  },

  deleteEmissionReport(id) {
    return apiDeleteServicesPromise(`/api/emissions/reports/${id}`);
  },
  /* 
  TODO: once Backend is ready with the new /emissions/filters, uncomment the following code
  We want to provide the same functionality as the EquipmentService to manage saved filters
  getSavedEmissionsFilters({ page, rowPerPage, sort, sortDirection }) {
    return nodeApiRequestServicesPromise("/emissions/filters", {
      page,
      page_size: rowPerPage,
      sort_by: sort,
      sort_direction: sortDirection,
    });
  },

  saveEmissionsFilter(name, filters) {
    return nodeApiPostServicesPromise("/emissions/filters", {
      name,
      filter: getFilterObject(filters),
    });
  },

  editEmissionsFilter(id, filter) {
    return nodeApiPutServicesPromise(`/emissions/filters/${id}`, filter);
  },

  deleteEmissionsFilter(id) {
    return nodeApiDeleteServicesPromise(`/emissions/filters/${id}`);
  },
  */
};

export default EmissionService;
