import { Modal } from "@validereinc/common-components";
import * as PropTypes from "prop-types";
import React from "react";
import { useSelectionModalContext } from "../Context/selectionModalContext";
import RecordOfQualityModalFooter from "../RecordOfQualityModalFooter";
import RecordOfQualityResults from "./Results/RecordOfQualityResults";
import RecordOfQualitySelection from "./Selection/RecordOfQualitySelection";

const RecordOfQualityOverview = ({
  setShowResetSelectionModal,
  modalBodyHeight,
  onClose,
}) => {
  const { resultLoadingState } = useSelectionModalContext();

  if (resultLoadingState !== "error") {
    return (
      <>
        <Modal.Body style={{ height: modalBodyHeight, padding: 0 }}>
          <div className="recordOfQualityModal__overview">
            <RecordOfQualitySelection />

            <RecordOfQualityResults />
          </div>
        </Modal.Body>

        <Modal.Footer>
          <RecordOfQualityModalFooter
            setShowResetSelectionModal={setShowResetSelectionModal}
            onClose={onClose}
          />
        </Modal.Footer>
      </>
    );
  } else {
    return (
      <div className="recordOfQualityModal__error">
        An error has occurred. Please refresh the page.
      </div>
    );
  }
};

RecordOfQualityOverview.propTypes = {
  modalBodyHeight: PropTypes.number.isRequired,
  setShowResetSelectionModal: PropTypes.func.isRequired,
  onClose: PropTypes.func,
};

export default RecordOfQualityOverview;
