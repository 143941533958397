import { z } from "zod";
import {
  DomainModelMetaExtendedSchema,
  DomainModelMetaSchema,
  DomainModelSchema,
} from "./DomainModelSchemas";

export const FormSubmissionStatus = {
  VALIDATED: "validated",
  INVALIDATED: "invalidated",
  PENDING: "pending",
  SUBMITTED: "submitted",
  DRAFT: "draft",
} as const;

export type FormSubmissionStatusType =
  (typeof FormSubmissionStatus)[keyof typeof FormSubmissionStatus];

export const FormSubmissionWithAnswersSchema = z
  .object({
    company_id: z.string().uuid(),
    form_schema_id: z.string().uuid(),
    form_schema_version: z.number().int(),
    status: z.union([
      z.literal("validated"),
      z.literal("invalidated"),
      z.literal("pending"),
      z.literal("submitted"),
      z.literal("draft"),
    ]),
  })
  .merge(DomainModelSchema)
  .merge(DomainModelMetaSchema)
  .merge(DomainModelMetaExtendedSchema.pick({ created_by: true }));

export type FormSubmissionWithAnswersType = z.infer<
  typeof FormSubmissionWithAnswersSchema
> &
  Record<`${string}:${string}`, string | number | boolean>;
