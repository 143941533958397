import { useCustomAttributeFilters } from "#hooks/FilterPanel/useCustomAttributeFilters";
import { useDeleteNetwork, useListNetworks } from "#hooks/adapters/useNetworks";
import { useGetManyReportingGroups } from "#hooks/adapters/useReportingGroups";
import { useGetManyUsers } from "#hooks/adapters/useUsers";
import { useNetworkHeaders } from "#hooks/tables/useNetworkHeaders";
import { useTableSortingAndPagination } from "#redux/reducers/tableStateReducer";
import { NetworkListRoute } from "#routes/organization/networks";
import EditAssetGroupDialog from "#src/batteries-included-components/Dialogs/EditAssetGroupDialog";
import {
  Button,
  DataTablePanel,
  Dialog,
  SortingType,
  StorageKeys,
  useFilters,
} from "@validereinc/common-components";
import { AssetGroupType, AssetType, SortDirection } from "@validereinc/domain";
import { toFlattenedObject } from "@validereinc/utilities";
import React, { useState } from "react";

const sorting: SortingType = {
  sortBy: "name",
  sortDirection: SortDirection.ASCENDING,
};

export const NetworkDataTablePanel = ({
  filterConfigStorageKey,
  tableConfigStorageKey,
}: StorageKeys) => {
  const [assetIdToEdit, setAssetIdToEdit] = useState<string | undefined>();
  const [networkToDelete, setNetworkToDelete] = useState<AssetGroupType>();
  const [filters] = useFilters(filterConfigStorageKey);
  const [tableState, updateTableState] = useTableSortingAndPagination(
    sorting,
    filters
  );

  const { data: networkData, isLoading: isNetworkDataLoading } =
    useListNetworks({
      page: tableState.page,
      pageSize: tableState.itemsPerPage,
      sortBy: tableState.sortBy,
      sortDirection: tableState.sortDirection,
      filters: toFlattenedObject(filters),
    });

  const items = networkData?.data ?? [];

  const usersQuery = useGetManyUsers(
    Array.from(
      new Set(
        items.flatMap(({ created_by, updated_by }) => [created_by, updated_by])
      )
    )
  );

  const reportingGroupQuery = useGetManyReportingGroups(
    Array.from(
      new Set(
        items.map(
          ({ default_network_reporting_group_id }) =>
            default_network_reporting_group_id
        )
      )
    ).filter(
      (item) => item && (typeof item === "string" || item instanceof String)
    )
  );

  const reportingGroupMap = reportingGroupQuery.reduce(
    (accumulator: Record<string, string>, current) => {
      if (current.data?.id) {
        accumulator[current.data.id] = current.data.name;
      }
      return accumulator;
    },
    {}
  );

  const isLoading = isNetworkDataLoading || usersQuery.some((q) => q.isLoading);

  const { customAttributeHeaders } = useCustomAttributeFilters({
    assetType: AssetType.ASSET_GROUP,
    sortable: true,
  });

  const headers = [
    ...useNetworkHeaders({ reportingGroupMap }),
    ...customAttributeHeaders,
  ];

  const deleteNetwork = useDeleteNetwork();

  const onClickDeleteNetwork = async () => {
    try {
      if (networkToDelete) {
        deleteNetwork.mutate(networkToDelete.id);
      }
    } finally {
      setNetworkToDelete(undefined);
    }
  };

  const getItemActions = ({ item }: { item: AssetGroupType }) => [
    {
      label: "Edit",
      buttonProps: {
        onClick: () => setAssetIdToEdit(item.id),
        icon: "pencil-simple",
      },
    },
    {
      label: "Delete",
      buttonProps: {
        onClick: () => {
          setNetworkToDelete(item);
        },
        icon: "trash",
      },
    },
  ];

  return (
    <>
      <DataTablePanel
        storageKey={tableConfigStorageKey}
        panelProps={{ title: NetworkListRoute.title }}
        dataTableProps={{
          isLoading,
          items,
          headers,
          getItemActions,
          onSortChange: updateTableState,
          onPaginationChange: updateTableState,
          sorting,
          pagination: {
            page: tableState.page,
            itemsPerPage: tableState.itemsPerPage,
            total: networkData?.total_entries ?? 0,
          },
        }}
      />
      <Dialog
        isOpen={!!networkToDelete}
        onClose={() => setNetworkToDelete(undefined)}
        title={`Delete "${networkToDelete?.name}"`}
        actionRow={[
          <Button
            key="delete-dialog-action"
            onClick={onClickDeleteNetwork}
            variant="error"
          >
            Delete
          </Button>,
        ]}
      >
        <div>
          Are you sure you want to delete this network? This action cannot be
          undone.
        </div>
      </Dialog>
      <EditAssetGroupDialog
        assetGroupId={assetIdToEdit}
        onClose={() => setAssetIdToEdit(undefined)}
      />
    </>
  );
};
