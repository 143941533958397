import { getMethodString } from "#components/Records/Quality/RecordOfQualityHelper";
import config from "#src/config";
import { getTimeStringFromDate } from "#utils/timeFormatter";

// Success Messages
export const ROQ_RESET = "The accounting record has been reset";
export const ROQ_SAVED = "The accounting record has been saved";
export const ROQ_APPROVED = "The accounting record has been saved and approved";
export const ROQ_UNAPPROVED = "The accounting record has been unapproved";

// Error Messages
const PREVIOUS_ROQ_NONE_SELECTED = `${getMethodString(
  "previous_accounting_record"
)} requires at least one RoQ to be selected`;

const NO_SAMPLE_SELECTED = (method) =>
  `${getMethodString(method)} requires at least one sample to be selected`;

const SINGLE_SELECTION_LIMIT = (method) =>
  `${getMethodString(method)} accepts at most one sample selected`;

const noRoQOrSampleSelected = (method) =>
  `${getMethodString(
    method
  )} requires at least one sample or RoQ to be selected`;

// Validation Helpers
const validateSingleSelection = (method, sampleIds) => {
  if (sampleIds.length === 0) {
    return NO_SAMPLE_SELECTED(method);
  } else if (sampleIds.length > 1) {
    return SINGLE_SELECTION_LIMIT(method);
  }
  return null;
};

const validateMultiSelection = (method, sampleIds) => {
  if (sampleIds.length === 0) {
    return NO_SAMPLE_SELECTED(method);
  }
  return null;
};

const validateAnySelection = (
  method,
  sampleIds,
  previousAccountingRecordId
) => {
  if (sampleIds?.length === 0 && !previousAccountingRecordId) {
    return noRoQOrSampleSelected(method);
  }
};

const validateIntervalSelection = (interval) => {
  const { method, previousAccountingRecordId, sampleIds } = interval;

  switch (method) {
    case "previous_accounting_record":
      if (!previousAccountingRecordId) {
        return PREVIOUS_ROQ_NONE_SELECTED;
      }
      break;
    case "latest_sample":
      return validateSingleSelection(method, sampleIds);
    case "earliest_sample":
      return validateSingleSelection(method, sampleIds);
    case "average_of_selected_samples":
      return validateMultiSelection(method, sampleIds);
    case "volume_weighted_average":
      return validateAnySelection(
        method,
        sampleIds,
        previousAccountingRecordId
      );
    case null:
      return "No method selected";
  }
  return null;
};

/**
 * Given a roqSelectionState, returns an error message to display if it would
 * result in an invalid request. Returns null if a valid request.
 */
const validateRoQSelection = ({ intervalSelections }) => {
  for (const interval of intervalSelections) {
    const error = validateIntervalSelection(interval);

    if (error) {
      const from = getTimeStringFromDate(interval.from, config.DATETIME_FORMAT);
      const until = getTimeStringFromDate(
        interval.until,
        config.DATETIME_FORMAT
      );
      return `Selection Error (${from} - ${until}): ${error}`;
    }
  }

  return null;
};

export default validateRoQSelection;
