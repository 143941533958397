import { getComponentShortForm } from "#utils/stringFormatter";

// Component Quality ordering users are familiar with from other programs
export const componentQualitySortOrder = [
  // Mole % Inerts
  "Mole Percentage Hydrogen (H2)",
  "Mole Percentage Helium (He)",
  "Mole Percentage Nitrogen (N2)",
  "Mole Percentage Carbon Dioxide (CO2)",
  "Mole Percentage Hydrogen Sulphide (H2S)",
  "Mole Percentage Nitrous Oxide (N2O)",

  // Short Form
  "Hydrogen (H2)",
  "Helium (He)",
  "Nitrogen (N2)",
  "Carbon Dioxide (CO2)",
  "Nitrous Oxide (N2O)",

  // Mole % Hydrocarbons
  "Mole Percentage Methane (C1)",
  "Mole Percentage Ethane (C2)",
  "Mole Percentage Propane (C3)",
  "Mole Percentage Isobutane (iC4)",
  "Mole Percentage n-Butane (NC4)",
  "Mole Percentage Isopentane (iC5)",
  "Mole Percentage n-Pentane (NC5)",
  "Mole Percentage Hexanes (C6)",
  "Mole Percentage Heptanes Plus (C7+)",

  // Short Form
  "Methane (C1)",
  "Ethane (C2)",
  "Propane (C3)",
  "Isobutane (iC4)",
  "n-Butane (NC4)",
  "Isopentane (iC5)",
  "n-Pentane (NC5)",
  "Hexanes (C6)",
  "Heptanes Plus (C7+)",

  // Sample Properties
  "Source Temperature",
  "Receipt Temperature",
  "Source Pressure",
  "Receipt Pressure",
  "Hydrogen Sulphide (H2S)", // field ppm
  "API Gravity",
  "Measured Relative Density",
  "Relative Density C5 Plus",
  "Relative Density C6 Plus",
  "Relative Density C7+",
  "Molecular Weight C5+",
  "Molecular Weight C6+",
  "Molecular Weight C7+",

  // C7 Accumulative Properties
  "Mole Percentage Propane Minus (C3-)",
  "Mole Percentage Butane (C4)",
  "Mole Percentage Butane Minus (C4-)",
  "Mole Percentage Deemed Butane",
  "Mole Percentage Pentane (C5)",
  "Mole Percentage Pentanes Plus (C5+)",
  "Mole Percentage Hexanes Plus (C6+)",

  // Short Form
  "Propane Minus (C3-)",
  "Butane (C4)",
  "Butane Minus (C4-)",
  "Deemed Butane",
  "Pentane (C5)",
  "Pentanes Plus (C5+)",
  "Hexanes Plus (C6+)",

  // Higher Hydrocarbons
  "Mole Percentage Octanes (C8)",
  "Mole Percentage Nonanes (C9)",
  "Mole Percentage Decanes (C10)",
  "Mole Percentage Undecanes (C11)",
  "Mole Percentage Dodecanes (C12)",
  "Mole Percentage Tridecanes (C13)",
  "Mole Percentage Tetradecanes (C14)",
  "Mole Percentage Pentadecanes (C15)",
  "Mole Percentage Hexadecanes (C16)",
  "Mole Percentage Heptadecanes (C17)",
  "Mole Percentage Octadecanes (C18)",
  "Mole Percentage Nonadecanes (C19)",
  "Mole Percentage Eicosanes (C20)",
  "Mole Percentage Heneicosanes (C21)",
  "Mole Percentage Docosanes (C22)",
  "Mole Percentage Tricosanes (C23)",
  "Mole Percentage Tetracosanes (C24)",
  "Mole Percentage Pentacosanes (C25)",
  "Mole Percentage Hexacosanes (C26)",
  "Mole Percentage Heptacosanes (C27)",
  "Mole Percentage Octacosanes (C28)",
  "Mole Percentage Nonacosanes (C29)",
  "Mole Percentage Triacosanes Plus (C30+)",
];

const componentQualitySortOrderMap = componentQualitySortOrder.reduce(
  (sortOrderMap, quality, index) => {
    sortOrderMap[quality] = index;
    return sortOrderMap;
  },
  {}
);

// sort by component quality order first then alphabetically if they don't appear
// in above list
export const componentQualityComparator = (a, b) => {
  const aSortOrder = componentQualitySortOrderMap[a];
  const bSortOrder = componentQualitySortOrderMap[b];

  if (aSortOrder !== undefined && bSortOrder !== undefined) {
    return aSortOrder !== bSortOrder
      ? aSortOrder - bSortOrder
      : a.localeCompare(b);
  } else if (aSortOrder !== undefined) {
    return -1; // a should come before b
  } else if (bSortOrder !== undefined) {
    return 1; // b should come before a
  } else {
    const aValue = getComponentShortForm(a);
    const bValue = getComponentShortForm(b);

    return aValue.localeCompare(bValue);
  }
};

// Useful for lodash sortBy. Instead of taking a comparator function, it instead
// uses the function to get the property value for each unit and then uses its
// own comparator logic.
// Pass in the property key and it will return a function suited for lodash sortBy
export const getComponentQualityOrderValue = (property) => (a) => {
  const aValue = a?.[property] ?? a;

  return componentQualitySortOrderMap[aValue];
};

// Useful in datatable sorting
export const sortByComponentQualityProperties = (list, columnKey) => {
  return [...list].sort((a, b) =>
    componentQualityComparator(a[columnKey], b[columnKey])
  );
};
