import React, { useMemo } from "react";

import { useSearchParams } from "#routers/hooks";

import { AssetType, SortDirection } from "@validereinc/domain";
import { NetworkDevicesPanel } from "./NetworkDevicesPanel";
import { NetworkEquipmentPanel } from "./NetworkEquipmentPanel";
import { NetworkFacilitiesPanel } from "./NetworkFacilitiesPanel";
import { NetworkFlowsPanel } from "./NetworkFlowsPanel";

export const NETWORK_ENTITY_INITIAL_SORT = {
  sortBy: "name",
  sortDirection: SortDirection.ASCENDING,
};

export const NetworkEntityTablePanel = () => {
  const [searchParams] = useSearchParams();

  const { entity } = searchParams || {};

  const EntityPanel = useMemo(() => {
    switch (entity) {
      case AssetType.FLOW:
        return NetworkFlowsPanel;
      case AssetType.FACILITY:
        return NetworkFacilitiesPanel;
      case AssetType.EQUIPMENT:
        return NetworkEquipmentPanel;
      case AssetType.DEVICE:
        return NetworkDevicesPanel;
      default:
        return () => null;
    }
  }, [entity]);

  return <EntityPanel />;
};
