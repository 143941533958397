import Immutable from "immutable";
import moment from "moment";

import {
  FETCH_STREAM_LIST,
  FETCH_STREAM_LIST_SUCCESS,
  FETCH_STREAM_LIST_ERROR,
  USER_SIGNED_OUT,
} from "../constants/action-types";

const stream = Immutable.Record({
  id: undefined,
  name: undefined,
  type: undefined,
  product_type: undefined,
  adjustment_factor_ids: undefined,
  sample_point_ids: undefined,
  restrict_to_site_ids: undefined,
  metadata: undefined,
  transport_type: undefined,
  source_facility: undefined,
  terminal_facility: undefined,
  associated_with_tank: undefined,
});

export const data = new Immutable.Set([]);

export const StreamState = Immutable.Record(
  {
    isRequestInProgress: false,
    fetchError: false,
    fetchErrorMessage: "",
    fetchErrorStatus: "",
    lastRetrievedTime: 0,
    data: data,
  },
  "StreamState"
);

export default (state = new StreamState(), action) => {
  let newData = [];
  switch (action.type) {
    case FETCH_STREAM_LIST:
      return state.withMutations((s) => {
        s.isRequestInProgress = true;
        s.fetchError = false;
        s.fetchErrorMessage = "";
        s.fetchErrorStatus = "";
      });

    case FETCH_STREAM_LIST_SUCCESS:
      newData = new Immutable.Set(
        action.payload.map((streamData) => new stream(streamData))
      ).sort((a, b) => a.name.localeCompare(b.name));

      return state.withMutations((s) => {
        s.isRequestInProgress = false;
        s.data = newData;
        s.lastRetrievedTime = moment().unix();
      });

    case FETCH_STREAM_LIST_ERROR:
      return state.withMutations((s) => {
        s.isRequestInProgress = false;
        s.fetchError = true;
        s.fetchErrorMessage = action.payload.message;
        s.fetchErrorStatus = action.payload.status;
      });

    case USER_SIGNED_OUT:
      return state.withMutations((s) => {
        s.data = new Immutable.Set([]);
        s.isRequestInProgress = false;
        s.fetchError = false;
        s.fetchErrorMessage = "";
        s.fetchErrorStatus = "";
        s.lastRetrievedTime = 0;
      });

    default:
      return state;
  }
};
