import { parseCustomReportConfiguration } from "#batteries-included-components/Layouts/CustomReport/CustomReportConfigurationPanel/CustomReportConfigurationPanel.helpers";
import { CustomReportDetailLayout } from "#batteries-included-components/Layouts/CustomReport/CustomReportDetailLayout";
import {
  CustomReportVariants,
  getCustomReportFilters,
  getCustomReportGroupBy,
} from "#batteries-included-components/Layouts/CustomReport/CustomReportDetailLayout.helpers";
import { CreateCustomReportModal } from "#batteries-included-components/Modals/CustomReports/CreateCustomReportModal";
import { useNavigate } from "#routers/hooks";
import {
  CustomStorageKeyTypes,
  useCustomStorageKey,
  useStorageKey,
} from "#src/hooks/useStorageKey";
import { EmissionsCustomReportDetailRoute } from "#src/routes/emissions/custom-reports/[reportId]/detail";
import { EmissionsCustomReportCreateRoute } from "#src/routes/emissions/custom-reports/create";
import { useBreadcrumbsFromRoute } from "#src/utils/route";
import { Button, Page, useFilters } from "@validereinc/common-components";
import { getFilterObject } from "@validereinc/domain";
import React, { useEffect, useState } from "react";

const VARIANT = CustomReportVariants.EMISSIONS;
export const CreateEmissionsCustomReportPage = () => {
  const navigate = useNavigate();
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
  const storageKeys = useStorageKey("emissions-custom-report-detail");
  const configStorageKey = useCustomStorageKey(
    "emissions-custom-report-detail",
    CustomStorageKeyTypes.CONFIG
  );

  const [filters] = useFilters(storageKeys.filterConfigStorageKey);
  const [configuration, setConfiguration] = useFilters(configStorageKey);
  const [breadcrumbs] = useBreadcrumbsFromRoute(
    EmissionsCustomReportCreateRoute
  );

  const { rows } = parseCustomReportConfiguration(configuration);

  useEffect(() => {
    setConfiguration({});
  }, []);

  const filter = getFilterObject(
    getCustomReportFilters(VARIANT, configuration, filters)
  );

  return (
    <>
      <Page
        category="Emissions Custom Report"
        title="Unsaved Emissions Report"
        actionRow={[
          <Button
            key="save-custom-report"
            variant="primary"
            onClick={() => setIsSaveModalOpen(true)}
          >
            Save Report
          </Button>,
        ]}
        breadcrumbs={breadcrumbs}
      >
        <CustomReportDetailLayout
          configStorageKey={configStorageKey}
          reportVariant={VARIANT}
          {...storageKeys}
        />
      </Page>
      <CreateCustomReportModal
        variant={VARIANT}
        isOpen={isSaveModalOpen}
        onClose={() => {
          setIsSaveModalOpen(false);
        }}
        onSave={(id) => {
          navigate({
            pathname: EmissionsCustomReportDetailRoute.toLink({
              pathParams: {
                reportId: id,
              },
            }),
          });
        }}
        filter={filter}
        groupBy={getCustomReportGroupBy(VARIANT, rows)}
      />
    </>
  );
};
