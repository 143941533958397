const displayProperties = () => [
  {
    label: "Avg. Sulphur Content",
    key: "Sulphur",
  },
  {
    label: "Avg. Sulphur Content (K)",
    key: "average_sulfur_percentage_k",
    measurementKey: "Sulphur",
  },
  {
    label: "Std. Dev. Sulphur Content",
    key: "standard_deviation",
    decimals: 5,
    notMeasurement: true,
  },
  {
    label: "Std. Dev. Sulphur Content (K)",
    key: "standard_deviation_k",
    decimals: 5,
    notMeasurement: true,
  },
  {
    label: "Num. of Submeasurement",
    key: "repeats",
    notMeasurement: true,
  },
  {
    label: "CAL Range Set",
    key: "cal_range_set",
    notMeasurement: true,
  },
  {
    label: "CAL Curve Auto",
    key: "cal_curve_auto",
    notMeasurement: true,
  },
  {
    label: "Warm Up",
    key: "warm_up",
    notMeasurement: true,
  },
  {
    label: "Measure Time",
    key: "measure_time_sec",
    unit: "s",
    notMeasurement: true,
  },
];

export default displayProperties;
