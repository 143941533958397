import EquipmentForm from "#components/Organization/Equipment/EquipmentForm";
import { useEquipmentForm } from "#components/Organization/Equipment/useEquipmentForm";
import { useFacilityDetail } from "#hooks/useFacilities";
import { useBreadcrumbs } from "#routers/breadcrumbsHelper";
import { useNavigate } from "#routers/hooks";
import { linkToFacilities } from "#routers/links";
import useLocalization from "#src/hooks/useLocalization";
import { Page } from "@validereinc/common-components";
import * as PropTypes from "prop-types";
import React from "react";
import { useParams } from "react-router-dom";

const AddEquipment = ({ breadcrumbs }) => {
  const navigate = useNavigate();
  const { facilityId } = useParams();
  const { localize } = useLocalization();

  const [facility] = useFacilityDetail(facilityId);

  const { footer, formProps } = useEquipmentForm({
    defaultValues: { facility_id: facilityId },
    formType: "add",
    onSubmit: () => navigate({ pathname: linkToFacilities(facilityId) }),
    onCancel: () =>
      navigate({ pathname: linkToFacilities(facilityId), replace: true }),
  });

  const equipmentBreadcrumbs = useBreadcrumbs(
    breadcrumbs,
    {},
    { id: facilityId }
  );

  return (
    <Page
      title={`Add ${localize("Equipment")}`}
      breadcrumbs={equipmentBreadcrumbs.map(
        ({ title, ...restBreadcrumb }, index) => ({
          ...restBreadcrumb,
          title: index === 2 ? facility?.name : title,
        })
      )}
      footer={footer}
    >
      <EquipmentForm {...formProps} />
    </Page>
  );
};

AddEquipment.propTypes = {
  breadcrumbs: PropTypes.array.isRequired,
};

export default AddEquipment;
