import { toFlattenedObject } from "@validereinc/utilities";
import isEmpty from "lodash/isEmpty";
import startCase from "lodash/startCase";
import {
  EquipmentStatus,
  EquipmentType,
  EquipmentTypeType,
  ReportType,
} from "../schemas";
import type {
  CreateOneRequestType,
  GetListRequestType,
  GetListResponseType,
  GetOneRequestType,
  ResourceServiceType,
} from "../util";
import { FilterObjectType, fetchAndCollate, getFilterObject } from "../util";
import { SavedFiltersDomain, restAPI } from "./";

export type EquipmentFilterType = FilterObjectType<
  Pick<EquipmentType, "id" | "name" | "status" | "custom_attributes"> & {
    "type.id": string;
    "type.name": string;
    "event.id": string;
    "facility.id": string;
  }
>;

export type ListEquipmentParametersType = {
  filters?: EquipmentFilterType;
  period?: string;
  pageSize?: number;
  page?: number;
  sortBy?: string;
  sortDirection?: "asc" | "desc";
  isExcelFile?: boolean;
};

export type EquipmentServiceType = Pick<
  ResourceServiceType<EquipmentType>,
  "getOne" | "getList" | "updateOne" | "createOne"
>;

export const EquipmentDomainV2: EquipmentServiceType = {
  /**
   * Get Equipment API
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/equipment/get_equipment
   */
  getOne: ({ id, meta }: GetOneRequestType<{ period: string }>) =>
    restAPI.nodeAPI
      .GET<EquipmentType>({
        endpoint: `/equipment/${id}`,
        query: meta?.period ? { period: meta.period } : {},
      })
      .then((resp) => ({
        data: resp,
      })),

  getList: ({
    filters,
    meta,
    pageSize,
    page,
    sortBy,
    sortDirection,
  }: GetListRequestType<EquipmentFilterType, { period: string }>) =>
    restAPI.nodeAPI.POST({
      endpoint: "/equipment/search",
      query: meta ? meta : undefined,
      body: {
        page,
        page_size: pageSize,
        sort_by: sortBy,
        sort_direction: sortDirection,
        filter: getFilterObject(filters),
      },
    }),
  updateOne: async ({
    id,
    data,
  }: {
    id: string;
    data: Partial<EquipmentType>;
  }) => {
    const resp = await restAPI.nodeAPI.PUT<EquipmentType>({
      endpoint: `/equipment/${id}`,
      body: data,
    });

    return {
      data: resp,
    };
  },
  // createOne: function (params: CreateOneRequestType<any, any>): Promise<CreateOneResponseType<{ name: string; status: "active" | "inactive" | "deprecated"; type: { name: string; id: string; }; latitude: number; longitude: number; effective_date: Date; facility_id: string; custom_attributes: Record<string, string | number | boolean | string[] | number[]>; id: string; created_at: string; updated_at: string; created_by: string | null; updated_by: string | null; facility: { name: string; status: "active" | "inactive"; type: string; latitude: number; longitude: number; custom_attributes: Record<string, string | number | boolean | string[] | number[]>; company_id: string; id: string; created_at: string; updated_at: string; created_by: string | null; updated_by: string | null; }; type_id: string; }>> {
  //   throw new Error("Function not implemented.");
  // }
  createOne: async (params: CreateOneRequestType<EquipmentType>) =>
    restAPI.nodeAPI
      .POST<EquipmentType>({
        endpoint: "/equipment",
        body: params.data,
      })
      .then((resp) => ({ data: resp })),
};

export const EquipmentDomain = {
  /**
   * Get a list of equipment by search filter
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/equipment/search_equipment
   * @returns the standard wrapped API response of this endpoint
   */
  getEquipment: async ({
    filters,
    period,
    pageSize,
    page,
    sortBy = "name",
    sortDirection = "asc",
    isExcelFile = false,
  }: ListEquipmentParametersType) => {
    const { status, ...restFilters } = filters ?? {};
    if (isExcelFile) {
      return restAPI.nodeAPI.POST<GetListResponseType<EquipmentType>>({
        endpoint: "/equipment/search",
        query: period ? { period } : undefined,
        body: {
          page,
          page_size: pageSize,
          sort_by: sortBy,
          sort_direction: sortDirection,
          filter: {
            ...getFilterObject(toFlattenedObject(restFilters)),
            ...(typeof status === "string" && !isEmpty(status)
              ? { status }
              : getFilterObject({ status })),
          },
        },
        ...(isExcelFile
          ? {
              headers: {
                Accept:
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              },
              responseType: "arraybuffer",
            }
          : {}),
      });
    } else {
      return fetchAndCollate(
        ({ page, pageSize }) =>
          restAPI.nodeAPI.POST<GetListResponseType<EquipmentType>>({
            endpoint: "/equipment/search",
            query: period ? { period } : undefined,
            body: {
              page,
              page_size: pageSize,
              sort_by: sortBy,
              sort_direction: sortDirection,
              filter: {
                ...getFilterObject(toFlattenedObject(restFilters)),
                ...(typeof status === "string" && !isEmpty(status)
                  ? { status }
                  : getFilterObject({ status })),
              },
            },
          }),
        page,
        pageSize
      );
    }
  },

  /**
   * Get a single equipment by id
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/equipment/get_equipment
   * @returns the standard wrapped API response of this endpoint
   */
  getEquipmentById: async ({
    equipmentId,
    period,
  }: {
    equipmentId: string;
    period: string;
  }) =>
    await restAPI.nodeAPI.GET<EquipmentType>({
      endpoint: `/equipment/${equipmentId}`,
      query: period ? { period } : undefined,
    }),

  getEquipmentTypes: async () =>
    await restAPI.nodeAPI.GET<EquipmentTypeType[]>({
      endpoint: "/equipment_types",
    }),

  /**
   * Delete an equipment
   * @see {@link https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/equipment/delete_equipment}
   */
  delete: async ({ id }: { id: string }) =>
    await restAPI.nodeAPI.DELETE({
      endpoint: `/equipment/${id}`,
    }),

  /**
   * Equipment Saved Filter APIs
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/equipment/get_equipment_filter
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/equipment/post_equipment_filter
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/equipment/put_equipment_filter
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/equipment/delete_equipment_filter
   */
  savedFilters: SavedFiltersDomain<EquipmentFilterType>({
    endpoint: "/equipment/filters",
  }),

  /**
   * Export a list of equipment by search filter
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/equipment/export_equipment
   * @returns the standard wrapped API response of this endpoint
   */
  exportEquipment: async ({
    filters,
    period,
    sortBy = "name",
    sortDirection = "asc",
  }: ListEquipmentParametersType) => {
    const { status, ...restFilters } = filters ?? {};
    return restAPI.nodeAPI.POST<ReportType>({
      endpoint: "/equipment/export",
      query: period ? { period } : undefined,
      body: {
        sort_by: sortBy,
        sort_direction: sortDirection,
        filter: {
          ...getFilterObject(toFlattenedObject(restFilters)),
          ...(typeof status === "string" && !isEmpty(status)
            ? { status }
            : getFilterObject({ status })),
        },
      },
    });
  },

  statuses: {
    getList: () => ({
      data: Object.values(EquipmentStatus).map((equipmentStatus) => ({
        id: equipmentStatus,
        name: startCase(equipmentStatus),
      })),
    }),
  },
};
