import {
  FilterPanel,
  StorageKeys,
  TextInput,
} from "@validereinc/common-components";
import React from "react";

const UserGroupsFilterPanel = ({
  filterConfigStorageKey,
}: Pick<StorageKeys, "filterConfigStorageKey">) => {
  return (
    <FilterPanel
      defaultActiveKeys={["Filters"]}
      storageKey={filterConfigStorageKey}
      filters={[
        {
          component: (
            <TextInput
              type="search"
              name="search"
              placeholder="Search Users..."
            />
          ),
        },
      ]}
    />
  );
};

export default UserGroupsFilterPanel;
