import forEach from "lodash/forEach";
import { MASS_POLLUTANTS } from "#src/constants";

export const patchMissingDataWithZeros = (data: any[]): any[] => {
  forEach(data, (record) => {
    forEach(MASS_POLLUTANTS, (pollutant) => {
      if (!record.measurement[pollutant.mass]) {
        record.measurement[pollutant.mass] = 0;
      }
    });
  });
  // return a updated data array with the missing data patched
  return data;
};
