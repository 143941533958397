import React from "react";
import * as PropTypes from "prop-types";

import DeleteModal from "#components/Common/DeleteModal/DeleteModal";

import DashboardService from "#components/Services/DashboardService";

const DeleteDashboardChartModal = ({
  isOpen,
  onClose,
  chartId,
  chartName,
  onRefetchData,
}) => {
  const doDelete = async () => {
    await DashboardService.deleteChart({ chartId });
  };

  const onDelete = async () => {
    await onRefetchData();
  };

  return (
    <DeleteModal
      open={isOpen}
      onClose={onClose}
      entityName="Chart"
      instanceName={chartName}
      doDelete={doDelete}
      onDelete={onDelete}
    />
  );
};

DeleteDashboardChartModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  chartId: PropTypes.string.isRequired,
  chartName: PropTypes.string.isRequired,
  onRefetchData: PropTypes.func.isRequired,
};

export default DeleteDashboardChartModal;
