import { useFetchPaginatedData } from "#common/Table/PaginationController/hooks";
import { addAlertMessage } from "#redux/actions/alertMessages";
import { getFormattedMeasurementValue } from "#redux/reducers/measurements";
import SampleService from "#services/SampleService";
import config from "#src/config";
import { getTimeStringFromDate } from "#utils/timeFormatter";
import { LegacyDataTable } from "@validereinc/common-components";
import find from "lodash/find";
import * as PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { AutoSizer } from "react-virtualized";
import {
  getMeasurementCellStyle,
  getSampleComparisonTableHeader,
  getSampleComparisonTableRow,
  getSortedTestContainMeasurements,
  getUniqMeasurements,
} from "./SampleComparisonTableHelper";
import {
  sampleApplicablePeriodRenderer,
  sampleStateRenderer,
} from "./SampleHelper";
import { getMeasurementsWithAlerts } from "./SampleValidationTableHelper";

import classNames from "classnames/bind";

import styles from "./SampleComparisonTable.module.scss";

const cx = classNames.bind(styles);

const DEFAULT_HEADER_WIDTH = 135;

const TABLE_HEIGHT_OFFSET = 30;

const DEFAULT_PAGINATION_PARAMETER = {
  page: 1,
  rowPerPage: 10,
  sort: { started_at: "desc" },
};

const DEFAULT_SAMPLE_COMPARISON_TABLE_ROW = [
  {
    label: "Alerted Measurements",
    key: "alerted_measurements",
  },
  {
    label: "State",
    key: "state",
  },
  {
    label: "Validated Date",
    key: "validated_at",
  },
  {
    label: "Validated By",
    key: "validated_by",
  },
  {
    label: "Applicable Period",
    key: "applicable_period",
  },
];

const DEFAULT_SAMPLE_COMPARISON_TABLE_HEADER = [
  {
    label: "Sample Date",
    key: "label",
    width: 180,
    fixed: true,
    disableSort: true,
  },
];

const mapStateToProps = ({ streams, sites, measurements }) => {
  return {
    streams: streams.data?.toJS() ?? [],
    sites: sites.data?.toJS() ?? [],
    getFormattedMeasurementValue: (measurementKey, measurementValue) =>
      getFormattedMeasurementValue(measurements)(
        measurementKey,
        measurementValue
      ),
  };
};

const mapDispatchToProps = {
  addAlertMessage,
};

const alertedMeasurementsRenderer = (sample) => {
  const alertedMeasurements = getMeasurementsWithAlerts(sample);

  if (alertedMeasurements.length > 0) {
    return alertedMeasurements.length === 1
      ? `${alertedMeasurements.length} alert`
      : `${alertedMeasurements.length} alerts`;
  } else {
    return "On-Spec";
  }
};

const validateAtRenderer = (sample) => {
  return getTimeStringFromDate(sample.validated_at, config.DATE_FORMAT);
};

const validateByRenderer = (sample) => {
  return <div data-hj-suppress>{sample?.validated_by?.email ?? "-"}</div>;
};

const measurementRenderer = (
  sample,
  measurementType,
  getFormattedMeasurementValue,
  onTestClick
) => {
  const tests = getSortedTestContainMeasurements(sample, measurementType);

  if (tests.length > 0) {
    return (
      <div className="sampleComparisonTable__measurementCell">
        {tests.map((test, index) => {
          const isLastTest = tests.length - 1 === index;
          const measurement = find(test.measurements, {
            type: measurementType,
          });

          const measurementCellStyle = getMeasurementCellStyle(
            measurement.alerts,
            test.status
          );

          const formattedValue = getFormattedMeasurementValue(
            measurementType,
            measurement.value
          );

          const label = `${formattedValue}${test.note_count ? "*" : ""}`;

          return (
            <a
              key={test.id}
              className="linkableText enablePointerEvent"
              style={measurementCellStyle}
              onClick={(e) => {
                e.stopPropagation();
                onTestClick(test);
              }}
            >
              {isLastTest ? `${label}` : `${label},`}
            </a>
          );
        })}
      </div>
    );
  }

  return "-";
};

const SampleComparisonTable = ({
  selectedSample,
  getFormattedMeasurementValue,
  onTestClick,
}) => {
  const [sampleComparisonTableRow, setSampleComparisonTableRow] = useState([]);
  const [sampleComparisonTableHeader, setSampleComparisonTableHeader] =
    useState(DEFAULT_SAMPLE_COMPARISON_TABLE_HEADER);

  const filterInputs = {
    stream: [selectedSample.stream.id],
  };

  const [
    historicalSampleData,
    isLoading,
    paginationDetail,
    onPaginationChange,
  ] = useFetchPaginatedData(
    SampleService.getSamplesForValidation,
    filterInputs,
    DEFAULT_PAGINATION_PARAMETER
  );

  const cellRenderer = (rowData, columnKey) => {
    const sample =
      columnKey === "selectedSample"
        ? selectedSample
        : find(historicalSampleData, { id: columnKey });

    switch (rowData.key) {
      case "alerted_measurements":
        return alertedMeasurementsRenderer(sample);
      case "state":
        return sampleStateRenderer(sample);
      case "validated_at":
        return validateAtRenderer(sample);
      case "validated_by":
        return validateByRenderer(sample);
      case "applicable_period":
        return sampleApplicablePeriodRenderer(sample);
      default:
        return measurementRenderer(
          sample,
          rowData.key,
          getFormattedMeasurementValue,
          onTestClick
        );
    }
  };

  useEffect(() => {
    if (historicalSampleData.length > 0 && selectedSample) {
      const samples = [...historicalSampleData, selectedSample];
      const measurements = getUniqMeasurements(samples);

      setSampleComparisonTableRow(
        getSampleComparisonTableRow(
          DEFAULT_SAMPLE_COMPARISON_TABLE_ROW,
          measurements
        )
      );

      setSampleComparisonTableHeader(
        getSampleComparisonTableHeader(
          DEFAULT_SAMPLE_COMPARISON_TABLE_HEADER,
          selectedSample,
          historicalSampleData,
          DEFAULT_HEADER_WIDTH,
          cellRenderer
        )
      );
    }
  }, [historicalSampleData, selectedSample]);

  return (
    <div className={cx("sampleComparisonTable")}>
      <AutoSizer>
        {({ height, width }) => (
          <LegacyDataTable
            width={width}
            height={height - TABLE_HEIGHT_OFFSET}
            headers={sampleComparisonTableHeader}
            list={sampleComparisonTableRow}
            headerHeight={50}
            rowHeight={50}
            showOverlayLoader={isLoading}
            paginationDetail={paginationDetail}
            onPaginationChange={onPaginationChange}
          />
        )}
      </AutoSizer>
    </div>
  );
};

SampleComparisonTable.propTypes = {
  selectedSample: PropTypes.object.isRequired,
  getFormattedMeasurementValue: PropTypes.func.isRequired,
  onTestClick: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SampleComparisonTable);
