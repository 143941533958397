import { useAlert } from "@validereinc/common-components";
import { JobStatus, JobTimeoutMinutes, JobType } from "@validereinc/domain";
import { addMinutes, isPast } from "date-fns";
import { useEffect } from "react";

export const useTimeoutHandler = (
  job: JobType,
  errorMessage: string,
  onTimeout: () => void
) => {
  const { addAlert } = useAlert();

  useEffect(() => {
    if (!job || job.status !== JobStatus.STARTED || !hasTimedOut(job)) return;

    // Check for stale job from previous session
    if (hasTimedOut(job)) {
      addAlert?.({
        variant: "error",
        message: errorMessage,
      });
      onTimeout();
      return;
    }

    // Otherwise check again after JobTimeoutMinutes
    const timer = setTimeout(
      () => {
        if (!hasTimedOut(job)) return;

        addAlert?.({
          variant: "error",
          message: errorMessage,
        });
        onTimeout();
      },
      JobTimeoutMinutes * 60 * 1000
    );

    return () => {
      clearTimeout(timer);
    };
  }, [job?.id, job?.status, job?.started_at]);
};

const hasTimedOut = (job: JobType) => {
  const isStale = isPast(
    addMinutes(new Date(job?.started_at), JobTimeoutMinutes)
  );
  return job?.status === JobStatus.STARTED && isStale;
};
