import { useSearchParams } from "#routers/hooks";
import { UnitConfigTablePanel } from "#src/batteries-included-components/Panels/TablePanels/UnitConfigTablePanel";
import { Page, Tab } from "@validereinc/common-components";
import { UnitConfigurationPreference } from "@validereinc/domain";
import React from "react";
import {
  MEASUREMENT_TYPES_SETTINGS_BREADCRUMB,
  MEASUREMENT_TYPE_TITLE,
} from ".";
import { SETTINGS_BREADCRUMB } from "..";

export const MeasurementTypePage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const DEFAULT_TAB_KEY = "company";

  return (
    <Page
      title={MEASUREMENT_TYPE_TITLE}
      breadcrumbs={[SETTINGS_BREADCRUMB, MEASUREMENT_TYPES_SETTINGS_BREADCRUMB]}
      activeTabKey={searchParams?.tab ?? DEFAULT_TAB_KEY}
      onActiveTabKeyChange={(tabKey: string) => {
        setSearchParams({ tab: tabKey });
      }}
    >
      <Tab
        title={"My Company"}
        tabKey="company"
      >
        <UnitConfigTablePanel
          unitConfigurationPreference={UnitConfigurationPreference.COMPANY}
        />
      </Tab>
      <Tab
        title={"My User"}
        tabKey="user"
      >
        <UnitConfigTablePanel
          unitConfigurationPreference={UnitConfigurationPreference.USER}
        />
      </Tab>
    </Page>
  );
};
