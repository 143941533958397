import { RoutingLink } from "#src/batteries-included-components/RoutingLink";
import FileDownloadLink from "#src/components/Common/FileDownloadLink/FileDownloadLink";
import { linkToAssetDetailPage } from "#src/utils/links";
import { DataTable } from "@validereinc/common-components";
import {
  FormSchemaDataType,
  FormSubmissionAnswerType,
} from "@validereinc/domain";
import {
  booleanFormatter,
  datetimeFormatter,
  formatCoordinateDegrees,
  getFormattedNumber,
  isFiniteNumber,
} from "@validereinc/utilities";
import isValid from "date-fns/isValid";
import isBoolean from "lodash/isBoolean";
import React from "react";

export const renderFormSubmissionAnswer = (
  answer: FormSubmissionAnswerType,
  questionId: string,
  {
    measurementUnits,
    schema,
  }: {
    measurementUnits: any;
    schema: FormSchemaDataType;
  }
) => {
  if (schema.config.questions[questionId].type === "measurement") {
    if (!answer?.subject_type || !answer?.subject_id) return answer.value;

    const displayMeasurementUnit = measurementUnits.find(
      ({ id }) => schema.config.questions[questionId].measurement_unit === id
    );

    const displayValue =
      Number(answer.value) > 10 ** 6
        ? Number(answer.value).toPrecision(6)
        : getFormattedNumber(answer.value, null, {
            errorFallback: "n/a",
            maxFractionDigits: 6,
            showSmallNumberAsExponential: true,
            smallNumberThreshold: 0.01,
          });

    return (
      <RoutingLink
        to={{
          pathname: linkToAssetDetailPage(
            answer.subject_type,
            answer.subject_id
          ),
          query: {
            tab: "measurements",
          },
        }}
      >
        {displayValue}
        {displayMeasurementUnit?.name?.symbol ??
          schema.config.questions[questionId].measurement_unit}
      </RoutingLink>
    );
  }

  // IMPROVE: need better renderers for complex data types
  switch (schema.config.questions[questionId].data_type) {
    case "geo_point":
      return formatCoordinateDegrees(answer.value);
    case "file": {
      if (!answer.value?.name || !answer.value?.ref) {
        return "-";
      }

      return (
        <FileDownloadLink
          fileName={answer.value.name}
          fileUrl={answer.value.ref}
          prefetch={false}
        />
      );
    }
    case "lookup":
      if (!answer?.entity_type || !answer?.value) {
        return answer?.name ?? "-";
      }

      return (
        <RoutingLink
          to={linkToAssetDetailPage(answer?.entity_type, answer?.value)}
        >
          {answer?.name}
        </RoutingLink>
      );
    case "multi-pick-list": {
      if (!Array.isArray(answer.value)) return "-";

      const valuesToShow = answer.value.slice(0, 3);
      return valuesToShow.length < answer.value.length
        ? `${valuesToShow.join(", ")}, +${answer.value.length - valuesToShow.length} more`
        : valuesToShow.join(", ");
    }
    case "pick-list": {
      if (typeof answer.value !== "string" && !Array.isArray(answer.value))
        return "-";

      return answer.value;
    }
    case "date": {
      if (typeof answer.value !== "string" && !isValid(answer.value))
        return "-";

      return (
        <DataTable.DataRow.DateCell
          value={answer.value}
          withTime={false}
        />
      );
    }
    case "date-time": {
      if (typeof answer.value !== "string" && !isValid(answer.value))
        return "-";

      return (
        <DataTable.DataRow.DateCell
          value={answer.value}
          withTime
        />
      );
    }
    case "date-time-range":
      return `${datetimeFormatter(
        new Date(answer.value?.[0])
      )} - ${datetimeFormatter(new Date(answer.value?.[1]))}`;
    case "boolean": {
      if (!isBoolean(answer.value)) return "-";

      return booleanFormatter(Boolean(answer.value));
    }
    case "number": {
      if (Number(answer.value) > 10 ** 6) {
        return Number(answer.value).toPrecision(6);
      }

      return getFormattedNumber(answer.value, null, {
        errorFallback: "n/a",
        maxFractionDigits: 6,
        showSmallNumberAsExponential: true,
        smallNumberThreshold: 0.01,
      });
    }
    case "integer": {
      if (!isFiniteNumber(answer.value)) return "-";

      if (Number(answer.value) > 10 ** 6) {
        return Number(answer.value).toPrecision(6);
      }

      return answer.value;
    }
    case "string": {
      if (typeof answer.value !== "string") return "-";

      return answer.value;
    }
    default:
      return "n/a";
  }
};

export const renderFormSectionName = (
  sectionId: string,
  section_index: number,
  schema: FormSchemaDataType
) => {
  const sectionName =
    schema?.config?.sections?.find((section) => section.id === sectionId)
      ?.name || "";

  const sectionIndexAsNumber = Number(section_index);
  return `${sectionName} ${!isNaN(sectionIndexAsNumber) ? sectionIndexAsNumber + 1 : ""}`;
};
