import { EmissionsRoute } from "#src/routes/emissions";
import { RoutePath } from "#src/utils/route";

/**
 * route config for /app/emissions/custom-reports/create
 */
export const EmissionsCustomReportCreateRoute = EmissionsRoute.concat(
  new RoutePath({
    path: "/create",
    title: "Create Emissions Custom Report",
  })
);

export * from "./CreateEmissionsCustomReportPage";
