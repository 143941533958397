import {
  CustomReportVariants,
  CustomReportVariantType,
} from "#batteries-included-components/Layouts/CustomReport/CustomReportDetailLayout.helpers";
import { useDeleteCalculatorResultSavedFilter } from "#hooks/adapters/useCalculatorResults";
import { useDeleteRecordSavedFilter } from "#hooks/adapters/useRecords";
import { Button, Dialog, DialogProps } from "@validereinc/common-components";
import React from "react";

export const DeleteCustomReportModal = ({
  onClose,
  onSuccess,
  variant,
  customReportId,
}: Pick<DialogProps, "onClose"> & {
  customReportId?: string;
  onSuccess?: () => void;
  variant: CustomReportVariantType;
}) => {
  const isOpen = !!customReportId;
  const onSuccessHandler = () => {
    onClose?.();
    onSuccess?.();
  };

  const deleteVolumetricCustomReport = useDeleteRecordSavedFilter({
    onSuccess: onSuccessHandler,
  });

  const deleteEmissionsCustomReport = useDeleteCalculatorResultSavedFilter({
    onSuccess: onSuccessHandler,
  });

  const deleteCustomReport =
    variant === CustomReportVariants.EMISSIONS
      ? deleteEmissionsCustomReport
      : deleteVolumetricCustomReport;

  return (
    <Dialog
      isOpen={isOpen}
      title="Delete Custom Report"
      actionRow={[
        <Button
          key="delete"
          variant="error"
          onClick={() => deleteCustomReport.mutate(customReportId)}
        >
          Delete
        </Button>,
      ]}
      onClose={onClose}
    >
      This action cannot be undone.
    </Dialog>
  );
};
