import { RecordContext } from "#src/contexts/RecordContext";
import { StatusPanel, type PillProps } from "@validereinc/common-components";
import { getRecordStatusFromValues } from "@validereinc/domain";
import { datetimeFormatter, yearMonthName } from "@validereinc/utilities";
import React, { useContext } from "react";

export const RecordSummaryPanel = () => {
  const { record, users } = useContext(RecordContext) ?? {};
  const recordTitle = record ? yearMonthName(record.year_month) : "-";

  const lockStatus = record?.values
    ? getRecordStatusFromValues(record.values)
    : "";

  const recordStatusOptions: Record<
    string,
    { label: string; variant: PillProps["variant"] }
  > = {
    locked: { label: "Locked", variant: "default" },
    partially_locked: { label: "Partially Locked", variant: "info" },
    unlocked: { label: "Unlocked", variant: "info" },
  };

  const data = [
    { label: "Date", value: recordTitle },
    {
      label: "Updated By",
      value: users?.find(({ id }) => id === record?.updated_by)?.name,
    },
    {
      label: "Updated At",
      value: record?.updated_at
        ? datetimeFormatter(new Date(record.updated_at))
        : "-",
    },
  ];

  return (
    <StatusPanel
      isFluidY={false}
      data={data}
      statusText={lockStatus ? recordStatusOptions[lockStatus].label : ""}
      statusVariant={
        lockStatus ? recordStatusOptions[lockStatus].variant : "default"
      }
    />
  );
};
