import { DropdownInput, useFormContext } from "@validereinc/common-components";
import React from "react";
import { LookupFields } from "./LookupFields";
import { PickListFields } from "./PickListFields";

export const QuestionFields = () => {
  const form = useFormContext();

  const dataType = form.watch("data_type");

  return (
    <>
      <DropdownInput
        label="Data Type"
        name={"data_type"}
        options={[
          { label: "String", value: "string" },
          { label: "Number", value: "number" },
          { label: "Integer", value: "integer" },
          { label: "Boolean", value: "boolean" },
          { label: "Date", value: "date" },
          { label: "Date Time", value: "date-time" },
          { label: "Date Time Range", value: "date-time-range" },
          { label: "Geopoint", value: "geo_point" },
          { label: "File", value: "file" },
          { label: "Pick List", value: "pick-list" },
          { label: "Multi Pick List", value: "multi-pick-list" },
          { label: "Lookup", value: "lookup" },
        ]}
        labelKey="label"
        valueKey="value"
        isRequired
        isFluid
        onChange={(newValue) => {
          if (["pick-list", "multi-pick-list"].includes(newValue)) {
            form.setValue("pick_list_values", [{ value: "" }]);
          }
        }}
      />

      {dataType === "lookup" ? <LookupFields /> : null}

      {["pick-list", "multi-pick-list"].includes(dataType) ? (
        <PickListFields />
      ) : null}
    </>
  );
};
