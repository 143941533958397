export * from "./CustomDashboardDetailsPage";

export const linkToCustomDashboardDetails = (dashboardId = ":dashboardId") => {
  return `/app/dashboards/custom-dashboard/${dashboardId}/details`;
};

export const CUSTOM_DASHBOARD_DETAILS_DASHBOARD_BREADCRUMB = {
  title: "Custom Dashboards",
  path: linkToCustomDashboardDetails,
};
