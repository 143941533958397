import moment from "moment";
import filter from "lodash/filter";
import find from "lodash/find";
import intersectionBy from "lodash/intersectionBy";
import { InputIsMatched } from "#utils/filters";
import {
  AssertIsSameDate,
  AssertIsBeforeDate,
  AssertIsBetweenDate,
} from "#utils/assert";
import { SortListByType } from "#utils/sorters";
import { getColorPalette } from "#utils/styleCalculator";
import config from "#src/config";
import { getTimeStringFromDate } from "#utils/timeFormatter";

export function filterWorkflowTasks(inputs, list, haveWorkflows) {
  // filter out tasks that is not contains filtered propertes
  const filteredWorkflowTasks = filter(list, function (workflowTasks) {
    if (!InputIsMatched(inputs.name, workflowTasks.name)) {
      return false;
    }

    const testTypeFilter = find(inputs.testTypes, {
      id: workflowTasks.test_type,
    });
    if (inputs.testTypes.length > 0 && !testTypeFilter) {
      return false;
    }

    const statusFilter = find(inputs.status, {
      id: workflowTasks.state,
    });

    if (inputs.status.length > 0 && !statusFilter) {
      return false;
    }

    const workflowsFilter = find(inputs.workflows, {
      id: workflowTasks.workflow_id,
    });
    if (inputs.workflows.length > 0 && !workflowsFilter && haveWorkflows) {
      return false;
    }

    const streamsFilter = find(inputs.streams, {
      id: workflowTasks.stream_id,
    });
    if (inputs.streams.length > 0 && !streamsFilter) {
      return false;
    }

    const siteFilter = intersectionBy(inputs.sites, workflowTasks.sites, "id");
    if (inputs.sites.length > 0 && !siteFilter.length > 0) {
      return false;
    }

    return true;
  });

  return filteredWorkflowTasks;
}

export function getDateRange(date, view) {
  let from;
  let to;

  if (view === "monthly") {
    from = getTimeStringFromDate(
      moment(date).startOf("month"),
      config.DATE_FORMAT
    );
    to = getTimeStringFromDate(moment(from).endOf("month"), config.DATE_FORMAT);
  }

  return { from, to };
}

export const getWorkflowTasksWithCalibrationTasks = (
  workflowTasks,
  instruments,
  from,
  to
) => {
  const calibrationTasks = getInstrumentCalibrationTasks(instruments, from, to);
  return [...workflowTasks, ...calibrationTasks];
};

export const getInstrumentCalibrationTasks = (instruments, from, to) => {
  return instruments.reduce((instrumentTasks, instrument) => {
    if (
      instrument.next_calibration_date &&
      AssertIsBetweenDate(instrument.next_calibration_date, from, to, "day")
    ) {
      const calibrationDateEpoch = moment(instrument.next_calibration_date)
        .unix()
        .valueOf();
      return [
        ...instrumentTasks,
        {
          name: instrument.name,
          deadline_at_utc_epoch: calibrationDateEpoch,
          inserted_at_utc_epoch: calibrationDateEpoch,
          tests_required: ["calibrations"],
          state: getInstrumentCalibrationStatus(
            instrument.next_calibration_date
          ),
          sites: [{ name: instrument.location, id: instrument.site_id }],
          workflow_id: "calibration",
          test_type: "calibration",
          stream_id: "calibration",
          samples: [],
          note_count: 0,
        },
      ];
    } else {
      return instrumentTasks;
    }
  }, []);
};

const getInstrumentCalibrationStatus = (calibrationDate) => {
  // if the calibration next date is today then we set the status to be pending
  // if the calibration next date is late then we set the status to be late
  // otherwise its scheduled
  const today = moment();
  if (AssertIsSameDate(calibrationDate, today, "day")) {
    return "pending";
  } else if (AssertIsBeforeDate(calibrationDate, today, "day")) {
    return "late";
  } else {
    return "scheduled";
  }
};

export const getOptions = (workflows, sites, streams) => {
  workflows = [
    ...workflows,
    { id: "calibration", name: "Calibrations", test_type: "calibration" },
  ];
  streams = [
    ...streams,
    {
      stream_id: "calibration",
      name: "Calibrations",
      test_type: "calibration",
    },
  ];

  workflows = SortListByType(workflows, "name", "asc");
  streams = SortListByType(streams, "name", "asc");
  sites = SortListByType(sites, "name", "asc");

  const workflowsColorPalette = getColorPalette(workflows.length);
  const streamsColorPalette = getColorPalette(streams.length);

  workflows.forEach((workflow, index) => {
    workflow.color = workflowsColorPalette[index];
  });

  const streamOptions = streams.map((stream, index) => ({
    ...stream,
    id: stream.stream_id,
    color: streamsColorPalette[index],
  }));

  return { workflows, sites, streams: streamOptions };
};

export const getNumWeeksInMonth = (date) => {
  const daysInMonth = moment(date).daysInMonth();
  const firstDayOfMonth = moment(date).startOf("month").day();
  const lastDayOfMonth = moment(date).endOf("month").day();

  // weekdays given a number from 0-6, sunday: 0 ... saturday: 6
  const numDaysInFirstWeek = 7 - firstDayOfMonth;
  const numDaysInLastWeek = lastDayOfMonth + 1;

  /**
   * Usually 5 weeks in a month except for two cases:
   * 1) 28 days in month and 1st day is Sunday (meaning 4 full weeks only)
   * 2) >28 days in month and last week does not have enough days to finish month
   *    e.g. 31 days in month, 2 days in last week, 31 - 28 - 2 = 1 day leftover
   *    which must be by itself in the first week
   */
  let numWeeksInMonth = 5;
  if (daysInMonth === 28) {
    if (numDaysInFirstWeek === 7) {
      numWeeksInMonth = 4;
    }
  } else {
    const numDaysOver28 = daysInMonth % 7;
    const leftoverDays = numDaysOver28 - numDaysInLastWeek;

    if (leftoverDays > 0) {
      numWeeksInMonth = 6;
    }
  }

  return numWeeksInMonth;
};
