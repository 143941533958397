import { WorkflowTaskType, WorkflowType } from "@validereinc/domain";

export const getFormSchemaId = (
  task: WorkflowTaskType | null,
  workflow?: WorkflowType
) => {
  if (!workflow || !task) {
    return { formSchemaId: null };
  }

  const associatedStepConfig = workflow.config.steps[task.step_id];

  if (
    !associatedStepConfig ||
    associatedStepConfig.type !== "user_task" ||
    associatedStepConfig.task?.type !== "submit_form"
  ) {
    return { formSchemaId: null };
  }

  const {
    // TODO: need to turn underlying schema into a discriminated union to correctly extract this prop
    task: { form_schema_id: schemaId },
  } = associatedStepConfig;

  return { formSchemaId: schemaId };
};

export const getEventSchemaId = (
  task: WorkflowTaskType | null,
  workflow?: WorkflowType
) => {
  if (!workflow || !task) {
    return { eventSchemaId: null };
  }

  const associatedStepConfig = workflow.config.steps[task.step_id];

  if (
    !associatedStepConfig ||
    associatedStepConfig.type !== "user_task" ||
    associatedStepConfig.task?.type !== "complete_event"
  ) {
    return { eventSchemaId: null };
  }

  const {
    // TODO: need to turn underlying schema into a discriminated union to correctly extract this prop
    task: { event_step_id: eventStepId },
  } = associatedStepConfig;

  const eventAssociatedStepConfig = workflow.config.steps[eventStepId];

  if (
    !eventAssociatedStepConfig ||
    eventAssociatedStepConfig?.task?.type !== "create_event"
  ) {
    return { eventSchemaId: null };
  }

  return { eventSchemaId: eventAssociatedStepConfig.task.event_schema_id };
};
