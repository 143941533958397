export * from "./EventAddFormSubmissionPage";

export const EVENT_ADD_FORM_SUBMISSION_PAGE_TITLE = "Add Form Submission";

export const linkToAddFormSubmissionToEvent = (
  eventCategoryId = ":eventCategoryId",
  eventTemplateId = ":eventTemplateId",
  eventId = ":eventId"
) => {
  return `/app/events/event-categories/${eventCategoryId}/templates/${eventTemplateId}/events/${eventId}/add-form-submission`;
};

export const EVENT_ADD_FORM_SUBMISSION_BREADCRUMB = {
  title: EVENT_ADD_FORM_SUBMISSION_PAGE_TITLE,
  path: linkToAddFormSubmissionToEvent,
};
