import { InputStack, SelectInput } from "@validereinc/common-components";
import * as PropTypes from "prop-types";
import React from "react";
import {
  COMPONENT_OPTIONS,
  COMPONENT_TOTAL_OPTIONS,
  GAS_DEFAULTS,
  LIQUID_DEFAULTS,
  OIL_DEFAULTS,
  VOLUME_UNIT_OPTIONS,
} from "./CreateProductionReportsConstants";

const TYPE_OPTIONS = ["gas", "liquid", "oil"];

const METHOD_OPTIONS = ["gpa 2172", "directive 17"];

const ComponentModalForm = ({ form }) => {
  const components = form.watch("components");

  const onTypeChange = (typeValue) => {
    let componentPreset = GAS_DEFAULTS;
    if (typeValue === "liquid") {
      componentPreset = LIQUID_DEFAULTS;
    } else if (typeValue === "oil") {
      componentPreset = OIL_DEFAULTS;
    }

    form.setValue("components", componentPreset, {
      shouldValidate: true,
      shoudDirty: true,
    });
  };

  return (
    <>
      <SelectInput
        name="componentType"
        label="Type"
        options={TYPE_OPTIONS}
        onChange={onTypeChange}
      />

      <SelectInput
        name="components"
        label="Components"
        isRequired
        options={COMPONENT_OPTIONS}
        isMulti
        closeMenuOnSelect={false}
      />

      {components?.map((component) => {
        return (
          <InputStack
            key={component}
            name={component}
            label={component}
          >
            {!COMPONENT_TOTAL_OPTIONS.includes(component) && (
              <SelectInput
                name={`componentProperties.${component}.phase`}
                placeholder="Phase"
                options={TYPE_OPTIONS}
                showIcon
                validate={{
                  notNull: (value) =>
                    !value ? `${component} phase is required.` : null,
                }}
              />
            )}

            <SelectInput
              name={`componentProperties.${component}.unit`}
              placeholder="Unit"
              options={VOLUME_UNIT_OPTIONS}
              showIcon
              validate={{
                notNull: (value) =>
                  !value ? `${component} unit is required.` : null,
              }}
            />

            {component !== "energy" && (
              <SelectInput
                name={`componentProperties.${component}.advanced`}
                options={METHOD_OPTIONS}
                placeholder="Method (Advanced)"
                showIcon
                isClearable
              />
            )}
          </InputStack>
        );
      })}
    </>
  );
};

ComponentModalForm.propTypes = {
  form: PropTypes.object.isRequired,
};

export default ComponentModalForm;
