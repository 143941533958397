import React from "react";

import { Button, Panel, StorageKeys } from "@validereinc/common-components";

import { useFlowDetailFlow } from "#routes/organization/flows/[flowId]/detail/FlowDetailPage.helpers";
import { useNavigate } from "#src/Routers/hooks";
import { useParams } from "react-router";
import { FlowEditRoute } from "../edit";
import { FlowEquipmentTable } from "./FlowEquipmentTable";
import { FlowFacilityTable } from "./FlowFacilityTable";
import { FlowFlowsTable } from "./FlowFlowsTable";

export const FlowUpstreamPanel = ({
  filterConfigStorageKey,
}: Pick<StorageKeys, "filterConfigStorageKey">) => {
  const { data: flow } = useFlowDetailFlow({ filterConfigStorageKey });
  const { flowId } = useParams<{ flowId: string }>();
  const navigate = useNavigate();

  return (
    <Panel
      title="Origin"
      actionRow={
        <Button
          onClick={() => {
            navigate(FlowEditRoute.toLinkParts({ pathParams: { flowId } }));
          }}
        >
          Edit
        </Button>
      }
    >
      {flow?.upstream_facility ? (
        <FlowFacilityTable
          variant="upstream"
          filterConfigStorageKey={filterConfigStorageKey}
        />
      ) : null}

      {flow?.upstream_equipment ? (
        <FlowEquipmentTable
          variant="upstream"
          filterConfigStorageKey={filterConfigStorageKey}
        />
      ) : null}

      {flow?.upstream_flows?.length ? (
        <FlowFlowsTable
          variant="upstream"
          filterConfigStorageKey={filterConfigStorageKey}
        />
      ) : null}
    </Panel>
  );
};
