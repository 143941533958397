import { useListFlows } from "#hooks/adapters/useFlows";
import { FLOW_ATTRIBUTES, useFlowHeaders } from "#hooks/tables/useFlowHeaders";
import { useTableSortingAndPagination } from "#redux/reducers/tableStateReducer";
import useLocalization from "#src/hooks/useLocalization";
import {
  DataTablePanel,
  SortingType,
  StorageKeys,
  useFilters,
} from "@validereinc/common-components";
import type { AssetGroupAssetType } from "@validereinc/domain";
import { AssetType, SortDirection } from "@validereinc/domain";
import { toFlattenedObject } from "@validereinc/utilities";
import React from "react";

const sorting: SortingType = {
  sortBy: "name",
  sortDirection: SortDirection.ASCENDING,
};

export const NetworkFlowsTablePanel = ({
  filterConfigStorageKey,
  tableConfigStorageKey,
  selectedFlows,
  setSelectedFlows,
}: StorageKeys & {
  selectedFlows: Record<string, AssetGroupAssetType>;
  setSelectedFlows: (newFlows: Record<string, AssetGroupAssetType>) => void;
}) => {
  const { localize } = useLocalization();
  const [filters] = useFilters(filterConfigStorageKey);

  const [tableState, updateTableState] = useTableSortingAndPagination(
    sorting,
    filters
  );

  const { data: flows, isLoading } = useListFlows({
    page: tableState.page,
    pageSize: tableState.itemsPerPage,
    sortBy: tableState.sortBy,
    sortDirection: tableState.sortDirection,
    filters: toFlattenedObject(filters),
  });

  const items =
    flows?.data?.map((flow) => ({ ...flow, asset_type: AssetType.FLOW })) ?? [];

  const addItem = (item: AssetGroupAssetType) => {
    return selectedFlows?.[item.id]
      ? null
      : setSelectedFlows({
          ...selectedFlows,
          [item.id]: { ...item, asset_type: AssetType.FLOW },
        });
  };

  const getItemActions = ({ item }: { item: AssetGroupAssetType }) => {
    return [
      {
        label: "Add",
        buttonProps: {
          onClick: () => addItem(item),
          icon: "plus-circle",
        },
      },
    ];
  };

  return (
    <DataTablePanel
      storageKey={tableConfigStorageKey}
      panelProps={{
        title: localize("flow_plural"),
      }}
      dataTableProps={{
        headers: useFlowHeaders<AssetGroupAssetType>().filter(
          ({ key }) => ![FLOW_ATTRIBUTES.PRODUCT_TYPE.key].includes(key)
        ),
        isLoading,
        getItemId: (item) => item?.id,
        items,
        sorting,
        pagination: {
          page: tableState.page,
          itemsPerPage: tableState.itemsPerPage,
          total: flows?.total_entries ?? 0,
        },
        onSortChange: updateTableState,
        onPaginationChange: updateTableState,
        isFluid: false,
        selected: selectedFlows,
        onSelectionChange: setSelectedFlows,
        getItemActions,
      }}
    />
  );
};
