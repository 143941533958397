import React from "react";

import { useNavigate } from "#src/Routers/hooks";

import { Button, Panel } from "@validereinc/common-components";

import { EventFormSubmissionsTable } from "#src/batteries-included-components/Tables/EventFormSubmissionsTable";

import { linkToAddFormSubmissionToEvent } from "#src/routes/events/event-categories/[eventCategoryId]/templates/[templateId]/events/[eventId]/add-form-submission";

export const EventFormSubmissionsTablePanel = ({
  eventCategoryId,
  eventTemplateId,
  eventId,
}: {
  eventCategoryId: string;
  eventTemplateId: string;
  eventId: string;
}) => {
  const navigate = useNavigate();

  const onAddFormSubmission = () => {
    navigate({
      pathname: linkToAddFormSubmissionToEvent(
        eventCategoryId,
        eventTemplateId,
        eventId
      ),
    });
  };

  return (
    <Panel
      title="Form Submissions"
      actionRow={
        <Button
          icon="plus-circle"
          variant="secondary"
          onClick={onAddFormSubmission}
        >
          Add Form Submission
        </Button>
      }
      isFluidY={false}
    >
      <EventFormSubmissionsTable />
    </Panel>
  );
};
