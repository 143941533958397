import Immutable from "immutable";

import {
  FETCH_SITE_LIST,
  FETCH_SITE_LIST_SUCCESS,
  FETCH_SITE_LIST_ERROR,
  USER_SIGNED_OUT,
} from "../constants/action-types";

const site = Immutable.Record({
  id: undefined,
  name: undefined,
  location: undefined,
  gps_location_id: undefined,
  notes: undefined,
  reference_temperatures: undefined,
});

export const data = new Immutable.Set([]);

export const SiteState = Immutable.Record(
  {
    fetchError: false,
    fetchErrorMessage: "",
    fetchErrorStatus: "",
    data: data,
  },
  "SiteState"
);

export default (state = new SiteState(), action) => {
  let newData = [];

  switch (action.type) {
    case FETCH_SITE_LIST:
      return state.withMutations((s) => {
        s.fetchError = false;
        s.fetchErrorMessage = "";
        s.fetchErrorStatus = "";
      });

    case FETCH_SITE_LIST_SUCCESS:
      newData = new Immutable.Set(
        action.payload.map((siteData) => new site(siteData))
      ).sort((a, b) => a.name.localeCompare(b.name));

      return state.withMutations((s) => {
        s.data = newData;
        s.fetchError = false;
        s.fetchErrorMessage = "";
        s.fetchErrorStatus = "";
      });

    case FETCH_SITE_LIST_ERROR:
      return state.withMutations((s) => {
        s.fetchError = true;
        s.fetchErrorMessage = action.payload.message;
        s.fetchErrorStatus = action.payload.status;
      });

    case USER_SIGNED_OUT:
      return state.withMutations((s) => {
        s.data = new Immutable.Set([]);
        s.fetchError = false;
        s.fetchErrorMessage = "";
        s.fetchErrorStatus = "";
      });

    default:
      return state;
  }
};
