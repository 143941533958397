import { AccessDeniedLayout } from "#src/batteries-included-components/Layouts/Authorization/AccessDenied";
import { NotAvailableLayout } from "#src/batteries-included-components/Layouts/Authorization/NotAvailable";
import { UnknownErrorLayout } from "#src/batteries-included-components/Layouts/Errors/UnknownError";
import { HTTPForbiddenError, HTTPNotFoundError } from "@validereinc/domain";
import React from "react";

/**
 * Based on the provided error type, shows UI specific to that error. Designed
 * to be used with the <Page /> common component.
 * @see https://validere-storybook.vercel.app/?path=/story/molecules-page--page
 */
export const PageErrorContent = ({ error }: { error: unknown }) => {
  // 404s for invalid routes are handled through a separate page. this one
  // handles 404s from the back-end as a result of a permissions evaluation.
  if (error instanceof HTTPNotFoundError) {
    return <NotAvailableLayout />;
  }

  if (error instanceof HTTPForbiddenError) {
    return <AccessDeniedLayout />;
  }

  return <UnknownErrorLayout error={error} />;
};
