import { WorkflowTemplateType } from "../schemas/WorkflowTemplateSchemas";
import {
  GetListResponseType,
  ResourceServiceType,
  fetchAndCollate,
  getFilterObject,
} from "../util";
import { restAPI } from "./api";

export type WorkflowTemplateWithOwnerAndCreatedByNameType =
  WorkflowTemplateType & {
    workflow_owner_name?: string;
    created_by_name?: string;
  };

export type WorkflowTemplateAdapterType = Pick<
  ResourceServiceType<WorkflowTemplateType>,
  "getList" | "getOne"
>;

export const WorkflowTemplatesAdapter: WorkflowTemplateAdapterType = {
  /**
   * Get the complete list of Workflow Templates
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/workflow_templates/search_workflow_templates
   */
  getList: async ({ page, pageSize, sortBy, sortDirection, filters }) =>
    fetchAndCollate(
      async ({ page, pageSize }) =>
        await restAPI.nodeAPI.POST<GetListResponseType<WorkflowTemplateType>>({
          endpoint: "/workflow_templates/search",
          body: {
            sort_by: sortBy,
            sort_direction: sortDirection,
            page: page,
            page_size: pageSize,
            filter: getFilterObject(filters),
          },
        }),
      page,
      pageSize
    ),
  /**
   * Get the detail info Workflow Templates
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/workflow_templates/get_workflow_template
   */
  getOne: async ({ id }) => {
    const resp = await restAPI.nodeAPI.GET<WorkflowTemplateType>({
      endpoint: `/workflow_templates/${id}`,
    });
    return { data: resp };
  },
};
