import config from "#config";
import { getTimeStringFromDate } from "#utils/timeFormatter";
import { LegacyDataTable, Panel } from "@validereinc/common-components";
import * as PropTypes from "prop-types";
import React from "react";
import { AutoSizer } from "react-virtualized";
import "./SelectedSampleTable.scss";

const SelectedSampleTable = ({
  samples,
  onEditSampleClick,
  onRemoveSampleClick,
  disabled,
}) => {
  const newSampleRenderer = (rowData, columnKey) => {
    return rowData[columnKey] ? "Yes" : "No";
  };

  const sampleNameRenderer = (rowData, columnKey) => {
    return <div className="wrap">{`${rowData[columnKey]}`}</div>;
  };

  const sampleTypeRenderer = (rowData, columnKey) => {
    return <div className="capitalized">{rowData[columnKey]}</div>;
  };

  const sampleDateRenderer = (rowData) => {
    if (rowData.started_at && rowData.ended_at) {
      const startedAt = getTimeStringFromDate(
        rowData.started_at,
        config.DATETIME_FORMAT
      );

      const endAt = getTimeStringFromDate(
        rowData.ended_at,
        config.DATETIME_FORMAT
      );
      return (
        <div className="wrap">
          {`From: ${startedAt}`}
          <br />
          {`To: ${endAt}`}
        </div>
      );
    }

    return (
      <a
        className="link"
        onClick={() => onEditSampleClick(rowData)}
      >
        Select a date
      </a>
    );
  };

  const workflowTypeRenderer = (rowData) => {
    const workflowInstance = rowData.workflow_instance;
    let workflowType = "Non-routine";

    if (workflowInstance?.test_type === "field") {
      workflowType = "Field";
    }

    if (workflowInstance?.test_type === "third_party_lab") {
      workflowType = "Third Party Lab";
    }

    return <div className="capitalized">{workflowType}</div>;
  };

  const headers = [
    {
      label: "Sample Name",
      key: "sample_name",
      width: 200,
      fixed: true,
      cellRenderer: sampleNameRenderer,
    },
    {
      label: "Sample Point ID",
      key: "sample_point_id",
      width: 125,
    },
    {
      label: "Sample Type",
      key: "sample_type",
      width: 115,
      cellRenderer: sampleTypeRenderer,
    },
    {
      label: "Workflow",
      key: "workflow_instance.test_type",
      width: 100,
      cellRenderer: workflowTypeRenderer,
    },
    {
      label: "New Sample",
      key: "new_sample",
      cellRenderer: newSampleRenderer,
      width: 100,
    },
    {
      label: "Sample Date",
      key: "started_at",
      width: 250,
      cellRenderer: sampleDateRenderer,
    },
  ];

  const actionDropdown = [
    {
      title: "Edit Sample",
      onClick: (rowData) => onEditSampleClick(rowData),
      disabled: (rowData) => !rowData.new_sample,
    },
    {
      title: "Remove Sample",
      onClick: (rowData) => onRemoveSampleClick(rowData),
    },
  ];

  return (
    <Panel>
      <div className="selectedSampleTable">
        <AutoSizer>
          {({ height, width }) => (
            <LegacyDataTable
              headers={headers}
              list={samples}
              width={width}
              height={height}
              filterKey="sample_name"
              filterTitle="Samples"
              defaultSortBy="sample_name"
              defaultSortDirection="desc"
              rowHeight={60}
              actionDropdown={disabled ? null : actionDropdown}
            />
          )}
        </AutoSizer>
      </div>
    </Panel>
  );
};

SelectedSampleTable.propTypes = {
  samples: PropTypes.array.isRequired,
  onEditSampleClick: PropTypes.func.isRequired,
  onRemoveSampleClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default SelectedSampleTable;
