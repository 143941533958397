import {
  MAX_DECIMAL_PLACES,
  COMPONENTS,
  HEXANES_PLUS,
} from "./ComponentAnalysisConstant";

export function getComponentTotalAccumulation(measurements) {
  const componentMeasurements = Object.keys(measurements).filter(
    (measurement) => {
      return (
        COMPONENTS.filter((component) => component === measurement).length > 0
      );
    }
  );

  const totalAccumulation = componentMeasurements.reduce(
    (total, componentMeasurement) => {
      //For C6+ the sum should be C1 - C6 & Inerts (C6+ == C6, C7+ == 0)
      //For C7+ the sum should be C1-C6 & C7+ & Inerts (C6+ == C6 & C7+)
      if (componentMeasurement === HEXANES_PLUS) {
        return total;
      }

      return (
        total + parseFloat(measurements?.[componentMeasurement]?.value || 0)
      );
    },
    0
  );

  return totalAccumulation.toFixed(MAX_DECIMAL_PLACES);
}

export const getMeasurementErrorMessage = (name, value) => {
  if (value < 0) {
    return `${name} must be a positive value.`;
  }
  return null;
};
