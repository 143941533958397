import { EntityDropdownInput } from "#batteries-included-components/Dropdowns/EntityDropdownInput";
import { EQUIPMENT_STATUS_OPTIONS } from "#constants";
import { useCustomAttributeFilters } from "#hooks/FilterPanel/useCustomAttributeFilters";
import { useListCalculatorResults } from "#hooks/adapters/useCalculatorResults";
import { useListCalculators } from "#hooks/adapters/useCalculators";
import { useListEquipmentTypes } from "#hooks/adapters/useEquipment";
import { useListFlowTypes } from "#hooks/adapters/useFlows";
import {
  EquipmentDropdownInput,
  FacilityDropdownInput,
  FlowDropdownInput,
} from "#src/batteries-included-components";
import { ReportingGroupFilterSwitcher } from "#src/batteries-included-components/Filters/ReportingGroupFilterSwitcher";
import { FACILITY_STATUS_OPTIONS } from "#src/constants";
import { DEFAULT_DATE_RANGES } from "#src/hooks/useDateRange";
import useLocalization from "#src/hooks/useLocalization";
import { useQuery } from "@tanstack/react-query";
import {
  DateSelectorInput,
  DropdownInput,
  FilterPanel,
  StorageKeys,
  TextInput,
} from "@validereinc/common-components";
import {
  AssetGroupStatus,
  AssetType,
  AssetTypeType,
  EquipmentDomain,
  EquipmentStatus,
  EstimationMethodDomain,
  FacilityDomain,
  FacilityStatus,
  FlowStatus,
  ReportingGroupDomain,
} from "@validereinc/domain";
import { toStartCaseString } from "@validereinc/utilities";
import React, { useMemo } from "react";
export const CALCULATIONS_FILTER_CONFIG = {
  dateRange: {
    name: "date_range",
  },
  reportingScenario: {
    name: "reporting_group",
  },
  status: {
    name: "status",
  },
  equipmentFacilityStatus: {
    name: "equipment.facility.status",
  },
  flowFacilityStatus: {
    name: "flow.facility.status",
  },
  flowStatus: {
    name: "flow.status",
  },
  equipmentStatus: {
    name: "equipment.status",
  },
  flowEquipmentStatus: {
    name: "flow.equipment.status",
  },
  calculatorName: {
    name: "analytics_calculator_id",
    placeholder: "Search Calculators",
  },
  equipment: {
    name: "entity_id",
  },
  equipmentType: {
    name: "equipment.type.id",
  },
  flow: {
    name: "entity_id",
  },
  flowType: {
    name: "flow.type",
  },
  flowFacility: {
    name: "flow.associated_facility_id",
  },
  flowEquipment: {
    name: "flow.associated_equipment_id",
  },
  equipmentName: {
    name: "entity_name",
  },
  facility: {
    name: "facility_id",
  },
  networkName: {
    name: "asset_group.name",
  },
  facilityName: {
    name: "facility_name",
  },
  estimationMethod: {
    name: "id",
  },
  library: {
    name: "analytics_library_id",
  },
  calculator: {
    name: "analytics_calculator_id",
  },
  networkStatus: {
    name: "asset_group.status",
  },
} as const;

export const CalculationsFilterPanel = ({
  filterConfigStorageKey,
  assetType,
}: {
  assetType: AssetTypeType;
} & Pick<StorageKeys, "filterConfigStorageKey">) => {
  const { localize } = useLocalization();

  const equipmentTypesQuery = useListEquipmentTypes();
  const flowTypesQuery = useListFlowTypes();
  const calculatorQuery = useListCalculators();
  const validCalculatorsQuery = useListCalculatorResults({
    page: 1,
    pageSize: 1000,
    filters: {
      entity_type: { $exact: assetType },
    },
    groupBy: ["estimation_method.analytics_calculator_id"],
  });

  const validCalculatorIds =
    validCalculatorsQuery.data?.data.map(
      (item) => item["estimation_method.analytics_calculator_id"]
    ) ?? [];

  const calculatorOptions = useMemo(
    () =>
      calculatorQuery.data?.calculators && validCalculatorIds.length
        ? calculatorQuery.data.calculators
            .map((calculator) => ({
              value: calculator.id,
              label:
                calculator.versions.find(
                  (version) => version.version === calculator.default_version
                )?.title ?? calculator.id,
            }))
            .filter(({ value }) => validCalculatorIds.includes(value))
        : [],
    [calculatorQuery.data, validCalculatorIds]
  );

  const {
    customAttributeFilters: facilityCustomAttributeFilters,
    isLoading: isFacilityCustomAttributesLoading,
  } = useCustomAttributeFilters({
    assetType: AssetType.FACILITY,
    section: localize("Facility"),
    prefix:
      assetType === AssetType.FLOW
        ? "flow.facility.custom_attributes"
        : "equipment.facility.custom_attributes",
  });

  const {
    customAttributeFilters: equipmentCustomAttributeFilters,
    isLoading: isEquipmentCustomAttributesLoading,
  } = useCustomAttributeFilters({
    assetType: AssetType.EQUIPMENT,
    section: localize("Equipment"),
    prefix:
      assetType === AssetType.FLOW
        ? "flow.equipment.custom_attributes"
        : "equipment.custom_attributes",
  });

  const isFlowCustomAttributesQueryEnabled = assetType === AssetType.FLOW; // Only show flow attributes for flow calcs
  const {
    customAttributeFilters: flowCustomAttributeFilters,
    isLoading: isFlowCustomAttributesLoading,
  } = useCustomAttributeFilters({
    assetType: AssetType.FLOW,
    section: "Flow",
    prefix: "flow.custom_attributes",
    enabled: isFlowCustomAttributesQueryEnabled,
  });

  const isAssetGroupCustomAttributesQueryEnabled =
    assetType === AssetType.ASSET_GROUP;
  const {
    customAttributeFilters: assetGroupCustomAttributeFilters,
    isLoading: isAssetGroupCustomAttributesLoading,
  } = useCustomAttributeFilters({
    assetType: AssetType.ASSET_GROUP,
    section: "Networks",
    prefix: "asset_group.custom_attributes",
    enabled: isAssetGroupCustomAttributesQueryEnabled,
  });

  const reportingGroupQuery = useQuery({
    queryKey: ["reportingGroups"],
    queryFn: () => ReportingGroupDomain.getList({}),
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  const customAttributeFilters = useMemo(() => {
    switch (assetType) {
      case AssetType.EQUIPMENT:
        return [
          ...facilityCustomAttributeFilters,
          ...equipmentCustomAttributeFilters,
        ];
      case AssetType.FLOW:
        return [
          ...facilityCustomAttributeFilters,
          ...equipmentCustomAttributeFilters,
          ...flowCustomAttributeFilters,
        ];
      case AssetType.ASSET_GROUP:
        return [...assetGroupCustomAttributeFilters];
      default:
        return [];
    }
  }, [
    facilityCustomAttributeFilters,
    equipmentCustomAttributeFilters,
    flowCustomAttributeFilters,
    assetGroupCustomAttributeFilters,
    assetType,
  ]);

  const getDefaultAssetStatus = () => {
    switch (assetType) {
      case AssetType.ASSET_GROUP:
        return {
          [CALCULATIONS_FILTER_CONFIG.networkStatus.name]: [
            AssetGroupStatus.ACTIVE,
          ],
        };
      case AssetType.EQUIPMENT:
        return {
          [CALCULATIONS_FILTER_CONFIG.equipmentFacilityStatus.name]: [
            FacilityStatus.ACTIVE,
          ],
          [CALCULATIONS_FILTER_CONFIG.equipmentStatus.name]: [
            EquipmentStatus.ACTIVE,
          ],
        };
      case AssetType.FLOW:
        return {
          [CALCULATIONS_FILTER_CONFIG.flowStatus.name]: FlowStatus.ACTIVE,
          [CALCULATIONS_FILTER_CONFIG.flowEquipmentStatus.name]: [
            EquipmentStatus.ACTIVE,
          ],
          [CALCULATIONS_FILTER_CONFIG.flowFacilityStatus.name]: [
            FacilityStatus.ACTIVE,
          ],
        };
      default:
        return;
    }
  };

  const defaultAssetStatus = getDefaultAssetStatus();

  const isLoading =
    reportingGroupQuery.isLoading ||
    calculatorQuery.isLoading ||
    isFacilityCustomAttributesLoading ||
    isEquipmentCustomAttributesLoading ||
    isAssetGroupCustomAttributesLoading ||
    (isFlowCustomAttributesQueryEnabled && isFlowCustomAttributesLoading);

  return (
    <FilterPanel
      defaultActiveKeys={[
        localize("Facility"),
        localize("Equipment"),
        localize("Flow"),
        localize("Networks"),
        "Estimation Method",
      ]}
      isLoading={isLoading}
      storageKey={filterConfigStorageKey}
      defaultValue={{
        [CALCULATIONS_FILTER_CONFIG.reportingScenario.name]:
          reportingGroupQuery.data?.data[0]?.id,
        [CALCULATIONS_FILTER_CONFIG.dateRange.name]:
          DEFAULT_DATE_RANGES.lastWholeMonth,
        ...defaultAssetStatus,
      }}
      filters={[
        {
          component: (
            <ReportingGroupFilterSwitcher
              key={CALCULATIONS_FILTER_CONFIG.reportingScenario.name}
              name={CALCULATIONS_FILTER_CONFIG.reportingScenario.name}
              shouldSelectFirstOptionAsDefault
            />
          ),
        },
        {
          component: (
            <DateSelectorInput
              key={CALCULATIONS_FILTER_CONFIG.dateRange.name}
              name={CALCULATIONS_FILTER_CONFIG.dateRange.name}
              variant="month"
              isRange
              isInline
            />
          ),
        },
        ...(assetType !== AssetType.ASSET_GROUP
          ? [
              {
                component: (
                  <DropdownInput
                    key={CALCULATIONS_FILTER_CONFIG.calculatorName.name}
                    name={CALCULATIONS_FILTER_CONFIG.calculatorName.name}
                    placeholder="Search Calculator Name"
                    options={calculatorOptions}
                    labelKey="label"
                    valueKey="value"
                    isLoading={calculatorQuery.isLoading}
                    isInline
                    description="Select a single calculator to view inputs for each row"
                  />
                ),
              },
              {
                section: localize("Facility"),
                component: (
                  <TextInput
                    key={CALCULATIONS_FILTER_CONFIG.facilityName.name}
                    name={CALCULATIONS_FILTER_CONFIG.facilityName.name}
                    label={localize("Facility Name")}
                    isOptionalTextShown={false}
                    type="search"
                  />
                ),
              },
              {
                section: localize("Facility"),
                component: (
                  <EntityDropdownInput
                    key={CALCULATIONS_FILTER_CONFIG.facility.name}
                    name={CALCULATIONS_FILTER_CONFIG.facility.name}
                    label={localize("Facility")}
                    fetchEntityList={FacilityDomain.getList}
                  />
                ),
              },
              {
                section: localize("Facility"),
                component: (
                  <DropdownInput
                    label={`${localize("Facility")} Status`}
                    name={
                      assetType === AssetType.EQUIPMENT
                        ? CALCULATIONS_FILTER_CONFIG.equipmentFacilityStatus
                            .name
                        : CALCULATIONS_FILTER_CONFIG.flowFacilityStatus.name
                    }
                    key={
                      assetType === AssetType.EQUIPMENT
                        ? CALCULATIONS_FILTER_CONFIG.equipmentFacilityStatus
                            .name
                        : CALCULATIONS_FILTER_CONFIG.flowFacilityStatus.name
                    }
                    options={FACILITY_STATUS_OPTIONS}
                    labelKey="label"
                    valueKey="value"
                    isMulti
                    isFluid
                    isSearchable
                    isOptionalTextShown={false}
                  />
                ),
              },
              {
                section: localize("Equipment"),
                component: (
                  <TextInput
                    key={CALCULATIONS_FILTER_CONFIG.equipmentName.name}
                    name={CALCULATIONS_FILTER_CONFIG.equipmentName.name}
                    label={localize("Equipment Name")}
                    isOptionalTextShown={false}
                    type="search"
                  />
                ),
              },
              {
                section: localize("Equipment"),
                component: (
                  <EntityDropdownInput
                    key={CALCULATIONS_FILTER_CONFIG.equipment.name}
                    name={CALCULATIONS_FILTER_CONFIG.equipment.name}
                    label={localize("Equipment")}
                    fetchEntityList={EquipmentDomain.getEquipment}
                  />
                ),
              },
              {
                section: localize("Equipment"),
                component: (
                  <DropdownInput
                    key={CALCULATIONS_FILTER_CONFIG.equipmentType.name}
                    name={CALCULATIONS_FILTER_CONFIG.equipmentType.name}
                    label={`${localize("Equipment")} Type`}
                    options={equipmentTypesQuery.data ?? []}
                    isLoading={equipmentTypesQuery.isLoading}
                    labelKey="name"
                    valueKey="id"
                    isMulti
                    isFluid
                    isSearchable
                    isOptionalTextShown={false}
                  />
                ),
              },
              {
                section: localize("Equipment"),
                component: (
                  <DropdownInput
                    label={`${localize("Equipment")} Status`}
                    name={
                      assetType === AssetType.EQUIPMENT
                        ? CALCULATIONS_FILTER_CONFIG.equipmentStatus.name
                        : CALCULATIONS_FILTER_CONFIG.flowEquipmentStatus.name
                    }
                    key={
                      assetType === AssetType.EQUIPMENT
                        ? CALCULATIONS_FILTER_CONFIG.equipmentStatus.name
                        : CALCULATIONS_FILTER_CONFIG.flowEquipmentStatus.name
                    }
                    options={EQUIPMENT_STATUS_OPTIONS}
                    labelKey="label"
                    valueKey="value"
                    isMulti
                    isFluid
                    isSearchable
                    isOptionalTextShown={false}
                  />
                ),
              },
              {
                section: "Estimation Method",
                component: (
                  <EntityDropdownInput
                    key={CALCULATIONS_FILTER_CONFIG.estimationMethod.name}
                    name={CALCULATIONS_FILTER_CONFIG.estimationMethod.name}
                    label="Estimation Method"
                    fetchEntityList={EstimationMethodDomain.getList}
                  />
                ),
              },
            ]
          : [
              {
                section: "Networks",
                component: (
                  <TextInput
                    key={CALCULATIONS_FILTER_CONFIG.networkName.name}
                    name={CALCULATIONS_FILTER_CONFIG.networkName.name}
                    label={localize("Network Name")}
                    isOptionalTextShown={false}
                    type="search"
                  />
                ),
              },
              {
                section: "Networks",
                component: (
                  <DropdownInput
                    key={CALCULATIONS_FILTER_CONFIG.networkStatus.name}
                    name={CALCULATIONS_FILTER_CONFIG.networkStatus.name}
                    label="Network Status"
                    options={Object.values(AssetGroupStatus).map((id) => ({
                      id,
                      name: toStartCaseString(id),
                    }))}
                    labelKey="name"
                    valueKey="id"
                    isMulti
                    isFluid
                    isSearchable
                    isOptionalTextShown={false}
                  />
                ),
              },
            ]),
        ...(assetType === AssetType.FLOW
          ? [
              {
                section: localize("Flow"),
                component: (
                  <FlowDropdownInput
                    key={CALCULATIONS_FILTER_CONFIG.flow.name}
                    name={CALCULATIONS_FILTER_CONFIG.flow.name}
                    label={localize("Flow")}
                    isMulti
                  />
                ),
              },
              {
                section: localize("Flow"),
                component: (
                  <DropdownInput
                    key={CALCULATIONS_FILTER_CONFIG.flowType.name}
                    name={CALCULATIONS_FILTER_CONFIG.flowType.name}
                    label={`${localize("Flow")} Type`}
                    options={flowTypesQuery.data ?? []}
                    isLoading={flowTypesQuery.isLoading}
                    labelKey="name"
                    valueKey="id"
                    isMulti
                    isFluid
                    isSearchable
                    isOptionalTextShown={false}
                  />
                ),
              },
              {
                section: localize("Flow"),
                component: (
                  <FacilityDropdownInput
                    key={CALCULATIONS_FILTER_CONFIG.flowFacility.name}
                    name={CALCULATIONS_FILTER_CONFIG.flowFacility.name}
                    label={`Associated ${localize("Facility")}`}
                    isMulti
                  />
                ),
              },
              {
                section: localize("Flow"),
                component: (
                  <DropdownInput
                    key={CALCULATIONS_FILTER_CONFIG.flowStatus.name}
                    name={CALCULATIONS_FILTER_CONFIG.flowStatus.name}
                    label={`${localize("Flow")} Status`}
                    options={Object.values(FlowStatus).map((id) => ({
                      id,
                      name: toStartCaseString(id),
                    }))}
                    labelKey="name"
                    valueKey="id"
                    isMulti
                    isFluid
                    isSearchable
                    isOptionalTextShown={false}
                  />
                ),
              },
              {
                section: localize("Flow"),
                component: (
                  <EquipmentDropdownInput
                    key={CALCULATIONS_FILTER_CONFIG.flowEquipment.name}
                    name={CALCULATIONS_FILTER_CONFIG.flowEquipment.name}
                    label={`Associated ${localize("Equipment")}`}
                    isMulti
                  />
                ),
              },
              {
                section: "Estimation Method",
                component: (
                  <EntityDropdownInput
                    key={CALCULATIONS_FILTER_CONFIG.estimationMethod.name}
                    name={CALCULATIONS_FILTER_CONFIG.estimationMethod.name}
                    label="Estimation Method"
                    fetchEntityList={EstimationMethodDomain.getList}
                  />
                ),
              },
            ]
          : []),
        ...customAttributeFilters,
      ]}
    />
  );
};
