export const EMITTED_POLLUTANTS = ["CO2", "CH4", "N2O", "CO2E"];

export const POLLUTANTS = [
  ...EMITTED_POLLUTANTS,
  "NOx",
  "CO",
  "PM",
  "PM10",
  "PM2.5",
  "SO2",
  "VOC",
  "H2S",
];

export const INTENSITY = ["Carbon", "Methane"];

export const DEFAULT_EMISSION_DECIMAL = 2;

export const DEFAULT_POLLUTANT = POLLUTANTS[0]; // CO2E
