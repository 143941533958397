import { frequencyTypeOptions, OFFSPEC } from "./WorkflowFormHelpers";

export const getOffSpec = (responseData) => {
  const offspec = [];
  if (responseData.offspec_retest) {
    offspec.push(OFFSPEC[0]);
  }
  if (responseData.offspec_retest_3pl) {
    offspec.push(OFFSPEC[1]);
  }

  return offspec;
};

export const getFrequencyDetails = (responseData) => {
  const frequencyDetails = {};
  if (responseData.frequency.type === "weekly") {
    const repeats = responseData.frequency.repeat_every_x_weeks;

    frequencyDetails.repeats = repeats;
    frequencyDetails.frequencyType = frequencyTypeOptions(repeats)[0];
    frequencyDetails.repeatsDay = responseData.frequency.repeat_on_days;
  } else {
    const repeats = responseData.frequency.repeat_every_x_months;

    frequencyDetails.repeats = repeats;
    frequencyDetails.frequencyType = frequencyTypeOptions(repeats)[1];
    frequencyDetails.repeatsDay = [];
  }

  return frequencyDetails;
};
