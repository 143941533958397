import CalibrationService from "#services/CalibrationService";
import {
  Modal,
  Title,
  useAlert,
  useForm,
} from "@validereinc/common-components";
import moment from "moment";
import * as PropTypes from "prop-types";
import React, { useState } from "react";
import EditCalibrationForm from "./EditCalibrationForm";

export const EditCalibrationModal = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const { addAlert } = useAlert();

  const formMethods = useForm({
    defaultValues: {
      lastChecked: moment(
        props.instrumentDetail?.prev_calibration_date
      ).toDate(),
      nextCheck: moment(props.instrumentDetail?.next_calibration_date).toDate(),
    },
  });

  const getNextCheckDateFromLast = (date) => {
    const nextDate = new Date(date);
    //If the instrument measures Vapor Pressure, then we suggest a next
    //recalibration date in 6 months time, otherwise 12 months.
    const monthDiff = props.instrumentDetail.type.includes("vapor") ? 6 : 12;
    return new Date(nextDate.setMonth(nextDate.getMonth() + monthDiff));
  };

  const onSubmit = (formProps) => {
    const { instrumentDetail } = props;
    const lastChecked = moment(formProps.lastChecked).toDate();
    const nextCheck = moment(formProps.nextCheck).toDate();

    setIsLoading(true);

    CalibrationService.updateCalibrationData(
      instrumentDetail.id,
      lastChecked,
      nextCheck
    )
      .then(() => {
        setIsLoading(false);

        addAlert({
          variant: "success",
          message: "Calibration update successfully.",
        });

        props.onCalibrationUpdated();
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const instrumentName = props.instrumentDetail
    ? props.instrumentDetail.name
    : "";

  return (
    <Modal
      className="editCalibrationModal"
      open={props.show}
      onClose={props.onHide}
      disableInitialFocus={true}
    >
      <Modal.Header>
        <Title>{instrumentName} Calibration</Title>
      </Modal.Header>

      <Modal.Body>
        <EditCalibrationForm
          onSubmit={onSubmit}
          formMethods={formMethods}
          isLoading={isLoading}
          onHide={props.onHide}
          getNextCheckDateFromLast={getNextCheckDateFromLast}
        />
      </Modal.Body>
    </Modal>
  );
};

EditCalibrationModal.propTypes = {
  addAlertMessage: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  instrumentDetail: PropTypes.object,
  onHide: PropTypes.func.isRequired,
  onCalibrationUpdated: PropTypes.func.isRequired,
};

export default EditCalibrationModal;
