import union from "lodash/union";

export function getAllComponents(components) {
  if (components) {
    const differencesMeasurements = Object.keys(components.differences);
    const percentDifferenceMeasurements = Object.keys(
      components.percent_differences
    );
    return union(differencesMeasurements, percentDifferenceMeasurements);
  }

  return [];
}

export function getBalanceList(
  balanceSummary,
  balanceRow,
  haveReferenceSources
) {
  const updatedBalanceRow = { ...balanceRow };

  if (Object.keys(balanceSummary).length > 0) {
    // Since Percent difference calculation is based on references so if there is
    // no references then there is no need to display percent differences.
    if (!haveReferenceSources) {
      delete updatedBalanceRow.percent_differences;
    }

    return Object.keys(updatedBalanceRow).map((key) => {
      const rowDetail = updatedBalanceRow[key];

      if (rowDetail.isSource) {
        return {
          ...rowDetail,
          total: balanceSummary[key].total,
          energy: balanceSummary[key].energy,
          ...balanceSummary[key].components,
        };
      } else {
        const balanceDetail = balanceSummary.summary;

        return {
          ...rowDetail,
          total: balanceDetail?.total[key],
          energy: balanceDetail?.energy[key],
          ...balanceDetail?.components[key],
        };
      }
    });
  }

  return [];
}
