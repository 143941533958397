import { WorkflowsListLayout } from "#src/batteries-included-components/Layouts/Workflows/List";
import { useStorageKey } from "#src/hooks/useStorageKey";
import { WorkflowsAllRoutePath } from "#src/routes/workflows/all";
import { useBreadcrumbsFromRoute } from "#src/utils/route";
import { Page } from "@validereinc/common-components";
import React from "react";

export const AllWorkflowsPage = () => {
  const storageKeys = useStorageKey("workflows");
  const [breadcrumbs] = useBreadcrumbsFromRoute(WorkflowsAllRoutePath);

  return (
    <Page
      title={WorkflowsAllRoutePath.title}
      category={WorkflowsAllRoutePath.subtitle}
      breadcrumbs={breadcrumbs}
    >
      <WorkflowsListLayout tablePanelProps={storageKeys} />
    </Page>
  );
};
