import CreateSampleModal from "#components/ChainOfCustody/CreateChainOfCustody/CreateSampleModal";
import ExistingSampleModal from "#components/ChainOfCustody/CreateChainOfCustody/SampleModals/ExistingSampleModal/ExistingSampleModal";
import {
  ADD_EXISTING_SAMPLE_MODAL_KEY,
  ADD_NEW_SAMPLE_MODAL_KEY,
} from "#components/ChainOfCustody/CreateChainOfCustody/SampleModals/ModalConstants";
import { addAlertMessage } from "#redux/actions/alertMessages";
import { fetchInstrumentList, fetchSiteList } from "#redux/actions/index";
import { getMeasurementType } from "#redux/reducers/measurements";
import history from "#routers/history";
import { linkToManualEntry } from "#routers/links";
import ManualEntryService from "#services/ManualEntryService";
import {
  Button,
  Form,
  FormButton,
  useAlert,
  useForm,
} from "@validereinc/common-components";
import moment from "moment";
import * as PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import "./FieldManualEntry.scss";
import FieldManualEntryConfirmationModal from "./FieldManualEntryConfirmationModal";
import FieldManualEntryForm from "./FieldManualEntryForm";

const TODAY = moment().startOf("day").toDate();

const DEFAULT_INPUTS = { date: TODAY, measurements: [], samples: null };

const MANUAL_ENTRY_CONFIRMATION_MODAL_KEY =
  "MANUAL_ENTRY_CONFIRMATION_MODAL_KEY";

const mapStateToProps = ({ instruments, measurements, profiles }) => ({
  instruments:
    instruments.data
      .toJS()
      ?.filter(
        (instrument) =>
          instrument.instrument_type === "field" &&
          instrument.type !== "water" &&
          instrument.status === "active"
      )
      ?.sort((a, b) => a.name.localeCompare(b.name)) ?? [],
  getMeasurementType: (measurement) =>
    getMeasurementType(measurements)(measurement),
  profile: profiles.data.toJS(),
});

const mapDispatchToProps = {
  fetchInstrumentList,
  fetchSiteList,
  addAlertMessage,
};

const FieldManualEntry = ({
  view = "page",
  instruments,
  instrumentInfo,
  sampleInfo,
  onFieldManualEntryModalHide,
  refetchData,
  profile,
  getMeasurementType,
  fetchInstrumentList,
  fetchSiteList,
  bodyComponent: BodyComponent = "div",
  footerComponent: FooterComponent = "div",
}) => {
  const [formState, setFormState] = useState("enabled");
  const [modalKey, setModalKey] = useState(null);
  const [createdTestId, setCreatedTestId] = useState(null);
  const { addAlert } = useAlert();

  const form = useForm({
    defaultValues: DEFAULT_INPUTS,
  });

  const onSubmitForm = (input) => {
    setFormState("loading");

    ManualEntryService.addFieldMeasurement(input, profile)
      .then(({ data }) => {
        addAlert({
          variant: "success",
          message: "Field Measurement successfully created.",
        });

        if (view === "page") {
          setModalKey(MANUAL_ENTRY_CONFIRMATION_MODAL_KEY);
          setCreatedTestId(data.test_id);
        }

        if (view === "modal") {
          onFieldManualEntryModalHide?.();
          refetchData?.();
        }
      })
      .finally(() => {
        setFormState("enabled");
      });
  };

  const onAddSample = (samples) => {
    form.setValue("samples", samples[0]);
  };

  const onCancelClick = () => {
    if (view === "page") {
      history.push(linkToManualEntry());
    }

    if (view === "modal") {
      onFieldManualEntryModalHide?.();
    }
  };

  const onModalToggle = (key) => {
    setModalKey(key);
  };

  const onHide = () => {
    setModalKey(null);
  };

  useEffect(() => {
    fetchInstrumentList();
    fetchSiteList();
  }, []);

  useEffect(() => {
    if (instrumentInfo && instruments) {
      const selectedInstrument = instruments.find(
        (instrument) => instrument.id === instrumentInfo.id
      );

      if (selectedInstrument) {
        form.setValue("instrument", selectedInstrument);
      }
    }
  }, [instrumentInfo, instruments]);

  useEffect(() => {
    if (sampleInfo) {
      form.setValue("samples", sampleInfo);
    }
  }, [sampleInfo]);

  return (
    <>
      <Form
        onSubmit={onSubmitForm}
        {...form}
      >
        <BodyComponent>
          <FieldManualEntryForm
            form={form}
            instruments={instruments}
            onModalToggle={onModalToggle}
            getMeasurementType={getMeasurementType}
            disabled={formState !== "enabled"}
          />
        </BodyComponent>

        <FooterComponent className="clearfix">
          <Button
            className="pull-left"
            onClick={onCancelClick}
            disabled={formState !== "enabled"}
          >
            Cancel
          </Button>

          <FormButton
            className="pull-right"
            variant="primary"
            type="submit"
            isLoading={formState === "loading"}
            disabled={formState !== "enabled"}
          >
            Add Field Measurement
          </FormButton>
        </FooterComponent>
      </Form>

      {modalKey === ADD_NEW_SAMPLE_MODAL_KEY && (
        <CreateSampleModal
          show={true}
          hide={onHide}
          onCreateSample={onAddSample}
        />
      )}

      {modalKey === ADD_EXISTING_SAMPLE_MODAL_KEY && (
        <ExistingSampleModal
          show={true}
          onHide={onHide}
          inputs={form.getValues()}
          onAddSample={onAddSample}
          singleSelect={true}
        />
      )}

      {modalKey === MANUAL_ENTRY_CONFIRMATION_MODAL_KEY && (
        <FieldManualEntryConfirmationModal
          show={true}
          onHide={onHide}
          input={form.getValues()}
          createdTestId={createdTestId}
        />
      )}
    </>
  );
};

FieldManualEntry.propTypes = {
  fetchInstrumentList: PropTypes.func.isRequired,
  fetchSiteList: PropTypes.func.isRequired,
  instruments: PropTypes.array.isRequired,
  getMeasurementType: PropTypes.func.isRequired,
  view: PropTypes.string,
  addAlertMessage: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  instrumentInfo: PropTypes.object,
  sampleInfo: PropTypes.object,
  onHide: PropTypes.func,
  refetchData: PropTypes.func,
  bodyComponent: PropTypes.elementType,
  footerComponent: PropTypes.elementType,
  onFieldManualEntryModalHide: PropTypes.func,
};

const FieldManualEntryContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(FieldManualEntry);

export default FieldManualEntryContainer;
