import { parseCustomReportConfiguration } from "#batteries-included-components/Layouts/CustomReport/CustomReportConfigurationPanel/CustomReportConfigurationPanel.helpers";
import { CustomReportDetailLayout } from "#batteries-included-components/Layouts/CustomReport/CustomReportDetailLayout";
import {
  CustomReportVariants,
  getConfigFromSavedFilter,
  getCustomReportFilters,
  getCustomReportGroupBy,
} from "#batteries-included-components/Layouts/CustomReport/CustomReportDetailLayout.helpers";
import { CreateCustomReportModal } from "#batteries-included-components/Modals/CustomReports/CreateCustomReportModal";
import { DeleteCustomReportModal } from "#batteries-included-components/Modals/CustomReports/DeleteCustomReportModal";
import { EditCustomReportModal } from "#batteries-included-components/Modals/CustomReports/EditCustomReportModal";
import { useListRecordSavedFilters } from "#hooks/adapters/useRecords";
import { useBreadcrumbs } from "#routers/breadcrumbsHelper";
import { useNavigate, useParams } from "#routers/hooks";
import { VOLUME_BREADCRUMB } from "#routes/volume";
import {
  VOLUME_CUSTOM_REPORT_LIST_BREADCRUMB,
  linkToVolumeCustomReportList,
} from "#routes/volume/custom-reports";
import {
  VOLUME_CUSTOM_REPORT_DETAIL_BREADCRUMB,
  VOLUME_CUSTOM_REPORT_DETAIL_PAGE_TITLE,
  VolumeCustomReportDetailParamType,
  linkToVolumeCustomReportDetail,
} from "#routes/volume/custom-reports/[reportId]/detail/index";
import { useAuthenticatedContext } from "#src/contexts/AuthenticatedContext.helpers";
import {
  CustomStorageKeyTypes,
  useCustomStorageKey,
  useStorageKey,
} from "#src/hooks/useStorageKey";
import {
  Button,
  DropdownMenu,
  Page,
  useFilters,
} from "@validereinc/common-components";
import { SavedFilterStatus, getFilterObject } from "@validereinc/domain";
import React, { useEffect, useState } from "react";

const VARIANT = CustomReportVariants.VOLUMETRIC;
export const VolumeCustomReportDetailPage = () => {
  const navigate = useNavigate();
  const { reportId } = useParams<VolumeCustomReportDetailParamType>();
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const storageKeys = useStorageKey("volume-custom-report-detail");
  const configStorageKey = useCustomStorageKey(
    "volume-custom-report-detail",
    CustomStorageKeyTypes.CONFIG
  );
  const {
    v2: {
      userInfo: { checkHasPermissions },
    },
  } = useAuthenticatedContext();

  const canWriteCompanyReports = checkHasPermissions(
    "company_custom_reports:write"
  );
  const canDeleteCompanyReports = checkHasPermissions(
    "company_custom_reports:delete"
  );

  const [filters, setFilters] = useFilters(storageKeys.filterConfigStorageKey);
  const [configuration, setConfiguration] = useFilters(configStorageKey);

  const recordFiltersQuery = useListRecordSavedFilters({});

  const savedFilter = recordFiltersQuery.data?.data?.find(
    ({ id }) => id === reportId
  );

  useEffect(() => {
    const { config, filter } = getConfigFromSavedFilter(VARIANT, savedFilter);
    setConfiguration(config);
    setFilters(filter);
  }, [savedFilter]);

  const { rows } = parseCustomReportConfiguration(configuration);
  const filter = getFilterObject(
    getCustomReportFilters(VARIANT, configuration, filters)
  );

  return (
    <Page
      category={VOLUME_CUSTOM_REPORT_DETAIL_PAGE_TITLE}
      title={savedFilter?.name ?? VOLUME_CUSTOM_REPORT_DETAIL_PAGE_TITLE}
      breadcrumbs={useBreadcrumbs(
        [
          VOLUME_BREADCRUMB,
          VOLUME_CUSTOM_REPORT_LIST_BREADCRUMB,
          VOLUME_CUSTOM_REPORT_DETAIL_BREADCRUMB,
        ],
        {
          2: savedFilter?.name,
        }
      )}
      actionRow={[
        ...(savedFilter?.status === SavedFilterStatus.DRAFT ||
        canWriteCompanyReports
          ? [
              <DropdownMenu
                key="edit-custom-report"
                options={[
                  {
                    label: "Overwrite",
                    onClick: () => {
                      setIsEditModalOpen(true);
                    },
                  },
                  {
                    label: "Save as New",
                    onClick: () => {
                      setIsCreateModalOpen(true);
                    },
                  },
                ]}
              >
                <Button
                  variant="primary"
                  icon="caret-down"
                  iconPosition="right"
                >
                  Save Changes
                </Button>
              </DropdownMenu>,
            ]
          : []),
        ...(savedFilter?.status === SavedFilterStatus.DRAFT ||
        canDeleteCompanyReports
          ? [
              <Button
                key="delete-custom-report"
                variant="error-outline"
                onClick={() => setIsDeleteModalOpen(true)}
              >
                Delete
              </Button>,
            ]
          : []),
      ]}
    >
      <CustomReportDetailLayout
        configStorageKey={configStorageKey}
        reportVariant={VARIANT}
        {...storageKeys}
      />
      <CreateCustomReportModal
        variant={VARIANT}
        isOpen={isCreateModalOpen}
        onClose={() => {
          setIsCreateModalOpen(false);
        }}
        onSave={(id) => {
          navigate({ pathname: linkToVolumeCustomReportDetail(id) });
        }}
        filter={filter}
        groupBy={getCustomReportGroupBy(VARIANT, rows)}
      />
      <DeleteCustomReportModal
        onClose={() => {
          setIsDeleteModalOpen(false);
        }}
        onSuccess={() => {
          navigate({ pathname: linkToVolumeCustomReportList() });
        }}
        variant={VARIANT}
        customReportId={isDeleteModalOpen ? reportId : undefined}
      />
      <EditCustomReportModal
        onClose={() => setIsEditModalOpen(false)}
        customReportId={isEditModalOpen ? reportId : undefined}
        filter={filter}
        groupBy={getCustomReportGroupBy(VARIANT, rows)}
        name={savedFilter?.name}
        status={savedFilter?.status}
        variant={VARIANT}
      />
    </Page>
  );
};
