import {
  COMPONENT_QUALITIES,
  INERTS,
  HELIUM_AND_HYDROGEN_INERTS,
} from "../../../Records/Quality/RecordOfQualityConstants";

export const TITLE_COLUMN = 3;

/* Constant related to C6 measurements */
export const HEXANES = "Hexanes (C6)";
export const HEXANES_PLUS = "Hexanes Plus (C6+)";
export const HEXANES_PLUS_MOLECULAR_WEIGHT = "Molecular Weight C6+";
export const DENSITY_HEXANES_PLUS = "Density C6+";

/* Constant related to C7 measurements */
export const HEPTANES_PLUS = "Heptanes Plus (C7+)";
export const HEPTANES_PLUS_MOLECULAR_WEIGHT = "Molecular Weight C7+";
export const DENSITY_HEPTANES_PLUS = "Density C7+";

export const ACCUMULATIVE_COMPONENT_OPTIONS = [HEXANES_PLUS, HEPTANES_PLUS];
export const PRESSURE_AND_TEMPERATURE_FIELDS_OPTIONS = [
  "Source",
  "Receipt",
  "Sample",
];
export const COMPONENT_UNITS_OPTIONS = ["Mole Percentage", "Volume Percentage"];

export const COMPONENTS = [
  ...HELIUM_AND_HYDROGEN_INERTS,
  ...INERTS,
  ...COMPONENT_QUALITIES,
];

export const MAX_DECIMAL_PLACES = 5;

export const MAX_ACCUMULATION_PERCENT = 100;
