export function getErrorMessage(input) {
  if (input.streams.length === 0) {
    return "You must select at least one stream.";
  }

  return null;
}

export function getRecordCheckboxState(recordOfVolumeDetail) {
  return recordOfVolumeDetail.is_locked;
}
