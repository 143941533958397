import { useSearchParams } from "#routers/hooks";
import { CustomDashboardTab } from "#src/batteries-included-components/Tabs/CustomDashboard/CustomDashboardTab";
import { InsightsDashboardTab } from "#src/batteries-included-components/Tabs/Insights/InsightsDashboardTab";
import {
  useIsFeatureAvailable,
  useLegacyIsFeatureAvailable,
} from "#src/contexts/AuthenticatedContext.helpers";
import { Page, Tab } from "@validereinc/common-components";
import React from "react";
import { DASHBOARDS_BREADCRUMB } from ".";

export const DashboardPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isCustomTabEnabledLegacy] = useLegacyIsFeatureAvailable({
    featureFlagQuery: ["core/dashboards"],
    permissionQuery: ["core:dashboard"],
    featureId: "dashboards",
  });
  const [isCustomTabEnabled] = useIsFeatureAvailable({
    featureFlagQuery: "core:dashboards",
    permissionQuery: "dashboards:read",
  });
  const [isInsightsTabEnabledLegacy] = useLegacyIsFeatureAvailable({
    featureFlagQuery: ["core/insights_dashboard"],
    permissionQuery: ["core:insights"],
    featureId: "dashboards",
  });
  const [isInsightsTabEnabled] = useIsFeatureAvailable({
    featureFlagQuery: "dashboards:insights",
    permissionQuery: "insights_dashboards:read",
  });

  const handleActiveTabKeyChange = (tabKey) => {
    setSearchParams({ tab: tabKey });
  };

  const getActiveTabKey = () => {
    if (searchParams?.tab) {
      return searchParams?.tab;
    }

    if (isInsightsTabEnabled) {
      return "insights";
    }

    if (isCustomTabEnabled) {
      return "custom";
    }

    return "";
  };

  return (
    <Page
      title="Dashboards"
      breadcrumbs={[DASHBOARDS_BREADCRUMB]}
      activeTabKey={getActiveTabKey()}
      onActiveTabKeyChange={handleActiveTabKeyChange}
    >
      {isInsightsTabEnabled || isInsightsTabEnabledLegacy ? (
        <Tab
          tabKey="insights"
          title="Insights"
        >
          <InsightsDashboardTab />
        </Tab>
      ) : null}

      {isCustomTabEnabled || isCustomTabEnabledLegacy ? (
        <Tab
          tabKey="custom"
          title="Custom Dashboards"
        >
          <CustomDashboardTab />
        </Tab>
      ) : null}
    </Page>
  );
};
