import {
  CUBIC_METERS_VOLUME,
  getFormattedMeasurementValue,
} from "#redux/reducers/measurements";
import { LegacyDataTable, Panel } from "@validereinc/common-components";
import * as PropTypes from "prop-types";
import React, { useMemo } from "react";
import { connect } from "react-redux";
import { AutoSizer } from "react-virtualized";
import { getComponentShortForm } from "../../../../RecordOfQualityHelper";
import { useSelectionModalContext } from "../../../Context/selectionModalContext";
import ResultDifference from "../ResultDifference";
import { SystemBalanceProperties } from "./ResultBalanceConstants";
import "./ResultsBalanceTable.scss";
import { getPropertiesValue } from "./ResultsBalanceTableHelper";

const COMPONENT_COLUMN_WIDTH = 135;

const NAME_COLUMN_WIDTH = 130;

const mapStateToProps = (state) => {
  return {
    getFormattedMeasurementValue: (measurementKey, measurementValue) =>
      getFormattedMeasurementValue(state.measurements)(
        measurementKey,
        measurementValue
      ),
  };
};

export const ResultsBalanceTable = (props) => {
  const { systemBalance, displayProperties, originalSystemBalance } =
    useSelectionModalContext();

  const { total, components, qualities } = systemBalance;

  const balanceTableRows = SystemBalanceProperties.map(({ id }) => {
    return {
      name: id,
      total: { ...total[id] },
      components: { ...components[id] },
      qualities: { ...qualities[id] },
    };
  });

  const originalSystemBalanceRows = useMemo(() => {
    if (originalSystemBalance?.total) {
      return SystemBalanceProperties.map(({ id }) => {
        return {
          name: id,
          total: { ...originalSystemBalance.total[id] },
          components: { ...originalSystemBalance.components[id] },
          qualities: { ...originalSystemBalance.qualities[id] },
        };
      });
    }
    return [];
  }, [originalSystemBalance]);

  const nameRenderer = (rowData) => {
    return (
      <div className="resultsBalanceTable__name">
        <span className="resultsBalanceTable__row capitalized">
          {SystemBalanceProperties.find(
            (property) => property.id === rowData?.name
          )?.name ?? "-"}
        </span>
      </div>
    );
  };

  const measurementRenderer = (rowData, columnKey) => {
    const rowIndex = originalSystemBalanceRows.findIndex(
      (row) => row.name === rowData.name
    );

    const { value, unit, percentChange, difference } = getPropertiesValue(
      rowData,
      originalSystemBalanceRows[rowIndex],
      columnKey
    );

    const measurementKey = unit !== "%" ? CUBIC_METERS_VOLUME : null;

    return value ? (
      <div className="resultsBalanceTable__row">
        <div className="resultsBalanceTable__measurement">
          <div className="resultsBalanceTable__measurement__value">
            {props.getFormattedMeasurementValue(measurementKey, value)}
          </div>

          <div className="resultsBalanceTable__measurement__unit">{unit}</div>
        </div>

        {difference && (
          <ResultDifference
            difference={difference}
            percentChange={percentChange}
            unit={unit}
            showPercentChange={
              SystemBalanceProperties[rowIndex]?.showPercentChange
            }
          />
        )}
      </div>
    ) : (
      "-"
    );
  };

  const Components = displayProperties.map((component) => ({
    label: getComponentShortForm(component, props.componentVolumeConfig),
    key: component,
    width: COMPONENT_COLUMN_WIDTH,
    cellRenderer: measurementRenderer,
    rightAlign: true,
    disableSort: true,
  }));

  const Headers = [
    {
      label: "",
      key: "name",
      width: NAME_COLUMN_WIDTH,
      cellRenderer: nameRenderer,
      disableSort: true,
      fixed: true,
    },
    {
      label: "Total",
      key: "total",
      width: COMPONENT_COLUMN_WIDTH,
      cellRenderer: measurementRenderer,
      rightAlign: true,
      disableSort: true,
    },
    ...Components,
  ];

  return (
    <Panel>
      <div className="resultsBalanceTable">
        <AutoSizer>
          {({ width, height }) => (
            <LegacyDataTable
              headers={Headers}
              list={balanceTableRows}
              width={width}
              height={height}
              rowHeight={55}
            />
          )}
        </AutoSizer>
      </div>
    </Panel>
  );
};

ResultsBalanceTable.propTypes = {
  componentVolumeConfig: PropTypes.object,
  getFormattedMeasurementValue: PropTypes.func,
};

export default connect(mapStateToProps)(ResultsBalanceTable);
