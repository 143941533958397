import { ensureStreamListIsFetched } from "#redux/actions/index";
import { Page, Panel } from "@validereinc/common-components";
import * as PropTypes from "prop-types";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { havePermission } from "../../Redux/reducers/permissions";
import "./Balances.scss";
import BalancesTable from "./BalancesTable";

const mapStateToProps = ({ permissions, streams }) => {
  return {
    haveWritePermission: havePermission(permissions)(
      "gas:accounting.balances",
      "write"
    ),
    streams: streams?.data.toJS() ?? [],
  };
};

const mapDispatchToProps = {
  ensureStreamListIsFetched,
};

const Balances = ({
  height,
  streams,
  haveWritePermission,
  ensureStreamListIsFetched,
  breadcrumbs,
}) => {
  useEffect(() => {
    ensureStreamListIsFetched();
  }, []);

  return (
    <Page
      title="Balances"
      breadcrumbs={breadcrumbs}
    >
      <Panel
        className="balanceContainer"
        style={{ height: height }}
      >
        <BalancesTable
          streams={streams}
          haveWritePermission={haveWritePermission}
        />
      </Panel>
    </Page>
  );
};

Balances.propTypes = {
  haveWritePermission: PropTypes.bool,
  height: PropTypes.number.isRequired,
  ensureStreamListIsFetched: PropTypes.func.isRequired,
  streams: PropTypes.array.isRequired,
  breadcrumbs: PropTypes.array.isRequired,
};

const BalancesContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Balances);

export default BalancesContainer;
