import { BALANCE_SOURCE_TYPES } from "#utils/enums";

export const generateReportSummaryTableRows = (
  summary,
  balanceSourceType,
  inlets,
  outlets
) => {
  const rows = [];
  if (balanceSourceType === BALANCE_SOURCE_TYPES.INLETS_AND_OUTLETS) {
    rows.push(
      {
        id: "summary.percent_differences",
        name: "Percent Differences",
        total: summary.total.percent_differences,
        energy: summary.energy.percent_differences,
        ...summary.components.percent_differences,
      },
      {
        id: "summary.differences",
        name: "Absolute Differences",
        total: summary.total.differences,
        energy: summary.energy.differences,
        ...summary.components.differences,
      }
    );
  }
  if (
    balanceSourceType === BALANCE_SOURCE_TYPES.INLETS_AND_OUTLETS ||
    balanceSourceType === BALANCE_SOURCE_TYPES.INLETS_ONLY
  ) {
    rows.push({
      id: "inlets",
      name: "Receipts Plus Opening Inventory",
      ...inlets,
    });
  }
  if (
    balanceSourceType === BALANCE_SOURCE_TYPES.INLETS_AND_OUTLETS ||
    balanceSourceType === BALANCE_SOURCE_TYPES.OUTLETS_ONLY
  ) {
    rows.push({
      id: "outlets",
      name: "Dispositions Plus Closing Inventory",
      ...outlets,
    });
  }
  return rows;
};
