import React from "react";

import { useNavigate } from "#src/Routers/hooks";

import { Button, Panel } from "@validereinc/common-components";

import { EventDevicesTable } from "#src/batteries-included-components/Tables/EventDevicesTable";

import { linkToAddDevicesToEvent } from "#src/routes/events/event-categories/[eventCategoryId]/templates/[templateId]/events/[eventId]/add-devices";

export const EventDevicesPanel = ({
  eventCategoryId,
  eventTemplateId,
  eventId,
}: {
  eventCategoryId: string;
  eventTemplateId: string;
  eventId: string;
}) => {
  const navigate = useNavigate();

  const onAddDevices = () => {
    navigate({
      pathname: linkToAddDevicesToEvent(
        eventCategoryId,
        eventTemplateId,
        eventId
      ),
    });
  };

  return (
    <Panel
      title="Devices"
      actionRow={
        <Button
          icon="plus-circle"
          variant="secondary"
          onClick={onAddDevices}
        >
          Add Devices
        </Button>
      }
      isFluidY={false}
    >
      <EventDevicesTable />
    </Panel>
  );
};
