import Section from "#common/Section/Section";
import { Title } from "@validereinc/common-components";
import classNames from "classnames/bind";
import * as PropTypes from "prop-types";
import React, { useState } from "react";
import styles from "./AdvancedSection.module.scss";

const cx = classNames.bind(styles);

const AdvancedOptionsTitle = ({ open, onClick }) => {
  return (
    <Title type="subheader">
      Advanced Options (
      <span
        onClick={onClick}
        className="link"
      >
        {open ? "Hide" : "Show"}
      </span>
      )
    </Title>
  );
};

const AdvancedSection = ({ children }) => {
  const [open, setOpen] = useState(false);

  const onClick = () => {
    setOpen((open) => setOpen(!open));
  };

  return (
    <Section
      open={open}
      header={
        <AdvancedOptionsTitle
          open={open}
          onClick={onClick}
        />
      }
      className={cx("section")}
    >
      {children}
    </Section>
  );
};

AdvancedOptionsTitle.propTypes = {
  open: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

AdvancedSection.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default AdvancedSection;
