export const PERCENTAGE_DIFFERENCE_KEY = "percent_differences";
export const ABSOLUTE_DIFFERENCE_KEY = "absolute_differences";
export const VOLUME_KEY = "volume";

export const RECOVERY_KEY = "recoveries";

export const SystemBalanceProperties = [
  {
    name: "Receipts Plus Opening Inventory",
    id: "receipts_plus_opening_inventory",
    wrap: true,
  },
  {
    name: "Dispositions Plus Closing Inventory",
    id: "dispositions_plus_closing_inventory",
    wrap: true,
  },
  { name: "Absolute Differences", id: "absolute_differences", wrap: true },
  { name: "Percent Differences", id: "percent_differences", wrap: true },
];

export const INLET_BALANCE_PROPERTIES = [
  {
    name: "Receipts Plus Opening Inventory",
    id: "receipts_plus_opening_inventory",
    wrap: true,
  },
];

export const OUTLET_BALANCE_PROPERTIES = [
  {
    name: "Dispositions Plus Closing Inventory",
    id: "dispositions_plus_closing_inventory",
    wrap: true,
  },
];

export const SYSTEM_BALANCE_PROPERTIES = [
  { name: "Absolute Differences", id: "absolute_differences", wrap: true },
  { name: "Percent Differences", id: "percent_differences", wrap: true },
];
