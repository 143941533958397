import { FormSubmissionsFilterPanel } from "#src/batteries-included-components/Panels/FilterPanels/FormSubmissionsFilterPanel";
import {
  SelectedFormSubmissionsPanel,
  SelectedFormSubmissionsPanelProps,
} from "#src/batteries-included-components/Panels/SelectedEntitiesPanels/SelectedFormSubmissionsPanel";
import classNames from "classnames/bind";
import React from "react";
import styles from "./AddFormSubmission.module.scss";
import { useAddFormSubmissionContext } from "#src/batteries-included-components/Layouts/Form/Submission/Add/AddFormSubmission/AddFormSubmissionContext";
import { AddFormSubmissionsTablePanel } from "#src/batteries-included-components/Panels/TablePanels/AddFormSubmissionsTablePanel";

const cx = classNames.bind(styles);

export type AddFormSubmissionProps = {
  selectedFormSubmissionsPanelProps: SelectedFormSubmissionsPanelProps;
};

export const AddFormSubmission = ({
  selectedFormSubmissionsPanelProps,
}: AddFormSubmissionProps) => {
  const { filterPanelDefaultValue, filterConfigStorageKey } =
    useAddFormSubmissionContext();

  return (
    <>
      <FormSubmissionsFilterPanel
        defaultValue={filterPanelDefaultValue ?? {}}
        filterConfigStorageKey={filterConfigStorageKey}
      />
      <div className={cx("container")}>
        <AddFormSubmissionsTablePanel />
        <SelectedFormSubmissionsPanel {...selectedFormSubmissionsPanelProps} />
      </div>
    </>
  );
};
