import { RootRoute } from "#src/routes/root";
import { RoutePath } from "#src/utils/route";

/**
 * route config for /app/emissions
 */
export const EmissionsRoute = RootRoute.concat(
  new RoutePath({
    title: "Emissions",
    path: "/emissions",
    isPresentational: true,
  })
);
