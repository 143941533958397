import { getBreadcrumbsObject } from "#src/Routers/breadcrumbsHelper";
import { useHistory, useNavigate, useParams } from "#src/Routers/hooks";
import { AddFormSubmission } from "#src/batteries-included-components/Layouts/Form/Submission/Add/AddFormSubmission";
import {
  AddFormSubmissionContextProvider,
  useAddFormSubmissionContext,
} from "#src/batteries-included-components/Layouts/Form/Submission/Add/AddFormSubmission/AddFormSubmissionContext";
import {
  EventCategoryContext,
  EventCategoryProvider,
} from "#src/contexts/EventCategoryContext";
import { EventContext, EventProvider } from "#src/contexts/EventContext";
import {
  EventTemplateContext,
  EventTemplateProvider,
} from "#src/contexts/EventTemplateContext";
import { useStorageKey } from "#src/hooks/useStorageKey";
import { EVENTS_BREADCRUMB } from "#src/routes/events";
import { EVENT_CATEGORIES_BREADCRUMB } from "#src/routes/events/event-categories";
import { EVENT_CATEGORY_DETAIL_BREADCRUMB } from "#src/routes/events/event-categories/[eventCategoryId]";
import { EVENT_TEMPLATE_DETAIL_BREADCRUMB } from "#src/routes/events/event-categories/[eventCategoryId]/templates/[templateId]/details";
import {
  EVENT_DETAIL_BREADCRUMB,
  linkToEventDetails,
} from "#src/routes/events/event-categories/[eventCategoryId]/templates/[templateId]/events/[eventId]";
import { Button, Page } from "@validereinc/common-components";
import { EventsDomain } from "@validereinc/domain";
import classNames from "classnames/bind";
import React, { useContext, useState } from "react";
import {
  EVENT_ADD_FORM_SUBMISSION_BREADCRUMB,
  EVENT_ADD_FORM_SUBMISSION_PAGE_TITLE,
} from "./";
import styles from "./EventAddFormSubmissionPage.module.scss";

const cx = classNames.bind(styles);

export const AddFormSubmissionToEventContent = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const urlParams = useParams<{ eventId: string }>();
  const navigate = useNavigate();
  const history = useHistory();
  const { eventCategory } = useContext(EventCategoryContext) || {};
  const { eventTemplate } = useContext(EventTemplateContext) || {};
  const { event } = useContext(EventContext) || {};
  const { selectedFormSubmissions } = useAddFormSubmissionContext();

  const breadcrumbs = getBreadcrumbsObject(
    [
      EVENTS_BREADCRUMB,
      EVENT_CATEGORIES_BREADCRUMB,
      EVENT_CATEGORY_DETAIL_BREADCRUMB,
      EVENT_TEMPLATE_DETAIL_BREADCRUMB,
      EVENT_DETAIL_BREADCRUMB,
      EVENT_ADD_FORM_SUBMISSION_BREADCRUMB,
    ],
    urlParams,
    { 2: eventCategory?.name, 3: eventTemplate?.name, 4: event?.name }
  );

  const onCancel = async () => {
    history.goBack();
  };

  const onSubmit = async () => {
    setIsSubmitting(true);

    await Promise.all(
      selectedFormSubmissions.map(({ id }) => {
        return EventsDomain.formSubmissions.addToEvent({
          eventId: event.id,
          formSubmissionId: id,
        });
      })
    );

    navigate({
      pathname: linkToEventDetails(
        eventCategory.id,
        eventTemplate.id,
        event.id
      ),
    });

    setIsSubmitting(false);
  };

  return (
    <Page
      title={EVENT_ADD_FORM_SUBMISSION_PAGE_TITLE}
      category="Event"
      breadcrumbs={breadcrumbs}
      footer={
        <div className={cx("footer")}>
          <Button
            onClick={onCancel}
            disabled={isSubmitting}
          >
            Cancel
          </Button>

          <div style={{ display: "flex", flexDirection: "row", gap: 16 }}>
            <Button
              variant="primary"
              onClick={onSubmit}
              disabled={isSubmitting || !selectedFormSubmissions.length}
            >
              Add
            </Button>
          </div>
        </div>
      }
    >
      <AddFormSubmission
        selectedFormSubmissionsPanelProps={{
          emptyStateProps: {
            suggestion: "Select form submissions to add to event.",
          },
        }}
      />
    </Page>
  );
};

export const AddFormSubmissionToEventPage = () => {
  const { eventCategoryId, eventTemplateId, eventId } = useParams<{
    eventCategoryId: string;
    eventTemplateId: string;
    eventId: string;
  }>();
  const storageKeys = useStorageKey("events-form-submissions-add");

  return (
    <EventCategoryProvider eventCategoryId={eventCategoryId}>
      <EventTemplateProvider eventTemplateId={eventTemplateId}>
        <EventProvider eventId={eventId}>
          <AddFormSubmissionContextProvider {...storageKeys}>
            <AddFormSubmissionToEventContent />
          </AddFormSubmissionContextProvider>
        </EventProvider>
      </EventTemplateProvider>
    </EventCategoryProvider>
  );
};
