import { LegacyDataTable, Panel } from "@validereinc/common-components";
import classNames from "classnames/bind";
import * as PropTypes from "prop-types";
import React from "react";
import { AutoSizer } from "react-virtualized";
import styles from "./EquipmentTable.module.scss";

const cx = classNames.bind(styles);

const PANEL_PADDING = 20;

const EquipmentTable = ({
  equipmentList = [],
  onCheckboxClick,
  selectedValues,
  isDisabled,
  onDeleteClick,
  filterRow,
  filterPillbox,
  noFilterListcount,
}) => {
  const Headers = [
    {
      label: "Name",
      key: "name",
      width: 250,
      fixed: true,
    },
    {
      label: "Facility",
      key: "facility",
      width: 250,
    },
    {
      label: "Facility Code",
      key: "facility_code",
      width: 150,
    },
  ];

  const actionDropdown = onDeleteClick
    ? [
        {
          title: "Delete Equipment",
          onClick: onDeleteClick,
        },
      ]
    : null;

  return (
    <Panel className={cx("container")}>
      <AutoSizer>
        {({ height, width }) => (
          <LegacyDataTable
            width={width}
            height={height - PANEL_PADDING}
            headers={Headers}
            list={equipmentList}
            filterKey="name"
            filterTitle="Name"
            defaultSortBy="name"
            defaultSortDirection="asc"
            onCheckboxClick={onCheckboxClick}
            checkedList={selectedValues}
            getCheckboxDisabledState={() => isDisabled}
            filterRow={filterRow}
            filterPillbox={filterPillbox}
            noFilterListcount={noFilterListcount}
            actionDropdown={actionDropdown}
          />
        )}
      </AutoSizer>
    </Panel>
  );
};

EquipmentTable.propTypes = {
  equipmentList: PropTypes.array,
  onCheckboxClick: PropTypes.func,
  selectedValues: PropTypes.array,
  isDisabled: PropTypes.bool,
  streams: PropTypes.array,
  onDeleteClick: PropTypes.func,
  filterRow: PropTypes.object.isRequired,
  filterPillbox: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  noFilterListcount: PropTypes.number,
};

export default EquipmentTable;
