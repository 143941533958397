import React from "react";

// components
import { Page } from "@validereinc/common-components";
import EquipmentForm from "../../../../components/Organization/Equipment/EquipmentForm";

// utils
import { useNavigate } from "#routers/hooks";
import { linkToEquipmentList } from "#routers/links";
import useLocalization from "#src/hooks/useLocalization";
import { useEquipmentForm } from "../../../../components/Organization/Equipment/useEquipmentForm";
import { useBreadcrumbsFromRoute } from "#src/utils/route";
import { EquipmentCreateRoute } from "#src/routes/organization/equipment/create";

const CreateEquipmentPage = () => {
  const [breadcrumbs] = useBreadcrumbsFromRoute(EquipmentCreateRoute);

  const navigate = useNavigate();
  const { localize } = useLocalization();

  const { footer, formProps } = useEquipmentForm({
    formType: "create",
    onSubmit: () => navigate({ pathname: linkToEquipmentList() }),
    onCancel: () =>
      navigate({ pathname: linkToEquipmentList(), replace: true }),
  });

  return (
    <Page
      title={`Create ${localize("Equipment")}`}
      breadcrumbs={breadcrumbs}
      footer={footer}
    >
      <EquipmentForm {...formProps} />
    </Page>
  );
};

export default CreateEquipmentPage;
