import { LegacyFeatureFlagsType, LegacyPermissionsType } from ".";
import { PermissionType } from "../schemas";
import { restAPI } from "./api";

/**
 * @deprecated reflects old monolith authorization system (Elixir API, OpsHub)
 * that is currently in use in legacy parts of the app
 */
type LegacyUserFacilities = {
  company_id: string;
  facility_id: string;
  id: string;
  inserted_by_id: string;
  updated_by_id: string;
  user_id: string;
};

/**
 * @deprecated reflects old monolith authorization system (Elixir API, OpsHub)
 * that is currently in use in legacy parts of the app
 */
type LegacyUserSites = {
  company_id: string;
  id: string;
  inserted_by_id: string;
  site_id: number;
  updated_by_id: string;
  user_id: string;
};

/**
 * @deprecated reflects old monolith authorization system (Elixir API, OpsHub)
 * that is currently in use in legacy parts of the app
 */
type LegacyMemberType = {
  email: string;
  id: string;
  last_activity_time: string | null;
  name: string;
  role: string;
  sites: number[];
  state: "active" | "inactive";
};

/**
 * @deprecated reflects old monolith authorization system (Elixir API, OpsHub)
 * that is currently in use in legacy parts of the app
 */
export type LegacyUserType = {
  alternative_email?: string;
  auth0_id: string;
  company_id: string;
  company_localization: Record<string, unknown>;
  company_name: string;
  digest_settings: {
    is_enabled: boolean;
    is_weekly_digest_enabled: boolean;
    time: string;
    weekly_digest_time: string;
    weekly_digest_weekday: number;
  };
  email: string;
  enabled_chain_of_custody_types: unknown[];
  id: string;
  insert_by_id: string;
  inserted_at: string;
  job_title: string | null;
  members: LegacyMemberType[];
  name: string;
  permissions?: PermissionType[];
  phone_number: string | null;
  quicksight: boolean;
  restrict_calibration_access: boolean;
  restricted_calibration_instrument_ids: unknown[];
  role: {
    id: string;
    name: string;
    frontend_feature_toggles: LegacyFeatureFlagsType;
    permissions: LegacyPermissionsType;
  };
  sites: number[];
  state: "active" | "inactive";
  timezone_preference: string;
  updated_at: string;
  updated_by_id: string;
  user_facilities?: LegacyUserFacilities[];
  user_sites?: LegacyUserSites[];
  username: string;
  uuid: string;
};

/**
 * @deprecated reflects old monolith authorization system (Elixir API, OpsHub)
 * that is currently in use in legacy parts of the app
 */
export const LegacyUsersAdapter = {
  /**
   * @deprecated use updated implementation in UsersAdapter instead
   * Get multiple users
   * @see https://stage.validere.xyz/swagger_ui_private#/Users/ValidereWeb.ApiUserInfoController.index
   */
  getMany: ({ userIds }: { userIds: string[] }) =>
    restAPI.elixirAPI.GET<{ data: LegacyUserType[] }>({
      endpoint: "/users/user_info",
      query: { user_ids: userIds },
    }),
  // REVIEW: endpoint currently has a back-end issue yet to be resolved which
  // makes it so you cannot lookup a user's UUID (assigned in Auth0) through
  // this endpoint but must instead use the integer ID assigned in the Elixir BE
  /**
   * Get a single user
   * @see https://stage.validere.xyz/swagger_ui_private#/Users/ValidereWeb.ApiUserAccountController.show
   */
  getOne: ({ id }: { id: string }) =>
    restAPI.elixirAPI.GET<LegacyUserType>({
      endpoint: `/users/${id}`,
    }),
  self: {
    /**
     * @deprecated use updated implementation in UsersAdapter instead
     * Get the details of the authenticated user
     * @see https://stage.validere.xyz/swagger_ui_private#/Users/ValidereWeb.ApiUserAccountController.get_self
     */
    get: () =>
      restAPI.elixirAPI.GET<LegacyUserType>({
        endpoint: "/users/@me",
      }),

    /**
     * @deprecated use updated implementation in UsersAdapter instead
     * Get the permissions of the authenticated user
     * @see https://stage.validere.xyz/swagger_ui_private#/
     */
    getPermissions: () =>
      restAPI.elixirAPI.GET<LegacyPermissionsType>({
        endpoint: "/users/@permissions",
      }),
  },
};
