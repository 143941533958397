import {
  fetchInstrumentList,
  fetchSiteList,
  fetchUserProfile,
} from "#redux/actions/index";
import {
  filterFieldInstruments,
  sortInstrumentByName,
} from "#redux/reducers/instruments";
import { havePermission } from "#redux/reducers/permissions";
import UserService from "#services/UserService";
import {
  Button,
  Form,
  FormButton,
  Panel,
  useAlert,
  useForm,
} from "@validereinc/common-components";
import intersectionWith from "lodash/intersectionWith";
import "moment-timezone";
import * as PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import AccessDefinitionsModal from "../AccessDefinitionsModal/AccessDefinitionsModal";
import SettingSection from "../SettingSection";
import "./Profile.scss";
import ProfileForm from "./ProfileForm";
import { formatTimezoneName, getAvailableTimezone } from "./ProfileHelpers";

const TIMEZONE_OPTIONS = Object.keys(getAvailableTimezone());

const DEFAULT_INPUT = {
  name: "",
  company_name: "",
  email: "",
  phone_number: "",
  job_title: "",
  timezone_preference: "",
  sites: [],
  role: [],
  restrict_calibration_access: [],
  restricted_calibration_instruments: [],
};

const mapStateToProps = (state) => {
  return {
    profile: state.profiles?.data?.toJS() ?? {},
    hasWritePermissions: havePermission(state.permissions)(
      "core:user.management",
      "write"
    ),
    hasFieldLabPermissions: havePermission(state.permissions)(
      "360:field_labs",
      "read"
    ),
    sites: state.sites.data?.toJS() ?? [],
    instrumentsList:
      state?.instruments?.data
        .toJS()
        .filter(filterFieldInstruments)
        .sort(sortInstrumentByName) ?? [],
  };
};

const mapDispatchToProps = {
  fetchUserProfile,
  fetchSiteList,
  fetchInstrumentList,
};

const Profile = ({
  profile,
  sites,
  instrumentsList,
  hasWritePermissions,
  hasFieldLabPermissions,
  fetchInstrumentList,
  fetchSiteList,
}) => {
  const [formState, setFormState] = useState("loading");
  const [showAccessDefinitions, setShowAccessDefinitions] = useState(false);
  const [availableRoles, setAvailableRoles] = useState([]);
  const [hasInitializedForm, setHasInitializedForm] = useState(false);
  const { addAlert } = useAlert();

  const form = useForm({
    defaultValues: DEFAULT_INPUT,
  });

  const handleOnUpdateClick = (inputs) => {
    setHasInitializedForm(true);
    setFormState("loading");

    UserService.requestUpdateProfile(inputs)
      .then(() => {
        addAlert({
          variant: "success",
          message: "Profile successfully updated.",
        });
      })
      .finally(() => {
        setFormState("enabled");
      });
  };

  const accessDefinitionsToggle = () => {
    setShowAccessDefinitions(!showAccessDefinitions);
  };

  const resetPassword = () => {
    UserService.requestChangePassword().then((_response) => {
      addAlert({
        variant: "success",
        message: "Password reset email has been sent.",
      });
    });
  };

  useEffect(() => {
    fetchSiteList();
    if (hasFieldLabPermissions) {
      fetchInstrumentList();
    }

    if (hasWritePermissions) {
      UserService.getRoles().then(({ data }) => {
        setAvailableRoles(data);
      });
    }
  }, []);

  useEffect(() => {
    if (profile?.id && !hasInitializedForm) {
      let formattedSites = [];
      if (sites.length > 0) {
        formattedSites = sites.filter((site) =>
          profile.sites?.includes(site.id)
        );
      }

      let formattedRestrictedInstruments = [];
      if (profile.restricted_calibration_instrument_ids?.length > 0) {
        formattedRestrictedInstruments = intersectionWith(
          instrumentsList,
          profile.restricted_calibration_instrument_ids,
          (instrument, restrictedCalibrationInstrumentId) =>
            instrument.id === restrictedCalibrationInstrumentId
        );
      }

      form.reset({
        ...profile,
        sites: formattedSites,
        timezone_preference: formatTimezoneName(profile.timezone_preference),
        phone_number: profile.phone_number ?? "",
        restricted_calibration_instruments: formattedRestrictedInstruments,
      });

      setFormState("enabled");
    }
  }, [sites, profile, instrumentsList]);

  const inputs = form.watch();

  return (
    <div className="profile">
      <SettingSection title="Password">
        <p>Send a password reset email to your inbox</p>
        <Button
          variant="primary"
          onClick={resetPassword}
        >
          Reset Password
        </Button>
      </SettingSection>
      <SettingSection title="Profile">
        <Panel className="profileContainer">
          <Form
            onSubmit={handleOnUpdateClick}
            {...form}
          >
            <ProfileForm
              inputs={inputs}
              formState={formState}
              hasWritePermissions={hasWritePermissions}
              availableSites={sites}
              availableRoles={availableRoles}
              availableInstruments={instrumentsList}
              availableTimezones={TIMEZONE_OPTIONS}
              handleAccessDefinitionClick={accessDefinitionsToggle}
            />

            <div className="clearfix">
              <FormButton
                variant="primary"
                className="pull-right"
                type="submit"
                isLoading={formState === "loading"}
                disabled={formState !== "enabled" || !hasWritePermissions}
              >
                Save changes
              </FormButton>
            </div>
          </Form>
        </Panel>

        <AccessDefinitionsModal
          show={showAccessDefinitions}
          onHide={accessDefinitionsToggle}
        />
      </SettingSection>
    </div>
  );
};

Profile.propTypes = {
  profile: PropTypes.object.isRequired,
  height: PropTypes.number.isRequired,
  sites: PropTypes.array.isRequired,
  instrumentsList: PropTypes.array.isRequired,
  hasWritePermissions: PropTypes.bool.isRequired,
  hasFieldLabPermissions: PropTypes.bool.isRequired,
  fetchInstrumentList: PropTypes.func.isRequired,
  fetchSiteList: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
