import { useLocation, useSearchParams } from "#src/Routers/hooks";
import { UserListTab } from "#src/batteries-included-components";
import { AccessDeniedLayout } from "#src/batteries-included-components/Layouts/Authorization/AccessDenied";
import UserGroupsTab from "#src/batteries-included-components/Tabs/UserGroupsTab/UserGroupsTab";
import {
  useIsFeatureAvailable,
  useIsOpsHubExperience,
} from "#src/contexts/AuthenticatedContext.helpers";
import { UsersRoutePath } from "#src/routes/settings/users";
import { useBreadcrumbsFromRoute } from "#src/utils/route";
import { Page, Tab } from "@validereinc/common-components";
import React from "react";
import { UserGroupsRoutePath } from "./groups";
import { UserGroupCategoriesRoutePath } from "./groups/categories";

export const UsersListPageTabs = {
  UsersTab: "users",
  UserGroupsTab: "user-groups",
} as const;

export const UsersListPage = () => {
  const [isOpsExperienceEnabled] = useIsOpsHubExperience();
  const [searchParams, setSearchParams] = useSearchParams();

  const location = useLocation();

  const DEFAULT_TAB_KEY = [
    UserGroupCategoriesRoutePath.path,
    UserGroupsRoutePath.path,
  ].includes(location.pathname)
    ? UsersListPageTabs.UserGroupsTab
    : UsersListPageTabs.UsersTab;

  if (isOpsExperienceEnabled) {
    return <AccessDeniedLayout />;
  }

  const [breadcrumbs] = useBreadcrumbsFromRoute(UsersRoutePath);

  const [isUserGroupsAvailable] = useIsFeatureAvailable({
    featureFlagQuery: "core:workflows",
    permissionQuery: "user_groups:read",
  });

  return (
    <Page
      title="Users"
      breadcrumbs={breadcrumbs}
      activeTabKey={searchParams?.tab ?? DEFAULT_TAB_KEY}
      onActiveTabKeyChange={(tabKey: string) => {
        setSearchParams({ tab: tabKey });
      }}
    >
      <Tab
        title="Individual Users"
        tabKey={UsersListPageTabs.UsersTab}
      >
        <UserListTab />
      </Tab>
      {isUserGroupsAvailable && (
        <Tab
          title="User Groups"
          tabKey={UsersListPageTabs.UserGroupsTab}
        >
          <UserGroupsTab />
        </Tab>
      )}
    </Page>
  );
};
