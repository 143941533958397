import { MEASUREMENT_TYPES } from "../Hooks/useMeasurementReducer";

export const makeSubmitAnnotatePayload = ({
  leftTubeState,
  rightTubeState,
  notes,
  leftTubeInformation,
  rightTubeInformation,
  isParallaxEnabled = true,
  isDilutionEnabled = true,
}) => {
  return {
    trace_left: stateToPayloadFragment(leftTubeState),
    trace_right: stateToPayloadFragment(rightTubeState),
    notes,
    is_error: hasError(leftTubeState, rightTubeState),
    apply_dilution_correction: isDilutionEnabled,
    apply_parallax_correction: isParallaxEnabled,
    left_tube_info: leftTubeInformation,
    right_tube_info: rightTubeInformation,
  };
};

const hasError = (leftTubeState, rightTubeState) => {
  return [
    MEASUREMENT_TYPES.SEDIMENT,
    MEASUREMENT_TYPES.WATER_SEDIMENT,
    MEASUREMENT_TYPES.WATER_SEDIMENT_INTERFACE,
  ].some((type) => {
    return leftTubeState[type].hasError || rightTubeState[type].hasError;
  });
};

const stateToPayloadFragment = (state) => {
  const sediment = measurementValueToPayloadFragment(
    state[MEASUREMENT_TYPES.SEDIMENT]
  );

  const waterSediment = measurementValueToPayloadFragment(
    state[MEASUREMENT_TYPES.WATER_SEDIMENT],
    sediment.meniscus.value
  );

  const waterSedimentInterface = measurementValueToPayloadFragment(
    state[MEASUREMENT_TYPES.WATER_SEDIMENT_INTERFACE],
    waterSediment.meniscus.value
  );

  return {
    [MEASUREMENT_TYPES.SEDIMENT]: sediment,
    [MEASUREMENT_TYPES.WATER_SEDIMENT]: waterSediment,
    [MEASUREMENT_TYPES.WATER_SEDIMENT_INTERFACE]: waterSedimentInterface,
  };
};

const measurementValueToPayloadFragment = (
  measurementValue,
  setToOnZero = 0
) => {
  let meniscusValue = measurementValue.meniscus.value;

  if (meniscusValue === 0) {
    meniscusValue = setToOnZero;
  }

  return {
    meniscus: {
      value: meniscusValue,
      critical_point: measurementValue.meniscus.criticalPoint,
      vertices: measurementValue.meniscus.vertices,
    },
    tick_upper: {
      value: measurementValue.upperTick.value,
      vertices: measurementValue.upperTick.vertices,
    },
    tick_lower: {
      value: measurementValue.lowerTick.value,
      vertices: measurementValue.lowerTick.vertices,
    },
  };
};
