import React from "react";
import compact from "lodash/compact";

const displayProperties = (test) => {
  const metadata = test?.metadata ?? {};

  const getTitrationMeasuredValue = (key) => {
    const { values, unit } = metadata?.titration_measured_values?.[key] ?? {};
    const valuesWithoutNullValue = compact(values);

    if (valuesWithoutNullValue?.length > 0 && unit) {
      const displayValue = valuesWithoutNullValue.join(", ") ?? "-";

      return `${displayValue} ${unit ?? ""}`;
    }

    return "-";
  };

  const getTitrationMeasuredValuesLabel = (label) => {
    return (
      <div>
        {label}
        <br />
        (Titration Measured Values)
      </div>
    );
  };

  return [
    {
      label: "TAN",
      key: "TAN",
    },
    {
      label: "Density",
      key: "density",
      decimals: 5,
      unit: metadata?.density_unit ?? "",
      notMeasurement: true,
    },
    {
      label: "Method",
      key: "method_id",
      notMeasurement: true,
    },
    {
      label: "Correction Factor",
      key: "correction_factor",
      notMeasurement: true,
    },
    {
      label: "Sample Size",
      key: "sample_size",
      decimals: 5,
      notMeasurement: true,
    },
    {
      label: "Result 2",
      key: "result_2",
      decimals: 5,
      notMeasurement: true,
    },
    {
      label: getTitrationMeasuredValuesLabel("Meas. value"),
      key: "Meas. value",
      getValue: (value, key) => getTitrationMeasuredValue(key),
      notMeasurement: true,
    },
    {
      label: getTitrationMeasuredValuesLabel("Time"),
      key: "Time",
      getValue: (value, key) => getTitrationMeasuredValue(key),
      notMeasurement: true,
    },
    {
      label: getTitrationMeasuredValuesLabel("Volume"),
      key: "Volume",
      getValue: (value, key) => getTitrationMeasuredValue(key),
      notMeasurement: true,
    },
    {
      label: getTitrationMeasuredValuesLabel("1st Derivative"),
      key: "1.Derivative",
      getValue: (value, key) => getTitrationMeasuredValue(key),
      notMeasurement: true,
    },
    {
      label: getTitrationMeasuredValuesLabel("Temperature"),
      key: "Temperature",
      getValue: (value, key) => getTitrationMeasuredValue(key),
      notMeasurement: true,
    },
    {
      label: getTitrationMeasuredValuesLabel("dE"),
      key: "dE",
      getValue: (value, key) => getTitrationMeasuredValue(key),
      notMeasurement: true,
    },
    {
      label: getTitrationMeasuredValuesLabel("dV"),
      key: "dV",
      getValue: (value, key) => getTitrationMeasuredValue(key),
      notMeasurement: true,
    },
  ];
};

export default displayProperties;
