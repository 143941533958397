const displayProperties = (test) => {
  const metadata = test?.metadata ?? {};

  return [
    {
      label: "Water",
      key: "Water",
    },
    {
      label: "Density",
      key: "density",
      decimals: 5,
      unit: metadata?.density_unit ?? "",
      notMeasurement: true,
    },
    {
      label: "Method",
      key: "method",
      notMeasurement: true,
    },
    {
      label: "Sample Size",
      key: "sample_size",
      decimals: 5,
      notMeasurement: true,
    },
  ];
};

export default displayProperties;
