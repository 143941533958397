import { parseCustomReportConfiguration } from "#batteries-included-components/Layouts/CustomReport/CustomReportConfigurationPanel/CustomReportConfigurationPanel.helpers";
import { CustomReportDetailLayout } from "#batteries-included-components/Layouts/CustomReport/CustomReportDetailLayout";
import {
  getCustomReportFilters,
  getCustomReportGroupBy,
  useSavedCustomReport,
} from "#batteries-included-components/Layouts/CustomReport/CustomReportDetailLayout.helpers";
import { CreateCustomReportModal } from "#batteries-included-components/Modals/CustomReports/CreateCustomReportModal";
import { DeleteCustomReportModal } from "#batteries-included-components/Modals/CustomReports/DeleteCustomReportModal";
import { EditCustomReportModal } from "#batteries-included-components/Modals/CustomReports/EditCustomReportModal";
import { useBreadcrumbs } from "#routers/breadcrumbsHelper";
import { useNavigate, useParams } from "#routers/hooks";
import { REPORTS_BREADCRUMB } from "#routes/reports";
import {
  CUSTOM_REPORT_LIST_BREADCRUMB,
  linkToCustomReportList,
} from "#routes/reports/custom-reports";
import {
  CUSTOM_REPORT_DETAIL_BREADCRUMB,
  CUSTOM_REPORT_DETAIL_PAGE_TITLE,
  CustomReportDetailParamType,
  linkToCustomReportDetail,
} from "#routes/reports/custom-reports/[reportVariant]/[reportId]/detail/index";
import { useAuthenticatedContext } from "#src/contexts/AuthenticatedContext.helpers";
import {
  CustomStorageKeyTypes,
  useCustomStorageKey,
  useStorageKey,
} from "#src/hooks/useStorageKey";
import {
  Button,
  DropdownMenu,
  Page,
  useFilters,
} from "@validereinc/common-components";
import { SavedFilterStatus, getFilterObject } from "@validereinc/domain";
import { toStartCaseString } from "@validereinc/utilities";
import React, { useState } from "react";

export const CustomReportDetailPage = () => {
  const navigate = useNavigate();
  const { reportVariant, reportId } = useParams<CustomReportDetailParamType>();
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const storageKeys = useStorageKey("custom-report-detail");
  const configStorageKey = useCustomStorageKey(
    "custom-report-detail",
    CustomStorageKeyTypes.CONFIG
  );
  const {
    v2: {
      userInfo: { checkHasPermissions },
    },
  } = useAuthenticatedContext();

  const canWriteCompanyReports = checkHasPermissions(
    "company_custom_reports:write"
  );
  const canDeleteCompanyReports = checkHasPermissions(
    "company_custom_reports:delete"
  );

  const [filters] = useFilters(storageKeys.filterConfigStorageKey);
  const [configuration] = useFilters(configStorageKey);

  const savedFilter = useSavedCustomReport(reportVariant, reportId);

  const { rows } = parseCustomReportConfiguration(configuration);
  const filter = getFilterObject(
    getCustomReportFilters(reportVariant, configuration, filters)
  );

  return (
    <Page
      category={`${toStartCaseString(reportVariant)} Custom Report`}
      title={savedFilter?.name ?? CUSTOM_REPORT_DETAIL_PAGE_TITLE}
      breadcrumbs={useBreadcrumbs(
        [
          REPORTS_BREADCRUMB,
          CUSTOM_REPORT_LIST_BREADCRUMB,
          CUSTOM_REPORT_DETAIL_BREADCRUMB,
        ],
        {
          2: savedFilter?.name,
        }
      )}
      actionRow={[
        ...(savedFilter?.status === SavedFilterStatus.DRAFT ||
        canWriteCompanyReports
          ? [
              <DropdownMenu
                key="edit-custom-report"
                options={[
                  {
                    label: "Overwrite",
                    onClick: () => {
                      setIsEditModalOpen(true);
                    },
                  },
                  {
                    label: "Save as New",
                    onClick: () => {
                      setIsCreateModalOpen(true);
                    },
                  },
                ]}
              >
                <Button
                  variant="primary"
                  icon="caret-down"
                  iconPosition="right"
                >
                  Save Changes
                </Button>
              </DropdownMenu>,
            ]
          : []),
        ...(savedFilter?.status === SavedFilterStatus.DRAFT ||
        canDeleteCompanyReports
          ? [
              <Button
                key="delete-custom-report"
                variant="error-outline"
                onClick={() => setIsDeleteModalOpen(true)}
              >
                Delete
              </Button>,
            ]
          : []),
      ]}
    >
      <CustomReportDetailLayout
        configStorageKey={configStorageKey}
        reportVariant={reportVariant}
        {...storageKeys}
      />
      <CreateCustomReportModal
        variant={reportVariant}
        isOpen={isCreateModalOpen}
        onClose={() => {
          setIsCreateModalOpen(false);
        }}
        onSave={(id) => {
          navigate({ pathname: linkToCustomReportDetail(reportVariant, id) });
        }}
        filter={filter}
        groupBy={getCustomReportGroupBy(reportVariant, rows)}
      />
      <DeleteCustomReportModal
        onClose={() => {
          setIsDeleteModalOpen(false);
        }}
        onSuccess={() => {
          navigate({ pathname: linkToCustomReportList() });
        }}
        variant={reportVariant}
        customReportId={isDeleteModalOpen ? reportId : undefined}
      />
      <EditCustomReportModal
        onClose={() => setIsEditModalOpen(false)}
        customReportId={isEditModalOpen ? reportId : undefined}
        filter={filter}
        groupBy={getCustomReportGroupBy(reportVariant, rows)}
        name={savedFilter?.name}
        status={savedFilter?.status}
        variant={reportVariant}
      />
    </Page>
  );
};
