import React from "react";
import * as PropTypes from "prop-types";
import { Media } from "react-bootstrap";
import { getTimeStringFromDate } from "#utils/timeFormatter";
import { Link } from "react-router-dom";
import "./AnnotationsPanel.css";
import { linkToAnnotate } from "../../Routers/links";
import config from "#src/config";

const AnnotationsPanelHeader = (props) => {
  if (props.test.site?.company) {
    return (
      <div>
        {`${props.test.site.company.name} - ${props.test.site.name} - ${props.test.name}`}
      </div>
    );
  } else {
    return null;
  }
};

AnnotationsPanelHeader.propTypes = {
  test: PropTypes.object.isRequired,
};

const AnnotationsPanel = (props) => {
  return (
    <div
      style={props.style}
      className="annotationsPanel"
    >
      <AnnotationsPanelHeader test={props.test} />
      <Media.Body>
        <Link to={linkToAnnotate(props.test.test_id)}>
          <Media.Heading>{props.test.test_id}</Media.Heading>
          <p>{getTimeStringFromDate(props.test.date, config.DATE_FORMAT)}</p>
        </Link>
      </Media.Body>
    </div>
  );
};

AnnotationsPanel.propTypes = {
  test: PropTypes.object.isRequired,
  style: PropTypes.object.isRequired,
};

export default AnnotationsPanel;
