import UserService from "#services/UserService";
import { Button, Modal, Title, useAlert } from "@validereinc/common-components";
import * as PropTypes from "prop-types";
import React, { useState } from "react";
import "./ConfirmationModal.css";

const DeactivateConfirmationModal = ({
  memberProfile,
  updateMemberProfile,
  onHide,
}) => {
  const { name } = memberProfile;
  const [buttonState, setButtonState] = useState("enabled");
  const { addAlert } = useAlert();

  const onDeactivateClicked = () => {
    setButtonState("loading");

    UserService.requestDisableUser(memberProfile.id)
      .then(() => {
        updateMemberProfile();
        onHide();
        addAlert({
          variant: "success",
          message: `Successfully deactivated ${name}.`,
        });
      })
      .catch(() => {
        setButtonState("enabled");
      });
  };

  return (
    <Modal
      className="confirmationModal"
      open={true}
      onClose={buttonState === "enabled" ? onHide : null}
    >
      <Modal.Header>
        <Title>Deactivate Account</Title>
      </Modal.Header>

      <Modal.Body>
        <div>Do you wish to deactivate {name}?</div>
      </Modal.Body>

      <Modal.Footer className="confirmationModal__footer">
        <Button
          onClick={onHide}
          disabled={buttonState !== "enabled"}
        >
          Cancel
        </Button>

        <Button
          className="confirmButton"
          variant="error"
          onClick={onDeactivateClicked}
          isLoading={buttonState === "loading"}
          disabled={buttonState !== "enabled"}
        >
          Deactivate
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

DeactivateConfirmationModal.propTypes = {
  memberProfile: PropTypes.object,
  onHide: PropTypes.func.isRequired,
  updateMemberProfile: PropTypes.func.isRequired,
  addAlertMessage: PropTypes.func.isRequired,
};

export default DeactivateConfirmationModal;
