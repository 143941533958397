import { queryParamsConverter } from "#routers/historyHelper";
import useQueryParams from "#src/Routers/useQueryParams";
import {
  FilterPillbox,
  MultiDropdownInputWithSearch,
} from "@validereinc/common-components";
import difference from "lodash/difference";
import differenceBy from "lodash/differenceBy";
import React, { useEffect, useState } from "react";

const STREAM_CATEGORIES = ["Receipt", "Disposition"];
const PRODUCT_OPTIONS = ["Liquid", "Gas"];

const StreamsTableFilter = (allStreams, withReport) => {
  const filterDropdownDefinitions = {
    stream: {
      label: "Stream Name",
      inputs: [],
      options: [],
      labelKey: "name",
      selectionLimit: 10,
    },
    ...(withReport
      ? {
          streamCategory: {
            label: "Stream Category",
            inputs: [],
            options: STREAM_CATEGORIES,
          },
        }
      : {}),
    productCategory: {
      label: "Product Category",
      inputs: [],
      options: PRODUCT_OPTIONS,
    },
    from: {
      label: "From",
      inputs: [],
      options: [],
      labelKey: "name",
      selectionLimit: 10,
    },
    to: {
      label: "To",
      inputs: [],
      options: [],
      labelKey: "name",
      selectionLimit: 10,
    },
  };

  const [filterDropdowns, setFilterDropdowns] = useState(
    filterDropdownDefinitions
  );

  const { queryParamInput, queryParamOptions } =
    queryParamsConverter(filterDropdowns);

  const [queryParams, setQueryParams] = useQueryParams(
    queryParamInput,
    queryParamOptions
  );

  // the added filters should not be present in filterPillbox
  const { report: _report, ...filterBy } = queryParams;

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (allStreams.length) {
      const { stream, to, from } = filterDropdowns;

      stream.options = allStreams;

      const newFromOptions = [];
      const newToOptions = [];

      for (const stream of allStreams) {
        const { source_facility, terminal_facility } = stream;

        if (!newFromOptions.find(({ id }) => id === source_facility.id)) {
          newFromOptions.push(source_facility);
        }

        if (!newToOptions.find(({ id }) => id === terminal_facility.id)) {
          newToOptions.push(terminal_facility);
        }
      }

      from.options = newFromOptions;
      to.options = newToOptions;

      setFilterDropdowns({
        ...filterDropdowns,
        stream,
        from,
        to,
      });

      setIsLoading(false);
    }
  }, [allStreams]);

  const onDropdownSelect = (selectedValue, key) => {
    setQueryParams({
      [key]: selectedValue,
    });
  };

  const clearAllFilters = () => {
    const newQueryParam = { ...queryParams };

    Object.keys(newQueryParam).forEach((key) => {
      newQueryParam[key] = [];
    });

    setQueryParams(newQueryParam);
  };

  const onClearFilterClick = (filterObject) => {
    if (!filterObject) {
      return clearAllFilters();
    }

    const newQueryParam = { ...queryParams };
    const { filterKey, name, id } = filterObject;

    if (id) {
      newQueryParam[filterKey] = differenceBy(
        newQueryParam[filterKey],
        [filterObject],
        "id"
      );
    } else {
      newQueryParam[filterKey] = difference(newQueryParam[filterKey], [name]);
    }

    setQueryParams({ ...newQueryParam });
  };

  const filterRow = (
    <div className="filterRow">
      {Object.keys(filterDropdowns).map((key) => {
        const { label, labelKey, options, selectionLimit } =
          filterDropdowns[key];

        return (
          <MultiDropdownInputWithSearch
            key={key}
            label={label}
            labelKey={labelKey ? labelKey : null}
            dropdownKey={key}
            value={queryParams[key]}
            options={options}
            onChange={onDropdownSelect}
            selectLimit={selectionLimit}
          />
        );
      })}
    </div>
  );

  const filterPillbox = (
    <FilterPillbox
      filterBy={filterBy}
      onClearFilterClick={onClearFilterClick}
    />
  );

  // Only pass the full queryParams object once all options are loaded
  // so it doesn't cause an extra fetch
  return [filterRow, filterPillbox, !isLoading ? queryParams : {}, isLoading];
};

export default StreamsTableFilter;
