import {
  COMPONENT_QUALITIES,
  CRUDE_PRODUCT_TYPE_PROPERTIES,
  HYDROGEN_AND_HELIUM_INERTS,
  INERTS,
  NGL_PRODUCT_TYPE_PROPERTIES,
} from "../StreamsConstants";

export const getDisplayProperties = (stream, recordDetail) => {
  const qualities = recordDetail?.qualities;

  switch (stream?.product_type) {
    case "Gas":
    case "Natural Gas":
      return getGasProductTypeProperties(qualities);
    case "NGL":
      return NGL_PRODUCT_TYPE_PROPERTIES;
    case "C5+":
    case "Condensate":
      return getCondyProductTypeProperties(qualities);
    case "CRD":
    case "Liquid":
      return CRUDE_PRODUCT_TYPE_PROPERTIES;
    default: {
      if (stream?.product_category === "gas") {
        return getGasProductTypeProperties(qualities);
      } else {
        return getLiquidProductCategoryProperties(qualities);
      }
    }
  }
};

/**
 * The record detail api returns qualities with vol% or mole% depending on the
 * balance_type of the accounting period the record belongs to. The mock data
 * for Production Records has to switch between a record id for "gas" or "liquid"
 * so that the detail api will return the correct qualities.
 *
 * TODO: once record detail api returns both mole% and vol%, remove this and update
 * the getDisplayProperties function to return both property and unit.
 *
 */
export const getCorrectMockUnit = (stream) => {
  switch (stream?.product_type) {
    case "Gas":
    case "Natural Gas":
      return "gas";
    case "NGL":
    case "C5+":
    case "Condensate":
      return "liquid";
    default: {
      if (stream?.product_category === "gas") {
        return "gas";
      } else {
        return "liquid";
      }
    }
  }
};

export const getAccumulativeDisplayProperties = (qualities) => {
  if (qualities?.["Heptanes Plus (C7+)"]?.value) {
    return ["Hexanes (C6)", "Heptanes Plus (C7+)"];
  } else if (qualities?.["Hexanes Plus (C6+)"]?.value) {
    return ["Hexanes Plus (C6+)"];
  } else {
    return [];
  }
};

export const getGasProductTypeProperties = (qualities) => {
  return [
    "Measured Volume",
    "Energy",
    ...COMPONENT_QUALITIES,
    ...getAccumulativeDisplayProperties(qualities),
    ...HYDROGEN_AND_HELIUM_INERTS,
    ...INERTS,
    "Gas Equivalent Factor",
    "Gross Heating Value",
  ];
};

export const getCondyProductTypeProperties = (qualities) => {
  return [
    "Measured Volume",
    "Density",
    "Vapour Pressure",
    "Deemed Butane",
    ...COMPONENT_QUALITIES,
    ...getAccumulativeDisplayProperties(qualities),
    ...INERTS,
  ];
};

export const getLiquidProductCategoryProperties = (qualities) => {
  return [
    "Measured Volume",
    "Density",
    "Vapour Pressure",
    "Deemed Butane",
    "Sulphur",
    ...COMPONENT_QUALITIES,
    ...getAccumulativeDisplayProperties(qualities),
    ...HYDROGEN_AND_HELIUM_INERTS,
    ...INERTS,
  ];
};

/**
 * Display Properties for acccounting periods use a shortened form (e.g. Methane (C1))
 * instead of what's in MeasurementTypes (e.g. Mole Percentage Methane (C1)). If the api
 * goes back to using the MeasurementType as the key, use `getComponentShortForm` instead.
 */
export function getDisplayPropertyShortForm(component) {
  try {
    // this regex will return any string between "(" and ")"
    // ex) Methane (C1) -> C1
    const componentShortForm = component.match(/\((.*?)\)/)?.[1] ?? component;

    return componentShortForm;
  } catch {
    return "-";
  }
}

export function getMeasurementString(measurement, decimal) {
  const { value, unit } = measurement ?? {};

  if (value !== null) {
    return `${value?.toFixed(decimal) ?? "-"} ${unit ?? ""}`;
  }

  return "-";
}
