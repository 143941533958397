import { useReducer } from "react";
import { Record } from "immutable";

export const MEASUREMENT_TYPES = {
  WATER_SEDIMENT_INTERFACE: "Water & Sediment & Interface",
  WATER_SEDIMENT: "Water & Sediment",
  SEDIMENT: "Sediment",
};

export const MEASUREMENT_LABELS = {
  [MEASUREMENT_TYPES.WATER_SEDIMENT_INTERFACE]: "Interface",
  [MEASUREMENT_TYPES.WATER_SEDIMENT]: "Water",
  [MEASUREMENT_TYPES.SEDIMENT]: "Sediment",
};

export const MeasurementState = Record({
  [MEASUREMENT_TYPES.WATER_SEDIMENT]: undefined,
  [MEASUREMENT_TYPES.SEDIMENT]: undefined,
  [MEASUREMENT_TYPES.WATER_SEDIMENT_INTERFACE]: undefined,
});

export const MeasurementValueActions = Record({
  setMeniscusValue: undefined,
  setUpperTickValue: undefined,
  setLowerTickValue: undefined,
  clearMeasurementValue: undefined,
  zeroOutMeasurementValue: undefined,
  setError: undefined,
});

export const MeniscusValues = Record({
  value: undefined,
  criticalPoint: null,
  vertices: [],
});

export const TickValues = Record({
  value: null,
  vertices: [],
});

const MeasurementValue = Record({
  hasError: false,
  meniscus: null,
  upperTick: null,
  lowerTick: null,
});

export const initialMeasurementValue = new MeasurementValue({
  hasError: false,
  meniscus: new MeniscusValues(),
  upperTick: new TickValues(),
  lowerTick: new TickValues(),
});

// ACTIONS
export const SET_MENISCUS = "SET_MENISCUS";
export const SET_UPPER_TICK = "SET_UPPER_TICK";
export const SET_LOWER_TICK = "SET_LOWER_TICK";
export const CLEAR_MEASUREMENT_STATE = "CLEAR_MEASUREMENT_STATE";
export const ZERO_OUT_MEASUREMENT_STATE = "ZERO_OUT_MEASUREMENT_STATE";
export const SET_ERROR = "SET_ERROR";

export const measurementStateReducer = (state, { type, value }) => {
  switch (type) {
    case SET_MENISCUS: {
      return state.set("meniscus", value);
    }
    case SET_UPPER_TICK: {
      return state.set("upperTick", value);
    }
    case SET_LOWER_TICK: {
      return state.set("lowerTick", value);
    }
    case CLEAR_MEASUREMENT_STATE: {
      return state.withMutations((s) => {
        s.hasError = false;
        s.meniscus = new MeniscusValues();
        s.upperTick = new TickValues();
        s.lowerTick = new TickValues();
      });
    }
    case ZERO_OUT_MEASUREMENT_STATE: {
      return state.withMutations((s) => {
        s.hasError = false;
        s.meniscus = new MeniscusValues({ value: 0.0 });
        s.upperTick = new TickValues();
        s.lowerTick = new TickValues();
      });
    }
    case SET_ERROR: {
      return state.withMutations((s) => {
        s.hasError = true;
        s.meniscus = new MeniscusValues({ value: null });
        s.upperTick = new TickValues();
        s.lowerTick = new TickValues();
      });
    }
  }
};

const useMeasurementReducer = () => {
  const [waterSediment, dispatchToWaterSediment] = useReducer(
    measurementStateReducer,
    initialMeasurementValue
  );
  const [sedimentValue, dispatchToSediment] = useReducer(
    measurementStateReducer,
    initialMeasurementValue
  );
  const [waterSedimentInterfaceValue, dispatchToWaterSedimentInterface] =
    useReducer(measurementStateReducer, initialMeasurementValue);

  const dispatchToMeasurementType = (measurementType, action) => {
    switch (measurementType) {
      case MEASUREMENT_TYPES.WATER_SEDIMENT: {
        dispatchToWaterSediment(action);
        break;
      }
      case MEASUREMENT_TYPES.SEDIMENT: {
        dispatchToSediment(action);
        break;
      }
      case MEASUREMENT_TYPES.WATER_SEDIMENT_INTERFACE: {
        dispatchToWaterSedimentInterface(action);
        break;
      }
    }
  };

  const setMeniscusValue = (measurementType, value) => {
    dispatchToMeasurementType(measurementType, {
      type: SET_MENISCUS,
      value,
    });
  };
  const setUpperTickValue = (measurementType, value) => {
    dispatchToMeasurementType(measurementType, {
      type: SET_UPPER_TICK,
      value,
    });
  };
  const setLowerTickValue = (measurementType, value) => {
    dispatchToMeasurementType(measurementType, {
      type: SET_LOWER_TICK,
      value,
    });
  };

  const clearMeasurementValue = (measurementType) => {
    dispatchToMeasurementType(measurementType, {
      type: CLEAR_MEASUREMENT_STATE,
    });
  };

  const zeroOutMeasurementValue = (measurementType) => {
    dispatchToMeasurementType(measurementType, {
      type: ZERO_OUT_MEASUREMENT_STATE,
    });
  };

  const setError = (measurementType) => {
    dispatchToMeasurementType(measurementType, {
      type: SET_ERROR,
    });
  };

  const value = new MeasurementState({
    [MEASUREMENT_TYPES.WATER_SEDIMENT]: waterSediment,
    [MEASUREMENT_TYPES.SEDIMENT]: sedimentValue,
    [MEASUREMENT_TYPES.WATER_SEDIMENT_INTERFACE]: waterSedimentInterfaceValue,
  });

  const actions = new MeasurementValueActions({
    setMeniscusValue,
    setUpperTickValue,
    setLowerTickValue,
    clearMeasurementValue,
    zeroOutMeasurementValue,
    setError,
  });

  return [value, actions];
};

export default useMeasurementReducer;
