export const COMPONENT_QUALITIES = [
  "Methane (C1)",
  "Ethane (C2)",
  "Propane (C3)",
  "Isobutane (iC4)",
  "n-Butane (NC4)",
  "Isopentane (iC5)",
  "n-Pentane (NC5)",
];

export const HYDROGEN_AND_HELIUM_INERTS = ["Hydrogen (H2)", "Helium (He)"];

export const INERTS = [
  "Nitrogen (N2)",
  "Carbon Dioxide (CO2)",
  "Hydrogen Sulphide (H2S)",
];

export const NGL_PRODUCT_TYPE_PROPERTIES = [
  "Measured Volume",
  ...COMPONENT_QUALITIES,
  "Hexanes Plus (C6+)",
];

export const CRUDE_PRODUCT_TYPE_PROPERTIES = [
  "Measured Volume",
  "Density",
  "Sulphur",
  "Vapour Pressure",
];
