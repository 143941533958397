import { getFirstValidValue } from "#utils/objectFormatter";

const displayProperties = (test) => {
  const densityTemperature = getFirstValidValue([
    test.metadata.temperature_c,
    test.measurements.Density?.temperature_in_c,
  ]);

  const DensityLabel = densityTemperature
    ? `Density @ ${densityTemperature} ℃`
    : "Density";

  return [
    {
      label: DensityLabel,
      key: "Density",
    },
    {
      label: "Specific Gravity",
      key: "specific_gravity",
      decimals: 4,
      notMeasurement: true,
    },
    {
      label: "API Density @ 15 ℃",
      key: "api_density_15_deg_c",
      measurementKey: "Density",
    },
    {
      label: "API Gravity @ 15 ℃",
      key: "api_gravity_15_deg_c",
      measurementKey: "API Gravity",
    },
    {
      label: "API Specific Gravity @ 15 ℃",
      key: "api_specific_gravity_15_deg_c",
      decimals: 4,
      notMeasurement: true,
    },
    {
      label: "API Density @ 20 ℃",
      key: "api_density_20_deg_c",
      measurementKey: "Density",
    },
    {
      label: "API Gravity @ 20 ℃",
      key: "api_gravity_20_deg_c",
      measurementKey: "API Gravity",
    },
    {
      label: "API Specific Gravity @ 20 ℃",
      key: "api_specific_gravity_20_deg_c",
      decimals: 4,
      notMeasurement: true,
    },
    {
      label: "API Density @ 60 °F",
      key: "api_density_60_deg_f",
      measurementKey: "Density",
    },
    {
      label: "API Gravity @ 60 °F",
      key: "api_gravity_60_deg_f",
      measurementKey: "API Gravity",
    },
    {
      label: "API Specific Gravity @ 60 °F",
      key: "api_specific_gravity_60_deg_f",
      decimals: 4,
      notMeasurement: true,
    },
  ];
};

export default displayProperties;
