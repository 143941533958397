import { useReducer, useEffect } from "react";
import moment from "moment";
import config from "#src/config";
import { AssertIsBeforeDate, AssertIsAfterDate } from "#utils/assert";
import {
  formatIntervalSelections,
  updateItemInArray,
  updateIntervalSelectionOnToggle,
  updateIntervalSelectionsOnMethodChange,
  updateIntervalSelectionsFromPreSelectedValues,
  clearIntervalSelections,
  updateSelectionPropertiesOnIntervalChange,
} from "../../RecordOfQualityHelper";
import { getTimeStringFromDate } from "#utils/timeFormatter";

// ACTIONS
export const SET_SELECTION_STATE = "SET_SELECTION_STATE";
export const SELECT_STREAM = "SELECT_STREAM";
export const SELECT_METHOD = "SELECT_METHOD";
export const TOGGLE_SAMPLE = "TOGGLE_SAMPLE";
export const SET_PRESELECTED_VALUES = "SET_PRESELECTED_VALUES";
export const SET_APPROVED = "SET_APPROVED";
export const SET_INTERVAL_SELECTIONS = "SET_INTERVAL_SELECTIONS";
export const UPDATE_INTERVAL_SELECTIONS = "UPDATE_INTERVAL_SELECTIONS";
export const DELETE_INTERVAL_SELECTION = "DELETE_INTERVAL_SELECTION";
export const RESET_SELECTION_STATE = "RESET_SELECTION_STATE";
export const SET_IS_INITIALIZED = "SET_IS_INITIALIZED";

export const DEFAULT_INTERVAL_SELECTION = {
  sampleIds: [],
  previousAccountingRecordId: null,
  method: null,
};

const APPROVED_ACTIONS = [
  SET_APPROVED,
  SET_SELECTION_STATE,
  RESET_SELECTION_STATE,
];

export const INITIAL_STATE = {
  intervalSelections: [],
  /**
   * If approved, only APPROVED_ACTIONS can be done to change state,
   * (allows for viewing only)
   */
  approved: false,
  /**
   * An object that defines the preselected values for methods that the
   * user cannot select. When the method corresponding to a precalculated
   * value is selected, it switches to these values.
   */
  preSelectedValues: null,
  selectionStreamId: null,
  isInitialized: false,
};

// Given a sample value, update the state to include the sample if it
// isn't there or remove it if it is
const toggleSample = (state, value) => {
  const updatedIntervalSelections = updateItemInArray(
    state.intervalSelections,
    value.updateIndex,
    (item) => updateIntervalSelectionOnToggle(item, value)
  );

  return { ...state, intervalSelections: updatedIntervalSelections };
};

const selectMethod = (state, value) => {
  const intervalSelections = updateIntervalSelectionsOnMethodChange(
    state,
    value
  );

  return { ...state, intervalSelections };
};

const setSelectionState = (state, value) => {
  const intervalSelections = formatIntervalSelections(
    value.selection_intervals
  );

  return {
    ...state,
    selectionStreamId: value.selection_stream_id,
    intervalSelections,
    isInitialized: true,
  };
};

const selectStream = (state, value) => {
  const intervalSelections = clearIntervalSelections(state.intervalSelections);

  return {
    ...state,
    intervalSelections,
    selectionStreamId: value,
  };
};

const setPreselectedValues = (state, preSelectedValues) => {
  // if selectionStreamIds don't match, the intervalSelections may need to be
  // updated (e.g. if current selected method is previous_roq)
  if (state.selectionStreamId !== state.preSelectedValues?.selectionStreamId) {
    const intervalSelections = updateIntervalSelectionsFromPreSelectedValues(
      state.intervalSelections,
      preSelectedValues
    );

    return { ...state, intervalSelections, preSelectedValues };
  } else {
    return { ...state, preSelectedValues };
  }
};

/**
 * Scenario 1: Updating last interval's `until` back creates a new interval
 *   - Old Intervals:  1st: 06-18 - 07-18
 *   - Change: Interval 1 `until` to 07-10
 *   - New Intervals: 1st: 06-18 - 07-10, 2nd: 07-11 - 07-18
 *
 * Scenario 2: Updating first interval's until back makes 2nd adjust
 *   - Old Intervals:  1st: 06-18 - 07-10, 2nd: 07-11 - 07-18
 *   - Change: Interval 1 `until` to 07-05
 *   - New Intervals: 1st: 06-18 - 07-05, 2nd: 07-06 - 07-18
 *
 * Scenario 3: Updating first interval's `until` forward makes 2nd adjust
 *   - same as above, method does not handle case where first completely
 *   - overlaps second or second does not exist since dateSelector's
 *   - disabledDays stops this
 */
const updateIntervalSelectionsOnIntervalChange = (
  state,
  { accountingPeriod, to, updateIndex, selectionData }
) => {
  const { intervalSelections: intervals } = state;
  const { until } = accountingPeriod;

  const previousEndDate = intervals[updateIndex].until;
  const updatedEndDate = to;
  const nextIntervalStartDate = intervals[updateIndex + 1]?.from;
  const dayAfterUpdate = getTimeStringFromDate(
    moment(updatedEndDate).add(1, "day"),
    config.DATE_FORMAT
  );

  let intervalSelections = updateItemInArray(
    intervals,
    updateIndex,
    (interval) => ({
      ...interval,
      until: getTimeStringFromDate(updatedEndDate, config.DATE_FORMAT),
    })
  );

  if (AssertIsBeforeDate(updatedEndDate, previousEndDate, "day")) {
    // Scenario 2
    if (nextIntervalStartDate) {
      intervalSelections[updateIndex + 1] = {
        ...intervalSelections[updateIndex + 1],
        from: dayAfterUpdate,
      };
    } else {
      // Scenario 1
      intervalSelections.push({
        from: dayAfterUpdate,
        until,
        ...DEFAULT_INTERVAL_SELECTION,
      });
    }

    intervalSelections = updateSelectionPropertiesOnIntervalChange(
      intervalSelections,
      updateIndex,
      selectionData
    );
  } else if (AssertIsAfterDate(updatedEndDate, previousEndDate, "day")) {
    // Scenario 3
    intervalSelections[updateIndex + 1] = {
      ...intervalSelections[updateIndex + 1],
      from: dayAfterUpdate,
    };
  }

  return { ...state, intervalSelections };
};

/**
 * Scenario 1: Deleting the first interval
 *   - cannot happen because no button, not handled
 *
 * Scenario 2: Deleting latest interval
 *   - The previous interval is moved forward to accountingPeriod until
 *
 * Scenario 3: Deleting a middle interval
 *   - The next intervals `from` date is moved backward to day after previous
 *   - interval end date
 */
const deleteIntervalSelection = (state, { accountingPeriod, deleteIndex }) => {
  const { intervalSelections: intervals } = state;
  const { until } = accountingPeriod;

  const previousEndDate = intervals[deleteIndex - 1].until;
  const nextIntervalStartDate = intervals[deleteIndex + 1]?.from;
  let intervalSelections = [...state.intervalSelections];

  // Scenario 2
  if (!nextIntervalStartDate) {
    intervalSelections[deleteIndex - 1] = {
      ...intervalSelections[deleteIndex - 1],
      until,
    };
  } else {
    // Scenario 3
    const dayAfterPreviousEndDate = moment(previousEndDate).add(1, "day");

    intervalSelections[deleteIndex + 1] = {
      ...intervalSelections[deleteIndex + 1],
      from: getTimeStringFromDate(dayAfterPreviousEndDate, config.DATE_FORMAT),
    };
  }

  intervalSelections = intervalSelections.filter(
    (_interval, index) => index !== deleteIndex
  );

  return { ...state, intervalSelections };
};

export const roqSelectionReducer = (state, { type, value }) => {
  if (state.approved && !APPROVED_ACTIONS.includes(type)) {
    return state;
  }

  switch (type) {
    case SET_APPROVED:
      return { ...state, approved: value };
    case SELECT_STREAM:
      return selectStream(state, value);
    case SELECT_METHOD:
      return selectMethod(state, value);
    case TOGGLE_SAMPLE:
      return toggleSample(state, value);
    case SET_SELECTION_STATE:
      return setSelectionState(state, value);
    case RESET_SELECTION_STATE:
      return {
        ...INITIAL_STATE,
        approved: state.approved,
      };
    case SET_PRESELECTED_VALUES:
      return setPreselectedValues(state, value);
    case SET_INTERVAL_SELECTIONS:
      return { ...state, intervalSelections: value };
    case UPDATE_INTERVAL_SELECTIONS:
      return updateIntervalSelectionsOnIntervalChange(state, value);
    case DELETE_INTERVAL_SELECTION:
      return deleteIntervalSelection(state, value);
    case SET_IS_INITIALIZED:
      return { ...state, isInitialized: value };
    default:
      return state;
  }
};

const useRoQSelection = (hasWriteAccess, isModalVisible) => {
  const [state, dispatch] = useReducer(roqSelectionReducer, INITIAL_STATE);

  // If user hasWriteAccess, the accounting record is not approved
  useEffect(() => {
    dispatch({ type: SET_APPROVED, value: !hasWriteAccess });
  }, [hasWriteAccess]);

  // reset the state whenever modal closes
  useEffect(() => {
    if (!isModalVisible) {
      dispatch({ type: RESET_SELECTION_STATE });
    }
  }, [isModalVisible]);

  const setSelectionState = (newValue) => {
    dispatch({ type: SET_SELECTION_STATE, value: newValue });
  };

  const setStream = (newValue) => {
    dispatch({ type: SELECT_STREAM, value: newValue });
  };

  const setMethod = (newValue, intervalSelectionIndex) => {
    dispatch({
      type: SELECT_METHOD,
      value: { ...newValue, updateIndex: intervalSelectionIndex },
    });
  };

  const toggleSample = (sampleId, sampleType, intervalSelectionIndex = 0) => {
    dispatch({
      type: TOGGLE_SAMPLE,
      value: {
        id: sampleId,
        type: sampleType,
        updateIndex: intervalSelectionIndex,
      },
    });
  };

  const setPreselectedValues = (newValue) => {
    dispatch({ type: SET_PRESELECTED_VALUES, value: newValue });
  };

  const setDefaultIntervalSelections = (from, until) => {
    dispatch({
      type: SET_INTERVAL_SELECTIONS,
      value: [{ ...DEFAULT_INTERVAL_SELECTION, from, until }],
    });
  };

  const updateIntervalSelections = (newValue) => {
    dispatch({
      type: UPDATE_INTERVAL_SELECTIONS,
      value: newValue,
    });
  };

  const deleteIntervalSelection = (
    accountingPeriod,
    intervalSelectionIndex
  ) => {
    dispatch({
      type: DELETE_INTERVAL_SELECTION,
      value: { accountingPeriod, deleteIndex: intervalSelectionIndex },
    });
  };

  const setIsInitialized = (newValue) => {
    dispatch({
      type: SET_IS_INITIALIZED,
      value: newValue,
    });
  };

  return [
    state,
    {
      setSelectionState,
      setStream,
      setMethod,
      toggleSample,
      updateIntervalSelections,
      deleteIntervalSelection,
      setPreselectedValues,
      setDefaultIntervalSelections,
      setIsInitialized,
    },
  ];
};

export default useRoQSelection;
