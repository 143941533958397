import { z } from "zod";
import {
  DomainModelMetaExtendedSchema,
  DomainModelMetaSchema,
  DomainModelSchema,
} from "./DomainModelSchemas";

export const WorkflowCategorySchema = z
  .object({
    name: z.string(),
    description: z.string(),
    company_id: z.string().uuid(),
  })
  .merge(DomainModelSchema)
  .merge(DomainModelMetaSchema)
  .merge(DomainModelMetaExtendedSchema);

export type WorkflowCategoryType = z.infer<typeof WorkflowCategorySchema>;
