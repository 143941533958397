export * from "./FacilityRecordDetailPage";

export const FACILITY_RECORD_DETAIL_PAGE_TITLE = "Facility Record Details";

export const linkToFacilityRecordDetail = (
  facilityId = ":facilityId",
  recordId = ":recordId"
) => `/app/organization/facilities/${facilityId}/record/${recordId}/details`;

export const FACILITY_RECORD_DETAIL_BREADCRUMB = {
  title: `${FACILITY_RECORD_DETAIL_PAGE_TITLE}:facilityId:recordId`,
  path: linkToFacilityRecordDetail,
};

export type FacilityRecordDetailPageParamsType = {
  facilityId: string;
  recordId: string;
};
