import React from "react";
import * as PropTypes from "prop-types";
import ComponentAnalysisPage from "./ManualEntryForms/ComponentAnalysis/ComponentAnalysisPage";
import InlineManualEntryPage from "./ManualEntryForms/Inline/InlineManualEntryPage";
import FieldManualEntryPage from "./ManualEntryForms/Field/FieldManualEntryPage";
import { connect } from "react-redux";
import { havePermission } from "../Redux/reducers/permissions";
import { AccessDeniedLayout } from "#src/batteries-included-components/Layouts/Authorization/AccessDenied";

const mapStateToProps = ({ permissions }) => ({
  havePermission: havePermission(permissions),
});

const CreateManualEntry = (props) => {
  const manualEntryType = props.match?.params?.manualEntryType;

  if (manualEntryType === "component-analysis") {
    return (
      <ComponentAnalysisPage
        match={props.match}
        breadcrumbs={props.breadcrumbs}
        profile={props.profile}
      />
    );
  } else if (manualEntryType === "inline") {
    return (
      <InlineManualEntryPage
        match={props.match}
        breadcrumbs={props.breadcrumbs}
      />
    );
  } else if (manualEntryType === "field") {
    return (
      <FieldManualEntryPage
        match={props.match}
        breadcrumbs={props.breadcrumbs}
      />
    );
  }

  return <AccessDeniedLayout />;
};

CreateManualEntry.propTypes = {
  havePermission: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  breadcrumbs: PropTypes.array,
  profile: PropTypes.object,
};

export default connect(mapStateToProps)(CreateManualEntry);
