import {
  FilterPillbox,
  MultiDropdownInputWithSearch,
} from "@validereinc/common-components";
import differenceBy from "lodash/differenceBy";
import intersectionBy from "lodash/intersectionBy";
import React, { useEffect, useState } from "react";

const DEFAULT_FILTER_DROPDOWN = {
  stream: {
    label: "Stream",
    inputs: [],
    options: [],
    labelKey: "name",
  },
  inventory: {
    label: "Inventory",
    inputs: [],
    options: [],
    labelKey: "name",
  },
};

const noStreamsMatchInProductionReport = (streams, productionReport) => {
  const { inlet_streams, outlet_streams, reference_streams } = productionReport;
  if (intersectionBy(streams, inlet_streams, "id").length) {
    return false;
  }
  if (intersectionBy(streams, outlet_streams, "id").length) {
    return false;
  }
  if (intersectionBy(streams, reference_streams, "id").length) {
    return false;
  }

  return true;
};

const noInventoriesMatchInProductionReport = (
  inventories,
  productionReport
) => {
  const { inlet_inventories, outlet_inventories, reference_inventories } =
    productionReport;
  if (intersectionBy(inventories, inlet_inventories, "id").length) {
    return false;
  }
  if (intersectionBy(inventories, outlet_inventories, "id").length) {
    return false;
  }
  if (intersectionBy(inventories, reference_inventories, "id").length) {
    return false;
  }

  return true;
};

function filterProductionReports(productionReports, filters) {
  const { stream, inventory } = filters;

  return productionReports.filter((report) => {
    if (
      stream?.length > 0 &&
      noStreamsMatchInProductionReport(stream, report)
    ) {
      return false;
    }

    if (
      inventory?.length > 0 &&
      noInventoriesMatchInProductionReport(inventory, report)
    ) {
      return false;
    }

    return true;
  });
}

export function getAllFilterInputs(filters) {
  const filterPillbox = {};

  Object.keys(filters).map((key) => {
    const inputs = filters[key]?.inputs ?? [];
    if (inputs.length > 0) {
      filterPillbox[key] = inputs;
    }
  });

  return filterPillbox;
}

export function clearAllInputs(filterDropdowns) {
  const updatedFilterDropdowns = { ...filterDropdowns };

  Object.keys(updatedFilterDropdowns).map((key) => {
    updatedFilterDropdowns[key].inputs = [];
  });

  return updatedFilterDropdowns;
}

const ReportsTableFilter = (productionReports, streams, inventories) => {
  const [filterDropdowns, setFilterDropdowns] = useState({
    ...DEFAULT_FILTER_DROPDOWN,
  });

  useEffect(() => {
    if (streams.length > 0) {
      const { stream } = { ...filterDropdowns };

      stream.options = streams;

      setFilterDropdowns((filterDropdowns) => ({
        ...filterDropdowns,
        stream,
      }));
    }
  }, [streams]);

  useEffect(() => {
    if (inventories.length > 0) {
      const { inventory } = { ...filterDropdowns };

      inventory.options = inventories;

      setFilterDropdowns((filterDropdowns) => ({
        ...filterDropdowns,
        inventory,
      }));
    }
  }, [inventories]);

  const onDropdownSelect = (selectedValue, key) => {
    const filterDropdown = { ...filterDropdowns[key] };
    filterDropdown.inputs = selectedValue;

    setFilterDropdowns({ ...filterDropdowns, [key]: filterDropdown });
  };

  const clearAllFilters = () => {
    setFilterDropdowns(clearAllInputs(filterDropdowns));
  };

  const onClearFilterClick = (filterObject) => {
    if (filterObject) {
      const { filterKey } = filterObject;
      const updatedFilterDropdown = { ...filterDropdowns[filterKey] };

      updatedFilterDropdown.inputs = differenceBy(
        updatedFilterDropdown.inputs,
        [filterObject],
        "id"
      );

      setFilterDropdowns({
        ...filterDropdowns,
        [filterKey]: updatedFilterDropdown,
      });
    } else {
      clearAllFilters();
    }
  };

  const filterRow = (
    <div className="filterRow">
      {Object.keys(filterDropdowns).map((key) => {
        const { label, labelKey, options, inputs } = filterDropdowns[key];

        return (
          <MultiDropdownInputWithSearch
            key={key}
            label={label}
            labelKey={labelKey ? labelKey : null}
            dropdownKey={key}
            value={inputs}
            options={options}
            onChange={onDropdownSelect}
          />
        );
      })}
    </div>
  );

  const filterInputs = getAllFilterInputs(filterDropdowns);

  const filteredProductionReports = filterProductionReports(
    productionReports,
    filterInputs
  );

  const filterPillbox = (
    <FilterPillbox
      filterBy={filterInputs}
      onClearFilterClick={onClearFilterClick}
    />
  );

  return [filterRow, filterPillbox, filteredProductionReports];
};

export default ReportsTableFilter;
