import { EquipmentDropdownInput } from "#batteries-included-components/Dropdowns/EquipmentDropdownInput";
import { ReportingGroupFilterSwitcher } from "#batteries-included-components/Filters/ReportingGroupFilterSwitcher";
import { useCustomAttributeFilters } from "#hooks/FilterPanel/useCustomAttributeFilters";
import { FacilityDropdownInput } from "#src/batteries-included-components/Dropdowns/FacilityDropdownInput";
import {
  SavedFilterTag,
  useSavedFilters,
} from "#src/components/hooks/FilterPanel/useSavedFilters";
import {
  EQUIPMENT_STATUS_OPTIONS,
  FACILITY_STATUS_OPTIONS,
} from "#src/constants";
import { useMeasurementTypes } from "#src/contexts/MeasurementTypeContext";
import { DEFAULT_DATE_RANGES } from "#src/hooks/useDateRange";
import useLocalization from "#src/hooks/useLocalization";
import { useQuery } from "@tanstack/react-query";
import {
  DateSelectorInput,
  DropdownInput,
  FilterPanel,
  StorageKeys,
  TextInput,
  useFilters,
} from "@validereinc/common-components";
import {
  AssetType,
  EquipmentStatus,
  EquipmentTypeDomain,
  FacilityStatus,
  RecordDomain,
  RecordFilterType,
  ReportingGroupDomain,
  Resources,
} from "@validereinc/domain";
import React from "react";

const defaultDate = DEFAULT_DATE_RANGES.currentWholeMonth;

export const EquipmentRecordFilterPanel = ({
  filterConfigStorageKey,
  selectableProperties,
}: Pick<StorageKeys, "filterConfigStorageKey"> & {
  selectableProperties?: string[];
}) => {
  const { getTypeName } = useMeasurementTypes();

  const propertySelectorValues = selectableProperties?.map((key) => ({
    key,
    name: getTypeName(key),
  }));

  const { localize, isLoading: isMappingLoading } = useLocalization();

  const { data: reportingGroups, isLoading: isReportingGroupDataLoading } =
    useQuery(["reportingGroups"], () => ReportingGroupDomain.getList(), {
      select: ({ data }) => data ?? [],
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    });

  const [_, setFilters] = useFilters(filterConfigStorageKey);
  const { filterProps } = useSavedFilters<RecordFilterType>({
    queryKey: [Resources.RECORD],
    savedFilterResourceAdapter: RecordDomain.savedFilters,
    setFilters,
    filterKeyBlacklist: ["year_month.from", "year_month.to"],
    tag: SavedFilterTag.EQUIPMENT_RECORDS,
  });

  const { data: equipmentTypes, isLoading: isEquipmentTypeDataLoading } =
    useQuery({
      queryKey: ["equipmentTypes"],
      queryFn: () => EquipmentTypeDomain.getAll(),
      select: (data) => data ?? [],
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    });

  const { customAttributeFilters: equipmentCustomAttributeFilters } =
    useCustomAttributeFilters({
      assetType: AssetType.EQUIPMENT,
      section: localize("equipment"),
      prefix: "equipment.custom_attributes",
    });

  const { customAttributeFilters: facilityCustomAttributeFilters } =
    useCustomAttributeFilters({
      assetType: AssetType.FACILITY,
      section: localize("facility"),
      prefix: "equipment.facility.custom_attributes",
    });

  const isLoading =
    isEquipmentTypeDataLoading ||
    isReportingGroupDataLoading ||
    isMappingLoading;

  return (
    <FilterPanel
      {...filterProps}
      storageKey={filterConfigStorageKey}
      isLoading={isLoading}
      defaultValue={{
        year_month: DEFAULT_DATE_RANGES.lastWholeMonth,
        reporting_group_id: reportingGroups?.[0]?.id,
        "equipment.facility.status": [FacilityStatus.ACTIVE],
        "equipment.status": [EquipmentStatus.ACTIVE],
      }}
      defaultActiveKeys={[localize("Facility")]}
      filters={[
        {
          component: (
            <ReportingGroupFilterSwitcher
              key="reporting_group_id"
              name="reporting_group_id"
              options={reportingGroups}
              shouldSelectFirstOptionAsDefault
            />
          ),
        },
        {
          component: (
            <DateSelectorInput
              key="year_month"
              name="year_month"
              variant="month"
              isRange
              isInline
            />
          ),
        },
        {
          component: (
            <TextInput
              name="equipment.name"
              placeholder={`Search ${localize("equipment_plural")}...`}
              type="search"
              isInline
            />
          ),
        },
        {
          component: (
            <DropdownInput
              key="equipment.type.id"
              name="equipment.type.id"
              options={equipmentTypes}
              placeholder={`${localize("Equipment")} Type`}
              labelKey="name"
              valueKey="id"
              isMulti
              isSearchable
              isInline
              isOptionalTextShown={false}
            />
          ),
        },
        {
          component: (
            <DropdownInput
              key="measurement_type"
              name="measurement_type"
              options={propertySelectorValues}
              placeholder="Select Property..."
              labelKey="name"
              valueKey="key"
              isSearchable
              isInline
              isOptionalTextShown={false}
            />
          ),
        },
        {
          section: localize("Facility"),
          component: (
            <FacilityDropdownInput
              isMulti
              isFluid
              name="equipment.facility.id"
              key="equipment.facility.id"
              isOptionalTextShown={false}
            />
          ),
        },
        {
          section: localize("Facility"),
          component: (
            <DropdownInput
              label={`${localize("Facility")} Status`}
              name="equipment.facility.status"
              key="equipment.facility.status"
              options={FACILITY_STATUS_OPTIONS}
              labelKey="label"
              valueKey="value"
              isMulti
              isFluid
              isSearchable
              isOptionalTextShown={false}
            />
          ),
        },
        ...facilityCustomAttributeFilters,
        {
          section: localize("Equipment"),
          component: (
            <EquipmentDropdownInput
              key="equipment.id"
              name="equipment.id"
              isMulti
              isFluid
              isOptionalTextShown={false}
            />
          ),
        },
        {
          section: localize("Equipment"),
          component: (
            <DropdownInput
              key="equipment.status"
              name="equipment.status"
              options={EQUIPMENT_STATUS_OPTIONS}
              label={`${localize("Equipment")} Status`}
              labelKey="label"
              valueKey="value"
              isMulti
              isFluid
              isSearchable
              isOptionalTextShown={false}
            />
          ),
        },
        ...equipmentCustomAttributeFilters,
      ]}
    />
  );
};
