import { EquipmentDropdownInput } from "#batteries-included-components/Dropdowns/EquipmentDropdownInput";
import { FacilityDropdownInput } from "#batteries-included-components/Dropdowns/FacilityDropdownInput";
import { FlowDropdownInput } from "#batteries-included-components/Dropdowns/FlowDropdownInput";
import { RecordValueConfigurationContext } from "#batteries-included-components/Layouts/RecordConfiguration/RecordConfigurationContext";
import { useParams } from "#routers/hooks";
import { getDefaultDateRange } from "#utils/recordUtils";
import {
  DateSelectorInput,
  FilterPanel,
  useFilters,
} from "@validereinc/common-components";
import { AssetType } from "@validereinc/domain";
import {
  fromUTC,
  yearMonthFormatter,
  yearMonthParser,
} from "@validereinc/utilities";
import React, { useContext, useEffect, useMemo } from "react";

export const EstimationMethodConfigurationFilterPanel = ({
  storageKey,
}: {
  storageKey: string;
}) => {
  const { record } = useContext(RecordValueConfigurationContext) || {};

  const [storedFilters, setStoredFilters] = useFilters(storageKey);
  const params = useParams<{ measurementType: string }>();

  const defaultDateRange = useMemo(
    () => getDefaultDateRange(record, params.measurementType),
    [record, params.measurementType]
  );

  const filterValue = {
    flow: storedFilters.flow,
    equipment: storedFilters.equipment,
    facility: storedFilters.facility,
    dateRange:
      storedFilters.from && storedFilters.to
        ? {
            from: yearMonthParser(storedFilters.from),
            to: yearMonthParser(storedFilters.to),
          }
        : defaultDateRange,
  };

  const onFiltersChange = ({
    dateRange,
    ...filters
  }: Record<string, string> & { dateRange?: { from: Date; to: Date } }) => {
    setStoredFilters({
      ...storedFilters,
      ...filters,
      ...(dateRange && {
        from: yearMonthFormatter(fromUTC(dateRange.from)),
        to: yearMonthFormatter(fromUTC(dateRange.to)),
      }),
    });
  };

  useEffect(() => {
    setStoredFilters({
      ...(record?.asset_type === AssetType.FACILITY
        ? { facility: record?.asset_id }
        : {}),
      ...(record?.asset_type === AssetType.EQUIPMENT
        ? { equipment: record?.asset_id }
        : {}),
      ...(record?.asset_type === AssetType.FLOW
        ? { flow: [record?.asset_id] }
        : {}),
      ...storedFilters,
      from: yearMonthFormatter(fromUTC(filterValue?.dateRange.from)),
      to: yearMonthFormatter(fromUTC(filterValue?.dateRange.to)),
    });
  }, [record, storedFilters.tab]);

  return (
    <FilterPanel
      value={filterValue}
      onChange={onFiltersChange}
      defaultActiveKeys={["Asset"]}
      isVertical
      filters={[
        {
          component: (
            <DateSelectorInput
              name="dateRange"
              variant="month"
              label="Period"
              isRange
              isRequired
            />
          ),
        },
        {
          section: "Asset",
          component: (
            <FacilityDropdownInput
              key="facility"
              name="facility"
              isFluid
            />
          ),
        },
        {
          section: "Asset",
          component: (
            <EquipmentDropdownInput
              key="equipment"
              name="equipment"
              isFluid
            />
          ),
        },
        {
          section: "Asset",
          component: (
            <FlowDropdownInput
              key="flow"
              name="flow"
              isFluid
            />
          ),
        },
      ]}
    />
  );
};
