export function randomNumberGenerator(min, max, decimal) {
  const randomNumber = Math.random() * (max - min) + min;

  if (decimal === 0) {
    return Math.round(randomNumber);
  }

  if (decimal > 0) {
    return randomNumber.toFixed(decimal);
  }

  return randomNumber;
}
