import {
  EntityDropdownInput,
  EntityDropdownProps,
} from "#batteries-included-components/Dropdowns/EntityDropdownInput";
import { FlowDomain, FlowType } from "@validereinc/domain";
import React from "react";

export const FlowTypeDropdownInput = ({
  name = "type",
  label,
  ...props
}: EntityDropdownProps<FlowType>) => {
  return (
    <EntityDropdownInput
      fetchEntityList={async () => ({ data: await FlowDomain.getFlowTypes() })}
      label={label ?? "Type"}
      name={name}
      sort={{
        sortBy: "name",
        sortDirection: "asc",
      }}
      {...props}
    />
  );
};
