import {
  FilterPanel,
  StorageKeys,
  TextInput,
} from "@validereinc/common-components";
import React from "react";

export const UserListFilterPanel = ({
  filterConfigStorageKey,
}: Pick<StorageKeys, "filterConfigStorageKey">) => {
  return (
    <>
      <FilterPanel
        storageKey={filterConfigStorageKey}
        filters={[
          {
            component: (
              <TextInput
                key="name"
                name="name"
                placeholder="Search Users"
                type="search"
                isInline
              />
            ),
          },
        ]}
      />
    </>
  );
};
