import { TextInput, Title } from "@validereinc/common-components";
import * as PropTypes from "prop-types";
import React from "react";
import { REFERENCE_TEMPERATURE_IN_CELSIUS_PROPERTIES } from "./SiteConstant";

const SiteSettingForm = ({ formState }) => {
  const sharedProps = {
    showIcon: true,
    isRequired: true,
  };

  return (
    <>
      <TextInput
        {...sharedProps}
        name="name"
        label="name"
        type="text"
        isDisabled
      />

      <Title type="subheader">Reference Temperature</Title>

      {REFERENCE_TEMPERATURE_IN_CELSIUS_PROPERTIES.map((property) => (
        <TextInput
          {...sharedProps}
          key={property}
          name={`reference_temperatures.${property}.value`}
          label={property}
          type="number"
          unit="°C"
          isDisabled={formState !== "enabled"}
          isRequired
          showIcon
        />
      ))}
    </>
  );
};

SiteSettingForm.propTypes = {
  form: PropTypes.object,
  formState: PropTypes.string.isRequired,
};

export default SiteSettingForm;
