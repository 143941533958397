import React, { useEffect, useState } from "react";

import {
  Button,
  Dialog,
  Form,
  RadioInput,
  useAlert,
  useForm,
} from "@validereinc/common-components";

import { EventsDomain } from "@validereinc/domain";

import { useQueryClient } from "@tanstack/react-query";
import type { EventDetailsType } from "@validereinc/domain";

export const ChangeEventStatusDialog = ({
  isOpen,
  onClose,
  event,
}: {
  isOpen: boolean;
  onClose: (shouldRefetch?: boolean) => void;
  event: EventDetailsType;
}) => {
  const [eventStatuses, setEventStatuses] = useState([]);

  const form = useForm({ defaultValues: { status: event.status } });

  const queryClient = useQueryClient();
  const { addAlert } = useAlert();

  const onClick = form.handleSubmit(async (formValues) => {
    try {
      await EventsDomain.edit({ eventId: event.id, ...formValues });

      addAlert({
        variant: "success",
        message: `Successfully updated status.`,
      });

      onClose(true);
    } catch (caught) {
      console.error("Error updating event status", caught);

      addAlert({
        variant: "error",
        message: `There was an error updating the event status.`,
      });
    } finally {
      queryClient.invalidateQueries({
        queryKey: ["events", event.id],
      });
      queryClient.invalidateQueries({
        queryKey: ["workflows"],
      });
    }
  });

  const onFetchEventStatuses = async () => {
    try {
      const eventStatuses = await EventsDomain.eventStatuses.getList();

      setEventStatuses(eventStatuses);
    } catch (caught) {
      console.error("Error fetching event statuses", caught);

      setEventStatuses([]);
    }
  };

  useEffect(() => {
    onFetchEventStatuses();
  }, []);

  return (
    <Dialog
      title="Update Event Status"
      isOpen={isOpen}
      onClose={onClose}
      actionRow={[
        <Button
          key="update-status-button"
          onClick={onClick}
          variant="primary"
        >
          Update Status
        </Button>,
      ]}
    >
      <Form {...form}>
        <RadioInput
          name="status"
          isRequired
          options={eventStatuses}
          valueKey="id"
          labelKey="name"
        />
      </Form>
    </Dialog>
  );
};
