import config from "#src/config";
import { TIMEZONE, getTimeStringFromDate } from "#utils/timeFormatter";
import { Panel } from "@validereinc/common-components";
import download from "downloadjs";
import moment from "moment";
import React, { Component } from "react";
import { Button, Col, Row } from "react-bootstrap";
import DayPicker, { DateUtils } from "react-day-picker";
import "react-day-picker/lib/style.css";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { linkToLogin } from "../../../Routers/links";
import { addAlertMessage } from "../../Redux/actions/alertMessages";
import { AlertMessage } from "../../Redux/reducers/alertMessages";
import SampleService from "../../Services/SampleService";
import "./InstrumentCSVExport.css";

/* eslint-disable react/prop-types */

const mapDispatchToProps = {
  addAlertMessage,
};

export class InstrumentCSVExport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      instrumentId: this.props.match.params.instrumentId,
      instrumentType: this.props.match.params.type,
      current_epoch: moment.utc().valueOf().toString().substring(0, 10), // current time
      from: null, // current time
      to: null,
      disableExportButton: false,
      isExporting: false,
      filename: "",
      sampleList: [],

      redirect: false,
    };

    this.handleExport = this.handleExport.bind(this);
    this.handleResetClick = this.handleResetClick.bind(this);
    this.handleDayClick = this.handleDayClick.bind(this);
    this.handleAlertDismiss = this.handleAlertDismiss.bind(this);
  }

  /**
   * handleExport() retrieve and exports a formatted csv file
   * based on the instrument id and epoch time range
   */
  handleExport() {
    const from_epoch = this.state.from.valueOf() / 1000;

    // We adding a day so the api will include them on the csv
    const to_epoch = this.state.to.valueOf() / 1000 + 24 * 60 * 60;

    const downloadCSVCallback = (response) => {
      this.props.addAlertMessage(
        new AlertMessage({
          type: "success",
          message: "Your file has started downloading.",
        })
      );

      const filename = this.getFileName();

      this.setState({
        sampleList: response.data,
        filename,
      });
      download(response.data, filename, "text/csv");
    };

    SampleService.getInstrumentSampleCSV(
      this.state.instrumentId,
      from_epoch,
      to_epoch,
      TIMEZONE
    ).then(downloadCSVCallback);
  }

  /**
   * handleDayClick() set the epoch date range
   * based on the user selected day range
   */
  handleDayClick(day) {
    const range = DateUtils.addDayToRange(day, this.state);
    this.setState(range);
  }

  /**
   * handleResetClick() returns a formatted file name
   * based on the instrument id, from, and to date
   */
  handleResetClick(event) {
    event.preventDefault();
    this.setState({ from: null, to: null });
  }

  /**
   * getFileName() returns a formatted file name
   * based on the instrument id, from, and to date
   */

  getFileName() {
    const fromTime = moment
      .utc(this.state.from)
      .format(config.DATE_WITH_UNDERSCORE_FORMAT);
    const endTime = moment
      .utc(this.state.to)
      .format(config.DATE_WITH_UNDERSCORE_FORMAT);
    const instrumentId = this.state.instrumentId;
    const filename =
      "validere_ctr_" +
      instrumentId +
      "_sample_detail_list_from_" +
      fromTime +
      "_to_" +
      endTime +
      ".csv";
    return filename;
  }

  handleAlertDismiss() {
    this.setState({ alertVisible: false });
  }

  render() {
    const { from, to } = this.state;

    if (this.state.redirect) {
      return <Redirect to={{ pathname: linkToLogin() }} />;
    }

    return (
      <Col
        xs={12}
        md={8}
        mdOffset={2}
        lg={8}
        lgOffset={2}
      >
        <Panel
          title="Export"
          className="instrumentPanels"
        >
          <Row>
            <Col xs={12}>
              <div className="text-align-center">
                {!from && !to && (
                  <p>
                    Please select the <strong>first day</strong>.
                  </p>
                )}
                {from && !to && (
                  <p>
                    Please select the <strong>last day</strong>.
                  </p>
                )}
                {from && to && (
                  <p>
                    {" "}
                    You chose from{" "}
                    {getTimeStringFromDate(from, config.DATE_FORMAT)} to{" "}
                    {getTimeStringFromDate(to, config.DATE_FORMAT)}. <br />
                    Click on <b>Export</b> to export the csv file. <br />
                    <a
                      href="."
                      onClick={this.handleResetClick}
                    >
                      Reset
                    </a>
                  </p>
                )}
              </div>
            </Col>
          </Row>

          <Row>
            <Col
              xs={12}
              className="text-align-center"
            >
              <DayPicker
                numberOfMonths={1}
                selectedDays={[from, { from, to }]}
                onDayClick={this.handleDayClick}
                disabledDays={[{ after: new Date() }]}
                fixedWeeks
              />
            </Col>
          </Row>

          <hr />

          <div className="actionRow">
            <Button
              className="sampleSettings__exportButton pull-right"
              disabled={
                this.state.disableExportButton || from === null || to === null
                  ? true
                  : false
              }
              onClick={this.handleExport}
              hidden={this.state.isExporting}
            >
              Export
            </Button>
          </div>
        </Panel>
      </Col>
    );
  }
}

export default connect(undefined, mapDispatchToProps)(InstrumentCSVExport);
