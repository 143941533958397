import { FileInput, SelectInput } from "@validereinc/common-components";
import find from "lodash/find";
import * as PropTypes from "prop-types";
import React from "react";

const ThirdPartyLabImportForm = ({ formState, sites }) => {
  const sharedProps = {
    isDisabled: formState !== "enabled",
    showIcon: true,
    isRequired: true,
  };

  return (
    <>
      <SelectInput
        {...sharedProps}
        name="site"
        label="Site"
        labelKey="name"
        options={sites}
        ignoreCase
      />

      <FileInput
        {...sharedProps}
        name="attachment"
        label="Attachment"
        multiple={false}
        validate={{
          isItOverFileSizeLimit: (files) => {
            const twentyMB = 1 * 1000 * 1000;
            const fileOverSizeLimit = find(
              files,
              (file) => file.size > twentyMB
            );

            if (fileOverSizeLimit) {
              return "File size is too large.";
            }
          },
        }}
      />
    </>
  );
};

ThirdPartyLabImportForm.propTypes = {
  formState: PropTypes.string,
  sites: PropTypes.array.isRequired,
};

export default ThirdPartyLabImportForm;
