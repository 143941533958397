import { z } from "zod";
import { DomainModelMetaSchema } from "./DomainModelSchemas";
import { AttributeSchema, ResourceLookup } from "./AttributeSchemas";

export const TemplatedReportStatus = {
  ACTIVE: "active",
  ARCHIVED: "archived",
};

export type TemplatedReportStatusType =
  (typeof TemplatedReportStatus)[keyof typeof TemplatedReportStatus];

export const TemplatedReportInputSchema = z
  .object({
    display_name: z.string(),
    lookup_entity_type: z
      .enum([
        ResourceLookup.ASSET_GROUP,
        ResourceLookup.DEVICE,
        ResourceLookup.EQUIPMENT,
        ResourceLookup.FACILITY,
        ResourceLookup.FLOW,
        ResourceLookup.FORM_SUBMISSION,
      ])
      .optional(),
    lookup_entity_attribute: z.string().optional(),
  })
  .merge(
    AttributeSchema.pick({
      data_type: true,
      description: true,
      pick_list_values: true,
      is_required: true,
    })
  );

export const TemplatedReportSchema = z
  .object({
    name: z.string(),
    display_name: z.string(),
    status: z.enum([
      TemplatedReportStatus.ACTIVE,
      TemplatedReportStatus.ARCHIVED,
    ]),
    input_schema: z.record(z.string(), TemplatedReportInputSchema),
  })
  .merge(DomainModelMetaSchema);

export type TemplatedReportInputType = z.infer<
  typeof TemplatedReportInputSchema
>;
export type TemplatedReportType = z.infer<typeof TemplatedReportSchema>;
export type TemplatedReportFilterType = Partial<TemplatedReportType>;
