import { DEFAULT_QUERY_OPTIONS } from "#hooks/adapters/adapterUtils";
import { useAuthenticatedContext } from "#src/contexts/AuthenticatedContext.helpers";
import { downloadFile } from "#utils/download";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useAlert } from "@validereinc/common-components";
import { DatasetAdapter, GetOneRequestType } from "@validereinc/domain";

export const useListDatasets = () => {
  const { claims } = useAuthenticatedContext();

  const datasetParams = {
    filters: {
      company_id: claims.company?.id ?? "",
    },
  };

  return useQuery({
    queryKey: ["dataset", "list", datasetParams],
    queryFn: () => DatasetAdapter.getList(datasetParams),
    ...DEFAULT_QUERY_OPTIONS,
    enabled: Boolean(claims.company?.id),
  });
};

export const useGetOneDataset = (apiParams: GetOneRequestType) => {
  const { claims } = useAuthenticatedContext();

  const params = {
    id: apiParams.id,
    meta: { company_id: claims.company?.id },
  };

  return useQuery({
    queryKey: ["dataset", "getOne", params],
    queryFn: () => DatasetAdapter.getOne(params),
    enabled: !!params.id && !!params.meta.company_id,
    ...DEFAULT_QUERY_OPTIONS,
  });
};

export const useDownloadTemplate = () => {
  const { addAlert } = useAlert();
  const { claims } = useAuthenticatedContext();

  return useMutation({
    mutationFn: async ({
      datasetId,
      fileName,
    }: {
      datasetId: string;
      fileName: string;
    }) => {
      const data = await DatasetAdapter.downloadTemplate({
        id: datasetId,
        meta: { company_id: claims.company?.id },
      });
      downloadFile(fileName, data);
    },
    onSuccess: () => {
      addAlert?.({
        variant: "success",
        message: "Successfully downloaded template",
      });
    },
    onError: (err) => {
      addAlert?.({
        variant: "error",
        message: "Could not download template",
      });
      console.error(err);
    },
  });
};
