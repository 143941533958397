import React from "react";

import { Button, Panel } from "@validereinc/common-components";

import { EventAlertsTable } from "#src/batteries-included-components/Tables/EventAlertsTable";

export const EventAlertsTablePanel = () => {
  return (
    <Panel
      title="Alerts"
      actionRow={
        <Button
          icon="plus-circle"
          variant="secondary"
        >
          Add Alert
        </Button>
      }
      isFluidY={false}
    >
      <EventAlertsTable />
    </Panel>
  );
};
