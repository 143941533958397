import { SummaryInformationContainer } from "#common/SummaryInformation";
import SummaryInformation from "#common/SummaryInformation/SummaryInformation";
import config from "#config";
import { getTimeStringFromDate } from "#utils/timeFormatter";
import { Panel } from "@validereinc/common-components";
import sortBy from "lodash/sortBy";
import * as PropTypes from "prop-types";
import React from "react";

const MappingOverviewPanel = ({ dataMappingDetail, taskList, isLoading }) => {
  const { mapping_entity, mapping_strategy, created_by } = dataMappingDetail;

  const latestTaskDate =
    sortBy(taskList, "upload_date", "desc")?.pop()?.upload_date ?? undefined;

  const shareProps = {
    type: "vertical",
    isLoading: isLoading,
  };

  return (
    <Panel title="Mapping Overview">
      <SummaryInformationContainer>
        <SummaryInformation
          {...shareProps}
          title="Uploads"
          value={taskList?.length ?? "-"}
        />
        <SummaryInformation
          {...shareProps}
          title="Mapping Categories"
          value={mapping_entity ?? "-"}
        />
        <SummaryInformation
          {...shareProps}
          title="Update Strategy"
          value={mapping_strategy ?? "-"}
        />
        <SummaryInformation
          {...shareProps}
          title="Last Data Upload"
          value={
            latestTaskDate
              ? getTimeStringFromDate(latestTaskDate, config.DATE_FORMAT)
              : "-"
          }
        />
        <SummaryInformation
          {...shareProps}
          title="Created By"
          value={created_by ?? "-"}
        />
      </SummaryInformationContainer>
    </Panel>
  );
};

MappingOverviewPanel.propTypes = {
  dataMappingDetail: PropTypes.object.isRequired,
  taskList: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default MappingOverviewPanel;
