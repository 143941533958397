import React from "react";
import config from "#src/config";
import { getTimeStringFromDate } from "#utils/timeFormatter";
import { statusRenderer } from "#common/Table/renderers";

export const getStatusTypeFromSampleState = (state) => {
  switch (state) {
    case "validated":
      return "success";
    case "pending":
      return "warning";
    case "invalidated":
      return "error";
    default:
      return "info";
  }
};

export const sampleStateRenderer = (sample) => {
  if (sample) {
    return statusRenderer("sample")(sample, "state");
  }
  return "-";
};

export const sortBySampleState = (tests) => {
  return [...tests].sort((a, b) => {
    const aValue = a?.sample?.state ?? "-";
    const bValue = b?.sample?.state ?? "-";
    return aValue.localeCompare(bValue);
  });
};

export const sampleApplicablePeriodRenderer = (sample) => {
  const from = getTimeStringFromDate(
    sample.applicable_from,
    config.DATE_FORMAT
  );
  const until = getTimeStringFromDate(
    sample.applicable_until,
    config.DATE_FORMAT
  );

  return sample.applicable_from ? (
    <div className="sampleAnalysis__applicablePeriod">
      <div>{`From: ${from}`}</div>
      <div>{`Until: ${until}`}</div>
    </div>
  ) : (
    "-"
  );
};
