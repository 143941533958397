export const ALERT_THRESHOLD_TOOLTIP =
  "The value that the aggregated measurement is compared against";
export const ALERT_WINDOW_TOOLTIP =
  "The time range over which the aggregation function is applied, looking back from the current measurement time";

export const ALERT_DURATION_TOOLTIP =
  "The amount of time the condition must continuously be true before an alert is generated.";

export const ALERT_CONDITION_TOOLTIP =
  "This condition will be evaluated whenever a new measurement on the selected device is received";
