export * from "./CreateUserPage";

export const CREATE_USER_PAGE_TITLE = "Create User";

export const linkToCreateUserPage = () => "/app/settings/users/create";

export const CREATE_USER_BREADCRUMB = {
  title: CREATE_USER_PAGE_TITLE,
  path: linkToCreateUserPage,
};
