export const EQUIPMENT_DETAIL_FALLBACK_PAGE_TITLE =
  "Equipment Detail:equipmentId";

export const linkToEquipmentDetail = (equipmentId = ":equipmentId") =>
  `/app/organization/equipment/${equipmentId}/detail`;

export const EQUIPMENT_DETAIL_BREADCRUMB = {
  title: EQUIPMENT_DETAIL_FALLBACK_PAGE_TITLE,
  path: linkToEquipmentDetail,
};
