import DateRangeSelector from "#common/DateSelector/DateRangeSelector";
import ReactLoader from "#components/LoadingBar/ReactLoader";
import { fetchAdjustmentFactors } from "#redux/actions/adjustmentFactors";
import { ensureStreamListIsFetched } from "#redux/actions/index";
import { havePermission } from "#redux/reducers/permissions";
import RecordOfVolumeService from "#services/RecordOfVolumeService";
import { Panel, Title } from "@validereinc/common-components";
import * as PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import InventoryDailyVolumeTable from "../../Records/Volume/DailyVolume/InventoryDailyVolumeTable";
import VolumeEntryModal from "../../Records/Volume/VolumeEntry/VolumeEntryModal";
import InventoryChart from "../InventoryChart/InventoryChart";
import "./VolumeOverview.scss";

const DAILY_VOLUME_TABLE_HEIGHT = 540;

const mapStateToProps = ({ permissions, streams, adjustmentFactors }) => {
  return {
    haveWritePermission: havePermission(permissions)(
      "gas:accounting.records_of_volume",
      "write"
    ),
    streams: streams?.data.toJS() ?? [],
    adjustmentFactors: adjustmentFactors?.data.toJS() ?? [],
  };
};

const mapDispatchToProps = {
  ensureStreamListIsFetched,
  fetchAdjustmentFactors,
};

const useFetchDailyVolume = (inventoryDetail, from, to) => {
  const [volumes, setVolumes] = useState([]);
  const [loadingState, setLoadingState] = useState("loading");
  const [lastUpdatedAt, setLastUpdatedAt] = useState(new Date());

  useEffect(() => {
    if (inventoryDetail?.stream_ids) {
      setLoadingState("loading");

      RecordOfVolumeService.getRecordOfVolumesOfActiveStreams(
        inventoryDetail?.stream_ids,
        from,
        to
      )
        .then(({ data }) => {
          setVolumes(data);
          setLoadingState("loaded");
        })
        .catch(() => {
          setLoadingState("error");
        });
    }
  }, [inventoryDetail, from, to, lastUpdatedAt]);

  const refetchDailyVolume = () => {
    setLastUpdatedAt(new Date());
  };

  return [loadingState, volumes, refetchDailyVolume];
};

const VolumeOverview = ({
  chartData,
  chartDataLoadingState,
  dateRange,
  selectDateAndTime,
  inventoryDetail,
  adjustmentFactors,
  haveWritePermission,
  ensureStreamListIsFetched,
  fetchAdjustmentFactors,
  location,
  productType,
}) => {
  const [modalState, setModalState] = useState("closed");
  const [shouldRefetch, setShouldRefetch] = useState(false);

  const [streamAdjustmentConfigs, setStreamAdjustmentConfigs] = useState([]);

  const [loadingState, streamVolumeData, refetchDailyVolume] =
    useFetchDailyVolume(inventoryDetail, dateRange.from, dateRange.to);

  useEffect(() => {
    ensureStreamListIsFetched();
    fetchAdjustmentFactors();

    RecordOfVolumeService.getStreamVolumeAdjustmentConfigs().then(
      ({ data }) => {
        setStreamAdjustmentConfigs(data);
      }
    );
  }, []);

  const onVolumeEntryModalClose = () => {
    setModalState("closed");

    if (shouldRefetch) {
      refetchDailyVolume();

      setShouldRefetch(false);
    }
  };

  const dateRangeSelector = (
    <div className="volumeOverview__actionRow">
      <DateRangeSelector
        dateRange={dateRange}
        onDateRangeChange={selectDateAndTime}
        allowTimeSelection
        icon
      />
    </div>
  );

  const title = productType
    ? `${productType} Volume Overview`
    : `Volume Overview`;

  return (
    <Row>
      <Col sm={12}>
        <Panel
          title={title}
          className="volumeOverview"
        >
          <InventoryChart
            chartData={chartData}
            chartDataLoadingState={chartDataLoadingState}
            dateRangeSelector={dateRangeSelector}
          />

          <Title type="subheader">Volume Detail</Title>

          <div
            className="volumeOverview__table"
            style={{ height: DAILY_VOLUME_TABLE_HEIGHT }}
          >
            <ReactLoader
              loaded={loadingState !== "loading"}
              position={50}
            >
              <InventoryDailyVolumeTable
                haveWritePermission={haveWritePermission}
                streamId={inventoryDetail?.active_stream_id}
                adjustmentFactors={adjustmentFactors}
                dateRange={dateRange}
                volumes={streamVolumeData}
                height={DAILY_VOLUME_TABLE_HEIGHT}
                setModalState={setModalState}
                location={location}
              />
            </ReactLoader>
          </div>

          {modalState !== "closed" && (
            <VolumeEntryModal
              show
              onHide={onVolumeEntryModalClose}
              streamId={inventoryDetail?.active_stream_id}
              action={modalState}
              setShouldRefetchDailyVolume={setShouldRefetch}
              streamAdjustmentConfigs={streamAdjustmentConfigs}
              isInventoryStream={true}
            />
          )}
        </Panel>
      </Col>
    </Row>
  );
};

VolumeOverview.propTypes = {
  chartData: PropTypes.array.isRequired,
  chartDataLoadingState: PropTypes.string,
  dateRange: PropTypes.object,
  selectDateAndTime: PropTypes.func,
  inventoryDetail: PropTypes.object,
  adjustmentFactors: PropTypes.array,
  haveWritePermission: PropTypes.bool,
  ensureStreamListIsFetched: PropTypes.func,
  fetchAdjustmentFactors: PropTypes.func,
  location: PropTypes.object,
  productType: PropTypes.string,
};

export default connect(mapStateToProps, mapDispatchToProps)(VolumeOverview);
