export const colorPalette = [
  "#03a9f4",
  "#3f51b5",
  "#673ab7",
  "#f44336",
  "#259b24",
  "#8bc34a",
  "#ffc107",
  "#ff9800",
  "#ff5722",
  "#9e9e9e",
  "#607d8b",
];
