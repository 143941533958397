import { useSearchParams } from "#routers/hooks";
import {
  linkToCreateFormSubmission,
  linkToFormSubmissionDetail,
} from "#src/Routers/links";
import { RoutingLink } from "#src/batteries-included-components/RoutingLink";
import { AGGREGATOR, AGGREGATOR_OPTIONS_READABLE } from "#src/constants";
import { useStorageKey } from "#src/hooks/useStorageKey";
import useTableState from "#src/hooks/useTableState";
import { getFrontendTableState } from "#src/utils/frontendTableActions";
import { getUTCTimeString } from "#src/utils/timeFormatter";
import {
  DataTable,
  DataTablePanel,
  DateRangeSelectorInput,
  DropdownInput,
  FilterPanel,
  HeaderType,
  SortingType,
} from "@validereinc/common-components";
import type {
  AssetTypeType,
  FormResponseDataItemType,
  MeasurementTypeType,
  SeriesResponseDataItemType,
} from "@validereinc/domain";
import {
  AssetType,
  MeasurementsDomain,
  SortDirection,
} from "@validereinc/domain";
import find from "lodash/find";
import groupBy from "lodash/groupBy";
import sortBy from "lodash/sortBy";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import EntityAnalysisPanel from "./EntityAnalysisPanel";

const sorting: SortingType = {
  sortBy: "time",
  sortDirection: SortDirection.ASCENDING,
};
const FIRST_DAY_OF_CURRENT_MONTH = moment().startOf("month").startOf("day");

const DEFAULT_DATE_RANGE = {
  from: new Date(FIRST_DAY_OF_CURRENT_MONTH),
  to: new Date(),
};

const DEFAULT_SEARCH_PARAMS = {
  ...DEFAULT_DATE_RANGE,
  interval: "1day",
  func: "avg",
};

export type DataForGraphType = Array<{
  axis: string;
  label: string;
  unit: string;
  data: SeriesResponseDataItemType[] | FormResponseDataItemType[];
  variant: string;
  isGradient: boolean;
}>;

type EntityMeasurementsTabPropsType = {
  entityId: string;
  entityType: AssetTypeType;
};

const generateFormDetailName = (formItem: FormResponseDataItemType) => {
  const formSchemaName: string = formItem?.form_schema?.name ?? "";
  const formSubmissionId: string =
    formItem?.form_submission_id?.substring(0, 7) ?? "";

  return `${formSchemaName} - ${formSubmissionId}`;
};

export const EntityMeasurementsTab = ({
  entityId,
  entityType,
}: EntityMeasurementsTabPropsType) => {
  const { tableConfigStorageKey } = useStorageKey(
    `${entityType}-detail-measurements`
  );

  const [graphData, setGraphData] = useState<DataForGraphType>([]);
  const [measurementTypes, setMeasurementTypes] = useState<
    MeasurementTypeType[]
  >([]);
  const [isMeasurementTypesLoading, setIsMeasurementTypesLoading] =
    useState(false);
  const [searchParams, setSearchParam] = useSearchParams();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setSearchParam({ ...DEFAULT_SEARCH_PARAMS, ...searchParams });
  }, []);

  useEffect(() => {
    /** FE-1892: Default to the first measurement type if none are selected */
    if (measurementTypes.length && !searchParams.measurement_left) {
      setSearchParam({
        measurement_left: measurementTypes[0].id,
        ...searchParams,
      });
    }
  }, [searchParams, measurementTypes]);

  useEffect(() => {
    const fetchMeasurementTypes = async () => {
      setIsMeasurementTypesLoading(true);
      const response = await MeasurementsDomain.getMeasurementTypesBySubject({
        subjectId: entityId,
        subjectType: entityType,
      });
      setIsMeasurementTypesLoading(false);
      setMeasurementTypes(response);
    };
    fetchMeasurementTypes();
  }, []);

  const onChange = (newValue: Record<string, any>) => {
    const {
      date_range: { from, to },
      ...restFilters
    } = newValue;
    setSearchParam({ ...restFilters, from, to });
  };

  const selectedMeasurement = useMemo(
    (): MeasurementTypeType | undefined =>
      find(measurementTypes, {
        id: searchParams?.measurement_left,
      }),
    [measurementTypes, searchParams?.measurement_left]
  );

  const tableHeaders: Array<HeaderType<FormResponseDataItemType>> = [
    {
      label: "Measurement",
      key: "measure_value",
      isSortable: true,
      renderComponent: ({ item }) => (
        <DataTable.DataRow.NumberCell value={item.measure_value} />
      ),
    },
    {
      label: "Measurement Type",
      key: "measurement_type",
      isSortable: true,
      renderComponent: () =>
        find(measurementTypes, {
          id: searchParams.measurement_left,
        })?.name ?? "-",
    },
    {
      label: "Source Type",
      key: "source_type",
      renderComponent: ({ item }) =>
        item?.form_schema?.name ? "Form" : "Device",
      isSortable: true,
    },
    {
      label: "Form Template",
      key: "form_schema",
      renderComponent: ({ item }) => (
        <RoutingLink to={linkToCreateFormSubmission(item?.form_schema?.id)}>
          {item?.form_schema?.name}
        </RoutingLink>
      ),
    },
    {
      label: "Form Name",
      key: "form_submission",
      renderComponent: ({ item }) => (
        <RoutingLink to={linkToFormSubmissionDetail(item?.form_submission_id)}>
          {generateFormDetailName(item)}
        </RoutingLink>
      ),
    },
    {
      label: "Date",
      key: "time",
      renderComponent: ({ item }) => (
        <DataTable.DataRow.DateCell
          value={item.time}
          withTime
        />
      ),
      isSortable: true,
    },
  ];
  const fetchTableData = useCallback(
    async (newSearchParams) => {
      setIsLoading(true);

      const {
        tab: _tab,
        from,
        to,
        measurement_left,
        ...restParams
      } = newSearchParams;

      if (!entityId || !measurement_left) {
        setIsLoading(false);
        return null;
      }

      const entityInformation = {
        ...(entityType === AssetType.DEVICE
          ? { deviceId: entityId }
          : { subjectId: entityId, subjectType: entityType }),
      };

      const response =
        await MeasurementsDomain.getMeasurementPlotByMeasurementType({
          measurementType: measurement_left,
          ...entityInformation,
          start: getUTCTimeString(from),
          end: getUTCTimeString(to),
          includeForms: true,
          ...restParams,
        });

      const searchParamsWithDefaults: Record<string, any> = {
        ...newSearchParams,
        sort: newSearchParams.sort ?? sorting.sortBy,
        sortDirection: newSearchParams.sortDirection ?? sorting.sortDirection,
      };

      const formData = Object.values(
        groupBy(response?.form.data, "form_schema.id")
      );

      const formDataForGraph = formData?.map((formDataBySchemaId) => ({
        axis: "left",
        label: formDataBySchemaId[0]?.form_schema?.name,
        itemSpecificTooltipLabel: (item: FormResponseDataItemType) =>
          generateFormDetailName(item),
        unit: "", //backend doesnt return unit yet
        data: formDataBySchemaId,
        variant: "scatter-plot",
        isGradient: false,
      }));
      const seriesDataForGraph =
        response?.series?.map((singleSeriesData) => ({
          axis: "left",
          label: singleSeriesData?.description?.split("/", 2)?.join(" "),
          unit: "", //backend doesnt return unit yet
          data: sortBy(singleSeriesData.data, ["time"]),
          variant: "line",
          isGradient: false,
        })) ?? [];

      const dataForTable: Array<
        SeriesResponseDataItemType | FormResponseDataItemType
      > = [];
      response?.series?.forEach((series) => {
        if (series?.data) {
          series.data.forEach((data) => {
            dataForTable.push(data);
          });
        }
      });
      response?.form?.data?.forEach((item) => {
        dataForTable.push(item);
      });

      const dataForGraph = [...formDataForGraph, ...seriesDataForGraph];
      setGraphData(dataForGraph);
      setIsLoading(false);

      return getFrontendTableState({
        data: { data: dataForTable },
        itemsKey: "data",
        query: searchParamsWithDefaults,
      });
    },
    [entityId, measurementTypes]
  );
  const { tableProps } = useTableState({
    onFetchData: fetchTableData,
    initialSort: sorting,
  });

  const { to, from, ...restSearchParams } = searchParams;
  const filterPanelValues = useMemo(
    () => ({
      ...restSearchParams,
      ...(to && from
        ? { date_range: { to: new Date(to), from: new Date(from) } }
        : { date_range: DEFAULT_DATE_RANGE }),
    }),
    [searchParams]
  );

  return (
    <>
      <FilterPanel
        value={filterPanelValues}
        onChange={onChange}
        filters={[
          {
            component: (
              <DateRangeSelectorInput
                name="date_range"
                allowOneDayRange={true}
                isInline
              />
            ),
          },
          {
            component: (
              <DropdownInput
                name="measurement_left"
                isClearable={false}
                placeholder="Left Y-axis"
                labelKey="name"
                valueKey="id"
                options={
                  entityType !== AssetType.DEVICE
                    ? measurementTypes?.filter(
                        ({ id }) => searchParams?.measurement_right !== id
                      )
                    : [{ name: "emissionrate", id: "emission_rate" }]
                }
                isInline
                isLoading={isMeasurementTypesLoading}
              />
            ),
          },
          // { // TODO: Add this back with supporting functionality
          //   component: (
          //     <DropdownInput
          //       name="measurement_right"
          //       placeholder="Right Y-axis"
          //       labelKey="name"
          //       valueKey="id"
          //       options={measurementTypes?.filter(
          //         ({ id }) => searchParams?.measurement_left !== id
          //       )}
          //       isInline
          //       isLoading={isMeasurementTypesLoading}
          //     />
          //   ),
          // },
          {
            component: (
              <DropdownInput
                name="interval"
                isClearable={false}
                placeholder="Interval"
                labelKey="name"
                valueKey="id"
                options={[
                  { name: "1 min", id: "1minute" },
                  { name: "15 mins", id: "15minute" },
                  { name: "1 hour", id: "1hour" },
                  { name: "1 day", id: "1day" },
                ]}
                isInline
                isSortedAlphabetically={false}
              />
            ),
          },
          {
            component: (
              <DropdownInput
                name="func"
                isClearable={false}
                placeholder="Aggregation Function"
                labelKey="label"
                valueKey="value"
                options={AGGREGATOR_OPTIONS_READABLE.filter((opt) =>
                  [AGGREGATOR.AVERAGE, AGGREGATOR.SUM].includes(opt.value)
                )}
                isInline
                isSortedAlphabetically={true}
              />
            ),
          },
        ]}
      />

      <EntityAnalysisPanel
        data={graphData}
        from={searchParams?.from}
        to={searchParams?.to}
        isLoading={isLoading}
        leftAxisLabel={selectedMeasurement?.name}
      />

      <DataTablePanel
        storageKey={tableConfigStorageKey}
        panelProps={{
          title: "Measurements",
        }}
        dataTableProps={{
          ...tableProps,
          headers: tableHeaders,
          isLoading,
        }}
      />
    </>
  );
};
