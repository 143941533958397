import { useFetchSample } from "#hooks/useSample";
import { ensureStreamListIsFetched } from "#redux/actions/index";
import { havePermission } from "#redux/reducers/permissions";
import { Modal, Tab, Tabs, Title } from "@validereinc/common-components";
import styles from "@validereinc/common-components/constants";
import find from "lodash/find";
import * as PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import { Badge } from "react-bootstrap";
import { connect } from "react-redux";
import SampleAnalysis from "./SampleAnalysis";
import SampleNotes from "./SampleNotes";
import "./SampleValidationModal.scss";

const TAB_KEYS = {
  analysis: "analysis",
  notes: "notes",
};

const NotesTitle = ({ noteCount }) => (
  <div>
    <span>Notes</span>{" "}
    <Badge
      style={{
        backgroundColor: styles.validereColour,
        opacity: noteCount ? 1 : 0,
      }}
    >
      {noteCount}
    </Badge>
  </div>
);

const mapStateToProps = ({ streams, permissions }) => ({
  streams: streams.data?.toJS() ?? [],
  haveWritePermission: havePermission(permissions)("360:field_labs", "write"),
});

const mapDispatchToProps = {
  ensureStreamListIsFetched,
};

const SampleValidationModal = ({
  selectedSampleId,
  onModalToggle,
  updateSampleTableList,
  haveWritePermission,
  streams,
  ensureStreamListIsFetched,
}) => {
  const [fetchedSample, selectedSampleLoadingState, refetchSample] =
    useFetchSample(selectedSampleId);

  const [activeTab, setActiveTab] = useState(TAB_KEYS.analysis);

  const selectedSample = useMemo(() => {
    if (fetchedSample?.id && streams?.length) {
      const selectedSampleWithStreamDetail = {
        ...fetchedSample,
        stream: find(streams, { id: fetchedSample.stream_id }),
      };

      return selectedSampleWithStreamDetail;
    } else {
      return null;
    }
  }, [fetchedSample, streams]);

  useEffect(() => {
    if (!streams?.length) {
      ensureStreamListIsFetched();
    }
  }, [streams]);

  const updatedSelectedSample = () => {
    refetchSample();

    if (updateSampleTableList) {
      updateSampleTableList();
    }
  };

  const onChangeTab = (newTab) => {
    setActiveTab(newTab);
  };

  return (
    <Modal
      size="large"
      className="sampleValidationModal"
      open={selectedSample !== null}
      onClose={() => onModalToggle(null)}
    >
      <Modal.Header>
        <Title>{selectedSample?.stream?.name ?? ""} Sample</Title>
      </Modal.Header>

      <div className="sampleValidationModal__content">
        <Tabs
          activeKey={activeTab}
          onChange={onChangeTab}
        >
          <Tab
            tabKey={TAB_KEYS.analysis}
            title="Analysis"
          >
            <SampleAnalysis
              selectedSample={selectedSample}
              isSelectedSampleLoading={selectedSampleLoadingState !== "loaded"}
              updatedSelectedSample={updatedSelectedSample}
              haveWritePermission={haveWritePermission}
              refreshSample={updatedSelectedSample}
              onClose={() => onModalToggle(null)}
            />
          </Tab>
          <Tab
            tabKey={TAB_KEYS.notes}
            title="Notes"
          >
            <SampleNotes
              selectedSample={selectedSample}
              refreshSample={updatedSelectedSample}
              onClose={() => onModalToggle(null)}
            />
          </Tab>
        </Tabs>
      </div>
    </Modal>
  );
};

NotesTitle.propTypes = {
  noteCount: PropTypes.number,
};

SampleValidationModal.propTypes = {
  streams: PropTypes.array.isRequired,
  ensureStreamListIsFetched: PropTypes.func.isRequired,
  selectedSampleId: PropTypes.string,
  haveWritePermission: PropTypes.bool.isRequired,
  onModalToggle: PropTypes.func.isRequired,
  updateSampleTableList: PropTypes.func,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SampleValidationModal);
