import Immutable from "immutable";

import { INSTRUMENT_TYPES } from "../../../utils/enums";

import {
  FETCH_INSTRUMENT_LIST,
  FETCH_INSTRUMENT_LIST_SUCCESS,
  FETCH_INSTRUMENT_LIST_ERROR,
  USER_SIGNED_OUT,
} from "../constants/action-types";

export const filterFieldInstruments = (instrument) =>
  instrument.instrument_type === INSTRUMENT_TYPES.FIELD;
export const sortInstrumentByName = (instrumentA, instrumentB) =>
  instrumentA.name.localeCompare(instrumentB.name);

const instrument = Immutable.Record({
  id: undefined,
  name: undefined,
  type: undefined,
  instrument_type: undefined,
  status: undefined,
  online: undefined,
  location: undefined,
  prev_calibration_date: undefined,
  next_calibration_date: undefined,
  prev_calibration_status: undefined,
  prev_calibration_staff: undefined,
  prev_standard_check_date: undefined,
  next_standard_check_date: undefined,
  prev_standard_check_status: undefined,
  prev_standard_check_staff: undefined,
  site_id: undefined,
  valid_measurement_types: undefined,
});

export const data = Immutable.Set.of([]);

export const InstrumentState = Immutable.Record(
  {
    isRequestInProgress: false,
    fetchError: false,
    fetchErrorMessage: "",
    fetchErrorStatus: "",
    data,
  },
  "InstrumentState"
);

export default (state = new InstrumentState(), action) => {
  let newData = [];
  switch (action.type) {
    case FETCH_INSTRUMENT_LIST:
      return state.withMutations((s) => {
        s.isRequestInProgress = true;
        s.fetchError = false;
        s.fetchErrorMessage = "";
        s.fetchErrorStatus = "";
      });

    case FETCH_INSTRUMENT_LIST_SUCCESS:
      newData = Immutable.Set.of(
        ...action.payload.map(
          (instrumentData) => new instrument(instrumentData)
        )
      );

      return state.withMutations((s) => {
        s.isRequestInProgress = false;
        s.data = newData;
      });

    case FETCH_INSTRUMENT_LIST_ERROR:
      return state.withMutations((s) => {
        s.isRequestInProgress = false;
        s.fetchError = true;
        s.fetchErrorMessage = action.payload.message;
        s.fetchErrorStatus = action.payload.status;
      });

    case USER_SIGNED_OUT:
      return state.withMutations((s) => {
        s.data = Immutable.Set.of([]);
        s.isRequestInProgress = false;
        s.fetchError = false;
        s.fetchErrorMessage = "";
        s.fetchErrorStatus = "";
      });

    default:
      return state;
  }
};
