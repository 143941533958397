import React from "react";
import * as PropTypes from "prop-types";
import config from "../../../config";
import InstrumentDetail from "../InstrumentDetail";
import { getMeasurementType } from "../../Redux/reducers/measurements";
import { connect } from "react-redux";
import { notesColumn } from "../Shared/TestsDatatable/NotesColumn";
import { getMeasurementValue } from "../InstrumentHelper";
import {
  sampleStateRenderer,
  sortBySampleState,
} from "../../Samples/SampleHelper";
import { getTimeStringFromDate } from "#utils/timeFormatter";
import TestDetailModal from "#components/Tests/TestDetailModal/TestDetailModal";
import MeasurementStatus from "../Shared/TestsDatatable/MeasurementStatus";
import { getAlertSpec } from "../InstrumentComponentHelper";

const DEFAULT_ALERT_SPEC = {
  upperLimit: 103,
  lowerLimit: 0,
};

const measurementRenderer = (measurementType) =>
  function measurementRendererInner(rowData, columnKey) {
    const defaultAlertSpec = {
      measurement_unit: measurementType.unit,
      upper_limit: DEFAULT_ALERT_SPEC.upperLimit,
      lower_limit: DEFAULT_ALERT_SPEC.lowerLimit,
    };

    try {
      const value = getMeasurementValue(
        rowData.measurements[columnKey].value,
        measurementType.decimals
      );
      const unit = rowData.measurements[columnKey].unit;

      return (
        <MeasurementStatus
          value={value}
          unit={unit}
          status={rowData.status}
          labels={rowData.labels}
          defaultAlertSpec={defaultAlertSpec}
          alertSpec={getAlertSpec(
            rowData.measurement_alert_specs,
            measurementType.name,
            defaultAlertSpec
          )}
          measurementType={measurementType}
        />
      );
    } catch {
      return <div>-</div>;
    }
  };

const dateRenderer = (rowData, columnKey) => {
  return (
    <div>
      {getTimeStringFromDate(rowData[columnKey], config.DATETIME_FORMAT)}
    </div>
  );
};

const headers = (measurementType) => [
  {
    label: "Name",
    key: "name",
    width: 300,
    fixed: true,
  },
  {
    label: "Sample State",
    key: "sample_state",
    width: 150,
    cellRenderer: (row) => sampleStateRenderer(row.sample),
    sort: sortBySampleState,
  },
  {
    label: "Date",
    key: "date",
    width: 170,
    cellRenderer: dateRenderer,
  },
  {
    label: "Tester",
    key: "tested_by",
    width: 150,
    suppress: true,
  },
  {
    label: measurementType.display_name,
    key: measurementType.name,
    width: 250,
    cellRenderer: measurementRenderer(measurementType),
    sort: (list, measurementName) => {
      return [...list].sort((a, b) => {
        return (
          a.measurements[measurementName].value -
          b.measurements[measurementName].value
        );
      });
    },
  },
  notesColumn,
];

const mapStateToProps = (state) => {
  return {
    measurementType: getMeasurementType(state.measurements)("Vapour Pressure"),
  };
};

const InstrumentGrabnerMiniVapTests = (props) => {
  return (
    <InstrumentDetail
      testId={props.match.params.testId}
      instrumentId={props.match.params.instrumentId}
      height={props.height}
      instrumentInfo={props.instrumentInfo}
      ReportModalComponent={TestDetailModal}
      filterKey="name"
      headers={headers(props.measurementType)}
      defaultSortBy="date"
      defaultSortDirection="desc"
    />
  );
};

InstrumentGrabnerMiniVapTests.propTypes = {
  instrumentInfo: PropTypes.object.isRequired,
  measurementType: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  height: PropTypes.number.isRequired,
};

export default connect(mapStateToProps, null)(InstrumentGrabnerMiniVapTests);
