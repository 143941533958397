import { z } from "zod";
import { CustomAttributeRecordSchema } from "./CustomAttributeSchemas";
import {
  DomainModelMetaExtendedSchema,
  DomainModelMetaSchema,
  DomainModelSchema,
} from "./DomainModelSchemas";
import { FacilitySchema } from "./FacilitySchemas";

export const EquipmentStatus = {
  ACTIVE: "active",
  INACTIVE: "inactive",
  DEPRECATED: "deprecated",
} as const;

export const EquipmentTypeSchema = z.object({
  id: z.string(),
  name: z.string(),
});

export const EquipmentOperatingConditionSchema = z.intersection(
  z.object({
    year_month: z.string(),
  }),
  z.record(z.number())
);

export const EquipmentSchema = z
  .object({
    name: z.string(),
    status: z.enum([
      EquipmentStatus.ACTIVE,
      EquipmentStatus.INACTIVE,
      EquipmentStatus.DEPRECATED,
    ]),
    latitude: z.number().lte(90).gte(-90),
    longitude: z.number().lte(180).gte(-180),
    effective_date: z.date(),
    facility_id: z.string().uuid(),
    facility: FacilitySchema,
    type_id: z.string(),
    type: EquipmentTypeSchema,
    custom_attributes: CustomAttributeRecordSchema,
  })
  .merge(DomainModelSchema)
  .merge(DomainModelMetaSchema)
  .merge(DomainModelMetaExtendedSchema)
  .describe("Schema for Equipment asset type");

export type EquipmentStatusType =
  (typeof EquipmentStatus)[keyof typeof EquipmentStatus];
export type EquipmentType = z.infer<typeof EquipmentSchema>;
export type EquipmentTypeType = z.infer<typeof EquipmentTypeSchema>;
