export * from "./AddEquipmentPage";

export const ADD_EQUIPMENT_PAGE_TITLE = "Add Equipment";

export const linkToAddEquipmentToEvent = (
  eventCategoryId = ":eventCategoryId",
  eventTemplateId = ":eventTemplateId",
  eventId = ":eventId"
) => {
  return `/app/events/event-categories/${eventCategoryId}/templates/${eventTemplateId}/events/${eventId}/add-equipment`;
};

export const ADD_EQUIPMENT_BREADCRUMB = {
  title: ADD_EQUIPMENT_PAGE_TITLE,
  path: linkToAddEquipmentToEvent,
};
