import { RoutingLink } from "#src/batteries-included-components/RoutingLink";
import { useTableSortingAndPagination } from "#src/components/Redux/reducers/tableStateReducer";
import { linkToRoleDetailPage } from "#src/routes/settings/roles-and-permissions/roles/[roleId]/detail";
import { useQueries, useQuery } from "@tanstack/react-query";
import {
  DataTable,
  DataTablePanel,
  HeaderType,
  PillProps,
  SortingType,
  StorageKeys,
  useFilters,
} from "@validereinc/common-components";
import { RoleSchema, RoleType, RolesAdapter } from "@validereinc/domain";
import React, { useMemo } from "react";

export const RoleListTablePanel = ({
  filterConfigStorageKey,
  tableConfigStorageKey,
}: StorageKeys) => {
  const [filters] = useFilters(filterConfigStorageKey);
  const sorting: SortingType = {
    sortBy: "name",
    sortDirection: "desc",
  };
  const [tableState, updateTableState] = useTableSortingAndPagination(
    sorting,
    filters
  );
  const queryPayload = {
    page: tableState.page,
    pageSize: tableState.itemsPerPage,
    sortBy: tableState.sortBy,
    sortDirection: tableState.sortDirection,
    filters,
  } satisfies Parameters<typeof RolesAdapter.getList>[0];
  const rolesQuery = useQuery(
    ["roles", queryPayload],
    () => RolesAdapter.getList(queryPayload),
    {
      staleTime: 3 * 60 * 1000,
    }
  );
  const rolesMembersQueries = useQueries({
    queries:
      rolesQuery.data?.data.map((role) => ({
        queryKey: ["roles", role.id, "members"],
        queryFn: () => RolesAdapter.members.getMany({ roleId: role.id }),
        staleTime: 3 * 60 * 1000,
      })) ?? [],
  });
  const rolesPermissionsQueries = useQueries({
    queries:
      rolesQuery.data?.data.map((role) => ({
        queryKey: ["roles", role.id, "permissions"],
        queryFn: () => RolesAdapter.permissions.getMany({ id: role.id }),
        staleTime: 3 * 60 * 1000,
      })) ?? [],
  });

  const rolesMembersCounts = useMemo(() => {
    return rolesMembersQueries.reduce<Record<string, number>>((map, query) => {
      if (!query.data?.length) {
        return map;
      }

      map[query.data[0].group_id] = query.data?.length ?? 0;
      return map;
    }, {});
  }, [rolesMembersQueries]);
  const groupsPermissionsCounts = useMemo(() => {
    return rolesPermissionsQueries.reduce<Record<string, number>>(
      (map, query) => {
        if (!query.data?.length) {
          return map;
        }

        map[query.data[0].group_id] = query.data?.length ?? 0;
        return map;
      },
      {}
    );
  }, [rolesPermissionsQueries]);

  const headers: Array<HeaderType<RoleType>> = [
    {
      label: "Name",
      key: RoleSchema.keyof().Enum.name,
      isSortable: true,
      renderComponent: ({ item }) => {
        const roleId = item?.id;

        return roleId ? (
          <RoutingLink to={linkToRoleDetailPage(roleId)}>
            {item.name}
          </RoutingLink>
        ) : (
          "-"
        );
      },
    },
    {
      label: "Status",
      isSortable: true,
      key: RoleSchema.keyof().Enum.status,
      renderComponent: ({ item }) => (
        <DataTable.DataRow.PillCell
          variant={
            (
              {
                active: "success",
                disabled: "default",
              } satisfies Record<RoleType["status"], PillProps["variant"]>
            )[item.status]
          }
          value={item.status}
        />
      ),
    },
    {
      label: "Description",
      key: RoleSchema.keyof().Enum.description,
    },
    {
      label: "Members",
      key: "users",
      renderComponent: ({ item }) => {
        return (
          <RoutingLink
            to={{
              pathname: linkToRoleDetailPage(item.id),
              search: "?tab=members",
            }}
          >
            {Object.hasOwn(rolesMembersCounts, item.id)
              ? rolesMembersCounts[item.id]
              : "-"}
          </RoutingLink>
        );
      },
    },
    {
      label: "Permissions",
      key: "permissions",
      renderComponent: ({ item }) => {
        return (
          <RoutingLink
            to={{
              pathname: linkToRoleDetailPage(item.id),
              search: "?tab=permissions",
            }}
          >
            {Object.hasOwn(groupsPermissionsCounts, item.id)
              ? groupsPermissionsCounts[item.id]
              : "-"}
          </RoutingLink>
        );
      },
    },
    {
      label: "Created At",
      key: RoleSchema.keyof().Enum.created_at,
      isSortable: true,
      renderComponent: ({ item }) => (
        <DataTable.DataRow.DateCell
          value={item.created_at}
          withTime
        />
      ),
    },
  ];

  return (
    <DataTablePanel
      storageKey={tableConfigStorageKey}
      dataTableProps={{
        headers,
        items: rolesQuery.data?.data ?? [],
        isLoading:
          rolesQuery.isLoading ||
          rolesMembersQueries.some((q) => q.isLoading) ||
          rolesPermissionsQueries.some((q) => q.isLoading),
        sorting,
        pagination: {
          page: tableState.page,
          itemsPerPage: tableState.itemsPerPage,
          total: rolesQuery.data?.total_entries ?? 0,
          entityPerPage: "Roles per page:",
        },
        onSortChange: updateTableState,
        onPaginationChange: updateTableState,
      }}
      panelProps={{ title: "Roles" }}
    />
  );
};
