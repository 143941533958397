import { Button, Modal, Title } from "@validereinc/common-components";
import * as PropTypes from "prop-types";
import React from "react";
import { useSelectionModalContext } from "./Context/selectionModalContext";
import "./ResetSelectionModal.scss";

const ResetSelectionModal = (props) => {
  const { show, onModalClose } = props;

  const { resetSelection, actionState, setActionState } =
    useSelectionModalContext();

  const onResetButtonClick = () => {
    setActionState("disabled");

    resetSelection().finally(() => {
      onModalClose();
      setActionState("enabled");
    });
  };

  return (
    <Modal
      className="resetSelectionModal datatable__row"
      open={show}
      onClose={onModalClose}
    >
      <Modal.Header>
        <Title>Reset Record of Quality</Title>
      </Modal.Header>

      <Modal.Body>
        Do you wish to reset this record of quality?
        <div className="resetSelectionModal__notes">
          **Note: The Record of Quality will <b>reset</b> to its initial
          selection.**
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button
          className="pull-left"
          disabled={actionState === "disabled"}
          onClick={onModalClose}
        >
          Cancel
        </Button>

        <Button
          variant="primary"
          isLoading={actionState === "disabled"}
          onClick={onResetButtonClick}
        >
          Reset
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

ResetSelectionModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onModalClose: PropTypes.func.isRequired,
};

export default ResetSelectionModal;
