export const copyToClipboard = (text) => {
  if (navigator.clipboard != undefined) {
    navigator.clipboard.writeText(text);
  }

  if (window.clipboardData !== undefined) {
    window.clipboardData.setData("Text", text);
  }

  return null;
};
