export const getCorrespondingMeasurementSeries = (
  alertConfig,
  measurementSeries
) => {
  if (alertConfig?.conditions?.length > 1) {
    return "Many";
  } else if (alertConfig?.conditions?.length === 1) {
    return measurementSeries.find(
      (measurementSeriesDetail) =>
        alertConfig.conditions[0].measurement_series_id ===
        measurementSeriesDetail?.id
    );
  }
  return null;
};
