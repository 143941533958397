import FormTable from "#common/Forms/FormTable";
import { FLOW_QUERY_KEY } from "#hooks/adapters/useFlows";
import { useFormCategories } from "#hooks/useForms";
import { useNavigate, useParams, useSearchParams } from "#routers/hooks";
import { useFlowDetailFlow } from "#routes/organization/flows/[flowId]/detail/FlowDetailPage.helpers";
import EditFlowDialog from "#src/batteries-included-components/Dialogs/EditFlowDialog";
import { PageErrorContent } from "#src/batteries-included-components/Layouts/Errors/PageError";
import { useIsFeatureAvailable } from "#src/contexts/AuthenticatedContext.helpers";
import { DEFAULT_DATE_RANGES } from "#src/hooks/useDateRange";
import useLocalization from "#src/hooks/useLocalization";
import { useStorageKey } from "#src/hooks/useStorageKey";
import { hydrateDateRange } from "#src/utils/date";
import { useBreadcrumbsFromRoute } from "#utils/route";
import { useQueryClient } from "@tanstack/react-query";
import {
  Button,
  Dialog,
  Page,
  Tab,
  useAlert,
  useFilters,
} from "@validereinc/common-components";
import { FlowDomain } from "@validereinc/domain";
import { getYearMonthFromDateRange } from "@validereinc/utilities";
import React, { useEffect, useState } from "react";
import { FlowListRoute } from "../../index";
import { ChangeLogTab } from "./ChangeLogTab";
import { FlowEstimationMethodsTab } from "./FlowEstimationMethodsTab";
import { FlowOverviewTab } from "./FlowOverviewTab";
import { FlowRecordsTab } from "./FlowRecordsTab";
import { FlowDetailRoute } from "./index";

const DEFAULT_TAB_KEY = "overview";

export const FlowDetailPageContent = () => {
  const navigate = useNavigate();
  const [isChangeLogAvailable] = useIsFeatureAvailable({
    featureFlagQuery: "core:activity_logs",
    permissionQuery: "activities:read",
  });
  const { localize } = useLocalization();
  const { flowId } = useParams<{ flowId: string }>();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isDeletingFlow, setIsDeletingFlow] = useState(false);
  const { addAlert } = useAlert();
  const [assetIdToEdit, setAssetIdToEdit] = useState<string | undefined>();
  const { filterConfigStorageKey } = useStorageKey("create-flow-filters");
  const {
    data: flow,
    isLoading,
    error,
  } = useFlowDetailFlow({
    filterConfigStorageKey,
  });
  const [formCategories] = useFormCategories({
    relatedEntityId: flowId,
    relatedEntityType: "flow",
  });

  const [breadcrumbs] = useBreadcrumbsFromRoute(FlowDetailRoute, {
    detail: { title: flow?.name },
  });

  const [filters] = useFilters<{
    period: { from: string; to: string };
  }>(filterConfigStorageKey);

  const activePeriod: string =
    getYearMonthFromDateRange(
      filters?.period
        ? hydrateDateRange(filters.period)
        : DEFAULT_DATE_RANGES.currentWholeMonth
    ) ?? "";

  const queryClient = useQueryClient();

  const onActiveTabKeyChange = (tabKey: string) => {
    setSearchParams({ tab: tabKey });
  };

  const onDeleteFlow = async () => {
    setIsDeletingFlow(true);

    if (flow?.id) {
      try {
        await FlowDomain.deleteFlow({ flowId: flow?.id });

        queryClient.invalidateQueries([FLOW_QUERY_KEY]);

        navigate({ pathname: FlowListRoute.toLink(), replace: true });

        addAlert({
          variant: "success",
          message: `${flow?.name ?? "Flow"} has been successfully deleted.`,
        });
      } catch (caught) {
        addAlert({
          variant: "error",
          message: `Sorry! Something went wrong and we were unable to delete ${
            flow?.name ?? "this flow"
          }.`,
        });
      }

      setIsDeleteModalOpen(false);

      setIsDeletingFlow(false);
    }
  };

  const onOpenDeleteModal = () => {
    setIsDeleteModalOpen(true);
  };

  useEffect(() => {
    if (!searchParams?.tab) {
      onActiveTabKeyChange("overview");
    }
  }, []);

  return (
    <>
      <Page
        breadcrumbs={breadcrumbs}
        title={flow?.name ?? FlowDetailRoute.title}
        category={localize("Flow")}
        activeTabKey={
          !isChangeLogAvailable && searchParams?.tab === "change-log"
            ? DEFAULT_TAB_KEY
            : searchParams?.tab ?? DEFAULT_TAB_KEY
        }
        onActiveTabKeyChange={onActiveTabKeyChange}
        isLoading={isLoading}
        actionRow={[
          <Button
            key="delete-flow-action"
            variant="error-outline"
            onClick={onOpenDeleteModal}
            disabled={isDeletingFlow}
          >
            Delete
          </Button>,
          <Button
            key="edit-flow-action"
            onClick={() => setAssetIdToEdit(flowId)}
          >
            Edit
          </Button>,
        ]}
        error={error}
        onErrorRender={({ error }) => <PageErrorContent error={error} />}
      >
        <Tab
          title="Overview"
          tabKey="overview"
        >
          <FlowOverviewTab filterConfigStorageKey={filterConfigStorageKey} />
        </Tab>

        <Tab
          title="Records"
          tabKey="records"
        >
          <FlowRecordsTab />
        </Tab>

        <Tab
          title="Estimation Methods"
          tabKey="estimation_methods"
        >
          <FlowEstimationMethodsTab
            filterConfigStorageKey={filterConfigStorageKey}
          />
        </Tab>

        <Tab
          tabKey="change-log"
          title="Change Log"
          isDisabled={!isChangeLogAvailable}
          iconVariant={!isChangeLogAvailable ? "lock" : ""}
        >
          {isChangeLogAvailable ? <ChangeLogTab resourceId={flowId} /> : null}
        </Tab>

        {formCategories?.map((formCategory) => (
          <Tab
            key={formCategory?.id}
            tabKey={formCategory?.id}
            title={formCategory.name}
          >
            <FormTable
              formCategoryId={formCategory?.id}
              relatedEntityId={flow?.id}
              relatedEntityType="flow"
            />
          </Tab>
        ))}
      </Page>

      <Dialog
        key="delete Modal"
        title={`Delete ${flow?.name}?`}
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        actionRow={[
          <Button
            key="delete-dialog-action"
            variant="error"
            onClick={onDeleteFlow}
          >
            Delete
          </Button>,
        ]}
      >
        Are you sure you want to delete this flow? This action cannot be undone.
      </Dialog>
      <EditFlowDialog
        flowId={assetIdToEdit}
        onClose={() => setAssetIdToEdit(undefined)}
        defaultPeriod={activePeriod}
      />
    </>
  );
};

export const FlowDetailPage = () => <FlowDetailPageContent />;
