import { OrganizationRoute } from "#src/routes/organization";
import { RoutePath } from "#src/utils/route";

export const EquipmentListRoute = OrganizationRoute.concat(
  new RoutePath({
    path: "/equipment",
    title: "Equipment",
  })
);

/** @deprecated directly declaring and referencing this breadcrumb is unnecessary. Use new RoutePath based pattern and directly generate breadcrumbs using 'useBreadcrumbsFromRoute()' hook */
export const EQUIPMENT_LIST_BREADCRUMB = EquipmentListRoute.toBreadcrumb();

export * from "./EquipmentListPage";
