import moment from "moment";
import filter from "lodash/filter";
import findIndex from "lodash/findIndex";

const lodash = { filter, findIndex };

export function FilterArrayByInput(array, filterBy, input) {
  let filteredArray = array;

  if (input && input !== "") {
    if (filterBy) {
      filteredArray = array.filter((row) =>
        InputIsMatched(input, row[filterBy])
      );
    } else {
      filteredArray = array.filter((row) => InputIsMatched(input, row));
    }
  }

  return filteredArray;
}

export function FilterArrayByDate(array, key, range) {
  let startTime = null;
  let endTime = null;
  let filteredArray = array;

  switch (range) {
    case "today":
      startTime = moment().subtract(1, "day").endOf("day");
      endTime = moment().add(1, "day").startOf("day");
      break;
    case "week":
      startTime = moment().startOf("week");
      endTime = moment().endOf("week");
      break;
    case "month":
      startTime = moment().startOf("month");
      endTime = moment().endOf("month");
      break;
    default:
      break;
  }

  if (startTime && endTime) {
    filteredArray = lodash.filter(filteredArray, function (row) {
      if (
        moment.unix(row[key]).isAfter(startTime, "day") &&
        moment.unix(row[key]).isBefore(endTime, "day")
      ) {
        return row;
      }
    });
  }

  return filteredArray;
}

export function FilterArrayByChecked(array, key, checkedList) {
  let filteredArray = array;

  filteredArray = filteredArray.filter((row) => {
    const rowKey = row[key];
    if (checkedList[rowKey]?.checked) {
      return true;
    }

    return false;
  });

  return filteredArray;
}

export function Filter2DArrayByChecked(
  array,
  levelOneKey,
  levelTwoKey,
  checkedList
) {
  let filteredArray = array;

  filteredArray = filteredArray.filter((row) => {
    const rowArray = row[levelOneKey];
    if (rowArray) {
      for (const row of rowArray) {
        const rowKey = row[levelTwoKey];

        if (checkedList[rowKey]?.checked) {
          return true;
        }
      }
    }

    return false;
  });

  return filteredArray;
}

export function FilterObjectByInput(objects, filterBy, input) {
  const filteredObjects = {};

  if (input && input !== "") {
    Object.keys(objects).forEach((row) => {
      if (InputIsMatched(input, objects[row][filterBy])) {
        filteredObjects[row] = objects[row];
      }
    });

    return filteredObjects;
  }

  return objects;
}

export function InputIsMatched(searchValue, itemValue) {
  if (searchValue && searchValue !== "") {
    const lowerCaseValue = String(itemValue).toLowerCase();
    const lowerCaseSearch = String(searchValue).toLowerCase();

    return lowerCaseValue.includes(lowerCaseSearch);
  } else {
    return true;
  }
}

export function CaseInsensitiveRowFilter(value, filterValues) {
  return filterValues
    .map((filterValue) => filterValue.toLowerCase())
    .includes(value.toLowerCase());
}

export function InputIsChecked(checkedList, keyValue) {
  if (checkedList[keyValue]?.checked) {
    return true;
  }

  return false;
}

export function InputIsWithinDateRange(dateRange, date) {
  let startTime = null;
  let endTime = null;

  switch (dateRange) {
    case "today":
      startTime = moment().subtract(1, "day").endOf("day");
      endTime = moment().add(1, "day").startOf("day");
      break;
    case "week":
      startTime = moment().startOf("week");
      endTime = moment().endOf("week");
      break;
    case "month":
      startTime = moment().startOf("month");
      endTime = moment().endOf("month");
      break;
    default:
      startTime = moment().subtract(10, "year").endOf("day");
      endTime = moment().add(1, "day").startOf("day");
      break;
  }

  return (
    moment(date).isAfter(startTime, "day") &&
    moment(date).isBefore(endTime, "day")
  );
}

export function FilterAllChecked(list) {
  const filteredlist = lodash.filter(list, { checked: true });
  return filteredlist;
}

export function isContainedInArray(array, value, keyValue) {
  return lodash.findIndex(array, { [keyValue]: value }) !== -1;
}

export function isObjectsContainInArray(array, objectKey, objects) {
  if (objects) {
    for (const obj of objects) {
      if (isContainedInArray(array, obj[objectKey], "id")) {
        return true;
      }
    }
  }

  return false;
}
