import {
  EntityDropdownInput,
  BaseEntityDropdownProps,
} from "#batteries-included-components/Dropdowns/EntityDropdownInput";
import useLocalization from "#src/hooks/useLocalization";
import { FacilityDomain } from "@validereinc/domain";
import React from "react";

export const FacilityDropdownInput = ({
  name = "facility.id",
  label,
  ...props
}: BaseEntityDropdownProps) => {
  const { localize } = useLocalization();

  return (
    <EntityDropdownInput
      fetchEntityList={FacilityDomain.getList}
      label={label ?? localize("Facility")}
      name={name}
      sort={{
        sortBy: "name",
        sortDirection: "asc",
      }}
      {...props}
    />
  );
};
