import { AGGREGATOR, AGGREGATOR_OPTIONS_READABLE } from "#src/constants";
import {
  DateRangeSelectorInput,
  DropdownInput,
  FilterPanel,
} from "@validereinc/common-components";
import * as PropTypes from "prop-types";
import React from "react";
import AnalysisPanel from "./AnalysisPanel";
import RecordPanel from "./RecordPanel";

const DeviceDetailMeasurementsTab = ({
  appliedFilters,
  onFiltersChange,
  measurementTypes,
  intervalOptions,
  filteredMeasurementData,
  isDataLoading,
  measurementSources,
}) => {
  const { formatter, showTime } =
    intervalOptions.find(({ id }) => id === appliedFilters.interval) ?? {};

  return (
    <>
      <FilterPanel
        value={appliedFilters}
        onChange={onFiltersChange}
        filters={[
          {
            component: (
              <DateRangeSelectorInput
                name="date_range"
                allowOneDayRange={true}
                allowTimeSelection={true}
                isInline
              />
            ),
          },
          {
            component: (
              <DropdownInput
                name="measurement_left"
                isClearable={false}
                placeholder="Left Y-axis"
                labelKey="name"
                valueKey="id"
                options={measurementTypes.filter(
                  ({ id }) => appliedFilters.measurement_right !== id
                )}
                isInline
              />
            ),
          },
          {
            component: (
              <DropdownInput
                name="measurement_right"
                placeholder="Right Y-axis"
                labelKey="name"
                valueKey="id"
                options={measurementTypes.filter(
                  ({ id }) => appliedFilters.measurement_left !== id
                )}
                isInline
              />
            ),
          },
          {
            component: (
              <DropdownInput
                name="interval"
                isClearable={false}
                placeholder="Interval"
                labelKey="name"
                valueKey="id"
                options={intervalOptions}
                isInline
                isSortedAlphabetically={false}
              />
            ),
          },
          {
            component: (
              <DropdownInput
                name="aggregation_function"
                isClearable={true}
                placeholder="Aggregation Function"
                labelKey="label"
                valueKey="value"
                options={AGGREGATOR_OPTIONS_READABLE.filter((opt) =>
                  [AGGREGATOR.AVERAGE, AGGREGATOR.SUM].includes(opt.value)
                )}
                isInline
                isSortedAlphabetically={true}
              />
            ),
          },
        ]}
      />

      <AnalysisPanel
        data={filteredMeasurementData}
        isLoading={isDataLoading}
        tickFormatter={formatter}
        from={appliedFilters.date_range.from}
        to={appliedFilters.date_range.to}
      />

      <RecordPanel
        data={filteredMeasurementData.flatMap(({ data }) => data)}
        withTime={showTime}
        measurementSources={measurementSources}
        isLoading={isDataLoading}
      />
    </>
  );
};

DeviceDetailMeasurementsTab.propTypes = {
  appliedFilters: PropTypes.object,
  onFiltersChange: PropTypes.func,
  measurementTypes: PropTypes.object,
  intervalOptions: PropTypes.array,
  filteredMeasurementData: PropTypes.array,
  isDataLoading: PropTypes.bool.isRequired,
  measurementSources: PropTypes.array,
};

export default DeviceDetailMeasurementsTab;
