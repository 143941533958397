import { FacilityDropdownInput } from "#src/batteries-included-components/Dropdowns/FacilityDropdownInput";
import useLocalization from "#src/hooks/useLocalization";
import { useQuery } from "@tanstack/react-query";
import {
  DropdownInput,
  FilterPanel,
  StorageKeys,
  TextInput,
} from "@validereinc/common-components";
import { EventsDomain } from "@validereinc/domain";
import React from "react";

export const SUPPORTED_EVENTS_FILTERS = [
  "name",
  "status",
  "associated_facility.id",
];

export const EventsFilterPanel = ({
  filterConfigStorageKey,
}: Pick<StorageKeys, "filterConfigStorageKey">) => {
  const { localize } = useLocalization();
  const { data: eventStatuses } = useQuery(
    ["events", "status"],
    () => EventsDomain.eventStatuses.getList(),
    {
      staleTime: 5 * 60 * 1000,
    }
  );

  return (
    <FilterPanel
      storageKey={filterConfigStorageKey}
      defaultActiveKeys={["Filters"]}
      filters={[
        {
          component: (
            <TextInput
              name={SUPPORTED_EVENTS_FILTERS[0]}
              key="event-template-name-filter"
              placeholder="Search Events..."
              type="search"
              isInline
            />
          ),
        },
        {
          component: (
            <DropdownInput
              name={SUPPORTED_EVENTS_FILTERS[1]}
              key="event-template-status-filter"
              options={eventStatuses}
              placeholder="Select a Status"
              labelKey="name"
              valueKey="id"
              isInline
              isMulti
              isOptionalTextShown={false}
            />
          ),
        },
        {
          section: "Filters",
          component: (
            <FacilityDropdownInput
              label={`Associated ${localize("Facility")}`}
              name={SUPPORTED_EVENTS_FILTERS[2]}
              key="event-associated-facility-filter"
              placeholder={`Select a ${localize("Facility")}`}
            />
          ),
        },
      ]}
    />
  );
};
