import { Panel } from "@validereinc/common-components";
import * as PropTypes from "prop-types";
import React from "react";

import { ALERT_DURATION_TOOLTIP } from "#components/AlertManagement/AlertConstants";
import { getAlertTimestamp } from "#components/AlertManagement/Alerts/AlertListHelpers";
import {
  StatusVariants,
  SummaryInformation,
  SummaryInformationContainer,
} from "#components/Common/SummaryInformation";
import { linkToAlertConfigurationDetail } from "#routers/links";
import config from "#src/config";
import { displayValue } from "#utils/stringFormatter";
import { getTimeStringFromUTCDate } from "#utils/timeFormatter";

const AlertOverviewPanel = ({
  alertDetail,
  measurementSeriesDetail,
  isLoading,
  conditionIndex,
}) => {
  const sharedProps = {
    type: "vertical",
    isLoading,
    allowWrap: true,
  };
  const conditionDescSentence: string =
    alertDetail?.condition_descriptions?.[conditionIndex] ?? null;

  return (
    <Panel isFluidY={false}>
      <SummaryInformationContainer
        variant="vertical"
        status={alertDetail?.resolved ? "Closed" : "Open"}
        statusVariant={
          alertDetail?.resolved
            ? StatusVariants.DEFAULT
            : StatusVariants.INACTIVE
        }
      >
        <SummaryInformation
          {...sharedProps}
          title="Alert Configuration"
          value={alertDetail?.monitor?.name}
          link={linkToAlertConfigurationDetail(alertDetail?.monitor?.id)}
        />
        <SummaryInformation
          {...sharedProps}
          title="Triggered At"
          value={getTimeStringFromUTCDate(
            getAlertTimestamp(alertDetail),
            config.DATETIME_FORMAT_READABLE
          )}
        />
        <SummaryInformation
          {...sharedProps}
          title="Alerted Measurement"
          value={measurementSeriesDetail?.measurement_type_name}
        />
        <SummaryInformation
          {...sharedProps}
          isLoading={sharedProps.isLoading}
          title="Alert Condition Satisfied"
          value={
            conditionDescSentence
              ? // if the sentence includes the unit, pass it through, otherwise, append it if available
                conditionDescSentence?.includes(measurementSeriesDetail?.unit)
                ? conditionDescSentence
                : `${conditionDescSentence}${
                    measurementSeriesDetail?.unit
                      ? " " + measurementSeriesDetail.unit
                      : ""
                  }`
              : null
          }
        />
        <SummaryInformation
          {...sharedProps}
          title="Duration"
          value={`${displayValue(
            alertDetail?.monitor?.conditions?.[conditionIndex]?.duration
          )} mins`}
          description={ALERT_DURATION_TOOLTIP}
        />
      </SummaryInformationContainer>
    </Panel>
  );
};

AlertOverviewPanel.propTypes = {
  measurementSeriesDetail: PropTypes.object,
  alertDetail: PropTypes.object,
  isLoading: PropTypes.bool,
  conditionIndex: PropTypes.number,
};

export default AlertOverviewPanel;
