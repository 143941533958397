import RecordOfVolumeService from "../../Services/RecordOfVolumeService";
import {
  FETCH_ADJUSTMENT_FACTORS_SUCCESS,
  FETCH_ADJUSTMENT_FACTORS,
  FETCH_ADJUSTMENT_FACTORS_ERROR,
} from "../constants/action-types";

export function fetchAdjustmentFactors() {
  return (dispatch) => {
    dispatch({
      type: FETCH_ADJUSTMENT_FACTORS,
    });

    RecordOfVolumeService.getAdjustmentFactors()
      .then(({ data }) => {
        dispatch({
          type: FETCH_ADJUSTMENT_FACTORS_SUCCESS,
          payload: data,
        });
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ADJUSTMENT_FACTORS_ERROR,
          payload: {
            message: error.message,
            status: error.status,
          },
        });
      });
  };
}
