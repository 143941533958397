import SidePanelSection from "#common/SidePanel/SidePanelSection";
import { Button, Modal, Panel } from "@validereinc/common-components";
import * as PropTypes from "prop-types";
import React, { useMemo, useState } from "react";
import { connect } from "react-redux";
import "./TestAnalysis.scss";

import { getMeasurementType } from "#redux/reducers/measurements";
import {
  getCustomMeasurementHeaders,
  getMeasurementDisplayProperties,
  MEASUREMENT_ROW_HEIGHT,
  MEASUREMENT_TABLE_OFFSET,
} from "../../Detail/TestMeasurementTableHelper";
import AlertsList from "./AlertsList";
import DownloadableReports from "./DownloadableReports";
import MeasurementTable from "./MeasurementTable";
import PreviewModal from "./PreviewModal";
import { getAlertMeasurementPanelHeight } from "./TestAnalysisHelper";
import TestOverview from "./TestOverview";

const MAX_PANEL_HEIGHT = 470;

const mapStateToProps = (state) => {
  return {
    getMeasurementType: (measurement) =>
      getMeasurementType(state.measurements)(measurement),
  };
};

const TestAnalysis = ({
  state,
  noteKey,
  testDetail,
  getMeasurementType,
  redirectToAnalyze,
  showInlineModal,
  onTabSelect,
  onClose,
}) => {
  const [previewLink, setPreviewLink] = useState(null);

  const {
    labels,
    metadata,
    pdf_url,
    lab_report_id,
    test_image_urls,
    sample_id,
    instrument,
  } = testDetail;

  const onPreviewClick = (url) => {
    setPreviewLink(url);
  };

  /**
   * A list of objects determining each row of the MeasurementTable
   *
   * @param {string} label The string displayed in the Measurement name column
   * @param {string} key The property to retrieve in `allMeasurements` object above
   * @param {string} measurementKey The string, if present, to use to find the
   *   measurement's MeasurementType for formatting (defaults to key)
   * @param {boolean} notMeasurement Whether or not the value could be formatted
   *   using an existing MeasurementType
   *
   * The Params below are only used if notMeasurement is true
   *
   * @param {function} getValue Formats the given value
   * @param {string} unit Additional string to be added after the value
   * @param {number} decimals formats the number value to have exactly number decimals
   */
  const [displayProperties, displayPropertiesWithAlerts] = useMemo(() => {
    return getMeasurementDisplayProperties(testDetail);
  }, [testDetail]);

  const customMeasurementHeaders = useMemo(() => {
    return getCustomMeasurementHeaders(testDetail, getMeasurementType);
  }, [testDetail, getMeasurementType]);

  const alertsExist = displayPropertiesWithAlerts.length !== 0;

  return (
    <>
      <Modal.Body className="testDetailModal__body">
        <div className="testAnalysis">
          <SidePanelSection subHeader="Test Overview">
            <TestOverview testDetail={testDetail} />
          </SidePanelSection>

          <SidePanelSection subHeader="Downloadable Reports">
            <DownloadableReports
              testDetail={testDetail}
              pdfReportLink={pdf_url}
              sampleId={sample_id}
              labReportId={lab_report_id}
              testImageUrls={test_image_urls}
              onPreviewClick={onPreviewClick}
            />
          </SidePanelSection>

          <SidePanelSection subHeader="Alerted Measurements">
            {alertsExist ? (
              <Panel
                className="measurementTable"
                style={getAlertMeasurementPanelHeight(
                  displayPropertiesWithAlerts,
                  MAX_PANEL_HEIGHT,
                  MEASUREMENT_ROW_HEIGHT,
                  MEASUREMENT_TABLE_OFFSET
                )}
              >
                <MeasurementTable
                  displayProperties={displayPropertiesWithAlerts}
                  testDetail={testDetail}
                  getMeasurementType={getMeasurementType}
                  customMeasurementHeaders={customMeasurementHeaders}
                />
              </Panel>
            ) : (
              <div className="testAnalysis__alertList">
                No Alerted Measurements
              </div>
            )}
          </SidePanelSection>

          <SidePanelSection
            subHeader="All Measurements"
            open={!alertsExist}
          >
            <Panel className="measurementTable">
              <MeasurementTable
                displayProperties={displayProperties}
                testDetail={testDetail}
                getMeasurementType={getMeasurementType}
                customMeasurementHeaders={customMeasurementHeaders}
              />
            </Panel>
          </SidePanelSection>

          {!!instrument && (
            <SidePanelSection subHeader="Instrument Warnings">
              <AlertsList
                labels={labels}
                metadata={metadata}
              />
            </SidePanelSection>
          )}

          {previewLink && (
            <PreviewModal
              smallImageUrl={previewLink.small}
              largeImageUrl={previewLink.original}
              onClose={() => onPreviewClick()}
            />
          )}
        </div>
      </Modal.Body>

      {testDetail && (
        <Modal.Footer className="testDetailModal__footer">
          <Button
            className="pull-left"
            onClick={onClose}
          >
            Close
          </Button>

          {redirectToAnalyze && (
            <Button onClick={() => redirectToAnalyze()}>
              Trend On Analyze
            </Button>
          )}

          <Button onClick={() => showInlineModal()}>
            Add Inline Measurement
          </Button>

          <Button
            variant="primary"
            onClick={() => {
              onTabSelect(noteKey);
            }}
            disabled={state !== "loaded"}
          >
            Update Status
          </Button>
        </Modal.Footer>
      )}
    </>
  );
};

TestAnalysis.propTypes = {
  state: PropTypes.string.isRequired,
  noteKey: PropTypes.string.isRequired,
  testDetail: PropTypes.object.isRequired,
  getMeasurementType: PropTypes.func.isRequired,
  redirectToAnalyze: PropTypes.func,
  showInlineModal: PropTypes.func.isRequired,
  onTabSelect: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, undefined)(TestAnalysis);
