import {
  AlertMessage,
  Button,
  Modal,
  Title,
  useAlert,
} from "@validereinc/common-components";
import * as PropTypes from "prop-types";
import React, { useReducer, useState } from "react";
import { connect } from "react-redux";
import { GetJSData } from "../../../utils/immutableConverter";
import { getMeasurementType } from "../../Redux/reducers/measurements";
import AnalyzeService from "../../Services/AnalyzeService";
import { getStreamsWithName } from "../AnalyzeHelper";
import BookmarkForm from "./BookmarkForm";
import { getBookmarkSpecs, validateBookmark } from "./BookmarkHelper";
import "./BookmarkModal.css";

const DATE_RANGE_OPTIONS = [
  { id: "fixed_date_range", name: "Fixed Date Range" },
  { id: "relative_date_range", name: "Relative Date Range" },
];

export function getDateRangeType(bookmark) {
  if (bookmark.relativeDayRange) {
    return DATE_RANGE_OPTIONS[1]; // Relative Date Range
  }

  return DATE_RANGE_OPTIONS[0]; // Fixed Date Range
}

const mapStateToProps = (state) => {
  return {
    getMeasurementUnit: (measurement) =>
      getMeasurementType(state.measurements)(measurement).unit,
  };
};

export const BookmarkModal = (props) => {
  const availableStreams = GetJSData(props.availableStreams);
  const [bookmark, updateBookmark] = useReducer(
    (state, { key, value }) => ({ ...state, [key]: value }),
    getStreamsWithName(props.bookmark.toJS(), availableStreams)
  );
  const [dateRangeType, setDateRangeType] = useState(
    getDateRangeType(props.bookmark)
  );

  const [alertMessage, setAlertMessage] = useState("");
  const { addAlert } = useAlert();

  const onInputChange = (key, value) => {
    updateBookmark({ key, value });
  };

  const onDateRangeTypeChange = (value) => {
    setDateRangeType(value);
  };

  const onAddBookmarkClick = () => {
    const bookmarkSpecs = getBookmarkSpecs({ ...bookmark }, dateRangeType);

    bookmarkSpecs.isBookmarked = true;

    const errorMessage = validateBookmark(
      bookmarkSpecs,
      dateRangeType,
      props.getMeasurementUnit
    );

    if (!errorMessage) {
      AnalyzeService.addAnalyzeBookmarks(bookmarkSpecs).then((response) => {
        addAlert({
          variant: "success",
          message: "Bookmark Added Successfully.",
        });

        // remove old analyze panel;
        props.removeAnalyzePanel(bookmarkSpecs);

        // create new analyze panel with updated id
        bookmarkSpecs.id = response.data.id;
        updateAnalyzePanel(bookmarkSpecs);
      });
    } else {
      setAlertMessage(errorMessage);
    }
  };

  const onRemoveBookmarkClick = () => {
    const bookmarkSpecs = getBookmarkSpecs({ ...bookmark }, dateRangeType);

    bookmarkSpecs.title = null;
    bookmarkSpecs.isBookmarked = false;

    AnalyzeService.removeAnalyzeBookmarks(bookmarkSpecs).then(() => {
      addAlert({
        variant: "success",
        message: "Bookmark Removed Successfully.",
      });

      updateAnalyzePanel(bookmarkSpecs);
    });
  };

  const onUpdateBookmarkClick = () => {
    const bookmarkSpecs = getBookmarkSpecs({ ...bookmark }, dateRangeType);

    const errorMessage = validateBookmark(
      bookmarkSpecs,
      dateRangeType,
      props.getMeasurementUnit
    );

    if (!errorMessage) {
      AnalyzeService.updateAnalyzeBookmarks(bookmarkSpecs).then(() => {
        addAlert({
          variant: "success",
          message: "Bookmark Updated Successfully.",
        });
        updateAnalyzePanel(bookmarkSpecs);
      });
    } else {
      setAlertMessage(errorMessage);
    }
  };

  const updateAnalyzePanel = (bookmarkSpecs) => {
    props.updateAnalyzePanel(bookmarkSpecs);
    props.getBookmarkList();
    props.onHide();
  };

  return (
    <Modal
      className="bookmarkModal"
      open={props.show}
      onClose={props.onHide}
    >
      <Modal.Header>
        <Title>
          {bookmark.isBookmarked
            ? `Edit Analyze Bookmark`
            : `Add Analyze Bookmark`}
        </Title>
      </Modal.Header>

      <Modal.Body>
        {alertMessage && (
          <AlertMessage
            type="warning"
            style={{ marginBottom: "15px" }}
          >
            <b>{alertMessage}</b>
          </AlertMessage>
        )}

        <BookmarkForm
          bookmark={bookmark}
          availableStreams={availableStreams}
          dateRangeOptions={DATE_RANGE_OPTIONS}
          dateRangeType={dateRangeType}
          onDateRangeTypeChange={onDateRangeTypeChange}
          onInputChange={onInputChange}
        />
      </Modal.Body>

      <Modal.Footer>
        <Button
          className="pull-left"
          onClick={props.onHide}
        >
          Close
        </Button>
        {bookmark.isBookmarked ? (
          <>
            <Button
              variant="error-outline"
              style={{ marginRight: "10px" }}
              onClick={() => onRemoveBookmarkClick()}
            >
              remove bookmark
            </Button>

            <Button
              variant="primary"
              onClick={() => onUpdateBookmarkClick()}
            >
              update bookmark
            </Button>
          </>
        ) : (
          <Button
            variant="primary"
            onClick={() => onAddBookmarkClick()}
          >
            add bookmark
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

BookmarkModal.propTypes = {
  show: PropTypes.bool,
  bookmark: PropTypes.object.isRequired,
  onHide: PropTypes.func,
  availableStreams: PropTypes.object.isRequired,
  removeAnalyzePanel: PropTypes.func.isRequired,
  updateAnalyzePanel: PropTypes.func.isRequired,
  getBookmarkList: PropTypes.func.isRequired,
  getMeasurementUnit: PropTypes.func.isRequired,
};

const BookmarkModalContainer = connect(mapStateToProps, null)(BookmarkModal);

export default BookmarkModalContainer;
