import { DomainModelMeta } from "../schemas";
import {
  DomainModelType,
  GetListRequestType,
  GetListResponseType,
} from "../util";
import { restAPI } from "./api";

const eventCategoryEndpoint = "/event_categories";

export type EventCategoryGetoneLookupType = {
  eventCategoryId: string;
};

export type EventCategoryGetListFilterType = {
  name?: string;
  archived?: boolean;
};

export type EventCategoryType = {
  name: string;
  archived: boolean;
  company_id: string;
} & DomainModelType &
  DomainModelMeta;

export const EventCategoryDomain = {
  getList: async ({
    filters,
    page = 1,
    pageSize = 10,
    sortBy = "name",
    sortDirection = "asc",
  }: GetListRequestType<EventCategoryGetListFilterType>): Promise<
    GetListResponseType<EventCategoryType>
  > =>
    await restAPI.nodeAPI.GET({
      endpoint: eventCategoryEndpoint,
      query: {
        ...filters,
        page,
        page_size: pageSize,
        sort_by: sortBy,
        sort_direction: sortDirection,
      },
    }),

  getOne: async ({
    eventCategoryId,
  }: EventCategoryGetoneLookupType): Promise<EventCategoryType> =>
    await restAPI.nodeAPI.GET({
      endpoint: `${eventCategoryEndpoint}/${eventCategoryId}`,
    }),
};
