import React from "react";
import * as PropTypes from "prop-types";
import { connect } from "react-redux";
import { getMeasurementType } from "../../Redux/reducers/measurements";
import { getAlertSpec } from "../InstrumentComponentHelper";
import InstrumentDetail from "../InstrumentDetail";
import get from "lodash/get";
import isNil from "lodash/isNil";
import {
  dateRenderer,
  sampleDateRenderer,
  sortBySampleDate,
  getMeasurementValue,
} from "../InstrumentHelper";
import MeasurementStatus from "../Shared/TestsDatatable/MeasurementStatus";
import { notesColumn } from "../Shared/TestsDatatable/NotesColumn";
import {
  sampleStateRenderer,
  sortBySampleState,
} from "../../Samples/SampleHelper";
import { getFirstValidValue } from "#utils/objectFormatter";
import TestDetailModal from "#components/Tests/TestDetailModal/TestDetailModal";

const shouldUseAPIGravity = (instrumentInfo) => {
  //Display options in metadata to be set on instrument level to
  //control UI renderings on field lab pages
  const useAPIGravityString = get(
    instrumentInfo.metadata,
    ["display_options", "use_api_gravity"],
    false
  );
  const useAPIGravity = JSON.parse(useAPIGravityString);
  return useAPIGravity;
};

const measurementRenderer = (
  measurementType,
  apiGravityMeasurementType,
  instrumentInfo
) =>
  function measurementRendererInner(rowData, columnKey) {
    //Include default API Gravity spec limits for demo purposes
    //Once we a clear use case for clients that use API Gravity from lab devices
    //We can create API Gravity as there own separate measurement
    const defaultAPIGravityAlert = {
      measurement_unit: apiGravityMeasurementType.unit,
      upper_limit: 60,
      lower_limit: 5,
    };

    const defaultAlertSpec = {
      measurement_unit: measurementType.unit,
      upper_limit: 1000,
      lower_limit: 0,
    };

    const alertSpec = shouldUseAPIGravity(instrumentInfo)
      ? defaultAPIGravityAlert
      : getAlertSpec(
          rowData.measurement_alert_specs,
          measurementType.name,
          defaultAlertSpec
        );

    try {
      const value = getMeasurementValue(
        rowData.measurements[columnKey].value,
        measurementType.decimals
      );
      const unit = rowData.measurements[columnKey].unit;

      return (
        <MeasurementStatus
          value={value}
          unit={unit}
          status={rowData.status}
          labels={rowData.labels}
          alertSpec={alertSpec}
          defaultAlertSpec={defaultAlertSpec}
          measurementType={measurementType}
        />
      );
    } catch {
      return <div>-</div>;
    }
  };

const measuringTempRenderer = (rowData, columnKey) => {
  const temperature_in_c = getFirstValidValue([
    rowData.metadata[columnKey],
    rowData.measurements?.Density.temperature_in_c,
  ]);

  if (temperature_in_c) {
    return <div>{temperature_in_c} °C</div>;
  } else {
    return <div>-</div>;
  }
};

const testerRenderer = (rowData, columnKey) => {
  return <div>{rowData.metadata[columnKey] ?? rowData.tested_by ?? "-"}</div>;
};

const sortByMeasurement = (list, measurementName) => {
  return [...list].sort((a, b) => {
    const aValue = a.measurements[measurementName]?.value ?? null;
    const bValue = b.measurements[measurementName]?.value ?? null;

    if (!isNil(aValue) && !isNil(bValue)) {
      return (
        a.measurements[measurementName].value -
        b.measurements[measurementName].value
      );
    } else if (isNil(aValue) && !isNil(bValue)) {
      return -1;
    } else if (!isNil(aValue) && isNil(bValue)) {
      return 1;
    } else {
      return 0;
    }
  });
};

const headers = (
  measurementType,
  apiGravityMeasurementType,
  instrumentInfo
) => {
  const useAPIGravity = shouldUseAPIGravity(instrumentInfo);

  return [
    {
      label: "Name",
      key: "name",
      width: 300,
      fixed: true,
    },
    {
      label: "Sample State",
      key: "sample_state",
      width: 150,
      cellRenderer: (row) => sampleStateRenderer(row.sample),
      sort: sortBySampleState,
    },
    {
      label: "Test Date",
      key: "date",
      width: 170,
      cellRenderer: dateRenderer,
    },
    {
      label: "Tester",
      key: "lot_id",
      width: 150,
      cellRenderer: testerRenderer,
      sort: (list, sortBy) => {
        return list.sort((a, b) => {
          const aValue = a.metadata[sortBy] ?? a.tested_by ?? "-";

          const bValue = b.metadata[sortBy] ?? b.tested_by ?? "-";

          return aValue.localeCompare(bValue);
        });
      },
      suppress: true,
    },
    {
      label: "Measuring Temp.",
      key: "temperature_c",
      width: 150,
      cellRenderer: measuringTempRenderer,
      sort: (list, sortBy) => {
        return list.sort((a, b) => {
          const aValue =
            a.metadata[sortBy] ??
            a.measurements?.Density.temperature_in_c ??
            null;

          const bValue =
            b.metadata[sortBy] ??
            b.measurements?.Density.temperature_in_c ??
            null;

          if (!isNil(aValue) && !isNil(bValue)) {
            return aValue - bValue;
          } else if (isNil(aValue) && !isNil(bValue)) {
            return -1;
          } else if (!isNil(aValue) && isNil(bValue)) {
            return 1;
          } else {
            return 0;
          }
        });
      },
    },
    useAPIGravity
      ? {
          label: "API Gravity",
          key: apiGravityMeasurementType.name,
          width: 250,
          cellRenderer: measurementRenderer(
            measurementType,
            apiGravityMeasurementType,
            instrumentInfo
          ),
          sort: sortByMeasurement,
        }
      : {
          label: measurementType.display_name,
          key: measurementType.name,
          width: 250,
          cellRenderer: measurementRenderer(
            measurementType,
            apiGravityMeasurementType,
            instrumentInfo
          ),
          sort: sortByMeasurement,
        },
    {
      label: "Sample Start Date",
      key: "started_at",
      width: 170,
      cellRenderer: sampleDateRenderer,
      sort: sortBySampleDate,
    },
    {
      label: "Sample End Date",
      key: "ended_at",
      width: 170,
      cellRenderer: sampleDateRenderer,
      sort: sortBySampleDate,
    },
    notesColumn,
  ];
};

const mapStateToProps = (state) => {
  return {
    measurementType: getMeasurementType(state.measurements)("Density"),
    apiGravityMeasurementType: getMeasurementType(state.measurements)(
      "API Gravity"
    ),
  };
};

const Tests = (props) => {
  return (
    <InstrumentDetail
      testId={props.match.params.testId}
      instrumentInfo={props.instrumentInfo}
      height={props.height}
      ReportModalComponent={TestDetailModal}
      filterKey="name"
      headers={headers(
        props.measurementType,
        props.apiGravityMeasurementType,
        props.instrumentInfo
      )}
      defaultSortBy="date"
      defaultSortDirection="desc"
    />
  );
};

Tests.propTypes = {
  match: PropTypes.object.isRequired,
  height: PropTypes.number.isRequired,
  instrumentInfo: PropTypes.object.isRequired,
  measurementType: PropTypes.object.isRequired,
  apiGravityMeasurementType: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, undefined)(Tests);
