export const PERCENTAGE_DIFFERENCE_KEY = "percent_differences";

export const RECOVERY_KEY = "recoveries";

export const SystemBalanceProperties = [
  {
    name: "Receipts Plus Opening Inventory",
    id: "receipts_plus_opening_inventory",
  },
  {
    name: "Dispositions Plus Closing Inventory",
    id: "dispositions_plus_closing_inventory",
  },
  { name: "Absolute Differences", id: "absolute_differences" },
  { name: "Percent Differences", id: "percent_differences" },
  { name: "Recoveries", id: "recoveries" },
];
