import React, { useState } from "react";
import {
  Panel,
  Table,
  MenuItem,
  SplitButton,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import * as PropTypes from "prop-types";
import "./MeasurementControlPanel.css";
import {
  MEASUREMENT_TYPES,
  MEASUREMENT_LABELS,
  MeasurementState,
  MeasurementValueActions,
} from "../Hooks/useMeasurementReducer";
import SubmitModal from "./SubmitModal";
import SubmitButton from "./SubmitButton";
import FontAwesome from "react-fontawesome";

const isValueSet = (value) => {
  return value !== undefined;
};

const WarningSymbol = ({ children }) => {
  const tooltip = (
    <Tooltip id="measurementControlPanel__submitButton__tooltip">
      {children}
    </Tooltip>
  );

  return (
    <OverlayTrigger
      placement="bottom"
      overlay={tooltip}
      className="measurementControlPanel__warningSymbol"
    >
      <FontAwesome
        name="exclamation-triangle"
        style={{
          color: "orange",
        }}
      />
    </OverlayTrigger>
  );
};

WarningSymbol.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

const MeasurementValue = ({ measurementValue, hasError }) => {
  let measurementValueText;

  if (hasError) {
    measurementValueText = "Error";
  } else if (isValueSet(measurementValue)) {
    measurementValueText = measurementValue;
  } else {
    measurementValueText = "-";
  }

  return (
    <td className="measurementControlPanel__measurementValue">
      {measurementValueText}
      {isValueSet(measurementValue) && measurementValue < 0 && (
        <WarningSymbol>Measurement value is below 0</WarningSymbol>
      )}
    </td>
  );
};

MeasurementValue.propTypes = {
  measurementValue: PropTypes.string,
  hasError: PropTypes.bool,
};

const MeasurementControlPanel = ({
  testId,
  leftTubeState,
  rightTubeState,
  leftTubeActions,
  rightTubeActions,
  onLeftMeasurementClick,
  onRightMeasurementClick,
}) => {
  const [isSubmitDialogOpen, setIsSubmitDialogOpen] = useState(false);

  return (
    <Panel>
      <Panel.Body>
        <section>
          <Table condensed>
            <thead className="measurementControlPanel__tableHeaders">
              <tr>
                <th>Measurement Type</th>
                <th>Left Tube</th>
                <th>Right Tube</th>
              </tr>
            </thead>
            {Object.values(MEASUREMENT_TYPES).map((type) => {
              const leftMeasurementValue = leftTubeState[type].meniscus.value;
              const rightMeasurementValue = rightTubeState[type].meniscus.value;

              const hasLeftTubeError = leftTubeState[type].hasError;
              const hasRightTubeError = rightTubeState[type].hasError;

              const leftMeasureButtonBsStyle =
                hasLeftTubeError || isValueSet(leftMeasurementValue)
                  ? "default"
                  : "primary";

              const rightMeasureButtonBsStyle =
                hasRightTubeError || isValueSet(rightMeasurementValue)
                  ? "default"
                  : "primary";

              return (
                <tbody key={type}>
                  <tr>
                    <td>{MEASUREMENT_LABELS[type]}</td>
                    <MeasurementValue
                      measurementValue={leftMeasurementValue}
                      hasError={hasLeftTubeError}
                    />
                    <MeasurementValue
                      measurementValue={rightMeasurementValue}
                      hasError={hasRightTubeError}
                    />
                  </tr>
                  <tr>
                    <td />
                    <td className="measurementControlPanel__measureButtons">
                      <SplitButton
                        title="Measure"
                        bsSize="small"
                        bsStyle={leftMeasureButtonBsStyle}
                        onClick={() => onLeftMeasurementClick(type)}
                        id="left-tube-measurement-dropdown"
                      >
                        <MenuItem
                          onSelect={() => leftTubeActions.setError(type)}
                        >
                          Set as Error
                        </MenuItem>
                        <MenuItem
                          onSelect={() =>
                            leftTubeActions.zeroOutMeasurementValue(type)
                          }
                        >
                          Set measurement to 0
                        </MenuItem>
                        <MenuItem divider />
                        <MenuItem
                          onSelect={() =>
                            leftTubeActions.clearMeasurementValue(type)
                          }
                        >
                          Clear
                        </MenuItem>
                      </SplitButton>
                    </td>
                    <td className="measurementControlPanel__measureButtons">
                      <SplitButton
                        title="Measure"
                        bsSize="small"
                        bsStyle={rightMeasureButtonBsStyle}
                        onClick={() => onRightMeasurementClick(type)}
                        id="right-tube-measurement-dropdown"
                      >
                        <MenuItem
                          onSelect={() => rightTubeActions.setError(type)}
                        >
                          Set as Error
                        </MenuItem>
                        <MenuItem
                          onSelect={() =>
                            rightTubeActions.zeroOutMeasurementValue(type)
                          }
                        >
                          Set measurement to 0
                        </MenuItem>
                        <MenuItem divider />
                        <MenuItem
                          onSelect={() =>
                            rightTubeActions.clearMeasurementValue(type)
                          }
                        >
                          Clear
                        </MenuItem>
                      </SplitButton>
                    </td>
                  </tr>
                </tbody>
              );
            })}
          </Table>
        </section>
        <footer className="measurementControlPanel__submitPanel">
          <SubmitButton
            onClick={() => setIsSubmitDialogOpen(true)}
            leftTubeState={leftTubeState}
            rightTubeState={rightTubeState}
          />
        </footer>
        {isSubmitDialogOpen && (
          <SubmitModal
            testId={testId}
            isOpen={isSubmitDialogOpen}
            onClose={() => setIsSubmitDialogOpen(false)}
            leftTubeState={leftTubeState}
            rightTubeState={rightTubeState}
          />
        )}
      </Panel.Body>
    </Panel>
  );
};

MeasurementControlPanel.propTypes = {
  testId: PropTypes.string.isRequired,
  leftTubeState: PropTypes.instanceOf(MeasurementState).isRequired,
  rightTubeState: PropTypes.instanceOf(MeasurementState).isRequired,
  onLeftMeasurementClick: PropTypes.func.isRequired,
  onRightMeasurementClick: PropTypes.func.isRequired,
  leftTubeActions: PropTypes.instanceOf(MeasurementValueActions).isRequired,
  rightTubeActions: PropTypes.instanceOf(MeasurementValueActions).isRequired,
};

export default MeasurementControlPanel;
