import { Button, Modal, Title } from "@validereinc/common-components";
import * as PropTypes from "prop-types";
import React from "react";

const ArchiveConfirmationModal = (props) => (
  <Modal
    open={props.show}
    onClose={props.onClose}
  >
    <Modal.Header>
      <Title>Archive Workflow</Title>
    </Modal.Header>

    <Modal.Body>
      <p>Do you wish to Archive {props.name}?</p>
    </Modal.Body>

    <Modal.Footer>
      <Button
        className="pull-left"
        onClick={props.onClose}
      >
        Close
      </Button>

      <Button
        variant="error"
        onClick={props.onClick}
      >
        Yes, I would like to archive this workflow.
      </Button>
    </Modal.Footer>
  </Modal>
);

ArchiveConfirmationModal.propTypes = {
  name: PropTypes.string,
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default ArchiveConfirmationModal;
