import config from "#src/config";
import { getTimeStringFromDate } from "#utils/timeFormatter";
import { Tooltip } from "@validereinc/common-components";
import get from "lodash/get";
import React, { Component } from "react";
import FontAwesome from "react-fontawesome";
import { connect } from "react-redux";
import { AutoSizer, Column } from "react-virtualized";
import IconWithTooltip from "../../../Common/Icons/IconWithTooltip";
import CustomTable from "../../../Common/Table/CustomTable";
import { addAlertMessage } from "../../../Redux/actions/alertMessages";
import { AlertMessage } from "../../../Redux/reducers/alertMessages";
import { sampleStateRenderer } from "../../../Samples/SampleHelper";
import InstrumentService from "../../../Services/InstrumentService";
import "./Analytics.css";

/* eslint-disable react/prop-types */

const lodash = { get };

const NUM_OF_COLUMNS = 8;

const mapDispatchToProps = {
  addAlertMessage,
};

class AnalyticsTable extends Component {
  constructor(props) {
    super(props);

    this.getValue = this.getValue.bind(this);
    this.getInlineMeasurement = this.getInlineMeasurement.bind(this);
    this.getLabel = this.getLabel.bind(this);
    this.csvDownload = this.csvDownload.bind(this);
  }

  getDate({ rowData, dataKey }) {
    return getTimeStringFromDate(rowData[dataKey], config.DATETIME_FORMAT);
  }

  getLabel({ rowData, dataKey }) {
    return (
      <FontAwesome
        name="circle"
        style={{ color: this.props.getStreamColor(rowData[dataKey]) }}
      />
    );
  }

  getStreamInfo({ rowData, dataKey }) {
    const value = rowData[dataKey];
    if (value) {
      return (
        <Tooltip content={value}>
          <div>{value}</div>
        </Tooltip>
      );
    } else {
      return "-";
    }
  }

  getString({ rowData, dataKey }) {
    const value = rowData[dataKey];

    if (value) {
      return value;
    } else {
      return "-";
    }
  }

  getValue({ rowData, dataKey }) {
    const value = rowData[dataKey];
    return this.addUnit(value);
  }

  getInlineMeasurement({ rowData }) {
    const measurement = lodash.get(rowData, "inline_measurement.value", null);
    const labels = lodash.get(rowData, "inline_measurement.labels", []);
    if (measurement) {
      return this.withLabel(measurement, labels);
    } else {
      return "-";
    }
  }

  withLabel = (value, labels) => {
    if (labels.length > 0) {
      return (
        <span>
          <IconWithTooltip errors={labels} />
          {this.addUnit(value)}
        </span>
      );
    } else {
      return this.addUnit(value);
    }
  };

  addUnit(value) {
    const { measurementTypes, selectedMeasurementType } = this.props;

    const measurementType = measurementTypes(selectedMeasurementType);

    if (value !== null && value !== undefined) {
      value = value.toFixed(measurementType.decimals);
      return `${value} ${measurementType.unit}`;
    } else {
      return "-";
    }
  }

  getDeviationValue(inlineMeasurement, fieldMeasurement) {
    if (inlineMeasurement && fieldMeasurement) {
      return inlineMeasurement - fieldMeasurement;
    }
  }

  csvDownload(tableRows) {
    const {
      period,
      selectedMeasurementType,
      selectedDownsampleWindow,
      instrument,
    } = this.props;
    const sampleIds = [
      ...new Set(tableRows.map((rowData) => rowData.sample_id)),
    ];

    if (sampleIds.length > 0) {
      this.props.addAlertMessage(
        new AlertMessage({
          type: "success",
          message: "Your files will start downloading shortly.",
        })
      );

      InstrumentService.getInlineInstrumentAnalyzeCSV(
        instrument.id,
        sampleIds,
        period,
        selectedMeasurementType,
        selectedDownsampleWindow.value
      ).then(({ data }) => {
        const link = document.createElement("a");
        link.download = "inline";
        link.href = data.url;
        document.body.appendChild(link);
        link.click();
      });
    }
  }

  render() {
    const { tableData, hightlightSampleId, height } = this.props;

    tableData.map((rowData) => {
      const inlineMeasurementValue = rowData.inline_measurement.value;
      const fieldMeasurement = rowData.field_lab_measurement;

      rowData.deviation = this.getDeviationValue(
        inlineMeasurementValue,
        fieldMeasurement
      );
      rowData.inline_measurement_value = inlineMeasurementValue;
    });

    return (
      <div
        className="tableContainer"
        style={{ height: height }}
      >
        <AutoSizer>
          {({ width, height }) => (
            <CustomTable
              csvDownload={this.csvDownload}
              list={tableData}
              width={width}
              height={height}
              highlightRow={{ sample_id: hightlightSampleId }}
              filterKey="stream_name"
              filterTitle="Stream"
              defaultSortBy="sample_start_date"
              defaultSortDirection="desc"
            >
              <Column
                label="Label"
                dataKey="stream_id"
                cellRenderer={this.getLabel}
                width={width / (NUM_OF_COLUMNS + 7)}
                disableSort
              />
              <Column
                label="Stream"
                dataKey="stream_name"
                cellRenderer={this.getStreamInfo}
                width={width / (NUM_OF_COLUMNS - 4)}
              />
              <Column
                label="Start Time"
                dataKey="sample_start_date"
                cellDataGetter={this.getDate}
                width={width / NUM_OF_COLUMNS}
              />
              <Column
                label="End Time"
                dataKey="sample_end_date"
                cellDataGetter={this.getDate}
                width={width / NUM_OF_COLUMNS}
              />
              <Column
                label="Sampled State"
                dataKey="sample_state"
                cellRenderer={({ rowData }) => {
                  return sampleStateRenderer({
                    state: rowData.sample_state,
                    validated_by: rowData.sample_validated_by_id,
                  });
                }}
                width={width / NUM_OF_COLUMNS}
              />
              <Column
                label="Inline"
                dataKey="inline_measurement_value"
                cellRenderer={this.getInlineMeasurement}
                width={width / (NUM_OF_COLUMNS + 1)}
              />
              <Column
                label="Field Lab"
                dataKey="field_lab_measurement"
                cellDataGetter={this.getValue}
                width={width / (NUM_OF_COLUMNS + 1)}
              />
              <Column
                label="Deviation"
                dataKey="deviation"
                cellRenderer={this.getValue}
                width={width / (NUM_OF_COLUMNS + 1)}
              />
            </CustomTable>
          )}
        </AutoSizer>
      </div>
    );
  }
}

export default connect(null, mapDispatchToProps)(AnalyticsTable);
