import React from "react";
import * as PropTypes from "prop-types";
import "./SystemBalance.scss";
import SystemBalanceTable from "./SystemBalanceTable";
import SystemBalanceChart from "./SystemBalanceChart";
import SystemBalanceSummary from "./SystemBalanceSummary";
import SidePanelSection from "#common/SidePanel/SidePanelSection";
import { getEstimatedValue } from "#components/Records/Quality/RecordOfQualityHelper";
import { useRecordSelectionContext } from "../Context/recordSelectionContext";
import ReactLoader from "#components/LoadingBar/ReactLoader";

const MAXIMUM_DISPLAY_PROPERTIES_FOR_CHART = 10;

const SystemBalanceSummaryHeader = () => (
  <span className="systemBalanceHeader__title">Estimated Report Value</span>
);

const SystemBalanceSummaryCaption = (props) => (
  <div className="systemBalanceHeader__estimatedValue">
    {getEstimatedValue(props.total.estimated_value)}
  </div>
);

const SystemBalance = (props) => {
  const { systemBalance, resultLoadingState } = useRecordSelectionContext();
  const { components, qualities, total, outlets, inlets } = systemBalance;
  // For the system balance chart we will need to limit to maximum properties displayed.
  const displayPropertiesForChart = [...props.displayProperties].slice(
    0,
    MAXIMUM_DISPLAY_PROPERTIES_FOR_CHART
  );

  return (
    <div className="systemBalance">
      {resultLoadingState !== "initial" && (
        <ReactLoader
          loaded={resultLoadingState !== "loading"}
          position={50}
        >
          {resultLoadingState !== "error" ? (
            <div>
              <SidePanelSection
                subHeader={<SystemBalanceSummaryHeader />}
                caption={<SystemBalanceSummaryCaption total={total} />}
              >
                <SystemBalanceSummary total={total} />
              </SidePanelSection>

              <SidePanelSection subHeader="Net Facility Balance">
                <SystemBalanceChart
                  components={components}
                  qualities={qualities}
                  totalDifference={total}
                  componentVolumeConfig={
                    props.accountingPeriod.component_volume_config
                  }
                  displayProperties={displayPropertiesForChart}
                  outlets={outlets}
                  inlets={inlets}
                />

                <SystemBalanceTable
                  height={props.height}
                  componentVolumeConfig={
                    props.accountingPeriod.component_volume_config
                  }
                  displayProperties={displayPropertiesForChart}
                  balanceType={props.accountingPeriod.balance_type}
                />
              </SidePanelSection>
            </div>
          ) : (
            <div>System Balance is unavailable.</div>
          )}
        </ReactLoader>
      )}
    </div>
  );
};

SystemBalanceSummaryCaption.propTypes = {
  total: PropTypes.object,
};

SystemBalance.propTypes = {
  accountingPeriod: PropTypes.object.isRequired,
  displayProperties: PropTypes.array.isRequired,
  total: PropTypes.object,
  height: PropTypes.number.isRequired,
};

export default SystemBalance;
