import DeleteModal from "#components/Common/DeleteModal/DeleteModal";
import AlertingService from "#components/Services/AlertingService";
import { getBreadcrumbsObject } from "#routers/breadcrumbsHelper";
import { useParams, useSearchParams } from "#routers/hooks";
import { useQuery } from "@tanstack/react-query";
import { Button, Page, Tab } from "@validereinc/common-components";
import { UsersAdapter } from "@validereinc/domain";
import * as PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import ConditionsTab from "./ConditionsTab";
import RecipientsTab from "./RecipientsTab";

const AlertConfigurationDetail = ({ breadcrumbs }) => {
  const { alertConfigurationId } = useParams<{
    alertConfigurationId: string;
  }>();
  const [searchParams, setSearchParam] = useSearchParams();
  const [isArchiveModalOpen, setIsArchiveModalOpen] = useState(false);
  const alertConfigDetailQuery = useQuery(
    ["alerts", "configurations", alertConfigurationId],
    () => AlertingService.getAlertConfiguration(alertConfigurationId),
    {
      enabled: Boolean(alertConfigurationId),
      staleTime: 3 * 60 * 1000,
      select: (resp) => resp.data,
    }
  );
  const createdByUserQuery = useQuery({
    queryKey: ["users", alertConfigDetailQuery.data.created_by_id],
    queryFn: () => {
      if (!alertConfigDetailQuery.data.created_by_id) {
        return;
      }

      return UsersAdapter.getOne({
        id: alertConfigDetailQuery.data.created_by_id,
      });
    },
    enabled: Boolean(alertConfigDetailQuery.data.created_by_id),
    staleTime: 3 * 60 * 1000,
    select: (resp) => resp?.data,
  });

  const breadcrumbsObject = useMemo(() => {
    const newBreadcrumbs = getBreadcrumbsObject(breadcrumbs);

    if (alertConfigurationDetail) {
      newBreadcrumbs.push({ title: alertConfigurationDetail?.name });
    }

    return newBreadcrumbs;
  }, [breadcrumbs, alertConfigDetailQuery.data]);

  const onChangeTab = (newActiveTab) =>
    setSearchParam({ ...searchParams, tab: newActiveTab });

  useEffect(() => {
    if (!searchParams.tab) {
      setSearchParam({ ...searchParams, tab: "overview" });
    }
  }, [searchParams.tab]);

  const refetchData = () => alertConfigDetailQuery.refetch();
  const alertConfigurationDetail = alertConfigDetailQuery.data;

  return (
    <>
      <Page
        title={alertConfigurationDetail?.name}
        breadcrumbs={breadcrumbsObject}
        isLoading={!alertConfigurationDetail}
        activeTabKey={searchParams.tab}
        onActiveTabKeyChange={onChangeTab}
        actionRow={
          alertConfigurationDetail?.active ? (
            <Button
              variant="error-outline"
              onClick={() => setIsArchiveModalOpen(true)}
            >
              Archive
            </Button>
          ) : null
        }
      >
        <Tab
          tabKey="overview"
          title="Overview"
        >
          <ConditionsTab
            alertConfigurationDetail={alertConfigurationDetail}
            createdByUser={createdByUserQuery.data}
          />
        </Tab>

        <Tab
          tabKey="recipients"
          title="Recipients"
        >
          <RecipientsTab
            recipients={alertConfigurationDetail?.subscribers}
            refetchData={refetchData}
            alertConfigId={alertConfigurationDetail?.id}
          />
        </Tab>
      </Page>

      <DeleteModal
        open={isArchiveModalOpen}
        instanceName={alertConfigurationDetail?.name}
        entityName="Alert Configuration"
        onClose={() => setIsArchiveModalOpen(false)}
        onDelete={() => refetchData()}
        doDelete={() =>
          AlertingService.archiveConfiguration(alertConfigurationDetail.id)
        }
        action={{ present: "archive", past: "archived" }}
      />
    </>
  );
};

AlertConfigurationDetail.propTypes = {
  breadcrumbs: PropTypes.array.isRequired,
};

export default AlertConfigurationDetail;
