export * from "./EventCategoriesPage";

export const EVENT_CATEGORIES_PAGE_TITLE = "Categories";

export const linkToEventCategories = () => `/app/events/event-categories`;

export const EVENT_CATEGORIES_BREADCRUMB = {
  title: EVENT_CATEGORIES_PAGE_TITLE,
  path: linkToEventCategories,
};

export const EVENT_CATEGORIES_TAB = {
  id: "event_categories",
  title: EVENT_CATEGORIES_PAGE_TITLE,
};
