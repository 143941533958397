import { RoleContext } from "#src/routes/settings/roles-and-permissions/roles/[roleId]/detail/RoleContext";
import { PillProps, StatusPanel } from "@validereinc/common-components";
import type { RoleType } from "@validereinc/domain";
import { datetimeFormatter } from "@validereinc/utilities";
import React, { useContext } from "react";

export const RoleDetailStatusPanel = () => {
  const { roleQuery, createdByUserQuery, updatedByUserQuery } =
    useContext(RoleContext) ?? {};

  if (!roleQuery?.data) {
    return null;
  }

  return (
    <StatusPanel
      statusText={roleQuery?.data?.status}
      statusVariant={
        (
          {
            active: "success",
            disabled: "default",
          } satisfies Record<RoleType["status"], PillProps["variant"]>
        )[roleQuery.data.status]
      }
      data={[
        {
          label: "Updated At",
          value: datetimeFormatter(new Date(roleQuery.data.updated_at)),
        },
        {
          label: "Updated By",
          value: updatedByUserQuery?.data?.name ?? "-",
        },
        {
          label: "Created At",
          value: datetimeFormatter(new Date(roleQuery.data.created_at)),
        },
        {
          label: "Created By",
          value: createdByUserQuery?.data?.name ?? "-",
        },
      ]}
    />
  );
};
