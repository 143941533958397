import RecordStatus from "#common/RecordStatus/RecordStatus";
import {
  SummaryInformation,
  SummaryInformationContainer,
} from "#common/SummaryInformation";
import { Panel } from "@validereinc/common-components";
import classNames from "classnames/bind";
import moment from "moment";
import * as PropTypes from "prop-types";
import React from "react";
import Qualities from "./Qualities";
import styles from "./SingleRecordView.module.scss";
import VolumeSection from "./VolumeSection";

const cx = classNames.bind(styles);

const SingleMonthView = ({
  recordDetail,
  recordDetailLoadingState,
  dateRange,
}) => {
  return (
    <>
      <Panel
        title="Summary"
        loaded={recordDetailLoadingState === "loaded"}
      >
        <SummaryInformationContainer>
          <SummaryInformation
            title="Product"
            value={recordDetail?.product_type ?? "-"}
            type="vertical"
          />

          <SummaryInformation
            title="Status"
            value={<RecordStatus type={recordDetail?.status} />}
            type="vertical"
          />

          <SummaryInformation
            title="Approved By"
            value={recordDetail?.approved_by?.name ?? "-"}
            type="vertical"
          />

          <SummaryInformation
            title="Last Edit By"
            value={recordDetail?.last_edit_by?.user?.name ?? "-"}
            type="vertical"
          />

          <SummaryInformation
            title="Last Updated Time"
            value={recordDetail?.last_update_time ?? "-"}
            type="vertical"
          />
        </SummaryInformationContainer>
      </Panel>

      <Panel
        title={`Volume - ${moment(dateRange.from).format("MMMM YYYY")}`}
        loaded={recordDetailLoadingState === "loaded"}
      >
        <div className={cx("record-panel")}>
          <VolumeSection
            recordDetail={recordDetail}
            streamId={recordDetail?.stream?.id}
            dateRange={dateRange}
          />

          <Qualities recordDetail={recordDetail} />
        </div>
      </Panel>
    </>
  );
};

SingleMonthView.propTypes = {
  recordDetail: PropTypes.object.isRequired,
  dateRange: PropTypes.object.isRequired,
  recordDetailLoadingState: PropTypes.string.isRequired,
};

export default SingleMonthView;
