import { useNavigate, useParams } from "#routers/hooks";
import {
  Button,
  DataTablePanel,
  HeaderType,
} from "@validereinc/common-components";
import { AssetType, AssetTypeType, DeviceType } from "@validereinc/domain";
import React from "react";

import { linkToDeviceDetail, linkToFacilities } from "#routers/links";

import { useGetOneNetwork } from "#hooks/adapters/useNetworks";
import { useTableSortingAndPagination } from "#redux/reducers/tableStateReducer";
import { NetworkDetailPageParameters } from "#routes/organization/networks/[networkId]/detail";
import { NETWORK_ENTITY_INITIAL_SORT } from "#routes/organization/networks/[networkId]/detail/NetworkDetailsTab/NetworkEntityTablePanel/index";
import { RoutingLink } from "#src/batteries-included-components/RoutingLink";
import { useListDevices } from "#src/components/hooks/adapters/utils/useDevices";
import { NetworkEditRoute } from "../../edit";

export const NetworkDevicesPanel = () => {
  const { networkId } = useParams<NetworkDetailPageParameters>();
  const { data: assetGroup } = useGetOneNetwork(networkId);
  const navigate = useNavigate();
  const getAssetIdsByType = (assetType: AssetTypeType) =>
    assetGroup?.assets
      ?.filter(({ asset_type }) => asset_type === assetType)
      .map(({ id }) => id) ?? [];
  const deviceIds = getAssetIdsByType?.(AssetType.DEVICE);

  const filters = { id: deviceIds };
  const [tableState, updateTableState] = useTableSortingAndPagination(
    NETWORK_ENTITY_INITIAL_SORT,
    filters
  );

  const deviceParams = {
    page: tableState.page,
    pageSize: tableState.itemsPerPage,
    sort_by: tableState.sortBy,
    sort_direction: tableState.sortDirection,
    filters,
  };

  const isDeviceQueryEnabled = !!deviceIds?.length;

  const { data, isLoading } = useListDevices(deviceParams, {
    enabled: isDeviceQueryEnabled,
  });

  const headers: Array<HeaderType<DeviceType>> = [
    {
      key: "name",
      label: "Name",
      isSortable: true,
      renderComponent: ({ item }) => (
        <RoutingLink to={linkToDeviceDetail(item.id)}>{item.name}</RoutingLink>
      ),
    },
    {
      key: "facility.name",
      label: "Facility",
      isSortable: true,
      renderComponent: ({ item }) => (
        <RoutingLink to={linkToFacilities(item.facility.id)}>
          {item.facility.name}
        </RoutingLink>
      ),
    },
    {
      key: "type.name",
      label: "Type",
      isSortable: true,
    },
  ];

  return (
    <DataTablePanel
      panelProps={{
        title: "Network Devices",
        actionRow: (
          <>
            <Button
              onClick={() => {
                navigate({
                  pathname: NetworkEditRoute.toLink({
                    pathParams: { networkId },
                  }),
                });
              }}
            >
              Edit
            </Button>
          </>
        ),
      }}
      dataTableProps={{
        loading: isDeviceQueryEnabled && isLoading,
        headers,
        items: data?.data ?? [],
        initialSort: NETWORK_ENTITY_INITIAL_SORT,
        onSortChange: updateTableState,
        onPaginationChange: updateTableState,
        pagination: {
          page: tableState.page,
          itemsPerPage: tableState.itemsPerPage,
          total: data?.total_entries ?? 0,
        },
      }}
    />
  );
};
