import config from "#src/config";
import { getTimeStringFromDate } from "#utils/timeFormatter";
import { Title } from "@validereinc/common-components";
import moment from "moment";
import React, { Component } from "react";
import DayPicker from "react-day-picker/DayPicker";
import { AssertIsBeforeDate, AssertIsSameDate } from "../../../utils/assert";
import "./WorkflowPreview.css";

/* eslint-disable react/prop-types */

const Midnight = "00:00:00";

const EventStyles = {
  pending: {
    backgroundColor: "#ffe0b3",
  },
  create: {
    backgroundColor: "#d7e6f9",
  },
  none: {
    backgroundColor: "#fff",
  },
};

class WorkflowPreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      redirect: false,

      alertVisible: false,
      alertType: "info",
      alertMessage: "",

      schedulePreview: {},
    };
    this.getNextCreateDate = this.getNextCreateDate.bind(this);
    this.setSchedulePreview = this.setSchedulePreview.bind(this);
    this.renderDay = this.renderDay.bind(this);
    this.renderInitialTaskPreviewMessage =
      this.renderInitialTaskPreviewMessage.bind(this);
  }

  componentDidMount() {
    this.setSchedulePreview(this.props.scheduleInput);
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      this.setSchedulePreview(this.props.scheduleInput);
    }
  }

  setSchedulePreview(scheduleInput) {
    const startDate = this.getStartDate(scheduleInput);

    if (startDate) {
      const nextEventDate = this.getNextCreateDate(startDate, scheduleInput);
      const deadlineDate = this.getDeadline(startDate, scheduleInput);

      const schedulePreview = {
        startDate: startDate,
        nextEventDate: nextEventDate,
        deadlineDate: deadlineDate,
      };

      this.setState({
        schedulePreview: schedulePreview,
      });
    }
  }

  getStartDate(scheduleInput) {
    const startDate = moment(scheduleInput.startDay).startOf("day");
    const frequencyType = scheduleInput.frequencySelected;

    if (frequencyType === "Month") {
      return startDate;
    }

    if (frequencyType === "Week") {
      const dayOfStartDay = moment(startDate).day();
      const repeatsDay = scheduleInput.repeatsDay;

      if (repeatsDay.includes(dayOfStartDay)) {
        return startDate;
      } else {
        const frequency = 1;
        return this.getNextWeeklyCreateDate(
          startDate,
          scheduleInput,
          frequency
        );
      }
    }
  }

  getNextCreateDate(startDate, scheduleInput) {
    const occurrences = this.getOccurrences(scheduleInput);

    if (occurrences && occurrences < 1) {
      return null;
    }

    if (scheduleInput.frequencySelected === "Month") {
      return moment(startDate).add(scheduleInput.repeats, "months");
    }

    if (scheduleInput.frequencySelected === "Week") {
      const frequency = scheduleInput.repeats;
      const nextCreateEvent = this.getNextWeeklyCreateDate(
        startDate,
        scheduleInput,
        frequency
      );

      return nextCreateEvent;
    }
  }

  getDeadline(startDate, scheduleInput) {
    const occurrences = this.getOccurrences(scheduleInput);

    if (!occurrences) {
      return null;
    }

    if (occurrences > 0 && scheduleInput.frequencySelected === "Month") {
      // Subtract by 1 since the first workflow is been scheduled
      const occurrencesLeft = occurrences - 1;
      const lastCreateEventMonth = scheduleInput.repeats * occurrencesLeft;
      const deadlineMonth = lastCreateEventMonth + scheduleInput.repeats;

      const deadline = moment(startDate).add(deadlineMonth, "months");
      return deadline;
    }

    if (occurrences > 0 && scheduleInput.frequencySelected === "Week") {
      let lastCreateEventDate = null;
      const startDay = moment(startDate).day();
      const createEventOnFirstWeek = this.getFirstWeekCreateEvent(
        startDay,
        scheduleInput.repeatsDay,
        occurrences
      );
      const lastCreateEventOnFirstWeek = createEventOnFirstWeek.pop();

      if (lastCreateEventOnFirstWeek) {
        const occurrencesLeft = lastCreateEventOnFirstWeek.occurrencesLeft;

        if (occurrencesLeft === 0) {
          // if there is no occurrences left on the first week then we know
          // the last create event is on the first week
          lastCreateEventDate = moment(startDate).day(
            lastCreateEventOnFirstWeek.day
          );
        } else {
          const createEventInWeek = scheduleInput.repeatsDay;
          const numCreateEventInWeek = createEventInWeek.length;
          const lastCreateEventWeek =
            Math.ceil(occurrencesLeft / numCreateEventInWeek) *
            scheduleInput.repeats;

          let lastCreateEventDay = null;
          const numOfCreateEventOnLastCreateEventWeek =
            occurrencesLeft % numCreateEventInWeek;

          if (numOfCreateEventOnLastCreateEventWeek) {
            lastCreateEventDay =
              createEventInWeek[(occurrencesLeft % numCreateEventInWeek) - 1];
          } else {
            lastCreateEventDay =
              createEventInWeek[createEventInWeek.length - 1];
          }

          lastCreateEventDate = moment(startDate)
            .day(lastCreateEventDay)
            .add(lastCreateEventWeek, "weeks");
        }
      }

      const deadline = this.getNextCreateDate(
        lastCreateEventDate,
        scheduleInput
      );
      return deadline;
    }
  }

  renderDay(day) {
    const date = day.getDate();
    const scheduleInput = this.props.scheduleInput;
    const startDate = this.state.schedulePreview.startDate;
    const deadlineDate = this.state.schedulePreview.deadlineDate;
    const dayType = this.getRenderDayType(
      day,
      startDate,
      deadlineDate,
      scheduleInput
    );

    return (
      <div className="dayCell">
        <div
          className="dayCell__date"
          style={EventStyles[dayType]}
        >
          {date}
        </div>
      </div>
    );
  }

  getRenderDayType(day, startDate, deadlineDate, scheduleInput) {
    if (
      !this.validScheduleInput(scheduleInput) ||
      this.getOccurrences(scheduleInput) === 0
    ) {
      return "none";
    }

    if (AssertIsSameDate(startDate, day, "day")) {
      return "create";
    }

    if (AssertIsBeforeDate(day, startDate)) {
      return "none";
    }

    if (AssertIsBeforeDate(day, deadlineDate) || !deadlineDate) {
      const frequencyType = scheduleInput.frequencySelected;
      if (frequencyType === "Month") {
        const createDate = this.getPrevMonthlyCreateDate(
          day,
          startDate,
          scheduleInput.repeats
        );

        return this.getType(day, createDate);
      }

      if (frequencyType === "Week") {
        const createDate = this.getPrevWeeklyCreateDate(
          day,
          startDate,
          scheduleInput
        );

        return this.getType(day, createDate);
      }
    }

    return "none";
  }

  getPrevMonthlyCreateDate(day, startDate, repeats) {
    const monthOfRenderDay = moment(day).month();
    const monthOfStartDay = moment(startDate).month();

    const dateOfStartDate = moment(startDate).date();
    const dateOfRenderDay = moment(day).date();

    const reminder = this.calculateReminder(
      monthOfRenderDay,
      monthOfStartDay,
      repeats
    );

    // If the renderDay day is on the month of create event when we just return
    // the date of start date as the create date. If the renderDay is not on
    // the month of create event then we find the previous month that contant
    // create event
    if (reminder === 0 && dateOfStartDate > dateOfRenderDay) {
      return moment(day).date(dateOfStartDate);
    } else {
      return moment(day)
        .month(monthOfRenderDay - reminder)
        .date(dateOfStartDate);
    }
  }

  getPrevWeeklyCreateDate(day, startDate, scheduleInput) {
    const weekOfRenderDay = moment(day).week();
    const weekOfStartDay = moment(startDate).week();
    const dayOfRenderDay = moment(day).day();
    const numDayInWeek = 7;

    const reminder = this.calculateReminder(
      weekOfRenderDay,
      weekOfStartDay,
      scheduleInput.repeats
    );

    if (reminder === 0) {
      // if the reminder is 0 then we know there is create event is on
      // this week.
      const repeatsDay = scheduleInput.repeatsDay;
      if (repeatsDay.includes(dayOfRenderDay)) {
        return day;
      }

      // find previous create event on this week to determine
      // what current day is (late or pending)
      const prevCreateDateOnSameWeek = this.getPrevCreateDateThisWeek(
        day,
        dayOfRenderDay,
        scheduleInput
      );
      if (prevCreateDateOnSameWeek) {
        return prevCreateDateOnSameWeek;
      } else {
        const prevCreateWeek = moment(day).week(
          weekOfRenderDay - scheduleInput.repeats
        );
        const numDayInWeek = 7;
        const prevCreateDateOfPreviousWeek = this.getPrevCreateDateThisWeek(
          prevCreateWeek,
          numDayInWeek,
          scheduleInput
        );
        return prevCreateDateOfPreviousWeek;
      }
    } else {
      // if the reminder is not 0 then we need to find previous weeks that contain
      // create events so we can use the create event to determine what current
      // day type
      const prevCreateWeek = moment(day).week(weekOfRenderDay - reminder);
      const prevCreateDateOfPreviousWeek = this.getPrevCreateDateThisWeek(
        prevCreateWeek,
        numDayInWeek,
        scheduleInput
      );
      return prevCreateDateOfPreviousWeek;
    }
  }

  getNextWeeklyCreateDate(startDate, scheduleInput, frequency) {
    // look forward in the week to find the next create date
    let nextCreateEvent = null;
    const dayOfStartDay = moment(startDate).day();

    scheduleInput.repeatsDay.forEach((dayNum) => {
      const eventDate = moment(startDate).day(dayNum);
      if (dayNum <= dayOfStartDay) {
        eventDate.add(frequency, "week");
      }
      if (
        nextCreateEvent === null ||
        AssertIsBeforeDate(eventDate, nextCreateEvent, "day")
      ) {
        nextCreateEvent = eventDate;
      }
    });

    return nextCreateEvent;
  }

  getPrevCreateDateThisWeek(day, dayOfRenderDay, scheduleInput) {
    // look backwards in the week to find previous create date within the week
    for (let i = scheduleInput.repeatsDay.length - 1; i >= 0; i--) {
      const dayNum = scheduleInput.repeatsDay[i];
      if (dayNum <= dayOfRenderDay) {
        return moment(day).day(dayNum);
      }
    }
  }

  getType(day, createDate) {
    if (AssertIsSameDate(day, createDate, "day")) {
      return "create";
    }

    return "pending";
  }

  getOccurrences(scheduleInput) {
    return scheduleInput.occurrences;
  }

  getFirstWeekCreateEvent(startDay, repeatsDay, occurrences) {
    const firstWeekCreateEvent = [];

    repeatsDay.forEach((day) => {
      if (day >= startDay && occurrences > 0) {
        occurrences--;
        firstWeekCreateEvent.push({ day, occurrencesLeft: occurrences });
      }
    });
    return firstWeekCreateEvent;
  }

  calculateReminder(monthOfRenderDay, monthOfStartDay, frequency) {
    const monthDifference = Math.abs(monthOfRenderDay - monthOfStartDay);
    const reminder = monthDifference % frequency;

    return reminder;
  }

  validScheduleInput(scheduleInput) {
    const frequencyType = scheduleInput.frequencySelected;
    const repeats = scheduleInput.repeats;

    if (scheduleInput.startDay) {
      if (frequencyType === "Month") {
        return repeats > 0;
      }

      if (frequencyType === "Week") {
        const numCreateEventInWeek = scheduleInput.repeatsDay;

        return repeats > 0 && numCreateEventInWeek.length > 0;
      }
    }
  }

  renderInitialTaskPreviewMessage() {
    if (
      this.state.schedulePreview?.startDate &&
      this.getOccurrences(this.props.scheduleInput) !== 0
    ) {
      const startDate = this.state.schedulePreview.startDate;
      const startDateString = getTimeStringFromDate(
        startDate,
        config.DATEMONTH_FORMAT
      );
      const startDateTimeZone = getTimeStringFromDate(startDate, "z");

      return (
        <p>
          Your tasks will initiate on
          <br />
          <b>{startDateString + " " + Midnight + " " + startDateTimeZone}.</b>
        </p>
      );
    }

    return null;
  }

  renderNextTaskPreviewMessage() {
    if (this.state.schedulePreview?.nextEventDate) {
      const nextEventDate = this.state.schedulePreview.nextEventDate;
      const nextEventDateString = getTimeStringFromDate(
        nextEventDate,
        config.DATEMONTH_FORMAT
      );
      const nextEventTimeZone = getTimeStringFromDate(nextEventDate, "z");
      const occurrences = this.getOccurrences(this.props.scheduleInput);
      if (occurrences === 0 || occurrences === 1) {
        return null;
      } else {
        return (
          <p>
            New tasks will be created on
            <br />
            <b>
              {nextEventDateString + " " + Midnight + " " + nextEventTimeZone}
            </b>
            <br />
            and incomplete tasks will be marked as missed.
          </p>
        );
      }
    }

    return null;
  }

  render() {
    const initialMonth = moment(this.props.initialMonth).toDate();

    return (
      <div className="workflowPreview">
        <Title
          type="subheader"
          className="workflowPreview__title"
        >
          Workflow Preview
        </Title>

        <DayPicker
          month={initialMonth}
          renderDay={this.renderDay}
        />

        <div className="workflowPreview__messageContainer">
          {this.validScheduleInput(this.props.scheduleInput) ? (
            <React.Fragment>
              {this.renderInitialTaskPreviewMessage()}
              {this.renderNextTaskPreviewMessage()}
            </React.Fragment>
          ) : (
            <p>Start scheduling by selecting Start Date.</p>
          )}
        </div>
      </div>
    );
  }
}
export default WorkflowPreview;
