import {
  useDeviceFilters,
  useEquipmentFilters,
  useFacilityFilters,
  useFlowFilters,
} from "./";

export const useFilters = (assetType: string) => {
  switch (assetType) {
    case "facility":
      return useFacilityFilters();
    case "device":
      return useDeviceFilters();
    case "equipment":
      return useEquipmentFilters();
    case "flow":
      return useFlowFilters();
    default:
      return [];
  }
};
