import { useEffect, useState } from "react";
import EmissionService from "../Services/EmissionService";
import useQueryParams from "#routers/useQueryParams";

export function useEmissionsReportDetail(id) {
  const [emissionReportDetail, setEmissionReportDetail] = useState({});
  const [fetchState, setFetchState] = useState("loading");
  const [lastFetchTime, setLastFetchTime] = useState(new Date());

  const updateEmissionReportDetail = () => {
    setLastFetchTime(new Date());
  };

  useEffect(() => {
    if (id) {
      EmissionService.getEmissionReportDetail(id)
        .then(({ data }) => {
          setEmissionReportDetail(data);
          setFetchState("loaded");
        })
        .catch(() => {
          setFetchState("error");
        });
    }
  }, [lastFetchTime]);

  return [emissionReportDetail, fetchState, updateEmissionReportDetail];
}

export function useEmissionsReportSummary(id, defaultDateRange) {
  const [emissionReportSummary, setEmissionReportSummary] = useState([]);
  const [fetchState, setFetchState] = useState("loading");
  const [lastFetchTime, setLastFetchTime] = useState(new Date());
  const [queryParams] = useQueryParams(defaultDateRange);

  const updateEmissionReportSummary = () => {
    setLastFetchTime(new Date());
  };

  useEffect(() => {
    if (id) {
      EmissionService.getEmissionReportSummary(
        id,
        queryParams.from,
        queryParams.until
      )
        .then(({ data }) => {
          setEmissionReportSummary(data);
          setFetchState("loaded");
        })
        .catch(() => {
          setFetchState("error");
        });
    }
  }, [lastFetchTime, queryParams]);

  return [emissionReportSummary, fetchState, updateEmissionReportSummary];
}

export function useEmissionDetail(id, defaultDateRange) {
  const [facilitySummary, setFacilitySummary] = useState([]);
  const [fetchState, setFetchState] = useState("loading");
  const [lastFetchTime, setLastFetchTime] = useState(new Date());
  const [queryParams] = useQueryParams(defaultDateRange);

  const updateFacilitySummary = () => {
    setLastFetchTime(new Date());
  };

  useEffect(() => {
    if (id) {
      EmissionService.getEmissionFacilityDetail(
        id,
        queryParams.from,
        queryParams.until
      )
        .then(({ data }) => {
          setFacilitySummary(data.data);
          setFetchState("loaded");
        })
        .catch(() => {
          setFetchState("error");
        });
    }
  }, [lastFetchTime, queryParams]);

  return [facilitySummary, fetchState, updateFacilitySummary];
}
