import useCarbonFacilities from "#components/hooks/useCarbonFacilities";
import useDeviceTypes from "#components/hooks/useDeviceTypes";
import useMeasurementTypes from "#components/hooks/useMeasurementTypes";
import useLocalization from "#src/hooks/useLocalization";
import { DropdownInput, TextInput } from "@validereinc/common-components";
import { Device, DeviceType } from "@validereinc/domain";
import * as PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

const AxisFields = ({ form, axis, formValues }) => {
  const [facilityOptions, facilityOptionLoadingState] = useCarbonFacilities();
  const [deviceTypeOptions, deviceTypeOptionsLoadingState] = useDeviceTypes();
  const [measurementTypes] = useMeasurementTypes();

  const [deviceOptions, setDeviceOptions] = useState<DeviceType[]>();
  const [deviceMeasurementOptions, setDeviceMeasurementOptions] = useState();

  const { localize } = useLocalization();

  const validateDevices = () => {
    const totalLength =
      (formValues?.leftAxis?.device?.length || 0) +
      (formValues?.rightAxis?.device?.length || 0);

    if (totalLength > 10) {
      return `Only up to 10 devices${
        formValues?.rightAxis?.device?.length ? " across both axes" : ""
      } are allowed.`;
    }
  };

  const onDeviceChange = (newDevices) => {
    let newMeasurementTypeOptions = [];

    if (newDevices?.length) {
      const selectedMeasurementTypes = newDevices.reduce((total, deviceId) => {
        const newValue = [...total];

        deviceOptions
          ?.find(({ id }) => id === deviceId)
          ?.measurement_types.forEach((measurement_type) => {
            if (total.includes(measurement_type)) {
              return;
            }

            newValue.push(measurement_type);
          });

        return newValue;
      }, []);

      newMeasurementTypeOptions = measurementTypes.filter(({ id }) =>
        selectedMeasurementTypes?.includes(id)
      );
    }

    setDeviceMeasurementOptions(newMeasurementTypeOptions);

    if (
      !newMeasurementTypeOptions?.find(
        ({ id }) => id === formValues?.[`${axis}Axis`]?.device_measurement
      )
    ) {
      form.setValue(`${axis}Axis.device_measurement`, undefined);
    }
  };

  const isRightAxisRequired =
    axis === "right" && formValues?.rightAxis
      ? Object.values(formValues?.rightAxis)?.some((value) =>
          Array.isArray(value) ? value.length : value
        )
      : false;

  useEffect(() => {
    if (
      formValues?.[`${axis}Axis`]?.device_type &&
      formValues?.[`${axis}Axis`]?.facilities
    ) {
      (async () => {
        const { data: devices } = await Device.getList({
          pageSize: 1000,
          filters: {
            type_id: formValues?.[`${axis}Axis`]?.device_type,
            "facility.id": { $in: formValues?.[`${axis}Axis`]?.facilities },
          },
        });

        setDeviceOptions(devices);

        form.setValue(
          `${axis}Axis.device`,
          formValues?.[`${axis}Axis`]?.device?.filter((deviceId) =>
            devices.find(({ id }) => id === deviceId)
          )
        );
      })();
    } else {
      setDeviceOptions([]);

      form.setValue(`${axis}Axis.device`, []);
    }
  }, [
    formValues?.[`${axis}Axis`]?.device_type,
    formValues?.[`${axis}Axis`]?.facilities,
  ]);

  useEffect(() => {
    if (formValues?.[`${axis}Axis`]?.device?.length) {
      const selectedMeasurementTypes = formValues?.[
        `${axis}Axis`
      ].device.reduce((total, deviceId) => {
        const newValue = [...total];

        deviceOptions
          ?.find(({ id }) => id === deviceId)
          ?.measurement_types.forEach((measurement_type) => {
            if (total.includes(measurement_type)) {
              return;
            }

            newValue.push(measurement_type);
          });

        return newValue;
      }, []);

      setDeviceMeasurementOptions(
        measurementTypes.filter(({ id }) =>
          selectedMeasurementTypes?.includes(id)
        )
      );
    }
  }, [deviceOptions, measurementTypes, formValues?.[`${axis}Axis`]?.device]);

  return (
    <>
      <DropdownInput
        name={`${axis}Axis.variant`}
        label="Style"
        isRequired={axis === "left" || isRightAxisRequired}
        isFluid
        valueKey="value"
        labelKey="label"
        defaultValue="line"
        options={[
          { value: "line", label: "Line" },
          { value: "scatter-plot", label: "Scatter Plot" },
        ]}
        isClearable={false}
      />

      <DropdownInput
        name={`${axis}Axis.facilities`}
        label={localize("facility_plural")}
        isRequired={axis === "left" || isRightAxisRequired}
        isFluid
        isSearchable
        isMulti
        valueKey="id"
        labelKey="name"
        options={facilityOptions}
        isLoading={facilityOptionLoadingState === "loading"}
      />

      <DropdownInput
        name={`${axis}Axis.device_type`}
        label="Device Type"
        isRequired={axis === "left" || isRightAxisRequired}
        isFluid
        valueKey="id"
        labelKey="name"
        options={deviceTypeOptions}
        isLoading={deviceTypeOptionsLoadingState === "loading"}
      />

      <DropdownInput
        name={`${axis}Axis.device`}
        label="Device"
        isRequired={axis === "left" || isRightAxisRequired}
        isFluid
        isSearchable
        isMulti
        valueKey="id"
        labelKey="name"
        options={deviceOptions}
        onChange={onDeviceChange}
        validate={(value) => validateDevices({ value, axis: "left" })}
        isLoading={!deviceOptions}
        isDisabled={
          !formValues?.[`${axis}Axis`]?.device_type?.length ||
          !formValues?.[`${axis}Axis`]?.facilities?.length
        }
      />

      <DropdownInput
        name={`${axis}Axis.device_measurement`}
        label="Device Measurement"
        isRequired={axis === "left" || isRightAxisRequired}
        isFluid
        valueKey="id"
        labelKey="name"
        options={deviceMeasurementOptions}
        isLoading={!deviceMeasurementOptions}
        isDisabled={!formValues?.[`${axis}Axis`]?.device?.length}
      />

      <TextInput
        label="Upper Limit"
        name={`${axis}Axis.upper_limit`}
        type="number"
      />

      <TextInput
        label="Lower Limit"
        name={`${axis}Axis.lower_limit`}
        type="number"
      />
    </>
  );
};

AxisFields.propTypes = {
  form: PropTypes.object.isRequired,
  axis: PropTypes.string.isRequired,
  formValues: PropTypes.object.isRequired,
};

export default AxisFields;
