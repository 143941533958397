import { EquipmentDropdownInput } from "#batteries-included-components/Dropdowns/EquipmentDropdownInput";
import { FacilityDropdownInput } from "#batteries-included-components/Dropdowns/FacilityDropdownInput";
import { useCustomAttributeFilters } from "#hooks/FilterPanel/useCustomAttributeFilters";
import {
  useListFlowProductCategories,
  useListFlowProductTypes,
  useListFlowTypes,
} from "#hooks/adapters/useFlows";
import {
  DropdownInput,
  FilterPanel,
  StorageKeys,
  TextInput,
} from "@validereinc/common-components";
import { AssetType, FlowStatus } from "@validereinc/domain";
import { toStartCaseString } from "@validereinc/utilities";
import React from "react";

export const NetworkFlowsFilterPanel = ({
  filterConfigStorageKey,
}: Pick<StorageKeys, "filterConfigStorageKey">) => {
  const flowTypes = useListFlowTypes().data ?? [];
  const productCategories = useListFlowProductCategories().data ?? [];
  const productTypes = useListFlowProductTypes().data ?? [];
  const { customAttributeFilters } = useCustomAttributeFilters({
    assetType: AssetType.FLOW,
  });

  return (
    <FilterPanel
      storageKey={filterConfigStorageKey}
      defaultActiveKeys={"Filters"}
      filters={[
        {
          component: (
            <TextInput
              name="name"
              key="flows-name-filter"
              placeholder="Search Flows..."
              type="search"
              isInline
            />
          ),
        },
        {
          section: "Filters",
          component: (
            <DropdownInput
              name="type"
              label="Flow Type"
              options={flowTypes}
              labelKey="name"
              valueKey="id"
              isMulti
              isFluid
              isSearchable
              isOptionalTextShown={false}
              key="flow-type-filter"
            />
          ),
        },
        {
          section: "Filters",
          component: (
            <DropdownInput
              key="status"
              name="status"
              label="Status"
              options={Object.values(FlowStatus).map((id) => ({
                id,
                name: toStartCaseString(id),
              }))}
              labelKey="name"
              valueKey="id"
              isMulti
              isFluid
              isSearchable
              isOptionalTextShown={false}
            />
          ),
        },
        {
          section: "Filters",
          component: (
            <DropdownInput
              name="product_category"
              label="Product Category"
              options={productCategories}
              labelKey="name"
              valueKey="id"
              isMulti
              isFluid
              isSearchable
              isOptionalTextShown={false}
              key="product-category-filter"
            />
          ),
        },
        {
          section: "Filters",
          component: (
            <DropdownInput
              name="product_type"
              label="Product Type"
              options={productTypes}
              labelKey="name"
              valueKey="id"
              isMulti
              isFluid
              isSearchable
              isOptionalTextShown={false}
              key="product-type-filter"
            />
          ),
        },
        {
          section: "Filters",
          component: (
            <FacilityDropdownInput
              key="associated_facility_id"
              name="associated_facility_id"
              isMulti
              isFluid
              isOptionalTextShown={false}
            />
          ),
        },
        {
          section: "Filters",
          component: (
            <EquipmentDropdownInput
              key="associated_equipment_id"
              name="associated_equipment_id"
              isMulti
              isFluid
              isOptionalTextShown={false}
            />
          ),
        },
        ...customAttributeFilters,
      ]}
    />
  );
};
