import {
  FlowType,
  GetOneEstimationMethodMetaType,
  ReportingGroupType,
  restAPI,
} from "../../adapters";
import { AssetTypeType, EquipmentType, FacilityType } from "../../schemas";
import { GetListResponseType, fetchAndCollate } from "../../util";

export type EstimationMethodAssetType = {
  asset_type: AssetTypeType;
  id: string;
  flow?: FlowType;
  equipment?: EquipmentType;
  facility?: FacilityType;
};

export type EstimationMethodDefaultRecordType = {
  method_id: string;
  input_name: string;
  measurement_type: string;
  asset: EstimationMethodAssetType;
  asset_id: string;
  reporting_group_id: string;
  reporting_group: ReportingGroupType;
  company_id: string;
  created_at: string;
  created_by: string;
  updated_at: string;
  updated_by: string;
};

export type EstimationMethodDefaultRecordMetaType = {
  entityType: AssetTypeType;
  inputName: string;
};

export type EstimationMethodDefaultRecordCreateType = Pick<
  EstimationMethodDefaultRecordType,
  "measurement_type" | "asset_id" | "reporting_group_id"
>;

export type EstimationMethodDefaultRecordUpdateType =
  Partial<EstimationMethodDefaultRecordCreateType>;

export const EstimationMethodDefaultRecordDomain: EstimationMethodDefaultRecordDomainType =
  {
    getList: ({ id, meta }) =>
      fetchAndCollate(({ page, pageSize }) =>
        restAPI.nodeAPI.GET({
          endpoint: `/estimation_methods/${meta.entityType}/${id}/default_records`,
          query: { page, page_size: pageSize },
        })
      ),
    create: ({ id, data, meta }) =>
      restAPI.nodeAPI.POST({
        endpoint: `/estimation_methods/${meta.entityType}/${id}/default_records/${meta.inputName}`,
        body: data,
      }),
    update: ({ id, data, meta }) =>
      restAPI.nodeAPI.PUT({
        endpoint: `/estimation_methods/${meta.entityType}/${id}/default_records/${meta.inputName}`,
        body: data,
      }),
  };

export interface EstimationMethodDefaultRecordDomainType {
  /*
   * List the default records for an estimation method
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/estimation_methods_default_records/list_estimation_method_default_record
   */
  getList: (params: {
    id: string;
    meta: GetOneEstimationMethodMetaType;
  }) => Promise<GetListResponseType<EstimationMethodDefaultRecordType>>;

  /*
   * Set the default record for an estimation method
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/estimation_methods_default_records/create_estimation_method_default_record
   */
  create: (params: {
    id: string;
    data: EstimationMethodDefaultRecordCreateType;
    meta: EstimationMethodDefaultRecordMetaType;
  }) => Promise<EstimationMethodDefaultRecordType>;

  /*
   * Update the default record for an estimation method
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/estimation_methods_default_records/update_estimation_method_default_record
   */
  update: (params: {
    id: string;
    data: EstimationMethodDefaultRecordUpdateType;
    meta: EstimationMethodDefaultRecordMetaType;
  }) => Promise<EstimationMethodDefaultRecordType>;
}
