import isArray from "lodash/isArray";
import {
  apiRequestServicesPromise,
  apiPutServicesPromise,
  apiDeleteServicesPromise,
  apiPostServicesPromise,
} from "./ServiceHelper";

const AlertService = {
  getAlertSpecs(type) {
    const parameter = {
      type: type,
    };

    return apiRequestServicesPromise("/api/v1/alert_specs", parameter);
  },

  getStreamAlertsSettings() {
    return apiRequestServicesPromise("/api/streams_alert_specs");
  },

  setAlertSpecs(measurementAlertSpecList) {
    const parameter = {
      measurement_alert_spec_list: measurementAlertSpecList,
    };

    return apiPostServicesPromise("/api/v1/alert_specs", parameter);
  },

  getSpecificAlertSpecSetting(streamId, property) {
    return apiRequestServicesPromise(
      `/api/streams/${streamId}/alert_specs/${property}`
    );
  },

  getSpecificInlineAlertSetting(streamId, property) {
    return apiRequestServicesPromise(
      `/api/streams/${streamId}/monitors/inline/${property}`
    );
  },

  getInlineMeasurementAlertSetting(streamList, property) {
    const stream_id = streamList.map((stream) => stream.id);
    return apiRequestServicesPromise("/api/streams/monitors/inline", {
      stream_id,
      property,
    });
  },

  setInlineMeasurementSetting(monitors) {
    return apiPutServicesPromise("/api/streams/monitors/inline", { monitors });
  },

  updateInlineMeasurementSettings(streamList, property, threshold) {
    const monitors = streamList.map((stream) => {
      return {
        stream_id: stream.id,
        property: property,
        interval_seconds: threshold.interval,
        num_offspec_threshold: threshold.offSpecThreshold,
        upper_limit: threshold.upperLimit || null,
        lower_limit: threshold.lowerLimit || null,
      };
    });

    return apiPutServicesPromise("/api/streams/monitors/inline", { monitors });
  },

  deleteInlineMeasurementSetting(monitors) {
    if (isArray(monitors)) {
      return apiDeleteServicesPromise(`/api/streams/monitors/inline`, {
        ids: monitors,
      });
    } else {
      return apiDeleteServicesPromise(
        `/api/streams/monitors/inline/${monitors}`
      );
    }
  },

  deleteInlineMeasurementSettings(monitors) {
    const ids = monitors.map((monitor) => monitor.id);
    return apiDeleteServicesPromise(`/api/streams/monitors/inline`, {
      ids: ids,
    });
  },
};

export default AlertService;
