export * from "./EventPage";

export const EVENT_DETAIL_PAGE_TITLE = "Event Details";

export const linkToEventDetails = (
  eventCategoryId = ":eventCategoryId",
  eventTemplateId = ":eventTemplateId",
  eventId = ":eventId"
) => {
  return `/app/events/event-categories/${eventCategoryId}/templates/${eventTemplateId}/events/${eventId}/details`;
};

export const EVENT_DETAIL_BREADCRUMB = {
  title: `${EVENT_DETAIL_PAGE_TITLE}:eventCategoryId:eventTemplateId:eventId`,
  path: linkToEventDetails,
};
