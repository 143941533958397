import { LegacyDataTable, Panel } from "@validereinc/common-components";
import classNames from "classnames/bind";
import * as PropTypes from "prop-types";
import React, { useMemo } from "react";
import { AutoSizer } from "react-virtualized";
import styles from "./ComponentsTable.module.scss";
const cx = classNames.bind(styles);

const PANEL_PADDING = 40;

const ComponentsTable = ({ components, componentProperties }) => {
  const Headers = [
    {
      label: "Properties",
      key: "name",
      width: 250,
      fixed: true,
    },
    {
      label: "Phase",
      key: "phase",
      width: 150,
    },
    {
      label: "Unit",
      key: "unit",
      width: 150,
    },
  ];

  const componentsList = useMemo(() => {
    let componentsList = [];
    if (components?.length) {
      componentsList = components.map((component) => ({
        name: component,
        phase: componentProperties[component]?.phase,
        unit: componentProperties[component]?.unit,
      }));
    }

    return componentsList;
  }, [components, componentProperties]);

  return (
    <Panel className={cx("container")}>
      <AutoSizer>
        {({ height, width }) => (
          <LegacyDataTable
            width={width}
            height={height - PANEL_PADDING}
            headers={Headers}
            list={componentsList}
            filterKey="name"
            filterTitle="Name"
            defaultSortBy="name"
            defaultSortDirection="asc"
          />
        )}
      </AutoSizer>
    </Panel>
  );
};

ComponentsTable.propTypes = {
  components: PropTypes.array,
  componentProperties: PropTypes.object,
};

export default ComponentsTable;
