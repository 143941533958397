import useDeviceTypes from "#components/hooks/useDeviceTypes";
import { linkToDeviceDetail, linkToFacilities } from "#routers/links";
import { ImportDataActionStatusWithQueue } from "#src/batteries-included-components/Banners/ImportDataActionStatus";
import { ImportDataAction } from "#src/batteries-included-components/Buttons/ImportDataAction";
import EditDeviceDialog from "#src/batteries-included-components/Dialogs/EditDeviceDialog";
import { FacilityDropdownInput } from "#src/batteries-included-components/Dropdowns/FacilityDropdownInput";
import { RoutingLink } from "#src/batteries-included-components/RoutingLink";
import { useTableSortingAndPagination } from "#src/components/Redux/reducers/tableStateReducer";
import { useCustomAttributeFilters } from "#src/components/hooks/FilterPanel/useCustomAttributeFilters";
import {
  SavedFilterTag,
  useSavedFilters,
} from "#src/components/hooks/FilterPanel/useSavedFilters";
import {
  useDeleteDevice,
  useExportDevices,
  useListDevices,
} from "#src/components/hooks/adapters/utils/useDevices";
import { DEVICE_STATUS_OPTIONS } from "#src/constants";
import { useIsFeatureAvailable } from "#src/contexts/AuthenticatedContext.helpers";
import useLocalization from "#src/hooks/useLocalization";
import { useStorageKey } from "#src/hooks/useStorageKey";
import {
  Button,
  DataTable,
  DataTablePanel,
  Dialog,
  DropdownInput,
  FilterPanel,
  HeaderType,
  SortingType,
  TextInput,
  useAlert,
  useFilters,
} from "@validereinc/common-components";
import {
  AssetType,
  Device,
  DeviceType,
  Resources,
  SortDirection,
} from "@validereinc/domain";
import { toStartCaseString } from "@validereinc/utilities";
import React, { useState } from "react";

const initialSort: SortingType = {
  sortBy: "name",
  sortDirection: SortDirection.ASCENDING,
};

const DeviceList = () => {
  const [isDataIngestionEnabled] = useIsFeatureAvailable({
    featureFlagQuery: "core:data_pipeline",
  });
  const { addAlert } = useAlert();
  const { tableConfigStorageKey, filterConfigStorageKey } =
    useStorageKey("devices");
  const [assetIdToEdit, setAssetIdToEdit] = useState<string | undefined>();
  const [deviceToDelete, setDeviceToDelete] = useState<DeviceType | undefined>(
    undefined
  );

  const [deviceTypes] = useDeviceTypes();

  const { localize } = useLocalization();

  const [filters, setFilters] = useFilters(filterConfigStorageKey);

  const [tableState, updateTableState] = useTableSortingAndPagination(
    initialSort,
    filters
  );

  const { savedFilter: _, ...cleanFilter } = filters;

  const { filterProps } = useSavedFilters({
    queryKey: [Resources.DEVICE],
    savedFilterResourceAdapter: Device.savedFilters,
    setFilters,
    tag: SavedFilterTag.LIST,
  });

  const devicesFilter = {
    page: tableState.page,
    pageSize: tableState.itemsPerPage,
    sortBy: tableState.sortBy,
    sortDirection: tableState.sortDirection,
    filters: cleanFilter,
  };

  const devicesQuery = useListDevices(devicesFilter, { enabled: true });

  const { customAttributeFilters, customAttributeHeaders, isLoading } =
    useCustomAttributeFilters({
      assetType: AssetType.DEVICE,
      section: "Device",
    });

  const headers: Array<HeaderType<DeviceType>> = [
    {
      label: "Device",
      key: "name",
      isSortable: true,
      renderComponent: ({ item }) => (
        <RoutingLink to={linkToDeviceDetail(item?.id)}>{item.name}</RoutingLink>
      ),
    },
    {
      label: "Device Type",
      key: "type_id",
      renderComponent: ({ item }) => item?.type?.name ?? "-",
      isSortable: true,
    },
    {
      label: localize("Facility"),
      key: "facility.name",
      renderComponent: ({ item }: { item: DeviceType }) => (
        <RoutingLink to={linkToFacilities(item?.facility_id)}>
          {item.facility.name}
        </RoutingLink>
      ),
    },
    {
      label: "Status",
      key: "status",
      isSortable: true,
      renderComponent: ({ item }) => (
        <DataTable.DataRow.PillCell
          variant={item.status === "active" ? "success" : "default"}
          value={toStartCaseString(item.status)}
        />
      ),
    },
    {
      label: "Created At",
      key: "created_at",
      isSortable: true,
      renderComponent: ({ item }) => (
        <DataTable.DataRow.DateCell value={item.created_at} />
      ),
    },
    ...customAttributeHeaders,
  ];

  const { mutate: handleExport, isLoading: isExporting } = useExportDevices({
    filters,
    sortBy: devicesFilter.sortBy,
    sortDirection: devicesFilter.sortDirection,
  });

  const { mutate: deleteDevice } = useDeleteDevice({
    onSuccess: () => {
      addAlert?.({
        variant: "success",
        message: `Successfully deleted '${deviceToDelete?.name ?? "Device"}'`,
      });
    },
    onError: () => {
      addAlert({
        variant: "error",
        message: "Failed to delete device",
      });
    },
    onSettled: () => {
      setDeviceToDelete(undefined);
    },
  });

  return (
    <>
      {isDataIngestionEnabled ? (
        <ImportDataActionStatusWithQueue
          resource={{
            id: "device",
            name: "Device",
            singular: "Device",
            plural: "Devices",
          }}
        />
      ) : null}
      <FilterPanel
        {...filterProps}
        defaultActiveKeys={["Filters"]}
        storageKey={filterConfigStorageKey}
        filters={[
          {
            component: (
              <TextInput
                name="name"
                placeholder="Search Devices..."
                type="search"
                isInline
                key="device-name"
              />
            ),
          },
          {
            section: "Filters",
            component: (
              <FacilityDropdownInput
                isFluid
                isMulti
                key="facility-name"
                isOptionalTextShown={false}
              />
            ),
          },
          {
            section: "Filters",
            component: (
              <DropdownInput
                label="Device Type"
                name="type_id"
                labelKey="name"
                valueKey="id"
                options={deviceTypes}
                isFluid
                isMulti
                isSearchable
                isOptionalTextShown={false}
              />
            ),
          },
          {
            section: "Filters",
            component: (
              <DropdownInput
                label="Status"
                name="status"
                options={DEVICE_STATUS_OPTIONS}
                labelKey="label"
                valueKey="value"
                isMulti
                isFluid
                isSearchable
                isOptionalTextShown={false}
              />
            ),
          },
          ...customAttributeFilters,
        ]}
      />
      <DataTablePanel
        storageKey={tableConfigStorageKey}
        panelProps={{
          title: "Devices",
          actionRow: [
            <Button
              key="export-devices"
              variant="outline"
              onClick={() => handleExport()}
              isLoading={isExporting}
            >
              Export
            </Button>,
            isDataIngestionEnabled ? (
              <ImportDataAction
                key="import-device"
                resource={{
                  id: "device",
                  name: "Device",
                  singular: "Device",
                  plural: "Devices",
                }}
              />
            ) : null,
          ],
        }}
        dataTableProps={{
          headers,
          items: devicesQuery.data?.data ?? [],
          isLoading: devicesQuery.isLoading || isLoading,
          onSortChange: updateTableState,
          sorting: initialSort,
          onPaginationChange: updateTableState,
          pagination: {
            page: tableState.page,
            itemsPerPage: tableState.itemsPerPage,
            total: devicesQuery.data?.total_entries ?? 0,
          },
          getItemActions: ({ item }) => [
            {
              label: "Edit",
              buttonProps: {
                onClick: () => setAssetIdToEdit(item.id),
                icon: "pencil-simple",
              },
            },
            {
              label: "Delete",
              buttonProps: {
                onClick: () => setDeviceToDelete(item),
                icon: "trash",
              },
            },
          ],
        }}
      />
      <Dialog
        isOpen={!!deviceToDelete}
        onClose={() => setDeviceToDelete(undefined)}
        title={`Delete "${deviceToDelete?.name}"`}
        actionRow={[
          <Button
            key="delete-dialog-action"
            onClick={() => {
              deleteDevice(deviceToDelete?.id ?? "");
            }}
            variant="error"
          >
            Delete
          </Button>,
        ]}
      >
        <div>
          Are you sure you want to delete this device? This action cannot be
          undone.
        </div>
      </Dialog>
      <EditDeviceDialog
        onClose={() => setAssetIdToEdit(undefined)}
        deviceId={assetIdToEdit}
        onEdit={() => {
          devicesQuery.refetch();
        }}
      />
    </>
  );
};

export default DeviceList;
