import {
  apiRequestServicesPromise,
  apiPutServicesPromise,
  apiPostServicesPromise,
} from "./ServiceHelper";
import config from "#src/config";
import { getUTCTimeString } from "#utils/timeFormatter";

const formatRoQUpdateParams = (recordId, roqSelectionState, approveRecord) => {
  const { selectionStreamId, intervalSelections } = roqSelectionState;

  const selection_intervals = intervalSelections.map((interval) => ({
    from: getUTCTimeString(interval.from, config.DATE_FORMAT),
    until: getUTCTimeString(interval.until, config.DATE_FORMAT),
    sample_ids: interval.sampleIds,
    previous_accounting_record_id: interval.previousAccountingRecordId ?? null,
    method: interval.method,
  }));

  return {
    record_id: recordId,
    selection_stream_id: selectionStreamId,
    selection_intervals,
    approve_record: approveRecord,
  };
};

const RecordOfQualityService = {
  getAccountingPeriods() {
    return apiRequestServicesPromise("/api/accounting_periods");
  },

  getRecordOfQualities(accountingPeriodId) {
    return apiRequestServicesPromise(
      `/api/accounting_periods/${accountingPeriodId}/records`
    );
  },

  getRecordOfQualitiesSample(stream_id, site_system_id, property, from, until) {
    return apiRequestServicesPromise(
      "/api/accounting_periods/records/chart/quality",
      { stream_id, site_system_id, property, from, until }
    );
  },

  getRecordOfQualitiesVolume(stream_id, site_system_id, property, from, until) {
    return apiRequestServicesPromise(
      "/api/accounting_periods/records/chart/volume",
      { stream_id, site_system_id, property, from, until }
    );
  },

  getSystemBalance(accountingPeriodId) {
    return apiRequestServicesPromise(
      `/api/accounting_periods/${accountingPeriodId}/system_balance`
    );
  },

  getRecordOfQualitiesSelection(stream_id, site_system_id, from, until) {
    return apiRequestServicesPromise(
      "/api/accounting_periods/records/table/quality",
      { stream_id, site_system_id, from, until }
    );
  },

  lockAccountingPeriod(accountingPeriodId) {
    return apiPutServicesPromise(
      `/api/accounting_periods/${accountingPeriodId}/lock`
    );
  },

  updateRecordOfQuality(recordId, roqSelectionState, approveRecord) {
    const updateParams = formatRoQUpdateParams(
      recordId,
      roqSelectionState,
      approveRecord
    );

    return apiPutServicesPromise(
      `/api/accounting_periods/records/${recordId}`,
      updateParams
    );
  },

  resetRecordOfQuality(recordId) {
    return apiPutServicesPromise(
      `/api/accounting_periods/records/${recordId}/reset`
    );
  },

  getDownloadSampleCompositionalLink(sampleId, type) {
    if (type === "compositional_analysis") {
      return apiRequestServicesPromise(
        `/api/samples/${sampleId}/compositional/pdf/url`
      );
    }

    if (type === "violation_letter") {
      return apiRequestServicesPromise(
        `/api/samples/${sampleId}/violation_letter/docx/url`
      );
    }

    return apiRequestServicesPromise(`/api/samples/${sampleId}/pdf/url`);
  },

  generateRecordOfQualityReport(recordOfQualityId) {
    return apiRequestServicesPromise(
      `/api/accounting_periods/records/${recordOfQualityId}/pdfs`
    );
  },

  generateRecombinedReport(fileName, accountingRecordIds) {
    const params = {
      file_name: fileName,
      accounting_record_ids: accountingRecordIds,
    };

    return apiPostServicesPromise(
      `/api/accounting_periods/records/recombined_pdfs`,
      params
    );
  },

  getRecordOfQualityModalResults(recordId, accountingPeriodId) {
    const recordDetail = apiRequestServicesPromise(
      `/api/accounting_periods/records/${recordId}`
    );

    const systemBalance = apiRequestServicesPromise(
      `/api/accounting_periods/${accountingPeriodId}/system_balance`
    );

    const historical = apiRequestServicesPromise(
      `/api/accounting_periods/records/${recordId}/historicals`
    );

    return Promise.all([recordDetail, systemBalance, historical]);
  },
};

export default RecordOfQualityService;
