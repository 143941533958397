import { AssetSchemaType } from "../schemas";
import {
  GetListRequestType,
  GetListResponseType,
  ResourceServiceType,
  fetchAndCollate,
  getFilterObject,
} from "../util";
import { restAPI } from "./api";

type AssetServiceType = Pick<ResourceServiceType<AssetSchemaType>, "getList">;

export const AssetAdapter: AssetServiceType = {
  /**
   * Asset Search API
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/assets/searchAssets
   */
  getList: ({
    filters,
    page,
    pageSize,
    sortBy,
    sortDirection,
  }: GetListRequestType) => {
    return fetchAndCollate<AssetSchemaType>(
      ({ page, pageSize }) =>
        restAPI.nodeAPI.POST<GetListResponseType<AssetSchemaType>>({
          endpoint: "/assets/search",
          body: {
            page,
            page_size: pageSize,
            sort_by: sortBy,
            sort_direction: sortDirection,
            filter: getFilterObject(filters),
          },
        }),
      page,
      pageSize
    );
  },
};
