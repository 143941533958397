import { z } from "zod";
import {
  DomainModelMetaExtendedSchema,
  DomainModelMetaSchema,
  DomainModelSchema,
} from "./DomainModelSchemas";

export const DatasetTypes = {
  BLOB: "blob",
  DOCUMENT: "document",
  TABULAR: "tabular",
  PARQUET: "parquet",
} as const;

export type DatasetTypeType = (typeof DatasetTypes)[keyof typeof DatasetTypes];

export const DatasetContexts = {
  PRODUCTION: "production",
  STAGING: "staging",
  DEVELOPMENT: "development",
  EXPERIMENTAL: "experimental",
  TEMPORARY: "temporary",
  FILE_UPLOAD: "file_upload",
} as const;

export type DatasetContextType =
  (typeof DatasetContexts)[keyof typeof DatasetContexts];

export const DatasetSchema = z
  .object({
    name: z.string(),
    namespace: z.string(),
    client_id: z.string(),
    company_id: z.string(),
    entity_id: z.string(),
    description: z.string(),
    tags: z.record(z.string(), z.string()),
    active: z.boolean(),
    data_product_id: z.string(),
    type: z.enum([
      DatasetTypes.BLOB,
      DatasetTypes.DOCUMENT,
      DatasetTypes.TABULAR,
      DatasetTypes.PARQUET,
    ]),
    context: z.enum([
      DatasetContexts.PRODUCTION,
      DatasetContexts.STAGING,
      DatasetContexts.DEVELOPMENT,
      DatasetContexts.EXPERIMENTAL,
      DatasetContexts.TEMPORARY,
      DatasetContexts.FILE_UPLOAD,
    ]),
    access_control: z.object({
      owners: z.array(z.string()),
      privileged: z.array(z.string()),
      readers: z.array(z.string()),
    }),
  })
  .merge(DomainModelSchema)
  .merge(DomainModelMetaSchema)
  .merge(DomainModelMetaExtendedSchema)
  .describe("Schema for datasets in the data platform");

export const DatasetGetListFilterSchema = DatasetSchema.omit({
  access_control: true,
  context: true,
  tags: true,
}).partial();

export type DatasetType = z.infer<typeof DatasetSchema>;
export type DatasetGetListFilterType = z.infer<
  typeof DatasetGetListFilterSchema
>;
