import { useListFacilities } from "#hooks/adapters/useFacilities";
import { useTableSortingAndPagination } from "#redux/reducers/tableStateReducer";
import useLocalization from "#src/hooks/useLocalization";
import { useStorageKey } from "#src/hooks/useStorageKey";
import {
  DataTablePanel,
  SortingType,
  StorageKeys,
  useFilters,
} from "@validereinc/common-components";
import {
  AssetGroupAssetType,
  AssetType,
  FacilityType,
  SortDirection,
} from "@validereinc/domain";
import { toFlattenedObject } from "@validereinc/utilities";
import classNames from "classnames/bind";
import React from "react";
import styles from "./FlowFormPanel.module.scss";

const cx = classNames.bind(styles);

const sorting: SortingType = {
  sortBy: "name",
  sortDirection: SortDirection.ASCENDING,
};

export const FlowFacilitySelectionTablePanel = ({
  selectedAssets,
  addAssetToFlow,
  filterConfigStorageKey,
}: Pick<StorageKeys, "filterConfigStorageKey"> & {
  selectedAssets: Record<string, AssetGroupAssetType>;
  addAssetToFlow: (asset: AssetGroupAssetType, isUpstream: boolean) => void;
}) => {
  const { tableConfigStorageKey } = useStorageKey("flow-facility-selection");
  const { localize } = useLocalization();
  const [{ entity_type: _, ...filters }] = useFilters(filterConfigStorageKey);
  const [tableState, updateTableState] = useTableSortingAndPagination(
    sorting,
    filters
  );

  const { data, isLoading } = useListFacilities({
    page: tableState.page,
    pageSize: tableState.itemsPerPage,
    sortBy: tableState.sortBy,
    sortDirection: tableState.sortDirection,
    filters: toFlattenedObject(filters),
  });

  const items = data?.data ?? [];

  const addOrigin = (item: FacilityType) => {
    addAssetToFlow({ ...item, asset_type: AssetType.FACILITY }, true);
  };

  const addDestination = (item: FacilityType) => {
    addAssetToFlow({ ...item, asset_type: AssetType.FACILITY }, false);
  };

  const getItemActions = ({ item }: { item: FacilityType }) =>
    Object.keys(selectedAssets)?.includes(item.id)
      ? []
      : [
          {
            isOverflow: true,
            label: "Add as origin",
            buttonProps: {
              onClick: () => addOrigin(item),
            },
          },
          {
            isOverflow: true,
            label: "Add as destination",
            buttonProps: {
              onClick: () => addDestination(item),
            },
          },
        ];

  return (
    <div className={cx("streamSelectionContainer")}>
      <div className={cx("streamTableContainer")}>
        <DataTablePanel
          storageKey={tableConfigStorageKey}
          panelProps={{
            title: localize("facility"),
            description: `You may select up to one ${localize(
              "facility"
            )} as the origin and/or destination of your ${localize("flow")}.`,
          }}
          dataTableProps={{
            headers: [{ key: "name", label: `${localize("Facility")} Name` }],
            sorting,
            isLoading,
            items,
            pagination: {
              page: tableState.page,
              itemsPerPage: tableState.itemsPerPage,
              total: data?.total_entries ?? 0,
            },
            onSortChange: updateTableState,
            onPaginationChange: updateTableState,
            isFluid: false,
            getItemActions,
          }}
        />
      </div>
    </div>
  );
};
