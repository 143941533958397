import { NetworkFilterPanel } from "#batteries-included-components/Panels/FilterPanels/NetworkFilterPanel";
import { NetworkDataTablePanel } from "#batteries-included-components/Panels/TablePanels/NetworkDataTablePanel";
import { NetworkListRoute } from "#routes/organization/networks";
import { NetworkCreateRoute } from "#routes/organization/networks/create";
import { useNavigate } from "#src/Routers/hooks";
import { useStorageKey } from "#src/hooks/useStorageKey";
import { useBreadcrumbsFromRoute } from "#utils/route";
import { Button, Page } from "@validereinc/common-components";
import React from "react";

export const NetworkListPage = () => {
  const navigate = useNavigate();
  const storageKeys = useStorageKey("networks");

  const [breadcrumbs] = useBreadcrumbsFromRoute(NetworkListRoute);

  const onClickCreateNetwork = () => {
    navigate({
      pathname: NetworkCreateRoute.toLink(),
    });
  };

  const actionRow = (
    <Button
      variant="primary"
      onClick={onClickCreateNetwork}
    >
      Create Network
    </Button>
  );

  return (
    <Page
      title={NetworkListRoute.title}
      breadcrumbs={breadcrumbs}
      actionRow={actionRow}
    >
      <NetworkFilterPanel
        filterConfigStorageKey={storageKeys.filterConfigStorageKey}
      />
      <NetworkDataTablePanel {...storageKeys} />
    </Page>
  );
};
