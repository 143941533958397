import { addAlertMessage } from "#redux/actions/alertMessages";
import { AlertMessage as Alert } from "#redux/reducers/alertMessages";
import {
  AlertMessage,
  Button,
  Modal,
  Title,
} from "@validereinc/common-components";
import React, { Component } from "react";
import {
  Col,
  ControlLabel,
  Form,
  FormGroup,
  ProgressBar,
} from "react-bootstrap";
import { connect } from "react-redux";
import { ValidateTextInput } from "../../utils/formValidator";
import { getIconStyle } from "../../utils/styleCalculator";
import {
  getDateRangeFromDate,
  getDateTimeRangeFromDate,
} from "../../utils/timeFormatter";
import TextBoxInput from "../Common/Form/TextBoxInput";
import ChainOfCustodyService from "../Services/ChainOfCustodyService";
import "./ChainOfCustodyDetailModal.css";
import ChainOfCustodyFormModal from "./ChainOfCustodyFormModal";
import { ChainOfCustodyStateIcons } from "./ChainOfCustodyStateIcons";

/* eslint-disable react/prop-types */

const CustomFormInputs = (props) => (
  <FormGroup>
    <Col
      componentClass={ControlLabel}
      sm={props.titleCol}
    >
      {props.title}
    </Col>
    <Col sm={12 - props.titleCol}>{props.children}</Col>
  </FormGroup>
);

const SampleList = (props) => {
  if (props.samples && props.samples.length > 0) {
    return (
      <div className="chainOfCustodyDetailModal__sampleList">
        {props.samples.map(function (sample, index) {
          return (
            <div
              className="chainOfCustodyDetailModal__sampleListItem"
              key={index}
            >
              <div className="chainOfCustodyDetailModal__sampleListContents">
                <b>{sample.name}</b>
                <div>
                  <b>Type: </b>
                  {sample.type}
                </div>
                <div>
                  <b>Date: </b>
                  {getDateTimeRangeFromDate(sample.started_at, sample.ended_at)}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  } else {
    return null;
  }
};

const mapDispatchToProps = {
  addAlertMessage,
};

export class ChainOfCustodyDetailModal extends Component {
  constructor(props) {
    super(props);
    const { chainOfCustody } = this.props;
    this.state = {
      alertVisible: false,
      alertType: "info",
      alertMessage: "",

      showProgress: false,
      disableForm: false,
      showChainOfCustodyFormModal: false,

      inputs: {
        name: chainOfCustody ? chainOfCustody.name : "",
      },
    };

    this.nameInputChanged = this.nameInputChanged.bind(this);
    this.printChainOfCustodyFormButtonClicked =
      this.printChainOfCustodyFormButtonClicked.bind(this);
    this.updateChainOfCustodyFormButtonClicked =
      this.updateChainOfCustodyFormButtonClicked.bind(this);
    this.updateChainOfCustodyForm = this.updateChainOfCustodyForm.bind(this);
    this.handleErrorResponse = this.handleErrorResponse.bind(this);
    this.clearForm = this.clearForm.bind(this);
    this.modalClosed = this.modalClosed.bind(this);
    this.closeChainOfCustodyFormModal =
      this.closeChainOfCustodyFormModal.bind(this);
  }

  printChainOfCustodyFormButtonClicked() {
    this.setState({
      chainOfCustodyId: this.props.chainOfCustody.id,
      showChainOfCustodyFormModal: true,
    });
  }

  nameInputChanged(input) {
    const value = input.target.value;
    const inputs = this.state.inputs;
    inputs.name = value;

    this.setState({
      inputs: inputs,
    });
  }

  updateChainOfCustodyFormButtonClicked() {
    const inputs = this.state.inputs;
    const errorMessage = this.getFormError(inputs);

    if (errorMessage) {
      this.setState({
        showProgress: false,
        disableForm: false,
        alertVisible: true,
        alertType: "warning",
        alertMessage: errorMessage,
      });
    } else {
      this.updateChainOfCustodyForm(inputs);
    }
  }

  updateChainOfCustodyForm(inputs) {
    const COCId = this.props.chainOfCustody.id;
    const name = inputs.name;

    this.setState({
      showProgress: true,
      disableForm: true,
    });

    ChainOfCustodyService.updateChainOfCustodyForm(COCId, name)
      .then((_response) => {
        this.setState({
          inputs: inputs,
          showProgress: false,
          disableForm: false,
          alertVisible: false,
        });

        this.props.addAlertMessage(
          new Alert({
            type: "success",
            message: "Your Chain of Custody Form has been updated.",
          })
        );

        this.props.updateChainOfCustodyList();
      })
      .catch(() => {
        this.handleErrorResponse();
      });
  }

  closeChainOfCustodyFormModal() {
    this.setState({
      showChainOfCustodyFormModal: false,
    });
  }

  modalClosed() {
    this.clearForm();
    this.props.close();
  }

  clearForm() {
    const inputs = this.state.inputs;
    inputs.name = "";

    this.setState({
      showChainOfCustodyFormModal: false,

      inputs: inputs,
      showProgress: false,
      disableForm: false,
      alertVisible: false,
      alertType: "warning",
      alertMessage: "",
    });
  }

  getFormError(input) {
    const required = true;
    const allowSpecialCharacter = true;

    const checkName = ValidateTextInput(
      "Name",
      input.name,
      "string",
      required,
      allowSpecialCharacter
    );

    if (checkName) {
      return checkName;
    }

    return false;
  }

  handleErrorResponse() {
    this.setState({
      showProgress: false,
      disableForm: false,
    });
  }

  render() {
    const chainOfCustody = this.props.chainOfCustody;
    return (
      <Modal
        className="chainOfCustodyDetailModal"
        open={this.props.show}
        onClose={this.modalClosed}
      >
        <Modal.Header>
          <Title>{chainOfCustody.name}</Title>
        </Modal.Header>

        <Modal.Body>
          <div hidden={!this.state.showProgress}>
            <ProgressBar
              active
              now={100}
            />
          </div>

          {this.state.alertVisible && (
            <AlertMessage
              type={this.state.alertType}
              style={{ marginBottom: "15px" }}
            >
              <b>{this.state.alertMessage}</b>
            </AlertMessage>
          )}

          <Form horizontal>
            <TextBoxInput
              titleCol={3}
              title="Name"
              className="chainOfCustodyDetailModal__input"
              type="text"
              onChange={this.nameInputChanged}
              value={this.state.inputs.name ? this.state.inputs.name : ""}
              placeholder="name"
              disabled={this.state.disableForm}
            />

            <TextBoxInput
              titleCol={3}
              title="Date"
              className="chainOfCustodyDetailModal__input"
              type="text"
              value={getDateRangeFromDate(
                chainOfCustody.issued_at,
                chainOfCustody.received_at
              )}
              placeholder="date"
              disabled={true}
            />

            <CustomFormInputs
              titleCol={3}
              title="Status"
            >
              <div
                style={{
                  backgroundColor: getIconStyle(
                    ChainOfCustodyStateIcons,
                    chainOfCustody.state
                  ).color,
                  borderRadius: "5px",
                  color: "#ffffff",
                  display: "inline-block",
                  padding: "0 5px 0 5px",
                  marginTop: "5px",
                }}
              >
                {chainOfCustody.state}
              </div>
            </CustomFormInputs>

            <TextBoxInput
              titleCol={3}
              title="Issued by"
              className="chainOfCustodyDetailModal__input"
              type="text"
              value={chainOfCustody.user ? chainOfCustody.user.email : null}
              placeholder="Issued by"
              disabled={true}
            />

            <TextBoxInput
              titleCol={3}
              title="Issued to"
              className="chainOfCustodyDetailModal__input"
              type="text"
              value={chainOfCustody.type}
              placeholder="Issued to"
              disabled={true}
            />

            <TextBoxInput
              titleCol={3}
              title="Site"
              className="chainOfCustodyDetailModal__input"
              type="text"
              value={chainOfCustody.site ? chainOfCustody.site.name : null}
              placeholder="Site"
              disabled={true}
            />

            <TextBoxInput
              titleCol={3}
              title="Validere ID"
              className="chainOfCustodyDetailModal__input"
              type="text"
              value={chainOfCustody.code}
              placeholder="Validere ID"
              disabled={true}
            />

            <CustomFormInputs
              titleCol={3}
              title="Samples"
            >
              <SampleList samples={chainOfCustody.samples} />
            </CustomFormInputs>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="pull-left"
            onClick={this.modalClosed}
          >
            Close
          </Button>

          <Button
            onClick={this.printChainOfCustodyFormButtonClicked}
            style={{ marginRight: "10px" }}
          >
            Print/View COC Form
          </Button>

          <Button
            variant="primary"
            onClick={this.updateChainOfCustodyFormButtonClicked}
          >
            Update COC Form
          </Button>
        </Modal.Footer>

        <ChainOfCustodyFormModal
          show={this.state.showChainOfCustodyFormModal}
          hide={this.closeChainOfCustodyFormModal}
          chainOfCustodyId={this.state.chainOfCustodyId}
        />
      </Modal>
    );
  }
}

export default connect(null, mapDispatchToProps)(ChainOfCustodyDetailModal);
