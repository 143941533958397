import { getBreadcrumbsObject } from "#routers/breadcrumbsHelper";
import config from "#src/config";
import { getTimeStringFromDate } from "#utils/timeFormatter";
import {
  ControlLabel,
  MultiDropdownInputWithSearch,
  Page,
  Panel,
} from "@validereinc/common-components";
import differenceWith from "lodash/differenceWith";
import moment from "moment";
import React, { Component } from "react";
import { Col } from "react-bootstrap";
import FontAwesome from "react-fontawesome";
import { connect } from "react-redux";
import { v1 as uuidv1 } from "uuid";
import { GetAllQueryParam } from "../../Routers/historyHelper";
import { SAMPLE_TYPES } from "../../utils/enums";
import {
  addToAnalyze,
  removeFromAnalyze,
  updateAnalyze,
} from "../Redux/actions/analyze";
import { ensureStreamListIsFetched } from "../Redux/actions/index";
import { AnalyzeSpec } from "../Redux/reducers/analyze";
import {
  defaultMeasurementNames,
  measurementNames,
} from "../Redux/reducers/measurements";
import { havePermission } from "../Redux/reducers/permissions";
import SampleValidationModal from "../Samples/SampleValidationModal";
import AnalyzeService from "../Services/AnalyzeService";
import "./Analyze.css";
import AnalyzePanel from "./AnalyzePanel";
import BookmarkModal from "./BookmarkModal/BookmarkModal";
import StreamListModal from "./StreamListModal/StreamListModal";

/* eslint-disable react/prop-types */

const lodash = { differenceWith };

const ALL_SAMPLE_TYPES = Object.values(SAMPLE_TYPES);

const MAXIMUM_NUM_OF_STREAMS = 5;

const MAXIMUM_NUM_OF_MEASUREMENT_TYPE = 6;

const DEFAULT_DATE_RANGE = {
  start: moment().subtract(3, "month").utc().startOf("month"),
  end: moment(),
};

const mapStateToProps = (state) => {
  return {
    analyzeSpecs: state.analyze.specs,
    streams: state.streams,
    supportedMeasurementTypes: measurementNames(state.measurements),
    defaultMeasurementType: defaultMeasurementNames(state.measurements)[0],
    haveWritePermissions: havePermission(state.permissions)(
      "lite:analyze",
      "write"
    ),
  };
};

const mapDispatchToProps = {
  addToAnalyze,
  updateAnalyze,
  removeFromAnalyze,
  ensureStreamListIsFetched,
};

export class Analyze extends Component {
  constructor(props) {
    super(props);

    this.state = {
      streams: [],
      isAddStreamModalOpen: false,
      openBookmarkModal: false,
      analyzeBookmarks: [],
      bookmark: {},
      selectedSampleId: null,
    };
  }

  componentDidMount() {
    this.props.ensureStreamListIsFetched();
    this.getBookmarkList();

    // Getting bookmark ids from query param
    const bookmarkIds = this.getBookmarksIds();
    if (bookmarkIds.length > 0) {
      AnalyzeService.getAnalyzeSpecs(bookmarkIds).then(({ data }) => {
        data.map((bookmarkSetting) => {
          this.addBookmarkedAnalyzeSpec(bookmarkSetting);
        });
      });
    }
  }

  onBookmarkClicked = (bookmarkId) => {
    if (bookmarkId?.id) {
      AnalyzeService.getAnalyzeSpec(bookmarkId.id).then(({ data }) => {
        this.addBookmarkedAnalyzeSpec(data);
      });
    }
  };

  onStreamClicked = (stream) => {
    this.setState({
      isAddStreamModalOpen: false,
    });

    const analyzeSpec = {
      streams: [stream.id],
      sampleTypes: [...ALL_SAMPLE_TYPES],
      measurementType: [this.props.defaultMeasurementType],
    };

    this.updateAnalyzePanel(
      analyzeSpec,
      DEFAULT_DATE_RANGE.start,
      DEFAULT_DATE_RANGE.end
    );
  };

  onPanelClosed = (removedSpec) => {
    this.props.removeFromAnalyze(removedSpec.id);
  };

  onAddStreamClicked = () => {
    this.setState({
      isAddStreamModalOpen: true,
    });
  };

  onAddStreamClosed = () => {
    this.setState({
      isAddStreamModalOpen: false,
    });
  };

  onCreateBookmarkClicked = (analyzeSpec) => {
    this.setState({
      openBookmarkModal: true,
      bookmark: analyzeSpec,
    });
  };

  onCreateBookmarkClosed = () => {
    this.setState({
      openBookmarkModal: false,
      bookmark: null,
    });
  };

  getBookmarkList = () => {
    AnalyzeService.getAnalyzeBookmarks().then((analyzebookmarks) => {
      this.setState({
        analyzeBookmarks: analyzebookmarks.data,
      });
    });
  };

  getBookmarksIds() {
    const bookmarks = GetAllQueryParam("bookmark_ids", null, []);
    const analyzePanels = this.props.analyzeSpecs.toJS();

    // Check the current active analyzePanels id against incoming bookmarks ids
    // to see if there are bookmarks id needs to be added.
    return lodash.differenceWith(
      bookmarks,
      analyzePanels,
      (analyzePanel, bookmark) => {
        return analyzePanel.id === bookmark.id;
      }
    );
  }

  addBookmarkedAnalyzeSpec(bookmark) {
    const analyzeSpec = {
      id: bookmark.id,
      title: bookmark.title,
      measurementType: bookmark.measurement_types,
      sampleTypes: bookmark.sample_types,
      timeRangeStart: getTimeStringFromDate(
        bookmark.start_date,
        config.DATE_FORMAT
      ),
      timeRangeEnd: getTimeStringFromDate(
        bookmark.end_date,
        config.DATE_FORMAT
      ),
      relativeDayRange: bookmark.relative_day_range,
      streams: bookmark.stream_ids,
      isBookmarked: true,
    };

    this.updateAnalyzePanel(analyzeSpec);
  }

  updateAnalyzePanel = (analyzeSpec, timeRangeStart, timeRangeEnd) => {
    // At most 6 measurement types
    const measurementType = analyzeSpec.measurementType.slice(
      0,
      MAXIMUM_NUM_OF_MEASUREMENT_TYPE
    );

    // at most 5 streams
    const streams = analyzeSpec.streams.slice(0, MAXIMUM_NUM_OF_STREAMS);

    timeRangeStart = timeRangeStart
      ? timeRangeStart
      : analyzeSpec.timeRangeStart;

    timeRangeEnd = timeRangeEnd ? timeRangeEnd : analyzeSpec.timeRangeEnd;

    const title = analyzeSpec.title ? analyzeSpec.title : null;

    const analyzePanelId = analyzeSpec.id ? analyzeSpec.id : uuidv1();

    this.props.addToAnalyze(
      new AnalyzeSpec({
        id: analyzePanelId,
        title: title,
        timeRangeStart: getTimeStringFromDate(
          timeRangeStart,
          config.DATE_FORMAT
        ),
        timeRangeEnd: getTimeStringFromDate(timeRangeEnd, config.DATE_FORMAT),
        relativeDayRange: analyzeSpec.relativeDayRange,
        measurementType: [...measurementType],
        sampleTypes: analyzeSpec.sampleTypes,
        streams: [...streams],
        isBookmarked: analyzeSpec.isBookmarked,
      })
    );
  };

  onSampleModalToggle = (selectedSample) => {
    if (selectedSample) {
      this.setState({ selectedSampleId: selectedSample.id });
    } else {
      this.setState({ selectedSampleId: null });
    }
  };

  render() {
    const { analyzeSpecs, streams, supportedMeasurementTypes, breadcrumbs } =
      this.props;

    const {
      isAddStreamModalOpen,
      analyzeBookmarks,
      openBookmarkModal,
      bookmark,
      selectedSampleId,
    } = this.state;

    return (
      <Page
        title="Analyze"
        breadcrumbs={getBreadcrumbsObject(breadcrumbs)}
      >
        <div className="analyze">
          {analyzeBookmarks && analyzeBookmarks.length > 0 && (
            <div className="analyze__filters">
              <ControlLabel label="Bookmarks">
                <MultiDropdownInputWithSearch
                  width={100}
                  value={[]}
                  options={analyzeBookmarks}
                  labelKey="title"
                  onChange={this.onBookmarkClicked}
                  isMulti={false}
                />
              </ControlLabel>
            </div>
          )}

          {isAddStreamModalOpen && (
            <StreamListModal
              title="Add Stream"
              handleClose={this.onAddStreamClosed}
              onMatchClicked={this.onStreamClicked}
            />
          )}

          {analyzeSpecs.reverse().map((analyzeSpec) => (
            <AnalyzePanel
              key={analyzeSpec.id}
              analyzeSpec={analyzeSpec}
              availableStreams={streams}
              bookmarkEnabled={this.props.haveWritePermissions}
              onClosePanelClicked={() => this.onPanelClosed(analyzeSpec)}
              onBookmarkClicked={() =>
                this.onCreateBookmarkClicked(analyzeSpec)
              }
              maxNumOfMeasurementType={MAXIMUM_NUM_OF_MEASUREMENT_TYPE}
              onSampleModalToggle={this.onSampleModalToggle}
            />
          ))}

          {openBookmarkModal && this.props.haveWritePermissions && (
            <BookmarkModal
              show={openBookmarkModal}
              bookmark={bookmark}
              supportedMeasurementTypes={supportedMeasurementTypes}
              availableStreams={streams}
              updateAnalyzePanel={this.updateAnalyzePanel}
              removeAnalyzePanel={this.onPanelClosed}
              getBookmarkList={this.getBookmarkList}
              onHide={this.onCreateBookmarkClosed}
            />
          )}

          {selectedSampleId && (
            <SampleValidationModal
              selectedSampleId={selectedSampleId}
              onModalToggle={this.onSampleModalToggle}
            />
          )}

          <Col xs={12}>
            <Panel className="analyzeAddPanel">
              <div
                className="addPanel"
                onClick={this.onAddStreamClicked}
              >
                <span className="addPanel__text">
                  <FontAwesome
                    name="plus-circle"
                    style={{ fontSize: "20px" }}
                  />
                  <br />
                  Click me to add stream
                </span>
              </div>
            </Panel>
          </Col>
        </div>
      </Page>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Analyze);
