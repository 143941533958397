import React from "react";

import {
  ContentSwitcherInput,
  FilterPanel,
} from "@validereinc/common-components";
import { AssetType } from "@validereinc/domain";

import { useSearchParams } from "#routers/hooks";
import useLocalization from "#src/hooks/useLocalization";

export const NetworkFilterPanel = () => {
  const { localize } = useLocalization();
  const [searchParams, setSearchParams] = useSearchParams();

  const onFiltersChange = (
    filters: Record<string, string | Record<string, string> | undefined>
  ) => {
    const { entity } = filters;

    setSearchParams({
      ...searchParams,
      entity,
      ...(entity !== AssetType.FACILITY ? { facilities: undefined } : {}),
      ...(entity !== AssetType.EQUIPMENT ? { equipment: undefined } : {}),
      ...(entity !== AssetType.FLOW ? { flows: undefined } : {}),
      ...(entity !== AssetType.DEVICE ? { devices: undefined } : {}),
    });
  };

  return (
    <FilterPanel
      value={searchParams}
      onChange={onFiltersChange}
      filters={[
        {
          component: (
            <ContentSwitcherInput
              name="entity"
              isInline
              items={[
                { dataKey: AssetType.FLOW, label: localize("flow_plural") },
                {
                  dataKey: AssetType.FACILITY,
                  label: localize("facility_plural"),
                },
                {
                  dataKey: AssetType.EQUIPMENT,
                  label: localize("equipment_plural"),
                },
                { dataKey: AssetType.DEVICE, label: "Devices" },
              ]}
            />
          ),
        },
      ]}
    />
  );
};
