import { RecordValueSourceConfigurationPage } from "#batteries-included-components/Layouts/RecordConfiguration";
import { useBreadcrumbs } from "#routers/breadcrumbsHelper";
import { useNavigate, useParams } from "#routers/hooks";
import { ORGANIZATION_BREADCRUMB } from "#routes/organization";
import { EQUIPMENT_LIST_BREADCRUMB } from "#routes/organization/equipment";
import { EQUIPMENT_DETAIL_BREADCRUMB } from "#routes/organization/equipment/[equipmentId]";
import {
  EQUIPMENT_RECORD_DETAIL_BREADCRUMB,
  EQUIPMENT_RECORD_DETAIL_PAGE_TITLE,
} from "#routes/organization/equipment/[equipmentId]/record-details/[recordId]";
import {
  EQUIPMENT_RECORD_VALUE_SOURCE_BREADCRUMB,
  EQUIPMENT_RECORD_VALUE_SOURCE_PAGE_TITLE,
} from "#routes/organization/equipment/[equipmentId]/record-details/[recordId]/value/[measurementType]/add-source/index";
import {
  EquipmentContext,
  EquipmentProvider,
} from "#src/contexts/EquipmentContext";
import { RecordContext, RecordProvider } from "#src/contexts/RecordContext";
import { useQuery } from "@tanstack/react-query";
import { MeasurementsDomain } from "@validereinc/domain";
import { yearMonthName } from "@validereinc/utilities";
import React, { useContext } from "react";
import {
  EQUIPMENT_RECORD_VALUE_DETAIL_BREADCRUMB,
  EQUIPMENT_RECORD_VALUE_DETAIL_PAGE_TITLE,
  EquipmentRecordValuePageParamsType,
  linkToEquipmentRecordValueDetail,
} from "../index";

const EquipmentRecordValueSourcePageContent = () => {
  const navigate = useNavigate();
  const params = useParams<EquipmentRecordValuePageParamsType>();

  const { equipment } = useContext(EquipmentContext) || {};
  const { record } = useContext(RecordContext) || {};

  const { data: measurementTypes } = useQuery({
    queryKey: ["measurementTypes"],
    queryFn: MeasurementsDomain.listMeasurementTypes,
  });
  const measurementType = measurementTypes?.find(
    ({ id }) => id === params.measurementType
  );

  const recordPageTitle = record
    ? `${yearMonthName(record.year_month)} Record`
    : EQUIPMENT_RECORD_DETAIL_PAGE_TITLE;

  const recordValuePageTitle = record
    ? `${yearMonthName(record.year_month)} ${measurementType?.name}`
    : EQUIPMENT_RECORD_VALUE_DETAIL_PAGE_TITLE;

  const pageTitle = measurementType
    ? `Configure Source`
    : EQUIPMENT_RECORD_VALUE_SOURCE_PAGE_TITLE;

  const breadcrumbs = useBreadcrumbs(
    [
      ORGANIZATION_BREADCRUMB,
      EQUIPMENT_LIST_BREADCRUMB,
      EQUIPMENT_DETAIL_BREADCRUMB,
      EQUIPMENT_RECORD_DETAIL_BREADCRUMB,
      EQUIPMENT_RECORD_VALUE_DETAIL_BREADCRUMB,
      EQUIPMENT_RECORD_VALUE_SOURCE_BREADCRUMB,
    ],
    {
      2: equipment?.name,
      3: recordPageTitle,
      4: recordValuePageTitle,
      5: pageTitle,
    }
  );

  return (
    <RecordValueSourceConfigurationPage
      pageProps={{
        title: recordValuePageTitle,
        category: "Configure Source",
        breadcrumbs,
      }}
      onClose={() => {
        navigate({
          pathname: linkToEquipmentRecordValueDetail(
            params.equipmentId,
            params.recordId,
            params.measurementType
          ),
        });
      }}
    />
  );
};

export const EquipmentRecordValueSourcePage = () => (
  <EquipmentProvider>
    <RecordProvider>
      <EquipmentRecordValueSourcePageContent />
    </RecordProvider>
  </EquipmentProvider>
);
