import { TextInput } from "@validereinc/common-components";
import * as PropTypes from "prop-types";
import React from "react";
import { getSourceTitle } from "../BalanceHelper";
import SourceInput from "./SourceInput";

const SourceSelectionForm = ({
  sources,
  sourceAction,
  selectedSourceType,
  onSourceChecked,
}) => {
  return (
    <>
      <SourceInput
        title={getSourceTitle(selectedSourceType)}
        sources={sources}
        onSourceChecked={onSourceChecked}
        showMultiplier={sourceAction === "edit" || sourceAction === "delete"}
        isInventory={false}
      />

      <TextInput
        name="multiplier"
        label="Multiplier"
        type="number"
        unit="x"
        placeholder="multiplier"
        validate={{
          validateMultiplier: (value) =>
            value <= 0 ? "Multiplier must be positive number." : null,
        }}
        showIcon
        isRequired
      />
    </>
  );
};

SourceSelectionForm.propTypes = {
  sources: PropTypes.array.isRequired,
  sourceAction: PropTypes.string.isRequired,
  selectedSourceType: PropTypes.string,
  onSourceChecked: PropTypes.func,
};

export default SourceSelectionForm;
