import config from "#src/config";
import { getTimeStringFromDate } from "#utils/timeFormatter";
import { LegacyDataTable, Panel } from "@validereinc/common-components";
import * as PropTypes from "prop-types";
import React from "react";
import { AutoSizer } from "react-virtualized";
import "./ApiTokensTable.scss";

const TABLE_HEIGHT_OFFSET = 35;

const ApiTokensTable = ({
  apiTokens,
  height,
  onDeleteApiTokenButtonClick,
  hasWritePermission,
}) => {
  const dateRenderer = (rowData, columnKey) => {
    return getTimeStringFromDate(rowData[columnKey], config.DATETIME_FORMAT);
  };

  const nameRenderer = (rowData, columnKey) => {
    const name = rowData[columnKey];
    return name && name !== "" ? name : "-";
  };

  const headers = [
    {
      label: "API Token Name",
      key: "name",
      width: 175,
      cellRenderer: nameRenderer,
      fixed: true,
    },
    {
      label: "API Token ID",
      key: "id",
      width: 275,
      fixed: true,
    },
    {
      label: "Created On",
      key: "date",
      width: 175,
      cellRenderer: dateRenderer,
    },
    {
      label: "Last Used On",
      key: "last_used_at",
      width: 175,
      cellRenderer: dateRenderer,
    },
  ];

  const actionDropdown = hasWritePermission
    ? [
        {
          title: "Delete API Token",
          onClick: (rowData) => onDeleteApiTokenButtonClick(rowData),
        },
      ]
    : null;

  return (
    <Panel
      className="apiTokensTable"
      title="API Token List"
      style={{ height: height }}
    >
      <AutoSizer>
        {({ width, height }) => (
          <LegacyDataTable
            width={width}
            height={height - TABLE_HEIGHT_OFFSET}
            headers={headers}
            list={apiTokens}
            defaultSortBy="created_at"
            defaultSortDirection="desc"
            filterKey="name"
            actionDropdown={actionDropdown}
          />
        )}
      </AutoSizer>
    </Panel>
  );
};

ApiTokensTable.propTypes = {
  apiTokens: PropTypes.array.isRequired,
  height: PropTypes.number.isRequired,
  onDeleteApiTokenButtonClick: PropTypes.func.isRequired,
  hasWritePermission: PropTypes.bool.isRequired,
};

export default ApiTokensTable;
