import { Panel } from "@validereinc/common-components";
import filter from "lodash/filter";
import * as PropTypes from "prop-types";
import React from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import FontAwesome from "react-fontawesome";
import { connect } from "react-redux";
import { LinkContainer } from "react-router-bootstrap";
import { AutoSizer, Column } from "react-virtualized";
import { SetCleanHistoryPath } from "../../../../Routers/historyHelper";
import {
  linkToEditMember,
  linkToEditRoleSetting,
} from "../../../../Routers/links";
import { GetJSData } from "../../../../utils/immutableConverter";
import CustomTable from "../../../Common/Table/CustomTable";
import "./RoleTable.css";

const lodash = { filter };

const TABLE_HEIGHT_OFFSET = 300;

const MIN_TABLE_HEIGHT = 150;

const mapStateToProps = (state) => {
  return {
    profile: state.profiles,
  };
};

const RoleTable = (props) => {
  const { availableRoles, height, profile } = props;

  const members = GetJSData(profile).members;

  const roleWithMembers = availableRoles.map((role) => {
    return {
      ...role,
      members: lodash.filter(members, { state: "active", role: role.name }),
    };
  });

  const titleRenderer = ({ rowData }) => {
    return <div className="roleTable__nameCell">{rowData.name}</div>;
  };

  const editableRenderer = ({ rowData }) => {
    return (
      <div className="roleTable__editableCell">
        {rowData.editable ? "Customized" : "Default"}
      </div>
    );
  };

  const membersRenderer = ({ rowData }) => {
    if (rowData.members.length > 0) {
      const popover = (
        <Popover id="popover-trigger-click-root-close">
          <div
            className="roleTable__memberList"
            data-hj-suppress
          >
            <ul>
              {rowData.members.map((member, index) => (
                <ol
                  key={index}
                  onClick={() => redirectToMember(member.id)}
                >
                  <FontAwesome
                    className="icon"
                    name="user"
                  />
                  {member.name}
                </ol>
              ))}
            </ul>
          </div>
        </Popover>
      );

      return (
        <OverlayTrigger
          trigger="click"
          placement="bottom"
          overlay={popover}
          rootClose
        >
          <div className="roleTable__memberCell">
            <FontAwesome
              name="user"
              className="icon "
            />
            {rowData.members.length}
          </div>
        </OverlayTrigger>
      );
    } else {
      return (
        <div className="roleTable__memberCell">
          <FontAwesome
            name="user"
            className="icon "
          />
          {rowData.members.length}
        </div>
      );
    }
  };

  const editRenderer = ({ rowData }) => {
    return (
      <div className="roleTable__memberActionCell">
        <LinkContainer to={linkToEditRoleSetting(rowData.id)}>
          <FontAwesome name={rowData.editable ? "pencil-square-o" : "eye"} />
        </LinkContainer>
      </div>
    );
  };

  const redirectToMember = (memberId) => {
    SetCleanHistoryPath(linkToEditMember(memberId));
  };

  return (
    <Panel
      className="roleTable"
      style={{
        height: Math.max(height - TABLE_HEIGHT_OFFSET, MIN_TABLE_HEIGHT),
      }}
    >
      <AutoSizer>
        {({ width, height }) => (
          <CustomTable
            width={width}
            height={height}
            list={roleWithMembers}
            filterKey="name"
            filterTitle="Name"
            defaultSortBy="name"
            defaultSortDirection="asc"
            rowHeight={35}
          >
            <Column
              label="Role"
              dataKey="name"
              cellRenderer={titleRenderer}
              width={width / 3}
            />
            <Column
              label="Type"
              dataKey="editable"
              cellRenderer={editableRenderer}
              width={width / 3}
            />
            <Column
              label="Members"
              dataKey="members"
              cellRenderer={membersRenderer}
              width={width / 3}
              disableSort
            />
            <Column
              label="Action"
              dataKey="action"
              cellRenderer={editRenderer}
              width={width / 4}
              disableSort
            />
          </CustomTable>
        )}
      </AutoSizer>
    </Panel>
  );
};

RoleTable.propTypes = {
  availableRoles: PropTypes.array,
  height: PropTypes.number,
  profile: PropTypes.object,
  rowData: PropTypes.object,
};

const RoleTableContainer = connect(mapStateToProps, undefined)(RoleTable);

export default RoleTableContainer;
