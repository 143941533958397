import React, { useEffect, useMemo } from "react";

import {
  Button,
  Dialog,
  DropdownInput,
  Form,
  TextInput,
  useForm,
} from "@validereinc/common-components";

import { useCreateCompanyUnitConfig } from "#src/components/hooks/adapters/useCompanyUnitConfig";
import { useCreateUserUnitConfig } from "#src/components/hooks/adapters/useUserUnitConfig";
import { useMeasurementTypes } from "#src/contexts/MeasurementTypeContext";
import {
  UnitConfigurationPreference,
  UnitConfigurationPreferenceType,
  UnitConfigurationSchema,
} from "@validereinc/domain";

export const AddUnitConfigDialog = ({
  isOpen,
  onClose,
  unitConfigurationPreference,
}: {
  isOpen: boolean;
  onClose: () => void;
  unitConfigurationPreference: UnitConfigurationPreferenceType;
}) => {
  const form = useForm({});
  const { measurementTypes, getUnitsByQuantity } = useMeasurementTypes();

  const measurementTypeId = form.watch("measurement_type");
  const measurementType = useMemo(
    () => measurementTypes.find(({ id }) => id === measurementTypeId),
    [measurementTypes, measurementTypeId]
  );
  const units = getUnitsByQuantity(measurementType?.quantity);

  useEffect(() => {
    form.reset();
  }, [isOpen]);

  const onSuccess = () => {
    onClose?.();
  };

  const createUserUnitConfig = useCreateUserUnitConfig({ onSuccess });
  const createCompanyUnitConfig = useCreateCompanyUnitConfig({ onSuccess });

  const createUnitConfig =
    unitConfigurationPreference === UnitConfigurationPreference.COMPANY
      ? createCompanyUnitConfig
      : createUserUnitConfig;

  const handleSubmit = form.handleSubmit((values) => {
    const { measurement_type, measurement_unit, precision } = values;
    createUnitConfig.mutate({
      id: measurement_type,
      data: {
        measurement_type,
        measurement_unit,
        precision: Number(precision),
      },
    });
  });

  return (
    <Dialog
      title="Add Unit Configuration Preference"
      isOpen={isOpen}
      onClose={onClose}
      actionRow={[
        <Button
          key="add-unit-config"
          variant="primary"
          onClick={handleSubmit}
        >
          Add
        </Button>,
      ]}
    >
      <Form {...form}>
        <DropdownInput
          label="Measurement Type"
          name={UnitConfigurationSchema.keyof().Enum.measurement_type}
          options={measurementTypes ?? []}
          valueKey="id"
          labelKey="name"
          isRequired
          isClearable={false}
        />
        <DropdownInput
          label="Unit Preference"
          name={UnitConfigurationSchema.keyof().Enum.measurement_unit}
          options={units}
          valueKey="id"
          labelKey="name.symbol"
          isRequired
          isClearable={false}
        />
        <TextInput
          name={UnitConfigurationSchema.keyof().Enum.precision}
          label="Decimal Precision"
          isRequired
          type="number"
        />
      </Form>
    </Dialog>
  );
};
