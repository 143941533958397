import { useTableSortingAndPagination } from "#redux/reducers/tableStateReducer";
import { useNavigate } from "#src/Routers/hooks";
import { ActivityLogsDrawer } from "#src/batteries-included-components/Drawers/ActivityLogsDrawer";
import { RoutingLink } from "#src/batteries-included-components/RoutingLink";
import {
  formatAnArrayOfStrings,
  getActionForDisplay,
  getAssetTypeForDisplay,
  getIsDeleted,
  getListOfChangedAttributes,
  getPath,
  getTransformedAssetName,
} from "#src/routes/change-log/ActivityLogsPage.helpers";
import { linkToUserDetailPage } from "#src/routes/settings/users/detail";
import { getPropertyAsMap } from "#src/utils/objectFormatter";
import { UseQueryOptions, useQueries, useQuery } from "@tanstack/react-query";
import {
  DataTable,
  DataTablePanel,
  HeaderType,
  Link,
  SortingType,
  StorageKeys,
  useAlert,
  useFilters,
} from "@validereinc/common-components";
import {
  ActivitiesDomain,
  ActivityResourceType,
  ActivitySchema,
  ActivityType,
  SortDirection,
  UserType,
  UsersAdapter,
} from "@validereinc/domain";
import React, { useMemo, useState } from "react";

export const ActivityLogsTablePanel = ({
  resourceId,
  resourceType,
  title,
  tableConfigStorageKey,
  filterConfigStorageKey,
}: {
  resourceId?: string;
  resourceType?: ActivityResourceType;
  title: string;
} & StorageKeys) => {
  const navigate = useNavigate();
  const { addAlert } = useAlert();
  const [filters] = useFilters<
    Parameters<typeof ActivitiesDomain.getList>[0]["filters"]
  >(filterConfigStorageKey);
  const [selectedLog, setSelectedLog] = useState<ActivityType | null>(null);
  const sorting: SortingType = {
    sortBy: "timestamp",
    sortDirection: SortDirection.DESCENDING,
  };
  const [tableState, setTableState] = useTableSortingAndPagination(
    sorting,
    filters
  );
  const activitiesQueryPayload = {
    page: tableState.page,
    pageSize: tableState.itemsPerPage,
    sortBy: tableState.sortBy,
    sortDirection: tableState.sortDirection,
    filters: {
      ...filters,
      ...(filters?.resource_id || resourceId
        ? { resource_id: filters?.resource_id ?? resourceId }
        : {}),
    },
    meta: { resourceType },
  } satisfies Parameters<typeof ActivitiesDomain.getList>[0];
  const activitiesQuery = useQuery(
    ["activities", activitiesQueryPayload],
    () => ActivitiesDomain.getList(activitiesQueryPayload),
    {
      staleTime: 1 * 60 * 1000,
    }
  );
  const associatedUsersQueries = useQueries<
    Array<
      UseQueryOptions<
        Awaited<ReturnType<typeof UsersAdapter.getOne>> | undefined,
        unknown,
        UserType | undefined
      >
    >
  >({
    queries:
      activitiesQuery.data?.data.map((activity) => ({
        queryKey: ["users", activity.author_id],
        queryFn: () => {
          if (!activity.author_id) {
            return;
          }

          return UsersAdapter.getOne({ id: activity.author_id });
        },
        enabled: Boolean(activity.author_id),
        staleTime: 3 * 60 * 1000,
        select: (resp) => resp?.data,
      })) ?? [],
  });

  const authorIdsToUserDetailsMap = useMemo(() => {
    return getPropertyAsMap(
      associatedUsersQueries,
      "data",
      "data.id"
    ) as Record<string, UserType>;
  }, [associatedUsersQueries]);

  const activityModelKeys = ActivitySchema.keyof().Enum;
  const headers: Array<HeaderType<ActivityType>> = [
    {
      label: "Type",
      key: activityModelKeys.action,
      renderComponent: ({ item }) => {
        if (!item) {
          return null;
        }

        const transformedAction = getActionForDisplay(item);
        const transformedAssetType = getAssetTypeForDisplay(item);
        const actionLabel = `${transformedAssetType} ${transformedAction}`;

        return (
          <Link
            label={actionLabel}
            onClick={() => setSelectedLog(item)}
          />
        );
      },
    },
    {
      label: "Updated Attributes",
      key: "updated_attributes",
      renderComponent: ({ item }) =>
        item ? formatAnArrayOfStrings(getListOfChangedAttributes(item)) : "-",
    },
    {
      label: "Resource ID",
      key: activityModelKeys.resource_id,
    },
    {
      label: "Resource Name",
      key: "name",
      renderComponent: ({ item }) => {
        if (!item) {
          return null;
        }

        const path = getPath(item);

        if (!path) {
          return null;
        }

        return (
          <Link
            label={getTransformedAssetName(item)}
            onClick={async () => {
              try {
                const result = await getIsDeleted(item);

                if (!result) {
                  return null;
                }

                navigate({ pathname: path });
              } catch (err) {
                addAlert?.({
                  variant: "error",
                  message: "The resource does not exist.",
                });
              }
            }}
          />
        );
      },
    },
    {
      label: "Resource Type",
      key: activityModelKeys.resource_type,
      renderComponent: ({ item }) =>
        item ? getAssetTypeForDisplay(item) : null,
    },
    {
      label: "By User",
      key: activityModelKeys.author_id,
      renderComponent: ({ item }) => {
        const id = item.author_id;
        const name = authorIdsToUserDetailsMap[id]?.name ?? "-";
        return id ? (
          <RoutingLink to={linkToUserDetailPage(id)}>{name}</RoutingLink>
        ) : (
          "-"
        );
      },
    },
    {
      label: "Logged At",
      key: activityModelKeys.timestamp,
      renderComponent: ({ item }) => (
        <DataTable.DataRow.DateCell
          value={item[activityModelKeys.timestamp]}
          withTime
        />
      ),
    },
  ];

  return (
    <>
      <DataTablePanel
        storageKey={tableConfigStorageKey}
        dataTableProps={{
          headers,
          items: activitiesQuery.data?.data ?? [],
          isLoading: activitiesQuery.isLoading,
          sorting,
          pagination: {
            page: tableState.page,
            itemsPerPage: tableState.itemsPerPage,
            total: activitiesQuery.data?.total_entries ?? 0,
            entityPerPage: "Logs per page:",
          },
          onSortChange: setTableState,
          onPaginationChange: setTableState,
        }}
        panelProps={{
          title,
        }}
      />
      <ActivityLogsDrawer
        logDetails={selectedLog}
        onClose={() => setSelectedLog(null)}
      />
    </>
  );
};
