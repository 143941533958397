import {
  Panel,
  RadioInput,
  TextAreaInput,
  TextInput,
} from "@validereinc/common-components";
import React from "react";

const IS_PRIMARY_DESCRIPTION = [
  "A single scenario may be selected to set as primary.",
  "This scenario will appear by default on all pages filtered by scenario.",
].join(" ");

export const ReportingGroupFormPanel = () => {
  return (
    <Panel title="Details">
      <TextInput
        name="name"
        label="Name"
        placeholder="Scenario Name"
        isRequired
      />

      <TextAreaInput
        name="description"
        label="Description"
      />

      <RadioInput
        name="is_primary"
        label="Set as primary scenario"
        description={IS_PRIMARY_DESCRIPTION}
        labelKey="label"
        valueKey="value"
        options={[
          { label: "Yes", value: true },
          { label: "No", value: false },
        ]}
        isOptionalTextShown={false}
      />
    </Panel>
  );
};
