import { useMultiStepFormContext } from "#src/hooks/useMultiStepForm";
import { Stepper } from "@validereinc/common-components";
import React from "react";

export const MutateRoleStepper = () => {
  const { currentStep, steps } = useMultiStepFormContext();

  return (
    <Stepper
      steps={steps.map((step) => ({ label: step.label }))}
      activeStep={currentStep}
    />
  );
};
