import {
  apiRequestServicesPromise,
  apiPutServicesPromise,
} from "./ServiceHelper";

const InstrumentService = {
  //Get instrument list
  getInstrumentList(options = {}) {
    return apiRequestServicesPromise("/api/instruments", options);
  },

  getInstrumentDetails(instrumentId) {
    return apiRequestServicesPromise(`/api/instruments/${instrumentId}`);
  },

  getMeasurementsForInlineInstrument(instrumentId, period) {
    const parameter = {
      start_time: period.start.unix(),
      end_time: period.end.unix(),
    };

    return apiRequestServicesPromise(
      `/api/v1/instruments/${instrumentId}/inline_measurements`,
      parameter
    );
  },

  getInlineInstrumentAnalyzeTable(
    instrumentId,
    period,
    measurementType,
    downsampleWindow
  ) {
    const parameters = {
      start_time: period.start,
      end_time: period.end,
      measurement_type: measurementType,
      downsample_window: downsampleWindow,
    };

    return apiRequestServicesPromise(
      `/api/instruments/${instrumentId}/analyze`,
      parameters
    );
  },

  getInlineInstrumentAnalyzeCSV(
    instrumentId,
    sampleIds,
    period,
    measurementType,
    downsampleWindow
  ) {
    const parameters = {
      field_sample_ids: sampleIds,
      start_time: period.start,
      end_time: period.end,
      measurement_type: measurementType,
      downsample_window: downsampleWindow,
    };

    return apiRequestServicesPromise(
      `/api/instruments/${instrumentId}/analyze/csv/url`,
      parameters
    );
  },

  updateDetails(instrumentId, name, serialNumber) {
    const parameter = {
      name: name,
      serial_number: serialNumber,
    };

    return apiPutServicesPromise(
      `/api/instruments/${instrumentId}/details`,
      parameter
    );
  },

  getInstrumentTests(instrumentId, dateRange) {
    return apiRequestServicesPromise(
      `/api/instruments/${instrumentId}/tests`,
      dateRange
    );
  },

  archiveInstrument(instrumentId) {
    return apiPutServicesPromise(`/api/instruments/${instrumentId}/archived`);
  },

  unarchiveInstrument(instrumentId) {
    return apiPutServicesPromise(`/api/instruments/${instrumentId}/unarchived`);
  },
};

export default InstrumentService;
