import FileService from "#src/components/Services/FileService";
import { useAuthenticatedContext } from "#src/contexts/AuthenticatedContext.helpers";
import { useQueueUnique } from "#src/hooks/useQueue";
import { downloadBlob } from "#src/utils/download";
import { useQuery } from "@tanstack/react-query";
import {
  Button,
  Dialog,
  FileUploadInput,
  Form,
  useAlert,
  useForm,
} from "@validereinc/common-components";
import {
  DatasetAdapter,
  TemplateAdapter,
  TransactionAdapter,
  TransactionType,
} from "@validereinc/domain";
import kebabCase from "lodash/kebabCase";
import snakeCase from "lodash/snakeCase";
import mime from "mime";
import React, { ReactNode, useEffect, useState } from "react";

export const ImportDataAction = ({
  resource,
  resourceId,
  getTrigger,
}: ImportDataActionProps) => {
  const { claims } = useAuthenticatedContext();
  const { addAlert } = useAlert();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [status, setStatus] = useState<ImportDataActionStatus>();
  const [queue, setQueue] = useQueueUnique<TransactionType>(
    [],
    `queue-import-data-action-${resource.id}-${kebabCase(resource.name)}${
      resourceId ? `-${resourceId}` : ""
    }`,
    (tx) => tx.transaction_id
  );
  const formProps = useForm({
    file: undefined,
  });
  const {
    formState: { isSubmitting, isValid, isSubmitSuccessful },
    getValues,
    reset,
  } = formProps;

  const { data: dataset, isLoading: isDatasetLoading } = useQuery(
    ["datasets", "default", resource.id, resourceId],
    () =>
      DatasetAdapter.default.getList({
        filters: {
          resource_name: resource.id,
          ...(resourceId && { resource_id: resourceId }),
          company_id: claims.company?.id,
        },
      }),
    {
      select: (resp) => (resp.total_entries >= 1 ? resp.data[0] : null),
      enabled:
        resource &&
        Boolean(claims.company?.id) &&
        Boolean(resourceId ? resource.id && resourceId : resource.id),
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );
  const { data: templateBlob, isLoading: isTemplateBlobLoading } = useQuery(
    ["datasets", "template", dataset?.id],
    () =>
      claims?.company?.id
        ? TemplateAdapter.dataset.getOne({
            datasetId: dataset?.id,
            companyId: claims.company.id,
          })
        : Promise.reject(),
    {
      enabled: Boolean(dataset?.id) && Boolean(claims?.company?.id),
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  const handleDialogOpenClick = () =>
    setIsDialogOpen((prevState) => !prevState);
  const handleDownloadTemplateClick = () => {
    const fileName = `${snakeCase(resource.name)}${
      resourceId ? `_${resourceId}_` : "_"
    }import_template`.toLowerCase();

    if (!claims) {
      addAlert({
        variant: "error",
        message: "You're not authorized to download a template",
      });
      return;
    }

    if (!templateBlob) {
      addAlert({
        variant: "error",
        message: "Template not available!",
      });
      return;
    }

    try {
      downloadBlob(fileName, templateBlob);
      addAlert({
        variant: "success",
        message: `Template ${fileName}.xlsx downloaded.`,
      });
    } catch (err) {
      addAlert({
        variant: "error",
        message: `Template failed to download.`,
      });
      console.error(err);
    }
  };
  const handleFileUpload = async (file: File) => {
    if (!dataset || !claims) {
      return {
        file,
        uploadedFile: null,
        transactionId: null,
      };
    }

    // extract key file details
    const { name, type: contentType, lastModifiedDate } = file;

    // create a file in the file upload service
    setStatus({ percentComplete: 1, label: "Uploading..." });
    const {
      status: createFileStatus,
      data: {
        data: [uploadedFileData],
        meta: { transaction_id: transactionId },
      },
    } = await FileService.createFile({
      name,
      contentType,
      description:
        "A user-uploaded file through the import data action component",
      tags: {
        lastModified: lastModifiedDate.toISOString(),
      },
      datasetId: dataset.id,
      companyId: claims.company?.id,
    });

    if (createFileStatus !== 200 && createFileStatus !== 201) {
      throw new Error("Could not create an entry in the file upload service");
    }

    // extract the ID and upload URL of the file entry in the file upload service
    const { file_id: fileId, uri: uploadUrl } = uploadedFileData;

    // upload the file to the pre-signed upload URL
    const { status: uploadFileStatus } = await FileService.uploadFile({
      url: uploadUrl,
      fileBlob: file,
    });

    if (uploadFileStatus !== 200 && uploadFileStatus !== 201) {
      throw new Error(
        "Could not upload file to the created entry in file upload service"
      );
    }

    // poll and check to see if the file scanning in the file upload service has completed
    setStatus({ percentComplete: 33, label: "Scanning..." });
    let scanStatus = "unknown";
    let pollLimit = 10;

    while (pollLimit > 0 && !["safe", "unsafe"].includes(scanStatus)) {
      const {
        data: {
          data: [{ file_scan_status }],
        },
      } = await FileService.getFileDownloadUrl(fileId);

      scanStatus = file_scan_status;

      // scan status re-check polling interval
      await new Promise((res) => setTimeout(res, 1000));
      pollLimit -= 1;
    }

    // verify based on the scan result
    setStatus({ percentComplete: 66, label: "Verifying..." });

    if (scanStatus !== "safe") {
      throw new Error(
        "The uploaded file has been deemed as unsafe or failed to complete verification"
      );
    }

    setStatus({ percentComplete: 100, label: "Uploading Complete" });
    return {
      file,
      uploadedFile: {
        ref: fileId,
        name,
      },
      transactionId,
    };
  };
  const handleSubmit = async () => {
    const formValues = getValues();

    try {
      const { transactionId } = await handleFileUpload(formValues.file);

      if (!transactionId) {
        throw new Error(
          "Transaction ID not returned for initiated data import"
        );
      }

      const transaction = await TransactionAdapter.getOne({
        id: transactionId,
        meta: { history: false },
      });

      if (!transaction?.data.length) {
        throw new Error("No transactions found for initiated transaction");
      }

      const newQueue = queue.insert(transaction.data[0]);

      setQueue(newQueue);
      setIsDialogOpen(false);
      addAlert({
        variant: "info",
        message: "Successfully started data import",
      });
    } catch (err) {
      console.error(err);
      addAlert({
        variant: "error",
        message: "Failed to start data import. Try again?",
      });
    } finally {
      setStatus({ percentComplete: 0, label: "Ready" });
    }
  };

  useEffect(() => {
    if (!isSubmitSuccessful) {
      return;
    }

    reset({
      file: undefined,
    });
  }, [isSubmitSuccessful]);

  const acceptedMimeTypes = [
    mime.getType("csv"),
    mime.getType("xls"),
    mime.getType("xlsx"),
    mime.getType("json"),
  ];
  const getInstruction = (resource: ResourceLocalizedType) =>
    `Upload a file to bulk import ${resource.plural} based off the defined ${resource.singular} template. You may download a blank template to structure your data below.`;

  const dialogActionRow = [
    <Button
      key="submit-action"
      variant="primary"
      type="submit"
      onClick={formProps.handleSubmit(handleSubmit)}
      isLoading={isSubmitting}
      disabled={!isValid || status?.percentComplete}
    >
      {status?.percentComplete ? status.label : "Import Data"}
    </Button>,
  ];
  const trigger = getTrigger ? (
    getTrigger({ handleClick: handleDialogOpenClick, isDialogOpen })
  ) : (
    <Button
      onClick={handleDialogOpenClick}
      isLoading={isDialogOpen}
    >
      Import
    </Button>
  );

  return (
    <>
      {trigger}
      <Dialog
        title={`Import Data${
          resource.singular ? `: ${resource.singular}` : ""
        }`}
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        actionRow={dialogActionRow}
      >
        <Form
          {...formProps}
          onSubmit={handleSubmit}
          onBlur={null}
        >
          <p>{getInstruction(resource)}</p>
          <Button
            icon="download-simple"
            variant="outline"
            onClick={handleDownloadTemplateClick}
            disabled={isDatasetLoading || isTemplateBlobLoading}
          >
            Download Import Template
          </Button>
          <FileUploadInput
            name="file"
            label="Select file to upload"
            placeholder="Upload a .csv, .xls, .xlsx, or .json file"
            isRequired={true}
            rules={{
              required: "A file with data to import is required",
            }}
            acceptedFileTypes={acceptedMimeTypes}
            showIcon={true}
            style={{ marginTop: 12 }}
            isDisabled={isSubmitting || status?.percentComplete}
          />
        </Form>
      </Dialog>
    </>
  );
};

export type ImportDataActionProps = {
  resource: ResourceLocalizedType;
  resourceId?: string;
  getTrigger?: ({
    handleClick,
    isDialogOpen,
  }: {
    handleClick: () => void;
    isDialogOpen: boolean;
  }) => ReactNode;
};

// TODO: should be in the domain layer
export type ResourceType = {
  id: string;
  name: string;
};

export type ResourceLocalizedType = {
  singular: string;
  plural: string;
} & ResourceType;

export type ImportDataActionStatus = {
  percentComplete: number;
  label: string;
};
