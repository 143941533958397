import {
  CalculationOutputType,
  CalculationParameterSavedType,
  CalculatorInputSourceConfigType,
  EstimationMethodInputType,
  GetOneEstimationMethodMetaType,
  restAPI,
} from "../";
import {
  BulkActionResultStatusType,
  BulkActionResultType,
  FilterObjectType,
  getFilterObject,
  GetListRequestType,
  GetListResponseType,
} from "../../util";
import { EstimationMethodConfigurationMetaType } from "./EstimationMethodConfiguration";
import {
  AssetType,
  AssetTypeType,
  JobStatusType,
  JobType,
} from "../../schemas";

export type GetListEstimationMethodRunFiltersType = {
  /** the ID of the estimation method the run is under */
  methodId: string;
};

export type GetListEstimationMethodRunMetaType = GetOneEstimationMethodMetaType;

export type GetOneEstimationMethodRunMetaType =
  EstimationMethodConfigurationMetaType;

export type CreateEstimationMethodRunMetaType =
  EstimationMethodConfigurationMetaType & {
    /** the ID of the method */
    methodId?: string;
    /** should this persist to the DB as a new "calculation result"? set to false if it should, otherwise the calculation run results are transient. */
    isPreview?: boolean;
    /** should we promote the calculation results to records on the relevant asset(s)? */
    promoteToRecord?: boolean;
  };

export type EstimationMethodRunFilterType = FilterObjectType<{
  year_month: string;
  status: string;
  entity_name: string;
  entity_type: string;
  estimation_method_id: string;
  "estimation_method.name": string;
  "estimation_method.reporting_group_id": string;
  "estimation_method.analytics_calculator_id": string;
  "estimation_method.analytics_library_id": string;
  "estimation_method.entity_id": string;
  "estimation_method.entity_type": AssetTypeType;
}>;

export type CreateManyEstimationMethodRunFiltersType = {
  /** a month represented as a string in the format YYYYMM e.g. 202210 for October 2022 */
  yearMonth?: string;
  /** ID of the calculator tied to this estimation method */
  calculatorId?: string;
  /** the ID of the method */
  methodId?: string;
  /** ID of the library tied to this estimation method */
  libraryId?: string;
  /** ID of the entity the estimation method is associated with */
  entityId?: string;
  /** the entity type of the entity specified by entityId */
  entityType?: AssetTypeType;
};

export type CreateManyEstimationMethodRunMetaType = {
  /** should this persist to the DB as a new "calculation result"? set to false if it should, otherwise the calculation run results are transient. */
  isPreview?: boolean;
};

export type EstimationMethodRunType = {
  year_month: string;
  calculation_end: string;
  calculation_start: string;
  company_id: string;
  created_by: string;
  created_at: string;
  updated_by: string;
  updated_at: string;
  id: string;
  configuration_input: Record<string, EstimationMethodInputType>;
  input: {
    calculation_id: string;
    calculation_parameters: CalculationParameterSavedType[];
  };
  input_sources: Record<string, CalculatorInputSourceConfigType>;
  output: {
    outputs: CalculationOutputType[];
  };
  estimation_method_id: string;
};

/** Bulk run has a bespoke type */
export type BulkRunResultType = {
  status: BulkActionResultStatusType;
  data: Record<
    string,
    {
      status: BulkActionResultStatusType;
      error?: string;
      result?: EstimationMethodRunType;
    }
  >;
};

/** We need to replace the embedded `result` key with `data` */
const convertBulkRunToBulkAction = (
  bulkRun: BulkRunResultType
): BulkActionResultType<EstimationMethodRunType> => {
  const data = Object.fromEntries(
    Object.entries(bulkRun.data).map(([key, value]) => {
      if (!value?.result) {
        return [key, value];
      }
      const { result, ...newValue } = value;
      const bulkAction = {
        data: result,
        ...newValue,
      };
      return [key, bulkAction];
    })
  );
  return { ...bulkRun, data };
};

export const EstimationMethodRun: EstimationMethodRunDomainType = {
  getList: ({
    filters = {
      entityType: AssetType.EQUIPMENT,
    },
    page,
    pageSize,
    sortBy,
    sortDirection,
  }) =>
    // TODO: provide custom error here if the right filters aren't provided
    restAPI.nodeAPI.GET({
      endpoint: `/estimation_methods/${filters?.entityType}/${filters?.methodId}/runs`,
      query: {
        page,
        page_size: pageSize,
        sort_by: sortBy,
        sort_direction: sortDirection,
      },
    }),
  getOne: ({
    id,
    meta = {
      // TODO: this should be the current month
      yearMonth: "",
      entityType: AssetType.EQUIPMENT,
    },
  }) =>
    restAPI.nodeAPI.GET({
      endpoint: `/estimation_methods/${meta.entityType}/${id}/configuration/${meta.yearMonth}/run`,
    }),
  create: ({
    meta = {
      methodId: "",
      yearMonth: "",
      entityType: AssetType.EQUIPMENT,
      isPreview: true,
      promoteToRecord: false,
    },
  }) =>
    restAPI.nodeAPI.POST({
      endpoint: `/estimation_methods/${meta.entityType}/${meta.methodId}/configuration/${meta.yearMonth}/run`,
      query: {
        ...(meta.isPreview ? { preview: "true" } : {}),
        ...(meta.promoteToRecord ? { promote_to_record: "true" } : {}),
      },
    }),
  bulkRun: async (
    { filters, isPreview, promoteToRecord } = {
      filters: {},
      isPreview: true,
      promoteToRecord: false,
    }
  ) => {
    const response = await restAPI.nodeAPI.POST<BulkRunResultType>({
      version: 2,
      endpoint: "/estimation_methods/run",
      query: {
        ...(isPreview ? { preview: "true" } : {}),
        ...(promoteToRecord ? { promote_to_record: "true" } : {}),
      },
      body: {
        filters: getFilterObject(filters),
      },
    });

    return convertBulkRunToBulkAction(response);
  },
  bulkRunAsync: (
    { filters, isPreview, promoteToRecord } = {
      filters: {},
      isPreview: true,
      promoteToRecord: false,
    }
  ) =>
    restAPI.nodeAPI.POST({
      version: 2,
      endpoint: "/estimation_methods/run_async",
      query: {
        ...(isPreview ? { preview: "true" } : {}),
        ...(promoteToRecord ? { promote_to_record: "true" } : {}),
      },
      body: {
        filters: getFilterObject(filters),
      },
    }),
  createMany: ({
    filters,
    meta = {
      isPreview: true,
    },
  }) => {
    return restAPI.nodeAPI.POST({
      version: 2,
      endpoint: "/estimation_methods/run",
      query: {
        ...(meta.isPreview ? { preview: "true" } : {}),
      },
      body: {
        filters: {
          ...(filters.methodId
            ? { estimation_method_id: filters.methodId }
            : {}),
          ...(filters.yearMonth ? { year_month: filters.methodId } : {}),
          ...(filters.calculatorId
            ? {
                "estimation_method.analytics_calculator_id":
                  filters.calculatorId,
              }
            : {}),
          ...(filters.libraryId
            ? {
                "estimation_method.analytics_library_id": filters.libraryId,
              }
            : {}),
          ...(filters.entityId
            ? {
                "estimation_method.entity_id": filters.entityId,
              }
            : {}),
          ...(filters.entityType
            ? {
                "estimation_method.entity_type": filters.entityType,
              }
            : {}),
        },
      },
    });
  },
};

export interface EstimationMethodRunDomainType {
  /**
   * Get all previously saved calculation run results for an estimation method
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/estimation_methods/list_estimation_method_runs
   * @returns the standard wrapped API response of this endpoint
   */
  getList: (
    params: GetListRequestType<
      GetListEstimationMethodRunFiltersType & GetListEstimationMethodRunMetaType
    >
  ) => Promise<GetListResponseType<EstimationMethodRunType>>;
  /**
   * Get a previous calculator run (a "calculation result")
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/estimation_methods/get_estimation_method_configuration_run
   * @returns the standard wrapped API response of this endpoint
   */
  getOne: (params: {
    id: string;
    meta?: GetOneEstimationMethodRunMetaType;
  }) => Promise<EstimationMethodRunType>;
  /**
   * Run calculator based on most recent estimation method configuration (creates a "calculation result")
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/estimation_methods/run_estimation_methods
   * @returns the standard wrapped API response of this endpoint
   */
  create: (params: {
    meta?: CreateEstimationMethodRunMetaType;
  }) => Promise<EstimationMethodRunType>;
  /**
   * Run calculators based on most recent estimation method configuration (creates "calculation result" for each calculator run)
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/estimation_methods/run_estimation_methods
   * @returns the standard wrapped API response of this endpoint
   */
  createMany: (params: {
    filters: CreateManyEstimationMethodRunFiltersType;
    meta?: CreateManyEstimationMethodRunMetaType;
  }) => Promise<EstimationMethodRunType>;
  bulkRun: (params: {
    filters: EstimationMethodRunFilterType;
    isPreview: boolean;
    promoteToRecord?: boolean;
  }) => Promise<BulkActionResultType<EstimationMethodRunType>>;
  bulkRunAsync: (params: {
    filters: EstimationMethodRunFilterType;
    isPreview: boolean;
    promoteToRecord?: boolean;
  }) => Promise<{ status: JobStatusType; job: JobType }>;
}
