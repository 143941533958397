export const EQUIPMENT_EMISSION_RECORD_LIST_PAGE_TITLE = "Emissions Records";

export const linkToEquipmentEmissionRecordList = (
  equipmentId = ":equipmentId"
) => `/app/organization/equipment/${equipmentId}/detail?tab=emissions`;

export const EQUIPMENT_EMISSION_RECORD_LIST_BREADCRUMB = {
  title: EQUIPMENT_EMISSION_RECORD_LIST_PAGE_TITLE,
  path: linkToEquipmentEmissionRecordList(),
};
