import React, { useState } from "react";
import * as PropTypes from "prop-types";
import "./SystemBalanceChart.scss";
import {
  XYPlot,
  VerticalBarSeries,
  XAxis,
  YAxis,
  HorizontalGridLines,
  Hint,
  ChartLabel,
} from "react-vis";
import { AutoSizer } from "react-virtualized";
import { getNumberColorBySign } from "#utils/styleCalculator";
import { getComponentShortForm } from "#components/Records/Quality/RecordOfQualityHelper";
import DropdownButton from "react-bootstrap/lib/DropdownButton";
import MenuItem from "react-bootstrap/lib/MenuItem";
import {
  PERCENTAGE_DIFFERENCE_KEY,
  ABSOLUTE_DIFFERENCE_KEY,
  VOLUME_KEY,
} from "./SystemBalanceConstant";
import { getMagnitudeNumber } from "#utils/stringFormatter";

const CONTAINER_PADDING = 30;
const MAXIMUM_X_AXIS_STRING_CHAR = 8;

function getTotalDifferencePlotData(totalDifference, source) {
  let value = 0;
  let unit;
  if (totalDifference) {
    const { percent_differences, absolute_differences } = totalDifference;
    switch (source) {
      case PERCENTAGE_DIFFERENCE_KEY:
        value = percent_differences?.value ?? 0;
        unit = percent_differences?.unit;
        break;
      case ABSOLUTE_DIFFERENCE_KEY:
        value = absolute_differences?.value ?? 0;
        unit = absolute_differences?.unit;
        break;
      case VOLUME_KEY:
        value = Math.abs(absolute_differences?.value ?? 0);
        unit = absolute_differences?.unit;
        break;
      default:
        break;
    }
  }

  return {
    x: "Total",
    y: value,
    label: value,
    color: getNumberColorBySign(value),
    unit: unit,
  };
}

const LABEL = {
  [PERCENTAGE_DIFFERENCE_KEY]: "Percent",
  [ABSOLUTE_DIFFERENCE_KEY]: "Absolute Difference",
  [VOLUME_KEY]: "Volume ",
};

const CHARTOPTIONLABEL = {
  [PERCENTAGE_DIFFERENCE_KEY]: "Percent Difference ",
  [ABSOLUTE_DIFFERENCE_KEY]: "Absolute Difference ",
};

function getUnit(source, totalDifference) {
  switch (source) {
    case PERCENTAGE_DIFFERENCE_KEY:
      return `${totalDifference?.percent_differences?.unit}`;
    case ABSOLUTE_DIFFERENCE_KEY:
      return `${totalDifference?.absolute_differences?.unit}`;
    case VOLUME_KEY:
      return `${totalDifference?.absolute_differences?.unit}`;
    default:
      return "";
  }
}

function getSource(inlets, outlets) {
  if (!!outlets && !!inlets) {
    return [PERCENTAGE_DIFFERENCE_KEY, ABSOLUTE_DIFFERENCE_KEY];
  } else {
    return [VOLUME_KEY];
  }
}

function getComponentPlotData(data, displayProperties, source) {
  return [...displayProperties].map((component) => {
    let value;
    if (source === VOLUME_KEY) {
      const absoluteDifference = data[component]?.value ?? 0;
      const volume = Math.abs(absoluteDifference);
      value = volume;
    } else {
      value = data[component]?.value ?? 0;
    }

    return {
      x: component,
      y: value,
      label: value,
      color: getNumberColorBySign(value),
      unit: data[component]?.unit,
    };
  });
}

function getXAxisTick(tick, componentVolumeConfig) {
  if (tick === "Total Percent" || tick === "Total Recovery") {
    return "Total";
  } else {
    return getComponentShortForm(tick, componentVolumeConfig).substring(
      0,
      MAXIMUM_X_AXIS_STRING_CHAR
    );
  }
}

const SystemBalance = ({
  components,
  qualities,
  totalDifference,
  componentVolumeConfig,
  displayProperties,
  outlets,
  inlets,
}) => {
  const sources = getSource(inlets, outlets);
  const [source, setSource] = useState(sources[0]);
  const [hintData, setHintData] = useState(null);

  const percentDifferences = {
    ...(components?.percent_differences ?? {}),
    ...(qualities?.percent_differences ?? {}),
  };

  const absoluteDifferences = {
    ...(components?.absolute_differences ?? {}),
    ...(qualities?.absolute_differences ?? {}),
  };

  const totalDifferencePlotData = getTotalDifferencePlotData(
    totalDifference,
    source
  );

  const componentPlotData = getComponentPlotData(
    source === PERCENTAGE_DIFFERENCE_KEY
      ? percentDifferences
      : absoluteDifferences,
    displayProperties,
    source
  );

  const hintFormat = (data) => {
    return [
      {
        title: "Property",
        value: data.x,
      },
      {
        title: "Value",
        value: `${data.y.toFixed(3)} ${data.unit}`,
      },
    ];
  };

  const onDropdownSelect = (eventKey) => {
    setSource(eventKey);
  };

  return (
    <div className="systemBalanceChartContainer">
      {source != VOLUME_KEY && (
        <div className="systemBalanceChart__selectionRow">
          <DropdownButton
            title={`${CHARTOPTIONLABEL[source]} (${getUnit(
              source,
              totalDifference
            )})`}
            onSelect={onDropdownSelect}
            id={`dropdown-basic`}
          >
            {sources.map((item) => (
              <MenuItem
                key={item}
                eventKey={item}
              >
                {`${CHARTOPTIONLABEL[item]} (${getUnit(
                  item,
                  totalDifference
                )})`}
              </MenuItem>
            ))}
          </DropdownButton>
        </div>
      )}

      <AutoSizer>
        {({ width, height }) => (
          <XYPlot
            width={width}
            height={Math.max(height - CONTAINER_PADDING, 0)}
            xType="ordinal"
            colorType="literal"
            margin={{ left: 60 }}
          >
            <HorizontalGridLines />

            <VerticalBarSeries
              data={[totalDifferencePlotData, ...componentPlotData]}
              barWidth={0.2}
              onValueMouseOver={(value) => setHintData(value)}
              onValueMouseOut={() => setHintData(null)}
            />

            <XAxis
              tickFormat={(tick) => getXAxisTick(tick, componentVolumeConfig)}
            />

            <YAxis tickFormat={(v) => getMagnitudeNumber(v, 3)} />

            <ChartLabel
              text={`${LABEL[source]} (${getUnit(source, totalDifference)})`}
              xPercent={0.02}
              yPercent={0.4}
              style={{ transform: "rotate(-90)" }}
            />

            {hintData && (
              <Hint
                value={hintData}
                format={hintFormat}
                style={{
                  width: "200px",
                }}
              />
            )}
          </XYPlot>
        )}
      </AutoSizer>
    </div>
  );
};

SystemBalance.propTypes = {
  components: PropTypes.object,
  qualities: PropTypes.object,
  totalDifference: PropTypes.object,
  componentVolumeConfig: PropTypes.object,
  displayProperties: PropTypes.array.isRequired,
  outlets: PropTypes.array,
  inlets: PropTypes.array,
};

export default SystemBalance;
