import Status from "#common/Status/Status";
import config from "#config";
import { getTimeStringFromUTC } from "#utils/timeFormatter";
import { LegacyDataTable } from "@validereinc/common-components";
import find from "lodash/find";
import * as PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { AutoSizer } from "react-virtualized";
import { notesColumn } from "../../Instrument/Shared/TestsDatatable/NotesColumn";
import { DEFAULT_TAB_KEY, TAB_KEYS } from "./ScheduleViewConstants";
import {
  canAddNotes,
  canComplete,
  canDismiss,
  canSample,
  dateSorter,
  nameSorter,
  sortByStatusAndName,
  statusSorter,
  typeSorter,
  workflowSorter,
} from "./WorkflowTaskHelpers";
import WorkflowTaskModal from "./WorkflowTaskModal";
import "./WorkflowViewTable.scss";

const CustomHintRow = ({ title, value }) => (
  <section>
    <span className="title">{title}</span>
    <span className="value">{value}</span>
  </section>
);

const WorkflowViewTable = ({
  workflows,
  workflowTasks,
  height,
  filterRow,
  filterPillbox,
  loadingState,
  refetchWorkflowTasks,
}) => {
  const [selectedWorkflowTask, setSelectedWorkflowTask] = useState(null);
  const [activeTabKey, setActiveTabKey] = useState(DEFAULT_TAB_KEY);

  const rowClassName = (rowData) => {
    return rowData.state === "missed" || rowData.state === "dismissed"
      ? "workflowsViewTable__row--grey"
      : "";
  };

  const onTaskClick = (workflowTask, tab) => {
    setSelectedWorkflowTask(workflowTask);
    setActiveTabKey(tab ?? DEFAULT_TAB_KEY);
  };

  const onClosePopup = () => {
    setSelectedWorkflowTask(null);
  };

  useEffect(() => {
    if (selectedWorkflowTask) {
      const task = workflowTasks.find((t) => t.id === selectedWorkflowTask.id);
      setSelectedWorkflowTask(task);
    }
  }, [workflowTasks]);

  const nameRenderer = (rowData) => {
    return <div className="wrap">{rowData?.name ?? "-"}</div>;
  };

  const statusRenderer = (rowData) => {
    let statusType = "";

    switch (rowData.state) {
      case "pending":
        statusType = "pending";
        break;
      case "completed":
        statusType = "completed";
        break;
      case "sampled":
        statusType = "inprogress";
        break;
      default:
        statusType = "default";
    }

    return <Status type={statusType}>{rowData.state}</Status>;
  };

  const dateRenderer = (rowData, columnKey) => {
    return (
      <div className="wrap">
        {getTimeStringFromUTC(rowData[columnKey], config.DATE_FORMAT)}
      </div>
    );
  };

  const testsRenderer = (rowData, columnKey) => {
    const tests = rowData[columnKey] ?? [];

    if (tests.length > 0) {
      return (
        <div className="workflowsViewTable__testsCell wrap">
          {rowData[columnKey].join(", ")}
        </div>
      );
    } else {
      return "None";
    }
  };

  const workflowRenderer = (rowData, columnKey, workflows) => {
    const workflowId = rowData[columnKey];

    if (workflowId) {
      const workflowDetail = find(workflows, { id: workflowId });
      if (workflowDetail) {
        return (
          <div className="workflowsViewTable__workflowCell">
            {workflowDetail.name}
          </div>
        );
      }
    }

    return "-";
  };

  const testTypeRenderer = (rowData, columnKey) => {
    return (
      <span className="capitalized">
        {rowData[columnKey]?.replace(/_/g, " ")}
      </span>
    );
  };

  const Headers = [
    {
      label: "Tasks",
      key: "name",
      width: 300,
      fixed: true,
      cellRenderer: nameRenderer,
      sort: nameSorter,
    },
    {
      label: "Status",
      key: "state",
      width: 125,
      fixed: true,
      cellRenderer: statusRenderer,
      sort: statusSorter,
    },
    {
      label: "Start Date",
      key: "inserted_at_utc_epoch",
      width: 125,
      cellRenderer: dateRenderer,
      sort: dateSorter,
    },
    {
      label: "Due Date",
      key: "deadline_at_utc_epoch",
      width: 125,
      cellRenderer: dateRenderer,
      sort: dateSorter,
    },
    {
      label: "Sample Date",
      key: "sampled_at_utc_epoch",
      width: 125,
      cellRenderer: dateRenderer,
      sort: dateSorter,
    },
    {
      label: "Test Required",
      key: "tests_required",
      width: 250,
      cellRenderer: testsRenderer,
      disableSort: true,
    },
    {
      label: "Test Completed",
      key: "tests_completed",
      width: 250,
      cellRenderer: testsRenderer,
      disableSort: true,
    },
    {
      label: "Workflow",
      key: "workflow_id",
      width: 250,
      cellRenderer: (rowData, columnKey) =>
        workflowRenderer(rowData, columnKey, workflows),
      sort: (list) => workflowSorter(list, workflows),
    },
    {
      label: "Type",
      key: "test_type",
      width: 150,
      style: { textTransform: "capitalize" },
      cellRenderer: testTypeRenderer,
      sort: typeSorter,
    },

    notesColumn,
  ];

  const actionDropdown = [
    {
      title: "View Detail",
      onClick: (rowData) => onTaskClick(rowData),
    },
    {
      title: "Add Notes",
      onClick: (rowData) => onTaskClick(rowData, TAB_KEYS.notes),
      disabled: (rowData) => !canAddNotes(rowData),
    },
    {
      title: "Complete Task",
      onClick: (rowData) => onTaskClick(rowData),
      disabled: (rowData) => !canComplete(rowData),
    },
    {
      title: "Dismiss Task",
      onClick: (rowData) => onTaskClick(rowData),
      disable: (rowData) => !canDismiss(rowData),
    },
    {
      title: "Sample Task",
      onClick: (rowData) => onTaskClick(rowData),
      disable: (rowData) => !canSample(rowData),
    },
  ];

  return (
    <div
      style={{ height }}
      className="workflowsViewTable"
    >
      <AutoSizer>
        {({ width, height }) => (
          <LegacyDataTable
            width={width}
            height={height}
            headers={Headers}
            list={sortByStatusAndName(workflowTasks)}
            rowHeight={60}
            getRowClassName={rowClassName}
            filterRow={filterRow}
            filterPillbox={filterPillbox}
            isLoading={loadingState === "loading"}
            actionDropdown={actionDropdown}
            highlightRow={true}
            onCellClick={(rowData) => onTaskClick(rowData)}
          />
        )}
      </AutoSizer>

      {selectedWorkflowTask && (
        <WorkflowTaskModal
          show={true}
          closeModal={onClosePopup}
          workflowTask={selectedWorkflowTask}
          activeTabKey={activeTabKey}
          setActiveTabKey={setActiveTabKey}
          refetchWorkflowTasks={refetchWorkflowTasks}
        />
      )}
    </div>
  );
};

CustomHintRow.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string,
};

WorkflowViewTable.propTypes = {
  workflows: PropTypes.array,
  workflowTasks: PropTypes.array.isRequired,
  height: PropTypes.number.isRequired,
  refetchWorkflowTasks: PropTypes.func,
  filterRow: PropTypes.object.isRequired,
  filterPillbox: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  loadingState: PropTypes.string,
};

export default WorkflowViewTable;
