import forEach from "lodash/forEach";
import indexOf from "lodash/indexOf";

const lodash = { forEach, indexOf };

export function ArrayToString(array, key) {
  let string = "";
  string = array.map((row) => row[key]).join(", ");

  return string;
}

export function ArrayToObjectWithCheck(array, condition) {
  const object = {};

  array.forEach(function (row) {
    row.checked = condition;
    object[row.id] = row;
  });

  return object;
}

export function CombineTwoArrayWithCheck(array, array2, compareKey) {
  lodash.forEach(array, (row) => {
    if (lodash.indexOf(array2, row[compareKey]) !== -1) {
      row.checked = true;
    } else {
      row.checked = false;
    }
  });

  return array;
}

export function ArrayWithCheck(array, condition) {
  array.forEach((row) => {
    row.checked = condition;
  });

  return array;
}

export function ObjectArrayToStringArray(array, field) {
  return array.map((item) => item[field]?.toUpperCase() ?? "-");
}

/**
 * Given two arrays, replaces every element of the oldArray with an element
 * from the newArray if the comparator properties of both elements are
 * equivalent. Keeps the oldArray element if no matching element in newArray.
 */
export const updateArray = (oldArray, newArray, comparator) => {
  return oldArray.map((oldElement) => {
    const updatedElement = newArray.find(
      (item) => item[comparator] === oldElement[comparator]
    );

    return updatedElement ?? oldElement;
  });
};

const arrayMoveMutate = (array, from, to) => {
  const startIndex = from < 0 ? array.length + from : from;

  if (startIndex >= 0 && startIndex < array.length) {
    const endIndex = to < 0 ? array.length + to : to;

    const [item] = array.splice(from, 1);
    array.splice(endIndex, 0, item);
  }
};

export const arrayMove = (array, from, to) => {
  array = [...array];
  arrayMoveMutate(array, from, to);
  return array;
};

export const sumArrayLengths = (...arrays) =>
  arrays.reduce((accumulator, array) => accumulator + array?.length ?? 0, 0);

export const getAllFilterInputs = (filters) => {
  const filterPillbox = {};

  Object.keys(filters).map((key) => {
    const inputs = filters[key]?.inputs ?? [];
    if (inputs.length > 0) {
      filterPillbox[key] = inputs;
    }
  });

  return filterPillbox;
};

export const clearAllInputs = (filterDropdowns) => {
  const updatedFilterDropdowns = { ...filterDropdowns };

  Object.keys(updatedFilterDropdowns).map((key) => {
    updatedFilterDropdowns[key].inputs = [];
  });

  return updatedFilterDropdowns;
};

export const uniqueArray = (inputArray, idKey = "id") => [
  ...new Map(inputArray?.map((input) => [input?.[idKey], input])).values(),
];

export const getArrayAdditionsAndExclusions = (
  oldArray: any[],
  newArray: any[]
) => {
  return {
    added: newArray.filter((x) => !oldArray.includes(x)),
    removed: oldArray.filter((x) => !newArray.includes(x)),
  };
};
