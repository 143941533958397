import { linkToDeviceDetail, linkToFacilities } from "#src/Routers/links";
import { EventContext } from "#src/contexts/EventContext";
import { DataTable, HeaderType } from "@validereinc/common-components";
import { DeviceType, EventsDomain } from "@validereinc/domain";
import React, { useContext } from "react";
import { RoutingLink } from "../RoutingLink";

export const EventDevicesTable = () => {
  const { event, isLoading, onRefetchEvent } = useContext(EventContext) || {};

  const headers: Array<HeaderType<DeviceType>> = [
    {
      key: "name",
      label: "Name",
      renderComponent: ({ item }) => (
        <RoutingLink to={linkToDeviceDetail(item.id)}>{item.name}</RoutingLink>
      ),
    },
    { key: "type.name", label: "Type" },
    {
      key: "facility.name",
      label: "Facility",
      renderComponent: ({ item }) => (
        <RoutingLink to={linkToFacilities(item.facility_id)}>
          {item.facility.name}
        </RoutingLink>
      ),
    },
    {
      key: "status",
      label: "Status",
      renderComponent: ({ item }) => (
        <DataTable.DataRow.PillCell
          variant={item.status === "active" ? "success" : "default"}
          value={item.status}
        />
      ),
    },
  ];

  const onRemove = async ({ deviceId }: { deviceId: string }) => {
    if (!event?.id) return;

    await EventsDomain.devices.removeOne({ deviceId, eventId: event?.id });

    onRefetchEvent?.();
  };

  return (
    <DataTable
      headers={headers}
      items={event?.devices?.data ?? []}
      isLoading={isLoading}
      getItemActions={({ item: { id } }) => [
        {
          label: "Remove",
          buttonProps: {
            onClick: () => onRemove({ deviceId: id }),
            icon: "trash",
          },
        },
      ]}
    />
  );
};
