import { ReactLoader } from "@validereinc/common-components";
import moment from "moment";
import React, { useEffect, useState } from "react";
import DateRangeSelector from "../../../Common/DateSelector/DateRangeSelector";
import UserService from "../../../Services/UserService";
import ActivityTable from "../ActivityTable/ActivityTable";
import "./UserActivity.css";

/* eslint-disable react/prop-types */

const useFetchActivities = (userId, startDate, endDate) => {
  const [activities, setActivities] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [csvLink, setCsvLink] = useState(null);

  useEffect(() => {
    if (userId) {
      setIsLoaded(false);
      UserService.getActivityForUser(userId, startDate, endDate)
        .then((response) => {
          setActivities(response.data.activities);
          setCsvLink(response.data.csv_link);
          setIsLoaded(true);
        })
        .catch(() => {
          setIsLoaded(false);
        });
    } else {
      setIsLoaded(false);
      UserService.getActivity(startDate, endDate)
        .then((response) => {
          setActivities(response.data.activities);
          setCsvLink(response.data.csv_link);
          setIsLoaded(true);
        })
        .catch(() => {
          setIsLoaded(false);
        });
    }
  }, [userId, startDate, endDate]);

  return [isLoaded, activities, csvLink];
};

const UserActivity = (props) => {
  const [startDate, setStartDate] = useState(
    moment().subtract(1, "month").toDate()
  );
  const [endDate, setEndDate] = useState(moment().toDate());

  const onDateSelect = (start, end) => {
    setStartDate(start);
    setEndDate(end);
  };

  const [isLoaded, activities, csvLink] = useFetchActivities(
    props.userId,
    startDate,
    endDate
  );

  const onCsvClick = () => {
    const link = document.createElement("a");
    link.download = "samples";
    link.href = csvLink;
    document.body.appendChild(link);
    link.click();
  };

  const filterRow = (
    <div className="filterRow">
      <DateRangeSelector
        dateRange={{
          from: startDate,
          to: endDate,
        }}
        onDateRangeChange={onDateSelect}
        icon
      />
    </div>
  );

  return (
    <ReactLoader loaded={isLoaded}>
      <div className="userActivities">
        <ActivityTable
          filterRow={filterRow}
          activities={activities}
          height={props.height}
          csvDownload={onCsvClick}
        />
      </div>
    </ReactLoader>
  );
};

export default UserActivity;
