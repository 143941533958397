import { z } from "zod";

export const NotificationDeliveryChannelType = {
  EMAIL: "email",
} as const;

export const NotificationChannelSchema = z.object({
  type: z.enum([NotificationDeliveryChannelType.EMAIL]),
});

export type NotificationChannelType = z.infer<typeof NotificationChannelSchema>;
