import { PillProps } from "@validereinc/common-components";
import {
  WorkflowStatus,
  WorkflowStatusType,
  WorkflowTaskStatus,
  WorkflowTaskStatusType,
} from "@validereinc/domain";

export const WorkflowStatusToPillVariantMap: Record<
  WorkflowStatusType,
  PillProps["variant"]
> = {
  [WorkflowStatus.IN_PROGRESS]: "info",
  [WorkflowStatus.COMPLETE]: "success",
  [WorkflowStatus.OVERDUE]: "warning",
  [WorkflowStatus.DISMISSED]: "default",
  [WorkflowStatus.MISSED]: "error",
};

export const WorkflowTaskStatusToPillVariantMap: Record<
  WorkflowTaskStatusType,
  PillProps["variant"]
> = {
  [WorkflowTaskStatus.OPEN]: "info",
  [WorkflowTaskStatus.COMPLETE]: "success",
  [WorkflowTaskStatus.DISMISSED]: "default",
};
