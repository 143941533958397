import {
  useFormatOutput,
  useNetworkCalculationResult,
} from "#routes/organization/networks/[networkId]/detail/calculation-result/[period]/details/NetworkCalculationResultPage.helpers";
import { KeyValuePanel } from "@validereinc/common-components";
import { CalculationOutputType, FlowType } from "@validereinc/domain";
import React from "react";

export const NetworkCalculationResultAdjustmentPanel = () => {
  const { run, network, flows } = useNetworkCalculationResult();

  const getOutput = (measurementType: string, assetId?: string) =>
    run?.output?.outputs?.find(
      ({ asset_id, measurement_type }: CalculationOutputType) => {
        if (assetId) {
          return assetId === asset_id && measurement_type === measurementType;
        }
        return measurement_type === measurementType;
      }
    );

  const getInput = (measurementType: string, assetId?: string) =>
    run?.input?.calculation_parameters?.find(
      ({ asset_id, id }: CalculationOutputType) => {
        if (assetId) {
          return assetId === asset_id && id === measurementType;
        }
        return id === measurementType;
      }
    );

  const flowToVolume = ({ id }: FlowType) =>
    getOutput("volume", id) ?? getInput("volume", id);

  const formatOutput = useFormatOutput();

  const getSummedVolume = (measurementType: string) => {
    const volumes = flows
      .filter(
        ({ type, id }) =>
          type === measurementType && !network?.network_inlet_ids?.includes(id)
      )
      .map(flowToVolume);

    if (!volumes.length) {
      return "-";
    }

    const totalVolume = volumes
      .map((volume) => (volume ? volume.measurement_value : 0) as number)
      .reduce((a, b) => a + b, 0);

    return formatOutput({ ...volumes[0], measurement_value: totalVolume });
  };

  return (
    <KeyValuePanel
      panelProps={{ title: "Volume Adjustment Details", isFluidY: false }}
      panelMaxColumnCount={4}
      data={[
        {
          title: "Total Measured Inlet",
          value: formatOutput(getOutput("upstream_volume")),
        },
        {
          title: "Fuel",
          value: getSummedVolume("fuel"),
        },
        {
          title: "Vent",
          value: getSummedVolume("vent"),
        },
        {
          title: "Flare",
          value: getSummedVolume("flare"),
        },
      ]}
    />
  );
};
