import { getRoundedValue } from "#utils/stringFormatter";
import {
  getFormattedNumber,
  getFormattedNumberWithUnit,
} from "@validereinc/utilities";
import Immutable from "immutable";

import {
  MEASUREMENT_TYPES_ERROR,
  MEASUREMENT_TYPES_RECEIVED,
} from "../constants/action-types";

export const CUBIC_METERS_VOLUME = "Volume (Cubic Meters)";
export const PERCENTAGE_KEY = "PERCENTAGE";
export const DEFAULT_PERCENTAGE_DECIMAL = 2;
const ERROR_MESSAGE = "-";

export const defaultMeasurementNames = (measurementState) => {
  return measurementState.defaultMeasurementTypesList.map((type) => type.name);
};

export const measurementNames = (measurementState) => {
  return measurementState.measurementTypesList.map((type) => type.name);
};

export const getMeasurementType = (measurementState) => (measurementType) => {
  const type = measurementState.measurementTypes.get(measurementType);

  if (type) {
    return type;
  } else {
    if (measurementState.measurementTypes.size > 0) {
      console.warn(`Could not find measurement type: '${measurementType}'`);
    }
    return MeasurementType();
  }
};

/**
 * @param {measurementTypes Object} measurementState A map of measurementTypes
 * @param {string} measurementKey The specific measurementType key of the measurement
 * @param {number} measurementValue
 * @returns The string representation of a given measurement with the standard amount
 *  of decimals (default 2). If measurementValue cannot be parsed into a number, returns "-"
 */
export const getFormattedMeasurementValue =
  (measurementState) => (measurementKey, measurementValue) => {
    let numDecimals = 2;

    if (measurementKey === PERCENTAGE_KEY) {
      numDecimals = DEFAULT_PERCENTAGE_DECIMAL;
    } else {
      const type =
        measurementState.measurementTypes.get(measurementKey) ??
        MeasurementType();

      numDecimals = type.decimals;
    }

    const roundedValue = getRoundedValue(
      measurementValue,
      numDecimals,
      ERROR_MESSAGE
    );

    return roundedValue !== ERROR_MESSAGE
      ? getFormattedNumber(roundedValue, numDecimals)
      : ERROR_MESSAGE;
  };

const getMeasurementKeyPrefix = (measurementUnit) => {
  switch (measurementUnit) {
    case "% mole":
      return "Mole Percentage";
    case "% vol.":
      return "Volume Percentage";
    case "% mass":
      return "Mass Percentage";
    default:
      return "";
  }
};

/**
 * @param {measurementTypes Object} measurementState A map of measurementTypes
 * @param {string} measurementKey The specific measurementType key of the measurement
 * @param {Object} measurement Contains value and unit properties
 * @returns The string representation of a given measurement with the standard amount
 *  of decimals (default 2). If measurement.value cannot be parsed into a number, returns "-"
 */
export const getFormattedMeasurementValueWithUnit =
  (measurementState) => (measurementKey, measurement) => {
    let type = measurementState.measurementTypes.get(measurementKey);

    if (type === undefined) {
      const prefix = getMeasurementKeyPrefix(measurement?.unit);

      type =
        measurementState.measurementTypes.get(`${prefix} ${measurementKey}`) ??
        MeasurementType();
    }

    const numDecimals = type.decimals;

    const roundedValue = getRoundedValue(measurement?.value, numDecimals, null);

    return getFormattedNumberWithUnit(
      {
        value: roundedValue,
        unit: measurement?.unit,
      },
      numDecimals
    );
  };

const clampToRange = (minimum, maximum) => (value) => {
  const smallerThanMax = Math.min(maximum, value);
  const andLargerThanMinimum = Math.max(minimum, smallerThanMax);

  return andLargerThanMinimum;
};

const sortBySortKey = (measurementTypeA, measurementTypeB) => {
  if (measurementTypeA.sort_key && measurementTypeB.sort_key) {
    const toSortRange = clampToRange(-1, 1);
    return toSortRange(measurementTypeA.sort_key - measurementTypeB.sort_key);
  } else if (measurementTypeA.sort_key) {
    return -1;
  } else if (measurementTypeB.sort_key) {
    return 1;
  } else {
    return measurementTypeA.name.localeCompare(measurementTypeB.name);
  }
};

export const MeasurementType = Immutable.Record({
  name: undefined,
  display_name: undefined,
  unit: undefined,
  decimals: 2,
  used_by: [],
  sort_key: undefined,
});

export const filterByUsedBy = (type) => (measurementType) => {
  return measurementType.used_by.includes(type);
};

export const MeasurementsState = Immutable.Record(
  {
    measurementTypes: Immutable.Map(),
    measurementTypesList: [],
    defaultMeasurementTypes: Immutable.Map(),
    defaultMeasurementTypesList: [],
  },
  "MeasurementsState"
);

export default (state = MeasurementsState(), action) => {
  switch (action.type) {
    case MEASUREMENT_TYPES_RECEIVED: {
      return state.withMutations((s) => {
        Object.entries(action.payload).forEach(([name, data]) => {
          const measurementType = MeasurementType({
            name,
            ...data,
          });

          s.measurementTypes = s.measurementTypes.set(name, measurementType);
          s.defaultMeasurementTypes = measurementType.sort_key
            ? s.defaultMeasurementTypes.set(name, measurementType)
            : s.defaultMeasurementTypes;
        });

        s.measurementTypesList = [...s.measurementTypes.values()];
        s.measurementTypesList.sort(sortBySortKey);

        s.defaultMeasurementTypesList = [...s.defaultMeasurementTypes.values()];
        s.defaultMeasurementTypesList.sort(sortBySortKey);
      });
    }
    case MEASUREMENT_TYPES_ERROR:
      return state.withMutations((s) => {
        s.fetchError = true;
        s.fetchErrorMessage = action.payload.message;
        s.fetchErrorStatus = action.payload.status;
      });

    default:
      return state;
  }
};
