import { RoutingLink } from "#batteries-included-components/RoutingLink";
import { getTableCellDisplayValue } from "#batteries-included-components/Tabs/EstimationMethod/helpers";
import { useCustomAttributeFilters } from "#hooks/FilterPanel/useCustomAttributeFilters";
import { useListEquipment } from "#hooks/adapters/useEquipment";
import { useListFlows } from "#hooks/adapters/useFlows";
import { useListNetworks } from "#hooks/adapters/useNetworks";
import { linkToEquipmentDetail } from "#routes/organization/equipment/[equipmentId]";
import { linkToFacilityDetail } from "#routes/organization/facilities/[facilityId]";
import { FlowDetailRoute } from "#routes/organization/flows/[flowId]/detail";
import { NetworkDetailRoute } from "#routes/organization/networks/[networkId]/detail";
import { useMeasurementTypes } from "#src/contexts/MeasurementTypeContext";
import useLocalization from "#src/hooks/useLocalization";
import { DataTable, HeaderType } from "@validereinc/common-components";
import {
  AssetType,
  AssetTypeType,
  CalculationParameterType,
  EstimationMethodWithConfigurationAndRunType,
} from "@validereinc/domain";
import startCase from "lodash/startCase";
import React from "react";

/** Add a `tableRowId` key to the configuration object for use with row selection */
export const withTableKey = (
  item: EstimationMethodWithConfigurationAndRunType
) => ({
  ...item,
  tableRowId: toTableKey(item),
});

export const toTableKey = (item: EstimationMethodWithConfigurationAndRunType) =>
  `${item.id}_${item.calculator_configuration?.year_month}`;

/** Convert a configuration object to an identifier for use in the API */
export const toApiKey = (
  item: EstimationMethodWithConfigurationAndRunType
) => ({
  estimation_method_id: item.id,
  year_month: item.calculator_configuration?.year_month,
});

export const getFacilityKey = (assetType: AssetTypeType) => {
  switch (assetType) {
    case AssetType.FLOW:
      return "flow.facility.id";
    case AssetType.EQUIPMENT:
      return "equipment.facility.id";
    default:
      return "facility.id";
  }
};

export const ASYNC_THRESHOLD = 25;

export const useAssetSpecificCalculationHeaders = (
  assetType: AssetTypeType,
  items: EstimationMethodWithConfigurationAndRunType[]
): {
  headers: Array<HeaderType<EstimationMethodWithConfigurationAndRunType>>;
  isLoading: boolean;
} => {
  const { localize } = useLocalization();

  const assetParams = {
    filters: {
      id: Array.from(
        new Set(
          items
            .filter(({ entity_type }) => entity_type === assetType)
            .map(({ entity_id }) => entity_id)
        )
      ),
    },
  };

  const isAssetQueryReady = !!assetParams.filters.id.length;
  const isEquipmentQueryEnabled =
    assetType === AssetType.EQUIPMENT && isAssetQueryReady;
  const isFlowQueryEnabled = assetType === AssetType.FLOW && isAssetQueryReady;
  const isNetworkQueryEnabled =
    assetType === AssetType.ASSET_GROUP && isAssetQueryReady;

  const equipmentQuery = useListEquipment(assetParams, {
    select: ({ data }) => data,
    enabled: isEquipmentQueryEnabled,
  });

  const flowsQuery = useListFlows(assetParams, {
    select: ({ data }) => data,
    enabled: isFlowQueryEnabled,
  });

  const networksQuery = useListNetworks(assetParams, {
    enabled: isNetworkQueryEnabled,
  });

  const {
    customAttributeHeaders,
    isLoading: isNetworkCustomAttributesLoading,
  } = useCustomAttributeFilters({
    assetType: AssetType.ASSET_GROUP,
  });

  const isLoading =
    (isEquipmentQueryEnabled && equipmentQuery.isLoading) ||
    (isFlowQueryEnabled && flowsQuery.isLoading) ||
    (isNetworkQueryEnabled && networksQuery.isLoading) ||
    isNetworkCustomAttributesLoading;

  switch (assetType) {
    case AssetType.EQUIPMENT:
      return {
        headers: [
          {
            key: "equipment_details",
            label: "Equipment Details",
            headers: [
              {
                key: "facility_name",
                label: localize("Facility"),
                isSortable: true,
                minWidth: 180,
                renderComponent: ({ item }) => (
                  <RoutingLink to={linkToFacilityDetail(item.facility_id)}>
                    {item.facility_name}
                  </RoutingLink>
                ),
              },
              {
                key: "entity_name",
                label: localize("Equipment"),
                isSortable: true,
                minWidth: 180,
                renderComponent: ({ item }) => (
                  <RoutingLink to={linkToEquipmentDetail(item.entity_id)}>
                    {item.entity_name}
                  </RoutingLink>
                ),
              },
              {
                key: "equipment.type.name",
                label: localize("Equipment Type"),
                minWidth: 180,
                renderComponent: ({ item }) => {
                  const equipment = equipmentQuery.data?.find(
                    ({ id }) => id === item.entity_id
                  );
                  return equipment?.type?.name ?? "-";
                },
              },
            ],
          },
        ],
        isLoading,
      };
    case AssetType.FLOW:
      return {
        headers: [
          {
            key: "flow_details",
            label: "Flow Details",
            headers: [
              {
                key: "entity_name",
                label: localize("Flow"),
                isSortable: true,
                minWidth: 180,
                renderComponent: ({ item }) => (
                  <RoutingLink
                    to={FlowDetailRoute.toLink({
                      pathParams: { flowId: item.entity_id },
                    })}
                  >
                    {item.entity_name}
                  </RoutingLink>
                ),
              },
              {
                key: "flow.associated_facility_id",
                label: localize("Facility"),
                isSortable: true,
                minWidth: 180,
                renderComponent: ({ item }) => {
                  const flow = flowsQuery.data?.find(
                    ({ id }) => id === item.entity_id
                  );
                  return flow?.associated_facility ? (
                    <RoutingLink
                      to={linkToFacilityDetail(flow.associated_facility_id)}
                    >
                      {flow?.associated_facility?.name}
                    </RoutingLink>
                  ) : (
                    "-"
                  );
                },
              },
              {
                key: "flow.associated_equipment_id",
                label: localize("Equipment"),
                isSortable: true,
                minWidth: 180,
                renderComponent: ({ item }) => {
                  const flow = flowsQuery.data?.find(
                    ({ id }) => id === item.entity_id
                  );
                  return flow?.associated_equipment ? (
                    <RoutingLink
                      to={linkToEquipmentDetail(flow.associated_equipment_id)}
                    >
                      {flow?.associated_equipment?.name}
                    </RoutingLink>
                  ) : (
                    "-"
                  );
                },
              },
              {
                key: "flow.type",
                label: localize("Flow Type"),
                isSortable: true,
                minWidth: 120,
                renderComponent: ({ item }) => {
                  const flow = flowsQuery.data?.find(
                    ({ id }) => id === item.entity_id
                  );
                  return startCase(flow?.type);
                },
              },
            ],
          },
        ],
        isLoading,
      };
    case AssetType.ASSET_GROUP:
      return {
        headers: [
          {
            key: "network_details",
            label: "Network Details",
            headers: [
              {
                key: "entity_name",
                label: "Network",
                isSortable: true,
                renderComponent: ({ item }) => (
                  <RoutingLink
                    to={NetworkDetailRoute.toLink({
                      pathParams: { networkId: item.entity_id },
                    })}
                  >
                    {item.entity_name}
                  </RoutingLink>
                ),
              },
              ...customAttributeHeaders.map((customAttributeHeader) => ({
                ...customAttributeHeader,
                renderComponent: ({
                  item,
                }: {
                  item: EstimationMethodWithConfigurationAndRunType;
                }) => {
                  const value = networksQuery.data?.data.find(
                    ({ id }) => id === item.entity_id
                  )?.custom_attributes?.[customAttributeHeader.field_name];
                  if (value) {
                    return customAttributeHeader.renderComponent({ value });
                  }
                  return "-";
                },
              })),
            ],
          },
        ],
        isLoading: isNetworkCustomAttributesLoading,
      };
    default:
      return { headers: [], isLoading };
  }
};

export const useCalculationInputHeaders = (
  params?: CalculationParameterType[]
) => {
  const { getUnitName } = useMeasurementTypes();

  if (!params?.length) {
    return [];
  }

  return [
    {
      key: "inputs",
      label: "Inputs",
      headers: params.map((input) => {
        return {
          key: input.id,
          label: input.display_name,
          tooltip: input.description,
          isSortable: false,
          renderComponent: ({
            item,
          }: {
            item: EstimationMethodWithConfigurationAndRunType;
          }) => {
            const inputValue =
              item.calculator_configuration?.inputs?.[input.id];

            return !inputValue && inputValue !== 0
              ? "-"
              : getTableCellDisplayValue({
                  ...input,
                  measurement_value:
                    item.calculator_configuration?.inputs?.[input.id]?.value,
                  measurement_unit: getUnitName(input.measurement_unit),
                });
          },
        };
      }),
    },
  ];
};

export const useCalculationOutputHeaders = ({
  measurementKeys,
  volumeAllowed,
  emissionsAllowed,
  networkAllowed,
  assetType,
}: {
  measurementKeys: string[];
  volumeAllowed: string[];
  networkAllowed: string[];
  emissionsAllowed: string[];
  assetType: AssetTypeType;
}) => {
  const { getTypeName, getUnitName, getPrecisionByType } =
    useMeasurementTypes();

  return [
    {
      key: "results",
      label: "Results",
      headers: measurementKeys.map((key) => {
        return {
          key: key,
          label: getTypeName(key),
          isSelected:
            volumeAllowed.includes(key) ||
            emissionsAllowed.includes(key) ||
            (assetType === AssetType.ASSET_GROUP &&
              networkAllowed.includes(key))
              ? true
              : false,
          renderComponent: ({
            item,
          }: {
            item: EstimationMethodWithConfigurationAndRunType;
          }) => {
            const outputValue = item.calculator_run?.run_output?.outputs?.find(
              ({ measurement_type }) => measurement_type === key
            );
            return outputValue ? (
              <DataTable.DataRow.NumberCell
                unit={getUnitName(outputValue.measurement_unit)}
                value={outputValue.measurement_value}
                precision={getPrecisionByType(key)}
              />
            ) : (
              "-"
            );
          },
        };
      }),
    },
  ];
};
