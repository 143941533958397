import {
  LegacyDataTable,
  MultiDropdownInputWithSearch,
  Panel,
  Title,
} from "@validereinc/common-components";
import * as PropTypes from "prop-types";
import React, { useMemo } from "react";
import { AutoSizer } from "react-virtualized";
import "../StreamAlerts.scss";

const StreamsAndMeasurementPicker = ({
  originalSelectedStreams,
  checkedStreams,
  measurementTypes,
  measurementInfo,
  getMeasurementType,
  setMeasurementInfo,
  setCheckedStreams,
}) => {
  const measurementTypesDropdownOptions = useMemo(() => {
    return measurementTypes.map((measurement) => measurement.display_name);
  }, [measurementTypes]);

  const cellRenderer = (rowData, columnKey) => {
    return <div className="wrap">{rowData[columnKey]}</div>;
  };

  const headers = [
    {
      label: "Stream",
      key: "name",
      width: 400,
      cellRenderer: cellRenderer,
    },
    {
      label: "Product Type",
      key: "product_type",
      cellRenderer: cellRenderer,
      width: 125,
    },
  ];

  return (
    <Panel>
      <Title
        className="streamAlertsCreate__formSectionTitle"
        type="subheader"
      >
        Selected Streams
      </Title>

      <Panel>
        <div className="streamAlertsCreate__tableForm">
          <AutoSizer>
            {({ height, width }) => (
              <LegacyDataTable
                height={height}
                width={width}
                headers={headers}
                list={originalSelectedStreams}
                checkedList={checkedStreams}
                onCheckboxClick={setCheckedStreams}
                rowHeight={60}
              />
            )}
          </AutoSizer>
        </div>
      </Panel>

      <Title
        type="subheader"
        className="streamAlertsCreate__formSectionTitle"
      >
        Measurement
      </Title>
      <MultiDropdownInputWithSearch
        name="test_property"
        label="Measurement"
        options={measurementTypesDropdownOptions}
        isMulti={false}
        value={[measurementInfo.name]}
        onChange={(value) => {
          const measurement = getMeasurementType(value);
          setMeasurementInfo({ name: value, unit: measurement.unit });
        }}
      />
    </Panel>
  );
};

StreamsAndMeasurementPicker.propTypes = {
  checkedStreams: PropTypes.array.isRequired,
  originalSelectedStreams: PropTypes.array.isRequired,
  onStreamChange: PropTypes.func.isRequired,
  measurementTypes: PropTypes.object.isRequired,
  getMeasurementType: PropTypes.func.isRequired,
  setMeasurementInfo: PropTypes.func.isRequired,
  setCheckedStreams: PropTypes.func.isRequired,
  measurementInfo: PropTypes.object.isRequired,
};

export default StreamsAndMeasurementPicker;
