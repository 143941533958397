import { useMeasurementTypes } from "#src/contexts/MeasurementTypeContext";
import { DropdownInput } from "@validereinc/common-components";
import React from "react";

import type { LookupFieldType } from "@validereinc/domain";

export const MeasurementFields = ({
  deviceQuestions,
}: {
  deviceQuestions?: LookupFieldType[];
}) => {
  const { measurementTypes, measurementUnits } = useMeasurementTypes();

  return (
    <>
      <DropdownInput
        label="Measurement Type"
        name="measurement_type"
        options={measurementTypes}
        labelKey="name"
        valueKey="id"
        isFluid
        isRequired
      />

      <DropdownInput
        label="Measurement Unit"
        name="measurement_unit"
        options={measurementUnits}
        labelKey="name.singular"
        valueKey="id"
        isFluid
        isRequired
      />

      {deviceQuestions?.length ? (
        <>
          <DropdownInput
            label="Source Question"
            name="source_lookup_question_id"
            options={deviceQuestions}
            labelKey="prompt"
            valueKey="question_id"
            isFluid
          />

          <DropdownInput
            label="Subject Question"
            name="subject_lookup_question_id"
            options={deviceQuestions}
            labelKey="prompt"
            valueKey="question_id"
            isFluid
          />
        </>
      ) : null}
    </>
  );
};
