import { getNumberColorBySign } from "../../../../utils/styleCalculator";
import { getComponentShortForm } from "../../Quality/RecordOfQualityHelper";
import { sortComponents } from "../BalancesTableHelper";
import { getTotalLabel } from "../BalanceUpserts/BalanceHelper";

function getPlotData(title, value) {
  if (value) {
    const measurementValue = value?.value ?? 0;

    return {
      x: title,
      y: measurementValue,
      label: value,
      color: getNumberColorBySign(measurementValue),
    };
  }
}

export function getPercentDifferencePlotPoint(
  balanceSummary,
  type,
  balanceType,
  showTotalVolume
) {
  if (balanceSummary) {
    const plotData = [];
    const components = balanceSummary?.components;

    sortComponents(Object.keys(components[type])).map((component) => {
      plotData.push(getPlotData(component, components[type][component]));
    });

    if (showTotalVolume) {
      const totalDifferenceValue = balanceSummary?.total?.[type] ?? null;
      const totalDifferencePlotData = getPlotData(
        getTotalLabel(balanceType),
        totalDifferenceValue
      );

      plotData.unshift(totalDifferencePlotData);
    }

    return plotData;
  }

  return [];
}

export function getXAxisTicks(tick) {
  return getComponentShortForm(tick);
}
