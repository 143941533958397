import { useState, useEffect } from "react";
import RecordOfQualityService from "#services/RecordOfQualityService";

const MODAL_TRANSITION_DELAY = 400; // 400 ms delay

const useFetchResults = (
  recordOfQualityId,
  accountingPeriodId,
  isModalVisible
) => {
  const [recordOfQualityDetail, setRecordOfQualityDetail] = useState({});
  const [systemBalance, setSystemBalance] = useState({});
  const [historicalData, setHistoricalData] = useState({});

  const [originalRecordOfQualityDetail, setOriginalRecordOfQualityDetail] =
    useState({});
  const [originalSystemBalance, setOriginalSystemBalance] = useState({});

  const [state, setState] = useState("loading");
  const [lastUpdatedAt, setLastUpdatedAt] = useState(new Date());

  const isInitialLoad = !recordOfQualityDetail?.id;

  useEffect(() => {
    if (!isModalVisible) {
      setData(null, "loading");
      setOriginalData(null);
    }
  }, [isModalVisible]);

  useEffect(() => {
    if (recordOfQualityId && accountingPeriodId && isModalVisible) {
      setState(isInitialLoad ? "loading" : "refetching");

      RecordOfQualityService.getRecordOfQualityModalResults(
        recordOfQualityId,
        accountingPeriodId
      )
        .then((dataArray) => {
          if (isInitialLoad) {
            // The delay allow a much smoother transition when loading up a modal
            setTimeout(() => {
              setData(dataArray, "loaded");
              setOriginalData(dataArray);
            }, MODAL_TRANSITION_DELAY);
          } else {
            setData(dataArray, "loaded");
          }
        })
        .catch(() => setState("error"));
    }
  }, [recordOfQualityId, accountingPeriodId, lastUpdatedAt, isModalVisible]);

  const refetchResults = () => {
    setLastUpdatedAt(new Date());
  };

  const setData = (dataArray, state) => {
    setRecordOfQualityDetail(dataArray?.[0]?.data ?? {});
    setSystemBalance(dataArray?.[1]?.data ?? {});
    setHistoricalData(dataArray?.[2]?.data ?? {});
    setState(state);
  };

  const setOriginalData = (dataArray) => {
    setOriginalRecordOfQualityDetail(dataArray?.[0]?.data ?? {});
    setOriginalSystemBalance(dataArray?.[1]?.data ?? {});
  };

  return [
    recordOfQualityDetail,
    systemBalance,
    historicalData,
    state,
    originalRecordOfQualityDetail,
    originalSystemBalance,
    refetchResults,
  ];
};

/**
 * This hook handles all the fetches related to showing the results of the current
 * roq selection to the record balance and overall system balance. It syncs the
 * roq selection state to the backend data fetched
 */
const useManageResultFetches = (
  recordOfQuality,
  accountingPeriod,
  setSelectionState,
  updateModalData,
  isModalVisible,
  roqSelectionState
) => {
  const [
    recordOfQualityDetail,
    systemBalance,
    historicalData,
    loadingState,
    originalRecordOfQualityDetail,
    originalSystemBalance,
    refetchResults,
  ] = useFetchResults(
    recordOfQuality?.id,
    accountingPeriod?.id,
    isModalVisible
  );

  /**
   * The only place where the results has an effect on the selection (i.e. right
   * side affects left side). There are two states where this happens: on modal
   * open and on reset selection. Both times will have isInitialized be false but
   * the loadingState will be "loading" for modal open and "refetching" for reset
   */
  useEffect(() => {
    if (
      recordOfQualityDetail?.selection_stream_id &&
      loadingState === "loaded" &&
      !roqSelectionState.isInitialized
    ) {
      setSelectionState(recordOfQualityDetail);
    }
  }, [recordOfQualityDetail, loadingState, roqSelectionState.isInitialized]);

  useEffect(() => {
    refetchResults();
  }, [updateModalData]);

  return [
    recordOfQualityDetail,
    systemBalance,
    historicalData,
    loadingState,
    originalRecordOfQualityDetail,
    originalSystemBalance,
  ];
};

export default useManageResultFetches;
