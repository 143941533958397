import { restAPI } from "./api";

export const TemplateAdapter = {
  dataset: {
    /**
     * Get a template for a dataset
     * @see {@link https://admin-staging.dataplatform.validere.xyz/docs#/Defaults/get_dataset_schema_template_file_defaults_datasets__dataset_id__schema_template_get}
     * // REVIEW: the BE shape here for request/resp in the docs don't make sense.
     * @returns a template file
     */
    getOne: ({
      datasetId,
      companyId,
      clientId,
    }: {
      datasetId: string;
      companyId: string;
      clientId?: string;
    }): Promise<Blob> =>
      restAPI.dataPlatformAdminAPI.GET({
        endpoint: `/defaults/datasets/${datasetId}/schema/template`,
        query: {
          ...(clientId ? { client_id: clientId } : {}),
          ...(companyId ? { company_id: companyId } : {}),
        },
        headers: {
          Accept:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        },
        responseType: "blob",
      }),
  },
};
