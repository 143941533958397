import { LegacyDataTable } from "@validereinc/common-components";
import * as PropTypes from "prop-types";
import React from "react";
import { AutoSizer } from "react-virtualized";
import AdjustmentCell from "./AdjustmentCell";
import "./AdjustmentFactors.scss";
import CompoundLabel from "./CompoundLabel";

const sortByDescription = (list) => {
  return [...list].sort((a, b) => {
    const aValue = a.description ?? "-";
    const bValue = b.description ?? "-";

    return aValue.localeCompare(bValue);
  });
};

const AdjustmentFactorsTable = (props) => {
  const onEditClick = (adjustmentFactors) => {
    props.onEditAdjustmentFactorClick(adjustmentFactors);
  };

  const typeRenderer = (rowData) => {
    return rowData.type === "component" ? "Components" : "Total Volume";
  };

  const compoundRenderer = (rowData) => {
    return rowData.is_compound ? "Yes" : "No";
  };

  const descriptionRenderer = (rowData) => {
    if (rowData.description) {
      return <div className="clipText">{rowData.description}</div>;
    } else {
      return "-";
    }
  };

  const Headers = [
    {
      label: "Name",
      key: "name",
      width: 200,
    },
    {
      label: "Type",
      key: "type",
      width: 115,
      cellRenderer: typeRenderer,
    },
    {
      label: <CompoundLabel />,
      key: "is_compound",
      width: 120,
      cellRenderer: compoundRenderer,
    },
    {
      label: "Adjustments",
      key: "adjustments",
      width: 200,
      cellRenderer: AdjustmentCell,
      sort: sortByDescription,
    },
    {
      label: "Description",
      key: "description",
      width: 400,
      cellRenderer: descriptionRenderer,
      sort: sortByDescription,
    },
  ];

  return (
    <AutoSizer>
      {({ height, width }) => (
        <LegacyDataTable
          width={width}
          height={height}
          headers={Headers}
          list={props.adjustmentFactors}
          filterKey="name"
          filterTitle="Name"
          defaultSortBy="name"
          defaultSortDirection="asc"
          addButtonName="Adjustment Factor"
          onAddClick={
            props.haveWritePermissions ? props.onAddAdjustmentFactorClick : null
          }
          onCellClick={(rowData) => onEditClick(rowData)}
          isLoading={props.loading}
          highlightRow
        />
      )}
    </AutoSizer>
  );
};

AdjustmentFactorsTable.propTypes = {
  haveWritePermissions: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  adjustmentFactors: PropTypes.array.isRequired,
  onAddAdjustmentFactorClick: PropTypes.func.isRequired,
  onEditAdjustmentFactorClick: PropTypes.func.isRequired,
};

export default AdjustmentFactorsTable;
