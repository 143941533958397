import {
  Button,
  Form,
  Modal,
  Title,
  useForm,
} from "@validereinc/common-components";
import * as PropTypes from "prop-types";
import React, { useState } from "react";
import EquipmentModalForm from "./EquipmentModalForm";
import useFilterInputs from "./EquipmentTableFilter";

const EquipmentModal = ({
  onClose,
  onSubmit,
  equipments,
  selectedEquipment,
}) => {
  const [formState, setFormState] = useState("enabled");

  const [filterRow, filterPillbox, filteredEquipments] =
    useFilterInputs(equipments);

  const form = useForm({
    defaultValues: {
      equipments: selectedEquipment ?? [],
    },
  });

  const handleSubmit = (formValues) => {
    setFormState("disabled");

    onSubmit(formValues);

    setFormState("enabled");
  };

  return (
    <Modal
      show={true}
      onClose={onClose}
      size="large"
    >
      <Modal.Header>
        <Title>Select Equipment</Title>
      </Modal.Header>

      <Form
        onSubmit={handleSubmit}
        {...form}
      >
        <Modal.Body>
          <EquipmentModalForm
            form={form}
            equipments={filteredEquipments}
            formState={formState}
            filterRow={filterRow}
            filterPillbox={filterPillbox}
            noFilterListCount={equipments.length}
          />
        </Modal.Body>

        <Modal.Footer>
          <Button
            className="pull-left"
            onClick={onClose}
          >
            Close
          </Button>

          <Button
            variant="primary"
            type="submit"
            disabled={formState !== "enabled"}
          >
            Update Equipments
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

EquipmentModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  defaultValues: PropTypes.object,
  equipments: PropTypes.array,
  selectedEquipment: PropTypes.array,
};

export default EquipmentModal;
