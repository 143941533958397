import { useNavigate, useSearchParams } from "#routers/hooks";
import {
  FlowRecordFilterPanel,
  FlowsFilterPanel,
  FlowsTablePanel,
  RecordsListTablePanel,
} from "#src/batteries-included-components";
import { ApplyAutomationProgressBanner } from "#src/batteries-included-components/Banners/ApplyAutomationProgressBanner";
import { ImportDataActionStatusWithQueue } from "#src/batteries-included-components/Banners/ImportDataActionStatus";
import { useIsFeatureAvailable } from "#src/contexts/AuthenticatedContext.helpers";
import useLocalization from "#src/hooks/useLocalization";
import {
  CustomStorageKeyTypes,
  useCustomStorageKey,
  useStorageKey,
} from "#src/hooks/useStorageKey";
import { useBreadcrumbsFromRoute } from "#utils/route";
import { Button, Page, Tab } from "@validereinc/common-components";
import { AssetType } from "@validereinc/domain";
import isEqual from "lodash/isEqual";
import React, { useState } from "react";
import { FlowListRoute } from "./";
import { FlowCreateRoute } from "./create";

const DEFAULT_TAB_KEY = "list";

export const FlowsListPage = () => {
  const [isDataIngestionEnabled] = useIsFeatureAvailable({
    featureFlagQuery: "core:data_pipeline",
  });
  const navigate = useNavigate();
  const { localize, isLoading } = useLocalization();
  const [selectableProperties, setSelectableProperties] = useState<string[]>();
  const [searchParams, setSearchParams] = useSearchParams();
  const listStorageKeys = useStorageKey("flows");
  const recordStorageKeys = useStorageKey("flows-records");
  const recordsApplyAutomationStorageKey = useCustomStorageKey(
    "flows-records-apply-automation",
    CustomStorageKeyTypes.JOB
  );

  const onClickAddNewFlow = () => {
    navigate(FlowCreateRoute.toLinkParts());
  };

  const actionRow = (
    <Button
      variant="primary"
      onClick={onClickAddNewFlow}
    >
      Create Flow
    </Button>
  );

  const [breadcrumbs] = useBreadcrumbsFromRoute(FlowListRoute);

  return (
    <Page
      breadcrumbs={breadcrumbs}
      title={FlowListRoute.title}
      actionRow={actionRow}
      activeTabKey={searchParams?.tab ?? DEFAULT_TAB_KEY}
      onActiveTabKeyChange={(tabKey: string) =>
        setSearchParams({ tab: tabKey })
      }
      isLoading={isLoading}
    >
      <Tab
        title="List"
        tabKey="list"
      >
        {isDataIngestionEnabled ? (
          <ImportDataActionStatusWithQueue
            resource={{
              id: "flow",
              name: "Flow",
              singular: `${localize("Flow")}`,
              plural: `${localize("flow_plural")}`,
            }}
          />
        ) : null}
        <FlowsFilterPanel
          filterConfigStorageKey={listStorageKeys.filterConfigStorageKey}
          isWithCustomAttributes
          isWithPeriod
        />
        <FlowsTablePanel
          {...listStorageKeys}
          isWithCustomAttributes
          isWithTableActions
        />
      </Tab>
      <Tab
        title="Records"
        tabKey="records"
      >
        {isDataIngestionEnabled ? (
          <ImportDataActionStatusWithQueue
            resource={{
              id: "record",
              name: "FlowRecord",
              singular: `${localize("Flow")} Record`,
              plural: `${localize("Flow")} Records`,
            }}
          />
        ) : null}
        <ApplyAutomationProgressBanner
          jobStorageKey={recordsApplyAutomationStorageKey}
        />
        <FlowRecordFilterPanel
          filterConfigStorageKey={recordStorageKeys.filterConfigStorageKey}
          selectableProperties={selectableProperties}
        />
        <RecordsListTablePanel
          type={AssetType.FLOW}
          {...recordStorageKeys}
          applyAutomationStorageKey={recordsApplyAutomationStorageKey}
          setAvailableProperties={(keys) => {
            if (isEqual(keys, selectableProperties)) return;
            setSelectableProperties(keys);
          }}
        />
      </Tab>
    </Page>
  );
};
