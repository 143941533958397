import { Device, DeviceTypeType } from "@validereinc/domain";
import { useEffect, useState } from "react";

// IMPROVE: deprecate this and use react query and @validereinc/domain adapters
const useDeviceTypes = (): [DeviceTypeType[], string] => {
  const [deviceTypes, setDeviceTypes] = useState<DeviceTypeType[]>([]);
  const [loadingState, setLoadingState] = useState("loading");

  useEffect(() => {
    Device.types
      .getList()
      .then((data) => {
        setDeviceTypes(data);
        setLoadingState("loaded");
      })
      .catch((error) => {
        console.error(error);
        setLoadingState("error");
      });
  }, []);

  return [deviceTypes, loadingState];
};

export default useDeviceTypes;
