import { useNavigate } from "#src/Routers/hooks";
import { AccountChip } from "#src/batteries-included-components/Layouts/Authentication/AccountChip";
import config from "#src/config";
import {
  useAuthenticatedContext,
  useIsFeatureAvailable,
} from "#src/contexts/AuthenticatedContext.helpers";
import { linkToActivityLogs } from "#src/routes/change-log";
import { linkToMeasurementTypesSettingsPage } from "#src/routes/settings/measurement-types/index";
import { linkToRolesListPage } from "#src/routes/settings/roles-and-permissions";
import { linkToUsersListPage } from "#src/routes/settings/users";
import { linkToUserDetailPage } from "#src/routes/settings/users/detail";
import { linkToUserEditPage } from "#src/routes/settings/users/edit";
import {
  Button,
  Dialog,
  DropdownMenu,
  DropdownMenuOption,
  Icon,
  Link,
  Tooltip,
} from "@validereinc/common-components";
import classNames from "classnames/bind";
import React, { useState } from "react";
import styles from "./AccountNavBar.module.scss";
import { CustomAttributeRoute } from "#routes/settings/custom-attributes";

const cx = classNames.bind(styles);

export const LegacyAccountNavBarSettings = {
  ACCOUNT: "account",
  COMPANY: "company",
  HELP: "help",
  DEFAULT: "settings",
} as const;

export const AccountNavBarSettings = {
  USERS: "users",
  ROLES_AND_PERMISSIONS: "roles-and-permissions",
  CHANGE_LOG: "change-log",
  KNOWLEDGE_BASE: "knowledge-base",
  CONTACT_SUPPORT: "contact-support",
  MEASUREMENT_TYPES: "measurement-types",
  CUSTOM_ATTRIBUTES: "custom-attributes",
} as const;

export const AccountNavBar = () => {
  const {
    appVersion,
    activeAuthVersion,
    logout,
    v1: { user: legacyUser },
    v2: {
      userInfo: { user },
      companyInfo: { company },
    },
  } = useAuthenticatedContext();
  const navigate = useNavigate();
  const [isSupportModalOpen, setIsSupportModalOpen] = useState(false);
  const [isUserRolesPermsAvailable] = useIsFeatureAvailable({
    featureFlagQuery: "core:roles_permissions",
  });
  const [isActivityLogsAvailable] = useIsFeatureAvailable({
    featureFlagQuery: "core:activity_logs",
    permissionQuery: "activities:read_all",
  });

  const getSettingsOptions = (): DropdownMenuOption[] => {
    const applicationOptions: DropdownMenuOption[] = [
      {
        label: "Application",
        isGroup: true,
        options: [
          {
            label: "Release Notes",
            onClick: () => window.open(config.RELEASE_NOTE_URL, "__blank"),
            slotLeft: ({ iconProps }) => (
              <Icon
                variant="package"
                {...iconProps}
              />
            ),
          },
          {
            label: "Public API",
            onClick: () => window.open(config.API_DOCS_URL, "__blank"),
            slotLeft: ({ iconProps }) => (
              <Icon
                variant="code"
                {...iconProps}
              />
            ),
          },
          {
            label: "Terms & Conditions",
            onClick: () => window.open(config.TERMS_OF_SERVICE_URL, "__blank"),
            slotLeft: ({ iconProps }) => (
              <Icon
                variant="file-text"
                {...iconProps}
              />
            ),
          },
          {
            label: "Privacy Policy",
            onClick: () => window.open(config.PRIVACY_POLICY_URL, "__blank"),
            slotLeft: ({ iconProps }) => (
              <Icon
                variant="file-text"
                {...iconProps}
              />
            ),
          },
        ],
      },
      {
        label: "Version",
        isPresentational: true,
        flair: appVersion,
      },
    ];

    if (!isUserRolesPermsAvailable && !isActivityLogsAvailable) {
      return applicationOptions;
    }

    return [
      {
        label: "Company",
        isGroup: true,
        options: [
          ...(isActivityLogsAvailable
            ? ([
                {
                  label: "Change Log",
                  onClick: () => navigate({ pathname: linkToActivityLogs() }),
                  slotLeft: ({ iconProps }) => (
                    <Icon
                      variant="clock-counter-clockwise"
                      {...iconProps}
                    />
                  ),
                },
              ] satisfies DropdownMenuOption[])
            : []),
          ...(isUserRolesPermsAvailable
            ? ([
                {
                  label: "Users",
                  onClick: () => navigate({ pathname: linkToUsersListPage() }),
                  slotLeft: ({ iconProps }) => (
                    <Icon
                      variant="users-three"
                      {...iconProps}
                    />
                  ),
                },
                {
                  label: "Roles & Permissions",
                  onClick: () => navigate({ pathname: linkToRolesListPage() }),
                  slotLeft: ({ iconProps }) => (
                    <Icon
                      variant="circle-wavy-check"
                      {...iconProps}
                    />
                  ),
                },
                {
                  label: "Measurement Types",
                  onClick: () =>
                    navigate({
                      pathname: linkToMeasurementTypesSettingsPage(),
                    }),
                  slotLeft: ({ iconProps }) => (
                    <Icon
                      variant="hourglass"
                      {...iconProps}
                    />
                  ),
                },
                {
                  label: "Custom Attributes",
                  onClick: () =>
                    navigate({
                      pathname: CustomAttributeRoute.path,
                    }),
                  slotLeft: ({ iconProps }) => (
                    <Icon
                      variant="database"
                      {...iconProps}
                    />
                  ),
                },
              ] satisfies DropdownMenuOption[])
            : []),
        ],
      },
      ...applicationOptions,
    ];
  };

  const getManageAccountOptions = (): DropdownMenuOption[] => {
    return [
      {
        label: "Manage Account",
        slotLeft: ({ iconProps }) => (
          <Icon
            variant="user-circle-gear"
            {...iconProps}
          />
        ),
        onClick: () =>
          navigate({
            pathname: linkToUserDetailPage(user?.id),
          }),
      },
    ];
  };

  const accountName =
    activeAuthVersion !== "unknown" &&
    activeAuthVersion.length === 1 &&
    activeAuthVersion.includes("v1")
      ? legacyUser?.name
      : user?.name;
  const accountEmail =
    activeAuthVersion !== "unknown" &&
    activeAuthVersion.length === 1 &&
    activeAuthVersion.includes("v1")
      ? legacyUser?.email
      : user?.email;
  const companyName =
    activeAuthVersion !== "unknown" &&
    activeAuthVersion.length === 1 &&
    activeAuthVersion.includes("v1")
      ? legacyUser?.company_name
      : company?.name;

  return (
    <nav className={cx("container")}>
      <div className={cx("settings-container")}>
        <div className={cx("org")}>
          {companyName ? (
            companyName
          ) : (
            <Tooltip
              title="You're not part of a company"
              content="Talk to our support team to configure a company for your account"
            >
              <div className={cx("text-with-icon", "warning")}>
                <Icon
                  variant="warning"
                  size={16}
                />
                No Company
              </div>
            </Tooltip>
          )}
        </div>
        <DropdownMenu
          options={[
            {
              label: "Knowledge Base",
              slotLeft: ({ iconProps }) => (
                <Icon
                  variant="lifebuoy"
                  {...iconProps}
                />
              ),
              onClick: () => window.open(config.KNOWLEDGE_BASE_URL, "__blank"),
            },
            {
              label: "Contact Support",
              slotLeft: ({ iconProps }) => (
                <Icon
                  variant="headset"
                  {...iconProps}
                />
              ),
              onClick: () => setIsSupportModalOpen(true),
            },
          ]}
        >
          <Button
            iconProps={{ size: 18 }}
            icon="question"
            variant="no-outline"
            size="xx-small"
          />
        </DropdownMenu>
        <DropdownMenu options={getSettingsOptions()}>
          <Button
            iconProps={{ size: 18 }}
            icon="gear-six"
            variant="no-outline"
            size="xx-small"
          />
        </DropdownMenu>
        <DropdownMenu
          options={[
            accountName
              ? {
                  label: accountName,
                  isPresentational: true,
                  variant: "highlight",
                }
              : {
                  label: "Update your name",
                  variant: "highlight",
                  onClick: () =>
                    navigate({ pathname: linkToUserEditPage(user?.id) }),
                },
            ...(accountEmail
              ? [
                  {
                    label: accountEmail,
                    isPresentational: true,
                  },
                ]
              : []),
            {
              isSeparator: true,
            },
            ...getManageAccountOptions(),
            {
              label: "Log out",
              slotLeft: ({ iconProps }) => (
                <Icon
                  variant="sign-out"
                  {...iconProps}
                />
              ),
              onClick: logout,
            },
          ]}
        >
          <AccountChip name={accountName} />
        </DropdownMenu>
      </div>
      <Dialog
        title="Contact our Support Team"
        isOpen={isSupportModalOpen}
        onClose={() => setIsSupportModalOpen(false)}
        actionRow={[
          <Button
            key="dismiss"
            variant="primary"
            onClick={() => setIsSupportModalOpen(false)}
          >
            Done
          </Button>,
        ]}
      >
        <>
          <p>
            You can reach us easiest by email. If possible please include a
            detailed explanation, relevant links, screenshots/video
            demonstrating the problem, replicable processes, and any other
            information that can assist you with your help request.
          </p>
          <div className={cx("support-options")}>
            <p className={cx("tag-with-label")}>
              <span
                className={cx("tag-label")}
                id="contact-support-email-tag"
              >
                Email
              </span>
              <span
                className={cx("tag-content")}
                aria-describedby="contact-support-email-tag"
              >
                <Link
                  anchorTagProps={{
                    href: `mailto:${
                      config.SUPPORT_EMAIL
                    }?subject=Need Product Help (app: Validere Private Web)${
                      companyName ? ` (company: ${companyName})` : ""
                    }${
                      accountEmail ? ` (account email: ${accountEmail})` : ""
                    }`,
                  }}
                  label={config.SUPPORT_EMAIL}
                />
              </span>
            </p>
          </div>
        </>
      </Dialog>
    </nav>
  );
};
