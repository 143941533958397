import { fetchAdjustmentFactors } from "#redux/actions/adjustmentFactors";
import { havePermission } from "#redux/reducers/permissions";
import { Page, Panel } from "@validereinc/common-components";
import * as PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getBreadcrumbsObject } from "../../../../Routers/breadcrumbsHelper";
import AddAdjustmentFactorsModal from "./AddAdjustmentFactorsModal";
import "./AdjustmentFactors.scss";
import AdjustmentFactorsTable from "./AdjustmentFactorsTable";
import EditAdjustmentFactorsModal from "./EditAdjustmentFactorsModal";

const ADD_ADJUSTMENT_FACTOR_MODAL_KEY = "add";
const EDIT_ADJUSTMENT_FACTOR_MODAL_KEY = "edit";

const mapStateToProps = ({ permissions, adjustmentFactors }) => {
  return {
    havePermission: havePermission(permissions),
    adjustmentFactors: adjustmentFactors.data.toJS(),
    isLoading: adjustmentFactors.isRequestInProgress,
  };
};

const mapDispatchToProps = {
  fetchAdjustmentFactors,
};

const AdjustmentFactors = (props) => {
  const [modalState, setModalState] = useState(null);
  const [selectedAdjustmentFactor, setSelectedAdjustmentFactor] =
    useState(null);

  const onAddAdjustmentFactorClick = () => {
    setModalState(ADD_ADJUSTMENT_FACTOR_MODAL_KEY);
  };

  const onEditAdjustmentFactorClick = (adjustmentFactor) => {
    setSelectedAdjustmentFactor(adjustmentFactor);
    setModalState(EDIT_ADJUSTMENT_FACTOR_MODAL_KEY);
  };

  const updateAdjustmentFactorList = () => {
    props.fetchAdjustmentFactors();
  };

  const onModalClose = () => {
    setModalState(null);
    setSelectedAdjustmentFactor(null);
  };

  useEffect(() => {
    props.fetchAdjustmentFactors();
  }, []);

  const haveWritePermissions = props.havePermission(
    "gas:accounting.adjustment_factors",
    "write"
  );

  const breadcrumbs = getBreadcrumbsObject(
    props.breadcrumbs,
    props.match.params
  );

  return (
    <Page
      title="Adjustment Factor List"
      breadcrumbs={breadcrumbs}
    >
      <Panel style={{ height: props.height }}>
        <AdjustmentFactorsTable
          adjustmentFactors={props.adjustmentFactors || []}
          onAddAdjustmentFactorClick={onAddAdjustmentFactorClick}
          onEditAdjustmentFactorClick={onEditAdjustmentFactorClick}
          loading={props.isLoading}
          haveWritePermissions={haveWritePermissions}
        />
      </Panel>

      <EditAdjustmentFactorsModal
        show={modalState === EDIT_ADJUSTMENT_FACTOR_MODAL_KEY}
        onHide={onModalClose}
        adjustmentFactors={props.adjustmentFactors || []}
        unboundedSelectedAdjustmentFactor={selectedAdjustmentFactor}
        updateAdjustmentFactorList={updateAdjustmentFactorList}
        haveWritePermissions={haveWritePermissions}
      />

      {haveWritePermissions && (
        <AddAdjustmentFactorsModal
          show={modalState === ADD_ADJUSTMENT_FACTOR_MODAL_KEY}
          onHide={onModalClose}
          adjustmentFactors={props.adjustmentFactors || []}
          updateAdjustmentFactorList={updateAdjustmentFactorList}
        />
      )}
    </Page>
  );
};

AdjustmentFactors.propTypes = {
  height: PropTypes.number.isRequired,
  havePermission: PropTypes.func.isRequired,
  fetchAdjustmentFactors: PropTypes.func.isRequired,
  adjustmentFactors: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  breadcrumbs: PropTypes.array,
  match: PropTypes.object,
};

const AdjustmentFactorsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AdjustmentFactors);

export default AdjustmentFactorsContainer;
