import React, { useState, useCallback } from "react";
import * as PropTypes from "prop-types";
import moment from "moment";
import sortBy from "lodash/sortBy";
import {
  XYPlot,
  HorizontalGridLines,
  VerticalGridLines,
  XAxis,
  YAxis,
  CustomSVGSeries,
  ChartLabel,
  Hint,
  WhiskerSeries,
  LineMarkSeries,
} from "react-vis";
import { AutoSizer } from "react-virtualized";
import {
  ROQ_PROPS,
  SELECTED_ROQ_PROPS,
  RecordOfQualitySampleChartLegend,
  INLINE_PROPS,
  SELECTED_INLINE_PROPS,
} from "#common/Chart/ChartSVG";
import Loader from "react-loader";
import loaderOptions from "#components/LoadingBar/LoadingBar";
import "./RecordOfQualitySampleChart.scss";
import { getComponentShortForm } from "#components/Records/Quality/RecordOfQualityHelper";
import { useRecordSelectionContext } from "../Context/recordSelectionContext";
import {
  getFormattedMeasurementValue,
  getFormattedMeasurementValueWithUnit,
} from "#redux/reducers/measurements";
import { connect } from "react-redux";
import {
  formatXAxis,
  getAllSelectedData,
  getSvgType,
  getSvgProps,
  getSvgPrecedence,
  getSvg,
  calculateXDomain,
  calculateYDomain,
} from "./RecordOfQualitySampleChartHelper";
import CustomHint from "./CustomHint";

/** A mapping from API type to hint sample type */
export const SAMPLE_TYPE = {
  composite_sample: "Composite",
  spot_sample: "Spot",
  record_of_quality: "RoQ",
  inline: "Inline",
  unknown_sample: "Unknown",
  virtual_sample: "Virtual",
};
/** The latter types will appear over the former types */
const DISPLAY_PRECEDENCE = ["unknown", "default", "alarm", "selected"];

const mapStateToProps = (state) => {
  return {
    getFormattedMeasurementValue: (measurementKey, measurementValue) =>
      getFormattedMeasurementValue(state.measurements)(
        measurementKey,
        measurementValue
      ),
    getFormattedMeasurementValueWithUnit: (measurementKey, measurementObject) =>
      getFormattedMeasurementValueWithUnit(state.measurements)(
        measurementKey,
        measurementObject
      ),
  };
};

const categorizeData = (data, intervalSelection) => {
  const roqData = [];
  let chartData = [];
  const selectedRoqData = [];
  let inlineData = [];
  let selectedInlineData = [];
  const { chart_points, inline } = data;

  const [sampleIds, previousAccountingRecordIds, methods] =
    getAllSelectedData(intervalSelection);

  if (chart_points) {
    chart_points.forEach((point) => {
      const modifiedPoint = {
        ...point,
        x: moment(point.until).valueOf(),
        y: point.value,
        value: point.value,
      };

      if (point.type === "record_of_quality") {
        const distance = moment(point.until).diff(moment(point.from));
        const roqPoint = {
          ...modifiedPoint,
          x: moment(point.from)
            .add(distance / 2)
            .valueOf(),
          xVariance: distance,
        };

        if (previousAccountingRecordIds.includes(point.id)) {
          return selectedRoqData.push(roqPoint);
        }

        return roqData.push(roqPoint);
      }

      const svgType = getSvgType(point);
      const svgProps = getSvgProps(point.type);
      const svgPrecedence = getSvgPrecedence(point, sampleIds);
      const svg = getSvg(svgType, svgProps, svgPrecedence);

      modifiedPoint.svg = svg;
      modifiedPoint.svgPrecedence = svgPrecedence;

      return chartData.push(modifiedPoint);
    });
    chartData = sortBy(chartData, (d) =>
      DISPLAY_PRECEDENCE.indexOf(d.svgPrecedence)
    );
  }

  if (inline) {
    const inlinePoints = inline
      .map((data) => ({
        ...data,
        x: moment(data.date).valueOf(),
        y: data.value,
        value: data.value,
      }))
      .sort((a, b) => moment(a.date) - moment(b.date));

    if (methods.includes("volume_weighted_inline")) {
      selectedInlineData = inlinePoints;
    } else {
      inlineData = inlinePoints;
    }
  }

  return [chartData, roqData, selectedRoqData, inlineData, selectedInlineData];
};

// MAIN COMPONENT
export const RecordOfQualitySampleChart = (props) => {
  const [hintData, setHintData] = useState(null);

  const { sampleData, sampleDataLoadingState, measurement, roqSelectionState } =
    useRecordSelectionContext();

  const { intervalSelections } = roqSelectionState;

  const [chartData, roqData, selectedRoqData, inlineData, selectedInlineData] =
    useCallback(
      categorizeData(
        sampleData,
        intervalSelections[props.intervalSelectionIndex]
      ),
      [sampleData, intervalSelections, props.intervalSelectionIndex]
    );

  const [minX, maxX, xTickValues, xGridlineValues] = useCallback(
    calculateXDomain(sampleData),
    [sampleData]
  );

  const [minY, maxY, yTickValues, yGridlineValues] = useCallback(
    calculateYDomain(sampleData),
    [sampleData]
  );

  const unit =
    sampleData.chart_points?.[0]?.unit ?? sampleData.inline?.[0]?.unit;

  const yLabel = unit ? `${getComponentShortForm(measurement)} (${unit})` : "";
  // The position of the legend as a single CustomSVGSeries data point
  const yStep = yGridlineValues.length > 0 ? yGridlineValues[1] : 0;
  const legendPosition = { x: maxX, y: maxY + yStep };

  const renderSVG = (data) => {
    const { svg, state } = data;

    const isClickable = props.onSampleClick && state === "validated";
    return (
      <g
        className={isClickable ? "recordOfQualitySampleChart__clickable" : ""}
        onClick={
          isClickable
            ? () => props.onSampleClick(data, props.intervalSelectionIndex)
            : null
        }
      >
        {svg}
      </g>
    );
  };

  const sharedProps = {
    onValueMouseOver: (value) => setHintData(value),
    onValueMouseOut: () => setHintData(null),
  };

  const hasData = sampleData.chart_points?.length || sampleData.inline?.length;

  return (
    <div className="recordOfQualitySampleChart">
      <Loader
        loaded={sampleDataLoadingState !== "loading"}
        options={{ ...loaderOptions, top: "50%" }}
      >
        {sampleDataLoadingState === "loaded" && hasData ? (
          <div className="recordOfQualitySampleChart__container">
            <AutoSizer>
              {({ width, height }) => (
                <XYPlot
                  height={height}
                  width={width}
                  margin={{ left: 70, right: 15, top: 20, bottom: 15 }}
                  xDomain={[minX, maxX]}
                  yDomain={[minY, maxY]}
                  onMouseLeave={() => setHintData(null)}
                >
                  <VerticalGridLines
                    className="recordOfQualitySampleChart__verticalGridLine"
                    tickValues={xGridlineValues}
                  />
                  <HorizontalGridLines
                    tickValues={yGridlineValues}
                    className="recordOfQualitySampleChart__horizontalGridLine"
                  />

                  <XAxis
                    tickFormat={formatXAxis}
                    tickPadding={0}
                    tickValues={xTickValues}
                    // matches other outlined gridlines
                    style={{ line: { stroke: "#999" } }}
                  />
                  <YAxis
                    tickFormat={(data) => data}
                    tickPadding={0}
                    tickValues={yTickValues}
                    style={{ line: { stroke: "none" } }}
                  />

                  <ChartLabel
                    text={yLabel}
                    className="recordOfQualitySampleChart__labelTitle"
                    xPercent={0.03}
                    yPercent={0.25}
                    style={{
                      transform: "rotate(-90)",
                      style: {
                        textAnchor: "middle",
                      },
                    }}
                  />

                  {/* The chart legend */}
                  <CustomSVGSeries
                    data={[legendPosition]}
                    customComponent={() => (
                      <RecordOfQualitySampleChartLegend
                        justifyRight
                        position={{ x: -10 }} // account for the right margin
                      />
                    )}
                  />

                  {/* The Volume Weighted Inline Data  */}
                  <LineMarkSeries
                    data={inlineData}
                    {...INLINE_PROPS}
                    {...sharedProps}
                  />

                  {/* The RoQ data  */}
                  <WhiskerSeries
                    data={roqData}
                    {...ROQ_PROPS}
                    {...sharedProps}
                  />

                  {/* The selected RoQ for the method "Previous RoQ" */}
                  <WhiskerSeries
                    data={selectedRoqData}
                    {...SELECTED_ROQ_PROPS}
                    {...sharedProps}
                  />

                  {/*  The sample data (spot, composite, etc.) */}
                  <CustomSVGSeries
                    data={chartData}
                    customComponent={renderSVG}
                    {...sharedProps}
                  />

                  {/* The Volume Weighted Inline Data when the method
                   "Volume Weighted Inline" is selected */}
                  <LineMarkSeries
                    data={selectedInlineData}
                    {...SELECTED_INLINE_PROPS}
                    {...sharedProps}
                  />

                  {hintData ? (
                    <Hint value={hintData}>
                      <CustomHint
                        point={hintData}
                        measurementKey={measurement}
                        getFormattedMeasurementValueWithUnit={
                          props.getFormattedMeasurementValueWithUnit
                        }
                      />
                    </Hint>
                  ) : null}
                </XYPlot>
              )}
            </AutoSizer>
          </div>
        ) : (
          <div className="recordOfQualitySampleChart__noData">
            No data available
          </div>
        )}
      </Loader>
    </div>
  );
};
RecordOfQualitySampleChart.propTypes = {
  onSampleClick: PropTypes.func,
  intervalSelectionIndex: PropTypes.number,
  getFormattedMeasurementValue: PropTypes.func,
  getFormattedMeasurementValueWithUnit: PropTypes.func,
};

export default connect(mapStateToProps)(RecordOfQualitySampleChart);
