import { RoleContext } from "#src/routes/settings/roles-and-permissions/roles/[roleId]/detail/RoleContext";
import { KeyValuePanel } from "@validereinc/common-components";
import React, { useContext } from "react";

export const RoleDetailsPanel = () => {
  const { roleQuery, roleMembersQuery, rolePermissionsQuery } =
    useContext(RoleContext) ?? {};

  return (
    <KeyValuePanel
      panelMaxColumnCount={2}
      panelKeyValueListProps={{ maxRowCount: 3, variant: "shaded" }}
      panelProps={{ isFluidY: false }}
      data={[
        {
          title: "Description",
          value: roleQuery?.data?.description,
        },
        {
          title: "Members",
          value: roleMembersQuery?.data?.length ?? 0,
        },
        {
          title: "Permissions",
          value: rolePermissionsQuery?.data?.length ?? 0,
        },
      ]}
    />
  );
};
