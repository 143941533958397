import UserService from "#services/UserService";
import { isSuccessfulResponse } from "#services/ServiceHelper";
import {
  FETCH_USER_PERMISSIONS_REQUESTED,
  USER_PERMISSIONS_RETRIEVED,
  FETCH_USER_PERMISSIONS_ERROR,
} from "../constants/action-types";

export function userPermissionsRetrieved(permissions) {
  return {
    type: USER_PERMISSIONS_RETRIEVED,
    permissions,
  };
}

export function userPermissionsFetchError(message, status) {
  return {
    type: FETCH_USER_PERMISSIONS_ERROR,
    payload: {
      message,
      status,
    },
  };
}

// IMPROVE: the backup param is a temporary by-pass - a step towards removing Redux completely
export function fetchUserPermissions(
  backupPermissions: Record<string, Array<"read" | "write" | "validere">>
) {
  return (dispatch) => {
    dispatch({
      type: FETCH_USER_PERMISSIONS_REQUESTED,
    });

    if (backupPermissions) {
      dispatch(userPermissionsRetrieved(backupPermissions));
      return Promise.resolve();
    }

    return UserService.getCurrentUserPermissions()
      .then(({ data, status }) => {
        if (isSuccessfulResponse(status)) {
          dispatch(userPermissionsRetrieved(data));
        }
      })
      .catch((error) => {
        if (error.status && error.status !== 403) {
          dispatch(userPermissionsFetchError(error.message, error.status));
        }
      });
  };
}
