import { DEFAULT_QUERY_OPTIONS } from "#hooks/adapters/adapterUtils";
import { useQuery } from "@tanstack/react-query";
import { DropdownInput, useFormContext } from "@validereinc/common-components";
import {
  AssetGroupDomain,
  AssetType,
  Device,
  EquipmentDomain,
  FacilityDomain,
  FlowDomain,
  TemplatedReportInputType,
} from "@validereinc/domain";
import get from "lodash/get";
import uniqBy from "lodash/uniqBy";
import React from "react";

export type AttributeLookupOptionType = {
  id: string;
  name: string;
};

export const AttributeLookupInput = ({
  lookup_entity_type,
  lookup_entity_attribute,
  name,
  ...restProps
}: Omit<TemplatedReportInputType, "lookup_entity_attribute"> & {
  name: string;
  lookup_entity_attribute: string;
}) => {
  const { watch } = useFormContext();
  const value = watch(name);

  const { data, isLoading } = useQuery({
    queryKey: [
      lookup_entity_type,
      "attributeDistinct",
      lookup_entity_attribute,
    ] as const,
    queryFn: async ({ queryKey: [assetType, _, attribute] }) => {
      let result;
      switch (assetType) {
        case AssetType.ASSET_GROUP:
          result = await AssetGroupDomain.getList({});
          break;
        case AssetType.DEVICE:
          result = await Device.getList({});
          break;
        case AssetType.EQUIPMENT:
          result = await EquipmentDomain.getEquipment({});
          break;
        case AssetType.FACILITY:
          result = await FacilityDomain.getList({});
          break;
        case AssetType.FLOW:
          result = await FlowDomain.getFlows({});
          break;
      }

      /** The backend requires we return the UUID of the entity we want to get the attribute from */
      const attributeOptions: AttributeLookupOptionType[] = [];

      for (const item of result?.data ?? []) {
        const id = item.id;
        const name = get(item, attribute);
        if (id && name) {
          attributeOptions.push({ id, name });
        }
      }

      return uniqBy(attributeOptions, ({ name }) => name);
    },
    ...DEFAULT_QUERY_OPTIONS,
  });

  return (
    <DropdownInput
      name={name}
      options={data ?? []}
      valueKey="id"
      labelKey="name"
      isMulti={false}
      isDisabled={isLoading}
      isFluid
      defaultValue={value ?? ""}
      {...restProps}
    />
  );
};
