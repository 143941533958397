import classNames from "classnames/bind";
import moment from "moment";
import * as PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";

import {
  Button,
  DateRangeSelector,
  Dropdown,
  Panel,
  useAlert,
  XYChart,
} from "@validereinc/common-components";

import styles from "./DashboardChart.module.scss";

import EmptyChartIcon from "#components/Common/SVG/EmptyChartIcon";
import { useParams } from "#routers/hooks";
import DashboardService from "#services/DashboardService";
import MeasurementsService from "#services/MeasurementsService";
import { NODE_API_MAX_PAGE_SIZE } from "#services/ServiceHelper";
import { useMeasurementTypes } from "#src/contexts/MeasurementTypeContext";
import { DEFAULT_DATE_RANGES } from "#src/hooks/useDateRange";
import { getIntervalOptions } from "#utils/date";
import { getConvertedUnitString } from "#utils/stringFormatter";
import { styles as commonStyles } from "@validereinc/common-components";
import { CircleNotch } from "phosphor-react";

import ChartDrawer from "#src/batteries-included-components/Drawers/DashboardChartDrawer";
import DeleteDashboardChartModal from "#src/batteries-included-components/Modals/DeleteDashboardChartModal/DeleteDashboardChartModal";
import { getThresholdObject } from "./DashboardsDetailHelpers";

const cx = classNames.bind(styles);

const CHART_HEIGHT = 400;

const DashboardChart = ({ id, name, json, onRefetchData }) => {
  const { getUnitName, getTypeName } = useMeasurementTypes();
  const { leftAxis, rightAxis, dateRange, interval, order } = json;

  const { dashboardId } = useParams();
  const { addAlert } = useAlert();

  const [sets, setSets] = useState([]);
  const [leftMeasurementSource, setLeftMeasurementSource] = useState();
  const [rightMeasurementSource, setRightMeasurementSource] = useState();
  const [isEditChartDrawerOpen, setIsEditChartDrawerOpen] = useState();
  const [isDeleteChartModalOpen, setIsDeleteChartModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [activeDateRange, setActiveDateRange] = useState(
    dateRange
      ? { from: new Date(dateRange.from), to: new Date(dateRange.to) }
      : DEFAULT_DATE_RANGES.last30Days
  );

  const [intervalOptions, setIntervalOptions] = useState([]);
  const [activeInterval, setActiveInterval] = useState(interval);

  const onOpenEditDrawer = () => setIsEditChartDrawerOpen(true);
  const onCloseEditDrawer = (shouldRefetchData = false) => {
    if (shouldRefetchData) {
      onRefetchData();
    }

    setIsEditChartDrawerOpen(false);
  };

  const onOpenDeleteChartModal = () => setIsDeleteChartModalOpen(true);
  const onCloseDeleteChartModal = () => setIsDeleteChartModalOpen(false);

  const onChangeDateRange = async (newDateRange) => {
    setIsLoading(true);
    setActiveDateRange(newDateRange);

    await DashboardService.editChart({
      dashboardId,
      chartId: id,
      name,
      ...json,
      order,
      dateRange: newDateRange,
    });

    await onFetchData({ dateRange: newDateRange });
    setIsLoading(false);
  };

  const onChangeInterval = async (newInterval) => {
    setActiveInterval(newInterval);

    await DashboardService.editChart({
      dashboardId,
      chartId: id,
      name,
      ...json,
      order,
      interval: newInterval,
    });

    await onFetchData({ interval: newInterval });
  };

  const actionRow = [
    <Button
      key={`edit-chart-${id}`}
      variant="outline"
      onClick={onOpenEditDrawer}
    >
      Edit
    </Button>,
    <Button
      key={`delete-chart-${id}`}
      variant="error-outline"
      onClick={onOpenDeleteChartModal}
    >
      Delete
    </Button>,
  ];

  const leftAxisDefinition = leftMeasurementSource && {
    label: `${getTypeName(
      leftMeasurementSource.measurement_type
    )} (${getUnitName(leftMeasurementSource.unit)})`,
    valueAccessor: (dataPoint) => dataPoint?.measure_value,
    valueRenderer: (dataPoint) =>
      getConvertedUnitString(
        dataPoint?.measure_value,
        (value) => value,
        getUnitName(leftMeasurementSource.unit)
      ),
  };

  const rightAxisDefinition = rightMeasurementSource && {
    label: `${getTypeName(
      rightMeasurementSource.measurement_type
    )} (${getUnitName(rightMeasurementSource?.unit)})`,
    valueAccessor: (dataPoint) => dataPoint?.measure_value,
    valueRenderer: (dataPoint) =>
      getConvertedUnitString(
        dataPoint?.measure_value,
        (value) => value,
        getUnitName(rightMeasurementSource.unit)
      ),
  };

  const bottomAxis = {
    compare: ({ time: aTime }, { time: bTime }) => {
      return moment(aTime).isAfter(bTime) ? 1 : -1;
    },
    valueAccessor: (dataPoint) => {
      return dataPoint?.time;
    },
    tickRenderer: (date) => moment(date).format("MMM DD, YYYY h:mm A"),
  };

  const thresholds = useMemo(() => {
    const result = [];

    const leftChartLabel = leftAxisDefinition?.label;
    const rightChartLabel = rightAxisDefinition?.label;

    const thresholdOptions = [
      ["left", leftAxis?.upper_limit, leftChartLabel, "Upper Limit"],
      ["left", leftAxis?.lower_limit, leftChartLabel, "Lower Limit"],
      ["right", rightAxis?.upper_limit, rightChartLabel, "Upper Limit"],
      ["right", rightAxis?.upper_limit, rightChartLabel, "Lower Limit"],
    ];

    thresholdOptions.forEach((options) => {
      // if value exists, add a threshold
      if (options[1]) {
        result.push(getThresholdObject(...options));
      }
    });

    return result?.length ? result : undefined;
  }, [leftAxis, rightAxis, leftAxisDefinition, rightAxisDefinition]);

  const onFetchData = async ({
    dateRange = activeDateRange,
    interval = activeInterval,
  }) => {
    setIsLoading(true);

    const leftAxisResponse = await Promise.all(
      leftAxis?.device?.map(async (deviceId) => {
        try {
          const {
            data: { data: measurementSources },
          } = await MeasurementsService.getMeasurementSources({
            deviceId,
          });

          const measurementToFetch = measurementSources.find(
            ({ measurement_type }) =>
              measurement_type === leftAxis.device_measurement
          );

          if (measurementToFetch) {
            const { data: measurementSourceDetail } =
              await MeasurementsService.getMeasurementSource(
                measurementToFetch?.id
              );

            setLeftMeasurementSource(measurementSourceDetail);

            const {
              data: { data },
            } = await MeasurementsService.getMeasurementList(
              measurementToFetch.id,
              {
                interval,
                func: "avg",
                rowPerPage: NODE_API_MAX_PAGE_SIZE,
                sort: "time",
                start: dateRange?.from,
                end: dateRange?.to,
                groupBy: "measurement_type",
              }
            );

            if (data) {
              return {
                axis: "left",
                data,
                label: `${measurementSourceDetail?.device?.facility_name} - ${measurementSourceDetail?.device?.name} - ${measurementSourceDetail?.measurement_type_name}`,
                variant: leftAxis.variant,
                isGradient: true,
              };
            } else {
              throw "error";
            }
          }
        } catch (caught) {
          addAlert({
            variant: "error",
            message: "There was an error fetching this chart.",
          });
        }
      })
    );

    const rightAxisResponse = rightAxis
      ? await Promise.all(
          rightAxis.device.map(async (deviceId) => {
            try {
              const {
                data: { data: measurementSources },
              } = await MeasurementsService.getMeasurementSources({
                deviceId,
              });

              const measurementToFetch = measurementSources.find(
                ({ measurement_type }) =>
                  measurement_type === rightAxis.device_measurement
              );

              if (measurementToFetch) {
                const { data: measurementSourceDetail } =
                  await MeasurementsService.getMeasurementSource(
                    measurementToFetch?.id
                  );

                setRightMeasurementSource(measurementSourceDetail);

                const {
                  data: { data },
                } = await MeasurementsService.getMeasurementList(
                  measurementToFetch?.id,
                  {
                    interval,
                    func: "avg",
                    rowPerPage: NODE_API_MAX_PAGE_SIZE,
                    sort: "time",
                    start: dateRange?.from,
                    end: dateRange?.to,
                    groupBy: "measurement_type",
                  }
                );

                if (data) {
                  return {
                    axis: "right",
                    data,
                    label: `${measurementSourceDetail?.device?.facility_name} - ${measurementSourceDetail?.device?.name} - ${measurementSourceDetail?.measurement_type_name}`,
                    variant: rightAxis.variant,
                  };
                } else {
                  throw "error";
                }
              }
            } catch (caught) {
              addAlert({
                variant: "error",
                message: "There was an error fetching this chart.",
              });
            }
          })
        )
      : [];

    setSets([
      ...leftAxisResponse.filter((value) => value),
      ...rightAxisResponse.filter((value) => value),
    ]);

    setIsLoading(false);
  };

  useEffect(() => {
    (async () => {
      await onFetchData({});
    })();
  }, [leftAxis, rightAxis]);

  useEffect(() => {
    if (activeDateRange) {
      const newIntervalOptions = getIntervalOptions(activeDateRange);

      setIntervalOptions(newIntervalOptions);
    }
  }, [activeDateRange]);

  useEffect(() => {
    if (
      intervalOptions?.length &&
      activeInterval &&
      !intervalOptions.find(({ id }) => id === activeInterval)
    ) {
      onChangeInterval(intervalOptions[0].id);
    }
  }, [intervalOptions, activeInterval]);

  return (
    <Panel
      title={name}
      actionRow={actionRow}
    >
      <div className={cx("actions")}>
        <DateRangeSelector
          key={`chart-date-range-${id}`}
          value={activeDateRange}
          allowOneDayRange={true}
          allowTimeSelection={true}
          onChange={onChangeDateRange}
        />

        <Dropdown
          key={`chart-interval-${id}`}
          options={intervalOptions}
          isClearable={false}
          labelKey="name"
          valueKey="id"
          isSortedAlphabetically={false}
          onChange={onChangeInterval}
          value={activeInterval}
        />
      </div>

      {isLoading ? (
        <div className={cx("loadingIndicatorContainer")}>
          <CircleNotch
            size={64}
            className={cx("loadingIndicator")}
            color={commonStyles?.primary?.[400]}
          />
        </div>
      ) : (
        <>
          {sets?.[0]?.data?.length ? (
            <XYChart
              height={CHART_HEIGHT}
              sets={sets}
              leftAxis={leftAxisDefinition}
              rightAxis={rightAxisDefinition}
              bottomAxis={bottomAxis}
              thresholds={thresholds}
              from={activeDateRange.from}
              to={activeDateRange.to}
            />
          ) : (
            <div className={cx("emptyState", "panel")}>
              <EmptyChartIcon />

              <p className={cx("error")}>There is no data to display</p>
              <p className={cx("helper")}>
                Update the filters to start plotting data
              </p>
            </div>
          )}

          <ChartDrawer
            isOpen={isEditChartDrawerOpen}
            onClose={onCloseEditDrawer}
            chart={{ id, name, leftAxis, rightAxis, interval, dateRange }}
            order={order}
          />

          <DeleteDashboardChartModal
            chartId={id}
            chartName={name}
            isOpen={isDeleteChartModalOpen}
            onClose={onCloseDeleteChartModal}
            onRefetchData={onRefetchData}
          />
        </>
      )}
    </Panel>
  );
};

DashboardChart.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  json: PropTypes.object.isRequired,
  onRefetchData: PropTypes.func.isRequired,
};

export default DashboardChart;
