import {
  SelectInput,
  TextAreaInput,
  TextInput,
  Title,
} from "@validereinc/common-components";
import * as PropTypes from "prop-types";
import React from "react";
import { MEASUREMENT_OPTIONS } from "./AdjustmentFactorsHelper";
import CompoundLabel from "./CompoundLabel";

const AdjustmentFactorForm = ({
  form,
  formState,
  availableTypeOptions,
  availableCompoundOptions,
}) => {
  const sharedProps = {
    isDisabled: formState !== "enabled",
    showIcon: true,
    isRequired: true,
  };

  const components = form.watch("components");

  return (
    <>
      <Title
        type="subheader"
        className="form__header"
      >
        Information
      </Title>
      <TextInput
        type="text"
        name="name"
        label="Name"
        {...sharedProps}
      />

      <SelectInput
        name="type"
        label="Type"
        options={availableTypeOptions}
        {...sharedProps}
      />

      <SelectInput
        name="compound"
        label={<CompoundLabel />}
        options={availableCompoundOptions}
        {...sharedProps}
      />

      <TextAreaInput
        name="description"
        label="Description"
        {...sharedProps}
      />

      <Title
        type="subheader"
        className="form__header"
      >
        Adjustments
      </Title>

      {form.watch("type") === "Total Volume" && (
        <TextInput
          name="total_volume_factor"
          label="Total Volume"
          type="number"
          unit="x"
          {...sharedProps}
        />
      )}

      {form.watch("type") === "Component Volume" && (
        <>
          <SelectInput
            name="components"
            label="Components"
            options={MEASUREMENT_OPTIONS}
            isMulti
            closeMenuOnSelect={false}
            {...sharedProps}
          />

          {components?.map((component) => (
            <TextInput
              name={component}
              label={component}
              key={component}
              type="number"
              unit="x"
              {...sharedProps}
            />
          ))}
        </>
      )}
    </>
  );
};

AdjustmentFactorForm.propTypes = {
  form: PropTypes.object.isRequired,
  formState: PropTypes.string.isRequired,
  availableTypeOptions: PropTypes.array.isRequired,
  availableCompoundOptions: PropTypes.array.isRequired,
};

export default AdjustmentFactorForm;
