import React, { Component } from "react";
import filter from "lodash/filter";
import moment from "moment";
import WorkflowViewTable from "./ScheduleView/WorkflowViewTable";
import MonthlyView from "./MonthlyView/MonthlyView";
import { SortListByType } from "../../utils/sorters";
import {
  AssertIsSameEpoch,
  AssertIsBeforeEpoch,
  AssertIsBeforeOrEqualDate,
  AssertIsBeforeDate,
} from "../../utils/assert";
import Loader from "react-loader";
import loaderOptions from "../LoadingBar/LoadingBar";
import { AutoSizer } from "react-virtualized";
import { getNumWeeksInMonth } from "./WorkflowsHelpers";
import "./WorkflowView.scss";

/* eslint-disable react/prop-types */
const lodash = { filter };

const HEIGHT_OFFSET = 166; // 15px height clearance

class WorkflowView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: true,

      pastAgendas: [],
      todayAgenda: {},
      futureAgendas: [],

      workflowColors: [],
      width: 0,
    };

    this.workflowViewRef = React.createRef();
    this.handleResize = this.handleResize.bind(this);
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.workflowTasks !== this.props.workflowTasks ||
      prevProps.groupStreamsByWorkflow !== this.props.groupStreamsByWorkflow
    ) {
      this.setAgenda(
        this.props.workflowTasks,
        this.props.workflows,
        this.props.streams
      );
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize() {
    if (this.workflowViewRef.current) {
      this.setState({
        width: this.workflowViewRef.current.clientWidth,
      });
    }
  }

  setAgenda(workflowTasks, workflows, streams) {
    const scheduledWorkflowTasks =
      this.categorizeWorkflowTasksBySchedule(workflowTasks);

    const pastAgendas = this.getPastAgendas(
      scheduledWorkflowTasks.past,
      workflows,
      streams
    );
    const todayAgenda = this.getTodayAgenda(
      scheduledWorkflowTasks.today,
      workflows,
      streams
    );
    const futureAgendas = this.getFutureAgendas(
      scheduledWorkflowTasks.scheduled,
      workflows,
      streams
    );

    this.setState({
      pastAgendas: pastAgendas,
      todayAgenda: todayAgenda,
      futureAgendas: futureAgendas,
    });
  }

  categorizeWorkflowTasksBySchedule(tasks) {
    const today = moment();

    return tasks.reduce((taskCategorizedByTime, task) => {
      // today's task include active tasks (pending, sampled) and completed and missed tasks on today
      const isTaskCompletedToday =
        task.state !== "completed" &&
        AssertIsSameEpoch(task.deadline_at_utc_epoch, today, "day");
      const isTaskInProgressToday =
        ["pending", "sampled"].includes(task.state) ||
        AssertIsSameEpoch(task.completed_at_utc_epoch, today, "day");

      if (isTaskInProgressToday || isTaskCompletedToday) {
        if (taskCategorizedByTime.today) {
          taskCategorizedByTime.today.push(task);
        } else {
          taskCategorizedByTime.today = [task];
        }
      }

      if (
        task.state === "scheduled" &&
        !AssertIsSameEpoch(task.inserted_at_utc_epoch, today, "day")
      ) {
        if (taskCategorizedByTime.scheduled) {
          taskCategorizedByTime.scheduled.push(task);
        } else {
          taskCategorizedByTime.scheduled = [task];
        }
      }

      if (
        task.state === "missed" ||
        (task.state === "completed" &&
          AssertIsBeforeEpoch(task.completed_at_utc_epoch, today, "day"))
      ) {
        if (taskCategorizedByTime.past) {
          taskCategorizedByTime.past.push(task);
        } else {
          taskCategorizedByTime.past = [task];
        }
      }

      return taskCategorizedByTime;
    }, {});
  }

  getTodayAgenda(todayTasks, workflows, streams) {
    if (!todayTasks) {
      return [];
    }

    const today = moment().startOf("day");
    const styledWorkflowTasks = this.getWorkflowTaskStyle(
      todayTasks,
      workflows,
      streams
    );

    return {
      date: today,
      agendas: SortListByType(
        styledWorkflowTasks,
        "deadline_at_utc_epoch",
        "asc"
      ),
    };
  }

  getFutureAgendas(scheduledTasks, workflows, streams) {
    const { to, from } = this.props;

    if (!scheduledTasks) {
      return [];
    }
    const futureAgendas = [];

    // first we categorize the future task by date then we categorize
    // by either tasks or workflows
    for (
      let i = 1;
      AssertIsBeforeOrEqualDate(moment(from).startOf("day").add(i, "day"), to);
      i++
    ) {
      const futureDay = moment(from).startOf("day").add(i, "day");
      const futureDayTasks = lodash.filter(scheduledTasks, function (task) {
        return AssertIsSameEpoch(task.inserted_at_utc_epoch, futureDay, "Day");
      });

      const styledWorkflowTasks = this.getWorkflowTaskStyle(
        futureDayTasks,
        workflows,
        streams
      );

      futureAgendas.push({
        date: futureDay,
        agendas: SortListByType(
          styledWorkflowTasks,
          "deadline_at_utc_epoch",
          "asc"
        ),
      });
    }

    return futureAgendas;
  }

  getPastAgendas(completedAndMissedTasks, workflows, streams) {
    if (!completedAndMissedTasks) {
      return [];
    }

    const { from } = this.props;

    const today = moment().startOf("day");
    const pastAgendas = [];

    for (
      let i = 1;
      AssertIsBeforeDate(moment(from).startOf("day").add(i, "day"), today);
      i++
    ) {
      const pastDay = moment(from).startOf("day").add(i, "day");
      const pastDayTasks = lodash.filter(
        completedAndMissedTasks,
        function (task) {
          return (
            AssertIsSameEpoch(task.deadline_at_utc_epoch, pastDay, "Day") ||
            AssertIsSameEpoch(task.completed_at_utc_epoch, pastDay, "Day")
          );
        }
      );

      const styledWorkflowTasks = this.getWorkflowTaskStyle(
        pastDayTasks,
        workflows,
        streams
      );

      pastAgendas.push({
        date: pastDay,
        agendas: SortListByType(
          styledWorkflowTasks,
          "deadline_at_utc_epoch",
          "asc"
        ),
      });
    }

    return pastAgendas;
  }

  getWorkflowTaskStyle(tasks, workflows, streams) {
    const { haveEditPermission, groupStreamsByWorkflow } = this.props;

    if (workflows.length > 0 && haveEditPermission && groupStreamsByWorkflow) {
      return this.categorizeTasksByWorkflow(tasks, workflows);
    } else {
      return this.getStreamStyle(tasks, streams);
    }
  }

  categorizeTasksByWorkflow(tasks, workflows) {
    return workflows.reduce((workflows, workflow) => {
      const workflowTasksFilterByWorkflow = lodash.filter(tasks, {
        workflow_id: workflow.id,
      });
      if (workflowTasksFilterByWorkflow.length > 0) {
        return [
          ...workflows,
          {
            name: workflow.name,
            id: workflow.id,
            type: workflow.test_type,
            workflow: workflow,
            color: this.getWorkflowColor(workflow.id),
            tasks: this.categorizeTasksByType(workflowTasksFilterByWorkflow),
          },
        ];
      } else {
        return [...workflows];
      }
    }, []);
  }

  categorizeTasksByType(workflowTasks) {
    return workflowTasks.reduce((categorizedWorkflowTasks, workflowTask) => {
      if (categorizedWorkflowTasks[workflowTask.state]) {
        categorizedWorkflowTasks[workflowTask.state].push(workflowTask);
        return categorizedWorkflowTasks;
      } else {
        categorizedWorkflowTasks[workflowTask.state] = [workflowTask];
        return categorizedWorkflowTasks;
      }
    }, {});
  }

  getStreamStyle(workflowTasks, streams) {
    const workflowTasksWithStyle = workflowTasks.reduce(
      (tasksWithColor, task) => {
        const stream = lodash.filter(streams, { stream_id: task.stream_id });

        if (stream && stream.length > 0) {
          task.color = stream[0].color;
        }

        tasksWithColor.push(task);
        return tasksWithColor;
      },
      []
    );

    return SortListByType(workflowTasksWithStyle, "state", "asc");
  }

  getWorkflowColor(workflowId) {
    const workflowColor = lodash.filter(this.props.workflows, {
      id: workflowId,
    });

    if (workflowColor[0]) {
      return workflowColor[0].color;
    }

    return "#ddd";
  }

  render() {
    const { todayAgenda, futureAgendas, pastAgendas, width } = this.state;
    const {
      haveEditPermission,
      groupStreamsByWorkflow,
      workflowTasks,
      view,
      to,
      filterRow,
      filterPillbox,
      workflows,
      refetchWorkflowTasks,
      loadingState,
    } = this.props;

    const workflowViewHeight = this.props.height - HEIGHT_OFFSET;

    const showWithWorkflow = haveEditPermission && groupStreamsByWorkflow;

    return (
      <div
        className="workflowView"
        ref={this.workflowViewRef}
        style={{ height: workflowViewHeight }}
      >
        {view === "schedule" ? (
          <WorkflowViewTable
            workflows={workflows}
            workflowTasks={workflowTasks}
            height={workflowViewHeight}
            refetchWorkflowTasks={refetchWorkflowTasks}
            filterRow={filterRow}
            filterPillbox={filterPillbox}
            loadingState={loadingState}
          />
        ) : (
          <div
            className="workflowView__monthlyViewContainer"
            style={{
              height: workflowViewHeight,
            }}
          >
            {filterRow}

            {filterPillbox}

            <div className="workflowView__calendar">
              <AutoSizer disableWidth>
                {({ height }) => (
                  <Loader
                    loaded={loadingState !== "loading"}
                    options={loaderOptions}
                  >
                    {workflowTasks?.length ? (
                      <MonthlyView
                        to={to}
                        numWeeksInMonth={getNumWeeksInMonth(to)}
                        pastAgendas={pastAgendas}
                        todayAgenda={todayAgenda}
                        futureAgendas={futureAgendas}
                        haveEditPermission={showWithWorkflow}
                        height={height}
                        width={width}
                      />
                    ) : (
                      <p className="workflowView__noData">
                        No workflow tasks found.
                      </p>
                    )}
                  </Loader>
                )}
              </AutoSizer>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default WorkflowView;
