import moment from "moment";

export function AssertIsValidMeasurement(measurement, condition) {
  if (measurement && measurement > condition) {
    return true;
  }
  return false;
}

export function AssertIsGreaterThan(upperValue, lowerValue) {
  if (upperValue > lowerValue) {
    return true;
  }
  return false;
}

export function AssertIsFloatLessThan(value, expectedValue) {
  const numOfDecimalPlace = 3;
  const roundValue = parseFloat(value).toFixed(numOfDecimalPlace);
  if (roundValue <= expectedValue) {
    return true;
  }

  return false;
}

export function AssertIsFloatGreaterThan(value, expectedValue) {
  const numOfDecimalPlace = 3;
  const roundValue = parseFloat(value).toFixed(numOfDecimalPlace);
  if (roundValue >= expectedValue) {
    return true;
  }

  return false;
}

export function AssertIsFloatEqualTo(value, expectedValue) {
  const numOfDecimalPlace = getDecimalPlace(expectedValue);
  const roundValue = parseFloat(value).toFixed(numOfDecimalPlace);
  if (roundValue === expectedValue) {
    return true;
  }

  return false;
}

export function AssertIsGreaterArrayLength(longerArray, shorterArray) {
  if (longerArray.length > shorterArray.length) {
    return true;
  }
  return false;
}

export function AssertIsAfterEpoch(time, laterTime, compareType) {
  return moment.unix(time).isAfter(moment(laterTime), compareType);
}

export function AssertIsSameEpoch(time, laterTime, compareType) {
  return moment.unix(time).isSame(moment(laterTime), compareType);
}

export function AssertIsBeforeEpoch(time, laterTime, compareType) {
  return moment.unix(time).isBefore(moment(laterTime), compareType);
}

export function AssertIsBeforeTodayEpoch(time, compareType) {
  return moment.unix(time).isBefore(moment(), compareType);
}

export function AssertIsSameDate(time, expectTime, compareType) {
  return moment(time).isSame(moment(expectTime), compareType);
}

export function AssertIsBeforeDate(time, expectTime, compareType) {
  return moment(time).isBefore(moment(expectTime), compareType);
}

export function AssertIsBeforeOrEqualDate(time, expectTime, compareType) {
  return (
    AssertIsBeforeDate(time, expectTime, compareType) ||
    AssertIsSameDate(time, expectTime, compareType)
  );
}

export function AssertIsAfterOrEqualDate(time, expectTime, compareType) {
  return (
    AssertIsAfterDate(time, expectTime, compareType) ||
    AssertIsSameDate(time, expectTime, compareType)
  );
}

export function AssertIsAfterDate(time, expectTime, compareType) {
  return moment(time).isAfter(moment(expectTime), compareType);
}

export function AssertIsBetweenDate(
  time,
  earilierExpectTime,
  laterExpectTime,
  compareType
) {
  return (
    AssertIsAfterOrEqualDate(time, earilierExpectTime, compareType) &&
    AssertIsBeforeOrEqualDate(time, laterExpectTime, compareType)
  );
}

export function AssertIsBetweenEpoch(
  time,
  earilierExpectTime,
  laterExpectTime,
  compareType
) {
  return (
    (AssertIsAfterEpoch(time, earilierExpectTime, compareType) ||
      AssertIsSameEpoch(time, earilierExpectTime, compareType)) &&
    (AssertIsBeforeEpoch(time, laterExpectTime, compareType) ||
      AssertIsSameEpoch(time, laterExpectTime, compareType))
  );
}

export function AssertIsStringSame(string, expectedString) {
  return string === expectedString;
}

export function AssertIsNumericValueSame(number, expectedNumber) {
  return parseInt(number, 10) === expectedNumber;
}

function getDecimalPlace(value) {
  if (value) {
    const decimal = value.toString().split(".");

    if (decimal) {
      return decimal.length;
    }
  }

  return 0;
}

export const AssertIsContainedByClassName = (node, className) => {
  while (node) {
    if (node.classList?.contains(className)) {
      return true;
    }
    node = node.parentNode;
  }
  return false;
};
