import intersectionWith from "lodash/intersectionWith";
import uniqBy from "lodash/uniqBy";
import uniq from "lodash/uniq";
import intersectionBy from "lodash/intersectionBy";
import indexOf from "lodash/indexOf";

function noSiteMatch(sites, sourceSiteIds) {
  return intersectionBy(sites, sourceSiteIds, "id").length === 0;
}

function noProductTypeMatch(productTypes, sourceProductType) {
  return indexOf(productTypes, sourceProductType) === -1;
}

function noCategoryMatch(category, sourceCategory) {
  return indexOf(category, sourceCategory) === -1;
}

export function filterSourceList(sourceList, inputs) {
  const { sites, productTypes, category } = inputs;
  const filteredSourceList = sourceList?.filter((source) => {
    // when Restrict to site is an empty array this means that the
    // stream is belong to all sites.
    if (
      sites?.length > 0 &&
      source.restrict_to_sites.length > 0 &&
      noSiteMatch(sites, source.restrict_to_sites)
    ) {
      return false;
    }

    if (
      productTypes?.length > 0 &&
      noProductTypeMatch(productTypes, source.product_type)
    ) {
      return false;
    }

    if (
      category?.length > 0 &&
      noCategoryMatch(category, source?.metadata?.Category ?? "")
    ) {
      return false;
    }

    return true;
  });

  return filteredSourceList;
}

export function filterInventorySourceList(sourceList, inputs) {
  const { sites, productTypes } = inputs;
  const filteredSourceList = sourceList.filter((source) => {
    if (sites?.length > 0 && sites.every((site) => site !== source.site_name)) {
      return false;
    }

    if (
      productTypes?.length > 0 &&
      noProductTypeMatch(productTypes, source.product_type)
    ) {
      return false;
    }

    return true;
  });

  return filteredSourceList;
}

export function getSiteDetails(siteDetailList, site) {
  return intersectionWith(
    siteDetailList,
    site,
    (siteDetail, site) => siteDetail.id === site
  );
}

export function getUniqSites(sourceList) {
  if (sourceList) {
    const allSites = sourceList.reduce((sites, source) => {
      const sourceSites = source.restrict_to_sites;

      if (sourceSites) {
        return [...sites, ...source.restrict_to_sites];
      } else {
        return sites;
      }
    }, []);

    return uniqBy(allSites, "id");
  }

  return [];
}

export function getUniqProductTypes(sourceList) {
  const allProductType = sourceList.reduce(
    (productType, source) => [...productType, source.product_type],
    []
  );

  return uniq(allProductType);
}

export function getUniqCategory(sourceList) {
  const allCategory = sourceList.reduce(
    (category, source) => [...category, source?.metadata?.Category ?? ""],
    []
  );

  return uniq(allCategory).filter((option) => option);
}

export function getAllFilterInputs(filters) {
  const filterPillbox = {};

  Object.keys(filters).map((key) => {
    const inputs = filters[key]?.inputs ?? [];
    if (inputs.length > 0) {
      filterPillbox[key] = inputs;
    }
  });

  return filterPillbox;
}

export function clearAllInputs(filterDropdowns) {
  const updatedFilterDropdowns = { ...filterDropdowns };

  Object.keys(updatedFilterDropdowns).map((key) => {
    updatedFilterDropdowns[key].inputs = [];
  });

  return updatedFilterDropdowns;
}

export function sortBySite(list) {
  return [...list].sort((a, b) => {
    const aValue = a.restrict_to_site_ids?.length ?? -1;
    const bValue = b.restrict_to_site_ids?.length ?? -1;

    return aValue - bValue;
  });
}

export function sortByMultiplier(list) {
  return [...list].sort((a, b) => {
    const aValue = a.multiplier ?? -1;
    const bValue = b.multiplier ?? -1;

    return aValue - bValue;
  });
}

export function sortByProductType(list) {
  return [...list].sort((a, b) => {
    const aValue = a?.product_type ?? "-";
    const bValue = b?.product_type ?? "-";

    return aValue.localeCompare(bValue);
  });
}

export function sortByCategory(list) {
  return [...list].sort((a, b) => {
    const aValue = a?.metadata?.Category ?? "-";
    const bValue = b?.metadata?.Category ?? "-";

    return aValue.localeCompare(bValue);
  });
}
