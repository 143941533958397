import {
  FormSubmissionsFilterPanel,
  FormSubmissionsFilterType,
} from "#src/batteries-included-components/Panels/FilterPanels/FormSubmissionsFilterPanel";
import { RoutingLink } from "#src/batteries-included-components/RoutingLink";
import { useExportFormSubmissionAsPDF } from "#src/components/Forms/exportFormSubmission";
import { useTableSortingAndPagination } from "#src/components/Redux/reducers/tableStateReducer";
import { useListFormSubmissions } from "#src/components/hooks/adapters/useFormSubmissions";
import { useStorageKey } from "#src/hooks/useStorageKey";
import { linkToFormCategoryDetails } from "#src/routes/forms/categories/[categoryId]";
import { linkToFormTemplateDetail } from "#src/routes/forms/categories/[categoryId]/templates/[formTemplateId]";
import { linkToUpdateFormSubmission } from "#src/routes/forms/categories/[categoryId]/templates/[formTemplateId]/update-form-submission";
import { UseQueryOptions, useQueries } from "@tanstack/react-query";
import {
  DataTable,
  DataTablePanel,
  HeaderType,
  useFilters,
} from "@validereinc/common-components";
import {
  FormSubmission,
  FormSubmissionStatus,
  FormSubmissionType,
  SortDirection,
  UserType,
  UsersAdapter,
} from "@validereinc/domain";
import { toFlattenedObject } from "@validereinc/utilities";
import React, { useMemo } from "react";

export const DraftFormSubmissionsList = () => {
  const { filterConfigStorageKey, tableConfigStorageKey } = useStorageKey(
    "forms-submissions-drafts"
  );
  const [filters] = useFilters<FormSubmissionsFilterType>(
    filterConfigStorageKey
  );
  const { created_at, ...restFilters } = filters;
  const sorting = {
    sortBy: "created_at",
    sortDirection: SortDirection.DESCENDING,
  };
  const [tableState, updateTableState] = useTableSortingAndPagination(
    sorting,
    filters
  );

  const draftQueryPayload: Parameters<typeof FormSubmission.getList>[0] = {
    page: tableState.page,
    pageSize: tableState.itemsPerPage,
    sortBy: tableState.sortBy,
    sortDirection: tableState.sortDirection,
    filters: {
      created_at,
      ...toFlattenedObject({
        ...restFilters,
        status: FormSubmissionStatus.DRAFT,
        "form_schema.status": "active",
      }),
    },
  };

  const { data: draftSubmissions, isLoading } = useListFormSubmissions({
    ...draftQueryPayload,
    meta: { answers: true },
  });

  const formSubmissionsCreatedByUsers = useQueries<
    Array<
      UseQueryOptions<
        Awaited<ReturnType<typeof UsersAdapter.getOne>> | undefined,
        unknown,
        UserType | undefined
      >
    >
  >({
    queries:
      draftSubmissions?.data?.map((submission) => ({
        queryKey: ["users", submission.created_by],
        queryFn: () =>
          UsersAdapter.getOne({
            id: submission.created_by,
          }),
        enabled: Boolean(submission.created_by),
        staleTime: 3 * 60 * 1000,
        select: (resp) => resp?.data,
      })) ?? [],
  });
  const formSubmissionsCreatedByUsersMap = useMemo(() => {
    return formSubmissionsCreatedByUsers.reduce<Record<string, UserType>>(
      (map, q) => {
        if (!q.data?.id || map[q.data.id]) {
          return map;
        }

        map[q.data.id] = q.data;
        return map;
      },
      {}
    );
  }, [formSubmissionsCreatedByUsers]);
  const exportPDFMutation = useExportFormSubmissionAsPDF({
    includeEmptyAnswers: true,
    showUpdatedAt: true,
    metaUserDataMap: formSubmissionsCreatedByUsersMap,
  });

  const headers: Array<HeaderType<FormSubmissionType>> = [
    {
      label: "Name",
      key: "id",
      renderComponent: ({ item }) => (
        <RoutingLink
          to={linkToUpdateFormSubmission(
            item?.form_schema?.form_category_id,
            item?.form_schema_id,
            item?.id
          )}
        >{`${item.form_schema?.name} - ${item?.id?.slice(0, 7)}`}</RoutingLink>
      ),
    },
    {
      label: "Form Template",
      key: "form_schema_id",
      renderComponent: ({ item }) => {
        return (
          <RoutingLink
            to={linkToFormTemplateDetail(
              item.form_schema?.form_category_id,
              item.form_schema_id
            )}
          >
            {item.form_schema?.name}
          </RoutingLink>
        );
      },
    },
    {
      label: "Form Category",
      key: "form_schema.form_category_id",
      renderComponent: ({ item }) => {
        return (
          <RoutingLink
            to={linkToFormCategoryDetails(item.form_schema?.form_category_id)}
          >
            {item.form_schema?.form_category?.name}
          </RoutingLink>
        );
      },
    },
    {
      label: "Saved At",
      key: "created_at",
      isSortable: true,
      renderComponent: ({ item }) => (
        <DataTable.DataRow.DateCell value={item.created_at} />
      ),
    },
    {
      label: "Saved By",
      key: "created_by",
      isSortable: true,
      renderComponent: ({ item }) =>
        formSubmissionsCreatedByUsersMap[item.created_by]?.name ?? "-",
    },
  ];

  return (
    <>
      <FormSubmissionsFilterPanel
        filterConfigStorageKey={filterConfigStorageKey}
        forDraftSubmissions={true}
      />
      <DataTablePanel
        storageKey={tableConfigStorageKey}
        panelProps={{
          title: "Drafts",
        }}
        dataTableProps={{
          items: draftSubmissions?.data ?? [],
          pagination: {
            page: tableState.page,
            itemsPerPage: tableState.itemsPerPage,
            total: draftSubmissions?.total_entries ?? 0,
            entityPerPage: "Rows per page",
          },
          sorting,
          headers,
          isLoading,
          onSortChange: updateTableState,
          onPaginationChange: updateTableState,
          getItemActions: ({ item }: { item: FormSubmissionType }) => [
            {
              label: "Export as PDF",
              buttonProps: {
                icon: "share",
                variant: "text",
                isLoading: exportPDFMutation.isLoading,
                onClick: () => {
                  exportPDFMutation.mutate(item);
                },
              },
            },
          ],
        }}
      />
    </>
  );
};
