import { DEFAULT_QUERY_OPTIONS } from "#hooks/adapters/adapterUtils";
import { UseQueryOptions, useQueries, useQuery } from "@tanstack/react-query";
import { UserType, UsersAdapter } from "@validereinc/domain";

export const USERS_QUERY_KEY = "users";

export const useGetOneUser = (apiParams: { id?: string }) =>
  useQuery({
    queryKey: [USERS_QUERY_KEY, apiParams.id],
    queryFn: () => {
      if (!apiParams.id) {
        return;
      }

      return UsersAdapter.getOne({ id: apiParams.id });
    },
    enabled: !!apiParams.id,
    select: (resp) => resp?.data,
    ...DEFAULT_QUERY_OPTIONS,
  });

export const useGetManyUsers = (ids: string[]) =>
  useQueries<
    Array<
      UseQueryOptions<
        Awaited<ReturnType<typeof UsersAdapter.getOne>>,
        unknown,
        UserType | undefined
      >
    >
  >({
    queries: ids.map((id) => ({
      queryKey: [USERS_QUERY_KEY, id],
      queryFn: () => UsersAdapter.getOne({ id }),
      select: (resp) => resp?.data,
      ...DEFAULT_QUERY_OPTIONS,
    })),
  });
