import { AssertIsValidMeasurement, AssertIsGreaterThan } from "#utils/assert";
import { ValidateNumberInput } from "#utils/formValidator";
import { getSecondsFromDays, getDaysFromSeconds } from "#utils/inputFormatter";

// Used to `require` all stat inputs if at least one is inputted
export const isStdValuesExist = (inputs) => {
  const {
    std_sigma,
    std_historical_range_unit,
    std_historical_range,
    std_min_num_measurements,
  } = inputs;
  return (
    Number(std_sigma) ||
    std_historical_range_unit ||
    Number(std_historical_range) ||
    Number(std_min_num_measurements)
  );
};

export const isLowerGreaterThanUpperLimit = (inputs) => {
  const { upper_limit, lower_limit } = inputs;
  return (
    Number(upper_limit) &&
    Number(lower_limit) &&
    !AssertIsGreaterThan(upper_limit, lower_limit)
  );
};

export const atLeastOneInlineLimitExists = (inputs) => {
  const { upper_limit, lower_limit } = inputs;
  return Number(upper_limit) || Number(lower_limit);
};

export const inlineLimitValidation = (limit, inputs) => {
  if (!atLeastOneInlineLimitExists(inputs)) {
    return "Requires at least one limit";
  }
  if (Number(limit)) {
    if (!AssertIsValidMeasurement(limit, 0)) {
      return "Upper measurement limit must be greater than zero.";
    }
    if (isLowerGreaterThanUpperLimit(inputs)) {
      return "Measurement upper limit must be greater than lower limit.";
    }
  }
};

export const stdHistoricalRangeValidation = (stdHistoricalRange, inputs) => {
  const { std_historical_range_unit } = inputs;

  if (isStdValuesExist(inputs)) {
    if (
      !AssertIsValidMeasurement(stdHistoricalRange, 3) &&
      std_historical_range_unit === "samples"
    ) {
      return "Number of historical samples must be at least 4.";
    }
    if (
      !AssertIsValidMeasurement(stdHistoricalRange, 0) &&
      std_historical_range_unit === "days"
    ) {
      return "Number of historical days must be at least 1.";
    }
    return ValidateNumberInput(
      "Historical Range",
      stdHistoricalRange,
      true,
      true
    );
  }
};

export const getHistoricalRange = ({
  std_num_measurements,
  std_seconds_in_past,
}) => {
  if (std_num_measurements) {
    return std_num_measurements;
  }
  if (std_seconds_in_past) {
    return getDaysFromSeconds(std_seconds_in_past);
  }
};

export const getHistoricalRangeUnit = ({
  std_num_measurements,
  std_seconds_in_past,
}) => {
  if (std_num_measurements) {
    return "samples";
  }
  if (std_seconds_in_past) {
    return "days";
  }
};

const stdHistoricalRangeToFormData = (
  std_historical_range_unit,
  std_historical_range
) => {
  const stdHistRangeData = {
    std_seconds_in_past: null,
    std_num_measurements: null,
  };
  if (std_historical_range_unit === "samples") {
    stdHistRangeData.std_num_measurements = std_historical_range;
  } else if (std_historical_range_unit === "days") {
    stdHistRangeData.std_seconds_in_past =
      getSecondsFromDays(std_historical_range);
  }
  return stdHistRangeData;
};

export const formatFormDataForAlertSpecsSubmission = (
  inputs,
  streams,
  measurementInfo
) => {
  const {
    upper_limit,
    lower_limit,
    std_min_num_measurements,
    std_sigma,
    previous_percentage_deviation,
    roq_previous_percentage_deviation,
    roq_previous_total_vol_percentage_deviation,
    std_historical_range_unit,
    std_historical_range,
  } = inputs;
  return streams.map((stream) => ({
    test_property: measurementInfo.name,
    measurement_unit: measurementInfo.unit,
    upper_limit: upper_limit ?? null,
    lower_limit: lower_limit ?? null,
    std_min_num_measurements: std_min_num_measurements ?? null,
    std_sigma: std_sigma ?? null,
    ...stdHistoricalRangeToFormData(
      std_historical_range_unit,
      std_historical_range
    ),
    previous_percentage_deviation: previous_percentage_deviation ?? null,
    previous_error_deviation: false,
    roq_previous_percentage_deviation:
      roq_previous_percentage_deviation ?? null,
    roq_previous_total_vol_percentage_deviation:
      roq_previous_total_vol_percentage_deviation ?? null,
    stream_id: stream.id,
  }));
};

export const formatFormDataForInlineMonitorsSubmission = (
  inputs,
  streams,
  measurementInfo
) => {
  const { num_offspec_threshold, interval_minutes, upper_limit, lower_limit } =
    inputs;
  return streams.map((stream) => ({
    property: measurementInfo.name,
    num_offspec_threshold,
    interval_seconds: interval_minutes && interval_minutes * 60,
    upper_limit: upper_limit ?? null,
    lower_limit: lower_limit ?? null,
    stream_id: stream.id,
  }));
};
