import config from "#config";
import { Button, Modal, Title } from "@validereinc/common-components";
import classNames from "classnames/bind";
import * as PropTypes from "prop-types";
import React from "react";
import FontAwesome from "react-fontawesome";
import moduleStyles from "./ContractSupportModal.module.scss";

const cx = classNames.bind(moduleStyles);

const ContactSupportModal = ({ onHide }) => (
  <Modal
    className={cx("contactSupportModal")}
    onClose={onHide}
  >
    <Modal.Header>
      <Title>Contact Support</Title>
    </Modal.Header>

    <Modal.Body>
      <p>
        If possible please include a detailed explanation, relevant links,
        screenshots/video demonstrating the problem, replicable processes, and
        any other information that can assist you with your request.
      </p>

      <div className={cx("contactInformation")}>
        <a href={`mailto:${config.SUPPORT_EMAIL}`}>
          <FontAwesome
            className="icon"
            name="envelope"
          />
          {config.SUPPORT_EMAIL}
        </a>
      </div>
    </Modal.Body>

    <Modal.Footer className="clearfix">
      <Button
        className="pull-left"
        onClick={onHide}
      >
        Close
      </Button>
    </Modal.Footer>
  </Modal>
);

ContactSupportModal.propTypes = {
  onHide: PropTypes.func.isRequired,
};

export default ContactSupportModal;
