import { NetworkAssetsStep } from "#batteries-included-components/Forms/NetworkForm/NetworkAssetsStep";
import {
  useGetOneNetwork,
  useUpdateNetwork,
} from "#hooks/adapters/useNetworks";
import { useNavigate, useParams } from "#routers/hooks";
import { NetworkListRoute } from "#routes/organization/networks";
import {
  NetworkDetailPageParameters,
  NetworkDetailRoute,
} from "#routes/organization/networks/[networkId]/detail";
import { NetworkEditRoute } from "#routes/organization/networks/[networkId]/detail/edit/index";
import {
  MultiStepFormProvider,
  useMultiStepFormContext,
} from "#src/hooks/useMultiStepForm";
import { useBreadcrumbsFromRoute } from "#utils/route";
import { Page } from "@validereinc/common-components";
import { AssetGroupType, UpdateNetworkType } from "@validereinc/domain";
import React from "react";

export const EditNetworkPageContent = () => {
  const { stepper, footer } = useMultiStepFormContext();
  const { networkId } = useParams<NetworkDetailPageParameters>();
  const { data: network } = useGetOneNetwork(networkId);

  const [breadcrumbs] = useBreadcrumbsFromRoute(NetworkEditRoute, {
    detail: { title: network?.name },
  });

  const category = network?.name
    ? `${NetworkEditRoute.previous?.previous?.title} | ${network.name}`
    : NetworkEditRoute.previous?.previous?.title;

  return (
    <Page
      title={NetworkEditRoute.title}
      category={category}
      breadcrumbs={breadcrumbs}
      actionRow={stepper}
      footer={footer}
    >
      <NetworkAssetsStep
        networkId={networkId}
        stepNumberOverride={1}
      />
    </Page>
  );
};

export const EditNetworkPage = () => {
  const navigate = useNavigate();
  const { networkId } = useParams<NetworkDetailPageParameters>();

  const onCancel = () => navigate({ pathname: NetworkListRoute.toLink() });

  const updateNetwork = useUpdateNetwork({
    onSuccess: (data: AssetGroupType) =>
      navigate({
        pathname: NetworkDetailRoute.toLink({
          pathParams: { networkId: data?.id },
        }),
      }),
  });
  const onSubmit = (values: Array<Partial<UpdateNetworkType>>) => {
    updateNetwork.mutate({
      ...values.reduce((total, current) => ({ ...total, ...current }), {}),
      assetGroupId: networkId,
    });
  };

  return (
    <MultiStepFormProvider
      steps={[
        {
          label: "Assets",
        },
      ]}
      onCancel={onCancel}
      onSubmit={onSubmit}
      onSubmitMutationState={{
        isSubmitting: updateNetwork.isLoading,
      }}
    >
      <EditNetworkPageContent />
    </MultiStepFormProvider>
  );
};
