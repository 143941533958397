import React from "react";
import { BasicInfoFormStep } from "./BasicInfoFormStep";
import { UserSelectionStep } from "./UserSelectionStep";

export const UserGroupFormPanel = () => {
  return (
    <>
      <BasicInfoFormStep />
      <UserSelectionStep />
    </>
  );
};
