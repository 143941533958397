import {
  WorkflowTemplatesFilterPanel,
  WorkflowTemplatesTablePanel,
} from "#src/batteries-included-components";
import { useStorageKey } from "#src/hooks/useStorageKey";
import { useBreadcrumbsFromRoute } from "#src/utils/route";
import { useQuery } from "@tanstack/react-query";
import { Page } from "@validereinc/common-components";
import { WorkflowCategoriesAdapter } from "@validereinc/domain";
import React from "react";
import { useParams } from "react-router";
import { WorkflowCategoryDetailsRoutePath } from ".";

export const WorkflowCategoryDetailsPage = () => {
  const storageKeys = useStorageKey("workflows-category-details-templates");
  const { workflowCategoryId } = useParams<{
    workflowCategoryId: string;
  }>();
  const { isLoading, data: category } = useQuery({
    queryKey: ["category", workflowCategoryId],
    queryFn: () => WorkflowCategoriesAdapter.getOne({ id: workflowCategoryId }),
  });
  const [breadcrumbs] = useBreadcrumbsFromRoute(
    WorkflowCategoryDetailsRoutePath,
    {
      ":workflowCategoryId": {
        title: category?.data.name,
      },
    }
  );

  return (
    <Page
      title={category?.data.name ?? WorkflowCategoryDetailsRoutePath.title}
      breadcrumbs={breadcrumbs}
      isLoading={isLoading}
      category={WorkflowCategoryDetailsRoutePath.previous?.title}
    >
      <WorkflowTemplatesFilterPanel
        showCategoryFilter={false}
        filterConfigStorageKey={storageKeys.filterConfigStorageKey}
      />
      <WorkflowTemplatesTablePanel {...storageKeys} />
    </Page>
  );
};
