import {
  RoleListFilterPanel,
  RoleListTablePanel,
} from "#src/batteries-included-components/Panels";
import { useStorageKey } from "#src/hooks/useStorageKey";
import React from "react";

export const RoleListTab = () => {
  const { filterConfigStorageKey, tableConfigStorageKey } =
    useStorageKey("roles");

  return (
    <>
      <RoleListFilterPanel filterConfigStorageKey={filterConfigStorageKey} />
      <RoleListTablePanel
        tableConfigStorageKey={tableConfigStorageKey}
        filterConfigStorageKey={filterConfigStorageKey}
      />
    </>
  );
};
