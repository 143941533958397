import { useParams } from "#routers/hooks";
import { useMeasurementTypes } from "#src/contexts/MeasurementTypeContext";
import { RecordContext } from "#src/contexts/RecordContext";
import { useForm } from "@validereinc/common-components";
import {
  AggregationFunctionType,
  MeasurementTypeType,
  RecordType,
  RecordValueConfigurationType,
  RecordValueType,
  RecordValueUpdateType,
} from "@validereinc/domain";
import { yearMonthParser } from "@validereinc/utilities";
import { endOfMonth, startOfMonth } from "date-fns";
import React, { createContext, useContext, useEffect } from "react";
import { UseFormReturn } from "react-hook-form";

export type RecordConfigurationFormType = RecordValueConfigurationType &
  Pick<RecordValueUpdateType, "measurement_unit">;

export type DeviceConfigurationFilterType = {
  asset_type: string;
  device: string;
  to: Date;
  from: Date;
};

export const RecordValueConfigurationContext = createContext<{
  record?: RecordType;
  recordValue?: RecordValueType;
  measurementType?: MeasurementTypeType;
  form?: UseFormReturn<RecordConfigurationFormType>;
  isLoading: boolean;
} | null>(null);

export const RecordValueConfigurationContextProvider = ({
  children,
}: {
  children: React.ReactElement | React.ReactElement[];
}) => {
  const params = useParams<{ measurementType: string }>();

  const { record, isLoading: isRecordLoading } =
    useContext(RecordContext) || {};
  const recordValue = record?.values.find(
    ({ measurement_type }) => measurement_type === params.measurementType
  );

  /* Fetch Measurement Type Metadata */
  const { measurementTypes, isLoading: isMeasurementTypeLoading } =
    useMeasurementTypes();

  const measurementType = measurementTypes?.find(
    ({ id }) => id === params.measurementType
  );

  const isLoading = isRecordLoading || isMeasurementTypeLoading;

  const form = useForm({
    defaultValues: {},
  })<RecordConfigurationFormType>;

  useEffect(() => {
    form.reset({
      /** Common Fields */
      aggregate_function:
        recordValue?.configuration?.aggregate_function ??
        AggregationFunctionType.AVERAGE,
      /** Manual Entry Fields */
      measurement_unit:
        recordValue?.measurement_unit ?? measurementType?.default_unit,
      value: recordValue?.configuration?.value ?? recordValue?.value,
      /** Calculator Fields */
      calculator_result_ids: recordValue?.configuration?.calculator_result_ids,
      /** Measurement Fields */
      measurement_ids: recordValue?.configuration?.measurement_ids,
      form_category_id: recordValue?.configuration?.form_category_id,
      /** Device Fields */
      measurement_series_id: recordValue?.configuration?.measurement_series_id,
      interval: "15minute",
      start: recordValue?.configuration?.start
        ? new Date(recordValue?.configuration?.start)
        : startOfMonth(
            record ? yearMonthParser(record.year_month) : new Date()
          ),
      end: recordValue?.configuration?.end
        ? new Date(recordValue?.configuration?.end)
        : endOfMonth(record ? yearMonthParser(record.year_month) : new Date()),
    });
  }, [recordValue]);

  return (
    <RecordValueConfigurationContext.Provider
      value={{
        record,
        recordValue,
        measurementType,
        isLoading,
        form,
      }}
    >
      {children}
    </RecordValueConfigurationContext.Provider>
  );
};
