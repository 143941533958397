import { z } from "zod";
import { AttributeSchema } from "./AttributeSchemas";
import { CustomAttributeRecordSchema } from "./CustomAttributeSchemas";
import {
  DomainModelMetaExtendedSchema,
  DomainModelMetaSchema,
  DomainModelSchema,
} from "./DomainModelSchemas";
import { FacilitySchema } from "./FacilitySchemas";

export const DeviceMeasurementSeriesSchema = z
  .object({
    description: z.string(),
    measurement_type: z.string(),
    unit: z.string(),
  })
  .merge(DomainModelSchema);

export const DeviceAttributeSchema = z
  .object({
    height: z.number(),
    location: z.tuple([
      z.number().lte(90).gte(-90),
      z.number().lte(180).gte(-180),
    ]),
    provider: z.string(),
  })
  .partial({
    location: true,
    height: true,
  });

export const DeviceTypeAttributeSchema = AttributeSchema.pick({
  description: true,
  data_type: true,
  id: true,
  name: true,
  is_required: true,
});

export const DeviceTypeSchema = z.object({
  name: z.string(),
  id: z.string(),
  attributes: z.array(DeviceTypeAttributeSchema),
});

export const DeviceStatus = {
  ACTIVE: "active",
  ARCHIVED: "archived",
} as const;

export const DeviceSchema = z
  .object({
    name: z.string(),
    status: z.enum([DeviceStatus.ACTIVE, DeviceStatus.ARCHIVED]),
    type_id: z.string(),
    facility_id: z.string().uuid(),
    attributes: DeviceAttributeSchema,
    custom_attributes: CustomAttributeRecordSchema,
    facility: FacilitySchema.pick({
      id: true,
      name: true,
    }),
    measurement_types: z.array(z.string()),
    type: DeviceTypeSchema.pick({
      name: true,
      id: true,
    }),
  })
  .merge(DomainModelSchema)
  .merge(DomainModelMetaSchema)
  .merge(DomainModelMetaExtendedSchema)
  .describe("Schema for Device asset type");

export const DeviceCreateSchema = DeviceSchema.pick({
  name: true,
  facility_id: true,
  type_id: true,
  status: true,
  measurement_types: true,
  attributes: true,
  custom_attributes: true,
});

export type DeviceMeasurementSeriesType = z.infer<
  typeof DeviceMeasurementSeriesSchema
>;
export type DeviceType = z.infer<typeof DeviceSchema>;
export type DeviceAttributeType = z.infer<typeof DeviceAttributeSchema>;
export type DeviceStatusType = (typeof DeviceStatus)[keyof typeof DeviceStatus];
export type DeviceTypeAttributeType = z.infer<typeof DeviceTypeAttributeSchema>;
export type DeviceTypeType = z.infer<typeof DeviceTypeSchema>;
export type DeviceCreateType = z.infer<typeof DeviceCreateSchema>;
