import { TransactionType } from "@validereinc/domain";

export const FiltersConfig = {
  search_name: {
    name: "search_file_name",
    placeholder: "Search by file name",
    label: "File Name",
  },
  search_transaction: {
    name: "search_transaction_id",
    placeholder: "Search by transaction ID",
    label: "Transaction ID",
  },
  date: {
    name: "dateRange",
  },
  status: {
    name: "status",
    placeholder: "Select a status",
    label: "Status",
  },
  source: {
    name: "source",
    placeholder: "Select an import type",
    label: "Import Type",
  },
  dataset: {
    name: "dataset",
    placeholder: "Select a dataset",
    label: "Dataset",
  },
} as const;

export const getTransactionStatusVariant = (
  status?: TransactionType["status"]
) => {
  switch (status) {
    case "completed":
      return "success";
    case "completed_with_partial_success":
      return "warning";
    case "pending":
      return "info";
    case "failed":
      return "error";
    default:
      return "default";
  }
};

export const getTransactionStateVariant = (
  status: TransactionType["state"]
) => {
  switch (status) {
    case "success":
      return "success";
    case "pending":
      return "info";
    case "partial_success":
    case "partial_failure":
      return "warning";
    case "failed":
      return "error";
    default:
      return "default";
  }
};

export const getSourceTypeDisplayLabel = (
  source: TransactionType["source"]
) => {
  switch (source) {
    case "api":
      return "API";
    case "email":
      return "Email";
    case "iot":
      return "IoT";
    case "kafka":
      return "Queue";
    case "sftp":
      return "SFTP";
    case "upload":
      return "File Upload";
    case "unknown":
    default:
      return "Unknown";
  }
};

export const getStatusDisplayLabel = (status?: TransactionType["status"]) => {
  switch (status) {
    case "completed":
      return "Completed";
    case "completed_with_partial_success":
      return "Partially Completed";
    case "failed":
      return "Failed";
    case "pending":
      return "In-Progress";
    default:
      return "Unknown";
  }
};
