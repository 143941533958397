import { CalculationsProgressBanner } from "#batteries-included-components/Banners/CalculationsProgressBanner";
import { ConfigurationProgressBanner } from "#batteries-included-components/Banners/ConfigurationProgressBanner";
import { ImportDataActionStatusWithQueue } from "#batteries-included-components/Banners/ImportDataActionStatus";
import { VOLUME_MEASUREMENT_TYPES } from "#routes/volume/constants";
import {
  CalculationsFilterPanel,
  CalculationsSummaryPanel,
  CalculationsTablePanel,
} from "#src/batteries-included-components";
import { useIsFeatureAvailable } from "#src/contexts/AuthenticatedContext.helpers";
import { useStorageKey } from "#src/hooks/useStorageKey";
import { AssetType, MeasurementTypeType } from "@validereinc/domain";
import React from "react";

export const CalculatorsVolumeTab = () => {
  const [isDataIngestionEnabled] = useIsFeatureAvailable({
    featureFlagQuery: "core:data_pipeline",
  });

  const volumeStorageKeys = useStorageKey("calculations-volumetric");
  const volumeCalculationJobStorageKey = useStorageKey(
    "calculations-volumetric-bulk-calculate"
  ).tableConfigStorageKey;
  const volumeConfigurationJobStorageKey = useStorageKey(
    "calculations-volumetric-bulk-configure"
  ).tableConfigStorageKey;

  const measurementTypeFilter = ({ quantity }: MeasurementTypeType) =>
    quantity === "volume";

  return (
    <>
      {isDataIngestionEnabled ? (
        <ImportDataActionStatusWithQueue
          resource={{
            id: "estimation_method_config",
            name: "Calculations",
            singular: "Volume Calculation",
            plural: "Volume Calculations",
          }}
        />
      ) : null}
      <CalculationsProgressBanner
        jobStorageKey={volumeCalculationJobStorageKey}
      />
      <ConfigurationProgressBanner
        jobStorageKey={volumeConfigurationJobStorageKey}
      />
      <CalculationsFilterPanel
        assetType={AssetType.FLOW}
        filterConfigStorageKey={volumeStorageKeys.filterConfigStorageKey}
      />
      <CalculationsSummaryPanel
        assetType={AssetType.FLOW}
        defaultMeasurementTypes={VOLUME_MEASUREMENT_TYPES}
        measurementTypeFilter={measurementTypeFilter}
        filterConfigStorageKey={volumeStorageKeys.filterConfigStorageKey}
      />
      <CalculationsTablePanel
        assetType={AssetType.FLOW}
        defaultMeasurementTypes={VOLUME_MEASUREMENT_TYPES}
        measurementTypeFilter={measurementTypeFilter}
        calculationJobStorageKey={volumeCalculationJobStorageKey}
        configurationJobStorageKey={volumeConfigurationJobStorageKey}
        {...volumeStorageKeys}
      />
    </>
  );
};
