import {
  TemplatedConfigurationInputCustomAttributeConfigurationType,
  TemplatedConfigurationInputType,
  TemplatedConfigurationType,
} from "../schemas/TemplatedConfigurationSchemas";
import {
  fetchAndCollate,
  getFilterObject,
  GetListResponseType,
  GetOneRequestType,
  GetOneResponseType,
  ResourceServiceType,
} from "../util";
import { restAPI } from "./api";

type TemplatedConfigurationServiceType = Pick<
  ResourceServiceType<TemplatedConfigurationType>,
  "getOne" | "getList"
> & {
  inputSchema: {
    getOne: (
      params: GetOneRequestType<{
        custom_attribute_configuration?: TemplatedConfigurationInputCustomAttributeConfigurationType;
      }>
    ) => Promise<GetOneResponseType<TemplatedConfigurationInputType>>;
  };
};

export const TemplatedConfigurationAdapter: TemplatedConfigurationServiceType =
  {
    getOne: ({ id }) =>
      restAPI.nodeAPI
        .GET<TemplatedConfigurationType>({
          endpoint: `/templated_configurations/${id}`,
        })
        .then((resp) => ({
          data: resp,
        })),

    getList: ({ filters, page, pageSize, sortBy, sortDirection }) =>
      fetchAndCollate(
        ({ page, pageSize }) =>
          restAPI.nodeAPI.POST<GetListResponseType<TemplatedConfigurationType>>(
            {
              endpoint: "/templated_configurations/search_company",
              body: {
                page,
                page_size: pageSize,
                sort_by: sortBy,
                sort_direction: sortDirection,
                filter: getFilterObject(filters),
              },
            }
          ),
        page,
        pageSize
      ),

    inputSchema: {
      getOne: ({ id, meta }) =>
        restAPI.nodeAPI
          .POST<TemplatedConfigurationInputType>({
            endpoint: `/templated_configurations/${id}/input_schema`,
            body: meta,
          })
          .then((resp) => ({ data: resp })),
    },
  };
