import get from "lodash/get";
import union from "lodash/union";
import unionBy from "lodash/unionBy";

export function SetObjectWithParameterValue(object, parameter, value) {
  Object.keys(object).map((key) => (object[key][parameter] = value));

  return object;
}

export function SetObjectValue(object, value) {
  Object.keys(object).map((key) => (object[key] = value));

  return object;
}

/**
 * Given an array of objects, returns an array of unique object[key] elements.
 * Excludes null/undefined (but not false) from the set
 *
 * @param {Object[]} objects
 * @param {string} key - string of object property to be aggregated
 * @param {string} uniqueBy - optional, if object[key] is an object/array of objects
 *  what property to check for uniqueness
 * @returns {Object[] | string[]}
 */
export function GetAllObjectValue(objects, key, uniqueBy) {
  return objects
    .reduce((values, object) => {
      const currentValue = Array.isArray(object[key])
        ? object[key]
        : [object[key]];
      if (uniqueBy) {
        return unionBy(values, currentValue, uniqueBy);
      } else {
        return union(values, currentValue);
      }
    }, [])
    .filter((object) => object != null);
}

// IMPROVE: this function needs a rework
/**
 * Given an array of objects, get a map of values where the values can be
 * extracted from a specific property and the key can be extracted from a
 * specific property.
 *
 * @example
 * getPropertyAsMap([{ id: 1, a: "hello", b: { c: 3 } }, {id: 2, a: "world", b: { c: 4 }}], "a")
 * // returns { 1: "hello", 2: "world" }
 *
 * @example
 * getPropertyAsMap([{ id: 1, a: "hello", b: { c: 3 } }, {id: 2, a: "world", b: { c: 4 }}], "b.c")
 * // returns { 1: 3, 2: 4 }
 *
 * @example
 * getPropertyAsMap([{ id: 1, a: "hello", b: { c: 3 } }, {id: 2, a: "world", b: { c: 4 }}], null, "a")
 * // returns { "hello": { id: 1, a: "hello", b: { c: 3 } }, "world": {id: 2, a: "world", b: { c: 4 }}}
 *
 * @returns an object with the specified values at the provided key property used as keys and the values at the specified value property used as the values
 */
export function getPropertyAsMap<T extends { id: string }>(
  /** the array of objects to be turned into a map */
  objects: T[],
  /** the property of the object to extract the value from. If not provided, the entire object will be used as the value. Used with lodash#get. @see {@link lodash https://lodash.com/docs/4.17.15#get} for the path syntax */
  valueProperty?: string | null,
  /** the key/property that will give the value to use as the key. If not provided, an "id" property will be used. Used with lodash#get. @see {@link lodash https://lodash.com/docs/4.17.15#get} for the path syntax */
  keyProperty?: string | null
) {
  // IMPROVE: how can we type this effectively?
  const map: any = {};

  objects.forEach((object) => {
    const key = keyProperty ? get(object, keyProperty) : object.id;
    const value = valueProperty ? get(object, valueProperty) : object;

    map[key] = value;
  });

  return map;
}

/**
 * Given an array of values, returns the first value in values that is not
 * false, null, undefined, or NaN (i.e. truthy values, 0, and "")
 * @param {*} values array of values to be checked
 * @param {*} defaultReturn the return value if no valid value found
 * @returns the first valid value or defaultReturn
 */
export function getFirstValidValue(values, defaultReturn = undefined) {
  return (
    values.find(
      (value) =>
        (value && typeof value !== "object") || value === 0 || value === ""
    ) ?? defaultReturn
  );
}
