import React from "react";
import * as PropTypes from "prop-types";
import FontAwesome from "react-fontawesome";
import "./PopupTaskBar.css";
import { Link } from "react-router-dom";

const PopupTaskBar = (props) => (
  <div className="monthlyView__popupTaskBar">
    {props.addLink ? (
      <Link to={props.addLink}>
        <FontAwesome name="plus" />
      </Link>
    ) : null}

    {props.editLink ? (
      <Link to={props.editLink}>
        <FontAwesome name="pencil-square-o" />
      </Link>
    ) : null}
  </div>
);

PopupTaskBar.propTypes = {
  onClose: PropTypes.func,
  addLink: PropTypes.string,
  editLink: PropTypes.string,
};

export default PopupTaskBar;
