import { ensureStreamListIsFetched } from "#redux/actions";
import StreamService from "#services/StreamService";
import WorkflowService from "#services/WorkflowService";
import {
  AssertIsAfterOrEqualDate,
  AssertIsBeforeOrEqualDate,
} from "#utils/assert";
import { DateTimeInput, SelectInput } from "@validereinc/common-components";
import find from "lodash/find";
import * as PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { getScheduleTaskOptions } from "../../CreateSampleModalHelper";

const SCHEDULED_OPTIONS = ["Yes", "No"];

const SAMPLE_TYPE_OPTIONS = ["composite", "spot"];

const mapStateToProps = (state) => {
  return {
    streams: state.streams.data.toJS(),
  };
};

const mapDispatchToProps = {
  ensureStreamListIsFetched,
};

const useFetchWorkflow = () => {
  const [workflows, setWorkflows] = useState([]);
  const [fetchWorkflowLoadingState, setFetchWorkflowLoadingState] =
    useState("loading");

  useEffect(() => {
    setFetchWorkflowLoadingState("loading");

    WorkflowService.getWorkflow()
      .then(({ data }) => {
        setWorkflows(data);
      })
      .finally(() => {
        setFetchWorkflowLoadingState("loaded");
      });
  }, []);

  return [workflows, fetchWorkflowLoadingState];
};

const useFetchScheduledTask = () => {
  const [scheduledTasks, setScheduledTasks] = useState([]);
  const [fetchScheduledTasksLoadingState, setFetchScheduledTasksLoadingState] =
    useState("loading");
  const [lastUpdatedAt, setLastUpdatedAt] = useState(new Date());

  const fetchScheduledTask = () => {
    setLastUpdatedAt(new Date());
  };

  useEffect(() => {
    setFetchScheduledTasksLoadingState("loading");

    StreamService.getScheduleTask()
      .then(({ data }) => {
        setScheduledTasks(data);
      })
      .finally(() => {
        setFetchScheduledTasksLoadingState("loaded");
      });
  }, [lastUpdatedAt]);

  return [scheduledTasks, fetchScheduledTasksLoadingState, fetchScheduledTask];
};

const SampleForm = ({ form, inputs, streams, ensureStreamListIsFetched }) => {
  const [workflows, fetchWorkflowLoadingState] = useFetchWorkflow();

  const [scheduledTasks, scheduledTasksLoadingState, fetchScheduledTask] =
    useFetchScheduledTask();

  const onIsScheduledSelect = (value) => {
    form.setValue("sample_point_id", null);
    form.setValue("stream", null);
    form.setValue("workflow_instance", null);

    if (value) {
      fetchScheduledTask();
    }
  };

  const availableScheduledTasks = useMemo(() => {
    if (
      fetchWorkflowLoadingState === "loaded" &&
      scheduledTasksLoadingState === "loaded"
    ) {
      return getScheduleTaskOptions(workflows, scheduledTasks, inputs.site);
    }

    return [];
  }, [fetchWorkflowLoadingState, scheduledTasksLoadingState, inputs.site]);

  const availableSamplePointIds =
    find(streams, (stream) => {
      return (
        (inputs.scheduled === "Yes" &&
          inputs.workflow_instance?.stream_id === stream.id) ||
        (inputs.scheduled === "No" && inputs?.stream?.id === stream.id)
      );
    })?.sample_point_ids ?? [];

  useEffect(() => {
    ensureStreamListIsFetched();
  }, []);

  return (
    <>
      <SelectInput
        name="sample_type"
        label="Sample Type"
        options={SAMPLE_TYPE_OPTIONS}
        ignoreCase
        isRequired
        showIcon
      />

      <SelectInput
        name="scheduled"
        label="Scheduled"
        options={SCHEDULED_OPTIONS}
        onChange={(value) => onIsScheduledSelect(value)}
        ignoreCase
        showIcon
      />

      {inputs?.scheduled === "No" ? (
        <SelectInput
          name="stream"
          label="Stream"
          labelKey="name"
          options={streams}
          ignoreCase
          isRequired
          showIcon
        />
      ) : (
        <SelectInput
          name="workflow_instance"
          label="Workflow Task"
          labelKey="name"
          options={availableScheduledTasks}
          isLoading={scheduledTasksLoadingState !== "loaded"}
          ignoreCase
          isRequired
          showIcon
        />
      )}

      <SelectInput
        name="sample_point_id"
        label="Sample Point ID"
        options={availableSamplePointIds}
        isClearable={true}
        ignoreCase
        showIcon
      />

      <DateTimeInput
        name="started_at"
        label="Start Time"
        dayPickerProps={{ disabledDays: { after: new Date() } }}
        validate={{
          validateStartTime: (value) => {
            if (!AssertIsBeforeOrEqualDate(value, inputs.ended_at, "second")) {
              return "Sample start time must be earlier than sample end time.";
            }
            return null;
          },
        }}
        isRequired
      />

      <DateTimeInput
        name="ended_at"
        label="End Time"
        dayPickerProps={{ disabledDays: { after: new Date() } }}
        validate={{
          validateEndTime: (value) => {
            if (!AssertIsAfterOrEqualDate(value, inputs.started_at, "second")) {
              return "Sample end time must be later than sample start time.";
            }
            return null;
          },
        }}
        isRequired
      />
    </>
  );
};

SampleForm.propTypes = {
  form: PropTypes.object.isRequired,
  inputs: PropTypes.object.isRequired,
  streams: PropTypes.array.isRequired,
  ensureStreamListIsFetched: PropTypes.func.isRequired,
};

const SampleFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SampleForm);

export default SampleFormContainer;
