import config from "#src/config";
import { ValidateCSVSafeInput } from "#utils/formValidator";
import {
  DateInput,
  InputStack,
  SelectInput,
  TextInput,
} from "@validereinc/common-components";
import findIndex from "lodash/findIndex";
import * as PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import {
  ALERT_TYPE,
  frequencyTypeOptions,
  OFFSPEC,
  TestTypeOptions,
  timezoneOptions,
  WEEK,
} from "./WorkflowFormHelpers";

const WorkflowForm = ({
  form,
  inputs,
  streamList,
  siteList,
  measurementTypes,
  selectedStreamList,
  selectedSitesList,
  disabled,
  requireTestsRequired,
}) => {
  const [showRepeatDay, setShowRepeatDay] = useState(true);

  useEffect(() => {
    const frequency = {};
    frequency.type = "weekly";
    frequency.repeat_every_x_weeks = inputs.repeats;
    frequency.repeat_on_days = inputs.repeatsDay;
    form.setValue("frequency", frequency);
  }, []);

  useEffect(() => {
    const frequency = {};
    if (inputs.frequencyType?.id === "Week") {
      frequency.type = "weekly";
      frequency.repeat_every_x_weeks = inputs.repeats;
      frequency.repeat_on_days = inputs.repeatsDay;
      delete frequency.repeat_every_x_months;
    } else {
      frequency.type = "monthly";
      frequency.repeat_every_x_months = inputs.repeats;
      delete frequency.repeat_on_days;
      delete frequency.repeat_every_x_weeks;

      setShowRepeatDay(false);
    }

    form.setValue("frequency", frequency);
  }, [inputs.repeats, inputs.frequencyType, inputs.repeatsDay]);

  useEffect(() => {
    if (selectedStreamList?.length && streamList.length) {
      const streams = [];
      const streamIds = [];
      selectedStreamList.forEach((streamId) => {
        const streamIndex = findIndex(streamList, ["id", streamId]);
        streams.push(streamList[streamIndex]);
        streamIds.push(streamId);
      });

      form.setValue("selectedStreamList", streams);
      form.setValue("streamIds", streamIds);
    }
  }, [streamList, selectedStreamList]);

  useEffect(() => {
    if (selectedSitesList?.length) {
      const siteId = [];
      selectedSitesList.forEach((site) => {
        siteId.push(site.id);
      });

      form.setValue("siteId", siteId);
    }
  }, [siteList, selectedSitesList]);

  const setIds = (key, value) => {
    const ids = [];

    value.forEach((val) => {
      ids.push(val.id);
    });

    form.setValue(key, ids);
  };

  const setOffspec = (value) => {
    value.includes(OFFSPEC[0])
      ? form.setValue("offspec_retest", true)
      : form.setValue("offspec_retest", false);

    value.includes(OFFSPEC[1])
      ? form.setValue("offspec_retest_3pl", true)
      : form.setValue("offspec_retest_3pl", false);
  };

  const setAlertSettings = (value) => {
    const alertSettings = {};
    ALERT_TYPE.forEach((alertType) => {
      value.includes(alertType)
        ? (alertSettings[alertType] = true)
        : (alertSettings[alertType] = false);
    });

    form.setValue("alertSettings", alertSettings);
  };

  const onFrequencyTypeChange = (value) => {
    if (value.id === "Month") {
      setShowRepeatDay(false);
    } else {
      setShowRepeatDay(true);
    }
  };

  const sharedProps = {
    isDisabled: disabled,
    showIcon: true,
  };

  return (
    <>
      <TextInput
        name="name"
        label="Workflow Name"
        type="text"
        placeholder="Workflow 1"
        validate={{
          validateName: (name) => ValidateCSVSafeInput("Workflow Name", name),
        }}
        isRequired
        {...sharedProps}
      />

      <DateInput
        name="startDay"
        label="Start Date"
        format={config.DATE_FORMAT}
        {...sharedProps}
      />

      <SelectInput
        name="timezone"
        label="Timezone"
        options={timezoneOptions}
        isRequired
        {...sharedProps}
      />

      <InputStack label="Repeat Every">
        <TextInput
          name="repeats"
          type="number"
          step={1}
          onChange={(val) => {
            form.setValue(
              "frequencyType",
              frequencyTypeOptions(val).find(
                (option) => option.id === inputs.frequencyType.id
              )
            );
          }}
          validate={{
            validateRepeats: (val) =>
              !val || val <= 0
                ? "Your repeats every must be greater than 0"
                : null,
          }}
          {...sharedProps}
        />

        <SelectInput
          name="frequencyType"
          options={frequencyTypeOptions(inputs.repeats)}
          onChange={onFrequencyTypeChange}
          labelKey="name"
          {...sharedProps}
        />
      </InputStack>

      {showRepeatDay && (
        <SelectInput
          name="repeatsDay"
          label="Repeat On"
          validate={{
            validateRepeatsDay: (val) =>
              val?.length === 0 && showRepeatDay
                ? "You must select one or more weekdays"
                : null,
          }}
          options={WEEK}
          isMulti
          closeMenuOnSelect={false}
          {...sharedProps}
        />
      )}

      <InputStack label="End on">
        <SelectInput
          name="endOn"
          options={["Never", "After"]}
          onChange={(val) => {
            if (val === "Never") {
              form.setValue("occurrences", null);
            }
          }}
          {...sharedProps}
        />

        {inputs.endOn === "After" ? (
          <TextInput
            name="occurrences"
            type="number"
            validate={{
              validateOccurrences: (val) =>
                !val || val <= 0
                  ? "Occurrences must be positive integer"
                  : null,
            }}
            placeholder={"infinite"}
            unit="times"
            {...sharedProps}
          />
        ) : (
          <div />
        )}
      </InputStack>

      <TextInput
        label="Repeat"
        name="repeats_per_scheduling_period"
        type="number"
        validate={{
          validateRepeatNumTimesPerDay: (val) =>
            !val || val <= 0
              ? "Your number times per day must be a positive integer"
              : null,
        }}
        unit="times per scheduled period"
        {...sharedProps}
      />

      <SelectInput
        name="testType"
        label="Workflow Type"
        labelKey="name"
        options={TestTypeOptions}
        {...sharedProps}
      />

      <SelectInput
        name="selectedStreamList"
        label="Stream"
        labelKey="name"
        options={streamList}
        onChange={(value) => setIds("streamIds", value)}
        ignoreCase
        isMulti
        isRequired
        closeMenuOnSelect={false}
        {...sharedProps}
      />

      <SelectInput
        name="siteList"
        label="Lab"
        labelKey="name"
        options={siteList}
        onChange={(value) => setIds("siteId", value)}
        ignoreCase
        isMulti
        isRequired
        closeMenuOnSelect={false}
        {...sharedProps}
      />

      <SelectInput
        name="testsRequired"
        label="Test Required"
        options={measurementTypes}
        ignoreCase
        isMulti
        isRequired={requireTestsRequired}
        closeMenuOnSelect={false}
        {...sharedProps}
      />

      <SelectInput
        name="offspec"
        label="If Off-spec"
        options={OFFSPEC}
        onChange={setOffspec}
        ignoreCase
        isMulti
        closeMenuOnSelect={false}
        {...sharedProps}
      />

      <SelectInput
        name="alert"
        label="Alert Type"
        options={ALERT_TYPE}
        onChange={setAlertSettings}
        ignoreCase
        isMulti
        closeMenuOnSelect={false}
        {...sharedProps}
      />
    </>
  );
};

WorkflowForm.propTypes = {
  form: PropTypes.object.isRequired,
  inputs: PropTypes.object,
  streamList: PropTypes.array,
  siteList: PropTypes.array,
  measurementTypes: PropTypes.array,
  selectedStreamList: PropTypes.array,
  selectedSitesList: PropTypes.array,
  disabled: PropTypes.bool,
  requireTestsRequired: PropTypes.bool,
};

export default WorkflowForm;
