import { EquipmentTypeType } from "../schemas";
import { restAPI } from "./api";

export const EquipmentTypeDomain = {
  getAll: async () =>
    restAPI.nodeAPI.GET<EquipmentTypeType[]>({
      endpoint: "/equipment_types",
    }),

  // UPDATE
};
