import { useNavigate } from "#src/Routers/hooks";
import { RoutingLink } from "#src/batteries-included-components/RoutingLink";
import { getPermissionDisplayLabel } from "#src/contexts/AuthenticatedContext.helpers";
import { linkToRoleDetailPage } from "#src/routes/settings/roles-and-permissions/roles/[roleId]/detail";
import { UserDetailContext } from "#src/routes/settings/users/detail/UserDetailContext";
import { linkToEditUserRolePage } from "#src/routes/settings/users/detail/roles/edit";
import { getPropertyAsMap } from "#src/utils/objectFormatter";
import {
  useMutation,
  useQueries,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import {
  Button,
  Dialog,
  EmptyState,
  Icon,
  KeyValuePanel,
  Panel,
  Pill,
  PillProps,
  Tooltip,
  useAlert,
} from "@validereinc/common-components";
import {
  RolePermissionType,
  RoleType,
  RolesAdapter,
  UsersAdapter,
} from "@validereinc/domain";
import { datetimeFormatter } from "@validereinc/utilities";
import classNames from "classnames/bind";
import React, { useContext, useMemo, useState } from "react";
import styles from "./RolesKeyValuePanel.module.scss";

const cx = classNames.bind(styles);

export const RolesKeyValuePanel = () => {
  const navigate = useNavigate();
  const { userDetails: user } = useContext(UserDetailContext) ?? {};
  const { addAlert } = useAlert();
  const [roleToDelete, setRoleToDelete] = useState<RoleType | null>(null);
  const queryClient = useQueryClient();
  const usersRolesQuery = useQuery({
    queryKey: ["users", user?.data?.id, "roles"],
    queryFn: ({ queryKey }) => {
      const [_, id] = queryKey;

      if (!id) {
        return;
      }

      return UsersAdapter.roles.getList({ userId: id });
    },
    enabled: Boolean(user?.data?.id),
    staleTime: 3 * 60 * 1000,
  });
  const rolesQueries = useQueries({
    queries:
      usersRolesQuery.data?.map((userRoleAssignment) => ({
        queryKey: ["roles", userRoleAssignment.group_id],
        queryFn: () => RolesAdapter.getOne({ id: userRoleAssignment.group_id }),
        staleTime: 3 * 60 * 1000,
        select: (resp) => resp.data,
      })) ?? [],
  });
  const rolesPermissionsQueries = useQueries({
    queries:
      usersRolesQuery.data?.map((userRoleAssignment) => ({
        queryKey: ["roles", userRoleAssignment.group_id, "permissions"],
        queryFn: () =>
          RolesAdapter.permissions.getMany({
            id: userRoleAssignment.group_id,
          }),
        staleTime: 3 * 60 * 1000,
      })) ?? [],
  });

  const rolesMap = useMemo(
    () =>
      getPropertyAsMap(rolesQueries ?? [], "data", "data.id") as Record<
        string,
        RoleType
      >,
    [rolesQueries]
  );
  const rolesPermissionsMap = useMemo(() => {
    if (
      !rolesPermissionsQueries ||
      rolesPermissionsQueries.some((query) => query.isLoading)
    ) {
      return {};
    }

    return rolesPermissionsQueries.reduce<Record<string, RolePermissionType[]>>(
      (map, q) => {
        if (!q.data?.length) {
          return map;
        }

        map[q.data[0].group_id] = q.data;
        return map;
      },
      {}
    );
  }, [rolesPermissionsQueries]);

  const removeGroupMutation = useMutation({
    mutationFn: ({ roleId, userId }: { roleId: string; userId: string }) =>
      RolesAdapter.members.delete({ roleId, userId }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["users"],
      });
      addAlert?.({
        variant: "success",
        message: "Successfully removed role",
      });
    },
    onError: () => {
      addAlert?.({
        variant: "error",
        message: "Failed to remove role",
      });
    },
    onMutate: () => {
      setRoleToDelete(null);
    },
  });

  const userId = user?.data?.id;
  const isUserDisabled = user?.data?.status === "disabled";

  return (
    <>
      <Panel
        loaded={
          !user?.isLoading &&
          !usersRolesQuery.isLoading &&
          !rolesQueries.some((query) => query.isLoading) &&
          !rolesPermissionsQueries.some((query) => query.isLoading)
        }
        title="Roles"
        actionRow={
          isUserDisabled ? (
            <Tooltip content="You cannot assign roles to a user's account that has been disabled">
              <Button
                onClick={() => {
                  navigate({
                    pathname: linkToEditUserRolePage(userId),
                  });
                }}
                disabled={isUserDisabled}
              >
                Assign Roles
              </Button>
            </Tooltip>
          ) : (
            <Button
              onClick={() => {
                navigate({
                  pathname: linkToEditUserRolePage(userId),
                });
              }}
            >
              Assign Roles
            </Button>
          )
        }
      >
        {usersRolesQuery.data?.length ? (
          usersRolesQuery.data.map((userRoleAssignment) => {
            const groupDetails = rolesMap[userRoleAssignment.group_id];
            const groupPermissions =
              rolesPermissionsMap[userRoleAssignment.group_id];
            const isPermissionsConfigured = Boolean(groupPermissions);

            if (!userRoleAssignment || !groupDetails?.name) {
              return null;
            }

            return (
              <KeyValuePanel
                key={userRoleAssignment.group_id}
                panelMaxColumnCount={2}
                panelKeyValueListContainerProps={{
                  style: { padding: 0 },
                }}
                panelProps={{
                  actionRow: [
                    ...(groupDetails
                      ? !isUserDisabled
                        ? [
                            <Button
                              key="remove"
                              className={cx("removeBtn")}
                              variant="error-outline"
                              onClick={() => setRoleToDelete(groupDetails)}
                            >
                              Remove
                            </Button>,
                          ]
                        : [
                            <Tooltip
                              key="remove"
                              content="You cannot remove roles from a user's account that has been disabled"
                            >
                              <Button
                                className={cx("removeBtn")}
                                variant="error-outline"
                                onClick={() => setRoleToDelete(groupDetails)}
                                disabled={isUserDisabled}
                              >
                                Remove
                              </Button>
                            </Tooltip>,
                          ]
                      : []),
                  ],
                  isFluidY: false,
                  title: (
                    <RoutingLink
                      to={linkToRoleDetailPage(userRoleAssignment.group_id)}
                    >
                      {groupDetails.name}
                    </RoutingLink>
                  ),
                  loaded: Boolean(groupDetails.name),
                  style: {
                    alignSelf: "flex-start",
                    marginBottom: "10px",
                    marginTop: "10px",
                  },
                }}
                data={[
                  {
                    title: "Status",
                    value: (
                      <Pill
                        variant={
                          (
                            {
                              active: "success",
                              disabled: "default",
                            } satisfies Record<
                              RoleType["status"],
                              PillProps["variant"]
                            >
                          )[groupDetails.status]
                        }
                      >
                        {groupDetails.status}
                      </Pill>
                    ),
                  },
                  {
                    title: "Permissions",
                    value: isPermissionsConfigured
                      ? groupPermissions
                          .map((perm) => getPermissionDisplayLabel(perm.name))
                          .join(", ")
                      : "Not Configured",
                  },
                  {
                    title: "Effective Date",
                    value: datetimeFormatter(
                      new Date(userRoleAssignment.created_at)
                    ),
                    description: "When the role was assigned",
                  },
                ]}
              />
            );
          })
        ) : (
          <EmptyState
            icon={<Icon variant="lock" />}
            title="This user has no roles"
            suggestion="Assign roles to see them here"
          />
        )}
      </Panel>
      <Dialog
        isOpen={roleToDelete !== null}
        onClose={() => setRoleToDelete(null)}
        title="Delete Role"
        actionRow={[
          <Button
            key="delete-dialog-action"
            onClick={() => {
              if (!roleToDelete || !user?.data?.id) {
                return;
              }

              removeGroupMutation.mutate({
                roleId: roleToDelete.id,
                userId: user.data.id,
              });
            }}
            disabled={!roleToDelete || !user?.data?.id}
            variant="error"
          >
            Remove Role
          </Button>,
        ]}
      >
        Are you sure you want to remove the &quot;{roleToDelete?.name}&quot;
        role?
      </Dialog>
    </>
  );
};
