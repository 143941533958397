import React, { Component } from "react";
import moment from "moment";
import Chart from "../../../Common/Chart/Chart";
import "./Analytics.css";
import { SortListByType } from "../../../../utils/sorters";

import { LineMarkSeries, MarkSeries } from "react-vis";
import pickBy from "lodash/pickBy";

/* eslint-disable react/prop-types */
const lodash = { pickBy };

class AnalyticsChart extends Component {
  constructor(props) {
    super(props);
    this.getYAxisTitle = this.getYAxisTitle.bind(this);
  }

  getYAxisTitle() {
    const { measurementTypes, selectedMeasurementType } = this.props;

    return `${selectedMeasurementType}
      (${measurementTypes(selectedMeasurementType).unit})`;
  }

  getInlineChartSeries(id, data) {
    if (data) {
      const dataWithDateFormat = this.convertDateFormat(data);

      const inlineChartProps = {
        key: id,
        data: SortListByType(dataWithDateFormat, "x", "asc"),
        color: this.props.getStreamColor(id),
      };

      return (
        <LineMarkSeries
          {...inlineChartProps}
          size={2}
          curve={"curveLinear"}
          style={{ pointerEvents: "none" }}
        />
      );
    }

    return null;
  }

  getFieldChartSeries(id, data) {
    if (data) {
      const dataWithDateFormat = this.convertDateFormat(data);

      const fieldChartProps = {
        key: id,
        data: dataWithDateFormat,
        color: this.props.getStreamColor(id),
        onValueMouseOver: this.props.hoverDataPoint,
      };

      return (
        <MarkSeries
          {...fieldChartProps}
          size={2}
          curve={"curveLinear"}
        />
      );
    }

    return null;
  }

  // Converting the data's date into ISO date format
  convertDateFormat(data) {
    return data.map((data) => {
      data.x = moment(data.x).toDate();
      return data;
    });
  }

  render() {
    const { chartData, yDomain, from, to, streams } = this.props;

    const chartDataWithMeasurementValue = lodash.pickBy(chartData, (stream) => {
      return (
        (stream.inline && stream.inline.length > 0) ||
        (stream.field && stream.field.length > 0)
      );
    });

    return (
      <div className="chartContainer">
        <Chart
          xType="time"
          xAxisTitle="Date"
          yAxisTitle={this.getYAxisTitle()}
          xDomain={[from, to]}
          yDomain={yDomain}
          labels={streams}
        >
          {chartDataWithMeasurementValue
            ? Object.keys(chartDataWithMeasurementValue).map((key) =>
                this.getFieldChartSeries(
                  key,
                  chartDataWithMeasurementValue[key].field
                )
              )
            : null}
          {chartDataWithMeasurementValue
            ? Object.keys(chartDataWithMeasurementValue).map((key) =>
                this.getInlineChartSeries(
                  key,
                  chartDataWithMeasurementValue[key].inline
                )
              )
            : null}
        </Chart>
      </div>
    );
  }
}

export default AnalyticsChart;
