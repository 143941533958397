import { InputIsMatched } from "#utils/filters";
import { getTimeStringFromDate } from "#utils/timeFormatter";
import styles from "@validereinc/common-components/constants";
import { getFormattedNumber } from "@validereinc/utilities";
import get from "lodash/get";
import isNil from "lodash/isNil";
import orderBy from "lodash/orderBy";
import React from "react";
import config from "../../config";

const lodash = { orderBy, get };

/**
 * isValidString() returns '-' or string value
 * based on if variable is null/undefined
 */
export function isValidString(sampleId) {
  if (sampleId !== undefined) {
    if (sampleId.search(/^\d+$/)) {
      return false;
    } else {
      return true;
    }
  }
}

/**
 * getPaginateList() return paginated list of samples
 * based on the page number, each page size, array
 */
export function getPaginateList(array, currentPageNumber, pageSize) {
  const listFrom = (currentPageNumber - 1) * pageSize;
  const listEnd = currentPageNumber * pageSize;

  return array.slice(listFrom, listEnd);
}

/**
 * getPageLimit() return page element
 * base on the array length and pageSize (number of row per page)
 */
export function getPageLimit(array, pageSize) {
  return Math.ceil(array.length / pageSize);
}

/**
 * getSortedList() return sorted filtered list
 * base on the previous sort order and sort by
 */
export function getSortedList(
  filterList,
  sortBy,
  sortOrderDesc,
  isButtonClick
) {
  // if the sorting button is clicked then swap the orderBy else keep the sample order
  if (isButtonClick) {
    if (sortOrderDesc) {
      return lodash.orderBy(
        filterList,
        [(row) => formatValue(row[sortBy])],
        "asc"
      );
    } else {
      return lodash.orderBy(
        filterList,
        [(row) => formatValue(row[sortBy])],
        "desc"
      );
    }
  } else {
    if (sortOrderDesc) {
      return lodash.orderBy(
        filterList,
        [(row) => formatValue(row[sortBy])],
        "desc"
      );
    } else {
      return lodash.orderBy(
        filterList,
        [(row) => formatValue(row[sortBy])],
        "asc"
      );
    }
  }
}

/**
 * getFilterList() return filtered list
 * base on the filter by (string)
 */
export function getFilterList(
  unfilteredList,
  filterBy,
  filterValue,
  filterFunction = undefined
) {
  if (!filterValue) {
    return unfilteredList;
  } else {
    return unfilteredList.filter((sample) => {
      if (filterFunction) {
        return filterFunction(filterValue, sample[filterBy]);
      } else {
        return InputIsMatched(filterValue, sample[filterBy]);
      }
    });
  }
}

/**
 * getFilterListFloat() return filtered list
 * base on the filter by (float)
 */
export function getFilterListFloat(unfilteredList, filterBy, filterValue) {
  let filterList = [];
  if (filterValue === null || filterValue === "") {
    filterList = unfilteredList;
  } else {
    filterList = unfilteredList.filter(function (sample) {
      if (sample[filterBy] !== null && sample[filterBy] !== undefined) {
        return sample[filterBy].toFixed(3) >= parseFloat(filterValue);
      } else {
        return null;
      }
    });
  }

  return filterList;
}

/**
 * formatValue() return string variable to lowercase else float/int value
 * base on the type of the variable
 */
export function formatValue(value) {
  if (typeof value === "string") {
    return value.toLowerCase();
  } else {
    return value;
  }
}

/**
 * getInstrumentType() split the instrument type by capital
 * eralyticsVapor -> eralytics vapor
 */
export function getInstrumentType(instrumentType) {
  if (instrumentType) {
    return instrumentType.toLowerCase();
  }

  return "";
}

export function getInstrumentIconStyle(instrumentType) {
  switch (getInstrumentType(instrumentType)) {
    case "water":
      return {
        color: styles.validereColour,
        shorthand: "W",
      };
    case "validere vapor":
      return {
        color: styles.validereColour,
        shorthand: "VP",
      };
    case "eralytics vapor":
      return {
        color: "#E57373",
        shorthand: "EV",
      };
    case "rigaku xray fluorescence":
      return {
        color: "#2196F3",
        shorthand: "RS",
      };
    case "anton paar density":
    case "anton paar dma 35 density":
      return {
        color: "#BF360C",
        shorthand: "AP",
      };
    case "anton paar svm 3000":
    case "anton paar svm 3001":
      return {
        color: "#BF360C",
        shorthand: "AP",
      };
    case "rudolph research density":
      return {
        color: "#046eac",
        shorthand: "RD",
      };
    case "horiba xray fluorescence":
      return {
        color: "#618AE1",
        shorthand: "HS",
      };
    case "drexelbrook":
      return {
        color: "#1565C0",
        shorthand: "D",
      };
    case "micro-motion":
      return {
        color: "#BF360C",
        shorthand: "M",
      };
    case "coriolis":
      return {
        color: "#607D8B",
        shorthand: "C",
      };
    case "gc":
      return {
        color: "#2E7D32",
        shorthand: "GC",
      };
    case "phase dynamics":
      return {
        color: "#E57373",
        shorthand: "P",
      };
    case "eralytics":
      return {
        color: "#E57373",
        shorthand: "E",
      };
    case "rigaku":
      return {
        color: "#2196F3",
        shorthand: "R",
      };
    case "virtual inline":
      return {
        color: "#BF360C",
        shorthand: "I",
      };
    case "agilent gas chromatography":
      return {
        color: "#0085d5",
        shorthand: "GC",
      };
    case "aquamax kf": {
      return {
        color: "#005792",
        shorthand: "AQ",
      };
    }
    case "mettler toledo t5":
    case "mettler toledo v20s": {
      return {
        color: "#65b42f",
        shorthand: "MT",
      };
    }
    case "isl vida density meter": {
      return {
        color: "#2B83B4",
        shorthand: "IV",
      };
    }
    case "grabner minivap vpxpert": {
      return {
        color: "#00747b",
        shorthand: "GI",
      };
    }
    default:
      return {
        color: "#5736ab",
        shorthand: "V",
      };
  }
}

export const dateRenderer = (rowData, columnKey) => {
  return (
    <div>
      {getTimeStringFromDate(rowData[columnKey], config.DATETIME_FORMAT)}
    </div>
  );
};

export const sampleDateRenderer = (rowData, columnKey) => {
  const { sample, sample_id } = rowData;
  if (sample_id) {
    return (
      <div>
        {getTimeStringFromDate(sample[columnKey], config.DATETIME_FORMAT)}
      </div>
    );
  } else {
    return "-";
  }
};

export const sortBySampleDate = (list, filterKey) => {
  return [...list].sort((a, b) => {
    const aDate = new Date(get(a, ["sample", filterKey], null));
    const bDate = new Date(get(b, ["sample", filterKey], null));

    return aDate - bDate;
  });
};

export const getMeasurementValue = (value, decimals) => {
  if ((value === 0 || value) && value !== -1) {
    return getFormattedNumber(value, decimals);
  }

  return "-";
};

export const sortByMeasurement = (list, measurementName) => {
  return [...list].sort((a, b) => {
    const aValue = a.measurements[measurementName]?.value;
    const bValue = b.measurements[measurementName]?.value;

    if (!isNil(aValue) && !isNil(bValue)) {
      return (
        a.measurements[measurementName].value -
        b.measurements[measurementName].value
      );
    } else if (isNil(aValue) && !isNil(bValue)) {
      return -1;
    } else if (!isNil(aValue) && isNil(bValue)) {
      return 1;
    } else {
      return 0;
    }
  });
};

export function getModelName(model) {
  switch (model) {
    case "water":
      return "Validere CTR";
    case "sample":
      return "Sample App";
    default:
      return model;
  }
}

export const getValue = (value, unit) => {
  if (value && unit) {
    return `${value} ${unit}`;
  }

  if (value) {
    return value;
  }

  return "-";
};
