import { LegacyDataTable, Tooltip } from "@validereinc/common-components";
import * as PropTypes from "prop-types";
import React, { useMemo } from "react";
import { AutoSizer } from "react-virtualized";
import AdjustmentFactorsCell from "./AdjustmentFactorCell";
import "./RecordOfVolume.scss";
import useFilterInputs from "./RecordOfVolumeTableFilter";

const RecordOfVolumeTable = (props) => {
  const {
    haveWritePermission,
    streams,
    adjustmentFactors,
    onViewDailyVolumeClick,
    onStreamChecked,
    streamAdjustmentConfigs,
    view,
    loading,
    actionRow,
  } = props;

  const streamListWithAdjustmentFactorIds = useMemo(() => {
    return streams.map((stream) => {
      const streamConfig = streamAdjustmentConfigs.find(
        (config) => config.stream_id == stream.id
      );

      return {
        ...stream,
        adjustmentFactorIds: streamConfig?.adjustment_factor_ids ?? [],
        upperLimit: streamConfig?.upper_limit,
      };
    });
  }, [streams, streamAdjustmentConfigs]);

  const [filterPillbox, filterRow, filteredStreams] = useFilterInputs(
    streamListWithAdjustmentFactorIds,
    adjustmentFactors,
    view
  );

  const streamRenderer = (rowData) => {
    const name = rowData.name ?? "-";

    return (
      <Tooltip content={name}>
        <div className="clipText">{name}</div>
      </Tooltip>
    );
  };

  const streamTypeRenderer = (rowData) => {
    return rowData?.associated_with_tank ? "Inventory" : "Stream";
  };

  const adjustmentFactorRenderer = (streamRowData) => {
    const { adjustmentFactorIds } = streamRowData;

    const adjustmentFactorsForStream =
      adjustmentFactorIds?.map((factorId) => {
        return adjustmentFactors.find((factor) => factor.id == factorId);
      }) ?? [];

    return (
      <AdjustmentFactorsCell adjustmentFactors={adjustmentFactorsForStream} />
    );
  };

  const upperLimitRenderer = (streamRowData) => {
    return streamRowData?.upperLimit ?? "-";
  };

  const Headers = [
    {
      label: "Stream",
      key: "name",
      cellRenderer: streamRenderer,
      width: 300,
      fixed: true,
    },
    {
      label: "Product Type",
      key: "product_type",
      width: 125,
    },
    {
      label: "Stream Type",
      key: "associated_with_tank",
      cellRenderer: streamTypeRenderer,
      width: 125,
    },
    {
      label: "Adjustment Factors",
      key: "adjustment_factors",
      cellRenderer: adjustmentFactorRenderer,
      width: 250,
      disableSort: true,
    },
    {
      label: "Adjustment Upper Limit",
      key: "upper_limit",
      cellRenderer: upperLimitRenderer,
      width: 165,
      disableSort: true,
    },
  ];

  return (
    <AutoSizer>
      {({ height, width }) => (
        <LegacyDataTable
          width={width}
          height={height}
          headers={Headers}
          list={filteredStreams}
          filterRow={filterRow}
          filterPillbox={filterPillbox}
          filterKey="name"
          defaultSortBy="name"
          defaultSortDirection="desc"
          onCheckboxClick={haveWritePermission ? onStreamChecked : null}
          isLoading={loading}
          actionRow={actionRow}
          onCellClick={onViewDailyVolumeClick}
          highlightRow={true}
        />
      )}
    </AutoSizer>
  );
};

RecordOfVolumeTable.propTypes = {
  haveWritePermission: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  streams: PropTypes.array.isRequired,
  adjustmentFactors: PropTypes.array.isRequired,
  onStreamChecked: PropTypes.func.isRequired,
  onViewDailyVolumeClick: PropTypes.func,
  streamAdjustmentConfigs: PropTypes.array.isRequired,
  view: PropTypes.string,
  actionRow: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default RecordOfVolumeTable;
