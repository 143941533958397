import { useSearchParams } from "#routers/hooks";
import { useQuery } from "@tanstack/react-query";
import { Page, Panel, Tab, Tabs } from "@validereinc/common-components";
import { LegacyUsersAdapter } from "@validereinc/domain";
import * as PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import ApiTokens from "../../User/ApiTokens/ApiTokens";
import UserActivity from "../../User/UserActivity/UserActivity";
import EditMemberProfile from "./EditMemberProfile";

const EditMember = ({ match, height }) => {
  const memberId = match.params.user_id;
  const [searchParams, setSearchParams] = useSearchParams();
  const memberDetailsQuery = useQuery(
    ["users", "legacy", memberId],
    () => LegacyUsersAdapter.getOne({ id: memberId }),
    {
      enabled: Boolean(memberId),
      staleTime: 3 * 60 * 1000,
    }
  );

  const onChangeTab = (newTab) => {
    setSearchParams({ ...searchParams, tab: newTab });
  };

  const memberDetails = memberDetailsQuery.data;

  return (
    <Page
      title={`Edit ${memberDetails?.name ?? ""} Profile Detail`}
      isLoading={memberDetailsQuery.isLoading}
    >
      <Panel>
        <Tabs
          activeKey={searchParams?.tab}
          onChange={onChangeTab}
        >
          <Tab
            tabKey={"profile-detail"}
            title="Profile Detail"
          >
            <EditMemberProfile
              memberProfile={memberDetails}
              updateMemberProfile={() => memberDetailsQuery.refetch()}
            />
          </Tab>

          <Tab
            tabKey={"activity"}
            title="Activity"
          >
            <UserActivity
              userId={memberId}
              height={height}
            />
          </Tab>

          <Tab
            tabKey={"api-tokens"}
            title="API Tokens"
          >
            <ApiTokens
              userId={memberId}
              height={height}
            />
          </Tab>
        </Tabs>
      </Panel>
    </Page>
  );
};

EditMember.propTypes = {
  match: PropTypes.object.isRequired,
  height: PropTypes.number.isRequired,
};

export default connect(null, null)(EditMember);
