import type { GetListResponseType, PaginatedListRequestType } from "../util";
import { FilterObjectType, getFilterObject } from "../util";
import { AssetTypeType } from "../schemas";
import { restAPI } from "./api";

export const DefaultRecordValueConfigurationTypeTypes = {
  MANUAL: "manual",
  CALCULATION: "calculator_result",
  MEASUREMENT: "form_measurement",
  MEASUREMENT_SERIES: "measurement_series",
};

export const DefaultRecordValueConfigurationTypeNames = {
  [DefaultRecordValueConfigurationTypeTypes.MANUAL]: "Default Value",
  [DefaultRecordValueConfigurationTypeTypes.CALCULATION]:
    "Specific Estimation Method",
  [DefaultRecordValueConfigurationTypeTypes.MEASUREMENT]: "Form Template",
  [DefaultRecordValueConfigurationTypeTypes.MEASUREMENT_SERIES]:
    "Device Measurements",
};

export type DefaultRecordValueConfigurationFilterType = FilterObjectType<{
  id?: string;
  measurement_type?: string;
  measurement_unit?: string;
  asset_id?: string;
  asset_type?: AssetTypeType;
  reporting_group_id?: string;
  reporting_group_name?: string;
}>;

export enum DefaultRecordValueConfigurationTypesType {
  manual = "manual",
  form_measurement = "form_measurement",
  calculator_result = "calculator_result",
  measurement_series = "measurement_series",
}

export enum DefaultRecordValueConfigurationFunctionType {
  top = "top",
  avg = "avg",
  sum = "sum",
}

export const DefaultRecordValueConfigurationFunctionNames = {
  [DefaultRecordValueConfigurationFunctionType.top]: "Most Recent",
  [DefaultRecordValueConfigurationFunctionType.avg]: "Average",
  [DefaultRecordValueConfigurationFunctionType.sum]: "Sum",
};

export interface DefaultRecordValueConfigurationFormMeasurementType {
  configuration_type: DefaultRecordValueConfigurationTypesType;
  aggregate_function: DefaultRecordValueConfigurationFunctionType;
  sort?: string;
  sort_direction?: "asc" | "desc";
  window?: string;
  filter: object;
  timezone?: string;
}

export interface DefaultRecordValueConfigurationCalculatorResultType {
  configuration_type: DefaultRecordValueConfigurationTypesType;
  aggregate_function: DefaultRecordValueConfigurationFunctionType;
  sort: string;
  sort_direction: "asc" | "desc";
  window?: string;
  filter: object;
  timezone?: string;
}

export interface DefaultRecordValueConfigurationMeasurementSeriesType {
  configuration_type: DefaultRecordValueConfigurationTypesType;
  aggregate_function: DefaultRecordValueConfigurationFunctionType;
  window?: string;
  filter: object;
  timezone?: string;
}

export type DefaultRecordValueCongurationConfiguration =
  | DefaultRecordValueConfigurationFormMeasurementType
  | DefaultRecordValueConfigurationCalculatorResultType
  | DefaultRecordValueConfigurationMeasurementSeriesType;

export type DefaultRecordValueConfigurationType = {
  id: string;
  company_id?: string;
  asset_id?: string;
  reporting_group_id?: string;
  measurement_type?: string;
  measurement_unit?: string;
  default_value: number | null;
  configuration: DefaultRecordValueCongurationConfiguration | null;
  created_at: Date | string;
  created_by: string;
  updated_at: Date | string;
  updated_by: string;
};

export type DefaultRecordValueConfigurationLookupType = { drvcId: string };

export const DefaultRecordValueConfigurationDomain = {
  getList: async ({
    page,
    pageSize,
    sortBy,
    sortDirection,
    groupBy,
    filters,
    ...restProps
  }: PaginatedListRequestType<DefaultRecordValueConfigurationFilterType>) => {
    const formattedFilters = getFilterObject(filters);

    return restAPI.nodeAPI.POST<
      GetListResponseType<DefaultRecordValueConfigurationType>
    >({
      endpoint: "/default_record_value_configurations/search",
      body: {
        ...restProps,
        page,
        page_size: pageSize,
        sort_by: sortBy,
        sort_direction: sortDirection,
        filter: formattedFilters,
        group_by: groupBy,
      },
    });
  },

  getOne: async ({ drvcId }: DefaultRecordValueConfigurationLookupType) =>
    restAPI.nodeAPI.GET<DefaultRecordValueConfigurationType>({
      endpoint: `/default_record_value_configurations/${drvcId}`,
    }),
};
