import useQueryParams from "#routers/useQueryParams";
import { GetAllObjectValue } from "#utils/objectFormatter";
import {
  FilterPillbox,
  MultiDropdownInputWithSearch,
} from "@validereinc/common-components";
import filter from "lodash/filter";
import React, { useEffect, useState } from "react";
import { METHOD_OPTIONS } from "../../RecordOfQualityConstants";

const STATUS_OPTIONS = [
  { id: "approved", name: "Approved" },
  { id: "approval_required", name: "Approval Required" },
  { id: "error", name: "Error" },
];
const PRODUCT_OPTIONS = ["liquid", "gas"];
const CATEGORY_OPTIONS = ["receipt", "disposition", "inventory"];

const DEFAULT_FILTER_DROPDOWN = {
  roq: { inputs: null, options: [] },
  status: {
    label: "Status",
    inputs: [],
    options: STATUS_OPTIONS,
    labelKey: "name",
  },
  category: {
    label: "Category",
    inputs: [],
    options: CATEGORY_OPTIONS,
  },
  method: {
    label: "Method",
    inputs: [],
    options: METHOD_OPTIONS,
    labelKey: "name",
  },
  product: {
    label: "Product",
    inputs: [],
    options: PRODUCT_OPTIONS,
  },
  approvedBy: {
    label: "Approved By",
    inputs: [],
    options: [],
    labelKey: "name",
  },
  lastEditBy: {
    label: "Last Edit By",
    inputs: [],
    options: [],
    labelKey: "name",
  },
};

const queryParamsConverter = (filterDropdowns) => {
  // This function will convert filterDropdown into useQueryParams format
  const queryParamInput = {};
  const queryParamOptions = {};

  Object.keys(filterDropdowns).map((key) => {
    queryParamInput[key] = filterDropdowns[key].inputs;
    queryParamOptions[key] = filterDropdowns[key].options;
  });

  return { queryParamInput, queryParamOptions };
};

const noStatusMatch = (status, roqStatus) => {
  return status.every((s) => {
    // the api will return "validere_approved" when the roq is being approved
    // by a validere user and "approved" for normal user.
    if (s.id === "approved") {
      return s.id !== roqStatus && "validere_approved" !== roqStatus;
    }

    return s.id !== roqStatus;
  });
};

const noCategoryMatch = (category, roqCategory) => {
  return category.every((c) => {
    if (c === "inventory") {
      return (
        roqCategory !== "inventory_closing" &&
        roqCategory !== "inventory_opening"
      );
    }

    return c !== roqCategory;
  });
};

const filterRecordOfQualities = (list, filters) => {
  const { approvedBy, method, status, product, category, lastEditBy } = filters;
  return list.filter((roq) => {
    if (
      approvedBy.length > 0 &&
      approvedBy.every((approver) => approver.id !== roq.approved_by?.id)
    ) {
      return false;
    }

    if (
      method.length > 0 &&
      method.every((m) => m.id !== roq.selection_method)
    ) {
      return false;
    }

    if (status.length > 0 && noStatusMatch(status, roq.state)) {
      return false;
    }

    if (product.length > 0 && product.every((p) => p !== roq.stream.phase)) {
      return false;
    }

    if (category.length > 0 && noCategoryMatch(category, roq.category)) {
      return false;
    }

    if (
      lastEditBy.length > 0 &&
      lastEditBy.every((editor) => editor.id !== roq.last_revision_by?.id)
    ) {
      return false;
    }

    return true;
  });
};

const RecordOfQualityTableFilter = (recordOfQualities) => {
  const [filterDropdowns, setFilterDropdowns] = useState({
    ...DEFAULT_FILTER_DROPDOWN,
  });

  const { queryParamInput, queryParamOptions } =
    queryParamsConverter(filterDropdowns);
  const [queryParams, setQueryParams] = useQueryParams(
    queryParamInput,
    queryParamOptions
  );

  const { roq, ...filterBy } = queryParams;

  useEffect(() => {
    if (recordOfQualities) {
      const approvedByOptions = GetAllObjectValue(
        recordOfQualities,
        "approved_by",
        "id"
      ).filter((option) => option);

      const lastEditByOptions = GetAllObjectValue(
        recordOfQualities,
        "last_revision_by",
        "id"
      ).filter((option) => option);

      const approvedBy = { ...filterDropdowns.approvedBy };
      approvedBy.options = approvedByOptions;

      const lastEditBy = { ...filterDropdowns.lastEditBy };
      lastEditBy.options = lastEditByOptions;

      const roq = { ...filterDropdowns.roq };
      roq.options = recordOfQualities;

      setFilterDropdowns((filterDropdowns) => ({
        ...filterDropdowns,
        approvedBy,
        lastEditBy,
        roq,
      }));
    }
  }, [recordOfQualities]);

  const onDropdownSelect = (selectedValue, key) => {
    setQueryParams({ [key]: selectedValue });
  };

  const onClearFilterClick = (filterObj) => {
    let inputs = queryParams;
    if (filterObj) {
      if (Array.isArray(inputs[filterObj.filterKey])) {
        inputs = [...inputs[filterObj.filterKey]];
      } else {
        inputs = [inputs[filterObj.filterKey]];
      }

      setQueryParams({
        [filterObj.filterKey]: filter(inputs, (input) => {
          const value = input.name ? input.name : input;
          return value !== filterObj.name;
        }),
      });
    } else {
      const removedFilters = Object.keys(filterBy).reduce(
        (filterObj, filter) => ({ ...filterObj, [filter]: null }),
        {}
      );
      setQueryParams(removedFilters);
    }
  };

  const filterRow = (
    <div className="filterRow">
      {Object.keys(filterDropdowns).map((key) => {
        const { label, labelKey, options } = filterDropdowns[key];

        if (!label) {
          return;
        }

        return (
          <MultiDropdownInputWithSearch
            key={key}
            label={label}
            labelKey={labelKey ? labelKey : null}
            dropdownKey={key}
            value={queryParams[key]}
            options={options}
            onChange={onDropdownSelect}
          />
        );
      })}
    </div>
  );

  const filterPillbox = (
    <FilterPillbox
      filterBy={filterBy}
      onClearFilterClick={onClearFilterClick}
    />
  );

  const filteredRecordOfQualities = filterRecordOfQualities(
    recordOfQualities,
    queryParams
  );

  return [
    roq,
    filterPillbox,
    filterRow,
    filteredRecordOfQualities,
    setQueryParams,
  ];
};

export default RecordOfQualityTableFilter;
