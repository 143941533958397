import { getComponentShortForm } from "#components/Records/Quality/RecordOfQualityHelper";
import { BALANCE_SOURCE_TYPES } from "#utils/enums";
import { getNumberColorBySign } from "#utils/styleCalculator";
import { getFormattedNumber } from "@validereinc/utilities";

export function getComponentPlotData(components, chartType, balanceSourceType) {
  const data =
    balanceSourceType !== BALANCE_SOURCE_TYPES.INLETS_AND_OUTLETS
      ? components
      : components[chartType];

  return Object.keys(data).map((component) => {
    const { value, unit } = data[component];

    return {
      x: component,
      y: value,
      label: unit,
      color: getNumberColorBySign(value),
    };
  });
}

export function getEnergyPlotData(energy, chartType, balanceSourceType) {
  const data =
    balanceSourceType !== BALANCE_SOURCE_TYPES.INLETS_AND_OUTLETS
      ? energy
      : energy[chartType];
  const { value, unit } = data;

  return [
    {
      x: "Energy",
      y: value,
      label: unit,
      color: getNumberColorBySign(value),
    },
  ];
}

export function getTotalPlotData(total, chartType, balanceSourceType) {
  const data =
    balanceSourceType !== BALANCE_SOURCE_TYPES.INLETS_AND_OUTLETS
      ? total
      : total[chartType];
  const { value, unit } = data;

  return [
    {
      x: "Total",
      y: value,
      label: unit,
      color: getNumberColorBySign(value),
    },
  ];
}

export function getHintFormat(data) {
  return [
    {
      title: "Property",
      value: data.x,
    },
    {
      title: "Value",
      value: `${getFormattedNumber(data.y)} ${data.label ?? ""}`,
    },
  ];
}

export function getXAxisTick(tick) {
  if (tick === "Total" || tick === "Energy") {
    return tick;
  }

  return getComponentShortForm(tick);
}

export const getCorrectSource = (productionReportDetail, balanceSourceType) => {
  if (balanceSourceType === BALANCE_SOURCE_TYPES.INLETS_ONLY) {
    return productionReportDetail.inlets;
  }
  if (balanceSourceType === BALANCE_SOURCE_TYPES.OUTLETS_ONLY) {
    return productionReportDetail.outlets;
  }
  return productionReportDetail.summary;
};
