import { WorkflowStatusToPillVariantMap } from "#src/batteries-included-components/Panels/TablePanels/WorkflowsTablePanel/WorkflowsTablePanel.helpers";
import config from "#src/config";
import { getTimeStringFromDate } from "#src/utils/timeFormatter";
import { useQuery } from "@tanstack/react-query";
import { StatusPanel } from "@validereinc/common-components";
import { UsersAdapter, type WorkflowType } from "@validereinc/domain";
import startCase from "lodash/startCase";
import React from "react";

export const WorkflowDetailStatusPanel = ({
  workflowDetails,
}: {
  workflowDetails?: WorkflowType;
}) => {
  const { data: createdByUser } = useQuery(
    ["users", workflowDetails?.created_by],
    () => {
      if (!workflowDetails?.created_by) {
        return;
      }

      return UsersAdapter.getOne({ id: workflowDetails.created_by });
    },
    {
      enabled: Boolean(workflowDetails?.created_by),
      staleTime: 5 * 60 * 1000,
      select: (resp) => resp?.data,
    }
  );

  const { data: updatedByUser } = useQuery(
    ["users", workflowDetails?.updated_by],
    () => {
      if (!workflowDetails?.updated_by) {
        return;
      }

      return UsersAdapter.getOne({ id: workflowDetails.updated_by });
    },
    {
      enabled: Boolean(workflowDetails?.updated_by),
      staleTime: 5 * 60 * 1000,
      select: (resp) => resp?.data,
    }
  );

  const statusPanelProps = workflowDetails
    ? {
        data: [
          {
            label: "Due Date",
            value: getTimeStringFromDate(
              workflowDetails?.due_date,
              config.DATETIME_FORMAT_READABLE
            ),
          },
          {
            label: "Updated At",
            value: getTimeStringFromDate(
              workflowDetails?.updated_at,
              config.DATETIME_FORMAT_READABLE
            ),
          },
          {
            label: "Updated By",
            value: updatedByUser?.name ?? "-",
          },
          {
            label: "Created At",
            value: getTimeStringFromDate(
              workflowDetails?.created_at,
              config.DATETIME_FORMAT_READABLE
            ),
          },
          {
            label: "Created By",
            value: createdByUser?.name ?? "-",
          },
        ],
        statusText: startCase(workflowDetails?.status),
        statusVariant: workflowDetails?.status
          ? WorkflowStatusToPillVariantMap[workflowDetails.status]
          : "default",
      }
    : {};

  return (
    <StatusPanel
      isLoading={!workflowDetails}
      {...statusPanelProps}
    />
  );
};
