import { recordValueStatusPickerOptions } from "#src/components/Common/InlineRecordValueStatusPicker/InlineRecordValueStatusPicker";
import LockConfirmationModal from "#src/components/Common/LockConfirmationModal/LockConfirmationModal";
import {
  useHasRecordLockingPermission,
  useUpdateStatusBySearch,
} from "#src/components/hooks/adapters/useRecords";
import {
  Button,
  DropdownMenu,
  DropdownMenuOption,
  Icon,
} from "@validereinc/common-components";
import {
  RecordInputFilterType,
  RecordValueStatusType,
} from "@validereinc/domain";
import classNames from "classnames/bind";
import React, { useState } from "react";
import styles from "./BulkRecordStatusChangeButton.module.scss";

const cx = classNames.bind(styles);

const BulkRecordStatusChangeButton = ({
  label,
  filter,
  onSuccess,
  isDisabled,
  recordsCount,
}: {
  label?: string;
  filter: RecordInputFilterType;
  recordsCount: number;
  onSuccess?: () => void;
  isDisabled?: boolean;
}) => {
  const [newStatus, setNewStatus] = useState<RecordValueStatusType | null>(
    null
  );

  const setStatusBySearch = useUpdateStatusBySearch({
    onSuccess: () => {
      setNewStatus(null);
      onSuccess?.();
    },
  });
  const hasLockPermission = useHasRecordLockingPermission();

  const isComponentDisabled = !!isDisabled || recordsCount === 0;

  if (!hasLockPermission) return null;

  const dropdownOptions = recordValueStatusPickerOptions.map(
    ({ label, value, icon, variant }) =>
      ({
        label,
        variant,
        slotLeft: ({ iconProps }) =>
          icon ? (
            <Icon
              {...iconProps}
              variant="lock-simple"
            />
          ) : (
            <div className={cx("empty-icon-spacer")} />
          ),
        onClick: () => {
          setNewStatus(value);
        },
      }) as DropdownMenuOption
  );

  const displayButton = (
    <Button
      isLoading={setStatusBySearch.isLoading}
      iconPosition="right"
      icon="caret-down"
      iconProps={{ weight: "fill", size: 16 }}
      disabled={isComponentDisabled}
    >
      {label ?? <>Change Record Status</>}
    </Button>
  );

  if (isComponentDisabled) return displayButton;

  return (
    <>
      <DropdownMenu options={dropdownOptions}>{displayButton}</DropdownMenu>
      <LockConfirmationModal
        action={newStatus}
        onClose={() => setNewStatus(null)}
        isLoading={setStatusBySearch.isLoading}
        recordsCount={recordsCount}
        onConfirm={() => {
          setStatusBySearch.mutate({
            filter,
            status: newStatus!,
          });
        }}
      />
    </>
  );
};

export default BulkRecordStatusChangeButton;
