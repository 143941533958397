export { default as CreateEstimationMethod } from "./CreateEstimationMethod";

export const ESTIMATION_METHOD_DETAIL_PAGE_TITLE = "Add Estimation Method";

export const linkToEstimationMethodCreate = (equipmentId = ":equipmentId") =>
  `/app/organization/equipment/${equipmentId}/estimation-method/create`;

export const ESTIMATION_METHOD_CREATE_BREADCRUMB = {
  title: ESTIMATION_METHOD_DETAIL_PAGE_TITLE,
  path: linkToEstimationMethodCreate(),
};
