import { isValidFloatwUnit } from "../InstrumentComponentHelper";

const SVM_3001_MEASUREMENTS = [
  {
    label: "API Density @ 15 ℃",
    key: "api_density_15_c",
    getValue: ({ value, unit }) => isValidFloatwUnit(value, 1, unit, "-"),
    notMeasurement: true,
  },
  {
    label: "API Gravity @ 15 ℃",
    key: "api_gravity_15_c",
    getValue: ({ value }) => isValidFloatwUnit(value, 1, "", "-"),
    notMeasurement: true,
  },
  {
    label: "API Spec. Gravity @ 15 ℃",
    key: "api_spec_gravity_15_c",
    getValue: ({ value }) => isValidFloatwUnit(value, 4, "", "-"),
    notMeasurement: true,
  },
  {
    label: "API Density @ 20 ℃",
    key: "api_density_20_c",
    getValue: ({ value, unit }) => isValidFloatwUnit(value, 1, unit, "-"),
    notMeasurement: true,
  },
  {
    label: "API Gravity @ 20 ℃",
    key: "api_gravity_20_c",
    getValue: ({ value }) => isValidFloatwUnit(value, 1, "", "-"),
    notMeasurement: true,
  },
  {
    label: "API Spec. Gravity @ 20 ℃",
    key: "api_spec_gravity_20_c",
    getValue: ({ value }) => isValidFloatwUnit(value, 4, "", "-"),
    notMeasurement: true,
  },
  {
    label: "API Density @ 60 °F",
    key: "api_density_60_f",
    getValue: ({ value, unit }) => isValidFloatwUnit(value, 1, unit, "-"),
    notMeasurement: true,
  },
  {
    label: "API Gravity @ 60 °F",
    key: "api_gravity_60_f",
    getValue: ({ value }) => isValidFloatwUnit(value, 1, "", "-"),
    notMeasurement: true,
  },
  {
    label: "API Spec. Gravity @ 60 °F",
    key: "api_spec_gravity_60_f",
    getValue: ({ value }) => isValidFloatwUnit(value, 4, "", "-"),
    notMeasurement: true,
  },
];

const displayProperties = (test) => {
  return [
    {
      label: "Kinematic Viscosity",
      key: "Kinematic Viscosity",
    },
    {
      label: "Dynamic Viscosity",
      key: "Dynamic Viscosity",
    },
    {
      label: "Density",
      key: "Density",
    },

    ...(test.instrument.type === "anton paar svm 3001"
      ? SVM_3001_MEASUREMENTS
      : []),
  ];
};

export default displayProperties;
