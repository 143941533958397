import { getTemperatureDisplayUnit } from "#components/Instrument/InstrumentComponentHelper";
import { getMeasurementType as unboundGetMeasurementType } from "#redux/reducers/measurements";
import {
  AssertIsAfterDate,
  AssertIsAfterOrEqualDate,
  AssertIsBeforeDate,
} from "#utils/assert";
import {
  DateInput,
  DateTimeInput,
  FileInput,
  InputStack,
  SelectInput,
  TextInput,
  Title,
} from "@validereinc/common-components";
import * as PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { PRESSURE_AND_TEMPERATURE_FIELDS_OPTIONS } from "./ComponentAnalysisConstant";

const PressureAndTemperatureInput = ({
  isDisabled,
  field,
  getMeasurementType,
  onMeasurementChange,
}) => {
  const pressureKey = field === "Sample" ? `Pressure` : `${field} Pressure`;
  const temperatureKey =
    field === "Sample" ? `Temperature` : `${field} Temperature`;

  const pressureUnit = getMeasurementType(pressureKey).unit;
  const temperatureUnit = getMeasurementType(temperatureKey).unit;
  const userFriendlyTemperatureUnit =
    getTemperatureDisplayUnit(temperatureUnit);

  return (
    <>
      <InputStack
        name="measurements"
        label={field}
      >
        <TextInput
          name={`measurements.${pressureKey}.value`}
          type="number"
          placeholder="Pressure"
          onChange={(value) =>
            onMeasurementChange(pressureKey, value, pressureUnit)
          }
          unit={pressureUnit}
          isDisabled={isDisabled}
          showIcon
        />

        <TextInput
          name={`measurements.${temperatureKey}.value`}
          type="number"
          placeholder="Temperature"
          onChange={(value) =>
            onMeasurementChange(temperatureKey, value, temperatureUnit)
          }
          unit={userFriendlyTemperatureUnit}
          isDisabled={isDisabled}
          showIcon
        />
      </InputStack>
    </>
  );
};

const mapStateToProps = ({ measurements }) => {
  return {
    getMeasurementType: unboundGetMeasurementType(measurements),
  };
};

const InformationForm = ({
  form,
  formState,
  sites,
  streams,
  sampleTypes,
  sourceLabs,
  getMeasurementType,
  onMeasurementChange,
  onStreamChange,
}) => {
  const sharedProps = {
    showIcon: true,
    isDisabled: formState !== "enabled",
  };

  const samplePointIds = form.getValues("stream")?.sample_point_ids ?? [];

  return (
    <>
      <Title
        type="subheader"
        className="componentAnalysis__header"
      >
        Information
      </Title>

      <div>
        <SelectInput
          {...sharedProps}
          name="site"
          label="Site"
          labelKey="name"
          options={sites}
          ignoreCase
          isRequired
        />

        <SelectInput
          {...sharedProps}
          name="stream"
          label="Stream"
          labelKey="name"
          options={streams}
          onChange={onStreamChange}
          ignoreCase
          isRequired
        />

        <SelectInput
          {...sharedProps}
          name="sampleType"
          label="Sample Type"
          options={sampleTypes}
          isRequired
        />

        <SelectInput
          {...sharedProps}
          name="samplePointId"
          label="Sample Point ID"
          options={samplePointIds}
        />

        <SelectInput
          {...sharedProps}
          name="sourceLab"
          label="Source Lab"
          options={sourceLabs}
          isRequired
        />

        <DateTimeInput
          {...sharedProps}
          name="sampleStartTime"
          label="Sample Start Time"
          validate={{
            startTimeEarlierThanEndTime: (startTime) => {
              if (
                !AssertIsBeforeDate(
                  startTime,
                  form.getValues("sampleEndTime"),
                  "second"
                )
              ) {
                return "Sample start time must be earlier than sample end time.";
              }

              return null;
            },
          }}
          isRequired
        />

        <DateTimeInput
          {...sharedProps}
          name="sampleEndTime"
          label="Sample End Time"
          validate={{
            endTimeLaterThanStartTime: (sampleEndTime) => {
              if (
                !AssertIsAfterDate(
                  sampleEndTime,
                  form.getValues("sampleStartTime"),
                  "second"
                )
              ) {
                return "Sample end time must be later than sample start time.";
              }
              return null;
            },
          }}
          isRequired
        />

        <DateInput
          {...sharedProps}
          name="testDate"
          label="Test Date"
          validate={{
            testDateLaterThanSampleEndTime: (testDate) => {
              if (
                !AssertIsAfterOrEqualDate(
                  testDate,
                  form.getValues("sampleEndTime"),
                  "day"
                )
              ) {
                return "Test date must be later than sample end date";
              }

              return null;
            },
          }}
          isRequired
        />

        {PRESSURE_AND_TEMPERATURE_FIELDS_OPTIONS.map((field) => (
          <PressureAndTemperatureInput
            {...sharedProps}
            key={field}
            field={field}
            getMeasurementType={getMeasurementType}
            onMeasurementChange={onMeasurementChange}
          />
        ))}

        <FileInput
          {...sharedProps}
          name="attachment"
          label="Attachment"
          isRequired
        />
      </div>
    </>
  );
};

PressureAndTemperatureInput.propTypes = {
  showIcon: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  field: PropTypes.string.isRequired,
  getMeasurementType: PropTypes.func.isRequired,
  onMeasurementChange: PropTypes.func.isRequired,
};

InformationForm.propTypes = {
  form: PropTypes.object.isRequired,
  formState: PropTypes.string.isRequired,
  analyzeBookmarks: PropTypes.array,
  analyzeBookmarkClicked: PropTypes.func,
  sites: PropTypes.array,
  streams: PropTypes.array,
  sampleTypes: PropTypes.array,
  sourceLabs: PropTypes.array,
  disabled: PropTypes.bool,
  onMeasurementChange: PropTypes.func.isRequired,
  onStreamChange: PropTypes.func.isRequired,
  getMeasurementType: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, undefined)(InformationForm);
