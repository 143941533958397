import { RecordValueDetailsPanel } from "#batteries-included-components/Panels/DetailsPanel/RecordValueDetailsPanel";
import { RecordValueSourcesTablePanel } from "#batteries-included-components/Panels/TablePanels/RecordValueSourcesTablePanel";
import {
  FACILITIES_BREADCRUMB,
  FACILITY_DETAIL_BREADCRUMB,
} from "#routers/breadcrumbs";
import { useBreadcrumbs } from "#routers/breadcrumbsHelper";
import { useParams } from "#routers/hooks";
import { ORGANIZATION_BREADCRUMB } from "#routes/organization";
import {
  FACILITY_RECORD_DETAIL_BREADCRUMB,
  FACILITY_RECORD_DETAIL_PAGE_TITLE,
} from "#routes/organization/facilities/[facilityId]/record/[recordId]/details";
import BulkRecordValueStatusChangeButton from "#src/batteries-included-components/Buttons/BulkRecordValueStatusChangeButton/BulkRecordValueStatusChangeButton";
import ReportingGroupPillPanel from "#src/batteries-included-components/Panels/PillPanels/ReportingGroupPillPanel/ReportingGroupPillPanel";
import { RecordValueSummaryPanel } from "#src/batteries-included-components/Panels/SummaryPanels/RecordValueSummaryPanel/RecordValueSummaryPanel";
import { RecordValueActivityLogTablePanel } from "#src/batteries-included-components/Panels/TablePanels/RecordValueActivityLogTablePanel";
import {
  FacilityContext,
  FacilityProvider,
} from "#src/contexts/FacilityContext";
import { RecordContext, RecordProvider } from "#src/contexts/RecordContext";
import useLocalization from "#src/hooks/useLocalization";
import { useStorageKey } from "#src/hooks/useStorageKey";
import { useQuery } from "@tanstack/react-query";
import { Column, Page, Row } from "@validereinc/common-components";
import { AssetType, MeasurementsDomain, Resources } from "@validereinc/domain";
import { yearMonthName } from "@validereinc/utilities";
import React, { useContext } from "react";
import {
  FACILITY_RECORD_VALUE_DETAIL_BREADCRUMB,
  FACILITY_RECORD_VALUE_DETAIL_PAGE_TITLE,
  FacilityRecordValuePageParamsType,
} from "./index";

const FacilityRecordValueDetailPageContent = () => {
  const params = useParams<FacilityRecordValuePageParamsType>();
  const { localize } = useLocalization();

  const { facility } = useContext(FacilityContext) || {};
  const { record, refetch } = useContext(RecordContext) || {};

  const { data: measurementTypes } = useQuery({
    queryKey: ["measurementTypes"],
    queryFn: MeasurementsDomain.listMeasurementTypes,
  });
  const measurementType = measurementTypes?.find(
    ({ id }) => id === params.measurementType
  );

  const recordPageTitle = record
    ? `${yearMonthName(record.year_month)} Record`
    : FACILITY_RECORD_DETAIL_PAGE_TITLE;

  const pageTitle = record
    ? `${yearMonthName(record.year_month)} ${measurementType?.name}`
    : FACILITY_RECORD_VALUE_DETAIL_PAGE_TITLE;

  const breadcrumbs = useBreadcrumbs(
    [
      ORGANIZATION_BREADCRUMB,
      FACILITIES_BREADCRUMB,
      FACILITY_DETAIL_BREADCRUMB,
      FACILITY_RECORD_DETAIL_BREADCRUMB,
      FACILITY_RECORD_VALUE_DETAIL_BREADCRUMB,
    ],
    {
      2: facility?.name,
      3: recordPageTitle,
      4: pageTitle,
    },
    {
      id: params.facilityId,
      ...params,
    }
  );

  const recordValue = record?.values.find(
    ({ measurement_type }) => measurement_type === measurementType?.id
  );

  const tableKeys = useStorageKey(
    `activity-logs-${Resources.RECORD_VALUE}-${recordValue?.id}`
  );

  return (
    <Page
      category={`${localize("Facility")} Record`}
      title={pageTitle}
      breadcrumbs={breadcrumbs}
      actionRow={
        <BulkRecordValueStatusChangeButton
          recordId={record?.id ?? ""}
          measurementTypes={[recordValue?.measurement_type ?? ""]}
          onSuccess={() => {
            refetch?.();
          }}
          variant="primary"
          label="Change Record Value Status"
        />
      }
    >
      <Row>
        <Column variant={6}>
          <RecordValueSummaryPanel />
          <ReportingGroupPillPanel
            id={record?.reporting_group_id}
            isLoading={!record}
          />
        </Column>
        <Column variant={18}>
          <RecordValueDetailsPanel />
          <RecordValueSourcesTablePanel type={AssetType.FACILITY} />
          <RecordValueActivityLogTablePanel
            recordId={record?.id ?? ""}
            measurement_type={recordValue?.measurement_type}
            {...tableKeys}
          />
        </Column>
      </Row>
    </Page>
  );
};

export const FacilityRecordValueDetailPage = () => (
  <FacilityProvider>
    <RecordProvider>
      <FacilityRecordValueDetailPageContent />
    </RecordProvider>
  </FacilityProvider>
);
