import { useNavigate } from "#src/Routers/hooks";
import { PageErrorContent } from "#src/batteries-included-components/Layouts/Errors/PageError";
import { RoutingLink } from "#src/batteries-included-components/RoutingLink";
import { USER_GROUP_QUERY_KEY } from "#src/components/hooks/adapters/useUserGroups";
import { useHasPermission } from "#src/contexts/AuthenticatedContext.helpers";
import { useStorageKey } from "#src/hooks/useStorageKey";
import { linkToRoleDetailPage } from "#src/routes/settings/roles-and-permissions/roles/[roleId]/detail";
import { linkToUserDetailPage } from "#src/routes/settings/users/detail";
import { UserGroupsRoutePath } from "#src/routes/settings/users/groups";
import { UserGroupDetailsRoutePath } from "#src/routes/settings/users/groups/[groupId]/details";
import { UserGroupEditRoutePath } from "#src/routes/settings/users/groups/[groupId]/edit";
import { UserGroupCategoryDetailsRoutePath } from "#src/routes/settings/users/groups/categories/[categoryId]/details";
import { AddMembersToUserGroupDialog } from "#src/routes/settings/users/groups/categories/[categoryId]/details/AddMembersToUserGroupDialog";
import { ExceptionUtils } from "#src/utils/exception";
import { useBreadcrumbsFromRoute } from "#src/utils/route";
import {
  useMutation,
  useQueries,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import {
  Button,
  DataTable,
  DataTablePanel,
  Dialog,
  FilterPanel,
  KeyValuePanel,
  Page,
  Pill,
  TextInput,
  Tooltip,
  useAlert,
  useFilters,
} from "@validereinc/common-components";
import {
  UserGroupCategoriesAdapter,
  UserGroupMembershipType,
  UserGroupsAdapter,
  UserType,
  UsersAdapter,
} from "@validereinc/domain";
import { dateFormatter, toStartCaseString } from "@validereinc/utilities";
import classNames from "classnames/bind";
import React, { useMemo, useState } from "react";
import { useParams } from "react-router";
import styles from "./UserGroupDetailsPage.module.css";

const cx = classNames.bind(styles);

export const UserGroupDetailsPage = () => {
  const { groupId } = useParams<{ groupId: string }>();
  const { addAlert } = useAlert();
  const navigate = useNavigate();
  const [isDeleteAllowed] = useHasPermission("user_groups:delete");
  const [isEditAllowed] = useHasPermission("user_groups:write");
  const [isManageUsersDialogOpen, setIsManageUsersDialogOpen] = useState(false);
  const [userToRemove, setUserToRemove] = useState<string | null>(null);
  const [isUsersToRemoveDialogOpen, setIsUsersToRemoveDialogOpen] =
    useState(false);
  const [selectedUsers, setSelectedUsers] = useState<
    Record<string, UserGroupMembershipType>
  >({});
  const queryClient = useQueryClient();
  const { tableConfigStorageKey, filterConfigStorageKey } = useStorageKey(
    "users-groups-details"
  );
  const [filters] = useFilters<{ search: string }>(filterConfigStorageKey);
  const query = useQuery({
    queryKey: ["users", "groups", groupId],
    queryFn: () => UserGroupsAdapter.getOne({ id: groupId }),
    enabled: !!groupId,
  });
  const groupCategoryQuery = useQuery({
    queryKey: [
      "users",
      "groups",
      "categories",
      query.data?.data.group_category_id,
    ],
    queryFn: () =>
      UserGroupCategoriesAdapter.getOne({
        id: query.data?.data.group_category_id,
      }),
    enabled: !!query.data?.data.group_category_id,
  });
  const userMembershipsQuery = useQuery({
    queryKey: ["users", "groups", groupId, "memberships"],
    queryFn: () =>
      UserGroupsAdapter.members.getList({ meta: { userGroupId: groupId } }),
    enabled: !!groupId,
  });
  const [breadcrumbs] = useBreadcrumbsFromRoute(UserGroupDetailsRoutePath, {
    "/details": {
      title: query.data?.data.name,
    },
  });
  const usersQueries = useQueries({
    queries:
      userMembershipsQuery.data?.data.map((membership) => ({
        queryKey: ["users", membership.user_id],
        queryFn: () => UsersAdapter.getOne({ id: membership.user_id }),
        enabled: !!membership.user_id,
      })) ?? [],
  });
  const usersQueriesMap = useMemo(() => {
    return usersQueries.reduce<Record<string, UserType>>((map, query) => {
      if (!query.data || map[query.data.data.id]) return map;

      map[query.data.data.id] = query.data.data;
      return map;
    }, {});
  }, [usersQueries]);
  const usersRolesQueries = useQueries({
    queries:
      userMembershipsQuery.data?.data.map((membership) => ({
        queryKey: ["users", membership.user_id, "roles"],
        queryFn: () =>
          UsersAdapter.roles.getList({ userId: membership.user_id }),
        enabled: !!membership.user_id,
      })) ?? [],
  });
  const usersRolesQueriesMap = useMemo(() => {
    return usersRolesQueries.reduce<Record<string, UserRoleAssignmentType[]>>(
      (map, query) => {
        if (!query.data?.length || map[query.data?.[0]?.user_id]) return map;

        map[query.data[0].user_id] = query.data;
        return map;
      },
      {}
    );
  }, [usersRolesQueries]);
  const metaUsersQueries = useQueries({
    queries: [query.data?.data.created_by, query.data?.data.updated_by].map(
      (userId) => ({
        queryKey: ["users", userId],
        queryFn: () => UsersAdapter.getOne({ id: userId }),
        enabled: !!userId,
      })
    ),
  });
  const metaUsersQueriesMap = useMemo(() => {
    return metaUsersQueries.reduce<Record<string, UserType>>((map, query) => {
      if (!query.data || map[query.data.data.id]) return map;

      map[query.data.data.id] = query.data.data;
      return map;
    }, {});
  }, [metaUsersQueries]);
  const removeMemberMutation = useMutation({
    mutationFn: (userId: string) => {
      return UserGroupsAdapter.members.remove({
        id: userId,
        meta: { userGroupId: groupId },
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [...USER_GROUP_QUERY_KEY],
      });
    },
    onError: (err, userId) => {
      addAlert({
        variant: "success",
        message: `Failed to remove member ${usersQueriesMap[userId].name} from group.`,
      });
      ExceptionUtils.reportException(err, "error", {
        hint: "Removing a user membership from a group",
      });
    },
  });
  const deleteMutation = useMutation({
    mutationFn: () => {
      return UserGroupsAdapter.deleteOne({ id: groupId });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [...USER_GROUP_QUERY_KEY],
      });
      addAlert({
        variant: "success",
        message: "Successfully deleted user group.",
      });
      navigate(UserGroupsRoutePath.toLinkParts());
    },
    onError: () => {
      addAlert({
        variant: "error",
        message: "Failed to delete user group.",
      });
    },
  });

  const handleRemoveSelectedMembers = () => {
    return Promise.all(
      Object.values(selectedUsers).map((membership) =>
        removeMemberMutation.mutateAsync(membership.user_id)
      )
    )
      .then(() =>
        addAlert({
          variant: "success",
          message: "Succesfully removed members from group",
        })
      )
      .finally(() => setSelectedUsers({}));
  };

  const data = query.data?.data;
  const deleteButton = (
    <Button
      key="delete"
      variant="error-outline"
      onClick={() => deleteMutation.mutate()}
      isLoading={deleteMutation.isLoading}
      icon={!isDeleteAllowed ? "lock" : ""}
      disabled={!isDeleteAllowed || !!userMembershipsQuery.data?.data.length}
    >
      Delete
    </Button>
  );

  return (
    <Page
      title={data?.name ?? UserGroupDetailsRoutePath.title}
      renderMeta={({ MetaSegments }) => (
        <MetaSegments
          values={[
            query.data?.data.updated_at
              ? `Last edited ${dateFormatter(new Date(query.data.data.updated_at ?? ""))}${
                  query.data.data.updated_by &&
                  metaUsersQueriesMap[query.data.data.updated_by]
                    ? ` by ${
                        metaUsersQueriesMap[query.data.data.updated_by ?? ""]
                          .name
                      }`
                    : ""
                }`
              : "",
            query.data?.data.created_at
              ? `Created ${dateFormatter(
                  new Date(query.data?.data.created_at ?? "")
                )}${
                  query.data.data.created_by &&
                  metaUsersQueriesMap[query.data.data.created_by]
                    ? ` by ${
                        metaUsersQueriesMap[query.data.data.created_by].name
                      }`
                    : ""
                }`
              : "",
          ]}
        />
      )}
      category={UserGroupDetailsRoutePath.previous?.title}
      breadcrumbs={breadcrumbs}
      isLoading={query.isLoading}
      error={query.error}
      onErrorRender={({ error }) => <PageErrorContent error={error} />}
      actionRow={[
        !isDeleteAllowed || !!userMembershipsQuery.data?.data.length ? (
          <Tooltip
            key="blocked-delete"
            title="Cannot be deleted"
            content={
              !isDeleteAllowed
                ? "You don't have the necessary permissions"
                : "You must remove all members from this group before you can delete it"
            }
          >
            {deleteButton}
          </Tooltip>
        ) : (
          deleteButton
        ),
        <Button
          key="edit"
          variant="outline"
          icon={!isEditAllowed ? "lock" : ""}
          disabled={!isEditAllowed}
          onClick={() => {
            navigate({
              pathname: UserGroupEditRoutePath.toLink({
                pathParams: { groupId },
              }),
            });
          }}
        >
          Edit
        </Button>,
      ]}
    >
      <div className={cx("container")}>
        <KeyValuePanel
          panelProps={{
            title: "Details",
          }}
          panelKeyValueListProps={{
            isLoading: query.isLoading,
          }}
          data={[
            {
              title: "Description",
              value: data?.description,
            },
            {
              title: "Category",
              value: groupCategoryQuery.data?.data.name ? (
                <RoutingLink
                  to={UserGroupCategoryDetailsRoutePath.toLink({
                    pathParams: {
                      categoryId: groupCategoryQuery.data.data.id,
                    },
                  })}
                >
                  {groupCategoryQuery.data.data.name}
                </RoutingLink>
              ) : (
                "-"
              ),
            },
          ]}
        />
        <FilterPanel
          storageKey={filterConfigStorageKey}
          filters={[
            {
              component: (
                <TextInput
                  name="search"
                  placeholder="Search Members..."
                  isInline
                />
              ),
            },
          ]}
        />
        <DataTablePanel<UserGroupMembershipType>
          storageKey={tableConfigStorageKey}
          panelProps={{
            title: "Members",
          }}
          actionRowWhenNoRowsSelected={[
            <Button
              key="manage"
              variant="outline"
              size="x-small"
              onClick={() => setIsManageUsersDialogOpen(true)}
              icon={!isEditAllowed ? "lock" : ""}
              disabled={!isEditAllowed}
            >
              Add Members
            </Button>,
          ]}
          actionRowWhenRowsSelected={[
            <Button
              key="remove"
              variant="error-outline"
              size="x-small"
              onClick={() => setIsUsersToRemoveDialogOpen(true)}
              icon={!isDeleteAllowed ? "lock" : ""}
              disabled={!isDeleteAllowed}
            >
              Remove Members
            </Button>,
          ]}
          dataTableProps={{
            items:
              userMembershipsQuery.data?.data.filter((membership) => {
                if (!filters.search) return true;

                return (
                  membership.user.name.toLowerCase().includes(filters.search) ||
                  membership.user.email.toLowerCase().includes(filters.search)
                );
              }) ?? [],
            headers: [
              {
                key: "user.name",
                label: "Name",
                renderComponent: ({ item }) => {
                  if (!item.user_id) return "-";

                  return (
                    <RoutingLink to={linkToUserDetailPage(item.user_id)}>
                      {item.user.name}
                    </RoutingLink>
                  );
                },
              },
              {
                key: "user.status",
                label: "Status",
                renderComponent: ({ item }) => {
                  if (!usersQueriesMap[item.user_id]) return "-";

                  return (
                    <DataTable.DataRow.PillCell
                      variant={
                        usersQueriesMap[item.user_id].status === "active"
                          ? "success"
                          : "default"
                      }
                      value={toStartCaseString(
                        usersQueriesMap[item.user_id].status
                      )}
                    />
                  );
                },
              },
              {
                key: "user.email",
                label: "Email",
              },
              {
                label: "Roles",
                key: "roles",
                tooltip: "The roles of a user determine their permissions",
                renderComponent: ({ item }) => {
                  const roles = usersRolesQueriesMap[item.user_id] as
                    | UserRoleAssignmentType[]
                    | undefined;

                  if (!roles?.length) {
                    return "-";
                  }

                  return roles.map((role) => (
                    <Pill
                      key={role.group_id}
                      variant="default"
                    >
                      <RoutingLink to={linkToRoleDetailPage(role.group_id)}>
                        {role.group.name ?? "n/a"}
                      </RoutingLink>
                    </Pill>
                  ));
                },
              },
            ],
            pagination: {
              page: 1,
              itemsPerPage: userMembershipsQuery.data?.data.length ?? 25,
              total: userMembershipsQuery.data?.data.length ?? 0,
            },
            selected: selectedUsers,
            onSelectionChange: (newSelection) => setSelectedUsers(newSelection),
            getItemId: (item) => `${item.user_group_id}:${item.user_id}`,
            emptyStateProps:
              filters && Object.keys(filters).length
                ? {
                    title: "No users found",
                    suggestion: "Try adjusting your filters",
                  }
                : {
                    title: "There are no users in this group",
                    suggestion: "Add some users by editing the group",
                  },
            getItemActions: ({ item }) => {
              return [
                {
                  label: "Remove Member",
                  buttonProps: {
                    icon: "minus-circle",
                    onClick: () => setUserToRemove(item.user_id),
                  },
                },
              ];
            },
          }}
        />
      </div>
      <AddMembersToUserGroupDialog
        isOpen={isManageUsersDialogOpen}
        onClose={() => setIsManageUsersDialogOpen(false)}
        currentMemberIds={
          userMembershipsQuery.data?.data.map(
            (membership) => membership.user_id
          ) ?? []
        }
        userGroupId={groupId}
      />
      <Dialog
        isOpen={!!userToRemove}
        onClose={() => setUserToRemove(null)}
        title="Remove member from group"
        actionRow={[
          <Button
            key="confirm"
            variant="error"
            onClick={() => {
              removeMemberMutation
                .mutateAsync(userToRemove)
                .then(() =>
                  addAlert({
                    variant: "success",
                    message: "Succesfully removed member from group",
                  })
                )
                .finally(() => setUserToRemove(null));
            }}
          >
            Yes
          </Button>,
        ]}
      >
        Are you sure you want to remove{" "}
        {usersQueriesMap[userToRemove ?? ""]?.name ?? "this user"} from the
        group?
      </Dialog>
      <Dialog
        isOpen={isUsersToRemoveDialogOpen}
        onClose={() => setIsUsersToRemoveDialogOpen(false)}
        title="Remove members from group"
        actionRow={[
          <Button
            key="confirm"
            variant="error"
            onClick={() => {
              setIsUsersToRemoveDialogOpen(false);
              handleRemoveSelectedMembers();
            }}
          >
            Yes
          </Button>,
        ]}
      >
        Are you sure you want to remove the selected members from the group?
      </Dialog>
    </Page>
  );
};
