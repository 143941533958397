import { parseCustomReportConfiguration } from "#batteries-included-components/Layouts/CustomReport/CustomReportConfigurationPanel/CustomReportConfigurationPanel.helpers";
import { CustomReportDetailLayout } from "#batteries-included-components/Layouts/CustomReport/CustomReportDetailLayout";
import {
  getCustomReportFilters,
  getCustomReportGroupBy,
} from "#batteries-included-components/Layouts/CustomReport/CustomReportDetailLayout.helpers";
import { CreateCustomReportModal } from "#batteries-included-components/Modals/CustomReports/CreateCustomReportModal";
import { useBreadcrumbs } from "#routers/breadcrumbsHelper";
import { useNavigate, useParams } from "#routers/hooks";
import { REPORTS_BREADCRUMB } from "#routes/reports";
import { CUSTOM_REPORT_LIST_BREADCRUMB } from "#routes/reports/custom-reports";
import { linkToCustomReportDetail } from "#routes/reports/custom-reports/[reportVariant]/[reportId]/detail";
import {
  CREATE_CUSTOM_REPORT_BREADCRUMB,
  CreateCustomReportParamType,
} from "#routes/reports/custom-reports/[reportVariant]/create/index";
import {
  CustomStorageKeyTypes,
  useCustomStorageKey,
  useStorageKey,
} from "#src/hooks/useStorageKey";
import { Button, Page, useFilters } from "@validereinc/common-components";
import { getFilterObject } from "@validereinc/domain";
import { toStartCaseString } from "@validereinc/utilities";
import React, { useEffect, useState } from "react";

export const CreateCustomReportPage = () => {
  const navigate = useNavigate();
  const { reportVariant } = useParams<CreateCustomReportParamType>();
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const storageKeys = useStorageKey("custom-report-detail");
  const configStorageKey = useCustomStorageKey(
    "custom-report-detail",
    CustomStorageKeyTypes.CONFIG
  );

  const [filters] = useFilters(storageKeys.filterConfigStorageKey);
  const [configuration, setConfiguration] = useFilters(configStorageKey);

  const { rows } = parseCustomReportConfiguration(configuration);

  useEffect(() => {
    setConfiguration({});
  }, []);

  const filter = getFilterObject(
    getCustomReportFilters(reportVariant, configuration, filters)
  );

  return (
    <>
      <Page
        category={`${toStartCaseString(reportVariant)} Custom Report`}
        title={`Unsaved ${toStartCaseString(reportVariant)} Report`}
        actionRow={[
          <Button
            key="save-custom-report"
            variant="primary"
            onClick={() => setIsCreateModalOpen(true)}
          >
            Save Report
          </Button>,
        ]}
        breadcrumbs={useBreadcrumbs([
          REPORTS_BREADCRUMB,
          CUSTOM_REPORT_LIST_BREADCRUMB,
          CREATE_CUSTOM_REPORT_BREADCRUMB,
        ])}
      >
        <CustomReportDetailLayout
          configStorageKey={configStorageKey}
          reportVariant={reportVariant}
          {...storageKeys}
        />
      </Page>
      <CreateCustomReportModal
        variant={reportVariant}
        isOpen={isCreateModalOpen}
        onClose={() => {
          setIsCreateModalOpen(false);
        }}
        onSave={(id) => {
          navigate({ pathname: linkToCustomReportDetail(reportVariant, id) });
        }}
        filter={filter}
        groupBy={getCustomReportGroupBy(reportVariant, rows)}
      />
    </>
  );
};
