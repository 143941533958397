import React from "react";

import {
  Button,
  EmptyState,
  Icon,
  MetricTileGrid,
  Panel,
} from "@validereinc/common-components";

import { EquipmentType } from "@validereinc/domain";

export const SelectedEquipmentPanel = ({
  selectedEquipment,
  setSelectedEquipment,
  suggestion,
  isFluidY = false,
}: {
  selectedEquipment: Record<string, EquipmentType>;
  setSelectedEquipment: (newSelection: Record<string, EquipmentType>) => void;
  suggestion?: string;
  isFluidY?: boolean;
}) => {
  const deselectEquipment = (equipmentId: string) => {
    const { [equipmentId]: _, ...newSelection } = selectedEquipment;
    setSelectedEquipment?.(newSelection);
  };

  return (
    <Panel
      title="Selected Equipment"
      isFluidY={isFluidY}
    >
      {Object.values(selectedEquipment).length ? (
        <MetricTileGrid
          size="small"
          orientation="vertical"
          data={Object.values(selectedEquipment).map(({ id, name }) => ({
            title: "Name",
            value: name,
            actions: [
              <Button
                key="remove-origin-button"
                onClick={() => deselectEquipment(id)}
                variant="text"
                icon="minus-circle"
              />,
            ],
          }))}
        />
      ) : (
        <EmptyState
          title="No Equipment Selected"
          icon={<Icon variant="stack" />}
          suggestion={suggestion ?? "Select equipment to add to event."}
        />
      )}
    </Panel>
  );
};
