import React from "react";

import { Button, Panel, StorageKeys } from "@validereinc/common-components";

import { useFlowDetailFlow } from "#routes/organization/flows/[flowId]/detail/FlowDetailPage.helpers";
import { useNavigate } from "#src/Routers/hooks";
import { useParams } from "react-router";
import { FlowEditRoute } from "../edit";
import { FlowEquipmentTable } from "./FlowEquipmentTable";
import { FlowFacilityTable } from "./FlowFacilityTable";
import { FlowFlowsTable } from "./FlowFlowsTable";

export const FlowDownstreamPanel = ({
  filterConfigStorageKey,
}: Pick<StorageKeys, "filterConfigStorageKey">) => {
  const { data: flow } = useFlowDetailFlow({ filterConfigStorageKey });
  const { flowId } = useParams<{ flowId: string }>();
  const navigate = useNavigate();

  return (
    <Panel
      title="Destination"
      actionRow={
        <Button
          onClick={() => {
            navigate(FlowEditRoute.toLinkParts({ pathParams: { flowId } }));
          }}
        >
          Edit
        </Button>
      }
    >
      {flow?.downstream_facility ? (
        <FlowFacilityTable
          filterConfigStorageKey={filterConfigStorageKey}
          variant="downstream"
        />
      ) : null}

      {flow?.downstream_equipment ? (
        <FlowEquipmentTable
          filterConfigStorageKey={filterConfigStorageKey}
          variant="downstream"
        />
      ) : null}

      {flow?.downstream_flows?.length ? (
        <FlowFlowsTable
          filterConfigStorageKey={filterConfigStorageKey}
          variant="downstream"
        />
      ) : null}
    </Panel>
  );
};
