import config from "#config";
import { useEmissionsReportDetail } from "#hooks/useEmissionsReports";
import { havePermission } from "#redux/reducers/permissions";
import { getBreadcrumbsObject } from "#routers/breadcrumbsHelper";
import history from "#routers/history";
import { linkToEditEmissionReports } from "#routers/links";
import { getUTCTimeString } from "#utils/timeFormatter";
import { Button, Page } from "@validereinc/common-components";
import classNames from "classnames/bind";
import moment from "moment";
import * as PropTypes from "prop-types";
import React, { useMemo } from "react";
import { connect } from "react-redux";
import PeriodSelect from "../../../Production/PeriodSelect";
import styles from "./EmissionReportDetail.module.scss";
import FacilitiesPanel from "./Facilities/FacilitiesPanel";
import SummaryPanel from "./Summary/SummaryPanel";

const cx = classNames.bind(styles);

export const START_OF_DEFAULT_RANGE = 6;

const mapStateToProps = ({ permissions }) => ({
  isValidereUser: havePermission(permissions)(
    "esg:emissions_reports",
    "validere"
  ),
});

const THREE_MONTH_AGO = moment()
  .subtract(START_OF_DEFAULT_RANGE, "months")
  .startOf("month")
  .startOf("day");

const LAST_DAY_OF_CURRENT_MONTH = moment().endOf("month").startOf("day");

const DEFAULT_DATE_RANGE = {
  periodFrom: getUTCTimeString(THREE_MONTH_AGO, config.DATE_FORMAT),
  periodTo: getUTCTimeString(LAST_DAY_OF_CURRENT_MONTH, config.DATE_FORMAT),
};

const EmissionReportDetail = ({ match, breadcrumbs, isValidereUser }) => {
  const emissionReportId = match.params.id ?? null;
  const [emissionDetail, recordDetailLoadingState] = useEmissionsReportDetail(
    emissionReportId,
    DEFAULT_DATE_RANGE
  );

  const actionRow = isValidereUser ? (
    <Button
      variant="primary"
      onClick={() => history.push(linkToEditEmissionReports(emissionReportId))}
    >
      Edit
    </Button>
  ) : null;

  const pageBreadcrumbs = useMemo(() => {
    const pageBreadcrumbs = getBreadcrumbsObject(breadcrumbs, match.params);

    if (pageBreadcrumbs[1] && emissionDetail?.name) {
      pageBreadcrumbs[1] = {
        ...pageBreadcrumbs[1],
        title: emissionDetail?.name,
      };
    }

    return pageBreadcrumbs;
  }, [breadcrumbs, match.params, emissionDetail]);

  return (
    <Page
      title={emissionDetail.name ?? "-"}
      breadcrumbs={pageBreadcrumbs}
      isLoading={recordDetailLoadingState !== "loaded"}
      actionRow={actionRow}
    >
      <div className={cx("emissionDropdownContainer")}>
        <PeriodSelect defaultDateRange={DEFAULT_DATE_RANGE} />
      </div>

      {emissionDetail?.sales_stream_ids?.length > 0 && (
        <SummaryPanel
          emissionReportId={emissionReportId}
          defaultDateRange={DEFAULT_DATE_RANGE}
        />
      )}

      <FacilitiesPanel
        emissionReportId={emissionReportId}
        defaultDateRange={DEFAULT_DATE_RANGE}
      />
    </Page>
  );
};

EmissionReportDetail.propTypes = {
  breadcrumbs: PropTypes.array.isRequired,
  match: PropTypes.object.isRequired,
  height: PropTypes.number.isRequired,
  isValidereUser: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(EmissionReportDetail);
