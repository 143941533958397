import { ensureStreamListIsFetched, fetchSiteList } from "#redux/actions/index";
import { filterByUsedBy } from "#redux/reducers/measurements";
import { getBreadcrumbsObject } from "#routers/breadcrumbsHelper";
import { linkToCreateWorkflow } from "#routers/links";
import WorkflowService from "#services/WorkflowService";
import history from "#src/Routers/history";
import {
  Button,
  Form,
  Page,
  Panel,
  useAlert,
  useForm,
} from "@validereinc/common-components";
import classNames from "classnames/bind";
import find from "lodash/find";
import moment from "moment";
import * as PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import ArchiveConfirmationModal from "./ArchiveConfirmationModal";
import "./EditWorkflow.scss";
import { getFrequencyDetails, getOffSpec } from "./EditWorkflowHelper";
import UpdateConfirmationModal from "./UpdateConfirmationModal";
import WorkflowForm from "./WorkflowForm";
import styles from "./WorkflowForm.module.scss";
import {
  DEFAULT_INPUTS,
  formatTimezoneName,
  prepareWorkflow,
  TestTypeOptions,
  WEEK,
} from "./WorkflowFormHelpers";
import WorkflowPreview from "./WorkflowPreview";

const cx = classNames.bind(styles);

const UPDATE_WORKFLOW_MODAL_KEY = "UPDATE_WORKFLOW";
const ARCHIVE_WORKFLOW_MODAL_KEY = "ARCHIVE WORKFLOW";

const mapStateToProps = ({ streams, sites, measurements }) => {
  return {
    streams: streams.data?.toJS() ?? [],
    sites: sites.data?.toJS() ?? [],
    fieldLabTypes: measurements.measurementTypesList
      .filter(filterByUsedBy("field"))
      .map((measurementType) => measurementType.name),
    thirdPartyTypes: measurements.measurementTypesList
      .filter(filterByUsedBy("third_party"))
      .map((measurementType) => measurementType.name),
  };
};

const mapDispatchToProps = {
  ensureStreamListIsFetched,
  fetchSiteList,
};

const EditWorkflow = ({
  breadcrumbs,
  match,
  fieldLabTypes,
  thirdPartyTypes,
  ensureStreamListIsFetched,
  fetchSiteList,
  streams,
  sites,
}) => {
  const [formState, setFormState] = useState("enabled");
  const [selectedStreamList, setSelectedStreamList] = useState([]);
  const [selectedSitesList, setSelectedSitesList] = useState([]);
  const { addAlert } = useAlert();
  const [modalKey, setModalKey] = useState(null);

  const workflowId = match.params.workflowId;

  const form = useForm({
    defaultValues: DEFAULT_INPUTS,
  });

  const getWorkflowDetail = (workflowId) => {
    setFormState("loading");

    WorkflowService.getWorkflowDetail(workflowId)
      .then(({ data }) => {
        const frequencyDetails = getFrequencyDetails(data);

        const offspec = getOffSpec(data);

        const alert = [];
        Object.entries(data.alert_settings).forEach(([key, value]) => {
          if (value) {
            alert.push(key);
          }
        });

        form.reset({
          name: data.name,
          startDay: moment(data.start_time_utc_epoch * 1000).toDate(),
          timezone: formatTimezoneName(data.timezone),
          ...frequencyDetails,
          occurrences: data.occurrences_left,
          endOn: data.occurrences_left ? "After" : "Never",
          repeats_per_scheduling_period: data.repeats_per_scheduling_period,
          testType:
            find(TestTypeOptions, { id: data.test_type }) ?? TestTypeOptions[0],
          siteList: data.sites,
          testsRequired: data.tests_required,
          offspec,
          alert,
          alertSettings: data.alert_settings,
        });

        setSelectedStreamList(data.stream_ids);
        setSelectedSitesList(data.sites);

        setFormState("enabled");
      })
      .catch(() => {
        setFormState("disabled");
      });
  };

  const handleUpdate = (updateActive) => {
    WorkflowService.updateWorkflow(
      prepareWorkflow(inputs, updateActive, workflowId)
    )
      .then(() => {
        addAlert({
          variant: "success",
          message: "Your workflow was updated.",
        });
        setModalKey(null);
      })
      .finally(() => {
        setFormState("enabled");
      });
  };

  const handleArchive = () => {
    WorkflowService.archiveWorkflow(match.params.workflowId)
      .then(() => {
        addAlert({
          variant: "success",
          message: "A workflow has been successfully archived.",
        });
        setFormState("disabled");
      })
      .catch(() => {
        setFormState("enabled");
      });
  };

  const onDuplicateWorkflowClick = () => {
    history.push({
      pathname: linkToCreateWorkflow(),
      state: { ...inputs },
    });
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    ensureStreamListIsFetched();
    fetchSiteList();

    if (workflowId) {
      getWorkflowDetail(workflowId);
    }
  }, []);

  const inputs = form.watch();

  const requireTestsRequired = inputs.testType?.id !== "manual";

  const pageBreadcrumbs = getBreadcrumbsObject(breadcrumbs, match.params);

  const measurementTypes =
    inputs.testType?.id === "field"
      ? fieldLabTypes
      : inputs.testType?.id === "third_party_lab"
        ? thirdPartyTypes
        : [];

  const scheduleInput = useMemo(() => {
    const repeatsDay = WEEK.map((day, index) =>
      inputs.repeatsDay?.includes(day) ? index : undefined
    ).filter((d) => d !== undefined);

    return {
      startDay: inputs.startDay,
      repeatsDay: repeatsDay,
      frequencySelected: inputs.frequencyType?.id,
      repeats: inputs.repeats,
      timezone: inputs.timezone,
      repeatNumTimesPerDay: inputs.repeatNumTimesPerDay,
      occurrences: inputs.occurrences,
    };
  }, [inputs]);

  return (
    <Page
      title="Edit Workflow"
      breadcrumbs={pageBreadcrumbs}
    >
      <div className={cx("container")}>
        <Panel className={cx("panel")}>
          <Form
            onSubmit={handleUpdate}
            {...form}
          >
            <WorkflowForm
              form={form}
              inputs={inputs}
              streamList={streams}
              siteList={sites}
              measurementTypes={measurementTypes}
              selectedStreamList={selectedStreamList}
              selectedSitesList={selectedSitesList}
              disabled={formState !== "enabled"}
              requireTestsRequired={requireTestsRequired}
            />

            <div className="buttonsRow">
              <Button
                variant="error"
                onClick={() => setModalKey(ARCHIVE_WORKFLOW_MODAL_KEY)}
                disabled={formState !== "enabled"}
              >
                archive workflow
              </Button>

              <Button
                onClick={onDuplicateWorkflowClick}
                disabled={formState !== "enabled"}
              >
                duplicate workflow
              </Button>

              <Button
                variant="primary"
                onClick={() => setModalKey(UPDATE_WORKFLOW_MODAL_KEY)}
                disabled={formState !== "enabled"}
                isLoading={formState === "loading"}
              >
                update workflow
              </Button>
            </div>

            {modalKey === UPDATE_WORKFLOW_MODAL_KEY && (
              <UpdateConfirmationModal
                show={true}
                onClose={() => setModalKey(null)}
                name={inputs.name}
                dirtyFields={form.formState.dirtyFields}
                onClick={handleUpdate}
                onUpdateCurrentAndFutureClick={() => handleUpdate(true)}
                onUpdateFutureClick={() => handleUpdate(false)}
              />
            )}

            {modalKey === ARCHIVE_WORKFLOW_MODAL_KEY && (
              <ArchiveConfirmationModal
                show={true}
                onClose={() => setModalKey(null)}
                name={inputs.name}
                onClick={handleArchive}
              />
            )}
          </Form>
        </Panel>

        <div>
          <WorkflowPreview
            initialMonth={inputs.startDay}
            scheduleInput={scheduleInput}
          />
        </div>
      </div>
    </Page>
  );
};

EditWorkflow.propTypes = {
  fieldLabTypes: PropTypes.array,
  thirdPartyTypes: PropTypes.array,
  breadcrumbs: PropTypes.array,
  match: PropTypes.object,
  ensureStreamListIsFetched: PropTypes.func.isRequired,
  fetchSiteList: PropTypes.func.isRequired,
  streams: PropTypes.array.isRequired,
  sites: PropTypes.array.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditWorkflow);
