const displayProperties = () => [
  {
    label: "Water",
    key: "Water",
  },
  {
    label: "Drift",
    key: "drift",
    notMeasurement: true,
  },
];

export default displayProperties;
