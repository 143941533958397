import {
  EstimationMethodDetailContextProvider,
  useEstimationMethodDetailContext,
} from "#batteries-included-components/Layouts/EstimationMethod/Detail";
import { EstimationMethodCalculationsTab } from "#batteries-included-components/Tabs/EstimationMethod/CalculationsTab";
import { ConfigurationTab } from "#batteries-included-components/Tabs/EstimationMethod/ConfigurationTab/ConfigurationTab";
import { EstimationMethodOverviewTab } from "#batteries-included-components/Tabs/EstimationMethod/OverviewTab";
import { EstimationMethodRecordsTab } from "#batteries-included-components/Tabs/EstimationMethod/RecordsTab";
import { MUTATION_STATUS } from "#constants";
import { useDeleteEstimationMethod } from "#hooks/useEstimationMethod";
import { useNavigate, useParams, useSearchParams } from "#routers/hooks";
import { EditEstimationMethodDialog } from "#routes/organization/equipment/[equipmentId]/estimation-method/[methodId]/detail/EditEstimationDialog";
import {
  FlowDetailParamsType,
  FlowDetailRoute,
} from "#routes/organization/flows/[flowId]/detail";
import { useFlowDetailFlow } from "#routes/organization/flows/[flowId]/detail/FlowDetailPage.helpers";
import {
  FlowEstimationMethodDetailParamsType,
  FlowEstimationMethodDetailRoute,
} from "#routes/organization/flows/[flowId]/detail/estimation-details/[estimationMethodId]/index";
import { FlowEstimationMethodResultDetailRoute } from "#routes/organization/flows/[flowId]/detail/estimation-details/[estimationMethodId]/result/[yearMonth]";
import { useStorageKey } from "#src/hooks/useStorageKey";
import { useBreadcrumbsFromRoute } from "#utils/route";
import { useQuery } from "@tanstack/react-query";
import {
  Button,
  Dialog,
  Link,
  Page,
  Tab,
} from "@validereinc/common-components";
import {
  AssetType,
  ReportingGroupDomain,
  type FlowType,
} from "@validereinc/domain";
import React, { useEffect, useState } from "react";

export const FlowEstimationMethodDetailPageContent = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { flowId } = useParams<FlowDetailParamsType>();
  const { data: flow } = useFlowDetailFlow();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteEstimationMethod, deleteEstimationMethodStatus] =
    useDeleteEstimationMethod();
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const reportingGroupsQuery = useQuery({
    queryKey: ["reportingGroups"],
    queryFn: ReportingGroupDomain.getList,
  });

  const reportingGroups = reportingGroupsQuery?.data?.data ?? [];

  const { method, calculator, calculatorVersion } =
    useEstimationMethodDetailContext<FlowType>();

  const methodDetail = method?.data;
  const calculatorDetail = calculator?.data;
  const [breadcrumbs] = useBreadcrumbsFromRoute(
    FlowEstimationMethodDetailRoute,
    {
      detail: { title: flow?.name },
      estimationMethodId: { title: methodDetail?.name },
    }
  );

  const handleDelete = () => {
    if (!methodDetail?.id) {
      return;
    }
    deleteEstimationMethod(methodDetail.id, AssetType.FLOW);
    navigate(
      FlowDetailRoute.toLinkParts({
        pathParams: { flowId },
        queryParams: { tab: "estimation_methods" },
      })
    );
  };
  const detailsPanelData = [
    {
      title: "Flow",
      value: (
        <Link
          onClick={() => {
            navigate(
              FlowDetailRoute.toLinkParts({
                pathParams: { flowId },
              })
            );
          }}
          label={flow?.name ?? "-"}
        />
      ),
    },
    {
      title: "Library",
      value: methodDetail?.analytics_library_id ?? calculatorDetail?.library,
    },
    {
      title: "Calculator Name",
      value: calculatorVersion?.title,
    },
  ];

  const pageActionRow = (
    <>
      <Button
        variant="error-outline"
        onClick={() => setIsDeleteModalOpen(true)}
        isLoading={deleteEstimationMethodStatus === MUTATION_STATUS.LOADING}
        disabled={!methodDetail?.id || !methodDetail?.name}
      >
        Delete
      </Button>
      <Button
        variant="outline"
        onClick={() => setIsEditDialogOpen(true)}
        isLoading={deleteEstimationMethodStatus === MUTATION_STATUS.LOADING}
        disabled={!methodDetail?.id || !methodDetail?.name}
      >
        Edit
      </Button>
    </>
  );

  const onActiveTabKeyChange = (tabKey: string) => {
    setSearchParams({ ...searchParams, tab: tabKey });
  };

  const onSavedResultRecordClick = (
    estimationMethodId: string,
    flowId: string,
    yearMonth: string
  ) =>
    navigate(
      FlowEstimationMethodResultDetailRoute.toLinkParts({
        pathParams: {
          flowId,
          estimationMethodId,
          yearMonth,
        },
      })
    );

  useEffect(() => {
    if (!searchParams?.tab) {
      onActiveTabKeyChange("overview");
    }
  }, []);

  return (
    <>
      <Page
        category="Estimation Method"
        title={methodDetail?.name}
        isLoading={!flow || !methodDetail}
        breadcrumbs={breadcrumbs}
        activeTabKey={searchParams?.tab}
        onActiveTabKeyChange={onActiveTabKeyChange}
        actionRow={pageActionRow}
      >
        <Tab
          tabKey="overview"
          title="Overview"
        >
          <EstimationMethodOverviewTab
            detailsPanelProps={{
              data: detailsPanelData,
              isLoading: false,
            }}
          />
        </Tab>

        <Tab
          tabKey="configuration"
          title="Configuration"
        >
          <ConfigurationTab />
        </Tab>

        <Tab
          tabKey="calculations"
          title="Calculations"
        >
          <EstimationMethodCalculationsTab
            onClickSavedResultRecord={onSavedResultRecordClick}
          />
        </Tab>

        <Tab
          tabKey="results"
          title="Results"
        >
          <EstimationMethodRecordsTab onClickRow={onSavedResultRecordClick} />
        </Tab>
      </Page>
      <Dialog
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        title={`Delete "${methodDetail?.name ?? "this estimation method"}"`}
        actionRow={[
          <Button
            key="delete-dialog-action"
            onClick={handleDelete}
            variant="error"
          >
            Delete
          </Button>,
        ]}
      >
        <div>
          Are you sure you want to delete this estimation method? This action
          cannot be undone.
        </div>
      </Dialog>

      <EditEstimationMethodDialog
        reportingGroups={reportingGroups}
        method={methodDetail}
        entityType={AssetType.FLOW}
        onClose={() => setIsEditDialogOpen(false)}
        onSubmit={() => method?.refetch?.()}
        isEditDialogOpen={isEditDialogOpen}
      />
    </>
  );
};

export const FlowEstimationMethodDetailPage = () => {
  const { estimationMethodId } =
    useParams<FlowEstimationMethodDetailParamsType>();
  const { filterConfigStorageKey } = useStorageKey("flow-detail-filters");
  const { data: flow } = useFlowDetailFlow({ filterConfigStorageKey });
  return (
    <EstimationMethodDetailContextProvider
      estimationMethodId={estimationMethodId}
      entityType={AssetType.FLOW}
      entityId={flow?.id}
      entityDetail={flow}
      entityFetchStatus={"loading"}
    >
      <FlowEstimationMethodDetailPageContent />
    </EstimationMethodDetailContextProvider>
  );
};
