/**
 * Check if a value is a number just like Number.isFinite, except accept strings that can be co-erced into numbers too
 * @param value some unknown value
 * @returns true if the value is a number, false otherwise
 */
export const isNumeric = (value: unknown) => {
  if (Number.isFinite(value)) {
    return true;
  }

  // Only a number or a string works
  if (typeof value !== "string") {
    return false;
  }

  return (
    !Number.isNaN(Number(value)) &&
    !Number.isNaN(Number.parseFloat(value.trim()))
  );
};

/**
 * Convert grams straight from the back-end to kilo-grams
 * @param grams usually a number or a string co-ercable to a number
 * @returns the provided grams in kilo-grams
 */
export const gramsToKg = (grams: any) => {
  if (!isNumeric(grams)) {
    return NaN;
  }

  return grams / 1000;
};

/**
 * Convert grams straight from the back-end to metric tonnes
 * @param grams usually a number or a string co-ercable to a number
 * @returns the provided grams in tonnes
 */
export const gramsToMt = (grams: any) => {
  if (!isNumeric(grams)) {
    return NaN;
  }

  return grams / 1000000;
};
