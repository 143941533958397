import React from "react";
import * as PropTypes from "prop-types";
import { Button } from "react-bootstrap";
import FontAwesome from "react-fontawesome";
import "./AnnotationStage.css";

const Checkmark = ({ isComplete }) => {
  let name, style, classModifier;
  if (isComplete === null) {
    name = "flask";
    style = { visibility: "hidden" };
    classModifier = "inactive";
  } else if (isComplete) {
    name = "check";
    style = { color: "green" };
    classModifier = "complete";
  } else {
    name = "times";
    style = { color: "red" };
    classModifier = "incomplete";
  }

  return (
    <FontAwesome
      className={`annotationStage__checkmark ${classModifier}`}
      name={name}
      fixedWidth
      style={style}
    />
  );
};
Checkmark.propTypes = {
  isComplete: PropTypes.bool,
};

const AnnotationStage = ({
  goToNextStage,
  isActive,
  isComplete = null,
  children = null,
}) => {
  return (
    <li>
      <div className="annotationStage__flexContainer">
        {<Checkmark isComplete={isComplete} />}
        {children}
        {isActive && isComplete && (
          <Button
            className="annotationStage__nextButton"
            bsStyle="success"
            onClick={goToNextStage}
            bsSize="small"
          >
            Next
          </Button>
        )}
      </div>
    </li>
  );
};

AnnotationStage.propTypes = {
  goToNextStage: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
  isComplete: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default AnnotationStage;
