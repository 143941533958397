import { z } from "zod";

export const WorkingFilterSchema = z
  .record(z.union([z.string(), z.array(z.string()), z.number(), z.boolean()]))
  .describe("For use in the front-end with raw filter key value pairs");

/**
 * @see https://github.com/ValidereInc/Carbon-Hub-Backend/blob/main/lambda/layer/validere_lib/src/models/helpers/filtering.ts#L559-L680
 */
export const NodeAPICoreFilterSchema = z
  .record(
    z.union([
      z.string(),
      z.number(),
      z.boolean(),
      z.object({ $like: z.string() }),
      z.object({ $in: z.array(z.string()) }),
      z.object({ $gte: z.union([z.string(), z.number()]) }),
      z.object({ $lte: z.union([z.string(), z.number()]) }),
      z.object({ $gt: z.union([z.string(), z.number()]) }),
      z.object({ $lt: z.union([z.string(), z.number()]) }),
    ])
  )
  .describe(
    "For use against the Node API when a filter object can be provided"
  );

/**
 * @see https://github.com/ValidereInc/Carbon-Hub-Backend/blob/main/lambda/layer/validere_lib/src/models/helpers/filtering.ts#L559-L680
 */
export const NodeAPIFilterSchema = z
  .union([
    z.object({
      $or: z.array(NodeAPICoreFilterSchema).optional(),
      $and: z.array(NodeAPICoreFilterSchema).optional(),
    }),
    NodeAPICoreFilterSchema,
  ])
  .describe(
    "For use against the Node API when a filter object can be provided, with full functionality including and'ing and or'ing"
  );

export const NodeAPICoreSavedFilterSchema = z
  .record(
    z.union([
      z.string(),
      z.object({ $like: z.string() }),
      z.object({ $in: z.array(z.string()) }),
    ])
  )
  .describe("Used in the Node API to represent saved filter values");

export const NodeAPISavedFilterSchema = z
  .union([
    z.object({
      $or: z.array(NodeAPICoreSavedFilterSchema).optional(),
    }),
    NodeAPICoreSavedFilterSchema,
  ])
  .describe("Used in the Node API to represent saved filters");

export type WorkingFilterType = z.infer<typeof WorkingFilterSchema>;
export type NodeAPICoreFilterType = z.infer<typeof NodeAPICoreFilterSchema>;
export type NodeAPIFilterType = z.infer<typeof NodeAPIFilterSchema>;
export type NodeAPICoreSavedFilterType = z.infer<
  typeof NodeAPICoreSavedFilterSchema
>;
export type NodeAPISavedFilterType = z.infer<typeof NodeAPISavedFilterSchema>;
