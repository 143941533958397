import { NetworkAssetsStep } from "#batteries-included-components/Forms/NetworkForm/NetworkAssetsStep";
import { NetworkDetailsStep } from "#batteries-included-components/Forms/NetworkForm/NetworkDetailsStep";
import { useCreateNetwork } from "#hooks/adapters/useNetworks";
import { useNavigate } from "#routers/hooks";
import { NetworkListRoute } from "#routes/organization/networks";
import { NetworkDetailRoute } from "#routes/organization/networks/[networkId]/detail";
import { NetworkCreateRoute } from "#routes/organization/networks/create/index";
import {
  MultiStepFormProvider,
  useMultiStepFormContext,
} from "#src/hooks/useMultiStepForm";
import { useBreadcrumbsFromRoute } from "#utils/route";
import { Page } from "@validereinc/common-components";
import type { AssetGroupType, CreateNetworkType } from "@validereinc/domain";
import React from "react";

export const CreateNetworkPageContent = () => {
  const { stepper, footer } = useMultiStepFormContext();

  const [breadcrumbs] = useBreadcrumbsFromRoute(NetworkCreateRoute);

  return (
    <Page
      title={NetworkCreateRoute.title}
      category={NetworkCreateRoute.previous?.title}
      breadcrumbs={breadcrumbs}
      actionRow={stepper}
      footer={footer}
    >
      <NetworkDetailsStep />
      <NetworkAssetsStep />
    </Page>
  );
};

export const CreateNetworkPage = () => {
  const navigate = useNavigate();

  const onCancel = () => navigate({ pathname: NetworkListRoute.toLink() });

  const createNetwork = useCreateNetwork({
    onSuccess: (data: AssetGroupType) =>
      navigate({
        pathname: NetworkDetailRoute.toLink({
          pathParams: { networkId: data?.id },
        }),
      }),
  });

  const onSubmit = (values: Array<Partial<CreateNetworkType>>) => {
    createNetwork.mutate(
      values.reduce((total, current) => ({ ...total, ...current }), {})
    );
  };

  return (
    <MultiStepFormProvider
      steps={[
        {
          label: "Details",
        },
        {
          label: "Assets",
        },
      ]}
      onCancel={onCancel}
      onSubmit={onSubmit}
      onSubmitMutationState={{
        isSubmitting: createNetwork.isLoading,
      }}
      submissionActionText="Create"
    >
      <CreateNetworkPageContent />
    </MultiStepFormProvider>
  );
};
