import { toFlattenedObject } from "@validereinc/utilities";
import { UserGroupCategoryType } from "../schemas";
import {
  fetchAndCollate,
  FilterObjectType,
  getFilterObject,
  GetListRequestType,
  GetListResponseType,
  ResourceServiceType,
  ResponseErrorType,
  ResponseStatusType,
  SortDirection,
} from "../util";
import { restAPI } from "./api";

export const UserGroupCategoriesAdapter: ResourceServiceType<UserGroupCategoryType> =
  {
    /**
     * Search user group categories
     * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/group_categories/search_group_categories
     */
    getList: async ({
      filters = {},
      pageSize,
      page,
      sortBy = "name",
      sortDirection = SortDirection.ASCENDING,
    }: GetListRequestType<FilterObjectType<UserGroupCategoryType>>) => {
      return fetchAndCollate(
        ({ page, pageSize }) =>
          restAPI.nodeAPI.POST<GetListResponseType<UserGroupCategoryType>>({
            endpoint: "/group_categories/search",
            body: {
              page,
              page_size: pageSize,
              sort_by: sortBy,
              sort_direction: sortDirection,
              filter: getFilterObject(toFlattenedObject(filters)),
            },
          }),
        page,
        pageSize
      );
    },
    /**
     * Get a single user group category
     * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/group_categories/get_group_category
     */
    getOne: async ({ id }) => {
      const data = await restAPI.nodeAPI.GET<UserGroupCategoryType>({
        endpoint: `/group_categories/${id}`,
      });

      return {
        data,
      };
    },

    /**
     * Update a user group category
     * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/group_categories/put_group_category
     */
    updateOne: async ({ id, data }) => {
      const resp = await restAPI.nodeAPI.PUT<UserGroupCategoryType>({
        endpoint: `/group_categories/${id}`,
        body: data,
      });
      return { data: resp };
    },

    /**
     * Create a user group category
     * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/group_categories/create_group_category
     */
    createOne: async (props) => {
      const resp = await restAPI.nodeAPI.POST<UserGroupCategoryType>({
        endpoint: "/group_categories",
        body: props.data,
      });

      return { data: resp };
    },

    /**
     * Delete a user group category
     * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/group_categories/delete_group_category
     */
    deleteOne: ({ id }) =>
      restAPI.nodeAPI.DELETE<ResponseStatusType | ResponseErrorType>({
        endpoint: `/group_categories/${id}`,
      }),
    getMany: () =>
      Promise.reject({
        success: false,
        error: "Method not implemented",
      }),
    updateMany: () =>
      Promise.reject({
        success: false,
        error: "Method not implemented",
      }),
    createMany: () =>
      Promise.reject({
        success: false,
        error: "Method not implemented",
      }),
    deleteMany: () =>
      Promise.reject({
        success: false,
        error: "Method not implemented",
      }),
  };
