import { fetchAdjustmentFactors } from "#redux/actions/adjustmentFactors";
import {
  ensureStreamListIsFetched,
  fetchStreamList,
} from "#redux/actions/index";
import { havePermission } from "#redux/reducers/permissions";
import { Button, Page, Panel } from "@validereinc/common-components";
import filter from "lodash/filter";
import intersectionWith from "lodash/intersectionWith";
import * as PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import RecordOfVolumeService from "../../Services/RecordOfVolumeService";
import DailyVolumeModal from "./DailyVolume/DailyVolumeModal";
import EditStreamAdjustmentFactorSettings from "./EditStreamAdjustmentFactorSettings";
import "./RecordOfVolume.scss";
import RecordOfVolumeTable from "./RecordOfVolumeTable";

const TAB_OFFSET = 98;

const ConditionallyRenderedPage = ({ children, isTab, breadcrumbs }) =>
  !isTab ? (
    <Page
      title="Record Of Volume"
      breadcrumbs={breadcrumbs}
    >
      {children}
    </Page>
  ) : (
    children
  );

ConditionallyRenderedPage.propTypes = {
  children: PropTypes.node.isRequired,
  isTab: PropTypes.bool.isRequired,
  breadcrumbs: PropTypes.array.isRequired,
};

const mapStateToProps = ({ permissions, streams, adjustmentFactors }) => {
  return {
    haveWritePermission: havePermission(permissions)(
      "gas:accounting.records_of_volume",
      "write"
    ),
    streams: streams?.data.toJS() ?? [],
    adjustmentFactors: adjustmentFactors?.data.toJS() ?? [],
    isLoading: adjustmentFactors.isRequestInProgress,
  };
};

const mapDispatchToProps = {
  ensureStreamListIsFetched,
  fetchStreamList,
  fetchAdjustmentFactors,
};

const RecordOfVolume = (props) => {
  const [editStreamModalState, setEditStreamModalState] = useState(null);
  const [checkedStreams, setCheckedStreams] = useState([]);

  const [dailyVolumeModalState, setDailyVolumeModalState] = useState(false);
  const [dailyVolumeStream, setDailyVolumeStream] = useState(null);

  const [streamAdjustmentConfigs, setStreamAdjustmentConfigs] = useState([]);

  const onStreamChecked = (selectedStreams) => {
    setCheckedStreams(selectedStreams);
  };

  const onEditStreamModalClose = () => {
    setEditStreamModalState(null);
  };

  const onViewDailyVolumeClick = (stream) => {
    setDailyVolumeModalState(true);
    setDailyVolumeStream(stream);
  };

  const updateStreams = () => {
    props.fetchAdjustmentFactors();
    RecordOfVolumeService.getStreamVolumeAdjustmentConfigs().then(
      ({ data }) => {
        setStreamAdjustmentConfigs(data);
      }
    );

    // If this component is a tab in the RoQ page (only way it has this prop),
    // refresh the accounting period as well (for RoQ to reflect volume changes)
    if (props.refreshAccountingPeriod) {
      props.refreshAccountingPeriod();
    }
  };

  useEffect(() => {
    props.ensureStreamListIsFetched();
    props.fetchAdjustmentFactors();

    RecordOfVolumeService.getStreamVolumeAdjustmentConfigs().then(
      ({ data }) => {
        setStreamAdjustmentConfigs(data);
      }
    );
  }, []);

  const streamsWithRecords = useMemo(() => {
    const clientStreams = filter(props.streams, { type: "Client" });

    if (props.recordOfQualities) {
      return intersectionWith(
        clientStreams,
        props.recordOfQualities,
        (stream, roq) => stream.id === roq.stream?.id
      );
    }

    return clientStreams;
  }, [props.streams, props.recordOfQualities]);

  const actionRow = (
    <div>
      <Button
        onClick={() => setEditStreamModalState("show")}
        disabled={checkedStreams.length === 0}
      >
        Edit Adjustment Factors
      </Button>
    </div>
  );

  return (
    <ConditionallyRenderedPage
      isTab={props.view === "tab"}
      breadcrumbs={props.breadcrumbs}
    >
      <Panel
        className={`${
          props.view === "tab" ? "recordOfVolumeContainer__tabView" : ""
        }`}
        style={{
          height:
            props.view === "tab" ? props.height - TAB_OFFSET : props.height,
        }}
      >
        <RecordOfVolumeTable
          streamAdjustmentConfigs={streamAdjustmentConfigs}
          streams={streamsWithRecords}
          adjustmentFactors={props.adjustmentFactors}
          haveWritePermission={props.haveWritePermission}
          onStreamChecked={onStreamChecked}
          loading={props.isLoading}
          onViewDailyVolumeClick={onViewDailyVolumeClick}
          view={props.view}
          actionRow={actionRow}
        />

        {editStreamModalState === "show" && (
          <EditStreamAdjustmentFactorSettings
            show={editStreamModalState === "show"}
            onHide={onEditStreamModalClose}
            streams={streamsWithRecords}
            adjustmentFactors={props.adjustmentFactors}
            streamAdjustmentConfigs={streamAdjustmentConfigs}
            checkedStreams={checkedStreams}
            updateStreams={updateStreams}
          />
        )}

        <DailyVolumeModal
          show={dailyVolumeModalState}
          onModalClose={() => setDailyVolumeModalState(false)}
          stream={dailyVolumeStream}
          from={props.accountingPeriod?.from}
          until={props.accountingPeriod?.until}
          streamAdjustmentConfigs={streamAdjustmentConfigs}
          refreshAccountingPeriod={props.refreshAccountingPeriod}
        />
      </Panel>
    </ConditionallyRenderedPage>
  );
};

RecordOfVolume.propTypes = {
  height: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired,
  haveWritePermission: PropTypes.bool.isRequired,
  streams: PropTypes.array.isRequired,
  adjustmentFactors: PropTypes.array.isRequired,
  ensureStreamListIsFetched: PropTypes.func.isRequired,
  fetchAdjustmentFactors: PropTypes.func.isRequired,
  fetchStreamList: PropTypes.func.isRequired,
  recordOfQualities: PropTypes.array,
  accountingPeriod: PropTypes.object,
  refreshAccountingPeriod: PropTypes.func,
  view: PropTypes.string,
  breadcrumbs: PropTypes.array.isRequired,
};

const RecordOfVolumeContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(RecordOfVolume);

export default RecordOfVolumeContainer;
