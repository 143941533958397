export const EDIT_USER_FALLBACK_TITLE = "Edit:userId";

export const linkToUserEditPage = (userId = ":userId") =>
  `/app/settings/users/${userId}/edit`;

export const EDIT_USER_PROFILE_BREADCRUMB = {
  title: EDIT_USER_FALLBACK_TITLE,
  path: linkToUserEditPage,
};

export * from "./EditUserPage";
