import { useSearchParams } from "#routers/hooks";
import RecordOfQualityService from "#services/RecordOfQualityService";
import { Tab, Tabs } from "@validereinc/common-components";
import * as PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import RecordOfVolume from "../../Volume/RecordOfVolume";
import RecombinedReportModal from "../RecombinedReportModal/RecombinedReportModal";
import RecordOfQualityTable from "./Quality/RecordOfQualityTable";
import "./Records.scss";
import { getCombinedInventoryRecord } from "./RecordsHelper";

function getNumApprovalRequired(list) {
  return list.filter((row) => row.approved_by === null).length;
}

const useFetchRecordOfQualities = (
  accountingPeriodId,
  updateAccountingPeriod,
  setNumApprovalRequired
) => {
  const [recordOfQualities, setRecordOfQualities] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    if (accountingPeriodId) {
      RecordOfQualityService.getRecordOfQualities(accountingPeriodId)
        .then(({ data }) => {
          setNumApprovalRequired(getNumApprovalRequired(data));
          setRecordOfQualities(getCombinedInventoryRecord(data));
        })
        .catch(() => {
          setRecordOfQualities([]);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setRecordOfQualities([]);
      setIsLoading(false);
    }
  }, [accountingPeriodId, updateAccountingPeriod]);
  return [recordOfQualities, isLoading];
};

const Records = (props) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [recordOfQualities, isLoading] = useFetchRecordOfQualities(
    props.accountingPeriod?.id ?? null,
    props.updateAccountingPeriod,
    props.setNumApprovalRequired
  );

  const onTabChange = (newTab) => {
    setSearchParams({ ...searchParams, tab: newTab });
  };

  return (
    <div className="records">
      <Tabs
        activeKey={searchParams?.tab}
        onChange={onTabChange}
      >
        <Tab
          tabKey="qualities"
          title="Qualities"
        >
          <RecordOfQualityTable
            accountingPeriod={props.accountingPeriod}
            displayProperties={props.displayProperties}
            height={props.height}
            refreshAccountingPeriod={props.refreshAccountingPeriod}
            hasWriteAccess={props.hasWriteAccess}
            setNumApprovalRequired={props.setNumApprovalRequired}
            recordOfQualities={recordOfQualities}
            isLoading={isLoading}
          />
        </Tab>

        <Tab
          tabKey="volumes"
          title="Volumes"
        >
          <RecordOfVolume
            height={props.height}
            accountingPeriod={props.accountingPeriod}
            recordOfQualities={recordOfQualities}
            refreshAccountingPeriod={props.refreshAccountingPeriod}
            view="tab"
            location={props.location}
          />
        </Tab>
      </Tabs>

      <RecombinedReportModal
        show={props.showReportModal}
        onModalClose={() => props.setShowReportModal(false)}
        recordOfQualities={recordOfQualities}
      />
    </div>
  );
};

Records.propTypes = {
  height: PropTypes.number,
  accountingPeriod: PropTypes.object,
  updateAccountingPeriod: PropTypes.bool,
  setNumApprovalRequired: PropTypes.func,
  displayProperties: PropTypes.array,
  refreshAccountingPeriod: PropTypes.func,
  hasWriteAccess: PropTypes.bool,
  location: PropTypes.object,
  showReportModal: PropTypes.bool,
  setShowReportModal: PropTypes.func,
};

export default Records;
