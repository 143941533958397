export const FACILITY_DETAIL_FALLBACK_PAGE_TITLE = "Facility Detail:facilityId";

export const linkToFacilityDetail = (facilityId = ":facilityId") =>
  `/app/organization/facilities/${facilityId}/detail`;

export const FACILITY_DETAIL_BREADCRUMB = {
  title: FACILITY_DETAIL_FALLBACK_PAGE_TITLE,
  path: linkToFacilityDetail,
};

export type FacilityDetailPageParams = {
  facilityId: string;
};

export * from "./FacilityDetailPage";
