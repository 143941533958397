import { DropdownInput } from "@validereinc/common-components";
import React from "react";

export const LookupFields = () => {
  return (
    <>
      <DropdownInput
        label="Lookup Entity Type"
        name="lookup_entity_type"
        options={[
          { label: "Facility", value: "facility" },
          { label: "Equipment", value: "equipment" },
          { label: "Device", value: "device" },
          { label: "Flow", value: "flow" },
        ]}
        labelKey="label"
        valueKey="value"
        isRequired
        isFluid
      />
    </>
  );
};
