import {
  ensureStreamListIsFetched,
  fetchInstrumentList,
} from "#redux/actions/index";
import { getMeasurementType } from "#redux/reducers/measurements";
import ManualEntryService from "#services/ManualEntryService";
import history from "#src/Routers/history";
import { linkToManualEntry } from "#src/Routers/links";
import {
  Button,
  Form,
  FormButton,
  useAlert,
  useForm,
} from "@validereinc/common-components";
import moment from "moment";
import * as PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { v1 as uuidv1 } from "uuid";
import "./InlineManualEntry.scss";
import InlineManualEntryForm from "./InlineManualEntryForm";
import { getInputWithTestInfo } from "./InlineManualEntryHelper";
import OverwriteConfirmationModal from "./OverwriteConfirmationModal";

const TODAY = moment().startOf("day").toDate();

const DEFAULT_INPUTS = {
  stream: null,
  measurements: [{ date: TODAY, uuid: uuidv1() }],
};

const mapStateToProps = ({ streams, instruments, measurements }) => ({
  streams: streams.data?.toJS() ?? [],
  instruments: instruments.data.toJS(),

  getMeasurementType: (measurement) =>
    getMeasurementType(measurements)(measurement),
});

const mapDispatchToProps = {
  ensureStreamListIsFetched,
  fetchInstrumentList,
};

const InlineManualEntry = ({
  streams,
  instruments,
  getMeasurementType,
  show,
  onHide,
  testInfo,
  refetchData,
  ensureStreamListIsFetched,
  fetchInstrumentList,
  view = "page",
  bodyComponent: BodyComponent = "div",
  footerComponent: FooterComponent = "div",
}) => {
  const [formState, setFormState] = useState("enabled");
  const [showOverwriteConfirmationModal, setShowOverwriteConfirmationModal] =
    useState(false);
  const { addAlert } = useAlert();
  const form = useForm({
    defaultValues: DEFAULT_INPUTS,
  });

  useEffect(() => {
    ensureStreamListIsFetched();
    fetchInstrumentList();
  }, []);

  useEffect(() => {
    if (show && testInfo) {
      getInputWithTestInfo(testInfo, streams, getMeasurementType, form.reset);
    }
  }, [show, testInfo, streams]);

  const onChangeNumMeasurements = (uuid) => {
    if (uuid !== undefined) {
      form.setValue(
        "measurements",
        form
          .getValues("measurements")
          .filter((measurement) => measurement.uuid !== uuid)
      );
    } else {
      form.setValue("measurements", [
        ...form.getValues("measurements"),
        { date: TODAY, uuid: uuidv1() },
      ]);
    }
  };

  const onCancelClick = () => {
    if (view === "page") {
      history.push(linkToManualEntry());
    }

    if (view === "modal") {
      onHide?.();
    }
  };

  const onSuccessResponse = () => {
    addAlert({
      variant: "success",
      message: "Inline Measurements successfully created.",
    });

    if (view === "page") {
      history.push(linkToManualEntry());
    }

    if (view === "modal") {
      // Update the inline table and chart
      refetchData?.();
      onHide?.();
    }
  };

  const onSubmitForm = (inputs) => {
    setFormState("loading");

    ManualEntryService.addInlineMeasurement(inputs)
      .then(() => {
        onSuccessResponse();
      })
      .catch((error) => {
        // 409 conflict - values will be overwritten
        // show confirmation modal to force overwrite
        if (error?.response?.status === 409) {
          setShowOverwriteConfirmationModal(true);
        }
      })
      .finally(() => {
        setFormState("enabled");
      });
  };

  return (
    <>
      <Form
        onSubmit={onSubmitForm}
        {...form}
      >
        <BodyComponent>
          <InlineManualEntryForm
            form={form}
            formState={formState}
            streams={streams}
            instruments={instruments}
            onChangeNumMeasurements={onChangeNumMeasurements}
            getMeasurementType={getMeasurementType}
          />
        </BodyComponent>

        <FooterComponent className="clearfix">
          <Button
            className="pull-left"
            onClick={onCancelClick}
            disabled={formState !== "enabled"}
          >
            Close
          </Button>

          <FormButton
            className="pull-right"
            variant="primary"
            type="submit"
            isLoading={formState === "loading"}
            disabled={formState !== "enabled"}
          >
            Add Inline Measurement
          </FormButton>
        </FooterComponent>
      </Form>

      {showOverwriteConfirmationModal && (
        <OverwriteConfirmationModal
          show={true}
          onOverwriteConfirmationModalClose={() =>
            setShowOverwriteConfirmationModal(false)
          }
          input={form.getValues()}
          view={view}
          onSuccessResponse={onSuccessResponse}
        />
      )}
    </>
  );
};

InlineManualEntry.propTypes = {
  streams: PropTypes.array.isRequired,
  instruments: PropTypes.array.isRequired,
  getMeasurementType: PropTypes.func.isRequired,
  breadcrumbs: PropTypes.array,
  match: PropTypes.object,
  view: PropTypes.string,
  show: PropTypes.bool,
  onHide: PropTypes.func,
  testInfo: PropTypes.object,
  refetchData: PropTypes.func,
  ensureStreamListIsFetched: PropTypes.func.isRequired,
  fetchInstrumentList: PropTypes.func.isRequired,
  bodyComponent: PropTypes.elementType,
  footerComponent: PropTypes.elementType,
};

const InlineManualEntryContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(InlineManualEntry);

export default InlineManualEntryContainer;
