import SummaryInformation from "#common/SummaryInformation/SummaryInformation";
import { sampleStateRenderer } from "#components/Samples/SampleHelper";
import { linkToInstrumentTests } from "#routers/links";
import config from "#src/config";
import { getTimeStringFromDate } from "#utils/timeFormatter";
import { Panel, Tooltip } from "@validereinc/common-components";
import classNames from "classnames/bind";
import * as PropTypes from "prop-types";
import React from "react";
import styles from "./TestDetail.module.scss";

const cx = classNames.bind(styles);

const SummaryItemTooltip = ({ name }) => (
  <Tooltip content={name}>
    <div>{name}</div>
  </Tooltip>
);

const TestDetailSummary = ({ testDetail, testDetailLoadingState }) => {
  const { name, status, type, date, instrument, sample, tested_by } =
    testDetail || {};

  const sharedProps = {
    className: cx("summaryContainerItem"),
    type: "vertical",
  };

  return (
    <Panel
      title="Summary"
      loaded={testDetailLoadingState !== "loading"}
    >
      <div className={cx("summaryContainer")}>
        <SummaryInformation
          {...sharedProps}
          title="Stream"
          value={<SummaryItemTooltip name={name ?? "-"} />}
          allowWrap
        />

        <SummaryInformation
          {...sharedProps}
          title="Test Date"
          value={getTimeStringFromDate(date, config.DATETIME_FORMAT)}
          capitalized
        />

        <SummaryInformation
          {...sharedProps}
          title="Sample Date"
          value={getTimeStringFromDate(
            sample?.ended_at,
            config.DATETIME_FORMAT
          )}
          capitalized
        />

        <SummaryInformation
          {...sharedProps}
          title="Type"
          value={type ?? "-"}
          capitalized
        />

        <SummaryInformation
          {...sharedProps}
          title="Test Status"
          value={status ?? "-"}
          capitalized
        />

        <SummaryInformation
          {...sharedProps}
          title="Sample Status"
          value={sampleStateRenderer(sample)}
          capitalized
        />

        <SummaryInformation
          {...sharedProps}
          title="Instrument"
          value={<SummaryItemTooltip name={instrument?.name ?? "-"} />}
          capitalized
          link={instrument ? linkToInstrumentTests(instrument?.id) : null}
          allowWrap
        />

        <SummaryInformation
          {...sharedProps}
          title="Tester"
          value={tested_by ?? "-"}
          capitalized
          suppress
        />

        <SummaryInformation
          {...sharedProps}
          title="Sample by"
          value={sample?.sampled_by ?? "-"}
          capitalized
          suppress
        />
      </div>
    </Panel>
  );
};

SummaryItemTooltip.propTypes = {
  name: PropTypes.string,
};

TestDetailSummary.propTypes = {
  testDetail: PropTypes.object,
  testDetailLoadingState: PropTypes.string,
};

export default TestDetailSummary;
