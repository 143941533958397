import { CustomList } from "#common/List/CustomList";
import { addAlertMessage } from "#redux/actions/alertMessages";
import { linkToRoleNotificationSetting } from "#routers/links";
import RoleService from "#services/RoleService";
import { SortListByType } from "#utils/sorters";
import { Panel } from "@validereinc/common-components";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import "./RoleNotificationSettingsList.css";

const LIST_HEIGHT_OFFSET = 400;

const MIN_LIST_HEIGHT = 150;

const mapDispatchToProps = {
  addAlertMessage,
};

const RoleNotificationSettingList = ({ height }) => {
  const roleNotificationTableHeight = Math.max(
    height - LIST_HEIGHT_OFFSET,
    MIN_LIST_HEIGHT
  );

  const [availableRoles, setAvailableRoles] = useState([]);

  const rowRenderer = (data) => {
    return (
      <div>
        <div
          className="roleNotificationListRow__title"
          data-hj-suppress
        >
          {data.name}
        </div>

        <Link
          className="roleNotificationListRow__link pull-right"
          to={linkToRoleNotificationSetting(data.id)}
        >
          Manage Notification
        </Link>
      </div>
    );
  };

  useEffect(() => {
    RoleService.getRoles().then(({ data }) => {
      const sortedRoleNotificationList = SortListByType(data, "name", "asc");
      setAvailableRoles(sortedRoleNotificationList);
    });
  }, []);

  return (
    <Panel className="roleNotificationSettingList">
      <CustomList
        title="role"
        maximumListHeight={roleNotificationTableHeight}
        rowHeight={60}
        list={availableRoles}
        listItemRenderer={rowRenderer}
      />
    </Panel>
  );
};

RoleNotificationSettingList.propTypes = {
  height: PropTypes.number.isRequired,
};

export default connect(null, mapDispatchToProps)(RoleNotificationSettingList);
