import {
  EQUIPMENT_CUSTOM_REPORT_GROUP_BY,
  EQUIPMENT_CUSTOM_REPORT_STATIC_ROWS,
  FLOW_CUSTOM_REPORT_GROUP_BY,
  FLOW_CUSTOM_REPORT_STATIC_ROWS,
} from "#batteries-included-components/Layouts/CustomReport/CustomReportConfigurationPanel/CustomReportConfigurationPanel.helpers";
import {
  CustomReportVariantType,
  CustomReportVariants,
} from "#batteries-included-components/Layouts/CustomReport/CustomReportDetailLayout.helpers";
import { RoutingLink } from "#batteries-included-components/RoutingLink";
import { useCustomAttributeFilters } from "#hooks/FilterPanel/useCustomAttributeFilters";
import { useListCalculators } from "#hooks/adapters/useCalculators";
import { FLOW_ATTRIBUTES, useFlowHeaders } from "#hooks/tables/useFlowHeaders";
import { linkToEquipmentDetail } from "#routes/organization/equipment/[equipmentId]";
import { linkToFacilityDetail } from "#routes/organization/facilities/[facilityId]";
import { useMeasurementTypes } from "#src/contexts/MeasurementTypeContext";
import useLocalization from "#src/hooks/useLocalization";
import { linkToEstimationMethodDetailPage } from "#utils/links";
import { DataTable, HeaderType } from "@validereinc/common-components";
import {
  AssetType,
  CalculatorResultType,
  RecordType,
  SortDirection,
} from "@validereinc/domain";
import {
  DateFormats,
  monthFormatter,
  toStartCaseString,
} from "@validereinc/utilities";
import parse from "date-fns/parse";
import isNil from "lodash/isNil";
import React, { useMemo } from "react";

export const useEquipmentHeaders = (): Array<
  HeaderType<CalculatorResultType>
> => {
  const { localize } = useLocalization();
  const { getTypeName } = useMeasurementTypes();

  const calculatorQuery = useListCalculators();
  const calculatorLookup = useMemo(
    () =>
      Object.fromEntries(
        calculatorQuery.data?.calculators?.map(
          ({ id, default_version, versions }) => [
            id,
            versions.find(({ version }) => version === default_version)?.title,
          ]
        ) ?? []
      ),
    [calculatorQuery.data]
  );

  const { customAttributeHeaders: facilityCustomAttributeHeaders } =
    useCustomAttributeFilters({
      assetType: AssetType.FACILITY,
      prefix: "equipment.facility.custom_attributes",
      sortable: true,
    });

  const { customAttributeHeaders: equipmentCustomAttributeHeaders } =
    useCustomAttributeFilters({
      assetType: AssetType.EQUIPMENT,
      prefix: "equipment.custom_attributes",
      sortable: true,
    });

  return [
    {
      key: EQUIPMENT_CUSTOM_REPORT_STATIC_ROWS.EQUIPMENT,
      label: localize("Equipment"),
      isSortable: true,
      renderComponent: ({ item }) => (
        <RoutingLink to={linkToEquipmentDetail(item["equipment.id"])}>
          {item[EQUIPMENT_CUSTOM_REPORT_STATIC_ROWS.EQUIPMENT]}
        </RoutingLink>
      ),
    },
    {
      key: EQUIPMENT_CUSTOM_REPORT_STATIC_ROWS.EQUIPMENT_STATUS,
      label: `${localize("Equipment")}: Status`,
      isSortable: true,
      renderComponent: ({ item }) => (
        <DataTable.DataRow.PillCell
          variant={
            item["equipment.status"] === "active" ? "success" : "default"
          }
          value={toStartCaseString(
            item[EQUIPMENT_CUSTOM_REPORT_STATIC_ROWS.EQUIPMENT_STATUS]
          )}
        />
      ),
    },
    {
      key: EQUIPMENT_CUSTOM_REPORT_STATIC_ROWS.EQUIPMENT_TYPE,
      label: `${localize("Equipment")}: Type`,
      isSortable: true,
      renderComponent: ({ item }) =>
        toStartCaseString(item["equipment.type.name"]),
    },
    {
      key: EQUIPMENT_CUSTOM_REPORT_STATIC_ROWS.FACILITY,
      label: localize("Facility"),
      isSortable: true,
      renderComponent: ({ item }) => (
        <RoutingLink to={linkToFacilityDetail(item["equipment.facility.id"])}>
          {item["equipment.facility.name"]}
        </RoutingLink>
      ),
    },
    {
      key: EQUIPMENT_CUSTOM_REPORT_STATIC_ROWS.FACILITY_STATUS,
      label: `${localize("Facility")}: Status`,
      isSortable: true,
      renderComponent: ({ item }) => (
        <DataTable.DataRow.PillCell
          variant={
            item["equipment.facility.status"] === "active"
              ? "success"
              : "default"
          }
          value={toStartCaseString(item["equipment.facility.status"])}
        />
      ),
    },
    {
      key: EQUIPMENT_CUSTOM_REPORT_STATIC_ROWS.ESTIMATION_METHOD,
      label: "Estimation Method",
      isSortable: true,
      renderComponent: ({ item }) => (
        <RoutingLink
          to={linkToEstimationMethodDetailPage(
            item.entity_type,
            item.entity_id,
            item.estimation_method_id
          )}
        >
          {item.estimation_method_name}
        </RoutingLink>
      ),
    },
    {
      key: EQUIPMENT_CUSTOM_REPORT_STATIC_ROWS.MEASUREMENT_TYPE,
      label: "Measurement Type",
      isSortable: true,
      renderComponent: ({ item }: { item: CalculatorResultType }) =>
        item?.measurement_type && !!getTypeName
          ? getTypeName?.(item.measurement_type)
          : item?.measurement_type,
    },
    {
      key: EQUIPMENT_CUSTOM_REPORT_STATIC_ROWS.YEAR_MONTH,
      label: "Period",
      isSortable: true,
      renderComponent: ({
        item: { year_month: yearMonth },
      }: {
        item: CalculatorResultType;
      }) =>
        yearMonth
          ? monthFormatter(parse(yearMonth, DateFormats.YEAR_MONTH, new Date()))
          : "-",
    },
    {
      key: EQUIPMENT_CUSTOM_REPORT_STATIC_ROWS.CALCULATOR,
      label: "Calculator",
      isSortable: true,
      renderComponent: ({ item }: { item: CalculatorResultType }) =>
        (item["estimation_method.analytics_calculator_id"] &&
          calculatorLookup?.[
            item["estimation_method.analytics_calculator_id"]
          ]) ??
        item["estimation_method.analytics_calculator_id"],
    },
    ...facilityCustomAttributeHeaders,
    ...equipmentCustomAttributeHeaders,
  ];
};

export const useCustomReportFlowHeaders = (): Array<HeaderType<RecordType>> => {
  const { customAttributeHeaders: flowCustomAttributeHeaders } =
    useCustomAttributeFilters({
      assetType: AssetType.FLOW,
      prefix: "flow.custom_attributes",
      sortable: true,
    });

  const { customAttributeHeaders: facilityCustomAttributeHeaders } =
    useCustomAttributeFilters({
      assetType: AssetType.FACILITY,
      prefix: "flow.associated_facility.custom_attributes",
      sortable: true,
    });

  const { customAttributeHeaders: equipmentCustomAttributeHeaders } =
    useCustomAttributeFilters({
      assetType: AssetType.EQUIPMENT,
      prefix: "flow.associated_equipment.custom_attributes",
      sortable: true,
    });

  return [
    ...useFlowHeaders<RecordType>({
      prefix: "flow",
      sortSubstitutions: {
        [FLOW_ATTRIBUTES.FACILITY.key]:
          FLOW_CUSTOM_REPORT_STATIC_ROWS.FLOW_ASSOCIATED_FACILITY,
        [FLOW_ATTRIBUTES.EQUIPMENT.key]:
          FLOW_CUSTOM_REPORT_STATIC_ROWS.FLOW_ASSOCIATED_EQUIPMENT,
      },
    }),
    {
      key: FLOW_CUSTOM_REPORT_STATIC_ROWS.YEAR_MONTH,
      label: "Period",
      isSortable: true,
      renderComponent: ({
        item: { year_month: yearMonth },
      }: {
        item: RecordType;
      }) =>
        yearMonth
          ? monthFormatter(parse(yearMonth, DateFormats.YEAR_MONTH, new Date()))
          : null,
    },
    ...flowCustomAttributeHeaders,
    ...equipmentCustomAttributeHeaders,
    ...facilityCustomAttributeHeaders,
  ];
};

export const useOutputHeaders = (
  variant: CustomReportVariantType,
  outputs: string[] = []
) => {
  const { getTypeName, getUnitName, getUnitByType, getPrecisionByType } =
    useMeasurementTypes();

  switch (variant) {
    case CustomReportVariants.VOLUMETRIC:
      return outputs.map((key) => ({
        key,
        label: getTypeName(key),
        isSortable: false,
        renderComponent: (componentProps: { item: RecordType }) => {
          const { item } = componentProps;
          const matchingValue = item?.values?.find(
            ({ measurement_type }) => measurement_type === key
          );

          return matchingValue ? (
            <DataTable.DataRow.NumberCell
              value={matchingValue.value}
              precision={getPrecisionByType(key)}
              unit={getUnitName(
                matchingValue.measurement_unit,
                matchingValue.value
              )}
            />
          ) : (
            "-"
          );
        },
      }));
    case CustomReportVariants.EMISSIONS:
      return outputs.map((key) => ({
        key: `measurement.${key}`,
        // the label maps 1:1 to the output config options. These headers are
        // filtered against the output options selected using this label.
        label: getTypeName(key),
        isSortable: true,
        renderComponent: (componentProps: { item: CalculatorResultType }) => {
          const { item } = componentProps;

          if (isNil(item?.measurement?.[key])) {
            return "-";
          }

          return !isNil(item?.measurement?.[key]) ? (
            <DataTable.DataRow.NumberCell
              value={Number(item.measurement[key])}
              precision={getPrecisionByType(key)}
              unit={getUnitName(getUnitByType(key))}
            />
          ) : (
            "-"
          );
        },
      }));
    default:
      return [];
  }
};

export const getCustomReportDefaultSort = (
  variant: CustomReportVariantType,
  rows: string[] = []
) => {
  let groupByMap: Record<string, string[]> = {};
  switch (variant) {
    case CustomReportVariants.EMISSIONS:
      groupByMap = EQUIPMENT_CUSTOM_REPORT_GROUP_BY;
      break;
    case CustomReportVariants.VOLUMETRIC:
      groupByMap = FLOW_CUSTOM_REPORT_GROUP_BY;
      break;
    default:
  }
  const sortDirection = SortDirection.ASCENDING;
  const keys = groupByMap[rows[0]];
  return keys?.length
    ? { sortBy: keys[keys.length - 1], sortDirection }
    : rows.length
      ? { sortBy: rows[0], sortDirection }
      : {};
};
