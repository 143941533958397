import { useState, useEffect, useCallback } from "react";
import AlertingService from "#services/AlertingService";

const useAlertConfigurations = () => {
  const [alertConfigurations, setAlertConfigurations] = useState([]);
  const [loadingState, setLoadingState] = useState("loading");
  const [lastUpdatedAt, setLastUpdatedAt] = useState(new Date());

  const refetchData = useCallback(() => {
    setLastUpdatedAt(new Date());
  }, []);

  useEffect(() => {
    setLoadingState("loading");

    AlertingService.getAlertConfigurations()
      .then(({ data }) => {
        setAlertConfigurations(data.data);
        setLoadingState("loaded");
      })
      .catch((error) => {
        console.error(error);
        setLoadingState("error");
      });
  }, [lastUpdatedAt]);

  return [alertConfigurations, loadingState, refetchData];
};

export default useAlertConfigurations;
