import React from "react";
import * as PropTypes from "prop-types";
import FontAwesome from "react-fontawesome";
import Inputbox from "./Inputbox";

const InputBoxWithIcon = ({ icon, ...props }) => (
  <Inputbox {...props}>
    <FontAwesome
      name={icon}
      className="fa-fw"
    />
  </Inputbox>
);

InputBoxWithIcon.propTypes = {
  icon: PropTypes.string.isRequired,
};

export default InputBoxWithIcon;
