import { ReportingGroupFilterSwitcher } from "#batteries-included-components/Filters/ReportingGroupFilterSwitcher";
import { CalculatorResultsSummaryPanel } from "#batteries-included-components/Panels/MetricTilePanels/CalculatorResultsSummaryPanel";
import { useListCalculatorResults } from "#hooks/adapters/useCalculatorResults";
import { useCalculatorResultsMeasurementHeaders } from "#hooks/tables/useMeasurementHeaders";
import { useTableSortingAndPagination } from "#redux/reducers/tableStateReducer";
import { linkToFacilityEmissionRecordDetail } from "#routes/organization/facilities/[facilityId]/emissions/[emissionPeriod]/detail";
import { RoutingLink } from "#src/batteries-included-components/RoutingLink";
import { DEFAULT_DATE_RANGES } from "#src/hooks/useDateRange";
import { useStorageKey } from "#src/hooks/useStorageKey";
import { linkToEquipmentEmissionRecordDetail } from "#src/routes/organization/equipment/[equipmentId]/emissions/[emissionPeriod]/detail";
import { getIntervalInMonthsFormatted, hydrateDateRange } from "#utils/date";
import {
  DataTablePanel,
  DateSelectorInput,
  FilterPanel,
  SortingType,
  useFilters,
} from "@validereinc/common-components";
import {
  AssetType,
  AssetTypeType,
  CalculatorResultType,
  SortDirection,
} from "@validereinc/domain";
import { DateFormats, monthFormatter } from "@validereinc/utilities";
import parse from "date-fns/parse";
import isNil from "lodash/isNil";
import React from "react";

const sorting: SortingType = {
  sortBy: "year_month",
  sortDirection: SortDirection.ASCENDING,
};

export const FILTER_CONFIG = {
  date: {
    name: "date_range",
  },
  reportingScenario: {
    name: "reporting_scenario",
  },
} as const;

export const EmissionsRecordsTab = ({
  assetType,
  assetId,
}: {
  assetType: AssetTypeType;
  assetId?: string;
}) => {
  const { tableConfigStorageKey, filterConfigStorageKey } = useStorageKey(
    `${assetType}-emissions-records`
  );

  const { assetFilter, linkToEmissionRecordDetail } = ((): {
    assetFilter: Record<string, string | undefined>;
    linkToEmissionRecordDetail?: (id: string, period: string) => string;
  } => {
    switch (assetType) {
      case AssetType.FACILITY:
        return {
          assetFilter: { "equipment.facility.id": assetId },
          linkToEmissionRecordDetail: linkToFacilityEmissionRecordDetail,
        };
      case AssetType.EQUIPMENT:
        return {
          assetFilter: { entity_id: assetId, entity_type: assetType },
          linkToEmissionRecordDetail: linkToEquipmentEmissionRecordDetail,
        };
      default:
        return {
          assetFilter: {},
        };
    }
  })();

  const [filterPanelFilters] = useFilters(filterConfigStorageKey);

  const dateRange = filterPanelFilters[FILTER_CONFIG.date.name];
  const reportingGroup =
    filterPanelFilters[FILTER_CONFIG.reportingScenario.name];

  const calculatorResultFilters = {
    ...assetFilter,
    ...(reportingGroup ? { reporting_group: { $exact: reportingGroup } } : {}),
    year_month: getIntervalInMonthsFormatted(
      dateRange
        ? hydrateDateRange(dateRange)
        : DEFAULT_DATE_RANGES.lastTwelveMonths
    ),
  };

  const [tableState, updateTableState] = useTableSortingAndPagination(
    sorting,
    calculatorResultFilters
  );

  const isQueryEnabled = !isNil(reportingGroup);

  const summaryQuery = useListCalculatorResults(
    {
      page: 1,
      pageSize: 1,
      filters: calculatorResultFilters,
    },
    { enabled: isQueryEnabled }
  );
  const summaryItems = summaryQuery.data?.data ?? [];

  const tableQuery = useListCalculatorResults({
    page: tableState.page,
    pageSize: tableState.itemsPerPage,
    sortBy: tableState.sortBy,
    sortDirection: tableState.sortDirection,
    filters: calculatorResultFilters,
    groupBy: ["year_month"],
  });
  const items = tableQuery.data?.data ?? [];
  const headers = [
    {
      label: "Time Period",
      key: "year_month",
      isSortable: true,
      renderComponent: ({
        item: { year_month: yearMonth },
      }: {
        item: CalculatorResultType;
      }) =>
        yearMonth && assetId && linkToEmissionRecordDetail ? (
          <RoutingLink to={linkToEmissionRecordDetail?.(assetId, yearMonth)}>
            {monthFormatter(
              parse(yearMonth, DateFormats.YEAR_MONTH, new Date())
            )}
          </RoutingLink>
        ) : (
          "-"
        ),
    },
    ...useCalculatorResultsMeasurementHeaders(summaryItems),
  ];

  return (
    <>
      <FilterPanel
        storageKey={filterConfigStorageKey}
        defaultValue={{
          [FILTER_CONFIG.date.name]: DEFAULT_DATE_RANGES.lastTwelveMonths,
        }}
        filters={[
          {
            component: (
              <ReportingGroupFilterSwitcher
                key={FILTER_CONFIG.reportingScenario.name}
                name={FILTER_CONFIG.reportingScenario.name}
                shouldSelectFirstOptionAsDefault
              />
            ),
          },
          {
            component: (
              <DateSelectorInput
                key={FILTER_CONFIG.date.name}
                name={FILTER_CONFIG.date.name}
                variant="month"
                isRange
                isInline
              />
            ),
          },
        ]}
      />
      <CalculatorResultsSummaryPanel
        // only the first result matters since this is an aggregate request with no group by
        data={summaryItems?.[0] ?? null}
        isLoading={isQueryEnabled && summaryQuery.isLoading}
        loadingGridSize={
          items.reduce(
            (max, row) => Math.max(max, Object.keys(row.measurement).length),
            6
          ) ?? null
        }
        isError={summaryQuery.isError}
      />
      <DataTablePanel
        storageKey={tableConfigStorageKey}
        panelProps={{ title: "Records", ariaLabel: "Records Panel" }}
        dataTableProps={{
          headers,
          items,
          sorting,
          isLoading: isQueryEnabled && tableQuery.isLoading,
          pagination: {
            page: tableState.page,
            itemsPerPage: tableState.itemsPerPage,
            total: tableQuery.data?.total_entries ?? 0,
          },
          onSortChange: updateTableState,
          onPaginationChange: updateTableState,
        }}
      />
    </>
  );
};
