import {
  EmptyState,
  Panel,
  PanelProps,
  Pill,
  PillProps,
} from "@validereinc/common-components";
import classNames from "classnames/bind";
import React, { ReactElement } from "react";
import styles from "./SinglePropertyPillPanel.module.css";

const cx = classNames.bind(styles);

const SinglePropertyPillPanel = ({
  values,
  panelProps,
  emptyStateProps,
  pillProps,
}: SinglePropertyPillPanelProps) => {
  return (
    <Panel
      style={{ marginBottom: 0 }}
      {...panelProps}
    >
      <div className={cx("content")}>
        {values?.length ? (
          values.map((val) => (
            <Pill
              isCapitalized={false}
              key={val}
              variant="primary"
              isBordered
              {...pillProps}
            >
              {val.trim()}
            </Pill>
          ))
        ) : (
          <EmptyState {...emptyStateProps} />
        )}
      </div>
    </Panel>
  );
};

// IMPROVE: when we can import and use types from common-components directly
export type SinglePropertyPillPanelProps = {
  values: string[];
  panelProps: Partial<PanelProps>;
  pillProps?: Partial<PillProps>;
  emptyStateProps: {
    title: string;
    suggestion?: string;
    icon?: ReactElement;
  };
};

export default SinglePropertyPillPanel;
