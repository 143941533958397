export * from "./EventCategoryDetailPage";

export const EVENT_CATEGORY_DETAIL_PAGE_TITLE = "Event Category";

export const linkToEventCategoryDetail = (
  eventCategoryId = ":eventCategoryId"
) => `/app/events/event-categories/${eventCategoryId}/details`;

export const EVENT_CATEGORY_DETAIL_BREADCRUMB = {
  title: `${EVENT_CATEGORY_DETAIL_PAGE_TITLE}:eventCategoryId`,
  path: linkToEventCategoryDetail,
};
