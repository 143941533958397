import moment from "moment";
import find from "lodash/find";

const lodash = { find };

/**
 * isValidString() returns errorMessage or variable value
 * based on if variable is null/undefined/empty
 */
export function isValidString(value, errorMessage) {
  if (
    value !== undefined &&
    value !== null &&
    value !== "" &&
    value !== "null"
  ) {
    return value;
  } else {
    return errorMessage;
  }
}

/**
 * isValidFloat() returns errorMessage or variable value
 * based on if variable is null/undefined/empty
 */
export function isValidFloat(value, errorMessage) {
  if (
    value !== undefined &&
    value !== null &&
    value !== "" &&
    value !== "null"
  ) {
    return value;
  } else {
    return errorMessage;
  }
}

/**
 * isValidFloatwDecimal() returns errorMessage or variable value
 * based on if variable is null/undefined/empty
 */
export function isValidFloatwDecimal(value, decimal, errorMessage) {
  if (
    value !== undefined &&
    value !== null &&
    value !== "" &&
    value !== "null"
  ) {
    return parseFloat(value).toFixed(decimal);
  } else {
    return errorMessage;
  }
}

/**
 * isValidFloatwUnit() returns errorMessage or variable value
 * based on if variable is null/undefined/empty
 */
export function isValidFloatwUnit(value, decimal, unit, errorMessage) {
  if (
    value !== undefined &&
    value !== null &&
    value !== "" &&
    value !== "null"
  ) {
    return parseFloat(value).toFixed(decimal) + " " + unit;
  } else {
    return errorMessage;
  }
}

/**
 * getTemperatureDisplayUnit checks temp units sent from back end
 * and selects the appropriate one to display on UI
 */
export function getTemperatureDisplayUnit(unit) {
  const map = { "deg. C": "°C", "deg. F": "°F" };
  if (unit) {
    return map[unit] || unit;
  } else {
    return "-";
  }
}

/**
 * formatDate() returns time in the date format is been set
 * based on utc epoch time
 */
export function formatDate(valueUTCepoch, dateFormat, errorMessage) {
  if (
    valueUTCepoch !== undefined &&
    valueUTCepoch !== null &&
    valueUTCepoch !== "" &&
    valueUTCepoch !== "null"
  ) {
    return moment(valueUTCepoch * 1000).format(dateFormat);
  } else {
    return errorMessage;
  }
}

/**
 * formatDateMilliseconds() returns time in the date format is been set
 * based on utc epoch time
 */
export function formatDateMilliseconds(
  valueUTCepoch,
  dateFormat,
  errorMessage
) {
  if (
    valueUTCepoch !== undefined &&
    valueUTCepoch !== null &&
    valueUTCepoch !== "" &&
    valueUTCepoch !== "null"
  ) {
    return moment(valueUTCepoch).format(dateFormat);
  } else {
    return errorMessage;
  }
}

/**
 * formatDateAgo() returns time in days ago format ( 7 days ago )
 * based on utc epoch time
 */
export function formatDateAgo(valueUTCepoch, errorMessage) {
  if (
    valueUTCepoch !== undefined &&
    valueUTCepoch !== null &&
    valueUTCepoch !== "" &&
    valueUTCepoch !== "null" &&
    valueUTCepoch >= 0
  ) {
    return moment(valueUTCepoch * 1000).fromNow(true) + " ago";
  } else {
    return errorMessage;
  }
}

/**
 * getSliceString() returns sliced string
 * based on value, number of digits they want to slice and error message
 */
export function getSliceString(value, numDigit, errorMessage) {
  if (
    value !== undefined &&
    value !== null &&
    value !== "" &&
    value !== "null"
  ) {
    return value.toString().slice(0, numDigit);
  } else {
    return errorMessage;
  }
}

/**
 * getWaterSampleType() return types of water sample
 * base on isSpotSeries and if the sample have spot sample id
 */
export function getWaterSampleType(isSpotSeries, spotSampleID) {
  if (isSpotSeries) {
    return "Spot Series";
  } else {
    if (spotSampleID !== null && spotSampleID !== undefined) {
      return "Spot";
    } else {
      return "Composite";
    }
  }
}

/**
 * getTimeDifference() return time difference in HH:mm:ss format
 * base on isSpotSeries and if the sample have spot sample id
 */
export function getTimeDifference(startTime, endTime) {
  if (
    startTime !== null &&
    startTime !== undefined &&
    endTime !== null &&
    startTime !== undefined
  ) {
    let duration = 0;
    if (endTime >= startTime) {
      duration = moment.utc(endTime - startTime).format("HH:mm:ss");
    } else {
      duration = 0;
    }

    return duration;
  } else {
    return "-";
  }
}

/**
 * getArrayAverage() return average of an array value
 * base on the array and array value
 */
export function getArrayAverage(array, value) {
  let totalSum = 0;
  let average = 0;
  let invalidValueCount = 0;

  array.forEach(function (sampleInfo) {
    if (
      sampleInfo[value] !== null &&
      sampleInfo[value] !== undefined &&
      sampleInfo[value] >= 0
    ) {
      totalSum += sampleInfo[value];
    } else {
      invalidValueCount += 1;
    }
  });

  average = totalSum / (array.length - invalidValueCount);
  return average;
}

/**
 * getArrayAverage1D() return average of an array value that is 1 dementional
 * base on the 1D array
 */
export function getArrayAverage1D(array) {
  let totalSum = 0;
  let average = 0;
  let invalidValueCount = 0;

  array.forEach(function (sampleInfo) {
    if (sampleInfo !== null && sampleInfo !== undefined && sampleInfo >= 0) {
      totalSum += sampleInfo;
    } else {
      invalidValueCount += 1;
    }
  });

  average = totalSum / (array.length - invalidValueCount);
  return average;
}

/**
 * getArraySTD() return standard deviation of an array value
 * base on the array and array value
 */
export function getArraySTD(array, value) {
  const average = getArrayAverage(array, value);
  let squareDiffs = [];

  squareDiffs = array.map(function (array) {
    if (
      array[value] !== null &&
      array[value] !== undefined &&
      array[value] >= 0
    ) {
      const diff = array[value] - average;
      const sqrDiff = Math.pow(diff, 2);
      return sqrDiff;
    } else {
      return null;
    }
  });

  const avgSquareDiff = getArrayAverage1D(squareDiffs);

  return Math.sqrt(avgSquareDiff);
}

export function getDifference(valueOne, valueTwo) {
  valueOne = isValidFloat(valueOne, 0);
  valueTwo = isValidFloat(valueTwo, 0);

  if (valueOne > valueTwo) {
    const difference = valueOne - valueTwo;
    return difference.toFixed(3);
  } else {
    return 0;
  }
}

export function getErrorLabel(labels) {
  const errorLabel = [];

  if (labels) {
    labels.forEach((label) => {
      const stringArray = label.split("_");

      if (stringArray.pop() === "error") {
        errorLabel.push(label);
      }
    });
  }

  return errorLabel;
}

export function getErrorLabelWithRuntimeCheck(labels, runtime) {
  const errorLabel = [];

  if (labels) {
    labels.forEach((label) => {
      const stringArray = label.split("_");

      if (stringArray.pop() === "error") {
        errorLabel.push(label);
      }
    });
  }

  if (runtime <= 0) {
    errorLabel.push("measurement_error");
  }

  return errorLabel;
}

export function getAlertSpec(alertSpecs, key, defaultSpec) {
  if (alertSpecs) {
    const alertSpec = lodash.find(alertSpecs, { test_property: key });
    if (alertSpec) {
      return alertSpec;
    }
  }
  return defaultSpec;
}

export function getValueStr(value, status, decimals, unit, type) {
  const valueStr = isValidFloatwUnit(value, decimals, unit, "Error");

  switch (status) {
    case "pending":
      return "Pending";
    case "preliminary":
    case "preliminary-error":
      if (type === "water_sediment") {
        // We only show preliminary values for WS.
        return `${valueStr} (preliminary)`;
      } else {
        return "Pending";
      }
    default:
      return valueStr;
  }
}
