import MeasurementsService from "#services/MeasurementsService";

export const validateFilters = (filters, measurementSources) => {
  const validatedMeasurementTypes = [
    filters.measurement_left,
    filters.measurement_right,
  ].filter((type) =>
    measurementSources.find(({ measurement_type }) => measurement_type === type)
  );

  // Use the first measurement for the left axis if none is specified
  if (!validatedMeasurementTypes.length) {
    validatedMeasurementTypes.push(measurementSources[0]?.measurement_type);
  }

  return {
    ...filters,
    measurement_left: validatedMeasurementTypes[0],
    measurement_right: validatedMeasurementTypes[1],
  };
};

export const fetchMeasurementData = async (
  deviceId,
  measurementSources,
  measurementTypes,
  filters
) => {
  const result = await MeasurementsService.getAllMeasurements(deviceId, {
    groupBy: "measurement_type",
    interval: filters.interval,
    sort: "time",
    sortDirection: "asc",
    start: filters.date_range.from,
    end: filters.date_range.to,
    func: filters.aggregation_function,
  });

  // Group results by measurement type
  const bucketsBySource = measurementSources
    .map((measurementSource) => {
      const measurementType = measurementTypes.find(
        ({ id }) => id === measurementSource.measurement_type
      );
      return {
        name: measurementType?.name,
        unit: measurementSource.unit,
        type: measurementSource.measurement_type,
        data: [],
      };
    })
    .reduce((accumulator, value) => {
      accumulator[value.type] = value;
      return accumulator;
    }, {});
  return Object.values(
    result.data.data.reduce((accumulator, value) => {
      accumulator[value.measure_name]?.data?.push(value);
      return accumulator;
    }, bucketsBySource)
  );
};
