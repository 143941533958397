import NotesList from "#common/Notes/NotesList";
import { addAlertMessage } from "#redux/actions/alertMessages";
import { useSubmitNote, WORKFLOWTASKS_NOTE } from "#services/NotesService";
import { SortListByType } from "#utils/sorters";
import {
  Button,
  Form,
  FormButton,
  Modal,
  ReactLoader,
  SelectInput,
  TextWithFileInput,
  useForm,
} from "@validereinc/common-components";
import * as PropTypes from "prop-types";
import React, { useMemo, useState } from "react";
import { connect } from "react-redux";
import { AutoSizer } from "react-virtualized";
import "./WorkflowTaskNotes.scss";

const AUTOFILL_OPTIONS = [
  "No volume received",
  "LACT (Lease automatic custody transfer unit) not in service",
  "Lab equipment out of service",
  "Ad-hoc test completed outside of schedule; this instance not required",
];

const mapStateToProps = ({ profiles }) => ({
  profile: profiles.data,
});

const mapDispatchToProps = {
  addAlertMessage,
};

const WorkflowTaskNotes = ({
  workflowTaskId,
  isLoading,
  notes,
  fetchNotes,
  refetchWorkflowTasks,
  closeModal,
}) => {
  const [formState, setFormState] = useState("enabled");

  const form = useForm({
    defaultValues: {
      note: {},
    },
  });

  const [_isSubmittingNote, submitNote] = useSubmitNote(
    workflowTaskId,
    WORKFLOWTASKS_NOTE
  );

  const onSelectInputChange = (text) => {
    const note = form.getValues("note");

    form.setValue("note", { ...note, text }, { shouldValidate: true });
  };

  const onSubmitNote = (inputs) => {
    setFormState("loading");

    return submitNote(inputs.note)
      .then((result) => {
        fetchNotes();
        refetchWorkflowTasks();

        return result;
      })
      .finally(() => {
        form.reset();
        setFormState("enabled");
      });
  };

  const sortedNotes = useMemo(() => {
    return notes ? SortListByType(notes, "date", "desc") : [];
  }, [notes]);

  form.watch("note");

  return (
    <Form
      onSubmit={onSubmitNote}
      {...form}
    >
      <Modal.Body className="workflowTaskNotes">
        <div className="workflowTaskNotes__listContainer">
          <AutoSizer disableWidth>
            {({ height }) => (
              <ReactLoader
                loaded={!isLoading}
                position={50}
              >
                <NotesList
                  list={sortedNotes}
                  height={height}
                />
              </ReactLoader>
            )}
          </AutoSizer>
        </div>

        <SelectInput
          name="reason"
          label="Reason"
          options={AUTOFILL_OPTIONS}
          onChange={onSelectInputChange}
          ignoreCase
        />

        <TextWithFileInput
          name="note"
          label=""
          isRequired
          showIcon
          multiple
          isDisabled={formState !== "enabled"}
        />
      </Modal.Body>

      <Modal.Footer className="workflowTaskNotes__buttonRow">
        <Button
          className="pull-left"
          onClick={closeModal}
        >
          Close
        </Button>

        <FormButton
          variant="primary"
          type="submit"
          isLoading={formState === "loading"}
          disabled={formState !== "enabled"}
        >
          Add Note
        </FormButton>
      </Modal.Footer>
    </Form>
  );
};

WorkflowTaskNotes.propTypes = {
  workflowTaskId: PropTypes.number,
  isLoading: PropTypes.bool.isRequired,
  notes: PropTypes.arrayOf(PropTypes.object).isRequired,
  fetchNotes: PropTypes.func.isRequired,
  profile: PropTypes.object,
  isActiveTab: PropTypes.bool,
  refetchWorkflowTasks: PropTypes.func,
  addAlertMessage: PropTypes.func,
  closeModal: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowTaskNotes);
