export const EDIT_ROLE_USERS_TITLE = "Edit:roleId";

export const linkToEditRoleUsersPage = (roleId = ":roleId") =>
  `/app/settings/roles-and-permissions/roles/${roleId}/users/edit`;

export const EDIT_ROLE_PROFILE_USERS_BREADCRUMB = {
  title: EDIT_ROLE_USERS_TITLE,
  path: linkToEditRoleUsersPage,
};

export * from "./EditRoleUsersPage";
