import React from "react";
import * as PropTypes from "prop-types";

import { useNavigate, useParams } from "#routers/hooks";

import DeleteModal from "#components/Common/DeleteModal/DeleteModal";

import DashboardService from "#components/Services/DashboardService";

import { linkToDashboards } from "#src/routes/dashboard";

const DeleteDashboardModal = ({ isOpen, onClose, dashboardName }) => {
  const params = useParams();
  const navigate = useNavigate();

  const doDelete = async () => {
    await DashboardService.deleteDashboard(params?.dashboardId);
  };

  const onDelete = () => {
    navigate({ pathname: linkToDashboards() });
  };

  return (
    <DeleteModal
      open={isOpen}
      onClose={onClose}
      entityName="Dashboard"
      instanceName={dashboardName}
      doDelete={doDelete}
      onDelete={onDelete}
    />
  );
};

DeleteDashboardModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  dashboardName: PropTypes.string.isRequired,
};

export default DeleteDashboardModal;
