import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { saveEntryLocation } from "../components/Redux/actions/authentication";
import AuthenticatedPage from "../components/Layout/AuthenticatedPage";
import Layout from "../components/Layout/Layout";
import { linkToLogin } from "./links";
import * as Sentry from "@sentry/react";

const SentryRoute = Sentry.withSentryRouting(Route);

const mapStateToProps = ({ authentication }) => {
  return {
    isLoggedIn: authentication.isLoggedIn,
  };
};

const mapDispatchToProps = {
  saveEntryLocation,
};

// IMPROVE: this component is unnecessary and also does not adhere to how <Route />
// components must be rendered within a <Switch />. They must be direct
// children... not within another component like here. This <Route /> is also
// duplicated further down the tree... which is a code smell. All this and more
// will be tackled in the routing engine overhaul to come. (https://validere.atlassian.net/browse/CHB-2026)
export const PrivateRoute = ({
  path,
  isLoggedIn,
  saveEntryLocation,
  ...rest
}) => {
  return (
    <SentryRoute
      path={path}
      exact
      {...rest}
      render={(props) => {
        if (!isLoggedIn) {
          // Save the location the user tried to go to. After they authenticate, we can redirect them
          // to where they wanted to go
          saveEntryLocation(props.location);
          return (
            <Redirect
              to={{
                pathname: linkToLogin(),
                state: { from: props.location },
              }}
            />
          );
        }

        return (
          <AuthenticatedPage>
            <Layout {...props} />
          </AuthenticatedPage>
        );
      }}
    />
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);
