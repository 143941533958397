import { FacilityDropdownInput } from "#src/batteries-included-components/Dropdowns/FacilityDropdownInput";
import { convertMapToOpts } from "#src/constants";
import { useQuery } from "@tanstack/react-query";
import {
  DropdownInput,
  FilterPanel,
  StorageKeys,
  TextInput,
} from "@validereinc/common-components";
import { DeviceStatus, DeviceTypesDomain } from "@validereinc/domain";
import startCase from "lodash/startCase";
import React from "react";

export const DevicesFilterPanel = ({
  filterConfigStorageKey,
}: Pick<StorageKeys, "filterConfigStorageKey">) => {
  const { data: deviceTypes } = useQuery(["device_types"], () =>
    DeviceTypesDomain.getList()
  );

  const deviceStatuses = convertMapToOpts(DeviceStatus, ([_, value]) =>
    startCase(value)
  );

  return (
    <FilterPanel
      storageKey={filterConfigStorageKey}
      defaultActiveKeys={["Filters"]}
      filters={[
        {
          component: (
            <TextInput
              name="name"
              key="device-name-filter"
              placeholder="Search Devices..."
              isFluid
              isInline
              type="search"
            />
          ),
        },
        {
          section: "Filters",
          component: (
            <DropdownInput
              label="Type"
              name="type_id"
              key="device-type-filter"
              options={deviceTypes}
              labelKey="name"
              valueKey="id"
              isFluid
              isMulti
              isOptionalTextShown={false}
            />
          ),
        },
        {
          section: "Filters",
          component: (
            <DropdownInput
              label="Status"
              name="status"
              key="form-template-filter"
              options={deviceStatuses}
              labelKey="label"
              valueKey="value"
              isFluid
              isMulti
              isOptionalTextShown={false}
            />
          ),
        },
        {
          section: "Filters",
          component: (
            <FacilityDropdownInput
              isFluid
              isMulti
              name="facility.id"
            />
          ),
        },
      ]}
    />
  );
};
