import {
  Accordion,
  Button,
  DropdownInput,
  Panel,
  RadioInput,
  useFormContext,
} from "@validereinc/common-components";
import React from "react";
import { QuestionFilterDropdown } from "./QuestionFilterDropdown";
import { QuestionFilterStaticInput } from "./QuestionFilterStaticInput";

const { AccordionPanel } = Accordion;

export const LookupQuestionFilter = ({
  name,
  questions,
  id,
  index,
  onRemove,
  lookupEntityType,
}: {
  name: string;
  questions: Record<
    string,
    {
      type: "measurement" | "question";
      data_type?:
        | "string"
        | "number"
        | "integer"
        | "date"
        | "date-time"
        | "multi-pick-list"
        | "pick-list"
        | "geo_point"
        | "boolean"
        | "number-array"
        | "lookup"
        | "file";
    }
  >;
  id: string;
  index: number;
  onRemove: (index: number) => void;
  lookupEntityType: "facility" | "device" | "flow" | "equipment";
}) => {
  const { watch } = useFormContext();

  const filterType = watch(`${name}.filterType`);
  const attribute = watch(`${name}.attribute`);
  const referenceQuestion = watch(`${name}.referenceQuestion`);

  const referencedQuestion = referenceQuestion
    ? questions?.[referenceQuestion]
    : null;

  return (
    <Panel>
      <Accordion defaultActiveKeys={[id]}>
        <AccordionPanel
          dataKey={id}
          title={`Filter ${index + 1}`}
          actionRow={[
            <Button
              key={`remove-filter-${id}`}
              icon="trash"
              onClick={() => {
                onRemove(index);
              }}
              variant="ghost"
            />,
          ]}
        >
          <>
            <QuestionFilterDropdown
              variant={lookupEntityType}
              name={`${name}.attribute`}
            />

            {questions?.length ? (
              <>
                <RadioInput
                  name={`${name}.filterType`}
                  labelKey="label"
                  valueKey="value"
                  options={[
                    { label: "Static", value: "static" },
                    {
                      label: "Reference Previous Question",
                      value: "dynamic",
                    },
                  ]}
                />

                {filterType === "dynamic" ? (
                  <DropdownInput
                    name={`${name}.referenceQuestion`}
                    options={questions}
                    labelKey="prompt"
                    valueKey="question_id"
                  />
                ) : null}

                {referencedQuestion?.type === "measurement" ||
                [
                  "string",
                  "number",
                  "integer",
                  "date",
                  "date-time",
                  "multi-pick-list",
                ].includes(referencedQuestion?.data_type) ? (
                  <DropdownInput
                    label="Comparator"
                    name={`${name}.operation`}
                    options={[
                      { label: "=", value: "$eq" },
                      ...(referencedQuestion?.data_type === "string"
                        ? [{ label: "⊂", value: "$like" }]
                        : []),
                      ...(referencedQuestion?.type === "measurement" ||
                      ["number", "integer", "date", "date-time"].includes(
                        referencedQuestion?.data_type
                      )
                        ? [
                            { label: ">", value: "$gt" },
                            { label: ">=", value: "$gte" },
                            { label: "<", value: "$lt" },
                            { label: "<=", value: "$lte" },
                          ]
                        : []),
                      ...(referencedQuestion?.data_type === "multi-pick-list"
                        ? [{ label: "∈", value: "$in" }]
                        : []),
                    ]}
                    labelKey="label"
                    valueKey="value"
                    isRequired
                    isFluid
                    defaultValue={"$eq"}
                  />
                ) : null}
              </>
            ) : null}

            {filterType === "static" ? (
              <QuestionFilterStaticInput
                name={`${name}`}
                variant={lookupEntityType}
                attribute={attribute}
              />
            ) : null}
          </>
        </AccordionPanel>
      </Accordion>
    </Panel>
  );
};
