import { EmissionsCustomReportListRoute } from "#src/routes/emissions/custom-reports";
import { RoutePath } from "#src/utils/route";

/**
 * route config for /app/emissions/custom-reports/:reportId/detail
 */
export const EmissionsCustomReportDetailRoute =
  EmissionsCustomReportListRoute.concat(
    new RoutePath<"reportId">({
      path: "/:reportId/detail",
      title: "Emissions Custom Report Detail",
    })
  );

export * from "./EmissionsCustomReportDetailPage";
