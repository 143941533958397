import Immutable from "immutable";
import moment from "moment";

import {
  FETCH_ADJUSTMENT_FACTORS,
  FETCH_ADJUSTMENT_FACTORS_SUCCESS,
  FETCH_ADJUSTMENT_FACTORS_ERROR,
  USER_SIGNED_OUT,
} from "../constants/action-types";

const adjustmentFactor = Immutable.Record({
  id: undefined,
  description: undefined,
  name: undefined,
  total_volume_factor: undefined,
  stream_ids: undefined,
  is_compound: undefined,
  type: undefined,
  component_volume_factors: undefined,
});

export const data = new Immutable.Set([]);

export const AdjustmentFactorState = Immutable.Record(
  {
    isRequestInProgress: false,
    fetchError: false,
    fetchErrorMessage: "",
    fetchErrorStatus: "",
    lastRetrievedTime: 0,
    data: data,
  },
  "AdjustmentFactorState"
);

export default (state = new AdjustmentFactorState(), action) => {
  let newData = [];
  switch (action.type) {
    case FETCH_ADJUSTMENT_FACTORS:
      return state.withMutations((s) => {
        s.isRequestInProgress = true;
        s.fetchError = false;
        s.fetchErrorMessage = "";
        s.fetchErrorStatus = "";
      });

    case FETCH_ADJUSTMENT_FACTORS_SUCCESS:
      newData = new Immutable.Set(
        action.payload.map(
          (adjustmentFactorsData) => new adjustmentFactor(adjustmentFactorsData)
        )
      );

      return state.withMutations((s) => {
        s.isRequestInProgress = false;
        s.data = newData;
        s.lastRetrievedTime = moment().unix();
      });

    case FETCH_ADJUSTMENT_FACTORS_ERROR:
      return state.withMutations((s) => {
        s.isRequestInProgress = false;
        s.fetchError = true;
        s.fetchErrorMessage = action.payload.message;
        s.fetchErrorStatus = action.payload.status;
      });

    case USER_SIGNED_OUT:
      return state.withMutations((s) => {
        s.data = new Immutable.Set([]);
        s.isRequestInProgress = false;
        s.fetchError = false;
        s.fetchErrorMessage = "";
        s.fetchErrorStatus = "";
        s.lastRetrievedTime = 0;
      });

    default:
      return state;
  }
};
