import DateRangeSelector from "#common/DateSelector/DateRangeSelector";
import { GetAllObjectValue } from "#utils/objectFormatter";
import {
  FilterPillbox,
  MultiDropdownInputWithSearch,
} from "@validereinc/common-components";
import difference from "lodash/difference";
import differenceBy from "lodash/differenceBy";
import React, { useEffect, useState } from "react";
import {
  clearAllInputs,
  getAllFilterInputs,
} from "../TestManagementTableFilterHelper";
import { noStreamShortCodeOptionsMatch } from "./SamplesTableFilterHelper";

const WORKFLOW_TYPES = [
  { id: "field", name: "Field" },
  { id: "third_party_lab", name: "Third Party Lab" },
  { id: "non-routine", name: "Non-Routine" },
];

const DEFAULT_FILTER_DROPDOWN = {
  site: {
    label: "Site",
    inputs: [],
    options: [],
  },
  streamShortCode: {
    label: "Stream Short Code",
    inputs: [],
    options: [],
  },
  workflowType: {
    label: "workflow Type",
    inputs: [],
    options: WORKFLOW_TYPES,
    labelKey: "name",
  },
  sampleType: {
    label: "Sample Type",
    inputs: [],
    options: [],
  },
};

const isWorkflowSelected = (workflowTypeFilters, sample) => {
  return workflowTypeFilters.every((filter) => {
    if (filter.id === "non-routine") {
      return !!sample.workflow_instance?.test_type;
    } else {
      return filter.id !== sample.workflow_instance?.test_type;
    }
  });
};

function filterSamples(samples, filters) {
  const { site, workflowType, sampleType, streamShortCode } = filters;

  const filteredSamples = samples.filter((sample) => {
    if (site?.length > 0 && site.every((s) => s !== sample.site?.name)) {
      return false;
    }

    if (workflowType?.length > 0 && isWorkflowSelected(workflowType, sample)) {
      return false;
    }

    if (
      sampleType?.length > 0 &&
      sampleType.every((s) => s !== sample.sample_type)
    ) {
      return false;
    }

    if (
      streamShortCode?.length > 0 &&
      noStreamShortCodeOptionsMatch(streamShortCode, sample.stream_short_code)
    ) {
      return false;
    }

    return true;
  });

  return filteredSamples;
}

const useFilterInputs = (samples, dateRange, selectDate) => {
  const [filterDropdowns, setFilterDropdown] = useState({
    ...DEFAULT_FILTER_DROPDOWN,
  });

  useEffect(() => {
    if (samples.length > 0) {
      const { site, sampleType, streamShortCode } = { ...filterDropdowns };

      const siteOptions = GetAllObjectValue(samples, "site", "name")
        .map((site) => site?.name)
        .filter((option) => option);
      site.options = siteOptions;

      const sampleTypeOptions = GetAllObjectValue(
        samples,
        "sample_type"
      ).filter((option) => option);
      sampleType.options = sampleTypeOptions;

      const streamShortCodeOptions = GetAllObjectValue(
        samples,
        "stream_short_code"
      ).filter((option) => option);
      streamShortCode.options = streamShortCodeOptions;

      setFilterDropdown({
        ...filterDropdowns,
        site,
        sampleType,
        streamShortCode,
      });
    }
  }, [samples]);

  const onDropdownSelect = (selectedValue, key) => {
    const filterDropdown = { ...filterDropdowns[key] };
    filterDropdown.inputs = selectedValue;

    setFilterDropdown({ ...filterDropdowns, [key]: filterDropdown });
  };

  const clearAllFilters = () => {
    setFilterDropdown(clearAllInputs(filterDropdowns));
  };

  const onClearFilterClick = (filterObject) => {
    if (filterObject) {
      const { filterKey, name } = filterObject;
      const updatedFilterDropdown = { ...filterDropdowns[filterKey] };

      // If options are an object with the identifying property "filterKey"
      if (DEFAULT_FILTER_DROPDOWN[filterKey].filterKey) {
        updatedFilterDropdown.inputs = differenceBy(
          updatedFilterDropdown.inputs,
          [filterObject],
          "id"
        );
      } else {
        updatedFilterDropdown.inputs = difference(
          updatedFilterDropdown.inputs,
          [name]
        );
      }

      setFilterDropdown({
        ...filterDropdowns,
        [filterKey]: updatedFilterDropdown,
      });
    } else {
      clearAllFilters();
    }
  };

  const filterRow = (
    <div className="filterRow">
      {Object.keys(filterDropdowns).map((key) => {
        const { label, labelKey, options, inputs } = filterDropdowns[key];

        return (
          <MultiDropdownInputWithSearch
            key={key}
            label={label}
            labelKey={labelKey ? labelKey : null}
            dropdownKey={key}
            value={inputs}
            options={options}
            onChange={onDropdownSelect}
          />
        );
      })}

      {dateRange && (
        <DateRangeSelector
          dateRange={{
            from: dateRange.from,
            to: dateRange.until,
          }}
          onDateRangeChange={selectDate}
          icon
        />
      )}
    </div>
  );

  const filterInputs = getAllFilterInputs(filterDropdowns);

  const filteredTests = filterSamples(samples, filterInputs);

  const filterPillbox = (
    <FilterPillbox
      filterBy={filterInputs}
      onClearFilterClick={onClearFilterClick}
    />
  );

  return [filterRow, filterPillbox, filteredTests];
};

export default useFilterInputs;
