import React from "react";

import * as PropTypes from "prop-types";

import { Panel } from "@validereinc/common-components";

import history from "#routers/history";
import { linkToTestDetail } from "#routers/links";

import SampleComparisonTable from "../SampleComparisonTable";

const SampleComparisonPanel = ({ selectedSample }) => {
  const onTestClick = (selectedTest) => {
    history.push(linkToTestDetail(selectedTest?.id));
  };

  return (
    <Panel
      title="Sample Analysis"
      style={{ height: 715 }}
      loaded={selectedSample}
    >
      <SampleComparisonTable
        selectedSample={selectedSample}
        onTestClick={onTestClick}
      />
    </Panel>
  );
};

SampleComparisonPanel.propTypes = {
  selectedSample: PropTypes.object.isRequired,
};

export default SampleComparisonPanel;
