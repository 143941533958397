import { UserDetailContext } from "#src/routes/settings/users/detail/UserDetailContext";
import { useQuery } from "@tanstack/react-query";
import { StatusPanel } from "@validereinc/common-components";
import { UsersAdapter } from "@validereinc/domain";
import { datetimeFormatter } from "@validereinc/utilities";
import React, { useContext } from "react";

export const UserDetailStatusPanel = () => {
  const { userDetails } = useContext(UserDetailContext) ?? {};
  const createdByUserQuery = useQuery(
    ["users", userDetails?.data?.created_by],
    () => {
      if (!userDetails?.data?.created_by) return;

      return UsersAdapter.getOne({ id: userDetails.data.created_by });
    },
    {
      enabled: Boolean(userDetails?.data?.created_by),
      staleTime: 5 * 60 * 1000,
      select: (resp) => resp?.data,
    }
  );
  const updatedByUserQuery = useQuery(
    ["users", userDetails?.data?.updated_by],
    () => {
      if (!userDetails?.data?.updated_by) return;

      return UsersAdapter.getOne({ id: userDetails.data.updated_by });
    },
    {
      enabled: Boolean(userDetails?.data?.updated_by),
      staleTime: 5 * 60 * 1000,
      select: (resp) => resp?.data,
    }
  );

  return userDetails?.data ? (
    <StatusPanel
      isLoading={
        userDetails.isLoading ||
        (createdByUserQuery.isLoading &&
          createdByUserQuery.fetchStatus !== "idle") ||
        (updatedByUserQuery.isLoading &&
          updatedByUserQuery.fetchStatus !== "idle")
      }
      statusText={userDetails.data.status === "active" ? "Active" : "Disabled"}
      statusVariant={
        userDetails.data.status === "active" ? "success" : "default"
      }
      data={[
        {
          label: "Updated At",
          value: datetimeFormatter(new Date(userDetails.data.updated_at)),
        },
        {
          label: "Updated By",
          value: updatedByUserQuery.data?.name,
        },
        {
          label: "Created At",
          value: datetimeFormatter(new Date(userDetails.data.created_at)),
        },
        {
          label: "Created By",
          value: createdByUserQuery.data?.name,
        },
      ]}
    />
  ) : null;
};
