import { ActivityLogsTablePanel } from "#src/batteries-included-components/Panels/TablePanels/ActivityLogsTablePanel";
import {
  ActivityActionOptions,
  ActivityResourceTypeOptions,
} from "#src/constants";
import { useStorageKey } from "#src/hooks/useStorageKey";
import {
  DropdownInput,
  FilterPanel,
  TextInput,
} from "@validereinc/common-components";
import { ActivitySchema, ResourceType } from "@validereinc/domain";
import startCase from "lodash/startCase";
import React from "react";

export const ActivityLogsList = ({
  resourceId,
  resourceType,
}: {
  resourceId?: string;
  resourceType?: { name: ResourceType };
}) => {
  const { tableConfigStorageKey, filterConfigStorageKey } = useStorageKey(
    `activity-logs${resourceType ? `-${resourceType.name}` : ""}`
  );
  const pageTitle = `${
    startCase(resourceType?.name) || "Organization"
  } Change Log`;
  const activityModelKeys = ActivitySchema.keyof().Enum;

  return (
    <>
      <FilterPanel
        storageKey={filterConfigStorageKey}
        defaultActiveKeys={["Filters"]}
        filters={[
          {
            component: (
              <TextInput
                key="resource_id"
                name={activityModelKeys.resource_id}
                placeholder="Search Logs..."
                type="search"
                isInline
              />
            ),
          },
          ...(!resourceType
            ? [
                {
                  section: "Filters",
                  component: (
                    <DropdownInput
                      label="Resource Type"
                      key="resource_type"
                      name={activityModelKeys.resource_type}
                      valueKey="value"
                      labelKey="label"
                      options={ActivityResourceTypeOptions}
                      isOptionalTextShown={false}
                    />
                  ),
                },
              ]
            : []),
          {
            component: (
              <DropdownInput
                label="Type"
                name={activityModelKeys.action}
                key="action"
                valueKey="value"
                labelKey="label"
                options={ActivityActionOptions}
                placeholder="Select a Change Type"
                isInline
                isLabelShown={false}
                isOptionalTextShown={false}
              />
            ),
          },
        ]}
      />
      <ActivityLogsTablePanel
        resourceId={resourceId}
        resourceType={resourceType?.name}
        title={pageTitle}
        tableConfigStorageKey={tableConfigStorageKey}
        filterConfigStorageKey={filterConfigStorageKey}
      />
    </>
  );
};
