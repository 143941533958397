import {
  KeyValuePanel,
  Link,
  WithTooltip,
} from "@validereinc/common-components";
import * as PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import { useNavigate } from "#routers/hooks";
import { linkToDeviceDetail, linkToFacilities } from "#routers/links";
import useLocalization from "#src/hooks/useLocalization";

import {
  ALERT_CONDITION_TOOLTIP,
  ALERT_DURATION_TOOLTIP,
  ALERT_THRESHOLD_TOOLTIP,
  ALERT_WINDOW_TOOLTIP,
} from "#components/AlertManagement/AlertConstants";
import MeasurementsService from "#components/Services/MeasurementsService";
import startCase from "lodash/startCase";

const ConditionPanel = ({ condition, index, updateUniqueCounts }) => {
  const [measurementSeries, setMeasurementSeries] = useState();
  const navigate = useNavigate();
  const { localize } = useLocalization();

  useEffect(() => {
    if (condition?.measurement_series_id) {
      (async () => {
        const { data: newMeasurementSeries } =
          await MeasurementsService.getMeasurementSource(
            condition?.measurement_series_id
          );

        updateUniqueCounts({
          device: newMeasurementSeries.device_id,
          measurementType: newMeasurementSeries.measurement_type,
          facility: newMeasurementSeries.device.facility_id,
        });

        setMeasurementSeries(newMeasurementSeries);
      })();
    }
  }, [condition?.measurement_series_id]);

  const panelTitle = (
    <WithTooltip
      tooltip={ALERT_CONDITION_TOOLTIP}
      align="start"
    >
      Condition #{index + 1}
    </WithTooltip>
  );

  const data = [
    {
      title: localize("facility"),
      value: (
        <Link
          onClick={() =>
            navigate({
              pathname: linkToFacilities(
                measurementSeries?.device?.facility?.id
              ),
            })
          }
          label={measurementSeries?.device?.facility?.name ?? "-"}
        />
      ),
    },
    {
      title: "Device Type",
      value: measurementSeries?.device?.type?.name,
    },
    {
      title: "Device",
      value: (
        <Link
          onClick={() =>
            navigate({
              pathname: linkToDeviceDetail(measurementSeries?.device?.id),
            })
          }
          label={measurementSeries?.device?.name ?? "-"}
        />
      ),
    },
    {
      title: "Measurement",
      value: measurementSeries?.measurement_type_name,
    },
    {
      title: "Aggregation Function",
      value: startCase(condition?.aggregation_function),
    },
    {
      title: "Operator",
      value: condition?.operator,
    },
    {
      title: "Threshold",
      value: condition?.threshold,
      description: ALERT_THRESHOLD_TOOLTIP,
    },
    {
      title: "Window",
      value: condition?.window,
      description: ALERT_WINDOW_TOOLTIP,
    },
    {
      title: "Duration",
      value: condition?.duration,
      description: ALERT_DURATION_TOOLTIP,
    },
  ];

  return (
    <KeyValuePanel
      panelMaxColumnCount={2}
      panelProps={{
        title: panelTitle,
        loaded: !!measurementSeries,
        isFluidY: false,
      }}
      data={data}
    />
  );
};

ConditionPanel.propTypes = {
  condition: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  updateUniqueCounts: PropTypes.func,
};

export default ConditionPanel;
