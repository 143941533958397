import React from "react";
import { MeasurementField } from "./MeasurementField";
import { QuestionField } from "./QuestionField";
import {
  FormMeasurementType,
  FormQuestionType,
  InputFieldType,
  LookupFieldType,
} from "@validereinc/domain";

export const FormSubmissionField = (
  props: {
    name: string;
    type: "question" | "measurement";
    dataKey: string;
  } & (FormMeasurementType | FormQuestionType)
) => {
  const { type, dataKey, ...questionProps } = props;
  const data_type =
    (props as InputFieldType).data_type ?? (props as LookupFieldType).data_type;

  return (
    <>
      {type === "question" ? (
        <QuestionField
          key={dataKey}
          data_type={data_type}
          {...questionProps}
        />
      ) : null}
      {type === "measurement" ? (
        <MeasurementField
          key={dataKey}
          {...questionProps}
        />
      ) : null}
    </>
  );
};
