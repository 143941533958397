import SummaryInformation from "#common/SummaryInformation/SummaryInformation";
import config from "#config";
import { getTimeStringFromDate } from "#utils/timeFormatter";
import { Button } from "@validereinc/common-components";
import * as PropTypes from "prop-types";
import React from "react";

const SelectedSampleSummary = ({ sample, onRemoveSampleClick, disabled }) => {
  const {
    sample_name,
    sample_point_id,
    workflow_instance,
    sample_type,
    started_at,
    ended_at,
  } = sample;

  return (
    <div
      className={`fieldManualEntry__selectedSample ${
        disabled ? "disabled" : null
      }`}
    >
      <b>{`${sample_name} ${sample_point_id ? `(${sample_point_id})` : ""}`}</b>
      <div className="fieldManualEntry__selectedSample__body">
        <SummaryInformation
          title="Workflow"
          value={workflow_instance?.test_type ?? "Non-routine"}
        />

        <SummaryInformation
          title="Type"
          value={sample_type}
        />

        <SummaryInformation
          title="From"
          value={getTimeStringFromDate(started_at, config.DATETIME_FORMAT)}
        />

        <SummaryInformation
          title="To"
          value={getTimeStringFromDate(ended_at, config.DATETIME_FORMAT)}
        />
      </div>

      {onRemoveSampleClick && (
        <Button
          className="fieldManualEntry__removeSelectedSample"
          variant="error-outline"
          onClick={onRemoveSampleClick}
          disabled={disabled}
        >
          Remove Sample
        </Button>
      )}
    </div>
  );
};

SelectedSampleSummary.propTypes = {
  sample: PropTypes.object.isRequired,
  onRemoveSampleClick: PropTypes.func,
  disabled: PropTypes.bool,
};

export default SelectedSampleSummary;
