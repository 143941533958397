import { RoutePath } from "#utils/route";
import { FlowRecordDetailRoute } from "#routes/organization/flows/[flowId]/detail/record/[recordId]";

export type FlowRecordValuePageParamsType = {
  flowId: string;
  recordId: string;
  measurementType: string;
};

export const FlowRecordValueDetailRoute = FlowRecordDetailRoute.concat(
  new RoutePath<"measurementType">({
    path: "/value/:measurementType",
    title: "Record Value Details",
  })
);
