import { useMeasurementTypes } from "#src/contexts/MeasurementTypeContext";
import { DataTable, HeaderType } from "@validereinc/common-components";
import { CalculatorResultType } from "@validereinc/domain";
import isPlainObject from "lodash/isPlainObject";
import React from "react";

export const useCalculatorResultsMeasurementHeaders = (
  calculatorResults?: CalculatorResultType[]
): Array<HeaderType<CalculatorResultType>> => {
  const { getUnitByType, getPrecisionByType, getTypeName, getUnitName } =
    useMeasurementTypes();

  if (!calculatorResults?.length) {
    return [];
  }

  const uniqueMeasurementTypes = Array.from(
    new Set(
      calculatorResults
        .filter(
          (calcResult) =>
            calcResult.measurement && isPlainObject(calcResult.measurement)
        )
        .flatMap(({ measurement }) => Object.keys(measurement))
    )
  ).sort((a, b) => a?.localeCompare(b));

  return uniqueMeasurementTypes.map((key) => ({
    key: `measurement.${key}`,
    label: getTypeName(key),
    isSortable: true,
    renderComponent: ({ item }) => {
      const value = item?.measurement?.[key];

      if (!value) {
        return "-";
      }

      return (
        <DataTable.DataRow.NumberCell
          value={Number(value)}
          unit={getUnitName(getUnitByType(key))}
          precision={getPrecisionByType(key)}
        />
      );
    },
  }));
};
