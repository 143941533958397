import { ValidateCSVSafeInput } from "#utils/formValidator";
import { SelectInput, TextInput, Title } from "@validereinc/common-components";
import * as PropTypes from "prop-types";
import React, { useEffect } from "react";
import { getSourceTitle } from "./BalanceHelper";
import "./BalancesForm.scss";
import { COMPONENTS } from "./BalancesUpsertsConstant";
import SourceInput from "./SourceSelection/SourceInput";

const BALANCE_TYPES = ["gas", "liquid", "oil"];

const VOLUME_UNIT_OPTIONS = ["m³", "e3m3", "Mcf", "gal"];

const SHOW_AGGREGATE_OPTIONS = ["yes", "no"];

const SYNC_INLET_WITH_REFERENCE_KEY = "syncInletWithReference";

const SYNC_INLET_INVENTORY_WITH_REFERENCE_KEY =
  "syncInletInventoryWithReference";

const INLET_INVENTORIES_KEY = "inlet_inventories";

const INLET_STREAMS_KEY = "inlet_streams";

const REFERENCE_INVENTORIES_KEY = "reference_inventories";

const REFERENCE_STREAMS_KEY = "reference_streams";

const BalancesForm = ({
  form,
  formState,
  balancesList,
  balancesDetail,
  haveWritePermission,
  sourceSelectionOptions,
  openSourceSelectionModal,
  onSourceChecked,
  editSourceClick,
  lastUpdatedTime,
}) => {
  const inputs = form.watch();

  useEffect(() => {
    if (inputs[SYNC_INLET_WITH_REFERENCE_KEY] && formState === "enabled") {
      form.setValue(REFERENCE_STREAMS_KEY, form.getValues(INLET_STREAMS_KEY));
    }
  }, [inputs?.[SYNC_INLET_WITH_REFERENCE_KEY], inputs[INLET_STREAMS_KEY]]);

  useEffect(() => {
    if (
      inputs[SYNC_INLET_INVENTORY_WITH_REFERENCE_KEY] &&
      formState === "enabled"
    ) {
      form.setValue(
        REFERENCE_INVENTORIES_KEY,
        form.getValues(INLET_INVENTORIES_KEY)
      );
    }
  }, [
    inputs[SYNC_INLET_INVENTORY_WITH_REFERENCE_KEY],
    inputs[INLET_INVENTORIES_KEY],
  ]);

  const isInventoryType = (sourceKey) => sourceKey.includes("inventories");

  const onSyncInletClick = (sourceKey) => {
    if (isInventoryType(sourceKey)) {
      form.setValue(
        SYNC_INLET_INVENTORY_WITH_REFERENCE_KEY,
        !inputs[SYNC_INLET_INVENTORY_WITH_REFERENCE_KEY]
      );
    } else {
      form.setValue(
        SYNC_INLET_WITH_REFERENCE_KEY,
        !inputs[SYNC_INLET_WITH_REFERENCE_KEY]
      );
    }
  };

  const shareProps = {
    isDisabled: formState !== "enabled",
    showIcon: true,
  };

  return (
    <>
      <Title
        className="balancesForm__title"
        type="subheader"
      >
        General
      </Title>

      <TextInput
        {...shareProps}
        name="name"
        label="Name"
        type="text"
        placeholder="name"
        validate={{
          validateName: (name) => {
            const checkNameCSVSafe = ValidateCSVSafeInput("Name", name);

            if (checkNameCSVSafe) {
              return checkNameCSVSafe;
            }

            const existingBalancesName = balancesList.find((existedBalance) => {
              return existedBalance.name === name;
            });

            const isNameChanged = balancesDetail?.name !== name;

            if (existingBalancesName && isNameChanged) {
              return "Balances name must be unique.";
            }

            return null;
          },
        }}
        isRequired
      />

      <SelectInput
        {...shareProps}
        name="type"
        label="Type"
        options={BALANCE_TYPES}
        isRequired
        ignoreCase
      />

      <SelectInput
        {...shareProps}
        name="components"
        label="Components"
        options={COMPONENTS}
        closeMenuOnSelect={false}
        ignoreCase
        isMulti
        isRequired
      />

      <SelectInput
        {...shareProps}
        name="showTotalVolume"
        label="Show Total Volume"
        options={SHOW_AGGREGATE_OPTIONS}
        ignoreCase
        isRequired
      />

      {form.getValues("type") === "gas" && (
        <SelectInput
          {...shareProps}
          name="showEnergy"
          label="Show Energy"
          options={SHOW_AGGREGATE_OPTIONS}
          ignoreCase
          isRequired
        />
      )}

      <SelectInput
        {...shareProps}
        name="volumeUnit"
        label="Unit"
        options={VOLUME_UNIT_OPTIONS}
        ignoreCase
        isRequired
      />

      {Object.keys(sourceSelectionOptions).map((sourceKey) => {
        return (
          <SourceInput
            {...shareProps}
            form={form}
            key={sourceKey}
            sourceKey={sourceKey}
            title={getSourceTitle(sourceKey)}
            sources={form.getValues(sourceKey)}
            openSourceSelectionModal={openSourceSelectionModal}
            onSourceChecked={onSourceChecked}
            onEditClick={editSourceClick}
            showMultiplier
            lastUpdatedTime={lastUpdatedTime}
            haveWritePermission={haveWritePermission}
            onSyncInletClick={onSyncInletClick}
            isInventory={isInventoryType(sourceKey)}
          />
        );
      })}
    </>
  );
};

BalancesForm.propTypes = {
  form: PropTypes.object.isRequired,
  formState: PropTypes.string.isRequired,
  balancesDetail: PropTypes.object,
  haveWritePermission: PropTypes.bool.isRequired,
  sourceSelectionOptions: PropTypes.object.isRequired,
  lastUpdatedTime: PropTypes.instanceOf(Date),
  openSourceSelectionModal: PropTypes.func,
  onSourceChecked: PropTypes.func,
  editSourceClick: PropTypes.func,
  balancesList: PropTypes.array,
};

export default BalancesForm;
