import {
  Button,
  Form,
  Modal,
  TextAreaInput,
  TextInput,
  Title,
  useForm,
} from "@validereinc/common-components";
import classNames from "classnames/bind";
import * as PropTypes from "prop-types";
import React from "react";

import { useNavigate } from "#routers/hooks";

import DashboardService from "#components/Services/DashboardService";

import { linkToCustomDashboardDetails } from "#src/routes/dashboard/custom-dashboard/[customDashboardId]/details";
import styles from "./CreateDashboardModal.module.scss";

const cx = classNames.bind(styles);

const { Header, Body, Footer } = Modal;

const CreateDashboardModal = ({ isOpen, onClose, dashboard }) => {
  const navigate = useNavigate();

  const form = useForm({ defaultValues: dashboard });

  const onSubmit = async (values) => {
    if (!dashboard?.id) {
      const {
        data: { id },
      } = await DashboardService.createDashboard(values);

      navigate({ pathname: linkToCustomDashboardDetails(id) });
    } else {
      await DashboardService.editDashboard({
        dashboardId: dashboard?.id,
        ...values,
      });

      onClose(true);
    }
  };

  return (
    <Modal
      open={isOpen}
      onClose={() => onClose()}
    >
      <Header>
        <Title>{!dashboard ? "Create New" : "Edit"} Dashboard</Title>
      </Header>

      <Body>
        <Form {...form}>
          <TextInput
            name="name"
            label="Dashboard Name"
            isRequired
          />

          <TextAreaInput
            name="description"
            label="Description"
          />
        </Form>
      </Body>

      <Footer>
        <div className={cx("actions")}>
          <Button onClick={onClose}>Cancel</Button>

          <Button
            variant="primary"
            onClick={form.handleSubmit(onSubmit)}
          >
            {!dashboard ? "Create" : "Save"}
          </Button>
        </div>
      </Footer>
    </Modal>
  );
};

CreateDashboardModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  dashboard: PropTypes.obj,
  onClose: PropTypes.func.isRequired,
};

export default CreateDashboardModal;
