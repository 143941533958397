import { SortListByType } from "#utils/sorters";
import {
  Button,
  MultiDropdownInputWithSearch,
  Panel,
  useAlert,
} from "@validereinc/common-components";
import * as PropTypes from "prop-types";
import React from "react";
import { DropdownButton, MenuItem } from "react-bootstrap/";
import FontAwesome from "react-fontawesome";
import "./AccountingPeriodSelectionPanel.scss";
import { formatDate } from "./RecordOfQualityHelper";

const ApproveButton = (props) => {
  const { numberApprovalRequired } = props;

  return (
    <Button
      variant="primary"
      className="pull-right"
      onClick={() => {
        props.setShowConfirmationModal(true);
      }}
      disabled={!props.haveWritePermissions || numberApprovalRequired !== 0}
      isLoading={numberApprovalRequired === null}
    >
      <span>
        {numberApprovalRequired !== 0 ? (
          <>
            <div className="count">{numberApprovalRequired ?? "-"}</div>
            Approval Remaining
          </>
        ) : (
          <>
            <FontAwesome
              className="icon"
              name="check-circle"
            />{" "}
            Approve
          </>
        )}
      </span>
    </Button>
  );
};

const AccountingPeriodSelectionPanel = (props) => {
  const { addAlert } = useAlert();
  const {
    siteSystems,
    accountingPeriods,
    selectedAccountingPeriod,
    numberApprovalRequired,
    isLoading,
    setShowReportModal,
  } = props;

  function getAccountingPeriodOptions(
    accountingPeriods,
    selectedAccountingPeriod
  ) {
    const filteredAccountingPeriod = accountingPeriods.filter(
      (accountingPeriod) => {
        return (
          accountingPeriod?.site_system.id ===
          selectedAccountingPeriod?.site_system?.id
        );
      }
    );

    return SortListByType(filteredAccountingPeriod, "from", "desc");
  }

  const onDownloadSelect = () => {
    addAlert({
      variant: "success",
      message: "Your files will start downloading shortly.",
    });
  };

  const AccountingPeriodOptions = getAccountingPeriodOptions(
    accountingPeriods,
    selectedAccountingPeriod
  );

  return (
    <Panel className="accountingPeriodSelectionPanel">
      <div className="accountingPeriodSelectionPanel__filterContainers">
        <div className="title">Site:</div>

        <MultiDropdownInputWithSearch
          width={300}
          isMulti={false}
          options={siteSystems}
          labelKey="name"
          value={
            selectedAccountingPeriod.site_system
              ? [selectedAccountingPeriod.site_system]
              : []
          }
          onChange={props.onSiteSystemSelect}
          isLoading={isLoading}
          ignoreCase
        />

        <div className="title">Period:</div>

        <MultiDropdownInputWithSearch
          width={200}
          isMulti={false}
          options={AccountingPeriodOptions}
          labelKey="from"
          value={
            selectedAccountingPeriod
              ? [
                  {
                    ...selectedAccountingPeriod,
                    from: formatDate(selectedAccountingPeriod.from),
                  },
                ]
              : []
          }
          onChange={props.onAccountingPeriodSelect}
          isLoading={isLoading}
          customLabelFormat={formatDate}
          ignoreCase
        />
      </div>

      <div className="accountingPeriodSelectionPanel__actionContainers">
        {selectedAccountingPeriod.files && (
          <DropdownButton
            className="defaultButton"
            title="Exports"
            id={`dropdown-basic`}
            pullRight
          >
            {selectedAccountingPeriod.files.map((file) => (
              <li key={file.url}>
                <a
                  role="menuitem"
                  href={file.url}
                  download
                  onClick={() => onDownloadSelect()}
                >
                  <FontAwesome
                    name="download"
                    className="icon buttonLink"
                  />

                  {file.filename}
                </a>
              </li>
            ))}

            {selectedAccountingPeriod.balance_type !== "oil" && (
              <MenuItem
                className="generateReportOption"
                onSelect={() => setShowReportModal(true)}
              >
                <FontAwesome
                  name="plus"
                  className="icon"
                />
                Generate Recombined Report
              </MenuItem>
            )}
          </DropdownButton>
        )}

        {selectedAccountingPeriod.is_locked ? (
          <div className="approveBy">
            Approved By: {selectedAccountingPeriod.locked_by?.name ?? "-"}
          </div>
        ) : (
          <ApproveButton
            numberApprovalRequired={numberApprovalRequired}
            setShowConfirmationModal={props.setShowConfirmationModal}
            haveWritePermissions={props.haveWritePermissions}
          />
        )}
      </div>
    </Panel>
  );
};

ApproveButton.propTypes = {
  numberApprovalRequired: PropTypes.number,
  setShowConfirmationModal: PropTypes.func,
  haveWritePermissions: PropTypes.bool,
};

AccountingPeriodSelectionPanel.propTypes = {
  siteSystems: PropTypes.array,
  accountingPeriods: PropTypes.array,
  selectedAccountingPeriod: PropTypes.object,
  numberApprovalRequired: PropTypes.number,
  isLoading: PropTypes.bool,
  haveWritePermissions: PropTypes.bool,
  setShowConfirmationModal: PropTypes.func,
  addAlertMessage: PropTypes.func,
  onSiteSystemSelect: PropTypes.func,
  onAccountingPeriodSelect: PropTypes.func,
  setShowReportModal: PropTypes.func,
};

export default AccountingPeriodSelectionPanel;
