import LineChart from "#common/Chart/LineChart";
import ReactLoader from "#components/LoadingBar/ReactLoader";
import { getFormattedNumberWithUnit } from "@validereinc/utilities";
import * as PropTypes from "prop-types";
import React from "react";
import { AutoSizer } from "react-virtualized";
import "./InventoryChart.scss";

const InventoryChart = ({
  chartData,
  chartDataLoadingState,
  dateRangeSelector,
  height,
}) => {
  const unit = chartData?.[0]?.volume?.unit ?? "";

  return (
    <div
      className="inventoryChart"
      style={{ height }}
    >
      {dateRangeSelector}

      <ReactLoader
        loaded={chartDataLoadingState !== "loading"}
        position={50}
      >
        <div className="inventoryChart__container">
          <AutoSizer>
            {({ width, height }) => (
              <LineChart
                width={width}
                height={height}
                className="inventoryChart__container"
                yLabel={`Volume (${unit})`}
                xLabel="Date"
                data={chartData}
                getCategory={(data) => data.streamName}
                getValue={(data) => getFormattedNumberWithUnit(data.volume)}
              />
            )}
          </AutoSizer>
        </div>
      </ReactLoader>
    </div>
  );
};

InventoryChart.propTypes = {
  chartData: PropTypes.array.isRequired,
  chartDataLoadingState: PropTypes.string,
  dateRangeSelector: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  height: PropTypes.number,
};

export default InventoryChart;
