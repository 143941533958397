import DataMappingService from "#components/Services/DataMappingService";
import { getBreadcrumbsObject } from "#routers/breadcrumbsHelper";
import { useNavigate, useSearchParams } from "#routers/hooks";
import {
  linkToCreateDataMapping,
  linkToCreateDataMappingEntityMapping,
  linkToDataMapping,
} from "#routers/links";
import {
  Button,
  FileInput,
  Form,
  Page,
  Panel,
  Stepper,
  useForm,
} from "@validereinc/common-components";
import classNames from "classnames/bind";
import * as PropTypes from "prop-types";
import React from "react";
import { STEPS, TITLE, UPLOAD_DATA_LABEL } from "../DataMappingConstants";
import styles from "./CreateDataMapping.module.scss";

const cx = classNames.bind(styles);

const CURRENT_STEP = 2;

const FileUploadForm = ({ breadcrumbs }) => {
  // Expect data mapping id to be provided
  const [{ dataMappingId }] = useSearchParams();

  const form = useForm({ defaultValues: { dataMappingId } });

  const navigate = useNavigate();

  const handleOnNextClick = async (formValues) => {
    const {
      data: { task_id },
    } = await DataMappingService.uploadCreateDataMappingFile(formValues);

    navigate({
      pathname: linkToCreateDataMappingEntityMapping(),
      query: {
        dataMappingId,
        taskId: task_id,
      },
    });
  };

  const handleOnCancelClick = () => {
    navigate({ pathname: linkToDataMapping() });
  };

  const handleOnPreviousClick = () => {
    navigate({ pathname: linkToCreateDataMapping(), query: { dataMappingId } });
  };

  const actionRow = (
    <Stepper
      steps={STEPS}
      activeStep={CURRENT_STEP}
    />
  );

  const footer = (
    <div className={cx("footer")}>
      <Button onClick={handleOnCancelClick}>Cancel</Button>
      <div className={cx("stepActionContainer")}>
        <Button onClick={handleOnPreviousClick}>Previous</Button>
        <Button
          variant="primary"
          onClick={form.handleSubmit(handleOnNextClick)}
        >
          Next
        </Button>
      </div>
    </div>
  );

  return (
    <Page
      title={TITLE}
      breadcrumbs={getBreadcrumbsObject(breadcrumbs)}
      actionRow={actionRow}
      footer={footer}
      footerAlign="left"
    >
      <Panel title={UPLOAD_DATA_LABEL}>
        <div style={{ minHeight: "60vh" }}>
          <p>
            Upload a data file from which to map columns to entity attributes.
          </p>

          <Form {...form}>
            <FileInput
              name="file"
              label="File Selection"
              placeholder="Drag a .csv or .xls file here to update records for this connection (Max. 20MB)"
              showIcon={true}
              isRequired={true}
            />
          </Form>
        </div>
      </Panel>
    </Page>
  );
};

FileUploadForm.propTypes = {
  breadcrumbs: PropTypes.array.isRequired,
};

export default FileUploadForm;
