import { havePermission } from "#redux/reducers/permissions";
import UserService from "#services/UserService";
import { Button } from "@validereinc/common-components";
import * as PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import SettingSection from "../../SettingSection";
import ApiTokensTable from "./ApiTokensTable";
import CreateApiTokenModal from "./CreateApiTokenModal";
import DeleteApiTokenModal from "./DeleteApiTokenModal";

const CREATE_API_TOKEN_MODAL_KEY = "create";
const DELETE_API_TOKEN_MODAL_KEY = "delete";
const HEIGHT_OFFSET = 250;

const mapStateToProps = (state) => {
  return {
    hasWritePermission: havePermission(state.permissions)(
      "core:user.management",
      "write"
    ),
  };
};

const useFetchApiTokens = (userId) => {
  const [apiTokens, setApiTokens] = useState([]);
  const [loadingState, setLoadingState] = useState("loading");
  const [lastUpdateTime, setLastUpdatedTime] = useState(new Date());

  useEffect(() => {
    if (userId) {
      UserService.getApiTokens(userId)
        .then(({ data }) => {
          setApiTokens(data);
        })
        .finally(() => {
          setLoadingState("loaded");
        });
    }
  }, [lastUpdateTime, userId]);

  return [apiTokens, loadingState, setLastUpdatedTime];
};

const ApiTokens = ({ userId, height, hasWritePermission }) => {
  const [apiTokens, loadingState, setLastUpdatedTime] =
    useFetchApiTokens(userId);

  const [apiTokenDetail, setApiTokenDetail] = useState(null);

  const [modalKey, setModalKey] = useState(null);

  const updateApiTokenList = () => {
    setLastUpdatedTime(new Date());
  };

  const openCreateApiTokenModal = () => {
    setModalKey(CREATE_API_TOKEN_MODAL_KEY);
  };

  const onDeleteApiTokenButtonClick = (detail) => {
    setApiTokenDetail(detail);
    setModalKey(DELETE_API_TOKEN_MODAL_KEY);
  };

  const onModalClose = () => {
    setApiTokenDetail(null);
    setModalKey(null);
  };

  return (
    <SettingSection
      title="API Tokens"
      message="Use API tokens to make programmatic API calls to Validere360."
    >
      {hasWritePermission ? (
        <Button
          variant="primary"
          onClick={openCreateApiTokenModal}
        >
          Create API Token
        </Button>
      ) : null}

      <ApiTokensTable
        height={height - HEIGHT_OFFSET}
        apiTokens={apiTokens}
        loadingState={loadingState}
        onDeleteApiTokenButtonClick={onDeleteApiTokenButtonClick}
        hasWritePermission={hasWritePermission}
      />

      {modalKey === CREATE_API_TOKEN_MODAL_KEY && userId ? (
        <CreateApiTokenModal
          show={true}
          userId={userId}
          updateApiTokenList={updateApiTokenList}
          onHide={onModalClose}
        />
      ) : null}

      {modalKey === DELETE_API_TOKEN_MODAL_KEY && apiTokenDetail ? (
        <DeleteApiTokenModal
          show={true}
          userId={userId}
          apiTokenDetail={apiTokenDetail}
          updateApiTokenList={updateApiTokenList}
          onHide={onModalClose}
        />
      ) : null}
    </SettingSection>
  );
};

ApiTokens.propTypes = {
  userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.number.isRequired,
  hasWritePermission: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps, null)(ApiTokens);
