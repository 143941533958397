export const linkToFormCategoryDetails = (formCategoryId = ":categoryId") =>
  `/app/forms/categories/${formCategoryId}`;

export const FORM_CATEGORY_DETAILS_PAGE_TITLE_PLACEHOLDER =
  "Form Category:categoryId";

export const FORM_CATEGORY_DETAILS_BREADCRUMB = {
  title: FORM_CATEGORY_DETAILS_PAGE_TITLE_PLACEHOLDER,
  path: linkToFormCategoryDetails,
};

export * from "./FormCategoryDetailsPage";
