import { useListNetworkCalculators } from "#hooks/adapters/useCalculators";
import { useNetworkCalculationResult } from "#routes/organization/networks/[networkId]/detail/calculation-result/[period]/details/NetworkCalculationResultPage.helpers";
import { FormulaRenderer, Panel } from "@validereinc/common-components";
import React from "react";

export const NetworkCalculationResultFormulaPanel = () => {
  const { network } = useNetworkCalculationResult();

  const networkQuery = useListNetworkCalculators();

  const calculator = networkQuery.data?.calculators.find(
    ({ id }: { id: string }) =>
      id === network?.default_estimation_method?.analytics_calculator_id
  );

  const lastCalculatorVersion = calculator?.versions?.find(
    ({ version }: { version: string }) =>
      version === calculator?.default_version
  );

  return (
    <>
      {typeof lastCalculatorVersion?.documentation === "object" &&
      Object.keys(lastCalculatorVersion?.documentation ?? {}).length ? (
        <Panel
          isFluidY={false}
          style={{ marginBottom: 0 }}
        >
          <FormulaRenderer
            data={lastCalculatorVersion?.documentation}
            isLoading={calculator?.status === "loading"}
          />
        </Panel>
      ) : null}
    </>
  );
};
