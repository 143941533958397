import React from "react";
import * as PropTypes from "prop-types";
import Immutable from "immutable";
import { Alert, Collapse } from "react-bootstrap";
import { connect } from "react-redux";
import "./AlertMessages.css";
import { AlertMessage as AlertMessageObject } from "../Redux/reducers/alertMessages";
import { removeAlertMessage } from "../Redux/actions/alertMessages";

const AlertMessage = ({ alertMessage, onDismiss }) => {
  return (
    <li
      style={{ listStyle: "none" }}
      className="alertMessageContainer__alertMessage"
    >
      <Collapse in={true}>
        <Alert
          bsStyle={alertMessage.type}
          onDismiss={() => onDismiss(alertMessage)}
        >
          {alertMessage.title && <h5>{alertMessage.title}</h5>}
          <div>{alertMessage.message}</div>
        </Alert>
      </Collapse>
    </li>
  );
};

AlertMessage.propTypes = {
  alertMessage: PropTypes.instanceOf(AlertMessageObject).isRequired,
  onDismiss: PropTypes.func.isRequired,
};

const mapStateToProps = ({ alertMessages }) => {
  return {
    list: alertMessages.list,
  };
};

const mapDispatchToProps = {
  removeAlertMessage,
};

export const AlertMessages = ({ list, removeAlertMessage }) => {
  return (
    <ol className="alertMessageContainer">
      {list
        .toArray()
        .reverse()
        .map((alertMessage) => {
          return (
            <AlertMessage
              key={alertMessage.timestamp}
              alertMessage={alertMessage}
              onDismiss={removeAlertMessage}
            />
          );
        })}
    </ol>
  );
};

AlertMessages.propTypes = {
  list: PropTypes.instanceOf(Immutable.List).isRequired,
  removeAlertMessage: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(AlertMessages);
