import { Tooltip } from "@validereinc/common-components";
import * as PropTypes from "prop-types";
import React from "react";
import FontAwesome from "react-fontawesome";
import "./AdjustmentCell.scss";
import { getAdjustmentFactorValue } from "./AdjustmentFactorsHelper";

const AdjustmentCell = (props) => {
  if (props.type === "total") {
    const totalVolumeFactorValue = getAdjustmentFactorValue(
      props.total_volume_factor
    );

    return (
      <div>
        {totalVolumeFactorValue
          ? `${totalVolumeFactorValue}x Total Volume`
          : "-"}
      </div>
    );
  } else {
    const { component_volume_factors } = props;

    const factors = Object.entries(component_volume_factors);
    const numFactors = factors.length;

    const tooltip = (
      <>
        {factors.map(([factor, value]) => {
          return (
            <li key={factor}>
              <span>{factor}</span>
              <span className="pull-right">{value}x</span>
            </li>
          );
        })}
      </>
    );

    return (
      <Tooltip
        content={tooltip}
        className="adjustmentCell__tooltip"
        title="Factors"
      >
        {`${numFactors} Component${numFactors > 1 ? "s" : ""}`}
        <FontAwesome
          name="info-circle"
          style={{ marginLeft: "3px" }}
        />
      </Tooltip>
    );
  }
};

AdjustmentCell.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  component_volume_factors: PropTypes.object,
  total_volume_factor: PropTypes.number,
};

export default AdjustmentCell;
