export const PERMISSION_OPTIONS = {
  read_and_write: "Read and Write",
  read: "Read",
  disabled: "Disabled",
};

export const stripProductFromPermission = (permissionName) => {
  const [_product, rest] = permissionName.split(":");

  if (_product === "commercial" || _product === "esg") {
    return permissionName;
  }

  return rest;
};
