import { RecordContext, RecordProvider } from "#src/contexts/RecordContext";
import {
  KeyValueTile,
  MetricTile,
  Panel,
  PillProps,
  StatusPanel,
} from "@validereinc/common-components";
import React, { useContext } from "react";

import { useParams } from "#routers/hooks";
import FileDownloadLink from "#src/components/Common/FileDownloadLink/FileDownloadLink";
import FileExtensionIcon from "#src/components/Common/SVG/FileExtensionIcon/FileExtensionIcon";
import { useGetOneUser } from "#src/components/hooks/adapters/useUsers";
import { useMeasurementTypes } from "#src/contexts/MeasurementTypeContext";
import { fileExtensionFromFilename } from "#src/utils/download";
import { RecordValueStatusType } from "@validereinc/domain";
import { datetimeFormatter, getFormattedNumber } from "@validereinc/utilities";
import classNames from "classnames/bind";
import truncate from "lodash/truncate";
import styles from "./RecordValueSummaryPanel.module.css";

const cx = classNames.bind(styles);

export const RecordValueSummaryPanelContent = () => {
  const { record, isLoading: isRecordLoading } =
    useContext(RecordContext) || {};
  const { measurementType } = useParams<{ measurementType: string }>();
  const {
    getTypeName,
    getUnitName,
    isLoading: isMeasurementTypesLoading,
  } = useMeasurementTypes();

  const isLoading = isRecordLoading || isMeasurementTypesLoading;

  const recordValue = record?.values.find(
    ({ measurement_type }) => measurement_type === measurementType
  );

  const usersQuery = useGetOneUser({ id: recordValue?.updated_by });

  const recordStatusOptions: Record<
    RecordValueStatusType,
    { label: string; variant: PillProps["variant"] }
  > = {
    locked: { label: "Locked", variant: "default" },
    unlocked: { label: "Unlocked", variant: "info" },
  };

  const data = [
    {
      label: "Updated At",
      value: recordValue?.updated_at
        ? datetimeFormatter(new Date(recordValue.updated_at)).replace(",", "")
        : null,
    },
    {
      label: "Updated By",
      value: usersQuery.data?.name,
    },
  ];

  return (
    <>
      <StatusPanel
        isFluidY={false}
        data={data}
        statusText={
          recordValue?.status
            ? recordStatusOptions[recordValue?.status].label
            : ""
        }
        statusVariant={
          recordValue?.status
            ? recordStatusOptions[recordValue?.status].variant
            : "default"
        }
        isLoading={isLoading}
      />
      <Panel
        title={getTypeName(measurementType)}
        isFluidY={false}
      >
        <div className={cx("items")}>
          <MetricTile
            isLoading={isLoading}
            title={getTypeName(measurementType)}
            value={
              recordValue ? getFormattedNumber(recordValue.value, null) : ""
            }
            unit={getUnitName(
              recordValue?.measurement_unit ?? "",
              recordValue?.value
            )}
          />
          {recordValue?.note && (
            <KeyValueTile
              title="Note"
              value={truncate(recordValue.note, { length: 500 })}
              isLoading={isLoading}
            />
          )}
          {recordValue?.attachment_filename && (
            <KeyValueTile
              title="Attachment"
              isLoading={isLoading}
              value={
                <div className={cx("attachment-name-and-icon")}>
                  <FileExtensionIcon
                    extension={fileExtensionFromFilename(
                      recordValue.attachment_filename
                    )}
                  />
                  <FileDownloadLink
                    fileName={recordValue.attachment_filename}
                    fileUrl={recordValue?.attachment_ref ?? ""}
                    hideIcon
                  />
                </div>
              }
            />
          )}
        </div>
      </Panel>
    </>
  );
};

export const RecordValueSummaryPanel = () => {
  return (
    <RecordProvider>
      <RecordValueSummaryPanelContent />
    </RecordProvider>
  );
};
