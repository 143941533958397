import { z } from "zod";

export const MeasurementQuantitySchema = z
  .object({
    id: z.string(),
    name: z.string(),
  })
  .describe("The domain model schema for a Carbon Hub Measurement Quantity");

export const MeasurementUnitSchema = z
  .object({
    id: z.string(),
    quantity: z.string(),
    name: z.object({
      singular: z.string(),
      plural: z.string(),
      symbol: z.string(),
    }),
    to_anchor: z.number(),
    anchor_shift: z.number(),
  })
  .describe("The domain model for a Carbon Hub Measurement Unit");

export const MeasurementTypeSchema = z
  .object({
    id: z.string(),
    name: z.string(),
    quantity: z.string(),
    default_unit: z.string(),
    default_unit_info: MeasurementUnitSchema.omit({ id: true }),
  })
  .describe("The domain model for a Carbon Hub Measurement Type (V2)");

export const UnitConfigurationSchema = z.object({
  measurement_type: z.string(),
  measurement_unit: z.string(),
  precision: z.number(),
  company_id: z.string(),
});

export type MeasurementQuantityType = z.infer<typeof MeasurementQuantitySchema>;
export type MeasurementTypeType = z.infer<typeof MeasurementTypeSchema>;
export type MeasurementUnitType = z.infer<typeof MeasurementUnitSchema>;
export type UnitConfigurationType = z.infer<typeof UnitConfigurationSchema>;
