import React, { useContext, useState } from "react";

import { useParams, useSearchParams } from "#src/Routers/hooks";

import {
  EventCategoryContext,
  EventCategoryProvider,
} from "#src/contexts/EventCategoryContext";

import {
  EventTemplateContext,
  EventTemplateProvider,
} from "#src/contexts/EventTemplateContext";

// components
import { EventCategoryEventsTab } from "#src/batteries-included-components/Tabs/EventCategory/EventCategoryEventsTab";
import { Button, Page, Tab } from "@validereinc/common-components";

import { AddEventDialog } from "#src/batteries-included-components/Dialogs/AddEventDialog";

import { useBreadcrumbs } from "#src/Routers/breadcrumbsHelper";

import { EVENTS_BREADCRUMB } from "#src/routes/events";
import { EVENT_CATEGORIES_BREADCRUMB } from "#src/routes/events/event-categories";
import { EVENT_CATEGORY_DETAIL_BREADCRUMB } from "#src/routes/events/event-categories/[eventCategoryId]";
import {
  EVENT_TEMPLATE_DETAIL_BREADCRUMB,
  EVENT_TEMPLATE_DETAIL_PAGE_TITLE,
} from "./";

const EventsTemplateDetailPageContent = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const { eventCategory } = useContext(EventCategoryContext) || {};
  const { eventTemplate } = useContext(EventTemplateContext) || {};

  const breadcrumbs = useBreadcrumbs(
    [
      EVENTS_BREADCRUMB,
      EVENT_CATEGORIES_BREADCRUMB,
      EVENT_CATEGORY_DETAIL_BREADCRUMB,
      EVENT_TEMPLATE_DETAIL_BREADCRUMB,
    ],
    { 2: eventCategory?.name, 3: eventTemplate?.name }
  );

  const onActiveTabKeyChange = (tabKey: string) => {
    setSearchParams({ ...searchParams, tab: tabKey });
  };

  const [isCreateNewEventDialogOpen, setIsCreateNewEventDialogOpen] =
    useState(false);

  const onOpenCreateNewEventModal = () => {
    setIsCreateNewEventDialogOpen(true);
  };

  const onCloseCreateNewEventModal = () => {
    setIsCreateNewEventDialogOpen(false);
  };

  return (
    <>
      <Page
        category={`${eventCategory?.name} Template`}
        title={eventTemplate?.name ?? EVENT_TEMPLATE_DETAIL_PAGE_TITLE}
        breadcrumbs={breadcrumbs}
        activeTabKey={searchParams?.tab ?? "events"}
        onActiveTabKeyChange={onActiveTabKeyChange}
        actionRow={[
          <Button
            key="create-new-event-button"
            onClick={onOpenCreateNewEventModal}
            variant="primary"
          >
            Create New Event
          </Button>,
        ]}
      >
        <Tab
          title="Events"
          tabKey="events"
        >
          <EventCategoryEventsTab />
        </Tab>
      </Page>

      <AddEventDialog
        eventTemplate={eventTemplate}
        isOpen={isCreateNewEventDialogOpen}
        onClose={onCloseCreateNewEventModal}
      />
    </>
  );
};

export const EventTemplateDetailPage = () => {
  const { eventCategoryId, eventTemplateId } = useParams<{
    eventCategoryId: string;
    eventTemplateId: string;
  }>();

  return (
    <EventCategoryProvider eventCategoryId={eventCategoryId}>
      <EventTemplateProvider eventTemplateId={eventTemplateId}>
        <EventsTemplateDetailPageContent />
      </EventTemplateProvider>
    </EventCategoryProvider>
  );
};
