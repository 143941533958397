import { useDataMapping } from "#components/hooks/useDataMapping";
import { linkToDataMapping } from "#routers/links";
import { Stepper } from "@validereinc/common-components";
import * as PropTypes from "prop-types";
import React from "react";
import { useParams } from "react-router-dom";
import ValidationForm from "../Create/ValidationForm";
import { UPLOAD_STEPS, UPLOAD_TITLE } from "../DataMappingConstants";

const CURRENT_STEP = 2;

const UploadValidationForm = ({ breadcrumbs }) => {
  const { id } = useParams();
  const [dataMappingDetail] = useDataMapping(id);

  const dataMappingName = dataMappingDetail?.mapping_name ?? "-";

  const uploadDataMappingBreadcrumbs = [
    ...breadcrumbs,
    {
      title: dataMappingName,
      path: linkToDataMapping(),
    },
    { title: "Upload" },
  ];

  const actionRow = (
    <Stepper
      steps={UPLOAD_STEPS}
      activeStep={CURRENT_STEP}
    />
  );

  return (
    <ValidationForm
      breadcrumbs={uploadDataMappingBreadcrumbs}
      title={UPLOAD_TITLE}
      actionRow={actionRow}
      successfulMessage={`Successfully upload data to ${dataMappingName}`}
    />
  );
};

UploadValidationForm.propTypes = {
  breadcrumbs: PropTypes.array.isRequired,
};

export default UploadValidationForm;
