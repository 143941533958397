import ReactLoader from "#components/LoadingBar/ReactLoader";
import {
  SAMPLE_NOTE,
  useFetchNotes,
  useSubmitNote,
} from "#services/NotesService";
import {
  Button,
  Form,
  FormButton,
  Modal,
  TextWithFileInput,
  useForm,
} from "@validereinc/common-components";
import * as PropTypes from "prop-types";
import React, { useState } from "react";
import { AutoSizer } from "react-virtualized";
import NotesList from "../Common/Notes/NotesList";
import "./SampleNotes.scss";

const SampleNotes = ({ selectedSample, refreshSample, onClose }) => {
  const [formState, setFormState] = useState("enabled");

  const form = useForm({
    defaultValues: {
      note: {},
    },
  });

  const [areNotesLoading, _errorStatus, notes, fetchNotes] = useFetchNotes(
    selectedSample.id,
    SAMPLE_NOTE
  );

  const [_isSubmittingNote, submitNote] = useSubmitNote(
    selectedSample.id,
    SAMPLE_NOTE
  );

  const onSubmitNote = (inputs) => {
    setFormState("loading");

    return submitNote(inputs.note)
      .then((result) => {
        fetchNotes();
        refreshSample();

        return result;
      })
      .finally(() => {
        form.reset();
        setFormState("enabled");
      });
  };

  return (
    <Form
      onSubmit={onSubmitNote}
      {...form}
    >
      <Modal.Body className="sampleNotes">
        <div className="sampleNotes__notesContainer">
          <AutoSizer disableWidth>
            {({ height }) => (
              <ReactLoader
                loaded={!areNotesLoading}
                position={50}
              >
                <NotesList
                  list={notes}
                  height={height}
                />
              </ReactLoader>
            )}
          </AutoSizer>
        </div>

        <TextWithFileInput
          name="note"
          label=""
          isRequired
          showIcon
          multiple
          isDisabled={formState !== "enabled"}
        />
      </Modal.Body>

      <Modal.Footer>
        <Button
          className="pull-left"
          onClick={onClose}
        >
          Close
        </Button>

        <FormButton
          variant="primary"
          type="submit"
          isLoading={formState === "loading"}
          disabled={formState !== "enabled" || !selectedSample}
        >
          Add Note
        </FormButton>
      </Modal.Footer>
    </Form>
  );
};

SampleNotes.propTypes = {
  selectedSample: PropTypes.object,
  refreshSample: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default SampleNotes;
