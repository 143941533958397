import {
  ValidateFirstCharIsLetter,
  ValidateTextInputPhone,
} from "#utils/formValidator";
import {
  FormInputWrapper,
  SelectInput,
  TextInput,
} from "@validereinc/common-components";
import * as PropTypes from "prop-types";
import React from "react";
import { ValidateTextInputEmail } from "../../../utils/formValidator";
import PermissionList from "../Permissions/PermissionList";
import "./ProfileForm.css";

const ProfileForm = ({
  isInvite,
  inputs,
  formState,
  availableSites,
  availableRoles,
  availableInstruments,
  availableTimezones,
  handleAccessDefinitionClick,
  hasWritePermissions,
}) => {
  const shareProps = {
    showIcon: true,
    isDisabled: formState !== "enabled" || !hasWritePermissions,
  };

  return (
    <div className="profileForm">
      <TextInput
        {...shareProps}
        name="name"
        label="Name"
        type="text"
        placeholder="Name"
        validate={{
          validateName: (name) => {
            if (!ValidateFirstCharIsLetter(name)) {
              return "Name must start with a letter";
            }
            return null;
          },
        }}
        isRequired
      />

      <TextInput
        {...shareProps}
        name="email"
        label="Email"
        type="text"
        placeholder="Email"
        validate={{
          validateName: (email) => {
            const checkEmail = ValidateTextInputEmail("Email", email, true);
            if (checkEmail) {
              return checkEmail;
            }
            return null;
          },
        }}
        isDisabled={!isInvite}
        isRequired
      />

      <TextInput
        {...shareProps}
        name="phone_number"
        label="Phone Number"
        type="text"
        placeholder="000-000-0000"
        validate={{
          validatePhoneNumber: (val) => {
            const checkPhone = ValidateTextInputPhone(
              "phone number",
              val,
              false
            );
            if (checkPhone) {
              return checkPhone;
            }
            return null;
          },
        }}
      />

      <TextInput
        {...shareProps}
        name="job_title"
        label="Position"
        type="text"
        placeholder="Position"
      />

      <SelectInput
        {...shareProps}
        name="timezone_preference"
        label="Timezone"
        options={availableTimezones}
        isRequired
        ignoreCase
      />

      <SelectInput
        {...shareProps}
        name="sites"
        label={
          <div>
            Sites
            <br />
            <div style={{ fontWeight: "normal" }}>
              Grant user access to the features and data on a site basis.
            </div>
          </div>
        }
        options={availableSites}
        labelKey="name"
        isMulti
        ignoreCase
      />

      <SelectInput
        {...shareProps}
        name="role"
        label={
          <div>
            Role{" "}
            <a onClick={handleAccessDefinitionClick}>(Access Definitions)</a>
          </div>
        }
        labelKey="name"
        options={availableRoles}
        isRequired
      />

      <FormInputWrapper
        {...shareProps}
        name="permission"
        label="Permissions"
        as={() => <PermissionList list={inputs?.role?.permissions ?? null} />}
      />

      {hasWritePermissions && (
        <SelectInput
          {...shareProps}
          name="restricted_calibration_instruments"
          label="Restricted Calibration Instruments"
          labelKey="name"
          options={availableInstruments}
          isMulti
        />
      )}
    </div>
  );
};

ProfileForm.propTypes = {
  isInvite: PropTypes.bool,
  inputs: PropTypes.object.isRequired,
  formState: PropTypes.string.isRequired,
  hasWritePermissions: PropTypes.bool.isRequired,
  availableSites: PropTypes.array.isRequired,
  availableRoles: PropTypes.array.isRequired,
  availableInstruments: PropTypes.array.isRequired,
  availableTimezones: PropTypes.array.isRequired,
  handleAccessDefinitionClick: PropTypes.func.isRequired,
};

export default ProfileForm;
