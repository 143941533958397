import { restAPI } from ".";
import { CustomAttributeType } from "../schemas";
import type {
  CreateOneRequestType,
  DeleteOneRequestType,
  ResourceServiceType,
  UpdateOneRequestType,
} from "../util";
import { GetListResponseType, fetchAndCollate } from "../util";

export type CustomAttributeServiceType = Pick<
  ResourceServiceType<CustomAttributeType>,
  "getList" | "createOne" | "updateOne" | "deleteOne"
>;

export const CustomAttributesDomain: CustomAttributeServiceType = {
  /**
   * List Custom Attributes API
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/custom_attribute_definition/ListCustomAttributeDefinition
   */
  getList: async ({
    page,
    pageSize,
    sortBy,
    sortDirection,
    filters: { entity_type, field_name, entity_subtype, archived },
  }) =>
    fetchAndCollate<CustomAttributeType>(
      ({ page, pageSize }) =>
        restAPI.nodeAPI.GET<GetListResponseType<CustomAttributeType>>({
          endpoint: `/custom_attribute_definition/${entity_type}`,
          query: {
            page,
            page_size: pageSize,
            sort_by: sortBy,
            sort_direction: sortDirection,
            ...(field_name ? { field_name } : {}),
            ...(entity_subtype ? { entity_subtype } : {}),
            ...(archived !== undefined
              ? {
                  archived:
                    typeof archived === "string"
                      ? archived
                      : Boolean(archived).toString(),
                }
              : {}),
          },
        }),
      page,
      pageSize
    ),
  /** Create a company custom attribute
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/custom_attribute_definition/createCustomAttributeDefinition
   */
  createOne: ({
    data: { entity_type, ...data },
  }: CreateOneRequestType<CustomAttributeType>) =>
    restAPI.nodeAPI
      .POST<CustomAttributeType>({
        endpoint: `/custom_attribute_definition/${entity_type}`,
        body: data,
      })
      .then((resp) => ({ data: resp })),
  /** Update a company custom attribute
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/custom_attribute_definition/updateCustomAttributeDefinition
   */
  updateOne: ({
    id,
    data: { entity_type, ...data },
  }: UpdateOneRequestType<CustomAttributeType>) =>
    restAPI.nodeAPI
      .PUT<CustomAttributeType>({
        endpoint: `/custom_attribute_definition/${entity_type}/${id}`,
        body: data,
      })
      .then((resp) => ({ data: resp })),
  /** Delete a company custom attribute
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/custom_attribute_definition/deleteCustomAttributeDefinition
   */
  deleteOne: ({
    id,
    meta: { entity_type },
  }: DeleteOneRequestType<CustomAttributeType>) =>
    restAPI.nodeAPI
      .DELETE<CustomAttributeType>({
        endpoint: `/custom_attribute_definition/${entity_type}/${id}`,
      })
      .then(() => ({ status: 200 })),
};
