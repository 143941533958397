import { useMeasurementTypes } from "#src/contexts/MeasurementTypeContext";
import { getConvertedUnitString } from "#utils/stringFormatter";
import { Panel, XYChart } from "@validereinc/common-components";
import moment from "moment";
import * as PropTypes from "prop-types";
import React, { useMemo } from "react";

const CHART_HEIGHT = 400;

const AnalysisPanel = ({
  data,
  isLoading,
  tickFormatter = (value: Date) => moment(value).format("MMM DD, YYYY h:mm A"),
  from,
  to,
  title = "Analysis",
  thresholds,
  actionRow,
}) => {
  const { getUnitName } = useMeasurementTypes();
  const [leftSource, rightSource] = data;

  const sets = useMemo(() => {
    return data.map(({ name, data } = {}, index) => ({
      axis: index % 2 ? "right" : "left",
      data,
      label: name,
      variant: "line",
      isGradient: true,
    }));
  }, [data]);

  const leftAxis = leftSource && {
    label: `${leftSource?.name} (${getUnitName(leftSource?.unit)})`,
    valueAccessor: (dataPoint) => dataPoint?.measure_value,
    valueRenderer: (dataPoint) =>
      getConvertedUnitString(
        dataPoint?.measure_value,
        (value) => value,
        getUnitName(leftSource.unit)
      ),
  };

  const rightAxis = rightSource && {
    label: `${rightSource?.name} (${getUnitName(rightSource?.unit)})`,
    valueAccessor: (dataPoint) => dataPoint?.measure_value,
    valueRenderer: (dataPoint) =>
      getConvertedUnitString(
        dataPoint?.measure_value,
        (value) => value,
        getUnitName(rightSource.unit)
      ),
  };

  const bottomAxis = {
    compare: ({ time: aTime }, { time: bTime }) =>
      moment(aTime).isAfter(bTime) ? 1 : -1,
    valueAccessor: (dataPoint) => dataPoint?.time,
    tickRenderer: (date) => tickFormatter(new Date(date)),
  };

  return (
    <Panel
      title={title}
      loaded={!isLoading}
      actionRow={actionRow}
    >
      <XYChart
        height={CHART_HEIGHT}
        sets={sets}
        leftAxis={leftAxis}
        rightAxis={rightAxis}
        bottomAxis={bottomAxis}
        from={from}
        to={to}
        thresholds={thresholds}
      />
    </Panel>
  );
};

AnalysisPanel.propTypes = {
  data: PropTypes.array.isRequired,
  isLoading: PropTypes.bool,
  tickFormatter: PropTypes.func,
  from: PropTypes.string,
  to: PropTypes.string,
  title: PropTypes.string,
  thresholds: PropTypes.array,
  actionRow: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default AnalysisPanel;
