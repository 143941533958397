import { toStartCaseString } from "@validereinc/utilities";
import { z } from "zod";
import { CustomAttributeRecordSchema } from "./CustomAttributeSchemas";
import {
  DomainModelMetaExtendedSchema,
  DomainModelMetaSchema,
  DomainModelSchema,
} from "./DomainModelSchemas";
import { EquipmentSchema } from "./EquipmentSchemas";

export const FacilityStatus = {
  ACTIVE: "active",
  INACTIVE: "inactive",
} as const;

export const FacilityStatusOptions = Object.values(FacilityStatus).map(
  (id) => ({
    id,
    name: toStartCaseString(id),
  })
);

export const FacilitySchema = z
  .object({
    name: z.string(),
    type: z.string(),
    status: z.enum([FacilityStatus.ACTIVE, FacilityStatus.INACTIVE]),
    latitude: z.number().lte(90).gte(-90),
    longitude: z.number().lte(180).gte(-180),
    custom_attributes: CustomAttributeRecordSchema,
    company_id: z.string().uuid(),
  })
  .merge(DomainModelSchema)
  .merge(DomainModelMetaSchema)
  .merge(DomainModelMetaExtendedSchema)
  .describe("Schema for Facility asset type");

export const FacilityWithEquipmentSchema = FacilitySchema.merge(
  z.object({
    equipment: z.array(z.lazy(() => EquipmentSchema)),
  })
);

export const FacilityFilterSchema = FacilitySchema.pick({
  name: true,
  status: true,
  custom_attributes: true,
}).merge(
  z.object({
    "event.id": z.string().uuid(),
  })
);

export type FacilityType = z.infer<typeof FacilitySchema>;
export type FacilityWithEquipmentType = z.infer<
  typeof FacilityWithEquipmentSchema
>;
export type FacilityStatusType =
  (typeof FacilityStatus)[keyof typeof FacilityStatus];
export type FacilityFilterType = z.infer<typeof FacilityFilterSchema>;
