import { FLOW_ATTRIBUTES, useFlowHeaders } from "#hooks/tables/useFlowHeaders";
import { useNetworkCalculationSourceHeaders } from "#routes/organization/networks/[networkId]/detail/NetworkCalculationTab/NetworkCalculationTab.helpers";
import { useNetworkCalculationResult } from "#routes/organization/networks/[networkId]/detail/calculation-result/[period]/details/NetworkCalculationResultPage.helpers";
import { useMeasurementTypes } from "#src/contexts/MeasurementTypeContext";
import {
  DataTable,
  DataTablePanel,
  HeaderType,
} from "@validereinc/common-components";
import { FlowV2Type } from "@validereinc/domain";
import React from "react";

export const CalculationFlowVariant = {
  INLET: "inlet",
  ADJUSTABLE: "adjustable",
  NON_ADJUSTABLE: "non_adjustable",
};

export type CalculationFlowVariantType =
  (typeof CalculationFlowVariant)[keyof typeof CalculationFlowVariant];

export const NetworkCalculationResultFlowPanel = ({
  variant,
}: {
  variant: CalculationFlowVariantType;
}) => {
  const { getUnitName } = useMeasurementTypes();
  const { network, flows, configuration, run } = useNetworkCalculationResult();

  const isAdjustable = variant === CalculationFlowVariant.ADJUSTABLE;

  const headers: Array<HeaderType<FlowV2Type>> = [
    ...useFlowHeaders().filter(({ key }) =>
      [FLOW_ATTRIBUTES.NAME.key, FLOW_ATTRIBUTES.TYPE.key].includes(key)
    ),
    ...useNetworkCalculationSourceHeaders<FlowV2Type>(configuration),
    ...(isAdjustable
      ? [
          {
            key: "volume_adjusted",
            label: "Adjusted Volume",
            renderComponent: ({ item }: { item: FlowV2Type }) => {
              const input = run?.output?.outputs?.find(
                ({ measurement_type, asset_id }) =>
                  measurement_type === "volume" && asset_id === item.id
              );
              return input ? (
                <DataTable.DataRow.NumberCell
                  value={input.measurement_value}
                  unit={getUnitName(input.measurement_unit)}
                />
              ) : (
                "-"
              );
            },
          },
        ]
      : []),
  ];

  const items = flows.filter(({ id }) => {
    switch (variant) {
      case CalculationFlowVariant.INLET:
        return network?.network_inlet_ids?.includes(id);
      default:
        return (
          !network?.network_inlet_ids?.includes(id) &&
          run?.configuration_asset_input[id]?.is_prorated?.value == isAdjustable
        );
    }
  });

  const title =
    variant === CalculationFlowVariant.INLET
      ? "Inlet Flows"
      : variant === CalculationFlowVariant.ADJUSTABLE
        ? "Adjustable Flows"
        : "Non Adjustable Flows";

  return (
    <DataTablePanel
      panelProps={{ title, isFluidY: false }}
      dataTableProps={{ items, headers }}
    />
  );
};
