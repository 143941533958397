import { useReducer } from "react";

const SET_VALUE = "SET_VALUE";
const RESET_HISTORY = "RESET_HISTORY";
const UNDO = "UNDO";

const reducer = (state, action) => {
  const { value, history } = state;

  switch (action.type) {
    case SET_VALUE: {
      return {
        ...state,
        value: action.value,
        history: [...history, value],
      };
    }
    case RESET_HISTORY: {
      return {
        ...state,
        history: [],
      };
    }
    case UNDO: {
      const newHistory = [...history];
      const lastValue = newHistory.pop();

      return {
        ...state,
        value: lastValue,
        history: newHistory,
      };
    }
  }
};

const useStateWithHistory = (defaultValue) => {
  const [{ value, history }, dispatch] = useReducer(reducer, {
    value: defaultValue,
    history: [],
  });

  const setValue = (newValue) => {
    dispatch({
      type: SET_VALUE,
      value: newValue,
    });
  };

  const canUndo = () => history.length > 0;

  const undo = () => {
    if (canUndo()) {
      dispatch({
        type: UNDO,
      });
    }
  };

  const resetHistory = () => {
    dispatch({
      type: RESET_HISTORY,
    });
  };

  return [value, setValue, { resetHistory, undo, canUndo }];
};

export default useStateWithHistory;
