import React from "react";

import { Button, Dialog } from "@validereinc/common-components";

import { useDeleteCompanyUnitConfig } from "#src/components/hooks/adapters/useCompanyUnitConfig";
import { useDeleteUserUnitConfig } from "#src/components/hooks/adapters/useUserUnitConfig";
import {
  UnitConfigurationPreference,
  UnitConfigurationPreferenceType,
  UnitConfigurationType,
} from "@validereinc/domain";

export const DeleteConfigDialog = ({
  isOpen,
  onClose,
  unitConfigurationPreference,
  itemToDelete,
}: {
  isOpen: boolean;
  onClose: () => void;
  unitConfigurationPreference: UnitConfigurationPreferenceType;
  itemToDelete: UnitConfigurationType;
}) => {
  const onSuccess = () => {
    onClose?.();
  };

  const deleteUserUnitConfig = useDeleteUserUnitConfig({ onSuccess });
  const deleteCompanyUnitConfig = useDeleteCompanyUnitConfig({ onSuccess });

  const deleteUnitConfig =
    unitConfigurationPreference === UnitConfigurationPreference.COMPANY
      ? deleteCompanyUnitConfig
      : deleteUserUnitConfig;

  const handleSubmit = () => {
    deleteUnitConfig.mutate({
      id: itemToDelete?.measurement_type,
    });
  };

  return (
    <Dialog
      title="Delete Unit Configuration"
      isOpen={isOpen}
      onClose={onClose}
      actionRow={[
        <Button
          key="delete-unit-config"
          variant="primary"
          onClick={handleSubmit}
        >
          Delete
        </Button>,
      ]}
    >
      Are you sure?
    </Dialog>
  );
};
