import React from "react";

import * as PropTypes from "prop-types";

import {
  Accordion,
  Button,
  Drawer,
  Form,
  TextInput,
  useForm,
} from "@validereinc/common-components";

import DashboardService from "#services/DashboardService";
import { DEFAULT_DATE_RANGES } from "#src/hooks/useDateRange";
import { getIntervalOptions } from "#utils/date";

import AxisFields from "./DashboardAxisFields";

const { AccordionPanel } = Accordion;

const ChartDrawer = ({ dashboardId, isOpen, onClose, chart, order }) => {
  const form = useForm({ defaultValues: chart });

  const formValues = form.watch();

  const onSubmit = async (values) => {
    if (!chart) {
      await DashboardService.createChart({
        dashboardId,
        ...values,
        order,
        dateRange: DEFAULT_DATE_RANGES.last30Days,
        interval: getIntervalOptions(DEFAULT_DATE_RANGES.last30Days)[0]?.id,
      });
    } else {
      await DashboardService.editChart({
        dashboardId,
        chartId: chart.id,
        ...chart,
        ...values,
        order,
      });
    }

    form.reset();

    onClose(true);
  };

  const actionRow = [
    <Button
      key="close-create-chart-drawer"
      onClick={() => onClose()}
    >
      Close
    </Button>,
    <Button
      key="submit-create-chart-drawer"
      variant="primary"
      onClick={form.handleSubmit(onSubmit)}
    >
      Apply
    </Button>,
  ];

  return (
    <Drawer
      title="Configure Chart"
      isOpen={isOpen}
      onClose={() => onClose()}
      actionRow={actionRow}
    >
      <Form
        {...form}
        onSubmit={onSubmit}
      >
        <Accordion defaultActiveKeys={["overview", "left-axis"]}>
          <AccordionPanel
            title="Overview"
            dataKey="overview"
          >
            <TextInput
              name="name"
              label="Name"
              isRequired
            />
          </AccordionPanel>

          <AccordionPanel
            title="Left Axis"
            dataKey="left-axis"
          >
            <AxisFields
              axis="left"
              formValues={formValues}
              form={form}
            />
          </AccordionPanel>

          <AccordionPanel
            title="Right Axis"
            dataKey="right-axis"
          >
            <AxisFields
              axis="right"
              formValues={formValues}
              form={form}
            />
          </AccordionPanel>
        </Accordion>
      </Form>
    </Drawer>
  );
};

ChartDrawer.propTypes = {
  dashboardId: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  chart: PropTypes.object,
  order: PropTypes.number,
};

export default ChartDrawer;
