import React, { Component } from "react";
import * as PropTypes from "prop-types";
import {
  XYPlot,
  Borders,
  VerticalGridLines,
  HorizontalGridLines,
  XAxis,
  YAxis,
  LineMarkSeries,
  LineSeries,
  CustomSVGSeries,
  Highlight,
  Hint,
} from "react-vis";
import "react-vis/dist/style.css";
import AnalyzeTable from "./AnalyzeTable";
import round from "lodash/round";
import findIndex from "lodash/findIndex";
import StatisticPanel from "./StatisticPanel";
import ResetButton from "./ResetButton";
import {
  getMeasurementType,
  MeasurementType,
  getFormattedMeasurementValue,
} from "../Redux/reducers/measurements";
import { connect } from "react-redux";
import {
  GetXAxisTicks,
  GraphTimeFormatter,
  GetYDomain,
  GetXDomain,
} from "../../utils/charts";
import { getMagnitudeNumber } from "#utils/stringFormatter";
import config from "#src/config";
import { AutoSizer } from "react-virtualized";
import { VirtualSampleSVG } from "#common/SVG/SamplesSVG";
import { getTimeStringFromDate } from "#utils/timeFormatter";

const _ = { round, findIndex };

const Y_PADDING = 0.25;

const NUM_X_AXIS_TICK = 20;

const MIN_Y_AXIS_RANGE = 0.1;

const MIN_TIME_PERIOD = 60 * 60 * 24; // 1 day

function getYAxisTitle(measurementType) {
  if (measurementType.unit) {
    return `${measurementType.unit}`;
  } else {
    return `${measurementType.name}`;
  }
}

const LIMIT_LINES_STYLE = {
  fontWeight: 200,
  strokeDasharray: "5, 5",
  pointerEvents: "none",
};

const mapStateToProps = (state, props) => {
  return {
    measurementType: getMeasurementType(state.measurements)(
      props.measurementTypeName
    ),
    submeasurementType: props.submeasurementTypeName
      ? getMeasurementType(state.measurements)(props.submeasurementTypeName)
      : undefined,
    getFormattedMeasurementValue: (measurementKey, measurementValue) =>
      getFormattedMeasurementValue(state.measurements)(
        measurementKey,
        measurementValue
      ),
    getMeasurementUnit: (measurement) =>
      getMeasurementType(state.measurements)(measurement).unit,
  };
};

class AnalyzeGraph extends Component {
  static propTypes = {
    measurementTypeName: PropTypes.string.isRequired,
    measurementType: PropTypes.instanceOf(MeasurementType).isRequired,
    alertSpec: PropTypes.object,
    streams: PropTypes.array.isRequired,
    startDate: PropTypes.instanceOf(Date).isRequired,
    endDate: PropTypes.instanceOf(Date).isRequired,
    removeStream: PropTypes.func.isRequired,
    submeasurementStreams: PropTypes.array,
    submeasurementTypeName: PropTypes.string,
    submeasurementType: PropTypes.instanceOf(MeasurementType),
    submeasurementAlertSpec: PropTypes.object,
    getFormattedMeasurementValue: PropTypes.func,
    getMeasurementUnit: PropTypes.func,
    onSampleModalToggle: PropTypes.func,
    measurementTypeListNames: PropTypes.array,
  };

  constructor(props) {
    super(props);
    this.state = {
      highlightedSample: null,
      yAxisTitle: null,
      graphDataSeries: [],
      updateTable: false,
      disablePointerEvent: false,
      showResetButton: false,
      measurements: {},
      xAxisTicks: [],
      hint: null,
    };

    this.getChart = this.getChart.bind(this);
    this.onHighlightStartBrush = this.onHighlightStartBrush.bind(this);
    this.onHighlightEndBrush = this.onHighlightEndBrush.bind(this);
    this.resetAxes = this.resetAxes.bind(this);
    this.convertToSubmeasurementUnit =
      this.convertToSubmeasurementUnit.bind(this);
    this.convertToMainMeasurementUnit =
      this.convertToMainMeasurementUnit.bind(this);
    this.getTimeTick = this.getTimeTick.bind(this);
    this.onLeaveDatapoint = this.onLeaveDatapoint.bind(this);
    this.getYDomainOfAllMeasurements =
      this.getYDomainOfAllMeasurements.bind(this);
  }

  componentDidMount() {
    const {
      alertSpec,
      streams,
      measurementType,
      startDate,
      endDate,
      submeasurementStreams,
      submeasurementType,
      submeasurementAlertSpec,
    } = this.props;
    const measurements = this.getMeasurements(
      alertSpec,
      streams,
      measurementType.name,
      startDate,
      endDate
    );

    const submeasurements = [];
    if (submeasurementType) {
      let submeasurement = null;
      submeasurementStreams.map((submeasurementStream) => {
        submeasurement = this.getMeasurements(
          submeasurementAlertSpec,
          [submeasurementStream],
          submeasurementType.name,
          startDate,
          endDate
        );
        measurements.samples = this.combineSamples(
          measurements.samples,
          submeasurement.samples
        );

        submeasurements.push(submeasurement);
      });
    }

    this.setState({
      submeasurements,
      measurements,
    });
  }

  static getDerivedStateFromProps(props, state) {
    const xAxisTicks = GetXAxisTicks(
      state.measurements.xDomain,
      NUM_X_AXIS_TICK
    );

    return { xAxisTicks: xAxisTicks };
  }

  onHighlightStartBrush() {
    this.setState({
      disablePointerEvent: true,
    });
  }

  onHighlightEndBrush(datapoint) {
    if (datapoint) {
      const startDate = new Date(datapoint.left);
      const endDate = new Date(datapoint.right);

      const highMeasurement = datapoint.top;
      const lowMeasurement = datapoint.bottom;

      const measurements = this.state.measurements;
      measurements.xDomain = GetXDomain(startDate, endDate, MIN_TIME_PERIOD);
      measurements.yDomain = GetYDomain(
        lowMeasurement,
        highMeasurement,
        MIN_Y_AXIS_RANGE
      );

      this.setState({
        disablePointerEvent: false,
        measurements: measurements,
        showResetButton: true,
      });
    } else {
      this.setState({
        disablePointerEvent: false,
      });
    }
  }

  hoverDataPoint = (datapoint, sample) => {
    if (sample && datapoint) {
      // Setting the position of the Hint
      sample.y = datapoint.y;
      sample.x = datapoint.x;

      this.setState({
        highlightedSample: sample,
        updateTable: !this.state.updateTable,
        hint: sample,
      });
    }
  };

  onLeaveDatapoint() {
    this.setState({
      hint: null,
    });
  }

  resetAxes() {
    const { alertSpec } = this.props;

    const startDate = this.props.startDate;
    const endDate = this.props.endDate;

    const measurements = this.state.measurements;
    measurements.xDomain = GetXDomain(startDate, endDate, MIN_TIME_PERIOD);
    measurements.yDomain = this.getYDomain(alertSpec, measurements);

    this.setState({
      measurements: measurements,
      showResetButton: false,
    });
  }

  getChart(sample, graphData, index) {
    const { measurements, disablePointerEvent } = this.state;

    const sharedProps = {
      key: index,
      data: graphData,
      size: 3,
      color: sample.stream.color,
      xDomain: measurements.xDomain,
      onValueMouseOver: (datapoint) => this.hoverDataPoint(datapoint, sample),
      onValueMouseOut: this.onLeaveDatapoint,
      style: disablePointerEvent ? { pointerEvents: "none" } : null,
    };

    const sampleType = sample.type;

    if (
      sampleType === "spot" ||
      sampleType === "unknown" ||
      sampleType == "Crude Monitor"
    ) {
      return <LineMarkSeries {...sharedProps} />;
    }

    if (sampleType === "composite") {
      return (
        <LineMarkSeries
          {...sharedProps}
          curve={"curveLinear"}
        />
      );
    }

    if (sampleType === "virtual") {
      return (
        <CustomSVGSeries
          {...sharedProps}
          customComponent={() => (
            <VirtualSampleSVG color={sample.stream.color} />
          )}
        />
      );
    }

    if (sampleType === "inline") {
      return (
        <LineMarkSeries
          {...sharedProps}
          curve={"curveLinear"}
          markStyle={{
            stroke: sample.stream.color,
            strokeWidth: 2,
            fill: "#fff",
          }}
          style={{ pointerEvents: "none", opacity: 0.6 }}
        />
      );
    }
  }

  getGraphDataSeries(samples) {
    return samples.reduce((graphDataSeries, sample) => {
      return [
        ...graphDataSeries,
        {
          sample: sample,
          graphData: sample.data_points,
        },
      ];
    }, []);
  }

  getYDomain(alertSpec, measurements) {
    const values = [];
    measurements.samples.forEach((sample) => {
      const rowValues = sample.data_points.map((data_point) => data_point.y);
      values.push(...rowValues);

      if (measurements.upperLimit !== null) {
        values.push(measurements.upperLimit);
      }
      if (measurements.lowerLimit !== null) {
        values.push(measurements.lowerLimit);
      }
    });

    const maxValue = Math.max(...values);
    // We need to have y-axis range to be able to plot the graph
    const minValue = maxValue !== Math.min(...values) ? Math.min(...values) : 0;
    const range = maxValue - minValue;

    const fullrange = Y_PADDING * range;

    const yLowerRange = Math.max(0, minValue - fullrange);
    const yUpperRange = Math.max(0, maxValue + fullrange);

    return [yLowerRange, yUpperRange];
  }

  getAlertSpec(spec, limit) {
    if (spec && spec[limit] !== null) {
      return spec[limit];
    }
    return null;
  }

  getAllSamples(streams) {
    return streams.reduce((samples, stream) => {
      if (Array.isArray(stream.samples)) {
        return [...samples, ...stream.samples];
      } else {
        return samples;
      }
    }, []);
  }

  combineSamples(samples, submeasurementSamples) {
    // merge the submeasuremnt samples into samples
    samples.forEach((sample) => {
      const index = _.findIndex(submeasurementSamples, { id: sample.id });
      if (index !== -1) {
        sample.submeasurement = submeasurementSamples[index].average_value;
        submeasurementSamples.splice(index, 1);
      }
    });

    //append unmatched submeasurement samples
    submeasurementSamples.forEach((submeasurementSample) => {
      delete Object.assign(submeasurementSample, {
        submeasurement: submeasurementSample.average_value,
      }).average_value;
      samples.push(submeasurementSample);
    });
    return samples;
  }

  getMeasurements(alertSpec, streams, measurementType, startDate, endDate) {
    const measurements = {};
    measurements.upperLimit = this.getAlertSpec(alertSpec, "upper_limit");
    measurements.lowerLimit = this.getAlertSpec(alertSpec, "lower_limit");
    measurements.samples = this.getAllSamples(streams);
    measurements.yDomain = this.getYDomain(alertSpec, measurements);
    measurements.xDomain = GetXDomain(startDate, endDate, MIN_TIME_PERIOD);
    measurements.graphDataSeries = this.getGraphDataSeries(
      measurements.samples
    );

    return measurements;
  }

  convertGraphDataUnits(graphData) {
    graphData.forEach((data) => {
      if (data.y || data.y === 0) {
        if (data.originalValue === undefined) {
          data.originalValue = data.y;
          data.y = this.convertToMainMeasurementUnit(data.y);
        } else {
          data.y = this.convertToMainMeasurementUnit(data.originalValue);
        }
      }
    });

    return graphData;
  }

  convertToSubmeasurementUnit(unit) {
    const { measurements, submeasurements } = this.state;
    return this.calculateUnits(
      unit,
      measurements.yDomain,
      submeasurements[0].yDomain
    );
  }

  convertToMainMeasurementUnit(unit) {
    const { measurements, submeasurements } = this.state;
    return this.calculateUnits(
      unit,
      submeasurements[0].yDomain,
      measurements.yDomain
    );
  }

  calculateUnits(value, originalUnits, convertUnits) {
    const originalUnitMax = originalUnits[1];
    const originalUnitMin = originalUnits[0];
    const convertUnitsMax = convertUnits[1];
    const convertUnitMin = convertUnits[0];

    const ratio =
      (value - originalUnitMin) / (originalUnitMax - originalUnitMin);
    return _.round(
      (convertUnitsMax - convertUnitMin) * ratio + convertUnitMin,
      2
    );
  }

  getTimeTick(value, index) {
    const { xAxisTicks } = this.state;
    return GraphTimeFormatter(value, index, xAxisTicks);
  }

  hintFormat = (data) => {
    const formattedValue = this.props.getFormattedMeasurementValue(
      data.stream.measurementType,
      data.average_value || data.data_points[0].originalValue
    );

    const hint = [
      {
        title: "Stream",
        value: data.stream.name,
      },
      {
        title: "Measurement",
        value: data.stream.measurementType,
      },
      {
        title: "Type",
        value: data.type,
      },
      {
        title: "Date",
        value: getTimeStringFromDate(data.start_date, config.DATETIME_FORMAT),
      },
      {
        title: "Value",
        value: formattedValue,
      },
    ];

    return hint;
  };

  getYDomainOfAllMeasurements() {
    const { measurements, submeasurements } = this.state;

    return submeasurements.reduce((yDomain, submeasurements) => {
      let currentMinYDomain = yDomain[0] ?? measurements.yDomain[0];
      let currentMaxYDomain = yDomain[1] ?? measurements.yDomain[1];

      const [minSubmeasurementYDomain, maxSubmeasurementYDomain] =
        submeasurements.yDomain;

      currentMinYDomain = Math.min(currentMinYDomain, minSubmeasurementYDomain);
      currentMaxYDomain = Math.max(currentMaxYDomain, maxSubmeasurementYDomain);

      return [currentMinYDomain, currentMaxYDomain];
    }, []);
  }

  render() {
    const {
      measurementType,
      submeasurementType,
      submeasurementStreams,
      streams,
      onSampleModalToggle,
      measurementTypeListNames,
      getFormattedMeasurementValue,
      getMeasurementUnit,
    } = this.props;
    const {
      measurements,
      submeasurements,
      highlightedSample,
      showResetButton,
      xAxisTicks,
      hint,
    } = this.state;

    const getX = ({ x }) => new Date(x);

    const hasDifferentMeasurementUnit =
      submeasurementType && submeasurementType.unit !== measurementType.unit;

    // If there is a sub-measurement with the same unit then we need to take
    // account of the yDomain of the submeasurement
    const yDomain =
      !hasDifferentMeasurementUnit && submeasurements?.length > 0
        ? this.getYDomainOfAllMeasurements()
        : measurements.yDomain;

    return (
      <>
        <div className="analyzePanel__statisticPanelContainer">
          {streams.map((stream, index) => {
            return (
              <StatisticPanel
                key={index}
                title={submeasurementType ? measurementType.name : stream.name}
                stream={stream}
                measurementType={measurementType}
              />
            );
          })}

          {submeasurements && submeasurementType
            ? submeasurementStreams.map((submeasurementStream) => (
                <StatisticPanel
                  key={submeasurementStream.measurementType}
                  title={submeasurementStream.measurementType}
                  stream={submeasurementStream}
                  measurementType={submeasurementType}
                />
              ))
            : null}
        </div>

        <AutoSizer disableHeight>
          {({ width }) => (
            <XYPlot
              width={width}
              height={400}
              margin={{ right: submeasurements ? 50 : 0 }}
              xDomain={measurements.xDomain}
              yDomain={yDomain}
              getX={getX}
            >
              <Highlight
                enableY={true}
                onBrushStart={() => this.onHighlightStartBrush()}
                onBrushEnd={(datapoint) => this.onHighlightEndBrush(datapoint)}
              />
              <VerticalGridLines style={{ pointerEvents: "none" }} />
              <HorizontalGridLines style={{ pointerEvents: "none" }} />

              {measurements.upperLimit ? (
                <LineSeries
                  color="#FF0000"
                  style={LIMIT_LINES_STYLE}
                  data={[
                    { x: measurements.xDomain[0], y: measurements.upperLimit },
                    { x: measurements.xDomain[1], y: measurements.upperLimit },
                  ]}
                  yDomain={yDomain}
                  xDomain={measurements.xDomain}
                />
              ) : null}
              {measurements.lowerLimit ? (
                <LineSeries
                  color="#FF0000"
                  style={LIMIT_LINES_STYLE}
                  data={[
                    { x: measurements.xDomain[0], y: measurements.lowerLimit },
                    { x: measurements.xDomain[1], y: measurements.lowerLimit },
                  ]}
                  yDomain={yDomain}
                  xDomain={measurements.xDomain}
                />
              ) : null}

              {submeasurements?.length > 0
                ? submeasurements.map((submeasurement) => {
                    return submeasurement.graphDataSeries.map(
                      ({ sample, graphData }, index) => {
                        // If there is two axis then we convert the submeasurement
                        // to align witht the right y-axis value
                        if (hasDifferentMeasurementUnit) {
                          graphData = this.convertGraphDataUnits(graphData);
                        }

                        return this.getChart(sample, graphData, index);
                      }
                    );
                  })
                : null}

              {measurements?.graphDataSeries
                ? measurements.graphDataSeries.map(
                    ({ sample, graphData }, index) => {
                      return this.getChart(sample, graphData, index);
                    }
                  )
                : null}

              <Borders
                style={{
                  bottom: { fill: "#fff" },
                  left: { fill: "#fff" },
                  right: { fill: "#fff" },
                  top: { fill: "#fff" },
                }}
              />

              <XAxis
                title="Day"
                position="middle"
                tickValues={xAxisTicks}
                tickFormat={this.getTimeTick}
              />

              <YAxis
                title={getYAxisTitle(measurementType)}
                tickFormat={(v) => getMagnitudeNumber(v, 3)}
                position="start"
              />

              {hasDifferentMeasurementUnit ? (
                <YAxis
                  orientation="right"
                  title={getYAxisTitle(submeasurementType)}
                  position="start"
                  tickFormat={this.convertToSubmeasurementUnit}
                  style={{
                    text: { fill: submeasurementStreams[0].color },
                  }}
                />
              ) : null}

              {hint ? (
                <Hint
                  value={hint}
                  format={this.hintFormat}
                  style={{
                    width: "400px",
                    zIndex: 2,
                  }}
                />
              ) : null}
            </XYPlot>
          )}
        </AutoSizer>

        <ResetButton
          onClick={this.resetAxes}
          show={showResetButton}
        />

        <AnalyzeTable
          measurementType={measurementType}
          submeasurementType={submeasurementType}
          submeasurements={submeasurements}
          streams={streams}
          samples={measurements?.samples ?? []}
          highlightedSample={highlightedSample}
          onSampleModalToggle={onSampleModalToggle}
          measurementTypeListNames={measurementTypeListNames}
          getFormattedMeasurementValue={getFormattedMeasurementValue}
          getMeasurementUnit={getMeasurementUnit}
        />
      </>
    );
  }
}

export default connect(mapStateToProps, null)(AnalyzeGraph);
