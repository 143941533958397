import { restAPI } from "../adapters";
import {
  CreateOneRequestType,
  GetListResponseType,
  PaginatedListRequestType,
  ResourceServiceType,
  ResponseErrorType,
  ResponseStatusType,
  UpdateOneRequestType,
  fetchAndCollate,
} from "../util";
import { DomainModelMetaType } from "../util/requests/types";

export type ReportingGroupFilterType = {
  id?: string;
  name?: string;
};

export type ReportingGroupType = {
  /** unique ID of the reporting group */
  id: string;
  /** the name of this reporting group */
  name: string;
  /** description of the purpose of this reporting group */
  description: string;
  /** order to show this reporting group in */
  priority: number;
  /** under what company account is this reporting group under? */
  company_id: string;
} & DomainModelMetaType;

export type CreateReportingGroupType = Pick<
  ReportingGroupType,
  "id" | "name" | "description" | "priority"
>;

export type ReportingGroupEstimationMethodType = {
  groupId: string;
  estimationMethodId: string;
};

/**
 * Methods to communicate with the API endpoints dealing with Reporting Groups
 */
export const ReportingGroupDomain: ReportingGroupDomainServiceType = {
  /**
   * Get a list of reporting groups
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/reporting_groups/list_reporting_group
   */
  getList: async ({
    filters,
    page,
    pageSize,
    ...rest
  }: Partial<PaginatedListRequestType<ReportingGroupFilterType>> = {}) =>
    fetchAndCollate<ReportingGroupType>(
      ({ page, pageSize }) =>
        restAPI.nodeAPI.GET<GetListResponseType<ReportingGroupType>>({
          endpoint: "/reporting_groups",
          query: {
            page,
            page_size: pageSize,
            ...rest,
            ...(filters?.id ? { id: filters.id } : {}),
            ...(filters?.name ? { name: filters.name } : {}),
          },
        }),
      page,
      pageSize
    ),
  /**
   * Get a single reporting group
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/reporting_groups/get_reporting_group
   */
  getOne: async ({ id }) =>
    restAPI.nodeAPI
      .GET<ReportingGroupType>({
        endpoint: `/reporting_groups/${id}`,
      })
      .then((resp) => ({
        data: resp,
      })),
  getMany: () =>
    Promise.reject({
      success: false,
      error: "Method not implemented",
    }),
  /**
   * Create a single reporting group
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/reporting_groups/create_reporting_group
   */
  createOne: async (
    params: CreateOneRequestType<CreateReportingGroupType, any>
  ) =>
    restAPI.nodeAPI
      .POST<ReportingGroupType>({
        endpoint: "/reporting_groups",
        body: params.data,
      })
      .then((resp) => ({ data: resp })),
  createMany: () =>
    Promise.reject({
      success: false,
      error: "Method not implemented",
    }),
  /**
   * Create a single reporting group
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/reporting_groups/update_reporting_group
   */
  updateOne: async ({
    id,
    data,
  }: UpdateOneRequestType<
    Pick<ReportingGroupType, "name" | "description" | "priority">,
    any
  >) =>
    restAPI.nodeAPI
      .PUT<ReportingGroupType>({
        endpoint: `/reporting_groups/${id}`,
        body: data,
      })
      .then((resp) => ({ data: resp })),
  updateMany: () =>
    Promise.reject({
      success: false,
      error: "Method not implemented",
    }),
  /**
   * Create a single reporting group
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/reporting_groups/delete_reporting_group
   */
  deleteOne: async ({ id }) =>
    restAPI.nodeAPI
      .DELETE<ResponseStatusType | ResponseErrorType>({
        endpoint: `/reporting_groups/${id}`,
      })
      .then((resp) => resp)
      .catch((resp: ResponseErrorType) => resp),
  deleteMany: () =>
    Promise.reject({
      success: false,
      error: "Method not implemented",
    }),
  /**
   * Associate an estimation method to a reporting group
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/reporting_groups/add_estimation_method
   */
  addEstimationMethodToReportingGroup: async ({ id, data }) =>
    restAPI.nodeAPI.PUT<ReportingGroupType>({
      endpoint: `/reporting_groups/${id}/estimation_methods/${data.estimationMethodId}`,
    }),
  /**
   * Delete an estimation method relationship to a reporting group
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/reporting_groups/remove_estimation_method
   */
  deleteEstimationMethod: async ({ id, data }) =>
    restAPI.nodeAPI.DELETE<ReportingGroupType>({
      endpoint: `/reporting_groups/${id}/estimation_methods/${data.estimationMethodId}`,
    }),
  /**
   * Given a list of available reporting groups and a list of new ids to add
   * it will:
   *  * Add the new reporting groups to the estimation method
   *  * Delete all existing reporting groups that no longer should belong to the method
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/reporting_groups/remove_estimation_method
   */
  smartUpdate: async ({
    methodId,
    currentReportingGroup,
    updatedReportingGroups,
  }: {
    methodId: string;
    currentReportingGroup: CreateReportingGroupType[] | undefined;
    updatedReportingGroups: string[];
  }) => {
    const groupsToDelete = currentReportingGroup?.filter((group) => {
      if (!updatedReportingGroups) {
        return false;
      }
      return !updatedReportingGroups.includes(group.id);
    });

    const currentReportingGroupIds = currentReportingGroup?.map(
      (group) => group.id
    );
    const groupsToAddIds = updatedReportingGroups?.filter((groupId) => {
      if (!updatedReportingGroups) {
        return false;
      }
      return !currentReportingGroupIds?.includes(groupId);
    });

    if (groupsToDelete && groupsToDelete?.length > 0) {
      await Promise.all(
        groupsToDelete.map((group) =>
          ReportingGroupDomain.deleteEstimationMethod({
            id: group.id,
            data: { estimationMethodId: methodId },
          })
        )
      );
    }

    if (groupsToAddIds && groupsToAddIds?.length > 0) {
      await Promise.all(
        groupsToAddIds.map((groupId) =>
          ReportingGroupDomain.addEstimationMethodToReportingGroup({
            id: groupId,
            data: { estimationMethodId: methodId },
          })
        )
      );
    }

    return true;
  },
  // UPDATE
};

export interface ReportingGroupDomainServiceType
  extends ResourceServiceType<ReportingGroupType> {
  addEstimationMethodToReportingGroup: (
    params: Pick<ReportingGroupType, "id"> & {
      data: Pick<ReportingGroupEstimationMethodType, "estimationMethodId">;
    }
  ) => Promise<any>;
  deleteEstimationMethod: (
    params: Pick<ReportingGroupType, "id"> & {
      data: Pick<ReportingGroupEstimationMethodType, "estimationMethodId">;
    }
  ) => Promise<any>;
  smartUpdate: (params: {
    methodId: string;
    currentReportingGroup: CreateReportingGroupType[] | undefined;
    updatedReportingGroups: string[];
  }) => Promise<boolean>;
}
