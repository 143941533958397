import { RoutingLink } from "#batteries-included-components/RoutingLink";
import { useListEstimationMethodRuns } from "#hooks/adapters/useEstimationMethods";
import { useGetOneNetwork } from "#hooks/adapters/useNetworks";
import { useGetManyUsers } from "#hooks/adapters/useUsers";
import { useTableSortingAndPagination } from "#redux/reducers/tableStateReducer";
import { useParams } from "#routers/hooks";
import { NetworkDetailPageParameters } from "#routes/organization/networks/[networkId]/detail";
import { NetworkCalculationResultRoute } from "#routes/organization/networks/[networkId]/detail/calculation-result/[period]/details";
import { useMeasurementTypes } from "#src/contexts/MeasurementTypeContext";
import {
  DataTable,
  DataTablePanel,
  HeaderType,
  SortingType,
  StorageKeys,
  useFilters,
} from "@validereinc/common-components";
import {
  AssetType,
  CalculationOutputType,
  EstimationMethodRunType,
  SortDirection,
  UserType,
} from "@validereinc/domain";
import {
  getFormattedNumberWithUnit,
  yearMonthName,
} from "@validereinc/utilities";
import React from "react";

const sorting: SortingType = {
  sortBy: "year_month",
  sortDirection: SortDirection.DESCENDING,
};
export const NetworkResultsTablePanel = ({
  filterConfigStorageKey,
  tableConfigStorageKey,
}: StorageKeys) => {
  const [filters] = useFilters(filterConfigStorageKey);
  const { getUnitName, getUnitByType, getPrecisionByType } =
    useMeasurementTypes();

  const { networkId } = useParams<NetworkDetailPageParameters>();
  const { data: network, isLoading: isNetworkLoading } =
    useGetOneNetwork(networkId);

  const [tableState, updateTableState] = useTableSortingAndPagination(
    sorting,
    filters
  );

  const getOutputValue = (run: EstimationMethodRunType, type: string) => {
    const output = run?.output?.outputs.find(
      ({ measurement_type }: CalculationOutputType) => measurement_type === type
    );

    if (!output) {
      return "-";
    }

    return getFormattedNumberWithUnit(
      {
        value: Number(output.measurement_value),
        unit: getUnitName(getUnitByType(type)),
      },
      getPrecisionByType(type),
      {
        showSmallNumberAsExponential: true,
        maxFractionDigits: getPrecisionByType(type)!,
        minFractionDigits: 0,
      }
    );
  };

  const isListEnabled = !!network?.default_estimation_method_id;

  const { data, isLoading: isListLoading } = useListEstimationMethodRuns(
    {
      page: tableState.page,
      pageSize: tableState.itemsPerPage,
      sortBy: tableState.sortBy,
      sortDirection: tableState.sortDirection,
      filters: {
        methodId: network?.default_estimation_method_id ?? "",
        entityType: AssetType.ASSET_GROUP,
      },
    },
    { enabled: isListEnabled }
  );

  const items = data?.data ?? [];
  const isLoading = isNetworkLoading || (isListEnabled && isListLoading);

  const usersQuery = useGetManyUsers(
    Array.from(new Set(items.map(({ updated_by }) => updated_by))).filter(
      (item) => item
    )
  );

  const userMap = usersQuery.reduce(
    (accumulator: Record<string, UserType>, current) => {
      if (current.data?.id) {
        accumulator[current.data.id] = current.data;
      }
      return accumulator;
    },
    {}
  );

  const headers: Array<HeaderType<EstimationMethodRunType>> = [
    {
      key: "year_month",
      label: "Time Period",
      isSortable: true,
      renderComponent: ({ item }) => {
        const period = item?.year_month;
        const pathname = NetworkCalculationResultRoute.toLink({
          pathParams: {
            networkId,
            period,
          },
        });
        return (
          <RoutingLink to={pathname}>
            {period ? yearMonthName(period) : "-"}
          </RoutingLink>
        );
      },
    },
    {
      key: "total_inlet",
      label: "Total Inlet",
      isSortable: false,
      renderComponent: ({ item }) => getOutputValue(item, "upstream_volume"),
    },
    {
      key: "total_adjusted",
      label: "Total Adjusted Volume",
      isSortable: false,
      renderComponent: ({ item }: { item: EstimationMethodRunType }) =>
        getOutputValue(item, "downstream_volume_adjusted"),
    },
    {
      key: "total_non_adjustable",
      label: "Total Non-Adjustable Volume",
      isSortable: false,
      renderComponent: ({ item }: { item: EstimationMethodRunType }) =>
        getOutputValue(item, "downstream_volume_non_adjustable"),
    },
    {
      key: "total_adjustable",
      label: "Total Adjustable Volume",
      isSortable: false,
      renderComponent: ({ item }: { item: EstimationMethodRunType }) =>
        getOutputValue(item, "downstream_volume_adjustable"),
    },
    {
      key: "adjustment_factor",
      label: "Adjustment Factor",
      isSortable: false,
      renderComponent: ({ item }: { item: EstimationMethodRunType }) =>
        getOutputValue(item, "adjustment_factor"),
    },
    {
      label: "Saved By",
      key: "created_by",
      isSortable: true,
      renderComponent: ({ item }) => userMap[item.created_by]?.name ?? "-",
    },
    {
      label: "Saved At",
      key: "created_at",
      isSortable: true,
      renderComponent: ({ item }) => (
        <DataTable.DataRow.DateCell
          value={item.created_at}
          withTime
        />
      ),
    },
  ];

  return (
    <DataTablePanel
      storageKey={tableConfigStorageKey}
      panelProps={{
        title: "Calculations",
      }}
      dataTableProps={{
        headers,
        items,
        isLoading,
        sorting,
        onSortChange: updateTableState,
        onPaginationChange: updateTableState,
        pagination: {
          page: tableState.page,
          itemsPerPage: tableState.itemsPerPage,
          total: data?.total_entries ?? 0,
        },
      }}
    />
  );
};
