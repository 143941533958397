import React, { useEffect } from "react";

import { useUpdateCustomAttributes } from "#src/components/hooks/adapters/useCustomAttributes";
import {
  Button,
  Dialog,
  DropdownInput,
  Form,
  RadioInput,
  TextInput,
  useForm,
} from "@validereinc/common-components";
import {
  AssetType,
  AssetTypeType,
  AttributeDataType,
  CustomAttributeSchema,
  CustomAttributeType,
} from "@validereinc/domain";

export const EditCustomAttributeDialog = ({
  isOpen,
  onClose,
  assetType,
  itemToUpdate,
}: {
  isOpen: boolean;
  onClose: () => void;
  assetType: AssetTypeType;
  itemToUpdate: CustomAttributeType;
}) => {
  const form = useForm({
    defaultValues: {
      display_name: itemToUpdate?.display_name,
      description: itemToUpdate?.description,
      is_required: itemToUpdate?.is_required,
      pick_list_values: Array.isArray(itemToUpdate?.pick_list_values)
        ? itemToUpdate?.pick_list_values.join(", ")
        : itemToUpdate?.pick_list_values,
      lookup_entity_type: itemToUpdate?.lookup_entity_type,
      unit: itemToUpdate?.unit,
    },
  });

  useEffect(() => {
    form.reset();
  }, [isOpen]);

  const onSuccess = () => {
    onClose?.();
  };

  const dataType = itemToUpdate?.data_type;

  const createCustomAttribute = useUpdateCustomAttributes({ onSuccess });
  const handleSubmit = form.handleSubmit((values) => {
    const {
      display_name,
      description,
      pick_list_values,
      lookup_entity_type,
      is_required,
    } = values;
    const field_name = itemToUpdate.field_name;
    const entity_type = assetType;
    createCustomAttribute.mutate({
      id: field_name,
      data: {
        entity_type,
        display_name,
        description,
        is_required,
        lookup_entity_type,
        pick_list_values: pick_list_values
          ? pick_list_values.split(",").map((item) => item.trim())
          : undefined,
      },
    });
  });
  const assetTypeOptions = Object.entries(AssetType).map(([key, value]) => ({
    label: value,
    id: key,
  }));

  return (
    <Dialog
      title="Edit Custom Attribute"
      isOpen={isOpen}
      onClose={onClose}
      actionRow={[
        <Button
          key="edit-custom-attribute"
          variant="primary"
          onClick={handleSubmit}
        >
          Edit
        </Button>,
      ]}
    >
      <Form {...form}>
        <TextInput
          name={CustomAttributeSchema.keyof().Enum.display_name}
          label="Display Name"
          isRequired
          type="text"
        />
        <TextInput
          name={CustomAttributeSchema.keyof().Enum.description}
          label="Description"
          type="text"
        />
        <RadioInput
          inputId="is_required"
          name={CustomAttributeSchema.keyof().Enum.is_required}
          label="Is Required"
          isRequired
          options={[
            {
              label: "Yes",
              value: true,
            },
            {
              label: "No",
              value: false,
            },
          ]}
          valueKey="value"
          labelKey="label"
        />
        {dataType == AttributeDataType.PICK_LIST ? (
          <TextInput
            name={CustomAttributeSchema.keyof().Enum.pick_list_values}
            label="Pick List Values"
            isRequired
            description="The values must be comma seperated values. e.g: Option 1, Option 2"
            type="text"
            value="Option 1, Option 2"
          />
        ) : (
          []
        )}
        {dataType == AttributeDataType.LOOKUP ? (
          <DropdownInput
            name={CustomAttributeSchema.keyof().Enum.lookup_entity_type}
            label="Lookup Asset"
            options={assetTypeOptions}
            valueKey="label"
            labelKey="label"
            isDisabled
          />
        ) : (
          []
        )}
        {dataType == AttributeDataType.NUMBER ? (
          <TextInput
            name={CustomAttributeSchema.keyof().Enum.unit}
            label="Unit"
            isDisabled
            type="text"
          />
        ) : (
          []
        )}
      </Form>
    </Dialog>
  );
};
