import React from "react";
import PropTypes from "prop-types";
import SelectInput from "../../../Common/Form/SelectInput";
import Form from "../../../Common/Form/Form";
import find from "lodash/find";
import isEqual from "lodash/isEqual";
import {
  NOTIFICATION_OPTIONS,
  NOTIFICATION_CONFIG,
} from "../../../../utils/enums";
import { ObjectArrayToStringArray } from "../../../../utils/arrayFormatter";

const lodash = { find, isEqual };

const titleCol = 5;

const getDropdownTitle = (value) => {
  const selectedValue = lodash.find(
    NOTIFICATION_OPTIONS,
    (option) => {
      return lodash.isEqual(option.value, value);
    },
    value
  );

  if (selectedValue) {
    return selectedValue.name.toUpperCase();
  } else {
    return "NONE";
  }
};

const NotificationForm = (props) => {
  const { notificationSettings } = props;
  const notificationOptions = ObjectArrayToStringArray(
    NOTIFICATION_OPTIONS,
    "name"
  );
  return (
    <Form>
      <SelectInput
        titleCol={titleCol}
        title={NOTIFICATION_CONFIG["tests.offspec"].title}
        message={NOTIFICATION_CONFIG["tests.offspec"].message}
        value={[getDropdownTitle(notificationSettings["tests.offspec"])]}
        onChange={(eventKey) =>
          props.onDropdownSelect(
            notificationOptions.indexOf(eventKey),
            "tests.offspec"
          )
        }
        options={notificationOptions}
      />
      <SelectInput
        titleCol={titleCol}
        title={NOTIFICATION_CONFIG["tests.non_routine"].title}
        message={NOTIFICATION_CONFIG["tests.non_routine"].message}
        value={[getDropdownTitle(notificationSettings["tests.non_routine"])]}
        onChange={(eventKey) =>
          props.onDropdownSelect(
            notificationOptions.indexOf(eventKey),
            "tests.non_routine"
          )
        }
        options={notificationOptions}
      />
      <SelectInput
        titleCol={titleCol}
        title={NOTIFICATION_CONFIG["tests.orphan"].title}
        message={NOTIFICATION_CONFIG["tests.orphan"].message}
        value={[getDropdownTitle(notificationSettings["tests.orphan"])]}
        onChange={(eventKey) =>
          props.onDropdownSelect(
            notificationOptions.indexOf(eventKey),
            "tests.orphan"
          )
        }
        options={notificationOptions}
      />
    </Form>
  );
};

NotificationForm.propTypes = {
  notificationSettings: PropTypes.object,
  onDropdownSelect: PropTypes.func,
  disabled: PropTypes.bool,
};

export default NotificationForm;
