export * from "./CalculatorsPage";

export const CALCULATORS_PAGE_TITLE = "Calculators";

export const linkToCalculators = () => {
  return `/app/calculations/calculators`;
};

export const CALCULATORS_BREADCRUMB = {
  title: CALCULATORS_PAGE_TITLE,
  path: linkToCalculators,
};

export const CALCULATORS_TAB = {
  id: "calculators",
  title: CALCULATORS_PAGE_TITLE,
};
