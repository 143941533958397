import React from "react";

export interface BrandmarkProps {
  isInverted?: boolean;
  isAnimated?: boolean;
}

export const Brandmark = ({
  isInverted = false,
  isAnimated = false,
}: BrandmarkProps) => {
  const animationDuration = 1250;
  const [originalFillColor, originalBgColor] = ["#1D2C34", "#E1EAEE"];
  const [invertedFillColor, invertedBgColor] = [
    originalBgColor,
    originalFillColor,
  ];

  return (
    <svg
      width="73"
      height="40"
      viewBox="0 0 73 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {isAnimated ? (
        <defs>
          <linearGradient id="left-to-right">
            <stop
              offset="0"
              stopColor={isInverted ? originalBgColor : invertedBgColor}
            >
              <animate
                dur={`${animationDuration}ms`}
                repeatCount="indefinite"
                attributeName="offset"
                // uses an ease-in-out cubic bezier easing function i.e. cubic-bezier(0.42, 0, 0.58, 1)
                keySplines="0.42 0 0.58 1;"
                keyTimes="0;1"
                calcMode="spline"
                from="0"
                to="1"
              />
            </stop>
            <stop
              offset="0"
              stopColor={isInverted ? invertedBgColor : originalBgColor}
            ></stop>
          </linearGradient>
        </defs>
      ) : null}
      <path
        fill={
          isAnimated
            ? "url(#left-to-right)"
            : isInverted
              ? invertedFillColor
              : originalFillColor
        }
        d="M54.2559 7.38699C52.6552 4.76641 50.9025 2.05652 48.0024 0.773017C45.2213 -0.456896 41.7792 -0.196624 39.1881 1.39308C38.0584 2.08458 37.0985 3.03892 36.3766 4.15145V4.1438C35.9714 4.76896 35.6345 5.45026 35.3888 6.18004L26.7518 31.6382C26.5948 32.1001 25.9464 32.1001 25.7919 31.6382C23.9049 25.9403 17.0409 4.94758 15.8556 1.74011L0 1.74011C2.58349 5.9657 18.4973 32.0465 18.4999 32.0491C20.1006 34.6696 21.8533 37.3795 24.7534 38.663C27.5345 39.8929 30.9766 39.6327 33.5677 38.043C34.6973 37.3515 35.6573 36.3971 36.3792 35.2846V35.2923C36.7844 34.6671 37.1213 33.9858 37.367 33.256L46.0014 7.79781C46.1584 7.33596 46.8069 7.33596 46.9614 7.79781C48.8483 13.4957 55.7123 34.4885 56.8977 37.6959L72.7558 37.6959C70.1723 33.4678 54.2585 7.38444 54.2559 7.38444V7.38699Z"
      />
    </svg>
  );
};
