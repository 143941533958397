import { RecordFacilitySummaryPanel } from "#batteries-included-components/Panels/SummaryPanels/RecordFacilitySummaryPanel";
import {
  FACILITIES_BREADCRUMB,
  FACILITY_DETAIL_BREADCRUMB,
} from "#routers/breadcrumbs";
import { useBreadcrumbs } from "#routers/breadcrumbsHelper";
import { useParams } from "#routers/hooks";
import { ORGANIZATION_BREADCRUMB } from "#routes/organization";
import ReportingGroupPillPanel from "#src/batteries-included-components/Panels/PillPanels/ReportingGroupPillPanel/ReportingGroupPillPanel";
import { RecordSummaryPanel } from "#src/batteries-included-components/Panels/SummaryPanels/RecordSummaryPanel";
import { RecordValueTablePanel } from "#src/batteries-included-components/Panels/TablePanels/RecordValueTablePanel";
import {
  FacilityContext,
  FacilityProvider,
} from "#src/contexts/FacilityContext";
import { RecordContext, RecordProvider } from "#src/contexts/RecordContext";
import useLocalization from "#src/hooks/useLocalization";
import { Column, Page, Row } from "@validereinc/common-components";
import { yearMonthName } from "@validereinc/utilities";
import React, { useContext } from "react";
import {
  FACILITY_RECORD_DETAIL_BREADCRUMB,
  FACILITY_RECORD_DETAIL_PAGE_TITLE,
  type FacilityRecordDetailPageParamsType,
} from "./index";

const FlowRecordDetailPageContent = () => {
  const params = useParams<FacilityRecordDetailPageParamsType>();
  const { localize } = useLocalization();

  const { facility } = useContext(FacilityContext) || {};
  const { record } = useContext(RecordContext) || {};

  const pageTitle = record
    ? `${yearMonthName(record.year_month)} Record`
    : FACILITY_RECORD_DETAIL_PAGE_TITLE;

  const breadcrumbs = useBreadcrumbs(
    [
      ORGANIZATION_BREADCRUMB,
      FACILITIES_BREADCRUMB,
      FACILITY_DETAIL_BREADCRUMB,
      FACILITY_RECORD_DETAIL_BREADCRUMB,
    ],
    {
      2: facility?.name,
      3: pageTitle,
    },
    {
      ...params,
      id: params.facilityId,
    }
  );

  return (
    <Page
      category={`${localize("Facility")} Record`}
      title={pageTitle}
      breadcrumbs={breadcrumbs}
    >
      <Row>
        <Column variant={6}>
          <RecordSummaryPanel />
          <ReportingGroupPillPanel
            id={record?.reporting_group_id}
            isLoading={!record}
          />
        </Column>
        <Column variant={18}>
          <RecordFacilitySummaryPanel />
          <RecordValueTablePanel />
        </Column>
      </Row>
    </Page>
  );
};

export const FacilityRecordDetailPage = () => (
  <FacilityProvider>
    <RecordProvider>
      <FlowRecordDetailPageContent />
    </RecordProvider>
  </FacilityProvider>
);
