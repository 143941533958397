import { ensureStreamListIsFetched } from "#redux/actions/index";
import { useNavigate } from "#routers/hooks";
import { linkToProductionStreams } from "#routers/links";
import { LegacyDataTable, Page, Panel } from "@validereinc/common-components";
import * as PropTypes from "prop-types";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { AutoSizer } from "react-virtualized";

const mapStateToProps = ({ streams }) => ({
  streams: streams.data?.toJS() ?? [],
});

const mapDispatchToProps = {
  ensureStreamListIsFetched,
};

const Streams = ({
  height,
  streams,
  breadcrumbs,
  ensureStreamListIsFetched,
}) => {
  const navigate = useNavigate();

  useEffect(() => {
    ensureStreamListIsFetched();
  }, []);

  function onRowClick(row) {
    navigate({
      pathname: linkToProductionStreams(row.id),
    });
  }

  const headers = [
    {
      label: "Stream Name",
      key: "name",
      width: 400,
      fixed: true,
    },
    {
      label: "Product Type",
      key: "product_type",
      width: 350,
      fixed: true,
    },
  ];

  return (
    <Page
      title="Production Streams"
      breadcrumbs={breadcrumbs}
    >
      <Panel style={{ height }}>
        <AutoSizer>
          {({ height, width }) => (
            <LegacyDataTable
              width={width}
              height={height}
              headers={headers}
              list={streams}
              defaultSortBy="name"
              defaultSortDirection="desc"
              rowHeight={50}
              highlightRow={true}
              onCellClick={onRowClick}
              filterKey="name"
            />
          )}
        </AutoSizer>
      </Panel>
    </Page>
  );
};

Streams.propTypes = {
  height: PropTypes.number.isRequired,
  match: PropTypes.object,
  breadcrumbs: PropTypes.array.isRequired,
  streams: PropTypes.array.isRequired,
  ensureStreamListIsFetched: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Streams);
