import { faker as faker_en } from "@faker-js/faker/locale/en";
import { faker as faker_en_CA } from "@faker-js/faker/locale/en_CA";
import { faker as faker_en_US } from "@faker-js/faker/locale/en_US";
import { SafeParseReturnType, ZodTypeAny } from "zod";

export const DomainFaker = {
  en: faker_en,
  en_US: faker_en_US,
  en_CA: faker_en_CA,
} as const;

export class DomainMock<TMock, TMockSchema extends ZodTypeAny> {
  private _mockFactory: (seed?: number) => TMock;
  private _mock: TMock | null = null;
  readonly schema: TMockSchema;
  private _isValid = false;

  get mock() {
    return this._mock ?? this._mockFactory();
  }

  get isValid() {
    return this._isValid;
  }

  /**
   * Used to create mocks in this domain package. Provide the mock you wish to
   * create, the associated Zod schema it's based on or testing, and if you're intended the
   * mock to be valid or invalid against the schema.
   *
   * If what you say about the mock's validity does NOT match what the Zod
   * schema you provided says about the mock's validity, then a DomainMockError
   * will be thrown. This is intentional. This keeps the mock definitions
   * _always_ in relation to the schema.
   *
   * @see {@link DomainMockError}
   * @param schema the Zod schema the mock is related to - whether it's based on it or testing it.
   * @param mock the mock you wish to create. Can be anything. Needs to be a generator function that accepts a seed number. (see Faker.js seed documentation)
   * @param isValid is the mock you declared valid against the schema? or is it intentionally invalid?
   * @returns instance of DomainMock, which you should store and consume as mocks in tests
   *
   * @example "mock with true validity"
   * new DomainMock({ greeting: "Hello World"}, z.object({ greeting: z.string() }), true)
   * @example "mock with intentional false validity"
   * new DomainMock({ greeting: 1 }, z.object({ greeting: z.string() }), false)
   * @example "mock that will throw an error"
   * new DomainMock({ greeting: "Hello World" }, z.object({ greeting: z.string(), time: z.date() }), true)
   * @example "mock that will throw an error"
   * new DomainMock({ greeting: "Hello World" }, z.object({ greeting: z.string() }), false)
   */
  constructor(
    schema: TMockSchema,
    mockFactory: (seed?: number) => TMock,
    isValid: boolean
  ) {
    this._mockFactory = mockFactory;
    this.schema = schema;

    this.verify(isValid);
  }

  protected verify = (isDeclaredValid: boolean) => {
    if (!this.schema || !this.mock) {
      return;
    }

    const result = this.validationResult();

    this._isValid = result.success;

    if (isDeclaredValid !== result.success) {
      throw new DomainMockError({
        message:
          "The declared validity of the mock does not match what the schema says about the mock's validity",
        declaredValdity: isDeclaredValid,
        actualValidity: result,
        mock: this.mock,
        schema: this.schema,
      });
    }
  };

  validationResult = (): SafeParseReturnType<any, any> => {
    if (!this.schema) {
      return { success: true, data: this.mock };
    }

    return this.schema.safeParse(this.mock);
  };

  /**
   * Run the mock factory to generate a new mock instead of the one stored within
   * @param seed provide a seed to use in generation @see {@link https://fakerjs.dev/guide/usage.html#reproducible-results}
   * @returns a new mock instance
   */
  generate = (seed?: number) => {
    return this._mockFactory(seed);
  };

  /**
   * Call the provided mock factory with the provided seed and store the
   * resulting mock within. All future calls to get the mock property will now
   * return the stored mock
   * @param seed provide a seed to use in generation @see {@link https://fakerjs.dev/guide/usage.html#reproducible-results}
   * @returns the original instance this was called on
   */
  fix = (seed?: number) => {
    this._mock = this.generate(seed);

    return this;
  };

  /**
   * Clears the stored mock
   * @returns the original instance this was called on
   */
  unfix = () => {
    this._mock = null;

    return this;
  };
}

// IMPROVE: need a better error class - look into leveraging https://github.com/ehmicky/modern-errors
class DomainMockError<TMock, TMockSchema extends ZodTypeAny> extends Error {
  readonly mock: TMock;
  readonly schema: TMockSchema;

  constructor(
    details: {
      message: string;
      declaredValdity: boolean;
      actualValidity: SafeParseReturnType<any, any>;
      mock: TMock;
      schema: TMockSchema;
    },
    options?: ErrorOptions
  ) {
    super(
      `DomainMockError: ${[
        details.message,
        `Declared = ${details.declaredValdity} | Actual = ${JSON.stringify(
          details.actualValidity
        )}`,
        details.mock ? `Mock - ${JSON.stringify(details.mock)}` : null,
        details.schema.description
          ? `Schema - ${details.schema.description}`
          : null,
        options?.cause ? `Cause - ${JSON.stringify(options.cause)}` : null,
      ]
        .filter((x) => Boolean(x))
        .join(", ")}`,
      options
    );
    this.mock = details.mock;
    this.schema = details.schema;
  }
}
