import {
  ADD_EXISTING_SAMPLE_MODAL_KEY,
  ADD_NEW_SAMPLE_MODAL_KEY,
} from "#components/ChainOfCustody/CreateChainOfCustody/SampleModals/ModalConstants";
import { GetAllObjectValue } from "#utils/objectFormatter";
import {
  Button,
  DateTimeInput,
  FormInputWrapper,
  InputStack,
  SelectInput,
  TextInput,
  Title,
} from "@validereinc/common-components";
import differenceWith from "lodash/differenceWith";
import * as PropTypes from "prop-types";
import React, { useMemo } from "react";
import { v1 as uuidv1 } from "uuid";
import { getRequiredMeasurementsForInstrument } from "./FieldManualEntryHelper";
import SelectedSampleSummary from "./SelectedSampleSummary";

const FieldManualEntryForm = ({
  form,
  instruments,
  disabled = false,
  onModalToggle,
  getMeasurementType,
}) => {
  const sharedProps = {
    isDisabled: disabled,
    showIcon: true,
  };

  const instrument = form.getValues("instrument");

  const samples = form.watch("samples");

  const measurements = form.watch("measurements");

  const onInstrumentChange = (value) => {
    const validMeasurements = value?.valid_measurement_types;

    const noInvalidMeasurements = validMeasurements
      ? measurements.filter((measurement) =>
          validMeasurements.includes(measurement.type)
        )
      : measurements;

    // Missing measurements for certain instruments can cause pages to crash
    // or look weird
    const requiredMeasurements = getRequiredMeasurementsForInstrument(value);

    requiredMeasurements.forEach((requiredMeasurement) => {
      if (
        noInvalidMeasurements.every(
          (measurement) => measurement.type !== requiredMeasurement
        )
      ) {
        noInvalidMeasurements.push({
          type: requiredMeasurement,
          unit: getMeasurementType(requiredMeasurement).unit,
        });
      }
    });

    form.setValue(
      "measurementTypes",
      noInvalidMeasurements.map((measurement) => measurement.type)
    );
    form.setValue("measurements", noInvalidMeasurements);
  };

  const onMeasurementTypesChange = (value) => {
    if (value.length > measurements.length) {
      const newMeasurementType = differenceWith(
        value,
        measurements,
        (value, measurement) => {
          return value === measurement.type;
        }
      )?.[0];

      form.setValue("measurements", [
        ...measurements,
        {
          type: newMeasurementType,
          unit: getMeasurementType(newMeasurementType).unit,
          uuid: uuidv1(),
        },
      ]);
    } else {
      form.setValue(
        "measurements",
        measurements.filter((measurement) => value.includes(measurement.type))
      );
    }
  };

  const measurementOptions = useMemo(() => {
    return instrument
      ? instrument.valid_measurement_types
      : GetAllObjectValue(instruments, "valid_measurement_types");
  }, [instruments, instrument]);

  return (
    <div className="fieldManualEntry">
      <Title
        type="subheader"
        className="form__header"
      >
        Information
      </Title>

      <FormInputWrapper
        name="samples"
        label="Select Sample"
        as={() => (
          <>
            <Button
              className="addNewButton"
              onClick={() => onModalToggle(ADD_NEW_SAMPLE_MODAL_KEY)}
              disabled={disabled}
            >
              Add New
            </Button>

            <Button
              onClick={() => onModalToggle(ADD_EXISTING_SAMPLE_MODAL_KEY)}
              disabled={disabled}
            >
              Select Existing
            </Button>

            <br />

            {samples && (
              <div className="fieldManualEntry__selectedSampleSummaryContainer">
                <SelectedSampleSummary
                  sample={samples}
                  onRemoveSampleClick={() => form.setValue("samples", null)}
                  disabled={disabled}
                />
              </div>
            )}
          </>
        )}
        {...sharedProps}
      />

      <SelectInput
        name="instrument"
        label="Instrument"
        labelKey="name"
        options={instruments}
        validate={{
          isEmptyInstrument: () => {
            if (!instrument) {
              return "You must enter an instrument.";
            }
            return null;
          },
        }}
        onChange={(value) => onInstrumentChange(value)}
        ignoreCase
        isRequired
        {...sharedProps}
      />

      <DateTimeInput
        name="date"
        label="Test Date"
        isRequired
        {...sharedProps}
      />

      <SelectInput
        name="measurementTypes"
        label="Measurements"
        options={measurementOptions}
        validate={{
          areEmptyMeasurementTypes: () => {
            if (!measurements.length) {
              return "You must enter a measurement.";
            }

            const requiredMeasurements =
              getRequiredMeasurementsForInstrument(instrument);

            for (const requiredMeasurement of requiredMeasurements) {
              if (
                !form
                  .getValues("measurementTypes")
                  .includes(requiredMeasurement)
              ) {
                return `The instrument ${instrument.name} must have the measurement '${requiredMeasurement}'.`;
              }
            }

            return null;
          },
        }}
        onChange={(value) => onMeasurementTypesChange(value)}
        isMulti
        ignoreCase
        isRequired
        {...sharedProps}
      />

      {measurements?.map((measurement, index) => (
        <div key={measurement.uuid}>
          <InputStack
            name={measurement.type}
            label={measurement.type}
            {...sharedProps}
          >
            <TextInput
              name={`measurements[${index}].value`}
              type="number"
              placeholder="Value"
              unit={measurement.unit ?? ""}
              validate={{
                isEmptyMeasurementValue: () => {
                  if (index < measurements.length && !measurement.value) {
                    return `The measurement '${measurement.type}' must have a value`;
                  }
                  return null;
                },
              }}
              {...sharedProps}
            />

            <TextInput
              name={`measurements[${index}].temperature`}
              type="number"
              placeholder="Temperature"
              unit="°C"
              {...sharedProps}
            />
          </InputStack>
        </div>
      ))}
    </div>
  );
};

FieldManualEntryForm.propTypes = {
  form: PropTypes.object.isRequired,
  instruments: PropTypes.array.isRequired,
  disabled: PropTypes.bool.isRequired,
  onModalToggle: PropTypes.func.isRequired,
  getMeasurementType: PropTypes.func.isRequired,
};

export default FieldManualEntryForm;
