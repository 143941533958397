import { useEmissionsReportSummary } from "#hooks/useEmissionsReports";
import {
  ControlLabel,
  MultiDropdownInputWithSearch,
  Panel,
} from "@validereinc/common-components";
import classNames from "classnames/bind";
import uniqBy from "lodash/uniqBy";
import * as PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import {
  DEFAULT_POLLUTANT,
  EMITTED_POLLUTANTS,
  INTENSITY,
} from "../../../EmissionConstants";
import styles from "../EmissionReportDetail.module.scss";
import SummaryChart from "./SummaryChart";
import SummaryTable from "./SummaryTable";

const cx = classNames.bind(styles);

const SummaryPanel = ({ emissionReportId, defaultDateRange }) => {
  const [selectedPollutant, setSelectedPollutant] = useState(DEFAULT_POLLUTANT);
  const [selectedSalesStream, setSelectedSalesStream] = useState({});
  const [salesStreamOptions, setSalesStreamOptions] = useState([]);

  const [emissionReportSummary, loadingState] = useEmissionsReportSummary(
    emissionReportId,
    defaultDateRange
  );

  const onPollutantChange = (pollutant) => {
    setSelectedPollutant(pollutant);
  };

  const onSaleStreamChange = (saleStream) => {
    setSelectedSalesStream(saleStream);
  };

  useEffect(() => {
    if (loadingState === "loaded") {
      const saleStreams = emissionReportSummary.map(
        (emissionReport) => emissionReport.sales_stream
      );

      setSalesStreamOptions(uniqBy(saleStreams, "id"));
      setSelectedSalesStream(emissionReportSummary[0]?.sales_stream ?? []);
    }
  }, [emissionReportSummary, loadingState]);

  return (
    <Panel
      title="Emission Summary"
      loaded={loadingState === "loaded"}
    >
      <div className={cx("emissionDropdownContainer")}>
        <ControlLabel
          label="Pollutants"
          className={cx("emissionDropdown")}
        >
          <MultiDropdownInputWithSearch
            isMulti={false}
            options={[...EMITTED_POLLUTANTS, ...INTENSITY]}
            value={[selectedPollutant]}
            onChange={onPollutantChange}
            ignoreCase
            width={200}
          />
        </ControlLabel>

        <ControlLabel
          label="Sales Stream"
          className={cx("emissionDropdown")}
        >
          <MultiDropdownInputWithSearch
            isMulti={false}
            labelKey="name"
            options={salesStreamOptions}
            value={[selectedSalesStream]}
            onChange={onSaleStreamChange}
            ignoreCase
            width={200}
            isLoading={loadingState !== "loaded"}
          />
        </ControlLabel>
      </div>

      <SummaryChart
        selectedPollutant={selectedPollutant}
        selectedSalesStream={selectedSalesStream}
        emissionReportSummary={emissionReportSummary}
      />

      <SummaryTable emissionReportSummary={emissionReportSummary} />
    </Panel>
  );
};

SummaryPanel.propTypes = {
  emissionReportId: PropTypes.string,
  defaultDateRange: PropTypes.object.isRequired,
};

export default SummaryPanel;
