import uniqBy from "lodash/uniqBy";
import {
  getLocalStorageItem,
  setLocalStorageItem,
} from "#utils/localStorageHelper";

export function getAllFilterInputs(filters) {
  const filterPillbox = {};

  Object.keys(filters).map((key) => {
    const inputs = filters[key]?.inputs ?? [];
    if (inputs.length > 0) {
      filterPillbox[key] = inputs;
    }
  });

  return filterPillbox;
}

export const getSampleMeasurements = (tests) => {
  return tests.reduce(
    (measurements, test) => [...measurements, ...test.measurements],
    []
  );
};

export const getMeasurementsWithAlerts = (sample) => {
  const measurements = getSampleMeasurements(sample.tests);

  return measurements.reduce((alertMeasurements, measurement) => {
    if (measurement.alerts && measurement.alerts.length > 0) {
      return [...alertMeasurements, measurement];
    } else {
      return [...alertMeasurements];
    }
  }, []);
};

export const getAlerts = (sample) => {
  const measurements = getSampleMeasurements(sample.tests);

  return measurements.reduce((alerts, measurement) => {
    return [...alerts, ...measurement.alerts];
  }, []);
};

export function saveSelectedColumns(columns) {
  const columnNames = columns.map(({ value }) => value);
  setLocalStorageItem("lab_results--columns", columnNames);
  setLocalStorageItem("lab_results--defaultMeasurementsSaved", true);
}

/**
 * lab_results--columns stores all the optional columns to be shown
 * lab_results--defaultMeasurementsSaved is to ensure the addition of
 * defaultMeasurements to saved columns
 */
export function getSelectedColumns(defaultMeasurementTypes) {
  const columns = getLocalStorageItem("lab_results--columns");
  const isDefaultMeasurementColumnSaved = getLocalStorageItem(
    "lab_results--defaultMeasurementsSaved"
  );
  const isDefaultMeasurementTypesLoaded = defaultMeasurementTypes.length !== 0;

  let storedColumns = [];
  if (columns) {
    storedColumns = columns.map((label) => ({
      value: label,
      label,
    }));
  }

  // If defaultMeasurements not saved in localStorage, add it to selected
  // columns and mark it as saved. Makes sure it only runs once and then
  // uses stored value for all other access
  if (!isDefaultMeasurementColumnSaved && isDefaultMeasurementTypesLoaded) {
    const defaultMeasurementLabels = defaultMeasurementTypes.map((label) => ({
      value: label,
      label,
    }));

    const selectedColumns = uniqBy(
      [...defaultMeasurementLabels, ...storedColumns],
      "value"
    );

    saveSelectedColumns(selectedColumns);
    return selectedColumns;
  } else {
    return storedColumns;
  }
}
