import { parseCustomReportConfiguration } from "#batteries-included-components/Layouts/CustomReport/CustomReportConfigurationPanel/CustomReportConfigurationPanel.helpers";
import {
  CustomReportVariantType,
  CustomReportVariants,
  getCustomReportFilters,
  getCustomReportGroupBy,
} from "#batteries-included-components/Layouts/CustomReport/CustomReportDetailLayout.helpers";
import {
  getCustomReportDefaultSort,
  useCustomReportFlowHeaders,
  useEquipmentHeaders,
  useOutputHeaders,
} from "#batteries-included-components/Panels/TablePanels/CustomReportTablePanel/CustomReportTablePanel.helpers";
import {
  useExportCalculatorResults,
  useListCalculatorResults,
} from "#hooks/adapters/useCalculatorResults";
import { useExportRecords, useListRecords } from "#hooks/adapters/useRecords";
import { useTableSortingAndPagination } from "#redux/reducers/tableStateReducer";
import {
  Button,
  DataTablePanel,
  HeaderType,
  StorageKeys,
  useFilters,
} from "@validereinc/common-components";
import { CalculatorResultType, RecordType } from "@validereinc/domain";
import uniqBy from "lodash/uniqBy";
import React, { useEffect } from "react";

export const CustomReportTablePanel = ({
  variant,
  configStorageKey,
  filterConfigStorageKey,
  tableConfigStorageKey,
}: StorageKeys & {
  variant: CustomReportVariantType;
  configStorageKey: string;
}) => {
  const [filters] = useFilters(filterConfigStorageKey);
  const [config] = useFilters(configStorageKey);

  const { rows, outputs } = parseCustomReportConfiguration(config);
  const initialSorting = getCustomReportDefaultSort(variant, rows);

  const [tableState, updateTableState] = useTableSortingAndPagination(
    initialSorting,
    { ...filters, ...config }
  );

  useEffect(() => {
    /** Reset the sort params if the rows change */
    updateTableState(getCustomReportDefaultSort(variant, rows));
  }, [variant, rows]);

  const apiParams = {
    page: tableState.page,
    pageSize: tableState.itemsPerPage,
    sortBy: tableState.sortBy,
    sortDirection: tableState.sortDirection,
    filters: getCustomReportFilters(variant, config, filters),
    groupBy: getCustomReportGroupBy(variant, rows),
  };

  const isEnabled = !!(rows?.length && outputs?.length);

  const recordsQuery = useListRecords(apiParams, {
    enabled: variant === CustomReportVariants.VOLUMETRIC && isEnabled,
  });

  const resultsQuery = useListCalculatorResults(apiParams, {
    enabled: variant === CustomReportVariants.EMISSIONS && isEnabled,
  });

  const exportParams = {
    ...apiParams,
    groupBy: getCustomReportGroupBy(variant, rows, true),
  };

  const exportRecords = useExportRecords(exportParams);
  const exportResults = useExportCalculatorResults(exportParams);

  const flowHeaders = useCustomReportFlowHeaders();
  const equipmentHeaders = useEquipmentHeaders();
  const outputHeaders = useOutputHeaders(variant, outputs);

  // REVIEW: Bundle this into an IIFE or something? Types aren't disambiguated by the table
  let exportQuery: ReturnType<typeof useExportRecords> | undefined;
  let items: RecordType[] | CalculatorResultType[] = [];
  let rowHeaders:
    | Array<HeaderType<CalculatorResultType>>
    | Array<HeaderType<RecordType>> = [];
  let isLoading = false;
  let total = 0;

  switch (variant) {
    case CustomReportVariants.EMISSIONS:
      items = resultsQuery.data?.data ?? [];
      isLoading = resultsQuery.isLoading;
      total = resultsQuery.data?.total_entries ?? 0;
      rowHeaders = equipmentHeaders;
      exportQuery = exportResults;
      break;
    case CustomReportVariants.VOLUMETRIC:
    default:
      items = recordsQuery.data?.data ?? [];
      isLoading = recordsQuery.isLoading;
      total = recordsQuery.data?.total_entries ?? 0;
      rowHeaders = flowHeaders;
      exportQuery = exportRecords;
      break;
  }

  const headers = uniqBy(
    [
      ...(rows?.flatMap((selectedKey: string) => {
        const header = rowHeaders.find(({ key }) => key === selectedKey);
        return header ? [header] : [];
      }) ?? []),
      ...outputHeaders,
    ],
    ({ key }) => key
  );

  // action row for Records
  const actionRow = exportQuery
    ? [
        <Button
          key="export-custom-report"
          variant="outline"
          onClick={() => exportQuery?.mutate()}
          isLoading={exportQuery?.isLoading}
        >
          Export
        </Button>,
      ]
    : [];

  return (
    <DataTablePanel
      storageKey={tableConfigStorageKey}
      panelProps={{ actionRow, title: "Results", style: { marginBottom: 0 } }}
      dataTableProps={{
        isLoading: isEnabled && isLoading,
        items: isEnabled ? items : [],
        headers,
        onSortChange: updateTableState,
        onPaginationChange: updateTableState,
        pagination: {
          page: tableState.page,
          itemsPerPage: tableState.itemsPerPage,
          total,
        },
      }}
    />
  );
};
