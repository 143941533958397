import {
  StatusVariants,
  SummaryInformation,
  SummaryInformationContainer,
} from "#components/Common/SummaryInformation";
import config from "#config";
import useLocalization from "#src/hooks/useLocalization";
import { getTimeStringFromUTCDate } from "#utils/timeFormatter";
import { Column, Panel, Row } from "@validereinc/common-components";
import { UserType } from "@validereinc/domain";
import * as PropTypes from "prop-types";
import React, { useReducer } from "react";
import ConditionPanel from "./ConditionPanel";

const ConditionsTab = ({
  alertConfigurationDetail,
  createdByUser,
}: {
  // IMPROVE: add proper typing for the alert config here. Out of scope at time of writing.
  alertConfigurationDetail: unknown;
  createdByUser?: UserType;
}) => {
  const { inserted_at, conditions } = alertConfigurationDetail || {};
  const { localize } = useLocalization();

  const uniqueIdReducer = (prevState, newEntities) => {
    const newState = { ...prevState };

    if (!prevState.measurementTypes.includes(newEntities.measurementType)) {
      newState.measurementTypes.push(newEntities.measurementType);
    }

    if (!prevState.facilities.includes(newEntities.facility)) {
      newState.facilities.push(newEntities.facility);
    }

    if (!prevState.devices.includes(newEntities.device)) {
      newState.devices.push(newEntities.device);
    }

    return newState;
  };

  const [uniqueCounts, updateUniqueCounts] = useReducer(uniqueIdReducer, {
    measurementTypes: [],
    facilities: [],
    devices: [],
  });

  const sharedProps = {
    type: "vertical",
    isLoading: !alertConfigurationDetail,
    allowWrap: true,
  };

  return (
    <Row>
      <Column variant={6}>
        <Panel isFluidY={false}>
          <SummaryInformationContainer
            variant="vertical"
            status={alertConfigurationDetail?.active ? "Active" : "Archived"}
            statusVariant={
              alertConfigurationDetail?.active
                ? StatusVariants.ACTIVE
                : StatusVariants.DEFAULT
            }
          >
            <SummaryInformation
              title="Created At"
              value={getTimeStringFromUTCDate(
                inserted_at,
                config.DATETIME_FORMAT_READABLE
              )}
              {...sharedProps}
            />
            <SummaryInformation
              title="Created By"
              value={createdByUser?.name}
              {...sharedProps}
            />

            <SummaryInformation
              title="Conditions"
              value={conditions?.length}
              {...sharedProps}
            />

            <SummaryInformation
              title="Measurement Types"
              value={uniqueCounts?.measurementTypes?.length}
              {...sharedProps}
            />

            <SummaryInformation
              title={localize("facility_plural")}
              value={uniqueCounts?.facilities?.length}
              {...sharedProps}
            />

            <SummaryInformation
              title="Devices"
              value={uniqueCounts?.devices?.length}
              {...sharedProps}
            />
          </SummaryInformationContainer>
        </Panel>
      </Column>
      <Column variant={18}>
        {conditions?.map((condition, index) => (
          <ConditionPanel
            key={condition.id}
            condition={condition}
            index={index}
            updateUniqueCounts={updateUniqueCounts}
          />
        ))}
      </Column>
    </Row>
  );
};

ConditionsTab.propTypes = {
  alertConfigurationDetail: PropTypes.object.isRequired,
};

export default ConditionsTab;
