import { EventsFilterPanel } from "#src/batteries-included-components/Panels/FilterPanels/EventsFilterPanel";
import { EventsTablePanel } from "#src/batteries-included-components/Panels/TablePanels/EventsTablePanel";
import { useStorageKey } from "#src/hooks/useStorageKey";
import React from "react";

export const EventCategoryEventsTab = () => {
  const storageKeys = useStorageKey("events-categories");

  return (
    <>
      <EventsFilterPanel
        filterConfigStorageKey={storageKeys.filterConfigStorageKey}
      />
      <EventsTablePanel {...storageKeys} />
    </>
  );
};
