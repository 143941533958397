export * from "./EquipmentRecordDetailPage";

export const EQUIPMENT_RECORD_DETAIL_PAGE_TITLE = "Equipment Record Details";

export const linkToEquipmentRecordDetail = (
  equipmentId = ":equipmentId",
  recordId = ":recordId"
) => `/app/organization/equipment/${equipmentId}/record-details/${recordId}`;

export const EQUIPMENT_RECORD_DETAIL_BREADCRUMB = {
  title: `${EQUIPMENT_RECORD_DETAIL_PAGE_TITLE}:equipmentId:recordId`,
  path: linkToEquipmentRecordDetail,
};

export type EquipmentRecordDetailPageParamsType = {
  flowId: string;
  recordId: string;
};
