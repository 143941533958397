import React from "react";

const SVM_3000_KEYS = {
  Density: "predeterm. density",
  "Dynamic Viscosity": "dynamic viscosity",
  "Kinematic Viscosity": "kinematic viscosity",
};

const SVM_3001_KEYS = {
  "Kinematic Viscosity": "kin_visc",
  "Dynamic Viscosity": "dyn_visc",
  Density: "density",
};

const getMeasurementKey = (key, instrumentType) => {
  if (instrumentType === "anton paar svm 3001") {
    return SVM_3001_KEYS[key];
  }
  return SVM_3000_KEYS[key];
};

const CustomMeasurementHeaders = (testDetail, getMeasurementType) => {
  const measurementRenderer = (rowData, temperature) => {
    const { label, key, notMeasurement, getValue } = rowData;
    const instrumentType = testDetail?.instrument?.type;

    const additionalMeasurements =
      instrumentType === "anton paar svm 3001"
        ? testDetail.metadata.measurements[temperature]
        : testDetail.metadata.raw_measurements[temperature];

    const measurements = {
      ...testDetail.measurements,
      ...additionalMeasurements,
    };

    try {
      const value = getValue
        ? getValue(measurements[key])
        : measurements[getMeasurementKey(key, instrumentType)].value;

      const measurementType = notMeasurement ? null : getMeasurementType(label);

      return (
        <div className="wrap">
          {notMeasurement
            ? value
            : `${parseFloat(value).toFixed(measurementType.decimals)} ${
                measurementType.unit
              }`}
          <br />

          {measurements.calculated ? " (calculated)" : null}
        </div>
      );
    } catch {
      return <div>-</div>;
    }
  };

  const measurementsByTemp =
    testDetail.instrument?.type === "anton paar svm 3001"
      ? testDetail.metadata?.measurements ?? {}
      : testDetail.metadata?.raw_measurements ?? {};

  if (!Object.keys(measurementsByTemp).length) {
    return [];
  }

  return [
    ...Object.keys(measurementsByTemp)
      .sort((a, b) => a - b)
      .map((temp) => {
        const unit =
          measurementsByTemp[temp]?.cell_temp?.unit ??
          measurementsByTemp[temp]?.temperature?.unit ??
          "";

        return {
          label: `${temp} ${unit}`,
          key: temp,
          cellRenderer: measurementRenderer,
          width: 150,
          disableSort: true,
          rightAlign: true,
        };
      }),
  ];
};

export default CustomMeasurementHeaders;
