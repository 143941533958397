import { ExceptionUtils } from "#src/utils/exception";
import { Location } from "history";
import { userSignedIn } from "./index";

const getAuthInstance = (getState) => {
  const { authentication } = getState();
  if (!authentication.auth0Instance) {
    ExceptionUtils.reportException(
      new Error("Authentication integration instance not available")
    );
  } else {
    return authentication.auth0Instance;
  }
};

const clearTemporaryStorage = () => {
  localStorage.removeItem("validere--auth0--state");
  localStorage.removeItem("validere--auth0--nonce");
};

export function signIn(email = "") {
  ExceptionUtils.registerLifecycleEvent({
    category: "app",
    type: "info",
    message: "Redirecting to login",
  });

  return (dispatch, getState) => {
    const auth0Instance = getAuthInstance(getState);

    auth0Instance.loginWithRedirect({
      login_hint: email,
    });
  };
}

export const handleRedirectCallback = () => async (dispatch, getState) => {
  const auth0Instance = getAuthInstance(getState);

  try {
    // We're handling the redirect back from Auth0 to our site, `auth0Instance` will handle the final part
    await auth0Instance.handleRedirectCallback();

    // auth0Instance must have succeeded at handling the redirect, so we'll get our initial access token
    const accessToken = await auth0Instance.getTokenSilently();

    // Getting the token succeeded, we can clear the temporary localStorage we set for the `state` and
    // `nonce` options, and dispatch an event signifying we've signed the user in.
    clearTemporaryStorage();
    dispatch(userSignedIn());

    return accessToken;
  } catch (err) {
    ExceptionUtils.reportException(err, "fatal", {
      hint: "Failed to handle login redirect callback",
    });

    return Promise.reject(err);
  }
};

export const signOut = () => async (_dispatch, getState) => {
  const auth0Instance = getAuthInstance(getState);

  ExceptionUtils.teardown();
  auth0Instance.logout({
    logoutParams: {
      returnTo: `${window.location.origin}/app/logout`,
    },
  });
};

export const saveEntryLocation = (entryLocation) => (dispatch) => {
  const asString = JSON.stringify(entryLocation);
  dispatch({
    type: "ENTRY_LOCATION_SAVED",
    location: asString,
  });

  return sessionStorage.setItem("auth--entryLocation", asString);
};

export const popEntryLocation = () => (dispatch) => {
  const fromSession = sessionStorage.getItem("auth--entryLocation");
  sessionStorage.removeItem("auth--entryLocation");
  try {
    const parsed = fromSession ? (JSON.parse(fromSession) as Location) : null;

    dispatch({
      type: "ENTRY_LOCATION_RETRIEVED",
      location: parsed,
    });

    return parsed;
  } catch (e) {
    return null;
  }
};
