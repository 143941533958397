import React from "react";

import { Panel } from "@validereinc/common-components";

import { EventCategoryTemplatesTable } from "#src/batteries-included-components/Tables/EventCategoryTemplatesTable";

export const EventCategoryTemplatesPanel = () => {
  return (
    <Panel title="Templates">
      <EventCategoryTemplatesTable />
    </Panel>
  );
};
