import { NotFoundLayout } from "#src/batteries-included-components/Layouts/Errors/NotFound";
import { Page } from "@validereinc/common-components";
import React from "react";

export const NotFoundPage = () => {
  return (
    <Page>
      <NotFoundLayout />
    </Page>
  );
};
