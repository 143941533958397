import { Button, Modal, Title } from "@validereinc/common-components";
import classNames from "classnames/bind";
import * as PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import Input from "#components/Common/Input/Input";

import styles from "../CreateAlertConfiguration.module.scss";

const { Header, Body, Footer } = Modal;

const cx = classNames.bind(styles);

const AddRecipientModal = ({ defaultEmail, isOpen, onClose, onAdd }) => {
  const [email, setEmail] = useState(defaultEmail);

  const onAddRecipient = () => {
    if (email) {
      onAdd(email);

      onClose();

      setEmail("");
    }
  };

  useEffect(() => {
    setEmail(defaultEmail);
  }, [defaultEmail]);

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      size="small"
    >
      <Header>
        <Title>{defaultEmail ? "Edit" : "Add"} Recipient</Title>
      </Header>

      <Body>
        <label className={cx("label")}>Enter recipient email</label>
        <Input
          value={email}
          onChange={(value) => setEmail(value)}
          isRequired
        />
      </Body>

      <Footer>
        <div className={cx("modalActionsContainer")}>
          <Button onClick={onClose}>Cancel</Button>

          <Button
            onClick={onAddRecipient}
            variant="primary"
            disabled={!email}
          >
            {defaultEmail ? "Save" : "Add"}
          </Button>
        </div>
      </Footer>
    </Modal>
  );
};

AddRecipientModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  defaultEmail: PropTypes.string,
};

export default AddRecipientModal;
