import { QUERY_STATUS, QueryStatusType } from "#constants";
import FormService from "#services/FormService";
import { useEffect, useState } from "react";

// IMPROVE: all functions in this file need to be deleted
/** @deprecated use react query patterns instead */
export function useFormSchemas() {
  const [formSchemas, setFormSchemas] = useState([]);
  const [status, setStatus] = useState<QueryStatusType>(QUERY_STATUS.LOADING);

  useEffect(() => {
    FormService.listFormSchemas({})
      .then(({ data: { data } }) => {
        setFormSchemas(data);
        setStatus(QUERY_STATUS.SUCCESS);
      })
      .catch(() => {
        setStatus(QUERY_STATUS.ERROR);
      });
  }, []);

  return [formSchemas, status];
}

/** @deprecated use react query patterns instead */
export function useFormCategories({ relatedEntityId, relatedEntityType } = {}) {
  const [formCategories, setFormCategories] = useState([]);
  const [status, setStatus] = useState<QueryStatusType>(QUERY_STATUS.LOADING);

  useEffect(() => {
    FormService.listFormCategories({ relatedEntityId, relatedEntityType })
      .then(({ data: { data } }) => {
        setFormCategories(data);
        setStatus(QUERY_STATUS.SUCCESS);
      })
      .catch(() => {
        setStatus(QUERY_STATUS.ERROR);
      });
  }, []);

  return [formCategories, status];
}
