import {
  Button,
  DataTable,
  Panel,
  useAlert,
} from "@validereinc/common-components";
import classNames from "classnames/bind";
import * as PropTypes from "prop-types";
import React, { useState } from "react";
import AddRecipientModal from "../CreateAlertConfiguration/DeliveryStep/AddRecipientModal";
import RemoveRecipientModal from "../CreateAlertConfiguration/DeliveryStep/RemoveRecipientModal";

import AlertingService from "#components/Services/AlertingService";
import styles from "./AlertConfigurationDetail.module.scss";

const cx = classNames.bind(styles);

const RecipientsTab = ({ recipients, refetchData, alertConfigId }) => {
  const [isAddRecipientModalOpen, setIsAddRecipientModalOpen] = useState(false);
  const [defaultEmail, setDefaultEmail] = useState();
  const [recipientEmailToRemove, setRecipientEmailToRemove] = useState("");
  const [recipientData, setRecipientData] = useState();

  const { addAlert } = useAlert();

  const onOpenRecipientModal = (subscriber) => {
    if (subscriber?.delivery_address) {
      setDefaultEmail(subscriber?.delivery_address);
      setRecipientData(subscriber);
    }

    setIsAddRecipientModalOpen(true);
  };

  const onCloseRecipientModal = () => {
    setIsAddRecipientModalOpen(false);
    setDefaultEmail(undefined);
  };

  const onAddRecipient = (newRecipientEmail) => {
    AlertingService.addSubscriberToAlert(alertConfigId, newRecipientEmail).then(
      () => {
        addAlert({
          variant: "success",
          message: `Successfully added recipient "${newRecipientEmail}".`,
        });

        refetchData();
      }
    );
  };

  const onOpenRemoveRecipientModal = (subscriber) => {
    setRecipientEmailToRemove(subscriber?.delivery_address);
    setRecipientData(subscriber);
  };

  const onCloseRemoveRecipientModal = () => {
    setRecipientEmailToRemove(false);
  };

  const onRemoveRecipient = () => {
    AlertingService.removeSubscriberFromAlert(recipientData?.id).then(() => {
      addAlert({
        variant: "success",
        message: `Successfully removed recipient "${recipientData.delivery_address}".`,
      });

      setRecipientData();
      refetchData();
    });
  };

  return (
    <>
      <Panel
        title="Recipients"
        actionRow={
          <Button onClick={() => onOpenRecipientModal()}>Add Recipient</Button>
        }
      >
        <DataTable
          headers={[
            {
              label: "Recipient",
              key: "delivery_address",
              renderComponent: ({ item }) => (
                <p className={cx("email")}>{item.delivery_address}</p>
              ),
            },
          ]}
          items={recipients}
          getItemActions={({ item }) => [
            {
              label: "Remove",
              buttonProps: {
                onClick: () => onOpenRemoveRecipientModal(item),
                icon: "trash",
              },
            },
          ]}
        />
      </Panel>

      <AddRecipientModal
        isOpen={isAddRecipientModalOpen}
        onAdd={onAddRecipient}
        onClose={onCloseRecipientModal}
        defaultEmail={defaultEmail}
      />

      <RemoveRecipientModal
        recipientEmailToRemove={recipientEmailToRemove}
        onRemove={onRemoveRecipient}
        onClose={onCloseRemoveRecipientModal}
      />
    </>
  );
};

RecipientsTab.propTypes = {
  recipients: PropTypes.array.isRequired,
  refetchData: PropTypes.func.isRequired,
  alertConfigId: PropTypes.string,
};

export default RecipientsTab;
