import { useNavigate } from "#src/Routers/hooks";
import { getPermissionDisplayLabel } from "#src/contexts/AuthenticatedContext.helpers";
import { RoleContext } from "#src/routes/settings/roles-and-permissions/roles/[roleId]/detail/RoleContext";
import { linkToEditRolePermissionsPage } from "#src/routes/settings/roles-and-permissions/roles/[roleId]/permissions/edit";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  Button,
  DataTable,
  DataTablePanel,
  Dialog,
  HeaderType,
  useAlert,
} from "@validereinc/common-components";
import { RolesAdapter, type RolePermissionType } from "@validereinc/domain";
import React, { useContext, useState } from "react";

export const RolePermissionsTablePanel = () => {
  const { addAlert } = useAlert();
  const navigate = useNavigate();
  const [permissionToRemove, setPermissionToRemove] =
    useState<RolePermissionType | null>(null);
  const { roleQuery, rolePermissionsQuery } = useContext(RoleContext) ?? {};
  const queryClient = useQueryClient();
  const deletePermissionFromRoleMutation = useMutation({
    mutationFn: ({
      permissionNameToDelete,
      roleId,
    }: {
      permissionNameToDelete: string;
      roleId: string;
    }) => {
      return RolesAdapter.permissions.delete({
        id: roleId,
        meta: { permissionName: permissionNameToDelete },
      });
    },
    onSuccess: (_, { roleId, permissionNameToDelete }) => {
      queryClient.invalidateQueries({ queryKey: ["roles", roleId] });
      addAlert?.({
        variant: "success",
        message: `Successfully removed permission configuration "${permissionNameToDelete}" from role`,
      });
    },
    onError: () => {
      addAlert?.({
        variant: "error",
        message: "Failed to remove permission configuration from role",
      });
    },
    onMutate: () => {
      setPermissionToRemove(null);
    },
  });

  const headers: Array<HeaderType<RolePermissionType>> = [
    {
      label: "Name",
      key: "name",
      renderComponent: ({ item }: { item?: RolePermissionType }) =>
        item?.name ? getPermissionDisplayLabel(item.name) : "-",
    },
    {
      label: "Effective Date",
      key: "created_at",
      tooltip: "When the permission was configured on this role",
      renderComponent: ({ item }) => (
        <DataTable.DataRow.DateCell
          value={item.created_at}
          withTime
        />
      ),
    },
  ];

  const getItemActions = ({ item }: { item?: RolePermissionType }) => {
    return [
      {
        label: "Delete",
        buttonProps: {
          onClick: () => setPermissionToRemove(item ?? null),
          icon: "trash",
          disabled: !item,
        },
      },
    ];
  };

  return (
    <>
      <DataTablePanel
        actionRowWhenNoRowsSelected={[
          <Button
            key="assign-permissions"
            onClick={() => {
              if (!roleQuery?.data?.id) {
                return;
              }

              navigate({
                pathname: linkToEditRolePermissionsPage(roleQuery.data.id),
              });
            }}
          >
            Assign Permissions
          </Button>,
        ]}
        dataTableProps={{
          headers,
          items: rolePermissionsQuery?.data ?? [],
          isLoading: roleQuery?.isLoading,
          getItemActions,
        }}
        panelProps={{
          title: "Permissions",
          loaded: !rolePermissionsQuery?.isLoading,
        }}
      />
      <Dialog
        isOpen={!!permissionToRemove}
        onClose={() => setPermissionToRemove(null)}
        title="Delete Permission"
        actionRow={[
          <Button
            key="delete-dialog-action"
            onClick={() => {
              if (!permissionToRemove?.name || !roleQuery?.data?.id) {
                return;
              }

              deletePermissionFromRoleMutation.mutate({
                roleId: roleQuery.data.id,
                permissionNameToDelete: permissionToRemove.name,
              });
            }}
            variant="error"
          >
            Delete Permission
          </Button>,
        ]}
      >
        Are you sure you want to remove permission &quot;
        {permissionToRemove?.name}&quot; from role {roleQuery?.data?.name}?
      </Dialog>
    </>
  );
};
