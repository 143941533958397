import React from "react";
import { getTimeStringFromDate } from "#utils/timeFormatter";
import config from "#src/config";
import {
  CircleSVG,
  AlarmSVG,
  SelectedSVG,
  SPOT_PROPS,
  COMPOSITE_PROPS,
  UNKNOWN_PROPS,
} from "#common/Chart/ChartSVG";
import { VirtualSampleSVG } from "#common/SVG/SamplesSVG";
import { getPrecision } from "#common/Chart/LineChartHelper";
import minBy from "lodash/minBy";
import maxBy from "lodash/maxBy";
import round from "lodash/round";
import ceil from "lodash/ceil";
import moment from "moment";

const MIN_WEEK_BUFFER = 2;

/** Not every y gridline has a label (tick) */
const Y_GRIDLINES = 20;
const Y_TICKS = 4;
const MINIMUM_RANGE = 0.1;

export const formatXAxis = (value) => {
  return getTimeStringFromDate(value, config.SHORT_DATEMONTH_FORMAT);
};

export const getAllSelectedData = (intervalSelection) => {
  let allSampleIds = [];
  const allPreviousAccountingRecordIds = [];
  const allMethods = [];

  const { sampleIds, previousAccountingRecordId, method } = intervalSelection;

  if (sampleIds) {
    allSampleIds = allSampleIds.concat(sampleIds);
  }

  if (previousAccountingRecordId) {
    allPreviousAccountingRecordIds.push(previousAccountingRecordId);
  }

  if (method) {
    allMethods.push(method);
  }

  return [allSampleIds, allPreviousAccountingRecordIds, allMethods];
};

export const getSvgType = (point) => {
  if (point.alarms?.length) {
    return "alarm";
  } else if (point.type === "virtual_sample") {
    return "virtual";
  } else {
    return "default";
  }
};

export const getSvgProps = (type) => {
  switch (type) {
    case "spot_sample":
      return SPOT_PROPS;
    case "composite_sample":
      return COMPOSITE_PROPS;
    default:
      return UNKNOWN_PROPS;
  }
};

export const getSvgPrecedence = (point, sampleIds) => {
  if (sampleIds.includes(point.id)) {
    return "selected";
  }
  if (point.alarms?.length) {
    return "alarm";
  }
  if (point.type === "spot_sample" || point.type === "composite_sample") {
    return "default";
  }
  if (point.type === "virtual_sample") {
    return "virtual";
  }
  return "unknown";
};

export const getSvg = (type, props, precedence) => {
  let svg = <CircleSVG {...props} />;

  if (type === "alarm") {
    svg = <AlarmSVG {...props} />;
  }

  if (type === "virtual") {
    svg = <VirtualSampleSVG />;
  }

  if (precedence === "selected") {
    svg = <SelectedSVG>{svg}</SelectedSVG>;
  }

  return svg;
};

/** Calculates min and max values, as well as putting gridlines and ticks on
 *  the start of every month. A buffer of at least MIN_WEEK_BUFFER is placed
 *  on the smallest and biggest values to ensure it's not flush with edge
 */
export const calculateXDomain = ({ chart_points = [], inline = [] }) => {
  const xTickValues = [];
  const xGridlineValues = [];
  let minX;
  let maxX;

  const data = [...chart_points, ...inline];
  if (data?.length) {
    const minData = minBy(data, (d) => d.from ?? d.date);
    const maxData = maxBy(data, (d) => d.until ?? d.date);
    minX = moment(minData.from ?? minData.date)
      .subtract(MIN_WEEK_BUFFER, "weeks")
      .valueOf();
    maxX = moment(maxData.until ?? maxData.date)
      .add(MIN_WEEK_BUFFER, "weeks")
      .valueOf();

    // Don't want ticks to go over
    const firstTick = moment(minX).startOf("month").add(1, "month").valueOf();

    const tick = moment(firstTick);
    while (tick.isBefore(maxX)) {
      xTickValues.push(tick.valueOf());
      xGridlineValues.push(tick.valueOf());
      tick.add(1, "month");
    }
  }

  return [minX ?? 0, maxX ?? 0, xTickValues, xGridlineValues];
};

/** Calculates min and max values, as well as a set amount of gridlines and ticks
 *  specified by Y_GRIDLINES and Y_TICKS.
 */
export const calculateYDomain = ({ chart_points = [], inline = [] }) => {
  const yTickValues = [];
  const yGridlineValues = [];
  let minY;
  let maxY;

  const data = [...chart_points, ...inline];
  if (data?.length) {
    const maxData = maxBy(data, (d) => d.value);
    if (maxData.value === 0) {
      maxData.value = MINIMUM_RANGE;
    }
    const precision = getPrecision(maxData.value / Y_GRIDLINES);
    maxY = ceil(maxData.value / Y_GRIDLINES, precision) * Y_GRIDLINES;

    const step = maxY / Y_GRIDLINES;
    for (let i = 0; i <= Y_GRIDLINES; i++) {
      if (i % (Y_GRIDLINES / Y_TICKS) === 0) {
        yTickValues.push(round(step * i, precision + 1));
      }
      yGridlineValues.push(round(step * i, precision + 1));
    }
  }
  return [minY ?? 0, maxY ?? 0, yTickValues, yGridlineValues];
};
