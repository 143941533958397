import { Button, Modal, Title, useAlert } from "@validereinc/common-components";
import * as PropTypes from "prop-types";
import React, { useState } from "react";
import InstrumentService from "../../../Services/InstrumentService";
import "./ArchiveInstrumentModal.scss";

export const ArchiveInstrumentModal = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const { addAlert } = useAlert();
  const [action, setAction] = useState(
    props.instrumentDetail.status === "active" ? "archive" : "unarchive"
  );
  const onSubmit = () => {
    const { instrumentDetail } = props;
    const actionFunc =
      action === "archive"
        ? InstrumentService.archiveInstrument
        : InstrumentService.unarchiveInstrument;

    setIsLoading(true);

    actionFunc(instrumentDetail.id)
      .then(() => {
        setIsLoading(false);
        addAlert({
          variant: "success",
          message: "Instrument successfully archived.",
        });

        props.refetchInstruments();
        action === "archive" ? setAction("unarchive") : setAction("archive");
        props.onHide();
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const instrumentName = props.instrumentDetail
    ? props.instrumentDetail.name
    : "";

  return (
    <Modal
      className="archiveInstrumentModal"
      open={props.show}
      onClose={props.onHide}
      disableInitialFocus={true}
    >
      <Modal.Header className="archiveInstrumentModal__header">
        <Title>
          {action === "archive" ? "Archive" : "Unarchive"} {instrumentName}
        </Title>
      </Modal.Header>

      <Modal.Body className="archiveInstrumentModal__body">
        <p>Are you sure you would like to {action} this instrument?</p>
        <Button
          className="archiveInstrumentModal__cancelButton pull-right"
          disabled={isLoading}
          onClick={props.onHide}
        >
          Cancel
        </Button>
        {action === "archive" ? (
          <Button
            className="archiveInstrumentModal__archiveButton pull-right"
            variant="error"
            disabled={isLoading}
            onClick={onSubmit}
          >
            Archive
          </Button>
        ) : (
          <Button
            className="archiveInstrumentModal__archiveButton pull-right"
            variant="primary"
            disabled={isLoading}
            onClick={onSubmit}
          >
            Unarchive
          </Button>
        )}
      </Modal.Body>
    </Modal>
  );
};

ArchiveInstrumentModal.propTypes = {
  show: PropTypes.bool.isRequired,
  instrumentDetail: PropTypes.object,
  onHide: PropTypes.func.isRequired,
  refetchInstruments: PropTypes.func.isRequired,
};

export default ArchiveInstrumentModal;
