import { restAPI } from "../../adapters";
import { AssetType, AssetTypeType } from "../../schemas";

export type EstimationMethodInputSourceType = {
  estimation_method_id: string;
  input_name: string;
  year_month: string;
  record_value_id: string;
  created_by: string;
  created_at: string;
  updated_by: string;
  updated_at: string;
};

export type EstimationMethodInputSourceMetaType = {
  /** a month represented as a string in the format YYYYMM e.g. 202210 for October 2022 */
  yearMonth: string;
  /** the entity type under which this estimation method exists. Default is "equipment". Possible options are "equipment" / "flow" */
  entityType: AssetTypeType;
  /** the name of the input */
  inputName: string;
};

export const EstimationMethodInputSourceDomain: EstimationMethodInputSourceDomainType =
  {
    getOne: ({
      id,
      meta = {
        // TODO: this should be the current month
        yearMonth: "",
        entityType: AssetType.EQUIPMENT,
        inputName: "",
      },
    }) =>
      restAPI.nodeAPI.GET({
        endpoint: `/estimation_methods/${meta.entityType}/${id}/record/${meta.yearMonth}/${meta.inputName}`,
      }),
    update: ({
      id,
      data,
      meta = {
        // TODO: this should be the current month
        yearMonth: "",
        entityType: AssetType.EQUIPMENT,
        inputName: "",
      },
    }) =>
      restAPI.nodeAPI.PUT({
        endpoint: `/estimation_methods/${meta.entityType}/${id}/record/${meta.yearMonth}/${meta.inputName}`,
        body: data,
      }),
    delete: ({
      id,
      meta = {
        // TODO: this should be the current month
        yearMonth: "",
        entityType: AssetType.EQUIPMENT,
        inputName: "",
      },
    }) =>
      restAPI.nodeAPI.DELETE({
        endpoint: `/estimation_methods/${meta.entityType}/${id}/record/${meta.yearMonth}/${meta.inputName}`,
      }),
  };

export interface EstimationMethodInputSourceDomainType {
  /**
   * Get the input source record for a given input on an estimation method
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/estimation_methods/get_calculator_input_record
   */
  getOne: (params: {
    /** the ID of the estimation method */
    id: string;
    meta?: EstimationMethodInputSourceMetaType;
  }) => Promise<EstimationMethodInputSourceType>;
  /**
   * Update the input source record for a given input on an estimation method
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/estimation_methods/set_calculator_input_record
   */
  update: (params: {
    /** the ID of the estimation method */
    id: string;
    data: Pick<EstimationMethodInputSourceType, "record_value_id">;
    meta?: EstimationMethodInputSourceMetaType;
  }) => Promise<EstimationMethodInputSourceType>;
  /**
   * Delete the input source record for a given input on an estimation method
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/estimation_methods/delete_calculator_input_record
   */
  delete: (params: {
    /** the ID of the estimation method */
    id: string;
    meta?: EstimationMethodInputSourceMetaType;
  }) => Promise<void>;
}
