import React, { Component } from "react";
import FontAwesome from "react-fontawesome";
import "./StatisticPanel.css";
import {
  Maximum,
  Minimum,
  Average,
  StandardDeviation,
} from "../../utils/calculator";

/* eslint-disable react/prop-types */
const TableRow = (props) => (
  <tr>
    <td className="statisticPanel__tableTitle">{props.title}</td>
    <td className="statisticPanel__tableValue">{`${props.value} ${props.unit}`}</td>
  </tr>
);

export class StatisticPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      statistics: {},
      measurementUnit: {},
    };

    this.openContent = this.openContent.bind(this);
  }

  getDataPoint(samples) {
    if (Array.isArray(samples)) {
      return samples.reduce((accDataPoints, sample) => {
        const sampleDataPoints =
          sample?.data_points?.map((data) => data.y) ?? [];
        return [...accDataPoints, ...sampleDataPoints];
      }, []);
    } else {
      return [];
    }
  }

  getStatistic(sampleData) {
    return {
      average: Average(sampleData),
      minimum: Minimum(sampleData),
      maximum: Maximum(sampleData),
      standardDeviation: StandardDeviation(sampleData),
      numOfMeasurement: sampleData?.length ?? "-",
    };
  }

  getRoundedValue(value, decimal) {
    if (value && typeof value === "number" && decimal) {
      if (decimal) {
        return `${value.toFixed(decimal)}`;
      }
    }

    return value;
  }

  openContent() {
    this.setState({
      show: !this.state.show,
    });
  }

  render() {
    const { onClose, stream, title, measurementType } = this.props;

    const dataPoint = this.getDataPoint(this.props.stream.samples);

    const statistics = this.getStatistic(dataPoint);

    return (
      <div className="statisticPanel">
        <div className="statisticPanel__header">
          <div
            className="statisticPanel__headerName"
            onClick={this.openContent}
            style={{ width: onClose ? "75%" : "100%" }}
          >
            <FontAwesome
              name="square"
              className="icon"
              style={{ color: stream.color }}
            />
            {title ? title : "Unknown"}
          </div>
        </div>
        <div
          className="statisticPanel__body"
          hidden={!this.state.show}
        >
          <table className="statisticPanel__table">
            <tbody>
              <TableRow
                title="Average"
                value={this.getRoundedValue(
                  statistics.average,
                  measurementType.decimals
                )}
                unit={measurementType.unit}
              />

              <TableRow
                title="Minimum"
                value={this.getRoundedValue(
                  statistics.minimum,
                  measurementType.decimals
                )}
                unit={measurementType.unit}
              />

              <TableRow
                title="Maximum"
                value={this.getRoundedValue(
                  statistics.maximum,
                  measurementType.decimals
                )}
                unit={measurementType.unit}
              />

              <TableRow
                title="STD"
                value={this.getRoundedValue(statistics.standardDeviation, 2)}
                unit=""
              />

              <TableRow
                title="# of Measurement"
                value={this.getRoundedValue(statistics.numOfMeasurement)}
                unit=""
              />
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default StatisticPanel;
