import { KeyValueList, Pill } from "@validereinc/common-components";
import type { ActivityType } from "@validereinc/domain";
import classNames from "classnames/bind";
import React, { ReactNode } from "react";
import styles from "./ActivityLogsActionKeyValueList.module.scss";

const cx = classNames.bind(styles);

export const ActivityLogsActionKeyValueList = ({
  selectedLog,
}: {
  selectedLog: ActivityType | null;
}) => {
  const getVariant = () => {
    if (selectedLog?.action === "create") {
      return {
        label: "Created",
        variant: "success",
      };
    }
    if (selectedLog?.action === "delete") {
      return {
        label: "Deleted",
        variant: "error",
      };
    }

    if (selectedLog?.action === "update") {
      return { label: "Updated", variant: "info" };
    }

    return { label: "-", variant: "default" };
  };

  const { label, variant } = getVariant();

  const data: Array<{ title: string; value: ReactNode }> = [
    {
      title: "Change Type",
      value: <Pill variant={variant}>{label}</Pill>,
    },
  ];

  return (
    <KeyValueList
      data={data}
      className={cx("keyValueListContainer")}
    />
  );
};
