import React, { useState } from "react";
import moment from "moment";
import { getNumberColorBySign } from "../../../../utils/styleCalculator";
import config from "#src/config";
import { Hint } from "react-vis";
import sortBy from "lodash/sortBy";
import { CUBIC_METERS_VOLUME } from "#redux/reducers/measurements";
import { getTimeStringFromDate } from "#utils/timeFormatter";

const MIN_BAR_WIDTH = 0.01;

const MAX_BAR_WIDTH = 0.5;

function getDateRange(dailyVolumeData) {
  const sortedDailyVolumeData = sortBy(dailyVolumeData, "date", "asc");

  const earliestDate = sortedDailyVolumeData[0]?.date;
  const latestDate = sortedDailyVolumeData.pop()?.date;

  return { earliestDate, latestDate };
}

export function getDefaultInterval(to, from) {
  return moment(to).diff(moment(from), "months", false) > 0
    ? "weekly"
    : "daily";
}

export function getDailyVolumePlotData(dailyVolumeChartData) {
  return dailyVolumeChartData.map((volume) => {
    const { date, value, unit } = volume;

    return {
      x: moment(date).toDate(),
      y: value,
      label: `${value} ${unit}`,
      color: getNumberColorBySign(value),
    };
  });
}

function getXAxis(date, index, maxIndex, middleIndex) {
  // We only show date that is either beginning, end, or middle of the chart
  if (index === 0 || index === maxIndex || index === middleIndex) {
    return getTimeStringFromDate(date, config.DATE_FORMAT);
  }

  return "";
}

export function getTickFormat(date, index, xTickCount) {
  const maxIndex = xTickCount - 1;
  const middleIndex = Math.ceil(maxIndex / 2);

  return getXAxis(date, index, maxIndex, middleIndex);
}

export const getXAxisSettings = (dailyVolumePlotData) => {
  const xTickValues = [];
  const dateRange = getDateRange(dailyVolumePlotData);

  const minX = moment(dateRange.earliestDate).valueOf();
  const maxX = moment(dateRange.latestDate).valueOf();

  if (dailyVolumePlotData.length) {
    dailyVolumePlotData.map((data) => {
      xTickValues.push(moment(data.date).valueOf());
    });
  }

  return [minX, maxX, xTickValues];
};

export const getYAxisLabel = (dailyVolumePlotData) => {
  const label = "Total Unadjusted Volume";

  if (dailyVolumePlotData.length > 0) {
    return `${label} (${dailyVolumePlotData[0].unit})`;
  }

  return label;
};

export const useHint = (getFormattedMeasurementValueWithUnit) => {
  const [hintData, setHintData] = useState(null);

  const hintFormat = (data) => {
    const [value, unit] = data.label?.split(" ") || [];

    return [
      {
        title: "Date",
        value: getTimeStringFromDate(data.x, config.DATE_FORMAT),
      },
      {
        title: "Value",
        value: getFormattedMeasurementValueWithUnit(CUBIC_METERS_VOLUME, {
          value,
          unit,
        }),
      },
    ];
  };

  const hint = hintData && (
    <Hint
      value={hintData}
      format={hintFormat}
      style={{
        width: "200px",
      }}
    />
  );

  return [hint, setHintData];
};

export function getBarWidth(width, numberOfColumns, barWidth) {
  if (width > 0 && numberOfColumns > 0) {
    const columnWidthInPixel = width / numberOfColumns;
    const barWidthInRatio = barWidth / columnWidthInPixel;

    if (barWidthInRatio > MAX_BAR_WIDTH) {
      return MAX_BAR_WIDTH;
    } else if (barWidthInRatio < MIN_BAR_WIDTH) {
      return MIN_BAR_WIDTH;
    } else {
      return barWidthInRatio;
    }
  } else {
    return 0;
  }
}
