import React from "react";
import * as PropTypes from "prop-types";
import InstrumentDetail from "../InstrumentDetail";
import { connect } from "react-redux";
import { getMeasurementType } from "../../Redux/reducers/measurements";
import { getAlertSpec } from "../InstrumentComponentHelper";
import get from "lodash/get";
import isNil from "lodash/isNil";
import {
  dateRenderer,
  sampleDateRenderer,
  sortBySampleDate,
  getMeasurementValue,
} from "../InstrumentHelper";
import MeasurementStatus from "../Shared/TestsDatatable/MeasurementStatus";
import { notesColumn } from "../Shared/TestsDatatable/NotesColumn";
import {
  sampleStateRenderer,
  sortBySampleState,
} from "../../Samples/SampleHelper";
import { getFirstValidValue } from "#utils/objectFormatter";
import TestDetailModal from "#components/Tests/TestDetailModal/TestDetailModal";

const _ = { get };

const measurementRenderer = (measurementType) =>
  function measurementRendererInner(rowData, columnKey) {
    const defaultAlertSpec = {
      measurement_unit: measurementType.unit,
      upper_limit: 1000,
      lower_limit: 0,
    };

    try {
      const unit = rowData.measurements[columnKey].unit;

      const value = getMeasurementValue(
        rowData.measurements[columnKey].value,
        measurementType.decimals
      );

      return (
        <MeasurementStatus
          value={value}
          unit={unit}
          labels={rowData.labels}
          status={rowData.status}
          defaultAlertSpec={defaultAlertSpec}
          alertSpec={getAlertSpec(
            rowData.measurement_alert_specs,
            measurementType.name,
            defaultAlertSpec
          )}
          measurementType={measurementType}
        />
      );
    } catch {
      return <div>-</div>;
    }
  };

const testerRenderer = (rowData, columnKey) => {
  const tester = getFirstValidValue([
    rowData.metadata[columnKey],
    rowData.tested_by,
  ]);

  return <div>{tester ?? "-"}</div>;
};

const measuringTempRenderer = (rowData, columnKey) => {
  const temperature_in_c = getFirstValidValue([
    _.get(rowData.measurements, columnKey),
    rowData.metadata?.density_temperature?.value,
  ]);

  if (temperature_in_c) {
    return <div>{temperature_in_c} °C</div>;
  } else {
    return <div>-</div>;
  }
};

const sortByMeasurement = (list, measurementName) => {
  return [...list].sort((a, b) => {
    return (
      a.measurements[measurementName].value -
      b.measurements[measurementName].value
    );
  });
};

const headers = (measurementType) => {
  return [
    {
      label: "Name",
      key: "name",
      width: 400,
      fixed: true,
    },
    {
      label: "Sample State",
      key: "sample_state",
      width: 150,
      cellRenderer: (row) => sampleStateRenderer(row?.sample),
      sort: sortBySampleState,
    },
    {
      label: "Date",
      key: "date",
      width: 170,
      cellRenderer: dateRenderer,
    },
    {
      label: "Tester",
      key: "user_name.value",
      width: 150,
      cellRenderer: testerRenderer,
      sort: (list, sortBy) =>
        [...list].sort((a, b) => {
          const aValue = _.get(a.metadata, sortBy, "-");
          const bValue = _.get(b.metadata, sortBy, "-");

          return aValue.localeCompare(bValue);
        }),
      suppress: true,
    },
    {
      label: "Measuring Temp.",
      key: "Density.temperature_in_c",
      width: 150,
      cellRenderer: measuringTempRenderer,
      sort: (list) =>
        [...list].sort((a, b) => {
          const aValue = _.get(a.measurements, "Density.temperature_in_c");
          const bValue = _.get(b.measurements, "Density.temperature_in_c");

          if (!isNil(aValue) && !isNil(bValue)) {
            return aValue - bValue;
          } else if (!isNil(aValue)) {
            return -1;
          } else if (!isNil(bValue)) {
            return 1;
          } else {
            return 0;
          }
        }),
    },
    {
      label: measurementType.display_name,
      key: measurementType.name,
      width: 250,
      cellRenderer: measurementRenderer(measurementType),
      sort: sortByMeasurement,
    },
    {
      label: "Sample Start Date",
      key: "started_at",
      width: 170,
      cellRenderer: sampleDateRenderer,
      sort: sortBySampleDate,
    },
    {
      label: "Sample End Date",
      key: "ended_at",
      width: 170,
      cellRenderer: sampleDateRenderer,
      sort: sortBySampleDate,
    },
    notesColumn,
  ];
};

const mapStateToProps = (state) => {
  return {
    measurementType: getMeasurementType(state.measurements)("Density"),
  };
};

const InstrumentAntonPaarTests = (props) => {
  return (
    <InstrumentDetail
      testId={props.match.params.testId}
      height={props.height}
      instrumentInfo={props.instrumentInfo}
      ReportModalComponent={TestDetailModal}
      filterKey="name"
      headers={headers(props.measurementType)}
      defaultSortBy="date"
      defaultSortDirection="desc"
    />
  );
};

InstrumentAntonPaarTests.propTypes = {
  match: PropTypes.object.isRequired,
  height: PropTypes.number.isRequired,
  instrumentInfo: PropTypes.object.isRequired,
  measurementType: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, undefined)(InstrumentAntonPaarTests);
