import { Tooltip } from "@validereinc/common-components";
import React from "react";
import FontAwesome from "react-fontawesome";

export const compoundMessage =
  "Compound adjustments adjust both component and total " +
  "volumes. Non-compound adjustments ignore the relationship between component " +
  "and total volumes and will only adjust the volume they are applied to.";

const CompoundLabel = () => {
  return (
    <Tooltip
      content={compoundMessage}
      width={300}
    >
      Compound
      <FontAwesome
        name="info-circle"
        style={{ marginLeft: "3px" }}
      />
    </Tooltip>
  );
};

export default CompoundLabel;
