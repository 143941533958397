export const TEMPLATED_REPORT_EXPORT_PAGE_TITLE = "Export Template Report";
export const linkToExportTemplatedReport = (reportName = ":reportName") =>
  `/app/templated-reports/${reportName}/export`;
export const TEMPLATED_REPORT_EXPORT_BREADCRUMB = {
  title: TEMPLATED_REPORT_EXPORT_PAGE_TITLE,
  path: linkToExportTemplatedReport,
};
export type TemplatedReportExportParamType = {
  reportName: string;
};

export * from "./TemplatedReportExportPage";
