export const ROLE_DETAILS_FALLBACK_PAGE_TITLE = "Role:roleId";

export const linkToRoleDetailPage = (roleId = ":roleId") => {
  return `/app/settings/roles-and-permissions/roles/${roleId}/detail`;
};

export const ROLE_DETAILS_BREADCRUMB = {
  title: ROLE_DETAILS_FALLBACK_PAGE_TITLE,
  path: linkToRoleDetailPage,
};

export * from "./RoleDetailPage";
