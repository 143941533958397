import { useFilters } from "#src/resources/filters";
import { DropdownInput } from "@validereinc/common-components";
import React from "react";
import { DataTypeInput } from "./utils";

export const QuestionFilterStaticInput = ({
  variant,
  attribute,
  name,
}: {
  variant: "facility" | "equipment" | "device" | "flow";
  attribute: string;
  name: string;
}) => {
  const filters = useFilters(variant);

  const filter = filters?.find((filter) => filter.field_name === attribute);

  const { display_name, data_type, lookup_type, pick_list_values } =
    filter ?? {};

  return (
    <div style={{ display: "flex", flexDirection: "row", gap: 16 }}>
      {[
        "string",
        "number",
        "integer",
        "date",
        "date-time",
        "multi-pick-list",
      ].includes(data_type) ? (
        <DropdownInput
          label="Comparator"
          name={`${name}.comparator`}
          options={[
            { label: "=", value: "$eq" },
            ...(data_type === "string" ? [{ label: "⊂", value: "$like" }] : []),
            ...(["number", "integer", "date", "date-time"].includes(data_type)
              ? [
                  { label: ">", value: "$gt" },
                  { label: ">=", value: "$gte" },
                  { label: "<", value: "$lt" },
                  { label: "<=", value: "$lte" },
                ]
              : []),
            ...(data_type === "multi-pick-list"
              ? [{ label: "∈", value: "$in" }]
              : []),
          ]}
          labelKey="label"
          valueKey="value"
          isRequired
          isFluid
          defaultValue={"$eq"}
          isLabelShown={false}
        />
      ) : null}

      <DataTypeInput
        name={`${name}.staticValue`}
        display_name={display_name}
        data_type={data_type}
        options={pick_list_values?.map((value) => ({ label: value, value }))}
        lookup_type={lookup_type}
      />
    </div>
  );
};
