import React, { useState } from "react";
import * as PropTypes from "prop-types";
import styles from "./NetFacilityChart.module.scss";
import classNames from "classnames/bind";
import { AutoSizer } from "react-virtualized";
import {
  XYPlot,
  VerticalBarSeries,
  XAxis,
  YAxis,
  HorizontalGridLines,
  Hint,
  ChartLabel,
} from "react-vis";
import {
  getComponentPlotData,
  getEnergyPlotData,
  getTotalPlotData,
  getHintFormat,
  getXAxisTick,
  getCorrectSource,
} from "./NetFacilityChartHelper";
import { BALANCE_SOURCE_TYPES } from "#utils/enums";
const CONTAINER_PADDING = 30;

const cx = classNames.bind(styles);

const NetFacilityChart = ({
  productionReportDetail,
  balanceSourceType,
  chartType,
  volumeUnit,
}) => {
  const chartDataSource = getCorrectSource(
    productionReportDetail,
    balanceSourceType
  );
  const { total, energy, components } = chartDataSource;
  const [hintData, setHintData] = useState();

  const totalPlotData = getTotalPlotData(
    total,
    chartType.id,
    balanceSourceType
  );
  const energyPlotData = getEnergyPlotData(
    energy,
    chartType.id,
    balanceSourceType
  );
  const componentPlotData = getComponentPlotData(
    components,
    chartType.id,
    balanceSourceType
  );

  const chartSettings = {
    margin: chartType.id === "differences" ? 90 : 60,
    yPercent: chartType.id === "differences" ? 0.2 : 0.4,
  };

  const formattedVolumeUnit = volumeUnit ? ` (${volumeUnit})` : "";

  return (
    <div className={cx("netFacilityChart")}>
      <AutoSizer>
        {({ width, height }) => (
          <XYPlot
            width={width}
            height={Math.max(height - CONTAINER_PADDING, 0)}
            xType="ordinal"
            colorType="literal"
            margin={{ left: chartSettings.margin }}
          >
            <HorizontalGridLines />

            <VerticalBarSeries
              data={[...totalPlotData, ...energyPlotData, ...componentPlotData]}
              barWidth={0.2}
              onValueMouseOver={(value) => setHintData(value)}
              onValueMouseOut={() => setHintData(null)}
            />

            <XAxis tickFormat={(tick) => getXAxisTick(tick)} />

            <YAxis />

            <ChartLabel
              text={
                balanceSourceType !== BALANCE_SOURCE_TYPES.INLETS_AND_OUTLETS
                  ? `Volume${formattedVolumeUnit}`
                  : chartType.title
              }
              xPercent={0.015}
              yPercent={chartSettings.yPercent}
              style={{ transform: "rotate(-90)" }}
            />

            {hintData && (
              <Hint
                value={hintData}
                format={(data) => getHintFormat(data)}
                style={{
                  width: "200px",
                }}
              />
            )}
          </XYPlot>
        )}
      </AutoSizer>
    </div>
  );
};

NetFacilityChart.propTypes = {
  productionReportDetail: PropTypes.object.isRequired,
  chartType: PropTypes.string.isRequired,
  balanceSourceType: PropTypes.string,
  volumeUnit: PropTypes.string,
};

export default NetFacilityChart;
