import { useEmissionDetail } from "#hooks/useEmissionsReports";
import {
  ControlLabel,
  MultiDropdownInputWithSearch,
  Panel,
} from "@validereinc/common-components";
import classNames from "classnames/bind";
import * as PropTypes from "prop-types";
import React, { useState } from "react";
import {
  DEFAULT_POLLUTANT,
  EMITTED_POLLUTANTS,
} from "../../../EmissionConstants";
import styles from "../EmissionReportDetail.module.scss";
import FacilityDetailChart from "./FacilityDetailChart";
import EmissionDetailTable from "./FacilityDetailTable";

const cx = classNames.bind(styles);

const FacilitiesPanel = ({ emissionReportId, defaultDateRange }) => {
  const [selectedPollutant, setSelectedPollutant] = useState(DEFAULT_POLLUTANT);
  const [facilitySummary, loadingState] = useEmissionDetail(
    emissionReportId,
    defaultDateRange
  );

  const onPollutantChange = (pollutant) => {
    setSelectedPollutant(pollutant);
  };

  return (
    <Panel
      title="Facilities"
      loaded={loadingState === "loaded"}
    >
      <div className={cx("emissionDropdownContainer")}>
        <ControlLabel
          label="Pollutants"
          className={cx("emissionDropdown")}
        >
          <MultiDropdownInputWithSearch
            isMulti={false}
            options={EMITTED_POLLUTANTS}
            value={[selectedPollutant]}
            onChange={onPollutantChange}
            ignoreCase
            width={200}
          />
        </ControlLabel>
      </div>

      <FacilityDetailChart
        facilitySummary={facilitySummary}
        pollutant={selectedPollutant}
      />

      <EmissionDetailTable facilitySummary={facilitySummary} />
    </Panel>
  );
};

FacilitiesPanel.propTypes = {
  emissionReportId: PropTypes.string,
  defaultDateRange: PropTypes.object.isRequired,
};

export default FacilitiesPanel;
