import AttributeFieldList, {
  ATTRIBUTE_FIELD_TYPES,
} from "#common/AttributeFieldList/AttributeFieldList";
import CustomAttributeField from "#components/Common/CustomAttributeField";
import FacilityService from "#components/Services/FacilityService";
import { useGetOneFacility } from "#src/components/hooks/adapters/useFacilities";
import {
  DateSelectorInput,
  DropdownInput,
  Panel,
  TextInput,
} from "@validereinc/common-components";
import { getYearMonthFromDateRange } from "@validereinc/utilities";
import classNames from "classnames/bind";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import styles from "./FacilityForm.module.scss";

const cx = classNames.bind(styles);
const STATUS_OPTIONS = ["Active", "Inactive"];

export default function FacilityForm({
  form,
  formState,
  formType,
  localize,
}: FacilityFormProps) {
  const [customAttributes, setCustomAttributes] = useState([]);
  const [period, setPeriod] = useState(undefined);

  const sharedProp = {
    isDisabled: formState !== "loaded",
  };

  const { facilityId } = useParams<{ facilityId: string }>();
  const { data: facility } = useGetOneFacility(facilityId, {
    period: getYearMonthFromDateRange(period),
  });

  useEffect(() => {
    (async () => {
      const {
        data: { data },
      } = await FacilityService.getCustomAttributes();

      setCustomAttributes(data);
    })();
  }, []);

  const handleOnPeriodChange = (period) => {
    setPeriod(period);
  };

  useEffect(() => {
    if (form) {
      const currentValues = form.getValues("custom_attributes") ?? {};
      const newAttributeValues = Object.keys(currentValues).reduce<
        Record<string, any>
      >((acc, key) => {
        if (currentValues[key]) acc[key] = "";
        return acc;
      }, {});
      form.setValue(
        "custom_attributes",
        {
          ...newAttributeValues,
          ...facility?.custom_attributes,
        },
        { shouldTouch: true }
      );
    }
  }, [facility]);

  return (
    <Panel className="clearfix">
      {formType === "edit" ? (
        <>
          <h4 className="sectionTitle">Effective Period</h4>
          <div>
            <DateSelectorInput
              label="Effective Period Start"
              key={"period"}
              name={"period"}
              variant="month"
              isRequired
              isInline
              onChange={handleOnPeriodChange}
            />
            <hr className={cx("horizontalRule")} />
          </div>
        </>
      ) : null}

      <h4 className="sectionTitle">Details</h4>

      <div className={cx("formContainer")}>
        <TextInput
          {...sharedProp}
          name="name"
          label={`${localize("Facility")} Name`}
          type="text"
          placeholder="Name"
          showIcon={true}
          isRequired
        />
        <DropdownInput
          {...sharedProp}
          name="status"
          label="Status"
          options={STATUS_OPTIONS}
          isRequired
          isMulti={false}
        />

        <AttributeFieldList
          attributeFieldTypes={[ATTRIBUTE_FIELD_TYPES.GEO_POINT]}
        />

        {customAttributes?.map(({ field_name, ...restAttribute }) => (
          <CustomAttributeField
            key={`custom_attributes.${field_name}`}
            attribute={{ field_name, ...restAttribute }}
            {...sharedProp}
          />
        ))}
      </div>
    </Panel>
  );
}

type FacilityFormProps = {
  form: any; // IMPROVE: use the type from react hook form or from common-components
  formType: string;
  formState: string;
  localize: (key: string) => string;
};
