import { AssetTypeType, DeviceType } from "../schemas";
import { DomainModelType, GetListResponseType } from "../util";
import { restAPI } from "./api";

export type MeasurementSeriesSubjectType = {
  entity_id: string;
  entity_type: AssetTypeType;
  start_time: string;
};

export type MeasurementSeriesType = DomainModelType & {
  description: string;
  device_id: string;
  measurement_type: string;
  unit: string;
  company_id: string;
  device: DeviceType;
  measurement_type_name: string;
  subjects: MeasurementSeriesSubjectType[];
};

export const MeasurementSeriesAdapter = {
  getList: async ({ ...restQuery }) =>
    await restAPI.nodeAPI.GET<GetListResponseType<MeasurementSeriesType>>({
      endpoint: "/measurement_series",
      query: { ...restQuery },
    }),
  getOne: ({ id }: { id: string }) =>
    restAPI.nodeAPI.GET<MeasurementSeriesType>({
      endpoint: `/measurement_series/${id}`,
    }),
};
