import { z } from "zod";
import { DomainModelSchema } from "./DomainModelSchemas";

export const AssetType = {
  FACILITY: "facility",
  EQUIPMENT: "equipment",
  DEVICE: "device",
  FLOW: "flow",
  ASSET_GROUP: "asset_group",
} as const;

export type AssetTypeType = (typeof AssetType)[keyof typeof AssetType];

export const AssetTypeSchema = z.enum([
  AssetType.FACILITY,
  AssetType.EQUIPMENT,
  AssetType.DEVICE,
  AssetType.FLOW,
  AssetType.ASSET_GROUP,
]);

export const AssetSchema = z
  .object({
    name: z.string(),
    asset_type: AssetTypeSchema,
  })
  .merge(DomainModelSchema)
  .describe("Schema for an asset in the Node API");

export type AssetSchemaType = z.infer<typeof AssetSchema>;
