import { KeyValueList, Pill } from "@validereinc/common-components";
import type { WorkflowTaskType } from "@validereinc/domain";
import classNames from "classnames/bind";
import startCase from "lodash/startCase";
import React, { ReactNode } from "react";
import styles from "./WorkflowTaskDetailDrawer.module.scss";

const cx = classNames.bind(styles);

export const WorkflowTaskDetailStatusKeyValueList = ({
  selectedTask,
}: {
  selectedTask: WorkflowTaskType | null;
}) => {
  if (!selectedTask) {
    return null;
  }

  const data: Array<{ title: string; value: ReactNode }> = [
    {
      title: "Task Type",
      value: <Pill variant="default">{startCase(selectedTask.type)}</Pill>,
    },
  ];

  return (
    <KeyValueList
      data={data}
      className={cx("keyValueListContainer")}
    />
  );
};
