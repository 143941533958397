import { Button, Modal, Title } from "@validereinc/common-components";
import * as PropTypes from "prop-types";
import React from "react";

const DeleteEmissionReportModal = ({ onClose, onDeleteClick, formState }) => {
  return (
    <Modal
      open={true}
      onClose={onClose}
    >
      <Modal.Header>
        <Title>Delete Report</Title>
      </Modal.Header>

      <Modal.Body>Do you wish to delete this emission report?</Modal.Body>

      <Modal.Footer>
        <Button
          className="pull-left"
          onClick={onClose}
        >
          Cancel
        </Button>

        <Button
          variant="error"
          onClick={onDeleteClick}
          disabled={formState !== "enabled"}
        >
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

DeleteEmissionReportModal.propTypes = {
  formState: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
};

export default DeleteEmissionReportModal;
