import filter from "lodash/filter";
import uniqWith from "lodash/uniqWith";

export function getTypeName(type) {
  switch (type) {
    case "field":
      return "field lab";
    case "lab":
      return "3PL";
    case "virtual":
      return "Virtual";
    default:
      return "unknown";
  }
}

function getOffSpecThresholdMessage(alert, unit) {
  const { limit_type, threshold_triggered, std_sigma } = alert;

  switch (limit_type) {
    case "lower_limit":
      return `Below limit of ${threshold_triggered} ${unit}`;
    case "upper_limit":
      return `Above limit of ${threshold_triggered} ${unit}`;
    case "roq_ previous_error_deviation":
      return `Exceeded previous ROQ percentage deviation: ${threshold_triggered} `;
    case "std_num_measurements":
    case "std_seconds_in_past":
      return `Outside of control limit: ${std_sigma} standard deviations`;
    case "previous_error_deviation":
      return `Exceeded previous sample expected error deviation: ${threshold_triggered} `;
    case "previous_percentage_deviation":
      return `Exceeded the error deviation from past samples by more than the expected threshold: ${threshold_triggered}`;
    default:
      return "Off-spec";
  }
}

export function buildOffSpecThresholdMessage(measurement) {
  const message = measurement.offspec_alerts.reduce(
    (acc, alert) =>
      acc + getOffSpecThresholdMessage(alert, measurement.unit) + "\n",
    ""
  );
  return message;
}

export function getMeasurementValue(value, measurementType) {
  if (value || value === 0) {
    return parseFloat(value.toFixed(measurementType.decimals));
  }

  return "-";
}

export function getMeasurementValueFromProps(column, cellProps) {
  const measurementType = column.id;

  if (cellProps?.length > 0) {
    const measurementValues = [];

    cellProps.forEach((cell) => {
      const test = cell.props.test;

      // If column type is lab then we don't need to check the instrument model
      if (column.type === "lab" && column.type === test.type) {
        measurementValues.push(test.measurements[measurementType].value);
      } else {
        // Check the column of the test match with the headers
        if (test.instrument?.model === column.model) {
          measurementValues.push(test.measurements[measurementType].value);
        }
      }
    });

    return measurementValues.join(", ");
  }

  return "";
}

function getTestsWithMeasurementType(column, tests) {
  return filter(tests, (test) => {
    return test.measurements[column];
  });
}

function getMeasurementHeader(measurementType, unit, source) {
  if (source.type === "field") {
    return `${measurementType} (${unit}) (${source.instrument.model})`;
  } else {
    return `${measurementType} (${unit}) (${source.type})`;
  }
}

function getInstrumentModel(test) {
  return test.instrument?.model ?? null;
}

export function getCSVExportHeaders(columns, data) {
  const allMeasurementTests = data.reduce((tests, data) => {
    return [...tests, ...data._original.tests];
  }, []);

  const CSVExportHeaders = [];

  columns.forEach((column) => {
    if (column.unit) {
      const measurementType = column.id;

      const testsWithMeasurementType = getTestsWithMeasurementType(
        measurementType,
        allMeasurementTests
      );

      const uniqueMeasurementSources = uniqWith(
        testsWithMeasurementType,
        (testA, testB) => {
          return (
            testA.type === testB.type &&
            getInstrumentModel(testA) === getInstrumentModel(testB)
          );
        }
      );

      uniqueMeasurementSources.forEach((source) => {
        const title = getMeasurementHeader(
          measurementType,
          column.unit,
          source
        );

        CSVExportHeaders.push({
          ...column,
          type: source.type,
          model: source.instrument?.model ?? null,
          Header: title,
        });
      });
    } else {
      CSVExportHeaders.push(column);
    }
  });

  return CSVExportHeaders;
}
