import React, { useCallback, useState } from "react";

import * as PropTypes from "prop-types";

import { connect } from "react-redux";

import {
  Button,
  DataTable,
  Filters,
  Page,
  Panel,
  useAlert,
} from "@validereinc/common-components";

import { addAlertMessage } from "#redux/actions/alertMessages";
import { havePermission } from "#redux/reducers/permissions";

import useTableState from "#src/hooks/useTableState";
import {
  linkToInlineInstrument,
  linkToInstrumentSummary,
} from "#src/Routers/links";

import { downloadLink } from "#utils/download";
import { getFrontendTableState } from "#utils/frontendTableActions";

import CalibrationService from "#services/CalibrationService";
import InstrumentService from "#services/InstrumentService";

import { getBreadcrumbsObject } from "#routers/breadcrumbsHelper";
import { useLocation, useNavigate, useSearchParams } from "#routers/hooks";

import useInstrumentsConfig from "./useInstrumentsConfig";

import ColumnSelect from "../Common/ColumnSelect/ColumnSelect";

import ArchiveInstrumentModal from "./InstrumentsList/Modals/ArchiveInstrumentModal";
import EditCalibrationModal from "./InstrumentsList/Modals/EditCalibrationModal";
import EditInstrumentDetailsModal from "./InstrumentsList/Modals/EditInstrumentDetailsModal";
import EffectiveDurationModal from "./InstrumentsList/Modals/EffectiveDurationModal";

import { INSTRUMENT_TYPE_LABEL } from "./InstrumentConstant";

const INSTRUMENTS_LOCAL_STORAGE_KEY = "instruments_selection_headers";

const ITEMS_KEY = "data.results";

const mapStateToProps = ({ permissions }) => {
  return {
    canEditCalibrations: havePermission(permissions)(
      "360:calibrations",
      "write"
    ),
    canEditFieldLabs: havePermission(permissions)("360:field_labs", "write"),
  };
};

const mapDispatchToProps = {
  addAlertMessage,
};

const Instruments = ({
  breadcrumbs,
  canEditCalibrations,
  canEditFieldLabs,
}) => {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const { addAlert } = useAlert();

  const [showModal, setShowModal] = useState(null);
  const [instrumentDetail, setInstrumentDetail] = useState(null);
  const [response, setResponse] = useState(undefined);

  const instrumentsPageBreadcrumbs = getBreadcrumbsObject(breadcrumbs);

  const onFetchData = useCallback(
    async ({ hardRefresh = false, ...newSearchParams }) => {
      let newResponse = response;

      if (hardRefresh || !response) {
        const { page, rowPerPage, sort, sortDirection, ...restQuery } =
          newSearchParams;

        newResponse = await InstrumentService.getInstrumentList(
          {
            page,
            rowPerPage,
            sort: { [sort]: sortDirection },
          },
          restQuery
        );

        setResponse(newResponse);
      }

      return getFrontendTableState({
        data: newResponse,
        itemsKey: ITEMS_KEY,
        query: newSearchParams,
        filterMapping: {
          instrument_type: {
            filter: ({ value, item }) => {
              if (Array.isArray(item)) {
                return item.includes(INSTRUMENT_TYPE_LABEL[value]);
              } else {
                return INSTRUMENT_TYPE_LABEL[value] === item;
              }
            },
          },
          name: {
            filter: ({ value, item }) =>
              value?.toLowerCase()?.includes(item?.toLowerCase()),
          },
        },
      });
    },
    [response]
  );

  const { tableProps, fetchData, data } = useTableState({
    onFetchData,
  });

  const { filters, quickFilters, headers } = useInstrumentsConfig({
    instruments: data?.allItems,
  });

  const [displayHeaders, setDisplayHeaders] = useState(headers);

  const onFiltersChange = (values) => {
    const { quickFilters, instrument_type, ...rest } = values;

    navigate({
      pathname: location.pathname,
      query: {
        ...searchParams,
        ...rest,
        status: quickFilters,
        instrument_type: instrument_type?.map(({ value }) => value),
      },
      replace: true,
    });
  };

  const onRowClick = ({ instrument_type: type, id }) => {
    navigate({
      pathname: ["inline", "inline_virtual", "emission_monitor"].includes(type)
        ? linkToInlineInstrument(type, id)
        : linkToInstrumentSummary(id),
    });
  };

  const onDownloadCSV = () => {
    addAlert({
      variant: "success",
      message: "Your files will start downloading shortly.",
    });

    CalibrationService.getCalibrationCSVDownloadLink().then(({ data }) => {
      downloadLink(data.url);
    });
  };

  const toggleModal = (showModal = null, instrumentDetail = null) => {
    setShowModal(showModal);
    setInstrumentDetail(instrumentDetail);
  };

  const hardRefresh = () => {
    fetchData({ ...searchParams, hardRefresh: true });
  };

  return (
    <>
      <Page
        title="Instruments"
        breadcrumbs={instrumentsPageBreadcrumbs}
      >
        <Filters
          onChange={onFiltersChange}
          filters={filters}
          quickFilters={quickFilters}
          actions={[
            <ColumnSelect
              key={"column-select"}
              options={headers}
              onChange={setDisplayHeaders}
              value={displayHeaders}
              localStorageKey={INSTRUMENTS_LOCAL_STORAGE_KEY}
            />,
            <Button
              key="export-button"
              variant="primary"
              size="small"
              onClick={onDownloadCSV}
            >
              Export
            </Button>,
          ]}
        />

        <Panel>
          <DataTable
            headers={displayHeaders}
            {...tableProps}
            onRowClick={onRowClick}
            isFluid={false}
            getItemActions={({ item }) => [
              ...(canEditCalibrations && item.instrument_type !== "inline"
                ? [
                    {
                      isOverflow: true,
                      label: "Edit Details",
                      buttonProps: {
                        onClick: () => toggleModal("editDetails", item),
                        icon: "pencil-simple",
                      },
                    },
                    {
                      isOverflow: true,
                      label: "Update Calibration Check",
                      buttonProps: {
                        onClick: () => toggleModal("calibration", item),
                      },
                    },
                    {
                      isOverflow: true,
                      label: "Update Effectivity Duration",
                      buttonProps: {
                        onClick: () => toggleModal("effectivityDuration", item),
                      },
                    },
                  ]
                : []),
              ...(canEditFieldLabs
                ? [
                    {
                      isOverflow: true,
                      label: "Archive/Unarchive Instrument",
                      buttonProps: {
                        onClick: () => toggleModal("archive", item),
                        icon: "archive",
                      },
                    },
                  ]
                : []),
            ]}
          />
        </Panel>

        {showModal === "calibration" && (
          <EditCalibrationModal
            show={true}
            instrumentDetail={instrumentDetail}
            onCalibrationUpdated={hardRefresh}
            onHide={toggleModal}
          />
        )}

        {showModal === "editDetails" && (
          <EditInstrumentDetailsModal
            show={true}
            onHide={toggleModal}
            instrumentId={instrumentDetail.id}
            instrumentName={instrumentDetail.name}
            serialNumber={instrumentDetail.current_serial_number}
            availableSerialNumbers={instrumentDetail.serial_numbers}
            refetchCalibrationData={hardRefresh}
          />
        )}

        {showModal === "archive" && (
          <ArchiveInstrumentModal
            show={true}
            onHide={toggleModal}
            instrumentDetail={instrumentDetail}
            refetchInstruments={hardRefresh}
          />
        )}

        {showModal === "effectivityDuration" && (
          <EffectiveDurationModal
            show={true}
            onHide={toggleModal}
            instrumentDetail={instrumentDetail}
            refetchCalibrationData={hardRefresh}
          />
        )}
      </Page>
    </>
  );
};

Instruments.propTypes = {
  breadcrumbs: PropTypes.array.isRequired,
  canEditCalibrations: PropTypes.bool,
  canEditFieldLabs: PropTypes.bool,
};

const InstrumentsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Instruments);

export default InstrumentsContainer;
