import { useMutation, useQuery } from "@tanstack/react-query";
import {
  Dialog,
  DropdownInput,
  Form,
  FormButton,
  useAlert,
  useForm,
} from "@validereinc/common-components";
import {
  AssetType,
  EstimationMethodDomain,
  MeasurementsDomain,
  ReportingGroupDomain,
} from "@validereinc/domain";
import React, { useEffect } from "react";

import { CalculationParameterConfigurationType } from "#batteries-included-components/Tabs/EstimationMethod/ConfigurationTab/ConfigurationTab";
import {
  EquipmentDropdownInput,
  FacilityDropdownInput,
  FlowDropdownInput,
} from "#src/batteries-included-components/index";
import useLocalization from "#src/hooks/useLocalization";
import type { AssetTypeType } from "@validereinc/domain";

export const DefaultInputSourceDialog = ({
  methodId,
  entityType,
  itemToEdit,
  onSubmit,
  onClose,
}: {
  methodId?: string;
  entityType?: AssetTypeType;
  itemToEdit?: CalculationParameterConfigurationType;
  onSubmit?: () => void;
  onClose?: () => void;
}) => {
  const { localize } = useLocalization();
  const { addAlert } = useAlert();

  const form = useForm({
    defaultValues: {
      asset_type: itemToEdit?.asset_type ?? AssetType.EQUIPMENT,
      asset_id: itemToEdit?.asset_id,
      measurement_type: itemToEdit?.measurement_type,
      reporting_group_id: itemToEdit?.reporting_group_id,
    },
  });

  const { data: reportingGroups } = useQuery({
    queryKey: ["reportingGroups"],
    queryFn: () => ReportingGroupDomain.getList(),
  });

  const { data: measurementTypes } = useQuery({
    queryKey: ["measurementTypes"],
    queryFn: MeasurementsDomain.listMeasurementTypes,
  });

  useEffect(() => {
    form.reset();
  }, [itemToEdit]);

  const setInputSource = useMutation({
    mutationFn: async ({
      asset_id,
      measurement_type,
      reporting_group_id,
    }: Record<string, string>) => {
      try {
        if (!itemToEdit || !methodId || !entityType) {
          throw new Error("Estimation method identifiers unavailable");
        }
        const upsertParams = {
          id: methodId,
          data: {
            measurement_type,
            asset_id,
            reporting_group_id,
          },
          meta: { entityType, inputName: itemToEdit.id },
        };

        if (itemToEdit.has_default) {
          await EstimationMethodDomain.defaultRecords.update(upsertParams);
        } else {
          await EstimationMethodDomain.defaultRecords.create(upsertParams);
        }

        onClose?.();
        onSubmit?.();
        addAlert({
          variant: "success",
          message: `Set default input source for ${itemToEdit?.display_name}`,
        });
      } catch (err) {
        addAlert({
          variant: "error",
          message: "Unable to update default input source",
        });
      }
    },
  });

  const commonFormProps = {
    labelKey: "name",
    valueKey: "id",
    isSearchable: true,
    isRequired: true,
    isFluid: true,
  };

  const getAssetInput = (assetType: AssetTypeType) => {
    switch (assetType) {
      case AssetType.FACILITY:
        return [
          <FacilityDropdownInput
            key="asset_id"
            name="asset_id"
            label="Facility"
            isMulti={false}
            isFluid
            isRequired
          />,
        ];
      case AssetType.EQUIPMENT:
        return [
          <EquipmentDropdownInput
            key="asset_id"
            name="asset_id"
            label="Equipment"
            isMulti={false}
            isFluid
            isRequired
          />,
        ];
      case AssetType.FLOW:
        return [
          <FlowDropdownInput
            key="asset_id"
            name="asset_id"
            label="Flow"
            isMulti={false}
            isFluid
            isRequired
          />,
        ];
      default:
        return [];
    }
  };

  return (
    <Form
      {...form}
      onSubmit={form.handleSubmit(setInputSource.mutate)}
    >
      <Dialog
        isOpen={!!itemToEdit}
        onClose={onClose}
        title="Select Source"
        actionRow={[
          <FormButton
            key="save-default-values"
            variant="primary"
            onClick={form.handleSubmit(setInputSource.mutate)}
          >
            Set Source
          </FormButton>,
        ]}
      >
        <DropdownInput
          {...commonFormProps}
          key="asset_type"
          name="asset_type"
          label="Asset Type"
          isClearable={false}
          options={[
            {
              id: AssetType.EQUIPMENT,
              name: localize("Equipment"),
            },
            {
              id: AssetType.FACILITY,
              name: localize("Facility"),
            },
            {
              id: AssetType.FLOW,
              name: localize("Flow"),
            },
          ]}
        />
        {...getAssetInput(form.watch("asset_type"))}
        <DropdownInput
          {...commonFormProps}
          key="measurement_type"
          name="measurement_type"
          label="Measurement Type"
          options={measurementTypes ?? []}
        />
        <DropdownInput
          {...commonFormProps}
          key="reporting_group_id"
          name="reporting_group_id"
          label="Reporting Scenario"
          options={reportingGroups?.data ?? []}
          isSortedAlphabetically={false}
        />
      </Dialog>
    </Form>
  );
};
