import { restAPI } from "./";
import { AssetTypeType } from "../schemas";

export type CalculatorFilterType = Record<string, string>;

export type CalculatorType = {
  id: string;
  library: string;
  versions: CalculatorVersionType[];
  default_version: string;
};

export type CalculatorV2Type = {
  id: string;
  library: string;
  versions: CalculatorVersionV2Type[];
  default_version: string;
};

export type CalculatorVersionType = {
  id: string;
  version: string;
  title: string;
  description: string;
  calculation_parameters: CalculationParameterType[];
  documentation: CalculatorDocumentationType;
  // REVIEW: check with the back-end team as this API is WIP
  intermediate_results: any[];
  defaults: CalculatorDefaultType[];
};

export enum CalculatorVersionIncludeFields {
  calculation_parameters = "calculation_parameters",
  assets = "assets",
  documentation = "documentation",
  intermediate_results = "intermediate_results",
  outputs = "outputs",
  default = "defaults",
}

export type GetCalculatorsRequestType = {
  include?: CalculatorVersionIncludeFields[];
};

export type GetCalculatorsResponseType = {
  calculators: CalculatorV2Type[];
};

export type CalculatorVersionV2Type = {
  id: string;
  version: string;
  title: string;
  description: string;
  calculation_parameters?: CalculationParameterType[];
  documentation?: CalculatorDocumentationType;
  // REVIEW: check with the back-end team as this API is WIP
  intermediate_results?: any[];
  defaults?: CalculatorDefaultType[];
};

export type CalculationParameterType = {
  id: string;
  display_name: string;
  required: boolean;
  measurement_quantity: string;
  measurement_type: string;
  measurement_unit: string;
  description: string;
  type: "number" | "integer" | "string" | "boolean";
  minimum?: number;
  maximum?: number;
  exclusive_minimum?: number;
  exclusive_maximum?: number;
  enum?: string[];
};

export type CalculationParameterSavedType = CalculationParameterType & {
  measurement_value: number | string;
};

export type CalculationOutputType = Pick<
  CalculationParameterType,
  | "description"
  | "measurement_quantity"
  | "measurement_type"
  | "measurement_unit"
  | "type"
> & { measurement_value: number | string };

export type CalculatorDocumentationType = {
  /** title of the calculator */
  title: string;
  /** set of string-encoded calculations in this calculator */
  calculations: CalculatorEquationType[];
  /** link to the original calculator details */
  link: CalculatorLinkType;
};

export type CalculatorEquationType = {
  /** an encoded string that MathJax can use to render the equation */
  equation: string;
  /** a reference number to find the original equation */
  reference: string;
  /** a list of conditions that make up the equation */
  conditions: CalculatorEquationConditionType[];
};

export type CalculatorEquationConditionType = {
  /** name of the variable */
  variable_name: string;
  /** description of the variable */
  variable_description: string;
};

export type CalculatorLinkType = {
  /** label for the link */
  name: string;
  /** url for the link */
  url: string;
};

export type CalculatorDefaultType = {
  id: string;
  // REVIEW: check with the back-end team as this API is wip
  condition: any;
  default_value: number | string;
};

export type LibraryNodeType = {
  name: string;
  child_type: string | null;
  children: LibraryNodeType[] | null;
  calculator_id: string | null;
  default_version: string | null;
};

type CalculatorLookupType = { calculatorId: string; versionId?: string };

export type CalculatorInputSourceConfigType = {
  /** Refers to a single value within a record */
  record_value_id: string;
  /** Refers to a single record */
  record_id: string;
  /** Refers to a specific asset with its ID */
  asset_id: string;
  /** The name of the asset with the ID specified through asset_id */
  asset_name: string;
  /** The type of the asset referenced */
  asset_type: AssetTypeType;
  /** The reporting group the referenced record falls under */
  reporting_group_id: string;
  /** The name of the calculator input (which will have a corresponding record value) */
  measurement_type: string;
  /** The unit of the calculator input (which will have a corresponding record value) */
  measurement_unit: string;
};

/**
 * Emissions Calculator details from the Analytics APIs proxied through the Node API
 */
export const CalculatorDomain = {
  /**
   * Get a list of all calculation libraries
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/emissions_calculator/get_libraries
   * @returns a list of libraries
   */
  getLibraries: () =>
    restAPI.nodeAPI.GET<LibraryNodeType>({
      endpoint: "/libraries",
    }),
  /**
   * Get a list of all calculators
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/emissions_calculator/get_calculators_v2
   * @returns a list of calculators
   */
  getCalculators: ({ include }: GetCalculatorsRequestType) =>
    restAPI.nodeAPI.GET<GetCalculatorsResponseType>({
      endpoint: "/calculators",
      version: 2,
      query: { ...(include && include.length > 0 && { include }) },
    }),

  /**
   * Get a specific calculator
   * @param calculatorId the ID of the calculator in the analytics back-end
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/emissions_calculator/get_calculator
   * @returns details of a calculator and all its versions
   */
  getCalculator: ({ calculatorId }: CalculatorLookupType) =>
    restAPI.nodeAPI.GET<CalculatorType>({
      endpoint: `/calculators/${calculatorId}`,
    }),

  /** Undocumented API to fetch network calculators */
  getNetworkCalculators: ({ include }: GetCalculatorsRequestType) =>
    restAPI.nodeAPI.GET<GetCalculatorsResponseType>({
      endpoint: "/network_calculators",
      version: 1,
      query: { ...(include && include.length > 0 && { include }) },
    }),

  /**
   * Get a specific calculator version
   * @param calculatorId the ID of the calculator in the analytics back-end
   * @param versionId the ID of the version to fetch
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/emissions_calculator/get_calculator_version
   * @returns details of a specific calculator version
   */
  getCalculatorVersion: ({
    calculatorId,
    versionId = "V1",
  }: CalculatorLookupType) =>
    restAPI.nodeAPI.GET<CalculatorVersionType>({
      endpoint: `/calculators/${calculatorId}/versions/${versionId}`,
    }),
};
