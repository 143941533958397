import { restHandlers } from "../mocks/handlers";
import { setupWorker } from "msw";

/**
 * Mock Service worker for all REST API adapters in the domain package
 * @returns a MSW instance
 */
const setupRestAPIWorker = () =>
  setupWorker(...Object.values(restHandlers).flat());

export { setupRestAPIWorker };
