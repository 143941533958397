import { AlertMessage, Tooltip } from "@validereinc/common-components";
import * as PropTypes from "prop-types";
import React from "react";
import "./RecordOfQualityAlarms.scss";

const ToolTipMessages = {
  prev_component_vol_pct_deviation:
    "The difference between the current and the previous record's component volume over the system's total receipt/disposition volume exceeds a percentage threshold.",
};

const RecordOfQualityAlarms = (props) => {
  return (
    <AlertMessage type="warning">
      {props.alarms.accounting_record_alarms.length > 0 && (
        <div className="recordOfQualityAlarms">
          <div className="alertHeader">Accounting Record Alarms</div>
          <ul>
            {props.alarms.accounting_record_alarms.map((alarm) =>
              ToolTipMessages[alarm.type] ? (
                <Tooltip
                  key={alarm.message}
                  content={ToolTipMessages[alarm.type]}
                  width={300}
                >
                  <li key={alarm.message}>{alarm.message}</li>
                </Tooltip>
              ) : (
                <li key={alarm.message}>{alarm.message}</li>
              )
            )}
          </ul>
        </div>
      )}

      {props.alarms.sample_alarms.length > 0 && (
        <>
          <div className="alertHeader">Sample Alarms</div>
          <ul>
            {props.alarms.sample_alarms.map((alarm) =>
              ToolTipMessages[alarm.type] ? (
                <Tooltip
                  key={alarm.message}
                  content={ToolTipMessages[alarm.type]}
                  width={300}
                >
                  <li key={alarm.message}>{alarm.message}</li>
                </Tooltip>
              ) : (
                <li key={alarm.message}>{alarm.message}</li>
              )
            )}
          </ul>
        </>
      )}
    </AlertMessage>
  );
};

RecordOfQualityAlarms.propTypes = {
  alarms: PropTypes.object.isRequired,
};

export default RecordOfQualityAlarms;
