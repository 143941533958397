import {
  CheckboxInput,
  TextAreaInput,
  TextInput,
} from "@validereinc/common-components";
import React from "react";
import { CreateFormTemplateQuestionTable } from "./CreateFormTemplateQuestionTable";

export const CreateFormTemplateSection = ({ index }: { index: number }) => {
  return (
    <>
      <TextInput
        name={`config.sections.${index}.name`}
        label="Section Name"
        isRequired
      />

      <TextAreaInput
        name={`config.sections.${index}.description`}
        label="Description"
      />

      <CheckboxInput
        name={`config.sections.${index}.is_repeatable`}
        label="Is Repeatable"
        isLabelShown={false}
      />

      <CreateFormTemplateQuestionTable
        name={`config.sections.${index}.questions`}
      />
    </>
  );
};
