import React from "react";
import * as PropTypes from "prop-types";
import { Button } from "react-bootstrap";
import { Tools } from "react-sketch";
import FontAwesome from "react-fontawesome";
import "./ImageControls.css";
import { ImageProperties } from "../Hooks/useAnnotationTools";

export const ImageControls = ({
  imageProperties,
  selectTool,
  children,
  resetView,
  undo,
  canUndo,
}) => {
  const isZoomDisabled = imageProperties.zoom !== 1;
  const zoomButtonBsStyle =
    imageProperties.tool === Tools.ZoomBox ? "primary" : "default";

  const selectZoomTool = () => {
    selectTool(Tools.ZoomBox, "grey");
  };

  return (
    <header className="annotationImageView__header">
      {children || null}
      <Button
        className="annotationImageView__zoomButton"
        bsSize="small"
        bsStyle={zoomButtonBsStyle}
        onClick={selectZoomTool}
        disabled={isZoomDisabled}
      >
        <FontAwesome name="search" /> Zoom In
      </Button>
      <Button
        className="annotationImageView__resetViewButton"
        bsSize="small"
        bsStyle="warning"
        onClick={resetView}
        disabled={!isZoomDisabled}
      >
        Reset View
      </Button>
      <Button
        className="annotationImageView__undoButton"
        bsSize="small"
        bsStyle="default"
        onClick={undo}
        disabled={!canUndo()}
      >
        Undo
      </Button>
    </header>
  );
};

ImageControls.propTypes = {
  imageProperties: PropTypes.instanceOf(ImageProperties).isRequired,
  resetView: PropTypes.func.isRequired,
  selectTool: PropTypes.func.isRequired,
  undo: PropTypes.func.isRequired,
  canUndo: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default ImageControls;
