import { useState, useEffect } from "react";
import FacilityService from "#components/Services/FacilityService";

const useCarbonFacilities = () => {
  const [facilities, setFacilities] = useState([]);
  const [fetchState, setFetchState] = useState("loading");

  useEffect(() => {
    FacilityService.getFacilitiesList({
      page: 1,
      rowPerPage: 1000,
      sort: "name",
      sortDirection: "asc",
    }).then(({ data }) => {
      setFacilities(data.data);
      setFetchState("loaded");
    });
  }, []);

  return [facilities, fetchState];
};

export default useCarbonFacilities;
