import React from "react";
import * as PropTypes from "prop-types";
import { FormControl, FormGroup, InputGroup } from "react-bootstrap";
import "./Inputbox.css";

/* eslint-disable react/prop-types */

const InputBox = (props) => (
  <InputGroup className="validereInputBox">
    {props.children ? (
      <InputGroup.Addon>{{ ...props.children }}</InputGroup.Addon>
    ) : null}
    <FormGroup validationState={props.validationState}>
      <FormControl
        inputRef={props.inputRef}
        type="text"
        className={props.className}
        placeholder={props.placeholder}
        disabled={props.disabled}
        onChange={props.onChange}
        style={props.style}
        value={props.value}
      />
    </FormGroup>
  </InputGroup>
);

InputBox.propTypes = {
  inputRef: PropTypes.func,
  className: PropTypes.string,
  style: PropTypes.object,
  placeholder: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  validationState: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default InputBox;
