import { ensureStreamListIsFetched } from "#redux/actions/index";
import { Panel } from "@validereinc/common-components";
import classNames from "classnames/bind";
import * as PropTypes from "prop-types";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import styles from "./StreamsPanel.module.scss";
import StreamsTable from "./StreamsTable";

const cx = classNames.bind(styles);

const mapStateToProps = ({ streams }) => ({
  streams: streams.data?.toJS() ?? [],
});

const mapDispatchToProps = {
  ensureStreamListIsFetched,
};

const StreamsPanel = ({
  height,
  streams,
  ensureStreamListIsFetched,
  title,
  report,
}) => {
  useEffect(() => {
    ensureStreamListIsFetched();
  }, []);

  return (
    <Panel
      className={cx("tablePanel")}
      style={{ height }}
      title={title}
    >
      <StreamsTable
        streams={streams}
        report={report}
      />
    </Panel>
  );
};

StreamsPanel.propTypes = {
  report: PropTypes.object,
  height: PropTypes.number.isRequired,
  streams: PropTypes.array.isRequired,
  ensureStreamListIsFetched: PropTypes.func.isRequired,
  title: PropTypes.string,
};

export default connect(mapStateToProps, mapDispatchToProps)(StreamsPanel);
