import { AttributeDataType, CustomAttributeType } from "@validereinc/domain";

/**
 * This function is used to sanitize custom attribute form values based on their definition.
 *
 * @param {Record<string, any>} values Custom attribute value object
 * @param {CustomAttributeType[]} customAttributes Definition of custom attributes of asset
 * @returns {Record<string, any>} Sanitized form values for custom attributes, ready to be sent to the API.
 */
export const sanitizeCustomAttributeFormInputs = (
  values: Record<string, any>,
  customAttributes: CustomAttributeType[]
): Record<string, any> => {
  const picklistCustomAttributeFieldNames = customAttributes.reduce(
    (list: string[], customAttrib: CustomAttributeType) => {
      if (customAttrib.data_type === AttributeDataType.PICK_LIST)
        list.push(customAttrib.field_name);
      return list;
    },
    []
  );

  return Object.keys({
    ...(values ?? {}),
  } as Record<string, string>).reduce(
    (newCustomAttributes: Record<string, string>, ca: string) => {
      if (
        !(
          picklistCustomAttributeFieldNames.includes(ca) &&
          values?.[ca] !== undefined &&
          !values[ca]
        )
      ) {
        newCustomAttributes[ca] = values[ca];
      }

      return newCustomAttributes;
    },
    {}
  );
};
