import React, { useEffect, useState, useRef } from "react";
import * as PropTypes from "prop-types";
import { SketchField } from "react-sketch";
import "./ImageCanvas.css";

const ImageCanvas = ({
  url,
  tool,
  lineColor,
  zoom,
  value,
  pan,
  onChange,
  onZoomBoxRelease,
  drawingRef,
  sketchHeight,
  sketchWidth,
  imageScale,
  blockLeft,
  blockRight,
}) => {
  const backgroundImageRef = useRef(null);
  const blockerImageRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (url) {
      setIsLoading(true);
      backgroundImageRef.current.clear();
      backgroundImageRef.current
        .addImg(url, {
          left: 0,
          top: 0,
          scale: imageScale,
        })
        .then(() => {
          setIsLoading(false);
        });
    }
  }, [url]);

  useEffect(() => {
    blockerImageRef.current.clear();

    const blockerRectWidth = 0.45 * sketchWidth;
    const blockerColour = "hsl(0, 0%, 0%, 0.625)";

    if (blockLeft) {
      blockerImageRef.current.addRect({
        left: 0,
        width: blockerRectWidth,
        top: 0,
        height: sketchHeight,
        fill: blockerColour,
      });
    }
    if (blockRight) {
      blockerImageRef.current.addRect({
        left: sketchWidth - blockerRectWidth,
        width: blockerRectWidth,
        top: 0,
        height: sketchHeight,
        fill: blockerColour,
      });
    }
  }, [sketchWidth, sketchHeight, blockLeft, blockRight]);

  return (
    <div
      className="imageCanvas"
      style={{ width: sketchWidth, height: sketchHeight }}
    >
      <SketchField
        className="imageCanvas__backgroundImage"
        ref={backgroundImageRef}
        width={sketchWidth}
        height={sketchHeight}
        pan={pan}
        zoom={zoom}
      />
      <SketchField
        className="imageCanvas__drawingLayer"
        width={sketchWidth}
        ref={drawingRef}
        height={sketchHeight}
        tool={tool}
        lineColor={lineColor}
        lineWidth={0.25}
        value={value}
        pan={pan}
        zoom={zoom}
        onZoomBoxRelease={onZoomBoxRelease}
        onChange={onChange}
      />
      <SketchField
        className="imageCanvas__tubeBlockingLayer"
        ref={blockerImageRef}
        width={sketchWidth}
        height={sketchHeight}
        pan={pan}
        zoom={zoom}
      />
      {isLoading && <div className="isLoadingNotice">Loading Image</div>}
    </div>
  );
};

ImageCanvas.propTypes = {
  url: PropTypes.string.isRequired,
  tool: PropTypes.string,
  lineColor: PropTypes.string,
  zoom: PropTypes.number,
  value: PropTypes.object,
  pan: PropTypes.object,
  onChange: PropTypes.func,
  onZoomBoxRelease: PropTypes.func,
  drawingRef: PropTypes.object,
  sketchHeight: PropTypes.number,
  sketchWidth: PropTypes.number,
  imageScale: PropTypes.number,
  blockLeft: PropTypes.bool,
  blockRight: PropTypes.bool,
};

export default ImageCanvas;
