import {
  Button,
  Form,
  FormButton,
  Modal,
  Title,
  useForm,
} from "@validereinc/common-components";
import moment from "moment";
import * as PropTypes from "prop-types";
import React from "react";
import { v1 as uuidv1 } from "uuid";
import SampleForm from "./SampleForm";

const DEFAULT_INPUTS = {
  sample_name: null,
  sample_type: null,
  started_at: moment().startOf("day").toDate(),
  ended_at: moment().endOf("day").toDate(),
  scheduled: "No",
  workflow_instance: null,
  workflow_instance_id: "",
  stream: null,
  stream_id: null,
  sample_point_id: null,
  new_sample: true,
};

const NewSampleModal = ({ show, onHide, onAddSample }) => {
  const form = useForm({
    defaultValues: DEFAULT_INPUTS,
  });

  const onAddSampleClick = (inputs) => {
    const sample = {
      ...inputs,
      id: uuidv1(),
      sample_name:
        inputs.stream?.name ?? inputs.workflow_instance?.name ?? null,
      stream_id:
        inputs.stream?.id ?? inputs.workflow_instance?.stream_id ?? null,
      workflow_instance_id: inputs.workflow_instance?.id ?? null,
    };

    onAddSample([sample]);
    onHide();
  };

  const inputs = form.watch();

  return (
    <Modal
      open={show}
      onClose={onHide}
    >
      <Form
        onSubmit={onAddSampleClick}
        {...form}
      >
        <Modal.Header>
          <Title>Add New Sample</Title>
        </Modal.Header>

        <Modal.Body>
          <SampleForm
            form={form}
            inputs={inputs}
          />
        </Modal.Body>

        <Modal.Footer>
          <Button
            className="pull-left"
            onClick={onHide}
          >
            Close
          </Button>
          <FormButton
            type="submit"
            variant="primary"
          >
            Add Sample
          </FormButton>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

NewSampleModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onAddSample: PropTypes.func.isRequired,
};

export default NewSampleModal;
