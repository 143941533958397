import { parseCustomReportConfiguration } from "#batteries-included-components/Layouts/CustomReport/CustomReportConfigurationPanel/CustomReportConfigurationPanel.helpers";
import { CustomReportDetailLayout } from "#batteries-included-components/Layouts/CustomReport/CustomReportDetailLayout";
import {
  CustomReportVariants,
  getConfigFromSavedFilter,
  getCustomReportFilters,
  getCustomReportGroupBy,
} from "#batteries-included-components/Layouts/CustomReport/CustomReportDetailLayout.helpers";
import { CreateCustomReportModal } from "#batteries-included-components/Modals/CustomReports/CreateCustomReportModal";
import { DeleteCustomReportModal } from "#batteries-included-components/Modals/CustomReports/DeleteCustomReportModal";
import { EditCustomReportModal } from "#batteries-included-components/Modals/CustomReports/EditCustomReportModal";
import { useListCalculatorResultSavedFilters } from "#hooks/adapters/useCalculatorResults";
import { useNavigate, useParams } from "#routers/hooks";
import { EmissionsCustomReportDetailRoute } from "#routes/emissions/custom-reports/[reportId]/detail/index";
import { useAuthenticatedContext } from "#src/contexts/AuthenticatedContext.helpers";
import {
  CustomStorageKeyTypes,
  useCustomStorageKey,
  useStorageKey,
} from "#src/hooks/useStorageKey";
import { EmissionsCustomReportListRoute } from "#src/routes/emissions/custom-reports";
import { useBreadcrumbsFromRoute } from "#src/utils/route";
import {
  Button,
  DropdownMenu,
  Page,
  useFilters,
} from "@validereinc/common-components";
import { SavedFilterStatus, getFilterObject } from "@validereinc/domain";
import React, { useEffect, useState } from "react";

const VARIANT = CustomReportVariants.EMISSIONS;
export const EmissionsCustomReportDetailPage = () => {
  const navigate = useNavigate();
  const { reportId } = useParams<{ reportId: string }>();
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const storageKeys = useStorageKey("emissions-custom-report-detail");
  const configStorageKey = useCustomStorageKey(
    "emissions-custom-report-detail",
    CustomStorageKeyTypes.CONFIG
  );

  const {
    v2: {
      userInfo: { checkHasPermissions },
    },
  } = useAuthenticatedContext();

  const canWriteCompanyReports = checkHasPermissions(
    "company_custom_reports:write"
  );
  const canDeleteCompanyReports = checkHasPermissions(
    "company_custom_reports:delete"
  );

  const [filters, setFilters] = useFilters(storageKeys.filterConfigStorageKey);
  const [configuration, setConfiguration] = useFilters(configStorageKey);

  const resultFiltersQuery = useListCalculatorResultSavedFilters({});

  const savedFilter = resultFiltersQuery.data?.data?.find(
    ({ id }) => id === reportId
  );

  const [breadcrumbs] = useBreadcrumbsFromRoute(
    EmissionsCustomReportDetailRoute,
    {
      detail: {
        title: savedFilter?.name,
      },
    }
  );

  useEffect(() => {
    const { config, filter } = getConfigFromSavedFilter(VARIANT, savedFilter);
    setConfiguration(config);
    setFilters(filter);
  }, [savedFilter]);

  const { rows } = parseCustomReportConfiguration(configuration);
  const filter = getFilterObject(
    getCustomReportFilters(VARIANT, configuration, filters)
  );

  return (
    <Page
      category={EmissionsCustomReportDetailRoute.title}
      title={savedFilter?.name ?? EmissionsCustomReportDetailRoute.title}
      breadcrumbs={breadcrumbs}
      actionRow={[
        ...(savedFilter?.status === SavedFilterStatus.DRAFT ||
        canWriteCompanyReports
          ? [
              <DropdownMenu
                key="edit-custom-report"
                options={[
                  {
                    label: "Overwrite",
                    onClick: () => {
                      setIsEditModalOpen(true);
                    },
                  },
                  {
                    label: "Save as New",
                    onClick: () => {
                      setIsCreateModalOpen(true);
                    },
                  },
                ]}
              >
                <Button
                  variant="primary"
                  icon="caret-down"
                  iconPosition="right"
                >
                  Save Changes
                </Button>
              </DropdownMenu>,
            ]
          : []),
        ...(savedFilter?.status === SavedFilterStatus.DRAFT ||
        canDeleteCompanyReports
          ? [
              <Button
                key="delete-custom-report"
                variant="error-outline"
                onClick={() => setIsDeleteModalOpen(true)}
              >
                Delete
              </Button>,
            ]
          : []),
      ]}
    >
      <CustomReportDetailLayout
        configStorageKey={configStorageKey}
        reportVariant={VARIANT}
        {...storageKeys}
      />
      <CreateCustomReportModal
        variant={VARIANT}
        isOpen={isCreateModalOpen}
        onClose={() => {
          setIsCreateModalOpen(false);
        }}
        onSave={(id) => {
          navigate({
            pathname: EmissionsCustomReportDetailRoute.toLink({
              pathParams: { reportId: id },
            }),
          });
        }}
        filter={filter}
        groupBy={getCustomReportGroupBy(VARIANT, rows)}
      />
      <DeleteCustomReportModal
        onClose={() => {
          setIsDeleteModalOpen(false);
        }}
        onSuccess={() => {
          navigate({ pathname: EmissionsCustomReportListRoute.toLink() });
        }}
        variant={VARIANT}
        customReportId={isDeleteModalOpen ? reportId : undefined}
      />
      <EditCustomReportModal
        onClose={() => setIsEditModalOpen(false)}
        customReportId={isEditModalOpen ? reportId : undefined}
        filter={filter}
        groupBy={getCustomReportGroupBy(VARIANT, rows)}
        name={savedFilter?.name}
        status={savedFilter?.status}
        variant={VARIANT}
      />
    </Page>
  );
};
