import Immutable from "immutable";
import { Auth0Client } from "@auth0/auth0-spa-js";
import config from "#config";

import {
  USER_SIGNED_OUT,
  FETCH_USER_PROFILE,
  USER_SIGNED_IN,
} from "../constants/action-types";

export const AuthenticationState = Immutable.Record(
  {
    domain: null,
    clientID: null,
    auth0Instance: null,
    userId: null,
    username: null,
    isLoggedIn: false,
  },
  "AuthenticationState"
);

const createWebAuthInstance = ({
  domain,
  clientId,
  isProduction,
}: {
  domain: string;
  clientId: string;
  isProduction: boolean;
}) =>
  new Auth0Client({
    domain,
    clientId,
    cacheLocation: !isProduction ? "localstorage" : "memory",
    authorizationParams: {
      redirect_uri: `${window.location.origin}/app/callback`,
      audience: config.API_ID,
      scope: "openid profile email",
    },
  });

const persistedState = () => {
  const domain = config.DOMAIN;
  const clientId = config.CLIENT_ID;
  const userId = localStorage.getItem("user_id");
  const username = localStorage.getItem("user_trait");

  const auth0Instance = createWebAuthInstance({
    domain,
    clientId,
    isProduction: config.ENV === "production",
  });

  const isLoggedIn = !!(userId && username);

  return new AuthenticationState({
    domain,
    clientID: clientId,
    auth0Instance,
    userId,
    username,
    isLoggedIn,
  });
};

export default (state = persistedState(), action) => {
  switch (action.type) {
    case FETCH_USER_PROFILE: {
      const userId = action.payload.id;
      const username = action.payload.name;

      localStorage.setItem("user_id", userId);
      localStorage.setItem("user_trait", username);

      return state.merge({ userId, username });
    }
    case USER_SIGNED_IN:
      return state.set("isLoggedIn", true);
    case USER_SIGNED_OUT:
      localStorage.removeItem("user_trait");
      localStorage.removeItem("user_id");

      return state.withMutations((s) => {
        s.isLoggedIn = false;
        s.username = undefined;
        s.userId = undefined;
      });
    default:
      return state;
  }
};
