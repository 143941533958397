import { NetworkCalculationConsumersPanel } from "#routes/organization/networks/[networkId]/detail/NetworkCalculationTab/NetworkCalculationConsumersPanel";
import { NetworkCalculationFilterPanel } from "#routes/organization/networks/[networkId]/detail/NetworkCalculationTab/NetworkCalculationFilterPanel";
import { NetworkCalculationInletPanel } from "#routes/organization/networks/[networkId]/detail/NetworkCalculationTab/NetworkCalculationInletPanel";
import { NetworkCalculationSummaryPanel } from "#routes/organization/networks/[networkId]/detail/NetworkCalculationTab/NetworkCalculationSummaryPanel";
import { useStorageKey } from "#src/hooks/useStorageKey";
import React from "react";

export const NetworkCalculationTabV2 = () => {
  const storageKeys = useStorageKey("network-calculation");
  const { tableConfigStorageKey } = useStorageKey(
    "network-calculation-consumer"
  );

  return (
    <>
      <NetworkCalculationFilterPanel
        filterConfigStorageKey={storageKeys.filterConfigStorageKey}
      />
      <NetworkCalculationSummaryPanel
        filterConfigStorageKey={storageKeys.filterConfigStorageKey}
      />
      <NetworkCalculationInletPanel {...storageKeys} />
      <NetworkCalculationConsumersPanel
        {...storageKeys}
        tableConfigStorageKey={tableConfigStorageKey}
      />
    </>
  );
};
