import { LegacyDataTable } from "@validereinc/common-components";
import * as PropTypes from "prop-types";
import React from "react";
import { AutoSizer } from "react-virtualized";
import {
  getComponentsHeaders,
  getPropertyValue,
} from "../ProductionReportHelper";
import { generateReportSummaryTableRows } from "./ReportSummaryTableHelper";

const TABLE_OFFSET = 90;
const ROW_HEIGHT = 60;

const ReportSummaryTable = ({ productionReportDetail, balanceSourceType }) => {
  const { summary, inlets, outlets } = productionReportDetail;

  function totalAndEnergyRenderer(rowData, columnKey) {
    const data = rowData[columnKey];
    return getPropertyValue(data);
  }

  function componentRenderer(rowData, columnKey) {
    const data = rowData.components?.[columnKey];
    return getPropertyValue(data);
  }

  const reportSummaryTableRows = generateReportSummaryTableRows(
    summary,
    balanceSourceType,
    inlets,
    outlets
  );

  const components = getComponentsHeaders(summary, componentRenderer);

  const headers = [
    {
      label: "Properties",
      key: "name",
      width: 250,
      fixed: true,
    },
    {
      label: "Total",
      key: "total",
      width: 150,
      fixed: true,
      cellRenderer: totalAndEnergyRenderer,
      rightAlign: true,
    },
    {
      label: "Energy",
      key: "energy",
      width: 150,
      fixed: true,
      cellRenderer: totalAndEnergyRenderer,
      rightAlign: true,
    },
    ...components,
  ];

  const tableHeight = TABLE_OFFSET + ROW_HEIGHT * reportSummaryTableRows.length;
  return (
    <div style={{ height: tableHeight }}>
      <AutoSizer>
        {({ width, height }) => (
          <LegacyDataTable
            width={width}
            height={height}
            headers={headers}
            list={reportSummaryTableRows}
            rowHeight={ROW_HEIGHT}
          />
        )}
      </AutoSizer>
    </div>
  );
};

ReportSummaryTable.propTypes = {
  productionReportDetail: PropTypes.object.isRequired,
  balanceSourceType: PropTypes.object.isRequired,
};

export default ReportSummaryTable;
