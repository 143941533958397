import React from "react";
import * as PropTypes from "prop-types";
import config from "#src/config";
import InstrumentDetail from "../InstrumentDetail";
import { getMeasurementType } from "../../Redux/reducers/measurements";
import { connect } from "react-redux";
import { notesColumn } from "../Shared/TestsDatatable/NotesColumn";
import { sortByMeasurement } from "../InstrumentHelper";
import { getComponentShortForm } from "../../../utils/stringFormatter";
import { getColumnWidth } from "./GenericTestsHelper";
import {
  sampleStateRenderer,
  sortBySampleState,
} from "../../Samples/SampleHelper";
import { getTimeStringFromDate } from "#utils/timeFormatter";
import TestDetailModal from "#components/Tests/TestDetailModal/TestDetailModal";

const MEASUREMENT_COLUMN_WIDTH = 150;

const dateRenderer = (rowData, columnKey) => {
  return (
    <div>
      {getTimeStringFromDate(rowData[columnKey], config.DATETIME_FORMAT)}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    getMeasurementDecimals: (measurement) =>
      getMeasurementType(state.measurements)(measurement).decimals,
  };
};

const GenericTests = (props) => {
  const measurementRenderer = (rowData, columnKey) => {
    const decimals = props.getMeasurementDecimals(columnKey);

    try {
      const { value, unit } = rowData.measurements[columnKey];
      return (
        <div>
          {value.toFixed(decimals)}
          {unit}
        </div>
      );
    } catch {
      return <div>-</div>;
    }
  };

  const measurementTypes =
    props?.instrumentInfo?.valid_measurement_types?.map((measurement) => {
      const label = getComponentShortForm(measurement);

      return {
        label,
        key: measurement,
        width: getColumnWidth(label, MEASUREMENT_COLUMN_WIDTH),
        cellRenderer: measurementRenderer,
        sort: sortByMeasurement,
        rightAlign: true,
      };
    }) ?? [];

  const headers = [
    {
      label: "Name",
      key: "name",
      width: 300,
      fixed: true,
    },
    {
      label: "Sample State",
      key: "sample_state",
      width: 150,
      cellRenderer: (row) => sampleStateRenderer(row.sample),
      sort: sortBySampleState,
    },
    {
      label: "Date",
      key: "date",
      width: 170,
      cellRenderer: dateRenderer,
    },
    {
      label: "Tester",
      key: "tested_by",
      width: 150,
      suppress: true,
    },

    ...measurementTypes,

    notesColumn,
  ];

  return (
    <InstrumentDetail
      testId={props.match.params.testId}
      instrumentId={props.match.params.instrumentId}
      height={props.height}
      instrumentInfo={props.instrumentInfo}
      ReportModalComponent={TestDetailModal}
      filterKey="name"
      headers={headers}
      defaultSortBy="date"
      defaultSortDirection="desc"
    />
  );
};

GenericTests.propTypes = {
  match: PropTypes.object.isRequired,
  height: PropTypes.number.isRequired,
  instrumentInfo: PropTypes.object,
  getMeasurementDecimals: PropTypes.func,
};

export default connect(mapStateToProps, null)(GenericTests);
