import isPlainObject from "lodash/isPlainObject";
import type { InstanceOptions } from "modern-errors";
import {
  BaseError,
  ErrorCodes,
  ErrorCodesToExplanationGetters,
  type BaseErrorProps,
} from "./base";

export interface HTTPRequestErrorProps {
  /** the API endpoint that was originally requested */
  endpoint?: string;
  /** the HTTP method used against the endpoint */
  method?: "GET" | "POST" | "PUT" | "DELETE";
  /** the HTTP status code of the response */
  status?: number;
  /** the request content type */
  requestContentType?: string;
  /** the request query */
  requestQuery?: Record<
    string | number,
    string | number | string[] | number[] | undefined | boolean | null
  >;
  /** the request body, if JSON provided */
  requestBodyJson?: string;
}

export interface HTTPResponseErrorProps {
  /** the API endpoint that was originally requested */
  endpoint?: string;
  /** the HTTP method used against the endpoint */
  method?: "GET" | "POST" | "PUT" | "DELETE";
  /** the HTTP status code of the response */
  status?: number;
  /** the request content type */
  requestContentType?: string | null;
  /** the request query */
  requestQuery?: Record<
    string | number,
    string | number | string[] | number[] | undefined | boolean | null
  >;
  /** the request body, if JSON provided */
  requestBodyJson?: object;
  /** the response body, if JSON provided */
  responseBodyJson?: object;
  /** the response content type */
  responseContentType?: string | null;
}

export const HTTPRequestError = BaseError.subclass("HTTPRequestError", {
  custom: class extends BaseError {
    endpoint?: HTTPRequestErrorProps["endpoint"];
    method?: HTTPRequestErrorProps["method"];
    status?: HTTPRequestErrorProps["status"];
    requestQuery?: HTTPRequestErrorProps["requestQuery"];
    requestContentType?: HTTPRequestErrorProps["requestContentType"];
    requestBodyJson?: HTTPRequestErrorProps["requestBodyJson"];

    constructor(
      message: string,
      options: InstanceOptions &
        Partial<HTTPRequestErrorProps & BaseErrorProps> = {}
    ) {
      options.code = options.code ?? ErrorCodes["http, unknown error"];
      options.getExplanation =
        options.getExplanation ??
        ErrorCodesToExplanationGetters[ErrorCodes["http, unknown error"]];

      super(message, options);

      this.endpoint = options.endpoint;
      this.method = options.method as HTTPRequestErrorProps["method"];
      this.requestContentType =
        options.requestContentType ?? "application/json";
      this.requestQuery = options.requestQuery;

      if (isPlainObject(options.requestBodyJson)) {
        this.requestBodyJson = options.requestBodyJson;
      }
    }
  },
});

export const HTTPResponseError = BaseError.subclass("HTTPResponseError", {
  custom: class extends BaseError {
    endpoint?: HTTPResponseErrorProps["endpoint"];
    method?: HTTPResponseErrorProps["method"];
    status?: HTTPResponseErrorProps["status"];
    requestContentType?: HTTPResponseErrorProps["requestContentType"];
    requestQuery?: HTTPRequestErrorProps["requestQuery"];
    requestBodyJson?: HTTPResponseErrorProps["requestBodyJson"];
    responseContentType?: HTTPResponseErrorProps["responseContentType"];
    responseBodyJson?: HTTPResponseErrorProps["requestBodyJson"];

    constructor(
      message: string,
      options: InstanceOptions &
        Partial<HTTPResponseErrorProps & BaseErrorProps> = {}
    ) {
      options.code = options.code ?? ErrorCodes["http, unknown error"];
      options.getExplanation =
        options.getExplanation ??
        ErrorCodesToExplanationGetters[ErrorCodes["http, unknown error"]];

      super(message, options);

      this.endpoint = options.endpoint;
      this.method = options.method as HTTPResponseErrorProps["method"];
      this.requestContentType =
        options.requestContentType ?? "application/json";
      this.requestQuery = options.requestQuery;

      if (
        isPlainObject(options.requestBodyJson) ||
        Array.isArray(options.requestBodyJson)
      ) {
        this.requestBodyJson = options.requestBodyJson;
      }

      this.status = options.status;
      this.responseContentType =
        options.responseContentType ?? "application/json";

      if (
        isPlainObject(options.responseBodyJson) ||
        Array.isArray(options.responseBodyJson)
      ) {
        this.responseBodyJson = options.responseBodyJson;
      }
    }
  },
});

export const HTTPNotFoundError = HTTPResponseError.subclass(
  "HTTPNotFoundError",
  {
    custom: class extends HTTPResponseError {
      constructor(
        message: string,
        options: InstanceOptions & Partial<HTTPResponseErrorProps> = {}
      ) {
        super(message, options);

        this.code = ErrorCodes["http, status 404"];
        this.getExplanation =
          ErrorCodesToExplanationGetters[ErrorCodes["http, status 404"]];
      }
    },
  }
);

export const HTTPForbiddenError = HTTPResponseError.subclass(
  "HTTPForbiddenError",
  {
    custom: class extends HTTPResponseError {
      constructor(
        message: string,
        options: InstanceOptions & Partial<HTTPResponseErrorProps> = {}
      ) {
        super(message, options);

        this.code = ErrorCodes["http, status 403"];
        this.getExplanation =
          ErrorCodesToExplanationGetters[ErrorCodes["http, status 403"]];
      }
    },
  }
);
