import { queryParamsConverter } from "#routers/historyHelper";
import useQueryParams from "#routers/useQueryParams";
import { GetAllObjectValue } from "#utils/objectFormatter";
import {
  FilterPillbox,
  MultiDropdownInputWithSearch,
} from "@validereinc/common-components";
import difference from "lodash/difference";
import differenceBy from "lodash/differenceBy";
import React, { useEffect, useState } from "react";

const DEFAULT_FILTER_DROPDOWN = {
  sites: {
    label: "Site",
    inputs: [],
    options: [],
    labelKey: "name",
  },
};

const filterInsights = (thirdPartyLabImportFiles, filters) => {
  const { sites } = filters;

  const filteredThirdPartyLabImportFiles = thirdPartyLabImportFiles.filter(
    (file) => {
      if (
        sites?.length > 0 &&
        sites.every((site) => site.id !== file.site.id)
      ) {
        return false;
      }

      return true;
    }
  );

  return filteredThirdPartyLabImportFiles;
};

const useFilterInputs = (thirdPartyLabImportFiles) => {
  const [filterDropdowns, setFilterDropdown] = useState({
    ...DEFAULT_FILTER_DROPDOWN,
  });

  const { queryParamInput, queryParamOptions } =
    queryParamsConverter(filterDropdowns);

  const [queryParams, setQueryParams] = useQueryParams(
    queryParamInput,
    queryParamOptions
  );

  useEffect(() => {
    if (thirdPartyLabImportFiles.length > 0) {
      const { sites } = { ...filterDropdowns };

      const siteOptions = GetAllObjectValue(
        thirdPartyLabImportFiles,
        "site",
        "id"
      ).filter((option) => option);

      sites.options = siteOptions;

      setFilterDropdown({
        ...filterDropdowns,
        sites,
      });
    }
  }, [thirdPartyLabImportFiles]);

  const onDropdownSelect = (selectedValue, key) => {
    setQueryParams({ [key]: selectedValue });
  };

  const clearAllFilters = () => {
    const newQueryParam = { ...queryParams };

    Object.keys(newQueryParam).forEach((key) => {
      newQueryParam[key] = [];
    });

    setQueryParams(newQueryParam);
  };

  const onClearFilterClick = (filterObject) => {
    if (!filterObject) {
      return clearAllFilters();
    }

    const newQueryParam = { ...queryParams };
    const { filterKey, name, id } = filterObject;

    if (id) {
      newQueryParam[filterKey] = differenceBy(
        newQueryParam[filterKey],
        [filterObject],
        "id"
      );
    } else {
      newQueryParam[filterKey] = difference(newQueryParam[filterKey], [name]);
    }

    setQueryParams({ ...newQueryParam });
  };

  const filterRow = (
    <div className="filterRow">
      {Object.keys(filterDropdowns).map((key) => {
        const { label, labelKey, options } = filterDropdowns[key];

        return (
          <MultiDropdownInputWithSearch
            key={key}
            label={label}
            labelKey={labelKey ? labelKey : null}
            dropdownKey={key}
            value={queryParams[key]}
            options={options}
            onChange={onDropdownSelect}
          />
        );
      })}
    </div>
  );

  const filteredThirdPartyLabImportFiles = filterInsights(
    thirdPartyLabImportFiles,
    queryParams
  );

  const filterPillbox = (
    <FilterPillbox
      filterBy={queryParams}
      onClearFilterClick={onClearFilterClick}
    />
  );

  return [filterRow, filterPillbox, filteredThirdPartyLabImportFiles];
};

export default useFilterInputs;
