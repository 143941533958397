import {
  alertingAPI,
  dataEngApi,
  dataPlatformApi,
  nodeAPI,
} from "#components/Services/ServiceHelper";
import { fetchMeasurementTypes, fetchUserProfile } from "#redux/actions";
import { signOut } from "#redux/actions/authentication";
import { fetchFeatureFlags } from "#redux/actions/featureFlags";
import { fetchUserPermissions } from "#redux/actions/permissions";
import { linkToUserSetting } from "#src/Routers/links";
import { MeasurementType } from "#src/components/Redux/reducers/measurements";
import { AuthenticatedContextAuthVersionType } from "#src/contexts/AuthenticatedContext";
import {
  useAuthenticatedContext,
  useIsFeatureAvailable,
  useIsOpsHubExperience,
} from "#src/contexts/AuthenticatedContext.helpers";
import { linkToHome } from "#src/routes/home";
import { linkToUserDetailPage } from "#src/routes/settings/users/detail";
import { ExceptionUtils } from "#src/utils/exception";
import { Auth0Client } from "@auth0/auth0-spa-js";
import { Map, Record } from "immutable";
import React, { ReactElement, useEffect } from "react";
import { connect } from "react-redux";
import { Redirect, useLocation } from "react-router";
import { useAxiosInterceptors } from "./useAxiosInterceptors";

const mapStateToProps = ({ authentication, measurements }) => {
  return {
    auth0Instance: authentication.auth0Instance,
    measurements,
  };
};

const mapDispatchToProps = {
  signOut,
  fetchUserProfile,
  fetchUserPermissions,
  fetchMeasurementTypes,
  fetchFeatureFlags,
};

export const AuthenticatedPage = (props: AuthenticatedPageProps) => {
  const {
    auth0Instance,
    measurements,
    signOut,
    fetchMeasurementTypes,
    children,
  } = props;
  const { activeAuthVersion } = useAuthenticatedContext();

  const isV2AuthOnly =
    activeAuthVersion !== "unknown" &&
    Array.isArray(activeAuthVersion) &&
    activeAuthVersion.length === 1 &&
    activeAuthVersion.includes("v2");

  const elixirServiceIsMounting = useAxiosInterceptors(signOut, auth0Instance);
  const nodeServiceIsMounting = useAxiosInterceptors(
    signOut,
    auth0Instance,
    nodeAPI
  );
  const dataEngServiceIsMounting = useAxiosInterceptors(
    signOut,
    auth0Instance,
    dataEngApi
  );
  const alertingServiceIsMounting = useAxiosInterceptors(
    signOut,
    auth0Instance,
    alertingAPI
  );
  const dataPlatformServiceIsMounting = useAxiosInterceptors(
    signOut,
    auth0Instance,
    dataPlatformApi
  );
  const areAxiosInterceptorsMounting = [
    elixirServiceIsMounting,
    nodeServiceIsMounting,
    dataEngServiceIsMounting,
    alertingServiceIsMounting,
    dataPlatformServiceIsMounting,
  ].some((isMounting) => isMounting);

  useEffect(() => {
    if (isV2AuthOnly || measurements.get("measurementTypes").size) return;

    fetchMeasurementTypes();
  }, [isV2AuthOnly, measurements]);

  useEffect(() => {
    if (areAxiosInterceptorsMounting) return;

    ExceptionUtils.registerLifecycleEvent({
      category: "app",
      type: "info",
      message: "Axios interceptors mounted",
    });
  }, [areAxiosInterceptorsMounting]);

  if (areAxiosInterceptorsMounting) {
    return null;
  }

  return (
    <AuthenticatedPageContent {...props}>{children}</AuthenticatedPageContent>
  );
};

export const AuthenticatedPageContent = ({
  children,
}: AuthenticatedPageContentProps) => {
  const location = useLocation();
  const {
    activeAuthVersion,
    isAuthenticated,
    isLoading,
    claims: { user, company },
    v1: { user: legacyUser },
    v2: {
      userInfo: { user: userFull },
    },
  } = useAuthenticatedContext();
  const [isHomeAvailable] = useIsFeatureAvailable({
    featureFlagQuery: "core:home",
  });
  const [isOpsExperienceEnabled] = useIsOpsHubExperience();

  const isV1AuthOnly =
    activeAuthVersion !== "unknown" &&
    Array.isArray(activeAuthVersion) &&
    activeAuthVersion.length === 1 &&
    activeAuthVersion.includes("v1");
  const isDualAuth =
    activeAuthVersion !== "unknown" &&
    Array.isArray(activeAuthVersion) &&
    activeAuthVersion.length === 2 &&
    (["v1", "v2"] satisfies AuthenticatedContextAuthVersionType[]).every((v) =>
      activeAuthVersion.includes(v)
    );
  const isV2AuthOnly =
    activeAuthVersion !== "unknown" &&
    Array.isArray(activeAuthVersion) &&
    activeAuthVersion.length === 1 &&
    activeAuthVersion.includes("v2");
  const isHomePageEnabled =
    ((isDualAuth && !isOpsExperienceEnabled) || isV2AuthOnly) &&
    isHomeAvailable; // the home page is irrelevant to v1 auth users

  useEffect(() => {
    if (!isAuthenticated || isLoading) return;

    const registeredSession = ExceptionUtils.getAuthenticatedSession();

    // don't re-register after critical details have been captured
    if (
      registeredSession.user?.id &&
      registeredSession.tags[ExceptionUtils.ExceptionContextKeys.company] &&
      registeredSession.tags[
        ExceptionUtils.ExceptionContextKeys.activeAuthVersion
      ] !== "unknown"
    )
      return;

    const status = isV1AuthOnly
      ? !legacyUser?.state || legacyUser?.state === "inactive"
        ? "disabled"
        : "active"
      : isDualAuth || isV2AuthOnly
        ? userFull?.status ?? "disabled"
        : null;
    const timezonePreferred = isV1AuthOnly
      ? legacyUser?.timezone_preference
      : isDualAuth || isV2AuthOnly
        ? userFull?.timezone
        : null;
    const quicksight = isV1AuthOnly
      ? legacyUser?.quicksight
      : isDualAuth || isV2AuthOnly
        ? userFull?.quicksight
        : null;

    ExceptionUtils.registerAuthenticatedSession({
      activeAuthVersion,
      ...(user.id ? { id: user.id } : {}),
      ...(company.id ? { company_id: company.id } : {}),
      ...(status !== null ? { status } : {}),
      ...(timezonePreferred !== null ? { timezonePreferred } : {}),
      ...(quicksight !== null ? { quicksight } : {}),
    });
    ExceptionUtils.registerLifecycleEvent({
      category: "session",
      type: "info",
      message: "Registered complete session details",
    });
  }, [
    isLoading,
    isAuthenticated,
    user,
    company,
    legacyUser,
    userFull,
    isV1AuthOnly,
    isDualAuth,
    isV2AuthOnly,
  ]);

  // redirect to the account settings page if the home page feature flag is not
  // enabled and the user is attempting to reach the home page
  if (
    isAuthenticated &&
    !isLoading &&
    !isHomePageEnabled &&
    location.pathname === linkToHome()
  ) {
    return (
      <Redirect
        to={
          (isDualAuth && !isOpsExperienceEnabled) || isV2AuthOnly
            ? linkToUserDetailPage(user.id)
            : linkToUserSetting()
        }
      />
    );
  }

  return children ?? null;
};

type AuthenticatedPageProps = {
  auth0Instance: Auth0Client;
  measurements: Record<{
    measurementTypes: Map<string, typeof MeasurementType>;
  }>;
  signOut: typeof signOut;
  fetchUserProfile: typeof fetchUserProfile;
  fetchUserPermissions: typeof fetchUserPermissions;
  fetchMeasurementTypes: typeof fetchMeasurementTypes;
  fetchFeatureFlags: typeof fetchFeatureFlags;
  children?: ReactElement;
};

type AuthenticatedPageContentProps = {
  children?: ReactElement;
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthenticatedPage);
