import mime from "mime";

/* adapted from: https://stackoverflow.com/a/24922761 , all credit to them */
export function downloadCSV(filename: string, csvString: string) {
  const csvMimeType = mime.getType(".csv");
  const blob = new Blob([csvString], {
    ...(csvMimeType ? { type: `${csvMimeType};charset=utf-8;` } : {}),
  });
  filename = filename + ".csv";
  downloadBlob(filename, blob);
}

export function downloadXLSX(filename: string, data: any) {
  const xlsMimeType = mime.getType(".xlsx");
  const blob = new Blob([data], {
    ...(xlsMimeType ? { type: xlsMimeType } : {}),
  });
  filename = filename + ".xlsx";
  downloadBlob(filename, blob);
}

export function downloadPDF(filename: string, pdfString: string) {
  const pdfMimeType = mime.getType(".pdf");
  const blob = new Blob([pdfString], {
    ...(pdfMimeType ? { type: `${pdfMimeType};charset=utf-8;` } : {}),
  });
  filename = filename + ".pdf";
  downloadBlob(filename, blob);
}

export function downloadFile(filename: string, data: any) {
  const blob = new Blob([data], { type: "charset=utf-8;" });
  downloadBlob(filename, blob);
}

/**
 * Initiate a file download at a URL by creating a temporary hidden link element with a download attribute, then virtually clicking it. The DOM node is then removed.
 * @param {string} link a valid GET link for a file
 * @param {string} fileName optionally specify the name of the downloaded file. If left unspecified, the name of the file will be determined by the link provided. NOTE: this only works for files from the same origin – has no effect otherwise – use a fetch request to GET the file and feed it into a blob that can be renamed instead.
 */
export function downloadLink(link: string, fileName?: string) {
  const linkElement = document.createElement("a");

  linkElement.setAttribute("href", link);
  linkElement.setAttribute("download", fileName ?? "");
  linkElement.hidden = true;
  document.body.appendChild(linkElement);
  linkElement.click();

  // remove the element after 1 second
  setTimeout(() => {
    linkElement.remove();
  }, 1000);
}

export function downloadBlob(filename: string, blob: Blob) {
  const link = document.createElement("a");

  if (link.download === undefined) {
    return;
  }

  const url = URL.createObjectURL(blob);

  link.setAttribute("href", url);
  link.setAttribute("download", filename || "");
  link.hidden = true;
  link.click();

  // destroy the object URL after 10 seconds
  setTimeout(() => URL.revokeObjectURL(url), 10000);
}

export const FILE_TYPES = {
  WORD_DOCUMENT: "WORD_DOCUMENT",
  IMAGE: "IMAGE",
  PDF: "PDF",
  POWERPOINT: "POWERPOINT",
  TEXT_DOCUMENT: "TEXT_DOCUMENT",
  EXCEL_DOCUMENT: "EXCEL_DOCUMENT",
  AUDIO: "AUDIO",
  VIDEO: "VIDEO",
  UNKNOWN: "UNKNOWN",
} as const;

export const fileTypeFromExtension = (fileName: string) => {
  const [extension] = fileName.split(".").slice(-1);

  if (!extension) {
    return "file-o";
  }

  switch (extension.toLowerCase()) {
    case "doc":
    case "docx": {
      return FILE_TYPES.WORD_DOCUMENT;
    }
    case "jpeg":
    case "png":
    case "bmp":
    case "tiff":
    case "gif":
    case "webp":
    case "jpg": {
      return FILE_TYPES.IMAGE;
    }
    case "pdf": {
      return FILE_TYPES.PDF;
    }
    case "ppt":
    case "pptx": {
      return FILE_TYPES.POWERPOINT;
    }
    case "rtf":
    case "md":
    case "log":
    case "txt": {
      return FILE_TYPES.TEXT_DOCUMENT;
    }
    case "csv":
    case "tsv":
    case "xls":
    case "xlsx": {
      return FILE_TYPES.EXCEL_DOCUMENT;
    }
    case "mp3":
    case "wav":
    case "aac": {
      return FILE_TYPES.AUDIO;
    }
    case "mov":
    case "mp4":
    case "mkv":
    case "wma":
    case "wmv":
    case "avi": {
      return FILE_TYPES.VIDEO;
    }
    default:
      return FILE_TYPES.UNKNOWN;
  }
};

export const getFileIconName = (
  fileType: (typeof FILE_TYPES)[keyof typeof FILE_TYPES]
) => {
  switch (fileType) {
    case FILE_TYPES.WORD_DOCUMENT: {
      return "file-word-o";
    }
    case FILE_TYPES.IMAGE: {
      return "file-image-o";
    }
    case FILE_TYPES.PDF: {
      return "file-pdf-o";
    }
    case FILE_TYPES.POWERPOINT: {
      return "file-powerpoint-o";
    }
    case FILE_TYPES.TEXT_DOCUMENT: {
      return "file-text-o";
    }
    case FILE_TYPES.EXCEL_DOCUMENT: {
      return "file-excel-o";
    }
    case FILE_TYPES.AUDIO: {
      return "file-audio-o";
    }
    case FILE_TYPES.VIDEO: {
      return "file-video-o";
    }
    default:
      return "file-o";
  }
};

export const fileExtensionFromFilename = (filename: string) =>
  filename.split(".").pop();
