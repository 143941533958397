import SummaryInformation from "#common/SummaryInformation/SummaryInformation";
import { getFormattedMeasurementValueWithUnit } from "#redux/reducers/measurements";
import { componentQualityComparator } from "#utils/componentQualitySortOrder";
import { getComponentShortForm } from "#utils/stringFormatter";
import { getTimeStringFromDate } from "#utils/timeFormatter";
import {
  LegacyDataTable,
  Panel,
  Tooltip,
} from "@validereinc/common-components";
import get from "lodash/get";
import moment from "moment";
import * as PropTypes from "prop-types";
import React, { useMemo } from "react";
import { Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { AutoSizer } from "react-virtualized";
import config from "../../../config";
import "./QualitySummary.scss";
import { sortByProperties } from "./QualitySummaryHelper";

const mapStateToProps = (state) => {
  return {
    getFormattedMeasurementValueWithUnit: (measurementKey, measurementObject) =>
      getFormattedMeasurementValueWithUnit(state.measurements)(
        measurementKey,
        measurementObject
      ),
  };
};

const sourceRenderer = (rowData, columnKey) => {
  const text = get(rowData, columnKey, "-").replaceAll("_", " ");

  return <div className="inventoryTable__cell">{text}</div>;
};

const propertyRenderer = (rowData, columnKey) => {
  const text = get(rowData, columnKey, "-");

  return (
    <div className="inventoryTable__cell wrap">
      <Tooltip content={text}>{getComponentShortForm(text)}</Tooltip>
    </div>
  );
};

const QualitySummary = ({
  activeStream,
  inventoryDetail,
  loadingState,
  getFormattedMeasurementValueWithUnit,
}) => {
  const sortedQualities = useMemo(() => {
    if (inventoryDetail.qualities) {
      return Object.entries(inventoryDetail.qualities)
        .map(([key, value]) => ({
          property: key,
          value: getFormattedMeasurementValueWithUnit(key, value),
          source: value.source,
          date: getTimeStringFromDate(value.datetime, config.DATETIME_FORMAT),
        }))
        .sort((a, b) => componentQualityComparator(a.property, b.property));
    }
    return [];
  }, [inventoryDetail]);

  const lastUpdatedAt = useMemo(() => {
    let now;
    if (inventoryDetail.qualities) {
      now = moment();
    }

    return getTimeStringFromDate(now, config.DATETIME_FORMAT);
  }, [inventoryDetail]);

  const headers = [
    {
      label: "Property",
      key: "property",
      width: 200,
      cellRenderer: propertyRenderer,
      sort: sortByProperties,
      fixed: true,
    },
    {
      label: "Value",
      key: "value",
      width: 150,
      disableSort: true,
    },
    {
      label: "Source",
      key: "source",
      cellRenderer: sourceRenderer,
      width: 150,
    },
    {
      label: "Effective Quality Date",
      key: "date",
      width: 200,
    },
  ];

  return (
    <Row>
      <Col
        sm={12}
        md={12}
      >
        <Panel
          title="Quality Summary"
          className="qualitySummary"
        >
          <div className="qualitySummary__summary">
            <SummaryInformation
              type="vertical"
              title="Product"
              value={activeStream?.product_type}
            />

            <div className="qualitySummary__divider" />

            <SummaryInformation
              type="vertical"
              title="Overall Quality"
              value="-"
            />

            <div className="qualitySummary__divider" />

            <SummaryInformation
              type="vertical"
              title="Last Updated Time"
              value={lastUpdatedAt}
            />
          </div>

          <div className="qualitySummary__table">
            <AutoSizer>
              {({ height, width }) => (
                <LegacyDataTable
                  height={height}
                  width={width}
                  headers={headers}
                  list={sortedQualities}
                  rowHeight={60}
                  isLoading={loadingState !== "loaded"}
                />
              )}
            </AutoSizer>
          </div>
        </Panel>
      </Col>
    </Row>
  );
};

QualitySummary.propTypes = {
  activeStream: PropTypes.object,
  inventoryDetail: PropTypes.object,
  loadingState: PropTypes.string,
  getFormattedMeasurementValueWithUnit: PropTypes.func,
};

export default connect(mapStateToProps)(QualitySummary);
