import FileService from "#src/components/Services/FileService";
import { useAuthenticatedContext } from "#src/contexts/AuthenticatedContext.helpers";
import { FileInputUploadStatus } from "@validereinc/common-components";
import { useState } from "react";

export enum FileUploadStatusLabels {
  UPLOADING = "Uploading...",
  SCANNING = "Scanning...",
  VERIFYING = "Verifying...",
  COMPLETE = "Upload Completed",
}

export const useFile = (params?: {
  uploadConfig?: { maxPolls?: number; pollSleepTime?: number };
}) => {
  const { claims } = useAuthenticatedContext();
  const [status, setStatus] = useState<FileInputUploadStatus>();

  const upload = async ({
    file,
    description,
  }: {
    file: File;
    description: string;
  }) => {
    const { name, type: contentType, lastModified } = file;

    setStatus({ percentComplete: 1, label: FileUploadStatusLabels.UPLOADING });

    const {
      status: createFileStatus,
      data: {
        data: [uploadedFileData],
      },
    } =
      (await FileService.createFile({
        name,
        contentType,
        description,
        tags: {
          lastModified: new Date(lastModified).toISOString(),
        },
        companyId: claims.company?.id,
      })) ?? {};

    if (createFileStatus !== 200 && createFileStatus !== 201) {
      throw new Error("Could not create an entry in the file upload service");
    }

    const { file_id: fileId, uri: uploadUrl } = uploadedFileData;

    const { status: uploadFileStatus } =
      (await FileService.uploadFile({
        url: uploadUrl,
        fileBlob: file,
      })) ?? {};

    if (uploadFileStatus !== 200 && uploadFileStatus !== 201) {
      throw new Error(
        "Could not upload file to the created entry in file upload service"
      );
    }

    setStatus({ percentComplete: 33, label: FileUploadStatusLabels.SCANNING });
    let scanStatus = "unknown";
    let pollLimit = params?.uploadConfig?.maxPolls ?? 10;

    while (pollLimit > 0 && !["safe", "unsafe"].includes(scanStatus)) {
      const {
        data: {
          data: [{ file_scan_status }],
        },
      } = (await FileService.getFileDownloadUrl(fileId)) ?? {};

      scanStatus = file_scan_status;

      // scan status re-check polling interval
      await new Promise((res) =>
        setTimeout(res, params?.uploadConfig?.pollSleepTime ?? 1000)
      );
      pollLimit -= 1;
    }

    // verify based on the scan result
    setStatus({
      percentComplete: 66,
      label: FileUploadStatusLabels.VERIFYING,
    });

    if (scanStatus !== "safe") {
      throw new Error(
        "The uploaded file has been deemed as unsafe or failed to complete verification"
      );
    }

    setStatus({
      percentComplete: 100,
      label: FileUploadStatusLabels.COMPLETE,
    });
    return { ref: fileId, name };
  };

  return { upload, status };
};
