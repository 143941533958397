import { ExceptionUtils } from "#src/utils/exception";

// IMPROVE: type the generic parameters. the action signature is all over the
// place due to the lack of typing for so long. also, exceptions are handled
// horribly across most legacy code so this may not be doing much.
const exceptionHandlerMiddleware = () => (next) => (action) => {
  try {
    return next(action);
  } catch (err) {
    ExceptionUtils.reportException(err, "error", {
      hint: "Error in Redux store",
    });
    throw err;
  }
};

export default exceptionHandlerMiddleware;
