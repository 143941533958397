const typography = require("./typography-vars.json");

module.exports = {
  typography,
  customProperties: {
    "--font-family-primary": typography.primaryFontFamily,
    "--fontsize-title": typography.titleFontSize,
    "--fontSize-breadcrumb": typography.breadcrumbFontSize,
    "--fontSize-subtext": typography.subtextFontSize,
    "--fontSize-body": typography.bodyFontSize,
    "--fontSize-subheader": typography.subheaderFontSize,
    "--fontSize-panelheader": typography.panelheaderFontSize,
    "--fontSize-header": typography.headerFontSize,
    "--font-headline-extraLarge": typography.headline.extraLarge,
    "--font-headline-large": typography.headline.large,
    "--font-headline-screen": typography.headline.screen,
    "--font-headline-medium": typography.headline.medium,
    "--font-headline-small": typography.headline.small,
    "--font-body-lead-bold": typography.body.lead.bold,
    "--font-body-lead-regular": typography.body.lead.regular,
    "--font-body-large-bold": typography.body.large.bold,
    "--font-body-large-regular": typography.body.large.regular,
    "--font-body-button": typography.body.button,
    "--font-body-medium-bold": typography.body.medium.bold,
    "--font-body-medium-regular": typography.body.medium.regular,
    "--font-body-small-bold": typography.body.small.bold,
    "--font-body-small-regular": typography.body.small.regular,
  },
};
