import {
  FilterPillbox,
  MultiDropdownInputWithSearch,
} from "@validereinc/common-components";
import differenceBy from "lodash/differenceBy";
import sortBy from "lodash/sortBy";
import React, { useEffect, useState } from "react";
import useQueryParams from "../../../Routers/useQueryParams";
import {
  filterBalancesList,
  getUniqComponents,
  getUniqStream,
  getUniqTypes,
  sortComponents,
} from "./BalancesTableHelper";

const DEFAULT_FILTER_DROPDOWN = {
  type: {
    label: "Type",
    inputs: [],
    options: [],
  },
  inlets: {
    label: "Inlets",
    inputs: [],
    options: [],
    labelKey: "name",
    paramKey: "id",
  },
  outlets: {
    label: "Outlets",
    inputs: [],
    options: [],
    labelKey: "name",
    paramKey: "id",
  },
  references: {
    label: "References",
    inputs: [],
    options: [],
    labelKey: "name",
    paramKey: "id",
  },
  components: {
    label: "Components",
    inputs: [],
    options: [],
  },
};

const queryParamsConverter = (filterDropdowns) => {
  // This function will convert filterDropdown into useQueryParams format
  const queryParamInput = {};
  const queryParamOptions = {};

  Object.keys(filterDropdowns).map((key) => {
    queryParamInput[key] = filterDropdowns[key].inputs;
    queryParamOptions[key] = filterDropdowns[key].options;
  });

  return { queryParamInput, queryParamOptions };
};

const useFilterInputs = (balancesList, streams) => {
  const [filterDropdowns, setFilterDropdowns] = useState({
    ...DEFAULT_FILTER_DROPDOWN,
  });

  const { queryParamInput, queryParamOptions } =
    queryParamsConverter(filterDropdowns);
  const [queryParams, setQueryParams] = useQueryParams(
    queryParamInput,
    queryParamOptions
  );

  const onDropdownSelect = (selectedValue, key) => {
    setQueryParams({ [key]: selectedValue });
  };

  const onClearFilterClick = (filterObject) => {
    const newQueryParam = { ...queryParams };

    if (filterObject) {
      const { filterKey } = filterObject;
      newQueryParam[filterKey] = differenceBy(
        newQueryParam[filterKey],
        [filterObject],
        "id"
      );
    } else {
      Object.keys(newQueryParam).map((key) => {
        newQueryParam[key] = [];
      });
    }

    setQueryParams({ ...newQueryParam });
  };

  useEffect(() => {
    if (balancesList.length > 0 && streams.length > 0) {
      const type = { ...filterDropdowns.type };
      type.options = sortBy(getUniqTypes(balancesList));

      const inlets = { ...filterDropdowns.inlets };
      inlets.options = sortBy(
        getUniqStream(balancesList, "inlet_streams", streams),
        "name"
      );

      const outlets = { ...filterDropdowns.outlets };
      outlets.options = sortBy(
        getUniqStream(balancesList, "outlet_streams", streams),
        "name"
      );

      const references = { ...filterDropdowns.references };
      references.options = sortBy(
        getUniqStream(balancesList, "reference_streams", streams),
        "name"
      );

      const components = { ...filterDropdowns.components };
      components.options = sortComponents(getUniqComponents(balancesList));

      setFilterDropdowns({ type, inlets, outlets, references, components });
    }
  }, [balancesList, streams]);

  const filterRow = (
    <div className="filterRow">
      {Object.keys(filterDropdowns).map((key) => {
        const { label, labelKey, options } = filterDropdowns[key];

        return (
          <MultiDropdownInputWithSearch
            key={key}
            label={label}
            labelKey={labelKey ? labelKey : null}
            dropdownKey={key}
            value={queryParams[key]}
            options={options}
            onChange={onDropdownSelect}
          />
        );
      })}
    </div>
  );

  const filterPillbox = (
    <FilterPillbox
      filterBy={queryParams}
      onClearFilterClick={onClearFilterClick}
    />
  );

  const filteredBalancesList = filterBalancesList(balancesList, queryParams);

  return [
    filterPillbox,
    filterRow,
    filteredBalancesList,
    onDropdownSelect,
    onClearFilterClick,
  ];
};

export default useFilterInputs;
