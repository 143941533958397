const CREATE_CUSTOM_REPORT_PAGE_TITLE = "Create Custom Report";

export const linkToCreateCustomReport = (reportVariant = ":reportVariant") =>
  `/app/custom-reports/${reportVariant}/create`;
export const CREATE_CUSTOM_REPORT_BREADCRUMB = {
  title: CREATE_CUSTOM_REPORT_PAGE_TITLE,
  path: linkToCreateCustomReport,
};
export type CreateCustomReportParamType = {
  reportVariant: string;
};

export * from "./CreateCustomReportPage";
