import { APPLICATION_INITIALIZED } from "#src/components/Redux/constants/action-types";
import { ExceptionUtils } from "#src/utils/exception";
import { Middleware } from "redux";

// IMPROVE: type the generic parameters. the action signature is all over the place
// due to the lack of typing for so long.
const loggerMiddleware: Middleware = () => (next) => (action) => {
  const result = next(action);

  switch (action.type) {
    case APPLICATION_INITIALIZED:
      ExceptionUtils.registerLifecycleEvent({
        category: "app",
        type: "info",
        message: "Initialized global Redux store",
      });
      break;
  }

  return result;
};

export default loggerMiddleware;
