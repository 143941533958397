import {
  apiRequestServicesPromise,
  apiPutServicesPromise,
} from "./ServiceHelper";

const NotificationService = {
  getUserAndSiteNotificationSettings() {
    return apiRequestServicesPromise(`/api/users/@me/notifications/settings`);
  },

  updateUserNotificationSettings(siteId, notificationSettings) {
    return apiPutServicesPromise(
      `/api/users/@me/sites/${siteId}/notifications/settings`,
      notificationSettings
    );
  },

  getCompanyNotificationSettings(companyId) {
    return apiRequestServicesPromise(
      `/api/companies/${companyId}/notifications/settings`
    );
  },

  updateCompanyNotificationSettings(companyId, notificationSettings) {
    return apiPutServicesPromise(
      `/api/companies/${companyId}/notifications/settings`,
      notificationSettings
    );
  },

  getCompanyRoleNotificationSettings(roleId) {
    return apiRequestServicesPromise(
      `/api/roles/${roleId}/notifications/settings`
    );
  },

  updateRoleNotificationSettings(roleId, notificationSettings) {
    return apiPutServicesPromise(
      `/api/roles/${roleId}/notifications/settings`,
      notificationSettings
    );
  },

  updateUserWorkflowDigestEmailTimePreferences(workflowDigestTimePreference) {
    return apiPutServicesPromise(
      `/api/users/@me/notifications/settings/digests`,
      workflowDigestTimePreference
    );
  },

  updateUserAlertDigestEmailTimePreferences(alertDigestTimePreference) {
    return apiPutServicesPromise(
      `/api/users/@me/notifications/settings/alert_digests`,
      alertDigestTimePreference
    );
  },
};

export default NotificationService;
