import { FeatureFlagType } from "../schemas";
import {
  GetListRequestType,
  ResourceServiceType,
  ResponseErrorType,
  fetchAndCollate,
  getFilterObject,
} from "../util";
import { restAPI } from "./api";

export const FeatureFlagAdapter: ResourceServiceType<FeatureFlagType> = {
  /**
   * Get list of global feature flags
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/feature_flags/search_feature_flags
   */
  getList: ({
    page,
    pageSize,
    filters,
  }: GetListRequestType<{
    name: string;
    isEnabled: boolean;
    isDeprecated: boolean;
  }>) =>
    fetchAndCollate(
      ({ page, pageSize }) =>
        restAPI.nodeAPI.POST({
          endpoint: "/feature_flags/search",
          body: {
            page,
            page_size: pageSize,
            filter: getFilterObject(filters),
          },
        }),
      page,
      pageSize
    ),
  /**
   * Get a single global feature flag
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/feature_flags/get_feature_flag
   */
  getOne: ({ id }) =>
    restAPI.nodeAPI.GET({
      endpoint: `/feature_flags/${id}`,
    }),
  getMany: () =>
    Promise.reject({
      success: false,
      error: "Method not supported",
    } satisfies ResponseErrorType),
  createOne: () =>
    Promise.reject({
      success: false,
      error: "Method not supported",
    } satisfies ResponseErrorType),
  createMany: () =>
    Promise.reject({
      success: false,
      error: "Method not supported",
    } satisfies ResponseErrorType),
  updateOne: () =>
    Promise.reject({
      success: false,
      error: "Method not supported",
    } satisfies ResponseErrorType),
  updateMany: () =>
    Promise.reject({
      success: false,
      error: "Method not supported",
    } satisfies ResponseErrorType),
  deleteOne: () =>
    Promise.reject({
      success: false,
      error: "Method not supported",
    } satisfies ResponseErrorType),
  deleteMany: () =>
    Promise.reject({
      success: false,
      error: "Method not supported",
    } satisfies ResponseErrorType),
};
