import { NetworkCalculationResultRoute } from "#routes/organization/networks/[networkId]/detail/calculation-result/[period]/details";
import { NetworkCalculationResultAdjustmentPanel } from "#routes/organization/networks/[networkId]/detail/calculation-result/[period]/details/NetworkCalculationResultAdjustmentPanel";
import { NetworkCalculationResultDetailPanel } from "#routes/organization/networks/[networkId]/detail/calculation-result/[period]/details/NetworkCalculationResultDetailPanel";
import {
  CalculationFlowVariant,
  NetworkCalculationResultFlowPanel,
} from "#routes/organization/networks/[networkId]/detail/calculation-result/[period]/details/NetworkCalculationResultFlowPanel";
import { NetworkCalculationResultFormulaPanel } from "#routes/organization/networks/[networkId]/detail/calculation-result/[period]/details/NetworkCalculationResultFormulaPanel";
import { NetworkCalculationResultStatusPanel } from "#routes/organization/networks/[networkId]/detail/calculation-result/[period]/details/NetworkCalculationResultStatusPanel";
import { useBreadcrumbsFromRoute } from "#utils/route";
import { Column, Grid, Page, Row } from "@validereinc/common-components";
import React from "react";

export const NetworkCalculationResultPage = () => {
  const [breadcrumbs] = useBreadcrumbsFromRoute(NetworkCalculationResultRoute);

  return (
    <Page breadcrumbs={breadcrumbs}>
      <Grid>
        <Row>
          <Column variant={6}>
            <NetworkCalculationResultStatusPanel />
            <NetworkCalculationResultDetailPanel />
          </Column>
          <Column variant={18}>
            <NetworkCalculationResultAdjustmentPanel />
            <NetworkCalculationResultFlowPanel
              variant={CalculationFlowVariant.INLET}
            />
            <NetworkCalculationResultFlowPanel
              variant={CalculationFlowVariant.NON_ADJUSTABLE}
            />
            <NetworkCalculationResultFlowPanel
              variant={CalculationFlowVariant.ADJUSTABLE}
            />
            <NetworkCalculationResultFormulaPanel />
          </Column>
        </Row>
      </Grid>
    </Page>
  );
};
