import {
  INIT_POLLUTANT,
  POLLUTANTS,
} from "#src/batteries-included-components/Dropdowns/PollutantsDropdown";
import {
  REPORTING_GROUP_VARIANTS,
  ReportingGroupFilterSwitcher,
} from "#src/batteries-included-components/Filters/ReportingGroupFilterSwitcher";
import { DEFAULT_DATE_RANGES } from "#src/hooks/useDateRange";
import useLocalization from "#src/hooks/useLocalization";
import {
  DateSelectorInput,
  DropdownInput,
  FilterPanel,
  StorageKeys,
} from "@validereinc/common-components";
import React from "react";

export const EMISSIONS_HOME_FILTERS = {
  dateRange: {
    name: "date_range",
  },
  reportingScenario: {
    name: "reporting_scenario",
  },
  pollutant: {
    name: "pollutant",
  },
};

export const EmissionsHomeFilterPanel = ({
  filterConfigStorageKey,
}: Pick<StorageKeys, "filterConfigStorageKey">) => {
  const { localize, isLoading } = useLocalization();

  const filterDefault = POLLUTANTS.find(
    (pollutant) => pollutant.dataKey === INIT_POLLUTANT
  )?.label;

  return (
    <FilterPanel
      isLoading={isLoading}
      storageKey={filterConfigStorageKey}
      defaultValue={{
        [EMISSIONS_HOME_FILTERS.dateRange.name]:
          DEFAULT_DATE_RANGES.currentWholeYear,
        [EMISSIONS_HOME_FILTERS.pollutant.name]: INIT_POLLUTANT,
      }}
      defaultActiveKeys={[localize("Facility")]}
      filters={[
        {
          component: (
            <ReportingGroupFilterSwitcher
              variant={REPORTING_GROUP_VARIANTS.MENU_EDIT}
              key={EMISSIONS_HOME_FILTERS.reportingScenario.name}
              name={EMISSIONS_HOME_FILTERS.reportingScenario.name}
              shouldSelectFirstOptionAsDefault
            />
          ),
        },
        {
          component: (
            <DateSelectorInput
              key={EMISSIONS_HOME_FILTERS.dateRange.name}
              name={EMISSIONS_HOME_FILTERS.dateRange.name}
              variant="month"
              isRange
              isInline
            />
          ),
        },
        {
          component: (
            <DropdownInput
              key={EMISSIONS_HOME_FILTERS.pollutant.name}
              name={EMISSIONS_HOME_FILTERS.pollutant.name}
              placeholder={filterDefault}
              options={POLLUTANTS}
              labelKey="label"
              valueKey="dataKey"
              isClearable={false}
              isInline
              isSortedAlphabetically={false}
            />
          ),
        },
      ]}
    />
  );
};
