import { GetAllObjectValue } from "#utils/objectFormatter";
import {
  FilterPillbox,
  MultiDropdownInputWithSearch,
} from "@validereinc/common-components";
import difference from "lodash/difference";
import differenceBy from "lodash/differenceBy";
import React, { useEffect, useState } from "react";

const DEFAULT_FILTER_DROPDOWN = {
  facility: {
    label: "Facility",
    inputs: [],
    options: [],
    labelKey: "name",
  },
  productType: {
    label: "Product Type",
    inputs: [],
    options: [],
  },
  balance: {
    label: "Balances",
    inputs: [],
    options: [],
    labelKey: "name",
  },
};

const filterSources = (sources, filters) => {
  const { facility, productType, balance } = filters;

  const filteredSources = sources.filter((source) => {
    if (
      facility?.length > 0 &&
      facility.every((facility) => facility.id !== source.facility_id)
    ) {
      return false;
    }

    if (
      productType?.length > 0 &&
      productType.every((type) => type !== source.product_type)
    ) {
      return false;
    }

    if (
      balance?.length > 0 &&
      balance.every((balance) => balance.id !== source.associated_balance)
    ) {
      return false;
    }

    return true;
  });

  return filteredSources;
};

export function getAllFilterInputs(filters) {
  const filterPillbox = {};

  Object.keys(filters).map((key) => {
    const inputs = filters[key]?.inputs ?? [];
    if (inputs.length > 0) {
      filterPillbox[key] = inputs;
    }
  });

  return filterPillbox;
}

export function clearAllInputs(filterDropdowns) {
  const updatedFilterDropdowns = filterDropdowns;

  Object.keys(updatedFilterDropdowns).map((key) => {
    updatedFilterDropdowns[key].inputs = [];
  });

  return updatedFilterDropdowns;
}

const useFilterInputs = (sources, streams) => {
  const [filterDropdowns, setFilterDropdown] = useState({
    ...DEFAULT_FILTER_DROPDOWN,
  });

  useEffect(() => {
    if (streams.length > 0) {
      const { facility, productType } = { ...filterDropdowns };

      const facilityOptions = GetAllObjectValue(
        streams,
        "facility",
        "id"
      ).filter((option) => option);
      facility.options = facilityOptions;

      const productTypeOptions = GetAllObjectValue(
        streams,
        "product_type"
      ).filter((option) => option);
      productType.options = productTypeOptions;

      setFilterDropdown({
        ...filterDropdowns,
        facility,
        productType,
      });
    }
  }, [streams]);

  const onDropdownSelect = (selectedValue, key) => {
    const filterDropdown = { ...filterDropdowns[key] };
    filterDropdown.inputs = selectedValue;

    setFilterDropdown({ ...filterDropdowns, [key]: filterDropdown });
  };

  const clearAllFilters = () => {
    setFilterDropdown(clearAllInputs(filterDropdowns));
  };

  const onFilterClick = (filterObject) => {
    if (filterObject) {
      const { filterKey, name } = filterObject;
      const updatedFilterDropdown = { ...filterDropdowns[filterKey] };

      // If options are an object with the identifying property "filterKey"
      if (DEFAULT_FILTER_DROPDOWN[filterKey].filterKey) {
        updatedFilterDropdown.inputs = differenceBy(
          updatedFilterDropdown.inputs,
          [filterObject],
          "id"
        );
      } else {
        updatedFilterDropdown.inputs = difference(
          updatedFilterDropdown.inputs,
          [name]
        );
      }

      setFilterDropdown({
        ...filterDropdowns,
        [filterKey]: updatedFilterDropdown,
      });
    } else {
      clearAllFilters();
    }
  };

  const filterRow = (
    <div className="filterRow">
      {Object.keys(filterDropdowns).map((key) => {
        const { label, labelKey, options, inputs } = filterDropdowns[key];

        return (
          <MultiDropdownInputWithSearch
            key={key}
            label={label}
            labelKey={labelKey ? labelKey : null}
            dropdownKey={key}
            value={inputs}
            options={options}
            onChange={onDropdownSelect}
          />
        );
      })}
    </div>
  );

  const filterInputs = getAllFilterInputs(filterDropdowns);

  const filteredSources = filterSources(sources, filterInputs);

  const filterPillbox = (
    <FilterPillbox
      filterBy={filterInputs}
      onClearFilterClick={onFilterClick}
    />
  );

  return [filterRow, filterPillbox, filteredSources];
};

export default useFilterInputs;
