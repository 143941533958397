import {
  Button,
  Form,
  FormButton,
  Modal,
  Title,
  useForm,
} from "@validereinc/common-components";
import * as PropTypes from "prop-types";
import React from "react";
import SampleForm from "./SampleForm";

const EditSampleModal = ({
  show,
  onHide,
  sample,
  onUpdateSample,
  onRemoveSample,
}) => {
  const form = useForm({
    defaultValues: sample,
  });

  const onUpdateSampleClick = (inputs) => {
    onUpdateSample({
      ...inputs,
      sample_name:
        inputs.stream?.name ?? inputs.workflow_instance?.name ?? null,
      stream_id:
        inputs.stream?.id ?? inputs.workflow_instance?.stream_id ?? null,
      workflow_instance_id: inputs.workflow_instance?.id ?? null,
    });

    onHide();
  };

  const onRemoveSampleClick = () => {
    onRemoveSample();
    onHide();
  };

  const inputs = form.watch();

  return (
    <Modal
      open={show}
      onClose={onHide}
    >
      <Form
        onSubmit={onUpdateSampleClick}
        {...form}
      >
        <Modal.Header>
          <Title>Edit Sample Modal</Title>
        </Modal.Header>

        <Modal.Body>
          <SampleForm
            form={form}
            inputs={inputs}
          />
        </Modal.Body>

        <Modal.Footer>
          <Button
            className="pull-left"
            variant="error-outline"
            onClick={onRemoveSampleClick}
          >
            Remove Sample
          </Button>

          <FormButton
            type="submit"
            variant="primary"
          >
            Update Sample
          </FormButton>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

EditSampleModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  sample: PropTypes.object.isRequired,
  onUpdateSample: PropTypes.func.isRequired,
  onRemoveSample: PropTypes.func.isRequired,
};

export default EditSampleModal;
