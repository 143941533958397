import exceptionHandlerMiddleware from "#src/components/Redux/middleware/exceptionHandler";
import loggerMiddleware from "#src/components/Redux/middleware/logger";
import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunkMiddleware from "redux-thunk";
import { APPLICATION_INITIALIZED } from "../constants/action-types";
import rootReducer from "../reducers/index";

const middleware = [
  thunkMiddleware,
  loggerMiddleware,
  exceptionHandlerMiddleware,
];

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(...middleware))
);

store.dispatch({
  type: APPLICATION_INITIALIZED,
});

export default store;
