import NotesList from "#common/Notes/NotesList";
import ReactLoader from "#components/LoadingBar/ReactLoader";
import {
  TEST_NOTE,
  useFetchNotes,
  useSubmitNote,
} from "#services/NotesService";
import SampleService from "#services/SampleService";
import {
  Button,
  Form,
  FormButton,
  Modal,
  TextWithFileInput,
  useAlert,
  useForm,
} from "@validereinc/common-components";
import * as PropTypes from "prop-types";
import React, { useState } from "react";
import { AutoSizer } from "react-virtualized";
import "./TestNotes.scss";

const TestNotes = ({ testId, testState, refreshTest, onClose }) => {
  const [formState, setFormState] = useState("enabled");
  const { addAlert } = useAlert();

  const form = useForm({
    defaultValues: {
      note: {},
    },
  });

  const [areNotesLoading, _errorStatus, notes, fetchNotes] = useFetchNotes(
    testId,
    TEST_NOTE
  );

  const [_isSubmittingNote, submitNote] = useSubmitNote(testId, TEST_NOTE);

  const onSubmitNote = (inputs) => {
    setFormState("loading");

    return submitNote(inputs.note)
      .then((result) => {
        fetchNotes();
        refreshTest();

        return result;
      })
      .finally(() => {
        form.reset();
        setFormState("enabled");
      });
  };

  const onMarkError = () => {
    setFormState("loading");
    const note = form.getValues("note");

    return SampleService.markTestAsError(testId, note)
      .then((result) => {
        addAlert({
          variant: "success",
          message: "The test has been marked as an error.",
        });

        fetchNotes();
        refreshTest();
        return result;
      })
      .finally(() => {
        form.reset();
        setFormState("enabled");
      });
  };

  return (
    <Form
      onSubmit={onSubmitNote}
      {...form}
    >
      <Modal.Body className="testDetailModal__body">
        <div className="testNotes">
          <div className="testNotes__notesContainer">
            <AutoSizer disableWidth>
              {({ height }) => (
                <ReactLoader
                  loaded={!areNotesLoading}
                  position={50}
                >
                  <NotesList
                    list={notes}
                    height={height}
                  />
                </ReactLoader>
              )}
            </AutoSizer>
          </div>

          <TextWithFileInput
            name="note"
            label=""
            isRequired
            showIcon
            multiple
            isDisabled={formState !== "enabled"}
          />
        </div>
      </Modal.Body>

      <Modal.Footer className="testDetailModal__footer clearfix">
        <Button
          className="pull-left"
          onClick={onClose}
        >
          Close
        </Button>

        {testState !== "error" && (
          <Button
            variant="error-outline"
            isLoading={formState === "loading"}
            disabled={formState !== "enabled"}
            onClick={onMarkError}
          >
            Mark as Error
          </Button>
        )}

        <FormButton
          variant="primary"
          type="submit"
          isLoading={formState === "loading"}
          disabled={formState !== "enabled"}
        >
          Add Note
        </FormButton>
      </Modal.Footer>
    </Form>
  );
};

TestNotes.propTypes = {
  testId: PropTypes.string.isRequired,
  testState: PropTypes.string.isRequired,
  refreshTest: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default TestNotes;
