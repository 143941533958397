import { dateTimeRenderer } from "#common/Table/renderers";
import FieldManualEntryModal from "#components/ManualEntry/ManualEntryForms/Field/FieldManualEntryModal";
import useManualEntryModal from "#components/ManualEntry/ManualEntryForms/useManualEntryModal";
import history from "#routers/history";
import { linkToTestDetail } from "#routers/links";
import SampleService from "#services/SampleService";
import { Button, LegacyDataTable, Panel } from "@validereinc/common-components";
import classNames from "classnames/bind";
import * as PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { AutoSizer } from "react-virtualized";
import styles from "./Tests.module.scss";

const cx = classNames.bind(styles);
const getRowClassName = () => {
  return cx("capitalize");
};

const headers = [
  {
    label: "Instrument Name",
    key: "instrument.name",
    width: 150,
    disableSort: true,
  },
  {
    label: "Test Type",
    key: "type",
    width: 150,
    fixed: true,
    disableSort: true,
  },
  {
    label: "Test Date",
    key: "date",
    width: 135,
    cellRenderer: dateTimeRenderer,
    disableSort: true,
  },
  {
    label: "Tester",
    key: "tested_by",
    width: 150,
    disableSort: true,
  },
  {
    label: "Site",
    key: "instrument.site_name",
    width: 150,
    disableSort: true,
  },

  {
    label: "Test Status",
    key: "status",
    width: 150,
    rightAlign: true,
    disableSort: true,
  },
];

const ROW_HEIGHT = 55;

const onTestClick = (selectedTest) => {
  history.push(linkToTestDetail(selectedTest?.id));
};

const TestsPanel = ({ tests, selectedSample, refetchSample }) => {
  const [testList, setTestList] = useState([]);
  const [testsLoadingState, setTestsLoadingState] = useState("unloaded");
  const [show, showModal, onHide] = useManualEntryModal();

  useEffect(() => {
    if (tests) {
      const testIdRequests = tests.map((test) =>
        SampleService.getTestDetails(test.id)
      );

      Promise.all(testIdRequests).then((tests) => {
        setTestList(tests.map((test) => test.data));
        setTestsLoadingState("loaded");
      });
    }
  }, [tests]);

  return (
    <Panel
      title="Tests"
      actionRow={
        <Button
          variant="primary"
          onClick={showModal}
        >
          Add test
        </Button>
      }
      loaded={testsLoadingState === "loaded"}
    >
      <div
        className={cx("testsContainer")}
        style={{ height: ROW_HEIGHT * (testList.length + 1) }}
      >
        <AutoSizer>
          {({ width, height }) => (
            <LegacyDataTable
              width={width}
              height={height}
              headers={headers}
              list={testList}
              rowHeight={ROW_HEIGHT}
              getRowClassName={getRowClassName}
              onCellClick={onTestClick}
            />
          )}
        </AutoSizer>
      </div>
      <FieldManualEntryModal
        show={show}
        onHide={onHide}
        sampleInfo={selectedSample}
        refetchData={refetchSample}
      />
    </Panel>
  );
};

TestsPanel.propTypes = {
  tests: PropTypes.arrayOf(PropTypes.object),
  selectedSample: PropTypes.object,
  refetchSample: PropTypes.func,
};

export default TestsPanel;
