import { useAuthenticatedContext } from "#src/contexts/AuthenticatedContext.helpers";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  filterResponseToFilterConfig,
  FilterValue,
} from "@validereinc/common-components";
import {
  NodeAPISavedFilterType,
  ResourceType,
  SavedFilterServiceType,
  SavedFilterStatus,
  SavedFilterStatusType,
  SavedFilterType,
} from "@validereinc/domain";

// Tag is a text value that helps indicate how and where this saved filter is used.
export const SavedFilterTag = {
  LIST: "list",
  CUSTOM_REPORT: "custom_report",
  FACILITY_RECORDS: "facility",
  EQUIPMENT_RECORDS: "equipment",
  ASSET_GROUP_RECORDS: "asset-group",
  FLOW_RECORDS: "flow",
};

// TODO: deprecate this hook once all filters are moved over to the new
// filtering pattern (leveraging the newer saved filters hook below)
/**
 * Hook that provides utilities for <FilterPanel /> for saving and loading filters.
 *
 * @template TFilterType
 * @param {{
 *   queryKey: string[];
 *   filterKeyBlacklist?: string[];
 *   savedFilterResourceAdapter: SavedFilterServiceType<TFilterType>;
 *   setFilters: (input: TFilterType) => void;
 * }} param0
 * @param {SavedFilterServiceType<TFilterType>} param0.savedFilterResourceAdapter Recource's savedFilter adapter (e.g. RecordDomain.savedFilters)
 * @param {{}} [param0.filterKeyBlacklist=[]] A list of key names in the filter object that shouldn't be saved in the database
 * @param {{}} [param0.tag] A free text value that helps indicate how and where this saved filter is used
 * @param {(input: TFilterType) => void} param0.setFilters function that sets filter values of the <FilterPanel />
 * @param {{}} param0.queryKey queryKey used by React query for fetching saved filters from its API endpoint
 * @returns {void; }) => { isLoading: boolean; filterProps: { ...; }; }}
 */
export const useSavedFilters = <TFilterType,>({
  savedFilterResourceAdapter,
  filterKeyBlacklist = [],
  setFilters,
  queryKey,
  tag,
}: {
  queryKey: string[];
  filterKeyBlacklist?: string[];
  savedFilterResourceAdapter: SavedFilterServiceType<TFilterType>;
  setFilters: (input: TFilterType) => void;
  tag: (typeof SavedFilterTag)[keyof typeof SavedFilterTag];
}) => {
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["savedFilter", ...queryKey],
    queryFn: () =>
      savedFilterResourceAdapter.getList({
        filters: { tag: tag ?? SavedFilterTag.LIST },
      }),
  });

  const savedFilters = data?.data ?? [];

  const keysToRemove = ["savedFilter", "columnsToShow", ...filterKeyBlacklist];

  const onSaveFilter = async (
    name: string,
    { savedFilter: _, ...filter }: FilterValue
  ) => {
    keysToRemove.forEach((key) => delete filter[key]);
    const result = await savedFilterResourceAdapter.createOne({
      data: {
        name,
        filter,
        tag: tag ?? SavedFilterTag.LIST,
      },
    });

    refetch();

    // TODO: this mutates the filters object and adds a new property "savedFilter" which is not captured in the filter type. fix this.
    setFilters({
      savedFilter: result?.data?.id,
      ...filterResponseToFilterConfig(result?.data?.filter),
    });
  };

  const onEditFilter = async (id: string, { name }: FilterValue) => {
    await savedFilterResourceAdapter.updateOne({
      id,
      data: { name },
    });
    await refetch();
  };

  const onDeleteFilter = async (id: string) => {
    await savedFilterResourceAdapter.deleteOne({ id });
    await refetch();
  };

  return {
    isLoading,
    filterProps: {
      onSaveFilter,
      savedFilters,
      onEditFilter,
      onDeleteFilter,
    },
  };
};

export const useSavedFiltersV2 = ({
  resourceType,
  savedFilterResourceAdapter,
  tag,
  filterBlacklist,
}: {
  resourceType: ResourceType;
  savedFilterResourceAdapter: SavedFilterServiceType<NodeAPISavedFilterType>;
  tag: (typeof SavedFilterTag)[keyof typeof SavedFilterTag];
  filterBlacklist?: string[];
}) => {
  const queryClient = useQueryClient();
  const {
    v2: {
      userInfo: { user },
    },
  } = useAuthenticatedContext();
  const listQueryKey = [
    "savedFilters",
    {
      filters: {
        entity_type: resourceType,
        tag: tag ?? SavedFilterTag.LIST,
      },
      sortBy: "name",
      sortDirection: "asc",
    },
  ];
  const listQuery = useQuery({
    queryKey: listQueryKey,
    queryFn: () =>
      savedFilterResourceAdapter.getList({
        filters: {
          entity_type: resourceType,
          tag: tag ?? SavedFilterTag.LIST,
        },
        sortBy: "name",
        sortDirection: "asc",
      }),
  });

  const createMutation = useMutation({
    mutationFn: async ({
      name,
      status = "draft",
      filters,
    }: {
      name: string;
      status?: SavedFilterStatusType;
      filters: NodeAPISavedFilterType;
    }) => {
      const newFilters = Object.entries(filters).reduce<NodeAPISavedFilterType>(
        (dict, [key, value]) => {
          if ((filterBlacklist ?? []).includes(key)) {
            return dict;
          }

          dict[key] = value;
          return dict;
        },
        {}
      );

      const result = await savedFilterResourceAdapter.createOne({
        data: {
          name,
          status,
          filter: newFilters,
          tag: tag ?? SavedFilterTag.LIST,
        },
      });

      return result;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: listQueryKey,
      });
    },
  });

  const updateMutation = useMutation({
    mutationFn: ({
      id,
      name,
      status,
    }: { id: string } & Partial<{
      name: string;
      status: SavedFilterStatusType;
    }>) => {
      return savedFilterResourceAdapter.updateOne({
        id,
        data: { name, status },
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: listQueryKey,
      });
    },
  });

  const deleteMutation = useMutation({
    mutationFn: ({ id }: { id: string }) => {
      return savedFilterResourceAdapter.deleteOne({ id });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: listQueryKey,
      });
    },
  });

  const getSavedFiltersAvailable = (
    allSavedFilters: Array<SavedFilterType<NodeAPISavedFilterType>>
  ) =>
    allSavedFilters.filter(
      (f) => f.created_by === user?.id || f.status === SavedFilterStatus.ACTIVE
    );

  return {
    savedFiltersQuery: listQuery,
    saveFiltersMutation: createMutation,
    updateFiltersMutation: updateMutation,
    deleteFiltersMutation: deleteMutation,
    getSavedFiltersAvailable,
  };
};
