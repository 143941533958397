import { getEventSchemaId } from "#src/batteries-included-components/Drawers/Workflows/WorkflowTaskDetailDrawer/WorkflowTaskDetailDrawer.helpers";
import { RoutingLink } from "#src/batteries-included-components/RoutingLink";
import { linkToEventCategoryDetail } from "#src/routes/events/event-categories/[eventCategoryId]";
import { linkToEventTemplateDetail } from "#src/routes/events/event-categories/[eventCategoryId]/templates/[templateId]/details";
import { linkToEventDetails } from "#src/routes/events/event-categories/[eventCategoryId]/templates/[templateId]/events/[eventId]";
import { useQueries, useQuery } from "@tanstack/react-query";
import { Pill } from "@validereinc/common-components";
import {
  EventCategoryDomain,
  EventDetailsType,
  EventSchemaDomain,
  EventsDomain,
  UsersAdapter,
  WorkflowTaskType,
  WorkflowType,
} from "@validereinc/domain";
import { datetimeFormatter } from "@validereinc/utilities";
import classNames from "classnames/bind";
import startCase from "lodash/startCase";
import React from "react";
import styles from "./WorkflowTaskCompleteEvent.module.scss";

const cx = classNames.bind(styles);

export const WorkflowTaskCompleteEvent = ({
  workflowTask,
  workflow,
}: {
  workflowTask: WorkflowTaskType;
  workflow: WorkflowType;
}) => {
  const events = useQueries({
    queries:
      workflowTask?.emission_event?.map((event) => ({
        queryKey: ["events", event.id],
        queryFn: () => {
          if (!event.id) {
            return;
          }

          return EventsDomain.getOne({ eventId: event.id });
        },
        enabled: Boolean(event.id),
        staleTime: 3 * 60 * 1000,
      })) ?? [],
  });

  return (
    <>
      {events?.map(({ data }, idx) =>
        data ? (
          <EventDetails
            key={data.id}
            event={data}
            workflowTask={workflowTask}
            workflow={workflow}
          />
        ) : (
          <div
            className={cx("skeleton", "card")}
            key={idx}
          ></div>
        )
      ) ?? null}
    </>
  );
};

const EventDetails = ({
  event,
  workflowTask,
  workflow,
}: {
  event: EventDetailsType;
  workflowTask: WorkflowTaskType;
  workflow: WorkflowType;
}) => {
  const { eventSchemaId } = getEventSchemaId(workflowTask, workflow);

  const { data: eventSchema } = useQuery({
    queryKey: ["events", "schemas", eventSchemaId],
    queryFn: () => {
      if (!eventSchemaId) {
        return;
      }

      return EventSchemaDomain.getOne({ eventSchemaId: eventSchemaId });
    },
    enabled: Boolean(eventSchemaId),
    staleTime: 3 * 60 * 1000,
  });
  const { data: eventCategory } = useQuery(
    ["events", "categories", eventSchema?.event_category_id],
    () => {
      if (!eventSchema?.event_category_id) {
        return;
      }

      return EventCategoryDomain.getOne({
        eventCategoryId: eventSchema.event_category_id,
      });
    },
    {
      enabled: Boolean(eventSchema?.event_category_id),
      staleTime: 3 * 60 * 1000,
    }
  );
  const { data: createdByUser } = useQuery(
    ["users", event.created_by],
    () => {
      if (!event.created_by) return;
      return UsersAdapter.getOne({ id: event.created_by });
    },
    {
      enabled: Boolean(event.created_by),
      staleTime: 3 * 60 * 1000,
      select: (resp) => resp?.data,
    }
  );

  const data = [
    {
      label: "Event",
      value: eventSchema ? (
        <RoutingLink
          to={{
            pathname: linkToEventDetails(
              eventCategory?.id,
              eventSchema?.id,
              event.id
            ),
            search: `?workflowTaskId=${workflowTask.id}`,
          }}
        >
          {event.name}
        </RoutingLink>
      ) : (
        "-"
      ),
    },
    {
      label: "Description",
      value: event.description,
    },
    {
      label: "Status",
      value: (
        <Pill
          variant={
            {
              open: "warning",
              overdue: "error",
              dismissed: "default",
              resolved: "success",
            }[event.status]
          }
        >
          {startCase(event.status)}
        </Pill>
      ),
    },
    {
      label: "Template",
      value: eventSchema ? (
        <RoutingLink
          to={linkToEventTemplateDetail(
            eventSchema.event_category_id,
            eventSchema.id
          )}
        >
          {eventSchema.name}
        </RoutingLink>
      ) : (
        "-"
      ),
    },
    {
      label: "Category",
      value: eventCategory ? (
        <RoutingLink to={linkToEventCategoryDetail(eventCategory.id)}>
          {eventCategory.name}
        </RoutingLink>
      ) : (
        "-"
      ),
    },
    {
      label: "Created By",
      value: createdByUser?.name ?? "-",
    },
    {
      label: "Created At",
      value: datetimeFormatter(new Date(event.created_at)),
    },
  ];

  return (
    <div>
      <p
        className={cx("title")}
        id={`event-${event.id}`}
      >
        Event
      </p>
      <dl
        className={cx("list")}
        aria-describedby={`event-${event.id}`}
      >
        {data.map((item) => (
          <div
            key={item.label}
            className={cx("item")}
          >
            <dt className={cx("label")}>{item.label}</dt>
            <dd className={cx("value")}>{item.value}</dd>
          </div>
        ))}
      </dl>
    </div>
  );
};
