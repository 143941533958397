import {
  DEFAULT_QUERY_OPTIONS,
  UseQueryOptionsType,
} from "#hooks/adapters/adapterUtils";
import { downloadLink } from "#utils/download";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useAlert } from "@validereinc/common-components";
import { ReportAdapter } from "@validereinc/domain";

export const REPORTS_QUERY_KEY = "reports";

export const useGetOneReport = (
  params: Parameters<typeof ReportAdapter.getOne>[0],
  options: UseQueryOptionsType = {}
) =>
  useQuery({
    queryKey: [REPORTS_QUERY_KEY, "getOne", params] as const,
    queryFn: ({ queryKey: [_, __, params] }) => ReportAdapter.getOne(params),
    enabled: !!params?.id,
    ...DEFAULT_QUERY_OPTIONS,
    ...options,
  });

export const useListReports = (
  params: Parameters<typeof ReportAdapter.getList>[0],
  options: UseQueryOptionsType = {}
) =>
  useQuery({
    queryKey: [REPORTS_QUERY_KEY, "getList", params] as const,
    queryFn: ({ queryKey: [_, __, params] }) => ReportAdapter.getList(params),
    ...DEFAULT_QUERY_OPTIONS,
    ...options,
  });

export const useDownloadReport = () => {
  const { addAlert } = useAlert();

  return useMutation({
    mutationFn: async ({ id }: { id: string }) => {
      const { data } = await ReportAdapter.getOne({ id });
      downloadLink(data?.s3_download_link, data?.name);
    },
    onError: (error) => {
      console.error(error);
      addAlert?.({
        variant: "error",
        message: "Unable to download report",
      });
    },
  });
};

export const useClearReportsCache = () => {
  const queryClient = useQueryClient();
  return {
    invalidate: () => {
      queryClient.invalidateQueries({
        queryKey: [REPORTS_QUERY_KEY],
      });
    },
  };
};
