import { useListLibrary } from "#components/hooks/useLibrary";
import { RoutingLink } from "#src/batteries-included-components/RoutingLink/RoutingLink";
import { useTableSortingAndPagination } from "#src/components/Redux/reducers/tableStateReducer";
import { useStorageKey } from "#src/hooks/useStorageKey";
import { linkToEstimationMethodDetailPage } from "#utils/links";
import { useQuery } from "@tanstack/react-query";
import {
  Button,
  DataTablePanel,
  DropdownInput,
  FilterPanel,
  HeaderType,
  SortingType,
  useFilters,
} from "@validereinc/common-components";
import type { AssetTypeType, EstimationMethodType } from "@validereinc/domain";
import { EstimationMethodDomain, SortDirection } from "@validereinc/domain";
import React from "react";

const ESTIMATION_METHOD_MODEL_KEYS = {
  Name: "name",
  Library: "analytics_library_id",
  "Reporting Group": "reporting_groups",
} as const;

const sorting: SortingType = {
  sortBy: ESTIMATION_METHOD_MODEL_KEYS.Name,
  sortDirection: SortDirection.ASCENDING,
};

const EstimationMethodsTab = ({
  entityId,
  entityType,
  onAdd,
}: EstimationMethodsTabProps) => {
  const storageKeys = useStorageKey(`${entityType}-estimation-methods`);
  const [filters] = useFilters<
    Record<
      (typeof ESTIMATION_METHOD_MODEL_KEYS)[keyof typeof ESTIMATION_METHOD_MODEL_KEYS],
      string
    >
  >(storageKeys.filterConfigStorageKey);
  const [tableState, setTableState] = useTableSortingAndPagination(
    sorting,
    filters
  );

  const reqParams = {
    page: tableState.page,
    pageSize: tableState.itemsPerPage,
    sortBy: tableState.sortBy,
    sortDirection: tableState.sortDirection,
    filters: {
      entity_id: entityId,
      entity_type: entityType,
      ...filters,
    },
  };

  const { data: estimationMethods, isLoading } = useQuery(
    ["estimation_methods", reqParams],
    () => EstimationMethodDomain.getList(reqParams)
  );

  const addEstimationMethod = () => {
    onAdd();
  };

  const tableHeaders: Array<HeaderType<EstimationMethodType>> = [
    {
      label: "Name",
      key: ESTIMATION_METHOD_MODEL_KEYS.Name,
      isSortable: true,
      renderComponent: ({ item }) => (
        <RoutingLink
          to={linkToEstimationMethodDetailPage(
            item.entity_type,
            item.entity_id,
            item.id
          )}
        >
          {item.name}
        </RoutingLink>
      ),
    },
    {
      label: "Reporting Scenario",
      key: ESTIMATION_METHOD_MODEL_KEYS["Reporting Group"],
      isSortable: false,
      renderComponent: ({
        item: { reporting_groups: reportingGroups },
      }: {
        item: EstimationMethodType;
      }) => reportingGroups?.map(({ name }) => name)?.join(", ") || "-",
    },
    {
      label: "Library",
      key: ESTIMATION_METHOD_MODEL_KEYS.Library,
      isSortable: true,
    },
  ];

  const [libraries] = useListLibrary();

  // computed
  const libraryNames =
    libraries?.children?.map((library) => library.name) ?? [];

  const tableActions = (
    <Button onClick={addEstimationMethod}>Add Estimation Method</Button>
  );

  return (
    <>
      <FilterPanel
        storageKey={storageKeys.filterConfigStorageKey}
        defaultActiveKeys={["Filters"]}
        filters={[
          {
            section: "Filters",
            component: (
              <DropdownInput
                label="Library"
                name={ESTIMATION_METHOD_MODEL_KEYS.Library}
                options={libraryNames}
                isFluid
                isSearchable
                isOptionalTextShown={false}
              />
            ),
          },
        ]}
      />
      <DataTablePanel
        storageKey={storageKeys.tableConfigStorageKey}
        panelProps={{
          title: "Estimation Methods",
          actionRow: tableActions,
        }}
        dataTableProps={{
          items: estimationMethods?.data ?? [],
          headers: tableHeaders,
          isLoading,
          sorting,
          pagination: {
            page: tableState.page,
            itemsPerPage: tableState.itemsPerPage,
            total: tableState.total,
            entityPerPage: tableState.entityPerPage,
          },
          onSortChange: setTableState,
          onPaginationChange: setTableState,
        }}
      />
    </>
  );
};

export type EstimationMethodsTabProps = {
  entityId: string;
  entityType: AssetTypeType;
  onAdd: () => void;
  onRowClick: (args: { item: EstimationMethodType }) => void;
};

export default EstimationMethodsTab;
