import { Button, Footer, Stepper } from "@validereinc/common-components";
import React, {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useState,
} from "react";

// IMPROVE: merge the best parts of this hook and the best parts of the
// useWizardContext together. This hook was made in parallel to the original so
// as to make tweaks and improvements in isolation and discuss them side by side
// as a team  and was heavily inspired by the original from @Sean which greatly
// simplified working with multi-step forms. Preserve the derived states, the
// name, the simplified state management (without unnecessary reduce), the
// convenience hook to use the context, the Provider component with the
// defaults, the ability for steps to run sync/async work during their submit
// handling, and the ability to hook into the most recent formState to be able
// to show errors, submitting state and so on in each step (and the stepper,
// footer etc.) Also bring in features from the other hook such as order
// specification, directly loading in the form object from react-hook-form which
// abstracted away a lot of the complexities for you, and merging all forms'
// values together in getValues(). This will also help us get rid of setValues()
// and associated properties on a step. (not needed as you
// can access form.getValues() directly)
export type MultiStepFormContextType<
  T extends Record<string, string | number | string[] | boolean> = Record<
    string,
    string | number | string[] | boolean
  >,
> = {
  /** the current step, 1-indexed */
  currentStep: number;
  /** is the step # on the last step in steps? */
  isLastStep: boolean;
  /** is the step # on the first step in steps? */
  isFirstStep: boolean;
  /** all the steps in the multistep form */
  steps: MultiStepFormStepType[];
  /** set the form values for a particular step, 1-indexed */
  setValues: (values: T, targetStep: number) => void;
  /** get the set form values across all steps */
  getValues: () => T[];
  /** the true form values across every step (i.e. the values that were not explicitly set with setValues()) */
  values: T[];
  /** get the details of a step, 1-indexed */
  getStep: (stepToGet: number) => MultiStepFormStepType | undefined;
  /** add a new step */
  addStep: (step: MultiStepFormStepType) => void;
  /** remove a step, 1-indexed */
  removeStep: (stepToRemove: number) => void;
  /** go to a specific step, 1-indexed */
  goToStep: (targetStep: number) => void;
  /** go to the next step if possible. Triggers the async onSubmit handler of that step, if specified. */
  goNext: () => Promise<void>;
  /** go to the previous step if possible */
  goPrevious: () => void;
  /** modify details of an existing step, 1-indexed */
  updateStep: (
    targetStep: number,
    stepProperties: Partial<Omit<MultiStepFormStepType, "defaultValues">>
  ) => void;
  /** are any of the forms of each step in a submitting state? or is the
   * overall form being submitted? (the onSubmit handler passed into the
   * {@link MultiStepFormProvider} being called or the mutation state passed
   * into {@link MultiStepFormProvider} signifying a submitting state) */
  isSubmitting: boolean;
  /** are all the forms of each step valid? */
  isValid: boolean;
  /** convenience component for the actionRow of the page hosting the form */
  stepper: ReactNode;
  /** convenience component for the footer of the page hosting the form */
  footer: ReactNode;
};

export type MultiStepFormStepType = {
  label: string;
  defaultValues?: Record<string, string | number | string[] | boolean>;
  values?: Record<string, string | number | string[] | boolean>;
  getValues: () => Record<string, string | number | string[] | boolean>;
  getSubmitHandler: (
    setValues: (
      values: Record<string, string | number | string[] | boolean>
    ) => void
  ) => () => Promise<void>;
  getFormState: () => {
    isSubmitting: boolean;
    isValid: boolean;
  };
};

const MultiStepFormContext = createContext<MultiStepFormContextType | null>(
  null
);

/**
 * Access the values within {@link MultiStepFormContext} provided by {@link MultiStepFormProvider}
 * @returns the multi-step form context
 */
export const useMultiStepFormContext = () => {
  const ctx = useContext(MultiStepFormContext);

  if (!ctx) {
    throw new Error("Must be used within a <MultiStepFormProvider />");
  }

  return ctx;
};

const isWithinStepsBounds = (
  steps: MultiStepFormStepType[],
  targetStepNumber?: number
): targetStepNumber is number =>
  typeof targetStepNumber === "number"
    ? targetStepNumber <= steps.length && targetStepNumber >= 1
    : false;
const clampStepNumber = (
  stepNumber: number,
  maxStepNumber: number,
  minStepNumber = 1
) => Math.min(Math.max(stepNumber, minStepNumber), maxStepNumber);

/**
 * Use in tandem with {@link useMultiStepFormContext} to manage a multi-step
 * form with forms in each step and aggregate all the values across all forms
 * for submission at the end.
 *
 * Provide a step configuration if you know the steps ahead of time. Starts at the first step,
 * unless specified. Then optionally provide an onSubmit handler to process the
 * final submission on the last step and an associated onSubmitMutationState to
 * communicate the mutation state to the context.
 * @returns the context provider
 */
export const MultiStepFormProvider = ({
  children,
  steps: initialSteps,
  currentStep: initialCurrentStep,
  onSubmit,
  onSubmitMutationState = {
    isSubmitting: false,
  },
  submissionActionText = "Submit",
  onCancel,
}: {
  children?: ReactNode;
  /** triggered when the last step of the form is submitted (after triggering
   * the last form's, onSubmitHandler as well). You can return a Promise or just
   * run the handler. Returning a Promise lets the context manage an
   * isSubmitting state. Otherwise, you have to pass in your own through
   * onSubmitMutationState. */
  onSubmit?:
    | ((values: MultiStepFormContextType["values"]) => Promise<void>)
    | ((values: MultiStepFormContextType["values"]) => void);
  /** contains the state toggles for the mutation likely occuring in the
   * onSubmit handler that's been passed in */
  onSubmitMutationState?: {
    isSubmitting: boolean;
  };
  /** label to put on the save button that appears in the footer */
  submissionActionText?: string;
  /** handler for the cancel button onClick in the footer*/
  onCancel?: () => void;
} & Partial<
  Pick<MultiStepFormContextType, "currentStep"> & {
    steps: Array<Pick<MultiStepFormStepType, "label" | "defaultValues">>;
  }
>) => {
  const [currentStep, setCurrentStep] = useState(initialCurrentStep ?? 1);
  const [steps, setSteps] = useState(
    initialSteps?.map<MultiStepFormStepType>((step) => ({
      getSubmitHandler: () => () => Promise.resolve(),
      getValues: () => ({}),
      getFormState: () => ({
        isSubmitting: false,
        isValid: true,
      }),
      ...step,
    })) ?? []
  );
  const [internalIsSubmitting, setInternalIsSubmitting] =
    useState<boolean>(false);

  const getStep: MultiStepFormContextType["getStep"] = (stepToGet) => {
    if (!isWithinStepsBounds(steps, stepToGet)) return;

    return steps[stepToGet - 1];
  };

  const getValues: MultiStepFormContextType["getValues"] = () =>
    steps.map((step) => step.values ?? step.getValues() ?? {});
  const setValues: MultiStepFormContextType["setValues"] = (
    values,
    stepNumber
  ) =>
    setSteps((prevSteps) =>
      prevSteps.map((step, idx) => {
        if (idx + 1 !== stepNumber) {
          return step;
        }

        return {
          ...step,
          values,
        };
      })
    );

  const triggerStepSubmitHandler = (oldStepNumber: number): Promise<void> => {
    const stepDetails = steps[oldStepNumber - 1];

    if (!stepDetails.getSubmitHandler) {
      return Promise.resolve();
    }

    return stepDetails.getSubmitHandler((values) =>
      setValues(values, oldStepNumber)
    )();
  };

  const updateStep: MultiStepFormContextType["updateStep"] = (
    targetStep,
    stepProperties
  ) => {
    if (!isWithinStepsBounds(steps, targetStep)) {
      return;
    }

    setSteps((prevSteps) => {
      return prevSteps.map((step, idx) => {
        if (idx + 1 !== targetStep) {
          return step;
        }

        return {
          ...step,
          ...stepProperties,
        };
      });
    });
  };

  const goToStep: MultiStepFormContextType["goToStep"] = (targetStep) =>
    setCurrentStep(clampStepNumber(targetStep, steps.length, 1));
  const goNext: MultiStepFormContextType["goNext"] = () => {
    // going next on the final step, is a final submission on the multi-step
    // form as well so trigger the overall submit handler...
    if (currentStep + 1 > steps.length) {
      setInternalIsSubmitting(true);

      return triggerStepSubmitHandler(currentStep)
        .then(() => onSubmit?.(getValues()))
        .finally(() => setInternalIsSubmitting(false));
    }

    // on every other step, just navigate to the next step
    return triggerStepSubmitHandler(currentStep).then(() =>
      goToStep(currentStep + 1)
    );
  };
  const goPrevious: MultiStepFormContextType["goPrevious"] = () =>
    goToStep(currentStep - 1);

  const addStep: MultiStepFormContextType["addStep"] = (stepDetails) =>
    setSteps((prevSteps) => [...prevSteps, stepDetails]);
  const removeStep: MultiStepFormContextType["removeStep"] = (stepToRemove) => {
    if (!isWithinStepsBounds(steps, stepToRemove)) return;

    if (stepToRemove === currentStep) {
      goPrevious();
    }

    setSteps((prevSteps) => {
      return prevSteps.slice().splice(stepToRemove - 1, 1);
    });
  };

  const values = steps.map((step) => step.values ?? {});
  const isFirstStep = currentStep === 1;
  const isLastStep = currentStep === steps.length;
  const isValid = steps.every((step) => step.getFormState().isValid);
  const isDisabled = isLastStep
    ? !isValid
    : !steps[currentStep - 1]?.getFormState().isValid;
  const isSubmitting =
    onSubmitMutationState.isSubmitting ||
    internalIsSubmitting ||
    steps.some((step) => step.getFormState().isSubmitting);

  const stepper = (
    <Stepper
      steps={steps}
      activeStep={currentStep}
    />
  );

  const footer = (
    <Footer>
      <Button onClick={onCancel}>Cancel</Button>
      <div
        style={{
          flex: 1,
          display: "flex",
          gap: "16px",
          justifyContent: "flex-end",
        }}
      >
        {!isFirstStep ? <Button onClick={goPrevious}>Previous</Button> : null}

        <Button
          variant="primary"
          onClick={goNext}
          isLoading={isSubmitting}
          disabled={isDisabled}
        >
          {!isLastStep ? "Next" : submissionActionText}
        </Button>
      </div>
    </Footer>
  );

  return (
    <MultiStepFormContext.Provider
      value={{
        currentStep,
        steps,
        goToStep,
        goNext,
        goPrevious,
        addStep,
        removeStep,
        getStep,
        setValues,
        getValues,
        isLastStep,
        isFirstStep,
        updateStep,
        values,
        isSubmitting,
        isValid,
        stepper,
        footer,
      }}
    >
      {children}
    </MultiStepFormContext.Provider>
  );
};

/** Helper to convert a useForm return to the formState required by this hook */
// TODO: Import UseFormReturn type from transitive react-hook-form dependency
export const useMemoizedFormState = (form: any) =>
  useCallback(
    () => ({
      isSubmitting: form?.formState?.isSubmitting,
      isValid: form?.formState?.isValid,
    }),
    [form?.formState?.isSubmitting, form?.formState?.isValid]
  );
