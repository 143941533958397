import { linkToCreateRoleSetting } from "#routers/links";
import RoleService from "#services/RoleService";
import { ReactLoader } from "@validereinc/common-components";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import FontAwesome from "react-fontawesome";
import { Link } from "react-router-dom";
import SettingSection from "../../SettingSection";
import "./Role.css";
import RoleTable from "./RoleTable";

const Role = ({ height }) => {
  const [requestState, setRequestState] = useState("loading");
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    RoleService.getRoles().then(({ data }) => {
      setRequestState("loaded");
      setRoles(data);
    });
  }, []);

  return (
    <ReactLoader loaded={requestState === "loaded"}>
      <div className="role">
        <SettingSection title="Create new role">
          <Link
            className="role__createRoleButton"
            to={linkToCreateRoleSetting()}
          >
            <FontAwesome
              name="plus"
              className="icon"
            />
            Create New Role
          </Link>
        </SettingSection>

        <SettingSection title="Edit role">
          <RoleTable
            availableRoles={roles}
            height={height}
          />
        </SettingSection>
      </div>
    </ReactLoader>
  );
};

Role.propTypes = {
  height: PropTypes.number.isRequired,
};

export default Role;
