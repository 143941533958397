import { AuthenticationLoadingLayout } from "#src/batteries-included-components/Layouts/Authentication/AuthenticationLoading";
import { Brandmark } from "#src/components/Presentational/Logos/Brandmark";
import { LoadingState } from "@validereinc/common-components";
import React from "react";

export const LoggingOutLayout = () => {
  return (
    <AuthenticationLoadingLayout>
      <LoadingState
        label="Logging out..."
        size="large"
        indicator={<Brandmark isAnimated />}
      />
    </AuthenticationLoadingLayout>
  );
};
