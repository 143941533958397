export * from "./FacilityRecordValueDetailPage";

export const FACILITY_RECORD_VALUE_DETAIL_PAGE_TITLE = "Record Value Details";

export const linkToFacilityRecordValueDetail = (
  facilityId = ":facilityId",
  recordId = ":recordId",
  measurementType = ":measurementType"
) =>
  `/app/organization/facilities/${facilityId}/record/${recordId}/value/${measurementType}`;

export const FACILITY_RECORD_VALUE_DETAIL_BREADCRUMB = {
  title: `${FACILITY_RECORD_VALUE_DETAIL_PAGE_TITLE}:facilityId:recordId:measurementType`,
  path: linkToFacilityRecordValueDetail,
};

export type FacilityRecordValuePageParamsType = {
  facilityId: string;
  recordId: string;
  measurementType: string;
};
