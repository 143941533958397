import {
  AssertIsBeforeDate,
  AssertIsBeforeOrEqualDate,
  AssertIsBetweenDate,
} from "#utils/assert";
import { isSampleData } from "../../RecordOfQualityHelper";

export const getRoQData = (selectionData, accountingPeriod) => {
  const { from } = accountingPeriod;

  return selectionData
    .filter((data) => data.type === "record_of_quality")
    .filter((data) => AssertIsBeforeOrEqualDate(data.until, from))
    .sort((a, b) => (AssertIsBeforeDate(a.until, b.until) ? -1 : 1));
};

export const getSampleData = (selectionData, accountingPeriod) => {
  const { from, until } = accountingPeriod;

  return selectionData
    .filter((data) => isSampleData(data))
    .filter((data) => AssertIsBetweenDate(data.until, from, until))
    .filter((data) => data.state === "validated")
    .sort((a, b) => (AssertIsBeforeDate(a.until, b.until) ? -1 : 1));
};
