import { RootRoute } from "#src/routes/root";
import { RoutePath } from "#src/utils/route";

export const SettingsRoutePath = RootRoute.concat(
  new RoutePath({
    path: "/settings",
    title: "Settings",
    isPresentational: true,
  })
);

/** @deprecated */
export const SETTINGS_BREADCRUMB = SettingsRoutePath.toBreadcrumb();
