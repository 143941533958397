import React from "react";

import * as PropTypes from "prop-types";

import { Panel } from "@validereinc/common-components";

import AlertsList from "../TestDetailModal/TestAnalysis/AlertsList";

const InstrumentWarningPanel = ({ metadata, labels }) => {
  return (
    <Panel title="Instrument Warnings">
      <AlertsList
        metadata={metadata}
        labels={labels}
      />
    </Panel>
  );
};

InstrumentWarningPanel.propTypes = {
  metadata: PropTypes.object.isRequired,
  labels: PropTypes.array.isRequired,
};

export default InstrumentWarningPanel;
