import { EQUIPMENT_STATUS_OPTIONS, FACILITY_STATUS_OPTIONS } from "#constants";
import { useCustomAttributeFilters } from "#hooks/FilterPanel/useCustomAttributeFilters";
import { useListEquipmentTypes } from "#hooks/adapters/useEquipment";
import { EQUIPMENT_ATTRIBUTES } from "#hooks/tables/useEquipmentHeaders";
import {
  EquipmentDropdownInput,
  FacilityDropdownInput,
} from "#src/batteries-included-components";
import useLocalization from "#src/hooks/useLocalization";
import {
  DateSelectorInput,
  DropdownInput,
  TextInput,
} from "@validereinc/common-components";
import { AssetType } from "@validereinc/domain";
import React from "react";

/** Get the FilterPanel Filters for querying the Equipment Search API */
export const useEquipmentFilters = ({
  isWithCustomAttributes = false,
  isWithPeriod = false,
}: {
  isWithCustomAttributes?: boolean;
  isWithPeriod?: boolean;
} = {}) => {
  const { localize } = useLocalization();
  const { customAttributeFilters } = useCustomAttributeFilters({
    assetType: AssetType.EQUIPMENT,
    enabled: isWithCustomAttributes,
  });

  const equipmentTypes = useListEquipmentTypes().data ?? [];

  const periodFilter = {
    component: (
      <DateSelectorInput
        key={"period"}
        name={"period"}
        variant="month"
        isInline
      />
    ),
  };

  return [
    {
      component: (
        <TextInput
          key={EQUIPMENT_ATTRIBUTES.NAME.key}
          name={EQUIPMENT_ATTRIBUTES.NAME.key}
          placeholder={`Search ${localize("equipment_plural")}...`}
          type="search"
          isInline
        />
      ),
    },
    ...(isWithPeriod ? [periodFilter] : []),
    {
      section: "Filters",
      component: (
        <EquipmentDropdownInput
          key={EQUIPMENT_ATTRIBUTES.ID.key}
          name={EQUIPMENT_ATTRIBUTES.NAME.key}
          isMulti
          isFluid
          isOptionalTextShown={false}
        />
      ),
    },
    {
      section: "Filters",
      component: (
        <DropdownInput
          key={EQUIPMENT_ATTRIBUTES.TYPE.key}
          name={EQUIPMENT_ATTRIBUTES.TYPE.key}
          label={EQUIPMENT_ATTRIBUTES.TYPE.label}
          options={equipmentTypes}
          labelKey="name"
          valueKey="id"
          isMulti
          isFluid
          isSearchable
          isOptionalTextShown={false}
        />
      ),
    },
    {
      section: "Filters",
      component: (
        <DropdownInput
          key={EQUIPMENT_ATTRIBUTES.STATUS.key}
          name={EQUIPMENT_ATTRIBUTES.STATUS.key}
          label={EQUIPMENT_ATTRIBUTES.STATUS.label}
          options={EQUIPMENT_STATUS_OPTIONS}
          labelKey="label"
          valueKey="value"
          isMulti
          isFluid
          isSearchable
          isOptionalTextShown={false}
        />
      ),
    },
    ...(isWithCustomAttributes ? customAttributeFilters : []),
  ];
};

export const EQUIPMENT_FILTERS = {
  equipmentName: {
    name: "equipment_id",
    label: "Name",
  },
  equipmentType: {
    name: "equipment_type_id",
    label: "Type",
  },
  equipmentStatus: {
    name: "equipment_status",
    label: "Status",
  },
  facilityName: {
    name: "facility_id",
    label: "Name",
  },
  facilityStatus: {
    name: "facility_status",
    label: "Status",
  },
};

/** Equipment filters for querying the Calculator Configuration Search API */
export const useEquipmentCalculationFilters = () => {
  const { localize } = useLocalization();

  const localizedEquipmentLabel = localize("Equipment");
  const localizedFacilityLabel = localize("Facility");

  const listEquipmentTypesQuery = useListEquipmentTypes();
  const equipmentTypes = listEquipmentTypesQuery.data ?? [];

  const { customAttributeFilters: facilityCustomAttributeFilters } =
    useCustomAttributeFilters({
      assetType: AssetType.FACILITY,
      section: localizedFacilityLabel,
      prefix: "equipment.facility.custom_attributes",
    });

  const { customAttributeFilters: equipmentCustomAttributeFilters } =
    useCustomAttributeFilters({
      assetType: AssetType.EQUIPMENT,
      section: localizedEquipmentLabel,
      prefix: "equipment.custom_attributes",
    });

  return {
    filters: [
      {
        section: localizedEquipmentLabel,
        component: (
          <EquipmentDropdownInput
            key={EQUIPMENT_FILTERS.equipmentName.name}
            name={EQUIPMENT_FILTERS.equipmentName.name}
            label={EQUIPMENT_FILTERS.equipmentName.label}
            isMulti
            isFluid
            isOptionalTextShown={false}
          />
        ),
      },
      {
        section: localizedEquipmentLabel,
        component: (
          <DropdownInput
            key={EQUIPMENT_FILTERS.equipmentType.name}
            name={EQUIPMENT_FILTERS.equipmentType.name}
            label={EQUIPMENT_FILTERS.equipmentType.label}
            options={equipmentTypes}
            labelKey="name"
            valueKey="id"
            isMulti
            isFluid
            isSearchable
            isOptionalTextShown={false}
          />
        ),
      },
      {
        section: localizedEquipmentLabel,
        component: (
          <DropdownInput
            key={EQUIPMENT_FILTERS.equipmentStatus.name}
            name={EQUIPMENT_FILTERS.equipmentStatus.name}
            label={EQUIPMENT_FILTERS.equipmentStatus.label}
            options={FACILITY_STATUS_OPTIONS}
            labelKey="label"
            valueKey="value"
            isMulti
            isFluid
            isSearchable
            isOptionalTextShown={false}
          />
        ),
      },
      {
        section: localizedFacilityLabel,
        component: (
          <FacilityDropdownInput
            key={EQUIPMENT_FILTERS.facilityName.name}
            name={EQUIPMENT_FILTERS.facilityName.name}
            label={EQUIPMENT_FILTERS.facilityName.label}
            isMulti
            isFluid
            isOptionalTextShown={false}
          />
        ),
      },
      {
        section: localizedFacilityLabel,
        component: (
          <DropdownInput
            key={EQUIPMENT_FILTERS.facilityStatus.name}
            name={EQUIPMENT_FILTERS.facilityStatus.name}
            label={EQUIPMENT_FILTERS.facilityStatus.label}
            options={FACILITY_STATUS_OPTIONS}
            labelKey="label"
            valueKey="value"
            isMulti
            isFluid
            isSearchable
            isOptionalTextShown={false}
          />
        ),
      },
      ...(facilityCustomAttributeFilters ?? []),
      ...(equipmentCustomAttributeFilters ?? []),
    ],
    filterKeys: [localizedEquipmentLabel, localizedFacilityLabel],
  };
};
