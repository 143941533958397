import classNames from "classnames/bind";
import React, { ReactNode } from "react";
import styles from "./AuthenticationLoading.module.css";

const cx = classNames.bind(styles);

export const AuthenticationLoadingLayout = ({
  children,
}: {
  children: ReactNode;
}) => {
  return <div className={cx("container")}>{children}</div>;
};
