import {
  SummaryInformation,
  SummaryInformationContainer,
} from "#components/Common/SummaryInformation";
import { useDataMapping } from "#components/hooks/useDataMapping";
import useDataMappingTaskStatus from "#components/hooks/useDataMappingTaskStatus";
import { getBreadcrumbsObject } from "#routers/breadcrumbsHelper";
import { useNavigate } from "#routers/hooks";
import { linkToDataMappingDetail } from "#routers/links";
import config from "#src/config";
import { getTimeStringFromDate } from "#utils/timeFormatter";
import { Page, Panel } from "@validereinc/common-components";
import * as PropTypes from "prop-types";
import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import TaskPreviewTable from "./TaskPreviewTable";

const DataMappingUploadDetail = ({ breadcrumbs }) => {
  const { id, taskId } = useParams();
  const navigate = useNavigate();

  const [dataMappingDetail, dataMappingDetailLoadingState] = useDataMapping(id);
  const [taskStatus] = useDataMappingTaskStatus(taskId);

  const taskDate = useMemo(() => {
    if (taskStatus?.date_created) {
      return getTimeStringFromDate(
        taskStatus?.date_created,
        `${config.DATEMONTH_FORMAT} ${config.TIME_MINUTE_FORMAT}`
      );
    }
  }, [taskStatus?.date_created]);

  const dataMappingUploadDetailBreadcrumbs = useMemo(() => {
    const newBreadcrumbs = getBreadcrumbsObject(breadcrumbs);
    if (dataMappingDetail?.mapping_name) {
      newBreadcrumbs[newBreadcrumbs.length - 2] = {
        title: dataMappingDetail.mapping_name,
        onClick: () => navigate({ pathname: linkToDataMappingDetail(id) }),
      };
    }

    if (taskDate) {
      newBreadcrumbs[newBreadcrumbs.length - 1].title = taskDate;
    }

    return newBreadcrumbs;
  }, [breadcrumbs, dataMappingDetail, taskDate]);

  return (
    <Page
      title={getTimeStringFromDate(
        taskStatus?.date_created,
        `${config.DATEMONTH_FORMAT} ${config.TIME_MINUTE_FORMAT}`
      )}
      category="Upload Event"
      breadcrumbs={dataMappingUploadDetailBreadcrumbs}
    >
      <Panel title="Validation Overview">
        <SummaryInformationContainer>
          <SummaryInformation
            title="File"
            value={taskStatus?.file_path?.split("/")?.pop()}
            type="vertical"
            isLoading={dataMappingDetailLoadingState !== "loaded"}
          />
          <SummaryInformation
            title="Uploaded By"
            value={taskStatus?.uploaded_by}
            type="vertical"
            isLoading={dataMappingDetailLoadingState !== "loaded"}
          />
          <SummaryInformation
            title="Rows Created"
            value={taskStatus?.apply_summary?.rows_created}
            type="vertical"
            isLoading={dataMappingDetailLoadingState !== "loaded"}
          />
          <SummaryInformation
            title="Rows Skipped"
            value={taskStatus?.apply_summary?.rows_skipped}
            type="vertical"
            isLoading={dataMappingDetailLoadingState !== "loaded"}
          />
        </SummaryInformationContainer>
      </Panel>

      <TaskPreviewTable
        status={taskStatus}
        title="Preview"
      />
    </Page>
  );
};

DataMappingUploadDetail.propTypes = {
  breadcrumbs: PropTypes.array.isRequired,
};

export default DataMappingUploadDetail;
