import {
  LegacyDataTable,
  Panel,
  Tooltip,
} from "@validereinc/common-components";
import * as PropTypes from "prop-types";
import React from "react";
import FontAwesome from "react-fontawesome";
import { AutoSizer } from "react-virtualized";
import "./SiteTable.scss";

const SiteTable = ({ height, sites, onOpenSiteSetting }) => {
  const referenceTemperaturesRenderer = (rowData, columnKey) => {
    const tooltip = (
      <>
        {Object.keys(rowData[columnKey]).map((property) => (
          <div
            className="referenceTemperaturesTooltip"
            key={property}
          >
            <div>
              <b>{property}:</b> {rowData[columnKey]?.[property]?.value ?? "-"}
            </div>
          </div>
        ))}
      </>
    );

    return (
      <Tooltip content={tooltip}>
        <div>
          Reference Temperatures{" "}
          <FontAwesome
            name="info-circle"
            className="icon"
          />
        </div>
      </Tooltip>
    );
  };

  const Headers = [
    {
      label: "Name",
      key: "name",
      width: 300,
    },
    {
      label: "Reference Temperature",
      key: "reference_temperatures",
      cellRenderer: referenceTemperaturesRenderer,
      width: 350,
      disableSort: true,
    },
  ];

  const actionDropdown = [
    {
      title: "Edit Site",
      onClick: (rowData) => onOpenSiteSetting(rowData),
    },
  ];

  return (
    <Panel
      className="siteTable"
      style={{ height: height }}
    >
      <AutoSizer>
        {({ height, width }) => (
          <LegacyDataTable
            width={width}
            height={height}
            headers={Headers}
            list={sites}
            filterKey="name"
            filterTitle="Sites"
            defaultSortBy="name"
            defaultSortDirection="asc"
            onCellClick={onOpenSiteSetting}
            highlightRow
            actionDropdown={actionDropdown}
          />
        )}
      </AutoSizer>
    </Panel>
  );
};

SiteTable.propTypes = {
  height: PropTypes.number.isRequired,
  sites: PropTypes.array,
  onOpenSiteSetting: PropTypes.func,
};

export default SiteTable;
