import React from "react";

import {
  UserPermissionsTablePanel,
  UserDetailStatusPanel,
} from "#src/batteries-included-components/Panels";

import styles from "./UserPermissionsTab.module.scss";
import classNames from "classnames/bind";
const cx = classNames.bind(styles);

export const UserPermissionsTab = () => {
  return (
    <div className={cx("container")}>
      <div className={cx("contentStatus")}>
        <UserDetailStatusPanel />
      </div>

      <div className={cx("contentContainer")}>
        <UserPermissionsTablePanel />
      </div>
    </div>
  );
};
