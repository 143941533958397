import React, { useState, useEffect } from "react";
import unionBy from "lodash/unionBy";
import differenceBy from "lodash/differenceBy";
import {
  getStreamWithSiteDetail,
  getInventoriesWithStreamDetails,
} from "../BalanceHelper";
import SourceSelectionModal from "./SourceSelectionModal";
import InventorySourceSelectionModal from "./Inventory/InventorySourceSelectionModal";

const useManageSourceInput = (streams, sites, inventories, form) => {
  const [sourceAction, setSourceAction] = useState("");
  const [selectedSourceKey, setSelectedSourceKey] = useState("");
  const [sourceCheckedList, setSourceCheckedList] = useState({});

  const [selectedStreamList, setSelectedStreamList] = useState([]);
  const [lastUpdatedTime, setLastUpdatedTime] = useState(new Date());
  const [showSourceSelectionModal, setShowSourceSelectionModal] =
    useState(false);
  const [clientStreams, setClientStreams] = useState([]);

  const [inventoryList, setInventoryList] = useState([]);

  useEffect(() => {
    if (streams && streams.length > 0) {
      const clientStreamsWithSiteDetail = getStreamWithSiteDetail(
        streams,
        sites
      );

      setClientStreams(clientStreamsWithSiteDetail);
    }
  }, [streams]);

  useEffect(() => {
    if (inventories?.length && streams && sites) {
      const inventoriesWithStreamDetails = getInventoriesWithStreamDetails(
        sites,
        streams,
        inventories
      );

      setInventoryList(inventoriesWithStreamDetails);
    }
  }, [inventories, streams, sites]);

  const onSourceChecked = (checkedList, sourceKey) => {
    setSourceCheckedList({ ...sourceCheckedList, [sourceKey]: checkedList });
    setLastUpdatedTime(new Date());
    form.watch();
  };

  const onSourceInputChange = (action, inputSourceList) => {
    if (action === "add" || action === "edit") {
      const newSourceList = unionBy(
        inputSourceList,
        form.getValues(selectedSourceKey),
        "id"
      );

      form.setValue(selectedSourceKey, newSourceList);
      setLastUpdatedTime(new Date());
    }

    if (action === "delete") {
      const newSourceList = differenceBy(
        form.getValues(selectedSourceKey),
        inputSourceList,
        "id"
      );

      form.setValue(selectedSourceKey, newSourceList);
      setLastUpdatedTime(new Date());
    }
  };

  const editSourceClick = (sourceKey, sourceDetail) => {
    const selectedStreamList = unionBy(
      sourceCheckedList[sourceKey],
      [sourceDetail],
      "id"
    );

    setSelectedStreamList(selectedStreamList);
    openSourceSelectionModal(sourceKey, "edit");
  };

  const openSourceSelectionModal = (sourceKey, action) => {
    setSelectedSourceKey(sourceKey);
    setSourceAction(action);
    setShowSourceSelectionModal(true);
  };

  const closeSourceSelectionModal = () => {
    setShowSourceSelectionModal(false);
  };

  let sourceList;
  if (sourceAction !== "add") {
    sourceList = selectedStreamList;
  } else if (selectedSourceKey.includes("streams")) {
    sourceList = clientStreams;
  } else {
    sourceList = inventoryList;
  }

  const sourceSelectionModal = selectedSourceKey.includes("streams") ? (
    <SourceSelectionModal
      show={showSourceSelectionModal}
      onHide={closeSourceSelectionModal}
      sources={sourceList}
      sourceAction={sourceAction}
      selectedSourceType={selectedSourceKey}
      onSourceInputChange={onSourceInputChange}
    />
  ) : (
    <InventorySourceSelectionModal
      show={showSourceSelectionModal}
      onHide={closeSourceSelectionModal}
      sources={sourceList}
      sourceAction={sourceAction}
      selectedSourceType={selectedSourceKey}
      onSourceInputChange={onSourceInputChange}
    />
  );

  return [
    lastUpdatedTime,
    onSourceChecked,
    editSourceClick,
    openSourceSelectionModal,
    sourceSelectionModal,
  ];
};

export default useManageSourceInput;
