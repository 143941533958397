import { signOut } from "#redux/actions/authentication";
import { linkToLogin } from "#routers/links";
import { Button } from "@validereinc/common-components";
import * as PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

const mapDispatchToProps = {
  signOut,
};

// TODO: unsure if this page is actually used or ever rendered. this component
// is also severely out of date and tied to legacy code. can likely be tossed.
class ErrorPage extends Component {
  static propTypes = {
    match: PropTypes.object,
    location: PropTypes.object,
    history: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.state = {
      redirectTo: null,
      errorDetails: null,
    };
  }

  redirectToLogin = () => {
    this.props.signOut();

    this.setState({
      redirectTo: linkToLogin(),
    });
  };

  componentDidMount() {
    const { location } = this.props;

    const urlParams = new URLSearchParams(location.search);

    if (urlParams.has("error_description")) {
      this.setState({
        errorDetails: urlParams.get("error_description"),
      });
    }

    if (urlParams.has("error") && urlParams.get("error") === "access_denied") {
      this.setState({
        redirectTo: linkToLogin({ hash: "bookmarkError" }),
      });
    }
  }

  render() {
    const { redirectTo, errorDetails } = this.state;

    if (redirectTo !== null) {
      return <Redirect to={redirectTo} />;
    }

    return (
      <div>
        <div>Error Details: {errorDetails}</div>
        <Button
          outline={true}
          onClick={this.redirectToLogin}
        >
          Take me to login
        </Button>
      </div>
    );
  }
}

ErrorPage.propTypes = {
  signOut: PropTypes.func.isRequired,
};

export default connect(null, mapDispatchToProps)(ErrorPage);
