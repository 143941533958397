import { useState, useEffect } from "react";
import moment from "moment";

const DEFAULT_NUM_MONTHS = 3;

/**
 * This hook keeps track of the parameters used for the sample chart and the
 * selection table, passing out the values and the functions needed to change
 * them.
 */
const useManageSelectionFetchParams = (
  displayProperties,
  accountingPeriod,
  accountingRecordDetail,
  streams,
  recordOfQualityDetail
) => {
  const DEFAULT_DISPLAY_PROPERTIES = displayProperties[0]; // C1
  const [measurement, setMeasurement] = useState(DEFAULT_DISPLAY_PROPERTIES);
  const [from, setFrom] = useState(moment().valueOf());
  const [until, setUntil] = useState(moment().valueOf());
  const [stream, setStream] = useState({});
  const [siteSystemId, setSiteSystemId] = useState(null);

  /**
   * accountingRecordDetail is set the moment the modal is opened, this ensures
   * that the selection fetches occur at the same time result fetches instead of
   * being dependent on the other.
   *
   * setSiteSystemId is change here as well so it changes in the same render as
   * streamId and won't cause two fetches that may become a race condition
   */
  useEffect(() => {
    if (accountingRecordDetail.selection_stream_id) {
      const stream = streams.find(
        (stream) => stream?.id === accountingRecordDetail.selection_stream_id
      );

      setStream(stream);
      setDefaultDateRange();

      setSiteSystemId(accountingRecordDetail?.site_system?.id);
    }
  }, [accountingRecordDetail]);

  // recordOfQualityDetail changes every time a save/reset/approve occurs. This
  // useEffect is needed in the case where a reset occurs and the "original" stream
  // is different from current stream, changing the local state to the "original"
  useEffect(() => {
    const { selection_stream_id } = recordOfQualityDetail;

    if (selection_stream_id && stream?.id !== selection_stream_id) {
      const stream = streams.find(
        (stream) => stream?.id === selection_stream_id
      );

      setStream(stream);
      setDefaultDateRange();
    }
  }, [recordOfQualityDetail]);

  // On modal open (only time displayProperties change)
  useEffect(() => {
    setMeasurement(displayProperties[0]);
  }, [displayProperties]);

  const setDefaultDateRange = () => {
    setUntil(
      moment(accountingPeriod?.until).endOf("day").add(1, "month").valueOf()
    );

    setFrom(
      moment(accountingPeriod?.until)
        .startOf("day")
        .subtract(DEFAULT_NUM_MONTHS, "months")
        .valueOf()
    );
  };

  const onMeasurementSelect = (value) => {
    setMeasurement(value);
  };

  const onDateSelect = (from, until) => {
    setFrom(moment(from).startOf("day").valueOf());
    setUntil(moment(until).endOf("day").valueOf());
  };

  const onStreamParamSelect = (value) => {
    if (value && value.id !== stream?.id) {
      setStream(value);
      setDefaultDateRange();
    }
  };

  return [
    onMeasurementSelect,
    onDateSelect,
    onStreamParamSelect,
    setDefaultDateRange,
    measurement,
    from,
    until,
    stream,
    siteSystemId,
  ];
};

export default useManageSelectionFetchParams;
