import { config } from "../../config";

export type RequestWrapperType = {
  /** request endpoint */
  endpoint: string;
  /** request method */
  method: "GET" | "POST" | "PUT" | "DELETE";
  /** request body payload. NOTE: content type is default to application/json. provide the "Content-Type" header in "headers" if the payload MIME type is different */
  body?: Record<string | number, any> | any[];
  /** request headers */
  headers?: Record<string, string>;
  /** URL query parameters */
  query?: Record<
    string | number,
    string | number | string[] | number[] | undefined | boolean | null
  >;
  /** some APIs require that query parameters that are arrays have their key set with a `[]` string in them, instead of comma seprating the values with the original key */
  shouldQueryHaveArrayNotation?: boolean;
};

export const requestWrapper = ({
  endpoint,
  method,
  body,
  headers = {},
  query = {},
  shouldQueryHaveArrayNotation = false,
}: RequestWrapperType) => {
  const queryParamString = Object.entries(query).reduce(
    (total, [key, value], index) => {
      const isArray = Array.isArray(value);

      let returnValue = `${key}=${
        Array.isArray(value) ? value.join(",") : value ?? ""
      }`;

      if (isArray && shouldQueryHaveArrayNotation) {
        // eslint-disable-next-line
        // @ts-ignore
        returnValue = value.reduce(
          (total: string, currentValue: string | number, index: number) =>
            `${total}${index ? "&" : ""}${key}[]=${String(currentValue)}`,
          ""
        );
      }

      return `${total}${index ? "&" : ""}${returnValue}`;
    },
    "?"
  );

  return new Request(
    `${endpoint}${queryParamString !== "?" ? queryParamString : ""}`,
    {
      method,
      headers: {
        "Content-Type": "application/json",
        ...(config.bearer ? { Authorization: `Bearer ${config.bearer}` } : {}),
        ...headers,
      },
      ...(body ? { body: JSON.stringify(body) } : {}),
    }
  );
};
