import { DefaultBodyType, MockedRequest, RestHandler } from "msw";
import { APIDomainName, getEndpointString } from "../adapters";
import { DomainConfig } from "../config";
// import { ResourceServiceType } from "../util";
import { RequestWrapperType } from "../util/requests/requestWrapper";
import { buildMeasurementTypeHandlers } from "./MeasurementTypeMocks";
import { buildReportingGroupHandlers } from "./ReportingGroupMocks";

const getApiUrlWithEndpoint = ({
  url,
  endpoint,
  version,
}: WebApiDetailsType & Pick<RequestWrapperType, "endpoint">) =>
  `${url}${getEndpointString({ url, endpoint, version }) ?? ""}`;

/**
 * Holds constructed instances of all the handlers for the various APIs
 */
const restHandlers: DomainRestMockHandlers = {
  publicNodeAPI: [],
  nodeAPI: [],
  alertingAPI: [],
  dataEngAPI: [],
  elixirAPI: [],
  insightsAPI: [],
  dataPlatformSourceAPI: [],
  dataPlatformAdminAPI: [],
};

// IMPROVE: reference all handler builders for each resource, then create a full map all the way down to adapter methods
const buildRestAPIHandlers = (configData: DomainConfig) => {
  restHandlers.nodeAPI = [
    ...Object.values(buildMeasurementTypeHandlers(configData)),
    ...Object.values(buildReportingGroupHandlers(configData)),
  ];
};

// WIP: load a specific resource, or even specific methods, given a spec
// const buildRestAPIHandlersV2 = (
//   configData: DomainConfig,
//   handlersLoadSpec: MockHandlersLoadSpec
// ) => {
//   return {};
// };

// WIP:
/**
 * @example
    {
      resources: [
        {
          resource: "equipment",
          methods: ["getList", "getOne"]
        }
      ]
    }

    @example
    {
      resources: [
        {
          resource: "equipment",
          methods: "*",
        },
      ];
    }

    @example
    {
      resources: "*";
    }
 */
// type MockHandlersLoadSpec = {
//   resources: "*" | MockHandlersResourceLoadSpec[];
// };

// IMPROVE: resource should be a specific string union of all available resources. methods are free-form.
// type MockHandlersResourceLoadSpec = {
//   resource: string;
//   methods: "*" | string[];
// };

type WebApiDetailsType = {
  url: string;
  version?: number;
};

type ResourceServiceMockHandlersType<
  TAdapter, // extends ResourceServiceType<any>, TODO: not everything is a CRUD
  THandler = RestHandler<MockedRequest<DefaultBodyType>>,
> = Partial<{
  [key in keyof TAdapter]: THandler;
}>;

type DomainRestMockHandlers = {
  [key in APIDomainName]: Array<RestHandler<MockedRequest<DefaultBodyType>>>;
};

type BuildMockHandlersType<
  TAdapter, // extends ResourceServiceType<any>, TODO: not everything is a CRUD
  THandler = RestHandler<MockedRequest<DefaultBodyType>>,
> = (
  configData: DomainConfig
) => ResourceServiceMockHandlersType<TAdapter, THandler>;

export { buildRestAPIHandlers, getApiUrlWithEndpoint, restHandlers };
export type {
  BuildMockHandlersType,
  DomainRestMockHandlers,
  ResourceServiceMockHandlersType,
  WebApiDetailsType,
};
