import { z } from "zod";

export const JobStatus = {
  STARTED: "started",
  FINISHED: "finished",
  FAILED: "failed",
  PARTIAL: "partial",
};

export const JobTimeoutMinutes = 16;

export type JobStatusType = (typeof JobStatus)[keyof typeof JobStatus];

export const JobTypes = {
  RUN_CALCULATION: "run_calculation",
  APPLY_RECORD_CONFIGURATION: "apply_default_record_configuration",
  APPLY_CALCULATOR_CONFIGURATION: "apply_default_calculator_configuration",
};

export type JobTypeType = (typeof JobTypes)[keyof typeof JobTypes];

export const JobSchema = z.object({
  id: z.string(),
  company_id: z.string(),
  status: z.enum([JobStatus.FAILED, JobStatus.FINISHED, JobStatus.STARTED]),
  type: z.enum([
    JobTypes.APPLY_CALCULATOR_CONFIGURATION,
    JobTypes.APPLY_RECORD_CONFIGURATION,
    JobTypes.RUN_CALCULATION,
  ]),
  started_at: z.string(),
  finished_at: z.string(),
  duration: z.number(),
  input: z.object({}),
  total_items: z.number(),
  processed_items: z.number(),
  item_status_counts: z.object({
    success: z.number(),
    failed: z.number(),
    skipped: z.number(),
  }),
});

export type JobType = z.infer<typeof JobSchema>;
