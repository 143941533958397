import {
  CUBIC_METERS_VOLUME,
  getFormattedMeasurementValueWithUnit,
  PERCENTAGE_KEY,
} from "#redux/reducers/measurements";
import { Panel } from "@validereinc/common-components";
import * as PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import SummaryInformation from "../../../../SummaryInformation";
import { useSelectionModalContext } from "../../../Context/selectionModalContext";
import { getTotalPrice } from "../RecordResults/RecordOfQualityResultsHelper";
import ResultDifference, { computeDifference } from "../ResultDifference";
import "./ResultsBalanceSummary.scss";

const mapStateToProps = (state) => {
  return {
    getFormattedMeasurementValueWithUnit: (measurementKey, measurementObject) =>
      getFormattedMeasurementValueWithUnit(state.measurements)(
        measurementKey,
        measurementObject
      ),
  };
};

export const ResultBalanceProperties = [
  {
    name: "Estimated Value",
    id: "estimated_value",
  },
  { name: "Absolute Differences", id: "absolute_differences" },
  { name: "Recoveries", id: "recoveries" },
  {
    name: "Balance Type",
    id: "balance_type",
  },
  { name: "Percent Differences", id: "percent_differences" },
];

export const ResultsBalanceSummary = (props) => {
  const { systemBalance, originalSystemBalance } = useSelectionModalContext();

  const { total } = systemBalance;
  const { total: originalTotal } = originalSystemBalance;

  const getPropertyValue = (total, id, balanceType, originalTotal) => {
    if (id === "balance_type") {
      return <div className="capitalized">{`${balanceType} Balance`}</div>;
    } else if (id === "estimated_value") {
      const { value, symbol } = total?.[id] ?? {};

      const { percentChange, difference } = computeDifference(
        total?.[id],
        originalTotal?.[id]
      );

      if (!value) {
        return "-";
      }

      return (
        <div className="resultBalanceSummary__row">
          {getTotalPrice(total?.[id])}

          {difference && (
            <ResultDifference
              difference={difference}
              percentChange={percentChange}
              unit={symbol}
              showUnitOnLeft
              showPercentChange
            />
          )}
        </div>
      );
    } else {
      const value = total?.[id];

      const measurementKey =
        value?.unit !== "%" ? CUBIC_METERS_VOLUME : PERCENTAGE_KEY;

      return props.getFormattedMeasurementValueWithUnit(measurementKey, value);
    }
  };

  return (
    <Panel>
      <div className="resultsBalanceSummary__content">
        {ResultBalanceProperties.map(({ name, id }) => (
          <SummaryInformation
            key={name}
            title={`${name}:`}
            value={getPropertyValue(
              total,
              id,
              props.balanceType,
              originalTotal
            )}
          />
        ))}
      </div>
    </Panel>
  );
};

ResultsBalanceSummary.propTypes = {
  balanceType: PropTypes.string,
  getFormattedMeasurementValueWithUnit: PropTypes.func,
};

export default connect(mapStateToProps)(ResultsBalanceSummary);
