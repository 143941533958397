import { Panel } from "@validereinc/common-components";
import * as PropTypes from "prop-types";
import React from "react";
import { Badge, Nav, NavItem } from "react-bootstrap";
import CalibrationTable from "./CalibrationTable";

import { IndexLinkContainer } from "react-router-bootstrap";
import {
  linkToInstrumentSummary,
  linkToInstrumentTests,
} from "../../Routers/links";
import "./Instrument.css";
import "./Instrument.scss";

const SampleNavItem = (props) => (
  <IndexLinkContainer
    to={props.path}
    className={props.className}
  >
    <NavItem title="Samples">
      Samples
      <Badge>{props.totalSampleRun}</Badge>
    </NavItem>
  </IndexLinkContainer>
);

SampleNavItem.propTypes = {
  path: PropTypes.string.isRequired,
  className: PropTypes.string,
  totalSampleRun: PropTypes.number,
};

const ExportNavItem = (props) => (
  <IndexLinkContainer
    to={props.path}
    className={props.className}
  >
    <NavItem title="Export">Export</NavItem>
  </IndexLinkContainer>
);

ExportNavItem.propTypes = {
  path: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export const InstrumentInfoPanel = (props) => {
  const instrumentId = props.instrumentInfo.id;
  const samplesLink = linkToInstrumentTests(instrumentId);
  const summaryLink = linkToInstrumentSummary(instrumentId);
  const isOnSummaryTab = props.location.pathname === summaryLink;

  return (
    <Panel className="infoPanel">
      {props.instrumentInfo.type !== "water" && isOnSummaryTab && (
        <CalibrationTable
          className="calibrationTable"
          instrumentInfo={props.instrumentInfo}
        />
      )}

      <Nav
        bsStyle="pills"
        className="instrument__navButtons"
      >
        <IndexLinkContainer
          className="instrument__navButton"
          to={linkToInstrumentSummary(instrumentId)}
          eventKey={1}
        >
          <NavItem title="Summary">Summary</NavItem>
        </IndexLinkContainer>

        {samplesLink ? (
          <SampleNavItem
            className="instrument__navButton"
            path={samplesLink}
            totalSampleRun={props.instrumentInfo.total_samples_run}
          />
        ) : null}

        {props.instrumentInfo.type === "water" ? (
          <ExportNavItem
            className="instrument__navButton"
            path={`/app/instrument/view/${instrumentId}/csvExport`}
          />
        ) : null}
      </Nav>
    </Panel>
  );
};

InstrumentInfoPanel.propTypes = {
  instrumentInfo: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  toggleUpdateModal: PropTypes.func.isRequired,
  toggleArchiveModal: PropTypes.func.isRequired,
};
