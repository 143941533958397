import isPlainObject from "lodash/isPlainObject";

const objectHasResponseAndRequest = (
  data: unknown
): data is { response: Response; request: Request } =>
  isPlainObject(data) &&
  Object.hasOwn(data as object, "response") &&
  (data as { response: Response })?.response instanceof Response &&
  Object.hasOwn(data as object, "request") &&
  (data as { request: Request })?.request instanceof Request;

const isSuccess = (code: number) => code >= 200 && code < 300;
const isFailure = (code: number) => code >= 400 && code <= 599;
const isNotFound = (code: number) => code === 404;
const isNotAuthorized = (code: number) => code === 401;
const isForbidden = (code: number) => code === 403;
const isClientError = (code: number) => code >= 400 && code < 500;
const isServerError = (code: number) => code >= 500;

export const HTTPUtils = {
  status: {
    isSuccess,
    isFailure,
    isNotFound,
    isNotAuthorized,
    isForbidden,
    isClientError,
    isServerError,
  },
  objectHasResponseAndRequest,
};
