import React, { useContext } from "react";

import { useParams, useSearchParams } from "#src/Routers/hooks";

import {
  EventCategoryContext,
  EventCategoryProvider,
} from "#src/contexts/EventCategoryContext";

// components
import { Page, Tab } from "@validereinc/common-components";

import { useBreadcrumbs } from "#src/Routers/breadcrumbsHelper";

import { EVENT_CATEGORIES_BREADCRUMB } from "../";
import { EVENTS_BREADCRUMB } from "../../";
import {
  EVENT_CATEGORY_DETAIL_BREADCRUMB,
  EVENT_CATEGORY_DETAIL_PAGE_TITLE,
} from "./";

import { EventCategoryEventsTab } from "#src/batteries-included-components/Tabs/EventCategory/EventCategoryEventsTab";
import { EventCategoryTemplatesTab } from "#src/batteries-included-components/Tabs/EventCategory/EventCategoryTemplatesTab";

const EventsCategoryDetailPageContent = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const { eventCategory } = useContext(EventCategoryContext) || {};

  const breadcrumbs = useBreadcrumbs(
    [
      EVENTS_BREADCRUMB,
      EVENT_CATEGORIES_BREADCRUMB,
      EVENT_CATEGORY_DETAIL_BREADCRUMB,
    ],
    { 2: eventCategory?.name }
  );

  const onActiveTabKeyChange = (tabKey: string) => {
    setSearchParams({ ...searchParams, tab: tabKey });
  };

  return (
    <Page
      category="Event Category"
      title={eventCategory?.name ?? EVENT_CATEGORY_DETAIL_PAGE_TITLE}
      breadcrumbs={breadcrumbs}
      activeTabKey={searchParams?.tab ?? "templates"}
      onActiveTabKeyChange={onActiveTabKeyChange}
    >
      <Tab
        title="Templates"
        tabKey="templates"
      >
        <EventCategoryTemplatesTab />
      </Tab>

      <Tab
        title="Events"
        tabKey="events"
      >
        <EventCategoryEventsTab />
      </Tab>
    </Page>
  );
};

export const EventCategoryDetailPage = () => {
  const { eventCategoryId } = useParams<{ eventCategoryId: string }>();

  return (
    <EventCategoryProvider eventCategoryId={eventCategoryId}>
      <EventsCategoryDetailPageContent />
    </EventCategoryProvider>
  );
};
