import { linkToFacilityRecordDetail } from "#routes/organization/facilities/[facilityId]/record/[recordId]/details";
import { FlowRecordDetailRoute } from "#routes/organization/flows/[flowId]/detail/record/[recordId]";
import { useNavigate } from "#src/Routers/hooks";
import useLocalization from "#src/hooks/useLocalization";
import { linkToEquipmentRecordDetail } from "#src/routes/organization/equipment/[equipmentId]/record-details/[recordId]";
import { Button, useAlert } from "@validereinc/common-components";
import {
  AssetType,
  type CalculationParameterType,
  type EstimationMethodConfigurationType,
  type EstimationMethodRunType,
} from "@validereinc/domain";
import classNames from "classnames/bind";
import React, { useState } from "react";
import styles from "./InputSourceFlair.module.scss";

const cx = classNames.bind(styles);

export const InputSourceFlair = ({
  inputParameter,
  estimationMethodConfig,
  estimationMethodRun,
}: InputSourceFlairProps) => {
  const { addAlert } = useAlert();
  const { localize } = useLocalization();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<unknown>();
  // all input source records are from the same month as the configuration itself
  const inputSources = estimationMethodConfig
    ? estimationMethodConfig.input_sources
    : estimationMethodRun
      ? estimationMethodRun.input_sources
      : null;
  const associatedInputSource = inputSources
    ? inputSources[inputParameter.id]
    : null;
  const {
    asset_id: assetId,
    asset_type: assetType,
    asset_name: assetName,
    record_id: recordId,
  } = associatedInputSource ?? {};

  const handleClick = () => {
    setIsLoading(true);
    setError(null);

    try {
      let pathname = "";

      switch (assetType) {
        case AssetType.FACILITY: {
          pathname = linkToFacilityRecordDetail(assetId, recordId);
          break;
        }
        case AssetType.EQUIPMENT: {
          pathname = linkToEquipmentRecordDetail(assetId, recordId);
          break;
        }
        case AssetType.FLOW: {
          pathname = FlowRecordDetailRoute.toLink({
            pathParams: { flowId: assetId, recordId },
          });
          break;
        }
      }

      if (!pathname) {
        throw new Error("Path to record not available");
      }

      navigate({
        pathname,
      });
    } catch (err) {
      console.error(err, {
        inputParameter,
        estimationMethodConfig,
        associatedInputSource,
      });
      setError(err);
      addAlert?.({
        variant: "error",
        message: `Couldn't get the source Record of ${inputParameter.display_name}`,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const label = `${assetType ? localize(assetType) : "Source"}${
    assetName ? ": " : ""
  }${assetName}`;

  if (!associatedInputSource) {
    return null;
  }

  return (
    <div className={cx("inputSourceInfo")}>
      <Button
        variant={error ? "text-error" : "text"}
        icon={
          error
            ? "exclamation-triangle"
            : isLoading
              ? "spinner"
              : "crosshair-simple"
        }
        disabled={isLoading}
        iconPosition="left"
        as="a"
        aria-label="Click to go to the source Record of this input value"
        onClick={handleClick}
      >
        {label}
      </Button>
    </div>
  );
};

type InputSourceFlairProps = {
  inputParameter: CalculationParameterType;
  estimationMethodConfig?: EstimationMethodConfigurationType;
  estimationMethodRun?: EstimationMethodRunType;
};
