import InlineManualEntryModal from "#components/ManualEntry/ManualEntryForms/Inline/InlineManualEntryModal";
import useInlineManualEntryModal from "#components/ManualEntry/ManualEntryForms/useManualEntryModal";
import { useTestDetail } from "#hooks/useTests";
import { ensureStreamListIsFetched } from "#redux/actions/index";
import { getBreadcrumbsObject } from "#routers/breadcrumbsHelper";
import SampleService from "#services/SampleService";
import { Button, Page, useAlert } from "@validereinc/common-components";
import classNames from "classnames/bind";
import find from "lodash/find";
import * as PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import DownloadableReportsPanel from "./DownloadableReportsPanel";
import InstrumentWarningPanel from "./InstrumentWarningPanel";
import NotesPanel from "./NotesPanel/NotesPanel";
import styles from "./TestDetail.module.scss";
import TestDetailSummary from "./TestDetailSummary";
import TestMeasurementTable from "./TestMeasurementTable";
import { getMeasurementDisplayProperties } from "./TestMeasurementTableHelper";

const cx = classNames.bind(styles);

const mapDispatchToProps = {
  ensureStreamListIsFetched,
};

const mapStateToProps = ({ streams }) => ({
  streams: streams?.data?.toJS() ?? [],
});

const TestDetail = ({
  match,
  breadcrumbs,
  streams,
  ensureStreamListIsFetched,
}) => {
  const [testDetail, testDetailLoadingState, refetchTest] = useTestDetail(
    match.params.testId
  );
  const [loadingState, setLoadingState] = useState("enabled");
  const { addAlert } = useAlert();

  function onMarkError() {
    setLoadingState("loading");

    SampleService.markTestAsError(testDetail.id, "")
      .then(() => {
        addAlert({
          variant: "success",
          message: "The test has been marked as an error.",
        });
        refetchTest();
      })
      .finally(() => {
        setLoadingState("enabled");
      });
  }

  const stream = useMemo(
    () => find(streams, { id: testDetail?.sample?.stream_id }),
    [testDetail]
  );

  const [showInline, showInlineModal, onHideInlineModal] =
    useInlineManualEntryModal();

  const pageBreadcrumbs = useMemo(() => {
    const pageBreadcrumbs = getBreadcrumbsObject(breadcrumbs, match.params);

    if (pageBreadcrumbs[2] && stream?.name) {
      pageBreadcrumbs[2] = {
        ...pageBreadcrumbs[2],
        title: stream?.name,
      };
    }

    return pageBreadcrumbs;
  }, [breadcrumbs, match.params, stream]);

  const [displayProperties, displayPropertiesWithAlerts] = useMemo(() => {
    return getMeasurementDisplayProperties(testDetail);
  }, [testDetail]);

  useEffect(() => {
    ensureStreamListIsFetched();
  }, []);

  const actionRow = (
    <div className={cx("actionsRow")}>
      {testDetail?.status !== "error" && (
        <Button
          variant="error-outline"
          onClick={onMarkError}
          isLoading={loadingState === "loading"}
          disabled={loadingState !== "enabled"}
        >
          Mark as Error
        </Button>
      )}

      <Button
        onClick={showInlineModal}
        variant="primary"
      >
        Add Inline Measurement
      </Button>
    </div>
  );

  const pageTitle = testDetail?.name
    ? `${testDetail?.name} Test`
    : "Test Detail";

  return (
    <Page
      title={pageTitle}
      breadcrumbs={pageBreadcrumbs}
      isLoading={testDetailLoadingState === "loading"}
      actionRow={actionRow}
    >
      <TestDetailSummary
        testDetail={testDetail}
        testDetailLoadingState={testDetailLoadingState}
      />

      <TestMeasurementTable
        testDetail={testDetail}
        displayProperties={displayPropertiesWithAlerts}
        title="Alerted Measurements"
      />
      <TestMeasurementTable
        testDetail={testDetail}
        displayProperties={displayProperties}
        title="All Measurements"
      />
      <DownloadableReportsPanel
        sampleId={testDetail?.sample_id}
        pdfReportLink={testDetail?.pdf_url}
        labReportId={testDetail?.lab_report_id}
        instrumentType={testDetail?.instrument?.type}
        testImageUrls={testDetail?.test_image_urls}
      />

      <InlineManualEntryModal
        show={showInline}
        onHide={onHideInlineModal}
        testInfo={testDetail}
        refetchData={refetchTest}
      />

      <InstrumentWarningPanel
        metadata={testDetail?.metadata}
        labels={testDetail?.labels}
      />

      <NotesPanel
        testId={testDetail?.id}
        refreshTest={refetchTest}
      />
    </Page>
  );
};

TestDetail.propTypes = {
  height: PropTypes.number.isRequired,
  match: PropTypes.object.isRequired,
  breadcrumbs: PropTypes.array.isRequired,
  streams: PropTypes.array.isRequired,
  ensureStreamListIsFetched: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(TestDetail);
