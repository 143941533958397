import config from "#src/config";
import { getTimeStringFromDate } from "#utils/timeFormatter";
import { Panel } from "@validereinc/common-components";
import moment from "moment";
import * as PropTypes from "prop-types";
import React from "react";
import { ListGroup, ListGroupItem } from "react-bootstrap";
import FontAwesome from "react-fontawesome";
import { Link } from "react-router-dom";
import { isValidString } from "./InstrumentComponentHelper";
import "./LatestSamplesPanel.css";

const LatestSamplesListItem = (props) => (
  <ListGroupItem
    className="latestSamplesListItem"
    style={{ borderLeft: "2px solid #69b2ca" }}
  >
    <p>
      <b className="latestSamplesListItem__title">
        {isValidString(props.sampleInfo.name, "-")}
      </b>

      <span className="pull-right">
        <Link to={`tests/${props.sampleInfo.id}`}>
          <FontAwesome
            className="latestSamplesListItem__icon"
            name="file-text-o"
          />
        </Link>
      </span>

      <br />

      <small>
        <i>
          <span className="latestSamplesListItem__time">
            {getTimeStringFromDate(
              props.sampleInfo.date,
              config.DATETIME_FORMAT
            )}
          </span>

          <b>
            <FontAwesome
              name="clock-o"
              className="fa-fw"
            />
          </b>
          {moment(props.sampleInfo.date).fromNow(true) + " ago"}
        </i>
      </small>
    </p>
  </ListGroupItem>
);

const LatestSamplesPanel = (props) => (
  <Panel
    title="Latest Samples"
    className="latestSamplesPanel"
    loaded={props.loaded}
  >
    <div className="latestSamplesPanel__content">
      <div hidden={props.sampleList.length <= 0 ? false : true}>
        <h6 className="latestSamplesPanel__noSampleMessage">
          No samples from last 7 days
        </h6>
      </div>

      <ListGroup hidden={props.sampleList.length > 0 ? false : true}>
        {props.sampleList.map(function (sampleInfo, index) {
          return (
            <LatestSamplesListItem
              key={index}
              sampleInfo={sampleInfo}
              index={index}
            />
          );
        })}
      </ListGroup>
    </div>
  </Panel>
);

LatestSamplesPanel.propTypes = {
  sampleList: PropTypes.array.isRequired,
  loaded: PropTypes.bool.isRequired,
};

LatestSamplesListItem.propTypes = {
  sampleInfo: PropTypes.object.isRequired,
};

export default LatestSamplesPanel;
