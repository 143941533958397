import { RoutePath } from "#utils/route";
import { RootRoute } from "#routes/root";

export const OrganizationRoute = RootRoute.concat(
  new RoutePath({
    path: "/organization",
    title: "Organization",
    isPresentational: true,
  })
);

/** @deprecated directly declaring and referencing this breadcrumb is unnecessary. Use new RoutePath based pattern and directly generate breadcrumbs using 'useBreadcrumbsFromRoute()' hook */
export const ORGANIZATION_BREADCRUMB = OrganizationRoute.toBreadcrumb();
