import React from "react";
import PropTypes from "prop-types";
import FontAwesome from "react-fontawesome";
import { ProfileIcon } from "../../../Common/Icons/ProfileIcon/ProfileIcon";

const IconWithText = (props) => (
  <div className="profile__detailRow">
    <FontAwesome
      className="fa-fw icon"
      name={props.icon}
    />
    {props.value ? props.value : "Unavailable"}
  </div>
);

const MemberNameCell = (props) => (
  <div
    className="memberNameCell"
    data-hj-suppress
  >
    <ProfileIcon username={props.memberInfo.name} />
    <div className="membersNameCell__name">{props.memberInfo.name}</div>
    <div>{props.memberInfo.email}</div>
  </div>
);

IconWithText.propTypes = {
  value: PropTypes.string,
  icon: PropTypes.string,
};

ProfileIcon.propTypes = {
  userFirstChar: PropTypes.string,
};

MemberNameCell.propTypes = {
  memberInfo: PropTypes.object,
};

export default MemberNameCell;
