export * from "./EventTemplateDetailPage";

export const EVENT_TEMPLATE_DETAIL_PAGE_TITLE = "Event Template";

export const linkToEventTemplateDetail = (
  eventCategoryId = ":eventCategoryId",
  eventTemplateId = ":eventTemplateId"
) =>
  `/app/events/event-categories/${eventCategoryId}/templates/${eventTemplateId}/details`;

export const EVENT_TEMPLATE_DETAIL_BREADCRUMB = {
  title: `${EVENT_TEMPLATE_DETAIL_PAGE_TITLE}:eventCategoryId:eventTemplateId`,
  path: linkToEventTemplateDetail,
};
