export const ESTIMATION_METHOD_DETAIL_FALLBACK_PAGE_TITLE =
  "Estimation Method Detail:methodId:equipmentId";

export const linkToEstimationMethodDetail = (
  methodId = ":methodId",
  equipmentId = ":equipmentId"
) =>
  `/app/organization/equipment/${equipmentId}/estimation-method/${methodId}/detail`;

export const ESTIMATION_METHOD_DETAIL_BREADCRUMB = {
  title: ESTIMATION_METHOD_DETAIL_FALLBACK_PAGE_TITLE,
  path: linkToEstimationMethodDetail,
};

export * from "./EstimationMethodDetail";
