import React, { useEffect } from "react";

import { ControlLabel, Dropdown } from "@validereinc/common-components";
import { EmissionCompositionType } from "@validereinc/domain";

export const POLLUTANTS: Array<{
  label: string;
  dataKey: EmissionCompositionType;
}> = [
  { label: "CO2e", dataKey: "co2_eq" },
  { label: "CO2", dataKey: "co2" },
  { label: "CH4", dataKey: "c1" },
  { label: "N2O", dataKey: "n2o" },
];

export const INIT_POLLUTANT = POLLUTANTS[0].dataKey as "co2_eq";

export const INIT_POLLUTANT_AS_MASS = {
  mass: POLLUTANTS[0].dataKey,
  label: POLLUTANTS[0].label,
};

export type PollutantKey = "co2_eq" | "co2" | "c1" | "n2o";

export const PollutantDropdown = ({
  value,
  onChange,
}: {
  value?: PollutantKey;
  onChange: (newValue: PollutantKey) => void;
}) => {
  const onChangeSelectedPollutant = (newPollutant: PollutantKey) => {
    onChange(newPollutant);
  };

  useEffect(() => {
    if (!value) {
      onChangeSelectedPollutant(INIT_POLLUTANT);
    }
  }, []);

  return (
    <ControlLabel label="Pollutant">
      <Dropdown
        isMulti={false}
        isClearable={false}
        labelKey="label"
        valueKey="dataKey"
        options={POLLUTANTS}
        value={value}
        onChange={onChangeSelectedPollutant}
        width={200}
      />
    </ControlLabel>
  );
};
