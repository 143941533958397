import { Button, Modal, Title } from "@validereinc/common-components";
import styles from "@validereinc/common-components/constants";
import * as PropTypes from "prop-types";
import React from "react";

const shouldUpdateCurrentTasks = ({
  startDay,
  repeats,
  repeatsDay,
  timezone,
  repeats_per_scheduling_period,
  occurrences,
}) => {
  return (
    startDay ||
    repeats ||
    repeatsDay ||
    timezone ||
    repeats_per_scheduling_period ||
    occurrences ||
    false
  );
};

const UpdateConfirmationModal = ({
  show,
  onClose,
  name,
  dirtyFields,
  onUpdateCurrentAndFutureClick,
  onUpdateFutureClick,
}) => {
  const isUpdateCurrentTask = shouldUpdateCurrentTasks(dirtyFields);

  return (
    <Modal
      open={show}
      onClose={onClose}
    >
      <Modal.Header>
        <Title>Update Workflow</Title>
      </Modal.Header>

      <Modal.Body>
        <p>Do you wish to update {name}?</p>

        {isUpdateCurrentTask ? (
          <p style={{ color: styles.statusErrorColour }}>
            *** Workflow will restart with the new schedule period and all
            current tasks under this workflow will be cancelled. ***
          </p>
        ) : null}
      </Modal.Body>

      <Modal.Footer>
        <Button
          className="pull-left"
          onClick={onClose}
        >
          Close
        </Button>

        <span>
          <Button onClick={onUpdateCurrentAndFutureClick}>
            Update Current & Future Tasks
          </Button>

          <span
            style={{ marginLeft: "5px" }}
            hidden={shouldUpdateCurrentTasks(dirtyFields)}
          >
            <Button
              variant="primary"
              onClick={onUpdateFutureClick}
            >
              Update Future Tasks Only
            </Button>
          </span>
        </span>
      </Modal.Footer>
    </Modal>
  );
};

UpdateConfirmationModal.propTypes = {
  name: PropTypes.string,
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  dirtyFields: PropTypes.object.isRequired,
  onUpdateCurrentAndFutureClick: PropTypes.func.isRequired,
  onUpdateFutureClick: PropTypes.func.isRequired,
};

export default UpdateConfirmationModal;
