import {
  ADDED_TO_ANALYZE,
  REMOVED_FROM_ANALYZE,
  UPDATED_TO_ANALYZE,
} from "../constants/action-types";

export function updateAnalyze(analyzeSpec) {
  return {
    type: UPDATED_TO_ANALYZE,
    analyzeSpec,
  };
}

export function addToAnalyze(analyzeSpec) {
  return {
    type: ADDED_TO_ANALYZE,
    analyzeSpec,
  };
}

export function removeFromAnalyze(streamId) {
  return {
    type: REMOVED_FROM_ANALYZE,
    id: streamId,
  };
}
