import { Button, Modal, Title } from "@validereinc/common-components";
import filter from "lodash/filter";
import * as PropTypes from "prop-types";
import React, { useMemo, useState } from "react";
import "./ExistingSampleModals.scss";
import ExistingSampleSelectionTable from "./ExistingSampleSelectionTable";

const ExistingSampleModal = ({
  show,
  onHide,
  inputs,
  onAddSample,
  singleSelect,
}) => {
  const [selectedSamples, setSelectedSamples] = useState([]);

  const onSampleClick = (samples, latestSample) => {
    setSelectedSamples(singleSelect ? [latestSample] : samples);
  };

  const onAddSamplesClick = () => {
    onAddSample(
      selectedSamples.map((sample) => {
        sample.new_sample = false;
        return sample;
      })
    );
    onHide();
  };

  const numOfSelectedSamples = selectedSamples?.length ?? 0;

  const selectedExistingSamples = useMemo(
    () => filter(inputs.samples, { new_sample: false }),
    []
  );

  return (
    <Modal
      className="existingSampleModal"
      open={show}
      onClose={onHide}
    >
      <Modal.Header>
        <Title>Add Existing Sample</Title>
      </Modal.Header>

      <Modal.Body>
        <ExistingSampleSelectionTable
          onSampleClick={onSampleClick}
          selectedExistingSamples={selectedExistingSamples}
          selectedSamples={selectedSamples}
        />
      </Modal.Body>

      <Modal.Footer>
        <Button
          className="pull-left"
          onClick={onHide}
        >
          Close
        </Button>
        <Button
          variant="primary"
          onClick={onAddSamplesClick}
          disabled={numOfSelectedSamples === 0}
        >
          Add {numOfSelectedSamples ? numOfSelectedSamples : ""} Sample
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

ExistingSampleModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  inputs: PropTypes.object.isRequired,
  onAddSample: PropTypes.func.isRequired,
  singleSelect: PropTypes.bool,
};

export default ExistingSampleModal;
