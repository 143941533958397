import { EquipmentDropdownInput } from "#batteries-included-components/Dropdowns/EquipmentDropdownInput";
import { FacilityDropdownInput } from "#batteries-included-components/Dropdowns/FacilityDropdownInput";
import { FlowDropdownInput } from "#batteries-included-components/Dropdowns/FlowDropdownInput";
import { ReportingGroupFilterSwitcher } from "#batteries-included-components/Filters/ReportingGroupFilterSwitcher";
import { useCustomAttributeFilters } from "#src/components/hooks/FilterPanel/useCustomAttributeFilters";
import {
  SavedFilterTag,
  useSavedFilters,
} from "#src/components/hooks/FilterPanel/useSavedFilters";
import { useMeasurementTypes } from "#src/contexts/MeasurementTypeContext";
import { DEFAULT_DATE_RANGES } from "#src/hooks/useDateRange";
import useLocalization from "#src/hooks/useLocalization";
import { useQuery } from "@tanstack/react-query";
import {
  DateSelectorInput,
  DropdownInput,
  FilterPanel,
  StorageKeys,
  TextInput,
  useFilters,
} from "@validereinc/common-components";
import {
  AssetType,
  FlowDomain,
  RecordDomain,
  RecordFilterType,
  ReportingGroupDomain,
  Resources,
} from "@validereinc/domain";
import React from "react";

const defaultDate = DEFAULT_DATE_RANGES.currentWholeMonth;

export const FlowRecordFilterPanel = ({
  filterConfigStorageKey,
  selectableProperties,
}: Pick<StorageKeys, "filterConfigStorageKey"> & {
  selectableProperties?: string[];
}) => {
  const { getTypeName } = useMeasurementTypes();

  const propertySelectorValues = selectableProperties?.map((key) => ({
    key,
    name: getTypeName(key),
  }));

  const { localize, isLoading: isMappingLoading } = useLocalization();

  const defaultQueryOptions = {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  };

  const { data: reportingGroups, isLoading: isReportingGroupDataLoading } =
    useQuery({
      queryKey: ["reportingGroups"],
      queryFn: () => ReportingGroupDomain.getList({}),
      select: ({ data }) => data,
      ...defaultQueryOptions,
    });

  const { data: flowTypes, isLoading: isFlowTypeDataLoading } = useQuery({
    queryKey: ["flowTypes"],
    queryFn: () => FlowDomain.getFlowTypes(),
    ...defaultQueryOptions,
  });

  const {
    data: flowProductCategories,
    isLoading: isFlowProductCategoryDataLoading,
  } = useQuery({
    queryKey: ["flowProductCategories"],
    queryFn: () => FlowDomain.getFlowProductCategories(),
    ...defaultQueryOptions,
  });

  const { data: flowProductTypes, isLoading: isFlowProductTypeDataLoading } =
    useQuery({
      queryKey: ["flowProductTypes"],
      queryFn: () => FlowDomain.getFlowProductTypes(),
      ...defaultQueryOptions,
    });

  const {
    customAttributeFilters: facilityCustomAttributeFilters,
    isLoading: isFacilityCustomAttributeLoading,
  } = useCustomAttributeFilters({
    assetType: AssetType.FACILITY,
    prefix: "flow.associated_facility.custom_attributes",
    section: localize("Facility"),
  });

  const {
    customAttributeFilters: flowCustomAttributeFilters,
    isLoading: isFlowCustomAttributeLoading,
  } = useCustomAttributeFilters({
    assetType: AssetType.FLOW,
    prefix: "flow.custom_attributes",
    section: localize("Flow"),
  });

  const {
    customAttributeFilters: equipmentCustomAttributeFilters,
    isLoading: isEquipmentCustomAttributeLoading,
  } = useCustomAttributeFilters({
    assetType: AssetType.EQUIPMENT,
    prefix: "flow.associated_equipment.custom_attributes",
    section: localize("Equipment"),
  });

  const isLoading =
    isEquipmentCustomAttributeLoading ||
    isFlowCustomAttributeLoading ||
    isFacilityCustomAttributeLoading ||
    isFlowProductTypeDataLoading ||
    isFlowProductCategoryDataLoading ||
    isFlowTypeDataLoading ||
    isReportingGroupDataLoading ||
    isMappingLoading;

  const [_, setFilters] = useFilters(filterConfigStorageKey);

  const { filterProps } = useSavedFilters<RecordFilterType>({
    queryKey: [Resources.RECORD],
    savedFilterResourceAdapter: RecordDomain.savedFilters,
    setFilters,
    filterKeyBlacklist: ["year_month.from", "year_month.to"],
    tag: SavedFilterTag.FLOW_RECORDS,
  });

  return (
    <FilterPanel
      {...filterProps}
      storageKey={filterConfigStorageKey}
      isLoading={isLoading}
      defaultValue={{
        year_month: DEFAULT_DATE_RANGES.lastWholeMonth,
        reporting_group_id: reportingGroups?.[0]?.id,
        "flow.status": ["active"],
      }}
      defaultActiveKeys={["Flow"]}
      filters={[
        {
          component: (
            <ReportingGroupFilterSwitcher
              key="reporting_group_id"
              name="reporting_group_id"
              options={reportingGroups}
              shouldSelectFirstOptionAsDefault
            />
          ),
        },
        {
          component: (
            <DateSelectorInput
              key="year_month"
              name="year_month"
              variant="month"
              isRange
              isInline
            />
          ),
        },
        {
          component: (
            <TextInput
              name="flow.name"
              placeholder="Search Flow..."
              type="search"
              isInline
            />
          ),
        },
        {
          component: (
            <DropdownInput
              key="flow.type"
              name="flow.type"
              options={flowTypes ?? []}
              placeholder={localize("Flow Type")}
              labelKey="name"
              valueKey="id"
              isMulti
              isSearchable
              isInline
              isOptionalTextShown={false}
            />
          ),
        },
        {
          component: (
            <DropdownInput
              key="measurement_type"
              name="measurement_type"
              options={propertySelectorValues}
              placeholder="Select Property..."
              labelKey="name"
              valueKey="key"
              isSearchable
              isInline
              isOptionalTextShown={false}
            />
          ),
        },
        {
          section: localize("Flow"),
          component: (
            <FlowDropdownInput
              key="flow.id"
              name="flow.id"
              isMulti
              isFluid
              isOptionalTextShown={false}
            />
          ),
        },
        {
          section: localize("Flow"),
          component: (
            <DropdownInput
              label={`${localize("Flow")} Status`}
              name="flow.status"
              key="flow.status"
              options={[
                { name: "Active", id: "active" },
                { name: "Inactive", id: "inactive" },
              ]}
              labelKey="name"
              valueKey="id"
              isMulti
              isFluid
              isSearchable
              isOptionalTextShown={false}
            />
          ),
        },
        {
          section: localize("Flow"),
          component: (
            <DropdownInput
              key="flow.product_category"
              name="flow.product_category"
              options={flowProductCategories ?? []}
              label={localize("Flow Product Category")}
              labelKey="name"
              valueKey="id"
              isMulti
              isFluid
              isSearchable
              isOptionalTextShown={false}
            />
          ),
        },
        {
          section: localize("Flow"),
          component: (
            <DropdownInput
              key="flow.product_type"
              name="flow.product_type"
              options={flowProductTypes ?? []}
              label={localize("Flow Product Type")}
              labelKey="name"
              valueKey="id"
              isMulti
              isFluid
              isSearchable
              isOptionalTextShown={false}
            />
          ),
        },
        ...flowCustomAttributeFilters,
        {
          section: localize("Facility"),
          component: (
            <FacilityDropdownInput
              key="flow.associated_facility_id"
              name="flow.associated_facility_id"
              isMulti
              isFluid
              isOptionalTextShown={false}
            />
          ),
        },
        ...facilityCustomAttributeFilters,
        {
          section: localize("Equipment"),
          component: (
            <EquipmentDropdownInput
              key="flow.associated_equipment_id"
              name="flow.associated_equipment_id"
              isMulti
              isFluid
              isOptionalTextShown={false}
            />
          ),
        },
        ...equipmentCustomAttributeFilters,
      ]}
    />
  );
};
