import { ResourceServiceType } from "../util";
import { JobType } from "../schemas";
import { restAPI } from "./api";

type JobServiceType = Pick<ResourceServiceType<JobType>, "getOne">;

export const JobAdapter: JobServiceType = {
  getOne: ({ id }) =>
    restAPI.nodeAPI
      .GET<JobType>({
        endpoint: `/jobs/${id}`,
        version: 2,
      })
      .then((resp) => ({
        data: resp,
      })),
};
