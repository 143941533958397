import {
  CUBIC_METERS_VOLUME,
  getFormattedMeasurementValueWithUnit,
} from "#redux/reducers/measurements";
import { LegacyDataTable, Tooltip } from "@validereinc/common-components";
import * as PropTypes from "prop-types";
import React from "react";
import FontAwesome from "react-fontawesome";
import { connect } from "react-redux";
import { AutoSizer } from "react-virtualized";
import { getComponentShortForm } from "../../Quality/RecordOfQualityHelper";
import { sortComponents } from "../BalancesTableHelper";
import { getTotalLabel } from "../BalanceUpserts/BalanceHelper";
import "./SourceSummaryTable.scss";
import {
  sortByAggregate,
  sortByMeasurements,
} from "./SourceSummaryTableHelper";

const COMPONENT_COLUMN_WIDTH = 150;

const mapStateToProps = (state) => {
  return {
    getFormattedMeasurementValueWithUnit: (measurementKey, measurementObject) =>
      getFormattedMeasurementValueWithUnit(state.measurements)(
        measurementKey,
        measurementObject
      ),
  };
};

const getAdjustedTotalVolumeHeader = (balanceType) => {
  const totalLabel = getTotalLabel(balanceType);

  const tooltipMessage = `Hover over adjusted ${totalLabel} to see unadjusted ${totalLabel}`;

  return (
    <Tooltip content={tooltipMessage}>
      <span>{totalLabel}</span>
      <FontAwesome
        name="info-circle"
        style={{ marginLeft: "3px" }}
      />
    </Tooltip>
  );
};

const SourceSummaryTable = (props) => {
  const {
    sources,
    balanceType,
    showTotalVolume,
    showEnergy,
    getFormattedMeasurementValueWithUnit,
  } = props;

  const components = Object.keys(sources?.[0]?.components ?? []);

  const nameRenderer = (rowData, columnKey) => {
    const name = rowData[columnKey];

    return (
      <Tooltip
        key={rowData.id}
        content={rowData.name}
      >
        <div className="clipText">{name}</div>
      </Tooltip>
    );
  };

  const aggregateRenderer = (rowData, columnKey) => {
    const measurement = rowData[columnKey];

    const measurementKey =
      columnKey === "energy" ? "Energy" : CUBIC_METERS_VOLUME;

    if (rowData.multiplier) {
      const tooltip = (
        <div>
          <b>Multiplier: </b>
          {rowData.multiplier}
        </div>
      );

      return (
        <Tooltip
          key={rowData.id}
          content={tooltip}
        >
          <div className="sourceSummaryTable__measurement">
            <span>
              {getFormattedMeasurementValueWithUnit(
                measurementKey,
                measurement
              )}
            </span>
          </div>
        </Tooltip>
      );
    } else {
      return measurement;
    }
  };

  const componentRenderer = (rowData, columnKey) => {
    const measurement = rowData.components[columnKey];

    return getFormattedMeasurementValueWithUnit(
      CUBIC_METERS_VOLUME,
      measurement
    );
  };

  const getAggregateProperties = (balanceType, showTotalVolume, showEnergy) => {
    const aggregateProperties = [];

    if (showTotalVolume) {
      aggregateProperties.push({
        label: getAdjustedTotalVolumeHeader(balanceType),
        key: "total",
        width: 160,
        cellRenderer: aggregateRenderer,
        sort: sortByAggregate,
        rightAlign: true,
      });
    }

    if (showEnergy) {
      aggregateProperties.push({
        label: "Energy",
        key: "energy",
        width: 160,
        cellRenderer: aggregateRenderer,
        sort: sortByAggregate,
        rightAlign: true,
      });
    }

    return aggregateProperties;
  };

  const typeRenderer = (rowData) => {
    return rowData?.source === "tank" ? "Inventory" : "Stream";
  };

  const ComponentHeaderConfigs =
    sortComponents(components).map((component) => {
      return {
        label: getComponentShortForm(component),
        key: component,
        width: COMPONENT_COLUMN_WIDTH,
        cellRenderer: componentRenderer,
        sort: sortByMeasurements,
        rightAlign: true,
      };
    }) ?? [];

  const Headers = [
    {
      label: "Source",
      key: "name",
      width: 250,
      fixed: true,
      cellRenderer: nameRenderer,
    },
    {
      label: "Type",
      key: "source",
      cellRenderer: typeRenderer,
      width: 75,
    },

    ...getAggregateProperties(balanceType, showTotalVolume, showEnergy),
    ...ComponentHeaderConfigs,
  ];

  return (
    <div className="sourceSummaryTable">
      <AutoSizer>
        {({ width, height }) => (
          <LegacyDataTable
            headers={Headers}
            list={sources}
            width={width}
            height={height}
            rowHeight={40}
            isLoading={!props.loaded}
          />
        )}
      </AutoSizer>
    </div>
  );
};

SourceSummaryTable.propTypes = {
  loaded: PropTypes.bool.isRequired,
  sources: PropTypes.array.isRequired,
  balanceType: PropTypes.string,
  showTotalVolume: PropTypes.bool,
  showEnergy: PropTypes.bool,
  getFormattedMeasurementValueWithUnit: PropTypes.func,
};

export default connect(mapStateToProps)(SourceSummaryTable);
