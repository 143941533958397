import { useSearchParams } from "#routers/hooks";
import { useMeasurementTypes } from "#src/contexts/MeasurementTypeContext";
import { useStorageKey } from "#src/hooks/useStorageKey";
import useTableState from "#src/hooks/useTableState";
import { getFrontendTableState } from "#utils/frontendTableActions";
import { getConvertedUnitString } from "#utils/stringFormatter";
import {
  DataTable,
  DataTablePanel,
  HeaderType,
  SortingType,
} from "@validereinc/common-components";
import { SortDirection } from "@validereinc/domain";
import get from "lodash/get";
import * as PropTypes from "prop-types";
import React, { useCallback, useEffect } from "react";

const sorting: SortingType = {
  sortBy: "time",
  sortDirection: SortDirection.ASCENDING,
};

const RecordPanel = ({
  data,
  measurementSources,
  withTime,
  isLoading = false,
}) => {
  const { tableConfigStorageKey } = useStorageKey("devices-measurements");
  const { getUnitName, getTypeName } = useMeasurementTypes();

  const headers: Array<HeaderType<any>> = [
    {
      label: "Measurement",
      key: "measure_value",
      renderComponent: ({ item, dataKey }) =>
        getConvertedUnitString(
          get(item, dataKey),
          (value) => value,
          getUnitName(
            measurementSources.find(
              ({ measurement_type }) => item.measure_name === measurement_type
            )?.unit
          )
        ),
      isSortable: true,
    },
    {
      label: "Measurement Type",
      key: "measure_name",
      renderComponent: ({ item, dataKey }) => getTypeName(get(item, dataKey)),
      isSortable: true,
    },
    {
      label: "Timestamp",
      key: "time",
      renderComponent: ({ item }) => (
        <DataTable.DataRow.DateCell
          value={item.time}
          withTime={withTime}
        />
      ),
      isSortable: true,
    },
  ];

  const [searchParams] = useSearchParams();

  const onFetchData = useCallback(
    async (newSearchParams) => {
      const searchParamsWithDefaults = {
        ...newSearchParams,
        sort: newSearchParams.sort ?? sorting.sortBy,
        sortDirection: newSearchParams.sortDirection ?? sorting.sortDirection,
      };
      return getFrontendTableState({
        data: { data },
        itemsKey: "data",
        query: searchParamsWithDefaults,
      });
    },
    [data]
  );

  const { tableProps, fetchData } = useTableState({
    onFetchData,
    initialSort: sorting,
  });

  useEffect(() => {
    fetchData(searchParams);
  }, [data, searchParams]);

  return (
    <DataTablePanel
      storageKey={tableConfigStorageKey}
      panelProps={{
        title: "Measurements",
      }}
      dataTableProps={{
        ...tableProps,
        headers,
        isLoading,
      }}
    />
  );
};

RecordPanel.propTypes = {
  data: PropTypes.array.isRequired,
  measurementSources: PropTypes.array.isRequired,
  withTime: PropTypes.bool,
  isLoading: PropTypes.bool,
};

export default RecordPanel;
