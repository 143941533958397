import { getFirstValidValue } from "#utils/objectFormatter";

const displayProperties = (test) => {
  const vapourPressureTemperature = getFirstValidValue([
    test.metadata?.measuring_temperature_in_c,
    test.measurements?.["Vapour Pressure"]?.temperature_in_c,
  ]);
  const VapourPressureLabel = vapourPressureTemperature
    ? `Vapour Pressure @ ${vapourPressureTemperature} ℃`
    : "Vapour Pressure";

  return [
    {
      label: VapourPressureLabel,
      key: "Vapour Pressure",
    },
    {
      label: "Method",
      key: "method",
      notMeasurement: true,
    },
    {
      label: "DVPE",
      key: "dvpe",
      decimal: "3",
      unit: "kPa",
      notMeasurement: true,
    },
    {
      label: "Volume/Liquid",
      key: "vapor_to_liquid_ratio",
      notMeasurement: true,
    },
    {
      label: "Container Volume",
      key: "sample_container_volume",
      unit: "mL",
      notMeasurement: true,
    },
    {
      label: "Filling Temp.",
      key: "filling_temperature_in_c",
      unit: "℃",
      notMeasurement: true,
    },
    {
      label: "Pressure After 1st Expansion",
      key: "pressure_after_first_expansion",
      measurementKey: "Vapour Pressure",
    },
    {
      label: "Pressure After 2nd Expansion",
      key: "pressure_after_second_expansion",
      measurementKey: "Vapour Pressure",
    },
    {
      label: "Pressure After 3rd Expansion",
      key: "total_pressure_after_third_expansion_in_kpa",
      measurementKey: "Vapour Pressure",
    },
    {
      label: "Correlation Formula",
      key: "correlation_formula",
      notMeasurement: true,
    },
    {
      label: "Absolute Pressure",
      key: "absolute_pressure",
      measurementKey: "Vapour Pressure",
    },
    {
      label: "Absolute Pressure Cal.",
      key: "absolute_pressure_calculated",
      measurementKey: "Vapour Pressure",
    },
    {
      label: "Heat Rate",
      key: "heating_rate",
      notMeasurement: true,
    },
  ];
};

export default displayProperties;
