export * from "./EstimationMethodListPage";

export const CALCULATIONS_ESTIMATION_METHODS_PAGE_TITLE = "Estimation Methods";
export const ESTIMATION_METHOD_LIST_TAB_FILTER_KEY =
  "calculations_estimation_methods_filters";
export const ESTIMATION_METHOD_INPUT_TAB_FILTER_KEY =
  "estimation-method-input-filters";

export const linkToEstimationMethodListPage = () => {
  return `/app/calculations/calculations-estimation-methods`;
};

export const CALCULATIONS_ESTIMATION_METHODS_BREADCRUMB = {
  title: CALCULATIONS_ESTIMATION_METHODS_PAGE_TITLE,
  path: linkToEstimationMethodListPage,
};

export const CALCULATIONS_ESTIMATION_METHODS_TAB = {
  id: "calculations_estimation_methods",
  title: CALCULATIONS_ESTIMATION_METHODS_PAGE_TITLE,
};
