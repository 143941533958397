import React, { createContext, useEffect, useReducer } from "react";

import { EventsDomain } from "@validereinc/domain";

import type { EnrichedEventDetailsType } from "@validereinc/domain";

type EventContextType = {
  event?: EnrichedEventDetailsType | undefined;
  isLoading: boolean;
  onRefetchEvent: () => void;
};

export const EventContext = createContext<EventContextType | null>(null);

export const EventProvider = ({
  eventId,
  children,
}: {
  eventId: string;
  children: React.ReactElement | React.ReactElement[];
}) => {
  const dataReducer = (
    prevState: EventContextType,
    newState: Partial<EventContextType>
  ): EventContextType => ({ ...prevState, ...newState });

  const [data, setData] = useReducer(dataReducer, {
    event: undefined,
    isLoading: false,
  });

  const fetchEvent = async () => {
    try {
      setData({ isLoading: true });

      const newEvent = await EventsDomain.getOne({
        eventId,
      });

      setData({ event: newEvent });
    } catch (caught) {
      console.error(caught);
    } finally {
      setData({ isLoading: false });
    }
  };

  useEffect(() => {
    if (eventId) {
      fetchEvent();
    }
  }, [eventId]);

  return (
    <EventContext.Provider value={{ ...data, onRefetchEvent: fetchEvent }}>
      {children}
    </EventContext.Provider>
  );
};
