import { OrganizationRoute } from "#routes/organization";
import { RoutePath } from "#utils/route";

export const CustomAttributeRoute = OrganizationRoute.concat(
  new RoutePath({
    path: "/app/settings/custom-attributes",
    title: "Custom Attributes",
  })
);

export * from "./CustomAttributePage";
