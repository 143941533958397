import { useNetworkCalculationResult } from "#routes/organization/networks/[networkId]/detail/calculation-result/[period]/details/NetworkCalculationResultPage.helpers";
import { StatusPanel } from "@validereinc/common-components";
import { datetimeFormatter } from "@validereinc/utilities";
import React from "react";

export const NetworkCalculationResultStatusPanel = () => {
  const { run, userMap, isLoading } = useNetworkCalculationResult();

  const statusText = isLoading ? "-" : run ? "Run" : "Not Run";
  const statusVariant = isLoading ? undefined : run ? "success" : "warning";

  return (
    <StatusPanel
      data={[
        {
          label: "Date Calculated",
          value: run ? datetimeFormatter(new Date(run.created_at)) : "-",
        },
        {
          label: "Calculated By",
          value: userMap[run?.created_by]?.name ?? "-",
        },
      ]}
      isLoading={isLoading}
      statusText={statusText}
      statusVariant={statusVariant}
      isFluidY={false}
    />
  );
};
