import { useBreadcrumbs } from "#routers/breadcrumbsHelper";
import { DATA_TOOLS_BREADCRUMB } from "#routes/data-tools";
import {
  DATA_SUBMISSIONS_LIST_BREADCRUMB,
  DATA_SUBMISSIONS_LIST_TITLE,
} from "#routes/data-tools/submissions";
import { useStorageKey } from "#src/hooks/useStorageKey";
import { Page } from "@validereinc/common-components";
import React from "react";
import { DataSubmissionsFilterPanel, DataSubmissionsTablePanel } from "./";

export const DataSubmissionsListPage = () => {
  const storageKeys = useStorageKey("data-submissions");
  const breadcrumbs = useBreadcrumbs([
    DATA_TOOLS_BREADCRUMB,
    DATA_SUBMISSIONS_LIST_BREADCRUMB,
  ]);

  return (
    <Page
      title={DATA_SUBMISSIONS_LIST_TITLE}
      breadcrumbs={breadcrumbs}
    >
      <DataSubmissionsFilterPanel
        filterConfigStorageKey={storageKeys.filterConfigStorageKey}
      />
      <DataSubmissionsTablePanel {...storageKeys} />
    </Page>
  );
};
