import React, { useContext, useMemo } from "react";

import { KeyValuePanel, Link } from "@validereinc/common-components";

import { EventContext } from "#src/contexts/EventContext";
import { EventTemplateContext } from "#src/contexts/EventTemplateContext";

import useLocalization from "#src/hooks/useLocalization";

import { useNavigate } from "#src/Routers/hooks";

import { linkToFacilities } from "#src/Routers/links";

const keyLabelMapping = {
  alerts: "Alerts",
  devices: "Devices",
  equipment: "equipment_plural",
  form_submissions: "Form Submissions",
};

const configKeyMapping = {
  alerts: "has_alerts",
  devices: "has_devices",
  equipment: "has_equipment",
  form_submissions: "has_forms",
};

export const EventSummaryPanel = () => {
  const { localize } = useLocalization();

  const navigate = useNavigate();

  const { eventTemplate } = useContext(EventTemplateContext) ?? {};
  const { config } = eventTemplate;

  const { event } = useContext(EventContext) ?? {};
  const {
    // REVIEW: this sort of asset relationship is slated to be removed. Remove
    // when BE support is dropped and/or BE migrations have completed.
    facility: legacyFacility,
    associated_facility: associatedFacility,
    description,
    alerts,
    devices,
    equipment,
    form_submissions,
  } = event ?? {};

  const data = useMemo(() => {
    if (!event) {
      return [];
    }

    return Object.entries({
      alerts,
      form_submissions,
      equipment,
      devices,
    }).reduce(
      (accumulator, [key, { total }]) =>
        config?.[configKeyMapping[key]]
          ? [
              ...accumulator,
              { title: localize(keyLabelMapping[key]), value: total },
            ]
          : accumulator,
      []
    );
  }, [alerts, devices, equipment, form_submissions, config]);

  return (
    <KeyValuePanel
      panelMaxColumnCount={2}
      data={[
        ...(associatedFacility || legacyFacility
          ? [
              {
                title: localize("Facility"),
                value: (
                  <Link
                    onClick={() => {
                      navigate({
                        pathname: linkToFacilities(
                          associatedFacility?.id ?? legacyFacility?.id
                        ),
                      });
                    }}
                    label={
                      associatedFacility?.name ?? legacyFacility?.name ?? "-"
                    }
                  />
                ),
              },
            ]
          : []),
        ...(description
          ? [
              {
                title: "Description",
                value: description,
              },
            ]
          : []),
        ...data,
      ]}
      panelProps={{ title: "Event Details", isFluidY: false }}
    />
  );
};
