import { Tooltip } from "@validereinc/common-components";
import * as PropTypes from "prop-types";
import React from "react";
import FontAwesome from "react-fontawesome";
import "./AdjustmentFactorCell.scss";

const AdjustmentFactorsCell = ({ adjustmentFactors }) => {
  if (adjustmentFactors.length == 0) {
    return "-";
  }

  const title =
    adjustmentFactors.length > 1
      ? "Multiple Adjustment Factors"
      : adjustmentFactors[0].name;

  const tooltip = (
    <>
      <b>Adjustment Factors</b>
      <ul>
        {adjustmentFactors.map((factor) => (
          <li key={factor.id}>{factor.name}</li>
        ))}
      </ul>
    </>
  );

  return adjustmentFactors.length > 1 ? (
    <Tooltip content={tooltip}>
      <span className="adjustmentFactorsCell__title">{title}</span>
      <FontAwesome
        name="info-circle"
        style={{ marginLeft: "3px" }}
      />
    </Tooltip>
  ) : (
    <span className="adjustmentFactorsCell__title">{title}</span>
  );
};

AdjustmentFactorsCell.propTypes = {
  adjustmentFactors: PropTypes.array.isRequired,
};

export default AdjustmentFactorsCell;
