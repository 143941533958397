/**
 * Formats a list so that it can be used in a top-n list like top 5 or top 10
 * it can either return a list of the top n items or sum the rest of the items
 * into an "other" item as the last item in the list
 * @param list - The list to convert to a top list
 * @returns the list in top list format
 */
export const getTopNumberList = (
  list: any[],
  total = 10,
  hasOther = true,
  mass = "mass_co2eq"
): any[] => {
  // sort the list by the value in descending order
  const sortedList = list?.sort(
    (a, b) => b.measurement[mass] - a.measurement[mass]
  );
  // if the list is less than the total, return the list
  if (sortedList?.length <= total) {
    return sortedList;
  }
  // if the list is greater than the total, return the top n items
  const sliceTotal = hasOther ? total - 1 : total;
  const topNumberList = sortedList?.slice(0, sliceTotal);
  // if we are summing up the rest of the items into an "other" item
  if (hasOther) {
    // get the sum of the rest of the items

    // this is a bug here where the total is not the sum of the other items

    const otherValue = sortedList
      ?.slice(total - 1)
      ?.reduce((sum, item) => sum + item.measurement[mass], 0);
    // add the "other" item to the end of the list
    topNumberList?.push({
      "equipment.type.name": "Other",
      measurement: { [mass]: otherValue },
    });
  }

  return topNumberList;
};
