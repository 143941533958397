import config from "#src/config";
import { getTimeStringFromDate } from "#utils/timeFormatter";
import { useAlert } from "@validereinc/common-components";
import moment from "moment";
import PropTypes from "prop-types";
import TimePicker from "rc-time-picker";
import React, { useEffect, useRef, useState } from "react";
import { Checkbox } from "react-bootstrap";
import FontAwesome from "react-fontawesome";
import { Link } from "react-router-dom";
import { linkToUserSetting } from "../../../../Routers/links";
import NotificationService from "../../../Services/NotificationService";
import "./AlertDigestEmailSettings.scss";

const InputIcon = <FontAwesome name="caret-down" />;

export const AlertDigestEmailSettings = (props) => {
  const isMount = useRef(true);
  const { addAlert } = useAlert();

  const [weeklyDigestTime, setWeeklyDigestTime] = useState(
    moment(
      props.profile.digest_settings.weekly_digest_time,
      config.TIME_MINUTE_FORMAT
    )
  );

  const [pickerTime, setPickerTime] = useState(
    moment(
      props.profile.digest_settings.weekly_digest_time,
      config.TIME_MINUTE_FORMAT
    )
  );

  const [weeklyDigestWeekday, setWeeklyDigestWeekday] = useState(
    props.profile.digest_settings.weekly_digest_weekday
  );

  const [isWeeklyDigestEnabled, setIsWeeklyDigestEnabled] = useState(
    props.profile.digest_settings.is_weekly_digest_enabled
  );

  useEffect(() => {
    if (isMount.current) {
      isMount.current = false;
    } else {
      setAlertDigestPreferences();
    }
  }, [weeklyDigestTime, weeklyDigestWeekday, isWeeklyDigestEnabled]);

  const onTimePickerChange = (time) => {
    setPickerTime(time);
  };

  const onTimePickerClose = () => {
    setWeeklyDigestTime(pickerTime);
  };

  const onWeekdayChange = (weekday) => {
    setWeeklyDigestWeekday(weekday);
  };

  const onCheckboxChange = () => {
    setIsWeeklyDigestEnabled(!isWeeklyDigestEnabled);
  };

  const setAlertDigestPreferences = () => {
    const newAlertDigestTimePreferences = {
      time: getTimeStringFromDate(weeklyDigestTime, config.TIME_FORMAT),
      weekday: parseInt(weeklyDigestWeekday),
      is_enabled: isWeeklyDigestEnabled,
    };

    NotificationService.updateUserAlertDigestEmailTimePreferences(
      newAlertDigestTimePreferences
    ).then(() => {
      addAlert({
        variant: "success",
        message: "Alert digest preferences have been updated.",
      });
      props.fetchUserProfile();
    });
  };

  const timePickerClass = (isEnabled) => {
    return isEnabled
      ? "alertDigestEmailSettings__timePicker"
      : "alertDigestEmailSettings__timePicker alertDigestEmailSettings__disabledControl";
  };

  const weekdayPickerClass = (isEnabled) => {
    return isEnabled
      ? "alertDigestEmailSettings__weekdayPicker"
      : "alertDigestEmailSettings__weekdayPicker alertDigestEmailSettings__disabledControl";
  };

  const weekdayList = [
    { name: "Monday", value: 1 },
    { name: "Tuesday", value: 2 },
    { name: "Wednesday", value: 3 },
    { name: "Thursday", value: 4 },
    { name: "Friday", value: 5 },
    { name: "Saturday", value: 6 },
    { name: "Sunday", value: 7 },
  ];

  return (
    <div className="alertDigestEmailSettings">
      <Checkbox
        className="alertDigestEmailSettings__checkbox"
        checked={isWeeklyDigestEnabled}
        onChange={onCheckboxChange}
      >
        Receive weekly alert digest emails every:
      </Checkbox>
      <select
        className={weekdayPickerClass(isWeeklyDigestEnabled)}
        value={weeklyDigestWeekday}
        disabled={!isWeeklyDigestEnabled}
        onChange={(event) => onWeekdayChange(event.target.value)}
      >
        {weekdayList.map((day) => (
          <option
            key={`${day.name}_${day.value}`}
            value={day.value}
          >
            {day.name}
          </option>
        ))}
      </select>
      <TimePicker
        value={pickerTime}
        className={timePickerClass(isWeeklyDigestEnabled)}
        format={config.TIME_MINUTE_FORMAT}
        onChange={onTimePickerChange}
        onClose={onTimePickerClose}
        use12Hours={true}
        disabled={!isWeeklyDigestEnabled}
        allowEmpty={false}
        showSecond={false}
        showMinute={false}
        inputIcon={InputIcon}
      />
      {props.profile.timezone_preference}
      <Link to={linkToUserSetting("profile")}>&nbsp;(change timezone)</Link>
    </div>
  );
};

AlertDigestEmailSettings.propTypes = {
  profile: PropTypes.object.isRequired,
  fetchUserProfile: PropTypes.func.isRequired,
};
