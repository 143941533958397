export const getStreamSettingsBreadcrumbs = (
  selectedStreams,
  setPage,
  page
) => {
  if (page === "create" && selectedStreams.length === 1) {
    return [
      {
        title: "Stream Alerts",
        onClick: () => setPage("view"),
      },
      { title: selectedStreams[0].name, onClick: () => setPage("details") },
      { title: "Create Stream Alert" },
    ];
  } else if (page === "create") {
    return [
      {
        title: "Stream Alerts",
        onClick: () => setPage("view"),
      },
      { title: "Create Stream Alert" },
    ];
  } else if (page === "details") {
    return [
      {
        title: "Stream Alerts",
        onClick: () => setPage("view"),
      },
      {
        title: selectedStreams[0].name,
      },
    ];
  }
};
