import React from "react";
import { CustomReportListTablePanel } from "#batteries-included-components/Panels/TablePanels/CustomReportListTablePanel";
import { CustomReportVariants } from "#batteries-included-components/Layouts/CustomReport/CustomReportDetailLayout.helpers";
import { CustomReportListFilterPanel } from "#batteries-included-components/Panels/FilterPanels/CustomReportListFilterPanel";
import { useStorageKey } from "#src/hooks/useStorageKey";

export const EmissionCustomReportListTab = ({
  isOldSidebarLocation,
}: {
  isOldSidebarLocation?: boolean;
}) => {
  const storageKeys = useStorageKey("emissions-custom-report-list");

  return (
    <>
      <CustomReportListFilterPanel
        filterConfigStorageKey={storageKeys.filterConfigStorageKey}
      />
      <CustomReportListTablePanel
        variant={CustomReportVariants.EMISSIONS}
        isOldSidebarLocation={isOldSidebarLocation}
        {...storageKeys}
      />
    </>
  );
};
