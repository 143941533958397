import { Stepper } from "@validereinc/common-components";
import * as PropTypes from "prop-types";
import React from "react";
import { STEPS, TITLE } from "../DataMappingConstants";
import ValidationForm from "./ValidationForm";

const CURRENT_STEP = 4;

const CreateValidationForm = ({ breadcrumbs }) => {
  const actionRow = (
    <Stepper
      steps={STEPS}
      activeStep={CURRENT_STEP}
    />
  );

  return (
    <ValidationForm
      breadcrumbs={breadcrumbs}
      title={TITLE}
      actionRow={actionRow}
      successfulMessage="Successfully created a new Data Mapping"
    />
  );
};

CreateValidationForm.propTypes = {
  breadcrumbs: PropTypes.array.isRequired,
};

export default CreateValidationForm;
