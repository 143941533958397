import ReactLoader from "#components/LoadingBar/ReactLoader";
import { ACCOUNTING_RECORD_NOTE, useFetchNotes } from "#services/NotesService";
import { Modal, Tab, Tabs, Title } from "@validereinc/common-components";
import styles from "@validereinc/common-components/constants";
import * as PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Badge } from "react-bootstrap";
import FontAwesome from "react-fontawesome";
import { getStatus } from "../RecordOfQualityHelper";
import { useSelectionModalContext } from "./Context/selectionModalContext";
import RecordOfQualityNotes from "./Notes/RecordOfQualityNotes";
import RecordOfQualityOverview from "./Overview/RecordOfQualityOverview";
import "./RecordOfQualityModal.scss";
import ResetSelectionModal from "./ResetSelectionModal";

// minimum to be able to see footer in 1440x900 without scrolling
// maximum is able to fit on RoQSelection with one table without scrolling
const MIN_MODAL_BODY_HEIGHT = 640;
const MAX_MODAL_BODY_HEIGHT = 950;

const getModalHeight = (pageHeight) => {
  const height = Math.max(pageHeight, MIN_MODAL_BODY_HEIGHT);

  return Math.min(height, MAX_MODAL_BODY_HEIGHT);
};

const TAB_KEYS = {
  overview: "overview",
  notes: "notes",
};
const DEFAULT_TAB_KEY = TAB_KEYS.overview;

const ACTIVE_STYLE = { color: styles.validereColour };

const OverviewTabTitle = ({ activeTabKey }) => (
  <div className="tabs__title">
    <FontAwesome
      name="list"
      className="icon"
      style={activeTabKey === TAB_KEYS.overview ? ACTIVE_STYLE : null}
    />
    <span>Overview</span>
  </div>
);

const NotesTabTitle = ({ activeTabKey, notes }) => {
  return (
    <div className="tabs__title">
      <FontAwesome
        name="file"
        className="icon"
        style={activeTabKey === TAB_KEYS.notes ? ACTIVE_STYLE : null}
      />
      <span>Notes</span>{" "}
      <Badge
        style={{
          backgroundColor: styles.validereColour,
          opacity: notes.length ? 1 : 0,
        }}
      >
        {notes.length}
      </Badge>
    </div>
  );
};

const getSaveIcon = (loadingState, actionState) => {
  if (loadingState !== "refetching" && loadingState !== "loaded") {
    return null;
  }

  const isRefetching =
    loadingState === "refetching" || actionState === "disabled";

  const iconName = isRefetching ? "refresh" : "check";
  return (
    <div className="recordOfQualityModal__saveStatus">
      <div className="fa-stack icon">
        <FontAwesome
          name="cloud"
          stack="2x"
        />

        <FontAwesome
          name={iconName}
          style={{ color: "white" }}
          spin={isRefetching}
          stack="1x"
        />
      </div>

      {isRefetching ? "Saving..." : "Saved"}
    </div>
  );
};

const RecordOfQualityModal = ({ show, onHide, height }) => {
  const {
    recordOfQualityDetail,
    resultLoadingState,
    accountingRecordDetail,
    onModalClose,
    actionState,
  } = useSelectionModalContext();

  const [showResetSelectionModal, setShowResetSelectionModal] = useState(false);

  const [activeTabKey, setActiveTabKey] = useState(DEFAULT_TAB_KEY);
  const [areNotesLoading, _errorStatus, notes, fetchNotes] = useFetchNotes(
    accountingRecordDetail.id,
    ACCOUNTING_RECORD_NOTE
  );

  useEffect(() => {
    setActiveTabKey(TAB_KEYS.overview);
  }, [accountingRecordDetail]);

  const getModalTitle = (recordOfQualityDetail) => {
    if (accountingRecordDetail) {
      return `${accountingRecordDetail.stream?.name} ${
        recordOfQualityDetail.category === "inventory_closing"
          ? "(Closing Inventory)"
          : ""
      }`;
    }

    return "-";
  };

  const onSelect = (newTabKey) => setActiveTabKey(newTabKey);

  const onClose = () => {
    onModalClose();
    onHide();
  };

  const modalBodyHeight = getModalHeight(height);

  return (
    <div>
      <Modal
        open={show}
        onClose={onClose}
        className="recordOfQualityModal"
      >
        <Modal.Header>
          <div className="recordOfQualityModal__title">
            <Title>{getModalTitle(recordOfQualityDetail)}</Title>

            {getStatus(recordOfQualityDetail?.state)}

            {getSaveIcon(resultLoadingState, actionState)}
          </div>
        </Modal.Header>

        <ReactLoader
          loaded={resultLoadingState !== "loading"}
          position={50}
        >
          <div className="recordOfQualityModal__content">
            <Tabs
              activeKey={activeTabKey}
              onChange={onSelect}
            >
              <Tab
                tabKey={TAB_KEYS.overview}
                title="Overview"
              >
                <RecordOfQualityOverview
                  modalBodyHeight={modalBodyHeight}
                  setShowResetSelectionModal={setShowResetSelectionModal}
                  onClose={onClose}
                />
              </Tab>

              <Tab
                tabKey={TAB_KEYS.notes}
                title="Notes"
              >
                <RecordOfQualityNotes
                  modalBodyHeight={modalBodyHeight}
                  accountingRecordId={accountingRecordDetail.id}
                  isLoading={areNotesLoading}
                  notes={notes}
                  fetchNotes={fetchNotes}
                />
              </Tab>
            </Tabs>
          </div>
        </ReactLoader>
      </Modal>

      <ResetSelectionModal
        show={showResetSelectionModal}
        recordOfQualityDetail={recordOfQualityDetail}
        onModalClose={() => setShowResetSelectionModal(false)}
      />
    </div>
  );
};

OverviewTabTitle.propTypes = {
  activeTabKey: PropTypes.string,
};

NotesTabTitle.propTypes = {
  activeTabKey: PropTypes.string,
  notes: PropTypes.array,
};

RecordOfQualityModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  profile: PropTypes.object,
  height: PropTypes.number,
};

export default RecordOfQualityModal;
