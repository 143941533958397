import React, { useMemo } from "react";
import DropdownInputWithSearch from "../../../../Inputs/DropdownInputWithSearch";
import { useRecordSelectionContext } from "../Context/recordSelectionContext";
import styles from "./SampleSelections.module.scss";
import classNames from "classnames/bind";
import moment from "moment";
import filter from "lodash/filter";
import { METHOD_OPTIONS } from "#components/Records/Quality/RecordOfQualityConstants";
import SampleSelectionForm from "./SampleSelectionForm";

const cx = classNames.bind(styles);

const SampleSelections = () => {
  const {
    roqSelectionState,
    accountingRecordDetail,
    stream,
    streams,
    onStreamSelect,
  } = useRecordSelectionContext();

  const { intervalSelections } = roqSelectionState;

  const visibleMethodOptions = useMemo(() => {
    const { preSelectedValues } = roqSelectionState;
    const balanceType = accountingRecordDetail.accounting_period.balance_type;
    const category = accountingRecordDetail.category;
    let methodOptions = [...METHOD_OPTIONS];

    // Filter out unselectable options
    methodOptions = filter(methodOptions, (method) => method.id !== "multiple");

    // Filter out pervious accounting record option due to there is no selection
    if (!preSelectedValues?.previousAccountingRecord) {
      methodOptions = filter(
        methodOptions,
        (method) => method.id !== "previous_accounting_record"
      );
    }

    if (category === "inventory_closing") {
      return methodOptions.filter((method) => method.inventory);
    }

    if (balanceType === "oil") {
      return methodOptions.filter((method) => method.oil);
    }

    return methodOptions.filter((method) => method.default);
  }, [
    roqSelectionState.preSelectedValues,
    accountingRecordDetail.accounting_period.balance_type,
  ]);

  return (
    <div>
      <div className={cx("dropdownContainer")}>
        <div className={cx("dropdownTitle")}>Stream</div>

        <DropdownInputWithSearch
          className={cx("streamDropdown")}
          value={stream?.name}
          options={streams}
          onSelect={onStreamSelect}
          filterKey={"name"}
        />
      </div>

      {intervalSelections.map((interval, index) => {
        const nextIntervalUntil = intervalSelections[index + 1]?.until
          ? moment(intervalSelections[index + 1].until).subtract(1, "day")
          : moment(accountingRecordDetail.accounting_period?.until);

        return (
          <SampleSelectionForm
            key={moment(interval.from).valueOf()}
            interval={interval}
            intervalSelectionIndex={index}
            visibleMethodOptions={visibleMethodOptions}
            nextIntervalUntil={nextIntervalUntil}
          />
        );
      })}
    </div>
  );
};

export default SampleSelections;
