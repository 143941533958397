import Immutable from "immutable";

import {
  FETCH_ALERT,
  FETCH_ALERT_ERROR,
  USER_SIGNED_OUT,
} from "../constants/action-types";

const alert = Immutable.Record({
  id: undefined,
  company_id: undefined,
  workflow_instance_id: undefined,
  alert_instance_type: undefined,
  type: undefined,
  triggered_at_utc_epoch: undefined,
  threshold_triggered: undefined,
  test_property: undefined,
  std_sigma: undefined,
  std_num_measurements: undefined,
  std_min_num_measurements: undefined,
  std_seconds_in_past: undefined,
  measurement_value: undefined,
  measurement_unit: undefined,
  sample_type: undefined,
  subject: undefined,
  message: undefined,
});

export const data = new Immutable.Set([]);

export const AlertState = Immutable.Record(
  {
    fetchError: false,
    fetchErrorMessage: "",
    fetchErrorStatus: "",
    fetchEtag: null,
    data: data,
  },
  "AlertState"
);

export default (state = new AlertState(), action) => {
  let newData = new Immutable.Set([]);

  switch (action.type) {
    case FETCH_ALERT:
      action.payload.forEach(function (alertItem) {
        newData = newData.add(new alert(alertItem));
      });

      return state.withMutations((s) => {
        s.data = newData;
        s.fetchEtag = action.ETag;
        s.fetchError = false;
        s.fetchErrorMessage = "";
        s.fetchErrorStatus = "";
      });

    case FETCH_ALERT_ERROR:
      return state.withMutations((s) => {
        s.fetchError = true;
        s.fetchErrorMessage = action.payload.message;
        s.fetchErrorStatus = action.payload.status;
      });

    case USER_SIGNED_OUT:
      return state.withMutations((s) => {
        s.data = s.data = new Immutable.Set([]);
        s.fetchEtag = null;
        s.fetchError = false;
        s.fetchErrorMessage = "";
        s.fetchErrorStatus = "";
      });

    default:
      return state;
  }
};
