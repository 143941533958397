import intersectionWith from "lodash/intersectionWith";
import find from "lodash/find";
import BalancesServices from "#services/BalancesService";
import { useEffect, useState } from "react";
import { getPropertyAsMap } from "#utils/objectFormatter";

export function getSiteDetails(siteDetailList, site) {
  return intersectionWith(
    siteDetailList,
    site,
    (siteDetail, site) => siteDetail.id === site
  );
}

export function getStreamWithSiteDetail(streams, sites) {
  return streams.map((stream) => {
    stream.restrict_to_sites = getSiteDetails(
      sites,
      stream.restrict_to_site_ids
    );
    return stream;
  });
}

export function getInventoriesWithStreamDetails(sites, streams, inventories) {
  const streamsMap = getPropertyAsMap(streams);

  const sitesNameMap = getPropertyAsMap(sites, "name");

  return inventories.map((inventory) => {
    const stream = streamsMap[inventory.active_stream_id];

    return {
      ...inventory,
      site_name: sitesNameMap[inventory.site_id],
      product_type: stream?.product_type,
    };
  });
}

function getSiteAndStreamDetail(sourceInputs, streams) {
  return sourceInputs.map((sourceInput) => {
    const stream = find(streams, { id: sourceInput.id });

    if (stream) {
      return { ...stream, multiplier: sourceInput.multiplier };
    } else {
      return sourceInput;
    }
  });
}

function getInventoryDetail(sourceInputs, inventories) {
  return sourceInputs.map((sourceInput) => {
    const inventory = find(inventories, { id: sourceInput.id });

    if (inventory) {
      return { ...inventory, multiplier: sourceInput.multiplier };
    } else {
      return sourceInput;
    }
  });
}

const isInventoryType = (sourceKey) => sourceKey.includes("inventories");

// sourceOptions is inlet_streams, inlet_inventories, etc.
export function getSourceWithSiteAndStreamDetail(
  streams,
  sites,
  inventories,
  balancesDetail,
  sourceOptions
) {
  const inventoriesWithStreamDetails = getInventoriesWithStreamDetails(
    sites,
    streams,
    inventories
  );

  const streamsWithSiteDetail = getStreamWithSiteDetail(streams, sites);

  const updatedBalanceDetail = { ...balancesDetail };

  Object.keys(sourceOptions).forEach((source) => {
    if (balancesDetail[source]?.length) {
      if (isInventoryType(source)) {
        updatedBalanceDetail[source] = getInventoryDetail(
          balancesDetail[source],
          inventoriesWithStreamDetails
        );
      } else {
        updatedBalanceDetail[source] = getSiteAndStreamDetail(
          balancesDetail[source],
          streamsWithSiteDetail
        );
      }
    }
  });

  updatedBalanceDetail.showTotalVolume = balancesDetail.show_total_volume
    ? "yes"
    : "no";

  updatedBalanceDetail.showEnergy = balancesDetail.show_energy ? "yes" : "no";

  updatedBalanceDetail.volumeUnit = balancesDetail.volume_unit;

  return updatedBalanceDetail;
}

export function getSourceTitle(sourceKey) {
  return sourceKey?.replace("_", " ") ?? "-";
}

export const useFetchBalanceList = (lastUpdatedDate) => {
  const [balancesList, setBalancesList] = useState([]);

  useEffect(() => {
    BalancesServices.getBalances().then(({ data }) => {
      setBalancesList(data);
    });
  }, [lastUpdatedDate]);

  return [balancesList];
};

export const getTotalLabel = (balanceType) => {
  return balanceType === "gas" ? "Total Meter GEV" : "Total Meter Volume";
};
