import { ensureStreamListIsFetched, fetchSiteList } from "#redux/actions/index";
import { filterByUsedBy } from "#redux/reducers/measurements";
import { getBreadcrumbsObject } from "#routers/breadcrumbsHelper";
import { linkToManageWorkflows } from "#routers/links";
import WorkflowService from "#services/WorkflowService";
import history from "#src/Routers/history";
import {
  Button,
  Form,
  FormButton,
  Page,
  Panel,
  useAlert,
  useForm,
} from "@validereinc/common-components";
import classNames from "classnames/bind";
import get from "lodash/get";
import isNil from "lodash/isNil";
import * as PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import WorkflowForm from "./WorkflowForm";
import styles from "./WorkflowForm.module.scss";
import {
  DEFAULT_INPUTS,
  formatTimezoneName,
  prepareWorkflow,
  WEEK,
} from "./WorkflowFormHelpers";
import WorkflowPreview from "./WorkflowPreview";

const cx = classNames.bind(styles);

const mapStateToProps = ({ profiles, streams, sites, measurements }) => {
  return {
    profiles: profiles.data ?? {},
    streams: streams.data?.toJS() ?? [],
    sites: sites.data?.toJS() ?? [],
    fieldLabTypes: measurements.measurementTypesList
      .filter(filterByUsedBy("field"))
      .map((measurementType) => measurementType.name),
    thirdPartyTypes: measurements.measurementTypesList
      .filter(filterByUsedBy("third_party"))
      .map((measurementType) => measurementType.name),
  };
};

const mapDispatchToProps = {
  ensureStreamListIsFetched,
  fetchSiteList,
};

const CreateWorkflow = ({
  profiles,
  breadcrumbs,
  match,
  location,
  fieldLabTypes,
  thirdPartyTypes,
  ensureStreamListIsFetched,
  fetchSiteList,
  streams,
  sites,
}) => {
  const duplicateWorkflowData = get(location, "state", null);
  const [formState, setFormState] = useState("enabled");
  const { addAlert } = useAlert();

  const form = useForm({
    defaultValues: DEFAULT_INPUTS,
  });

  const handleSubmit = (formInputs) => {
    setFormState("loading");

    WorkflowService.createWorkflow(prepareWorkflow(formInputs))
      .then(() => {
        addAlert({
          variant: "success",
          message: "A workflow has been successfully created.",
        });
        setFormState("preview");
      })
      .catch(() => {
        setFormState("enabled");
      });
  };

  const onAddAnotherWorkflowClick = () => {
    form.reset();
    setFormState("enabled");
  };

  useEffect(() => {
    if (!isNil(duplicateWorkflowData)) {
      form.reset(duplicateWorkflowData);
    }
  }, [duplicateWorkflowData]);

  useEffect(() => {
    ensureStreamListIsFetched();
    fetchSiteList();

    if (profiles.id) {
      form.setValue(
        "timezone",
        formatTimezoneName(profiles.timezone_preference)
      );
    }
  }, []);

  const inputs = form.watch();

  const requireTestsRequired = inputs.testType?.id !== "manual";

  const pageBreadcrumbs = getBreadcrumbsObject(breadcrumbs, match.params);

  const measurementTypes =
    inputs.testType?.id === "field"
      ? fieldLabTypes
      : inputs.testType?.id === "third_party_lab"
        ? thirdPartyTypes
        : [];

  const scheduleInput = useMemo(() => {
    const repeatsDay = WEEK.map((day, index) =>
      inputs.repeatsDay?.includes(day) ? index : undefined
    ).filter((d) => d !== undefined);

    return {
      startDay: inputs.startDay,
      repeatsDay: repeatsDay,
      frequencySelected: inputs.frequencyType?.id,
      repeats: inputs.repeats,
      timezone: inputs.timezone,
      repeatNumTimesPerDay: inputs.repeatNumTimesPerDay,
      occurrences: inputs.occurrences,
    };
  }, [inputs]);

  return (
    <Page
      title="Create New Workflow"
      breadcrumbs={pageBreadcrumbs}
    >
      <div className={cx("container")}>
        <Panel className={cx("panel")}>
          <Form
            onSubmit={handleSubmit}
            {...form}
          >
            <WorkflowForm
              form={form}
              inputs={inputs}
              streamList={streams}
              siteList={sites}
              measurementTypes={measurementTypes}
              disabled={formState !== "enabled"}
              requireTestsRequired={requireTestsRequired}
            />

            <div className="clearfix">
              {formState === "preview" ? (
                <>
                  <Button onClick={() => history.push(linkToManageWorkflows())}>
                    View Workflows
                  </Button>

                  <Button
                    variant="primary"
                    className="pull-right"
                    onClick={onAddAnotherWorkflowClick}
                  >
                    Create Another Workflow
                  </Button>
                </>
              ) : (
                <FormButton
                  variant="primary"
                  className="pull-right"
                  type="submit"
                  disabled={formState !== "enabled"}
                  isLoading={formState === "loading"}
                >
                  Submit
                </FormButton>
              )}
            </div>
          </Form>
        </Panel>

        <div>
          <WorkflowPreview
            initialMonth={inputs.startDay}
            scheduleInput={scheduleInput}
          />
        </div>
      </div>
    </Page>
  );
};

CreateWorkflow.propTypes = {
  profiles: PropTypes.object.isRequired,
  breadcrumbs: PropTypes.array,
  match: PropTypes.object,
  fieldLabTypes: PropTypes.array,
  thirdPartyTypes: PropTypes.array,
  addAlertMessage: PropTypes.func.isRequired,
  location: PropTypes.object,
  ensureStreamListIsFetched: PropTypes.func.isRequired,
  fetchSiteList: PropTypes.func.isRequired,
  streams: PropTypes.array.isRequired,
  sites: PropTypes.array.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateWorkflow);
