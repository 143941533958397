import { FacilityDropdownInput } from "#batteries-included-components/Dropdowns/FacilityDropdownInput";
import { ReportingGroupFilterSwitcher } from "#batteries-included-components/Filters/ReportingGroupFilterSwitcher";
import CustomAttributeField from "#common/CustomAttributeField";
import {
  SavedFilterTag,
  useSavedFilters,
} from "#src/components/hooks/FilterPanel/useSavedFilters";
import { useListCustomAttributes } from "#src/components/hooks/adapters/useCustomAttributes";
import { FACILITY_STATUS_OPTIONS } from "#src/constants";
import { useMeasurementTypes } from "#src/contexts/MeasurementTypeContext";
import { DEFAULT_DATE_RANGES } from "#src/hooks/useDateRange";
import useLocalization from "#src/hooks/useLocalization";
import { useQuery } from "@tanstack/react-query";
import {
  DateSelectorInput,
  DropdownInput,
  FilterPanel,
  StorageKeys,
  TextInput,
  useFilters,
} from "@validereinc/common-components";
import {
  AssetType,
  FacilityStatus,
  RecordDomain,
  RecordFilterType,
  ReportingGroupDomain,
  Resources,
} from "@validereinc/domain";
import React from "react";

const defaultDate = DEFAULT_DATE_RANGES.currentWholeMonth;

export const FacilityRecordFilterPanel = ({
  filterConfigStorageKey,
  selectableProperties,
}: Pick<StorageKeys, "filterConfigStorageKey"> & {
  selectableProperties?: string[];
}) => {
  const { getTypeName } = useMeasurementTypes();
  const propertySelectorValues = selectableProperties?.map((key) => ({
    key,
    name: getTypeName(key),
  }));
  const { localize, isLoading: isMappingLoading } = useLocalization();

  const { data: reportingGroups, isLoading: isReportingGroupDataLoading } =
    useQuery(["reportingGroups"], () => ReportingGroupDomain.getList(), {
      select: ({ data }) => data ?? [],
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    });

  const [_, setFilters] = useFilters(filterConfigStorageKey);
  const { filterProps } = useSavedFilters<RecordFilterType>({
    queryKey: [Resources.RECORD],
    savedFilterResourceAdapter: RecordDomain.savedFilters,
    setFilters,
    filterKeyBlacklist: ["year_month.from", "year_month.to"],
    tag: SavedFilterTag.FACILITY_RECORDS,
  });

  const {
    data: customAttributesData,
    isLoading: isCustomAttributeDataLoading,
  } = useListCustomAttributes({ filters: { entity_type: AssetType.FACILITY } });
  const customAttributes = customAttributesData?.data;

  const isLoading =
    isCustomAttributeDataLoading ||
    isReportingGroupDataLoading ||
    isMappingLoading;

  return (
    <FilterPanel
      {...filterProps}
      storageKey={filterConfigStorageKey}
      defaultValue={{
        year_month: DEFAULT_DATE_RANGES.lastWholeMonth,
        reporting_group_id: reportingGroups?.[0]?.id,
        "facility.status": [FacilityStatus.ACTIVE],
      }}
      isLoading={isLoading}
      defaultActiveKeys={["Filters"]}
      filters={[
        {
          component: (
            <ReportingGroupFilterSwitcher
              key="reporting_group_id"
              name="reporting_group_id"
              options={reportingGroups}
              shouldSelectFirstOptionAsDefault
            />
          ),
        },
        {
          component: (
            <DateSelectorInput
              key="year_month"
              name="year_month"
              variant="month"
              isRange
              isInline
            />
          ),
        },
        {
          component: (
            <TextInput
              name="facility.name"
              placeholder="Search Facility..."
              type="search"
              isInline
            />
          ),
        },
        {
          component: (
            <DropdownInput
              key="measurement_type"
              name="measurement_type"
              options={propertySelectorValues}
              placeholder="Select Property..."
              labelKey="name"
              valueKey="key"
              isSearchable
              isInline
              isOptionalTextShown={false}
            />
          ),
        },
        {
          section: "Filters",
          component: (
            <FacilityDropdownInput
              key="facility.id"
              name="facility.id"
              isMulti
              isFluid
              isOptionalTextShown={false}
            />
          ),
        },
        {
          section: "Filters",
          component: (
            <DropdownInput
              key="facility.status"
              name="facility.status"
              options={FACILITY_STATUS_OPTIONS}
              label={localize("Facility Status")}
              labelKey="label"
              valueKey="value"
              isMulti
              isFluid
              isSearchable
              isOptionalTextShown={false}
            />
          ),
        },
        ...(customAttributes?.map((props: any, index: number) => ({
          section: "Filters",
          component: (
            <CustomAttributeField
              name={`facility.custom_attributes.${props.field_name}`}
              attribute={{ ...props, is_required: false }}
              isDisabled={false}
              isOptionalTextShown={false}
              isFilterForm
              key={`filter-${index}`}
            />
          ),
        })) ?? []),
      ]}
    />
  );
};
