import { useState, useEffect } from "react";
import debounce from "lodash/debounce";
import RecordOfQualityService from "#services/RecordOfQualityService";
import { isCurrentSelectionEqualToInitial } from "./recordSelectionContextHelper";
import { AlertMessage } from "#redux/reducers/alertMessages";
import validateRoQSelection, {
  ROQ_APPROVED,
  ROQ_UNAPPROVED,
  ROQ_RESET,
} from "./validateRoQSelection";

/**
 * Stale record error occurs if a save request occurs while an earlier request
 * is still being processed. The average response time of the save request is
 * ~800ms in integration, which is the total time to receive/process/return
 * the request. Using this time as a conservative wait time
 *
 * Note: debouncing will only make stale record unlikely, not impossible
 */
const SAVE_WAIT_TIME = 800;

/**
 * roqSelectionState.selectionStreamId is changed after 2 things:
 *   (1) After RoQDetail fetch => roqSelectionState initialization
 *   (2) onStreamSelect
 * Both cases require preselectedValues to be re-selected before the values
 * can be used/saved. The preSelectedValues object stores the streamId the values
 * were selected for. When both are equal, roqSelectionState is in a consistent
 * state and can be used/saved.
 */
const isPreselectedValuesSelected = (roqSelectionState) => {
  return (
    roqSelectionState.selectionStreamId ===
    roqSelectionState.preSelectedValues?.selectionStreamId
  );
};

/**
 * lodash debounce works only if the function is the same throughout, (e.g.
 * it is not redefined every re-render by using useCallback or moving it outside
 * render tree.) For both solutions, need to explicitly define all inputs
 */
const saveSelection = (id, roqSelectionState, approveRecord = false) => {
  return RecordOfQualityService.updateRecordOfQuality(
    id,
    roqSelectionState,
    approveRecord
  );
};

/**
 * The debounce function returns a normal function that can't be chained to.
 * To use with promises, use a function that accepts an anonymous `then` and
 * `finally` block as arguments and return a promise chain that can use the
 * differing id and roqSelectionState at the time of invocation
 *
 * leading option is to start the request right away if it hasn't been called
 * in the last `SAVE_WAIT_TIME` milliseconds
 */
const debouncedSaveSelection = debounce(
  (id, roqSelectionState, resolve, cleanup) => {
    saveSelection(id, roqSelectionState).then(resolve).finally(cleanup);
  },
  SAVE_WAIT_TIME,
  { leading: true }
);

const useManageSubmission = (
  roqSelectionState,
  accountingRecordDetail,
  addAlertMessage,
  updateRecordDetail,
  refetchResults,
  setIsInitialized
) => {
  const [actionState, setActionState] = useState("enabled");

  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [isReset, setIsReset] = useState(false);

  const { approved_by, id } = accountingRecordDetail;

  /**
   * useRoQSelection setup: fetch accountingRecordDetail => setSelectionState run
   * => isInitialized true => setPreselectedValues run => isPreselectedValuesSelected
   * true. At that point, the roqSelectionState is valid and would cause a save right
   * away, which is stopped by "isFirstLoad". Any further changes to roqSelectionState
   * is something that needs to be saved (e.g. method change, sample change).
   */
  useEffect(() => {
    if (
      roqSelectionState.isInitialized &&
      isPreselectedValuesSelected(roqSelectionState) &&
      validateRoQSelection(roqSelectionState) === null
    ) {
      if (!isFirstLoad) {
        setActionState("disabled");

        debouncedSaveSelection(
          id,
          roqSelectionState,
          ({ data }) => {
            updateRecordDetail({ ...accountingRecordDetail, ...data });
            refetchResults();
          },
          () => setActionState("enabled")
        );
      } else {
        setIsFirstLoad(false);
      }
    }
  }, [roqSelectionState.intervalSelections]);

  // Error records will not be valid on first load and won't trigger a save, meaning
  // it does not need the isFirstLoad check to prevent saving the first time. The
  // exception is when a record is reset to an error state, which will trigger a save
  // like a normal record and thus "isFirstLoad" does not need to be turned off
  useEffect(() => {
    if (accountingRecordDetail.state === "error" && !isReset) {
      setIsFirstLoad(false);
    }
  }, [accountingRecordDetail.state]);

  const updateRecordOfQuality = (approveRecord) => {
    const errorMessage = validateRoQSelection(roqSelectionState);

    if (!errorMessage) {
      return RecordOfQualityService.updateRecordOfQuality(
        id,
        roqSelectionState,
        approveRecord === "approve" ? true : false
      ).then(({ data }) => {
        updateRecordDetail({ ...accountingRecordDetail, ...data });
        refetchResults();

        if (approveRecord !== "save") {
          addAlertMessage(
            new AlertMessage({
              type: "success",
              message:
                approveRecord === "approve" ? ROQ_APPROVED : ROQ_UNAPPROVED,
            })
          );
        }
      });
    } else {
      addAlertMessage(
        new AlertMessage({ type: "warning", message: errorMessage })
      );

      return Promise.resolve();
    }
  };

  const approveSelection = () => {
    // Approve if not approved or selection is different from initial
    if (
      !approved_by ||
      !isCurrentSelectionEqualToInitial(
        roqSelectionState,
        accountingRecordDetail
      )
    ) {
      return updateRecordOfQuality("approve");
    } else {
      return updateRecordOfQuality("unapprove");
    }
  };

  const resetSelection = () => {
    return RecordOfQualityService.resetRecordOfQuality(
      accountingRecordDetail.id
    ).then(({ data }) => {
      addAlertMessage(
        new AlertMessage({
          type: "success",
          message: ROQ_RESET,
        })
      );

      refetchResults();

      // has to be after refetching or else the useEffect in useManageResultFetches
      // will run prematurely and the reset won't show in selection table
      setIsInitialized(false);
      setIsReset(true);
      setIsFirstLoad(true);

      updateRecordDetail({ ...accountingRecordDetail, ...data });
    });
  };

  return [approveSelection, resetSelection, actionState, setActionState];
};

export default useManageSubmission;
