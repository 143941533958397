import { z } from "zod";
import {
  DomainModelMetaExtendedSchema,
  DomainModelMetaSchema,
  DomainModelSchema,
} from "./DomainModelSchemas";
import { WorkflowSchema } from "./WorkflowSchemas";

export const WorkflowTaskNotificationEventType = {
  CREATED: "created",
  STATUS_CHANGED: "status_changed",
  ASSIGNEE_CHANGED: "assignee_changed",
} as const;

export const WorkflowTaskNotificationRecipientType = {
  WORKFLOW_OWNER: "workflow_owner",
  ASSIGNEE: "assignee",
  ASSIGNEE_USER: "assignee_user",
  ASSIGNEE_GROUP: "assignee_group",
  USERS: "users",
} as const;

export const WorkflowTaskTypes = {
  MANUAL: "manual",
  SUBMIT_FORM: "submit_form",
  COMPLETE_EVENT: "complete_event",
} as const;

export type WorkflowTaskTypesType =
  (typeof WorkflowTaskTypes)[keyof typeof WorkflowTaskTypes];

export const WorkflowTaskStatus = {
  OPEN: "open",
  COMPLETE: "complete",
  DISMISSED: "dismissed",
} as const;
export type WorkflowTaskStatusType =
  (typeof WorkflowTaskStatus)[keyof typeof WorkflowTaskStatus];

export const WorkflowStepType = {
  SYSTEM_ACTION: "system_action",
  USER_TASK: "user_task",
} as const;
export type WorkflowTaskStepType =
  (typeof WorkflowStepType)[keyof typeof WorkflowStepType];

export const WorkflowTaskBaseSchema = z
  .object({
    name: z.string(),
    type: z.enum([
      WorkflowTaskTypes.MANUAL,
      WorkflowTaskTypes.SUBMIT_FORM,
      WorkflowTaskTypes.COMPLETE_EVENT,
    ]),
    step_id: z.string(),
    step_type: z
      .enum([WorkflowStepType.SYSTEM_ACTION, WorkflowStepType.USER_TASK])
      .optional(),
    status: z.enum([
      WorkflowTaskStatus.OPEN,
      WorkflowTaskStatus.COMPLETE,
      WorkflowTaskStatus.DISMISSED,
    ]),
    description: z.string(),
    assignee_user: z.string().uuid().nullable(),
    assignee_group: z.string().uuid().nullable(),
    workflow_id: z.string(),
    workflow: WorkflowSchema.omit({
      workflow_template_id: true,
      facility_id: true,
    }),
    company_id: z.string(),
    form_submission: z.undefined(),
    emission_event: z
      .array(
        z.object({
          id: z.string().uuid(),
          name: z.string(),
        })
      )
      .optional(),
  })
  .merge(DomainModelSchema)
  .merge(DomainModelMetaSchema)
  .merge(DomainModelMetaExtendedSchema.pick({ updated_by: true }))
  .describe("The domain model schema for a Workflow Task");

export const WorkflowTaskWithFormSubmissionSchema =
  WorkflowTaskBaseSchema.merge(
    z.object({
      form_submission: z.array(
        z
          .object({
            status: z.enum(["validated", "pending", "submitted", "draft"]),
            form_schema: z
              .object({
                name: z.string(),
              })
              .merge(DomainModelSchema),
          })
          .merge(DomainModelSchema)
          .merge(DomainModelMetaSchema)
          .merge(DomainModelMetaExtendedSchema)
      ),
    })
  );

export const WorkflowTaskSchema = z.union([
  WorkflowTaskBaseSchema,
  WorkflowTaskWithFormSubmissionSchema,
]);

export type WorkflowTaskType = z.infer<typeof WorkflowTaskSchema>;
export type WorkflowTaskWithFormSubmissionType = z.infer<
  typeof WorkflowTaskWithFormSubmissionSchema
>;

/**
 * Type predicate to determine if a workflow task is a base workflow task or one
 * with form submissions attached
 * @param task a workflow task
 * @returns true with a type assertion if the workflow task is one with form submissions
 */
export const isWorkflowTaskWithFormSubmission = (
  task: WorkflowTaskType
): task is WorkflowTaskWithFormSubmissionType =>
  task.form_submission !== undefined && Array.isArray(task.form_submission);
