import { ActivityLogsList } from "#src/batteries-included-components/Layouts/ActivityLogs/ActivityLogsList";
import { AccessDeniedLayout } from "#src/batteries-included-components/Layouts/Authorization/AccessDenied";
import { useIsOpsHubExperience } from "#src/contexts/AuthenticatedContext.helpers";
import { Page } from "@validereinc/common-components";
import React from "react";
import { ACTIVITY_LOGS_BREADCRUMB } from ".";

export const ActivityLogsPage = () => {
  const [isOpsExperienceEnabled] = useIsOpsHubExperience();

  if (isOpsExperienceEnabled) {
    return <AccessDeniedLayout />;
  }

  return (
    <Page
      title="Change Log"
      breadcrumbs={[ACTIVITY_LOGS_BREADCRUMB]}
    >
      <ActivityLogsList />
    </Page>
  );
};
