import CreatableSelectInput from "#common/Form/CreatableSelectInput";
import { TextInput } from "@validereinc/common-components";
import * as PropTypes from "prop-types";
import React from "react";

const EditInstrumentDetailsForm = ({
  availableSerialNumbers,
  isLoading,
  formMethods,
  handleCreateOption,
}) => {
  const defaultValue = formMethods.getValues("serialNumber");

  const validSerialNumber = (serialNumber) =>
    !/[^0-9a-zA-Z-_]/.test(serialNumber);

  const validateNewOption = (option) => {
    return (
      option !== "" &&
      validSerialNumber(option) &&
      !availableSerialNumbers.map((sn) => sn.value).includes(option)
    );
  };

  const validateSerialNumber = (input) => {
    if (input && !validSerialNumber(input.value)) {
      return "Serial number must contain alphanumeric and/or [-, _] characters.";
    }
  };

  return (
    <>
      <TextInput
        name="name"
        label="Name"
        isDisabled={isLoading}
        showIcon
        isRequired
      />
      <CreatableSelectInput
        name="serialNumber"
        label="Serial Number"
        placeholder="Select or Create Option..."
        className="selectInput"
        defaultValue={defaultValue}
        options={availableSerialNumbers || []}
        onCreateOption={handleCreateOption}
        isClearable
        isDisabled={isLoading}
        showIcon
        validate={validateSerialNumber}
        isValidNewOption={validateNewOption}
      />
    </>
  );
};

EditInstrumentDetailsForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  formMethods: PropTypes.object.isRequired,
  availableSerialNumbers: PropTypes.array,
  isLoading: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  handleCreateOption: PropTypes.func.isRequired,
};

export default EditInstrumentDetailsForm;
