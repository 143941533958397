import { RoutingLink } from "#batteries-included-components/RoutingLink";
import { NetworkStatusPillVariants } from "#hooks/adapters/useNetworks";
import { NetworkDetailRoute } from "#routes/organization/networks/[networkId]/detail";
import useLocalization from "#src/hooks/useLocalization";
import { DataTable, HeaderType } from "@validereinc/common-components";
import { AssetGroupStatusType, AssetGroupType } from "@validereinc/domain";
import { toStartCaseString } from "@validereinc/utilities";
import get from "lodash/get";
import React from "react";

export const NETWORK_ATTRIBUTES = {
  ID: {
    key: "id",
    label: "ID",
  },
  NAME: {
    key: "name",
    label: "Network",
  },
  STATUS: {
    key: "status",
    label: "Status",
  },
  REPORTING_GROUP: {
    key: "default_network_reporting_group_id",
    label: "Reporting Scenario",
  },
  CREATED_AT: {
    key: "created_at",
    label: "Created At",
  },
  CREATED_BY: {
    key: "created_by",
    label: "Created By",
  },
  UPDATED_AT: {
    key: "updated_at",
    label: "Updated At",
  },
  UPDATED_BY: {
    key: "updated_by",
    label: "Updated By",
  },
};

export const useNetworkHeaders = <ItemType = AssetGroupType,>({
  prefix,
  keySubstitutions,
  sortSubstitutions,
  reportingGroupMap,
}: {
  prefix?: string;
  keySubstitutions?: Record<string, string>;
  sortSubstitutions?: Record<string, string>;
  reportingGroupMap?: Record<string, string>;
} = {}): Array<HeaderType<ItemType>> => {
  const { localize } = useLocalization();

  const withSortSubstitution = (key: string) =>
    sortSubstitutions?.[key] ?? (prefix ? `${prefix}.${key}` : key);
  const withKeySubstitution = (key: string) =>
    keySubstitutions?.[key] ?? (prefix ? `${prefix}.${key}` : key);

  return [
    {
      key: withSortSubstitution(NETWORK_ATTRIBUTES.NAME.key),
      label: localize(NETWORK_ATTRIBUTES.NAME.label),
      isSortable: true,
      renderComponent: ({ item }: { item: ItemType }) => {
        const id = get(item, withKeySubstitution(NETWORK_ATTRIBUTES.ID.key));
        const name = get(
          item,
          withKeySubstitution(NETWORK_ATTRIBUTES.NAME.key)
        );
        const link = NetworkDetailRoute.toLink({
          pathParams: { networkId: id },
        });

        return name && id ? <RoutingLink to={link}>{name}</RoutingLink> : "-";
      },
    },
    {
      key: withSortSubstitution(NETWORK_ATTRIBUTES.STATUS.key),
      label: NETWORK_ATTRIBUTES.STATUS.label,
      isSortable: true,
      renderComponent: ({ item }: { item: ItemType }) => {
        const status = get(
          item,
          withKeySubstitution(NETWORK_ATTRIBUTES.STATUS.key)
        ) as AssetGroupStatusType;
        return (
          <DataTable.DataRow.PillCell
            variant={NetworkStatusPillVariants[status]}
            value={toStartCaseString(status)}
          />
        );
      },
    },
    {
      key: withSortSubstitution(NETWORK_ATTRIBUTES.REPORTING_GROUP.key),
      label: NETWORK_ATTRIBUTES.REPORTING_GROUP.label,
      isSortable: true,
      renderComponent: ({ item }: { item: ItemType }) => {
        const reportingGroup = get(
          item,
          withKeySubstitution(NETWORK_ATTRIBUTES.REPORTING_GROUP.key)
        );
        return reportingGroupMap?.[reportingGroup] ?? "-";
      },
    },
  ];
};
