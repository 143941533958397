import { Button, TextInput } from "@validereinc/common-components";
import * as PropTypes from "prop-types";
import React from "react";
import FontAwesome from "react-fontawesome";

const HIDDEN_SECRET =
  "*****************************************************************";

const CreateApiTokenForm = ({
  apiTokenDetail,
  isLoading,
  showSecret,
  onShowClick,
  onCopyClick,
}) => {
  return (
    <>
      {apiTokenDetail ? (
        <>
          <div className="alertSuccess">
            <div className="alertSuccess__icon">
              <FontAwesome name="check-circle" />
            </div>

            <div className="alertSuccess__alert">
              You have successfully generate an API Token. This is the{" "}
              <b>only time </b>
              that you will be able to view and copy the API Token. You will{" "}
              <b>not be able </b>
              to recover the API Token but you will be able to generate a new
              one.
            </div>
          </div>

          <div className="createApiTokenModal__apiTokens">
            <table>
              <tbody>
                <tr>
                  <td>
                    <b>API Token Name:</b>
                  </td>
                  <td>{`${apiTokenDetail?.name ?? "-"}`}</td>
                </tr>

                <tr>
                  <td>
                    <b>API Token ID:</b>
                  </td>
                  <td>{`${apiTokenDetail.id}`}</td>
                </tr>

                <tr>
                  <td>
                    <b>Secret API Token:</b>
                  </td>
                  <td>
                    {showSecret ? `${apiTokenDetail.secret}` : HIDDEN_SECRET}{" "}
                    <br />
                    <Button
                      className="showSecretButton"
                      onClick={onShowClick}
                    >
                      {showSecret ? "Hide" : "Show"}
                    </Button>
                    <Button onClick={onCopyClick}>Copy</Button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </>
      ) : (
        <TextInput
          name="name"
          label="Name"
          placeholder="name"
          disabled={isLoading}
        />
      )}
    </>
  );
};

CreateApiTokenForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  apiTokenDetail: PropTypes.object,
  showSecret: PropTypes.bool.isRequired,
  onShowClick: PropTypes.func.isRequired,
  onCopyClick: PropTypes.func.isRequired,
  closeButtonClick: PropTypes.func.isRequired,
};

export default CreateApiTokenForm;
