import { useNavigate } from "#src/Routers/hooks";
import {
  ProgrammaticLink,
  ProgrammaticLinkProps,
} from "@validereinc/common-components";
import React, { PropsWithChildren } from "react";
import { Link, LinkProps, useLocation } from "react-router-dom";

export type RoutingLinkProps<TLinkState = unknown> = Omit<
  ProgrammaticLinkProps,
  "onClick"
> &
  Pick<LinkProps<TLinkState>, "to">;

/**
 * Use this component wherever you'd use the <Link /> component from
 * react-router-dom when intending to use it to render a generic link with
 * router-powered navigation
 * @see {@link https://v5.reactrouter.com/web/api/Link}
 * @see ProgrammaticLink component in common-components
 */
export const RoutingLink = <TLinkState,>({
  children,
  to,
  ...props
}: PropsWithChildren<RoutingLinkProps<TLinkState>>) => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    // @ts-expect-error: the Link component does not allow generic typing for the provided component
    <Link
      component={ProgrammaticLink}
      {...props}
      to={to}
      onClick={() => {
        if (typeof to === "function") {
          // @ts-expect-error the default type should pass through properly but isn't
          const loc = to(location);

          if (typeof loc === "string") {
            navigate({
              pathname: loc,
            });
          } else {
            navigate({
              ...loc,
              pathname: loc.pathname ?? "",
            });
          }
        } else if (typeof to === "string") {
          navigate({
            pathname: to,
          });
        } else {
          navigate({
            ...to,
            pathname: to.pathname ?? "",
          });
        }
      }}
    >
      {children}
    </Link>
  );
};
