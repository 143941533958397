import {
  EntityDropdownInput,
  BaseEntityDropdownProps,
} from "#batteries-included-components/Dropdowns/EntityDropdownInput";
import useLocalization from "#src/hooks/useLocalization";
import { EquipmentDomain } from "@validereinc/domain";
import React from "react";

export const EquipmentDropdownInput = ({
  name = "equipment.id",
  label,
  ...props
}: BaseEntityDropdownProps) => {
  const { localize } = useLocalization();

  return (
    <EntityDropdownInput
      fetchEntityList={EquipmentDomain.getEquipment}
      label={label ?? localize("Equipment")}
      name={name}
      sort={{
        sortBy: "name",
        sortDirection: "asc",
      }}
      {...props}
    />
  );
};
