import React, { useMemo } from "react";
import moment from "moment";
import "./RecordOfQualitySampleSelections.scss";
import { METHOD_OPTIONS } from "../../../RecordOfQualityConstants";
import RecordOfQualitySampleSelectionForm from "./RecordOfQualitySampleSelectionForm";
import { useSelectionModalContext } from "../../Context/selectionModalContext";
import filter from "lodash/filter";

const RecordOfQualitySampleSelections = () => {
  const { accountingRecordDetail, roqSelectionState, accountingPeriod } =
    useSelectionModalContext();

  const { intervalSelections } = roqSelectionState;

  const visibleMethodOptions = useMemo(() => {
    const { preSelectedValues } = roqSelectionState;
    const balanceType = accountingPeriod.balance_type;
    const category = accountingRecordDetail.category;
    let methodOptions = [...METHOD_OPTIONS];

    // Filter out unselectable options
    methodOptions = filter(methodOptions, (method) => method.id !== "multiple");

    // Filter out pervious accounting record option due to there is no selection
    if (!preSelectedValues?.previousAccountingRecord) {
      methodOptions = filter(
        methodOptions,
        (method) => method.id !== "previous_accounting_record"
      );
    }

    if (category === "inventory_closing") {
      return methodOptions.filter((method) => method.inventory);
    }

    if (balanceType === "oil") {
      return methodOptions.filter((method) => method.oil);
    }

    return methodOptions.filter((method) => method.default);
  }, [roqSelectionState.preSelectedValues, accountingPeriod.balance_type]);

  return (
    <div className="recordOfQualitySampleSelections">
      {intervalSelections.map((interval, index) => {
        const nextIntervalUntil = intervalSelections[index + 1]?.until
          ? moment(intervalSelections[index + 1].until).subtract(1, "day")
          : moment(accountingPeriod.until);

        return (
          <RecordOfQualitySampleSelectionForm
            key={moment(interval.from).valueOf()}
            interval={interval}
            intervalSelectionIndex={index}
            visibleMethodOptions={visibleMethodOptions}
            nextIntervalUntil={nextIntervalUntil}
          />
        );
      })}
    </div>
  );
};

export default RecordOfQualitySampleSelections;
