import React from "react";
import { getNumberColorBySign } from "#utils/styleCalculator";
import { getDollarValue } from "#utils/stringFormatter";

export function getTotalPrice(estimatedValue) {
  if (!estimatedValue?.value) {
    return "—";
  }

  return (
    <div
      className="recordOfQualityResultPricing__value"
      style={{
        color: getNumberColorBySign(estimatedValue.value),
      }}
    >
      {getDollarValue(estimatedValue.value, estimatedValue.currency)}
    </div>
  );
}
