import {
  CUBIC_METERS_VOLUME,
  getFormattedMeasurementValueWithUnit,
} from "#redux/reducers/measurements";
import { LegacyDataTable, Tooltip } from "@validereinc/common-components";
import * as PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import {
  default as Fontawesome,
  default as FontAwesome,
} from "react-fontawesome";
import { connect } from "react-redux";
import { AutoSizer } from "react-virtualized";
import {
  getComponentShortForm,
  getGasEquivalentFactorTooltip,
  getMethodString,
  getStatus,
} from "../../RecordOfQualityHelper";
import SelectionModalProvider from "../../SelectionModal/Context/selectionModalContext";
import RecordOfQualityModal from "../../SelectionModal/RecordOfQualityModal";
import "./RecordOfQualityTable.scss";
import useRoQTableFilter from "./RecordOfQualityTableFilter";
import {
  findAccountingRecordIndex,
  formatCategory,
  getInventoryRecord,
  getOpeningInventoryDetail,
  searchByStreamName,
  showOpeningInventory,
  sortByApprovedBy,
  sortByEnergy,
  sortByGasEquivalentVolume,
  sortByLiquidEquivalentVolume,
  sortByMeasurement,
  sortByStream,
  sortByTotalVolume,
} from "./RecordOfQualityTableHelper";

const MEASUREMENT_WIDTH = 140;

const TABLE_HEIGHT_OFF_SET = 95;

const INVENTORY_ROW_HEIGHT = 115;

const DEFAULT_ROW_HEIGHT = 65;

const mapStateToProps = (state) => {
  return {
    getFormattedMeasurementValueWithUnit: (measurementKey, measurementObject) =>
      getFormattedMeasurementValueWithUnit(state.measurements)(
        measurementKey,
        measurementObject
      ),
  };
};

const streamRenderer = (rowData, columnKey) => {
  return (
    <div className="recordOfQualityTable__streamCell datatable__row">
      {rowData.stream?.[columnKey] ?? "-"}
    </div>
  );
};

const statusRenderer = (rowData) => {
  return getStatus(rowData.state);
};

const categoryRenderer = (rowData, columnKey) => {
  return (
    <div className="capitalized datatable__row">
      {showOpeningInventory(rowData) && (
        <div className="openingInventory__cell">
          {formatCategory(
            getOpeningInventoryDetail(rowData, columnKey) ?? "inventory_opening"
          )}
        </div>
      )}

      {formatCategory(rowData[columnKey]) ?? "-"}
    </div>
  );
};

const gasEquivalentVolumeRenderer = (
  rowData,
  columnKey,
  getFormattedMeasurementValueWithUnit
) => {
  const volume = rowData[columnKey];
  const tooltip = getGasEquivalentFactorTooltip(rowData?.gas_equivalent_factor);

  const openingInventoryVolume = getOpeningInventoryDetail(rowData, columnKey);
  const openingInventoryTooltip = getGasEquivalentFactorTooltip(
    getOpeningInventoryDetail(rowData, "gas_equivalent_factor")
  );

  return (
    <div className="recordOfQualityTable__volumeCell datatable__row">
      {showOpeningInventory(rowData) && (
        <Tooltip content={openingInventoryTooltip}>
          <div className="openingInventory__cell">
            {openingInventoryVolume
              ? `${getFormattedMeasurementValueWithUnit(
                  CUBIC_METERS_VOLUME,
                  openingInventoryVolume
                )}`
              : "-"}
          </div>
        </Tooltip>
      )}
      <div>
        <Tooltip content={tooltip}>
          {volume ? (
            <div>
              {getFormattedMeasurementValueWithUnit(
                CUBIC_METERS_VOLUME,
                volume
              )}
            </div>
          ) : (
            "-"
          )}
        </Tooltip>
      </div>
    </div>
  );
};

const measuredVolumeRenderer = (
  rowData,
  columnKey,
  getFormattedMeasurementValueWithUnit
) => {
  const volume = rowData[columnKey];

  const openingInventoryVolume = getOpeningInventoryDetail(rowData, columnKey);

  return (
    <div className="datatable__row">
      {showOpeningInventory(rowData) && (
        <div className="openingInventory__cell">
          {getFormattedMeasurementValueWithUnit(
            CUBIC_METERS_VOLUME,
            openingInventoryVolume
          )}
        </div>
      )}

      {getFormattedMeasurementValueWithUnit(CUBIC_METERS_VOLUME, volume)}
    </div>
  );
};

const methodRenderer = (rowData, columnKey) => {
  return (
    <div className="recordOfQualityTable__methodCell">
      {showOpeningInventory(rowData) && (
        <div className="openingInventory__cell">
          {getMethodString(getOpeningInventoryDetail(rowData, columnKey))}
        </div>
      )}

      {getMethodString(rowData[columnKey])}
    </div>
  );
};

const alarmsRenderer = (rowData, columnKey) => {
  const sampleAlarms = rowData[columnKey]?.sample_alarms ?? [];
  const roqAlarms = rowData[columnKey]?.accounting_record_alarms ?? [];

  const tooltip = (
    <>
      {sampleAlarms.length > 0 && (
        <>
          <div className="recordOfQualityTable__tooltip__header">
            Sample Alarms
          </div>
          <ul>
            {sampleAlarms.map((sampleAlarm) => (
              <li key={sampleAlarm.message}>{sampleAlarm.message}</li>
            ))}
          </ul>
        </>
      )}

      {roqAlarms.length > 0 && (
        <>
          <div className="recordOfQualityTable__tooltip__header">
            RoQ Alarms
          </div>
          <ul>
            {roqAlarms.map((roqAlarm) => (
              <li key={roqAlarm.message}>{roqAlarm.message}</li>
            ))}
          </ul>
        </>
      )}
    </>
  );

  if (sampleAlarms.length > 0 || roqAlarms.length > 0) {
    return (
      <div className="datatable__row">
        <Tooltip content={tooltip}>
          <Fontawesome
            className="recordOfQualityTable__warningIcon"
            name="exclamation-triangle"
          />
        </Tooltip>
      </div>
    );
  } else {
    return null;
  }
};

const measurementRenderer = (
  rowData,
  columnKey,
  getFormattedMeasurementValueWithUnit
) => {
  try {
    const measurement = rowData.qualities[columnKey];
    const volume = rowData.component_volumes[columnKey];

    const openingMeasurement =
      getOpeningInventoryDetail(rowData, "qualities")?.[columnKey] ?? "-";

    const openingVolume =
      getOpeningInventoryDetail(rowData, "component_volumes")?.[columnKey] ??
      "-";

    return (
      <div className="datatable__row">
        {showOpeningInventory(rowData) && (
          <div className="openingInventory__cell">
            {getFormattedMeasurementValueWithUnit(
              columnKey,
              openingMeasurement
            )}
            <br />
            {openingVolume
              ? getFormattedMeasurementValueWithUnit(
                  CUBIC_METERS_VOLUME,
                  openingVolume
                )
              : null}
          </div>
        )}

        {getFormattedMeasurementValueWithUnit(columnKey, measurement)}
        <br />
        {volume
          ? getFormattedMeasurementValueWithUnit(CUBIC_METERS_VOLUME, volume)
          : null}
      </div>
    );
  } catch {
    return "-";
  }
};

const productRenderer = (rowData, columnKey) => {
  return (
    <div className="datatable__row">
      {showOpeningInventory(rowData) && (
        <div className="openingInventory__cell">
          {getOpeningInventoryDetail(rowData, "stream")?.[columnKey] ?? "-"}
        </div>
      )}

      {rowData.stream?.[columnKey] ?? "-"}
    </div>
  );
};

const approvedByRenderer = (rowData, columnKey) => {
  return (
    <div className="datatable__row">
      {showOpeningInventory(rowData) && (
        <div className="openingInventory__cell">
          {getOpeningInventoryDetail(rowData, columnKey)?.name ?? "-"}
        </div>
      )}

      {rowData[columnKey]?.name ?? "-"}
    </div>
  );
};

const lastEditedByRenderer = (rowData, columnKey) => {
  return (
    <div className="datatable__row">
      {showOpeningInventory(rowData) && (
        <div className="openingInventory__cell">
          {getOpeningInventoryDetail(rowData, columnKey)?.name ?? "Validere"}
        </div>
      )}

      {rowData[columnKey]?.name ?? "Validere"}
    </div>
  );
};

const getGasEquivalentVolumeLabel = () => {
  const tooltipMessage = "Hover over GEV value to see GEF value";

  return (
    <Tooltip content={tooltipMessage}>
      Total Volume (GEV)
      <FontAwesome
        name="info-circle"
        style={{ marginLeft: "3px" }}
      />
    </Tooltip>
  );
};

const getTotalVolumeHeader = (
  balanceType,
  getFormattedMeasurementValueWithUnit
) => {
  switch (balanceType) {
    case "gas":
      return [
        {
          label: getGasEquivalentVolumeLabel(),
          key: "gas_equivalent_volume",
          width: 155,
          cellRenderer: (rowData, columnKey) =>
            gasEquivalentVolumeRenderer(
              rowData,
              columnKey,
              getFormattedMeasurementValueWithUnit
            ),
          sort: sortByGasEquivalentVolume,
          rightAlign: true,
        },
      ];
    case "liquid":
      return [
        {
          label: "Total Volume (LEV)",
          key: "liquid_equivalent_volume",
          width: 155,
          cellRenderer: (rowData, columnKey) =>
            measuredVolumeRenderer(
              rowData,
              columnKey,
              getFormattedMeasurementValueWithUnit
            ),
          sort: sortByLiquidEquivalentVolume,
          rightAlign: true,
        },
      ];
    default:
      return [];
  }
};

const getEnergyHeader = (balanceType, getFormattedMeasurementValueWithUnit) => {
  return balanceType === "gas"
    ? [
        {
          label: "Energy",
          key: "energy",
          width: MEASUREMENT_WIDTH,
          cellRenderer: (rowData, columnKey) =>
            measuredVolumeRenderer(
              rowData,
              columnKey,
              getFormattedMeasurementValueWithUnit
            ),
          sort: sortByEnergy,
          rightAlign: true,
        },
      ]
    : [];
};

const Components = (
  componentVolumeConfig,
  displayProperties,
  getFormattedMeasurementValueWithUnit
) => {
  return displayProperties.map((component) => ({
    label: getComponentShortForm(component, componentVolumeConfig),
    key: component,
    width: MEASUREMENT_WIDTH,
    cellRenderer: (rowData, columnKey) =>
      measurementRenderer(
        rowData,
        columnKey,
        getFormattedMeasurementValueWithUnit
      ),
    sort: sortByMeasurement,
    rightAlign: true,
  }));
};

const headers = (
  componentVolumeConfig,
  displayProperties,
  balanceType,
  getFormattedMeasurementValueWithUnit
) => [
  {
    label: "Stream",
    key: "name",
    width: 300,
    cellRenderer: streamRenderer,
    sort: sortByStream,
    fixed: true,
  },
  {
    label: "Status",
    key: "state",
    width: 110,
    cellRenderer: statusRenderer,
    fixed: true,
  },
  {
    label: "Category",
    key: "category",
    cellRenderer: categoryRenderer,
    width: 115,
    fixed: true,
  },

  ...getTotalVolumeHeader(balanceType, getFormattedMeasurementValueWithUnit),

  {
    label: "Measured Volume",
    key: "raw_volume",
    width: MEASUREMENT_WIDTH,
    cellRenderer: (rowData, columnKey) =>
      measuredVolumeRenderer(
        rowData,
        columnKey,
        getFormattedMeasurementValueWithUnit
      ),
    sort: sortByTotalVolume,
    rightAlign: true,
  },

  ...getEnergyHeader(balanceType, getFormattedMeasurementValueWithUnit),

  {
    label: "Method",
    key: "selection_method",
    cellRenderer: methodRenderer,
    width: 125,
  },
  {
    label: "Alarms",
    key: "alarms",
    cellRenderer: alarmsRenderer,
    width: 100,
  },

  ...Components(
    componentVolumeConfig,
    displayProperties,
    getFormattedMeasurementValueWithUnit
  ),

  {
    label: "Product",
    key: "phase",
    width: 100,
    cellRenderer: productRenderer,
    sort: sortByStream,
  },
  {
    label: "Approved By",
    key: "approved_by",
    width: 100,
    cellRenderer: approvedByRenderer,
    sort: sortByApprovedBy,
    suppress: true,
  },
  {
    label: "Last Edit By",
    key: "last_revision_by",
    width: 175,
    cellRenderer: lastEditedByRenderer,
    disableSort: true,
    suppress: true,
  },
];

const useManageModal = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [accountingRecordDetail, setAccountingRecordDetail] = useState({});

  const showModal = (detail) => {
    setAccountingRecordDetail(detail);
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
  };

  return [isModalVisible, accountingRecordDetail, showModal, closeModal];
};

const RecordOfQualityTable = (props) => {
  const { recordOfQualities, isLoading } = props;
  const hasInventoryRecord = useMemo(
    () => getInventoryRecord(recordOfQualities).length > 0,
    [recordOfQualities]
  );

  const [isModalVisible, accountingRecordDetail, showModal, closeModal] =
    useManageModal();

  const [
    roq,
    filterPillbox,
    filterRow,
    filteredRecordOfQualities,
    setQueryParams,
  ] = useRoQTableFilter(recordOfQualities);

  useEffect(() => {
    if (roq) {
      showModal(roq);
    } else {
      closeModal();
    }
  }, [roq]);

  const onCellClick = (row) => {
    setQueryParams({ roq: row.id });
  };

  const onPanelClose = () => {
    setQueryParams({ roq: null });
  };

  return (
    <>
      <div
        className="recordOfQualityTable"
        style={{ height: props.height - TABLE_HEIGHT_OFF_SET }}
      >
        <AutoSizer>
          {({ height, width }) => (
            <LegacyDataTable
              headers={headers(
                props.accountingPeriod.component_volume_config,
                props.displayProperties,
                props.accountingPeriod.balance_type,
                props.getFormattedMeasurementValueWithUnit
              )}
              list={filteredRecordOfQualities}
              width={width}
              height={height}
              rowHeight={
                hasInventoryRecord ? INVENTORY_ROW_HEIGHT : DEFAULT_ROW_HEIGHT
              }
              filterKey="stream_name"
              highlightRow={true}
              defaultSortBy="name"
              defaultSortDirection="desc"
              isLoading={isLoading}
              customSearch={searchByStreamName}
              onCellClick={onCellClick}
              filterRow={filterRow}
              filterPillbox={filterPillbox}
              highlightSelected={(list) =>
                findAccountingRecordIndex(list, accountingRecordDetail)
              }
              noFilterListCount={recordOfQualities?.length}
            />
          )}
        </AutoSizer>

        {isModalVisible && (
          <SelectionModalProvider
            accountingPeriod={props.accountingPeriod}
            accountingRecordDetail={accountingRecordDetail}
            displayProperties={props.displayProperties}
            hasWriteAccess={props.hasWriteAccess}
            refreshAccountingPeriod={props.refreshAccountingPeriod}
            isModalVisible={true}
          >
            <RecordOfQualityModal
              show={true}
              onHide={onPanelClose}
              height={props.height}
            />
          </SelectionModalProvider>
        )}
      </div>
    </>
  );
};

RecordOfQualityTable.propTypes = {
  accountingPeriod: PropTypes.object.isRequired,
  displayProperties: PropTypes.array.isRequired,
  height: PropTypes.number.isRequired,
  refreshAccountingPeriod: PropTypes.func,
  hasWriteAccess: PropTypes.bool,
  setNumApprovalRequired: PropTypes.func,
  recordOfQualities: PropTypes.array,
  isLoading: PropTypes.bool,
  getFormattedMeasurementValueWithUnit: PropTypes.func,
};

export default connect(mapStateToProps)(RecordOfQualityTable);
