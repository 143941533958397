export const HOME_PAGE_TITLE = "Home";

export const linkToHome = () => {
  return `/app/home`;
};

export const HOME_BREADCRUMB = {
  title: HOME_PAGE_TITLE,
  path: linkToHome,
};

export const HOME_TAB = {
  id: "home",
  title: HOME_PAGE_TITLE,
};
