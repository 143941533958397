import * as PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
//import { Tabs, Tab } from "react-bootstrap";
import ReactLoader from "#components/LoadingBar/ReactLoader";
import SampleService from "#services/SampleService";
import InlineManualEntryModal from "#src/components/ManualEntry/ManualEntryForms/Inline/InlineManualEntryModal";
import useInlineManualEntryModal from "#src/components/ManualEntry/ManualEntryForms/useManualEntryModal";
import { Modal, Tab, Tabs, Title } from "@validereinc/common-components";
import TestAnalysis from "./TestAnalysis/TestAnalysis";
import "./TestDetailModal.scss";
import TestNotes from "./TestNotes/TestNotes";

const TAB_KEYS = {
  analysis: "analysis",
  notes: "notes",
};

const useFetchTests = (testId) => {
  const [state, setState] = useState("loading");
  const [test, setTest] = useState(null);
  const [lastFetchDate, setLastFetchDate] = useState(new Date());

  const refreshTest = () => {
    setLastFetchDate(new Date());
  };

  useEffect(() => {
    setState("loading");

    SampleService.getTestDetails(testId)
      .then(({ data }) => {
        setTest(data);
      })
      .finally(() => {
        setState("loaded");
      });
  }, [testId, lastFetchDate]);

  return [test, state, refreshTest];
};

const TestDetailModal = ({
  testId,
  showModal,
  onHide,
  updateSample,
  onTestsChange,
  redirectToAnalyze,
}) => {
  const [activeTabKey, setActiveTabKey] = useState(TAB_KEYS.analysis);
  const [test, state, refreshTest] = useFetchTests(testId);

  const [showInline, showInlineModal, onHideInlineModal] =
    useInlineManualEntryModal();

  const onTabSelect = (eventKey) => {
    setActiveTabKey(eventKey);
  };

  const refetchData = () => {
    refreshTest();

    if (updateSample) {
      updateSample();
    }

    if (onTestsChange) {
      onTestsChange();
    }
  };

  return (
    <Modal
      className="testDetailModal"
      open={showModal}
      onClose={onHide}
    >
      <Modal.Header>
        <Title>{test?.name ?? ""} Test</Title>
      </Modal.Header>

      <ReactLoader
        position={15}
        loaded={state === "loaded"}
      >
        {test ? (
          <div style={{ padding: "0 32px" }}>
            <Tabs
              activeKey={activeTabKey}
              onChange={onTabSelect}
            >
              <Tab
                tabKey={TAB_KEYS.analysis}
                title="Analysis"
              >
                <TestAnalysis
                  state={state}
                  noteKey={TAB_KEYS.notes}
                  testDetail={test}
                  showInlineModal={showInlineModal}
                  onTabSelect={onTabSelect}
                  redirectToAnalyze={redirectToAnalyze}
                  onClose={onHide}
                />
              </Tab>

              <Tab
                tabKey={TAB_KEYS.notes}
                title="Notes"
              >
                <TestNotes
                  testId={test.id}
                  testState={test.status}
                  refreshTest={refetchData}
                  onClose={onHide}
                />
              </Tab>
            </Tabs>
          </div>
        ) : (
          <div className="testDetailModal__errorMessage">
            No test detail available.
          </div>
        )}
      </ReactLoader>

      <InlineManualEntryModal
        show={showInline}
        onHide={onHideInlineModal}
        testInfo={test}
        refetchData={refetchData}
      />
    </Modal>
  );
};

TestDetailModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  testId: PropTypes.string.isRequired,
  onHide: PropTypes.func.isRequired,
  updateSample: PropTypes.func,
  onTestsChange: PropTypes.func,
  redirectToAnalyze: PropTypes.func,
};

export default TestDetailModal;
