import { useState, useEffect } from "react";

import SampleService from "#services/SampleService";

export const useFetchSample = (sampleId) => {
  const [sample, setSample] = useState(null);
  const [loadingState, setLoadingState] = useState("loading");
  const [lastFetchDate, setLastFetchDate] = useState(new Date());

  useEffect(() => {
    if (sampleId) {
      setLoadingState("loading");

      SampleService.getSample(sampleId)
        .then(({ data }) => {
          setSample(data);
          setLoadingState("loaded");
        })
        .catch(() => {
          setLoadingState("error");
        });
    }
  }, [sampleId, lastFetchDate]);

  const refetchSample = () => {
    setLastFetchDate(new Date());
  };

  return [sample, loadingState, refetchSample];
};
