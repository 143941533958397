import { NetworkFlowSelectionTablePanel } from "#batteries-included-components/Forms/NetworkForm/NetworkFlowSelectionTablePanel";
import { NetworkFlowsTablePanel } from "#batteries-included-components/Forms/NetworkForm/NetworkFlowsTablePanel";
import { useListFlows } from "#hooks/adapters/useFlows";
import { useGetOneNetwork } from "#hooks/adapters/useNetworks";
import { NetworkFlowsFilterPanel } from "#src/batteries-included-components";
import { useMultiStepFormContext } from "#src/hooks/useMultiStepForm";
import { useStorageKey } from "#src/hooks/useStorageKey";
import { Panel } from "@validereinc/common-components";
import { AssetGroupAssetType, AssetType } from "@validereinc/domain";
import classNames from "classnames/bind";
import React, { useEffect, useMemo, useState } from "react";
import styles from "./NetworkForm.module.scss";

const cx = classNames.bind(styles);

export const NetworkAssetsStep = ({
  networkId,
  stepNumberOverride,
}: {
  networkId?: string;
  stepNumberOverride?: number;
}) => {
  const STEP_NUMBER = stepNumberOverride ?? 2;
  const storageKeys = useStorageKey("networks-flows");

  const { currentStep, updateStep } = useMultiStepFormContext();

  const { data: network } = useGetOneNetwork(networkId);
  const flowIds =
    network?.assets
      ?.filter(({ asset_type }) => asset_type === AssetType.FLOW)
      ?.map(({ id }) => id) ?? [];

  /** We need to query the flows for their names */
  const flows = useListFlows(
    {
      filters: {
        id: flowIds,
      },
      pageSize: flowIds.length,
    },
    {
      enabled: !!flowIds.length,
    }
  ).data?.data;

  const defaultFlows = useMemo(
    () =>
      flows?.reduce((total: Record<string, AssetGroupAssetType>, item) => {
        total[item.id] = { ...item, asset_type: AssetType.FLOW };
        return total;
      }, {}) ?? {},
    [flows]
  );

  const [selectedFlows, setSelectedFlows] = useState(defaultFlows ?? {});
  useEffect(() => {
    setSelectedFlows(defaultFlows ?? {});
  }, [defaultFlows]);

  useEffect(() => {
    const values = { flow_ids: Object.keys(selectedFlows) };
    updateStep(STEP_NUMBER, {
      getValues: () => values,
      getFormState: () => ({
        isValid: !!Object.keys(selectedFlows).length,
        isSubmitting: false,
      }),
    });
  }, [selectedFlows]);

  return (
    <>
      {currentStep === STEP_NUMBER ? (
        <Panel title="Flow Selection">
          <div className={cx("introContainer")}>
            <div className={cx("instructionsContainer")}>
              <p>Select which flows to add to your network.</p>
            </div>
          </div>
          <NetworkFlowsFilterPanel
            filterConfigStorageKey={storageKeys.filterConfigStorageKey}
          />
          <div className={cx("container")}>
            <div className={cx("tableContainer")}>
              <NetworkFlowsTablePanel
                {...storageKeys}
                selectedFlows={selectedFlows}
                setSelectedFlows={setSelectedFlows}
              />
            </div>
            <div className={cx("selectContainer")}>
              <NetworkFlowSelectionTablePanel
                selectedFlows={selectedFlows}
                setSelectedFlows={setSelectedFlows}
              />
            </div>
          </div>
        </Panel>
      ) : null}
    </>
  );
};
