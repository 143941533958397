import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { addAlertMessage } from "../../../Redux/actions/alertMessages";
import { havePermission } from "../../../Redux/reducers/permissions";
import "./Notifications.css";
import CompanyNotificationSettings from "./CompanyNotificationSettings";
import RoleNotificationSettingsList from "./RoleNotificationSettingsList";
import SettingSection from "../../SettingSection";
import config from "../../../../config";

const COMPANY_NOTIFICATION_PREFERENCE_HELPSCOUT_LINK = `${config.KNOWLEDGE_BASE_URL}article/51-notifications-company-preferences`;

const mapStateToProps = (state) => {
  return {
    hasWritePermissions: havePermission(state.permissions)(
      "core:user.management",
      "write"
    ),
  };
};

const mapDispatchToProps = {
  addAlertMessage,
};

const Notifications = (props) => {
  if (props.hasWritePermissions) {
    return (
      <div className="notifications">
        <SettingSection
          title="Company Default Notification Settings"
          message="Set company default notification settings for all users."
          link={COMPANY_NOTIFICATION_PREFERENCE_HELPSCOUT_LINK}
        >
          <CompanyNotificationSettings />
        </SettingSection>

        <SettingSection
          title="Role Notification Settings"
          message="Set notification preferences by role. This will override the company default notifications."
          link={COMPANY_NOTIFICATION_PREFERENCE_HELPSCOUT_LINK}
        >
          <RoleNotificationSettingsList height={props.height} />
        </SettingSection>
      </div>
    );
  } else {
    return <div>No notification settings available.</div>;
  }
};

Notifications.propTypes = {
  height: PropTypes.number,
  hasWritePermissions: PropTypes.bool,
};

const SettingsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Notifications);

export default SettingsContainer;
