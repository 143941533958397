import { useBreadcrumbs } from "#src/Routers/breadcrumbsHelper";
import { useNavigate } from "#src/Routers/hooks";
import { CreateRoleUsersStepContent } from "#src/batteries-included-components";
import { AccessDeniedLayout } from "#src/batteries-included-components/Layouts/Authorization/AccessDenied";
import { useIsOpsHubExperience } from "#src/contexts/AuthenticatedContext.helpers";
import { SETTINGS_BREADCRUMB } from "#src/routes/settings";
import { ROLES_AND_PERMISSIONS_BREADCRUMB } from "#src/routes/settings/roles-and-permissions";
import {
  RoleContext,
  RoleProvider,
} from "#src/routes/settings/roles-and-permissions/roles/[roleId]/detail/RoleContext";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  Button,
  Form,
  Page,
  useAlert,
  useForm,
} from "@validereinc/common-components";
import { RoleUserMembershipType, RolesAdapter } from "@validereinc/domain";
import classNames from "classnames/bind";
import React, { useContext } from "react";
import { EDIT_ROLE_PROFILE_USERS_BREADCRUMB } from ".";
import { ROLE_DETAILS_USERS_BREADCRUMB } from "..";
import { ROLES_BREADCRUMB } from "../../..";
import { ROLE_DETAILS_BREADCRUMB, linkToRoleDetailPage } from "../../detail";
import styles from "./EditRoleUsersPage.module.scss";

const cx = classNames.bind(styles);

export const EditUserPermissionsPageContent = () => {
  const navigate = useNavigate();
  const { addAlert } = useAlert();
  const { roleQuery, roleMembersQuery } = useContext(RoleContext) ?? {};
  const queryClient = useQueryClient();
  const form = useForm({
    defaultValues: {
      selected_users: roleMembersQuery?.data?.map((d) => d.user) ?? [],
    },
  });
  const breadcrumbs = useBreadcrumbs(
    [
      SETTINGS_BREADCRUMB,
      ROLES_AND_PERMISSIONS_BREADCRUMB,
      ROLES_BREADCRUMB,
      ROLE_DETAILS_BREADCRUMB,
      ROLE_DETAILS_USERS_BREADCRUMB,
      EDIT_ROLE_PROFILE_USERS_BREADCRUMB,
    ],
    {
      3: roleQuery?.data?.name,
    },
    { roleId: roleQuery?.data?.id }
  );
  const associateUsersMutation = useMutation({
    mutationFn: ({
      roleId,
      selectedUsers,
    }: {
      roleId: string;
      selectedUsers: Array<RoleUserMembershipType["user"]>;
    }) => {
      const roleMembersToDelete = (roleMembersQuery?.data ?? []).filter(
        (originalMember) =>
          !selectedUsers.find((member) => member.id === originalMember.user_id)
      );

      return Promise.all(
        selectedUsers
          .map(({ id: userId }) =>
            RolesAdapter.members.update({
              roleId,
              userId,
            })
          )
          .concat(
            roleMembersToDelete.map(({ user_id: userId }) =>
              RolesAdapter.members.delete({
                roleId,
                userId,
              })
            )
          )
      );
    },
    onError: () => {
      addAlert?.({
        variant: "error",
        message: "Unable to associate members to role.",
      });
    },
    onSuccess: (_, { roleId }) => {
      queryClient.invalidateQueries({
        queryKey: ["roles", roleId, "members"],
      });
      queryClient.invalidateQueries({
        queryKey: ["users"],
      });
      addAlert?.({
        variant: "success",
        message: "Successfully assigned selected users to role",
      });
    },
  });

  const goBack = () =>
    navigate({
      pathname: linkToRoleDetailPage(roleQuery?.data?.id),
      query: {
        tab: "members",
      },
    });
  const onSubmit = form.handleSubmit(
    async (values: {
      selected_users: Array<RoleUserMembershipType["user"]>;
    }) => {
      if (!roleQuery?.data?.id) {
        return;
      }

      const { selected_users: selectedUsers } = values;

      await associateUsersMutation.mutateAsync({
        roleId: roleQuery.data.id,
        selectedUsers,
      });

      goBack();
    }
  );

  const footer = (
    <div className={cx("footerContainer")}>
      <Button
        onClick={goBack}
        disabled={form?.formState.isSubmitting}
      >
        Cancel
      </Button>
      <Button
        variant="primary"
        onClick={onSubmit}
        disabled={!roleQuery?.data?.id}
        isLoading={roleQuery?.isLoading ?? form?.formState.isSubmitting}
        type="button"
      >
        Assign Users
      </Button>
    </div>
  );

  return (
    <Form {...form}>
      <Page
        title="Assign Users"
        category={`Role | ${roleQuery?.data?.name}`}
        breadcrumbs={breadcrumbs}
        isLoading={roleQuery?.isLoading}
        footer={footer}
      >
        <CreateRoleUsersStepContent />
      </Page>
    </Form>
  );
};

export const EditRoleUsersPage = () => {
  const [isOpsExperienceEnabled] = useIsOpsHubExperience();

  if (isOpsExperienceEnabled) {
    return <AccessDeniedLayout />;
  }

  return (
    <RoleProvider>
      <EditUserPermissionsPageContent />
    </RoleProvider>
  );
};
