import WorkflowTaskUserGroupDropdown from "#src/batteries-included-components/Filters/WorkflowTaskUserGroupDropdown/WorkflowTaskUserGroupDropdown";
import useLocalization from "#src/hooks/useLocalization";
import {
  useCompositeAssetFilters,
  useAssetCustomAttributeFilters,
  AssetTypeSelection,
  AssetTypeSelectionType,
} from "../assetFilterComponents";
import { WORKFLOW_ALL_FILTERS } from "../WorkflowsFilterPanel";
import {
  DateSelectorInput,
  DropdownInput,
  FilterPanel,
  StorageKeys,
  TextInput,
  useFilters,
} from "@validereinc/common-components";
import {
  AssetType,
  WorkflowCategoriesAdapter,
  WorkflowTaskBaseSchema,
  WorkflowTemplatesAdapter,
} from "@validereinc/domain";
import { asUTC } from "@validereinc/utilities";
import { addDays, endOfDay, startOfDay, subDays } from "date-fns";
import React, { useMemo } from "react";

export const filterConfig = {
  search: {
    id: WorkflowTaskBaseSchema.keyof().Enum.name,
    name: "search",
    placeholder: "Search Tasks...",
  },
  facility: {
    name: "facilityId",
  },
  equipment: {
    name: "equipmentId",
  },
  device: {
    name: "deviceId",
  },
  flow: {
    name: "flowId",
  },
  asset_group: {
    name: "assetGroupId",
  },
  assetType: {
    name: "assetType",
  },
  dueDate: {
    id: "workflow.due_date",
    name: "workflow.due_date",
  },
  assignee_user: {
    id: "assignee_user",
    name: "assignee_user",
  },
  assignee_group: {
    id: "assignee_group",
    name: "assignee_group",
  },
  workflowCategory: {
    name: "categoryId",
  },
  workflowTemplate: {
    name: "templateId",
  },
} as const;

export type WorkflowTaskFilterPanelFiltersType = {
  [filterConfig.search.name]: string;
  [filterConfig.facility.name]: string[];
  [filterConfig.equipment.name]: string[];
  [filterConfig.device.name]: string[];
  [filterConfig.flow.name]: string[];
  [filterConfig.asset_group.name]: string[];
  [filterConfig.dueDate.name]: { from: string; to: string };
  [filterConfig.assignee_user.name]?: string;
  [filterConfig.assignee_group.name]?: string;
  [filterConfig.workflowCategory.name]?: string;
  [filterConfig.workflowTemplate.name]?: string;
  assetType: AssetTypeSelectionType;
  workflow: Record<string, any>;
};

export const useRelativeDateRange = (dateRangeInDays = 30) =>
  useMemo(
    () => ({
      from: asUTC(startOfDay(subDays(new Date(), dateRangeInDays))),
      to: asUTC(endOfDay(addDays(new Date(), dateRangeInDays))),
    }),
    []
  );

export const WorkflowTasksFilterPanel = ({
  filterConfigStorageKey,
  isDisplayingMyTasks,
}: Pick<StorageKeys, "filterConfigStorageKey"> & {
  isDisplayingMyTasks?: boolean;
}) => {
  const { localize } = useLocalization();
  const defaultDateRange = useRelativeDateRange();
  const [filters] = useFilters<WorkflowTaskFilterPanelFiltersType>(
    filterConfigStorageKey
  );
  const assetType = filters.assetType ?? AssetTypeSelection.FACILITY;
  const assetFilters = useCompositeAssetFilters(assetType, filterConfig);
  const attributeFilters =
    useAssetCustomAttributeFilters(assetType).customAttributeFilters;

  return (
    <FilterPanel
      storageKey={filterConfigStorageKey}
      defaultActiveKeys={Object.values(AssetType).map((asset) =>
        localize(asset)
      )}
      defaultValue={{
        [filterConfig.dueDate.name]: defaultDateRange,
        [WORKFLOW_ALL_FILTERS.category.name]: null,
        [WORKFLOW_ALL_FILTERS.template.name]: null,
        assetType: AssetType.FACILITY,
      }}
      filters={[
        {
          component: (
            <DateSelectorInput
              {...filterConfig.dueDate}
              key="date-filter"
              variant="day"
              isRange
              isInline
              isLabelShown={false}
              description="Search based on Workflow Due Date"
            />
          ),
        },
        {
          component: (
            <TextInput
              name={filterConfig.search.name}
              placeholder={filterConfig.search.placeholder}
              type="search"
              isInline
            />
          ),
        },
        // This custom component does not work with "assignee" name, but with "assignee_user" & "assignee_group" underneath:
        ...(isDisplayingMyTasks
          ? []
          : [
              {
                component: (
                  <WorkflowTaskUserGroupDropdown
                    inputId="assignee"
                    key="assignee"
                    name="assignee"
                    placeholder="Select Assignees..."
                    isInline
                    isLabelShown={false}
                    isOptionalTextShown={false}
                    filterConfigStorageKey={filterConfigStorageKey}
                  />
                ),
              },
            ]),
        ...assetFilters,
        {
          component: (
            <DropdownInput
              key={WORKFLOW_ALL_FILTERS.category.name}
              name={WORKFLOW_ALL_FILTERS.category.name}
              onFetchData={async (payload) => {
                let { data } = await WorkflowCategoriesAdapter.getList({
                  ...payload,
                  filters: {
                    ...(payload.searchTerm
                      ? {
                          name: payload.searchTerm,
                        }
                      : {}),
                  },
                });

                // REVIEW: not a fan of the fact that we need to do this. A refactor of DropdownInput is needed.
                if (Array.isArray(payload.value)) {
                  data = data.filter((d) => payload.value.includes(d.id));
                }
                return data;
              }}
              labelKey="name"
              valueKey="id"
              placeholder="Select Category"
              isInline
              isMulti
            />
          ),
        },
        {
          component: (
            <DropdownInput
              key={WORKFLOW_ALL_FILTERS.template.name}
              name={WORKFLOW_ALL_FILTERS.template.name}
              onFetchData={async (payload) => {
                let { data } = await WorkflowTemplatesAdapter.getList({
                  ...payload,
                  filters: {
                    ...(payload.searchTerm
                      ? {
                          name: payload.searchTerm,
                        }
                      : {}),
                  },
                });

                // REVIEW: not a fan of the fact that we need to do this. A refactor of DropdownInput is needed.
                if (Array.isArray(payload.value)) {
                  data = data.filter((d) => payload.value.includes(d.id));
                }

                return data;
              }}
              labelKey="name"
              valueKey="id"
              placeholder="Select Template"
              isInline
              isMulti
            />
          ),
        },
        ...attributeFilters,
      ]}
    />
  );
};
