export * from "./RolesAndPermissionsPage";

export const ROLES_AND_PERMISSIONS_PAGE_TITLE = "Roles & Permissions";

export const linkToRolesListPage = () => "/app/settings/roles-and-permissions";

export const ROLES_AND_PERMISSIONS_BREADCRUMB = {
  title: ROLES_AND_PERMISSIONS_PAGE_TITLE,
  path: linkToRolesListPage,
};
