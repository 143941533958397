import { RootRoute } from "#src/routes/root";
import { RoutePath } from "#src/utils/route";

export const WorkflowsRoutePath = RootRoute.concat(
  new RoutePath({
    path: "/workflows",
    title: "Workflows",
    isPresentational: true,
  })
);
