import { useInventories } from "#hooks/useInventories";
import { useProductionReports } from "#hooks/useProductionReports";
import { ensureStreamListIsFetched } from "#redux/actions/index";
import { Page, Panel } from "@validereinc/common-components";
import * as PropTypes from "prop-types";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import ProductionReportsTable from "./ProductionReportsTable";
import useFilterInputs from "./ProductionReportsTableFilter";

const BALANCE_TABLE_OFFSET = 70;

const mapStateToProps = ({ streams }) => ({
  streams: streams.data?.toJS() ?? [],
});

const mapDispatchToProps = {
  ensureStreamListIsFetched,
};

const ProductionReports = ({
  height,
  streams,
  ensureStreamListIsFetched,
  breadcrumbs,
}) => {
  const [productionReports, productionReportsLoadingState] =
    useProductionReports();
  const [inventories] = useInventories();

  const [filterRow, filterPillbox, filteredProductionReports] = useFilterInputs(
    productionReports,
    streams,
    inventories
  );

  useEffect(() => {
    ensureStreamListIsFetched();
  }, []);

  return (
    <Page
      title="Production Reports"
      breadcrumbs={breadcrumbs}
    >
      <Panel
        loaded={productionReportsLoadingState !== "loading"}
        style={{ height: height - BALANCE_TABLE_OFFSET }}
      >
        <ProductionReportsTable
          height={height}
          streams={streams}
          productionReports={filteredProductionReports}
          inventories={inventories}
          filterPillbox={filterPillbox}
          filterRow={filterRow}
        />
      </Panel>
    </Page>
  );
};

ProductionReports.propTypes = {
  height: PropTypes.number.isRequired,
  ensureStreamListIsFetched: PropTypes.func.isRequired,
  streams: PropTypes.array.isRequired,
  breadcrumbs: PropTypes.array.isRequired,
};

const ProductionReportsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductionReports);

export default ProductionReportsContainer;
