import { linkToFormCategoryDetails } from "..";

export const linkToCreateFormCategoryFormTemplate = (
  formCategoryId = ":categoryId"
) => `${linkToFormCategoryDetails(formCategoryId)}/create-form-template`;

export const CREATE_FORM_CATEGORY_FORM_TEMPLATE_PAGE_TITLE_PLACEHOLDER =
  "Create Form Template";

export const CREATE_FORM_CATEGORY_FORM_TEMPLATE_BREADCRUMB = {
  title: CREATE_FORM_CATEGORY_FORM_TEMPLATE_PAGE_TITLE_PLACEHOLDER,
  path: linkToCreateFormCategoryFormTemplate,
};

export * from "./CreateFormTemplatePage";
