import { GetAllObjectValue } from "#utils/objectFormatter";
import {
  FilterPillbox,
  MultiDropdownInputWithSearch,
} from "@validereinc/common-components";
import difference from "lodash/difference";
import differenceBy from "lodash/differenceBy";
import uniqBy from "lodash/uniqBy";
import React, { useEffect, useState } from "react";
import {
  clearAllInputs,
  filterRecordOfQualities,
  getAllFilterInputs,
} from "./RecombinedReportTableFilterHelper";

const DEFAULT_FILTER_DROPDOWN = {
  status: {
    label: "Status",
    inputs: [],
    options: [],
    labelKey: "name",
  },
  product: {
    label: "Product",
    inputs: [],
    options: [],
  },
  category: {
    label: "Category",
    inputs: [],
    options: [],
  },
};

const useFilterInputs = (recordOfQualities) => {
  const [filterDropdowns, setFilterDropdown] = useState({
    ...DEFAULT_FILTER_DROPDOWN,
  });

  const onDropdownSelect = (selectedValue, key) => {
    const filterDropdown = { ...filterDropdowns[key] };
    filterDropdown.inputs = selectedValue;

    setFilterDropdown({ ...filterDropdowns, [key]: filterDropdown });
  };

  const clearAllFilters = () => {
    setFilterDropdown(clearAllInputs(filterDropdowns));
  };

  const onClearFilterClick = (filterObject) => {
    if (filterObject) {
      const { filterKey, name } = filterObject;
      const updatedFilterDropdown = { ...filterDropdowns[filterKey] };

      if (filterKey === "status") {
        updatedFilterDropdown.inputs = differenceBy(
          updatedFilterDropdown.inputs,
          [filterObject],
          "id"
        );
      }

      if (filterKey === "product" || filterKey === "category") {
        updatedFilterDropdown.inputs = difference(
          updatedFilterDropdown.inputs,
          [name]
        );
      }

      setFilterDropdown({
        ...filterDropdowns,
        [filterKey]: updatedFilterDropdown,
      });
    } else {
      clearAllFilters();
    }
  };

  useEffect(() => {
    if (recordOfQualities.length > 0) {
      const status = { ...filterDropdowns.status };

      const statusOptions = GetAllObjectValue(recordOfQualities, "state").map(
        (state) => {
          if (state === "validere_approved") {
            state = "approved";
          }

          return {
            name: state.replace("_", " "),
            id: state,
          };
        }
      );
      status.options = uniqBy(statusOptions, "id");

      const product = { ...filterDropdowns.product };
      product.options = GetAllObjectValue(recordOfQualities, "stream", "phase")
        .map((stream) => stream.phase)
        .sort();

      const category = { ...filterDropdowns.category };
      category.options = GetAllObjectValue(
        recordOfQualities,
        "category"
      ).sort();

      setFilterDropdown({
        ...filterDropdowns,
        status,
        product,
        category,
      });
    }
  }, [recordOfQualities]);

  const filterRow = (
    <div className="filterRow">
      {Object.keys(filterDropdowns).map((key) => {
        const { label, labelKey, options, inputs } = filterDropdowns[key];

        return (
          <MultiDropdownInputWithSearch
            key={key}
            label={label}
            labelKey={labelKey ? labelKey : null}
            dropdownKey={key}
            value={inputs}
            options={options}
            onChange={onDropdownSelect}
          />
        );
      })}
    </div>
  );

  const filterInputs = getAllFilterInputs(filterDropdowns);

  const filteredRecordOfQualities = filterRecordOfQualities(
    recordOfQualities,
    filterInputs
  );

  const filterPillbox = (
    <FilterPillbox
      filterBy={filterInputs}
      onClearFilterClick={onClearFilterClick}
    />
  );

  return [filterRow, filterPillbox, filteredRecordOfQualities, clearAllFilters];
};

export default useFilterInputs;
