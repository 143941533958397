import {
  AssetTypeType,
  JobStatusType,
  JobType,
  ReportWithDownloadType,
} from "../../schemas";
import type {
  GetListRequestType,
  GetListResponseType,
  PaginatedListRequestType,
} from "../../util";
import { FilterObjectType, getFilterObject } from "../../util";
import { FlowType } from "../FlowDomain";
import {
  RecordValueDomain,
  RecordValueStatusType,
  RecordValueType,
  isRecordValueLocked,
} from "../RecordDomain/RecordValueDomain";
import { restAPI } from "../api";
import { SavedFiltersDomain } from "../composable";

export type RecordType = {
  id: string;
  company_id?: string;
  asset_id?: string;
  asset_type?: AssetTypeType;
  reporting_group_id?: string;
  year_month: string;
  created_at?: string;
  created_by?: string;
  updated_at?: string;
  updated_by?: string;
  values: RecordValueType[];
  flow?: FlowType;
  "flow.id"?: string;
  "flow.type"?: string;
  "flow.product_type"?: string;
  "flow.product_category"?: string;
  "flow.associated_facility.id"?: string;
  "flow.associated_facility.name"?: string;
  "flow.associated_equipment.id"?: string;
  "flow.associated_equipment.name"?: string;
};

export type RecordLookupType = { recordId: string };

export type LockRecordValuesOfRecordReponse = { success: boolean };
export type LockRecordValuesBySearchReponse = {
  success: boolean;
  affected: number;
};

export type CreateRecordType = {
  asset_id: string;
  asset_type: AssetTypeType;
  year_month: string;
  reporting_group_id: string;
  values?: RecordValueType[];
};

export type RecordFilterType = FilterObjectType<{
  asset_id?: string;
  asset_type?: AssetTypeType;
  reporting_group_id?: string;
  measurement_type?: string;
  year_month?: string;
  "flow.id"?: string;
  "flow.name"?: string;
  "flow.type"?: string;
  "flow.product_type"?: string;
  "flow.product_category"?: string;
}>;

export type RecordInputFilterType = FilterObjectType<{
  "facility.custom_attributes"?: Record<string, string>;
  "facility.name"?: string;
  "facility.status"?: string;
  "equipment.custom_attributes"?: Record<string, string>;
  "equipment.id"?: string;
  "equipment.method"?: string;
  "equipment.name"?: string;
  "equipment.type.id"?: string;
  "equipment.type.name"?: string;
  "device.attributes"?: Record<string, string>;
  "device.custom_attributes"?: Record<string, string>;
  "device.id"?: string;
  "device.measurement_types"?: string;
  "device.name"?: string;
  "device.status"?: string;
  "device.type_id"?: string;
  "flow.id"?: string;
  "flow.name"?: string;
  "flow.type"?: string;
  "flow.product_type"?: string;
  "flow.product_category"?: string;
  id?: string;
  asset_type?: AssetTypeType;
  asset_id?: string;
  reporting_group_id?: string;
  measurement_type?: string;
  year_month?: string;
  "record_value.id"?: string;
}>;

export type InputRecordType = {
  asset: {
    equipment?: {
      id: string;
      name: string;
      facility_id: string;
      facility: {
        id: string;
        name: string;
      };
      type_id: string;
      type: {
        id: string;
        name: string;
      };
    };
    flow?: {
      id: string;
      name: string;
      product_category: string;
      product_type: string;
      type: string;
      upstream_facility_id?: string | null;
      upstream_equipment_id?: string | null;
      upstream_flow_ids?: string[] | null;
      downstream_facility_id?: string | null;
      downstream_equipment_id?: string | null;
      downstream_flow_ids?: string[] | null;
      associated_equipment_id?: string | null;
      associated_facility_id?: string | null;
    };
  };
  asset_id: string;
  company_id: string;
  created_at: string;
  created_by: string;
  id: string;
  record_value: Array<{
    id: string;
    record_id: string;
    measurement_type: string;
    measurement_unit: string;
    value: number;
  }>;
  reporting_group: { id: string; name: string };
  reporting_group_id: string;
  updated_at: string;
  updated_by: string;
  year_month: string;
};

export type DefaultConfigurationFilterType = FilterObjectType<{
  id?: string;
  asset_type?: AssetTypeType;
  asset_id?: string;
  reporting_group_Id?: string;
  year_month?: string;
}>;

export type DefaultConfigurationResponseType = {
  status: "success" | "failed" | "skipped";
  data: Record<
    string,
    {
      status: "success" | "failed" | "skipped";
      upserts: RecordValueType[];
    }
  >;
  skippedCount: number;
};

export type DefaultConfigurationAsyncResponseType = {
  status: JobStatusType;
  job: JobType;
};

export type LockRecordValuesOfRecordInputType = RecordLookupType & {
  status: RecordValueStatusType;
  measurement_types: string[];
};

export type LockRecordValuesBySearchInputType = {
  filter: RecordInputFilterType;
  status: RecordValueStatusType;
};

// TODO: BE should be able to aggregate record value statuses instead of FE:
export const getRecordStatusFromValues = (
  values: RecordValueType[]
): RecordValueStatusType | "partially_locked" => {
  if (!values?.length) return "unlocked";

  return values.every((value: RecordValueType) => isRecordValueLocked(value))
    ? "locked"
    : values.every((value: RecordValueType) => !isRecordValueLocked(value))
      ? "unlocked"
      : "partially_locked";
};

/**
 * A group of values for an asset during a period
 */
export const RecordDomain = {
  getList: async ({
    pageSize,
    sortBy,
    sortDirection,
    groupBy,
    filters,
    includeAssetDetail,
    ...restProps
  }: PaginatedListRequestType<RecordFilterType> & {
    /** set to true to include asset details when grouping by `${asset type}.id` */
    includeAssetDetail?: boolean;
  }) => {
    const formattedFilters = getFilterObject(filters);

    return restAPI.nodeAPI.POST<GetListResponseType<RecordType>>({
      endpoint: "/records/search",
      body: {
        ...restProps,
        page_size: pageSize,
        sort_by: sortBy,
        sort_direction: sortDirection,
        filter: formattedFilters,
        group_by: groupBy,
      },
      ...(includeAssetDetail
        ? {
            query: {
              asset_detail: "true",
            },
          }
        : {}),
    });
  },

  /**
   * Get download link for record export
   * @see {@link https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/records/export_records}
   * @returns a report object for the record export
   */
  exportList: async ({
    sortBy,
    sortDirection,
    groupBy,
    filters,
    ...restProps
  }: GetListRequestType<RecordFilterType>) => {
    const formattedFilters = getFilterObject(filters);

    return restAPI.nodeAPI.POST<ReportWithDownloadType>({
      endpoint: "/records/export",
      body: {
        ...restProps,
        sort_by: sortBy,
        sort_direction: sortDirection,
        filter: formattedFilters,
        group_by: groupBy,
      },
    });
  },

  getOne: async ({ recordId }: RecordLookupType) =>
    restAPI.nodeAPI.GET<RecordType>({
      endpoint: `/records/${recordId}`,
    }),

  create: async (body: CreateRecordType) =>
    restAPI.nodeAPI.POST<RecordType>({
      endpoint: "/records",
      body,
    }),

  update: async ({
    recordId,
    ...body
  }: RecordLookupType & Partial<CreateRecordType>) =>
    restAPI.nodeAPI.PUT<RecordType>({
      endpoint: `/records/${recordId}`,
      body,
    }),

  updateStatusOfValues: async ({
    recordId,
    ...body
  }: LockRecordValuesOfRecordInputType) =>
    restAPI.nodeAPI.PUT<LockRecordValuesOfRecordReponse>({
      endpoint: `/records/${recordId}/status`,
      body,
    }),

  updateStatusBySearch: async ({
    filter,
    status,
  }: LockRecordValuesBySearchInputType) =>
    restAPI.nodeAPI.PUT<LockRecordValuesBySearchReponse>({
      endpoint: `/records/status`,
      body: { filter: getFilterObject(filter), status },
    }),

  delete: async ({ recordId }: RecordLookupType) =>
    restAPI.nodeAPI.DELETE<RecordType>({
      endpoint: `/records/${recordId}`,
    }),

  searchInputs: async (
    entity_type: AssetTypeType,
    {
      page,
      pageSize,
      sortBy,
      sortDirection,
      filters,
    }: PaginatedListRequestType<RecordInputFilterType>
  ) =>
    restAPI.nodeAPI.POST<GetListResponseType<InputRecordType>>({
      endpoint: `/records/${entity_type}/input_records/search`,
      body: {
        page,
        page_size: pageSize,
        sort_by: sortBy,
        sort_direction: sortDirection,
        filter: getFilterObject(filters),
      },
    }),
  applyDefaultConfiguration: (filters?: DefaultConfigurationFilterType) =>
    restAPI.nodeAPI.POST<DefaultConfigurationResponseType>({
      endpoint: `/records/apply_default_configuration`,
      body: {
        filter: filters ? getFilterObject(filters) : {},
      },
    }),
  applyDefaultConfigurationAsync: (filters?: DefaultConfigurationFilterType) =>
    restAPI.nodeAPI.POST<DefaultConfigurationAsyncResponseType>({
      endpoint: `/records/apply_default_configuration_async`,
      body: {
        filter: filters ? getFilterObject(filters) : {},
      },
    }),

  recordValue: RecordValueDomain,

  /**
   * Record Saved Filter APIs
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/records/get_records_filter
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/records/post_records_filter
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/records/delete_records_filter
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/records/put_records_filter
   */
  savedFilters: SavedFiltersDomain<RecordFilterType>({
    endpoint: "/records/filters",
  }),
};
