import { validereApi } from "../../Services/ServiceHelper";
import {
  FEATURE_FLAGS_REQUESTED,
  FEATURE_FLAGS_RETRIEVED,
  FEATURE_FLAGS_RETRIEVAL_FAILED,
} from "../constants/action-types";

/* eslint-disable no-console */

export function featureFlagsRetrieved(featureToggles) {
  return {
    type: FEATURE_FLAGS_RETRIEVED,
    featureToggles,
  };
}

// IMPROVE: the backup param is a temporary by-pass - a step towards removing Redux completely
export const fetchFeatureFlags =
  (backupFeatureFlags: Record<string, boolean>) => (dispatch) => {
    dispatch({ type: FEATURE_FLAGS_REQUESTED });

    if (backupFeatureFlags) {
      dispatch(featureFlagsRetrieved(backupFeatureFlags));
      return Promise.resolve();
    }

    return validereApi
      .get(`/api/@feature_flags`)
      .then((response) => {
        const { features } = response.data;

        dispatch(featureFlagsRetrieved(features));
      })
      .catch((error) => {
        dispatch({
          type: FEATURE_FLAGS_RETRIEVAL_FAILED,
          error,
        });
      });
  };
