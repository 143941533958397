import {
  ValidateTextInput,
  ValidateDateRange,
  ValidateInputArray,
  ValidateCSVSafeInput,
} from "../../../utils/formValidator";
import moment from "moment";
import uniqWith from "lodash/uniqWith";

const MAX_NUM_STREAMS = 5;

function ValidateMeasurementType(bookmarksSpecs, getMeasurementUnit) {
  if (bookmarksSpecs.measurementType.length === 0) {
    return "Measurement Type can not be empty";
  }

  if (bookmarksSpecs.measurementType.length > 6) {
    return "Measurement Type maximum is 6";
  }

  if (
    bookmarksSpecs.streams.length > 1 &&
    bookmarksSpecs.measurementType.length > 1
  ) {
    return "You can only plot at most 1 measurement type if you have mutiple streams.";
  }

  // We can only plot two measurement unit when there is only
  // one stream
  const numOfUniqMeasurementUnit = uniqWith(
    bookmarksSpecs.measurementType,
    (valueA, valueB) => {
      return getMeasurementUnit(valueA) === getMeasurementUnit(valueB);
    }
  ).length;

  if (numOfUniqMeasurementUnit > 2) {
    return "You can only plot at most 2 different measurement units at once.";
  }

  return null;
}

export function validateBookmark(
  bookmarkSpecs,
  dateRangeType,
  getMeasurementUnit
) {
  const isRequired = true;

  const titleErrorMessage = ValidateTextInput(
    "Title",
    bookmarkSpecs.title,
    "string",
    isRequired
  );
  if (titleErrorMessage) {
    return titleErrorMessage;
  }

  const checkTitleCSVSafe = ValidateCSVSafeInput("Title", bookmarkSpecs.title);
  if (checkTitleCSVSafe) {
    return checkTitleCSVSafe;
  }

  const streamsErrorMessage = ValidateInputArray(
    "Streams",
    bookmarkSpecs.streams,
    isRequired,
    MAX_NUM_STREAMS
  );
  if (streamsErrorMessage) {
    return streamsErrorMessage;
  }
  const measurementTypeErrorMessage = ValidateMeasurementType(
    bookmarkSpecs,
    getMeasurementUnit
  );
  if (measurementTypeErrorMessage) {
    return measurementTypeErrorMessage;
  }

  const sampleTypesErrorMessage = ValidateInputArray(
    "Sample Type",
    bookmarkSpecs.sampleTypes,
    isRequired
  );
  if (sampleTypesErrorMessage) {
    return sampleTypesErrorMessage;
  }

  if (dateRangeType.id === "fixed_date_range") {
    const fixDateRangeErrorMessage = ValidateDateRange(
      "Date",
      bookmarkSpecs.timeRangeStart,
      bookmarkSpecs.timeRangeEnd
    );
    if (fixDateRangeErrorMessage) {
      return fixDateRangeErrorMessage;
    }
  } else {
    const relativeDayRangeErrorMessage = ValidateTextInput(
      "Last Num. Of Days",
      bookmarkSpecs.relativeDayRange,
      "string"
    );
    return relativeDayRangeErrorMessage;
  }

  return null;
}

export function getBookmarkSpecs(bookmark, dateRangeType) {
  bookmark.streams = bookmark.streams.map((stream) => stream.id);

  if (dateRangeType.id === "relative_date_range") {
    bookmark.timeRangeEnd = null;
    bookmark.timeRangeStart = null;
  } else {
    bookmark.relativeDayRange = null;
    // Format time range into iso 8601 format
    bookmark.timeRangeStart = moment(bookmark.timeRangeStart).toISOString();
    bookmark.timeRangeEnd = moment(bookmark.timeRangeEnd).toISOString();
  }

  return bookmark;
}
