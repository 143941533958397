import config from "#config";
import ChainOfCustodyService from "#services/ChainOfCustodyService";
import { getTimeStringFromDate } from "#utils/timeFormatter";
import { LegacyDataTable, Panel } from "@validereinc/common-components";
import moment from "moment";
import * as PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { AutoSizer } from "react-virtualized";
import useFilterInputs from "./ExistingSampleFormFilters";
import "./ExistingSampleModals.scss";

const DEFAULT_DATE_RANGE = 30; // 1 Month

const useFetchSamples = () => {
  const [samples, setSamples] = useState([]);
  const [loadingState, setLoadingState] = useState("loading");
  const [from, setFrom] = useState(
    moment().subtract(DEFAULT_DATE_RANGE, "days")
  );
  const [to, setTo] = useState(moment().endOf("day"));

  const onDateRangeChange = (from, to) => {
    setFrom(from);
    setTo(to);
  };

  useEffect(() => {
    setLoadingState("loading");

    ChainOfCustodyService.getSamples(from, to)
      .then(({ data }) => {
        setSamples(data);
      })
      .finally(() => {
        setLoadingState("loaded");
      });
  }, [from, to]);

  return [samples, loadingState, from, to, onDateRangeChange];
};

const ExistingSampleSelectionTable = ({
  onSampleClick,
  selectedExistingSamples,
  selectedSamples,
}) => {
  const [samples, loadingState, from, to, onDateRangeChange] =
    useFetchSamples();

  const [filterPillbox, filterRow, filteredList] = useFilterInputs(
    samples,
    from,
    to,
    onDateRangeChange,
    selectedExistingSamples
  );

  const sampleNameRenderer = (rowData, columnKey) => {
    const sample = rowData[columnKey];
    const samplePointId = sample.sample_point_id
      ? `(${sample.sample_point_id})`
      : "";

    return (
      <div className="wrap">{`${rowData[columnKey]} ${samplePointId}`}</div>
    );
  };

  const offSpecRenderer = (rowData, columnKey) => {
    return rowData[columnKey] ? "Yes" : "No";
  };

  const sampleTypeRenderer = (rowData, columnKey) => {
    return <div className="capitalized">{rowData[columnKey]}</div>;
  };

  const workflowTypeRenderer = (rowData) => {
    const workflowInstance = rowData.workflow_instance;
    let workflowType = "Non-routine";

    if (workflowInstance?.test_type === "field") {
      workflowType = "Field";
    }

    if (workflowInstance?.test_type === "third_party_lab") {
      workflowType = "Third Party Lab";
    }

    return <div className="capitalized">{workflowType}</div>;
  };

  const sampleDateRenderer = (rowData, columnKey) => {
    const date = rowData[columnKey];
    const fromNow = moment(date).fromNow();
    const dateTime = getTimeStringFromDate(date, config.DATETIME_FORMAT);

    return <div className="wrap">{`${dateTime} (${fromNow})`}</div>;
  };

  const headers = [
    {
      label: "Sample Name",
      key: "sample_name",
      width: 250,
      fixed: true,
      cellRenderer: sampleNameRenderer,
    },
    {
      label: "Sample Point ID",
      key: "sample_point_id",
      width: 125,
    },
    {
      label: "Sample Type",
      key: "sample_type",
      width: 115,
      cellRenderer: sampleTypeRenderer,
    },
    {
      label: "Workflow",
      key: "workflow_type",
      width: 100,
      cellRenderer: workflowTypeRenderer,
    },
    {
      label: "Off-Spec",
      key: "has_offspec_tests",
      width: 100,
      cellRenderer: offSpecRenderer,
    },
    {
      label: "Sample Date",
      key: "started_at",
      width: 200,
      cellRenderer: sampleDateRenderer,
    },
  ];

  return (
    <Panel className="existingSampleForm">
      <div className="existingSampleForm__table">
        <AutoSizer>
          {({ height, width }) => (
            <LegacyDataTable
              filterKey="sample_name"
              filterTitle="Samples"
              headers={headers}
              list={filteredList}
              width={width}
              height={height}
              onCheckboxClick={onSampleClick}
              checkedList={selectedSamples}
              isLoading={loadingState !== "loaded"}
              filterRow={filterRow}
              filterPillbox={filterPillbox}
              defaultSortBy="started_at"
              defaultSortDirection="desc"
              rowHeight={60}
            />
          )}
        </AutoSizer>
      </div>
    </Panel>
  );
};

ExistingSampleSelectionTable.propTypes = {
  onSampleClick: PropTypes.func.isRequired,
  selectedExistingSamples: PropTypes.array.isRequired,
  selectedSamples: PropTypes.array,
};

export default ExistingSampleSelectionTable;
