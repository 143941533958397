import Immutable from "immutable";

import { FEATURE_FLAGS_RETRIEVED } from "../constants/action-types";

/**
 * Creates a scoped function which you can invoke to check if a feature flag is enabled
 * @param featureFlagsState the Redux state holding the Immutable feature flag toggles
 * @returns a function which can be invoked with a feature flag string that returns a boolean
 */
export const isFeatureEnabled =
  (featureFlagsState) =>
  (featureFlags: string | string[]): boolean => {
    if (!featureFlagsState.toggles) {
      return false;
    }

    if (Array.isArray(featureFlags)) {
      return featureFlags.some((name) => featureFlagsState.toggles.get(name));
    }

    if (featureFlagsState.toggles.has(featureFlags)) {
      return Boolean(featureFlagsState.toggles.get(featureFlags));
    }

    console.warn(
      `Could not find feature '${featureFlags}' in permissions, defaulting to disabled.`
    );
    return false;
  };

export const FeatureFlagsState = Immutable.Record(
  {
    toggles: undefined,
  },
  "FeatureFlagsState"
);

export default (state = FeatureFlagsState(), action) => {
  switch (action.type) {
    case FEATURE_FLAGS_RETRIEVED: {
      return state.set("toggles", Immutable.Map(action.featureToggles));
    }
    default:
      return state;
  }
};
