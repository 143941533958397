import { Title } from "@validereinc/common-components";
import * as PropTypes from "prop-types";
import React from "react";
import FontAwesome from "react-fontawesome";
import "./SettingSection.css";

const HelperLink = (props) => (
  <a
    className="helperLink"
    href={props.link}
    target="_blank"
    rel="noreferrer"
  >
    <FontAwesome
      className="icon"
      name="question-circle"
    />
  </a>
);

HelperLink.propTypes = {
  link: PropTypes.string.isRequired,
};

const SettingSection = (props) => (
  <div
    className="settingSection"
    style={props.style}
  >
    <Title
      type="subheader"
      className="settingSection__title"
    >
      {props.title}
      {props.link && <HelperLink link={props.link} />}
    </Title>
    <hr />
    {props.message && (
      <div className="sectionSection__message">{props.message}</div>
    )}
    {props.children}
  </div>
);

SettingSection.propTypes = {
  style: PropTypes.object,
  title: PropTypes.string,
  message: PropTypes.string,
  link: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default SettingSection;
