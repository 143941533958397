import { getBreadcrumbsObject } from "#src/Routers/breadcrumbsHelper";
import { Page, Panel } from "@validereinc/common-components";
import * as PropTypes from "prop-types";
import React from "react";
import { getManualEntryType } from "../../ManualEntryHelper";
import FieldManualEntry from "./FieldManualEntry";

const FieldManualEntryPage = (props) => {
  const breadcrumbs = getBreadcrumbsObject(
    props?.breadcrumbs,
    props?.match?.params
  );

  return (
    <Page
      title={getManualEntryType("field")?.title}
      breadcrumbs={breadcrumbs}
    >
      <Panel>
        <FieldManualEntry />
      </Panel>
    </Page>
  );
};

FieldManualEntryPage.propTypes = {
  breadcrumbs: PropTypes.array,
  match: PropTypes.object,
  view: PropTypes.string,
};

export default FieldManualEntryPage;
