import { useNavigate } from "#src/Routers/hooks";
import { RoutingLink } from "#src/batteries-included-components/RoutingLink";
import { useAuthenticatedContext } from "#src/contexts/AuthenticatedContext.helpers";
import { useStorageKey } from "#src/hooks/useStorageKey";
import { RoleContext } from "#src/routes/settings/roles-and-permissions/roles/[roleId]/detail/RoleContext";
import { linkToEditRoleUsersPage } from "#src/routes/settings/roles-and-permissions/roles/[roleId]/users/edit";
import { linkToUserDetailPage } from "#src/routes/settings/users/detail";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  Button,
  DataTable,
  DataTablePanel,
  Dialog,
  HeaderType,
  useAlert,
} from "@validereinc/common-components";
import { RoleUserMembershipType, RolesAdapter } from "@validereinc/domain";
import React, { useContext, useState } from "react";

export const RoleUsersTablePanel = () => {
  const { addAlert } = useAlert();
  const navigate = useNavigate();
  const {
    claims: {
      user: { id: currentUserId },
    },
  } = useAuthenticatedContext();
  const [userToRemove, setUserToRemove] = useState<
    RoleUserMembershipType["user"] | null
  >(null);
  const { tableConfigStorageKey } = useStorageKey("roles-users");
  const queryClient = useQueryClient();
  const { roleQuery, roleMembersQuery, isCurrentUserInRole } =
    useContext(RoleContext) ?? {};

  const removeUserFromRoleMutation = useMutation({
    mutationFn: ({
      roleId,
      user,
    }: {
      roleId: string;
      user: { id: string; name: string };
    }) => {
      return RolesAdapter.members.delete({
        roleId,
        userId: user.id,
      });
    },
    onSuccess: (_, { user }) => {
      queryClient.invalidateQueries({ queryKey: ["roles"] });
      queryClient.invalidateQueries({ queryKey: ["users"] });
      addAlert?.({
        variant: "success",
        message: `Successfully removed ${user.name ?? "user"} from role`,
      });
    },
    onError: (_, { user }) => {
      addAlert?.({
        variant: "success",
        message: `Failed to remove ${user.name ?? "user"} from role`,
      });
    },
    onMutate: () => {
      setUserToRemove(null);
    },
  });

  const headers: Array<HeaderType<RoleUserMembershipType>> = [
    {
      label: "Name",
      key: "user.name",
      renderComponent: ({ item }) => (
        <RoutingLink to={linkToUserDetailPage(item?.user_id)}>
          {item?.user.name}
          {currentUserId === item?.user_id ? " (You)" : ""}
        </RoutingLink>
      ),
    },
    {
      label: "Email",
      key: "user.email",
      renderComponent: ({ item }) => item?.user.email ?? "-",
    },
    {
      label: "Effective Date",
      key: "created_at",
      tooltip: "When the user was assigned this role",
      renderComponent: ({ item }) => (
        <DataTable.DataRow.DateCell
          value={item.created_at}
          withTime
        />
      ),
    },
  ];

  const getItemActions = ({ item }: { item?: RoleUserMembershipType }) => {
    return [
      {
        label: "Remove",
        buttonProps: {
          onClick: () => setUserToRemove(item?.user ?? null),
          icon: "trash",
          disabled: !item,
        },
      },
    ];
  };

  return (
    <>
      <DataTablePanel
        storageKey={tableConfigStorageKey}
        actionRowWhenNoRowsSelected={[
          <Button
            key="assign-users"
            onClick={() => {
              if (!roleQuery?.data?.id) {
                return;
              }

              navigate({
                pathname: linkToEditRoleUsersPage(roleQuery.data.id),
              });
            }}
          >
            Assign Users
          </Button>,
        ]}
        dataTableProps={{
          headers,
          items: roleMembersQuery?.data ?? [],
          isLoading: roleMembersQuery?.isLoading,
          getItemActions,
        }}
        panelProps={{ title: "Members", loaded: !roleMembersQuery?.isLoading }}
      />

      <Dialog
        isOpen={!!userToRemove}
        onClose={() => setUserToRemove(null)}
        title="Remove User"
        actionRow={[
          <Button
            key="delete-dialog-action"
            onClick={() => {
              if (!roleQuery?.data?.id || !userToRemove) {
                return;
              }

              removeUserFromRoleMutation.mutate({
                roleId: roleQuery.data.id,
                user: {
                  id: userToRemove.id,
                  name: userToRemove.name,
                },
              });
            }}
            variant="error"
            disabled={!roleQuery?.data?.id || !userToRemove}
          >
            Remove User
          </Button>,
        ]}
      >
        Are you sure you want to remove{" "}
        {isCurrentUserInRole ? "yourself" : userToRemove?.name ?? "this user"}{" "}
        from this role?
      </Dialog>
    </>
  );
};
