import React, { useState, useEffect } from "react";
import moment from "moment";
import * as PropTypes from "prop-types";
import InstrumentTests from "./InstrumentTests";
import InstrumentService from "../Services/InstrumentService";
import SampleService from "../Services/SampleService";
import DateRangeSelector from "#common/DateSelector/DateRangeSelector";
import { linkToTestDetails, linkToInstrumentTests } from "#routers/links";
import history from "#src/Routers/history";

const DEFAULT_DATE_RANGE = 30;

const useManageTestModal = (testId, instrumentType) => {
  const [testDetail, setTestDetail] = useState(null);
  const [showDetailReport, setShowDetailReport] = useState(false);

  const fetchTestDetail = (testId) => {
    SampleService.getTestDetails(testId).then(({ data }) => {
      setTestDetail(data);
      setShowDetailReport(true);
    });
  };

  const openTestDetail = (rowDetail) => {
    const { instrument, id } = rowDetail;

    if (instrument?.id && id) {
      history.push(linkToTestDetails(instrument.id, id));
    }
  };

  const closeTestDetail = (instrumentId) => {
    if (instrumentId) {
      history.push(linkToInstrumentTests(instrumentId));
    }

    setShowDetailReport(false);
  };

  useEffect(() => {
    if (testId && testId !== "*") {
      // Remove once WaterSampleReport migrated to TestDetailModal
      if (instrumentType === "water") {
        fetchTestDetail(testId);
      } else {
        setShowDetailReport(true);
      }
    }
  }, [testId]);

  return {
    testDetail,
    showDetailReport,
    openTestDetail,
    closeTestDetail,
  };
};

const useFetchTests = (instrumentId) => {
  const [tests, setTests] = useState([]);
  const [areTestsLoading, setAreTestsLoading] = useState(true);

  const [dateRange, setDateRange] = useState({
    from: moment().subtract(DEFAULT_DATE_RANGE, "days").startOf("day").toDate(),
    until: moment().endOf("day").toDate(),
  });

  const fetchInstrumentTests = () => {
    setAreTestsLoading(true);

    InstrumentService.getInstrumentTests(instrumentId, dateRange).then(
      ({ data }) => {
        setTests(data);
        setAreTestsLoading(false);
      }
    );
  };

  useEffect(() => {
    if (instrumentId) {
      fetchInstrumentTests();
    }
  }, [instrumentId, dateRange]);

  const selectDate = (from, until) => {
    setDateRange({
      from: moment(from).startOf("day").toDate(),
      until: moment(until).endOf("day").toDate(),
    });
  };

  const filterRow = (
    <div className="filterRow">
      <DateRangeSelector
        dateRange={{
          from: dateRange.from,
          to: dateRange.until,
        }}
        onDateRangeChange={selectDate}
        icon
      />
    </div>
  );

  return {
    tests,
    areTestsLoading,
    fetchInstrumentTests,
    filterRow,
  };
};

const InstrumentDetail = (props) => {
  const { testDetail, showDetailReport, openTestDetail, closeTestDetail } =
    useManageTestModal(props.testId, props.instrumentInfo?.type);

  const { tests, areTestsLoading, fetchInstrumentTests, filterRow } =
    useFetchTests(props.instrumentInfo.id);

  const { ReportModalComponent } = props;

  return (
    <>
      <InstrumentTests
        tests={tests}
        height={props.height}
        headers={props.headers}
        filterKey={props.filterKey}
        filterRow={filterRow}
        openTestDetail={openTestDetail}
        defaultSortBy={props.defaultSortBy}
        defaultSortDirection={props.defaultSortDirection}
        isLoading={areTestsLoading}
        instrumentInfo={props.instrumentInfo}
        refetchData={fetchInstrumentTests}
      />

      {showDetailReport && (
        <ReportModalComponent
          instrumentInfo={props.instrumentInfo}
          testDetail={testDetail}
          testId={props.testId}
          showModal={true}
          onHide={() => closeTestDetail(props?.instrumentInfo?.id)}
          isLoaded={true}
          onTestsChange={fetchInstrumentTests}
        />
      )}
    </>
  );
};

InstrumentDetail.propTypes = {
  ReportModalComponent: PropTypes.elementType.isRequired,
  testId: PropTypes.string,
  height: PropTypes.number.isRequired,
  headers: PropTypes.array.isRequired,
  filterKey: PropTypes.string.isRequired,
  instrumentInfo: PropTypes.object.isRequired,
  defaultSortBy: PropTypes.string,
  defaultSortDirection: PropTypes.string,
};

export default InstrumentDetail;
