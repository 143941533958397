import { UserListFilterPanel } from "#src/batteries-included-components/Panels/FilterPanels/UserListFilterPanel";
import { useStorageKey } from "#src/hooks/useStorageKey";
import { useFormContext } from "@validereinc/common-components";
import classNames from "classnames/bind";
import React from "react";
import styles from "./CreateRoleUsersStep.module.scss";
import { SelectedAssetsPanel } from "./SelectedAssetsPanel";
import { UserSelectionTablePanel } from "./UserSelectionTablePanel";

const cx = classNames.bind(styles);

export const CreateRoleUsersStepContent = () => {
  const { filterConfigStorageKey, tableConfigStorageKey } =
    useStorageKey("roles-assign-users");
  const { watch } = useFormContext();
  const selectedUsers = watch("selected_users");

  return (
    <>
      <UserListFilterPanel filterConfigStorageKey={filterConfigStorageKey} />
      <div className={cx("container")}>
        <UserSelectionTablePanel
          selectedUsers={selectedUsers}
          filterConfigStorageKey={filterConfigStorageKey}
          tableConfigStorageKey={tableConfigStorageKey}
        />

        <div className={cx("selectionContainer")}>
          <SelectedAssetsPanel selectedUsers={selectedUsers} />
        </div>
      </div>
    </>
  );
};
