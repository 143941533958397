import {
  CompanySchema,
  CompanyType,
  LegacyCompanyClaimSchema,
  LegacyCompanyClaimType,
} from "../schemas";
import { DomainFaker, DomainMock } from "./utils";

export const legacyCompanyClaimMock = new DomainMock<
  LegacyCompanyClaimType,
  typeof LegacyCompanyClaimSchema
>(
  LegacyCompanyClaimSchema,
  () => {
    return {
      id: DomainFaker.en.string.uuid(),
      int_id: DomainFaker.en.number.int({ min: 1 }),
      name: DomainFaker.en.company.name(),
    };
  },
  true
).fix();

export const companyMock = new DomainMock<CompanyType, typeof CompanySchema>(
  CompanySchema,
  () => {
    const createdAt = DomainFaker.en.date.past();

    return {
      id: DomainFaker.en.string.uuid(),
      name: DomainFaker.en.company.name(),
      localization: {},
      created_at: createdAt.toISOString(),
      created_by: DomainFaker.en.string.uuid(),
      updated_at: DomainFaker.en.date
        .between({ from: createdAt, to: new Date() })
        .toISOString(),
      updated_by: DomainFaker.en.string.uuid(),
    };
  },
  true
).fix();
