import {
  CUBIC_METERS_VOLUME,
  getFormattedMeasurementValueWithUnit,
} from "#redux/reducers/measurements";
import { LegacyDataTable, Tooltip } from "@validereinc/common-components";
import * as PropTypes from "prop-types";
import React, { useMemo } from "react";
import FontAwesome from "react-fontawesome";
import { connect } from "react-redux";
import { AutoSizer } from "react-virtualized";
import { isValidFloatwDecimal } from "../../../Instrument/InstrumentComponentHelper";
import {
  getDisplayProperties,
  getDisplayPropertyShortForm,
} from "../Detail/StreamDetailHelper";
import { useRecordSelectionContext } from "./Context/recordSelectionContext";

const ROW_HEIGHT = 45;
const COMPONENT_VALUE_WIDTH = 130;

const mapStateToProps = (state) => {
  return {
    getFormattedMeasurementValueWithUnit: (measurementKey, measurementObject) =>
      getFormattedMeasurementValueWithUnit(state.measurements)(
        measurementKey,
        measurementObject
      ),
  };
};

const AVERAGE_ROQ_TOOLIP =
  "The average is calculated from the previous 4 RoQ (not including current one)";

function getQualityColumns(currentRecordOfQuality, historicalRecordOfQuality) {
  const qualityColumns = {};

  qualityColumns["Current Volume"] = {
    ["Measured Volume"]: currentRecordOfQuality?.raw_volume ?? "-",
    ...currentRecordOfQuality.component_volumes,
  };

  qualityColumns["Current RoQ"] = {
    ["Measured Volume"]: currentRecordOfQuality?.raw_volume ?? "-",
    ...currentRecordOfQuality.qualities,
    Energy: currentRecordOfQuality?.energy ?? "-",
  };

  qualityColumns["Previous RoQ"] = {
    ["Measured Volume"]: historicalRecordOfQuality.previous?.volume,
    ...historicalRecordOfQuality.previous?.qualities,
  };

  return qualityColumns;
}

function getQualityColumnHeader(header) {
  if (header === "Average RoQ") {
    return (
      <Tooltip content={AVERAGE_ROQ_TOOLIP}>
        {header}
        <FontAwesome
          name="info-circle"
          style={{ marginLeft: "5px" }}
        />
      </Tooltip>
    );
  }
  return header;
}

const RecordResultsTable = (props) => {
  const { streams } = useRecordSelectionContext();

  const qualityColumns = getQualityColumns(
    props.recordOfQualityDetail,
    props.historicalData
  );

  const priceRenderer = (component) => {
    const priceValue =
      props.recordOfQualityDetail.accounting_period.component_prices?.[
        component
      ];

    if (priceValue?.value) {
      const { symbol, value, currency, unit } = priceValue;

      return (
        <div className="recordOfQualityResultTable__row">
          {`${symbol}${isValidFloatwDecimal(
            value,
            2,
            "-"
          )} ${currency} / ${unit}`}
        </div>
      );
    } else {
      return "-";
    }
  };

  const componentValueRenderer = (component, column) => {
    const componentValue = qualityColumns?.[column]?.[component];

    let measurementKey = component;
    if (component == "Measured Volume") {
      measurementKey = CUBIC_METERS_VOLUME;
    }

    if (componentValue?.value) {
      return (
        <div className="recordOfQualityResultTable__row">
          {props.getFormattedMeasurementValueWithUnit(
            measurementKey,
            componentValue
          )}
        </div>
      );
    } else {
      return <div className="recordOfQualityResultTable__row--empty">-</div>;
    }
  };

  // TODO: The properties shown in the table is currently defined by the selected stream.
  // If a Production Report is chosen, the display properties of that report should override
  const sortedDisplayProperties = useMemo(() => {
    if (streams.length && props.recordOfQualityDetail) {
      const selectedStream = streams.find(
        (stream) => stream.id === props.recordOfQualityDetail.stream.id
      );

      return getDisplayProperties(selectedStream, props.recordOfQualityDetail);
    }
    return [];
  }, [streams, props.recordOfQualityDetail]);

  const headers = [
    {
      label: "Component",
      key: "name",
      width: 140,
      cellRenderer: getDisplayPropertyShortForm,
      disableSort: true,
      fixed: true,
    },

    {
      label: "Price",
      key: "price",
      width: COMPONENT_VALUE_WIDTH,
      cellRenderer: priceRenderer,
      disableSort: true,
      rightAlign: true,
    },

    ...Object.keys(qualityColumns).map((column) => ({
      label: getQualityColumnHeader(column),
      key: column,
      width: COMPONENT_VALUE_WIDTH,
      cellRenderer: componentValueRenderer,
      disableSort: true,
      rightAlign: true,
    })),
  ];

  return (
    <div style={{ height: props.height }}>
      <AutoSizer>
        {({ width, height }) => (
          <LegacyDataTable
            list={sortedDisplayProperties}
            headers={headers}
            height={height}
            width={width}
            rowHeight={ROW_HEIGHT}
          />
        )}
      </AutoSizer>
    </div>
  );
};

RecordResultsTable.propTypes = {
  getFormattedMeasurementValueWithUnit: PropTypes.func.isRequired,
  recordOfQualityDetail: PropTypes.object.isRequired,
  historicalData: PropTypes.object.isRequired,
  height: PropTypes.number.isRequired,
};

export default connect(mapStateToProps)(RecordResultsTable);
