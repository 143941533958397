import { Title } from "@validereinc/common-components";
import React from "react";
import { useSelectionModalContext } from "../../../Context/selectionModalContext";
import ResultDifference, { computeDifference } from "../ResultDifference";
import "./RecordOfQualityResultPricing.scss";
import { getTotalPrice } from "./RecordOfQualityResultsHelper";

const RecordOfQualityResultPricing = () => {
  const { recordOfQualityDetail, originalRecordOfQualityDetail } =
    useSelectionModalContext();

  const currentPrice = recordOfQualityDetail?.estimated_value;
  const originalPrice = originalRecordOfQualityDetail?.estimated_value;

  const { percentChange, difference } = computeDifference(
    currentPrice,
    originalPrice
  );

  return (
    <div className="recordOfQualityResultPricing">
      <Title
        type="subheader"
        className="recordOfQualityResultPricing__title"
      >
        Pricing
      </Title>

      {getTotalPrice(currentPrice)}

      {difference && (
        <ResultDifference
          difference={difference}
          percentChange={percentChange}
          unit={currentPrice?.symbol}
          showUnitOnLeft
          showPercentChange
        />
      )}

      <div className="recordOfQualityResultPricing__label">
        Estimated Total Value
      </div>
    </div>
  );
};

export default RecordOfQualityResultPricing;
