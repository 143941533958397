import LineChart from "#common/Chart/LineChart";
import { getFormattedNumberWithUnit } from "@validereinc/utilities";
import classNames from "classnames/bind";
import moment from "moment";
import * as PropTypes from "prop-types";
import React, { useMemo } from "react";
import { AutoSizer } from "react-virtualized";
import { INTENSITY } from "../../../EmissionConstants";
import styles from "../EmissionReportDetail.module.scss";

const cx = classNames.bind(styles);

const DEFAULT_DECIMAL = 2;

const SummaryChart = ({
  emissionReportSummary,
  selectedSalesStream,
  selectedPollutant,
}) => {
  function getYAxis(report, selectedPollutant) {
    if (INTENSITY.includes(selectedPollutant)) {
      return report.intensities?.[selectedPollutant] ?? null;
    } else {
      return report.emissions?.[selectedPollutant] ?? null;
    }
  }

  const lineData = useMemo(() => {
    if (emissionReportSummary.length > 0) {
      const emissionLineChartData = [];

      emissionReportSummary.forEach((report) => {
        if (report.sales_stream.id === selectedSalesStream.id) {
          emissionLineChartData.push({
            streamName: selectedPollutant,
            title: report.sales_stream.name,
            value: getYAxis(report, selectedPollutant) ?? 0,
            x: moment(report.start_date).valueOf(),
            y: getYAxis(report, selectedPollutant)?.value ?? 0,
          });
        }
      });

      return emissionLineChartData;
    }
    return [];
  }, [emissionReportSummary, selectedSalesStream, selectedPollutant]);

  return (
    <div className={cx("emissionChart")}>
      <AutoSizer>
        {({ width, height }) => (
          <LineChart
            width={width}
            height={height}
            className="emissionChart__container"
            yLabel={selectedPollutant ?? "Emissions"}
            xLabel="Date"
            data={lineData}
            getCategory={(data) => data.streamName}
            getValue={(data) =>
              getFormattedNumberWithUnit(data.value, DEFAULT_DECIMAL)
            }
            showChartLegend={false}
          />
        )}
      </AutoSizer>
    </div>
  );
};

SummaryChart.propTypes = {
  emissionReportSummary: PropTypes.array.isRequired,
  selectedSalesStream: PropTypes.object.isRequired,
  selectedPollutant: PropTypes.string.isRequired,
};

export default SummaryChart;
