import { UserDetailContext } from "#src/routes/settings/users/detail/UserDetailContext";
import {
  KeyValuePanel,
  TelephoneDisplay,
} from "@validereinc/common-components";
import formatInTimeZone from "date-fns-tz/formatInTimeZone";
import React, { useContext } from "react";

export const UserDetailsPanel = () => {
  const { userDetails } = useContext(UserDetailContext) ?? {};

  return (
    <KeyValuePanel
      panelMaxColumnCount={2}
      panelKeyValueListProps={{ maxRowCount: 3, variant: "shaded" }}
      panelProps={{ isFluidY: false }}
      data={[
        {
          title: "Name",
          value: userDetails?.data?.name,
        },
        {
          title: "Phone  Number",
          value: (
            <TelephoneDisplay
              value={userDetails?.data?.phone}
              fallbackDisplay="-"
            />
          ),
        },
        {
          title: "Email",
          value: userDetails?.data?.email,
        },
        {
          title: "Timezone",
          value:
            userDetails?.data?.timezone &&
            `${userDetails.data.timezone} (${formatInTimeZone(
              new Date(),
              userDetails.data.timezone,
              "E p, O"
            )})`,
        },
      ]}
    />
  );
};
