import { NetworkListRoute } from "#routes/organization/networks";
import { RoutePath } from "#utils/route";

export type NetworkDetailPageParameters = {
  networkId: string;
};

export const NetworkDetailRoute = NetworkListRoute.concat(
  new RoutePath<"networkId">({
    path: "/:networkId/detail",
    title: "Network Detail",
  })
);
