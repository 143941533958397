import { useTableSortingAndPagination } from "#redux/reducers/tableStateReducer";
import { useStorageKey } from "#src/hooks/useStorageKey";
import { useQuery } from "@tanstack/react-query";
import {
  DataTable,
  DataTablePanel,
  SortingType,
  StorageKeys,
  useFilters,
} from "@validereinc/common-components";
import {
  SortDirection,
  UserGroupMembershipType,
  UserType,
  UsersAdapter,
} from "@validereinc/domain";
import { toStartCaseString } from "@validereinc/utilities";
import classNames from "classnames/bind";
import React, { useMemo } from "react";
import styles from "./UserGroupFormPanel.module.scss";

const cx = classNames.bind(styles);

const sorting: SortingType = {
  sortBy: "name",
  sortDirection: SortDirection.ASCENDING,
};

export const UserSelectionTablePanel = ({
  selectedUsers,
  addUser,
  filterConfigStorageKey,
}: Pick<StorageKeys, "filterConfigStorageKey"> & {
  selectedUsers: Array<UserGroupMembershipType["user"]>;
  addUser: (newUser: UserType) => void;
}) => {
  const { tableConfigStorageKey } = useStorageKey("user-selection-table");
  const [filters] = useFilters<{ search: string }>(filterConfigStorageKey);
  const [tableState, updateTableState] = useTableSortingAndPagination(
    sorting,
    filters
  );

  const queryPayload = {
    page: tableState.page,
    pageSize: tableState.itemsPerPage,
    sortBy: tableState.sortBy,
    sortDirection: tableState.sortDirection,
    filters: {
      isAlreadyFormatted: true,
      ...(filters.search
        ? {
            $or: [
              { name: { $like: filters.search } },
              { email: { $like: filters.search } },
            ],
          }
        : {}),
    },
  };

  const { data, isLoading } = useQuery(
    ["users", "v2", queryPayload],
    () => UsersAdapter.getList(queryPayload),
    {
      staleTime: 3 * 60 * 1000,
    }
  );

  const items = data?.data ?? [];

  const selectedUserIds = useMemo(
    () => selectedUsers.map(({ id }) => id),
    [selectedUsers]
  );

  const getItemActions = ({ item }: { item: UserType }) =>
    selectedUserIds.includes(item.id)
      ? []
      : [
          {
            label: "Add User",
            buttonProps: {
              onClick: () => addUser(item),
              icon: "plus-circle",
            },
          },
        ];

  return (
    <div className={cx("userSelectionContainer")}>
      <div className={cx("userTableContainer")}>
        <DataTablePanel
          storageKey={tableConfigStorageKey}
          panelProps={{
            title: "All Users",
          }}
          dataTableProps={{
            headers: [
              { key: "name", label: "Name" },
              { key: "email", label: "Email" },
              {
                label: "Status",
                key: "status",
                isSortable: true,
                renderComponent: ({ item }: { item: UserType }) => (
                  <DataTable.DataRow.PillCell
                    variant={item.status === "active" ? "success" : "default"}
                    value={toStartCaseString(item.status)}
                  />
                ),
              },
              {
                label: "Effective Date",
                key: "created_at",
                isSortable: true,
                renderComponent: ({ item }: { item: UserType }) => (
                  <DataTable.DataRow.DateCell value={item.created_at} />
                ),
              },
            ],
            sorting,
            isLoading,
            items,
            pagination: {
              page: tableState.page,
              itemsPerPage: tableState.itemsPerPage,
              total: data?.total_entries ?? 0,
            },
            onSortChange: updateTableState,
            onPaginationChange: updateTableState,
            isFluid: false,
            getItemActions,
          }}
        />
      </div>
    </div>
  );
};
