import React from "react";
import { NetworkResultsTablePanel } from "#routes/organization/networks/[networkId]/detail/NetworkResultsTab/NetworkResultsTablePanel";
import { useStorageKey } from "#src/hooks/useStorageKey";

export const NetworkResultsTab = () => {
  const storageKeys = useStorageKey("network-calculation-results");

  return (
    <>
      <NetworkResultsTablePanel {...storageKeys} />
    </>
  );
};
