import { z } from "zod";
import {
  DomainModelMetaExtendedSchema,
  DomainModelMetaSchema,
} from "./DomainModelSchemas";

export const CompanyFeatureFlagSchema = z
  .object({
    company_id: z.string().uuid(),
    feature_flag_name: z.string(),
  })
  .merge(DomainModelMetaSchema)
  .merge(DomainModelMetaExtendedSchema)
  .describe("Represents a feature flag being turned on for a specific company");

export type CompanyFeatureFlagType = z.infer<typeof CompanyFeatureFlagSchema>;
