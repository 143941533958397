import FacilityService from "#services/FacilityService";
import { QUERY_STATUS, QueryStatusType } from "#src/constants";
import type { EquipmentType } from "@validereinc/domain";
import { useEffect, useState } from "react";

export function useEquipmentDetail(
  equipmentId: string
): [EquipmentType | undefined, QueryStatusType] {
  const [equipment, setEquipment] = useState<EquipmentType | undefined>();
  const [fetchState, setFetchState] = useState<QueryStatusType>(
    QUERY_STATUS.LOADING
  );

  useEffect(() => {
    const fetchDataAsync = async () => {
      setFetchState(QUERY_STATUS.LOADING);
      try {
        // 1. fetch equipment detail
        const equipmentResponse =
          await FacilityService.getEquipmentDetail(equipmentId);

        setEquipment(equipmentResponse?.data);
        setFetchState(QUERY_STATUS.SUCCESS);
      } catch (error) {
        setFetchState(QUERY_STATUS.ERROR);
      }
    };

    fetchDataAsync();
  }, [equipmentId]);

  return [equipment, fetchState];
}
