import { CalculatorDomain, LibraryNodeType } from "@validereinc/domain";
import { useEffect, useState } from "react";

import { QUERY_STATUS, QueryStatusType } from "../../constants/fetch";

/**
 * Request convenience hook to fetch a list of libraries (aka "Calculation Libraries")
 * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/emissions_calculator/get_libraries
 */
export function useListLibrary(): [
  LibraryNodeType | undefined,
  QueryStatusType,
] {
  const [libraries, setLibraries] = useState<LibraryNodeType>();
  const [fetchState, setFetchState] = useState<QueryStatusType>(
    QUERY_STATUS.LOADING
  );

  useEffect(() => {
    setFetchState(QUERY_STATUS.LOADING);
    CalculatorDomain.getLibraries()
      .then((data) => {
        setLibraries(data);
        setFetchState(QUERY_STATUS.SUCCESS);
      })
      .catch((err) => {
        console.error(err);
        setFetchState(QUERY_STATUS.ERROR);
      });
  }, []);

  return [libraries, fetchState];
}
