import EmissionService from "#services/EmissionService";
import { LegacyDataTable, Panel } from "@validereinc/common-components";
import * as PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { AutoSizer } from "react-virtualized";
import useFilterInputs from "./EmissionRecordsTableFilter";

import {
  DEFAULT_PAGINATION_VALUES,
  useFetchPaginatedDataWithQueryParam,
} from "#common/Table/PaginationController/hooks";
import config from "#config";
import { getFormattedMeasurementValueWithUnit } from "#redux/reducers/measurements";
import useQueryParams from "#routers/useQueryParams";
import { getTimeStringFromDate } from "#utils/timeFormatter";
import { POLLUTANTS } from "./EmissionRecordsConstants";

const HEIGHT_OFFSET = 45;
const mapStateToProps = (state) => {
  return {
    getFormattedMeasurementValueWithUnit: (measurementKey, measurementObject) =>
      getFormattedMeasurementValueWithUnit(state.measurements)(
        measurementKey,
        measurementObject
      ),
  };
};

const EmissionRecordsPanel = ({
  height,
  defaultDateRange,
  facilities,
  isFacilitiesLoaded,
  getFormattedMeasurementValueWithUnit,
}) => {
  const measurementRenderer = (rowData, columnKey) => {
    const measurement = rowData.total_emissions[columnKey];
    return getFormattedMeasurementValueWithUnit(columnKey, measurement);
  };

  const recordDateRenderer = (rowData, columnKey) =>
    getTimeStringFromDate(
      rowData.accounting_period[columnKey],
      config.SHORT_DATEMONTH_FORMAT
    );

  const headers = [
    {
      label: "Facility",
      key: "facility.name",
      width: 300,
      fixed: true,
    },
    {
      label: "Facility Code",
      key: "facility.facility_code",
      width: 125,
    },
    {
      label: "Record Date",
      key: "start_date",
      width: 125,
      cellRenderer: recordDateRenderer,
    },
    ...POLLUTANTS.map((pollutant) => ({
      label: pollutant,
      key: pollutant,
      cellRenderer: measurementRenderer,
      width: 125,
      rightAlign: true,
    })),
  ];

  const [queryParams] = useQueryParams({
    ...defaultDateRange,
  });

  const [filterRow, filterPillbox, filterInputs, isFilterInputsLoading] =
    useFilterInputs(facilities, queryParams);

  const [emissionRecords, isLoading, paginationDetail, onPaginationChange] =
    useFetchPaginatedDataWithQueryParam(
      EmissionService.getEmissionRecords,
      filterInputs,
      DEFAULT_PAGINATION_VALUES,
      isFilterInputsLoading
    );

  return (
    <Panel
      style={{ height: height - HEIGHT_OFFSET }}
      loaded={isFacilitiesLoaded}
    >
      <AutoSizer>
        {({ height, width }) => (
          <LegacyDataTable
            height={height}
            width={width}
            headers={headers}
            filterRow={filterRow}
            filterPillbox={filterPillbox}
            list={emissionRecords}
            paginationDetail={paginationDetail}
            onPaginationChange={onPaginationChange}
            showOverlayLoader={isLoading}
          />
        )}
      </AutoSizer>
    </Panel>
  );
};

EmissionRecordsPanel.propTypes = {
  height: PropTypes.number.isRequired,
  defaultDateRange: PropTypes.object.isRequired,
  facilities: PropTypes.array.isRequired,
  isFacilitiesLoaded: PropTypes.bool.isRequired,
  getFormattedMeasurementValueWithUnit: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(EmissionRecordsPanel);
