export const EDIT_ROLE_PERMISSIONS_TITLE = "Edit:roleId";

export const linkToEditRolePermissionsPage = (roleId = ":roleId") =>
  `/app/settings/roles-and-permissions/roles/${roleId}/permissions/edit`;

export const EDIT_ROLE_PROFILE_BREADCRUMB = {
  title: EDIT_ROLE_PERMISSIONS_TITLE,
  path: linkToEditRolePermissionsPage,
};

export * from "./EditRolePermissionsPage";
