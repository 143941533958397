import { addAlertMessage } from "#redux/actions/alertMessages";
import { ensureStreamListIsFetched, fetchSiteList } from "#redux/actions/index";
import { AlertMessage } from "#redux/reducers/alertMessages";
import { getBreadcrumbsObject } from "#routers/breadcrumbsHelper";
import history from "#routers/history";
import { linkToChainOfCustody } from "#routers/links";
import ChainOfCustodyService from "#services/ChainOfCustodyService";
import {
  Button,
  Form,
  FormButton,
  Page,
  Panel,
  useForm,
} from "@validereinc/common-components";
import differenceBy from "lodash/differenceBy";
import filter from "lodash/filter";
import isEqual from "lodash/isEqual";
import unionWith from "lodash/unionWith";
import * as PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import ChainOfCustodyFormModal from "../ChainOfCustodyFormModal";
import "./CreateChainOfCustody.scss";
import CreateChainOfCustodyForm from "./CreateChainOfCustodyForm";
import { formatSamples } from "./CreateChainOfCustodyHelper";
import ExistingSampleModal from "./SampleModals/ExistingSampleModal/ExistingSampleModal";
import {
  ADD_EXISTING_SAMPLE_MODAL_KEY,
  ADD_NEW_SAMPLE_MODAL_KEY,
  CHAIN_OF_CUSTODY_FORM,
  EDIT_SAMPLE_MODAL_KEY,
} from "./SampleModals/ModalConstants";
import EditSampleModal from "./SampleModals/NewSampleModal/EditSampleModal";
import NewSampleModal from "./SampleModals/NewSampleModal/NewSampleModal";

const DEFAULT_VALUES = {
  name: "",
  type: null,
  site: null,
  samples: [],
};

const mapStateToProps = (state) => {
  return {
    sites: state.sites.data.toJS(),
    clientStreams: state.streams?.data?.toJS() ?? [],
  };
};

const mapDispatchToProps = {
  addAlertMessage,
  fetchSiteList,
  ensureStreamListIsFetched,
};

const CreateChainOfCustody = ({
  profile,
  clientStreams,
  sites,
  fetchSiteList,
  ensureStreamListIsFetched,
  breadcrumbs,
  match,
  addAlertMessage,
}) => {
  const [modalKey, setModalKey] = useState(null);
  const [selectedSample, setSelectedSample] = useState(null);
  const [formState, setFormState] = useState("enabled");
  const [chainOfCustodyId, setChainOfCustodyId] = useState(null);

  const form = useForm({
    defaultValues: DEFAULT_VALUES,
  });

  const onModalToggle = (key, sample) => {
    setModalKey(key);

    if (sample) {
      setSelectedSample(sample);
    }
  };

  const onAddSample = (samples) => {
    const newSamples = unionWith(form.getValues("samples"), samples, isEqual);

    form.setValue("samples", newSamples, {
      shouldValidate: true,
      shouldTouch: true,
    });
  };

  const onUpdateSample = (updatedSample) => {
    let newSamples = form.getValues("samples");
    newSamples = newSamples.map((sample) =>
      sample.id === selectedSample.id ? updatedSample : sample
    );

    form.setValue("samples", newSamples, {
      shouldValidate: true,
      shouldTouch: true,
    });
  };

  const onRemoveSample = (sample) => {
    const removeSample = sample ?? selectedSample;
    const newSamples = differenceBy(
      form.getValues("samples"),
      [removeSample],
      "id"
    );

    form.setValue("samples", newSamples, {
      shouldValidate: true,
      shouldTouch: true,
    });
  };

  const onCreateChainOfCustodyClick = (inputs) => {
    setFormState("loading");

    const newSample = filter(inputs.samples, { new_sample: true });
    const existingSample = filter(inputs.samples, { new_sample: false });

    const siteId = inputs.site?.id;
    const sampleIds = existingSample.map((sample) => sample.id);

    ChainOfCustodyService.createChainOfCustodyForm(
      inputs.type,
      siteId,
      sampleIds,
      inputs.name,
      newSample
    )
      .then(({ data }) => {
        addAlertMessage(
          new AlertMessage({
            type: "success",
            message: "Chain Of Custody created successfully.",
          })
        );

        setFormState("locked");
        setChainOfCustodyId(data);
        setModalKey(CHAIN_OF_CUSTODY_FORM);
      })
      .catch(() => {
        setFormState("enabled");
      });
  };

  const resetChainOfCustodyClick = () => {
    setSelectedSample(null);
    setChainOfCustodyId(null);

    form.reset({ ...DEFAULT_VALUES });
    setFormState("enabled");
  };

  const onCancelClick = () => {
    history.push(linkToChainOfCustody());
  };

  const onCloseModalClick = () => {
    setModalKey(null);
  };

  useEffect(() => {
    fetchSiteList();
    ensureStreamListIsFetched();

    const cocId = match.params.cocId;

    if (cocId) {
      setFormState("loading");

      ChainOfCustodyService.getChainOfCustodyDetail(cocId)
        .then(({ data }) => {
          form.reset({
            name: `${data?.name ?? ""} (copy)`,
            type: data?.type ?? null,
            site: data?.site ?? null,
            samples: formatSamples(data.samples),
          });
        })
        .finally(() => {
          setFormState("enabled");
        });
    }
  }, []);

  const chainOfCustodyBreadcrumbs = getBreadcrumbsObject(
    breadcrumbs,
    match.params
  );

  const availableIssuers = profile?.enabled_chain_of_custody_types || [];

  form.watch("samples");

  return (
    <Page
      title="Create Chain of Custody"
      breadcrumbs={chainOfCustodyBreadcrumbs}
    >
      <div className="createChainOfCustody">
        <Panel>
          <Form
            onSubmit={onCreateChainOfCustodyClick}
            className="createChainOfCustodyForm"
            {...form}
          >
            <CreateChainOfCustodyForm
              form={form}
              formState={formState}
              availableIssuers={availableIssuers}
              availableSites={sites}
              availableStreams={clientStreams}
              onModalToggle={onModalToggle}
              onRemoveSample={onRemoveSample}
              resetChainOfCustodyClick={resetChainOfCustodyClick}
            />

            <div className="createChainOfCustodyForm__footer clearfix">
              <Button onClick={onCancelClick}>Cancel</Button>

              {formState === "locked" ? (
                <>
                  <Button
                    className="pull-right"
                    variant="primary"
                    onClick={() => onModalToggle(CHAIN_OF_CUSTODY_FORM)}
                  >
                    View Chain Of Custody
                  </Button>
                  <Button
                    className="pull-right"
                    style={{ marginRight: "10px" }}
                    onClick={() => resetChainOfCustodyClick()}
                  >
                    Create Another Chain of Custody
                  </Button>
                </>
              ) : (
                <FormButton
                  className="pull-right"
                  variant="primary"
                  type="submit"
                  isLoading={formState === "loading"}
                >
                  Create Chain of Custody
                </FormButton>
              )}
            </div>
          </Form>
        </Panel>

        {modalKey === ADD_EXISTING_SAMPLE_MODAL_KEY && (
          <ExistingSampleModal
            show={true}
            onHide={onCloseModalClick}
            inputs={form.getValues()}
            onAddSample={onAddSample}
          />
        )}

        {modalKey === ADD_NEW_SAMPLE_MODAL_KEY && (
          <NewSampleModal
            show={true}
            onHide={onCloseModalClick}
            onAddSample={onAddSample}
          />
        )}

        {modalKey === EDIT_SAMPLE_MODAL_KEY && selectedSample && (
          <EditSampleModal
            show={true}
            onHide={onCloseModalClick}
            sample={selectedSample}
            onUpdateSample={onUpdateSample}
            onRemoveSample={onRemoveSample}
          />
        )}

        {modalKey === CHAIN_OF_CUSTODY_FORM && chainOfCustodyId && (
          <ChainOfCustodyFormModal
            show={true}
            hide={onCloseModalClick}
            chainOfCustodyId={chainOfCustodyId}
          />
        )}
      </div>
    </Page>
  );
};

CreateChainOfCustody.propTypes = {
  profile: PropTypes.object.isRequired,
  sites: PropTypes.array.isRequired,
  clientStreams: PropTypes.array.isRequired,
  fetchSiteList: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  breadcrumbs: PropTypes.array.isRequired,
  ensureStreamListIsFetched: PropTypes.func.isRequired,
  addAlertMessage: PropTypes.func.isRequired,
};

const CreateChainOfCustodyContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateChainOfCustody);

export default CreateChainOfCustodyContainer;
