export * from "./DataSubmissionsFilterPanel";
export * from "./DataSubmissionsTablePanel";
export * from "./DataSubmissionsListPage";

export const DATA_SUBMISSIONS_LIST_TITLE = "Submissions";

export const linkToDataSubmissionsList = () => "/app/data-tools/submissions";

export const DATA_SUBMISSIONS_LIST_BREADCRUMB = {
  title: DATA_SUBMISSIONS_LIST_TITLE,
  path: linkToDataSubmissionsList,
};
