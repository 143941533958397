// REVIEW: the "hook" here is badly implemented and has an insidious memory leak
// issue that's hard to debug. Needs refactoring.
import { useCallback } from "react";
import isObject from "lodash/isObject";
import {
  GetAllQueryParam,
  SetHistoryQueryParam,
  GetQueryParam,
  ClearQueryParam,
  GetQueryString,
} from "./historyHelper";
import history from "./history";

/**
 * A Custom Hook that eases the use of query params and the history helpers functions.
 *
 * @param {Object} defaultObj - An object whose properties match the parameter to be tracked
 *  and value matches the default value they should be if no query param found. If no
 *  defaultObj given, will return all query parameter values.
 *
 * @param {Object} acceptedObj - An object whose properties should be in the set of defaultObj
 *  properties. Each param should be an array of accepted values that the URL query param
 *  can be. Use the value null or leave empty to opt out for specific query params.
 *
 * @param {Object} comparatorObj If the acceptedObj contains an array of objects, which property
 *  of that object should be used to compare to the query params values
 *
 * @param {String} search - The query param string
 *
 * @returns An object whose properties are the url query params keys and the values being the
 *  string or array of strings of those url query params
 */
const useQueryParams = (defaultObj, acceptedObj, comparatorObj, search) => {
  const params = new URLSearchParams(search);
  const queryParams = {};

  if (!defaultObj) {
    /* eslint-disable no-unused-vars */
    for (const key of params.keys()) {
      queryParams[key] = GetAllQueryParam(key, []);
    }
  } else {
    Object.keys(defaultObj).forEach((key) => {
      const defaultValue = defaultObj[key];
      const acceptedValues = acceptedObj ? acceptedObj[key] : null;
      // comparatorObj[key] or "id" if acceptedValues is a list of {id: ... } or null
      const comparatorProp =
        comparatorObj?.[key] ??
        // eslint-disable-next-line no-prototype-builtins
        (acceptedValues?.[0]?.hasOwnProperty("id") ? "id" : null);

      if (acceptedValues) {
        queryParams[key] = GetArrayQueryParams(
          key,
          comparatorProp,
          acceptedValues,
          defaultValue
        );
      } else if (Array.isArray(defaultValue)) {
        queryParams[key] = GetAllQueryParam(key, defaultValue);
      } else {
        queryParams[key] = GetQueryParam(key, defaultValue);
      }
    });
  }

  const setQueryParams = (obj) => {
    if (isObject(obj)) {
      // emulates a hook setState in that setting it to the same query param
      // won't cause a rerender.
      const { search } = history.location;
      const newQueryString = GetQueryString(obj, search);
      if (newQueryString !== search) {
        SetHistoryQueryParam(obj, newQueryString);
      }
    } else if (obj === null || obj === undefined || obj === false) {
      ClearQueryParam();
    }
  };
  return [queryParams, setQueryParams];
};

/**
 * Using this instead of other historyhelper function due to inconsistent returns
 * compared to other GetParams. See the unit tests for more info.
 */
const GetArrayQueryParams = (
  key,
  comparatorProp,
  acceptedValuesArray,
  defaultValue
) => {
  const { search } = history.location;
  const params = new URLSearchParams(search);
  const value = params.getAll(key);

  // return a subset of acceptedValues that match query param value, default otherwise
  const filteredArray = acceptedValuesArray.filter(
    (row) =>
      value.indexOf(String(comparatorProp ? row?.[comparatorProp] : row)) !== -1
  );
  if (filteredArray.length > 0) {
    return Array.isArray(defaultValue) ? filteredArray : filteredArray[0];
  }
  return defaultValue;
};

/**
 * A small optimization that returns the same object if neither the inputs nor
 * the search string changed, satisfying shallow comparisons done in React.memo,
 * dependency arrays in hooks, React.PureComponent, etc.
 */
const useMemoizedQueryParams = (defaultObj, acceptedObj, comparatorObj) => {
  const { search } = history.location;

  return useCallback(
    useQueryParams(defaultObj, acceptedObj, comparatorObj, search),
    [defaultObj, acceptedObj, comparatorObj, search]
  );
};

export default useMemoizedQueryParams;
