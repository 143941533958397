import { Panel } from "@validereinc/common-components";
import * as PropTypes from "prop-types";
import React from "react";
import "./BalanceSummary.scss";
import BalanceSummaryChart from "./BalanceSummaryChart";
import BalanceSummaryTable from "./BalanceSummaryTable";

const BalanceSummary = (props) => {
  const {
    balanceSummary,
    balanceType,
    showTotalVolume,
    showEnergy,
    loadingState,
    haveReferenceSources,
  } = props;

  return (
    <Panel
      className="balanceSummary"
      title="Balance Summary"
      loaded={loadingState === "loaded"}
    >
      {balanceSummary ? (
        <>
          <BalanceSummaryChart
            balanceSummary={balanceSummary.summary}
            balanceType={balanceType}
            haveReferenceSources={haveReferenceSources}
            showTotalVolume={showTotalVolume}
          />

          <BalanceSummaryTable
            balanceSummary={balanceSummary}
            balanceType={balanceType}
            haveReferenceSources={haveReferenceSources}
            showTotalVolume={showTotalVolume}
            showEnergy={showEnergy}
          />
        </>
      ) : (
        <div className="balanceSummary__hint">
          <p>
            No data available.
            <br />
            Please select an appropriate date range.
          </p>
        </div>
      )}
    </Panel>
  );
};

BalanceSummary.propTypes = {
  balanceSummary: PropTypes.object,
  haveReferenceSources: PropTypes.bool,
  loadingState: PropTypes.string.isRequired,
  balanceType: PropTypes.string,
  showTotalVolume: PropTypes.bool,
  showEnergy: PropTypes.bool,
};

export default BalanceSummary;
