import React from "react";
import * as PropTypes from "prop-types";
import { Table } from "react-bootstrap";
import FontAwesome from "react-fontawesome";
import { getTimeStringFromDate } from "#utils/timeFormatter";
import { getUserString } from "../../utils/stringFormatter";
import "./CalibrationTable.css";
import config from "#src/config";

const StatusCode = {
  completed: { color: "#079c0d", icon: "check", title: "Completed" },
  pending: {
    color: "#ff9800",
    icon: "exclamation-triangle",
    title: "Check Required",
  },
  failed: { color: "#e51c23", icon: "times", title: "Check Failed" },
};

const Status = (props) => (
  <td>
    <FontAwesome
      name={props.status.icon}
      className="icon"
      style={{ color: props.status.color }}
    />
    {props.status.title}
  </td>
);

const CalibrationCheckRow = (props) => (
  <tr className="calibrationRow">
    <td>Calibration Check</td>
    <Status status={StatusCode[props.instrumentInfo.prev_calibration_status]} />
    <td>
      {getTimeStringFromDate(
        props.instrumentInfo.prev_calibration_date,
        config.DATE_FORMAT
      )}
    </td>
    <td>{getUserString(props.instrumentInfo.prev_calibration_staff)}</td>
    <td>
      {getTimeStringFromDate(
        props.instrumentInfo.next_calibration_date,
        config.DATE_FORMAT
      )}
    </td>
  </tr>
);

const StandardCheck = (props) => (
  <tr className="calibrationRow">
    <td>Standard Check</td>
    <Status
      status={StatusCode[props.instrumentInfo.prev_standard_check_status]}
    />
    <td>
      {getTimeStringFromDate(
        props.instrumentInfo.prev_standard_check_date,
        config.DATE_FORMAT
      )}
    </td>
    <td>{getUserString(props.instrumentInfo.prev_standard_check_staff)}</td>
    <td>
      {getTimeStringFromDate(
        props.instrumentInfo.next_standard_check_date,
        config.DATE_FORMAT
      )}
    </td>
  </tr>
);

const NoCalibrationInformation = () => (
  <tr>
    <td
      className="instrumentCalibration__errorMessage"
      colSpan={4}
    >
      Calibration Information Unavailable
    </td>
  </tr>
);

const CalibrationTable = (props) => (
  <Table
    condensed
    responsive
    className={props.className}
  >
    <thead>
      <tr className="calibrationRow">
        <th>Calibration</th>
        <th>Status</th>
        <th>Last Checked</th>
        <th>Last Checked By</th>
        <th>Next Check</th>
      </tr>
    </thead>

    <tbody>
      {props.instrumentInfo.prev_standard_check_status ? (
        <StandardCheck {...props} />
      ) : null}

      {props.instrumentInfo.prev_calibration_status ? (
        <CalibrationCheckRow {...props} />
      ) : null}

      {!props.instrumentInfo.prev_calibration_status &&
      !props.instrumentInfo.prev_standard_check_status ? (
        <NoCalibrationInformation />
      ) : null}
    </tbody>
  </Table>
);

Status.propTypes = {
  status: PropTypes.object,
};

CalibrationCheckRow.propTypes = {
  instrumentInfo: PropTypes.object,
};

StandardCheck.propTypes = {
  instrumentInfo: PropTypes.object,
};

CalibrationTable.propTypes = {
  className: PropTypes.string,
  instrumentInfo: PropTypes.object.isRequired,
};

export default CalibrationTable;
