import { LegacyDataTable } from "@validereinc/common-components";
import * as PropTypes from "prop-types";
import React from "react";
import { AutoSizer } from "react-virtualized";
import { getMeasurementRenderers } from "../../Detail/TestMeasurementTableHelper";

const MeasurementTable = ({
  displayProperties,
  testDetail,
  customMeasurementHeaders,
  getMeasurementType,
}) => {
  const {
    measurementNameRenderer,
    measurementRenderer,
    alertSpecRenderer,
    alertTypeRenderer,
  } = getMeasurementRenderers(testDetail, getMeasurementType);

  const measurementHeaders = customMeasurementHeaders.length
    ? customMeasurementHeaders
    : [
        {
          label: "Value",
          key: "value",
          cellRenderer: measurementRenderer,
          width: 165,
          disableSort: true,
          rightAlign: true,
        },
      ];

  const headers = [
    {
      label: "Measurement",
      key: "label",
      width: 200,
      disableSort: true,
      fixed: true,
      cellRenderer: measurementNameRenderer,
    },

    ...measurementHeaders,
    {
      label: "Alert Type",
      key: "alertType",
      cellRenderer: alertTypeRenderer,
      width: 150,
      disableSort: true,
    },
    {
      label: "Alert Threshold",
      key: "alertSpec",
      cellRenderer: alertSpecRenderer,
      width: 150,
      disableSort: true,
    },
  ];

  return (
    <AutoSizer>
      {({ height, width }) => (
        <LegacyDataTable
          height={height}
          width={width}
          headers={headers}
          list={displayProperties}
          defaultSortBy="started_at"
          defaultSortDirection="desc"
          rowHeight={80}
        />
      )}
    </AutoSizer>
  );
};

MeasurementTable.propTypes = {
  displayProperties: PropTypes.array.isRequired,
  testDetail: PropTypes.object.isRequired,
  getMeasurementType: PropTypes.func.isRequired,
  customMeasurementHeaders: PropTypes.array,
};

export default MeasurementTable;
