import React, { Component } from "react";
import {
  FormGroup,
  MenuItem,
  DropdownButton,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import FontAwesome from "react-fontawesome";
import { FilterArrayByInput } from "../../utils/filters";
import { List, AutoSizer } from "react-virtualized";
import "./DropdownInputWithSearch.css";

// TODO: this needs to be deprecated
/* eslint-disable react/prop-types */
class DropdownInputWithSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterInput: "",
      filteredOptions: [],
      updatelist: false,
      openDropdown: false,
      scrollIndex: 0,
    };

    this.filterList = this.filterList.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.onToggle = this.onToggle.bind(this);
    this.getRowRenderer = this.getRowRenderer.bind(this);
    this.getNoRowsRenderer = this.getNoRowsRenderer.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.setState({
        filteredOptions: this.props.options,
        updatelist: !this.state.updatelist,
      });
    }
  }

  onSelect(index) {
    const filteredOptions = this.state.filteredOptions;
    this.props.onSelect(filteredOptions[index]);

    this.setState({
      openDropdown: false,
    });
  }

  onToggle() {
    this.setState({
      filterInput: "",
      scrollIndex: 0,
      filteredOptions: this.props.options,
      updatelist: !this.state.updatelist,
      openDropdown: !this.state.openDropdown,
    });
  }

  filterList(input) {
    const value = input.target.value;
    const filterBy = this.props.filterKey;
    const array = this.props.options;

    const filteredOptions = FilterArrayByInput(array, filterBy, value);

    this.setState({
      filterInput: value,
      filteredOptions: filteredOptions,
      updatelist: !this.state.updatelist,
    });
  }

  getRowRenderer(props) {
    let value = "";

    if (this.props.filterKey) {
      value = this.state.filteredOptions[props.index][this.props.filterKey];
    } else {
      value = this.state.filteredOptions[props.index];
    }

    return (
      <div
        className="menuitem"
        role="menuitem"
        key={props.index}
        style={props.style}
        onClick={() => this.onSelect(props.index)}
      >
        <div className="menuitem__checkContainer">
          {this.props.value === value ? (
            <FontAwesome
              name="check"
              className="icon"
            />
          ) : null}
        </div>
        {value}
      </div>
    );
  }

  getNoRowsRenderer() {
    return (
      <div
        className="dropdownInputWithSearch__noResult"
        role="menuitem"
      >
        {`No ${this.props.title} found`}
      </div>
    );
  }

  getTitle() {
    const { value, title } = this.props;

    if (value) {
      return value;
    }

    if (title) {
      return title;
    }

    return "Select";
  }

  render() {
    const title = this.getTitle();

    return (
      <FormGroup className={`dropdownInputWithSearch ${this.props.className}`}>
        <DropdownButton
          className={`dropdownInputWithSearch__button`}
          componentClass={InputGroup.Button}
          id="input-dropdown-addon"
          title={title}
          onSelect={this.onSelect}
          onToggle={this.onToggle}
          disabled={this.props.disabled}
          open={this.state.openDropdown}
          noCaret={this.props.noCaret}
          pullRight={this.props.pullRight}
        >
          <MenuItem
            header
            className="dropdownInputWithSearch__dropdownSearch"
          >
            <FormControl
              type="text"
              value={this.state.filterInput}
              placeholder="&#xF002; Search..."
              onChange={this.filterList}
            />
          </MenuItem>

          <div className="dropdownInputWithSearch__menuitemContainer">
            <AutoSizer>
              {({ height, width }) => (
                <List
                  width={width}
                  height={height}
                  rowCount={this.state.filteredOptions.length}
                  rowHeight={35}
                  noRowsRenderer={this.getNoRowsRenderer}
                  rowRenderer={this.getRowRenderer}
                  updatelist={this.state.updatelist}
                />
              )}
            </AutoSizer>
          </div>
        </DropdownButton>
      </FormGroup>
    );
  }
}

export default DropdownInputWithSearch;
