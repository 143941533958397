import React from "react";
import * as PropTypes from "prop-types";

import { useFetchDailyVolume } from "#src/hooks/records/fetches";

import ReactLoader from "#components/LoadingBar/ReactLoader";
import DailyVolumeChart from "#components/Records/Volume/DailyVolume/DailyVolumeChart";
import VolumeTable from "#components/Records/Volume/DailyVolume/VolumeTable";
import { formatDailyVolumeDate } from "../../../Records/Volume/DailyVolume/DailyVolumeHelper";

const TABLE_HEIGHT = 850;

const VolumeSection = ({ streamId, dateRange }) => {
  const { from, to } = dateRange;
  const [volumeLoadingState, volumes] = useFetchDailyVolume(streamId, from, to);

  return (
    <ReactLoader
      loaded={volumeLoadingState !== "loading"}
      position={50}
    >
      <DailyVolumeChart
        streamId={streamId}
        from={formatDailyVolumeDate(from)}
        to={formatDailyVolumeDate(to)}
      />

      <VolumeTable
        streamId={streamId}
        volumes={volumes}
        dateRange={{ from, to }}
        height={TABLE_HEIGHT}
      />
    </ReactLoader>
  );
};

VolumeSection.propTypes = {
  streamId: PropTypes.string,
  dateRange: PropTypes.object.isRequired,
};

export default VolumeSection;
