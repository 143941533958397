import {
  EntityDropdownInput,
  EntityDropdownProps,
} from "#batteries-included-components/Dropdowns/EntityDropdownInput";
import { FlowDomain, FlowMetaDataType } from "@validereinc/domain";
import React from "react";

export const ProductTypeDropdownInput = ({
  name = "product_type",
  label,
  ...props
}: EntityDropdownProps<FlowMetaDataType>) => {
  return (
    <EntityDropdownInput
      fetchEntityList={async () => ({
        data: await FlowDomain.getFlowProductTypes(),
      })}
      label={label ?? "Product Type"}
      name={name}
      sort={{
        sortBy: "name",
        sortDirection: "asc",
      }}
      {...props}
    />
  );
};
