export * from "./InsightsDetailsPage";

export const linkToInsightsDetails = (insightsId = ":insightsId") => {
  return `/app/dashboards/insights/${insightsId}/details`;
};

export const INSIGHTS_DETAILS_DASHBOARD_BREADCRUMB = {
  title: "Insights Dashboard",
  path: linkToInsightsDetails,
};
