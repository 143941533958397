export const EQUIPMENT_ATTRIBUTES = {
  ID: {
    key: "id",
    label: "ID",
  },
  NAME: {
    key: "name",
    label: "Equipment",
  },
  TYPE: {
    key: "type",
    label: "Type",
  },
  STATUS: {
    key: "status",
    label: "Status",
  },
  FACILITY: {
    key: "associated_facility_id",
    label: "Facility",
  },
  FACILITY_NAME: {
    key: "associated_facility.name",
    label: "Facility",
  },
};
