import { Modal, Title } from "@validereinc/common-components";
import * as PropTypes from "prop-types";
import React from "react";
import { getManualEntryType } from "../../ManualEntryHelper";
import FieldManualEntry from "./FieldManualEntry";

const FieldManualEntryModal = ({
  show,
  onHide,
  instrumentInfo,
  sampleInfo,
  refetchData,
}) => {
  return (
    <Modal
      open={show}
      onClose={onHide}
    >
      <Modal.Header>
        <Title>{getManualEntryType("field")?.title ?? "Unavailable"}</Title>
      </Modal.Header>

      <FieldManualEntry
        view="modal"
        instrumentInfo={instrumentInfo}
        sampleInfo={sampleInfo}
        refetchData={refetchData}
        onFieldManualEntryModalHide={onHide}
        bodyComponent={Modal.Body}
        footerComponent={Modal.Footer}
      />
    </Modal>
  );
};

FieldManualEntryModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  instrumentInfo: PropTypes.object,
  sampleInfo: PropTypes.object,
  refetchData: PropTypes.func,
};

export default FieldManualEntryModal;
