import RoleService from "#services/RoleService";
import { SetObjectValue } from "#utils/objectFormatter";
import {
  Button,
  Form,
  FormButton,
  Page,
  Panel,
  useAlert,
  useForm,
} from "@validereinc/common-components";
import React, { useEffect, useState } from "react";
import RoleForm from "./RoleForm";

const DEFAULT_INPUT = {
  name: "",
  permissions: {},
};

const CreateRole = () => {
  const [currentRoles, setCurrentRoles] = useState([]);
  const [formState, setFormState] = useState("enabled");
  const [lastFetchRoleTimestamp, setLastFetchRoleTimestamp] = useState(
    new Date()
  );
  const form = useForm({ defaultValues: DEFAULT_INPUT });
  const { addAlert } = useAlert();

  const onPermissionChange = (key, input) => {
    const permissions = form.getValues("permissions");

    permissions[key] = input;

    form.setValue("permissions", permissions);
  };

  const onCreateRole = (inputs) => {
    setFormState("loading");

    RoleService.requestCreateNewRole(inputs)
      .then(() => {
        setFormState("preview");
        addAlert({
          variant: "success",
          message: `${inputs.name} role has been created.`,
        });
      })
      .catch(() => {
        setFormState("enabled");
      });
  };

  const onCreateAnotherRow = () => {
    setLastFetchRoleTimestamp(new Date());

    form.reset();
    setFormState("enabled");
  };

  useEffect(() => {
    setFormState("loading");

    RoleService.getRoles()
      .then(({ data }) => {
        const permissions = data[0].permissions;
        const availablePermissions = SetObjectValue(permissions, []);

        setCurrentRoles(data);

        form.reset({
          name: "",
          permissions: { ...availablePermissions },
        });
      })
      .finally(() => {
        setFormState("enabled");
      });
  }, [lastFetchRoleTimestamp]);

  form.watch("permissions");

  return (
    <Page title="Create New Role">
      <Panel>
        <Form
          onSubmit={onCreateRole}
          {...form}
        >
          <RoleForm
            form={form}
            formState={formState}
            currentRoles={currentRoles}
            onPermissionChange={onPermissionChange}
          />

          <div className="clearfix">
            {formState !== "preview" ? (
              <FormButton
                className="pull-right"
                variant="primary"
                type="submit"
                isLoading={formState === "loading"}
                disabled={formState !== "enabled"}
              >
                Create Role
              </FormButton>
            ) : (
              <Button
                className="pull-right"
                variant="primary"
                onClick={onCreateAnotherRow}
              >
                Create Another Role
              </Button>
            )}
          </div>
        </Form>
      </Panel>
    </Page>
  );
};

export default CreateRole;
