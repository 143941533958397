import { useCallback, useContext } from "react";
import {
  LocalizationContext,
  DefaultLocalizationMapping,
} from "./LocalizationProvider";

const useLocalization = () => {
  const { mappings, isLoading } = useContext(LocalizationContext);

  const findMapping = useCallback(
    (word: string) => {
      const key = word?.toLowerCase();

      return (
        mappings?.[key] ??
        DefaultLocalizationMapping[
          key as keyof typeof DefaultLocalizationMapping
        ] ??
        word
      );
    },
    [mappings, DefaultLocalizationMapping]
  );

  const localize = useCallback(
    (phrase: string) => {
      const words = phrase.split(" ");

      return words.map((word) => findMapping(word)).join(" ");
    },
    [findMapping]
  );

  return { localize, mappings, isLoading };
};

export default useLocalization;
