import React, { useState, useRef } from "react";
import { Button, Panel, Col, Row, Table, Label } from "react-bootstrap";
import FontAwesome from "react-fontawesome";
import InputBoxWithIcon from "../../../Inputs/InputboxWithIcon";
import AnnotationStage from "./AnnotationStage";
import useKeyDown from "../Hooks/useKeyDown";
import { APPROXIMATION_METHODS } from "../Utils/AnnotationUtils";
import { havePermission } from "../../../Redux/reducers/permissions";
import { connect } from "react-redux";
import useStages, { STAGE_NAMES } from "./useStages";

/* eslint-disable react/prop-types */

const getTickValidationState = (tickValue) => {
  if (!isNaN(tickValue) && tickValue !== "") {
    return "success";
  } else if (isNaN(tickValue)) {
    return "error";
  } else {
    return "warning";
  }
};

const ApproximateMethodDisplay = ({
  approximationMethod,
  setApproximationMethod,
}) => {
  const approximationMethodBsStyle = (method) => {
    return method === approximationMethod ? "primary" : "default";
  };

  return (
    <div className="annotationStage__approximateMethodDisplay">
      <span>(Method:</span>
      <Button
        bsSize="xs"
        bsStyle={approximationMethodBsStyle(APPROXIMATION_METHODS.MINIMA)}
        onClick={() => setApproximationMethod(APPROXIMATION_METHODS.MINIMA)}
      >
        Minima
      </Button>
      <Button
        bsSize="xs"
        bsStyle={approximationMethodBsStyle(APPROXIMATION_METHODS.AVERAGE)}
        onClick={() => setApproximationMethod(APPROXIMATION_METHODS.AVERAGE)}
      >
        Average
      </Button>
      <Button
        bsSize="xs"
        bsStyle={approximationMethodBsStyle(APPROXIMATION_METHODS.MAXIMA)}
        onClick={() => setApproximationMethod(APPROXIMATION_METHODS.MAXIMA)}
      >
        Maxima
      </Button>
      <span>)</span>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isValidereUser: havePermission(state.permissions)(
      "360:annotations",
      "validere"
    ),
  };
};

export const AnnotationSteps = ({
  onStageComplete: parentOnStageComplete,
  onComplete,
  onToolSelect,
  approximationMethod,
  setApproximationMethod,
  value,
  undo,
  canUndo,
  isValidereUser = false,
}) => {
  const [lowerTickValue, setLowerTick] = useState("");
  const [upperTickValue, setUpperTick] = useState("");

  const upperTickRef = useRef(null);

  const onStageComplete = (prevStageName) => {
    if (prevStageName === STAGE_NAMES.DRAW_BOX) {
      upperTickRef.current.focus();
    }
    parentOnStageComplete(prevStageName);
  };

  const submitMeasurements = () => {
    onComplete(Number(lowerTickValue), Number(upperTickValue));
  };

  const {
    currentStageName,
    isStageActive,
    isStageDisabled,
    isStageComplete,
    goToNextStage,
  } = useStages({
    value,
    onToolSelect,
    onStageComplete,
    lowerTickValue,
    upperTickValue,
  });

  useKeyDown({
    13: () => {
      // Enter key
      if (currentStageName === STAGE_NAMES.COMPLETE) {
        submitMeasurements();
      } else if (isStageComplete(currentStageName)) {
        goToNextStage();
      }
    },
    90: () => {
      // Z key
      if (canUndo()) {
        undo();
      }
    },
    81: () => {
      // Q key
      if (isValidereUser) {
        setApproximationMethod(APPROXIMATION_METHODS.MINIMA);
      }
    },
    87: () => {
      // W key
      if (isValidereUser) {
        setApproximationMethod(APPROXIMATION_METHODS.AVERAGE);
      }
    },
    69: () => {
      // E key
      if (isValidereUser) {
        setApproximationMethod(APPROXIMATION_METHODS.MAXIMA);
      }
    },
  });

  const stageLabelBsStyle = (stageName) => {
    return isStageActive(stageName) ? "primary" : "default";
  };

  const completeButtonBsStyle = isStageActive(STAGE_NAMES.COMPLETE)
    ? "success"
    : "default";

  return (
    <Panel>
      <Panel.Body>
        <Row>
          <Col sm={8}>
            <ol>
              <AnnotationStage
                goToNextStage={goToNextStage}
                isActive={isStageActive(STAGE_NAMES.ZOOM_IN)}
                isComplete={isStageComplete(STAGE_NAMES.ZOOM_IN)}
              >
                <Label bsStyle={stageLabelBsStyle(STAGE_NAMES.ZOOM_IN)}>
                  <FontAwesome name="search" /> Zoom to the water line
                </Label>
              </AnnotationStage>
              <AnnotationStage
                goToNextStage={goToNextStage}
                isActive={isStageActive(STAGE_NAMES.DRAW_LINE)}
                isComplete={isStageComplete(STAGE_NAMES.DRAW_LINE)}
              >
                <Label bsStyle={stageLabelBsStyle(STAGE_NAMES.DRAW_LINE)}>
                  <FontAwesome name="pencil" /> Draw in the meniscus
                </Label>
                {isValidereUser ? (
                  <ApproximateMethodDisplay
                    approximationMethod={approximationMethod}
                    setApproximationMethod={setApproximationMethod}
                  />
                ) : null}
              </AnnotationStage>
              <AnnotationStage
                goToNextStage={goToNextStage}
                isActive={isStageActive(STAGE_NAMES.DRAW_BOX)}
                isComplete={isStageComplete(STAGE_NAMES.DRAW_BOX)}
              >
                <Label bsStyle={stageLabelBsStyle(STAGE_NAMES.DRAW_BOX)}>
                  <FontAwesome name="square-o" /> Make a box on the ticks
                </Label>
              </AnnotationStage>
              <AnnotationStage
                goToNextStage={goToNextStage}
                isActive={isStageActive(STAGE_NAMES.ENTER_TICKS)}
                isComplete={isStageComplete(STAGE_NAMES.ENTER_TICKS)}
              >
                <InputBoxWithIcon
                  inputRef={(ref) => {
                    upperTickRef.current = ref;
                  }}
                  className="inputbox-compress"
                  icon="level-up"
                  disabled={isStageDisabled(STAGE_NAMES.ENTER_TICKS)}
                  onChange={(input) => setUpperTick(input.target.value)}
                  placeholder="Enter upper tick"
                  validationState={getTickValidationState(upperTickValue)}
                />
                <InputBoxWithIcon
                  className="inputbox-compress"
                  icon="level-down"
                  disabled={isStageDisabled(STAGE_NAMES.ENTER_TICKS)}
                  onChange={(input) => setLowerTick(input.target.value)}
                  placeholder="Enter lower tick"
                  validationState={getTickValidationState(lowerTickValue)}
                />
              </AnnotationStage>
              <AnnotationStage
                goToNextStage={goToNextStage}
                isActive={isStageActive(STAGE_NAMES.COMPLETE)}
              >
                <Button
                  bsStyle={completeButtonBsStyle}
                  onClick={submitMeasurements}
                  disabled={isStageDisabled(STAGE_NAMES.COMPLETE)}
                >
                  <FontAwesome name="check-circle" /> Complete
                </Button>
              </AnnotationStage>
            </ol>
          </Col>
          <Col sm={4}>
            <Panel>
              <Panel.Heading>Keyboard Shortcuts</Panel.Heading>
              <Panel.Body>
                <Table
                  condensed
                  bordered
                >
                  <tbody>
                    <tr>
                      <td>
                        <Label>Enter</Label>
                      </td>
                      <td>Complete step</td>
                    </tr>
                    <tr>
                      <td>
                        <Label>Z</Label>
                      </td>
                      <td>Undo</td>
                    </tr>
                    {isValidereUser ? (
                      <>
                        <tr>
                          <td>
                            <Label>Q</Label>
                          </td>
                          <td>Approx. method: Minima</td>
                        </tr>
                        <tr>
                          <td>
                            <Label>W</Label>
                          </td>
                          <td>Approx. method: Average</td>
                        </tr>
                        <tr>
                          <td>
                            <Label>E</Label>
                          </td>
                          <td>Approx. method: Maxima</td>
                        </tr>
                      </>
                    ) : null}
                  </tbody>
                </Table>
              </Panel.Body>
            </Panel>
          </Col>
        </Row>
      </Panel.Body>
    </Panel>
  );
};

export default connect(mapStateToProps, undefined)(AnnotationSteps);
