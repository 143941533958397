import axios from "axios";

import { dataPlatformRequestServicesPromise } from "#services/ServiceHelper";
import {
  getSafeFileName,
  getSafeFileMimeType,
} from "#src/utils/stringFormatter";

const FileService = {
  createFile({
    name,
    contentType,
    datasetId,
    companyId,
    ...rest
  }: {
    name: string;
    contentType: string;
    datasetId?: string;
    companyId?: string;
    [key: string]: any;
  }) {
    if (!name) {
      return;
    }

    return dataPlatformRequestServicesPromise(`/data/file_upload_url`, {
      // NOTE: the file upload service needs the file name to only contain one dot for extension validation
      file_name: getSafeFileName(name),
      content_type: getSafeFileMimeType(name) ?? contentType,
      ...(datasetId ? { dataset_id: datasetId } : {}),
      ...(companyId ? { company_id: companyId } : {}),
      ...rest,
    });
  },
  uploadFile({ url, fileBlob }: { url: string; fileBlob: File }) {
    if (!url || !fileBlob) {
      return;
    }

    // if the MIME type is deemed to be invalid, don't upload the file
    const overrideContentType = getSafeFileMimeType(
      fileBlob.name,
      fileBlob.type
    );

    if (!overrideContentType) {
      return;
    }

    return axios.put(url, fileBlob, {
      withCredentials: false,
      headers: {
        "Content-Type": overrideContentType,
      },
    });
  },
  getFileDownloadUrl(fileId: string) {
    if (!fileId) {
      return;
    }

    return dataPlatformRequestServicesPromise(
      `/data/file_download_url/${fileId}`
    );
  },
};

export default FileService;
