import { useNavigate } from "#src/Routers/hooks";
import { UserGroupFormPanel } from "#src/batteries-included-components/Panels/FormPanels/UserGroupFormPanel/UserGroupFormPanel";
import { useCreateOneUserGroup } from "#src/components/hooks/adapters/useUserGroups";
import {
  MultiStepFormProvider,
  useMultiStepFormContext,
} from "#src/hooks/useMultiStepForm";
import { UserGroupsRoutePath } from "#src/routes/settings/users/groups";
import { useBreadcrumbsFromRoute } from "#src/utils/route";
import { Page } from "@validereinc/common-components";
import {
  CreateOneRequestType,
  CreateUserGroupType,
  UserGroupType,
} from "@validereinc/domain";
import React from "react";
import { GroupCreateRoutePath } from ".";
import { UserGroupCategoryDetailsRoutePath } from "../categories/[categoryId]/details";

const CreateUserGroupPageContent = () => {
  const { stepper, footer, getStep, isFirstStep } = useMultiStepFormContext();
  const [breadcrumbs] = useBreadcrumbsFromRoute(GroupCreateRoutePath);
  const groupDetails = getStep(1)?.getValues();

  return (
    <Page
      breadcrumbs={breadcrumbs}
      title={
        groupDetails?.name && !isFirstStep
          ? groupDetails?.name.toString()
          : "Create User Group"
      }
      category={!isFirstStep ? "Create User Group" : ""}
      actionRow={stepper}
      footer={footer}
    >
      <UserGroupFormPanel />
    </Page>
  );
};

const CreateUserGroupPage = () => {
  const urlParams = new URLSearchParams(window.location.search);

  const navigate = useNavigate();
  const onCancel = () =>
    navigate(
      urlParams.get("defaultCategoryId")
        ? UserGroupCategoryDetailsRoutePath.toLinkParts({
            pathParams: {
              categoryId: urlParams.get("defaultCategoryId") ?? "",
            },
          })
        : UserGroupsRoutePath.toLinkParts()
    );

  const createUserGroup = useCreateOneUserGroup({
    onSuccess: (data) => {
      navigate(
        UserGroupCategoryDetailsRoutePath.toLinkParts({
          pathParams: {
            categoryId: (data as CreateOneRequestType<UserGroupType>).data
              .group_category_id,
          },
        })
      );
    },
  });

  const onSubmit = (values: Array<Partial<CreateUserGroupType>>) => {
    const allValues = values.reduce(
      (total, current) => ({ ...total, ...current }),
      {}
    );

    createUserGroup.mutate({
      name: allValues.name ?? "",
      group_category_id: allValues.group_category_id ?? "",
      description: allValues.description,
      user_ids: allValues.user_ids ?? [],
    });
  };

  return (
    <MultiStepFormProvider
      steps={[
        {
          label: "Details",
        },
        {
          label: "User Selection",
        },
      ]}
      onCancel={onCancel}
      onSubmit={onSubmit}
      onSubmitMutationState={{
        isSubmitting: createUserGroup.isLoading,
      }}
      submissionActionText="Create"
    >
      <CreateUserGroupPageContent />
    </MultiStepFormProvider>
  );
};

export default CreateUserGroupPage;
