import { Button, Title } from "@validereinc/common-components";
import * as PropTypes from "prop-types";
import React, { Component } from "react";
import { Col, FormControl, FormGroup, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { AutoSizer, List } from "react-virtualized";
import { InputIsMatched } from "../../../utils/filters";
import { ensureStreamListIsFetched } from "../../Redux/actions/index";
import "./StreamChooser.css";

/* eslint-disable react/prop-types */

const mapStateToProps = (state) => {
  return {
    streams: state.streams,
  };
};

const mapDispatchToProps = {
  ensureStreamListIsFetched,
};

class StreamChooser extends Component {
  constructor(props) {
    super(props);

    this.state = {
      streamsQuery: "",
    };
  }

  componentDidMount() {
    this.props.ensureStreamListIsFetched();
  }

  handleStreamsFilterChange = (event) => {
    this.setState({
      streamsQuery: event.target.value,
    });
  };

  filterStreams() {
    const { streams } = this.props;
    const { streamsQuery } = this.state;

    return streams.data.filter((stream) =>
      InputIsMatched(streamsQuery, stream.name)
    );
  }

  render() {
    const { streamsQuery } = this.state;
    const { selectedStream, onStreamSelected } = this.props;

    const filteredStreams = [...this.filterStreams()];
    filteredStreams.sort((a, b) => a.id - b.id);

    return (
      <FormGroup className="streamChooser">
        <Row>
          <Col xs={12}>
            <Title type="subheader">Stream List</Title>

            <FormControl
              className="dropdownSearchField"
              type="text"
              value={streamsQuery}
              placeholder="Stream Name"
              onChange={this.handleStreamsFilterChange}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <div className="streamTableContainer">
              <StreamList
                selectedStream={selectedStream}
                StreamList={filteredStreams}
                onStreamSelected={onStreamSelected}
              />
            </div>
          </Col>
        </Row>
      </FormGroup>
    );
  }
}
StreamChooser.propTypes = {
  selectedStream: PropTypes.object,
  onStreamSelected: PropTypes.func.isRequired,
};

const StreamChooserContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(StreamChooser);
export default StreamChooserContainer;

const StreamList = ({ StreamList, onStreamSelected }) => {
  const streamRowRenderer = (props) => {
    const currentRow = StreamList[props.index];

    return (
      <div
        key={props.key}
        style={props.style}
      >
        <Button
          className="streamList_row_button"
          onClick={() => onStreamSelected(currentRow)}
        >
          {currentRow.name}
        </Button>
      </div>
    );
  };

  return (
    <AutoSizer>
      {({ height, width }) => (
        <List
          width={width}
          height={height}
          rowCount={StreamList.length}
          rowHeight={45}
          rowRenderer={streamRowRenderer}
        />
      )}
    </AutoSizer>
  );
};
StreamList.propTypes = {
  StreamList: PropTypes.array.isRequired,
  onStreamSelected: PropTypes.func.isRequired,
};
