import { useParams, useSearchParams } from "#routers/hooks";
import { WorkflowFormSubmissionsTablePanel } from "#src/batteries-included-components/Panels/TablePanels/WorkflowFormSubmissionsTablePanel";
import { WorkflowTasksTablePanel } from "#src/batteries-included-components/Panels/TablePanels/WorkflowTasksTablePanel";
import { useGetOneFormSubmission } from "#src/components/hooks/adapters/useFormSubmissions";
import { useStorageKey } from "#src/hooks/useStorageKey";
import { useBreadcrumbsFromRoute } from "#src/utils/route";
import { useQuery } from "@tanstack/react-query";
import {
  Button,
  ContentSwitcher,
  FilterPanel,
  Page,
  TextInput,
} from "@validereinc/common-components";
import { WorkflowAdapter } from "@validereinc/domain";
import classNames from "classnames/bind";
import React, { useEffect, useState } from "react";
import { WorkflowDetailsRoutePath } from ".";
import { WorkflowDetailDeleteDialog } from "./WorkflowDetailDeleteDialog";
import { WorkflowDetailKeyValuePanel } from "./WorkflowDetailKeyValuePanel";
import styles from "./WorkflowDetailPage.module.scss";
import { WorkflowDetailStatusDialog } from "./WorkflowDetailStatusDialog";
import { WorkflowDetailStatusPanel } from "./WorkflowDetailStatusPanel";

const cx = classNames.bind(styles);

export const WorkflowDetailPage = () => {
  const { workflowId } = useParams<{
    workflowId: string;
  }>();
  const tasksStorageKeys = useStorageKey("workflows-detail-tasks");
  const formsStorageKeys = useStorageKey("workflows-detail-forms");

  const [{ context }, setSearchParams] = useSearchParams<{
    context: "actions" | "submissions";
  }>();

  useEffect(() => {
    if (context) return;
    setSearchParams({ context: "actions" });
  }, [context]);

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [isStatusModalOpen, setIsStatusModalOpen] = useState<boolean>(false);

  const { data, isLoading } = useQuery({
    queryKey: ["workflows", workflowId],
    queryFn: () => WorkflowAdapter.getOne({ id: workflowId }),
    select: (resp) => resp.data,
  });

  const [breadcrumbs] = useBreadcrumbsFromRoute(WorkflowDetailsRoutePath, {
    "/detail": {
      title: `${data?.workflow_template.name} - ${data?.id}`,
    },
  });

  const formSubmissionQuery = useGetOneFormSubmission(
    { id: data?.form_trigger?.form_submission_id },
    {
      enabled: !!data?.form_trigger?.form_submission_id,
    }
  );

  const formSubmission = formSubmissionQuery.data?.data;

  const workflowWithTrigger = { ...data };

  if (formSubmission) {
    workflowWithTrigger.form_trigger.form_submission = formSubmission;
  }

  const handleDelete = () => {
    setIsDeleteModalOpen(true);
  };

  const handleChangeStatus = () => {
    setIsStatusModalOpen(true);
  };

  const { workflow_template } = data ?? {};

  return (
    <>
      <Page
        title={data?.workflow_template.name ?? WorkflowDetailsRoutePath.title}
        category={WorkflowDetailsRoutePath.subtitle}
        breadcrumbs={breadcrumbs}
        isLoading={isLoading}
        actionRow={[
          <Button
            key="secondary"
            variant="error-outline"
            onClick={handleDelete}
          >
            Delete
          </Button>,
          <Button
            key="primary"
            variant="primary"
            onClick={handleChangeStatus}
          >
            Change Status
          </Button>,
        ]}
      >
        <div className={cx("topContainer")}>
          <div className={cx("firstColumn")}>
            <WorkflowDetailStatusPanel workflowDetails={data} />
          </div>
          <div className={cx("secondColumn")}>
            <WorkflowDetailKeyValuePanel
              workflowDetails={workflowWithTrigger}
            />
          </div>
        </div>
        <div className={cx("filterContainer")}>
          <ContentSwitcher
            className={cx("contentSwitch")}
            items={[
              { label: "Actions", dataKey: "actions" },
              { label: "Form Submissions", dataKey: "submissions" },
            ]}
            activeKey={context}
            onChange={(newStatus: "actions" | "submissions") =>
              setSearchParams({ context: newStatus })
            }
          />
          {context === "actions" ? (
            <FilterPanel
              storageKey={tasksStorageKeys.filterConfigStorageKey}
              defaultActiveKeys={["Filters"]}
              key="workflows-detail-tasks"
              filters={[
                {
                  component: (
                    <TextInput
                      name="name"
                      placeholder="Search Tasks..."
                      type="search"
                      key="search_tasks"
                      isInline
                    />
                  ),
                },
              ]}
            />
          ) : (
            <FilterPanel
              storageKey={formsStorageKeys.filterConfigStorageKey}
              defaultActiveKeys={["Filters"]}
              key="workflows-detail-forms"
              filters={[
                {
                  component: (
                    <TextInput
                      name="form_schema.name"
                      placeholder="Search Submissions..."
                      type="search"
                      key="search_forms"
                      isInline
                    />
                  ),
                },
              ]}
            />
          )}
        </div>
        <div>
          {context === "actions" ? (
            <WorkflowTasksTablePanel
              {...tasksStorageKeys}
              isWithinWorkflowDetail
              isDisplayingActions
              taskFilters={{ workflow_id: workflowId }}
            />
          ) : (
            <WorkflowFormSubmissionsTablePanel
              {...formsStorageKeys}
              workflowId={workflowId}
            />
          )}
        </div>
      </Page>

      {workflow_template ? (
        <WorkflowDetailDeleteDialog
          setIsDeleteModalOpen={setIsDeleteModalOpen}
          isDeleteModalOpen={isDeleteModalOpen}
          workflowId={workflowId}
          workflowCategoryId={workflow_template.workflow_category.id}
          workflowTemplateId={workflow_template.id}
        />
      ) : null}

      {data ? (
        <WorkflowDetailStatusDialog
          setIsStatusModalOpen={setIsStatusModalOpen}
          isStatusModalOpen={isStatusModalOpen}
          workflowDetails={data}
        />
      ) : null}
    </>
  );
};
