import { useState, useEffect } from "react";
import moment from "moment";
import RecordOfQualityService from "#services/RecordOfQualityService";
import useManageSelectionFetchParams from "./useManageSelectionFetchParams";
import { getRoQData, getSampleData } from "./useManageSelectionFetchesHelpers";

/**
 * Given the current accountingPeriod and selectionData (RoQ and sample points),
 * returns the latest RoQ that is not within the current accounting period. i.e.
 * The RoQ end date is the same as or before the start date of accountingPeriod
 */
const getPreSelectedValues = (selectionData, accountingPeriod) => {
  let value = {};

  const roqData = getRoQData(selectionData, accountingPeriod);

  const sampleData = getSampleData(selectionData, accountingPeriod);

  if (roqData.length) {
    const previousAccountingRecord = roqData[roqData.length - 1];
    value = {
      previousAccountingRecord,
    };
  }

  if (sampleData.length) {
    const earliestSample = sampleData[0];
    const latestSample = sampleData[sampleData.length - 1];
    value = {
      ...value,
      earliestSample,
      latestSample,
    };
  }

  return value;
};

const useFetchRoQSampleData = (streamId, siteSystemId, type, from, until) => {
  const [sampleData, setSampleData] = useState({});
  const [loadingState, setLoadingState] = useState("loading");
  const [lastUpdatedAt, setLastUpdatedAt] = useState(new Date());

  useEffect(() => {
    if (streamId && siteSystemId && type) {
      RecordOfQualityService.getRecordOfQualitiesSample(
        streamId,
        siteSystemId,
        type,
        moment(from).toDate(),
        moment(until).toDate()
      )
        .then(({ data }) => {
          setSampleData(data);
          setLoadingState("loaded");
        })
        .catch(() => setLoadingState("error"));
    } else {
      setLoadingState("error");
    }
  }, [streamId, siteSystemId, type, from, until, lastUpdatedAt]);

  const refetchRoQSampleData = () => {
    setLastUpdatedAt(new Date());
  };
  return [sampleData, loadingState, refetchRoQSampleData];
};

const useFetchRoQSelectionData = (streamId, siteSystemId, from, until) => {
  const [selectionData, setSelectionData] = useState([]);
  const [state, setState] = useState("loading");

  useEffect(() => {
    setState("loading");
    setSelectionData([]);

    if (streamId && siteSystemId) {
      RecordOfQualityService.getRecordOfQualitiesSelection(
        streamId,
        siteSystemId,
        moment(from).toDate(),
        moment(until).toDate()
      )
        .then(({ data }) => {
          setSelectionData(data);
          setState("loaded");
        })
        .catch(() => setState("error"));
    } else {
      setState("error");
    }
  }, [streamId, siteSystemId, from, until]);

  return [selectionData, state];
};

/**
 * This hook handles all the fetches related to editing the current roq selection
 * and changes the roqSelectionState whenever a fetch has been made or a user
 * interacted with the data (e.g. sample selection, method selection).
 */
const useManageSelectionFetches = (
  accountingRecordDetail,
  roqSelectionState,
  streams,
  updateResultsData,
  setSelectionStream,
  setPreselectedValues
) => {
  const [
    onMeasurementSelect,
    onDateSelect,
    onStreamParamSelect,
    resetDateRange,
    measurement,
    from,
    until,
    stream,
    siteSystemId,
    displayProperties,
  ] = useManageSelectionFetchParams(accountingRecordDetail, streams);

  const [sampleData, sampleDataLoadingState, refetchRoQSampleData] =
    useFetchRoQSampleData(stream?.id, siteSystemId, measurement, from, until);

  const [selectionData, selectionDataLoadingState] = useFetchRoQSelectionData(
    stream?.id,
    siteSystemId,
    from,
    until
  );

  // Calculate preSelectedValues only after RoQDetail fetched and roqSelectionState
  // initialized to prevent preSelectedValues selected using a different stream than
  // roqSelectionState.selectionStreamId and causing saving issues
  useEffect(() => {
    if (
      selectionDataLoadingState === "loaded" &&
      roqSelectionState.isInitialized
    ) {
      const value = getPreSelectedValues(
        selectionData,
        accountingRecordDetail.accounting_period
      );

      if (value) {
        setPreselectedValues({ ...value, selectionStreamId: stream?.id });
      }
    }
  }, [
    selectionData,
    selectionDataLoadingState,
    roqSelectionState.isInitialized,
  ]);

  // Current RoQ will change if results are updated
  useEffect(() => {
    refetchRoQSampleData();
  }, [updateResultsData]);

  const onStreamSelect = (value) => {
    if (value && value.id !== stream?.id) {
      onStreamParamSelect(value);

      setSelectionStream(value.id);
    }
  };

  return [
    sampleData,
    sampleDataLoadingState,
    selectionData,
    selectionDataLoadingState,
    onMeasurementSelect,
    onDateSelect,
    onStreamSelect,
    measurement,
    from,
    until,
    stream,
    resetDateRange,
    displayProperties,
  ];
};

export default useManageSelectionFetches;
