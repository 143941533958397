import { KeyValuePanel, Link, Pill } from "@validereinc/common-components";
import * as PropTypes from "prop-types";
import React from "react";

import { useNavigate } from "#routers/hooks";
import { linkToDeviceDetail, linkToFacilities } from "#routers/links";
import useLocalization from "#src/hooks/useLocalization";
import startCase from "lodash/startCase";

const DeviceOverviewPanel = ({ deviceDetail, isLoading }) => {
  const { localize, isLoading: isMappingLoading } = useLocalization();
  const navigate = useNavigate();

  const data = [
    {
      title: "Device",
      value: (
        <Link
          onClick={() =>
            navigate({ pathname: linkToDeviceDetail(deviceDetail?.id) })
          }
          label={deviceDetail?.name ?? "-"}
        />
      ),
    },
    {
      title: localize("facility"),
      value: (
        <Link
          onClick={() =>
            navigate({ pathname: linkToFacilities(deviceDetail?.facility_id) })
          }
          label={deviceDetail?.facility?.name ?? "-"}
        />
      ),
    },
    {
      title: "Status",
      value: !isLoading ? (
        <Pill
          variant={deviceDetail.status === "active" ? "success" : "warning"}
        >
          {startCase(deviceDetail.status) ?? "-"}
        </Pill>
      ) : (
        "-"
      ),
    },
    ...Object.entries(deviceDetail?.attributes ?? {}).map(([key, value]) => ({
      key: key,
      title: startCase(key),
      value: key !== "location" ? startCase(value) : value,
    })),
    {
      title: "Measurement Types",
      value:
        deviceDetail.measurement_types
          ?.map((type) => startCase(type))
          ?.join(", ") ?? "-",
    },
  ];

  return (
    <KeyValuePanel
      panelMaxColumnCount={2}
      panelKeyValueListProps={{ maxRowCount: 6, variant: "shaded" }}
      panelProps={{
        title: "Device Details",
        loaded: !isMappingLoading && !isLoading,
      }}
      data={data}
    />
  );
};

DeviceOverviewPanel.propTypes = {
  deviceDetail: PropTypes.object,
  isLoading: PropTypes.bool,
};

export default DeviceOverviewPanel;
