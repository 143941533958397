import React from "react";
import * as PropTypes from "prop-types";
import InstrumentDetail from "../InstrumentDetail";
import { connect } from "react-redux";
import { getMeasurementType } from "../../Redux/reducers/measurements";
import { getAlertSpec } from "../InstrumentComponentHelper";
import {
  sampleDateRenderer,
  sortBySampleDate,
  dateRenderer,
  getMeasurementValue,
} from "../InstrumentHelper";
import MeasurementStatus from "../Shared/TestsDatatable/MeasurementStatus";
import { notesColumn } from "../Shared/TestsDatatable/NotesColumn";
import {
  sampleStateRenderer,
  sortBySampleState,
} from "../../Samples/SampleHelper";
import TestDetailModal from "#components/Tests/TestDetailModal/TestDetailModal";

const measurementRenderer = (measurementType) =>
  function measurementRendererInner(rowData, columnKey) {
    const defaultAlertSpec = {
      measurement_unit: measurementType.unit,
      upper_limit: 0.5,
      lower_limit: 0,
    };

    try {
      const value = getMeasurementValue(
        rowData.measurements[columnKey].value,
        measurementType.decimals
      );
      const unit = rowData.measurements[columnKey].unit;

      return (
        <MeasurementStatus
          value={value}
          unit={unit}
          labels={rowData.labels}
          status={rowData.status}
          defaultAlertSpec={defaultAlertSpec}
          alertSpec={getAlertSpec(
            rowData.measurement_alert_specs,
            measurementType.name,
            defaultAlertSpec
          )}
          measurementType={measurementType}
        />
      );
    } catch {
      return <div>-</div>;
    }
  };

const headers = (measurementType) => {
  return [
    {
      label: "Name",
      key: "name",
      width: 400,
      fixed: true,
    },
    {
      label: "Sample State",
      key: "sample_state",
      width: 150,
      cellRenderer: (row) => sampleStateRenderer(row.sample),
      sort: sortBySampleState,
    },
    {
      label: "Date",
      key: "date",
      width: 170,
      cellRenderer: dateRenderer,
    },
    {
      label: "Tester",
      key: "tested_by",
      width: 150,
      suppress: true,
    },
    {
      label: measurementType.display_name,
      key: measurementType.name,
      width: 250,
      cellRenderer: measurementRenderer(measurementType),
      sort: (list, measurementName) => {
        return [...list].sort((a, b) => {
          return (
            a.measurements[measurementName].value -
            b.measurements[measurementName].value
          );
        });
      },
    },
    {
      label: "Sample Start Date",
      key: "started_at",
      width: 170,
      cellRenderer: sampleDateRenderer,
      sort: sortBySampleDate,
    },
    {
      label: "Sample End Date",
      key: "ended_at",
      width: 170,
      cellRenderer: sampleDateRenderer,
      sort: sortBySampleDate,
    },
    notesColumn,
  ];
};

const mapStateToProps = (state) => {
  return {
    measurementType: getMeasurementType(state.measurements)("Water"),
  };
};

const InstrumentMettlerToledoV20STests = (props) => {
  return (
    <InstrumentDetail
      testId={props.match.params.testId}
      height={props.height}
      instrumentInfo={props.instrumentInfo}
      ReportModalComponent={TestDetailModal}
      filterKey="name"
      headers={headers(props.measurementType)}
      defaultSortBy="date"
      defaultSortDirection="desc"
    />
  );
};

InstrumentMettlerToledoV20STests.propTypes = {
  match: PropTypes.object.isRequired,
  height: PropTypes.number.isRequired,
  instrumentInfo: PropTypes.object.isRequired,
  measurementType: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  undefined
)(InstrumentMettlerToledoV20STests);
