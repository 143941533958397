import React, { useEffect, useReducer } from "react";

import {
  DataTable,
  HeaderType,
  SortingType,
} from "@validereinc/common-components";

import { EventCategoryDomain, SortDirection } from "@validereinc/domain";

import { linkToEventCategoryDetail } from "#src/routes/events/event-categories/[eventCategoryId]";

import type { EventCategoryType, SortDirectionType } from "@validereinc/domain";
import { RoutingLink } from "../RoutingLink";

type EventCategoriesDataType = {
  eventCategories: EventCategoryType[];
  isLoading: boolean;
  pagination: {
    page: number;
    itemsPerPage: number;
  };
  sorting: {
    sortBy: string;
    sortDirection: SortDirectionType;
  };
  total?: number;
};

export const EventCategoriesTable = () => {
  const eventCategoriesReducer = (
    prevState: EventCategoriesDataType,
    newState: Partial<EventCategoriesDataType>
  ) => {
    return { ...prevState, ...newState };
  };

  const [eventCategoriesData, setEventCategoriesData] = useReducer(
    eventCategoriesReducer,
    {
      eventCategories: [],
      isLoading: true,
      pagination: {
        page: 1,
        itemsPerPage: 10,
      },
      sorting: { sortBy: "name", sortDirection: SortDirection.ASCENDING },
    }
  );

  const onFetchData = async () => {
    setEventCategoriesData({ isLoading: true });

    const { data: eventCategories, total_entries } =
      await EventCategoryDomain.getList({
        ...eventCategoriesData.pagination,
        ...eventCategoriesData.sorting,
      });

    setEventCategoriesData({
      eventCategories,
      isLoading: false,
      total: total_entries,
    });
  };

  const headers: Array<HeaderType<EventCategoryType>> = [
    {
      key: "name",
      label: "Name",
      renderComponent: ({ item }) => (
        <RoutingLink to={linkToEventCategoryDetail(item.id)}>
          {item.name}
        </RoutingLink>
      ),
    },
    {
      key: "created_at",
      label: "Date Created",
      isSortable: true,
      renderComponent: ({ item }) => (
        <DataTable.DataRow.DateCell
          value={item.created_at}
          withTime
        />
      ),
    },
  ];

  const onPaginationChange = ({
    page,
    itemsPerPage,
  }: {
    page: number;
    itemsPerPage: number;
  }) => {
    setEventCategoriesData({
      pagination: {
        ...eventCategoriesData.pagination,
        page,
        itemsPerPage,
      },
    });
  };

  const onSortChange = ({ sortBy, sortDirection }: SortingType) => {
    setEventCategoriesData({
      sorting: {
        ...eventCategoriesData.sorting,
        sortBy,
        sortDirection,
      },
    });
  };

  useEffect(() => {
    onFetchData();
  }, [eventCategoriesData.pagination, eventCategoriesData.sorting]);

  return (
    <DataTable
      headers={headers}
      items={eventCategoriesData.eventCategories}
      isLoading={eventCategoriesData.isLoading}
      pagination={{
        ...eventCategoriesData.pagination,
        total: eventCategoriesData.total ?? 0,
      }}
      onPaginationChange={onPaginationChange}
      onSortChange={onSortChange}
    />
  );
};
