import React from "react";
import { AssetGroupDomain } from "@validereinc/domain";
import {
  EntityDropdownInput,
  BaseEntityDropdownProps,
} from "#batteries-included-components/Dropdowns/EntityDropdownInput";

export const AssetGroupDropdownInput = ({
  name = "network.id",
  ...props
}: BaseEntityDropdownProps) => {
  return (
    <EntityDropdownInput
      fetchEntityList={AssetGroupDomain.getList}
      label="Network"
      name={name}
      {...props}
    />
  );
};
