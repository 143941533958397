import { Modal, Tab, Tabs, Title } from "@validereinc/common-components";
import * as PropTypes from "prop-types";
import React from "react";
import { useFetchNotes, WORKFLOWTASKS_NOTE } from "../../Services/NotesService";
import { TAB_KEYS } from "./ScheduleViewConstants";
import WorkflowTaskDetail from "./WorkflowTaskDetail";
import { canAddNotes } from "./WorkflowTaskHelpers";
import "./WorkflowTaskModal.scss";
import WorkflowTaskNotes from "./WorkflowTaskNotes";

const WorkflowTaskModal = (props) => {
  const [areNotesLoading, _errorStatus, notes, fetchNotes] = useFetchNotes(
    props.workflowTask?.id,
    WORKFLOWTASKS_NOTE
  );

  return (
    <>
      <Modal
        open={props.show}
        onClose={props.closeModal}
      >
        <Modal.Header>
          <Title>{props.workflowTask?.name ?? "-"}</Title>
        </Modal.Header>

        <div className="workflowTaskModal">
          <Tabs
            activeKey={props.activeTabKey}
            onChange={(newTabKey) => props.setActiveTabKey(newTabKey)}
          >
            <Tab
              tabKey={TAB_KEYS.details}
              title="Details"
            >
              <WorkflowTaskDetail
                workflowTask={props.workflowTask}
                refetchWorkflowTasks={props.refetchWorkflowTasks}
                closeModal={props.closeModal}
              />
            </Tab>

            <Tab
              tabKey={TAB_KEYS.notes}
              title="Notes"
              disabled={!canAddNotes(props.workflowTask)}
            >
              <WorkflowTaskNotes
                isLoading={areNotesLoading}
                notes={notes}
                fetchNotes={fetchNotes}
                workflowTaskId={props.workflowTask?.id}
                isActiveTab={props.activeTabKey === TAB_KEYS.notes}
                refetchWorkflowTasks={props.refetchWorkflowTasks}
                closeModal={props.closeModal}
              />
            </Tab>
          </Tabs>
        </div>
      </Modal>
    </>
  );
};

WorkflowTaskModal.propTypes = {
  workflowTask: PropTypes.object,
  activeTabKey: PropTypes.string,
  setActiveTabKey: PropTypes.func,
  refetchWorkflowTasks: PropTypes.func,
  closeModal: PropTypes.func,
  show: PropTypes.bool,
};

export default WorkflowTaskModal;
