import { useStickyState } from "#src/hooks/useStickyState";
import { Button, EmptyState, Icon, Link } from "@validereinc/common-components";
import classNames from "classnames/bind";
import React from "react";
import styles from "./UnsupportedMediaError.module.css";

const cx = classNames.bind(styles);

export const UnsupportedMediaError = ({
  targetBreakpointWidth,
}: {
  targetBreakpointWidth: number;
}) => {
  const [isAcknowledged, setIsAcknowledged] = useStickyState(
    false,
    "app-unsupported-media-is-acknowledged"
  );

  if (isAcknowledged) {
    return null;
  }

  return (
    <EmptyState
      icon={<Icon variant="monitor" />}
      variant="warning"
      size="large"
      title="We're sorry, this app is only optimized for desktop screen sizes."
      suggestion={({ className }) => (
        <p className={className}>
          {"For the best experience, "}
          {typeof targetBreakpointWidth === "number" &&
          targetBreakpointWidth >= 0
            ? `resize your window, decrease your page zoom, or change to a screen of ${targetBreakpointWidth}px or larger width.`
            : "resize your window, decrease your page zoom, or change to a larger screen size."}
          {
            " If that doesn't work for you, you have two options: (1) you can continue your work here anyway but note that the app might not be entirely functional at this media size. We're working towards being fully responsive soon - stay tuned! (2) or even better, you can download our mobile app available at "
          }
          <Link
            key="ios"
            anchorTagProps={{
              href: "https://apps.apple.com/ca/app/validere/id6446293374",
              target: "__blank",
            }}
            label="the iOS App Store"
          />{" "}
          or the{" "}
          <Link
            key="google"
            anchorTagProps={{
              href: "https://play.google.com/store/apps/details?id=com.validereinc.validerecompanionapp&pli=1",
              target: "__blank",
            }}
            label="the Google Play Store"
          />
          {" to continue your work."}
        </p>
      )}
      action={
        <Button
          variant="primary"
          onClick={() => setIsAcknowledged(true)}
        >
          I Understand
        </Button>
      }
      className={cx("container")}
    />
  );
};
