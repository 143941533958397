import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AlertBaseProps, useAlert } from "@validereinc/common-components";
import {
  ResourceDefinitions,
  ResourceServiceType,
  ResourceType,
} from "@validereinc/domain";

export const DEFAULT_QUERY_OPTIONS = {
  refetchOnWindowFocus: false,
  refetchOnMount: false,
  staleTime: 1 * 60 * 1000,
  retry: false,
} as const;

export const NON_INVALIDATING_QUERY_OPTIONS = {
  ...DEFAULT_QUERY_OPTIONS,
  staleTime: Infinity,
} as const;

export const DEFAULT_INVALIDATE_OPTIONS = {
  /** invalidate queries which are not mounted */
  refetchType: "all",
} as const;

export const getPollingOptions = (
  terminalStates: string[],
  statusAttribute = "status"
) => {
  return {
    /** If the entity isn't in a terminal state, refetch periodically */
    refetchIntervalInBackground: true,
    // TODO: Get useQuery typing for data/query
    refetchInterval: (data: any, query: any) =>
      data && !terminalStates.includes(data?.data?.[statusAttribute])
        ? query.state.dataUpdateCount > 5
          ? 3500
          : 1500
        : undefined,
  };
};

export type UseMutationCallbackType<
  TQueryReturnType = unknown,
  TError = unknown,
> = {
  onSuccess?: (
    data: TQueryReturnType,
    variables: any,
    context: unknown
  ) => void;
  onError?: (err: TError, variables: any, context: unknown) => void;
  successMessage?:
    | AlertBaseProps["message"]
    | ((
        data: TQueryReturnType,
        variables: any,
        context: unknown
      ) => AlertBaseProps["message"]);
  errorMessage?:
    | AlertBaseProps["message"]
    | ((
        err: TError,
        variables: any,
        context: unknown
      ) => AlertBaseProps["message"]);
  noAlerts?: boolean;
  customInvalidateFunction?: () => void;
};

export type UseQueryOptionsType<TQueryReturnType> = {
  enabled?: boolean;
  select?: <TSelectType>(data: TQueryReturnType) => TSelectType;
  refetchOnWindowFocus?: boolean;
  refetchOnMount?: boolean;
};

export const useClearCache = (rootQueryKey: string | string[]) => {
  const queryClient = useQueryClient();
  const rootQueryKeys = Array.isArray(rootQueryKey)
    ? rootQueryKey
    : [rootQueryKey];

  return {
    invalidate: (queryKey: string[] = []) => {
      queryClient.invalidateQueries({
        queryKey: [...rootQueryKeys, ...queryKey],
        ...DEFAULT_INVALIDATE_OPTIONS,
      });
    },
  };
};

export const useQueryWrapper =
  <TQueryParamType, TQueryReturnType, TSelectReturnType = TQueryReturnType>(
    /** The domain method that performs the API call.
     *  Usually in the form `MyDomain.subdomain.getOne` */
    domainMethod: (inputParams: TQueryParamType) => Promise<TQueryReturnType>,
    /** The top-level cache keys for the domain.
     *  `"MyDomain.getOne"` => `["MyDomain", "getOne"]` */
    queryKeyPrefixes: string[],
    /** Set to true if you don't want the cache to ever go stale */
    isNonInvalidating = false
  ) =>
  (
    params: TQueryParamType,
    options: {
      enabled?: boolean;
      /** TODO: Cannot figure out how to cleanly interpolate select type */
      // select?: (data: TQueryReturnType) => TSelectReturnType;
    } = {}
  ) =>
    useQuery<
      TQueryReturnType,
      TQueryReturnType,
      TSelectReturnType,
      [...string[], TQueryParamType]
    >({
      queryKey: [...queryKeyPrefixes, params],
      queryFn: ({ queryKey }) => {
        const [queryParams] = queryKey.slice(-1);
        return domainMethod(queryParams as TQueryParamType);
      },
      ...(isNonInvalidating
        ? NON_INVALIDATING_QUERY_OPTIONS
        : DEFAULT_QUERY_OPTIONS),
      ...options,
    });

export const useMutationWrapper = <TMutationParamType, TMutationReturnType>(
  /** The domain method that performs the API call.
   *  Usually in the form `MyDomain.subdomain.updateOne` */
  domainMethod: (
    inputParams: TMutationParamType
  ) => Promise<TMutationReturnType>,
  /** The query keys to invalidate */
  queryKeys: string[],
  {
    successMessage,
    errorMessage,
    onSuccess,
    onError,
    noAlerts,
    customInvalidateFunction,
  }: UseMutationCallbackType = {}
) => {
  const { addAlert } = useAlert();
  const { invalidate } = useClearCache(queryKeys);

  return useMutation({
    mutationFn: (params: TMutationParamType) => domainMethod(params),
    onSuccess: (data, variables, context) => {
      if (!noAlerts) {
        const generatedSuccessMessage =
          typeof successMessage === "function"
            ? successMessage(data, variables, context)
            : successMessage;
        addAlert?.({
          variant: "success",
          message:
            generatedSuccessMessage ?? "Successfully completed operation",
        });
      }
      (customInvalidateFunction ?? invalidate)();
      onSuccess?.(data, variables, context);
    },
    onError: (err, variables, context) => {
      if (!noAlerts) {
        const generatedErrorMessage =
          typeof errorMessage === "function"
            ? errorMessage(err, variables, context)
            : errorMessage;
        addAlert?.({
          variant: "error",
          message: generatedErrorMessage ?? "Failed to complete operation",
        });
      }
      onError?.(err, variables, context);
    },
  });
};

export const useGetOne = <TDomainModel>(
  getOne: ResourceServiceType<TDomainModel>["getOne"],
  resource: ResourceType,
  ...subdomain: string[]
) => {
  const queryKeyRoot = [resource, ...subdomain];
  return useQueryWrapper(getOne, [...queryKeyRoot, "getOne"]);
};

export const useGetList = <TDomainModel>(
  getList: ResourceServiceType<TDomainModel>["getList"],
  resource: ResourceType,
  ...subdomain: string[]
) => {
  const queryKeyRoot = [resource, ...subdomain];
  return useQueryWrapper(getList, [...queryKeyRoot, "getList"]);
};

export const useUpdateOne = <TDomainModel>(
  updateOne: ResourceServiceType<TDomainModel>["updateOne"],
  resource: ResourceType,
  ...subdomain: string[]
) => {
  const queryKeyRoot = [resource, ...subdomain];
  const getResourceName = (resource: ResourceType) =>
    ResourceDefinitions[resource].label.singular;

  return (options: UseMutationCallbackType = {}) =>
    useMutationWrapper(updateOne, queryKeyRoot, {
      successMessage: `Successfully updated ${getResourceName(resource)}`,
      errorMessage: `Failed to update ${getResourceName(resource)}`,
      ...options,
    });
};

export const useDeleteOne = <TDomainModel>(
  deleteOne: ResourceServiceType<TDomainModel>["deleteOne"],
  resource: ResourceType,
  ...subdomain: string[]
) => {
  const queryKeyRoot = [resource, ...subdomain];
  const getResourceName = (resource: ResourceType) =>
    ResourceDefinitions[resource].label.singular;

  return (options: UseMutationCallbackType = {}) =>
    useMutationWrapper(deleteOne, queryKeyRoot, {
      successMessage: `Successfully deleted ${getResourceName(resource)}`,
      errorMessage: `Failed to delete ${getResourceName(resource)}`,
      ...options,
    });
};

export const useCreateOne = <TDomainModel>(
  createOne: ResourceServiceType<TDomainModel>["createOne"],
  resource: ResourceType,
  ...subdomain: string[]
) => {
  const queryKeyRoot = [resource, ...subdomain];
  const getResourceName = (resource: ResourceType) =>
    ResourceDefinitions[resource].label.singular;

  return (options: UseMutationCallbackType = {}) =>
    useMutationWrapper(createOne, queryKeyRoot, {
      successMessage: `Successfully created ${getResourceName(resource)}`,
      errorMessage: `Failed to create ${getResourceName(resource)}`,
      ...options,
    });
};
