import { useMemo, useCallback } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";

type NavigateFunctionType = {
  (args: {
    /** the path to navigate to */
    pathname: string;
    /** query parameters in the shape of a record */
    query?: Partial<Record<string, string>>;
    /** URL-safe query parameter string with the `?` suffix */
    search?: string;
    /** replace the last item in the browser history or just push? */
    replace?: boolean;
  }): void;
  goBack: () => void;
};

export const useNavigate = (): NavigateFunctionType => {
  const history = useHistory();

  const navigate = useCallback<
    (args: {
      pathname: string;
      query?: Record<string, string>;
      search?: string;
      replace?: boolean;
    }) => void
  >(
    ({ pathname, query = {}, search, replace = false }) => {
      let queryString = search;

      if (!search) {
        queryString = `?${new URLSearchParams(
          Object.fromEntries(
            Object.entries(query).filter(
              ([_key, value]) => ![undefined, null, ""].includes(value)
            )
          )
        ).toString()}`;
      }

      if (replace) {
        history.replace(`${pathname}${queryString}`);
      } else {
        history.push(`${pathname}${queryString}`);
      }
    },
    [history?.push, history?.replace]
  );

  (navigate as NavigateFunctionType).goBack = history?.goBack;
  return navigate as NavigateFunctionType;
};

const queryReducer = (total, [key, value]) => {
  if (value) {
    if (Object.prototype.hasOwnProperty.call(total, key)) {
      if (Array.isArray(total[key])) {
        total[key].push(value);
      } else {
        total[key] = [total[key], value];
      }
    } else {
      if (value.includes(",")) {
        total[key] = value.split(",");
      } else {
        total[key] = value;
      }
    }
  }

  return total;
};

const getQueryObjectFromString = (string) => {
  const newQueryObject = new URLSearchParams(string);

  return [...newQueryObject].reduce(queryReducer, {});
};

/**
 * Hook to retrieve any URL query search parameters as a URLSearchParams instance then reduce it into an object of kv pairs.
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams}
 * @returns an object map of query search parameters if any and a setter function to update query parameters
 */
export const useSearchParams = <T extends Record<string, any>>(): [
  Partial<T>,
  (newQueryObject: Record<string, any>) => void,
] => {
  const { search, pathname } = useLocation();

  const navigate = useNavigate();

  const setSearchParams = useCallback(
    (newQuery) => {
      let newValue = newQuery;

      if (typeof newValue === "string") {
        newValue = getQueryObjectFromString(newValue);
      }

      navigate({ pathname, query: newValue, replace: true });
    },
    [navigate, pathname]
  );

  const query = useMemo(() => getQueryObjectFromString(search), [search]);

  return [query, setSearchParams];
};

export { useHistory, useLocation, useParams };
