import { dateTimeRenderer } from "#common/Table/renderers";
import useQueryParams from "#routers/useQueryParams";
import ChainOfCustodyService from "#services/ChainOfCustodyService";
import { LegacyDataTable } from "@validereinc/common-components";
import get from "lodash/get";
import sortBy from "lodash/sortBy";
import moment from "moment";
import * as PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import { AutoSizer } from "react-virtualized";
import {
  sampleStateRenderer,
  sortBySampleState,
} from "../../Samples/SampleHelper";
import AssociatedTestsCell from "./AssociatedTestsCell";
import "./SamplesTable.scss";
import useFilterInputs from "./SamplesTableFilter";

const DEFAULT_DATE_RANGE = 30;

const ROW_HEIGHT = 60;

const sortByColumnKey = (rowData, columnKey) => {
  return sortBy(rowData, (rowData) => get(rowData, columnKey));
};

const simpleTextRenderer = (rowData, columnKey) => {
  const text = get(rowData, columnKey, "-");

  return <div className="capitalized textWrapColumn">{text}</div>;
};

const shortCodeRenderer = (rowData, columnKey) => {
  const shortCodes = rowData[columnKey];

  return shortCodes ? (
    <div className="samplesTable__streamShortCode">
      {rowData[columnKey]?.map((shortCode) => (
        <div key={shortCode}>{shortCode}</div>
      ))}
    </div>
  ) : (
    "-"
  );
};

const workflowTypesRenderer = (rowData) => {
  const workflowType = rowData.workflow_instance?.test_type;

  if (workflowType === "field") {
    return "Field";
  } else if (workflowType === "third_party_lab") {
    return "Third Party Lab";
  } else {
    return "Non-routine";
  }
};

const assiocatedTestRenderer = (rowData) => {
  const associatedTests = rowData.associated_tests;

  if (associatedTests?.length) {
    return (
      <AssociatedTestsCell
        associatedTests={associatedTests}
        rowHeight={ROW_HEIGHT}
      />
    );
  }

  return "-";
};

const useFetchSamples = (lastUpdatedAt) => {
  const [samples, setSamples] = useState([]);
  const [state, setState] = useState("loading");

  const [dateRange, setDateRange] = useState({
    from: moment()
      .subtract(DEFAULT_DATE_RANGE, "days")
      .startOf("day")
      .valueOf(),
    until: moment().endOf("day").valueOf(),
  });

  useEffect(() => {
    setState("loading");

    const fromDate = moment(dateRange.from).toDate();
    const untilDate = moment(dateRange.until).toDate();

    ChainOfCustodyService.getSamples(fromDate, untilDate)
      .then(({ data }) => {
        setSamples(data);
        setState("loaded");
      })
      .catch(() => setState("error"));
  }, [dateRange.from, dateRange.until, lastUpdatedAt]);

  const selectDate = (from, until) => {
    setDateRange({
      from: moment(from).startOf("day").valueOf(),
      until: moment(until).endOf("day").valueOf(),
    });
  };

  return [samples, state, dateRange, selectDate];
};

const SamplesTable = (props) => {
  const [samples, loadingState, dateRange, selectDate] = useFetchSamples(
    props.samplesTableLastUpdatedAt
  );

  const [queryParams, setQueryParams] = useQueryParams(
    { samples: null },
    { samples: samples }
  );

  useEffect(() => {
    if (queryParams.samples) {
      props.setSelectedSample(queryParams.samples);

      setQueryParams({ samples: null });
    }
  }, [queryParams.samples, samples]);

  const samplesWithShortCode = useMemo(() => {
    const shortCodeMap = {};
    props.shortCodes.forEach((shortCode) => {
      if (shortCodeMap[shortCode.stream_id]) {
        shortCodeMap[shortCode.stream_id].push(shortCode.short_code);
      } else {
        shortCodeMap[shortCode.stream_id] = [shortCode.short_code];
      }
    });

    const associatedTestMap = {};
    props.allTests.forEach((test) => {
      if (!test.sample_id) {
        return;
      }

      if (associatedTestMap[test.sample_id]) {
        associatedTestMap[test.sample_id] = [
          ...associatedTestMap[test.sample_id],
          test,
        ];
      } else {
        associatedTestMap[test.sample_id] = [test];
      }
    });

    return samples.map((sample) => {
      const shortCode = shortCodeMap[sample.stream_id];
      const associatedTests = associatedTestMap[sample.id];

      const newSample = { ...sample };

      if (shortCode) {
        newSample.stream_short_code = shortCode;
      }

      if (associatedTests) {
        newSample.associated_tests = associatedTests;
      }

      return newSample;
    });
  }, [samples, props.shortCodes, props.allTests]);

  const [filterRow, filterPillbox, filteredSamplesWithShortCodes] =
    useFilterInputs(samplesWithShortCode, dateRange, selectDate);

  const headers = [
    {
      label: "Sample Name",
      key: "sample_name",
      cellRenderer: simpleTextRenderer,
      width: 250,
      fixed: true,
    },
    {
      label: "Stream Short Code",
      key: "stream_short_code",
      width: 175,
      cellRenderer: shortCodeRenderer,
    },
    {
      label: "Sample Short Code",
      key: "short_code",
      width: 150,
    },
    {
      label: "Sample Date",
      key: "started_at",
      cellRenderer: dateTimeRenderer,
      sort: sortByColumnKey,
      width: 135,
    },
    {
      label: "Sample Type",
      key: "sample_type",
      cellRenderer: simpleTextRenderer,
      sort: sortByColumnKey,
      width: 115,
    },
    {
      label: "Status",
      key: "state",
      cellRenderer: sampleStateRenderer,
      sort: sortBySampleState,
      width: 135,
    },
    {
      label: "Workflow",
      key: "workflow_instance.test_type",
      cellRenderer: workflowTypesRenderer,
      sort: sortByColumnKey,
      width: 115,
    },
    {
      label: "Site",
      key: "site.name",
      cellRenderer: simpleTextRenderer,
      sort: sortByColumnKey,
      width: 200,
    },
    {
      label: "Associated Tests",
      key: "associated_tests",
      cellRenderer: assiocatedTestRenderer,
      width: 200,
    },
  ];

  return (
    <AutoSizer>
      {({ height, width }) => (
        <LegacyDataTable
          width={width}
          height={height}
          headers={headers}
          list={filteredSamplesWithShortCodes}
          filterRow={filterRow}
          filterPillbox={filterPillbox}
          filterKey="sample_name"
          defaultSortBy="started_at"
          defaultSortDirection="desc"
          onCheckboxClick={props.onSampleCheck}
          rowHeight={ROW_HEIGHT}
          noFilterListCount={samples?.length}
          isLoading={loadingState !== "loaded"}
          checkedList={props.selectedSample}
          onAddClick={props.canCreateSamples ? props.onAddClick : null}
          addButtonName="Samples"
          disableSelectAll
          disableCheckbox={props.disabled}
        />
      )}
    </AutoSizer>
  );
};

SamplesTable.propTypes = {
  onSampleCheck: PropTypes.func,
  selectedSample: PropTypes.array,
  shortCodes: PropTypes.array,
  allTests: PropTypes.array,
  onAddClick: PropTypes.func,
  canCreateSamples: PropTypes.bool,
  samplesTableLastUpdatedAt: PropTypes.instanceOf(Date),
  setSelectedSample: PropTypes.func,
  disabled: PropTypes.bool,
};

export default SamplesTable;
