import config from "#src/config";
import { getTimeStringFromDate } from "#utils/timeFormatter";

// Insights Links
export const linkToInsights = () => {
  return `/app/insights`;
};

// Dashboard Links
export const linkToDashboards = (id) => {
  if (id) {
    return `/app/dashboards/${id}/detail`;
  }

  return `/app/dashboards`;
};

// Facility Link

export const linkToFacilities = (id) => {
  if (id) {
    return `/app/organization/facilities/${id}/detail`;
  }

  return `/app/organization/facilities`;
};

export const linkToCreateFacility = () => {
  return `/app/organization/facilities/create`;
};

export const linkToEquipmentDetail = (equipmentId = ":equipmentId") => {
  return `/app/organization/equipment/${equipmentId}/detail`;
};

export const linkToCreateEquipment = () => {
  return `/app/organization/equipment/create`;
};

export const linkToAddEquipment = (id) => {
  return `/app/organization/facilities/${id}/equipment/add`;
};

export const linkToAddDevice = (id) => {
  return `/app/organization/facilities/${id}/devices/add`;
};

// Equipment Links
export const linkToEquipmentList = () => {
  return `/app/organization/equipment`;
};

// Device Links
export const linkToDeviceDetail = (deviceId) => {
  return `/app/organization/devices/${deviceId}/details`;
};

// Alert Links
export const linkToAlertConfiguration = () => {
  return `/app/alerts/configuration`;
};

export const linkToCreateAlertConfiguration = () => {
  return `/app/alerts/configuration/create`;
};

export const linkToAlertConfigurationDetail = (alertConfigId) => {
  return `/app/alerts/configuration/${alertConfigId}/detail`;
};

export const linkToAlerts = () => {
  return `/app/alerts/alerts`;
};

export const linkToAlertDetail = (alertId) => {
  return `/app/alerts/alerts/${alertId}/detail`;
};

// Form Links
export const linkToFormSubmissionList = () => {
  return `/app/forms/submissions`;
};

export const linkToFormSubmissionDetail = (formSubmissionId?: string) => {
  return `/app/forms/submissions/${formSubmissionId}/detail`;
};

export const linkToCreateFormSubmission = (formSchemaId) => {
  return `/app/forms/templates/${formSchemaId}/create`;
};

// Workflow Links
export const linkToViewWorkflows = (viewFormat = "") => {
  if (viewFormat) {
    return `/app/workflows/tasks/${viewFormat}/view`;
  } else {
    return `/app/workflows/tasks/schedule/view`;
  }
};

export const linkToCreateWorkflow = () => {
  return `/app/workflows/create`;
};

export const linkToEditWorkflow = (workflowId) => {
  return `/app/workflows/manage/edit/${workflowId}`;
};

export const linkToManageWorkflows = () => {
  return `/app/workflows/manage`;
};

// Instrument Links
export const linkToInstruments = () => {
  return `/app/instrument/list`;
};

export const linkToInstrumentSummary = (instrumentId) => {
  return `/app/instrument/view/${instrumentId}/summary`;
};

export const linkToInstrumentTests = (instrumentId) => {
  return `/app/instrument/view/${instrumentId}/tests/*`;
};

export const linkToTestDetails = (instrumentId, testId) => {
  return `/app/instrument/view/${instrumentId}/tests/${testId}`;
};

export const linkToInlineInstrument = (instrumentType, instrumentId) => {
  return `/app/inline-instruments/${instrumentType}/${instrumentId}/summary`;
};

export const linkToInlineInstrumentTab = (
  instrumentType,
  instrumentId,
  tab
) => {
  return `/app/inline-instruments/${instrumentType}/${instrumentId}/${tab}`;
};

export const linkToTests = () => {
  return `/app/tests`;
};

export const linkToTestDetail = (testId) => {
  return `/app/tests/${testId}/detail`;
};

// Sample Links
export const linkToSamples = (sampleId = "") => {
  if (sampleId) {
    return `/app/samples?sample=${sampleId}`;
  }

  return `/app/samples`;
};

// Data Mapping Links
export const linkToDataMapping = () => {
  return `/app/data-management/data-mapping`;
};

export const linkToDataMappingDetail = (dataMappingId) => {
  if (dataMappingId) {
    return `/app/data-management/data-mapping/${dataMappingId}/detail`;
  }

  return linkToDataMapping();
};

export const linkToDataMappingUploadDetail = (dataMappingId, taskId) => {
  if (dataMappingId && taskId) {
    return `/app/data-management/data-mapping/${dataMappingId}/detail/${taskId}`;
  }

  return linkToDataMapping();
};

export const linkToCreateDataMapping = () => {
  return `/app/data-management/data-mapping/create/basic-info`;
};

export const linkToCreateDataMappingUploadData = () => {
  return `/app/data-management/data-mapping/create/upload`;
};

export const linkToCreateDataMappingEntityMapping = () => {
  return `/app/data-management/data-mapping/create/entity-mapping`;
};

export const linkToCreateDataMappingValidation = () => {
  return `/app/data-management/data-mapping/create/validation`;
};

export const linkToUploadDataMapping = (dataMappingId) => {
  return `/app/data-management/data-mapping/${dataMappingId}/upload/file-upload`;
};

export const linkToUploadDataMappingValidation = (dataMappingId) => {
  return `/app/data-management/data-mapping/${dataMappingId}/upload/validation`;
};

// Sample detail Links
export const linkToSampleDetail = (sampleId) => {
  return `/app/samples/${sampleId}/detail`;
};

// Inventory Links
export const linkToInventories = (inventoryId = "", tab = "") => {
  if (inventoryId) {
    if (tab) {
      return `/app/inventories/view/${inventoryId}/${tab}`;
    }

    return `/app/inventories/view/${inventoryId}`;
  }

  return `/app/inventories`;
};

// Lab-Result Links
export const linkToLabResults = (startDate = "", endDate = "") => {
  if (startDate && endDate) {
    const startDateISO = getTimeStringFromDate(startDate, config.DATE_FORMAT);
    const endDateISO = getTimeStringFromDate(endDate, config.DATE_FORMAT);

    return `/app/lab-results/${startDateISO}/${endDateISO}/view`;
  } else {
    return `/app/lab-results`;
  }
};

// Analysis Links
export const linkToAnalyze = () => {
  return `/app/analyze`;
};

// Chain of Custody Links
export const linkToChainOfCustody = () => {
  return `/app/chain-of-custody`;
};
export const linkToViewChainOfCustody = (cocId) => {
  return `/app/chain-of-custody/form/${cocId}/view`;
};
export const linkToCreateChainOfCustody = (cocId) => {
  if (cocId) {
    return `/app/chain-of-custody/create/${cocId}`;
  } else {
    return `/app/chain-of-custody/create`;
  }
};

// Import
export const linkToThirdPartyImports = () => {
  return `/app/imports`;
};

// Manual Entry
export const linkToManualEntry = () => {
  return `/app/manual-entry`;
};

export const linkToCreateManualEntry = (manualEntryType) => {
  return `/app/manual-entry/create/${manualEntryType}`;
};

// Record of Quality
export const linkToRecordOfQuality = () => {
  return `/app/record-of-quality`;
};

export const linkToProductionStreams = (streamId = "") => {
  if (streamId) {
    return `/app/production/streams/${streamId}`;
  }

  return `/app/production/streams`;
};

export const linkToEditProductionRecord = (streamId, recordId) => {
  if (streamId && recordId) {
    return `/app/production/streams/${streamId}/records/${recordId}/edit`;
  }

  return linkToProductionStreams();
};

// Record of Volume
export const linkToRecordOfVolume = () => {
  return `/app/record-of-volume`;
};

// Adjustment Factor
export const linkToAdjustmentFactors = () => {
  return `/app/adjustment-factors`;
};

// Balances
export const linkToBalances = (balanceId = "") => {
  if (balanceId) {
    return `/app/balances/analyze/${balanceId}`;
  }

  return `/app/balances`;
};

export const linkToCreateBalances = () => {
  return `/app/balances/create`;
};

export const linkToEditBalances = (balanceId) => {
  if (balanceId) {
    return `/app/balances/edit/${balanceId}`;
  }

  return linkToBalances();
};

// Production
export const linkToProductionReports = (id = "") => {
  if (id) {
    return `/app/production/reports/detail/${id}`;
  }

  return `/app/production/reports`;
};

export const linkToCreateProductionReports = () => {
  return `/app/production/reports/create`;
};

export const linkToEditProductionReports = (reportId) => {
  if (reportId) {
    return `/app/production/reports/edit/${reportId}`;
  }

  return linkToProductionReports();
};

// Emissions
export const linkToEmissionRecords = () => {
  return `/app/esg_emissions/records`;
};

export const linkToEmissionReports = (reportId = "") => {
  if (reportId) {
    return `/app/esg_emissions/reports/detail/${reportId}`;
  }

  return `/app/esg_emissions/reports`;
};

export const linkToCreateEmissionReports = (reportId) => {
  if (reportId) {
    return `/app/esg_emissions/reports/${reportId}/edit`;
  }

  return `/app/esg_emissions/reports/create`;
};

export const linkToEditEmissionReports = (reportId) => {
  if (reportId) {
    return `/app/esg_emissions/reports/detail/${reportId}/edit`;
  }

  return linkToEmissionReports();
};

// Account Settings
export const linkToUserSetting = (tab?: string) => {
  if (tab) {
    return `/app/settings/account/${tab}/view`;
  } else {
    return `/app/settings/account`;
  }
};

// Calculation apply default
export const linkToApplyDefaults = (year, month) => {
  if (year && month) {
    return `/app/emissions/calculations/apply-defaults/${year}/${month}`;
  }

  return `/app/emissions/calculations/apply-defaults/`;
};

// Company Settings
export const linkToCompanySettings = (tab = "") => {
  if (tab) {
    return `/app/settings/company/${tab}/view`;
  } else {
    return `/app/settings/company`;
  }
};

export const linkToCreateMember = () => {
  return `/app/settings/company/members/create`;
};

export const linkToEditMember = (userId) => {
  if (userId) {
    return `/app/settings/company/members/${userId}/edit`;
  }
};

export const linkToRoleNotificationSetting = (roleId) => {
  if (roleId) {
    return `/app/settings/company/notifications/roles/${roleId}/edit`;
  } else {
    return `/app/settings/company/notifications/view`;
  }
};

export const linkToEditRoleSetting = (roleId) => {
  if (roleId) {
    return `/app/settings/company/roles/${roleId}/edit`;
  } else {
    return `/app/settings/company/roles/view`;
  }
};

export const linkToCreateRoleSetting = () => {
  return `/app/settings/company/roles/create`;
};

// Annotations
export const linkToAnnotations = () => {
  return `/app/annotations`;
};

export const linkToAnnotate = (testId) => {
  return `/app/annotations/${testId}/annotate`;
};

//Plants
export const linkToPlantSamples = (id) => {
  if (id) {
    return `/app/plant/samples/${id}/detail`;
  }
  return `/app/plant/samples`;
};

export const linkToPlantTests = (id) => {
  if (id) {
    return `/app/plant/tests/${id}/detail`;
  }

  return `/app/plant/tests`;
};

export const linkToPlantManualEntries = () => {
  return `/app/plant/manual-entry`;
};

// Login
export const linkToLogin = ({ hash } = {}) => {
  if (hash) {
    return `/app/login#${hash}`;
  } else {
    return `/app/login`;
  }
};
