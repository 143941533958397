const breakpoints = require("./breakpoints-vars.json");

module.exports = {
  breakpoints,
  customProperties: {
    "--third-breakpoint": breakpoints.thirdBreakpoint,
    "--second-breakpoint": breakpoints.secondBreakpoint,
    "--first-breakpoint": breakpoints.firstBreakpoint,
    "--grid-columns": breakpoints.gridColumns,
    "--grid-margin-gutters": breakpoints.gridMarginGutters,
  },
};
