import React from "react";

import {
  DateSelectorInput,
  Dialog,
  Form,
  FormButton,
  useAlert,
  useForm,
} from "@validereinc/common-components";

import { useMutation } from "@tanstack/react-query";
import { RecordDomain } from "@validereinc/domain";
import { monthFormatter, yearMonthFormatter } from "@validereinc/utilities";
import endOfMonth from "date-fns/endOfMonth";
import startOfMonth from "date-fns/startOfMonth";

import type { AssetTypeType } from "@validereinc/domain";

export const AddRecordDialog = ({
  isOpen,
  onClose,
  onSubmit,
  assetId,
  assetType,
  reportingGroupId,
}: {
  isOpen: boolean;
  onClose?: () => void;
  onSubmit?: () => void;
  assetId?: string;
  assetType?: AssetTypeType;
  reportingGroupId?: string;
}) => {
  const { addAlert } = useAlert();

  const form = useForm();

  const addRecord = useMutation({
    mutationFn: async ({ yearMonth }: { yearMonth: { from: Date } }) => {
      try {
        if (!assetId || !assetType || !reportingGroupId) {
          throw new Error("Record identifiers unavailable");
        }
        await RecordDomain.create({
          reporting_group_id: reportingGroupId,
          year_month: yearMonthFormatter(yearMonth.from),
          asset_id: assetId,
          asset_type: assetType,
        });
        onClose?.();
        onSubmit?.();
        addAlert({
          variant: "success",
          message: `Added new record for ${monthFormatter(yearMonth.from)}`,
        });
      } catch (error) {
        console.error(error);
        addAlert({
          variant: "error",
          message: "Unable to add new month",
        });
      }
    },
  });

  return (
    <Form
      {...form}
      onSubmit={form.handleSubmit(addRecord.mutate)}
    >
      <Dialog
        key="add-record"
        title="Add Record"
        isOpen={isOpen}
        onClose={onClose}
        actionRow={[
          <FormButton
            key="save-record"
            variant="primary"
            onClick={form.handleSubmit(addRecord.mutate)}
          >
            Add
          </FormButton>,
        ]}
      >
        <DateSelectorInput
          name="yearMonth"
          variant="month"
          key="create-record-year-month"
          isRange={false}
          defaultValue={{
            from: startOfMonth(new Date()),
            to: endOfMonth(new Date()),
          }}
        />
      </Dialog>
    </Form>
  );
};
