import React from "react";

import { PeriodSelector } from "#src/batteries-included-components";
import { StorageKeys } from "@validereinc/common-components";
import classNames from "classnames/bind";
import { NetworkDetailsPanel } from "./NetworkDetailsPanel";
import styles from "./NetworkDetailsTab.module.scss";
import { NetworkEntityTablePanel } from "./NetworkEntityTablePanel";
import { NetworkFilterPanel } from "./NetworkFilterPanel";
import { NetworkMapPanel } from "./NetworkMapPanel";
import { NetworkStatusPanel } from "./NetworkStatusPanel";

const cx = classNames.bind(styles);

export const NetworkDetailsTab = ({
  filterConfigStorageKey,
}: Pick<StorageKeys, "filterConfigStorageKey">) => {
  return (
    <>
      <PeriodSelector filterKey={filterConfigStorageKey} />
      <div className={cx("container")}>
        <div className={cx("detailsContainer")}>
          <NetworkStatusPanel filterConfigStorageKey={filterConfigStorageKey} />

          <NetworkDetailsPanel
            filterConfigStorageKey={filterConfigStorageKey}
          />

          <NetworkMapPanel filterConfigStorageKey={filterConfigStorageKey} />
        </div>

        <div className={cx("entityTableContainer")}>
          <NetworkFilterPanel />

          <NetworkEntityTablePanel />
        </div>
      </div>
    </>
  );
};
