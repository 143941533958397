export const linkToUpdateFormSubmission = (
  formCategoryId = ":categoryId",
  formTemplateId = ":formTemplateId",
  formSubmissionId = ":formSubmissionId"
) =>
  `/app/forms/categories/${formCategoryId}/templates/${formTemplateId}/update-form-submission/${formSubmissionId}`;

export const UPDATE_FORM_SUBMISSION_BREADCRUMB = {
  title: "Create Form Submission from Draft",
  path: linkToUpdateFormSubmission,
};

export * from "./UpdateFormSubmissionPage";
