import {
  Button,
  FormInputWrapper,
  SelectInput,
  TextInput,
  Title,
} from "@validereinc/common-components";
import classNames from "classnames/bind";
import * as PropTypes from "prop-types";
import React from "react";
import styles from "./CreateEmissionReports.module.scss";
import EquipmentTable from "./EquipmentTable";
import useFilterInputs from "./EquipmentTableFilter";

const cx = classNames.bind(styles);

const EmissionReportsForm = ({
  form,
  streams,
  facilities,
  formState,
  openEquipmentModal,
  handleEquipmentDelete,
}) => {
  const equipment = form.watch("equipment") ?? [];

  const [filterRow, filterPillbox, filteredEquipments] =
    useFilterInputs(equipment);

  const sharedProps = {
    isDisabled: formState !== "enabled",
    showIcon: true,
  };

  return (
    <>
      <Title
        type="subheader"
        className={cx("formTitle")}
      >
        General
      </Title>

      <TextInput
        {...sharedProps}
        name="name"
        label="Name"
        isRequired
      />

      <Title
        type="subheader"
        className={cx("formTitle")}
      >
        Equipment
      </Title>

      <FormInputWrapper
        name="equipment"
        validate={{
          exist: (equipment) => {
            return !equipment?.length ? "Equipment is required." : null;
          },
        }}
        as={({ inputRef }) => (
          // react hook form uses ${element}.focus() to scroll to input with error
          // <div> do not have this function unless tabIndex prop is given
          <div
            tabIndex={-1}
            ref={inputRef}
          >
            <Button
              className={cx("button")}
              onClick={() => openEquipmentModal()}
              disabled={formState !== "enabled"}
            >
              {equipment?.length ? "Edit" : "Add"} Equipments
            </Button>

            <EquipmentTable
              equipmentList={filteredEquipments}
              onDeleteClick={handleEquipmentDelete}
              filterRow={filterRow}
              filterPillbox={filterPillbox}
            />
          </div>
        )}
      />

      <SelectInput
        {...sharedProps}
        name="facilities"
        label="Facilities"
        labelKey="name"
        options={facilities}
        isMulti
        ignoreCase
        closeMenuOnSelect={false}
      />

      <SelectInput
        {...sharedProps}
        name="streams"
        label="Sales Product Streams"
        labelKey="name"
        options={streams}
        isMulti
        ignoreCase
        closeMenuOnSelect={false}
      />
    </>
  );
};

EmissionReportsForm.propTypes = {
  form: PropTypes.object.isRequired,
  streams: PropTypes.array.isRequired,
  facilities: PropTypes.array.isRequired,
  formState: PropTypes.string.isRequired,
  openEquipmentModal: PropTypes.func.isRequired,
  handleEquipmentDelete: PropTypes.func.isRequired,
};

export default EmissionReportsForm;
