import NotesList from "#common/Notes/NotesList";
import ReactLoader from "#components/LoadingBar/ReactLoader";
import {
  TEST_NOTE,
  useFetchNotes,
  useSubmitNote,
} from "#services/NotesService";
import {
  Form,
  FormButton,
  Panel,
  TextWithFileInput,
  useForm,
} from "@validereinc/common-components";
import classNames from "classnames/bind";
import * as PropTypes from "prop-types";
import React, { useState } from "react";
import { AutoSizer } from "react-virtualized";
import styles from "./NotesPanel.module.scss";

const cx = classNames.bind(styles);

const TestNotes = ({ testId, refreshTest }) => {
  const [formState, setFormState] = useState("enabled");

  const form = useForm({
    defaultValues: {
      note: {},
    },
  });

  const [areNotesLoading, _errorStatus, notes, fetchNotes] = useFetchNotes(
    testId,
    TEST_NOTE
  );

  const [_isSubmittingNote, submitNote] = useSubmitNote(testId, TEST_NOTE);

  const onSubmitNote = (inputs) => {
    setFormState("loading");

    return submitNote(inputs.note)
      .then((result) => {
        fetchNotes();
        refreshTest();

        return result;
      })
      .finally(() => {
        form.reset();
        setFormState("enabled");
      });
  };

  return (
    <Panel title="Notes">
      <Form
        onSubmit={onSubmitNote}
        {...form}
      >
        <div>
          {notes?.length ? (
            <div className={cx("notesContainer")}>
              <AutoSizer disableWidth>
                {({ height }) => (
                  <ReactLoader
                    loaded={!areNotesLoading}
                    position={50}
                  >
                    <NotesList
                      list={notes}
                      height={height}
                    />
                  </ReactLoader>
                )}
              </AutoSizer>
            </div>
          ) : null}

          <TextWithFileInput
            name="note"
            label=""
            isRequired
            showIcon
            multiple
            isDisabled={formState !== "enabled"}
          />
        </div>

        <div className={cx("testNotesActionsContainer")}>
          <FormButton
            variant="primary"
            type="submit"
            isLoading={formState === "loading"}
            disabled={formState !== "enabled"}
          >
            Add Note
          </FormButton>
        </div>
      </Form>
    </Panel>
  );
};

TestNotes.propTypes = {
  testId: PropTypes.string.isRequired,
  refreshTest: PropTypes.func.isRequired,
};

export default TestNotes;
