import { useState, useMemo } from "react";
import useRoQSelection from "./useRoQSelection";
import useManageResultFetches from "./useManageResultFetches";
import useManageSelectionFetches from "./useManageSelectionFetches";
import useManageSubmission from "./useManageSubmission";
import { useFetchHistoricalData } from "#src/hooks/records/fetches";

function getAvailableStreams(streams, accountingRecordSelectedStream) {
  if (streams.length > 0) {
    streams = streams.sort((a, b) => {
      if (accountingRecordSelectedStream === a.id) {
        return -1;
      }
      if (accountingRecordSelectedStream === b.id) {
        return 1;
      }

      return a.name.localeCompare(b.name);
    });

    streams[0].name = `(Current) ${streams[0].name}`;

    return streams;
  }

  return [];
}

/**
 * The logic behind the modal has been split into four parts.
 *
 * useRoQSelection maintains the state of the current roqSelection and gives
 * out functions to change that state.
 *
 * useManageResultFetches fetches the non-interactable data (results) and
 * initializes the above roqSelectionState with contents of data fetched
 *
 * useManageSelectionFetches fetches the interactable data (selection data)
 * and changes roqSelectionState via two ways: the contents of data fetched and
 * user interaction (e.g. sample selection, method selection). There is local
 * state handling the fetch parameters in useManageSelectionFetchParams.
 *
 * useManageSubmission handles interaction with backend whenever the above
 * roqSelection requires a reset/save/approve action
 */
const useManageRecordSelection = ({
  accountingRecordDetail,
  updateRecordDetail,
  streams,
  addAlertMessage,
}) => {
  const [updateResultsData, setUpdateResultsData] = useState(new Date());

  const [historicalData] = useFetchHistoricalData(accountingRecordDetail?.id);

  const refetchResults = () => {
    setUpdateResultsData(new Date());
  };

  const [
    roqSelectionState,
    {
      setSelectionState,
      setStream,
      setMethod,
      toggleSample,
      updateIntervalSelections,
      deleteIntervalSelection,
      setPreselectedValues,
      setIsInitialized,
    },
  ] = useRoQSelection(accountingRecordDetail);

  const accountingRecordStreams = useMemo(() => {
    return getAvailableStreams(streams, accountingRecordDetail?.stream?.id);
  }, [streams, accountingRecordDetail?.id]);

  const [
    systemBalance,
    resultLoadingState,
    initialSystemBalanceResult,
    balances,
    isBalancesLoading,
    selectedBalance,
    onBalanceSelect,
  ] = useManageResultFetches(updateResultsData);

  const [
    sampleData,
    sampleDataLoadingState,
    selectionData,
    selectionDataLoadingState,
    onMeasurementSelect,
    onDateSelect,
    onStreamSelect,
    measurement,
    from,
    until,
    stream,
    resetDateRange,
    displayProperties,
  ] = useManageSelectionFetches(
    accountingRecordDetail,
    roqSelectionState,
    accountingRecordStreams,
    updateResultsData,
    setStream,
    setPreselectedValues
  );

  const [approveSelection, resetSelection, actionState, setActionState] =
    useManageSubmission(
      roqSelectionState,
      accountingRecordDetail,
      addAlertMessage,
      updateRecordDetail,
      refetchResults,
      setIsInitialized
    );

  return {
    roqSelectionState,
    setSelectionState,
    setStream,
    setMethod,
    toggleSample,
    updateIntervalSelections,
    deleteIntervalSelection,
    systemBalance,
    historicalData,
    resultLoadingState,
    initialSystemBalanceResult,
    sampleData,
    sampleDataLoadingState,
    selectionData,
    selectionDataLoadingState,
    onMeasurementSelect,
    onDateSelect,
    onStreamSelect,
    measurement,
    from,
    until,
    stream,
    resetDateRange,
    streams: accountingRecordStreams,
    displayProperties,
    accountingRecordDetail,
    hasWriteAccess: true,
    approveSelection,
    resetSelection,
    actionState,
    setActionState,
    updateRecordDetail,
    balances,
    isBalancesLoading,
    selectedBalance,
    onBalanceSelect,
  };
};

export default useManageRecordSelection;
