import { rest } from "msw";
import { MeasurementTypeAdapterType } from "../adapters";
import { DomainConfig } from "../config";
import {
  MeasurementQuantityType,
  MeasurementTypeType,
  MeasurementUnitType,
} from "../schemas";
import { BuildMockHandlersType, getApiUrlWithEndpoint } from "./handlers";

export const MOCK_MEASUREMENT_QUANTITIES = [
  {
    id: "volume",
    name: "Volume",
  },
  {
    id: "speed",
    name: "Speed",
  },
  {
    id: "molar_concentration",
    name: "Molar Concentration",
  },
  {
    id: "mass",
    name: "Mass",
  },
];

export const MOCK_MEASUREMENT_TYPES = [
  {
    id: "application_rate",
    name: "Application Rate",
    quantity: "speed",
    default_unit: "in_per_d",
    default_unit_info: {
      name: {
        singular: "Inch per Day",
        plural: "Inches per Day",
        symbol: "in/d",
      },
      to_anchor: 2640,
      anchor_shift: 0,
      quantity: "speed",
      system: "imperial",
    },
  },
  {
    id: "mol_c7_plus_composition",
    name: "C7+ Composition",
    quantity: "molar_concentration",
    default_unit: "mol_percent",
    default_unit_info: {
      name: {
        singular: "Percent",
        plural: "Percent",
        symbol: "mol%",
      },
      to_anchor: 0.0001,
      anchor_shift: 0,
      quantity: "molar_concentration",
      system: "metric",
    },
  },
  {
    id: "volume",
    name: "Volume",
    quantity: "volume",
    default_unit: "mm3",
    default_unit_info: {
      name: {
        singular: "Cubic Millimeter",
        plural: "Cubic Millimeters",
        symbol: "mm³",
      },
      to_anchor: 1e-9,
      anchor_shift: 0,
      quantity: "volume",
      system: "metric",
    },
  },
  {
    id: "mass_co",
    name: "Mass CO",
    quantity: "mass",
    default_unit: "g",
    default_unit_info: {
      name: {
        singular: "Gram",
        plural: "Grams",
        symbol: "g",
      },
      to_anchor: 1,
      anchor_shift: 0,
      quantity: "mass",
      system: "metric",
    },
  },
  {
    id: "mass_ch4",
    name: "Mass CH4",
    quantity: "mass",
    default_unit: "g",
    default_unit_info: {
      name: {
        singular: "Gram",
        plural: "Grams",
        symbol: "g",
      },
      to_anchor: 1,
      anchor_shift: 0,
      quantity: "mass",
      system: "metric",
    },
  },
  {
    id: "mass_co2",
    name: "Mass CO2",
    quantity: "mass",
    default_unit: "mt",
    default_unit_info: {
      name: {
        singular: "Metric Ton",
        plural: "Metric Tonnes",
        symbol: "tonnes",
      },
      to_anchor: 1000000,
      anchor_shift: 0,
      quantity: "mass",
      system: "metric",
    },
  },
  {
    id: "mass_n2o",
    name: "Mass N2O",
    quantity: "mass",
    default_unit: "g",
    default_unit_info: {
      name: {
        singular: "Gram",
        plural: "Grams",
        symbol: "g",
      },
      to_anchor: 1,
      anchor_shift: 0,
      quantity: "mass",
      system: "metric",
    },
  },
  {
    id: "mass_vocs",
    name: "Mass VOCs",
    quantity: "mass",
    default_unit: "g",
    default_unit_info: {
      name: {
        singular: "Gram",
        plural: "Grams",
        symbol: "g",
      },
      to_anchor: 1,
      anchor_shift: 0,
      quantity: "mass",
      system: "metric",
    },
  },
  {
    id: "mass_co2eq",
    name: "Mass CO2eq",
    quantity: "mass",
    default_unit: "lb",
    default_unit_info: {
      name: {
        singular: "Pound",
        plural: "Pounds",
        symbol: "lb",
      },
      to_anchor: 1,
      anchor_shift: 0,
      quantity: "mass",
      system: "imperial",
    },
  },
];

export const MOCK_MEASUREMENT_UNITS = [
  {
    id: "m3",
    quantity: "volume",
    name: {
      singular: "Cubic Meter",
      plural: "Cubic Meters",
      symbol: "m³",
    },
    to_anchor: 1,
    anchor_shift: 0,
  },
  {
    id: "in_per_d",
    name: {
      singular: "Inch per Day",
      plural: "Inches per Day",
      symbol: "in/d",
    },
    to_anchor: 2640,
    anchor_shift: 0,
    quantity: "speed",
    system: "imperial",
  },
  {
    id: "mol_percent",
    quantity: "molar_concentration",
    name: {
      singular: "Percent",
      plural: "Percent",
      symbol: "mol%",
    },
    to_anchor: 0.0001,
    anchor_shift: 0,
  },
  {
    id: "g",
    quantity: "mass",
    name: {
      singular: "Gram",
      plural: "Grams",
      symbol: "g",
    },
    to_anchor: 1,
    anchor_shift: 0,
  },
  {
    id: "mt",
    quantity: "mass",
    name: {
      singular: "Metric Ton",
      plural: "Metric Tonnes",
      symbol: "tonnes",
    },
    to_anchor: 1000000,
    anchor_shift: 0,
  },
];

export const buildMeasurementTypeHandlers: BuildMockHandlersType<
  MeasurementTypeAdapterType
> = (configData: DomainConfig) => {
  const { nodeURL: url } = configData;

  return {
    listMeasurementQuantities: rest.get<
      never,
      Record<string, string>,
      MeasurementQuantityType[]
    >(
      getApiUrlWithEndpoint({
        url,
        version: 1,
        endpoint: "/measurement_quantities",
      }),
      (_, res, ctx) => {
        return res(ctx.status(200), ctx.json(MOCK_MEASUREMENT_QUANTITIES));
      }
    ),
    listMeasurementTypes: rest.get<
      never,
      Record<string, string>,
      MeasurementTypeType[]
    >(
      getApiUrlWithEndpoint({
        url,
        version: 2,
        endpoint: "/measurement_types",
      }),
      (_, res, ctx) => {
        return res(ctx.status(200), ctx.json(MOCK_MEASUREMENT_TYPES));
      }
    ),
    listMeasurementUnits: rest.get<
      never,
      Record<string, string>,
      MeasurementUnitType[]
    >(
      getApiUrlWithEndpoint({
        url,
        version: 1,
        endpoint: "/measurement_quantities",
      }),
      (_, res, ctx) => {
        return res(ctx.status(200), ctx.json(MOCK_MEASUREMENT_UNITS));
      }
    ),
  };
};
