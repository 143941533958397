import {
  CustomReportVariantType,
  CustomReportVariants,
  getConfigFromSavedFilter,
  useSavedCustomReport,
} from "#batteries-included-components/Layouts/CustomReport/CustomReportDetailLayout.helpers";
import { useCalculatorFilters } from "#hooks/filters/useCalculatorFilters";
import { useEquipmentCalculationFilters } from "#hooks/filters/useEquipmentFilters";
import {
  FLOW_FILTERS,
  useFlowCalculationFilters,
} from "#hooks/filters/useFlowFilters";
import { useParams } from "#routers/hooks";
import { CustomReportDetailParamType } from "#routes/reports/custom-reports/[reportVariant]/[reportId]/detail";
import { FilterPanel, StorageKeys } from "@validereinc/common-components";
import { EquipmentStatus, FlowStatus } from "@validereinc/domain";
import React from "react";

export const CustomReportDetailFilterPanel = ({
  filterConfigStorageKey,
  variant,
}: Pick<StorageKeys, "filterConfigStorageKey"> & {
  variant: CustomReportVariantType;
}) => {
  const { reportId } = useParams<CustomReportDetailParamType>();
  const savedFilter = useSavedCustomReport(variant, reportId);

  const calculatorFilters = useCalculatorFilters();
  const equipmentFilters = useEquipmentCalculationFilters();
  const flowFilters = useFlowCalculationFilters();

  let filterConfiguration;
  let defaultActiveKeys;
  let defaultFilterValues;

  switch (variant) {
    case CustomReportVariants.EMISSIONS:
      filterConfiguration = [
        ...equipmentFilters.filters,
        ...calculatorFilters.filters,
      ];
      defaultActiveKeys = [
        ...equipmentFilters.filterKeys,
        ...calculatorFilters.filterKeys,
      ];
      defaultFilterValues = {
        equipment_status: [EquipmentStatus.ACTIVE],
      };
      break;
    case CustomReportVariants.VOLUMETRIC:
      filterConfiguration = flowFilters.filters;
      defaultActiveKeys = flowFilters.filterKeys;
      defaultFilterValues = {
        [FLOW_FILTERS.flowStatus.name]: [FlowStatus.ACTIVE],
      };
      break;
  }

  const defaultValue = savedFilter
    ? getConfigFromSavedFilter(variant, savedFilter).filter
    : defaultFilterValues;

  return (
    <FilterPanel
      defaultActiveKeys={defaultActiveKeys}
      defaultValue={defaultValue}
      storageKey={filterConfigStorageKey}
      filters={filterConfiguration ?? []}
    />
  );
};
