import { useNavigate, useSearchParams } from "#src/Routers/hooks";
import { AddFormSubmission } from "#src/batteries-included-components/Layouts/Form/Submission/Add/AddFormSubmission";
import {
  AddFormSubmissionContextProvider,
  useAddFormSubmissionContext,
} from "#src/batteries-included-components/Layouts/Form/Submission/Add/AddFormSubmission/AddFormSubmissionContext";
import { RoutingLink } from "#src/batteries-included-components/RoutingLink";
import { useStorageKey } from "#src/hooks/useStorageKey";
import { WorkflowDetailsRoutePath } from "#src/routes/workflows/all/[workflowId]";
import { WorkflowTaskAddFormSubmissionRoutePath } from "#src/routes/workflows/all/[workflowId]/detail/task/[taskId]/form-submission/add";
import { useBreadcrumbsFromRoute } from "#src/utils/route";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Banner, Button, Page, useAlert } from "@validereinc/common-components";
import {
  FormSubmissionType,
  WorkflowAdapter,
  WorkflowTaskAdapter,
} from "@validereinc/domain";
import classNames from "classnames/bind";
import React, { useState } from "react";
import { useHistory, useParams } from "react-router";
import styles from "./WorkflowTaskAddFormSubmissionPage.module.scss";

const cx = classNames.bind(styles);

export const WorkflowTaskAddFormSubmissionPageContent = () => {
  const queryClient = useQueryClient();
  const { selectedFormSubmissions } = useAddFormSubmissionContext();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const history = useHistory();
  const navigate = useNavigate();
  const { addAlert } = useAlert();
  const { workflowId, taskId } = useParams<{
    workflowId: string;
    taskId: string;
  }>();
  const { data: workflowDetails } = useQuery(
    ["workflows", workflowId],
    () => WorkflowAdapter.getOne({ id: workflowId }),
    {
      enabled: Boolean(workflowId),
    }
  );
  const { data: workflowTaskDetails } = useQuery(
    ["workflows", "tasks", taskId],
    () => WorkflowTaskAdapter.getOne({ id: taskId }),
    {
      enabled: Boolean(taskId),
    }
  );
  const { mutate: associateFormSubmissionsToWorkflowTask } = useMutation({
    mutationFn: async (formSubmissions: FormSubmissionType[]) => {
      setIsSubmitting(true);

      try {
        await Promise.all(
          formSubmissions.map(({ id }) =>
            WorkflowTaskAdapter.formSubmission.updateOne({
              id,
              data: undefined,
              meta: {
                taskId,
              },
            })
          )
        );

        queryClient.invalidateQueries({ queryKey: ["workflows"] });
        setTimeout(
          () =>
            queryClient.refetchQueries({
              queryKey: ["workflows"],
            }),
          4000
        );

        addAlert?.({
          variant: "success",
          message: `Successfully added ${selectedFormSubmissions.length} form submission(s) to workflow task`,
        });

        navigate(
          WorkflowDetailsRoutePath.toLinkParts({
            pathParams: {
              workflowId,
            },
          })
        );
      } catch (err) {
        addAlert?.({
          variant: "error",
          message:
            "Couldn't add selected form submissions to the workflow task",
        });
        setIsSubmitting(false);
      }
    },
  });

  const [breadcrumbs] = useBreadcrumbsFromRoute(
    WorkflowTaskAddFormSubmissionRoutePath,
    {
      "/detail": {
        title: workflowDetails?.data.workflow_template.name,
      },
      ":taskId": {
        title: workflowTaskDetails?.data.name,
      },
    }
  );

  const handleSubmit = async () => {
    associateFormSubmissionsToWorkflowTask(selectedFormSubmissions);
  };

  return (
    <Page
      title={WorkflowTaskAddFormSubmissionRoutePath.title}
      category={WorkflowTaskAddFormSubmissionRoutePath.previous?.title}
      breadcrumbs={breadcrumbs}
      footer={
        <>
          <Button
            onClick={() => {
              history.goBack();
            }}
            disabled={isSubmitting}
          >
            Cancel
          </Button>

          <div style={{ display: "flex", flexDirection: "row", gap: 16 }}>
            <Button
              variant="primary"
              onClick={handleSubmit}
              disabled={isSubmitting || !selectedFormSubmissions.length}
            >
              Add to Workflow Task
            </Button>
          </div>
        </>
      }
    >
      {workflowDetails?.data && workflowTaskDetails?.data ? (
        <Banner
          titleText="This form has some associated automation."
          descriptionText={`Selected form submissions here will be attached to the "${workflowTaskDetails?.data.name}" task in workflow "${workflowDetails.data?.workflow_template.name}".`}
          color="info"
          actionContent={
            <RoutingLink
              to={WorkflowDetailsRoutePath.toLink({
                pathParams: { workflowId: workflowDetails.data?.id },
              })}
            >
              View Workflow
            </RoutingLink>
          }
          className={cx("banner")}
        />
      ) : null}
      <AddFormSubmission
        selectedFormSubmissionsPanelProps={{
          emptyStateProps: {
            suggestion: "Select form submissions to add to workflow task.",
          },
        }}
      />
    </Page>
  );
};

export const WorkflowTaskAddFormSubmissionPage = () => {
  const [{ formSchemaId }] = useSearchParams<{ formSchemaId: string }>();
  const storageKeys = useStorageKey("workflows-form-submissions-add");

  return (
    <AddFormSubmissionContextProvider
      {...storageKeys}
      filterPanelDefaultValue={{
        "form_schema.id": formSchemaId,
      }}
    >
      <WorkflowTaskAddFormSubmissionPageContent />
    </AddFormSubmissionContextProvider>
  );
};
