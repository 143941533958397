import { useFormCategories } from "#hooks/useForms";
import {
  DropdownInput,
  FilterPanel,
  TextInput,
} from "@validereinc/common-components";
import React from "react";

import type { StorageKeys } from "@validereinc/common-components";

export type FormTemplatesFilters = {
  formCategory?: string;
  name?: string;
};

export const FormSchemaFilterPanel = ({
  filterConfigStorageKey,
  withCategoryField = true,
}: {
  withCategoryField?: boolean;
} & Pick<StorageKeys, "filterConfigStorageKey">) => {
  const [formCategories] = useFormCategories();

  return (
    <FilterPanel
      storageKey={filterConfigStorageKey}
      filters={[
        {
          component: (
            <TextInput
              name="name"
              key="form-schema-name-filter"
              placeholder="Search Templates..."
              type="search"
              autocomplete="on"
              isInline
            />
          ),
        },
        ...(withCategoryField
          ? [
              {
                component: (
                  <DropdownInput
                    name="form_category.id"
                    options={formCategories}
                    placeholder="Search Categories..."
                    labelKey="name"
                    valueKey="id"
                    isInline
                    isSearchable
                    isOptionalTextShown={false}
                    key="form_category.id"
                  />
                ),
              },
            ]
          : []),
      ]}
    />
  );
};
