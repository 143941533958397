import { useNavigate } from "#routers/hooks";
import { linkToCreateFormSubmission } from "#src/routes/forms/categories/[categoryId]/templates/[formTemplateId]/create-form-submission";
import { Flex, GridCard, Panel } from "@validereinc/common-components";
import type {
  EventAssociatedFormSchemaType,
  FormSubmissionType,
} from "@validereinc/domain";
import React from "react";

const { Container, Item } = Flex;

export const PredefinedEventFormTemplatesPanel = ({
  eventId,
  formTemplates,
  formSubmissions,
}: {
  eventId: string;
  formTemplates: EventAssociatedFormSchemaType[];
  formSubmissions?: FormSubmissionType[];
}) => {
  const navigate = useNavigate();

  const onClickCard = ({
    formSchemaId,
    formCategoryId,
  }: {
    formSchemaId: string;
    formCategoryId: string;
  }) => {
    navigate({
      pathname: linkToCreateFormSubmission(formCategoryId, formSchemaId),
      query: { "event-id": eventId },
    });
  };

  return (
    <Panel title="Predefined Form Templates">
      <Container>
        {formTemplates.map(
          ({
            id,
            name,
            description,
            form_category: { id: formCategoryId, name: formCategoryName },
            required,
          }) => {
            const hasSubmissions = formSubmissions?.some(
              ({ form_schema_id: formSchemaId }) => formSchemaId === id
            );

            return (
              <Item key={`flex-item-${id}`}>
                <GridCard
                  title={name}
                  subtitle={formCategoryName}
                  description={description}
                  onClick={() =>
                    onClickCard({ formSchemaId: id, formCategoryId })
                  }
                  status={hasSubmissions ? "success" : "warning"}
                  pills={[
                    ...(hasSubmissions
                      ? [
                          {
                            children: "Submitted",
                            variant: "success",
                            hasBackgroundColor: false,
                          },
                        ]
                      : [
                          {
                            children: "Not Submitted",
                            variant: "warning",
                            hasBackgroundColor: false,
                          },
                        ]),
                    ...(required
                      ? [{ children: "Required", hasBackgroundColor: false }]
                      : []),
                  ]}
                />
              </Item>
            );
          }
        )}
      </Container>
    </Panel>
  );
};
