export const permissionStringToList = (string) => {
  switch (string) {
    case "read_and_write":
      return ["read", "write"];
    case "read":
      return ["read"];
    default:
      return [];
  }
};

export const permissionListToString = (list) => {
  if (list.includes("write")) {
    return "read_and_write";
  } else if (list.includes("read")) {
    return "read";
  } else {
    return "disabled";
  }
};
