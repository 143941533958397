import { getBreadcrumbsObject } from "#src/Routers/breadcrumbsHelper";
import { Page, Panel } from "@validereinc/common-components";
import * as PropTypes from "prop-types";
import React from "react";
import { getManualEntryType } from "../../ManualEntryHelper";
import InlineManualEntry from "./InlineManualEntry";

const InlineManualEntryPage = (props) => {
  const breadcrumbs = getBreadcrumbsObject(
    props?.breadcrumbs,
    props?.match?.params
  );

  return (
    <Page
      title={getManualEntryType("inline")?.title}
      breadcrumbs={breadcrumbs}
    >
      <Panel>
        <InlineManualEntry view="page" />
      </Panel>
    </Page>
  );
};

InlineManualEntryPage.propTypes = {
  breadcrumbs: PropTypes.array,
  match: PropTypes.object,
  view: PropTypes.string,
};

export default InlineManualEntryPage;
