import {
  CreateEstimationMethodForm,
  useCreateEstimationMethodForm,
} from "#batteries-included-components/Layouts/EstimationMethod/Create";
import { useEquipmentDetail } from "#components/hooks/useEquipment";
import { useBreadcrumbs } from "#routers/breadcrumbsHelper";
import { useNavigate, useParams } from "#routers/hooks";
import { linkToEquipmentDetail } from "#routers/links";
import { AccessDeniedLayout } from "#src/batteries-included-components/Layouts/Authorization/AccessDenied";
import { IsFeatureAvailable } from "#src/batteries-included-components/Layouts/Authorization/IsFeatureAvailable";
import { ORGANIZATION_BREADCRUMB } from "#src/routes/organization";
import { EQUIPMENT_LIST_BREADCRUMB } from "#src/routes/organization/equipment";
import { EQUIPMENT_DETAIL_BREADCRUMB } from "#src/routes/organization/equipment/[equipmentId]";
import { ESTIMATION_METHOD_LIST_BREADCRUMB } from "#src/routes/organization/equipment/[equipmentId]/estimation-method";
import { ESTIMATION_METHOD_CREATE_BREADCRUMB } from "#src/routes/organization/equipment/[equipmentId]/estimation-method/create";
import { Page } from "@validereinc/common-components";
import { AssetType } from "@validereinc/domain";
import React from "react";

const CreateEstimationMethod = () => {
  // state, effects, and hooks
  const { equipmentId } = useParams<{
    equipmentId: string;
  }>();
  const navigate = useNavigate();
  const [equipmentDetail, equipmentDetailFetchState] =
    useEquipmentDetail(equipmentId);
  const { footer, formProps } = useCreateEstimationMethodForm({
    entityId: equipmentId,
    entityType: AssetType.EQUIPMENT,
    mutationType: "create",
    onSubmit: () => {
      navigate({
        pathname: linkToEquipmentDetail(equipmentId),
        query: {
          tab: "estimationMethods",
        },
      });
    },
    onCancel: () => {
      navigate({
        pathname: linkToEquipmentDetail(equipmentId),
        query: {
          tab: "estimationMethods",
        },
        replace: true,
      });
    },
  });

  // computed
  const breadcrumbs = useBreadcrumbs(
    [
      ORGANIZATION_BREADCRUMB,
      EQUIPMENT_LIST_BREADCRUMB,
      EQUIPMENT_DETAIL_BREADCRUMB,
      ESTIMATION_METHOD_LIST_BREADCRUMB,
      ESTIMATION_METHOD_CREATE_BREADCRUMB,
    ],
    { 2: equipmentDetail?.name }
  );

  return (
    <IsFeatureAvailable
      fallbackChildren={<AccessDeniedLayout />}
      featureFlagQuery="core:estimation_methods"
      permissionQuery="calculator_results:read"
    >
      <Page
        title="Add Estimation Method"
        breadcrumbs={breadcrumbs}
        footer={footer}
        isLoading={equipmentDetailFetchState === "loading"}
      >
        <CreateEstimationMethodForm formProps={formProps} />
      </Page>
    </IsFeatureAvailable>
  );
};

export default CreateEstimationMethod;
