import { Device, DeviceType } from "@validereinc/domain";
import { useEffect, useState } from "react";

const useDevices = () => {
  const [devices, setDevices] = useState<DeviceType[]>([]);
  const [fetchState, setFetchState] = useState("loading");

  useEffect(() => {
    Device.getList({
      page: 1,
      pageSize: 1000,
      sortBy: "name",
      sortDirection: "asc",
    }).then((data) => {
      setDevices(data.data);
      setFetchState("loaded");
    });
  }, []);

  return [devices, fetchState];
};

export default useDevices;
