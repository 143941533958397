import {
  Button,
  Form,
  FormButton,
  Modal,
  Title,
  useAlert,
  useForm,
} from "@validereinc/common-components";
import moment from "moment";
import * as PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import SampleService from "../Services/SampleService";
import SubmitAnalysisForm from "./SubmitAnalysisForm";
import "./SubmitAnalysisModal.scss";

const DEFAULT_INPUTS = {
  state: "pending",
  enable_applicable_period: false,
};

const SubmitAnalysisModal = ({
  selectedSample,
  show,
  onHide,
  updatedSelectedSample,
}) => {
  const [formState, setFormState] = useState("enabled");
  const { addAlert } = useAlert();

  const form = useForm({
    defaultValues: DEFAULT_INPUTS,
  });

  const onSubmitYourAnalysisClick = (formState) => {
    setFormState("loading");

    SampleService.updateSampleState(selectedSample.id, formState)
      .then(() => {
        addAlert({
          variant: "success",
          message: "Sample state has been successfully updated.",
        });

        onHide();
        updatedSelectedSample();
      })
      .finally(() => {
        setFormState("enabled");
      });
  };

  useEffect(() => {
    if (show && selectedSample) {
      const { state, applicable_from, applicable_until } = selectedSample;

      const from = applicable_from
        ? moment(applicable_from)
        : moment().startOf("day");

      const until = applicable_until
        ? moment(applicable_until)
        : moment().endOf("day");

      form.reset({
        state: state,
        enable_applicable_period: applicable_from ? "yes" : "no",
        from: from.toDate(),
        until: until.toDate(),
      });
    }
  }, [show]);

  return (
    <Modal
      className="submitAnalysisModal"
      open={show}
      onClose={onHide}
    >
      <Form
        onSubmit={onSubmitYourAnalysisClick}
        {...form}
      >
        <Modal.Header>
          <Title>{`${selectedSample?.stream?.name ?? "-"} Analysis`}</Title>
        </Modal.Header>

        <Modal.Body>
          <SubmitAnalysisForm
            form={form}
            formState={formState}
          />
        </Modal.Body>

        <Modal.Footer className="clearfix">
          <Button
            className="pull-left"
            onClick={onHide}
          >
            Cancel
          </Button>

          <FormButton
            variant="primary"
            type="submit"
            className="pull-right"
            isLoading={formState === "loading"}
            disabled={formState === "disabled"}
          >
            Submit Validation Status
          </FormButton>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

SubmitAnalysisModal.propTypes = {
  selectedSample: PropTypes.object.isRequired,
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  updatedSelectedSample: PropTypes.func.isRequired,
};

export default SubmitAnalysisModal;
