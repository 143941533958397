export const getThresholdObject = (
  axis,
  value,
  chartAxisLabel,
  thresholdLabel
) => {
  // e.g. Methane Concentration (ppm) => Methane Concentration
  const labelWithoutUnit = chartAxisLabel?.replace(/\s*\(.*?\)\s*/g, "");

  return {
    axis,
    value,
    label: `${labelWithoutUnit} ${thresholdLabel}`,
  };
};
