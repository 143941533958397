import { WorkflowCategoryType } from "../schemas";
import {
  GetListRequestType,
  GetListResponseType,
  ResourceServiceType,
} from "../util";
import { restAPI } from "./api";

export const WorkflowCategoriesAdapter: WorkflowCategoriesAdapterType = {
  /**
   * Get the complete list of Workflow Categories
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/workflow_categories/list_workflow_category
   */
  getList: async ({ filters, page, pageSize, sortBy, sortDirection }) => {
    return restAPI.nodeAPI.GET<GetListResponseType<WorkflowCategoryType>>({
      endpoint: "/workflow_categories",
      query: {
        ...(filters?.name ? { name: filters.name } : {}),
        ...(filters?.description ? { description: filters.description } : {}),
        page,
        page_size: pageSize,
        sortBy,
        sortDirection,
      },
    });
  },

  /**
   * Get the detail info Category Templates
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/workflow_categories/get_workflow_category
   */
  getOne: async ({ id }) => {
    const d = await restAPI.nodeAPI.GET<WorkflowCategoryType>({
      endpoint: `/workflow_categories/${id}`,
    });

    return {
      data: d,
    };
  },
};

export interface WorkflowCategoriesAdapterType
  extends Pick<
    ResourceServiceType<WorkflowCategoryType>,
    "getList" | "getOne"
  > {
  getList: (
    params: GetListRequestType<
      Partial<Pick<WorkflowCategoryType, "name" | "description">>
    >
  ) => Promise<GetListResponseType<WorkflowCategoryType>>;
}
