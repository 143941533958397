import { AssetGroupAssetType, AssetType, FlowType } from "@validereinc/domain";

export const selectedAssetsToFlowFields = (
  selected: AssetGroupAssetType[],
  isUpstream?: boolean
) => {
  const types = selected.reduce<string[]>((total, asset) => {
    if (!total.includes(asset.asset_type)) {
      total.push(asset.asset_type);
    }
    return total;
  }, []);

  if (types.length > 1) {
    throw new Error(
      "Flow has an invalid number of upstream or downstream assets"
    );
  }

  const keys = selected.map(({ id }) => id);
  switch (types[0]) {
    case AssetType.FACILITY:
      return isUpstream
        ? {
            upstream_facility_id: keys[0],
          }
        : {
            downstream_facility_id: keys[0],
          };
    case AssetType.EQUIPMENT:
      return isUpstream
        ? {
            upstream_equipment_id: keys[0],
          }
        : {
            downstream_equipment_id: keys[0],
          };
    case AssetType.FLOW:
      return isUpstream
        ? {
            upstream_flow_ids: keys,
          }
        : {
            downstream_flow_ids: keys,
          };
    default:
      return {};
  }
};

export const getUpstreamAssetsFromFlow = (flow: FlowType) =>
  flow?.upstream_flows?.length
    ? flow?.upstream_flows.map((upstreamFlow) => ({
        ...upstreamFlow,
        asset_type: AssetType.FLOW,
      }))
    : flow?.upstream_facility
      ? [{ ...flow?.upstream_facility, asset_type: AssetType.FACILITY }]
      : flow?.upstream_equipment
        ? [{ ...flow?.upstream_equipment, asset_type: AssetType.EQUIPMENT }]
        : [];

export const getDownstreamAssetsFromFlow = (flow: FlowType) =>
  flow?.downstream_flows?.length
    ? flow?.downstream_flows.map((downstreamFlow) => ({
        ...downstreamFlow,
        asset_type: AssetType.FLOW,
      }))
    : flow?.downstream_facility
      ? [{ ...flow?.downstream_facility, asset_type: AssetType.FACILITY }]
      : flow?.downstream_equipment
        ? [{ ...flow?.downstream_equipment, asset_type: AssetType.EQUIPMENT }]
        : [];
