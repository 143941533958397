import DropdownList from "#common/Form/DropdownList";
import { ValidateCSVSafeInput } from "#utils/formValidator";
import { FormInputWrapper, TextInput } from "@validereinc/common-components";
import PropTypes from "prop-types";
import React from "react";
import {
  PERMISSION_OPTIONS,
  stripProductFromPermission,
} from "../../Permissions/Permissions";
import {
  permissionListToString,
  permissionStringToList,
} from "./permissionUtils";
import { validateRoleExist } from "./RoleHelper";

const PermissionDropdownInput = ({
  onPermissionChange,
  permissions,
  isDisabled,
}) => {
  const onSelect = (activity, permissionString) => {
    const permissionsList = permissionStringToList(permissionString);

    onPermissionChange(activity, permissionsList);
  };

  const list = Object.entries(permissions).reduce((prev, curr) => {
    const [activity, permissionList] = curr;

    return {
      ...prev,
      [activity]: permissionListToString(permissionList),
    };
  }, {});

  return (
    <DropdownList
      onSelect={onSelect}
      options={PERMISSION_OPTIONS}
      nameFormatter={stripProductFromPermission}
      list={list}
      disabled={isDisabled}
    />
  );
};

const RoleForm = ({
  roleDetail,
  form,
  formState,
  currentRoles,
  onPermissionChange,
}) => {
  const sharedProp = {
    isDisabled: formState !== "enabled",
    isRequired: true,
  };

  return (
    <>
      <TextInput
        {...sharedProp}
        name="name"
        label="Name"
        type="text"
        placeholder="Name"
        showIcon
        validate={{
          doesNameExist: (name) =>
            validateRoleExist(currentRoles, name, roleDetail?.name) ||
            ValidateCSVSafeInput("Name", name),
        }}
      />

      <FormInputWrapper
        {...sharedProp}
        name="permissions"
        label="Permissions"
        as={({ isDisabled }) => (
          <PermissionDropdownInput
            isDisabled={isDisabled}
            onPermissionChange={onPermissionChange}
            permissions={form.getValues("permissions")}
          />
        )}
      />
    </>
  );
};

PermissionDropdownInput.propTypes = {
  onPermissionChange: PropTypes.func.isRequired,
  permissions: PropTypes.object.isRequired,
  isDisabled: PropTypes.bool.isRequired,
};

RoleForm.propTypes = {
  roleDetail: PropTypes.object,
  form: PropTypes.object.isRequired,
  formState: PropTypes.string.isRequired,
  currentRoles: PropTypes.array,
  onPermissionChange: PropTypes.func.isRequired,
};

export default RoleForm;
