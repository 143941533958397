import {
  CUBIC_METERS_VOLUME,
  getFormattedMeasurementValueWithUnit,
} from "#redux/reducers/measurements";
import { Title, Tooltip } from "@validereinc/common-components";
import * as PropTypes from "prop-types";
import React, { useState } from "react";
import { Button, ButtonGroup } from "react-bootstrap";
import FontAwesome from "react-fontawesome";
import { connect } from "react-redux";
import { AutoSizer } from "react-virtualized";
import {
  ChartLabel,
  Hint,
  HorizontalGridLines,
  VerticalBarSeries,
  XAxis,
  XYPlot,
  YAxis,
} from "react-vis";
import "./BalanceSummaryChart.scss";
import {
  getPercentDifferencePlotPoint,
  getXAxisTicks,
} from "./BalanceSummaryChartHelper";

const PERCENT_DIFFERENCE_VIEW_KEY = "percent_differences";
const DIFFERENCE_VIEW_KEY = "differences";

const mapStateToProps = (state) => {
  return {
    getFormattedMeasurementValueWithUnit: (measurementKey, measurementObject) =>
      getFormattedMeasurementValueWithUnit(state.measurements)(
        measurementKey,
        measurementObject
      ),
  };
};

const CalculationTooltip = (
  <>
    <div>
      <b>Difference Calculation: </b>
      Outlets - Inlets
    </div>
    <div>
      <b>Percent Difference Calculation: </b>
      Differences / References
    </div>
  </>
);
const BalanceSummaryChart = (props) => {
  const {
    balanceSummary,
    balanceType,
    showTotalVolume,
    haveReferenceSources,
    getFormattedMeasurementValueWithUnit,
  } = props;

  const [view, setView] = useState(DIFFERENCE_VIEW_KEY);
  const [hintData, setHintData] = useState(null);

  const plotData = getPercentDifferencePlotPoint(
    balanceSummary,
    view,
    balanceType,
    showTotalVolume
  );

  const chartLabelUnit = `(${balanceSummary?.total?.[view]?.unit})` ?? "(-)";

  const onViewClick = (view) => {
    setView(view);
  };

  const hintFormat = (data) => {
    return [
      {
        title: "Property",
        value: data.x,
      },
      {
        title: "Value",
        value: getFormattedMeasurementValueWithUnit(
          CUBIC_METERS_VOLUME,
          data.label
        ),
      },
    ];
  };

  return (
    <div className="balanceSummaryChartContainer">
      <div className="balanceSummaryChartHeader">
        <Title
          type="subheader"
          className="balanceSummaryChartHeader__title"
        >
          <Tooltip content={CalculationTooltip}>
            Summary
            <FontAwesome
              name="info-circle"
              style={{ marginLeft: "3px" }}
            />
          </Tooltip>
        </Title>

        <div className="balanceSummaryChartHeader__toggle">
          <ButtonGroup>
            {haveReferenceSources ? (
              <Button
                onClick={() => onViewClick(PERCENT_DIFFERENCE_VIEW_KEY)}
                className={
                  view === PERCENT_DIFFERENCE_VIEW_KEY ? "active" : null
                }
              >
                % Diff.
              </Button>
            ) : null}

            <Button
              onClick={() => onViewClick(DIFFERENCE_VIEW_KEY)}
              className={view === DIFFERENCE_VIEW_KEY ? "active" : null}
            >
              Diff.
            </Button>
          </ButtonGroup>
        </div>
      </div>

      <div className="balanceSummaryChart">
        <AutoSizer>
          {({ width, height }) => (
            <XYPlot
              width={width}
              height={height}
              xType="ordinal"
              colorType="literal"
              margin={{ left: 100 }}
            >
              <HorizontalGridLines />

              <VerticalBarSeries
                data={plotData}
                barWidth={0.2}
                onValueMouseOver={(value) => setHintData(value)}
                onValueMouseOut={() => setHintData(null)}
              />

              <XAxis tickFormat={(tick) => getXAxisTicks(tick)} />

              <YAxis />

              <ChartLabel
                text={`Difference ${chartLabelUnit}`}
                xPercent={0.02}
                yPercent={0.25}
                style={{ transform: "rotate(-90)" }}
              />

              {hintData && (
                <Hint
                  value={hintData}
                  format={hintFormat}
                  style={{
                    width: "200px",
                  }}
                />
              )}
            </XYPlot>
          )}
        </AutoSizer>
      </div>
    </div>
  );
};

BalanceSummaryChart.propTypes = {
  balanceSummary: PropTypes.object.isRequired,
  haveReferenceSources: PropTypes.bool,
  balanceType: PropTypes.string,
  showTotalVolume: PropTypes.bool,
  getFormattedMeasurementValueWithUnit: PropTypes.func,
};

export default connect(mapStateToProps)(BalanceSummaryChart);
