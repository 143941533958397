import { useBreadcrumbs } from "#routers/breadcrumbsHelper";
import { EmissionsRecordDetail } from "#src/batteries-included-components/Layouts/Emissions/Record/Detail";
import { useFacilityDetail } from "#src/components/hooks/useFacilities";
import { QUERY_STATUS } from "#src/constants";
import { ORGANIZATION_BREADCRUMB } from "#src/routes/organization";
import { FACILITIES_LIST_BREADCRUMB } from "#src/routes/organization/facilities";
import { Page } from "@validereinc/common-components";
import { DateFormats, monthFormatter } from "@validereinc/utilities";
import parse from "date-fns/parse";
import React, { useState } from "react";
import { useParams } from "react-router";
import {
  FACILITY_EMISSION_RECORD_DETAIL_BREADCRUMB,
  FACILITY_EMISSION_RECORD_DETAIL_FALLBACK_PAGE_TITLE,
} from ".";
import { FACILITY_EMISSION_RECORD_LIST_BREADCRUMB } from "../..";
import { FACILITY_DETAIL_BREADCRUMB } from "../../..";

const FacilityEmissionsRecordDetailPage = () => {
  // state
  const { facilityId, emissionPeriod } = useParams<{
    facilityId: string;
    emissionPeriod: string;
  }>();
  const [resultPeriod] = useState(() =>
    parse(emissionPeriod, DateFormats.YEAR_MONTH, new Date())
  );
  const [facilityDetail, facilityDetailFetchStatus] =
    useFacilityDetail(facilityId);

  // effects & callbacks

  // computed
  const breadcrumbs = useBreadcrumbs(
    [
      ORGANIZATION_BREADCRUMB,
      FACILITIES_LIST_BREADCRUMB,
      FACILITY_DETAIL_BREADCRUMB,
      FACILITY_EMISSION_RECORD_LIST_BREADCRUMB,
      FACILITY_EMISSION_RECORD_DETAIL_BREADCRUMB,
    ],
    {
      2: facilityDetail?.name,
      4: monthFormatter(resultPeriod),
    }
  );

  const pageTitle = resultPeriod
    ? `${
        facilityDetail?.name ? `${facilityDetail.name as string} ` : ""
      }${monthFormatter(resultPeriod)} Emissions Record`
    : FACILITY_EMISSION_RECORD_DETAIL_FALLBACK_PAGE_TITLE;

  const emissionRecordsDetailFilters = {
    "equipment.facility.id": facilityId,
  };

  return (
    <Page
      isLoading={facilityDetailFetchStatus === QUERY_STATUS.LOADING}
      breadcrumbs={breadcrumbs}
      category={FACILITY_EMISSION_RECORD_DETAIL_FALLBACK_PAGE_TITLE}
      title={pageTitle}
    >
      <EmissionsRecordDetail
        filters={emissionRecordsDetailFilters}
        timePeriod={emissionPeriod}
      >
        <EmissionsRecordDetail.EquipmentPanel
          subscription={null}
          filters={emissionRecordsDetailFilters}
          timePeriod={emissionPeriod}
        />
        <EmissionsRecordDetail.EstimationMethodPanel
          subscription={null}
          filters={emissionRecordsDetailFilters}
          timePeriod={emissionPeriod}
          showEquipment
        />
      </EmissionsRecordDetail>
    </Page>
  );
};

export default FacilityEmissionsRecordDetailPage;
