import ManualEntryService from "#services/ManualEntryService";
import { Button, Modal, Title } from "@validereinc/common-components";
import * as PropTypes from "prop-types";
import React, { useState } from "react";

const OverwriteConfirmationModal = (props) => {
  const [buttonState, setButtonState] = useState("enabled");

  const onApproveButtonClick = () => {
    setButtonState("disabled");

    const upsert = true;

    ManualEntryService.addInlineMeasurement(props.input, upsert)
      .then(() => {
        props.onOverwriteConfirmationModalClose();

        props.onSuccessResponse();
      })
      .finally(() => {
        setButtonState("enabled");
      });
  };

  return (
    <Modal
      open={props.show}
      onClose={props.onOverwriteConfirmationModalClose}
    >
      <Modal.Header>
        <Title>Overwrite Inline Measurement</Title>
      </Modal.Header>

      <Modal.Body>
        There is already an existing measurement for some of the measurements
        being inserted. Do you wish to overwrite with the new measurements?
      </Modal.Body>

      <Modal.Footer>
        <Button
          className="pull-left"
          onClick={props.onOverwriteConfirmationModalClose}
        >
          Close
        </Button>

        <Button
          variant="primary"
          onClick={onApproveButtonClick}
          isLoading={buttonState !== "enabled"}
        >
          Overwrite
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

OverwriteConfirmationModal.propTypes = {
  show: PropTypes.bool.isRequired,
  input: PropTypes.object.isRequired,
  onOverwriteConfirmationModalClose: PropTypes.func.isRequired,
  view: PropTypes.string,
  onSuccessResponse: PropTypes.func,
};

export default OverwriteConfirmationModal;
