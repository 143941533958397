export const linkToCreateFormSubmission = (
  formCategoryId = ":categoryId",
  formTemplateId = ":formTemplateId"
) =>
  `/app/forms/categories/${formCategoryId}/templates/${formTemplateId}/create-form-submission`;

export const CREATE_FORM_SUBMISSION_BREADCRUMB = {
  title: "Create Form Submission",
  path: linkToCreateFormSubmission,
};

export * from "./CreateFormSubmissionPage";
