import React from "react";
import { getNumberColorBySign } from "#utils/styleCalculator";
import { getDollarValue } from "#utils/stringFormatter";
import { computeDifference } from "./ResultDifference";
import {
  INLET_BALANCE_PROPERTIES,
  OUTLET_BALANCE_PROPERTIES,
  SYSTEM_BALANCE_PROPERTIES,
} from "./SystemBalanceConstant";

export function getPropertiesValue(
  currentSystemBalance,
  initialSystemBalanceResult,
  property,
  columnKey
) {
  const componentVolumeProperties =
    currentSystemBalance?.components?.[columnKey]?.[property] ?? null;
  if (componentVolumeProperties) {
    const originalProperties =
      initialSystemBalanceResult?.components?.[columnKey]?.[property];

    const { percentChange, difference } = computeDifference(
      componentVolumeProperties,
      originalProperties
    );

    return { ...componentVolumeProperties, percentChange, difference };
  }

  const qualityMeasurementProperties =
    currentSystemBalance?.qualities?.[columnKey]?.[property] ?? null;
  if (qualityMeasurementProperties) {
    const originalProperties =
      initialSystemBalanceResult?.qualities?.[columnKey]?.[property];

    const { percentChange, difference } = computeDifference(
      qualityMeasurementProperties,
      originalProperties
    );
    return { ...qualityMeasurementProperties, percentChange, difference };
  }

  return {};
}

export const shouldShowPercentChange = (columnKey) => {
  switch (columnKey) {
    case "receipts_plus_opening_inventory":
    case "dispositions_plus_closing_inventory":
    case "absolute_differences":
      return true;
    default:
      return false;
  }
};

export function getTotalPrice(estimatedValue) {
  if (!estimatedValue?.value) {
    return "—";
  }

  return (
    <div
      className="recordOfQualityResultPricing__value"
      style={{
        color: getNumberColorBySign(estimatedValue.value),
      }}
    >
      {getDollarValue(estimatedValue.value, estimatedValue.currency)}
    </div>
  );
}

export const getSystemBalanceProperties = ({ inlets, outlets }) => {
  if (inlets?.length && outlets?.length) {
    return [
      ...INLET_BALANCE_PROPERTIES,
      ...OUTLET_BALANCE_PROPERTIES,
      ...SYSTEM_BALANCE_PROPERTIES,
    ];
  } else if (outlets?.length) {
    return [...OUTLET_BALANCE_PROPERTIES];
  }

  return [...INLET_BALANCE_PROPERTIES];
};
