import { DEFAULT_QUERY_OPTIONS } from "#hooks/adapters/adapterUtils";
import { useQuery } from "@tanstack/react-query";
import { DefaultRecordValueConfigurationDomain } from "@validereinc/domain";

export const DRVC_QUERY_KEY = "default_record_value_configurations";

export const useGetOneDRVC = (
  params: {
    drvcId: string;
  },
  options: { enabled?: boolean } = {}
) =>
  useQuery({
    queryKey: [DRVC_QUERY_KEY, "getOne", params],
    queryFn: () => DefaultRecordValueConfigurationDomain.getOne(params),
    ...DEFAULT_QUERY_OPTIONS,
    ...options,
  });
