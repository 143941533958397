import {
  DateSelectorInput,
  DropdownInput,
  GeoPointInput,
  RadioInput,
  TextInput,
} from "@validereinc/common-components";
import React from "react";
import { FileField } from "./FileField";
import { LookupField } from "./LookupField";
import {
  FormSchemaQuestionQuestionFields,
  InputFieldType,
} from "@validereinc/domain";

export const QuestionField = (
  props: FormSchemaQuestionQuestionFields & { name: string }
) => {
  const { data_type, prompt, is_required, ...restProps } = props;

  const commonProps = {
    label: prompt,
    isRequired: is_required,
    isFluid: true,
    ...restProps,
  };

  return (
    <>
      {["string", "number", "integer"].includes(data_type) ? (
        <TextInput
          {...commonProps}
          type={data_type === "string" ? "text" : "number"}
        />
      ) : null}

      {["date", "date-time", "date-time-range"].includes(data_type) ? (
        <DateSelectorInput
          {...commonProps}
          {...(data_type === "date-time-range"
            ? { isRange: true, isArraySyntax: true }
            : {})}
          variant={data_type === "date" ? "day" : "time"}
        />
      ) : null}

      {data_type === "boolean" ? (
        <RadioInput
          {...commonProps}
          options={[
            { label: "Yes", value: true },
            { label: "No", value: false },
          ]}
          labelKey="label"
          valueKey="value"
        />
      ) : null}

      {["pick-list", "multi-pick-list"].includes(data_type) ? (
        <DropdownInput
          {...commonProps}
          options={(props as InputFieldType).pick_list_values}
          isMulti={data_type === "multi-pick-list"}
        />
      ) : null}

      {data_type === "lookup" ? (
        <LookupField
          lookup_entity_display_attributes={props.lookup_entity_attributes}
          {...commonProps}
        />
      ) : null}

      {data_type === "geo_point" ? (
        <GeoPointInput
          {...commonProps}
          isFluid
        />
      ) : null}

      {data_type === "file" ? <FileField {...commonProps} /> : null}
    </>
  );
};
