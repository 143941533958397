import React, { useState } from "react";
import PropTypes from "prop-types";
import { Tab, Nav, NavItem } from "react-bootstrap";
import "./CompanySettings.css";
import { SetCleanHistoryPath } from "../../../Routers/historyHelper";
import Members from "./Members/Members";
import Roles from "./Roles/Role";
import StreamSettings from "./Streams/Settings";
import Notifications from "./Notifications/Notifications";
import Sites from "./Sites/Sites";
import { linkToCompanySettings } from "../../../Routers/links";
import { havePermission } from "../../Redux/reducers/permissions";
import { connect } from "react-redux";
import UsersActivity from "./UsersActivity/UsersActivity";

const DEFAULT_TAB = "users";

const SETTING_PERMISSION_REQUIREMENT = "write";

const mapStateToProps = (state) => {
  return {
    hasSettingPermission: (permission) =>
      havePermission(state.permissions)(permission.id, permission.requirement),
  };
};

const CompanySettings = (props) => {
  const paramKey = props.match.params.tab;

  const availableSettings = [
    {
      id: "users",
      title: "Users",
      permission: { id: "core:user.management", requirement: "read" },
      render: Members,
    },
    {
      id: "user_activity",
      title: "User Activity",
      permission: { id: "core:user.management", requirement: "read" },
      render: UsersActivity,
    },
    {
      id: "roles",
      title: "Roles",
      permission: { id: "core:user.management", requirement: "read" },
      render: Roles,
    },
    {
      id: "stream_alerts",
      title: "Stream Alert",
      permission: {
        id: "lite:streams.alerts",
        requirement: SETTING_PERMISSION_REQUIREMENT,
      },
      render: StreamSettings,
    },
    {
      id: "notifications",
      title: "Notifications",
      permission: {
        id: "core:notifications",
        requirement: SETTING_PERMISSION_REQUIREMENT,
      },
      render: Notifications,
    },
    {
      id: "sites",
      title: "Sites",
      permission: {
        id: "360:field_labs",
        requirement: SETTING_PERMISSION_REQUIREMENT,
      },
      render: Sites,
    },
  ];

  const [tabKey, setTabKey] = useState(paramKey ? paramKey : DEFAULT_TAB);

  const onSelect = (eventKey) => {
    const link = linkToCompanySettings(eventKey);
    SetCleanHistoryPath(link);
    setTabKey(eventKey);
  };

  const settingPermission = (permission) => {
    return props.hasSettingPermission(permission);
  };

  return (
    <Tab.Container
      className="companySettings"
      id="companySettings__tabs"
      activeKey={tabKey}
      onSelect={onSelect}
    >
      <div className="companySettings__tabs">
        <div className="companySettings__navs">
          <div className="companySettings__navsHeader">Company</div>
          <Nav stacked>
            {availableSettings.map((setting) => {
              if (settingPermission(setting.permission)) {
                return (
                  <NavItem
                    eventKey={setting.id}
                    key={setting.id}
                  >
                    {setting.title}
                  </NavItem>
                );
              } else {
                return null;
              }
            })}
          </Nav>
        </div>
        <div className="companySettings__content">
          <Tab.Content animation>
            {availableSettings.map((setting) => {
              if (settingPermission(setting.permission)) {
                const RenderComponent = setting.render;

                return (
                  <Tab.Pane
                    eventKey={setting.id}
                    key={setting.id}
                  >
                    <RenderComponent
                      isActive={setting.id === tabKey}
                      height={props.height}
                      match={props.match}
                    />
                  </Tab.Pane>
                );
              }
            })}
          </Tab.Content>
        </div>
      </div>
    </Tab.Container>
  );
};

CompanySettings.propTypes = {
  height: PropTypes.number,
  match: PropTypes.object,
  hasSettingPermission: PropTypes.func,
  isNewStreamAlertSettingsEnabled: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, null)(CompanySettings);
