import useQueryParams from "#routers/useQueryParams";
import config from "#src/config";
import { getTimeStringFromDate } from "#utils/timeFormatter";
import {
  ControlLabel,
  DateRangeSelector,
} from "@validereinc/common-components";
import classNames from "classnames/bind";
import moment from "moment";
import * as PropTypes from "prop-types";
import React from "react";
import styles from "./Streams/Streams.module.scss";

const cx = classNames.bind(styles);

const PeriodSelect = ({
  defaultDateRange,
  hoverRange,
  fromKey = "periodFrom",
  toKey = "periodTo",
  includeBottomSpacing,
  label = "Period",
}) => {
  const [queryParams, setQueryParams] = useQueryParams(defaultDateRange);

  const selectDate = ({ from, to }) => {
    setQueryParams({
      [fromKey]: getTimeStringFromDate(from, config.DATE_FORMAT),
      [toKey]: getTimeStringFromDate(to, config.DATE_FORMAT),
    });
  };

  return (
    <ControlLabel
      label={label}
      className={cx("dropdownContainer", {
        dateSelectBottomSpacing: includeBottomSpacing,
      })}
    >
      <div className="filterRow">
        <DateRangeSelector
          value={{
            from: moment(queryParams[fromKey]).toDate(),
            to: moment(queryParams[toKey]).toDate(),
          }}
          hoverRange={hoverRange}
          onChange={selectDate}
          icon
        />
      </div>
    </ControlLabel>
  );
};

PeriodSelect.propTypes = {
  defaultDateRange: PropTypes.object.isRequired,
  hoverRange: PropTypes.string,
  fromKey: PropTypes.string,
  toKey: PropTypes.string,
  includeBottomSpacing: PropTypes.bool,
  label: PropTypes.string,
};

export default PeriodSelect;
