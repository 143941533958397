import { useState, useEffect } from "react";
import DataMappingService from "#components/Services/DataMappingService";

const useDataMappingTaskStatus = (dataMappingId) => {
  const [taskStatus, setTaskStatus] = useState({});
  const [fetchState, setFetchState] = useState("loading");

  useEffect(() => {
    if (dataMappingId) {
      DataMappingService.getDataMappingTaskStatus(dataMappingId)
        .then(({ data }) => {
          setTaskStatus(data);
          setFetchState("loaded");
        })
        .catch(() => {
          setFetchState("error");
        });
    }
  }, [dataMappingId]);

  return [taskStatus, fetchState];
};

export default useDataMappingTaskStatus;
