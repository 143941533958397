import React, { useRef } from "react";
import * as PropTypes from "prop-types";
import { Panel } from "react-bootstrap";
import ImageCanvas from "./ImageCanvas";
import "./AnnotationImageView.css";
import { ImageProperties } from "../Hooks/useAnnotationTools";

export const TUBE_VALUES = {
  LEFT: "left",
  RIGHT: "right",
  NONE: "none",
};

export const AnnotationImageView = ({
  regularImageUrl,
  uvImageUrl,
  imageProperties,
  setValue,
  sketchHeight,
  sketchWidth,
  imageScale,
  children,
  setPan,
  setZoom,
  activeTube,
}) => {
  const leftSketchRef = useRef(null);
  const rightSketchRef = useRef(null);

  const onChange = (affectedRef) => (event) => {
    if (event && event.type === "mouseup") {
      const value = affectedRef.current.toJSON();
      setValue(value);
    }
  };

  const onZoomBoxRelease = (leftX, rightX, upperY, bottomY) => {
    const { x, y } = imageProperties.pan;
    setPan({
      x: x + leftX,
      y: y + upperY,
    });

    const xZoomFactor = (rightX - leftX) / sketchWidth;
    const yZoomFactor = (bottomY - upperY) / sketchHeight;

    // Get the bigger of the two zooms, otherwise we won't be able to see everything
    // in the area we wanted to zoom to
    const newZoom = 1 / Math.max(xZoomFactor, yZoomFactor);
    setZoom(newZoom * imageProperties.zoom);
  };

  const sharedOptions = {
    ...imageProperties.toObject(),
    imageScale,
    sketchHeight,
    sketchWidth,
    onZoomBoxRelease,
  };

  const blockLeftSide = activeTube === TUBE_VALUES.RIGHT;
  const blockRightSide = activeTube === TUBE_VALUES.LEFT;

  return (
    <Panel>
      <Panel.Body>
        {children || null}
        <section className="annotationImageView__section">
          <ImageCanvas
            url={regularImageUrl}
            drawingRef={leftSketchRef}
            onChange={onChange(leftSketchRef)}
            blockLeft={blockLeftSide}
            blockRight={blockRightSide}
            {...sharedOptions}
          />
          <ImageCanvas
            url={uvImageUrl}
            drawingRef={rightSketchRef}
            onChange={onChange(rightSketchRef)}
            blockLeft={blockLeftSide}
            blockRight={blockRightSide}
            {...sharedOptions}
          />
        </section>
      </Panel.Body>
    </Panel>
  );
};

AnnotationImageView.propTypes = {
  imageProperties: PropTypes.instanceOf(ImageProperties).isRequired,
  regularImageUrl: PropTypes.string.isRequired,
  uvImageUrl: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  setPan: PropTypes.func.isRequired,
  setZoom: PropTypes.func.isRequired,
  sketchHeight: PropTypes.number.isRequired,
  sketchWidth: PropTypes.number.isRequired,
  imageScale: PropTypes.number.isRequired,
  activeTube: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default AnnotationImageView;
