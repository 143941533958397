import { useListEquipment } from "#hooks/adapters/useEquipment";
import { useTableSortingAndPagination } from "#redux/reducers/tableStateReducer";
import useLocalization from "#src/hooks/useLocalization";
import { useStorageKey } from "#src/hooks/useStorageKey";
import {
  DataTablePanel,
  SortingType,
  StorageKeys,
  useFilters,
} from "@validereinc/common-components";
import {
  AssetGroupAssetType,
  AssetType,
  EquipmentType,
  SortDirection,
} from "@validereinc/domain";
import { toFlattenedObject } from "@validereinc/utilities";
import classNames from "classnames/bind";
import React from "react";
import styles from "./FlowFormPanel.module.scss";

const cx = classNames.bind(styles);

const sorting: SortingType = {
  sortBy: "name",
  sortDirection: SortDirection.ASCENDING,
};
export const FlowEquipmentSelectionTablePanel = ({
  filterConfigStorageKey,
  selectedAssets,
  addAssetToFlow,
}: Pick<StorageKeys, "filterConfigStorageKey"> & {
  selectedAssets: Record<string, AssetGroupAssetType>;
  addAssetToFlow: (asset: AssetGroupAssetType, isUpstream: boolean) => void;
}) => {
  const { localize } = useLocalization();
  const { tableConfigStorageKey } = useStorageKey("flow-equipment-selection");
  const [{ entity_type: _, ...filters }] = useFilters(filterConfigStorageKey);
  const [tableState, updateTableState] = useTableSortingAndPagination(
    sorting,
    filters
  );

  const { data, isLoading } = useListEquipment({
    page: tableState.page,
    pageSize: tableState.itemsPerPage,
    sortBy: tableState.sortBy,
    sortDirection: tableState.sortDirection,
    filters: toFlattenedObject(filters),
  });

  const items = data?.data ?? [];

  const addOrigin = (item: EquipmentType) => {
    addAssetToFlow({ ...item, asset_type: AssetType.EQUIPMENT }, true);
  };

  const addDestination = (item: EquipmentType) => {
    addAssetToFlow({ ...item, asset_type: AssetType.EQUIPMENT }, false);
  };

  const getItemActions = ({ item }: { item: EquipmentType }) =>
    Object.keys(selectedAssets)?.includes(item.id)
      ? []
      : [
          {
            isOverflow: true,
            label: "Add as origin",
            buttonProps: {
              onClick: () => addOrigin(item),
            },
          },
          {
            isOverflow: true,
            label: "Add as destination",
            buttonProps: {
              onClick: () => addDestination(item),
            },
          },
        ];

  return (
    <div className={cx("streamSelectionContainer")}>
      <div className={cx("streamTableContainer")}>
        <DataTablePanel
          storageKey={tableConfigStorageKey}
          panelProps={{
            title: localize("equipment"),
            description: `You may select up to one ${localize(
              "equipment"
            )} as the origin and/or destination of your ${localize("flow")}.`,
          }}
          dataTableProps={{
            headers: [
              { key: "name", label: `${localize("Equipment")} Name` },
              { key: "type.name", label: `${localize("Equipment")} Type` },
              { key: "facility.name", label: localize("Facility") },
            ],
            sorting,
            isLoading,
            items,
            pagination: {
              page: tableState.page,
              itemsPerPage: tableState.itemsPerPage,
              total: data?.total_entries ?? 0,
            },
            onSortChange: updateTableState,
            onPaginationChange: updateTableState,
            isFluid: false,
            getItemActions,
          }}
        />
      </div>
    </div>
  );
};
