import {
  useDownloadTemplate,
  useGetOneDataset,
} from "#hooks/adapters/useDatasets";
import { useGetOneTransaction } from "#hooks/adapters/useTransactions";
import { DATA_SUBMISSION_DETAIL_TITLE } from "#routes/data-tools/submissions/[transactionId]/detail/index";
import {
  Button,
  FilterPillVariants,
  Icon,
} from "@validereinc/common-components";
import {
  DatasetType,
  TransactionDetailItemStatus,
  TransactionDetailItemStatusType,
  TransactionDetailsItemType,
  TransactionDetailsType,
  TransactionStatus,
  TransactionType,
} from "@validereinc/domain";
import {
  datetimeFormatter,
  toFlattenedObject,
  toStartCaseString,
} from "@validereinc/utilities";
import React from "react";

export const getTransactionTitle = (
  transaction?: TransactionType,
  dataset?: DatasetType
) =>
  dataset?.name && transaction?.created_at
    ? `${toStartCaseString(dataset?.name)} - ${datetimeFormatter(
        new Date(transaction.created_at)
      )}`
    : DATA_SUBMISSION_DETAIL_TITLE;

export const useDataSubmissionDetail = () => {
  const { data: transactionData, isLoading: isTransactionLoading } =
    useGetOneTransaction();
  const { data: datasetData, isFetching: isDatasetLoading } = useGetOneDataset({
    id: transactionData?.data[0]?.dataset_id,
  });

  const transaction = transactionData?.data;
  const firstTransactionState = transactionData?.data?.[0];
  const finalTransactionState =
    transactionData?.data?.[transactionData?.data?.length - 1];
  const dataset = datasetData?.data;
  const isLoading = isTransactionLoading || isDatasetLoading;

  return {
    transaction,
    dataset,
    isLoading,
    firstTransactionState,
    finalTransactionState,
  };
};

export const getTransactionDetailStatusDisplayLabel = (
  status?: TransactionDetailItemStatusType
) => {
  switch (status) {
    case TransactionDetailItemStatus.CREATED:
    case TransactionDetailItemStatus.UPDATED:
    case TransactionDetailItemStatus.FAILED:
    case TransactionDetailItemStatus.UNCHANGED:
      return toStartCaseString(status);
    default:
      return "Not Ingested";
  }
};

export const getTransactionDetailStatusVariant = (
  status?: TransactionDetailItemStatusType
) => {
  switch (status) {
    case TransactionDetailItemStatus.CREATED:
      return "success";
    case TransactionDetailItemStatus.UPDATED:
      return "info";
    case TransactionDetailItemStatus.FAILED:
      return "error";
    case TransactionDetailItemStatus.UNCHANGED:
    default:
      return "default";
  }
};

export const getTransactionDetailStatusFilterPillVariant = (
  status?: TransactionDetailItemStatusType
) => {
  switch (status) {
    case TransactionDetailItemStatus.CREATED:
      return FilterPillVariants.GOOD;
    case TransactionDetailItemStatus.UPDATED:
      return FilterPillVariants.PENDING;
    case TransactionDetailItemStatus.FAILED:
      return FilterPillVariants.FAILURE;
    case TransactionDetailItemStatus.UNCHANGED:
      return FilterPillVariants.NEUTRAL;
    default:
      return undefined;
  }
};

export const extractItemsFromTransactionDetails = (queryData?: {
  data: TransactionDetailsType[];
}): TransactionDetailsItemType[] => queryData?.data?.[0]?.details ?? [];

export const showErrorDetails = (items: TransactionDetailsItemType[]) =>
  !!items.filter(({ status }) => status === TransactionDetailItemStatus.FAILED)
    .length;

export const useDataSubmissionRowTableEmptyStateProps = () => {
  const { finalTransactionState, dataset } = useDataSubmissionDetail();
  const { mutate: downloadTemplate, isLoading } = useDownloadTemplate();

  switch (finalTransactionState?.status) {
    case TransactionStatus.FAILED:
      return {
        title: "Failed to import",
        suggestion: finalTransactionState?.details,
        icon: <Icon variant="smiley-sad" />,
        variant: "error" as const,
        action: (
          <Button
            onClick={() =>
              downloadTemplate({
                datasetId: dataset?.id,
                fileName: `${dataset?.name}_template.csv`,
              })
            }
            isLoading={isLoading}
            variant={"primary" as const}
          >
            Download Template
          </Button>
        ),
      };
    case TransactionStatus.PENDING:
      return {
        title: "Import in progress",
        suggestion: "Row level data currently unavailable",
      };
    default:
      return undefined;
  }
};

/** We get a pseudo-JSON representation of the submitted data; clean it up a bit to parse it */
export const extractTransactionItemData = (data: string) => {
  const cleanedData = data
    .replaceAll("'", '"')
    .replaceAll("None", "null")
    .replaceAll("True", "true")
    .replaceAll("False", "false");

  try {
    return Object.fromEntries(
      Object.entries(toFlattenedObject(JSON.parse(cleanedData))).map(
        ([key, value]) => [
          key,
          typeof value === "string" || !value ? value : JSON.stringify(value),
        ]
      )
    );
  } catch (e) {
    console.error(`Cannot extract data from ${data}: ${e}.`);
    // Fail gracefully
    return {};
  }
};

export type DataSubmissionTransactionDetailItemType = Omit<
  TransactionDetailsItemType,
  "index"
> & {
  index: number;
};
