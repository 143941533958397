import { useListFlowTypes } from "#hooks/adapters/useFlows";
import { useTableSortingAndPagination } from "#redux/reducers/tableStateReducer";
import { useListCustomAttributes } from "#src/components/hooks/adapters/useCustomAttributes";
import useEquipmentTypes from "#src/components/hooks/useEquipmentTypes";
import { useIsFeatureAvailable } from "#src/contexts/AuthenticatedContext.helpers";
import { displayValue } from "#utils/stringFormatter";
import {
  Button,
  DataTable,
  DataTablePanel,
  HeaderType,
  SortingType,
  Tooltip,
} from "@validereinc/common-components";
import {
  AssetType,
  AssetTypeType,
  CustomAttributeSchema,
  CustomAttributeType,
  SortDirection,
} from "@validereinc/domain";
import { toStartCaseString } from "@validereinc/utilities";
import React, { useState } from "react";
import { AddCustomAttributeDialog } from "../../Dialogs/AddCustomAttributeDialog";
import { DeleteCustomAttributeDialog } from "../../Dialogs/DeleteCustomAttributeDialog";
import { EditCustomAttributeDialog } from "../../Dialogs/EditCustomAttributeDialog";

const sorting: SortingType = {
  sortBy: "field_name",
  sortDirection: SortDirection.ASCENDING,
};

export const CustomAttributeTablePanel = ({
  assetType,
}: {
  assetType: AssetTypeType;
}) => {
  const [tableState, updateTableState] = useTableSortingAndPagination(sorting);
  const [isAddCustomAttributeOpen, setIsAddCustomAttributeOpen] =
    useState(false);
  const [itemToDelete, setItemToDelete] =
    useState<CustomAttributeType | null>();
  const [itemToUpdate, setItemToUpdate] =
    useState<CustomAttributeType | null>();
  const [isCustomAttributeWriteEnabled] = useIsFeatureAvailable({
    featureFlagQuery: "core:facilities",
    permissionQuery: "custom_attribute_definition:write",
  });
  const [isCustomAttributeDeleteEnabled] = useIsFeatureAvailable({
    featureFlagQuery: "core:facilities",
    permissionQuery: "custom_attribute_definition:delete",
  });

  const params = {
    page: tableState.page,
    pageSize: tableState.itemsPerPage,
    sortBy: tableState.sortBy,
    sortDirection: tableState.sortDirection,
    filters: { entity_type: assetType },
  };

  const [equipmentTypes] = useEquipmentTypes();
  const { data: flowTypes } = useListFlowTypes();
  const { data, isLoading } = useListCustomAttributes(params);
  const items = data?.data ?? [];

  const getEquipmentTypeName = (equipmentTypeId: string) => {
    const equipmentType = equipmentTypes.find((e) => e.id === equipmentTypeId);
    return equipmentType?.name;
  };
  const getFlowTypeName = (flowTypeId: string) => {
    const flowType = flowTypes.find((f) => f.id === flowTypeId);
    return flowType?.name;
  };

  const headers: Array<HeaderType<CustomAttributeType>> = [
    {
      key: CustomAttributeSchema.keyof().Enum.display_name,
      label: "Display Name",
    },
    {
      key: CustomAttributeSchema.keyof().Enum.field_name,
      label: "ID",
      isSortable: true,
    },
    {
      key: CustomAttributeSchema.keyof().Enum.description,
      label: "Description",
    },
    {
      key: CustomAttributeSchema.keyof().Enum.entity_subtype,
      label: "Sub Type",
      renderComponent: ({ value }) => {
        switch (assetType) {
          case AssetType.EQUIPMENT:
            return getEquipmentTypeName(value);
          case AssetType.FLOW:
            return getFlowTypeName(value);
          default:
            return value;
        }
      },
    },
    {
      key: CustomAttributeSchema.keyof().Enum.is_required,
      label: "Is Required?",
      minWidth: 120,
      renderComponent: ({ value }) => displayValue(value),
    },
    {
      key: CustomAttributeSchema.keyof().Enum.data_type,
      label: "Data Type",
      minWidth: 120,
      renderComponent: ({ value }) => (
        <DataTable.DataRow.PillCell
          variant="default"
          value={toStartCaseString(value)}
        />
      ),
    },
    {
      key: CustomAttributeSchema.keyof().Enum.pick_list_values,
      label: "Pick List Values",
      renderComponent: ({ value }) => displayValue(value),
    },
    {
      key: CustomAttributeSchema.keyof().Enum.lookup_entity_type,
      label: "Lookup Asset",
      minWidth: 120,
      renderComponent: ({ value }) => toStartCaseString(value),
    },
    {
      key: CustomAttributeSchema.keyof().Enum.unit,
      label: "Unit",
    },
  ];

  const getItemActions = ({ item }: { item: CustomAttributeType }) => [
    ...(isCustomAttributeWriteEnabled
      ? [
          {
            label: "Edit",
            buttonProps: {
              onClick: () => setItemToUpdate(item),
              icon: "pencil",
            },
          },
        ]
      : []),
    ...(isCustomAttributeDeleteEnabled
      ? [
          {
            label: "Delete",
            buttonProps: {
              onClick: () => setItemToDelete(item),
              icon: "trash",
            },
          },
        ]
      : []),
  ];

  const actionRow = isCustomAttributeWriteEnabled
    ? [
        <Button
          key="add-custom-attribute"
          onClick={() => setIsAddCustomAttributeOpen(true)}
        >
          Add
        </Button>,
      ]
    : [
        <Tooltip
          key="add-custom-attribute"
          content="You do not have permissions to add new custom attributes."
        >
          <Button
            onClick={() => setIsAddCustomAttributeOpen(true)}
            disabled
            icon="lock"
          >
            Add
          </Button>
          ,
        </Tooltip>,
      ];

  return (
    <>
      <DataTablePanel
        panelProps={{ title: "Custom Attributes", actionRow }}
        dataTableProps={{
          items,
          isLoading: isLoading,
          headers,
          getItemActions,
          onSortChange: updateTableState,
          onPaginationChange: updateTableState,
          sorting,
          pagination: {
            page: tableState.page,
            itemsPerPage: tableState.itemsPerPage,
            total: data?.total_entries ?? 0,
          },
        }}
      />
      <AddCustomAttributeDialog
        isOpen={isAddCustomAttributeOpen}
        onClose={() => setIsAddCustomAttributeOpen(false)}
        assetType={assetType}
      />
      <DeleteCustomAttributeDialog
        isOpen={!!itemToDelete}
        onClose={() => setItemToDelete(undefined)}
        assetType={assetType}
        itemToDelete={itemToDelete}
      />

      <EditCustomAttributeDialog
        isOpen={!!itemToUpdate}
        onClose={() => setItemToUpdate(undefined)}
        assetType={assetType}
        itemToUpdate={itemToUpdate}
      />
    </>
  );
};
