import {
  FilterPanel,
  StorageKeys,
  TextInput,
} from "@validereinc/common-components";
import React from "react";

export const RoleListFilterPanel = ({
  filterConfigStorageKey,
}: Pick<StorageKeys, "filterConfigStorageKey">) => {
  return (
    <FilterPanel
      storageKey={filterConfigStorageKey}
      filters={[
        {
          component: (
            <TextInput
              key="name"
              name="name"
              placeholder="Search Roles..."
              type="search"
              isInline
            />
          ),
        },
      ]}
    />
  );
};
