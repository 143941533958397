import React from "react";
import moment from "moment";
import { AssertIsSameDate } from "./assert";
import { getTimeStringFromDate } from "./timeFormatter";

export function GetYDomain(min, max, lowestRangeLimit) {
  const range = max - min;

  if (range < lowestRangeLimit) {
    max = min + lowestRangeLimit;
  }

  return [min, max];
}

export function GetXDomain(startDate, endDate, lowestDurationLimit) {
  startDate = moment(startDate);
  endDate = moment(endDate);

  const duration = GetDurationInSeconds(startDate, endDate);

  if (duration.asSeconds() < lowestDurationLimit) {
    endDate = moment(startDate).add(lowestDurationLimit, "seconds");
  }

  return [startDate.toDate(), endDate.toDate()];
}

export function GetXAxisTicks(xDomain, numXAxisTicks) {
  const xAxisTicks = [];

  if (xDomain) {
    const startDate = moment(xDomain[0]);
    const endDate = moment(xDomain[1]);

    const duration = GetDurationInSeconds(startDate, endDate);
    const timePeriod = GetTimeInterval(duration.asSeconds(), numXAxisTicks);

    for (let i = 0; i < numXAxisTicks; i++) {
      const xAxisTick = moment(startDate).add(i * timePeriod, "seconds");
      const xAxisTickEpoch = xAxisTick.unix() * 1000;

      xAxisTicks.push(xAxisTickEpoch);
    }
  }

  return xAxisTicks;
}

export function GetTimeInterval(timePeriod, numXAxisTicks) {
  const numSecondInADay = 60 * 60 * 24;
  const numOfDays = Math.ceil(timePeriod / numSecondInADay);
  const numIntervalBetweenDays = numXAxisTicks / numOfDays;

  return numSecondInADay / numIntervalBetweenDays;
}

export function GraphTimeFormatter(value, index, xAxisTicks) {
  const prevTick = index - 1;

  if (prevTick !== -1 && AssertIsSameDate(value, xAxisTicks[prevTick], "day")) {
    return <tspan>{getTimeStringFromDate(value, "HH:mm")}</tspan>;
  } else {
    return (
      <tspan style={{ fontWeight: 600 }}>
        {getTimeStringFromDate(value, "MM/DD")}
      </tspan>
    );
  }
}

export function GetDurationInSeconds(startDate, endDate) {
  return moment.duration(endDate.diff(startDate));
}
