import {
  Button,
  Form,
  FormButton,
  TextInput,
} from "@validereinc/common-components";
import * as PropTypes from "prop-types";
import React from "react";

const EffectiveDurationForm = ({
  onSubmit,
  formMethods,
  isLoading,
  onHide,
}) => (
  <Form
    onSubmit={onSubmit}
    {...formMethods}
  >
    <TextInput
      name="effectiveDuration"
      label="Effective Duration"
      type="number"
      unit="day(s)"
      step={1}
      isDisabled={isLoading}
      placeholder="days in effect"
      showIcon={true}
      isRequired={true}
    />

    <div className="clearfix">
      <Button
        onClick={onHide}
        disabled={isLoading}
      >
        Cancel
      </Button>

      <FormButton
        variant="primary"
        type="submit"
        className="pull-right"
        isLoading={isLoading}
      >
        Update Effective Duration
      </FormButton>
    </div>
  </Form>
);

EffectiveDurationForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  formMethods: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
};

export default EffectiveDurationForm;
