import { NetworkListRoute } from "#routes/organization/networks";
import { RoutePath } from "#utils/route";

export const NetworkCreateRoute = NetworkListRoute.concat(
  new RoutePath({
    path: "/create",
    title: "Create Network",
  })
);

export * from "./CreateNetworkPage";
