import { Panel } from "@validereinc/common-components";
import React from "react";
import { Table } from "react-bootstrap";
import FontAwesome from "react-fontawesome";
import "../../../Instrument/Instrument.css";
import { isValidString } from "../../InstrumentComponentHelper";

/* eslint-disable react/prop-types */

const InstrumentDescriptionPanel = ({ instrument }) => (
  <Panel
    title="Instrument Description"
    className="instrumentDescription"
  >
    <Table className="instrumentDescription__table">
      <tbody>
        <DescriptionRow
          title="Instrument ID"
          value={instrument.name}
          icon="hashtag"
        />

        <DescriptionRow
          title="Type"
          value={instrument.type}
          icon="cube"
        />

        <DescriptionRow
          title="Location"
          value={instrument.location}
          icon="map-marker"
        />

        <DescriptionRow
          title="Serial Number"
          value={instrument.serial_number}
          icon="id-card"
        />

        <DescriptionRow
          title="Firmware Ver"
          value={instrument.version}
          icon="laptop"
        />

        <DescriptionRow
          title="Hardware Ver"
          value={instrument.hardware_version}
          icon="cog"
        />
      </tbody>
    </Table>
  </Panel>
);

const DescriptionRow = (props) => (
  <tr>
    <td>
      <span className="hidden-xs">
        <FontAwesome
          className="fa-fw instrumentDescription__icon"
          name={props.icon}
        />
        {props.title + ":"}
      </span>

      <span className="visible-xs">{props.title + ":"}</span>
    </td>
    <td>{isValidString(props.value, "Unavailable")}</td>
  </tr>
);

export default InstrumentDescriptionPanel;
