import DateRangeSelector from "#common/DateSelector/DateRangeSelector";
import { getDateObject } from "#common/DateSelector/DateRangeSelectorHelper";
import SidePanelSection from "#common/SidePanel/SidePanelSection";
import {
  getMethodString,
  getSampleSelectionCaption,
} from "#components/Records/Quality/RecordOfQualityHelper";
import {
  Button,
  ControlLabel,
  MultiDropdownInputWithSearch,
  Tooltip,
} from "@validereinc/common-components";
import classNames from "classnames/bind";
import moment from "moment";
import * as PropTypes from "prop-types";
import React, { useMemo } from "react";
import { Badge } from "react-bootstrap";
import FontAwesome from "react-fontawesome";
import { AutoSizer } from "react-virtualized";
import { useRecordSelectionContext } from "../Context/recordSelectionContext";
import { isAdjustableMethod } from "../Context/recordSelectionContextHelper";
import RecordOfQualitySampleChart from "./RecordOfQualitySampleChart";
import RecordOfQualitySelectionTable from "./RecordOfQualitySelectionTable";
import styles from "./SampleSelectionForm.module.scss";

const cx = classNames.bind(styles);

const dateRangeTooltipMessage =
  "You can choose different methods/samples for calculating the RoQ over the " +
  "course of an accounting period. Create or adjust intervals by changing " +
  "the end date here.";

const UTC = true;

const getSubHeader = (interval) => {
  let numSelected = interval?.sampleIds?.length ?? 0;

  if (interval.previousAccountingRecordId) {
    numSelected = 1;
  }

  if (interval.method === "volume_weighted_inline") {
    numSelected = 1;
  }

  return (
    <div className="recordOfQualitySampleSelections__subHeader">
      <span>Selection Interval</span>{" "}
      <span>{`(${getSampleSelectionCaption(interval)})`}</span>{" "}
      <Badge
        className="recordOfQualitySampleSelections__badge"
        style={{
          display: numSelected ? "inline-block" : "none",
        }}
      >
        {numSelected}
      </Badge>
    </div>
  );
};

const SampleSelectionForm = (props) => {
  const {
    interval,
    intervalSelectionIndex,
    visibleMethodOptions,
    nextIntervalUntil,
  } = props;

  const {
    accountingRecordDetail,
    setMethod,
    updateIntervalSelections,
    deleteIntervalSelection,
    selectionData,
    hasWriteAccess,
    resetDateRange,
    toggleSample,
    roqSelectionState,
    onMeasurementSelect,
    onDateSelect: onChartDateSelect,
    measurement,
    from,
    until,
    displayProperties,
  } = useRecordSelectionContext();

  const onDateSelect = (from, to) => {
    from = moment.utc(from);
    to = moment.utc(to);

    updateIntervalSelections({
      accountingPeriod: accountingRecordDetail.accounting_period,
      from,
      to,
      updateIndex: intervalSelectionIndex,
      selectionData,
    });
  };

  const onRemoveInterval = () => {
    deleteIntervalSelection(
      accountingRecordDetail.accounting_period,
      intervalSelectionIndex
    );
  };

  const onMethodSelect = (value) => {
    resetDateRange();
    setMethod(value, intervalSelectionIndex);
  };

  const onSampleClick = (sample, intervalSelectionIndex) => {
    toggleSample(sample.id, sample.type, intervalSelectionIndex);
  };

  // Clicking and adding samples to selection intervals is only enabled when
  // there is only one interval (does not add sample to multiple intervals).
  // The method for that one interval should be adjustable (able to change
  // sample chosen)
  const qualityChartSelectionEnabled = useMemo(() => {
    const { intervalSelections } = roqSelectionState;

    return (
      hasWriteAccess &&
      isAdjustableMethod(intervalSelections[intervalSelectionIndex].method)
    );
  }, [roqSelectionState.intervalSelections, hasWriteAccess]);

  return (
    <SidePanelSection
      subHeader={getSubHeader(interval)}
      className={cx("section")}
    >
      <section className={`${cx("selectionDropdownSection")}`}>
        <div className={`${cx("dropdownContainer")} filterRow`}>
          <div className={cx("dropdownTitle")}>
            Selection Interval
            {hasWriteAccess && (
              <Tooltip
                content={dateRangeTooltipMessage}
                width={300}
              >
                <FontAwesome
                  name="info-circle"
                  className={cx("dropdownTitle__icon")}
                />
              </Tooltip>
            )}
          </div>

          <DateRangeSelector
            dateRange={{
              from: interval.from,
              to: interval.until,
            }}
            onDateRangeChange={onDateSelect}
            icon
            disableRelativeDays
            disabledDays={[
              {
                before: getDateObject(interval.from, UTC),
                after: getDateObject(nextIntervalUntil, UTC),
              },
            ]}
            disableFrom
            disabled={!hasWriteAccess}
            allowOneDayRange
            initialMonth="from"
            utc={UTC}
          />
        </div>

        <ControlLabel label="Method">
          <AutoSizer disableHeight>
            {({ width }) => (
              <MultiDropdownInputWithSearch
                className={cx("dropdown")}
                options={visibleMethodOptions}
                labelKey="name"
                value={
                  interval.method
                    ? [{ name: getMethodString(interval.method) }]
                    : []
                }
                onChange={onMethodSelect}
                ignoreCase={true}
                isMulti={false}
                width={width}
              />
            )}
          </AutoSizer>
        </ControlLabel>
      </section>

      <section className={`${cx("chartDropdownSection")}`}>
        <div className={`${cx("dropdownContainer")}`}>
          <MultiDropdownInputWithSearch
            value={measurement ? [measurement] : []}
            options={displayProperties}
            onChange={onMeasurementSelect}
            ignoreCase={true}
            isMulti={false}
          />
        </div>

        <div className="filterRow">
          <DateRangeSelector
            dateRange={{
              from,
              to: until,
            }}
            onDateRangeChange={onChartDateSelect}
            relativeToDate={() =>
              accountingRecordDetail.accounting_period?.until
            }
            icon
          />
        </div>
      </section>

      <RecordOfQualitySampleChart
        onSampleClick={qualityChartSelectionEnabled ? onSampleClick : null}
        intervalSelectionIndex={intervalSelectionIndex}
      />

      <RecordOfQualitySelectionTable
        hasWriteAccess={hasWriteAccess}
        intervalSelectionIndex={intervalSelectionIndex}
        interval={interval}
      />

      {intervalSelectionIndex !== 0 && hasWriteAccess && (
        <Button
          variant="error-outline"
          style={{ marginBottom: "20px" }}
          onClick={onRemoveInterval}
        >
          Remove Selection
        </Button>
      )}
    </SidePanelSection>
  );
};

SampleSelectionForm.propTypes = {
  interval: PropTypes.object.isRequired,
  intervalSelectionIndex: PropTypes.number.isRequired,
  visibleMethodOptions: PropTypes.array,
  nextIntervalUntil: PropTypes.object,
};

export default SampleSelectionForm;
