import * as React from "react";
import { useEffect, useRef, useState } from "react";

import { Link } from "@validereinc/common-components";

import { NO_FACILITY_NEARBY } from "#src/constants";

import { animated, useSpring } from "@react-spring/web";
import classNames from "classnames/bind";
import styles from "./MapClusterTooltip.module.scss";

// types
import { MapFacilityNodeDataType } from "../types";

const cx = classNames.bind(styles);

type MapTooltipProps = {
  data: MapFacilityNodeDataType;
  handleClosePopup: () => void;
  tooltipStyle: { top: number; left: number; opacity: number };
  navigate: (url: string) => void;
};

export const MapClusterTooltip = ({
  data,
  handleClosePopup,
  tooltipStyle,
  navigate,
}: MapTooltipProps) => {
  const [currentHeight, setCurrentHeight] = useState(0);
  const mapToolTipRef = useRef(null);

  const [labelSprings, labelApi] = useSpring(() => ({
    from: { y: 0, transform: "scale(0)", scale: 0, opacity: 0 },
  }));

  const [nodeSprings, nodeApi] = useSpring(() => ({
    from: { y: 0, transform: "scale(0)", scale: 0, opacity: 0 },
  }));

  const onAnimateInTooltip = () => {
    labelApi.start({
      from: { y: 0, transform: "scale(0)", scale: 0, opacity: 0 },
      to: { y: 0, transform: "scale(1)", scale: 1, opacity: 1 },
      config: { delay: 1000 },
    });
    nodeApi.start({
      from: { y: 0, transform: "scale(0)", scale: 0, opacity: 0 },
      to: { y: 0, transform: "scale(1)", scale: 1, opacity: 1 },
      config: { delay: 200 },
    });
  };

  const onAnimateOutTooltip = () => {
    labelApi.start({
      to: { y: 0, transform: "scale(0)", scale: 0, opacity: 0 },
      from: { y: 0, transform: "scale(1)", scale: 1, opacity: 1 },
      config: { delay: 0 },
    });
    nodeApi.start({
      to: { y: 0, transform: "scale(0)", scale: 0, opacity: 0 },
      from: { y: 0, transform: "scale(1)", scale: 1, opacity: 1 },
      config: { delay: 0 },
    });
  };

  useEffect(() => {
    if (mapToolTipRef?.current) {
      const height =
        mapToolTipRef.current.getBoundingClientRect()?.height ?? perfectHeight;
      setCurrentHeight(height);
    }
  }, [data?.name]);

  useEffect(() => {
    if (tooltipStyle?.opacity === 0) {
      onAnimateOutTooltip();
    } else {
      onAnimateInTooltip();
    }
  }, [tooltipStyle?.opacity]);

  if (!tooltipStyle?.top) {
    return null;
  }
  if (!data) {
    return null;
  }

  const perfectHeight = 98;
  const adjustmentDifference = currentHeight - perfectHeight + 80;
  const adjustedTop = tooltipStyle.top - adjustmentDifference;

  const handleLinkClick = () => {
    navigate({
      pathname: data?.url ?? "",
    });
  };

  return (
    <div
      className={cx("mapTooltipContainer")}
      ref={mapToolTipRef}
      style={{
        ...tooltipStyle,
        top: adjustedTop,
        left: tooltipStyle.left - 82,
      }}
      onMouseLeave={handleClosePopup}
    >
      <div>
        <animated.div
          className={cx("message")}
          style={labelSprings}
        >
          {data?.name !== NO_FACILITY_NEARBY ? (
            <div
              className={cx("mapTooltipLabelContainer")}
              style={labelSprings}
            >
              <Link
                label={data?.name ?? ""}
                underline="hover"
                onClick={handleLinkClick}
              />

              <div className={cx("mapTooltipLabelMassRow")}>
                <div>
                  <p className={cx("mapTooltipLabel")}>{data?.label ?? ""}</p>
                </div>
                <div>
                  {data?.mass ? (
                    <p className={cx("mapTooltipMass")}> {data.mass}</p>
                  ) : (
                    <div className={cx("skeleton", "mapTooltipMassSkeleton")} />
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div style={labelSprings}>
              <p className={cx("mapTooltipMass")}>
                {data?.name ?? NO_FACILITY_NEARBY}
              </p>
            </div>
          )}
        </animated.div>
      </div>
      <animated.div
        className={cx("nodeContainer")}
        style={nodeSprings}
      >
        <div className={cx("node")} />
      </animated.div>
    </div>
  );
};
