import { useListFlows } from "#hooks/adapters/useFlows";
import { FLOW_ATTRIBUTES, useFlowHeaders } from "#hooks/tables/useFlowHeaders";
import { useTableSortingAndPagination } from "#redux/reducers/tableStateReducer";
import { useParams } from "#routers/hooks";
import useLocalization from "#src/hooks/useLocalization";
import { useStorageKey } from "#src/hooks/useStorageKey";
import {
  DataTablePanel,
  SortingType,
  StorageKeys,
  useFilters,
} from "@validereinc/common-components";
import type { FlowType } from "@validereinc/domain";
import {
  AssetGroupAssetType,
  AssetType,
  SortDirection,
} from "@validereinc/domain";
import { toFlattenedObject } from "@validereinc/utilities";
import classNames from "classnames/bind";
import React from "react";
import styles from "./FlowFormPanel.module.scss";

const cx = classNames.bind(styles);

const sorting: SortingType = {
  sortBy: "name",
  sortDirection: SortDirection.ASCENDING,
};

export const FlowSelectionTablePanel = ({
  filterConfigStorageKey,
  selectedAssets,
  addAssetToFlow,
}: Pick<StorageKeys, "filterConfigStorageKey"> & {
  selectedAssets: Record<string, AssetGroupAssetType>;
  addAssetToFlow: (asset: AssetGroupAssetType, isUpstream: boolean) => void;
}) => {
  const { localize } = useLocalization();
  const { tableConfigStorageKey } = useStorageKey("flow-flow-selection");
  const { flowId } = useParams<{ flowId: string }>();
  const [{ entity_type: _, ...filters }] = useFilters(filterConfigStorageKey);

  const [tableState, updateTableState] = useTableSortingAndPagination(
    sorting,
    filters
  );

  const { data, isLoading } = useListFlows({
    page: tableState.page,
    pageSize: tableState.itemsPerPage,
    sortBy: tableState.sortBy,
    sortDirection: tableState.sortDirection,
    filters: toFlattenedObject(filters),
  });

  const items = data?.data ?? [];
  const addOrigin = (item: FlowType) => {
    addAssetToFlow({ ...item, asset_type: AssetType.FLOW }, true);
  };

  const addDestination = (item: FlowType) => {
    addAssetToFlow({ ...item, asset_type: AssetType.FLOW }, false);
  };

  const getItemActions = ({ item }: { item: FlowType }) =>
    Object.keys(selectedAssets)?.includes(item.id) || item.id === flowId
      ? []
      : [
          {
            isOverflow: true,
            label: "Add as origin",
            buttonProps: {
              onClick: () => addOrigin(item),
            },
          },
          {
            isOverflow: true,
            label: "Add as destination",
            buttonProps: {
              onClick: () => addDestination(item),
            },
          },
        ];

  return (
    <div className={cx("streamSelectionContainer")}>
      <div className={cx("streamTableContainer")}>
        <DataTablePanel
          storageKey={tableConfigStorageKey}
          panelProps={{
            title: localize("flow_plural"),
            description: `You may select any number of ${localize(
              "flow_plural"
            )} as the origin(s) and/or destination(s) of your ${localize(
              "flow"
            )}.`,
          }}
          dataTableProps={{
            headers: useFlowHeaders().filter(({ key }) =>
              [FLOW_ATTRIBUTES.NAME.key, FLOW_ATTRIBUTES.TYPE.key].includes(key)
            ),
            sorting,
            isLoading,
            items,
            pagination: {
              page: tableState.page,
              itemsPerPage: tableState.itemsPerPage,
              total: data?.total_entries ?? 0,
            },
            onSortChange: updateTableState,
            onPaginationChange: updateTableState,
            isFluid: false,
            getItemActions,
          }}
        />
      </div>
    </div>
  );
};
