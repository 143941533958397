import { useEffect, useState } from "react";
import FacilityService from "#services/FacilityService";

export function useFacilities() {
  const [facilities, setFacilities] = useState([]);
  const [fetchState, setFetchState] = useState("loading");

  useEffect(() => {
    FacilityService.getFacilities()
      .then(({ data }) => {
        setFacilities(data);
        setFetchState("loaded");
      })
      .catch(() => {
        setFetchState("error");
      });
  }, []);

  return [facilities, fetchState];
}

export function useFacilityDetail(facilityId) {
  const [facility, setFacility] = useState(undefined);
  const [fetchState, setFetchState] = useState("loading");

  useEffect(() => {
    if (facilityId) {
      setFetchState("loading");
      FacilityService.getFacilityDetail(facilityId)
        .then(({ data: facilityDetail }) => {
          setFacility(facilityDetail);
          setFetchState("loaded");
        })
        .catch(() => {
          setFetchState("error");
        });
    }
  }, [facilityId]);

  return [facility, fetchState];
}
