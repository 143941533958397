import { EmissionCustomReportListTab } from "#batteries-included-components/Tabs/CustomReports/EmissionCustomReportListTab";
import { VolumeCustomReportListTab } from "#batteries-included-components/Tabs/CustomReports/VolumeCustomReportListTab";
import { useSearchParams } from "#routers/hooks";
import { REPORTS_BREADCRUMB } from "#routes/reports";
import {
  CUSTOM_REPORT_LIST_BREADCRUMB,
  CUSTOM_REPORT_LIST_PAGE_TITLE,
} from "#routes/reports/custom-reports/index";
import { useIsFeatureAvailable } from "#src/contexts/AuthenticatedContext.helpers";
import { Page, Tab } from "@validereinc/common-components";
import React from "react";

export const CustomReportListPage = () => {
  const [{ tab }, setSearchParams] = useSearchParams();
  const [isFlowVolumeEnabled] = useIsFeatureAvailable({
    featureFlagQuery: "core:flows",
    permissionQuery: { $and: ["flows:read", "records:read"] },
  });
  const [isEquipmentEmissionsEnabled] = useIsFeatureAvailable({
    featureFlagQuery: "core:equipment",
    permissionQuery: { $and: ["equipment:read", "calculator_results:read"] },
  });

  return (
    <Page
      title={CUSTOM_REPORT_LIST_PAGE_TITLE}
      breadcrumbs={[REPORTS_BREADCRUMB, CUSTOM_REPORT_LIST_BREADCRUMB]}
      activeTabKey={tab ?? (isFlowVolumeEnabled ? "volume" : "emissions")}
      onActiveTabKeyChange={(tab) => setSearchParams({ tab })}
    >
      {isFlowVolumeEnabled ? (
        <Tab
          title="Volume"
          tabKey="volume"
        >
          <VolumeCustomReportListTab />
        </Tab>
      ) : null}
      {isEquipmentEmissionsEnabled ? (
        <Tab
          title="Emissions"
          tabKey="emissions"
        >
          <EmissionCustomReportListTab />
        </Tab>
      ) : null}
    </Page>
  );
};
