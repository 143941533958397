import {
  DEFAULT_QUERY_OPTIONS,
  UseQueryOptionsType,
} from "#hooks/adapters/adapterUtils";
import { useQueries, useQuery } from "@tanstack/react-query";
import { ReportingGroupDomain } from "@validereinc/domain";

export const REPORTING_GROUP_KEY = "reportingGroups";

export const useGetOneReportingGroup = (id?: string) =>
  useQuery({
    queryKey: [REPORTING_GROUP_KEY, "getOne", id],
    queryFn: ({ queryKey: [_, __, id] }) =>
      id ? ReportingGroupDomain.getOne({ id }) : undefined,
    enabled: !!id,
    ...DEFAULT_QUERY_OPTIONS,
  });

export const useGetManyReportingGroups = (ids: string[]) =>
  useQueries({
    queries: ids.map((id) => ({
      queryKey: [REPORTING_GROUP_KEY, "getOne", id],
      queryFn: () => (id ? ReportingGroupDomain.getOne({ id }) : undefined),
      select: (resp) => resp?.data,
      ...DEFAULT_QUERY_OPTIONS,
    })),
  });

export const useListReportingGroups = (
  params: Parameters<typeof ReportingGroupDomain.getList>[0] = {},
  options: UseQueryOptionsType<
    Awaited<ReturnType<typeof ReportingGroupDomain.getList>>
  > = {}
) =>
  useQuery({
    queryKey: [REPORTING_GROUP_KEY, "getList", params] as const,
    queryFn: ({ queryKey: [_, __, params] }) =>
      ReportingGroupDomain.getList(params),
    ...DEFAULT_QUERY_OPTIONS,
    ...options,
  });
