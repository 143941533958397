import moment from "moment";
import "moment-timezone";
import isNil from "lodash/isNil";

const TODAY = moment().toDate();

export const TestTypeOptions = [
  {
    id: "field",
    name: "Field",
  },
  {
    id: "third_party_lab",
    name: "Third Party Lab",
  },
  {
    id: "manual",
    name: "Manual",
  },
];

export const frequencyTypeOptions = (repeats) => {
  return [
    { id: "Week", name: repeats > 1 ? "Weeks" : "Week" },
    { id: "Month", name: repeats > 1 ? "Months" : "Month" },
  ];
};

export const DEFAULT_INPUTS = {
  name: "",
  startDay: TODAY,
  timezone: "(GMT+00:00) Etc/UTC",
  frequency: {},
  repeats: 1,
  frequencyType: frequencyTypeOptions()[0],
  repeatsDay: [],
  endOn: "Never",
  occurrences: "",
  repeats_per_scheduling_period: 1,
  testType: TestTypeOptions[0],
  selectedStreamList: [],
  streamIds: [],
  testsRequired: [],
  offspec_retest: false,
  offspec_retest_3pl: false,
  alertSettings: { late: true, completed: false, missed: true },
  alert: ["late", "missed"],
};

export const prepareWorkflow = (formInputs, updateActive, workflowId) => {
  const workflow = {
    alertSettings: formInputs.alertSettings,
    frequency: formInputs.frequency,
    name: formInputs.name,
    occurrences:
      formInputs.endOn === "Never" || !formInputs.occurrences
        ? null
        : Number(formInputs.occurrences),
    offspec_retest: formInputs.offspec_retest,
    offspec_retest_3pl: formInputs.offspec_retest_3pl,
    repeats_per_scheduling_period: Number(
      formInputs.repeats_per_scheduling_period
    ),
    siteId: formInputs.siteId,
    startTime: moment(formInputs.startDay).unix(),
    streamIds: formInputs.streamIds,
    testType: formInputs.testType.id,
    testsRequired: formInputs.testsRequired,
    timezone: formInputs.timezone.split(" ")[1],
  };

  if (!isNil(updateActive)) {
    workflow.updateActive = updateActive;
  }

  if (!isNil(workflowId)) {
    workflow.workflowId = workflowId;
  }

  return workflow;
};

// Map: (GMT-06:00) America/Denver => "America/Denver"
function GetAvailableTimezone() {
  const timezones = moment.tz.names();
  const availableTimezones = {};

  timezones.map((timezone) => {
    const name = formatTimezoneName(timezone);
    availableTimezones[name] = timezone;
  });
  return availableTimezones;
}

// "America/Denver" => (GMT-06:00) America/Denver
export function formatTimezoneName(timezone) {
  const name = `(GMT${moment.tz(timezone).format("Z")}) ${timezone}`;
  return name;
}

const timezoneMap = GetAvailableTimezone();
export const timezoneOptions = Object.keys(timezoneMap);

export const WEEK = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
];

export const OFFSPEC = ["Retest", "Request 3rd Party Lab Test"];

export const ALERT_TYPE = ["late", "completed", "missed"];
