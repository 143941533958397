export const FACILITIES_LIST_PAGE_TITLE = "facility_plural";

export const linkToFacilityList = () => "/app/organization/facilities";

export const FACILITIES_LIST_BREADCRUMB = {
  title: FACILITIES_LIST_PAGE_TITLE,
  path: linkToFacilityList,
};

export * from "./FacilityListPage";
